import React from 'react'
import { $, Img, Text, Row, Column } from 'atoms'
import TextField from '@material-ui/core/TextField'

const BindContent = ({
  submission,
  quotation,
  coverageStartDate,
  handleDateChange,
}) => {
  return (
    <Row>
      <Column flex="1">
        <Row>
          <Column width="auto" mb={1} mr={1}>
            <Text fontSize="s">Named Insured</Text>
            <Text fontWeight={2} fontSize="m">
              {submission.name}
            </Text>
          </Column>
        </Row>
        <Row justifyContent="space-between" mb={1}>
          <Column>
            <Text fontSize="s">Limit</Text>
            <Text fontWeight={2} fontSize="m">
              <$>{quotation.limit}</$>
            </Text>
          </Column>
          <Column>
            <Text fontSize="s">Deductible</Text>
            <Text fontWeight={2} fontSize="m">
              <$>{quotation.deductible}</$>
            </Text>
          </Column>
          <Column>
            <Text fontSize="s">Cost for Insured</Text>
            <Text fontWeight={2} fontSize="m">
              <$>{quotation.quotedLines?.[0].clientCost}</$>
            </Text>
          </Column>
        </Row>
        <Row>
          <Column minWidth="25%" width="auto" mb={1} mr={1}>
            <Text fontSize="s">Policy Effective Date</Text>
            <TextField
              id="date"
              type="date"
              defaultValue={coverageStartDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Column>
        </Row>
      </Column>
      <Column flex="0" alignItems="center" mx={0}>
        {quotation.logoUrl && (
          <Img
            width="150px"
            pt={0}
            src={quotation.logoUrl}
            title={`CFC Logo`}
          />
        )}
      </Column>
    </Row>
  )
}

export default BindContent
