import React from 'react'
import styled from 'styled-components'
import { layout } from 'styled-system'
import { color } from 'styled-system'
import { transparentize } from 'polished'

import useOnClickOutside from 'hooks/onClickOutside'
import { Panel } from 'atoms'

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: ${props => transparentize(0.6, props.theme.colors.black)};
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalCard = styled(Panel)`
  background-color: ${props =>
    props.light
      ? props.theme.colors.white
      : props.theme.colors.secondaryBackground};
  width: ${props => (props.wide ? '1000px' : props.width || '650px')};
  min-height: 200px;
  display: block;
  max-height: 80%;
  overflow-y: auto;

  & input,
  & textarea {
    background-color: ${props =>
      props.light
        ? props.theme.colors.white
        : props.theme.colors.secondaryBackground};
  }
  ${color}
  ${props => props.background && `background: ${props.background};`}
  ${layout}
`

const Modal = ({
  children,
  light = false,
  wide = false,
  handleClose = null,
  persistant = false,
  ...rest
}) => {
  const ref = useOnClickOutside(
    () => !persistant && handleClose && handleClose()
  )

  return (
    <ModalContainer>
      <ModalCard {...rest} ref={ref} light={light} wide={wide}>
        {children}
      </ModalCard>
    </ModalContainer>
  )
}

export default Modal
