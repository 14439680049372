import React, { Suspense, lazy } from 'react'
import { Loading } from 'atoms'

const LazyDateSelect = lazy(() => import('./DateSelect'))

const DateSelect = props => {
  return (
    <Suspense fallback={<Loading />}>
      <LazyDateSelect {...props} />
    </Suspense>
  )
}

export default DateSelect
