import styled from 'styled-components'
import { transparentize } from 'polished'
import { space, layout } from 'styled-system'
import { Div } from 'atoms'

const colorEdge = props => {
  if (props.submission) {
    return `border-top: 0.625rem solid ${props.theme.colors.secondaryYellow};`
  }
  if (props.quote) {
    return `border-top: 0.625rem solid ${props.theme.colors.quote};`
  }
  if (props.binder) {
    return `border-top: 0.625rem solid ${props.theme.colors.binder};`
  }
  if (props.certificate) {
    return `border-top: 0.625rem solid ${props.theme.colors.certificate};`
  }
  if (props.primary) {
    return `border-top: 0.625rem solid ${props.theme.colors.primary};`
  }
  if (props.info) {
    return `border-left: 0.8rem solid ${props.theme.colors.primary};`
  }
}

const gradient = props => {
  if (props.gradient) {
    if (props.certificate) {
      return `background-image:
        linear-gradient(
          to bottom,
          ${transparentize(0.85, props.theme.colors.certificate)},
          ${props.theme.colors.white} ${props.gradient}%
        );
      );`
    } else if (props.info) {
      return `background-image:
        linear-gradient(
          150deg,
          ${transparentize(0.9, props.theme.colors.certificate)},
          ${props.theme.colors.white} ${props.gradient}%
        );
      );`
    }
  }
}

const Panel = styled(Div)`
  box-sizing: border-box;
  border-radius: ${props => (props.info ? '.3rem' : '0.625rem')};
  box-shadow: 4px 4px 15px
    ${props => transparentize(0.76, props.theme.colors.primary)};
  background-color: white;

  & input,
  & textarea {
    background-color: ${props => props.theme.colors.white};
  }

  ${colorEdge}
  ${gradient}
  ${space}
  ${layout}
`
Panel.defaultProps = {
  p: 2,
}
Panel.displayName = 'Panel'

export default Panel
