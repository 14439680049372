import { default as Autocomplete } from './Autocomplete'
import { default as Currency } from './Currency'
import { default as CurrencyDropdown } from './CurrencyDropdown'
import { default as Checkboxes } from './Checkboxes'
import { default as ButtonGroup } from './ButtonGroup'
import { default as Warning } from './Warning'
import { default as RichText } from './RichText'
import { default as DatePicker } from './Date'
import { default as DottedTrail } from './DottedTrail'
import { default as CustomText } from './CustomText'
import { default as Label } from './Label'
import { default as ReadOnlyCurrency } from './ReadOnlyCurrency'
import { default as ReadOnlyText } from './ReadOnlyText'
import { default as Subtitle } from './Subtitle'
import { default as NaicsCode } from './NaicsCode'
import { withSideHelperText } from './WithSideHelperText'
import { default as Checkbox } from './Checkbox'
import { default as PlacementTermsCheckbox } from 'components/react-json-schema-form/Widgets/CheckboxWidget/PlacementTermsCheckbox'
import { default as CustomWebsite } from './CustomWebsite'

export default {
  autocomplete: withSideHelperText(Autocomplete),
  currency: withSideHelperText(Currency),
  naicsSuggestions: NaicsCode,
  currencyDropdown: CurrencyDropdown,
  customText: CustomText,
  customWebsite: CustomWebsite,
  checkboxes: Checkboxes,
  'radio-button': ButtonGroup,
  richText: RichText,
  SelectWidget: Autocomplete,
  DateWidget: DatePicker,
  dottedTrail: DottedTrail,
  label: Label,
  warning: Warning,
  readOnlyText: ReadOnlyText,
  readOnlyCurrency: ReadOnlyCurrency,
  subtitle: Subtitle,
  CheckboxWidget: Checkbox,
  'placement-terms-checkbox': PlacementTermsCheckbox,
}
