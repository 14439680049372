import jp, { mapJson, mapFields } from 'util/jsonpath'

export const EndorsementMapping = {
  ...mapFields(['name', 'limit', 'coinsurance']),
}

export const AdditionalFeaturesMapping = {
  ...mapFields(['description', 'title']),
}

export const CoverageMapping = {
  ...mapFields(['name', 'type', 'limit', 'deductible', 'waitingPeriod']),
}

export const QuotationMapping = {
  ...mapFields([
    'id',
    'indication',
    'participationId',
    'submissionId',
    'status',
    'statusMessage',
    'admitted',
    'declinationReason',
    'limit',
    'deductible',
    'documents',
    'totalCost',
    'premium',
    'fee',
    'submissionId',
    'submissionOwner',
    'surplusLinesTax',
    'signingUrl',
    'policyNumber',
    'requestedAt',
    'selected',
    'tax',
    'retailCommissionPercentage',
    'retailCommission',
    'underwriterFirstName',
    'underwriterLastName',
    'underwriterEmail',
    'requestedLimit',
    'requestedDeductible',
    'archivedAt',
  ]),
  ambestRating: 'ambestRating.rating',
  carrier: ['apiUser.firstName', 'user.company.name', 'externalCarrier.name'],
  externalCarrier: 'externalCarrier.name',
  isApi: 'collaborator.api',
  isManual: quotation => quotation.type === 'Cyber::ManualQuotation',
  isCarrier: quotation => quotation.type === 'Cyber::CarrierQuotation',
  logoUrl: [
    'apiUser.avatarUrl',
    'user.company.logoUrl',
    'externalCarrier.logoUrl',
  ],
  isExtracted: quotation =>
    quotation.extractedAt !== null && quotation.reviewedAt === null,
  isOutdated: quotation => quotation.status === 'outdated',
  additionalFeatures: quotation => {
    if (quotation.additionalFeatures) {
      return quotation.additionalFeatures.map(af =>
        mapJson(af || {}, AdditionalFeaturesMapping)
      )
    }
    return []
  },
  endorsements: quotation => {
    if (quotation.endorsements) {
      return quotation.endorsements.map(endorsement =>
        mapJson(endorsement || {}, EndorsementMapping)
      )
    }
    return []
  },
  coverages: quotation => {
    if (quotation.coverages) {
      return quotation.coverages.map(coverage =>
        mapJson(coverage || {}, CoverageMapping)
      )
    }
    return []
  },
}

export const SubmissionMapping = {
  ...mapFields([
    'id',
    'country',
    'name',
    'limit',
    'deductible',
    'documents',
    'coverageStartDate',
    'lineOfBusiness',
    'revisedAt',
    'customLabelForFees',
  ]),
  panelId: 'panel.id',
  quotations: submission => {
    const participations = jp.query(submission, 'participations.nodes[*]')

    return participations.reduce(
      (
        memo,
        {
          id: participationId,
          createdAt,
          documents,
          user,
          apiUser,
          quotations,
          collaborator,
        }
      ) => {
        if (quotations.nodes.length > 0) {
          return memo.concat(
            quotations.nodes.map(quotation => {
              return mapJson(
                {
                  ...quotation,
                  documents: quotation.documents.concat(documents),
                  user,
                  apiUser,
                  participationId,
                  collaborator,
                  requestedAt: createdAt,
                  submissionId: submission.id,
                  submissionOwner: submission.owner.fullName,
                  country: submission.country,
                  revisedAt: submission.revisedAt,
                },
                QuotationMapping
              )
            })
          )
        } else {
          return memo.concat([
            mapJson(
              {
                status: 'pending',
                user,
                apiUser,
                participationId,
                collaborator,
                requestedAt: createdAt,
                submissionId: submission.id,
                submissionOwner: submission.owner.fullName,
                country: submission.country,
                revisedAt: submission.revisedAt,
              },
              QuotationMapping
            ),
          ])
        }
      },
      []
    )
  },
}
