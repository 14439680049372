import React from 'react'
import { Row, ButtonContainer, Button, Div } from 'atoms'
import { Field } from 'react-final-form'
import { TextInput } from 'atoms/form'
import { combine, email, required } from 'redux-form-validators'
import validateEmailDomain from 'validators/emailDomain'

const NewUserForm = ({ onSubmit, onClose }) => {
  return (
    <>
      <Row mb={2} width="100%">
        <Div mr={5} flexGrow={1}>
          <Field
            component={TextInput}
            name="attributes.firstName"
            label="First Name"
            required
            validate={required()}
            mb={2}
          />
        </Div>
        <Div ml={5} flexGrow={1}>
          <Field
            component={TextInput}
            name="attributes.lastName"
            label="Last Name"
            required
            validate={required()}
            mb={2}
          />
        </Div>
      </Row>
      <Field
        component={TextInput}
        name="attributes.email"
        label="Email"
        required
        validate={combine(
          required({ message: 'Enter a email address' }),
          email({ message: 'Enter a valid Email' }),
          validateEmailDomain
        )}
        mb={2}
      />
      <ButtonContainer justifyContent="space-between">
        <Button onClick={onClose} light>
          Cancel
        </Button>
        <Button onClick={onSubmit} primary>
          Save and Reassign
        </Button>
      </ButtonContainer>
    </>
  )
}

export default NewUserForm
