import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  formatter as currencyFormatter,
  normalizer as currencyNormalizer,
} from 'util/currency'
import {
  formatter as percentageFormatter,
  normalizer as percentageNormalizer,
} from 'util/percentage'

const KNOWN_TYPES = {
  custom: 'Enter Custom Value',
  money: '$',
  percentage: '%',
}

const formatter = ({ value, type }) => {
  if (type === 'money') {
    // to always show a dollar sign when type money is selected
    return value === '$' || value === '' ? '$' : currencyFormatter(value)
  } else if (type === 'percentage') {
    // to always show a percentage sign when type percentage is selected
    return value === '%' || value === '' ? '%' : percentageFormatter(value)
  } else {
    return value
  }
}

export const typeAndValue = ({
  newValue,
  currentType,
  reason,
  optionLabel,
}) => {
  // reason is either input or reset. Input if user types. Reset if user select from dropdown
  if (
    optionLabel.includes(newValue) &&
    !Object.values(KNOWN_TYPES).includes(newValue)
  ) {
    return { type: newValue, value: newValue }
  }
  // for money type
  if (newValue === '$' && (currentType !== 'custom' || reason === 'reset')) {
    return { type: 'money', value: '' }
  }
  if (currentType === 'money' && newValue.startsWith('$')) {
    return { type: 'money', value: currencyNormalizer(newValue) }
  }

  // for percentage
  if (newValue === '%' && currentType !== 'custom') {
    return { type: 'percentage', value: '' }
  }
  if (currentType === 'percentage' && /%/.test(newValue)) {
    return {
      type: 'percentage',
      value: percentageNormalizer(newValue.replace(/%/g, '')),
    }
  }

  if (newValue === 'Enter Custom Value') return { type: 'custom', value: '' }
  return { type: 'custom', value: newValue }
}

const MultiInputTypeDropdown = ({
  formData,
  schema,
  onChange,
  label = schema.title,
  required,
  id,
  uiSchema,
  errorSchema,
  rawErrors,
}) => {
  const [open, setOpen] = useState(false)
  const defaultData = {
    type: schema.properties.type.default || '',
    value: schema.properties.value.default || '',
  }
  const hasError = !!(errorSchema?.value || rawErrors)

  const errorText = errorSchema?.value
    ? required
      ? 'is required'
      : errorSchema.value
    : null
  const data = formData ? formData : defaultData
  const { 'ui:styles': styles = {} } = uiSchema
  const { alignSelf, width } = styles
  const separateLabel = uiSchema['ui:labelPosition'] === 'separate'
  const optionLabel = schema.optionsLabel || schema.options
  const options = schema.options.map((value, index) => ({
    value,
    label: optionLabel[index],
  }))

  const handleInputChange = (event, value, reason) => {
    onChange(
      typeAndValue({
        newValue: value,
        currentType: data.type,
        reason,
        optionLabel,
      })
    )
    setOpen(false)
  }
  const handleOpen = event => {
    if (event.type === 'click') setOpen(true)
    if (event.type === 'keydown' && event.key === 'ArrowDown') setOpen(true)
  }
  const handleEnterPressed = event => {
    if (event.key == 'Enter') {
      event.preventDefault() //prevent from submitting the form
    }
  }
  const formattedValue = formatter(data)
  const field = (
    <Autocomplete
      freeSolo
      inputValue={formattedValue}
      value={formattedValue}
      onInputChange={handleInputChange}
      disableClearable={true}
      forcePopupIcon={true}
      style={{ width: width || 'auto', alignSelf: alignSelf || 'auto' }}
      renderInput={params => (
        <TextField
          id={id}
          label={separateLabel ? null : label}
          error={hasError}
          required={required}
          variant="outlined"
          helperText={errorText}
          {...params}
          onKeyDown={handleEnterPressed}
        />
      )}
      options={options}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.label
      }
      getOptionSelected={option => option.value === data.type}
      open={open}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      onFocus={() => setOpen(true)}
      onDoubleClick={() => setOpen(true)}
    />
  )
  if (separateLabel) {
    return (
      <Grid container alignItems="baseline" justify="space-between">
        <Typography
          component="label"
          htmlFor={id}
          style={{ alignSelf: 'center' }}>
          {label}
        </Typography>
        {field}
      </Grid>
    )
  }

  return field
}

export default MultiInputTypeDropdown
