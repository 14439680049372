import { SubmissionConfig } from 'pages/shared/panels'
import SubmissionModel from 'models/panels/BOP'
import { lineKeys } from '../utils'
import { isEmpty } from 'lodash'
import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'
import startCase from 'lodash/startCase'

const defaults = submission => {
  submission.email_carriers = []
  submission.message = SubmissionConfig.defaultMessage(
    "Business Owner's Policy"
  )
}
const schemas = (schemas, editSubmission) => {
  let modifiedSchema = { ...schemas }
  Object.entries(modifiedSchema.schema.properties).forEach(
    ([key, property]) => {
      const icons = {
        applicant: 'file-alt',
        business_owner: 'briefcase',
        review: 'paper-plane',
      }
      if (editSubmission) {
        property.tab = startCase(key)
        property.icon = icons[property.tab]
      } else {
        property.icon = icons[key]
        property.tab = startCase(key)
      }
      if (property.title.length === 0) {
        property.title = property.tab
      }
    }
  )
  let review = modifiedSchema.schema.properties.review
  delete modifiedSchema.schema.properties.email_carriers
  delete modifiedSchema.schema.properties.review
  delete modifiedSchema.schema.properties.documents
  delete modifiedSchema.schema.properties.message
  modifiedSchema.schema.properties.review = review
  return modifiedSchema
}

const formatData = (formData, isEdit) => {
  if (isEdit) {
    delete formData.participations
    delete formData.owner
    delete formData.panel
  } else {
    const bopQuotes = {
      type: 'BOP::Product',
      ...formData.business_owner,
      coverages_attributes: formData.business_owner.coverages,
    }
    formData.name = formData.applicant.company_name
    formData.applicant.address_attributes = formData.applicant.address
    delete formData.applicant.address
    delete bopQuotes.coverages
    formData.products_attributes = [bopQuotes]
    delete formData.business_owner
    delete formData.review
  }
}

const model = participation => {
  const submissionModel = new SubmissionModel()
  return submissionModel.build(participation.submission)
}

const submissionData = ({ products, panel_info, ...submission }) => {
  submission.panel_info = JSON.parse(panel_info)
  products.forEach(product => {
    const key = lineKeys[product.__typename]
    submission[key] = product
  })
  const carriers = {}
  submission.email_carriers = []
  submission.participations.nodes.map(participation => {
    const userId = participation.user?.id
    if (userId && !carriers[userId]) {
      submission.email_carriers.push({
        id: participation.user.id,
      })
      carriers[userId] = true
    }
  })
  return submission
}

const panel = { gradient: 5, certificate: true }

const getFormData = ({ data }) => {
  const submission = data.editPanelSubmission.submission
  if (!isEmpty(submission)) {
    toast('Saved')
  }
  return formatData(submissionData(submission))
}

const getFormErrors = ({ data }) => {
  const errors = data?.editPanelSubmission?.errors
  if (!isEmpty(errors)) {
    toast.error('Failed to save changes')
    Sentry.captureException(errors)
  }
  return errors
}

const flexiForm = () => ({
  submitLabel: null,
  multiline: true,
  getFormData,
  getFormErrors,
})

export default {
  defaults,
  schemas,
  formatData,
  submissionData,
  model,
  panel,
  flexiForm,
}
