import React from 'react'

import { Flex, Text } from 'atoms'

const ReadOnlyText = ({ value, label, uiSchema }) => {
  const { 'ui:styles': styles = {} } = uiSchema
  const {
    justifyContent,
    marginTop,
    marginRight,
    marginBottom,
    width,
    flexLabel,
    flexValue,
  } = styles
  // if there is no label
  if (!label)
    return (
      <Flex alignItems="center" height="40px">
        <Text>{value}</Text>
      </Flex>
    )
  return (
    <Flex
      justifyContent={justifyContent || 'space-between'}
      mt={marginTop || '0px'}
      mr={marginRight || '0px'}
      mb={marginBottom || '0px'}
      width={width || '100%'}>
      <Text flex={flexLabel || 'initial'} mr={'5px'}>
        {label}
      </Text>
      <Text flex={flexValue || 'initial'}>
        {' '}
        <strong> {value} </strong>
      </Text>
    </Flex>
  )
}

export default ReadOnlyText
