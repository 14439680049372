import React from 'react'
import { PercentRenderer, MoneyRenderer, TextRenderer } from '.'
const rendererType = type => {
  let rendererType = MoneyRenderer
  switch (type) {
    case 'money':
      rendererType = MoneyRenderer
      break
    case 'percentage':
      rendererType = PercentRenderer
      break
    default:
      rendererType = TextRenderer
  }
  return rendererType
}
// this is renderer is to be used for any form field that uses  the MultiInputTypeDropdown component in FlexiForm
const MultiTypeRenderer = ({ children: data, ...props }) => {
  const Renderer = rendererType(data?.type)
  return <Renderer {...props}>{data?.value}</Renderer>
}

export default MultiTypeRenderer
