import useCurrentUser from './currentUser'

const PLANS = [
  'free',
  'launch',
  'grow',
  'fit',
  'scale',
  'plus',
  'premium',
  'guest',
  'enterprise',
]

const usePlan = reqs => {
  const user = useCurrentUser()
  const plan = !user
    ? 'free'
    : reqs.ignoreTrial
    ? user.company.subscription.plan
    : user.company.subscription.featureLevel
  if (reqs.exactly) {
    return plan === reqs.exactly
  }
  let success = true
  if (reqs.atLeast) {
    success = PLANS.indexOf(plan) >= PLANS.indexOf(reqs.atLeast)
  }
  if (reqs.atMost) {
    success = success && PLANS.indexOf(plan) <= PLANS.indexOf(reqs.atMost)
  }
  return success
}

export const useFeatureLevel = () => {
  const currentUser = useCurrentUser()
  return currentUser ? currentUser.company.subscription.featureLevel : 'free'
}

export default usePlan
