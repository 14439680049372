import nextMAndALogo from 'images/nfp/nextM&ALogo.png'

export const DOMAINS = [
  'nfp.relay.test',
  'nfpinsurer.relay.test',
  'nfp.sandbox.relayplatform.com',
  'nfp.qa.relayplatform.com',
  'nfp.demo.relayplatform.com',
  'rw.demo.relayplatform.com',
  'nfp.relayplatform.com',
  'next.nfp.com',
]

export default {
  brand: 'nfp',
  brandName: 'NextM&A',
  name: 'NextM&A',
  logo: nextMAndALogo,
  logoAlt: 'NFP | Next M&A',
  cobranded: true,
  showHelp: false,
  allowSignup: false,
  billable: true,
}
