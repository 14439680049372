import jp from 'jsonpath'
import isObject from 'lodash/isObject'
import isFunction from 'lodash/isFunction'

const mapJson = (json, mappings) => {
  return Object.entries(mappings).reduce((result, [key, path]) => {
    if (isFunction(path)) {
      result[key] = path(json)
    } else if (Array.isArray(path)) {
      result[key] = path.map(p => jp.query(json, p)[0]).filter(Boolean)[0]
    } else if (isObject(path)) {
      result[key] = mapJson(json, path)
    } else {
      // jp.query returns all matching results - for our mappings the paths need
      // to be unique so we return only the first.
      result[key] = jp.query(json, path)[0]
    }
    return result
  }, {})
}

const mapFields = fields =>
  fields.reduce((memo, field) => ({ ...memo, [field]: field }), {})

export { mapJson, mapFields }
export default jp
