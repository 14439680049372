import React from 'react'
import { toast } from 'react-toastify'
import { useSwitchToken } from 'hooks'
import GeneralInboxAlert from './GeneralInboxAlert'
import {
  useChangeQuoteOwnerNewUserMutation,
  useChangeParicipationNewOwnerMutation,
} from 'graphql/api'

export const SubmissionGeneralInboxAlert = ({ participation }) => {
  const [switchToken] = useSwitchToken()

  const [change] = useChangeParicipationNewOwnerMutation()
  const onChange = async ({ attributes }) => {
    const response = await change({
      variables: {
        input: {
          participationId: participation.id,
          newOwnerAttributes: attributes,
        },
      },
    })

    const token = response.data.changeParicipationNewOwner.invitationToken
    if (token) switchToken(token)
  }
  return <GeneralInboxAlert changeOwner={onChange} />
}

export const worksheetGeneralInboxOnChange =
  ({ change, quote, switchToken }) =>
  async ({ attributes }) => {
    const response = await change({
      variables: {
        input: {
          quoteId: quote.id,
          newOwnerAttributes: attributes,
        },
      },
    })

    const token = response.data.changeQuoteOwnerNewUser.invitationToken
    if (token) {
      switchToken(token)
    } else {
      toast.error(response.data.changeQuoteOwnerNewUser.errors[0])
    }
  }

export const WorksheetGeneralInboxAlert = ({ quote }) => {
  const [switchToken] = useSwitchToken()

  const [change] = useChangeQuoteOwnerNewUserMutation()
  const onChange = worksheetGeneralInboxOnChange({ change, quote, switchToken })
  return <GeneralInboxAlert changeOwner={onChange} />
}
