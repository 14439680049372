import lodashTruncate from 'lodash/truncate'

const truncate = (str, char = 30, omission = '...') =>
  lodashTruncate(str, { length: char, omission: omission })

export const truncateInTheMiddle = (
  str,
  maxLength = 25,
  omission = ' ... '
) => {
  if (str.length <= maxLength) return str

  const charsToShow = maxLength - omission.length
  const frontHalf = Math.ceil(charsToShow / 2)
  const backHalf = Math.floor(charsToShow / 2)

  return str.substr(0, frontHalf) + omission + str.substr(str.length - backHalf)
}

export default truncate
