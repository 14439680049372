import React from 'react'
import { Warning } from 'components/common/Indicators'
import styled from 'styled-components'

// Stolen from https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
export const IS_IE = false || !!window.document.documentMode

const Container = styled.div`
  display: flex;
  margin-top: 30px;
`

const NoMoreIE = () =>
  IS_IE ? (
    <Container>
      <Warning>
        Your browser is an old version. Relay will run faster and more reliably
        in a modern browser like Chrome.
      </Warning>
    </Container>
  ) : null

export default NoMoreIE
