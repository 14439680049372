import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import {
  typography,
  space,
  color,
  flexbox,
  layout,
  border,
  background,
  position,
  shadow,
} from 'styled-system'
import { colors } from 'components/theme'

import { LightContainer } from 'components/common/Layout'

const Card = styled(LightContainer)`
  width: 100%;
  display: flex;
  outline: none;
  border-radius: 9px;
  padding: 1rem;
  box-shadow: 0px 2px 12px 0px ${props => rgba(props.theme.colors.black, 0.24)};

  ${typography}
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${border}
  ${background}
  ${position}
  ${shadow}
`

export const CardBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 155px;

  ${typography}
  ${space}
  ${color}
  ${flexbox}
  ${layout}
  ${border}
  ${background}
  ${position}
  ${shadow}
`

export const CardWithoutPadding = styled(Card)`
  padding: 0;
`

export const CardWithBorderAndGradient = ({ children }) => (
  <Card p={2} borderLeft="16px solid" background={colors.blueGradient}>
    {children}
  </Card>
)

export default Card
