import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { colors, fonts } from '../theme'

const Link = styled.span`
  cursor: pointer;
  color: ${colors.link};
  font-size: 16px;
  font-family: ${fonts.secondary};
`
const ActionLink = ({ onClick, children }) => (
  <Link onClick={onClick}>{children}</Link>
)

ActionLink.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default ActionLink
