import React from 'react'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'

import { ModalContainer } from 'components/common/Modal'
import { Panel, Button } from 'atoms'
import { TextInput } from 'atoms/form'
import { useOnClickOutside } from 'hooks'
import { required } from 'util/validators'

const ModalCard = styled(Panel)`
  width: 600px;
  display: block;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const ModalInput = ({
  label,
  initialValue,
  submit,
  close,
  action = 'Go',
  ...rest
}) => {
  const ref = useOnClickOutside(close)
  const onKeyPress = (save, close) => event => {
    switch (event.key) {
      case 'Enter':
        return save()
      case 'Escape':
        return close()
      default:
      // do nothing
    }
  }

  const onSubmit = values => submit(values[label])

  return (
    <ModalContainer>
      <Form onSubmit={onSubmit} initialValues={{ [label]: initialValue }}>
        {({ handleSubmit }) => (
          <ModalCard ref={ref} {...rest}>
            <Field
              label={label}
              name={label}
              component={TextInput}
              autoFocus
              onKeyUp={onKeyPress(handleSubmit, close)}
              fontSize="xl"
              validate={required()}
            />
            <Button ml={2} onClick={() => handleSubmit()}>
              {action}
            </Button>
          </ModalCard>
        )}
      </Form>
    </ModalContainer>
  )
}

export default ModalInput
