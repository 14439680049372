import React from 'react'
import styled from 'styled-components'

import {
  NotReinsurer,
  ReinsurerOnly,
  LoggedInOnly,
  CompletedProfileOnly,
  BrokerOnly,
  InsuranceOnly,
} from '../contexts/user'
import FeatureSwitch from '../common/FeatureSwitch'
import ManageMenu from './menu/ManageMenu'
import NotificationsMenu from 'components/header/notifications/NotificationsMenu'
import StyledNavLink, { LinkBorder } from 'atoms/StyledNavLink'
import { isGuest } from 'hooks/currentUser'
import { useBranding, useCurrentUser, useFeature } from 'hooks'
import NetDiligence from './Netdiligence'

const NavContainer = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 50px;
  min-width: 100%;
  height: 80px;
`

const ZENDESK_LINK =
  'https://relayplatformhelp.zendesk.com/hc/en-us/requests/new?ticket_form_id=360002777654'

const RWNav = ({ showHelp }) => (
  <LoggedInOnly>
    <NotReinsurer>
      <StyledNavLink to="/submissions" activeClassName="active">
        Place
      </StyledNavLink>
      <ManageMenu />
    </NotReinsurer>
    <ReinsurerOnly>
      <StyledNavLink to="/quotes" activeClassName="active">
        Quote
      </StyledNavLink>
      <ManageMenu />
    </ReinsurerOnly>
    {showHelp && (
      <LinkBorder>
        <StyledNavLink as="a" target="_blank" href={ZENDESK_LINK}>
          Request a Feature
        </StyledNavLink>
      </LinkBorder>
    )}
    <NotificationsMenu />
  </LoggedInOnly>
)

const OtherNav = ({ showHelp, isWhitelabel }) => {
  const user = useCurrentUser()
  const userIsGuest = isGuest(user)
  const replaceZendesk = useFeature('replaceZendesk')

  return (
    <LoggedInOnly>
      <CompletedProfileOnly>
        <NotReinsurer>
          <StyledNavLink to="/submissions" activeClassName="active">
            Place
          </StyledNavLink>
          {!userIsGuest && (
            <StyledNavLink to="/reports" activeClassName="active">
              Analyze
            </StyledNavLink>
          )}
          <ManageMenu />
          {!userIsGuest && (
            <StyledNavLink to="/integrate" activeClassName="active">
              Integrate
            </StyledNavLink>
          )}
          <FeatureSwitch withoutFeature="hideNetDiligence">
            <InsuranceOnly>
              <BrokerOnly>
                <NetDiligence isWhitelabel={isWhitelabel} />
              </BrokerOnly>
            </InsuranceOnly>
          </FeatureSwitch>
        </NotReinsurer>
        <ReinsurerOnly>
          <StyledNavLink to="/quotes" activeClassName="active">
            Quote
          </StyledNavLink>
          <ManageMenu />
          <FeatureSwitch withFeature="demoUi">
            <StyledNavLink to="/panel/capacity" activeClassName="active">
              Panel
            </StyledNavLink>
          </FeatureSwitch>
          <StyledNavLink to="/integrate" activeClassName="active">
            Integrate
          </StyledNavLink>
        </ReinsurerOnly>
        {showHelp && !replaceZendesk && (
          <StyledNavLink
            as="a"
            target="_blank"
            href={ZENDESK_LINK}
            isWhitelabel={isWhitelabel}>
            Request a Feature
          </StyledNavLink>
        )}
        {replaceZendesk && (
          <StyledNavLink
            as="a"
            href={`mailto:${user.company.supportEmail}`}
            isWhitelabel={isWhitelabel}>
            Help
          </StyledNavLink>
        )}
        <NotificationsMenu />
      </CompletedProfileOnly>
    </LoggedInOnly>
  )
}

const Nav = () => {
  const { showHelp, isNFP, isWhitelabel } = useBranding()
  return (
    <NavContainer>
      {isNFP ? (
        <RWNav showHelp={showHelp} />
      ) : (
        <OtherNav showHelp={showHelp} isWhitelabel={isWhitelabel} />
      )}
    </NavContainer>
  )
}

export default Nav
