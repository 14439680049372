import styled from 'styled-components'
import { space, color } from 'styled-system'
import { Link } from 'react-router-dom'

export const BackLink = styled(Link)`
  font-family: ${props => props.theme.fonts.secondary}};
  font-size: 18px;
  color: ${props => props.theme.colors.primaryText};
  text-decoration: none;
  height: 20px;
  &:visited {
    color: ${props => props.theme.colors.primaryText};
  }
  cursor: pointer;
  ${space}
  ${color}
`

export default BackLink
