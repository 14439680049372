import React, { useEffect, useState } from 'react'
import Popper from '@material-ui/core/Popper'
import { Paper, Box, Button } from '@material-ui/core'
import { faEdit } from '@fortawesome/pro-regular-svg-icons'

import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { Icon, Text, Flex } from 'atoms'
import { colors } from 'components/theme'
import truncate from 'util/truncate'

const getDisplayValue = data => {
  const newData = { ...data }
  delete newData['__typename']

  const dataValues = Object.values(newData)

  if (dataValues.some(value => !!value)) {
    return dataValues.join(', ')
  }
}

const Popover = ({
  id,
  label,
  required,
  onChange,
  schema,
  uiSchema,
  formData,
  registry,
  ...props
}) => {
  const [anchor, setAnchorEl] = useState(null)
  const [displayValue, setDisplayValue] = useState(getDisplayValue(formData))

  useEffect(() => {
    setDisplayValue(getDisplayValue(formData))
  }, [formData])

  if (Object.keys(schema.properties).length === 0) {
    return null
  }

  const close = () => {
    setAnchorEl(null)
  }

  const open = e => {
    setAnchorEl(e.currentTarget)
  }

  // Remove the ui:field so it's not an infinitely nested popper.
  const newUiSchema = { ...uiSchema }
  delete newUiSchema['ui:field']

  const handleChange = formData => {
    onChange(formData)
  }

  const handleSubmit = formData => {
    onChange(formData)
    close()
  }

  const { SchemaField } = registry.fields

  return (
    <Flex flexBasis="100%" flexWrap="wrap" height="40px">
      <Flex
        width="100%"
        alignItems="center"
        style={{ cursor: 'pointer' }}
        onClick={open}>
        <Text color={colors.link}>
          {truncate(displayValue || `Enter ${schema.title}`, 30)}
        </Text>
        <Icon ml={0} color={colors.link} icon={faEdit}></Icon>
      </Flex>
      <Popper open={!!anchor} anchorEl={anchor} placement="bottom">
        <Paper p={2} elevation={3}>
          <Box p={3} width="auto" height="auto">
            <Flex flexDirection="column">
              <Icon
                onClick={close}
                clickable
                style={{ justifyContent: 'flex-end' }}
                icon={faTimes}
              />
              <SchemaField
                schema={schema}
                uiSchema={newUiSchema}
                formData={formData}
                onChange={handleChange}
                registry={registry}
                {...props}
              />
              <Flex width="100%" justifyContent="flex-end">
                <Button ml="auto" onClick={() => handleSubmit(formData)}>
                  Save &amp; Close
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Paper>
      </Popper>
    </Flex>
  )
}
export default Popover
