import bannedDomainsList from 'data/freeEmailProviderDomains.json'

const MESSAGE = 'Please provide a company email account'

const emailDomainValidator = email => {
  if (bannedDomainsList.find(domain => email.toLowerCase().endsWith(domain))) {
    return MESSAGE
  }
}

export default emailDomainValidator
