import {
  border,
  compose,
  layout,
  position,
  space,
  typography,
} from 'styled-system'

const stylesParser = compose(space, typography, layout, position, border)

export const parseStyles = styles => {
  if (Object.keys(styles || {}).length === 0) return {}

  const {
    mr,
    ml,
    mt,
    mb,
    border,
    position,
    top,
    right,
    bottom,
    left,
    ...restStyles
  } = styles

  return stylesParser({
    mr,
    ml,
    mt,
    mb,
    border,
    position,
    top,
    right,
    bottom,
    left,
    ...restStyles,
  })
}
