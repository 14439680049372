import styled from 'styled-components'

const Label = styled.label`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  color: ${props => props.theme.colors.primaryText};
  & :first-child {
    margin-top: 3px;
  }

  span {
    font-weight: normal;
  }
`

export default Label
