import { useState, useEffect } from 'react'

const useLocalStorage = key => {
  const value = localStorage.getItem(key)
  const [state, setState] = useState(value)

  useEffect(() => {
    if (state) {
      localStorage.setItem(key, state)
    } else {
      localStorage.removeItem(key)
    }
  }, [state, key])

  return [state, setState]
}

export default useLocalStorage
