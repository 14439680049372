import './debug-tools'
import './yup'
import './i18next'
import './styles'

import React, { useState, useEffect } from 'react'
import GraphqlProvider from 'GraphqlProvider'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Provider as ReduxProvider } from 'react-redux'
import { ErrorBoundary } from '@sentry/react'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { enableES5 as enableES5Immer } from 'immer'

import Oops from 'pages/Oops'
import BarebonesErrorPage from 'pages/BarebonesErrorPage'
import Toaster, { toast } from 'components/Toaster'
import Auth from 'Auth'
import configureStore from './store'
import Appcues from './components/appcues'
import { reportPageView } from 'util/analytics'
import theme, { global, MaterialTheme } from 'components/theme'
import Icon from 'components/Icon'
import { Row } from 'components/common/Layout'
import BrandingContext from 'components/contexts/branding'

import { loadBranding, loadUrlBranding } from 'components/theme/branding'

import 'i18n'

enableES5Immer()

const GlobalStyles = createGlobalStyle`
  ${global}
`

const store = configureStore()

const Offline = () => {
  return (
    <Row gap="1rem">
      <Icon icon={faTimesCircle} />
      <span>Connection lost. New data will be loaded when you reconnect.</span>
    </Row>
  )
}

const TrailingSlashRemover = () => {
  const { pathname, search } = useLocation()
  if (pathname.endsWith('/')) {
    const newPath = search ? `${pathname.slice(0, -1)}${search}` : pathname
    return <Redirect to={newPath} />
  }
  return null
}

const mainFallback = props => <Oops {...props} />
const minimalFallback = props => <BarebonesErrorPage {...props} />

const App = ({ children }) => {
  const [online, setOnline] = useState(true)
  useEffect(() => reportPageView())
  const [token, setToken] = useState('')

  useEffect(() => {
    let offlineToast
    const goOffline = () => {
      setOnline(false)
      offlineToast = toast.error(<Offline />, {
        autoClose: false,
        position: toast.POSITION.TOP_CENTER,
        onClose: () => {
          if (!online) {
            offlineToast = toast('Working Offline', {
              autoClose: false,
              closeButton: false,
            })
          }
        },
      })
    }
    const goOnline = () => {
      setOnline(true)
      toast.dismiss(offlineToast)
    }

    window.addEventListener('offline', goOffline)
    window.addEventListener('online', goOnline)

    return () => {
      window.removeEventListener('offline', goOffline)
      window.removeEventListener('online', goOnline)
    }
  })

  const urlBranding = loadUrlBranding()

  const brandOverride = localStorage.getItem('relay.brand')
  const brandOverridden = brandOverride !== null
  const urlBrand = loadBranding(brandOverridden ? brandOverride : urlBranding)
  const [brand, setBrand] = useState(urlBrand)

  const setBranding = newBrand => {
    if (typeof newBrand === 'string') {
      newBrand = loadBranding(newBrand)
    }
    if (newBrand.brand !== brand.brand) {
      setBrand(newBrand)
    }
  }

  return (
    <ErrorBoundary fallback={minimalFallback}>
      <GraphqlProvider online={online} token={token}>
        <BrandingContext.Provider value={{ ...brand, setBranding }}>
          <ThemeProvider theme={brand.theme || theme}>
            <MaterialTheme>
              <ReduxProvider store={store}>
                <GlobalStyles />
                <Router onUpdate={() => Appcues.page()}>
                  <TrailingSlashRemover />
                  <ErrorBoundary fallback={mainFallback}>
                    <Toaster />
                    {children}
                    <Switch>
                      <Route
                        path="/accept-invite"
                        render={() => (
                          <Auth setToken={setToken} signUpWithToken />
                        )}
                      />
                      <Route
                        path="/signup"
                        render={() => <Auth setToken={setToken} signUp />}
                      />
                      <Route
                        path="/"
                        render={() => (
                          <Auth setToken={setToken} signUp={false} />
                        )}
                      />
                    </Switch>
                  </ErrorBoundary>
                </Router>
              </ReduxProvider>
            </MaterialTheme>
          </ThemeProvider>
        </BrandingContext.Provider>
      </GraphqlProvider>
    </ErrorBoundary>
  )
}

export default App
