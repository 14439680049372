import React from 'react'
import { $, Flex } from 'atoms'

const LimitRenderer = ({ children = {} }) => {
  const { limit } = children
  return (
    <Flex>
      <$ fallback="—">{limit}</$>
    </Flex>
  )
}

export default LimitRenderer
