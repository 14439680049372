import React from 'react'
import { toast } from 'react-toastify'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { EnhancedModal, Subtitle, Div } from 'atoms'
import { useCreateQuestionMutation } from 'graphql/api'
import QuestionForm from './QuestionForm'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'

const AddQuestionModal = ({
  initialValues,
  sections,
  onCompleted,
  onClose,
  scope = QUESTION,
}) => {
  const [createQuestion] = useCreateQuestionMutation()
  const translator = qandaTranslator(scope)

  const handleSubmit = values => {
    createQuestion({
      variables: {
        input: {
          ...values,
          questionableScope: scope,
        },
      },
      refetchQueries: [translator('Questions')],
    }).then(({ data }) => {
      if (data.createQuestion.errors) {
        toast.error(data.createQuestion.errors)
        return data.createQuestion.errors
      } else {
        onCompleted()
      }
    })
  }
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize={false}
      mutators={arrayMutators}>
      {({ handleSubmit }) => (
        <EnhancedModal onClose={onClose}>
          <Div p={2}>
            <Subtitle>Add {translator('Question')}</Subtitle>
            <QuestionForm
              scope={scope}
              sections={sections}
              onClose={onClose}
              onSubmit={handleSubmit}
              documentCategory={translator('Question')}
            />
          </Div>
        </EnhancedModal>
      )}
    </Form>
  )
}

export default AddQuestionModal
