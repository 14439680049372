export function set(key, value) {
  localStorage[key] = value
  return localStorage[key]
}

export function get(key, defaultValue) {
  return localStorage[key] || defaultValue
}

export function setObject(key, value) {
  localStorage[key] = JSON.stringify(value)
  return localStorage[key]
}

export function getObject(key, defaultValue) {
  return localStorage[key] ? JSON.parse(localStorage[key]) : defaultValue
}

export function clear() {
  return localStorage.clear()
}

export function remove(key) {
  return localStorage.removeItem(key)
}
