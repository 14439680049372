const formatError = function ({ error, relPath, key }) {
  const values = Object.assign({}, error, { relPath })
  return Object.assign({}, { key, values })
}

export const mixed = {
  required: error => formatError({ error, key: 'required', relPath: 'mixed' }),
  default: error => formatError({ error, key: 'required', relPath: 'mixed' }),
  oneOf: error => formatError({ error, key: 'oneOf', relPath: 'mixed' }),
  notOneOf: error => formatError({ error, key: 'notOneOf', relPath: 'mixed' }),
  notType: error => {
    const { value, originalValue } = error
    const isCast = originalValue != null && originalValue !== value
    const key = ['number', 'date'].includes(error.type) ? 'required' : 'notType'

    return formatError({
      error,
      key,
      relPath: 'mixed',
      isCast,
    })
  },
}

export const string = {
  length: error => formatError({ error, key: 'length', relPath: 'string' }),
  min: error => formatError({ error, key: 'min', relPath: 'string' }),
  max: error => formatError({ error, key: 'max', relPath: 'string' }),
  matches: error => formatError({ error, key: 'matches', relPath: 'string' }),
  email: error => formatError({ error, key: 'email', relPath: 'string' }),
  url: error => formatError({ error, key: 'url', relPath: 'string' }),
  trim: error => formatError({ error, key: 'trim', relPath: 'string' }),
  lowercase: error =>
    formatError({ error, key: 'lowercase', relPath: 'string' }),
  uppercase: error =>
    formatError({ error, key: 'uppercase', relPath: 'string' }),
}

export const number = {
  min: error => formatError({ error, key: 'min', relPath: 'number' }),
  max: error => formatError({ error, key: 'max', relPath: 'number' }),
  lessThan: error => formatError({ error, key: 'lessThan', relPath: 'number' }),
  notEqual: error => formatError({ error, key: 'notEqual', relPath: 'number' }),
  positive: error => formatError({ error, key: 'positive', relPath: 'number' }),
  negative: error => formatError({ error, key: 'negative', relPath: 'number' }),
  integer: error => formatError({ error, key: 'integer', relPath: 'number' }),
}

export const date = {
  min: error => formatError({ error, key: 'min', relPath: 'date' }),
  max: error => formatError({ error, key: 'max', relPath: 'date' }),
}

export const boolean = {}

export const object = {
  noUnknown: error =>
    formatError({ error, key: 'noUnknown', relPath: 'object' }),
}

export const array = {
  min: error => formatError({ error, key: 'min', relPath: 'array' }),
  max: error => formatError({ error, key: 'max', relPath: 'array' }),
}

export default {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
}
