import React from 'react'
import Handlebars from 'handlebars'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const TitleContainer = ({ children }) => (
  <Box mb={1} mt={1}>
    {children}
  </Box>
)

const TitleField = ({ title, formData }) => {
  // If we've been given an object it's because we want to use the title
  // templating functionality, so let's compile and render the template.
  if (
    typeof title === 'object' &&
    Object.prototype.hasOwnProperty.call(title, 'template')
  ) {
    const template = Handlebars.compile(title.template)
    return (
      <TitleContainer>
        <div
          dangerouslySetInnerHTML={{
            __html: template({ ...formData, ...title }),
          }}
        />
      </TitleContainer>
    )
  }

  return (
    <TitleContainer>
      <Typography variant="h5">{title}</Typography>
    </TitleContainer>
  )
}

export default TitleField
