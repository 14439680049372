import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { transparentize } from 'polished'
import { LazyRSCreatable as Creatable } from 'atoms/react-select'
import { FixedSizeList as List } from 'react-window'

import Error from '../common/Error'
import { colors, fonts } from '../theme'

const StyledSelect = styled(Creatable)`
  width: 100%;
`

const MenuList = props => {
  const optionHeight = 35

  const { options, children, maxHeight, getValue } = props
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * optionHeight

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={optionHeight}
      initialScrollOffset={initialOffset}>
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  )
}

const MultiSelect = ({
  input: { name, value, onChange },
  meta: { touched, error },
  exclude,
  shouldHighlight,
  options,
  ...rest
}) => {
  return (
    <>
      <StyledSelect
        components={options?.length > 100 ? { MenuList } : {}}
        isMulti
        isSearchable
        inputId={name}
        value={value}
        onChange={onChange}
        options={options}
        styles={{
          control: currentStyle => ({
            ...currentStyle,
            fontFamily: fonts.secondary,
            fontSize: '18px',
            color: colors.primaryText,
            border: '0px',
            borderBottom: `1px solid ${
              touched && error
                ? colors.error
                : transparentize(0.7, colors.primaryText)
            }`,
            '&:hover': {
              borderBottom: `1px solid ${colors.primaryText}`,
            },
            borderRadius: 0,
            background: `${
              shouldHighlight ? colors.lightGreen : 'transparent'
            }`,
          }),
          indicatorSeparator: () => {},
          dropdownIndicator: current => ({ ...current, color: '#7D7D86' }),
          singleValue: current => ({
            ...current,
            color: colors.primaryText,
            fontFamily: fonts.secondary,
          }),
          menu: styles => ({
            ...styles,
            fontSize: '16px',
          }),
          multiValue: styles => ({
            ...styles,
            height: '32px',
            borderRadius: '25px',
            marginRight: '8px',
            marginBottom: '8px',
            backgroundColor: `${exclude ? colors.error : colors.control}`,
            textDecoration: `${exclude ? 'line-through' : 'none'}`,
            outline: 'none',
            color: colors.white,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            ':hover': {
              backgroundColor: 'initial',
              color: colors.primaryTextLight,
              div: {
                color: colors.primaryTextLight,
              },
              border: `1px solid ${colors.secondaryBlue}`,
            },
          }),
          multiValueLabel: () => ({
            color: colors.white,
            fontFamily: fonts.secondary,
            fontWeight: 'bold',
            padding: '0 1.5rem 0 1rem',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            transform: 'translateY(-1px)',
          }),
          multiValueRemove: () => ({
            color: colors.white,
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: '0.5rem',
          }),
        }}
        {...rest}
      />
      {error && touched && <Error>{error}</Error>}
    </>
  )
}

MultiSelect.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  exclude: PropTypes.bool,
}

MultiSelect.displayName = 'MultiSelectComponent'

export default MultiSelect
