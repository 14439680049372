import React from 'react'
import numeral from 'numeral'
import styled from 'styled-components'

const Amount = styled.span`
  white-space: nowrap;
  display: inline-block;
  text-decoration: inherit;
`
const DEFAULT_FORMAT = '0.[000]a'

const $ = ({ format = DEFAULT_FORMAT, fallback = '', children }) => {
  if (children === undefined || children === null || children === '') {
    return <>{fallback}</>
  }

  const value = numeral(children.toString())

  if (value.value() === null || value.value().toString() === 'NaN') {
    return <Amount>{fallback}</Amount>
  }

  const number = numeral(value.value())

  return <Amount>{`${number.format(format)}`}</Amount>
}

export default $
