import React, { useState } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { Field } from 'react-final-form'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'

import { Icon, IconCircle, Pill } from 'atoms'
import { Dropdown, TextInput } from 'atoms/form'
import { colors, fonts } from 'components/theme'
import { convertToOption } from 'util/forms'

const styles = {
  control: currentStyle => ({
    ...currentStyle,
    fontFamily: fonts.secondary,
    fontSize: '18px',
    color: colors.primaryText,
    border: '0px',
    borderBottom: '0px',
    cursor: 'pointer',
    borderRadius: '25px',
    background: colors.dropdown,
    '&:hover': {
      background: darken(0.05, colors.dropdown),
    },
  }),
  indicatorSeparator: () => {},
  dropdownIndicator: current => ({ ...current, color: '#7D7D86' }),
  singleValue: current => ({
    ...current,
    color: colors.primaryText,
    fontFamily: fonts.secondary,
  }),
}

const StyledPill = styled(Pill)`
  background-color: ${({ theme }) => theme.colors.dropdown};
  color: ${({ theme }) => theme.colors.primaryText};
`

const Category = ({
  name,
  documentCategories,
  readOnly = false,
  creatable,
  canBeBlank,
  defaultCategory,
  onCategoryChanged = () => {},
}) => {
  const [showButton, setShowButton] = useState(true)

  const options = documentCategories && [
    ...documentCategories.map(convertToOption),
  ]

  canBeBlank && options.unshift({ value: null, label: 'Not tagged' })
  return (
    <Field
      name={`${name}.category`}
      options={options}
      styles={styles}
      showButton={showButton}
      render={({ input, options, styles, showButton }) => {
        setShowButton(input.value.category)
        return readOnly ? (
          <CategoryPill document={input.value} />
        ) : showButton ? (
          <IconCircle
            ml={1}
            title="Remove"
            role="button"
            onClick={() => {
              setShowButton(false)
            }}
            borderColor="link"
            bg="link">
            <Icon alt="Add" icon={faPlus} color="white" />
          </IconCircle>
        ) : options ? (
          <Field
            initialValue={defaultCategory}
            name={input.name}
            component={Dropdown}
            options={options}
            creatable={creatable}
            onChange={props => {
              if (
                document.activeElement['id'] !== input.name &&
                input.value !== props.value
              ) {
                onCategoryChanged(name, props.value)
              }
              input.onChange(props.value)
            }}
            styles={styles}
            minWidth="15ch"
            width="80%"
            display="inline-block"
          />
        ) : (
          <Field name={input.name} component={TextInput} />
        )
      }}
    />
  )
}

export const CategoryPill = ({ document }) => {
  if (!document.category) return null
  return <StyledPill>{document.category}</StyledPill>
}

export default Category
