import React from 'react'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

import { Div, Icon } from 'atoms'

const CheckedTile = ({ children }) => (
  <Div
    display="flex"
    justifyContent="center"
    alignItems="center"
    p={1}
    bg="primaryText"
    color="white"
    borderRadius={1}
    boxShadow="small">
    <Icon icon={faCheck} mr={1} />
    {children}
  </Div>
)
export default CheckedTile
