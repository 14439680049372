import styled from 'styled-components'
import { darken } from 'polished'
import { layout, space, flexbox } from 'styled-system'

const border = ({ disabled, light, tertiary, theme }) =>
  `${tertiary ? '1px' : '4px'} solid ${
    tertiary
      ? theme.colors.disabled
      : disabled || light
      ? theme.colors.secondaryBlue
      : theme.colors.primaryAction
  }`

const buttonColor = props => {
  if (props.light) {
    return {
      background: 'none',
      border: border(props),
      color: props.theme.colors.primaryActionText,
      '&:hover': {
        background: darken(0.03, props.theme.colors.white),
        borderColor: darken(0.03, props.theme.colors.secondaryBlue),
      },
      '&:active': {
        background: darken(0.05, props.theme.colors.white),
        borderColor: darken(0.05, props.theme.colors.secondaryBlue),
      },
    }
  }

  if (props.secondary) {
    return {
      background: 'none',
      border: border(props),
      color: props.theme.colors.primaryActionText,
      '&:hover': {
        background: darken(0.03, props.theme.colors.white),
        borderColor: darken(0.03, props.theme.colors.primaryAction),
      },
      '&:active': {
        background: darken(0.05, props.theme.colors.white),
        borderColor: darken(0.05, props.theme.colors.primaryAction),
      },
    }
  }

  if (props.tertiary) {
    return {
      background: 'none',
      border: border(props),
      color: props.theme.colors.primaryActionText,
      '&:hover': {
        background: darken(0.03, props.theme.colors.white),
        borderColor: darken(0.03, props.theme.colors.disabled),
      },
      '&:active': {
        background: 'none',
        borderColor: darken(0.05, props.theme.colors.primaryAction),
      },
    }
  }

  if (props.danger) {
    return {
      background: props.theme.colors.error,
      border: 'none',
      color: props.theme.colors.white,
      '&:hover': {
        background: darken(0.03, props.theme.colors.error),
      },
      '&:active': {
        background: darken(0.05, props.theme.colors.error),
      },
    }
  }

  if (props.disabled) {
    return {
      background: props.theme.colors.inactive,
      border: 'none',
      color: props.theme.colors.primaryActionTextMid,
    }
  }

  return {
    background: props.theme.colors.primaryAction,
    border: border(props),
    color: props.theme.colors.primaryActionText,
    '&:hover': {
      background: darken(0.03, props.theme.colors.primaryAction),
      borderColor: darken(0.03, props.theme.colors.primaryAction),
    },
    '&:active': {
      background: darken(0.05, props.theme.colors.primaryAction),
      borderColor: darken(0.05, props.theme.colors.primaryAction),
    },
  }
}

const Button = styled.button`
  min-width: 140px;
  height: 50px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 600;
  font-size: 16px;
  border-radius: 24px;
  box-sizing: border-box;
  outline: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 24px;
  padding: ${props => (props.padding ? props.padding : '0 1rem')};
  white-space: nowrap;
  flex-shrink: 0;

  ${buttonColor}
  ${space}
  ${layout}
  ${flexbox}
`

export default Button
