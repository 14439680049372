import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'

import Error from '../common/Error'
import InputContainer from './InputContainer'

export const StyledInput = styled.input`
  width: 100%;
  padding: 7px 0;
  box-sizing: border-box;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 300;
  font-size: 18px;
  color: ${props => props.theme.colors.primaryText};
  outline: none;
  border-width: 0 0 ${props => (props.disabled ? 0 : '1px')} 0;
  border-style: solid;
  border-color: ${({ touched, error, theme }) =>
    touched && error
      ? theme.colors.error
      : transparentize(0.7, theme.colors.primaryText)};
  background: ${props =>
    props.highlight
      ? `${props.theme.colors.lightGreen} !important`
      : 'transparent !important'};

  &::placeholder {
    color: ${props => props.theme.colors.primaryTextInactive};
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.primaryText};
  }
`
StyledInput.displayName = 'StyledInput'

const InputSymbolWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: ${props => props.theme.colors.primaryTextInactive};
  font-size: 16px;
  font-family: ${props => props.theme.fonts.primary};
  min-height: 40px;
`

const Input = ({ type, touched, error, width, ...props }) => (
  <InputContainer className={props.className} width={width}>
    <InputSymbolWrapper>
      <StyledInput touched={touched} error={error} type={type} {...props} />
      {props.symbol && <span>{props.symbol}</span>}
    </InputSymbolWrapper>
    {touched && error && <Error>{error}</Error>}
  </InputContainer>
)

export default Input
