import React from 'react'
import styled from 'styled-components'
import { ToastContainer, Slide } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import { fonts, colors } from './theme'

const Icon = styled.span`
  padding-left: 16px;
`

const Closer = ({ closeToast }) => <Icon onClick={closeToast}>{'\u2715'}</Icon>

export const WrappedToastContainer = ({ className, ...rest }) => (
  <div className={className}>
    <ToastContainer transition={Slide} closeButton={<Closer />} {...rest} />
  </div>
)

export default styled(WrappedToastContainer)`
  .Toastify__toast-container {
    font-family: ${fonts.secondary};
    width: auto;
    font-size: 16px;
    top: 85px;
    z-index: 99999;
  }
  .Toastify__toast {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    min-height: auto;
    padding: 16px;
  }
  .Toastify__toast-body {
    flex: 1 1 auto;
  }

  .Toastify__toast--success {
    background-color: ${colors.toast.success.bg};
    color: ${colors.toast.success.text};
    border-color: ${colors.toast.success.bar};
  }
  .Toastify__close-button--success {
    color: ${colors.toast.success.text};
  }
  .Toastify__progress-bar--success {
    background-color: ${colors.toast.success.bar};
  }

  .Toastify__toast--info {
    background-color: ${colors.toast.info.bg};
    color: ${colors.toast.info.text};
    border-color: ${colors.toast.info.bar};
  }
  .Toastify__close-button--info {
    color: ${colors.toast.info.text};
  }
  .Toastify__progress-bar--info {
    background-color: ${colors.toast.info.bar};
  }

  .Toastify__toast--error {
    background-color: ${colors.toast.error.bg};
    color: ${colors.toast.error.text};
    border-color: ${colors.toast.error.bar};
  }
  .Toastify__close-button--error {
    color: ${colors.toast.error.text};
  }
  .Toastify__progress-bar--error {
    background-color: ${colors.toast.error.bar};
  }
`

export { toast } from 'react-toastify'
