import React from 'react'

import { FileCabinet } from 'pages/shared/panels'
import mapDocs from 'util/mapDocs'
import { useUpdateParticipationDocumentsMutation } from 'graphql/api'
import { useParticipation } from 'hooks'

const ParticipationFileCabinet = ({ allowDelete = false }) => {
  const [upload] = useUpdateParticipationDocumentsMutation()
  const participation = useParticipation()

  const handleSubmit = values => {
    return upload({
      variables: {
        input: {
          participantId: participation.id,
          documents: mapDocs(values.documents),
        },
      },
    })
  }
  const autoSave = {
    getErrors: ({ data }) => {
      data.updateParticipationDocuments.errors
    },
    getFormData: ({ data }) => {
      return {
        documents: data.updateParticipationDocuments.participation.documents,
      }
    },
  }
  return (
    <FileCabinet
      title="Shared Documents"
      documents={participation.documents}
      uploadFile={handleSubmit}
      autoSave={autoSave}
      allowDelete={allowDelete}
    />
  )
}

export default ParticipationFileCabinet
