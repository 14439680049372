const isRootQuestionMatcher = new RegExp(`^\\d+$`)
const isFollowUpQuestionMatcher = new RegExp(`^\\d+-\\d+$`)
const isRoot = question => isRootQuestionMatcher.test(question.number)
const isFollowUp = question => isFollowUpQuestionMatcher.test(question.number)

const nestFollowUpQuestions = questions => {
  const nestedQuestions = []
  // get root questions
  questions.forEach(
    question =>
      isRoot(question) &&
      nestedQuestions.push({
        followUps: [],
        ...question,
      })
  )
  // nest follow ups
  questions.forEach(question => {
    if (isFollowUp(question)) {
      nestedQuestions
        .find(q => question.number.split('-')[0] === q.number)
        .followUps.push(question)
    }
  })

  return nestedQuestions
}

export default nestFollowUpQuestions
