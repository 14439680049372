import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { displayDate } from 'util/date'
import { Title, Note } from 'atoms'
import PageNav from 'molecules/PageNav'
import ParticipationContext from 'components/contexts/participation'
import { DeclineModal, ParticipationNavAndConvo } from 'pages/shared/panels'
import { SubmissionGeneralInboxAlert } from 'molecules/GeneralInbox'
import { GeneralInboxOnly, LoggedInOnly } from 'components/contexts/user'

const ShowLayoutWrapper = ({
  config,
  returnUrl,
  returnPage,
  Component,
  userType,
  isManual,
  editUrl,
  participation,
  submission,
  broker,
  carrier,
}) => {
  const { path } = useRouteMatch()
  return (
    <ParticipationContext.Provider value={participation}>
      <Route
        path={`${path}/decline`}
        render={props => (
          <DeclineModal config={config} submission={submission} {...props} />
        )}
      />
      <GeneralInboxOnly>
        <SubmissionGeneralInboxAlert participation={participation} />
      </GeneralInboxOnly>
      <LoggedInOnly>
        <PageNav to={returnUrl} label={`Return to ${returnPage}`} />
      </LoggedInOnly>
      <Title mb={1}>{submission.name}</Title>
      <Note>Last Updated: {displayDate(submission.updatedAt)}</Note>
      <Component
        config={config}
        participation={participation}
        submission={submission}
        editUrl={editUrl}
        broker={broker}
        carrier={carrier}
        userType={userType}
      />
      {!isManual && (
        <ParticipationNavAndConvo
          userType={userType}
          submission={submission}
          participation={participation}
        />
      )}
    </ParticipationContext.Provider>
  )
}

export default ShowLayoutWrapper
