import {
  insertBulkEdit,
  removeKeyFromUiOrder,
  insertToAfterKeyUiOrder,
} from 'pages/shared/panels/config'
import {
  ignoreList,
  formDataMapping,
  schemasToFormDataMapping,
} from './constants'
import {
  insertCarrierName,
  setCheckboxHeadings,
  setSubjectivities,
  bulkEditData,
} from './utils'
import { capitalize } from 'util/lodash'
import { ISSUED } from 'panels/constants'

const schemas = (
  schemas,
  userType,
  formData,
  updateFormData,
  manual = false,
  editQuotation = false
) => {
  setCheckboxHeadings(schemas, formData, updateFormData)
  if (!manual && userType === 'reinsurer') {
    schemas.uiSchema.status['ui:widget'] = 'hidden'
  }
  if (manual) {
    schemas.uiSchema.admitted.cols = 4
    schemas.uiSchema.annual_premium.cols = 4
    if (editQuotation) {
      insertCarrierName(schemas)
    } else {
      removeKeyFromUiOrder(schemas, 'carrier')
    }
    insertToAfterKeyUiOrder(schemas, 'admitted', 'carrier')
  } else {
    if (userType === 'broker') {
      schemas.uiSchema.admitted.cols = 4
      schemas.uiSchema.annual_premium.cols = 4
      schemas.uiSchema.status.cols = 4
    } else {
      schemas.uiSchema.admitted.cols = 6
      schemas.uiSchema.annual_premium.cols = 6
      removeKeyFromUiOrder(schemas, 'status')
      schemas.uiSchema['ui:order'].push('status')
    }
  }
  if (schemas.schema.properties.status) {
    capitalizeStatuses(schemas.schema.properties.status)
  }

  insertBulkEdit(
    schemas,
    formData,
    updateFormData,
    'annual_premium',
    bulkEditData
  )

  setSubjectivities(schemas, 'subjectivities_attributes')
  if (editQuotation) {
    schemas.schema.title = 'Revise Quote'
  }

  return schemas
}

const capitalizeStatuses = statuses =>
  statuses.enum.forEach((status, i) => {
    statuses.enum[i] = capitalize(status)
  })

const productOptions = submission => ({
  headings: ['Coverage', 'Limit', 'Retention', 'Higher Limit Requested'],
  monetary: [false, true, true],
  options: submission.products[0].coverages.map(coverage => [
    coverage.name,
    coverage.limit,
    coverage.deductible,
    coverage.request_higher_limit ? 'Yes' : 'No',
  ]),
})

const prefillQuotationData = (schemas, submission, userType) => {
  let result = {}
  if (userType === 'broker') {
    result = {
      admitted: false,
      annual_premium: '0',
      status: 'pending',
    }
    const coverableMapping = {}
    submission.products[0].coverages.forEach(coverage => {
      coverableMapping[coverage.type] = coverage
    })
    Object.keys(schemas.schema.properties).forEach(key => {
      if (!ignoreList.schemas.entry.includes(key)) {
        const entry = schemas.schema.properties[key].properties
        const dataEntry = {}
        const {
          limit,
          deductible,
          id: coverable_id,
        } = coverableMapping[schemasToFormDataMapping[key].coverable_type]
        Object.keys(entry).forEach(subKey => {
          if (!ignoreList.schemas.subEntry.includes(subKey)) {
            dataEntry[subKey] = {
              checkbox: false,
              limit: limit,
              retention: deductible,
            }
          }
        })
        result[key] = {
          ...dataEntry,
          ...schemasToFormDataMapping[key],
          coverable_id,
        }
      }
    })
  } else {
    result.status = 'Quoted'
  }
  return result
}

const formatQuotationData = (formData, userType, manual = false) => {
  const participation = formData
  formData = { ...formData.quote, participation }
  const coverages = {}
  let status
  if (!manual && userType === 'reinsurer') {
    status = 'Quoted'
  }

  formData.quotedLines.nodes.forEach(group => {
    Object.keys(group).forEach(key => {
      if (!ignoreList.schemas.subEntry.includes(key)) {
        const mapEntry = formDataMapping[key]
        if (!coverages[mapEntry.section]) {
          coverages[mapEntry.section] = {
            id: group.id,
          }
        }
        coverages[mapEntry.section][mapEntry.key] = {
          limit: group[key].limit,
          retention: group[key].retention,
        }
      }
    })
  })
  let supplements = {}
  if (manual) {
    supplements = {
      current_policy: formData.currentPolicy,
      carrier:
        formData.carrier || formData.participation?.externalCarrier?.name,
    }
  }
  return {
    status: status || capitalize(formData.status),
    subjectivities_attributes: formData.subjectivitiesAttributes,
    admitted: formData.admitted,
    annual_premium: formData.annualPremium,
    ...coverages,
    ...supplements,
  }
}

const doneStatus = (quotations, quotationId) => {
  const quotation = quotations.find(quote => quote.id === quotationId)

  if (quotation.isManual) return false
  return quotation.status.toLowerCase() === ISSUED
}

export default {
  schemas,
  productOptions,
  formatQuotationData,
  prefillQuotationData,
  doneStatus,
}
