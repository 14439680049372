import React from 'react'
import { Warning as InfoWarning } from 'components/common/Indicators'

const Warning = ({ schema, uiSchema }) => {
  const styles = uiSchema['ui:styles'] || {}
  return (
    <InfoWarning style={styles}>{schema.title}</InfoWarning>
  )
}
export default Warning
