import React from 'react'
import { EnhancedModal, Subtitle, Div } from 'atoms'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import AnswerForm from './AnswerForm'
import { useCreateAnswerMutation } from 'graphql/api'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'

const AddAnswerModal = ({
  scope = QUESTION,
  initialValues,
  questions,
  onCompleted,
  onClose,
  setHandleSubmitErrors,
}) => {
  const [createAnswer] = useCreateAnswerMutation()
  const { questionId } = useParams()
  const translator = qandaTranslator(scope)
  const question = questions.find(question => question.id === questionId)
  const handleSubmit = values => {
    createAnswer({
      variables: {
        input: {
          questionId,
          ...values,
        },
      },
      refetchQueries: [translator('Questions')],
    }).then(({ data }) => {
      if (data.createAnswer.errors) {
        setHandleSubmitErrors(data.createAnswer.errors)
      } else {
        onCompleted()
      }
    })
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={arrayMutators}>
      {({ handleSubmit }) => (
        <EnhancedModal onClose={onClose}>
          <Div p={2}>
            <Subtitle>Add {translator('Answer')}</Subtitle>
            <AnswerForm
              scope={scope}
              onClose={onClose}
              question={question}
              onSubmit={handleSubmit}
              documentCategory={translator('Answer')}
            />
          </Div>
        </EnhancedModal>
      )}
    </Form>
  )
}

export default AddAnswerModal
