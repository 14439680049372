export const truncateFileName = (str, length = 30) => {
  if (!str || str.length <= length + 1) {
    return str
  }

  const indexOfPeriod = str.lastIndexOf('.')

  return `${str.slice(0, indexOfPeriod).substr(0, length)}…${str.slice(
    indexOfPeriod
  )}`
}
