import ApplicationModel from '../../ApplicationModel'
import { currencyToBigDecimalNumber } from 'util/fieldNormalizers'
import { SubmissionMapping } from './mappings'

class SubmissionModel extends ApplicationModel {
  constructor() {
    super({
      mapping: SubmissionMapping,
    })
  }

  get updatedAt() {
    return this.attributes.updatedAt
  }

  get name() {
    return this.attributes.name
  }

  get id() {
    return this.attributes.id
  }

  get country() {
    return 'US'
  }

  get documents() {
    return this.attributes.documents
  }

  get quotations() {
    return this.attributes.quotations
  }

  get owner() {
    return this.attributes.owner
  }

  get status() {
    return this.attributes.status
  }

  get products() {
    return this.attributes.products
  }

  get applicant() {
    return this.attributes.applicant
  }

  hasLoaded(id) {
    return this.attributes && this.attributes.id === id
  }

  _toBigNumber(currencyAmount) {
    return currencyToBigDecimalNumber({
      currencyAmount,
    })
  }
}

export default SubmissionModel
