import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'

import { Column, Div, Loading, Text, Subtitle, Row } from 'atoms'
import { usePanelQuery } from 'graphql/api'

const Img = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

const PanelistDiv = styled(Div)`
  background: white;
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 0.5rem;

  &:hover {
    background: ${props => props.theme.colors.lightGrey};
  }
`

const Carrier = ({ logoUrl, name }) => (
  <Column width="auto">
    <Text textAlign="center" mb={0} fontSize="3" fontWeight={2}>
      {name}
    </Text>
    <PanelistDiv
      p={0}
      borderWidth="2px"
      borderStyle="solid"
      borderColor={'primaryText'}>
      <Img src={logoUrl} />
    </PanelistDiv>
  </Column>
)
const findCarrier = ({ data, schema, formData }) => {
  if (!data || !schema || !formData?.id) return undefined
  if (schema.panelists) {
    const panelist = data.viewer.user.company.panel.panelists.nodes.find(
      panelist => panelist.id === formData.id
    )
    return panelist
      ? {
          name: panelist.firstName,
          logoUrl: panelist.company.logoUrl,
        }
      : undefined
  }

  if (schema.externalCarriers) {
    const externalCarrier =
      data.viewer.user.company.panel.externalCarriers.find(
        carrier => carrier.id === formData.id
      )
    return externalCarrier
      ? { name: externalCarrier.name, logoUrl: externalCarrier.logoUrl }
      : undefined
  }
}
// This field is only to show the selected carrier. The carrier can not be changed. To change carrier use SelectedCarriersField instead
// An usage example is on edit quote form for email quotation where the carrier cannot be changed but need to be displayed
const DisplayCarrier = ({ formData = {}, schema }) => {
  const { panelId } = useParams()
  const { data, loading, error } = usePanelQuery({ variables: { panelId } })

  if (error) {
    throw error
  }

  if (loading) {
    return <Loading />
  }
  const carrier = findCarrier({ data, formData, schema })
  if (!carrier) return null

  return (
    <Column>
      <Subtitle mt={0} mb={0}>
        {schema.title?.toUpperCase()}
      </Subtitle>
      <Row>
        <Carrier {...carrier} />
      </Row>
    </Column>
  )
}

export default DisplayCarrier
