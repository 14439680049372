import React from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { space } from 'styled-system'
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons'

import IconButton from 'components/common/IconButton'
import { colors } from '../theme'
import Button from 'components/antd/Button'

const Container = styled.div`
  width: fit-content;
  cursor: pointer;
  ${space}
`

const FileButton = ({
  className,
  onDrop,
  dropzoneOptions,
  children,
  icon = faPaperclip,
  acceptFileTypes,
  linkColor,
  id,
  ...rest
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 1024 * 1024 * 50,
    onDrop,
    accept: acceptFileTypes,
    ...dropzoneOptions,
  })

  return (
    <Container className={className} {...getRootProps()}>
      <input id={id} data-testid="file-input" {...getInputProps()} />
      {linkColor ? (
        <Button type="primary" {...rest}>
          {children}
        </Button>
      ) : (
        <IconButton
          icon={icon}
          transform={{ rotate: 315 }}
          {...rest}
          title={children}
        />
      )}
    </Container>
  )
}

const Link = styled.span`
  color: ${colors.link};
`

const FileLink = ({
  className,
  onDrop,
  dropzoneOptions,
  children,
  acceptFileTypes,
  ...rest
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 1024 * 1024 * 50,
    onDrop,
    accept: acceptFileTypes,
    ...dropzoneOptions,
  })

  return (
    <Container className={className} {...getRootProps()}>
      <input data-testid="file-input" {...getInputProps()} />
      <Link {...rest}>{children}</Link>
    </Container>
  )
}

export { FileButton, FileLink }
