import React, { useEffect, useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { Chip } from '@material-ui/core'
import { identity } from 'util/lodash'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { Popover, Space } from 'antd'
import { usePrevious, useLocalStorage } from 'hooks'
import { useFlexiForm } from 'FlexiForm'
import { default as Autocomplete } from './Autocomplete'
import { SmallLoading, Column, Icon, Row, Text } from 'atoms'
import { colors } from 'components/theme'
import { useCyberSuggestCodesSubmissionMutation } from 'graphql/api'
import { usePanel } from 'hooks'

const NaicsCode = props => {
  const panel = usePanel()
  const { formData } = useFlexiForm()

  let panelConfig
  try {
    panelConfig = JSON.parse(panel.configuration)
  } catch (err) {
    if (err instanceof SyntaxError) {
      // error parsing the json - the config is probably missing
      panelConfig = {}
    } else {
      throw err
    }
  }
  if (!panelConfig.r6) {
    return <Autocomplete {...props} />
  }

  const {
    options: { enumOptions },
  } = props

  const [inputValue, setInputValue] = useState(props.inputValue)
  const [localCodes, setLocalCodes] = useLocalStorage('localCodes')
  const [failed, setFailed] = useState(false)
  const [suggest] = useCyberSuggestCodesSubmissionMutation()
  const debouncedSave = useCallback(
    debounce((companyName, streetLine1, state) => {
      handleSuggest(companyName, streetLine1, state)
    }, 2000),
    []
  )

  const { companyName, streetLine1, state } = formData

  const previousValues = usePrevious([companyName, streetLine1, state])
  const currentValues = [companyName, streetLine1, state]

  useEffect(() => {
    if (
      !(
        (currentValues && !previousValues) ||
        isEqual(previousValues, currentValues)
      )
    ) {
      setLocalCodes([])
      setFailed(false)
      debouncedSave(companyName, streetLine1, state)
    }
  }, [companyName, streetLine1, state])

  const handleCodeClick = localCodes => {
    setInputValue(localCodes)
    props.onChange(localCodes)
  }

  const handleSuggest = async (companyName, streetLine1, state) => {
    if (!companyName || !streetLine1 || !state) {
      return
    }
    const response = await suggest({
      variables: {
        input: {
          panelId: panel.id,
          name: companyName,
          address: streetLine1,
          state: state,
        },
      },
    })

    const {
      data: {
        cyberSuggestCodesSubmission: { suggestions, error },
      },
    } = response

    if (error) {
      toast.error('No NAICS code suggestions found.')
      setFailed(true)
    } else {
      setLocalCodes(
        JSON.stringify(
          suggestions
            .map(s => enumOptions.find(e => e.value === s)?.label)
            .filter(identity)
        )
      )
    }
  }

  return (
    <Column className={`form-input-naics`}>
      <Autocomplete
        {...props}
        value={props.value}
        inputValue={inputValue}
        onChange={(_, newValue) => {
          props.onChange(newValue?.value)
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue?.replace(/ .*/, ''))
        }}
      />
      {companyName && streetLine1 && state && (
        <Row
          color={colors.darkGray}
          mt="0.5em"
          space="1em"
          alignItems="baseline">
          <Popover
            placement="topLeft"
            trigger="hover"
            content={
              <Text>
                We will recommend the best Industry Code based on company name
                and location.
              </Text>
            }
            style={{
              verticalAlign: 'top',
            }}>
            <Row alignItems="center" color={colors.darkGray}>
              <Text color="inherit" fontWeight="400" textOverflow>
                <strong>Suggested</strong>
              </Text>
              <Icon icon={faQuestionCircle} ml="0.3em" />
              <Text color="inherit" fontWeight="400" textOverflow mr={1}>
                :
              </Text>
            </Row>
          </Popover>
          <Space wrap>
            {failed ? (
              <Chip
                size="small"
                label="No Suggestions"
                style={{
                  backgroundColor: colors.darkGray,
                  color: colors.white,
                }}
              />
            ) : localCodes?.length ? (
              JSON.parse(localCodes)
                .filter(code => code !== props.value)
                .map((code, i) => {
                  return (
                    <Chip
                      key={i}
                      size="small"
                      onClick={() => {
                        handleCodeClick(code.replace(/ .*/, '')) // Retrieve first "word", i.e. only the numerical code
                      }}
                      label={code}
                      style={{
                        backgroundColor: colors.link,
                        color: colors.white,
                      }}
                    />
                  )
                })
            ) : (
              <SmallLoading />
            )}
          </Space>
        </Row>
      )}
    </Column>
  )
}

export default NaicsCode
