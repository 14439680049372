import React from 'react'
import { faUser, faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { Link, useParams } from 'react-router-dom'
import { Row, Column, Icon, IconButton, Text } from 'atoms'

const UnderwriterRenderer = ({
  children: quotation,
  criterion,
  show = true,
}) => {
  if (!show) return null
  if (!quotation.underwriterEmail) {
    return <></>
  }

  const firstName = quotation?.underwriterFirstName
    ? quotation.underwriterFirstName
    : 'Carrier'
  const fulltName = quotation?.underwriterFirstName
    ? `${quotation.underwriterFirstName} ${quotation?.underwriterLastName}`
    : 'General Inbox'
  quotation = { ...quotation, replySubject: `RE: ${quotation?.contactName}` }

  return (
    <Column textAlign="center" alignItems="center" justifyContent="center">
      <Row mb="20px">
        <Icon
          mr={1}
          icon={faUser}
          color="#C1D2F2"
          fontSize="50px"
          width="44px"
          height="50px"
        />
        <Column>
          <Text color="#434F5C" fontSize="18px" fontWeight="600">
            {fulltName}
          </Text>
          <Text color="#434F5C" fontSize="14px">
            1 bus. day response
          </Text>
        </Column>
      </Row>

      <IconButton
        maxWidth="90%"
        width="263px"
        icon={faEnvelope}
        tertiary="true"
        style={{ borderColor: '#D2CDC3' }}
        as={Link}
        to={criterion.path(useParams(), quotation)}>
        <Text>Message {firstName}</Text>
      </IconButton>
    </Column>
  )
}

export default UnderwriterRenderer
