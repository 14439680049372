import { capitalize, isPlainObject, isEmpty } from 'util/lodash'
import { snakeCase } from 'lodash'

export const sentenceCase = str =>
  capitalize(str.replace(/([a-z])([A-Z])/g, '$1 $2'))

export const transformCoverages = line => {
  const nameMapping = JSON.parse(line.quotedLineNames)
  const filtered = Object.keys(line).reduce((obj, key) => {
    if (isPlainObject(line[key]) && key != 'coverage') obj[key] = line[key]
    return obj
  }, {})
  return Object.entries(filtered).reduce((acc, [name, cov]) => {
    acc.push({
      ...cov,
      name: nameMapping[snakeCase(name)],
    })

    return acc
  }, [])
}

export const filteredDirectorsAndOfficersQuotedLine = quotation => {
  const filtered = quotation.quotedLines?.nodes?.filter(
    l =>
      l != null &&
      l !== undefined &&
      l.type === 'ManagementLiability::DirectorsAndOfficersQuotedLine'
  )

  return !isEmpty(filtered) && filtered[0].directorsAndOfficers
}
