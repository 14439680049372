import React from 'react'

import { Flex, Text, $ } from 'atoms'

const ReadOnlyCurrency = ({ currency = 'USD', value, label, uiSchema }) => {
  const { 'ui:styles': styles = {} } = uiSchema
  const {
    justifyContent,
    marginTop,
    marginRight,
    width,
    flexLabel,
    flexValue,
  } = styles
  // if there is no label
  if (!label)
    return (
      <Flex alignItems="center" height="40px">
        <$ currency={currency}>{value}</$>
      </Flex>
    )
  return (
    <Flex
      justifyContent={justifyContent || 'space-between'}
      mt={marginTop || '0px'}
      mr={marginRight || '0px'}
      width={width || '100%'}>
      <Text flex={flexLabel || 'initial'} mr={1}>
        {label}
      </Text>
      <$ flex={flexValue || 'initial'} currency={currency}>
        {value}
      </$>
    </Flex>
  )
}

export default ReadOnlyCurrency
