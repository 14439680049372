import React from 'react'
import styled from 'styled-components'
import { Auth } from 'aws-amplify'

import { colors, fonts } from '../components/theme'
import { H1, Paragraph } from '../components/theme/typography'
import Button from '../components/common/Button'
import img from '../images/ripped.png'
import Header from '../components/header/Header'

const Container = styled.div`
  height: 100vh;
  background-color: ${colors.background};
  background-image: url(${img});
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
  background-position: calc(50% - 40px) calc(50% + 80px);
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
  text-align: center;
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px;
`

const Title = styled(H1)`
  font-weight: 600;
  margin-bottom: 0;
  font-family: ${fonts.primary};
`

const SubTitle = styled(Paragraph)`
  margin-top: 0.25em;
  font-family: ${fonts.secondary};
`

const StyledButton = styled(Button)`
  margin: 0.5em;
  width: 300px;
  &:first-child {
    background-color: ${colors.white};
    border: 4px solid ${colors.secondaryYellow};
  }
  font-family: ${fonts.secondary};
`

const DEFAULT_TITLE = 'We were hoping you would never see this.'
const DEFAULT_MESSAGE = 'Our technical team has been notified of this problem.'

const Oops = ({ error, resetError }) => {
  let title = DEFAULT_TITLE
  let message = DEFAULT_MESSAGE
  let canReload = true

  if (error.message?.match(/Account Disabled/)) {
    title = 'Account Disabled'
    message = 'Please contact support.'
    canReload = false
  } else if (error.message?.match(/User not authenticated/)) {
    Auth.signOut()
    resetError()
  }

  return (
    <Container>
      <Header />
      <ContentContainer>
        <Title>{title}</Title>
        <SubTitle>{message}</SubTitle>
        <ButtonContainer>
          {canReload && (
            <StyledButton onClick={() => window.location.reload()}>
              Try Reloading
            </StyledButton>
          )}
          <StyledButton
            onClick={() => {
              window.zE.activate()
            }}>
            Contact Support
          </StyledButton>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )
}

export default Oops
