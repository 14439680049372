import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Panelist from './Panelist'
const PanelistWithAuthentication = ({
  authentications = [],
  onChange,
  onInputChange,
  checked,
  panelist,
  ...rest
}) => {
  const defaultValue = authentications.find(auth => auth.default === true)?.id
  const currentValue = checked?.auth_id
  const [value, setValue] = useState(currentValue || defaultValue)
  const selected = authentications.find(auth => auth.id === value)
  const handleChange = () => onChange(panelist.id, value)
  useEffect(() => {
    onInputChange(panelist.id, value)
  }, [value])
  return (
    <Panelist
      panelist={panelist}
      {...rest}
      onChange={handleChange}
      checked={!!checked}>
      <Autocomplete
        value={selected || null}
        options={authentications}
        onChange={(_event, selected) => setValue(selected?.id)}
        getOptionLabel={option => option.nickname}
        style={{ marginBottom: '1rem', background: 'white' }}
        disableClearable
        renderInput={params => <TextField {...params} variant="outlined" />}
      />
    </Panelist>
  )
}

export default PanelistWithAuthentication
