import React from 'react'
import styled from 'styled-components'

import { space } from 'styled-system'

const Card = styled.div`
  border-radius: 0.25rem;
  background-color: ${props => props.theme.colors.warningBackground};
  border: 1px solid ${props => props.theme.colors.secondaryYellow};

  ${space}
`

const Highlight = ({ children }) => {
  return (
    <Card p={2} mb={2}>
      {children}
    </Card>
  )
}

export default Highlight
