const INSURING_AGREEMENTS = [
  {
    label: 'Terrorism and/or Sabotage Insurance',
    key: 'insuringAgreements[0]',
    defaultTooltipContent:
      'To insure property against physical loss or damage caused by an act of terrorism and/or sabotage, according to the full provisions of the policy.',
  },
  {
    label: 'Acquisition',
    key: 'insuringAgreements[1]',
    defaultTooltipContent:
      'To insure property and locations of the type insured by the policy at any location rented, leased, purchased, or acquired by the insured after the inception date of the policy, for the term noted in the policy, according to the full provisions of the policy.',
  },
  {
    label: 'Active Assailant Event Insurance',
    key: 'insuringAgreements[2]',
    defaultTooltipContent:
      'To pay the reasonable and necessary expenses incurred by the insured as the consequence of an active assailant event, including any physical damage caused by responding law enforcement or security forces, at the insured property or within a specified radius, according to the full provisions of the policy.',
  },
  {
    label: 'Business Interruption',
    key: 'insuringAgreements[3]',
  },
  {
    label: 'Contingent Time Element',
    key: 'insuringAgreements[4]',
    defaultTooltipContent:
      'To cover the actual loss sustained by the insured or the extra expense incurred by the insured as a result of an act of terrorism and/or sabotage at their supplier or receiver properties, according to the full provisions of the policy.',
  },
  {
    label: 'Debris Removal',
    key: 'insuringAgreements[5]',
    defaultTooltipContent:
      'To pay the reasonable expenses and necessary costs incurred in the removal from the insured property of debris of property that remains as a direct result of physical loss or physical damage insured by this policy, according to the full provisions of the policy.',
  },
  {
    label: 'Demolition & Increased Cost of Construction',
    key: 'insuringAgreements[6]',
    defaultTooltipContent:
      'To pay the costs incurred to demolish the physically undamaged part of the insured property and rebuild it with materials and in a manner to satisfy the law or ordinance in effect on the date of loss, according to the full provisions of the policy.',
  },
  {
    label: 'Denial of Access',
    key: 'insuringAgreements[7]',
    defaultTooltipContent:
      "To cover expenses incurred due to the necessary interruption of the insured's business due to the prevention of access and/or the prevention of ingress to or egress from the insured property including non-damage denial of access imposed on the insured by civil or military authority, as a result of an act of terrorism and/or sabotage, according to the full provisions of the policy.",
  },
  {
    label: 'Expert Fees',
    key: 'insuringAgreements[8]',
    defaultTooltipContent:
      'To pay the necessary and reasonable fees of architects, surveyors, consulting engineers and other professional experts resulting from an insured loss payable under this policy, according to the full provisions of the policy.',
  },
  {
    label: 'Extended Period of Liability',
    key: 'insuringAgreements[9]',
    defaultTooltipContent:
      "To cover the actual business interruption loss sustained by the insured for such additional length of time needed to restore the insured's business to the condition that would have existed had no loss occurred, according to the full provisions of the policy.",
  },
  {
    label: 'Extra Expense',
    key: 'insuringAgreements[10]',
    defaultTooltipContent:
      'To cover the excess (if any) of the total cost incurred during the period of restoration chargeable to the operation of the insured’s business over and above the total cost that would normally have been incurred to conduct the business during the same period had no loss or damage occurred, according to the full provisions of the policy.',
  },
  {
    label: 'Rental Insurance',
    key: 'insuringAgreements[11]',
    defaultTooltipContent:
      'To cover the loss of rental income resulting directly from necessary untenability caused by direct physical loss or damage by an act of terrorism and/or sabotage, according to the full provisions of the policy.',
  },
  {
    label: 'Service Interruption',
    key: 'insuringAgreements[12]',
    defaultTooltipContent:
      'To cover loss sustained and extra expense incurred during the period defined by the policy caused by the interruption of incoming or outgoing services as defined in the policy by reason of physical loss or physical damage, according to the full provisions of the policy.',
  },
  {
    label: 'Threat or Hoax',
    key: 'insuringAgreements[13]',
    defaultTooltipContent:
      'To pay the expenses incurred by or on behalf of the insured as a consequence of any threat or hoax of an act of terrorism and/or sabotage occurring at the insured property or at a location within a specified distance, for a defined period of time, according to the full provisions of the policy.',
  },
  {
    label: 'Terrorism and/or Sabotage Liability Insurance (Sublimit)',
    key: 'insuringAgreements[14]',
    defaultTooltipContent:
      'To cover financial costs to a company against claims for damages by third parties and/or employees for property damage or bodily injury, according to the full provisions of the policy. Provided as a part of and not in addition to the sum insured/policy limit stated on the policy schedule.',
  },
  {
    label: 'Terrorism and/or Sabotage Liability Insurance',
    key: 'insuringAgreements[15]',
    defaultTooltipContent:
      'To cover financial costs to a company against claims for damages by third parties and/or employees for property damage or bodily injury, according to the full provisions of the policy. This stand-alone coverage is typically broader in scope than the Liability Sublimit that can be added onto some Terrorism and/or Sabotage Property policies.',
  },
  {
    label: 'Chemical, Biological, Radiological and Nuclear (CBRN) Insurance',
    key: 'insuringAgreements[16]',
    defaultTooltipContent:
      'To cover property damage and/or contamination at an insured location caused by a CBRN device released with malicious intent, according to the full provisions of the policy.',
  },
  {
    label: 'Chemical, Biological, Radiological and Nuclear Liability Insurance',
    key: 'insuringAgreements[17]',
    defaultTooltipContent:
      'To cover public liability of an insured associated with a CBRN event on their premises, according to the full provisions of the policy.',
  },

  {
    label: 'Loss of Attraction Insurance',
    key: 'insuringAgreements[18]',
    defaultTooltipContent:
      "To cover a business interruption loss without any property damage to the insured's property when an act of terrorism and/or sabotage occurs within a specified distance of the insured's property or at a pre-specified attraction property (e.g., convention centre or theme park), according to the full provisions of the policy.",
  },
  {
    label: 'Threat of Malicious Act Insurance',
    key: 'insuringAgreements[19]',
    defaultTooltipContent:
      'To insure against the threat of a malicious act to either damage property of the insured or cause bodily injury to personnel whilst at an insured location, according to the full provisions of the policy. This coverage is broader in scope than the Threat or Hoax provided under the Terrorism and/or Sabotage Coverage.',
  },
  {
    label: 'Strikes, Riots, Civil Commotions Insurance',
    key: 'insuringAgreements[20]',
    defaultTooltipContent:
      'To provide cover for loss or damage caused directly by strikers, locked-out workers, people involved in work-related unrest and riots of various kinds, or by a riot involving a large number of people whose collective action causes damage to people or property, according to the full provisions of the policy.',
  },
]

export { INSURING_AGREEMENTS }
