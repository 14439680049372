import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'
import { Field, SubmissionError, formValueSelector } from 'redux-form'
import { length, required, email } from 'redux-form-validators'
import { connect } from 'react-redux'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'

import usePermission from 'hooks/permissions'
import Button from 'components/common/Button'
import ReinsuranceCompaniesDropdown from '../ReinsuranceCompaniesDropdown'
import InputWidget from 'components/forms/widgets/InputWidget'
import Modal from 'components/common/Modal'
import Label from 'components/forms/FieldLabel'
import _Icon from 'components/Icon'
import validateEmailDomain from 'validators/emailDomain'
import validateDisabledMarket from 'util/validateDisabledMarket'
import { ModalForm, ApprovalContainer } from 'components/common/Layout'
import { ButtonBox, Title, SubRow, Column } from 'components/shared/Layout'
import { form } from 'util/forms'
import { RequiredLegend } from 'components/forms'
import { Error } from 'components/common/Indicators'
import { colors } from 'components/theme'
import { lower, capitalize } from 'util/string'

const ContactForm = styled(ModalForm)`
  width: 100%;
`

const StyledTitle = styled(Title)`
  margin-bottom: 0px;
`

const Icon = styled(_Icon)`
  margin-top: 0.25em;
`

let validateMarket

const CreateAuthorizedContactForm = ({
  onCancel,
  currentCompany,
  handleSubmit,
  translator,
  error,
}) => {
  const [showWarning, toggleWarning] = useState(false)
  const [canManageMarkets] = usePermission('manage', 'authorized_markets')

  useEffect(() => {
    validateMarket = validateDisabledMarket(canManageMarkets)
  }, [canManageMarkets])

  const showWarningIfNeeded = value =>
    toggleWarning(
      canManageMarkets &&
        value &&
        value.name &&
        (!Object.prototype.hasOwnProperty.call(value, 'disabled') ||
          value.disabled)
    )

  const submitHandler = evt => {
    evt.stopPropagation()
    return handleSubmit(evt)
  }

  return (
    <Modal>
      <ContactForm onSubmit={submitHandler}>
        <StyledTitle>Add Contact</StyledTitle>
        <RequiredLegend />
        <SubRow>
          <Column wide>
            <Field
              component={ReinsuranceCompaniesDropdown}
              name="company"
              id="company"
              onBlur={showWarningIfNeeded}
              onChange={showWarningIfNeeded}
              validate={validateMarket}
              company
              secondaryBackground
            />
          </Column>
        </SubRow>
        <SubRow wide>
          <Column wide>
            <Label required htmlFor="emailAddress">
              Email
            </Label>
            <Field
              component={InputWidget}
              name="email"
              id="email"
              validate={[
                required({ message: 'Enter Email' }),
                email({ message: 'Enter a valid Email' }),
                validateEmailDomain,
              ]}
              secondary
              normalize={lower}
            />
          </Column>
        </SubRow>
        <SubRow>
          <Column wide>
            <Label required htmlFor="firstName">
              First Name
            </Label>
            <Field
              component={InputWidget}
              name="firstName"
              id="firstName"
              validate={required({ message: 'Enter First Name' })}
            />
          </Column>
        </SubRow>
        <SubRow>
          <Column wide>
            <Label required htmlFor="lastName">
              Last Name
            </Label>
            <Field
              component={InputWidget}
              name="lastName"
              id="lastName"
              validate={required({ message: 'Enter Last Name' })}
            />
          </Column>
        </SubRow>
        <SubRow>
          <Column wide>
            <Label htmlFor="additionalInfo">Additional Information</Label>
            <Field
              component={InputWidget}
              name="description"
              id="description"
              validate={length({
                max: 60,
                message: 'Maximum of 60 characters allowed',
              })}
            />
          </Column>
        </SubRow>
        {error && error.authorizedMarketContacts && (
          <SubRow>
            {error.authorizedMarketContacts.map(error => (
              <Error key={error}>{error}</Error>
            ))}
          </SubRow>
        )}
        {showWarning ? (
          <ApprovalContainer>
            <h3>Approve {currentCompany} for Your Team?</h3>
            <p>
              <Icon icon={faExclamationCircle} color={colors.secondaryYellow} />
              {currentCompany} has not been approved. If you continue, this{' '}
              {translator('role')} will become an approved {translator('role')}{' '}
              for your team.
            </p>
            <ButtonBox>
              <Button light onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">
                Approve {capitalize(translator('role'))} and Add Contact
              </Button>
            </ButtonBox>
          </ApprovalContainer>
        ) : (
          <ButtonBox>
            <Button light onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={submitHandler}>Add Contact</Button>
          </ButtonBox>
        )}
      </ContactForm>
    </Modal>
  )
}

CreateAuthorizedContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  error: PropTypes.array,
}

const selector = formValueSelector('CREATE_AUTHORIZED_MARKET_CONTACT')

export default compose(
  form({
    form: 'CREATE_AUTHORIZED_MARKET_CONTACT',
    analyticsEvent: 'CreateAuthMarketContact',
    onSubmit: (
      { company, email, firstName, lastName, description, generalInbox },
      _,
      { createAuthorizedMarketContact, onConfirm }
    ) => {
      return createAuthorizedMarketContact({
        input: {
          companyId: company.id,
          companyName: company.name,
          attributes: {
            email: email,
            firstName: firstName,
            lastName: lastName,
            description: description,
            generalInbox,
          },
        },
      }).then(({ data }) => {
        if (data.createAuthorizedMarketContact.authorizedMarketContact) {
          const market =
            data.createAuthorizedMarketContact.authorizedMarketContact
          onConfirm(market)
        } else {
          throw new SubmissionError({
            _error: data.createAuthorizedMarketContact.errors,
          })
        }
      })
    },
  }),
  connect(state => ({
    currentCompany: selector(state, 'company.name'),
  }))
)(CreateAuthorizedContactForm)
