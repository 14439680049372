import jp, { mapJson, mapFields } from 'util/jsonpath'
import { QuotationMapping as TerrorismQuotationMapping } from '../terrorism/mappings'
import { QuotationMapping as CyberQuotationMapping } from '../multilineCyber/mappings'
import { QuotationMapping as BopQuotationMapping } from '../BOP/mappings'

const PanelMapping = {
  ...mapFields(['id', 'lineOfBusiness']),
}

const CyberProductMapping = {
  ...mapFields([
    'id',
    'status',
    'limit',
    'website',
    'deductible',
    'annual_revenue',
    'number_of_records',
    'selected_panelists',
    'coverage_start_date',
    'number_of_employees',
    '__typename',
  ]),
  panel: product => mapJson(product.panel, PanelMapping),
}

const BopProductMapping = {
  ...mapFields([
    'id',
    'status',
    'limit',
    'website',
    'deductible',
    'annual_revenue',
    'number_of_records',
    'selected_panelists',
    'coverage_start_date',
    'number_of_employees',
    '__typename',
  ]),
  panel: product => mapJson(product.panel, PanelMapping),
}

const HistoryMapping = {
  ...mapFields([
    'number_of_previous_losses',
    'previous_losses',
    'previous_threat',
    'total_loss_value',
    '__typename',
  ]),
}

const CoveredLocationsMapping = {
  ...mapFields([
    'armed_guards',
    'business_interruption_limit',
    'city',
    'contents_stock',
    'crash_barrier_perimeter_fence',
    'property_damage_limit',
    'state',
    'street_number_and_name',
    'zip_code',
    '__typename',
  ]),
}

const TerrorismProductMapping = {
  ...mapFields([
    'id',
    'status',
    'limit',
    'deductible',
    'occupancy_type',
    'additional_active_assailant',
    'coverage_start_date',
    'coverage_end_date',
    'deductible',
    'general_liability',
    'general_liability_limit',
    'limit',
    'target_price',
    '__typename',
  ]),
  panel: product => mapJson(product.panel, PanelMapping),
  carriers: product => product.carriers,
  history: product =>
    product.history ? mapJson(product.history, HistoryMapping) : {},
  locations: product => {
    if (product.locations) {
      return product.locations.map(loc =>
        mapJson(loc || {}, CoveredLocationsMapping)
      )
    }
    return []
  },
}

const getQuotationMappingForType = type => {
  switch (type) {
    case 'CyberQuotation':
      return CyberQuotationMapping
    case 'TerrorismQuotation':
      return TerrorismQuotationMapping
    case 'BOPQuotation':
      return BopQuotationMapping
    default:
      throw new Error(
        'Invalid quotation typename: must be CyberQuotation or TerrorismQuotation'
      )
  }
}

const getProductMappingForType = type => {
  switch (type) {
    case 'CyberProduct':
      return CyberProductMapping
    case 'TerrorismProduct':
      return TerrorismProductMapping
    case 'BOPProduct':
      return BopProductMapping
    default:
      throw new Error(
        'Invalid product typename: must be CyberProduct or TerrorismProduct'
      )
  }
}

export const ApplicantMapping = {
  ...mapFields([
    'id',
    'company_name',
    'naics_code',
    'name',
    'email',
    'phone_number',
  ]),
}

export const QuotedLinesMapping = {
  ...mapFields([
    'annualPremium',
    'amountCommission',
    'maximumCommissionPercentage',
    'retailCommissionPercentage',
    'wholesalerCommissionPercentage',
    'totalCommissionPercentage',
    'retailCommission',
    'clientCost',
    'retailCommission',
    'wholesalerCommission',
    'policyNumber',
    'coverageStartDate',
    '__typename',
  ]),
}

export const CombinedQuotationMapping = {
  ...mapFields([
    'admitted',
    'coverageStartDate',
    'declinationReason',
    'deductible',
    'documents',
    'fee',
    'id',
    'indication',
    'limit',
    'participationId',
    'policyNumber',
    'premium',
    'requestedAt',
    'requestedDeductible',
    'requestedLimit',
    'selected',
    'signingUrl',
    'status',
    'statusMessage',
    'submissionId',
    'submissionOwner',
    'surplusLinesTax',
    'totalCost',
    'underwriterEmail',
    'underwriterFirstName',
    'underwriterLastName',
  ]),
  ambestRating: 'user.company.ambestRating.rating',
  carrier: ['user.company.name', 'externalCarrier.name'],
  externalCarrier: 'externalCarrier.name',
  isApi: 'collaborator.api',
  isManual: quotation => quotation.type === 'Cyber::ManualQuotation',
  isCarrier: quotation => quotation.type === 'Cyber::CarrierQuotation',
  logoUrl: ['user.company.logoUrl', 'externalCarrier.logoUrl'],
  isExtracted: quotation => quotation.extractedAt && !quotation.reviewedAt,
  isOutdated: quotation => quotation.status === 'outdated',
  quotedLines: quotation => {
    if (quotation.quotedLines) {
      return (
        quotation.quotedLines.nodes?.map(line =>
          mapJson(line || {}, QuotedLinesMapping)
        ) || []
      )
    }
  },
}

export const SubmissionMapping = {
  ...mapFields(['id', 'name', 'status', 'owner', 'documents', 'updatedAt']),

  applicant: submission =>
    mapJson(submission.applicant || {}, ApplicantMapping),

  products: submission =>
    submission.products?.map(product =>
      mapJson(product || {}, getProductMappingForType(product.__typename))
    ) || [],

  quotations: submission => {
    const participations = jp.query(submission, 'participations.nodes[*]')

    return participations.reduce(
      (
        memo,
        { id: participationId, user, createdAt, quotations, collaborator }
      ) => {
        if (quotations.nodes.length > 0) {
          return memo.concat(
            quotations.nodes.map(quotation => {
              return mapJson(
                {
                  ...quotation,
                  user,
                  participationId,
                  collaborator,
                  requestedAt: createdAt,
                  submissionId: submission.id,
                  submissionOwner: submission.owner.fullName,
                  carrierName: user.company.name,
                },
                getQuotationMappingForType(quotation.__typename)
              )
            })
          )
        } else {
          return memo.concat([
            mapJson(
              {
                status: 'pending',
                participationId,
                collaborator,
                user: user,
                requestedAt: createdAt,
                submissionId: submission.id,
                submissionOwner: submission.owner.fullName,
                carrierName: user.company.name,
              },
              CombinedQuotationMapping
            ),
          ])
        }
      },
      []
    )
  },
}
