import React from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import union from 'lodash/union'
import { v4 as uuid } from 'uuid'
import { useForm } from 'react-final-form'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'

import { Flex, Label, Error, Table, TR, TH, TD, Icon } from 'atoms'
import { FilenameWithField } from './Filename'
import Version from './Version'
import Category from './Category'
import UploadButton from './UploadButton'
import { length } from 'util/validators'
import { getNextVersion } from './index'

const Trash = ({ remove }) => {
  return (
    <Flex p={1}>
      <Icon data-testid="trash" icon={faTrash} onClick={remove} />
    </Flex>
  )
}

const SimpleUploader = ({
  name,
  label,
  note,
  required,
  categories,
  defaultCategory,
  creatable = true,
  canBeBlank = true,
  useSimpleRemove = false,
  generateId = false,
  ...props
}) => {
  const { change } = useForm()
  const { fields, meta } = useFieldArray(
    name,
    required ? { validate: length({ min: 1 }) } : undefined
  )

  const remove = index => () => {
    const current = fields.value[index]
    if (current.id) {
      fields.update(index, { ...current, _destroy: true })
    } else {
      fields.remove(index)
    }
  }

  const simpleRemove = index => () => {
    fields.length >= 1 ? fields.remove(index) : null
  }

  // To prevent a race condition, generateId should be enabled for any forms that autosave.
  const onUpload = accepted => {
    accepted.forEach(file =>
      fields.push({
        id: generateId ? uuid() : null,
        attachment: file,
        category: defaultCategory,
        version: getNextVersion(fields.value || [], defaultCategory),
      })
    )
  }

  const onCategoryChanged = (name, newCategory) => {
    const version = getNextVersion(fields.value, newCategory)
    change(`${name}.version`, version)
  }

  const value = fields.value || []

  const documentCategories = union(
    categories,
    (value || []).map(f => f.category).filter(Boolean)
  )

  if (value.filter(file => !file._destroy).length > 0) {
    return (
      <Flex flexDirection="column" {...props}>
        <Table
          header={
            <TR>
              <TH p="0px">Document</TH>
              <TH p="0px" width="30%">
                Type
              </TH>
              <TH p="0px" width="20%">
                Version
              </TH>
              <TH p="0px" width="1.5rem"></TH>
            </TR>
          }>
          {fields.map((name, index) => {
            const file = value[index]
            if (file._destroy) return null

            return (
              <TR key={index}>
                <TD p="0px">
                  <FilenameWithField name={name} />
                </TD>
                <TD p="0px" pr={1}>
                  <Category
                    name={name}
                    documentCategories={documentCategories}
                    creatable={creatable}
                    canBeBlank={canBeBlank}
                    onCategoryChanged={onCategoryChanged}
                  />
                </TD>
                <TD p="0px" pr={1}>
                  <Version name={name} />
                </TD>
                <TD p="0px">
                  <Trash
                    remove={
                      useSimpleRemove ? simpleRemove(index) : remove(index)
                    }
                  />
                </TD>
              </TR>
            )
          })}
          {meta.touched && meta.error && <Error>{meta.error}</Error>}
        </Table>
        <UploadButton onUpload={onUpload} label="Attach Document" secondary />
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" {...props}>
      {label && (
        <Label mb={1} required={required} note={note}>
          {label}
        </Label>
      )}
      <UploadButton onUpload={onUpload} />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </Flex>
  )
}

export default SimpleUploader
