import { I18n } from 'aws-amplify'

const vocabularies = {
  en: {
    // Settings
    'settings.company.success': 'Company settings saved successfully',

    // Terms & Conditions Page for Quoters
    'termsAndConditions.poweredBy': 'Digital Risk Transfers Powered by Relay',
    'termsAndConditions.questions': 'Have Questions About Relay?',
    'termsAndConditions.reinsurance.body': `Relay helps reinsurance ceding teams,
    insurance underwriters, reinsurers, MGAs and brokers turn data into visual
    coverage towers, then use them to secure and manage quotes. Once you view
    the submission, you can reply by email or quote through Relay.`,
    'termsAndConditions.insurance.body': `Relay helps insurance underwriters,
    MGAs and brokers turn data into visual coverage towers, then use them to secure and
    manage quotes. Once you view the submission, you can reply by email or quote through Relay.`,

    // Quote Workflow
    'quote.approved.success': 'The quote has been approved and sent',
    'quote.updated.success': 'Your updated quote has been sent',
    'quote.created.success': 'Your quote has been sent',
    'quote.approvalRequested.success':
      'Your quote has been sent to a manager for approval',
    'quote.reassignToSelf.success': 'Quote has been reassigned to you',
    'quote.binder.sent.success': 'Quote has been bound',

    'counterProposal.sent.success': 'You have sent a counter-proposal',
    'counterProposal.updated.success':
      'Your updated counter-proposal has been sent',
    'counterProposal.declined.success':
      'You have declined the counter-proposal',
    'quote.reinsurance.certification.sent.success':
      'Certificate added. The deal is complete',
    'quote.insurance.certification.sent.success':
      'Policy added. The deal is complete',

    // Quote labels
    'quote.reinsurance.layerLimit': 'Layer Limit',
    'quote.insurance.layerLimit': 'Amount of Coverage',
    'quote.reinsurance.attachmentPoint': 'Attachment Point',
    'quote.insurance.attachmentPoint': 'Attachment',
    'quote.reinsurance.layerPremium': 'Layer Premium',
    'quote.insurance.layerPremium': 'Quote Premium',
    'quote.reinsurance.portionPremium': "Reinsurer's Premium",
    'quote.insurance.portionPremium': "Insurer's Premium",
    'quote.reinsurance.cedentSubmission': 'Cedent Submission',
    'quote.insurance.cedentSubmission': 'Submission Overview',
    'quote.reinsurance.brokerSubmission': 'Broker Submission',
    'quote.insurance.brokerSubmission': 'Submission Overview',
    'quote.insurance.referenceNumber': 'Reference Number',
    'quote.reinsurance.certificateNumber': 'Certificate Number',
    'quote.reinsurance.referenceNumber': 'Reference Number',
    'quote.insurance.certificateNumber': 'Policy Number',
    'quote.reinsurance.certificateToFollow': 'Certificate to follow',
    'quote.insurance.certificateToFollow': 'Policy to follow',
    'quote.reinsurance.certificate': 'Certificate',
    'quote.insurance.certificate': 'Policy',
    'quote.insurance.role': 'Insurer',
    'quote.reinsurance.role': 'Reinsurer',

    // Submission Workflow
    'submission.reinsurance.layerLimit': 'Layer Limit',
    'submission.insurance.layerLimit': 'Amount of Coverage',
    'submission.reinsurance.attachmentPoint': 'Attachment Point',
    'submission.insurance.attachmentPoint': 'Attachment',
    'submission.reinsurance.perOccurrence': 'Per Occurrence Limit',
    'submission.reinsurance.perOccurrenceExcessUmbrella':
      'Umbrella Liability Limit',
    'submission.insurance.perOccurrence':
      'Amount of Coverage You Need Per Occurrence',
    'submission.insurance.perOccurrenceExcessUmbrella':
      'Amount of Coverage You Need Per Occurrence',
    'submission.reinsurance.aggregateLimit': 'Aggregate Limit',
    'submission.insurance.aggregateLimit': 'Aggregate Limit',
    'submission.reinsurance.limit': 'Policy Limit',
    'submission.insurance.limit': 'Amount of Coverage Desired',
    'submission.reinsurance.limit.validation': 'Enter Policy Limit',
    'submission.insurance.limit.validation': 'Enter Amount of Coverage Desired',
    'submission.reinsurance.kind': 'reinsurance',
    'submission.insurance.kind': 'insurance',
    'submission.reinsurance.role': 'reinsurer',
    'submission.insurance.role': 'insurer',
    'submission.insurance.cedent': 'cedent',
    'submission.reinsurance.cedent': 'cedent',
    'submission.insurance.broker': 'broker',
    'submission.reinsurance.broker': 'broker',
    'submission.reinsurance.company': 'Ceding Company',
    'submission.insurance.company': 'Company Name',
    'submission.reinsurance.reinsurerCompany': 'Reinsurer Company Name',
    'submission.insurance.reinsurerCompany': 'Insurer Company Name',
    'submission.reinsurance.action': 'ceding',
    'submission.insurance.action': 'placing',
    'submission.reinsurance.effectiveDate': 'Policy Effective Date',
    'submission.insurance.effectiveDate': 'Coverage Start Date ',
    'submission.reinsurance.expiryDate': 'Policy Expiry Date',
    'submission.insurance.expiryDate': 'Coverage Expiry Date',
    'submission.reinsurance.towerForm.name': 'Reinsurance Structure',
    'submission.insurance.towerForm.name': 'Insurance Structure',
    'submission.reinsurance.towerForm.info':
      'Define the maximum amount of risk you will be ceding.',
    'submission.insurance.towerForm.info':
      'Define the maximum amount of risk you are looking to insure.',
    'submission.coverage.intro': 'Describe the coverage you need.',
    'submission.coverage.underlying.label': 'Underlying Policies',
    'submission.reinsurance.property.limit.fieldName': 'Policy Limit',
    'submission.insurance.property.limit.fieldName': 'Amount Requested',
    'submission.reinsurance.layers.title': 'Reinsurance Layer',
    'submission.insurance.layers.title': 'Insurance Layer',
    'submission.reinsurance.towerForm.message.label':
      'Special Instructions For Reinsurer',
    'submission.insurance.towerForm.message.label':
      'Special Instructions For Insurer',
    'submission.creation.choose': 'Choose Submission Type',
    'submission.creation.choose.coverage': 'Select one or multiple coverages',
    'submission.insurance.underlying.title': 'Coverage Requested',
    'submission.reinsurance.underlying.title': 'Underlying Coverages',
    'submission.insurance.underlying.intro':
      'Please describe the coverage you require.',
    'submission.reinsurance.underlying.intro':
      'Please describe the coverages you would like to reinsure.',
    'submission.reinsurance.perOccurrenceLimit.validation':
      'Enter Per-Occurrence Limit',
    'submission.reinsurance.perOccurrenceLimitExcessUmbrella.validation':
      'Enter Umbrella Liability Limit',
    'submission.insurance.perOccurrenceLimit.validation':
      'Enter Per-Occurrence Limit',
    'submission.reinsurance.insurer.validation': 'Enter Insurer',
    'submission.insurance.insurer.validation': 'Enter Insurer',
    'submission.reinsurance.layerPremium': 'Layer Premium',
    'submission.insurance.layerPremium': 'Quote Premium',
    'submission.reinsurance.layerPremium.widget': 'Layer Premium',
    'submission.insurance.layerPremium.widget': 'Premium',
    'submission.insurance.pricingDeductible': 'Deductible',
    'submission.reinsurance.policyLabel': 'Policy',
    'submission.insurance.policyLabel': 'Coverage',
    'submission.reinsurance.policyVerb': 'reinsuring',
    'submission.insurance.policyVerb': 'insuring',
    'submission.reinsurance.certificate': 'Certificate',
    'submission.insurance.certificate': 'Policy',
    'submission.reinsurance.applicant': 'Cedent',
    'submission.insurance.applicant': 'Applicant',
    'submission.reinsurance.invite': 'Invite Reinsurers',
    'submission.insurance.invite': 'Invite Insurers',
    'submission.reinsurance.underlying.comment':
      'Describe Underlying and Additional Coverage',
    'submission.insurance.underlying.comment':
      'Describe Underlying and Additional Coverage',
    'submission.reinsurance.primary.comment': 'Comment',
    'submission.insurance.primary.comment': 'Comment',
    'submission.reinsurance.portionPremium': "Reinsurer's Premium",
    'submission.reinsurance.productsCompletedOpsAggregate':
      'Products and Completed Ops Aggregate Limit',
    'submission.reinsurance.programStructure': 'Program Structure',
    'submission.reinsurance.bms.aggregateLimit': 'General Aggregate',
    'submission.reinsurance.bms.user': 'BMS',
    'submission.insurance.portionPremium': "Insurer's Premium",

    // Coverage Details Modal, Summary page, Worksheet details
    'submission.reinsurance.bms.perOccurrence.generalLiability':
      'Each Occurrence',
    'submission.reinsurance.perOccurrence.generalLiability':
      'Per Occurrence Limit',
    'submission.reinsurance.bms.perOccurrence.excessUmbrella':
      'Each Occurrence / Aggregate Where Applicable Excess Scheduled Underlying Limits',
    'submission.reinsurance.perOccurrence.excessUmbrella':
      'Per Occurrence Limit',
    'submission.reinsurance.bms.perOccurrence.commercialAuto':
      'Each Accident Bodily Injury (BI) & Property Damage (PD) Combined Single Limit',
    'submission.reinsurance.perOccurrence.commercialAuto':
      'Per Occurrence Limit',

    // Amplify translations
    'Sign In': 'Log In',
    'Enter your email': ' ',
    'Family Name': 'Last Name',
    'Given Name': 'First Name',
    'Enter your password': ' ',
    'Sign in to your account': 'Log in to your account',
    'Back to Sign In': 'Back to Log In',
    'Custom auth lambda trigger is not configured for the user pool.':
      'Your password is required',
    'UserMigration failed with error Failed to authenticate.':
      'Incorrect email or password',
    'Incorrect username or password.': 'Incorrect email or password',
    'Confirm TOTP Code': 'Two-Factor Authentication',
    'Member must satisfy regular expression pattern:':
      'You are using unsupported characters in your username',

    // Reporting translation
    'report.reinsurance.cededPremium': 'Ceded Premium',
    'report.reinsurance.cededAmount': 'Ceded Amount',
    'report.reinsurance.cededReinsurance': 'Reinsurance Ceded',
    'report.insurance.cededPremium': 'Premium',
    'report.insurance.cededAmount': 'Coverage Bound',
    'report.insurance.cededReinsurance': 'Insurance Bound',

    // Nav translation
    'nav.reinsurance.structure': 'Reinsurance Structure',
    'nav.insurance.structure': 'Insurance Structure',

    // QandA translation
    'qanda.question.question': 'question',
    'qanda.subjectivity.question': 'subjectivity',
    'qanda.question.questions': 'questions',
    'qanda.subjectivity.questions': 'subjectivities',
    'qanda.question.Questions': 'Questions',
    'qanda.subjectivity.Questions': 'Subjectivities',
    'qanda.question.Question': 'Question',
    'qanda.subjectivity.Question': 'Subjectivity',
    'qanda.question.Q': 'Q',
    'qanda.subjectivity.Q': 'S',
    'qanda.question.A': 'A',
    'qanda.subjectivity.A': 'R',
    'qanda.question.answer': 'answer',
    'qanda.subjectivity.answer': 'response',
    'qanda.question.Answer': 'Answer',
    'qanda.subjectivity.Answer': 'Response',

    'qanda.question.editQuestion': 'Edit Question',
    'qanda.subjectivity.editQuestion': 'Edit Subjectivity',
    'qanda.question.approveAnswer': 'Approve Answer',
    'qanda.subjectivity.approveAnswer': 'Accept Response',
    'qanda.question.releaseAnswer': 'Release answer to Carrier',
    'qanda.subjectivity.releaseAnswer': 'Release Answer',
    'qanda.question.retractQuestion': 'Retract Question',
    'qanda.subjectivity.retractQuestion': 'Retract Subjectivity',
    'qanda.question.bulkImportQuestion': 'Bulk Import Questions',
    'qanda.subjectivity.bulkImportQuestion': 'Bulk Import Subjectivity',
    'qanda.question.exportQuestion': 'Export Questions',
    'qanda.subjectivity.exportQuestion': 'Export Subjectivities',
    'qanda.question.copyQuestions': 'Copy Questions',
    'qanda.subjectivity.copyQuestions': 'Copy Subjectivities',
    'qanda.question.copied': 'Copied!',
    'qanda.subjectivity.copied': 'Copied!',
    'qanda.question.enterQuestion': 'Enter Questions (one per line)',
    'qanda.subjectivity.enterQuestion': 'Enter Subjectivities (one per line)',
    'qanda.question.previewImport': 'Preview Import',
    'qanda.subjectivity.previewImport': 'Preview Import',
    'qanda.question.editQuestions': 'Edit Questions',
    'qanda.subjectivity.editQuestions': 'Edit Subjectivities',
    'qanda.question.finalizeImport': 'Finalize Import',
    'qanda.subjectivity.finalizeImport': 'Finalize Import',
    'qanda.question.previewBulkImport': 'Preview Questions Before Importing',
    'qanda.subjectivity.previewBulkImport':
      'Preview Subjectivities Before Importing',
    'qanda.question.shareQuestion': 'Share Questions',
    'qanda.question.copyLink': 'Copy Share Link',
    'qanda.question.unapproveAnswer': 'Unapprove Answer',

    'qanda.question.editAnswer': 'Edit Answer',
    'qanda.subjectivity.editAnswer': 'Edit Response',
    'qanda.question.retractAnswer': 'Retract Answer',
    'qanda.subjectivity.retractAnswer': 'Retract Response',
    'qanda.question.addAnswer': 'Add Answer',
    'qanda.subjectivity.addAnswer': 'Add Response',
  },
}

I18n.putVocabularies(vocabularies)
