import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { faEdit, faSparkles, faTimes } from '@fortawesome/pro-solid-svg-icons'
import {
  Row,
  Column,
  Icon,
  Text,
  Link,
  Flex,
  Button,
  Div,
  WarningContainer,
} from 'atoms'
import { NON_EDITABLE_STATES } from 'panels/constants'
import { includedInQuotationStatus } from 'pages/shared/panels'

const OutdatedWarningMessage = () => (
  <Text>
    The broker updated the Application&nbsp;
    <i>
      <b>after</b>
    </i>
    &nbsp; you provided this quote. You may update this quote according to the
    new information provided or declined based on the new information.
  </Text>
)
const QuotationEditButton = ({
  participation,
  quotation,
  isOutdated,
  editUrl,
}) => {
  const { url } = useRouteMatch()
  const basePath = url.replace(/(.*panels\/\d+).*/, '$1')
  const isDeclinedOutdated = quotation.status === 'declined_outdated'

  return (
    <Row>
      {(isOutdated || isDeclinedOutdated) && (
        <WarningContainer p={1}>
          <Flex justifyContent="space-between" alignItems="center">
            <Div width="80%">
              <OutdatedWarningMessage />
            </Div>
            <Div>
              {quotation.declinationReason ? (
                <Button
                  color="black"
                  secondary
                  as={Link}
                  to={{
                    pathname: `${basePath}/decline`,
                    state: {
                      quotationId: quotation.id,
                      reason: 'declination_confirmed',
                    },
                  }}
                  mr={1}>
                  <Icon icon={faTimes} mr={0} />
                  Confirm Declination
                </Button>
              ) : (
                <Button
                  color="black"
                  secondary
                  as={Link}
                  to={{
                    pathname: `${basePath}/decline`,
                    state: {
                      quotationId: quotation.id,
                      reason: 'declined_outdated',
                    },
                  }}
                  mr={1}>
                  <Icon icon={faTimes} mr={0} />
                  Decline
                </Button>
              )}
            </Div>
            <Div>
              {quotation.declinationReason ? (
                <Button
                  color="black"
                  primary
                  as={Link}
                  to={`${basePath}/quotes/new`}>
                  <Icon icon={faSparkles} mr={0} />
                  Provide Quote
                </Button>
              ) : (
                <Button
                  color="black"
                  primary
                  as={Link}
                  to={editUrl(participation, quotation)}>
                  <Icon icon={faEdit} mr={0} />
                  Update Quote
                </Button>
              )}
            </Div>
          </Flex>
        </WarningContainer>
      )}
      {!includedInQuotationStatus(quotation, NON_EDITABLE_STATES) && (
        <Column flex={1} justifyContent="center" alignItems="flex-end">
          <Link to={editUrl(participation, quotation)}>Revise Quote</Link>
        </Column>
      )}
    </Row>
  )
}

export default QuotationEditButton
