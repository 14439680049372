const validateDisabledMarket = canManage => value => {
  if (!canManage && value && !value.id) {
    return 'You do not have permissions to add new markets. Please contact your manager.'
  } else if (!value || !value.name) {
    return 'Enter Company'
  } else if (value.name.length < 3) {
    return 'Company name must be greater than 2 characters'
  } else if (value.disabled && !canManage) {
    return 'This market has not been approved. Contact your manager.'
  } else {
    return undefined
  }
}

export default validateDisabledMarket

/* We pass in a hard code boolean value because redux-form does not bode well with
dynamic values when they're used as part of the validation. In this case, the
passed-in value of the canManage hook is the dynamic value (either true/false)
When dynamic values are used, redux-form creates a new instance of the
validator every time, which causes an infinite loop.
*/
