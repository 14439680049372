import React from 'react'
import { Text } from 'atoms'
import numeral from 'numeral'

const FORMAT = '0.0[00]%'
const SHORT = '0.[00]%'

const Percent = ({ fallback = '—', children, short, criterion }) => {
  if (children === undefined || children === null || children === '') {
    return <>{fallback}</>
  }
  const value = criterion?.decimal
    ? numeral(children.toString())
    : numeral((children / 100).toString())

  if (
    value.value() === null ||
    value.value().toString() === 'NaN' ||
    value.format(FORMAT) === 'NaN%' ||
    value.format(SHORT) === 'NaN%'
  ) {
    return <>{fallback}</>
  }

  if (short || criterion?.short) {
    return <span title={`${children}%`}>{value.format(SHORT)}</span>
  } else if (criterion?.decimal) {
    return <span title={`${children * 100}%`}>{value.format(FORMAT)}</span>
  }

  return <span title={`${children}%`}>{value.format(FORMAT)}</span>
}

const PercentRenderer = props => {
  const disableColor = props.locked ? { color: '#AAAAAA' } : {}
  return (
    <Text {...disableColor}>
      <Percent fallback="—" {...props} />
    </Text>
  )
}

export default PercentRenderer
