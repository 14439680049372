import React, { useState } from 'react'
import { EnhancedModal, Text, Divider, Div, Subtitle } from 'atoms'
import ActionLink from 'components/common/ActionLink'
const TextModalRenderer = ({
  criterion: { label, title, button },
  children: value,
}) => {
  const [open, setOpen] = useState(false)
  if (!value) return null

  return (
    <>
      <ActionLink onClick={() => setOpen(true)}>{button || label}</ActionLink>
      {open && (
        <EnhancedModal light onClose={() => setOpen(false)}>
          <Div p={2}>
            <Subtitle mt={0} mb={0}>
              {title || label}
            </Subtitle>
            <Divider />
            <Div mt={1}>
              <Text>{value}</Text>
            </Div>
          </Div>
        </EnhancedModal>
      )}
    </>
  )
}

export default TextModalRenderer
