import { I18n } from 'aws-amplify'

import { CompanyType } from 'graphql/api'

const t = I18n.get
const INSURANCE_COMPANY_TYPES = [
  CompanyType.InsuranceBroker,
  CompanyType.Insurer,
  CompanyType.Mga,
  CompanyType.NfpRepsAndWarrantiesBroker,
]

const kindTranslator = kind => (key, prefix) =>
  t(`${prefix}.${kind.toLowerCase()}.${key}`)

const quoteKindTranslator = kind => key =>
  t(`quote.${kind.toLowerCase()}.${key}`)

const quoteUserTranslator = kind => {
  return (key, worksheetOwner = null) => {
    return worksheetOwner
      ? t(`quote.${kind.toLowerCase()}.${worksheetOwner.toLowerCase() + key}`)
      : t(`quote.${kind.toLowerCase()}.${key}`)
  }
}

const quoteTranslator = quote => quoteKindTranslator(quote.worksheet.kind)

const submissionTranslator = worksheet => key =>
  t(`submission.${worksheet.kind.toLowerCase()}.${key}`)

const companyTranslator = company => (prefix, suffix) => {
  if (INSURANCE_COMPANY_TYPES.includes(company.type)) {
    return t(`${prefix}.insurance.${suffix}`)
  } else {
    return t(`${prefix}.reinsurance.${suffix}`)
  }
}

const companyNameTranslator = company => (prefix, suffix) => {
  if (company.name === 'BMS' || company.name === 'BMS Re') {
    return t(`${prefix}.reinsurance.bms.${suffix}`)
  }
  return t(`${prefix}.reinsurance.${suffix}`)
}

const qandaTranslator = scope => key => {
  return t(`qanda.${scope.toLowerCase()}.${key}`)
}

const navKindTranslator = kind => key => t(`nav.${kind.toLowerCase()}.${key}`)

export {
  t,
  kindTranslator,
  quoteTranslator,
  quoteKindTranslator,
  quoteUserTranslator,
  companyNameTranslator,
  companyTranslator,
  submissionTranslator,
  navKindTranslator,
  qandaTranslator,
}
