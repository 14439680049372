import { titleCase } from 'util/string'

export const EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

export const length =
  (options = {}) =>
  (value, values, meta) => {
    const name = meta.name.slice(meta.name.lastIndexOf('.') + 1)
    const min = options.min || 1

    if (!value || value.length < min) {
      return options.message || `Select ${titleCase(name)}`
    }
  }

export const httpsUrl = value => {
  if (!value) return undefined
  return /^https?:\/\//.test(value)
    ? undefined
    : 'Please enter https:// at the start of your LinkedIn url'
}

export const email = value => {
  if (!value) return 'Email address is required'
  return EMAIL_REGEX.test(value) ? undefined : 'Please enter a valid email'
}

export const composeValidators =
  (...validators) =>
  (value, ...rest) =>
    validators.reduce(
      (error, validator) => error || validator(value, ...rest),
      undefined
    )

export const required =
  (options = {}) =>
  (value, values, meta) => {
    const name = meta.name.slice(meta.name.lastIndexOf('.') + 1)

    if (typeof options.condition === 'function') {
      if (!options.condition(value, values, meta)) {
        return
      }
    }

    if (!value) {
      return options.message || `Enter ${titleCase(name)}`
    }
  }

export const validateExposure = (value, allValues) => {
  const isRatingBasis = allValues.ratingBasis

  if (isRatingBasis && !value) {
    return ['Enter exposure value']
  }
}

export const validateRatingBasis = (value, allValues) => {
  const isExposure = allValues.exposure

  if (isExposure && !value) {
    return ['Enter rating basis value']
  }
}

export const INVALID_PASSWORD_MESSAGE =
  'Enter at least 10 characters including numbers and letters'

export const validatePassword = value =>
  /^(?=.*[0-9])(?=.*[a-z]).{10,}$/.test(value)
    ? undefined
    : INVALID_PASSWORD_MESSAGE
