import { mapFields } from 'util/jsonpath'

export const SubmissionMapping = {
  ...mapFields([
    'id',
    'country',
    'name',
    'limit',
    'deductible',
    'documents',
    'coverageStartDate',
    'lineOfBusiness',
    'revisedAt',
  ]),
}

export const QuotationMapping = {
  ...mapFields([
    'id',
    '__typename',
    'submissionId',
    'carrierName',
    'generalLiabilityPremium',
    'overallPropertyPremium',
    'status',
    'generalLiability',
    'property',
    'dynamic',
    'disclaimer',
    'portalUrl',
  ]),
  isApi: 'collaborator.api',
  logoUrl: [
    'apiUser.avatarUrl',
    'user.company.logoUrl',
    'externalCarrier.logoUrl',
  ],
}
