import React from 'react'

import { ToastContractError } from 'atoms/toasts'
import { toast } from 'react-toastify'

const getGqlData = ({ queryName, callback }) =>
  Promise.resolve(
    callback()
      .then(response => ({ errors: {}, ...response.data[queryName] }))
      .catch(error => {
        const errors = { base: error.message }
        errorToast('Unexpected error occurred', {
          errors,
        })
        return { errors }
      })
  )

export const showToast = (response, { messages: { success, error } } = {}) => {
  if (response.errors) return errorToast(error, response.errors)

  toast.success(success)
}

export const errorToast = (message, { errors = {} } = {}) => {
  return toast.error(<ToastContractError title={message} errors={errors} />)
}

const useGqlResponseHandler = () => {
  const showToastFor = (gqlResponse, { messages: { success, error } }) => {
    showToast(gqlResponse, { messages: { success, error } })
  }

  return {
    showToastFor,
    getGqlDataFor: queryName => callback => getGqlData({ queryName, callback }),
  }
}

export default useGqlResponseHandler
