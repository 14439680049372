import pick from 'lodash/pick'

const mapDocs = documents => {
  const docs = documents.map(doc =>
    pick(doc, [
      '_destroy',
      'attachment',
      'category',
      'id',
      'source',
      'version',
      'tags',
    ])
  )

  docs.forEach(doc => {
    for (const key in doc) {
      if (!doc[key] || JSON.stringify(doc[key]) === '{}') {
        delete doc[key]
      }
    }
  })

  return docs
}
export default mapDocs
