import React from 'react'

import { Grid, Button } from '@material-ui/core'
import { AddIcon } from '@material-ui/icons/Add'
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'

import { utils } from '@rjsf/core'

import { emptySection, emptyArraySection } from './utils'

const AddButton = props => (
  <Button {...props} color="secondary">
    <AddIcon /> Add Item
  </Button>
)

const { canExpand } = utils

const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
})

const SCALE_FACTOR = 0.88
const nestedTheme = partialOuterTheme => {
  const outerTheme = createMuiTheme(partialOuterTheme || {})
  const outerFontSize = outerTheme.typography.h5.fontSize
  const innerFontSize = parseFloat(outerFontSize) * SCALE_FACTOR
  const innerFontWeight = innerFontSize < 1.2 ? 'normal' : 'bold'
  return {
    ...outerTheme,
    typography: {
      ...outerTheme.typography,
      h5: {
        ...outerTheme.typography.h5,
        fontSize: innerFontSize + 'rem',
        fontWeight: innerFontWeight,
      },
    },
    spacing: input => 0.8 * outerTheme.spacing(input),
  }
}

const ObjectFieldTemplate = ({
  DescriptionField,
  description,
  TitleField,
  title,
  properties,
  required,
  disabled,
  readonly,
  uiSchema,
  idSchema,
  schema,
  formData,
  onAddClick,
}) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={nestedTheme}>
      {(uiSchema['ui:title'] || title) && (
        <TitleField
          id={`${idSchema.$id}-title`}
          title={title}
          formData={formData}
          required={required}
        />
      )}
      {description && (
        <DescriptionField
          id={`${idSchema.$id}-description`}
          description={description}
        />
      )}
      <Grid container={true} spacing={2} className={classes.root}>
        {properties.map((element, index) => {
          const ui = uiSchema[element.name]
          const hidden =
            ui && (ui['ui:widget'] === 'hidden' || ui['ui:field'] === 'hidden')
          if (hidden) return element.content
          if (element.hidden) {
            return element.content
          }

          const property = schema.properties[element.name]
          const empty = emptySection(property) || emptyArraySection(property)
          if (empty) return element.content

          return (
            <Grid
              item={true}
              xs={ui?.cols || 12}
              key={index}
              style={{ marginBottom: '0.5rem' }}>
              {element.content}
            </Grid>
          )
        })}
        {canExpand(schema, uiSchema, formData) && (
          <Grid container justify="flex-end">
            <Grid item={true}>
              <AddButton
                className="object-property-expand"
                onClick={onAddClick(schema)}
                disabled={disabled || readonly}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  )
}

export default ObjectFieldTemplate
