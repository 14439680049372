import React, { useState, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

import { Flex } from 'atoms'
import { MultiSelect, MultipleTextArea } from 'atoms/form'
import { convertToOption } from 'util/forms'

const HiddenValue = () => null

const prefillerValuesFromInput = (value, options) =>
  value.filter(text => options.includes(text)).map(convertToOption)

const MultipleTextAreaFromSelect = ({
  input,
  meta,
  testId,
  options = [],
  noOptionsMessage = 'No Options',
  selectLabel = 'Select from Preset Options',
  ...rest
}) => {
  const [prefillerValues, setPrefillerValues] = useState(
    prefillerValuesFromInput(input.value, options)
  )

  useEffect(() => {
    const newPrefillerValues = prefillerValuesFromInput(input.value, options)
    if (!isEqual(prefillerValues, newPrefillerValues)) {
      setPrefillerValues(newPrefillerValues)
    }
  }, [input.value, options, prefillerValues])

  const addTextArea = (text = '') => {
    const newValue = [...input.value, text]
    return input.onChange(newValue)
  }

  const prefillerOnChange = (_allValues, action) => {
    if (action.action === 'select-option') {
      addTextArea(action.option.value)
    }
  }

  const defaultOptions = options.map(convertToOption)

  return (
    <Flex flexDirection="column" {...rest}>
      <MultipleTextArea
        testId={testId}
        input={input}
        meta={meta}
        {...rest}
        hideError>
        <MultiSelect
          label={selectLabel}
          mb={1}
          px="1.5rem"
          placeholder=""
          noOptionsMessage={() => noOptionsMessage}
          options={defaultOptions}
          input={{
            value: prefillerValues,
            onChange: prefillerOnChange,
          }}
          meta={meta}
          components={{ MultiValue: HiddenValue, ClearIndicator: HiddenValue }}
        />
      </MultipleTextArea>
    </Flex>
  )
}

export default MultipleTextAreaFromSelect
