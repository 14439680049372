import React from 'react'
import { isDev } from '../util'

if (isDev) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  console.warn(
    '[why-did-you-render] Reference: https://medium.com/welldone-software/why-did-you-render-mr-big-pure-react-component-2a36dd86996f & https://medium.com/welldone-software/why-did-you-render-mr-big-pure-react-component-part-2-common-fixing-scenarios-667bfdec2e0f'
  )

  whyDidYouRender(React, {
    include: null,
    exclude: null,
    trackAllPureComponents: false,
    trackHooks: true,
    logOnDifferentValues: false, // show valid differences
    hotReloadBufferMs: 500,
    onlyLogs: false, // if set to true will not group logs by component.
    collapseGroups: false,
    titleColor: '#058',
    diffNameColor: 'blue',
    diffPathColor: 'red',
    logOwnerReasons: true,
  })
}
