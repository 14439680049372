import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export const AvatarFragmentDoc = gql`
    fragment Avatar on User {
  id
  fullName
  avatarUrl
}
    `;
export const DocumentFragmentDoc = gql`
    fragment Document on Document {
  id
  url
  filename
  createdAt
  type
  tags
  category
  version
  name: filename
  size: byteSize
  hellosignTemplateId
  templateEditUrl
  templateEditUrlExpiresAt
  isTagged
}
    `;
export const CyberSubmissionInfoFragmentDoc = gql`
    fragment CyberSubmissionInfo on Submission {
  id
  name
  owner {
    id
    ...Avatar
    jobTitle
    company {
      id
      name
    }
  }
  ... on CyberSubmission {
    documents {
      id
      ...Document
    }
    updatedAt
    coverageStartDate
    websites
    hasNoWebsite
    naicsCode
    cyberboxxIndustryCode
    chubbIndustryCode
    phoneNumber
    country
    contactName
    email
    numberOfEmployees
    numberOfRecords
    annualRevenue
    limit
    deductible
    streetLine1
    streetLine2
    city
    state
    status
    zipCode
    message
    selectedPanelists
    multiFactorAuthentication
    additionalCarriers
    pdfExport {
      id
      filename
      url
    }
    questions
  }
}
    ${AvatarFragmentDoc}
${DocumentFragmentDoc}`;
export const CyberQuotationInfoFragmentDoc = gql`
    fragment CyberQuotationInfo on Quotation {
  id
  status
  documents {
    id
    ...Document
  }
  ambestRating {
    id
    rating
  }
  ... on CyberQuotation {
    type
    selected
    indication
    statusMessage
    admitted
    declinationReason
    deductible
    fee
    tax
    limit
    requestedLimit
    requestedDeductible
    policyNumber
    premium
    signingUrl
    surplusLinesTax
    totalCost
    extractedAt
    reviewedAt
    coverageStartDate
    underwriterFirstName
    underwriterLastName
    underwriterEmail
    coverageStartDate
    retailCommissionPercentage
    retailCommission
    endorsements {
      name
      limit
      coinsurance
    }
    additionalFeatures {
      title
      description
    }
    coverages {
      name
      type
      limit
      deductible
      waitingPeriod
    }
    externalCarrier {
      id
      name
    }
  }
}
    ${DocumentFragmentDoc}`;
export const CyberSubmissionFileCabinetFragmentDoc = gql`
    fragment CyberSubmissionFileCabinet on CyberSubmission {
  id
  name
  country
  owner {
    id
    fullName
  }
  documents {
    id
    ...Document
  }
  participations {
    nodes {
      id
      documents {
        id
        ...Document
      }
      user {
        id
        company {
          id
          name
        }
      }
      quotations {
        nodes {
          id
          documents {
            id
            ...Document
          }
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}`;
export const DiscussionParticipantsFragmentDoc = gql`
    fragment DiscussionParticipants on Discussion {
  id
  discussionParticipants {
    nodes {
      id
      lastReadMessageId
      unreadCount
      participant {
        id
      }
    }
  }
}
    `;
export const QuotedLayerInfoFragmentDoc = gql`
    fragment QuotedLayerInfo on QuotedLayer {
  id
  attachmentPoint
  cedingCommission
  deductible
  layerLimit
  netPremium
  note
  participation
  premium
  isIncomplete
  declinedAt
  keyPerils
  exclusions
  policyCoverage {
    id
  }
}
    `;
export const LatestRevisionFragmentDoc = gql`
    fragment LatestRevision on Quote {
  id
  status
  stage
  latestRevisionForEdit: revisions(last: 1) {
    nodes {
      id
      participation
      layerLimit
      attachmentPoint
      premium
      netPremium
      cedingCommission
      expiryDate
      deductible
      number
      keyPerils
      exclusions
      brokerageCommission
      subjectivities
      customFields {
        name
        type
        ... on StringField {
          value
        }
      }
      quotedLayers {
        id
        ...QuotedLayerInfo
      }
      source
      isIncomplete
      documents {
        nodes {
          id
          url
          type
          name: filename
          size: byteSize
        }
      }
    }
  }
}
    ${QuotedLayerInfoFragmentDoc}`;
export const AttachmentsFragmentDoc = gql`
    fragment Attachments on Message {
  id
  attachments {
    nodes {
      id
      ...Document
    }
  }
}
    ${DocumentFragmentDoc}`;
export const MessageFragmentDoc = gql`
    fragment Message on Message {
  id
  body
  subject
  timestamp
  author {
    id
    ...Avatar
  }
  ... on Broadcast {
    type
  }
  ...Attachments
}
    ${AvatarFragmentDoc}
${AttachmentsFragmentDoc}`;
export const MessagesFragmentDoc = gql`
    fragment Messages on Discussion {
  id
  messages(order: "DESC") {
    nodes {
      id
      ...Message
    }
  }
}
    ${MessageFragmentDoc}`;
export const MlQuotedLinesInfoFragmentDoc = gql`
    fragment MLQuotedLinesInfo on MLQuotation {
  id
  quotedLines {
    nodes {
      id
      type
      ... on DirectorsAndOfficersQuotedLineType {
        coverageName
        iconName
        quotedLineNames
        coverage {
          id
          type
        }
        directorsAndOfficers {
          limit
          retention
        }
        entityCoverage {
          limit
          retention
        }
        indemnifiableLoss {
          limit
          retention
        }
        nonIndemnifiableLoss {
          limit
          retention
        }
      }
      ... on EmploymentPracticesLiabilityQuotedLineType {
        coverageName
        iconName
        quotedLineNames
        coverage {
          id
          type
        }
        employmentPracticesLiability {
          limit
          retention
        }
        employee {
          limit
          retention
        }
        thirdParty {
          limit
          retention
        }
      }
      ... on FidelityCrimeQuotedLineType {
        coverageName
        iconName
        quotedLineNames
        coverage {
          id
          type
        }
        fidelityCrime {
          limit
          retention
        }
        employeeDishonesty {
          limit
          retention
        }
        onPremises {
          limit
          retention
        }
        inTransite {
          limit
          retention
        }
        forgeryOrAlteration {
          limit
          retention
        }
        counterfeitMoney {
          limit
          retention
        }
        computerSystem {
          limit
          retention
        }
        fundsTransferFraud {
          limit
          retention
        }
        impersonationFraud {
          limit
          retention
        }
      }
      ... on FiduciaryQuotedLineType {
        coverageName
        iconName
        quotedLineNames
        coverage {
          id
          type
        }
        fiduciary {
          limit
          retention
        }
      }
      ... on SpecialRiskQuotedLineType {
        coverageName
        iconName
        quotedLineNames
        coverage {
          id
          type
        }
        specialRisk {
          limit
          retention
        }
      }
    }
  }
}
    `;
export const MlQuotationInfoFragmentDoc = gql`
    fragment MlQuotationInfo on Quotation {
  id
  status
  documents {
    id
    ...Document
  }
  ... on MLQuotation {
    id
    type
    indication
    admitted
    currentPolicy
    declinationReason
    status
    annualPremium
    selected
    policyNumber
    retailCommissionPercentage
    retailCommission
    subjectivitiesAttributes {
      text
    }
    ...MLQuotedLinesInfo
  }
}
    ${DocumentFragmentDoc}
${MlQuotedLinesInfoFragmentDoc}`;
export const MlProductInfoFragmentDoc = gql`
    fragment MLProductInfo on ManagementLiabilityProduct {
  id
  number_of_employees
  annual_revenue
  fee_based_account
  first_time_buyer
  continuity_date
  effective_date
  coverages {
    id
    type
    ... on ManagementLiabilityDirectorsAndOfficersCoverage {
      limit
      deductible
      request_higher_limit
      name
    }
    ... on ManagementLiabilityEmploymentPracticesLiabilityCoverage {
      limit
      deductible
      request_higher_limit
      name
    }
    ... on ManagementLiabilityFidelityCrimeCoverage {
      limit
      deductible
      request_higher_limit
      name
    }
    ... on ManagementLiabilityFiduciaryCoverage {
      limit
      deductible
      request_higher_limit
      name
    }
    ... on ManagementLiabilitySpecialRiskCoverage {
      limit
      deductible
      request_higher_limit
      name
    }
  }
}
    `;
export const MlSubmissionInfoFragmentDoc = gql`
    fragment MlSubmissionInfo on PanelSubmission {
  id
  name
  status
  panel_info
  updatedAt
  owner {
    id
    ...Avatar
    jobTitle
    company {
      id
      name
    }
  }
  panel {
    id
    lineOfBusiness
  }
  applicant {
    id
    company_name
    name
    email
    phone_number
    naics_code
    address {
      id
      street_line1
      street_line2
      city
      state
      zip_code
    }
  }
  email_carriers {
    id
  }
  documents {
    id
    name: filename
    url
    type
    size: byteSize
  }
  message
  products {
    id
    panel {
      id
      lineOfBusiness
    }
    status
    quotations {
      id
      ...MlQuotationInfo
    }
    ...MLProductInfo
  }
  participations {
    nodes {
      id
      documents {
        id
        ...Document
      }
      externalCarrier {
        id
        name
      }
      user {
        id
        fullName
        email
        firstName
        lastName
        company {
          id
          name
          logoUrl
        }
      }
      collaborator {
        id
        firstName
        api
        company {
          id
          name
          logoUrl
          ambestRating {
            id
            rating
          }
        }
      }
      discussion {
        id
        ...Messages
      }
      quotations {
        nodes {
          id
          ...MlQuotationInfo
          ... on MLQuotation {
            id
            participation {
              id
              externalCarrier {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    ${AvatarFragmentDoc}
${MlQuotationInfoFragmentDoc}
${MlProductInfoFragmentDoc}
${DocumentFragmentDoc}
${MessagesFragmentDoc}`;
export const MlParticipationFragmentDoc = gql`
    fragment MLParticipation on Participation {
  id
  documents {
    id
    ...Document
  }
  owner {
    id
    fullName
  }
  user {
    id
    fullName
  }
  discussion {
    id
    ...Messages
  }
  submission {
    id
    ...MlSubmissionInfo
  }
  quotations {
    nodes {
      id
      ...MlQuotationInfo
    }
  }
}
    ${DocumentFragmentDoc}
${MessagesFragmentDoc}
${MlSubmissionInfoFragmentDoc}
${MlQuotationInfoFragmentDoc}`;
export const NetworkContactFragmentDoc = gql`
    fragment NetworkContact on Account {
  id
  fullName
  firstName
  email
  lastName
  jobTitle
  avatarUrl
  companyName
}
    `;
export const PanelsSubmissionFileCabinetFragmentDoc = gql`
    fragment PanelsSubmissionFileCabinet on PanelSubmission {
  id
  name
  documents {
    id
    ...Document
  }
  participations {
    nodes {
      id
      documents {
        id
        ...Document
      }
      user {
        id
        company {
          id
          name
        }
      }
      quotations {
        nodes {
          id
          documents {
            id
            ...Document
          }
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}`;
export const CyberQuotedLinesInfoFragmentDoc = gql`
    fragment CyberQuotedLinesInfo on CyberQuotation {
  id
  quotedLines {
    nodes {
      id
      type
      ... on CyberQuotedLineType {
        amountCommission
        annualPremium
        clientCost
        retailCommission
        retailCommissionPercentage
        totalCommissionPercentage
        wholesalerCommission
        wholesalerCommissionPercentage
        policyNumber
      }
    }
  }
}
    `;
export const TerrorismQuotedLinesInfoFragmentDoc = gql`
    fragment TerrorismQuotedLinesInfo on TerrorismQuotation {
  id
  quotedLines {
    nodes {
      id
      type
      ... on TerrorismQuotedLineType {
        coverage {
          id
          type
        }
        annualPremium
        maximumCommissionPercentage
        retailCommissionPercentage
        wholesalerCommissionPercentage
        totalCommissionPercentage
        retailCommission
        wholesalerCommission
        clientCost
        policyNumber
        taxAmount
        coverageStartDate
      }
    }
  }
}
    `;
export const PanelQuotationInfoFragmentDoc = gql`
    fragment PanelQuotationInfo on Quotation {
  id
  status
  documents {
    id
    ...Document
  }
  participation {
    id
    discussion {
      id
      participants {
        nodes {
          id
        }
      }
    }
    user {
      id
      firstName
      lastName
      company {
        id
        name
      }
    }
  }
  ... on CyberQuotation {
    id
    type
    selected
    admitted
    indication
    declinationReason
    status
    statusMessage
    deductible
    fee
    limit
    requestedLimit
    requestedDeductible
    policyNumber
    premium
    signingUrl
    surplusLinesTax
    totalCost
    extractedAt
    reviewedAt
    underwriterFirstName
    underwriterLastName
    underwriterEmail
    coverageStartDate
    retailCommissionPercentage
    retailCommission
    endorsements {
      name
      limit
      coinsurance
    }
    additionalFeatures {
      title
      description
    }
    coverages {
      name
      type
      limit
      deductible
      waitingPeriod
    }
    externalCarrier {
      id
      name
    }
    ...CyberQuotedLinesInfo
  }
  ... on TerrorismQuotation {
    id
    type
    declinationReason
    status
    admitted
    selected
    requestedLimit
    requestedDeductible
    limit
    deductible
    underwriterFirstName
    underwriterLastName
    underwriterEmail
    insuringAgreements {
      name
      value
    }
    ...TerrorismQuotedLinesInfo
  }
  ...MlQuotationInfo
  ... on BOPQuotation {
    id
    carrierName
    generalLiabilityPremium
    overallPropertyPremium
    status
    generalLiability
    property
    dynamic
    disclaimer
    portalUrl
  }
}
    ${DocumentFragmentDoc}
${CyberQuotedLinesInfoFragmentDoc}
${TerrorismQuotedLinesInfoFragmentDoc}
${MlQuotationInfoFragmentDoc}`;
export const PanelSubmissionInfoFragmentDoc = gql`
    fragment PanelSubmissionInfo on PanelSubmission {
  id
  name
  status
  panel_info
  updatedAt
  owner {
    id
    ...Avatar
    jobTitle
    company {
      id
      name
    }
  }
  panel {
    id
    lineOfBusiness
    configuration
    displayTax
  }
  collaborations {
    nodes {
      id
      type
      user {
        id
      }
      discussion {
        id
        participants {
          nodes {
            id
          }
        }
        ...Messages
      }
    }
  }
  applicant {
    id
    company_name
    naics_code
    name
    email
    phone_number
    address {
      id
      street_line1
      street_line2
      city
      state
      zip_code
    }
  }
  surplusLinesLicenseInfo {
    address {
      street_line
      state
      city
      zip_code
    }
    expiration_date
    license_holder
    license_number
    state
  }
  email_carriers {
    id
  }
  ...PanelsSubmissionFileCabinet
  message
  products {
    id
    panel {
      id
      lineOfBusiness
      configuration
      panelistConfigurations {
        nodes {
          panelist {
            id
            firstName
            mfaCorporateEmailRequired
            mfaCorporateNetworksRequired
            mfaRemoteNetworkAccessRequired
            websiteOptional
            indicationProvided
            lastName
            avatarUrl
            type
            company {
              id
              logoUrl
              name
              ambestRating {
                id
                rating
              }
            }
          }
          carrierAuthentications {
            nodes {
              id
              nickname
              default
            }
          }
          preferred
          indicationOnly
        }
      }
    }
    status
    quotations {
      id
      ...PanelQuotationInfo
    }
    ... on CyberProduct {
      limit
      website
      deductible
      annual_revenue
      number_of_records
      selected_panelists
      coverage_start_date
      number_of_employees
    }
    ... on BOPProduct {
      selectedPanelists: selected_panelists {
        id
        auth_id
      }
      step
      ivans_basic_appetite
      ivans_enhanced_appetite
      basic_appetite: basic_appetite_data
      enhanced_appetite: enhanced_appetite_data
      form_current_state
      skip_basic_appetite_capture
      basic_appetite_capture_error {
        title
        subtitle
        errors
        message
      }
      enhanced_appetite_error {
        title
        subtitle
        errors
        message
      }
    }
    ... on TerrorismProduct {
      carriers
      occupancy_type
      additional_active_assailant
      coverage_start_date
      coverage_end_date
      deductible
      general_liability
      general_liability_limit
      add_policy_limit
      limit
      occupancy_type
      target_price
      history {
        number_of_previous_losses
        previous_losses
        previous_threat
        total_loss_value
      }
      locations {
        armed_guards
        business_interruption_limit
        city
        contents_stock
        crash_barrier_perimeter_fence
        property_damage_limit
        state
        street_number_and_name
        zip_code
      }
    }
    ...MLProductInfo
  }
  participations {
    nodes {
      id
      documents {
        id
        ...Document
      }
      externalCarrier {
        id
        name
      }
      user {
        id
        fullName
        email
        firstName
        lastName
        company {
          id
          name
          logoUrl
          ambestRating {
            id
            rating
          }
        }
      }
      collaborator {
        id
        firstName
        api
        company {
          id
          name
          logoUrl
          ambestRating {
            id
            rating
          }
        }
      }
      discussion {
        id
        ...Messages
      }
      quotations {
        nodes {
          id
          ...PanelQuotationInfo
        }
      }
    }
  }
}
    ${AvatarFragmentDoc}
${MessagesFragmentDoc}
${PanelsSubmissionFileCabinetFragmentDoc}
${PanelQuotationInfoFragmentDoc}
${MlProductInfoFragmentDoc}
${DocumentFragmentDoc}`;
export const DocumentForFormFragmentDoc = gql`
    fragment DocumentForForm on Document {
  id
  name: filename
  url
  type
  size: byteSize
  tags
  createdAt
  category
  version
  source
}
    `;
export const PcgSubmissionInfoFragmentDoc = gql`
    fragment PCGSubmissionInfo on PCGSubmission {
  id
  name
  lineOfBusiness
  status
  coverages {
    id
    type
    ... on PCGHomeCoverageType {
      address {
        id
        fullAddress
        streetLine1
      }
    }
    ... on PCGAutoCoverageType {
      vehicles {
        year
        make
        model
      }
    }
  }
  owner {
    id
    ...Avatar
    jobTitle
    company {
      id
      name
    }
  }
  participations {
    nodes {
      id
      createdAt
      user {
        id
        firstName
        email
        company {
          id
          name
          logoUrl
        }
      }
      discussion {
        id
        ...Messages
      }
      quotations {
        nodes {
          id
          status
          selected
          documents {
            id
            category
            version
            ...DocumentForForm
          }
          ... on PCGQuotation {
            type
            declinationReason
            currentPolicy
            totalPremium
            externalCarrier: carrier {
              id
              name
              logoUrl
            }
            quotedLines {
              nodes {
                id
                coverableId
                ... on PCGValuablesQuotedLineType {
                  coverages {
                    name
                    limit
                    blanketLimit
                  }
                  additionalFeatures {
                    feature
                    included
                  }
                  totalPremium
                }
                ... on PCGHomeQuotedLineType {
                  totalPremium
                  coverages {
                    coverage
                    homeCoverageLimit {
                      value
                      type
                    }
                    deductible {
                      value
                      type
                    }
                  }
                  allOtherPerilDeductible
                  additionalFeatures {
                    feature
                    included
                  }
                  additionalOptions {
                    option
                    deductible {
                      value
                      type
                    }
                    premium {
                      value
                      type
                    }
                  }
                  enhancements {
                    enhancement
                    limit {
                      value
                      type
                    }
                    deductible {
                      value
                      type
                    }
                    premium {
                      value
                      type
                    }
                  }
                  address {
                    id
                    fullAddress
                  }
                }
                ... on PCGExcessQuotedLineType {
                  coverages {
                    coverage
                    limit
                  }
                  totalPremium
                  limitOptions {
                    coverage
                    limit
                    totalPremium
                  }
                }
                ... on PCGAutoQuotedLineType {
                  agreedValues {
                    vehicle
                    value
                  }
                  combinedLimit
                  bodilyInjuryAndPropertyDamage
                  bodilyInjuryPerPerson
                  bodilyInjuryEachAccident
                  propertyDamage
                  medicalPayments {
                    value
                    type
                  }
                  underinsuredMotorists {
                    value
                    type
                  }
                  extendedTowingAndLabor {
                    value
                    type
                  }
                  fullGlassCoverage {
                    value
                    type
                  }
                  collisionDeductible {
                    value
                    type
                  }
                  comprehensiveDeductible {
                    value
                    type
                  }
                  totalPremium
                  additionalCoverages {
                    name
                    limit {
                      value
                      type
                    }
                    premium
                  }
                  additionalFeatures {
                    feature
                    included
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${AvatarFragmentDoc}
${MessagesFragmentDoc}
${DocumentForFormFragmentDoc}`;
export const PermissionsFragmentDoc = gql`
    fragment Permissions on User {
  id
  permissions {
    nodes {
      id
      area
      action
      metadata
    }
  }
}
    `;
export const ProfileFragmentDoc = gql`
    fragment Profile on Account {
  id
  firstName
  fullName
  jobTitle
  avatarUrl
  email
  phoneNumber
  linkedinUrl
  aboutMe
  bannerUrl
  locations
  linesOfBusiness
  industries
  companyName
}
    `;
export const QuestionInfoFragmentDoc = gql`
    fragment QuestionInfo on Question {
  id
  text
  deadline
  section
  number
  documents {
    id
    category
    version
    ...DocumentForForm
  }
  answer {
    id
    text
    releasedAt
    acceptedAt
    documents {
      id
      category
      version
      ...DocumentForForm
    }
  }
}
    ${DocumentForFormFragmentDoc}`;
export const QAndAFragmentDoc = gql`
    fragment QAndA on Questionable {
  id
  questions {
    nodes {
      id
      ...QuestionInfo
    }
  }
}
    ${QuestionInfoFragmentDoc}`;
export const SubjectivityInfoFragmentDoc = gql`
    fragment SubjectivityInfo on Subjectivity {
  id
  text
  deadline
  section
  number
  documents {
    id
    category
    version
    ...DocumentForForm
  }
  answer {
    id
    text
    acceptedAt
    documents {
      id
      category
      version
      ...DocumentForForm
    }
  }
}
    ${DocumentForFormFragmentDoc}`;
export const EndorsementInfoFragmentDoc = gql`
    fragment EndorsementInfo on Endorsement {
  id
  name
  reason
  kind
  status
  reinsuranceEffectiveDate
  reinsuranceExpiryDate
  endorsementStartDate
  endorsementEndDate
  identifierNumber
  perils
  exclusions
  namedInsured
  totalInsuredValue
  createdAt
  documents {
    nodes {
      id
      ...Document
    }
  }
  insurancePolicy {
    id
    number
    effectiveDate
    expiryDate
    coverages {
      nodes {
        id
        limit
      }
    }
  }
  endorsementQuotes {
    id
    status
    additionalPremium
    returnPremium
    schemas {
      sentEndorsementQuote
      requestedEndorsementQuote
    }
    documents {
      nodes {
        id
        ...Document
      }
    }
    quote {
      id
      reinsurer {
        id
        fullName
      }
      revisions(last: 1) {
        nodes {
          id
          layerLimit
          attachmentPoint
          participation
          quotedLayers {
            id
            ...QuotedLayerInfo
          }
        }
      }
      reinsuranceRequests {
        nodes {
          id
          layerText
        }
      }
      discussion {
        id
        messages {
          nodes {
            id
          }
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}
${QuotedLayerInfoFragmentDoc}`;
export const TowerFragmentDoc = gql`
    fragment Tower on Worksheet {
  id
  perOccurrenceLimit
  insurancePolicy {
    id
    coverages {
      nodes {
        id
        kind
        type
        limit
        aggregateLimit
        perOccurrenceLimit
        deductible
        description
        comment
        lines {
          id
          attachmentPoint
          cedingCommission
          deductible
          layerLimit
          netPremium
          note
          participation
          premium
        }
        customFields {
          name
          type
          ... on StringField {
            value
          }
        }
        ... on CasualtyCoverage {
          basis
          extendedReportingPeriod
          retroactiveDate
          keyPerils
          exclusions
        }
        ... on PropertyCoverage {
          keyPerils
          exclusions
          insuredPropertyMajorTypes
          majorCatastropheExposures
          statementsOfValues {
            valueEntries {
              cover
              value
            }
            perils
            excludedPerils
          }
          sublimits {
            peril
            limit
          }
        }
      }
    }
  }
  unavailableLayers {
    nodes {
      id
      layerLimit
      attachmentPoint
      participation
    }
  }
  reinsuranceRequests {
    nodes {
      id
      participation
      attachmentPoint
      layerLimit
    }
  }
  kind
}
    `;
export const TowerSummaryFragmentDoc = gql`
    fragment TowerSummary on Worksheet {
  id
  description
}
    `;
export const LayerFragmentDoc = gql`
    fragment Layer on Quote {
  id
  stage
  reinsurer {
    id
  }
  latestRevision: revisions(last: 1) {
    nodes {
      id
      participation
      layerLimit
      attachmentPoint
      isIncomplete
      source
      brokerageCommission
      subjectivities
      quotedLayers {
        id
        ...QuotedLayerInfo
      }
    }
  }
  reinsuranceRequests {
    nodes {
      id
      participation
      attachmentPoint
      layerLimit
      layerCoverage {
        ... on Worksheet {
          id
        }
        ... on PropertyCoverage {
          id
        }
        ... on CasualtyCoverage {
          id
        }
      }
    }
  }
}
    ${QuotedLayerInfoFragmentDoc}`;
export const WorksheetQuoteInformationDocumentsFragmentDoc = gql`
    fragment WorksheetQuoteInformationDocuments on Quote {
  id
  discussion {
    id
    cedentMessages: messages(authorType: Cedent) {
      nodes {
        id
        ...Attachments
        documents {
          nodes {
            id
            ...Document
          }
        }
      }
    }
  }
  binderRequest {
    nodes {
      id
      ...Document
    }
  }
  policyRequest {
    nodes {
      id
      ...Document
    }
  }
}
    ${AttachmentsFragmentDoc}
${DocumentFragmentDoc}`;
export const RevisionPerilsFragmentDoc = gql`
    fragment RevisionPerils on Quote {
  id
  revisionPerils: revisions(last: 1) {
    nodes {
      id
      keyPerils
      exclusions
      quotedLayers {
        id
        ...QuotedLayerInfo
      }
    }
  }
}
    ${QuotedLayerInfoFragmentDoc}`;
export const WorksheetInformationDocumentsFragmentDoc = gql`
    fragment WorksheetInformationDocuments on Worksheet {
  id
  documents {
    nodes {
      id
      ...Document
    }
  }
}
    ${DocumentFragmentDoc}`;
export const CedentQuoteActionsFragmentDoc = gql`
    fragment CedentQuoteActions on Quote {
  id
  status
  readableStatus: status(readable: true)
  binder {
    nodes {
      id
      ...Document
    }
  }
  certificate {
    nodes {
      id
      ...Document
    }
  }
}
    ${DocumentFragmentDoc}`;
export const QuoteInfoFragmentFragmentDoc = gql`
    fragment QuoteInfoFragment on Quote {
  id
  status
  readableStatus: status(readable: true)
  releasedAt
  brokerComment
  companyName
  outdatedAt
  certificateNumber
  providerLastViewedAt
  manuallyTracked
  ...Layer
  ...WorksheetQuoteInformationDocuments
  ...RevisionPerils
  reinsurer {
    id
    fullName
    companyName
    ...Avatar
  }
  discussion {
    id
    ...Messages
    reinsurerMessages: messages(authorType: Reinsurer) {
      nodes {
        id
        ...Attachments
        documents {
          nodes {
            id
            ...Document
          }
        }
      }
    }
  }
  revisions(last: 1) {
    nodes {
      id
      attachmentPoint
      brokerageCommission
      subjectivities
      cedingCommission
      deductible
      exclusions
      expiryDate
      isIncomplete
      keyPerils
      layerLimit
      netPremium
      number
      participation
      premium
      customFields {
        name
        type
        ... on StringField {
          value
        }
      }
      source
      status
      quotedLayers {
        id
        ...QuotedLayerInfo
      }
      documents {
        nodes {
          id
          ...Document
        }
      }
      zip {
        id
        url
      }
    }
  }
  cedentZip {
    id
    url
  }
  reinsurerZip {
    id
    url
  }
  owner {
    id
    fullName
    company {
      id
      name
      defaultBrand
      brandColor
      brandName
      logoUrl
    }
  }
  worksheet {
    id
    name
    demo
    kind
    address
    namedInsured
    industryType
    lineOfBusiness
    clientName
    showTower
    certificateNumber
    perOccurrenceLimit
    isBrokered
    lossHistory
    cope
    basisOfAcceptance
    source
    totalInsuredValue
    underwriterName
    reinsuranceEffectiveDate
    reinsuranceExpiryDate
    keyPerils
    exclusions
    updatedAt
    updatedFields {
      name {
        updatedAt
        oldValue
      }
      address {
        updatedAt
        oldValue
      }
      namedInsured {
        updatedAt
        oldValue
      }
      underwriterName {
        updatedAt
        oldValue
      }
      certificateNumber {
        updatedAt
        oldValue
      }
      clientName {
        updatedAt
        oldValue
      }
      description {
        updatedAt
        oldValue
      }
      industryType {
        updatedAt
        oldValue
      }
      customFields {
        updatedAt
        oldValue
      }
      insurancePolicy {
        expiryDate {
          updatedAt
          oldValue
        }
        effectiveDate {
          updatedAt
          oldValue
        }
      }
      participationComment {
        updatedAt
        oldValue
      }
      participationSubject {
        updatedAt
        oldValue
      }
      reinsuranceEffectiveDate {
        updatedAt
        oldValue
      }
      reinsuranceExpiryDate {
        updatedAt
        oldValue
      }
      lossHistory {
        updatedAt
        oldValue
      }
      cope {
        updatedAt
        oldValue
      }
      basisOfAcceptance {
        updatedAt
        oldValue
      }
      source {
        updatedAt
        oldValue
      }
      showTower {
        updatedAt
        oldValue
      }
      riskDocuments {
        updatedAt
        oldValue
      }
    }
    insurancePolicy {
      id
      number
      expiryDate
      effectiveDate
      coverages {
        nodes {
          id
          type
        }
      }
    }
    owner {
      id
      avatarUrl
      companyName
      fullName
      type
    }
    broadcasts {
      nodes {
        id
        ...Message
      }
    }
    ...WorksheetInformationDocuments
    ...Tower
    ...TowerSummary
  }
  appendices {
    nodes {
      id
      ...Document
    }
  }
  binder {
    nodes {
      id
      ...Document
    }
  }
  binderDraft {
    id
    ...Document
  }
  binderWordDraft {
    id
    ...Document
  }
  mergedBinder {
    id
    ...Document
  }
  certificateDraft {
    id
    ...Document
  }
  certificateWordDraft {
    id
    ...Document
  }
  referenceNumber
  certificate {
    nodes {
      id
      ...Document
    }
  }
  ...CedentQuoteActions
}
    ${LayerFragmentDoc}
${WorksheetQuoteInformationDocumentsFragmentDoc}
${RevisionPerilsFragmentDoc}
${AvatarFragmentDoc}
${MessagesFragmentDoc}
${AttachmentsFragmentDoc}
${DocumentFragmentDoc}
${QuotedLayerInfoFragmentDoc}
${MessageFragmentDoc}
${WorksheetInformationDocumentsFragmentDoc}
${TowerFragmentDoc}
${TowerSummaryFragmentDoc}
${CedentQuoteActionsFragmentDoc}`;
export const SubmissionInfoFragmentDoc = gql`
    fragment SubmissionInfo on Worksheet {
  id
  name
  namedInsured
  namedInsuredAddress
  address
  locationDescription
  status
  currency
  issuingCompany
  description
  coverageType
  contractType
  industryType
  kind
  lineOfBusiness
  showTower
  lossHistory
  cope
  basisOfAcceptance
  source
  companyRetention
  policyType
  policyLinesOfBusiness
  status
  reinsuranceEffectiveDate
  demo
  reinsuranceExpiryDate
  isRenewal
  totalInsuredValue
  isQuotable
  keyPerils
  exclusions
  isBrokered
  brokeredOrInvited
  brokerInvitationStatus
  participationSubject
  participationComment
  insuredPropertyMajorTypes
  majorCatastropheExposures
  multiTower
  canEndorse
  commissionPercentage
  clientName
  underwriterName
  address
  certificateNumber
  internalReferenceNumber
  needByDate
  schemas {
    autoLiabilityUnderwritingLoss
    endorsement
  }
  statementsOfValues {
    valueEntries {
      cover
      value
    }
    perils
    excludedPerils
  }
  sublimits {
    peril
    limit
  }
  customFields {
    name
    type
    ... on StringField {
      value
    }
  }
  client {
    id
  }
  owner {
    id
    type
    emailSignOff
    company {
      id
      name
      logoUrl
    }
    ...Avatar
  }
  discussion {
    id
    ...Messages
  }
  documents {
    nodes {
      id
      ...Document
    }
  }
  collaborations {
    id
    status
    type
    user {
      id
    }
  }
  permissions {
    edit
    manage
  }
  insurancePolicy {
    id
    number
    insurer
    description
    effectiveDate
    expiryDate
    premium
    selfInsuredRetention
    perOccurrenceLimit
    underlyingPolicies {
      nodes {
        id
        number
        description
        perOccurrenceLimit
        coverages {
          nodes {
            id
            kind
            type
            limit
            aggregateLimit
            perOccurrenceLimit
            deductible
            stringDeductible
            description
            comment
            documents {
              nodes {
                id
                name: filename
                url
                type
                size: byteSize
              }
            }
            lines {
              id
              attachmentPoint
              cedingCommission
              deductible
              layerLimit
              netPremium
              note
              participation
              premium
            }
            customFields {
              name
              type
              ... on StringField {
                value
              }
            }
            ... on CasualtyCoverage {
              basis
              extendedReportingPeriod
              retroactiveDate
              keyPerils
              exclusions
              underwriting {
                id
                garaging
                radiusLocal
                radiusIntermediate
                radiusLongHaul
                additionalInformation
                hiredNonOwnedExposure
                excessOfAmount
                currentExposureTotal
                otherCurrentExposureTotal
                currentExposure {
                  ppt
                  heavy
                  xHeavy
                  light
                  tractors
                  other
                  vans
                  buses
                }
                historicalExposure {
                  year
                  powerUnits
                }
                largeLosses {
                  dateOfLoss
                  totalIncurred
                  status
                  description
                }
                lossInformation {
                  year
                  numberOfClaims
                  totalIncurred
                  totalPaid
                  valuationDate
                }
              }
            }
            ... on PropertyCoverage {
              keyPerils
              exclusions
              insuredPropertyMajorTypes
              majorCatastropheExposures
              statementsOfValues {
                valueEntries {
                  cover
                  value
                }
                perils
                excludedPerils
              }
              sublimits {
                peril
                limit
              }
            }
          }
        }
      }
    }
    coverages {
      nodes {
        id
        kind
        type
        limit
        aggregateLimit
        perOccurrenceLimit
        deductible
        description
        comment
        documents {
          nodes {
            id
            name: filename
            url
            type
            size: byteSize
          }
        }
        lines {
          id
          attachmentPoint
          cedingCommission
          deductible
          layerLimit
          netPremium
          note
          participation
          premium
          quotes {
            nodes {
              id
              reinsurer {
                id
              }
            }
          }
        }
        keyPerils
        exclusions
        customFields {
          name
          type
          ... on StringField {
            value
          }
        }
        ... on CasualtyCoverage {
          basis
          extendedReportingPeriod
          retroactiveDate
          keyPerils
          exclusions
          underwriting {
            id
            garaging
            radiusLocal
            radiusIntermediate
            radiusLongHaul
            additionalInformation
            hiredNonOwnedExposure
            excessOfAmount
            currentExposure {
              ppt
              heavy
              xHeavy
              light
              tractors
            }
            historicalExposure {
              year
              powerUnits
            }
            largeLosses {
              dateOfLoss
              totalIncurred
              status
              description
            }
            lossInformation {
              year
              numberOfClaims
              totalIncurred
              totalPaid
              valuationDate
            }
          }
        }
        ... on PropertyCoverage {
          insuredPropertyMajorTypes
          majorCatastropheExposures
          statementsOfValues {
            valueEntries {
              cover
              value
            }
            perils
            excludedPerils
          }
          sublimits {
            peril
            limit
          }
        }
      }
    }
  }
  endorsements {
    id
    ...EndorsementInfo
  }
  stages {
    draft
    issued
  }
  ...Tower
  ...TowerSummary
  quotes {
    nodes {
      id
      ...QuoteInfoFragment
    }
  }
  quotesForTower: quotes {
    nodes {
      id
      ...Layer
    }
  }
}
    ${AvatarFragmentDoc}
${MessagesFragmentDoc}
${DocumentFragmentDoc}
${EndorsementInfoFragmentDoc}
${TowerFragmentDoc}
${TowerSummaryFragmentDoc}
${QuoteInfoFragmentFragmentDoc}
${LayerFragmentDoc}`;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on User {
  id
  uuid
  type
  chatToken
  fullName
  firstName
  lastName
  email
  avatarUrl
  bannerUrl
  emailSignOff
  generalInbox
  manager {
    id
  }
  features
  jobTitle
  phoneNumber
  onboarding
  pendingInvitation
  termsAndConditionsAcceptedAt
  ... on Cedent {
    demoData
    placementsAvailable
  }
  ... on Broker {
    placementsAvailable
    demoData
  }
  notificationSettings {
    mySubmissions {
      sentConfirmations
      received
      declined
      referred
      bound
      issued
    }
    retailerSubmissions {
      sentConfirmations
      received
      declined
      referred
      bound
      issued
    }
  }
  company {
    id
    name
    type
    verifiedAt
    inInsurance
    defaultBrand
    brandColor
    brandName
    emailLogoUrl
    logoUrl
    defaultAttachment
    guest
    kind
    userType
    supportEmail
    dealVisibility
    subscription {
      id
      plan
      featureLevel
      trialDaysRemaining
      seatsRemaining
      period
    }
    incomingEmailAddress
    panels {
      nodes {
        id
        lineOfBusiness
        name
        configuration
        displayTax
      }
    }
    pdfTemplates {
      nodes {
        id
        name
        tags
      }
    }
  }
}
    `;
export const WorksheetInfoFragmentDoc = gql`
    fragment WorksheetInfo on Worksheet {
  id
  name
  demo
  currency
  multiTower
  owner {
    id
    company {
      id
    }
  }
}
    `;
export const QuoteMessagesSectionFragmentDoc = gql`
    fragment QuoteMessagesSection on Quote {
  id
  discussion {
    id
    ...Messages
  }
  worksheet {
    id
    broadcasts {
      nodes {
        id
        ...Message
      }
    }
  }
}
    ${MessagesFragmentDoc}
${MessageFragmentDoc}`;
export const DashboardInfoFragmentDoc = gql`
    fragment DashboardInfo on BrokerDashboardEntry {
  id
  type
  ownerName
  ownerId
  ownerCompanyId
  ownerCompanyName
  name
  submission {
    id
    owner {
      id
      company {
        id
        name
      }
    }
    ... on CyberSubmission {
      owner {
        id
        company {
          id
          name
        }
      }
      panel {
        id
      }
      collaborations {
        nodes {
          id
          type
          user {
            id
          }
        }
      }
    }
    ... on PanelSubmission {
      products {
        id
        ... on CyberProduct {
          limit
          coverage_start_date
        }
        ... on TerrorismProduct {
          limit
          coverage_start_date
          totalInsuredValue
        }
      }
      owner {
        id
        company {
          id
          name
        }
      }
      panel {
        id
      }
      collaborations {
        nodes {
          id
          type
          user {
            id
          }
        }
      }
    }
    ... on PCGSubmission {
      owner {
        id
        company {
          id
          name
        }
      }
      panel {
        id
      }
    }
  }
  clientCompanyId
  clientCompanyName
  lineOfBusiness
  effectiveDate
  keyPerils
  policyLimit
  demo
  status
  isInvited
  isBrokered
  industryType
  receivedAt
  isRenewal
}
    `;
export const WorksheetCellFragmentDoc = gql`
    fragment WorksheetCell on Worksheet {
  id
  createdAt
  name
  status
  issuingCompany
  brokeredOrInvited
  isBrokered
  demo
  kind
  policyLimit
  perOccurrenceLimit
  totalReinsuranceRequested
  lineOfBusiness
  collaborators {
    id
    fullName
  }
  reinsurerQuote: quotes(last: 1) {
    nodes {
      id
      latestRevision: revisions(last: 1) {
        nodes {
          id
          status
          layerLimit
          participation
          quotedLayers {
            id
            layerLimit
            participation
          }
        }
      }
    }
  }
}
    `;
export const CedingCompanyCellFragmentDoc = gql`
    fragment CedingCompanyCell on Worksheet {
  id
  owner {
    id
    fullName
    companyName
  }
}
    `;
export const WorksheetStatusCellFragmentDoc = gql`
    fragment WorksheetStatusCell on Worksheet {
  id
  stages {
    draft
    submitted
    quoted
    bound
    issued
  }
}
    `;
export const ParticipatingMarketsCellFragmentDoc = gql`
    fragment ParticipatingMarketsCell on Worksheet {
  id
  quotes {
    totalCount
  }
  quoted: quotes(group: "quoted") {
    totalCount
  }
  bound: quotes(group: "bound") {
    totalCount
  }
  issued: quotes(group: "issued") {
    totalCount
  }
}
    `;
export const ReinsuranceRemainingCellFragmentDoc = gql`
    fragment ReinsuranceRemainingCell on Worksheet {
  id
  reinsuranceRequests {
    nodes {
      id
      participation
      attachmentPoint
      layerLimit
    }
  }
  completedQuotes: quotes(groups: ["bound", "issued", "completed"]) {
    nodes {
      id
      latestRevision: revisions(last: 1) {
        nodes {
          id
          participation
          layerLimit
          attachmentPoint
          isIncomplete
          quotedLayers {
            id
            ...QuotedLayerInfo
          }
        }
      }
    }
  }
}
    ${QuotedLayerInfoFragmentDoc}`;
export const WorksheetExportFragmentDoc = gql`
    fragment WorksheetExport on Worksheet {
  id
  export {
    id
    url
    filename
    createdAt
  }
}
    `;
export const WorksheetFragmentFragmentDoc = gql`
    fragment WorksheetFragment on Worksheet {
  id
  name
  demo
  insurancePolicy {
    id
    insurer
  }
}
    `;
export const QuoteLockedFragmentDoc = gql`
    fragment QuoteLocked on Quote {
  id
  revisions(last: 1) {
    nodes {
      id
      number
      zip {
        id
        url
      }
    }
  }
  reinsurer {
    id
    fullName
  }
}
    `;
export const ReportSummaryDetailsFragmentDoc = gql`
    fragment ReportSummaryDetails on WorksheetConnection {
  nodes {
    id
    name
    owner {
      id
      fullName
    }
    insurancePolicy {
      id
      coverages {
        nodes {
          id
          type
        }
      }
    }
    completedQuotes: quotes(groups: ["bound", "issued", "completed"]) {
      nodes {
        id
        analysis {
          id
          premiumPerMillion
        }
        reinsurer {
          id
          fullName
          company {
            id
            name
            ambestRating {
              id
              rating
              naicCompanyNumber
            }
          }
        }
        averagePlacementTime
        averageResponseTime
        averageReplyRate
        latestRevision: revisions(last: 1) {
          nodes {
            id
            participation
            layerLimit
            attachmentPoint
            isIncomplete
            premium
            quotedLayers {
              id
              ...QuotedLayerInfo
            }
          }
        }
      }
    }
  }
}
    ${QuotedLayerInfoFragmentDoc}`;
export const WorksheetBrokerInfoFragmentDoc = gql`
    fragment WorksheetBrokerInfo on Worksheet {
  id
  isBrokered
  brokeredOrInvited
  owner {
    id
    fullName
    company {
      id
      name
    }
  }
  collaborations {
    id
    __typename
    user {
      id
      fullName
    }
  }
}
    `;
export const QuoteInformationPerilsFragmentDoc = gql`
    fragment QuoteInformationPerils on Quote {
  id
  worksheet {
    id
    lineOfBusiness
    keyPerils
    exclusions
    insurancePolicy {
      id
      coverages {
        nodes {
          id
          type
        }
      }
    }
  }
  revisionPerils: revisions(last: 1) {
    nodes {
      id
      keyPerils
      exclusions
      quotedLayers {
        id
        ...QuotedLayerInfo
      }
    }
  }
}
    ${QuotedLayerInfoFragmentDoc}`;
export const QuotedQuoteFragmentDoc = gql`
    fragment QuotedQuote on Quote {
  id
  status
  stage
  worksheet {
    id
    policyLimit
    perOccurrenceLimit
  }
  reinsurer {
    id
    ...Avatar
    companyName
  }
  revisions(last: 1) {
    nodes {
      id
      layerLimit
      attachmentPoint
      participation
      premium
      netPremium
      expiryDate
      isIncomplete
      quotedLayers {
        id
        ...QuotedLayerInfo
      }
    }
  }
  ...QuoteInformationPerils
}
    ${AvatarFragmentDoc}
${QuotedLayerInfoFragmentDoc}
${QuoteInformationPerilsFragmentDoc}`;
export const WorksheetSummaryFragmentDoc = gql`
    fragment WorksheetSummary on Worksheet {
  id
  ...Tower
  ...TowerSummary
  quotesForTower: quotes {
    nodes {
      id
      ...Layer
    }
  }
  description
  policyNumber
  reinsuranceEffectiveDate
  reinsuranceExpiryDate
}
    ${TowerFragmentDoc}
${TowerSummaryFragmentDoc}
${LayerFragmentDoc}`;
export const AcceptQuoteDocument = gql`
    mutation acceptQuote($input: AcceptQuoteInput!) {
  acceptQuote(input: $input) {
    quote {
      id
      status
      readableStatus: status(readable: true)
      stage
      binder {
        nodes {
          id
          url
          filename
          createdAt
        }
      }
      certificate {
        nodes {
          id
          url
          filename
          createdAt
        }
      }
    }
  }
}
    `;
export type AcceptQuoteMutationFn = ApolloReactCommon.MutationFunction<AcceptQuoteMutation, AcceptQuoteMutationVariables>;
export type AcceptQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AcceptQuoteMutation, AcceptQuoteMutationVariables>
    } & TChildProps;
export function withAcceptQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AcceptQuoteMutation,
  AcceptQuoteMutationVariables,
  AcceptQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AcceptQuoteMutation, AcceptQuoteMutationVariables, AcceptQuoteProps<TChildProps, TDataName>>(AcceptQuoteDocument, {
      alias: 'acceptQuote',
      ...operationOptions
    });
};

/**
 * __useAcceptQuoteMutation__
 *
 * To run a mutation, you first call `useAcceptQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptQuoteMutation, { data, loading, error }] = useAcceptQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptQuoteMutation, AcceptQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptQuoteMutation, AcceptQuoteMutationVariables>(AcceptQuoteDocument, baseOptions);
      }
export type AcceptQuoteMutationHookResult = ReturnType<typeof useAcceptQuoteMutation>;
export type AcceptQuoteMutationResult = ApolloReactCommon.MutationResult<AcceptQuoteMutation>;
export type AcceptQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptQuoteMutation, AcceptQuoteMutationVariables>;
export const AcceptQuoteProposalDocument = gql`
    mutation acceptQuoteProposal($input: AcceptProposalInput!) {
  acceptProposal(input: $input) {
    quote {
      id
      status
      readableStatus: status(readable: true)
    }
  }
}
    `;
export type AcceptQuoteProposalMutationFn = ApolloReactCommon.MutationFunction<AcceptQuoteProposalMutation, AcceptQuoteProposalMutationVariables>;
export type AcceptQuoteProposalProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AcceptQuoteProposalMutation, AcceptQuoteProposalMutationVariables>
    } & TChildProps;
export function withAcceptQuoteProposal<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AcceptQuoteProposalMutation,
  AcceptQuoteProposalMutationVariables,
  AcceptQuoteProposalProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AcceptQuoteProposalMutation, AcceptQuoteProposalMutationVariables, AcceptQuoteProposalProps<TChildProps, TDataName>>(AcceptQuoteProposalDocument, {
      alias: 'acceptQuoteProposal',
      ...operationOptions
    });
};

/**
 * __useAcceptQuoteProposalMutation__
 *
 * To run a mutation, you first call `useAcceptQuoteProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptQuoteProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptQuoteProposalMutation, { data, loading, error }] = useAcceptQuoteProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptQuoteProposalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptQuoteProposalMutation, AcceptQuoteProposalMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptQuoteProposalMutation, AcceptQuoteProposalMutationVariables>(AcceptQuoteProposalDocument, baseOptions);
      }
export type AcceptQuoteProposalMutationHookResult = ReturnType<typeof useAcceptQuoteProposalMutation>;
export type AcceptQuoteProposalMutationResult = ApolloReactCommon.MutationResult<AcceptQuoteProposalMutation>;
export type AcceptQuoteProposalMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptQuoteProposalMutation, AcceptQuoteProposalMutationVariables>;
export const ApproveRevisionDocument = gql`
    mutation approveRevision($input: ApproveRevisionInput!) {
  approveRevision(input: $input) {
    revision {
      id
      status
      quote {
        id
        status
      }
    }
    errors
  }
}
    `;
export type ApproveRevisionMutationFn = ApolloReactCommon.MutationFunction<ApproveRevisionMutation, ApproveRevisionMutationVariables>;
export type ApproveRevisionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ApproveRevisionMutation, ApproveRevisionMutationVariables>
    } & TChildProps;
export function withApproveRevision<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ApproveRevisionMutation,
  ApproveRevisionMutationVariables,
  ApproveRevisionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ApproveRevisionMutation, ApproveRevisionMutationVariables, ApproveRevisionProps<TChildProps, TDataName>>(ApproveRevisionDocument, {
      alias: 'approveRevision',
      ...operationOptions
    });
};

/**
 * __useApproveRevisionMutation__
 *
 * To run a mutation, you first call `useApproveRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveRevisionMutation, { data, loading, error }] = useApproveRevisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveRevisionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveRevisionMutation, ApproveRevisionMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveRevisionMutation, ApproveRevisionMutationVariables>(ApproveRevisionDocument, baseOptions);
      }
export type ApproveRevisionMutationHookResult = ReturnType<typeof useApproveRevisionMutation>;
export type ApproveRevisionMutationResult = ApolloReactCommon.MutationResult<ApproveRevisionMutation>;
export type ApproveRevisionMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveRevisionMutation, ApproveRevisionMutationVariables>;
export const BopAppetiteDocument = gql`
    mutation bopAppetite($input: BOPAppetiteInput!, $submissionId: ID!) {
  bopAppetite(input: $input) {
    panels {
      id
      schemas {
        edit(submissionId: $submissionId)
      }
      submission(submissionId: $submissionId) {
        id
        ...PanelSubmissionInfo
      }
    }
    errors {
      base
    }
  }
}
    ${PanelSubmissionInfoFragmentDoc}`;
export type BopAppetiteMutationFn = ApolloReactCommon.MutationFunction<BopAppetiteMutation, BopAppetiteMutationVariables>;
export type BopAppetiteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BopAppetiteMutation, BopAppetiteMutationVariables>
    } & TChildProps;
export function withBopAppetite<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BopAppetiteMutation,
  BopAppetiteMutationVariables,
  BopAppetiteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BopAppetiteMutation, BopAppetiteMutationVariables, BopAppetiteProps<TChildProps, TDataName>>(BopAppetiteDocument, {
      alias: 'bopAppetite',
      ...operationOptions
    });
};

/**
 * __useBopAppetiteMutation__
 *
 * To run a mutation, you first call `useBopAppetiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBopAppetiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bopAppetiteMutation, { data, loading, error }] = useBopAppetiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useBopAppetiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BopAppetiteMutation, BopAppetiteMutationVariables>) {
        return ApolloReactHooks.useMutation<BopAppetiteMutation, BopAppetiteMutationVariables>(BopAppetiteDocument, baseOptions);
      }
export type BopAppetiteMutationHookResult = ReturnType<typeof useBopAppetiteMutation>;
export type BopAppetiteMutationResult = ApolloReactCommon.MutationResult<BopAppetiteMutation>;
export type BopAppetiteMutationOptions = ApolloReactCommon.BaseMutationOptions<BopAppetiteMutation, BopAppetiteMutationVariables>;
export const BopReturnPreviousStateDocument = gql`
    mutation bopReturnPreviousState($input: BOPReturnPreviousStateInput!, $submissionId: ID!) {
  bopReturnPreviousState(input: $input) {
    panels {
      id
      schemas {
        edit(submissionId: $submissionId)
      }
      submission(submissionId: $submissionId) {
        id
        ...PanelSubmissionInfo
      }
    }
    errors {
      base
    }
  }
}
    ${PanelSubmissionInfoFragmentDoc}`;
export type BopReturnPreviousStateMutationFn = ApolloReactCommon.MutationFunction<BopReturnPreviousStateMutation, BopReturnPreviousStateMutationVariables>;
export type BopReturnPreviousStateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<BopReturnPreviousStateMutation, BopReturnPreviousStateMutationVariables>
    } & TChildProps;
export function withBopReturnPreviousState<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BopReturnPreviousStateMutation,
  BopReturnPreviousStateMutationVariables,
  BopReturnPreviousStateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, BopReturnPreviousStateMutation, BopReturnPreviousStateMutationVariables, BopReturnPreviousStateProps<TChildProps, TDataName>>(BopReturnPreviousStateDocument, {
      alias: 'bopReturnPreviousState',
      ...operationOptions
    });
};

/**
 * __useBopReturnPreviousStateMutation__
 *
 * To run a mutation, you first call `useBopReturnPreviousStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBopReturnPreviousStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bopReturnPreviousStateMutation, { data, loading, error }] = useBopReturnPreviousStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useBopReturnPreviousStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BopReturnPreviousStateMutation, BopReturnPreviousStateMutationVariables>) {
        return ApolloReactHooks.useMutation<BopReturnPreviousStateMutation, BopReturnPreviousStateMutationVariables>(BopReturnPreviousStateDocument, baseOptions);
      }
export type BopReturnPreviousStateMutationHookResult = ReturnType<typeof useBopReturnPreviousStateMutation>;
export type BopReturnPreviousStateMutationResult = ApolloReactCommon.MutationResult<BopReturnPreviousStateMutation>;
export type BopReturnPreviousStateMutationOptions = ApolloReactCommon.BaseMutationOptions<BopReturnPreviousStateMutation, BopReturnPreviousStateMutationVariables>;
export const AcceptBrokerInviteDocument = gql`
    mutation acceptBrokerInvite($input: AcceptBrokerInviteInput!) {
  acceptBrokerInvite(input: $input) {
    worksheet {
      id
      owner {
        id
      }
      collaborations {
        id
        __typename
        user {
          id
        }
      }
    }
    errors
  }
}
    `;
export type AcceptBrokerInviteMutationFn = ApolloReactCommon.MutationFunction<AcceptBrokerInviteMutation, AcceptBrokerInviteMutationVariables>;
export type AcceptBrokerInviteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AcceptBrokerInviteMutation, AcceptBrokerInviteMutationVariables>
    } & TChildProps;
export function withAcceptBrokerInvite<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AcceptBrokerInviteMutation,
  AcceptBrokerInviteMutationVariables,
  AcceptBrokerInviteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AcceptBrokerInviteMutation, AcceptBrokerInviteMutationVariables, AcceptBrokerInviteProps<TChildProps, TDataName>>(AcceptBrokerInviteDocument, {
      alias: 'acceptBrokerInvite',
      ...operationOptions
    });
};

/**
 * __useAcceptBrokerInviteMutation__
 *
 * To run a mutation, you first call `useAcceptBrokerInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBrokerInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBrokerInviteMutation, { data, loading, error }] = useAcceptBrokerInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptBrokerInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptBrokerInviteMutation, AcceptBrokerInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptBrokerInviteMutation, AcceptBrokerInviteMutationVariables>(AcceptBrokerInviteDocument, baseOptions);
      }
export type AcceptBrokerInviteMutationHookResult = ReturnType<typeof useAcceptBrokerInviteMutation>;
export type AcceptBrokerInviteMutationResult = ApolloReactCommon.MutationResult<AcceptBrokerInviteMutation>;
export type AcceptBrokerInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptBrokerInviteMutation, AcceptBrokerInviteMutationVariables>;
export const ChangeQuoteOwnerDocument = gql`
    mutation changeQuoteOwner($input: ChangeQuoteOwnerInput!) {
  changeQuoteOwner(input: $input) {
    quote {
      id
      reinsurer {
        id
        quotes {
          edges {
            node {
              id
            }
          }
        }
      }
    }
    errors
  }
}
    `;
export type ChangeQuoteOwnerMutationFn = ApolloReactCommon.MutationFunction<ChangeQuoteOwnerMutation, ChangeQuoteOwnerMutationVariables>;
export type ChangeQuoteOwnerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeQuoteOwnerMutation, ChangeQuoteOwnerMutationVariables>
    } & TChildProps;
export function withChangeQuoteOwner<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeQuoteOwnerMutation,
  ChangeQuoteOwnerMutationVariables,
  ChangeQuoteOwnerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeQuoteOwnerMutation, ChangeQuoteOwnerMutationVariables, ChangeQuoteOwnerProps<TChildProps, TDataName>>(ChangeQuoteOwnerDocument, {
      alias: 'changeQuoteOwner',
      ...operationOptions
    });
};

/**
 * __useChangeQuoteOwnerMutation__
 *
 * To run a mutation, you first call `useChangeQuoteOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeQuoteOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeQuoteOwnerMutation, { data, loading, error }] = useChangeQuoteOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeQuoteOwnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeQuoteOwnerMutation, ChangeQuoteOwnerMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeQuoteOwnerMutation, ChangeQuoteOwnerMutationVariables>(ChangeQuoteOwnerDocument, baseOptions);
      }
export type ChangeQuoteOwnerMutationHookResult = ReturnType<typeof useChangeQuoteOwnerMutation>;
export type ChangeQuoteOwnerMutationResult = ApolloReactCommon.MutationResult<ChangeQuoteOwnerMutation>;
export type ChangeQuoteOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeQuoteOwnerMutation, ChangeQuoteOwnerMutationVariables>;
export const ChangeReferenceNumberDocument = gql`
    mutation changeReferenceNumber($input: ChangeReferenceNumberInput!) {
  changeReferenceNumber(input: $input) {
    quote {
      id
      referenceNumber
    }
    errors
  }
}
    `;
export type ChangeReferenceNumberMutationFn = ApolloReactCommon.MutationFunction<ChangeReferenceNumberMutation, ChangeReferenceNumberMutationVariables>;
export type ChangeReferenceNumberProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeReferenceNumberMutation, ChangeReferenceNumberMutationVariables>
    } & TChildProps;
export function withChangeReferenceNumber<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeReferenceNumberMutation,
  ChangeReferenceNumberMutationVariables,
  ChangeReferenceNumberProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeReferenceNumberMutation, ChangeReferenceNumberMutationVariables, ChangeReferenceNumberProps<TChildProps, TDataName>>(ChangeReferenceNumberDocument, {
      alias: 'changeReferenceNumber',
      ...operationOptions
    });
};

/**
 * __useChangeReferenceNumberMutation__
 *
 * To run a mutation, you first call `useChangeReferenceNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeReferenceNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeReferenceNumberMutation, { data, loading, error }] = useChangeReferenceNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeReferenceNumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeReferenceNumberMutation, ChangeReferenceNumberMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeReferenceNumberMutation, ChangeReferenceNumberMutationVariables>(ChangeReferenceNumberDocument, baseOptions);
      }
export type ChangeReferenceNumberMutationHookResult = ReturnType<typeof useChangeReferenceNumberMutation>;
export type ChangeReferenceNumberMutationResult = ApolloReactCommon.MutationResult<ChangeReferenceNumberMutation>;
export type ChangeReferenceNumberMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeReferenceNumberMutation, ChangeReferenceNumberMutationVariables>;
export const ChangeSubmissionOwnerDocument = gql`
    mutation changeSubmissionOwner($input: ChangeSubmissionOwnerInput!) {
  changeSubmissionOwner(input: $input) {
    submission {
      id
      owner {
        id
        fullName
        company {
          id
          name
        }
      }
    }
    errors
  }
}
    `;
export type ChangeSubmissionOwnerMutationFn = ApolloReactCommon.MutationFunction<ChangeSubmissionOwnerMutation, ChangeSubmissionOwnerMutationVariables>;
export type ChangeSubmissionOwnerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeSubmissionOwnerMutation, ChangeSubmissionOwnerMutationVariables>
    } & TChildProps;
export function withChangeSubmissionOwner<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeSubmissionOwnerMutation,
  ChangeSubmissionOwnerMutationVariables,
  ChangeSubmissionOwnerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeSubmissionOwnerMutation, ChangeSubmissionOwnerMutationVariables, ChangeSubmissionOwnerProps<TChildProps, TDataName>>(ChangeSubmissionOwnerDocument, {
      alias: 'changeSubmissionOwner',
      ...operationOptions
    });
};

/**
 * __useChangeSubmissionOwnerMutation__
 *
 * To run a mutation, you first call `useChangeSubmissionOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubmissionOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubmissionOwnerMutation, { data, loading, error }] = useChangeSubmissionOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeSubmissionOwnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeSubmissionOwnerMutation, ChangeSubmissionOwnerMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeSubmissionOwnerMutation, ChangeSubmissionOwnerMutationVariables>(ChangeSubmissionOwnerDocument, baseOptions);
      }
export type ChangeSubmissionOwnerMutationHookResult = ReturnType<typeof useChangeSubmissionOwnerMutation>;
export type ChangeSubmissionOwnerMutationResult = ApolloReactCommon.MutationResult<ChangeSubmissionOwnerMutation>;
export type ChangeSubmissionOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeSubmissionOwnerMutation, ChangeSubmissionOwnerMutationVariables>;
export const ChangePlacementOwnerDocument = gql`
    mutation changePlacementOwner($input: ChangeOwnerInput!) {
  changePlacementOwner(input: $input) {
    worksheet {
      id
      owner {
        id
        ... on Cedent {
          worksheets {
            edges {
              node {
                id
              }
            }
          }
        }
        ... on Broker {
          worksheets {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    errors
  }
}
    `;
export type ChangePlacementOwnerMutationFn = ApolloReactCommon.MutationFunction<ChangePlacementOwnerMutation, ChangePlacementOwnerMutationVariables>;
export type ChangePlacementOwnerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangePlacementOwnerMutation, ChangePlacementOwnerMutationVariables>
    } & TChildProps;
export function withChangePlacementOwner<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangePlacementOwnerMutation,
  ChangePlacementOwnerMutationVariables,
  ChangePlacementOwnerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangePlacementOwnerMutation, ChangePlacementOwnerMutationVariables, ChangePlacementOwnerProps<TChildProps, TDataName>>(ChangePlacementOwnerDocument, {
      alias: 'changePlacementOwner',
      ...operationOptions
    });
};

/**
 * __useChangePlacementOwnerMutation__
 *
 * To run a mutation, you first call `useChangePlacementOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePlacementOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePlacementOwnerMutation, { data, loading, error }] = useChangePlacementOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePlacementOwnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePlacementOwnerMutation, ChangePlacementOwnerMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePlacementOwnerMutation, ChangePlacementOwnerMutationVariables>(ChangePlacementOwnerDocument, baseOptions);
      }
export type ChangePlacementOwnerMutationHookResult = ReturnType<typeof useChangePlacementOwnerMutation>;
export type ChangePlacementOwnerMutationResult = ApolloReactCommon.MutationResult<ChangePlacementOwnerMutation>;
export type ChangePlacementOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePlacementOwnerMutation, ChangePlacementOwnerMutationVariables>;
export const CreateChargebeeCheckoutDocument = gql`
    mutation createChargebeeCheckout($input: CreateChargebeeCheckoutInput!) {
  createChargebeeCheckout(input: $input) {
    checkout
    errors
  }
}
    `;
export type CreateChargebeeCheckoutMutationFn = ApolloReactCommon.MutationFunction<CreateChargebeeCheckoutMutation, CreateChargebeeCheckoutMutationVariables>;
export type CreateChargebeeCheckoutProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateChargebeeCheckoutMutation, CreateChargebeeCheckoutMutationVariables>
    } & TChildProps;
export function withCreateChargebeeCheckout<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateChargebeeCheckoutMutation,
  CreateChargebeeCheckoutMutationVariables,
  CreateChargebeeCheckoutProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateChargebeeCheckoutMutation, CreateChargebeeCheckoutMutationVariables, CreateChargebeeCheckoutProps<TChildProps, TDataName>>(CreateChargebeeCheckoutDocument, {
      alias: 'createChargebeeCheckout',
      ...operationOptions
    });
};

/**
 * __useCreateChargebeeCheckoutMutation__
 *
 * To run a mutation, you first call `useCreateChargebeeCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargebeeCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargebeeCheckoutMutation, { data, loading, error }] = useCreateChargebeeCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChargebeeCheckoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateChargebeeCheckoutMutation, CreateChargebeeCheckoutMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateChargebeeCheckoutMutation, CreateChargebeeCheckoutMutationVariables>(CreateChargebeeCheckoutDocument, baseOptions);
      }
export type CreateChargebeeCheckoutMutationHookResult = ReturnType<typeof useCreateChargebeeCheckoutMutation>;
export type CreateChargebeeCheckoutMutationResult = ApolloReactCommon.MutationResult<CreateChargebeeCheckoutMutation>;
export type CreateChargebeeCheckoutMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateChargebeeCheckoutMutation, CreateChargebeeCheckoutMutationVariables>;
export const CreateChargebeePortalSessionDocument = gql`
    mutation createChargebeePortalSession($input: CreateChargebeePortalSessionInput!) {
  createChargebeePortalSession(input: $input) {
    session
    errors
  }
}
    `;
export type CreateChargebeePortalSessionMutationFn = ApolloReactCommon.MutationFunction<CreateChargebeePortalSessionMutation, CreateChargebeePortalSessionMutationVariables>;
export type CreateChargebeePortalSessionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateChargebeePortalSessionMutation, CreateChargebeePortalSessionMutationVariables>
    } & TChildProps;
export function withCreateChargebeePortalSession<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateChargebeePortalSessionMutation,
  CreateChargebeePortalSessionMutationVariables,
  CreateChargebeePortalSessionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateChargebeePortalSessionMutation, CreateChargebeePortalSessionMutationVariables, CreateChargebeePortalSessionProps<TChildProps, TDataName>>(CreateChargebeePortalSessionDocument, {
      alias: 'createChargebeePortalSession',
      ...operationOptions
    });
};

/**
 * __useCreateChargebeePortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateChargebeePortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargebeePortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargebeePortalSessionMutation, { data, loading, error }] = useCreateChargebeePortalSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChargebeePortalSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateChargebeePortalSessionMutation, CreateChargebeePortalSessionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateChargebeePortalSessionMutation, CreateChargebeePortalSessionMutationVariables>(CreateChargebeePortalSessionDocument, baseOptions);
      }
export type CreateChargebeePortalSessionMutationHookResult = ReturnType<typeof useCreateChargebeePortalSessionMutation>;
export type CreateChargebeePortalSessionMutationResult = ApolloReactCommon.MutationResult<CreateChargebeePortalSessionMutation>;
export type CreateChargebeePortalSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateChargebeePortalSessionMutation, CreateChargebeePortalSessionMutationVariables>;
export const ApproveQuoteDocument = gql`
    mutation approveQuote($input: ClientApprovalInput!) {
  approveQuote(input: $input) {
    quote {
      id
      status
    }
  }
}
    `;
export type ApproveQuoteMutationFn = ApolloReactCommon.MutationFunction<ApproveQuoteMutation, ApproveQuoteMutationVariables>;
export type ApproveQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ApproveQuoteMutation, ApproveQuoteMutationVariables>
    } & TChildProps;
export function withApproveQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ApproveQuoteMutation,
  ApproveQuoteMutationVariables,
  ApproveQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ApproveQuoteMutation, ApproveQuoteMutationVariables, ApproveQuoteProps<TChildProps, TDataName>>(ApproveQuoteDocument, {
      alias: 'approveQuote',
      ...operationOptions
    });
};

/**
 * __useApproveQuoteMutation__
 *
 * To run a mutation, you first call `useApproveQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveQuoteMutation, { data, loading, error }] = useApproveQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveQuoteMutation, ApproveQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveQuoteMutation, ApproveQuoteMutationVariables>(ApproveQuoteDocument, baseOptions);
      }
export type ApproveQuoteMutationHookResult = ReturnType<typeof useApproveQuoteMutation>;
export type ApproveQuoteMutationResult = ApolloReactCommon.MutationResult<ApproveQuoteMutation>;
export type ApproveQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveQuoteMutation, ApproveQuoteMutationVariables>;
export const ConfirmOutdatedQuoteDocument = gql`
    mutation confirmOutdatedQuote($input: ConfirmOutdatedQuoteInput!) {
  confirmOutdatedQuote(input: $input) {
    quote {
      id
      outdatedAt
    }
  }
}
    `;
export type ConfirmOutdatedQuoteMutationFn = ApolloReactCommon.MutationFunction<ConfirmOutdatedQuoteMutation, ConfirmOutdatedQuoteMutationVariables>;
export type ConfirmOutdatedQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ConfirmOutdatedQuoteMutation, ConfirmOutdatedQuoteMutationVariables>
    } & TChildProps;
export function withConfirmOutdatedQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConfirmOutdatedQuoteMutation,
  ConfirmOutdatedQuoteMutationVariables,
  ConfirmOutdatedQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ConfirmOutdatedQuoteMutation, ConfirmOutdatedQuoteMutationVariables, ConfirmOutdatedQuoteProps<TChildProps, TDataName>>(ConfirmOutdatedQuoteDocument, {
      alias: 'confirmOutdatedQuote',
      ...operationOptions
    });
};

/**
 * __useConfirmOutdatedQuoteMutation__
 *
 * To run a mutation, you first call `useConfirmOutdatedQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOutdatedQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOutdatedQuoteMutation, { data, loading, error }] = useConfirmOutdatedQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmOutdatedQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmOutdatedQuoteMutation, ConfirmOutdatedQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmOutdatedQuoteMutation, ConfirmOutdatedQuoteMutationVariables>(ConfirmOutdatedQuoteDocument, baseOptions);
      }
export type ConfirmOutdatedQuoteMutationHookResult = ReturnType<typeof useConfirmOutdatedQuoteMutation>;
export type ConfirmOutdatedQuoteMutationResult = ApolloReactCommon.MutationResult<ConfirmOutdatedQuoteMutation>;
export type ConfirmOutdatedQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmOutdatedQuoteMutation, ConfirmOutdatedQuoteMutationVariables>;
export const AcceptCompanyInvitationDocument = gql`
    mutation acceptCompanyInvitation($input: AcceptCompanyInvitationInput!) {
  acceptCompanyInvitation(input: $input) {
    success
    errors
  }
}
    `;
export type AcceptCompanyInvitationMutationFn = ApolloReactCommon.MutationFunction<AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables>;
export type AcceptCompanyInvitationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables>
    } & TChildProps;
export function withAcceptCompanyInvitation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AcceptCompanyInvitationMutation,
  AcceptCompanyInvitationMutationVariables,
  AcceptCompanyInvitationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables, AcceptCompanyInvitationProps<TChildProps, TDataName>>(AcceptCompanyInvitationDocument, {
      alias: 'acceptCompanyInvitation',
      ...operationOptions
    });
};

/**
 * __useAcceptCompanyInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptCompanyInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCompanyInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCompanyInvitationMutation, { data, loading, error }] = useAcceptCompanyInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptCompanyInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables>(AcceptCompanyInvitationDocument, baseOptions);
      }
export type AcceptCompanyInvitationMutationHookResult = ReturnType<typeof useAcceptCompanyInvitationMutation>;
export type AcceptCompanyInvitationMutationResult = ApolloReactCommon.MutationResult<AcceptCompanyInvitationMutation>;
export type AcceptCompanyInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables>;
export const CyberBindQuoteDocument = gql`
    mutation cyberBindQuote($input: CyberBindQuoteInput!) {
  cyberBindQuote(input: $input) {
    quotation {
      id
      status
      statusMessage
      policyNumber
      documents {
        id
        category
        version
        ...DocumentForForm
      }
      participation {
        id
        discussion {
          id
          ...Messages
        }
      }
    }
    error
  }
}
    ${DocumentForFormFragmentDoc}
${MessagesFragmentDoc}`;
export type CyberBindQuoteMutationFn = ApolloReactCommon.MutationFunction<CyberBindQuoteMutation, CyberBindQuoteMutationVariables>;
export type CyberBindQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberBindQuoteMutation, CyberBindQuoteMutationVariables>
    } & TChildProps;
export function withCyberBindQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberBindQuoteMutation,
  CyberBindQuoteMutationVariables,
  CyberBindQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberBindQuoteMutation, CyberBindQuoteMutationVariables, CyberBindQuoteProps<TChildProps, TDataName>>(CyberBindQuoteDocument, {
      alias: 'cyberBindQuote',
      ...operationOptions
    });
};

/**
 * __useCyberBindQuoteMutation__
 *
 * To run a mutation, you first call `useCyberBindQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberBindQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberBindQuoteMutation, { data, loading, error }] = useCyberBindQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberBindQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberBindQuoteMutation, CyberBindQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberBindQuoteMutation, CyberBindQuoteMutationVariables>(CyberBindQuoteDocument, baseOptions);
      }
export type CyberBindQuoteMutationHookResult = ReturnType<typeof useCyberBindQuoteMutation>;
export type CyberBindQuoteMutationResult = ApolloReactCommon.MutationResult<CyberBindQuoteMutation>;
export type CyberBindQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberBindQuoteMutation, CyberBindQuoteMutationVariables>;
export const CyberCreateManualQuoteDocument = gql`
    mutation cyberCreateManualQuote($input: CyberCreateManualQuoteInput!) {
  cyberCreateManualQuote(input: $input) {
    quotation {
      ...CyberQuotationInfo
    }
    errors {
      base
      admitted
      carrierName
      comments
      declinationReason
      deductible
      endorsements {
        name
        limit
        coinsurance
      }
      coverages {
        name
        limit
        deductible
      }
      fee
      limit
      policyNumber
      premium
    }
  }
}
    ${CyberQuotationInfoFragmentDoc}`;
export type CyberCreateManualQuoteMutationFn = ApolloReactCommon.MutationFunction<CyberCreateManualQuoteMutation, CyberCreateManualQuoteMutationVariables>;
export type CyberCreateManualQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberCreateManualQuoteMutation, CyberCreateManualQuoteMutationVariables>
    } & TChildProps;
export function withCyberCreateManualQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberCreateManualQuoteMutation,
  CyberCreateManualQuoteMutationVariables,
  CyberCreateManualQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberCreateManualQuoteMutation, CyberCreateManualQuoteMutationVariables, CyberCreateManualQuoteProps<TChildProps, TDataName>>(CyberCreateManualQuoteDocument, {
      alias: 'cyberCreateManualQuote',
      ...operationOptions
    });
};

/**
 * __useCyberCreateManualQuoteMutation__
 *
 * To run a mutation, you first call `useCyberCreateManualQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberCreateManualQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberCreateManualQuoteMutation, { data, loading, error }] = useCyberCreateManualQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberCreateManualQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberCreateManualQuoteMutation, CyberCreateManualQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberCreateManualQuoteMutation, CyberCreateManualQuoteMutationVariables>(CyberCreateManualQuoteDocument, baseOptions);
      }
export type CyberCreateManualQuoteMutationHookResult = ReturnType<typeof useCyberCreateManualQuoteMutation>;
export type CyberCreateManualQuoteMutationResult = ApolloReactCommon.MutationResult<CyberCreateManualQuoteMutation>;
export type CyberCreateManualQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberCreateManualQuoteMutation, CyberCreateManualQuoteMutationVariables>;
export const CyberCreateShareableApplicationLinkDocument = gql`
    mutation cyberCreateShareableApplicationLink($input: CyberCreateShareableApplicationLinkInput!) {
  cyberCreateShareableApplicationLink(input: $input) {
    shareableLink
    errors
  }
}
    `;
export type CyberCreateShareableApplicationLinkMutationFn = ApolloReactCommon.MutationFunction<CyberCreateShareableApplicationLinkMutation, CyberCreateShareableApplicationLinkMutationVariables>;
export type CyberCreateShareableApplicationLinkProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberCreateShareableApplicationLinkMutation, CyberCreateShareableApplicationLinkMutationVariables>
    } & TChildProps;
export function withCyberCreateShareableApplicationLink<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberCreateShareableApplicationLinkMutation,
  CyberCreateShareableApplicationLinkMutationVariables,
  CyberCreateShareableApplicationLinkProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberCreateShareableApplicationLinkMutation, CyberCreateShareableApplicationLinkMutationVariables, CyberCreateShareableApplicationLinkProps<TChildProps, TDataName>>(CyberCreateShareableApplicationLinkDocument, {
      alias: 'cyberCreateShareableApplicationLink',
      ...operationOptions
    });
};

/**
 * __useCyberCreateShareableApplicationLinkMutation__
 *
 * To run a mutation, you first call `useCyberCreateShareableApplicationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberCreateShareableApplicationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberCreateShareableApplicationLinkMutation, { data, loading, error }] = useCyberCreateShareableApplicationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberCreateShareableApplicationLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberCreateShareableApplicationLinkMutation, CyberCreateShareableApplicationLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberCreateShareableApplicationLinkMutation, CyberCreateShareableApplicationLinkMutationVariables>(CyberCreateShareableApplicationLinkDocument, baseOptions);
      }
export type CyberCreateShareableApplicationLinkMutationHookResult = ReturnType<typeof useCyberCreateShareableApplicationLinkMutation>;
export type CyberCreateShareableApplicationLinkMutationResult = ApolloReactCommon.MutationResult<CyberCreateShareableApplicationLinkMutation>;
export type CyberCreateShareableApplicationLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberCreateShareableApplicationLinkMutation, CyberCreateShareableApplicationLinkMutationVariables>;
export const CyberCreateShareableLinkDocument = gql`
    mutation cyberCreateShareableLink($input: CyberCreateShareableLinkInput!) {
  cyberCreateShareableLink(input: $input) {
    shareableLink
    errors
  }
}
    `;
export type CyberCreateShareableLinkMutationFn = ApolloReactCommon.MutationFunction<CyberCreateShareableLinkMutation, CyberCreateShareableLinkMutationVariables>;
export type CyberCreateShareableLinkProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberCreateShareableLinkMutation, CyberCreateShareableLinkMutationVariables>
    } & TChildProps;
export function withCyberCreateShareableLink<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberCreateShareableLinkMutation,
  CyberCreateShareableLinkMutationVariables,
  CyberCreateShareableLinkProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberCreateShareableLinkMutation, CyberCreateShareableLinkMutationVariables, CyberCreateShareableLinkProps<TChildProps, TDataName>>(CyberCreateShareableLinkDocument, {
      alias: 'cyberCreateShareableLink',
      ...operationOptions
    });
};

/**
 * __useCyberCreateShareableLinkMutation__
 *
 * To run a mutation, you first call `useCyberCreateShareableLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberCreateShareableLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberCreateShareableLinkMutation, { data, loading, error }] = useCyberCreateShareableLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberCreateShareableLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberCreateShareableLinkMutation, CyberCreateShareableLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberCreateShareableLinkMutation, CyberCreateShareableLinkMutationVariables>(CyberCreateShareableLinkDocument, baseOptions);
      }
export type CyberCreateShareableLinkMutationHookResult = ReturnType<typeof useCyberCreateShareableLinkMutation>;
export type CyberCreateShareableLinkMutationResult = ApolloReactCommon.MutationResult<CyberCreateShareableLinkMutation>;
export type CyberCreateShareableLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberCreateShareableLinkMutation, CyberCreateShareableLinkMutationVariables>;
export const CyberAdjustQuotesDocument = gql`
    mutation cyberAdjustQuotes($input: CyberAdjustQuotesInput!) {
  cyberAdjustQuotes(input: $input) {
    clientMutationId
    submission {
      annualRevenue
      city
      coverageStartDate
      createdAt
      deductible
      deletedAt
      description
      fields
      id
      lastEditedAt
      limit
      naicsCode
      name
      state
      status
      streetLine1
      streetLine2
      type
      updatedAt
      websites
      zipCode
    }
    errors {
      id
      limit
      deductible
    }
  }
}
    `;
export type CyberAdjustQuotesMutationFn = ApolloReactCommon.MutationFunction<CyberAdjustQuotesMutation, CyberAdjustQuotesMutationVariables>;
export type CyberAdjustQuotesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberAdjustQuotesMutation, CyberAdjustQuotesMutationVariables>
    } & TChildProps;
export function withCyberAdjustQuotes<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberAdjustQuotesMutation,
  CyberAdjustQuotesMutationVariables,
  CyberAdjustQuotesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberAdjustQuotesMutation, CyberAdjustQuotesMutationVariables, CyberAdjustQuotesProps<TChildProps, TDataName>>(CyberAdjustQuotesDocument, {
      alias: 'cyberAdjustQuotes',
      ...operationOptions
    });
};

/**
 * __useCyberAdjustQuotesMutation__
 *
 * To run a mutation, you first call `useCyberAdjustQuotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberAdjustQuotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberAdjustQuotesMutation, { data, loading, error }] = useCyberAdjustQuotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberAdjustQuotesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberAdjustQuotesMutation, CyberAdjustQuotesMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberAdjustQuotesMutation, CyberAdjustQuotesMutationVariables>(CyberAdjustQuotesDocument, baseOptions);
      }
export type CyberAdjustQuotesMutationHookResult = ReturnType<typeof useCyberAdjustQuotesMutation>;
export type CyberAdjustQuotesMutationResult = ApolloReactCommon.MutationResult<CyberAdjustQuotesMutation>;
export type CyberAdjustQuotesMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberAdjustQuotesMutation, CyberAdjustQuotesMutationVariables>;
export const CyberArchiveQuoteDocument = gql`
    mutation cyberArchiveQuote($input: CyberArchiveQuoteInput!) {
  cyberArchiveQuote(input: $input) {
    quotation {
      id
      archivedAt
    }
  }
}
    `;
export type CyberArchiveQuoteMutationFn = ApolloReactCommon.MutationFunction<CyberArchiveQuoteMutation, CyberArchiveQuoteMutationVariables>;
export type CyberArchiveQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberArchiveQuoteMutation, CyberArchiveQuoteMutationVariables>
    } & TChildProps;
export function withCyberArchiveQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberArchiveQuoteMutation,
  CyberArchiveQuoteMutationVariables,
  CyberArchiveQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberArchiveQuoteMutation, CyberArchiveQuoteMutationVariables, CyberArchiveQuoteProps<TChildProps, TDataName>>(CyberArchiveQuoteDocument, {
      alias: 'cyberArchiveQuote',
      ...operationOptions
    });
};

/**
 * __useCyberArchiveQuoteMutation__
 *
 * To run a mutation, you first call `useCyberArchiveQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberArchiveQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberArchiveQuoteMutation, { data, loading, error }] = useCyberArchiveQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberArchiveQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberArchiveQuoteMutation, CyberArchiveQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberArchiveQuoteMutation, CyberArchiveQuoteMutationVariables>(CyberArchiveQuoteDocument, baseOptions);
      }
export type CyberArchiveQuoteMutationHookResult = ReturnType<typeof useCyberArchiveQuoteMutation>;
export type CyberArchiveQuoteMutationResult = ApolloReactCommon.MutationResult<CyberArchiveQuoteMutation>;
export type CyberArchiveQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberArchiveQuoteMutation, CyberArchiveQuoteMutationVariables>;
export const CyberCreateDraftSubmissionDocument = gql`
    mutation cyberCreateDraftSubmission($input: CyberCreateDraftSubmissionInput!) {
  cyberCreateDraftSubmission(input: $input) {
    clientMutationId
    submission {
      id
      country
      message
      name
      websites
      hasNoWebsite
      naicsCode
      cyberboxxIndustryCode
      chubbIndustryCode
      streetLine1
      streetLine2
      type
      status
      city
      state
      zipCode
      phoneNumber
      contactName
      email
      numberOfEmployees
      numberOfRecords
      annualRevenue
      limit
      deductible
      coverageStartDate
      multiFactorAuthentication
      selectedPanelists
      additionalCarriers
      documents {
        id
      }
      questions
    }
    errors {
      id
      additionalCarriers
      annualRevenue
      base
      chubbIndustryCode
      city
      contactName
      country
      coverageStartDate
      cyberboxxIndustryCode
      deductible
      email
      hasNoWebsite
      limit
      message
      naicsCode
      name
      numberOfEmployees
      numberOfRecords
      phoneNumber
      multiFactorAuthentication
      selectedPanelists
      state
      status
      streetLine1
      streetLine2
      websites
      zipCode
    }
  }
}
    `;
export type CyberCreateDraftSubmissionMutationFn = ApolloReactCommon.MutationFunction<CyberCreateDraftSubmissionMutation, CyberCreateDraftSubmissionMutationVariables>;
export type CyberCreateDraftSubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberCreateDraftSubmissionMutation, CyberCreateDraftSubmissionMutationVariables>
    } & TChildProps;
export function withCyberCreateDraftSubmission<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberCreateDraftSubmissionMutation,
  CyberCreateDraftSubmissionMutationVariables,
  CyberCreateDraftSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberCreateDraftSubmissionMutation, CyberCreateDraftSubmissionMutationVariables, CyberCreateDraftSubmissionProps<TChildProps, TDataName>>(CyberCreateDraftSubmissionDocument, {
      alias: 'cyberCreateDraftSubmission',
      ...operationOptions
    });
};

/**
 * __useCyberCreateDraftSubmissionMutation__
 *
 * To run a mutation, you first call `useCyberCreateDraftSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberCreateDraftSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberCreateDraftSubmissionMutation, { data, loading, error }] = useCyberCreateDraftSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberCreateDraftSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberCreateDraftSubmissionMutation, CyberCreateDraftSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberCreateDraftSubmissionMutation, CyberCreateDraftSubmissionMutationVariables>(CyberCreateDraftSubmissionDocument, baseOptions);
      }
export type CyberCreateDraftSubmissionMutationHookResult = ReturnType<typeof useCyberCreateDraftSubmissionMutation>;
export type CyberCreateDraftSubmissionMutationResult = ApolloReactCommon.MutationResult<CyberCreateDraftSubmissionMutation>;
export type CyberCreateDraftSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberCreateDraftSubmissionMutation, CyberCreateDraftSubmissionMutationVariables>;
export const CyberCreateQuoteDocument = gql`
    mutation cyberCreateQuote($input: CyberCreateQuoteInput!) {
  cyberCreateQuote(input: $input) {
    quotation {
      ...CyberQuotationInfo
      participation {
        id
        quotations {
          nodes {
            id
          }
        }
      }
    }
    errors {
      base
      limit
      deductible
      premium
      fee
      admitted
      coverages {
        name
        limit
        deductible
      }
      endorsements {
        name
        limit
        coinsurance
      }
      documents
    }
  }
}
    ${CyberQuotationInfoFragmentDoc}`;
export type CyberCreateQuoteMutationFn = ApolloReactCommon.MutationFunction<CyberCreateQuoteMutation, CyberCreateQuoteMutationVariables>;
export type CyberCreateQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberCreateQuoteMutation, CyberCreateQuoteMutationVariables>
    } & TChildProps;
export function withCyberCreateQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberCreateQuoteMutation,
  CyberCreateQuoteMutationVariables,
  CyberCreateQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberCreateQuoteMutation, CyberCreateQuoteMutationVariables, CyberCreateQuoteProps<TChildProps, TDataName>>(CyberCreateQuoteDocument, {
      alias: 'cyberCreateQuote',
      ...operationOptions
    });
};

/**
 * __useCyberCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCyberCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberCreateQuoteMutation, { data, loading, error }] = useCyberCreateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberCreateQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberCreateQuoteMutation, CyberCreateQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberCreateQuoteMutation, CyberCreateQuoteMutationVariables>(CyberCreateQuoteDocument, baseOptions);
      }
export type CyberCreateQuoteMutationHookResult = ReturnType<typeof useCyberCreateQuoteMutation>;
export type CyberCreateQuoteMutationResult = ApolloReactCommon.MutationResult<CyberCreateQuoteMutation>;
export type CyberCreateQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberCreateQuoteMutation, CyberCreateQuoteMutationVariables>;
export const CyberExportSubmissionDocument = gql`
    mutation cyberExportSubmission($input: CyberExportSubmissionInput!) {
  cyberExportSubmission(input: $input) {
    errors
  }
}
    `;
export type CyberExportSubmissionMutationFn = ApolloReactCommon.MutationFunction<CyberExportSubmissionMutation, CyberExportSubmissionMutationVariables>;
export type CyberExportSubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberExportSubmissionMutation, CyberExportSubmissionMutationVariables>
    } & TChildProps;
export function withCyberExportSubmission<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberExportSubmissionMutation,
  CyberExportSubmissionMutationVariables,
  CyberExportSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberExportSubmissionMutation, CyberExportSubmissionMutationVariables, CyberExportSubmissionProps<TChildProps, TDataName>>(CyberExportSubmissionDocument, {
      alias: 'cyberExportSubmission',
      ...operationOptions
    });
};

/**
 * __useCyberExportSubmissionMutation__
 *
 * To run a mutation, you first call `useCyberExportSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberExportSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberExportSubmissionMutation, { data, loading, error }] = useCyberExportSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberExportSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberExportSubmissionMutation, CyberExportSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberExportSubmissionMutation, CyberExportSubmissionMutationVariables>(CyberExportSubmissionDocument, baseOptions);
      }
export type CyberExportSubmissionMutationHookResult = ReturnType<typeof useCyberExportSubmissionMutation>;
export type CyberExportSubmissionMutationResult = ApolloReactCommon.MutationResult<CyberExportSubmissionMutation>;
export type CyberExportSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberExportSubmissionMutation, CyberExportSubmissionMutationVariables>;
export const CyberGenerateQuotesDocument = gql`
    mutation cyberGenerateQuotes($input: CyberGenerateQuotesInput!) {
  cyberGenerateQuotes(input: $input) {
    clientMutationId
    submission {
      id
      name
      type
      status
      naicsCode
      websites
      streetLine1
      streetLine2
      city
      state
      zipCode
      limit
      deductible
      coverageStartDate
      annualRevenue
      description
      fields
      lastEditedAt
      createdAt
      updatedAt
      deletedAt
    }
    errors {
      additionalCarriers {
        id
      }
      annualRevenue
      base
      city
      coverageStartDate
      deductible
      documents
      limit
      message
      naicsCode
      numberOfEmployees
      numberOfRecords
      placementTermsAccepted
      name
      streetLine1
      streetLine2
      state
      zipCode
      websites
    }
  }
}
    `;
export type CyberGenerateQuotesMutationFn = ApolloReactCommon.MutationFunction<CyberGenerateQuotesMutation, CyberGenerateQuotesMutationVariables>;
export type CyberGenerateQuotesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberGenerateQuotesMutation, CyberGenerateQuotesMutationVariables>
    } & TChildProps;
export function withCyberGenerateQuotes<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberGenerateQuotesMutation,
  CyberGenerateQuotesMutationVariables,
  CyberGenerateQuotesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberGenerateQuotesMutation, CyberGenerateQuotesMutationVariables, CyberGenerateQuotesProps<TChildProps, TDataName>>(CyberGenerateQuotesDocument, {
      alias: 'cyberGenerateQuotes',
      ...operationOptions
    });
};

/**
 * __useCyberGenerateQuotesMutation__
 *
 * To run a mutation, you first call `useCyberGenerateQuotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberGenerateQuotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberGenerateQuotesMutation, { data, loading, error }] = useCyberGenerateQuotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberGenerateQuotesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberGenerateQuotesMutation, CyberGenerateQuotesMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberGenerateQuotesMutation, CyberGenerateQuotesMutationVariables>(CyberGenerateQuotesDocument, baseOptions);
      }
export type CyberGenerateQuotesMutationHookResult = ReturnType<typeof useCyberGenerateQuotesMutation>;
export type CyberGenerateQuotesMutationResult = ApolloReactCommon.MutationResult<CyberGenerateQuotesMutation>;
export type CyberGenerateQuotesMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberGenerateQuotesMutation, CyberGenerateQuotesMutationVariables>;
export const CyberUpdateExtractedQuoteDocument = gql`
    mutation cyberUpdateExtractedQuote($input: CyberUpdateExtractedQuoteInput!) {
  cyberUpdateExtractedQuote(input: $input) {
    quotation {
      id
      ...CyberQuotationInfo
      participation {
        id
        quotations {
          nodes {
            id
          }
        }
      }
    }
    errors {
      base
      limit
      deductible
      premium
      fee
      admitted
      coverages {
        name
        limit
        deductible
      }
      endorsements {
        name
        limit
        coinsurance
      }
      documents
    }
  }
}
    ${CyberQuotationInfoFragmentDoc}`;
export type CyberUpdateExtractedQuoteMutationFn = ApolloReactCommon.MutationFunction<CyberUpdateExtractedQuoteMutation, CyberUpdateExtractedQuoteMutationVariables>;
export type CyberUpdateExtractedQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberUpdateExtractedQuoteMutation, CyberUpdateExtractedQuoteMutationVariables>
    } & TChildProps;
export function withCyberUpdateExtractedQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberUpdateExtractedQuoteMutation,
  CyberUpdateExtractedQuoteMutationVariables,
  CyberUpdateExtractedQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberUpdateExtractedQuoteMutation, CyberUpdateExtractedQuoteMutationVariables, CyberUpdateExtractedQuoteProps<TChildProps, TDataName>>(CyberUpdateExtractedQuoteDocument, {
      alias: 'cyberUpdateExtractedQuote',
      ...operationOptions
    });
};

/**
 * __useCyberUpdateExtractedQuoteMutation__
 *
 * To run a mutation, you first call `useCyberUpdateExtractedQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberUpdateExtractedQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberUpdateExtractedQuoteMutation, { data, loading, error }] = useCyberUpdateExtractedQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberUpdateExtractedQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberUpdateExtractedQuoteMutation, CyberUpdateExtractedQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberUpdateExtractedQuoteMutation, CyberUpdateExtractedQuoteMutationVariables>(CyberUpdateExtractedQuoteDocument, baseOptions);
      }
export type CyberUpdateExtractedQuoteMutationHookResult = ReturnType<typeof useCyberUpdateExtractedQuoteMutation>;
export type CyberUpdateExtractedQuoteMutationResult = ApolloReactCommon.MutationResult<CyberUpdateExtractedQuoteMutation>;
export type CyberUpdateExtractedQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberUpdateExtractedQuoteMutation, CyberUpdateExtractedQuoteMutationVariables>;
export const CyberUpdateQuoteDocument = gql`
    mutation cyberUpdateQuote($input: CyberUpdateQuoteInput!) {
  cyberUpdateQuote(input: $input) {
    quotation {
      id
      ...CyberQuotationInfo
      participation {
        id
        quotations {
          nodes {
            id
          }
        }
      }
    }
    errors {
      base
      limit
      deductible
      premium
      fee
      admitted
      coverages {
        name
        limit
        deductible
      }
      endorsements {
        name
        limit
        coinsurance
      }
      documents
    }
  }
}
    ${CyberQuotationInfoFragmentDoc}`;
export type CyberUpdateQuoteMutationFn = ApolloReactCommon.MutationFunction<CyberUpdateQuoteMutation, CyberUpdateQuoteMutationVariables>;
export type CyberUpdateQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberUpdateQuoteMutation, CyberUpdateQuoteMutationVariables>
    } & TChildProps;
export function withCyberUpdateQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberUpdateQuoteMutation,
  CyberUpdateQuoteMutationVariables,
  CyberUpdateQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberUpdateQuoteMutation, CyberUpdateQuoteMutationVariables, CyberUpdateQuoteProps<TChildProps, TDataName>>(CyberUpdateQuoteDocument, {
      alias: 'cyberUpdateQuote',
      ...operationOptions
    });
};

/**
 * __useCyberUpdateQuoteMutation__
 *
 * To run a mutation, you first call `useCyberUpdateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberUpdateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberUpdateQuoteMutation, { data, loading, error }] = useCyberUpdateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberUpdateQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberUpdateQuoteMutation, CyberUpdateQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberUpdateQuoteMutation, CyberUpdateQuoteMutationVariables>(CyberUpdateQuoteDocument, baseOptions);
      }
export type CyberUpdateQuoteMutationHookResult = ReturnType<typeof useCyberUpdateQuoteMutation>;
export type CyberUpdateQuoteMutationResult = ApolloReactCommon.MutationResult<CyberUpdateQuoteMutation>;
export type CyberUpdateQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberUpdateQuoteMutation, CyberUpdateQuoteMutationVariables>;
export const CyberUpdateSubmissionDocument = gql`
    mutation cyberUpdateSubmission($input: CyberUpdateSubmissionInput!) {
  cyberUpdateSubmission(input: $input) {
    clientMutationId
    submission {
      id
      ...CyberSubmissionInfo
    }
    errors {
      annualRevenue
      base
      city
      coverageStartDate
      documents
      naicsCode
      numberOfEmployees
      numberOfRecords
      placementTermsAccepted
      name
      streetLine1
      streetLine2
      state
      zipCode
      websites
    }
    error
  }
}
    ${CyberSubmissionInfoFragmentDoc}`;
export type CyberUpdateSubmissionMutationFn = ApolloReactCommon.MutationFunction<CyberUpdateSubmissionMutation, CyberUpdateSubmissionMutationVariables>;
export type CyberUpdateSubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberUpdateSubmissionMutation, CyberUpdateSubmissionMutationVariables>
    } & TChildProps;
export function withCyberUpdateSubmission<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberUpdateSubmissionMutation,
  CyberUpdateSubmissionMutationVariables,
  CyberUpdateSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberUpdateSubmissionMutation, CyberUpdateSubmissionMutationVariables, CyberUpdateSubmissionProps<TChildProps, TDataName>>(CyberUpdateSubmissionDocument, {
      alias: 'cyberUpdateSubmission',
      ...operationOptions
    });
};

/**
 * __useCyberUpdateSubmissionMutation__
 *
 * To run a mutation, you first call `useCyberUpdateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberUpdateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberUpdateSubmissionMutation, { data, loading, error }] = useCyberUpdateSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberUpdateSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberUpdateSubmissionMutation, CyberUpdateSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberUpdateSubmissionMutation, CyberUpdateSubmissionMutationVariables>(CyberUpdateSubmissionDocument, baseOptions);
      }
export type CyberUpdateSubmissionMutationHookResult = ReturnType<typeof useCyberUpdateSubmissionMutation>;
export type CyberUpdateSubmissionMutationResult = ApolloReactCommon.MutationResult<CyberUpdateSubmissionMutation>;
export type CyberUpdateSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberUpdateSubmissionMutation, CyberUpdateSubmissionMutationVariables>;
export const CyberUpdateSubmissionDocumentsDocument = gql`
    mutation cyberUpdateSubmissionDocuments($input: CyberUpdateSubmissionDocumentsInput!) {
  cyberUpdateSubmissionDocuments(input: $input) {
    submission {
      id
      ...CyberSubmissionFileCabinet
    }
    errors
  }
}
    ${CyberSubmissionFileCabinetFragmentDoc}`;
export type CyberUpdateSubmissionDocumentsMutationFn = ApolloReactCommon.MutationFunction<CyberUpdateSubmissionDocumentsMutation, CyberUpdateSubmissionDocumentsMutationVariables>;
export type CyberUpdateSubmissionDocumentsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberUpdateSubmissionDocumentsMutation, CyberUpdateSubmissionDocumentsMutationVariables>
    } & TChildProps;
export function withCyberUpdateSubmissionDocuments<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberUpdateSubmissionDocumentsMutation,
  CyberUpdateSubmissionDocumentsMutationVariables,
  CyberUpdateSubmissionDocumentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberUpdateSubmissionDocumentsMutation, CyberUpdateSubmissionDocumentsMutationVariables, CyberUpdateSubmissionDocumentsProps<TChildProps, TDataName>>(CyberUpdateSubmissionDocumentsDocument, {
      alias: 'cyberUpdateSubmissionDocuments',
      ...operationOptions
    });
};

/**
 * __useCyberUpdateSubmissionDocumentsMutation__
 *
 * To run a mutation, you first call `useCyberUpdateSubmissionDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberUpdateSubmissionDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberUpdateSubmissionDocumentsMutation, { data, loading, error }] = useCyberUpdateSubmissionDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberUpdateSubmissionDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberUpdateSubmissionDocumentsMutation, CyberUpdateSubmissionDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberUpdateSubmissionDocumentsMutation, CyberUpdateSubmissionDocumentsMutationVariables>(CyberUpdateSubmissionDocumentsDocument, baseOptions);
      }
export type CyberUpdateSubmissionDocumentsMutationHookResult = ReturnType<typeof useCyberUpdateSubmissionDocumentsMutation>;
export type CyberUpdateSubmissionDocumentsMutationResult = ApolloReactCommon.MutationResult<CyberUpdateSubmissionDocumentsMutation>;
export type CyberUpdateSubmissionDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberUpdateSubmissionDocumentsMutation, CyberUpdateSubmissionDocumentsMutationVariables>;
export const CyberUpdateSubmissionMarketsDocument = gql`
    mutation cyberUpdateSubmissionMarkets($input: CyberUpdateSubmissionMarketsInput!) {
  cyberUpdateSubmissionMarkets(input: $input) {
    clientMutationId
    submission {
      id
      ...CyberSubmissionInfo
    }
    error
  }
}
    ${CyberSubmissionInfoFragmentDoc}`;
export type CyberUpdateSubmissionMarketsMutationFn = ApolloReactCommon.MutationFunction<CyberUpdateSubmissionMarketsMutation, CyberUpdateSubmissionMarketsMutationVariables>;
export type CyberUpdateSubmissionMarketsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberUpdateSubmissionMarketsMutation, CyberUpdateSubmissionMarketsMutationVariables>
    } & TChildProps;
export function withCyberUpdateSubmissionMarkets<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberUpdateSubmissionMarketsMutation,
  CyberUpdateSubmissionMarketsMutationVariables,
  CyberUpdateSubmissionMarketsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberUpdateSubmissionMarketsMutation, CyberUpdateSubmissionMarketsMutationVariables, CyberUpdateSubmissionMarketsProps<TChildProps, TDataName>>(CyberUpdateSubmissionMarketsDocument, {
      alias: 'cyberUpdateSubmissionMarkets',
      ...operationOptions
    });
};

/**
 * __useCyberUpdateSubmissionMarketsMutation__
 *
 * To run a mutation, you first call `useCyberUpdateSubmissionMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberUpdateSubmissionMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberUpdateSubmissionMarketsMutation, { data, loading, error }] = useCyberUpdateSubmissionMarketsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberUpdateSubmissionMarketsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberUpdateSubmissionMarketsMutation, CyberUpdateSubmissionMarketsMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberUpdateSubmissionMarketsMutation, CyberUpdateSubmissionMarketsMutationVariables>(CyberUpdateSubmissionMarketsDocument, baseOptions);
      }
export type CyberUpdateSubmissionMarketsMutationHookResult = ReturnType<typeof useCyberUpdateSubmissionMarketsMutation>;
export type CyberUpdateSubmissionMarketsMutationResult = ApolloReactCommon.MutationResult<CyberUpdateSubmissionMarketsMutation>;
export type CyberUpdateSubmissionMarketsMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberUpdateSubmissionMarketsMutation, CyberUpdateSubmissionMarketsMutationVariables>;
export const CyberDeclineQuoteDocument = gql`
    mutation cyberDeclineQuote($input: CyberDeclineQuoteInput!) {
  cyberDeclineQuote(input: $input) {
    quotation {
      id
      status
      declinationReason
      participation {
        id
        quotations {
          nodes {
            id
          }
        }
      }
    }
  }
}
    `;
export type CyberDeclineQuoteMutationFn = ApolloReactCommon.MutationFunction<CyberDeclineQuoteMutation, CyberDeclineQuoteMutationVariables>;
export type CyberDeclineQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberDeclineQuoteMutation, CyberDeclineQuoteMutationVariables>
    } & TChildProps;
export function withCyberDeclineQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberDeclineQuoteMutation,
  CyberDeclineQuoteMutationVariables,
  CyberDeclineQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberDeclineQuoteMutation, CyberDeclineQuoteMutationVariables, CyberDeclineQuoteProps<TChildProps, TDataName>>(CyberDeclineQuoteDocument, {
      alias: 'cyberDeclineQuote',
      ...operationOptions
    });
};

/**
 * __useCyberDeclineQuoteMutation__
 *
 * To run a mutation, you first call `useCyberDeclineQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberDeclineQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberDeclineQuoteMutation, { data, loading, error }] = useCyberDeclineQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberDeclineQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberDeclineQuoteMutation, CyberDeclineQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberDeclineQuoteMutation, CyberDeclineQuoteMutationVariables>(CyberDeclineQuoteDocument, baseOptions);
      }
export type CyberDeclineQuoteMutationHookResult = ReturnType<typeof useCyberDeclineQuoteMutation>;
export type CyberDeclineQuoteMutationResult = ApolloReactCommon.MutationResult<CyberDeclineQuoteMutation>;
export type CyberDeclineQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberDeclineQuoteMutation, CyberDeclineQuoteMutationVariables>;
export const CyberEmailShareableApplicationLinkDocument = gql`
    mutation cyberEmailShareableApplicationLink($input: EmailShareableApplicationLinkInput!) {
  cyberEmailShareableApplicationLink(input: $input) {
    shareableLink
    errors
  }
}
    `;
export type CyberEmailShareableApplicationLinkMutationFn = ApolloReactCommon.MutationFunction<CyberEmailShareableApplicationLinkMutation, CyberEmailShareableApplicationLinkMutationVariables>;
export type CyberEmailShareableApplicationLinkProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberEmailShareableApplicationLinkMutation, CyberEmailShareableApplicationLinkMutationVariables>
    } & TChildProps;
export function withCyberEmailShareableApplicationLink<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberEmailShareableApplicationLinkMutation,
  CyberEmailShareableApplicationLinkMutationVariables,
  CyberEmailShareableApplicationLinkProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberEmailShareableApplicationLinkMutation, CyberEmailShareableApplicationLinkMutationVariables, CyberEmailShareableApplicationLinkProps<TChildProps, TDataName>>(CyberEmailShareableApplicationLinkDocument, {
      alias: 'cyberEmailShareableApplicationLink',
      ...operationOptions
    });
};

/**
 * __useCyberEmailShareableApplicationLinkMutation__
 *
 * To run a mutation, you first call `useCyberEmailShareableApplicationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberEmailShareableApplicationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberEmailShareableApplicationLinkMutation, { data, loading, error }] = useCyberEmailShareableApplicationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberEmailShareableApplicationLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberEmailShareableApplicationLinkMutation, CyberEmailShareableApplicationLinkMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberEmailShareableApplicationLinkMutation, CyberEmailShareableApplicationLinkMutationVariables>(CyberEmailShareableApplicationLinkDocument, baseOptions);
      }
export type CyberEmailShareableApplicationLinkMutationHookResult = ReturnType<typeof useCyberEmailShareableApplicationLinkMutation>;
export type CyberEmailShareableApplicationLinkMutationResult = ApolloReactCommon.MutationResult<CyberEmailShareableApplicationLinkMutation>;
export type CyberEmailShareableApplicationLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberEmailShareableApplicationLinkMutation, CyberEmailShareableApplicationLinkMutationVariables>;
export const CyberExportQuoteComparisonDocument = gql`
    mutation cyberExportQuoteComparison($input: CyberExportQuoteComparisonInput!) {
  cyberExportQuoteComparison(input: $input) {
    errors
  }
}
    `;
export type CyberExportQuoteComparisonMutationFn = ApolloReactCommon.MutationFunction<CyberExportQuoteComparisonMutation, CyberExportQuoteComparisonMutationVariables>;
export type CyberExportQuoteComparisonProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberExportQuoteComparisonMutation, CyberExportQuoteComparisonMutationVariables>
    } & TChildProps;
export function withCyberExportQuoteComparison<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberExportQuoteComparisonMutation,
  CyberExportQuoteComparisonMutationVariables,
  CyberExportQuoteComparisonProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberExportQuoteComparisonMutation, CyberExportQuoteComparisonMutationVariables, CyberExportQuoteComparisonProps<TChildProps, TDataName>>(CyberExportQuoteComparisonDocument, {
      alias: 'cyberExportQuoteComparison',
      ...operationOptions
    });
};

/**
 * __useCyberExportQuoteComparisonMutation__
 *
 * To run a mutation, you first call `useCyberExportQuoteComparisonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberExportQuoteComparisonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberExportQuoteComparisonMutation, { data, loading, error }] = useCyberExportQuoteComparisonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberExportQuoteComparisonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberExportQuoteComparisonMutation, CyberExportQuoteComparisonMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberExportQuoteComparisonMutation, CyberExportQuoteComparisonMutationVariables>(CyberExportQuoteComparisonDocument, baseOptions);
      }
export type CyberExportQuoteComparisonMutationHookResult = ReturnType<typeof useCyberExportQuoteComparisonMutation>;
export type CyberExportQuoteComparisonMutationResult = ApolloReactCommon.MutationResult<CyberExportQuoteComparisonMutation>;
export type CyberExportQuoteComparisonMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberExportQuoteComparisonMutation, CyberExportQuoteComparisonMutationVariables>;
export const InviteRetailBrokerDocument = gql`
    mutation inviteRetailBroker($input: InviteRetailBrokerInput!) {
  inviteRetailBroker(input: $input) {
    company {
      id
      name
    }
    errors
  }
}
    `;
export type InviteRetailBrokerMutationFn = ApolloReactCommon.MutationFunction<InviteRetailBrokerMutation, InviteRetailBrokerMutationVariables>;
export type InviteRetailBrokerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<InviteRetailBrokerMutation, InviteRetailBrokerMutationVariables>
    } & TChildProps;
export function withInviteRetailBroker<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InviteRetailBrokerMutation,
  InviteRetailBrokerMutationVariables,
  InviteRetailBrokerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, InviteRetailBrokerMutation, InviteRetailBrokerMutationVariables, InviteRetailBrokerProps<TChildProps, TDataName>>(InviteRetailBrokerDocument, {
      alias: 'inviteRetailBroker',
      ...operationOptions
    });
};

/**
 * __useInviteRetailBrokerMutation__
 *
 * To run a mutation, you first call `useInviteRetailBrokerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteRetailBrokerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteRetailBrokerMutation, { data, loading, error }] = useInviteRetailBrokerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteRetailBrokerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteRetailBrokerMutation, InviteRetailBrokerMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteRetailBrokerMutation, InviteRetailBrokerMutationVariables>(InviteRetailBrokerDocument, baseOptions);
      }
export type InviteRetailBrokerMutationHookResult = ReturnType<typeof useInviteRetailBrokerMutation>;
export type InviteRetailBrokerMutationResult = ApolloReactCommon.MutationResult<InviteRetailBrokerMutation>;
export type InviteRetailBrokerMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteRetailBrokerMutation, InviteRetailBrokerMutationVariables>;
export const CyberIssuePolicyDocument = gql`
    mutation cyberIssuePolicy($input: CyberIssuePolicyInput!) {
  cyberIssuePolicy(input: $input) {
    quotation {
      id
      status
      statusMessage
      documents {
        id
        category
        version
        ...DocumentForForm
      }
    }
    errors {
      policyNumber
    }
  }
}
    ${DocumentForFormFragmentDoc}`;
export type CyberIssuePolicyMutationFn = ApolloReactCommon.MutationFunction<CyberIssuePolicyMutation, CyberIssuePolicyMutationVariables>;
export type CyberIssuePolicyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberIssuePolicyMutation, CyberIssuePolicyMutationVariables>
    } & TChildProps;
export function withCyberIssuePolicy<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberIssuePolicyMutation,
  CyberIssuePolicyMutationVariables,
  CyberIssuePolicyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberIssuePolicyMutation, CyberIssuePolicyMutationVariables, CyberIssuePolicyProps<TChildProps, TDataName>>(CyberIssuePolicyDocument, {
      alias: 'cyberIssuePolicy',
      ...operationOptions
    });
};

/**
 * __useCyberIssuePolicyMutation__
 *
 * To run a mutation, you first call `useCyberIssuePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberIssuePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberIssuePolicyMutation, { data, loading, error }] = useCyberIssuePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberIssuePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberIssuePolicyMutation, CyberIssuePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberIssuePolicyMutation, CyberIssuePolicyMutationVariables>(CyberIssuePolicyDocument, baseOptions);
      }
export type CyberIssuePolicyMutationHookResult = ReturnType<typeof useCyberIssuePolicyMutation>;
export type CyberIssuePolicyMutationResult = ApolloReactCommon.MutationResult<CyberIssuePolicyMutation>;
export type CyberIssuePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberIssuePolicyMutation, CyberIssuePolicyMutationVariables>;
export const CyberManualSubjectivitiesDocument = gql`
    mutation cyberManualSubjectivities($input: CyberManualSubjectivitiesInput!) {
  cyberManualSubjectivities(input: $input) {
    participation {
      id
      subjectivities {
        nodes {
          id
          text
        }
      }
    }
    errors
  }
}
    `;
export type CyberManualSubjectivitiesMutationFn = ApolloReactCommon.MutationFunction<CyberManualSubjectivitiesMutation, CyberManualSubjectivitiesMutationVariables>;
export type CyberManualSubjectivitiesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberManualSubjectivitiesMutation, CyberManualSubjectivitiesMutationVariables>
    } & TChildProps;
export function withCyberManualSubjectivities<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberManualSubjectivitiesMutation,
  CyberManualSubjectivitiesMutationVariables,
  CyberManualSubjectivitiesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberManualSubjectivitiesMutation, CyberManualSubjectivitiesMutationVariables, CyberManualSubjectivitiesProps<TChildProps, TDataName>>(CyberManualSubjectivitiesDocument, {
      alias: 'cyberManualSubjectivities',
      ...operationOptions
    });
};

/**
 * __useCyberManualSubjectivitiesMutation__
 *
 * To run a mutation, you first call `useCyberManualSubjectivitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberManualSubjectivitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberManualSubjectivitiesMutation, { data, loading, error }] = useCyberManualSubjectivitiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberManualSubjectivitiesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberManualSubjectivitiesMutation, CyberManualSubjectivitiesMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberManualSubjectivitiesMutation, CyberManualSubjectivitiesMutationVariables>(CyberManualSubjectivitiesDocument, baseOptions);
      }
export type CyberManualSubjectivitiesMutationHookResult = ReturnType<typeof useCyberManualSubjectivitiesMutation>;
export type CyberManualSubjectivitiesMutationResult = ApolloReactCommon.MutationResult<CyberManualSubjectivitiesMutation>;
export type CyberManualSubjectivitiesMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberManualSubjectivitiesMutation, CyberManualSubjectivitiesMutationVariables>;
export const CyberReinstateEmailQuotesDocument = gql`
    mutation cyberReinstateEmailQuotes($input: CyberReinstateEmailQuotesInput!) {
  cyberReinstateEmailQuotes(input: $input) {
    quotes {
      id
      status
      participation {
        id
        documents {
          id
          ...Document
        }
        owner {
          id
          fullName
        }
        user {
          id
          fullName
        }
        discussion {
          id
          ...Messages
        }
        submission {
          id
          ...CyberSubmissionInfo
        }
        quotations {
          nodes {
            id
            ...CyberQuotationInfo
          }
        }
      }
    }
    error
  }
}
    ${DocumentFragmentDoc}
${MessagesFragmentDoc}
${CyberSubmissionInfoFragmentDoc}
${CyberQuotationInfoFragmentDoc}`;
export type CyberReinstateEmailQuotesMutationFn = ApolloReactCommon.MutationFunction<CyberReinstateEmailQuotesMutation, CyberReinstateEmailQuotesMutationVariables>;
export type CyberReinstateEmailQuotesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberReinstateEmailQuotesMutation, CyberReinstateEmailQuotesMutationVariables>
    } & TChildProps;
export function withCyberReinstateEmailQuotes<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberReinstateEmailQuotesMutation,
  CyberReinstateEmailQuotesMutationVariables,
  CyberReinstateEmailQuotesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberReinstateEmailQuotesMutation, CyberReinstateEmailQuotesMutationVariables, CyberReinstateEmailQuotesProps<TChildProps, TDataName>>(CyberReinstateEmailQuotesDocument, {
      alias: 'cyberReinstateEmailQuotes',
      ...operationOptions
    });
};

/**
 * __useCyberReinstateEmailQuotesMutation__
 *
 * To run a mutation, you first call `useCyberReinstateEmailQuotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberReinstateEmailQuotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberReinstateEmailQuotesMutation, { data, loading, error }] = useCyberReinstateEmailQuotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberReinstateEmailQuotesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberReinstateEmailQuotesMutation, CyberReinstateEmailQuotesMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberReinstateEmailQuotesMutation, CyberReinstateEmailQuotesMutationVariables>(CyberReinstateEmailQuotesDocument, baseOptions);
      }
export type CyberReinstateEmailQuotesMutationHookResult = ReturnType<typeof useCyberReinstateEmailQuotesMutation>;
export type CyberReinstateEmailQuotesMutationResult = ApolloReactCommon.MutationResult<CyberReinstateEmailQuotesMutation>;
export type CyberReinstateEmailQuotesMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberReinstateEmailQuotesMutation, CyberReinstateEmailQuotesMutationVariables>;
export const CyberRequestToBindDocument = gql`
    mutation cyberRequestToBind($input: CyberRequestToBindInput!) {
  cyberRequestToBind(input: $input) {
    quotation {
      id
      status
      statusMessage
      submission {
        id
        status
      }
    }
    error
  }
}
    `;
export type CyberRequestToBindMutationFn = ApolloReactCommon.MutationFunction<CyberRequestToBindMutation, CyberRequestToBindMutationVariables>;
export type CyberRequestToBindProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberRequestToBindMutation, CyberRequestToBindMutationVariables>
    } & TChildProps;
export function withCyberRequestToBind<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberRequestToBindMutation,
  CyberRequestToBindMutationVariables,
  CyberRequestToBindProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberRequestToBindMutation, CyberRequestToBindMutationVariables, CyberRequestToBindProps<TChildProps, TDataName>>(CyberRequestToBindDocument, {
      alias: 'cyberRequestToBind',
      ...operationOptions
    });
};

/**
 * __useCyberRequestToBindMutation__
 *
 * To run a mutation, you first call `useCyberRequestToBindMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberRequestToBindMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberRequestToBindMutation, { data, loading, error }] = useCyberRequestToBindMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberRequestToBindMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberRequestToBindMutation, CyberRequestToBindMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberRequestToBindMutation, CyberRequestToBindMutationVariables>(CyberRequestToBindDocument, baseOptions);
      }
export type CyberRequestToBindMutationHookResult = ReturnType<typeof useCyberRequestToBindMutation>;
export type CyberRequestToBindMutationResult = ApolloReactCommon.MutationResult<CyberRequestToBindMutation>;
export type CyberRequestToBindMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberRequestToBindMutation, CyberRequestToBindMutationVariables>;
export const CyberSuggestCodesSubmissionDocument = gql`
    mutation cyberSuggestCodesSubmission($input: CyberSuggestCodesSubmissionInput!) {
  cyberSuggestCodesSubmission(input: $input) {
    suggestions
    error
  }
}
    `;
export type CyberSuggestCodesSubmissionMutationFn = ApolloReactCommon.MutationFunction<CyberSuggestCodesSubmissionMutation, CyberSuggestCodesSubmissionMutationVariables>;
export type CyberSuggestCodesSubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberSuggestCodesSubmissionMutation, CyberSuggestCodesSubmissionMutationVariables>
    } & TChildProps;
export function withCyberSuggestCodesSubmission<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberSuggestCodesSubmissionMutation,
  CyberSuggestCodesSubmissionMutationVariables,
  CyberSuggestCodesSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberSuggestCodesSubmissionMutation, CyberSuggestCodesSubmissionMutationVariables, CyberSuggestCodesSubmissionProps<TChildProps, TDataName>>(CyberSuggestCodesSubmissionDocument, {
      alias: 'cyberSuggestCodesSubmission',
      ...operationOptions
    });
};

/**
 * __useCyberSuggestCodesSubmissionMutation__
 *
 * To run a mutation, you first call `useCyberSuggestCodesSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberSuggestCodesSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberSuggestCodesSubmissionMutation, { data, loading, error }] = useCyberSuggestCodesSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberSuggestCodesSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberSuggestCodesSubmissionMutation, CyberSuggestCodesSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberSuggestCodesSubmissionMutation, CyberSuggestCodesSubmissionMutationVariables>(CyberSuggestCodesSubmissionDocument, baseOptions);
      }
export type CyberSuggestCodesSubmissionMutationHookResult = ReturnType<typeof useCyberSuggestCodesSubmissionMutation>;
export type CyberSuggestCodesSubmissionMutationResult = ApolloReactCommon.MutationResult<CyberSuggestCodesSubmissionMutation>;
export type CyberSuggestCodesSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberSuggestCodesSubmissionMutation, CyberSuggestCodesSubmissionMutationVariables>;
export const CyberUpdateManualQuoteDocument = gql`
    mutation cyberUpdateManualQuote($input: CyberUpdateManualQuoteInput!) {
  cyberUpdateManualQuote(input: $input) {
    quotation {
      id
      limit
      deductible
      premium
      fee
      surplusLinesTax
      totalCost
      admitted
      declinationReason
      signingUrl
      policyNumber
      comments
      coverages {
        name
        type
        limit
        deductible
        periodOfIndemnity
        waitingPeriod
      }
      documents {
        id
        ...DocumentForForm
      }
      endorsements {
        name
        limit
        coinsurance
      }
      externalCarrier {
        id
        name
      }
    }
    errors {
      base
      admitted
      carrierName
      comments
      coverages {
        name
        limit
        deductible
      }
      declinationReason
      deductible
      endorsements {
        name
        limit
        coinsurance
      }
      fee
      limit
      policyNumber
      premium
    }
  }
}
    ${DocumentForFormFragmentDoc}`;
export type CyberUpdateManualQuoteMutationFn = ApolloReactCommon.MutationFunction<CyberUpdateManualQuoteMutation, CyberUpdateManualQuoteMutationVariables>;
export type CyberUpdateManualQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CyberUpdateManualQuoteMutation, CyberUpdateManualQuoteMutationVariables>
    } & TChildProps;
export function withCyberUpdateManualQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberUpdateManualQuoteMutation,
  CyberUpdateManualQuoteMutationVariables,
  CyberUpdateManualQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CyberUpdateManualQuoteMutation, CyberUpdateManualQuoteMutationVariables, CyberUpdateManualQuoteProps<TChildProps, TDataName>>(CyberUpdateManualQuoteDocument, {
      alias: 'cyberUpdateManualQuote',
      ...operationOptions
    });
};

/**
 * __useCyberUpdateManualQuoteMutation__
 *
 * To run a mutation, you first call `useCyberUpdateManualQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCyberUpdateManualQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cyberUpdateManualQuoteMutation, { data, loading, error }] = useCyberUpdateManualQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCyberUpdateManualQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CyberUpdateManualQuoteMutation, CyberUpdateManualQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CyberUpdateManualQuoteMutation, CyberUpdateManualQuoteMutationVariables>(CyberUpdateManualQuoteDocument, baseOptions);
      }
export type CyberUpdateManualQuoteMutationHookResult = ReturnType<typeof useCyberUpdateManualQuoteMutation>;
export type CyberUpdateManualQuoteMutationResult = ApolloReactCommon.MutationResult<CyberUpdateManualQuoteMutation>;
export type CyberUpdateManualQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CyberUpdateManualQuoteMutation, CyberUpdateManualQuoteMutationVariables>;
export const DeclineQuoteProposalDocument = gql`
    mutation declineQuoteProposal($input: DeclineProposalInput!) {
  declineProposal(input: $input) {
    quote {
      id
      status
      readableStatus: status(readable: true)
      ...LatestRevision
      revisions(last: 1) {
        nodes {
          id
          attachmentPoint
          cedingCommission
          deductible
          documents {
            nodes {
              id
            }
          }
          quotedLayers {
            ...QuotedLayerInfo
          }
          exclusions
          expiryDate
          keyPerils
          layerLimit
          netPremium
          participation
          premium
          status
          createdAt
          number
          isIncomplete
          zip {
            id
            url
          }
        }
      }
      worksheet {
        id
      }
    }
  }
}
    ${LatestRevisionFragmentDoc}
${QuotedLayerInfoFragmentDoc}`;
export type DeclineQuoteProposalMutationFn = ApolloReactCommon.MutationFunction<DeclineQuoteProposalMutation, DeclineQuoteProposalMutationVariables>;
export type DeclineQuoteProposalProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeclineQuoteProposalMutation, DeclineQuoteProposalMutationVariables>
    } & TChildProps;
export function withDeclineQuoteProposal<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeclineQuoteProposalMutation,
  DeclineQuoteProposalMutationVariables,
  DeclineQuoteProposalProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeclineQuoteProposalMutation, DeclineQuoteProposalMutationVariables, DeclineQuoteProposalProps<TChildProps, TDataName>>(DeclineQuoteProposalDocument, {
      alias: 'declineQuoteProposal',
      ...operationOptions
    });
};

/**
 * __useDeclineQuoteProposalMutation__
 *
 * To run a mutation, you first call `useDeclineQuoteProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineQuoteProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineQuoteProposalMutation, { data, loading, error }] = useDeclineQuoteProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineQuoteProposalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclineQuoteProposalMutation, DeclineQuoteProposalMutationVariables>) {
        return ApolloReactHooks.useMutation<DeclineQuoteProposalMutation, DeclineQuoteProposalMutationVariables>(DeclineQuoteProposalDocument, baseOptions);
      }
export type DeclineQuoteProposalMutationHookResult = ReturnType<typeof useDeclineQuoteProposalMutation>;
export type DeclineQuoteProposalMutationResult = ApolloReactCommon.MutationResult<DeclineQuoteProposalMutation>;
export type DeclineQuoteProposalMutationOptions = ApolloReactCommon.BaseMutationOptions<DeclineQuoteProposalMutation, DeclineQuoteProposalMutationVariables>;
export const DeleteAuthorizedMarketContactDocument = gql`
    mutation deleteAuthorizedMarketContact($input: DeleteAuthorizedMarketContactInput!) {
  deleteAuthorizedMarketContact(input: $input) {
    authorizedMarket {
      id
      disabled
      specialInstructions
      reinsuranceCompany {
        id
        name
      }
      contacts {
        nodes {
          id
          description
          reinsurer {
            id
            email
            firstName
            lastName
            fullName
            companyName
            avatarUrl
            pendingInvitation
          }
        }
      }
    }
    errors
  }
}
    `;
export type DeleteAuthorizedMarketContactMutationFn = ApolloReactCommon.MutationFunction<DeleteAuthorizedMarketContactMutation, DeleteAuthorizedMarketContactMutationVariables>;
export type DeleteAuthorizedMarketContactProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteAuthorizedMarketContactMutation, DeleteAuthorizedMarketContactMutationVariables>
    } & TChildProps;
export function withDeleteAuthorizedMarketContact<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAuthorizedMarketContactMutation,
  DeleteAuthorizedMarketContactMutationVariables,
  DeleteAuthorizedMarketContactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAuthorizedMarketContactMutation, DeleteAuthorizedMarketContactMutationVariables, DeleteAuthorizedMarketContactProps<TChildProps, TDataName>>(DeleteAuthorizedMarketContactDocument, {
      alias: 'deleteAuthorizedMarketContact',
      ...operationOptions
    });
};

/**
 * __useDeleteAuthorizedMarketContactMutation__
 *
 * To run a mutation, you first call `useDeleteAuthorizedMarketContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuthorizedMarketContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuthorizedMarketContactMutation, { data, loading, error }] = useDeleteAuthorizedMarketContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAuthorizedMarketContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAuthorizedMarketContactMutation, DeleteAuthorizedMarketContactMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAuthorizedMarketContactMutation, DeleteAuthorizedMarketContactMutationVariables>(DeleteAuthorizedMarketContactDocument, baseOptions);
      }
export type DeleteAuthorizedMarketContactMutationHookResult = ReturnType<typeof useDeleteAuthorizedMarketContactMutation>;
export type DeleteAuthorizedMarketContactMutationResult = ApolloReactCommon.MutationResult<DeleteAuthorizedMarketContactMutation>;
export type DeleteAuthorizedMarketContactMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAuthorizedMarketContactMutation, DeleteAuthorizedMarketContactMutationVariables>;
export const ForwardDocumentDocument = gql`
    mutation forwardDocument($input: DocumentForwardInput!) {
  forwardDocument(input: $input) {
    participations {
      id
      documents {
        id
      }
    }
    errors {
      id
    }
  }
}
    `;
export type ForwardDocumentMutationFn = ApolloReactCommon.MutationFunction<ForwardDocumentMutation, ForwardDocumentMutationVariables>;
export type ForwardDocumentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ForwardDocumentMutation, ForwardDocumentMutationVariables>
    } & TChildProps;
export function withForwardDocument<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ForwardDocumentMutation,
  ForwardDocumentMutationVariables,
  ForwardDocumentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ForwardDocumentMutation, ForwardDocumentMutationVariables, ForwardDocumentProps<TChildProps, TDataName>>(ForwardDocumentDocument, {
      alias: 'forwardDocument',
      ...operationOptions
    });
};

/**
 * __useForwardDocumentMutation__
 *
 * To run a mutation, you first call `useForwardDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForwardDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forwardDocumentMutation, { data, loading, error }] = useForwardDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForwardDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForwardDocumentMutation, ForwardDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<ForwardDocumentMutation, ForwardDocumentMutationVariables>(ForwardDocumentDocument, baseOptions);
      }
export type ForwardDocumentMutationHookResult = ReturnType<typeof useForwardDocumentMutation>;
export type ForwardDocumentMutationResult = ApolloReactCommon.MutationResult<ForwardDocumentMutation>;
export type ForwardDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<ForwardDocumentMutation, ForwardDocumentMutationVariables>;
export const UpdateParticipationDocumentsDocument = gql`
    mutation updateParticipationDocuments($input: UpdateParticipationDocumentsInput!) {
  updateParticipationDocuments(input: $input) {
    participation {
      id
      documents {
        id
        ...Document
      }
    }
    errors
  }
}
    ${DocumentFragmentDoc}`;
export type UpdateParticipationDocumentsMutationFn = ApolloReactCommon.MutationFunction<UpdateParticipationDocumentsMutation, UpdateParticipationDocumentsMutationVariables>;
export type UpdateParticipationDocumentsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateParticipationDocumentsMutation, UpdateParticipationDocumentsMutationVariables>
    } & TChildProps;
export function withUpdateParticipationDocuments<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateParticipationDocumentsMutation,
  UpdateParticipationDocumentsMutationVariables,
  UpdateParticipationDocumentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateParticipationDocumentsMutation, UpdateParticipationDocumentsMutationVariables, UpdateParticipationDocumentsProps<TChildProps, TDataName>>(UpdateParticipationDocumentsDocument, {
      alias: 'updateParticipationDocuments',
      ...operationOptions
    });
};

/**
 * __useUpdateParticipationDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateParticipationDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipationDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipationDocumentsMutation, { data, loading, error }] = useUpdateParticipationDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateParticipationDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateParticipationDocumentsMutation, UpdateParticipationDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateParticipationDocumentsMutation, UpdateParticipationDocumentsMutationVariables>(UpdateParticipationDocumentsDocument, baseOptions);
      }
export type UpdateParticipationDocumentsMutationHookResult = ReturnType<typeof useUpdateParticipationDocumentsMutation>;
export type UpdateParticipationDocumentsMutationResult = ApolloReactCommon.MutationResult<UpdateParticipationDocumentsMutation>;
export type UpdateParticipationDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateParticipationDocumentsMutation, UpdateParticipationDocumentsMutationVariables>;
export const CompleteEndorsementDocument = gql`
    mutation completeEndorsement($input: CompleteEndorsementInput!) {
  completeEndorsement(input: $input) {
    endorsement {
      id
      ...EndorsementInfo
    }
    errors
  }
}
    ${EndorsementInfoFragmentDoc}`;
export type CompleteEndorsementMutationFn = ApolloReactCommon.MutationFunction<CompleteEndorsementMutation, CompleteEndorsementMutationVariables>;
export type CompleteEndorsementProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CompleteEndorsementMutation, CompleteEndorsementMutationVariables>
    } & TChildProps;
export function withCompleteEndorsement<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CompleteEndorsementMutation,
  CompleteEndorsementMutationVariables,
  CompleteEndorsementProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CompleteEndorsementMutation, CompleteEndorsementMutationVariables, CompleteEndorsementProps<TChildProps, TDataName>>(CompleteEndorsementDocument, {
      alias: 'completeEndorsement',
      ...operationOptions
    });
};

/**
 * __useCompleteEndorsementMutation__
 *
 * To run a mutation, you first call `useCompleteEndorsementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteEndorsementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeEndorsementMutation, { data, loading, error }] = useCompleteEndorsementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteEndorsementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteEndorsementMutation, CompleteEndorsementMutationVariables>) {
        return ApolloReactHooks.useMutation<CompleteEndorsementMutation, CompleteEndorsementMutationVariables>(CompleteEndorsementDocument, baseOptions);
      }
export type CompleteEndorsementMutationHookResult = ReturnType<typeof useCompleteEndorsementMutation>;
export type CompleteEndorsementMutationResult = ApolloReactCommon.MutationResult<CompleteEndorsementMutation>;
export type CompleteEndorsementMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteEndorsementMutation, CompleteEndorsementMutationVariables>;
export const CreateEndorsementDocument = gql`
    mutation createEndorsement($input: CreateEndorsementInput!) {
  createEndorsement(input: $input) {
    endorsement {
      id
      ...EndorsementInfo
    }
    errors
  }
}
    ${EndorsementInfoFragmentDoc}`;
export type CreateEndorsementMutationFn = ApolloReactCommon.MutationFunction<CreateEndorsementMutation, CreateEndorsementMutationVariables>;
export type CreateEndorsementProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateEndorsementMutation, CreateEndorsementMutationVariables>
    } & TChildProps;
export function withCreateEndorsement<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateEndorsementMutation,
  CreateEndorsementMutationVariables,
  CreateEndorsementProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateEndorsementMutation, CreateEndorsementMutationVariables, CreateEndorsementProps<TChildProps, TDataName>>(CreateEndorsementDocument, {
      alias: 'createEndorsement',
      ...operationOptions
    });
};

/**
 * __useCreateEndorsementMutation__
 *
 * To run a mutation, you first call `useCreateEndorsementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEndorsementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEndorsementMutation, { data, loading, error }] = useCreateEndorsementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEndorsementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEndorsementMutation, CreateEndorsementMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEndorsementMutation, CreateEndorsementMutationVariables>(CreateEndorsementDocument, baseOptions);
      }
export type CreateEndorsementMutationHookResult = ReturnType<typeof useCreateEndorsementMutation>;
export type CreateEndorsementMutationResult = ApolloReactCommon.MutationResult<CreateEndorsementMutation>;
export type CreateEndorsementMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEndorsementMutation, CreateEndorsementMutationVariables>;
export const DropEndorsementDocument = gql`
    mutation dropEndorsement($input: DropEndorsementInput!) {
  dropEndorsement(input: $input) {
    endorsement {
      id
      ...EndorsementInfo
    }
    errors
  }
}
    ${EndorsementInfoFragmentDoc}`;
export type DropEndorsementMutationFn = ApolloReactCommon.MutationFunction<DropEndorsementMutation, DropEndorsementMutationVariables>;
export type DropEndorsementProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DropEndorsementMutation, DropEndorsementMutationVariables>
    } & TChildProps;
export function withDropEndorsement<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DropEndorsementMutation,
  DropEndorsementMutationVariables,
  DropEndorsementProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DropEndorsementMutation, DropEndorsementMutationVariables, DropEndorsementProps<TChildProps, TDataName>>(DropEndorsementDocument, {
      alias: 'dropEndorsement',
      ...operationOptions
    });
};

/**
 * __useDropEndorsementMutation__
 *
 * To run a mutation, you first call `useDropEndorsementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDropEndorsementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dropEndorsementMutation, { data, loading, error }] = useDropEndorsementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDropEndorsementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DropEndorsementMutation, DropEndorsementMutationVariables>) {
        return ApolloReactHooks.useMutation<DropEndorsementMutation, DropEndorsementMutationVariables>(DropEndorsementDocument, baseOptions);
      }
export type DropEndorsementMutationHookResult = ReturnType<typeof useDropEndorsementMutation>;
export type DropEndorsementMutationResult = ApolloReactCommon.MutationResult<DropEndorsementMutation>;
export type DropEndorsementMutationOptions = ApolloReactCommon.BaseMutationOptions<DropEndorsementMutation, DropEndorsementMutationVariables>;
export const StartEndorsementDocument = gql`
    mutation startEndorsement($input: StartEndorsementInput!) {
  startEndorsement(input: $input) {
    endorsement {
      id
      ...EndorsementInfo
    }
    errors
  }
}
    ${EndorsementInfoFragmentDoc}`;
export type StartEndorsementMutationFn = ApolloReactCommon.MutationFunction<StartEndorsementMutation, StartEndorsementMutationVariables>;
export type StartEndorsementProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<StartEndorsementMutation, StartEndorsementMutationVariables>
    } & TChildProps;
export function withStartEndorsement<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StartEndorsementMutation,
  StartEndorsementMutationVariables,
  StartEndorsementProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, StartEndorsementMutation, StartEndorsementMutationVariables, StartEndorsementProps<TChildProps, TDataName>>(StartEndorsementDocument, {
      alias: 'startEndorsement',
      ...operationOptions
    });
};

/**
 * __useStartEndorsementMutation__
 *
 * To run a mutation, you first call `useStartEndorsementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartEndorsementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startEndorsementMutation, { data, loading, error }] = useStartEndorsementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartEndorsementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartEndorsementMutation, StartEndorsementMutationVariables>) {
        return ApolloReactHooks.useMutation<StartEndorsementMutation, StartEndorsementMutationVariables>(StartEndorsementDocument, baseOptions);
      }
export type StartEndorsementMutationHookResult = ReturnType<typeof useStartEndorsementMutation>;
export type StartEndorsementMutationResult = ApolloReactCommon.MutationResult<StartEndorsementMutation>;
export type StartEndorsementMutationOptions = ApolloReactCommon.BaseMutationOptions<StartEndorsementMutation, StartEndorsementMutationVariables>;
export const UpdateEndorsementDocument = gql`
    mutation updateEndorsement($input: UpdateEndorsementInput!) {
  updateEndorsement(input: $input) {
    endorsement {
      id
      ...EndorsementInfo
    }
    errors
  }
}
    ${EndorsementInfoFragmentDoc}`;
export type UpdateEndorsementMutationFn = ApolloReactCommon.MutationFunction<UpdateEndorsementMutation, UpdateEndorsementMutationVariables>;
export type UpdateEndorsementProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateEndorsementMutation, UpdateEndorsementMutationVariables>
    } & TChildProps;
export function withUpdateEndorsement<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEndorsementMutation,
  UpdateEndorsementMutationVariables,
  UpdateEndorsementProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateEndorsementMutation, UpdateEndorsementMutationVariables, UpdateEndorsementProps<TChildProps, TDataName>>(UpdateEndorsementDocument, {
      alias: 'updateEndorsement',
      ...operationOptions
    });
};

/**
 * __useUpdateEndorsementMutation__
 *
 * To run a mutation, you first call `useUpdateEndorsementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEndorsementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEndorsementMutation, { data, loading, error }] = useUpdateEndorsementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEndorsementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEndorsementMutation, UpdateEndorsementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEndorsementMutation, UpdateEndorsementMutationVariables>(UpdateEndorsementDocument, baseOptions);
      }
export type UpdateEndorsementMutationHookResult = ReturnType<typeof useUpdateEndorsementMutation>;
export type UpdateEndorsementMutationResult = ApolloReactCommon.MutationResult<UpdateEndorsementMutation>;
export type UpdateEndorsementMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEndorsementMutation, UpdateEndorsementMutationVariables>;
export const AcceptEndorsementQuoteDocument = gql`
    mutation acceptEndorsementQuote($input: AcceptEndorsementQuoteInput!) {
  acceptEndorsementQuote(input: $input) {
    endorsementQuote {
      id
      status
      endorsement {
        id
        worksheet {
          id
        }
      }
    }
    errors
  }
}
    `;
export type AcceptEndorsementQuoteMutationFn = ApolloReactCommon.MutationFunction<AcceptEndorsementQuoteMutation, AcceptEndorsementQuoteMutationVariables>;
export type AcceptEndorsementQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AcceptEndorsementQuoteMutation, AcceptEndorsementQuoteMutationVariables>
    } & TChildProps;
export function withAcceptEndorsementQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AcceptEndorsementQuoteMutation,
  AcceptEndorsementQuoteMutationVariables,
  AcceptEndorsementQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AcceptEndorsementQuoteMutation, AcceptEndorsementQuoteMutationVariables, AcceptEndorsementQuoteProps<TChildProps, TDataName>>(AcceptEndorsementQuoteDocument, {
      alias: 'acceptEndorsementQuote',
      ...operationOptions
    });
};

/**
 * __useAcceptEndorsementQuoteMutation__
 *
 * To run a mutation, you first call `useAcceptEndorsementQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptEndorsementQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptEndorsementQuoteMutation, { data, loading, error }] = useAcceptEndorsementQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptEndorsementQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptEndorsementQuoteMutation, AcceptEndorsementQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptEndorsementQuoteMutation, AcceptEndorsementQuoteMutationVariables>(AcceptEndorsementQuoteDocument, baseOptions);
      }
export type AcceptEndorsementQuoteMutationHookResult = ReturnType<typeof useAcceptEndorsementQuoteMutation>;
export type AcceptEndorsementQuoteMutationResult = ApolloReactCommon.MutationResult<AcceptEndorsementQuoteMutation>;
export type AcceptEndorsementQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptEndorsementQuoteMutation, AcceptEndorsementQuoteMutationVariables>;
export const CompleteEndorsementQuoteDocument = gql`
    mutation completeEndorsementQuote($input: CompleteEndorsementQuoteInput!) {
  completeEndorsementQuote(input: $input) {
    endorsementQuote {
      id
      status
      endorsement {
        id
        worksheet {
          id
        }
      }
    }
    errors
  }
}
    `;
export type CompleteEndorsementQuoteMutationFn = ApolloReactCommon.MutationFunction<CompleteEndorsementQuoteMutation, CompleteEndorsementQuoteMutationVariables>;
export type CompleteEndorsementQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CompleteEndorsementQuoteMutation, CompleteEndorsementQuoteMutationVariables>
    } & TChildProps;
export function withCompleteEndorsementQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CompleteEndorsementQuoteMutation,
  CompleteEndorsementQuoteMutationVariables,
  CompleteEndorsementQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CompleteEndorsementQuoteMutation, CompleteEndorsementQuoteMutationVariables, CompleteEndorsementQuoteProps<TChildProps, TDataName>>(CompleteEndorsementQuoteDocument, {
      alias: 'completeEndorsementQuote',
      ...operationOptions
    });
};

/**
 * __useCompleteEndorsementQuoteMutation__
 *
 * To run a mutation, you first call `useCompleteEndorsementQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteEndorsementQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeEndorsementQuoteMutation, { data, loading, error }] = useCompleteEndorsementQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteEndorsementQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteEndorsementQuoteMutation, CompleteEndorsementQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CompleteEndorsementQuoteMutation, CompleteEndorsementQuoteMutationVariables>(CompleteEndorsementQuoteDocument, baseOptions);
      }
export type CompleteEndorsementQuoteMutationHookResult = ReturnType<typeof useCompleteEndorsementQuoteMutation>;
export type CompleteEndorsementQuoteMutationResult = ApolloReactCommon.MutationResult<CompleteEndorsementQuoteMutation>;
export type CompleteEndorsementQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteEndorsementQuoteMutation, CompleteEndorsementQuoteMutationVariables>;
export const DeclineEndorsementQuoteDocument = gql`
    mutation declineEndorsementQuote($input: DeclineEndorsementQuoteInput!) {
  declineEndorsementQuote(input: $input) {
    endorsementQuote {
      id
      status
      endorsement {
        id
        worksheet {
          id
        }
      }
    }
    errors
  }
}
    `;
export type DeclineEndorsementQuoteMutationFn = ApolloReactCommon.MutationFunction<DeclineEndorsementQuoteMutation, DeclineEndorsementQuoteMutationVariables>;
export type DeclineEndorsementQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeclineEndorsementQuoteMutation, DeclineEndorsementQuoteMutationVariables>
    } & TChildProps;
export function withDeclineEndorsementQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeclineEndorsementQuoteMutation,
  DeclineEndorsementQuoteMutationVariables,
  DeclineEndorsementQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeclineEndorsementQuoteMutation, DeclineEndorsementQuoteMutationVariables, DeclineEndorsementQuoteProps<TChildProps, TDataName>>(DeclineEndorsementQuoteDocument, {
      alias: 'declineEndorsementQuote',
      ...operationOptions
    });
};

/**
 * __useDeclineEndorsementQuoteMutation__
 *
 * To run a mutation, you first call `useDeclineEndorsementQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineEndorsementQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineEndorsementQuoteMutation, { data, loading, error }] = useDeclineEndorsementQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineEndorsementQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclineEndorsementQuoteMutation, DeclineEndorsementQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeclineEndorsementQuoteMutation, DeclineEndorsementQuoteMutationVariables>(DeclineEndorsementQuoteDocument, baseOptions);
      }
export type DeclineEndorsementQuoteMutationHookResult = ReturnType<typeof useDeclineEndorsementQuoteMutation>;
export type DeclineEndorsementQuoteMutationResult = ApolloReactCommon.MutationResult<DeclineEndorsementQuoteMutation>;
export type DeclineEndorsementQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeclineEndorsementQuoteMutation, DeclineEndorsementQuoteMutationVariables>;
export const SendEndorsementQuoteDocument = gql`
    mutation sendEndorsementQuote($input: SendEndorsementQuoteInput!) {
  sendEndorsementQuote(input: $input) {
    endorsementQuote {
      id
      status
      endorsement {
        id
        worksheet {
          id
        }
      }
    }
    errors
  }
}
    `;
export type SendEndorsementQuoteMutationFn = ApolloReactCommon.MutationFunction<SendEndorsementQuoteMutation, SendEndorsementQuoteMutationVariables>;
export type SendEndorsementQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SendEndorsementQuoteMutation, SendEndorsementQuoteMutationVariables>
    } & TChildProps;
export function withSendEndorsementQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendEndorsementQuoteMutation,
  SendEndorsementQuoteMutationVariables,
  SendEndorsementQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SendEndorsementQuoteMutation, SendEndorsementQuoteMutationVariables, SendEndorsementQuoteProps<TChildProps, TDataName>>(SendEndorsementQuoteDocument, {
      alias: 'sendEndorsementQuote',
      ...operationOptions
    });
};

/**
 * __useSendEndorsementQuoteMutation__
 *
 * To run a mutation, you first call `useSendEndorsementQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEndorsementQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEndorsementQuoteMutation, { data, loading, error }] = useSendEndorsementQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEndorsementQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEndorsementQuoteMutation, SendEndorsementQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<SendEndorsementQuoteMutation, SendEndorsementQuoteMutationVariables>(SendEndorsementQuoteDocument, baseOptions);
      }
export type SendEndorsementQuoteMutationHookResult = ReturnType<typeof useSendEndorsementQuoteMutation>;
export type SendEndorsementQuoteMutationResult = ApolloReactCommon.MutationResult<SendEndorsementQuoteMutation>;
export type SendEndorsementQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEndorsementQuoteMutation, SendEndorsementQuoteMutationVariables>;
export const ExportBmsQuotesDocument = gql`
    mutation exportBmsQuotes($input: ExportBmsQuotesInput!) {
  exportBmsQuotes(input: $input) {
    worksheet {
      id
    }
    errors
  }
}
    `;
export type ExportBmsQuotesMutationFn = ApolloReactCommon.MutationFunction<ExportBmsQuotesMutation, ExportBmsQuotesMutationVariables>;
export type ExportBmsQuotesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ExportBmsQuotesMutation, ExportBmsQuotesMutationVariables>
    } & TChildProps;
export function withExportBmsQuotes<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExportBmsQuotesMutation,
  ExportBmsQuotesMutationVariables,
  ExportBmsQuotesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ExportBmsQuotesMutation, ExportBmsQuotesMutationVariables, ExportBmsQuotesProps<TChildProps, TDataName>>(ExportBmsQuotesDocument, {
      alias: 'exportBmsQuotes',
      ...operationOptions
    });
};

/**
 * __useExportBmsQuotesMutation__
 *
 * To run a mutation, you first call `useExportBmsQuotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportBmsQuotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportBmsQuotesMutation, { data, loading, error }] = useExportBmsQuotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportBmsQuotesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExportBmsQuotesMutation, ExportBmsQuotesMutationVariables>) {
        return ApolloReactHooks.useMutation<ExportBmsQuotesMutation, ExportBmsQuotesMutationVariables>(ExportBmsQuotesDocument, baseOptions);
      }
export type ExportBmsQuotesMutationHookResult = ReturnType<typeof useExportBmsQuotesMutation>;
export type ExportBmsQuotesMutationResult = ApolloReactCommon.MutationResult<ExportBmsQuotesMutation>;
export type ExportBmsQuotesMutationOptions = ApolloReactCommon.BaseMutationOptions<ExportBmsQuotesMutation, ExportBmsQuotesMutationVariables>;
export const ExportQuotesDocument = gql`
    mutation exportQuotes($input: ExportQuotesInput!) {
  exportQuotes(input: $input) {
    worksheet {
      id
    }
  }
}
    `;
export type ExportQuotesMutationFn = ApolloReactCommon.MutationFunction<ExportQuotesMutation, ExportQuotesMutationVariables>;
export type ExportQuotesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ExportQuotesMutation, ExportQuotesMutationVariables>
    } & TChildProps;
export function withExportQuotes<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExportQuotesMutation,
  ExportQuotesMutationVariables,
  ExportQuotesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ExportQuotesMutation, ExportQuotesMutationVariables, ExportQuotesProps<TChildProps, TDataName>>(ExportQuotesDocument, {
      alias: 'exportQuotes',
      ...operationOptions
    });
};

/**
 * __useExportQuotesMutation__
 *
 * To run a mutation, you first call `useExportQuotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportQuotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportQuotesMutation, { data, loading, error }] = useExportQuotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportQuotesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExportQuotesMutation, ExportQuotesMutationVariables>) {
        return ApolloReactHooks.useMutation<ExportQuotesMutation, ExportQuotesMutationVariables>(ExportQuotesDocument, baseOptions);
      }
export type ExportQuotesMutationHookResult = ReturnType<typeof useExportQuotesMutation>;
export type ExportQuotesMutationResult = ApolloReactCommon.MutationResult<ExportQuotesMutation>;
export type ExportQuotesMutationOptions = ApolloReactCommon.BaseMutationOptions<ExportQuotesMutation, ExportQuotesMutationVariables>;
export const ImportQuoteFromDocumentDocument = gql`
    mutation ImportQuoteFromDocument($input: ImportQuoteExtractionDocumentInput!) {
  importQuoteExtractionDocument(input: $input) {
    errors
    import {
      id
      completedAt
    }
  }
}
    `;
export type ImportQuoteFromDocumentMutationFn = ApolloReactCommon.MutationFunction<ImportQuoteFromDocumentMutation, ImportQuoteFromDocumentMutationVariables>;
export type ImportQuoteFromDocumentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ImportQuoteFromDocumentMutation, ImportQuoteFromDocumentMutationVariables>
    } & TChildProps;
export function withImportQuoteFromDocument<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ImportQuoteFromDocumentMutation,
  ImportQuoteFromDocumentMutationVariables,
  ImportQuoteFromDocumentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ImportQuoteFromDocumentMutation, ImportQuoteFromDocumentMutationVariables, ImportQuoteFromDocumentProps<TChildProps, TDataName>>(ImportQuoteFromDocumentDocument, {
      alias: 'importQuoteFromDocument',
      ...operationOptions
    });
};

/**
 * __useImportQuoteFromDocumentMutation__
 *
 * To run a mutation, you first call `useImportQuoteFromDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportQuoteFromDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importQuoteFromDocumentMutation, { data, loading, error }] = useImportQuoteFromDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportQuoteFromDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportQuoteFromDocumentMutation, ImportQuoteFromDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportQuoteFromDocumentMutation, ImportQuoteFromDocumentMutationVariables>(ImportQuoteFromDocumentDocument, baseOptions);
      }
export type ImportQuoteFromDocumentMutationHookResult = ReturnType<typeof useImportQuoteFromDocumentMutation>;
export type ImportQuoteFromDocumentMutationResult = ApolloReactCommon.MutationResult<ImportQuoteFromDocumentMutation>;
export type ImportQuoteFromDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportQuoteFromDocumentMutation, ImportQuoteFromDocumentMutationVariables>;
export const InviteBrokerDocument = gql`
    mutation InviteBroker($input: InviteBrokerInput!) {
  inviteBroker(input: $input) {
    collaboration {
      id
      __typename
      worksheet {
        id
        collaborations {
          id
          __typename
        }
      }
      user {
        id
        fullName
        company {
          id
          brokers {
            nodes {
              id
              fullName
              company {
                id
                name
              }
            }
          }
        }
      }
    }
    errors
  }
}
    `;
export type InviteBrokerMutationFn = ApolloReactCommon.MutationFunction<InviteBrokerMutation, InviteBrokerMutationVariables>;
export type InviteBrokerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<InviteBrokerMutation, InviteBrokerMutationVariables>
    } & TChildProps;
export function withInviteBroker<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InviteBrokerMutation,
  InviteBrokerMutationVariables,
  InviteBrokerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, InviteBrokerMutation, InviteBrokerMutationVariables, InviteBrokerProps<TChildProps, TDataName>>(InviteBrokerDocument, {
      alias: 'inviteBroker',
      ...operationOptions
    });
};

/**
 * __useInviteBrokerMutation__
 *
 * To run a mutation, you first call `useInviteBrokerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteBrokerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteBrokerMutation, { data, loading, error }] = useInviteBrokerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteBrokerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteBrokerMutation, InviteBrokerMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteBrokerMutation, InviteBrokerMutationVariables>(InviteBrokerDocument, baseOptions);
      }
export type InviteBrokerMutationHookResult = ReturnType<typeof useInviteBrokerMutation>;
export type InviteBrokerMutationResult = ApolloReactCommon.MutationResult<InviteBrokerMutation>;
export type InviteBrokerMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteBrokerMutation, InviteBrokerMutationVariables>;
export const IssueCertificateDocument = gql`
    mutation issueCertificate($input: IssueCertificateInput!) {
  issueCertificate(input: $input) {
    quote {
      id
      ...QuoteInfoFragment
    }
  }
}
    ${QuoteInfoFragmentFragmentDoc}`;
export type IssueCertificateMutationFn = ApolloReactCommon.MutationFunction<IssueCertificateMutation, IssueCertificateMutationVariables>;
export type IssueCertificateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<IssueCertificateMutation, IssueCertificateMutationVariables>
    } & TChildProps;
export function withIssueCertificate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IssueCertificateMutation,
  IssueCertificateMutationVariables,
  IssueCertificateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, IssueCertificateMutation, IssueCertificateMutationVariables, IssueCertificateProps<TChildProps, TDataName>>(IssueCertificateDocument, {
      alias: 'issueCertificate',
      ...operationOptions
    });
};

/**
 * __useIssueCertificateMutation__
 *
 * To run a mutation, you first call `useIssueCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueCertificateMutation, { data, loading, error }] = useIssueCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueCertificateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IssueCertificateMutation, IssueCertificateMutationVariables>) {
        return ApolloReactHooks.useMutation<IssueCertificateMutation, IssueCertificateMutationVariables>(IssueCertificateDocument, baseOptions);
      }
export type IssueCertificateMutationHookResult = ReturnType<typeof useIssueCertificateMutation>;
export type IssueCertificateMutationResult = ApolloReactCommon.MutationResult<IssueCertificateMutation>;
export type IssueCertificateMutationOptions = ApolloReactCommon.BaseMutationOptions<IssueCertificateMutation, IssueCertificateMutationVariables>;
export const MarkAsDeclinedQuoteDocument = gql`
    mutation markAsDeclinedQuote($input: MarkAsDeclinedQuoteInput!) {
  markAsDeclinedQuote(input: $input) {
    quote {
      id
      status
      readableStatus: status(readable: true)
      stage
    }
  }
}
    `;
export type MarkAsDeclinedQuoteMutationFn = ApolloReactCommon.MutationFunction<MarkAsDeclinedQuoteMutation, MarkAsDeclinedQuoteMutationVariables>;
export type MarkAsDeclinedQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MarkAsDeclinedQuoteMutation, MarkAsDeclinedQuoteMutationVariables>
    } & TChildProps;
export function withMarkAsDeclinedQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MarkAsDeclinedQuoteMutation,
  MarkAsDeclinedQuoteMutationVariables,
  MarkAsDeclinedQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MarkAsDeclinedQuoteMutation, MarkAsDeclinedQuoteMutationVariables, MarkAsDeclinedQuoteProps<TChildProps, TDataName>>(MarkAsDeclinedQuoteDocument, {
      alias: 'markAsDeclinedQuote',
      ...operationOptions
    });
};

/**
 * __useMarkAsDeclinedQuoteMutation__
 *
 * To run a mutation, you first call `useMarkAsDeclinedQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsDeclinedQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsDeclinedQuoteMutation, { data, loading, error }] = useMarkAsDeclinedQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkAsDeclinedQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkAsDeclinedQuoteMutation, MarkAsDeclinedQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkAsDeclinedQuoteMutation, MarkAsDeclinedQuoteMutationVariables>(MarkAsDeclinedQuoteDocument, baseOptions);
      }
export type MarkAsDeclinedQuoteMutationHookResult = ReturnType<typeof useMarkAsDeclinedQuoteMutation>;
export type MarkAsDeclinedQuoteMutationResult = ApolloReactCommon.MutationResult<MarkAsDeclinedQuoteMutation>;
export type MarkAsDeclinedQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkAsDeclinedQuoteMutation, MarkAsDeclinedQuoteMutationVariables>;
export const OnboardingProfileCompletedDocument = gql`
    mutation onboardingProfileCompleted {
  onboardingProfileCompleted(input: {}) {
    user {
      id
      firstName
      lastName
      fullName
      email
      avatarUrl
      bannerUrl
      phoneNumber
      linesOfBusiness
      jobTitle
      onboarding
    }
    errors
  }
}
    `;
export type OnboardingProfileCompletedMutationFn = ApolloReactCommon.MutationFunction<OnboardingProfileCompletedMutation, OnboardingProfileCompletedMutationVariables>;
export type OnboardingProfileCompletedProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<OnboardingProfileCompletedMutation, OnboardingProfileCompletedMutationVariables>
    } & TChildProps;
export function withOnboardingProfileCompleted<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnboardingProfileCompletedMutation,
  OnboardingProfileCompletedMutationVariables,
  OnboardingProfileCompletedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, OnboardingProfileCompletedMutation, OnboardingProfileCompletedMutationVariables, OnboardingProfileCompletedProps<TChildProps, TDataName>>(OnboardingProfileCompletedDocument, {
      alias: 'onboardingProfileCompleted',
      ...operationOptions
    });
};

/**
 * __useOnboardingProfileCompletedMutation__
 *
 * To run a mutation, you first call `useOnboardingProfileCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingProfileCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingProfileCompletedMutation, { data, loading, error }] = useOnboardingProfileCompletedMutation({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingProfileCompletedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OnboardingProfileCompletedMutation, OnboardingProfileCompletedMutationVariables>) {
        return ApolloReactHooks.useMutation<OnboardingProfileCompletedMutation, OnboardingProfileCompletedMutationVariables>(OnboardingProfileCompletedDocument, baseOptions);
      }
export type OnboardingProfileCompletedMutationHookResult = ReturnType<typeof useOnboardingProfileCompletedMutation>;
export type OnboardingProfileCompletedMutationResult = ApolloReactCommon.MutationResult<OnboardingProfileCompletedMutation>;
export type OnboardingProfileCompletedMutationOptions = ApolloReactCommon.BaseMutationOptions<OnboardingProfileCompletedMutation, OnboardingProfileCompletedMutationVariables>;
export const UpdateCommissionPercentageDocument = gql`
    mutation UpdateCommissionPercentage($input: UpdateCommissionPercentageInput!) {
  updateCommissionPercentage(input: $input) {
    errors
  }
}
    `;
export type UpdateCommissionPercentageMutationFn = ApolloReactCommon.MutationFunction<UpdateCommissionPercentageMutation, UpdateCommissionPercentageMutationVariables>;
export type UpdateCommissionPercentageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCommissionPercentageMutation, UpdateCommissionPercentageMutationVariables>
    } & TChildProps;
export function withUpdateCommissionPercentage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCommissionPercentageMutation,
  UpdateCommissionPercentageMutationVariables,
  UpdateCommissionPercentageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCommissionPercentageMutation, UpdateCommissionPercentageMutationVariables, UpdateCommissionPercentageProps<TChildProps, TDataName>>(UpdateCommissionPercentageDocument, {
      alias: 'updateCommissionPercentage',
      ...operationOptions
    });
};

/**
 * __useUpdateCommissionPercentageMutation__
 *
 * To run a mutation, you first call `useUpdateCommissionPercentageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommissionPercentageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommissionPercentageMutation, { data, loading, error }] = useUpdateCommissionPercentageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommissionPercentageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCommissionPercentageMutation, UpdateCommissionPercentageMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCommissionPercentageMutation, UpdateCommissionPercentageMutationVariables>(UpdateCommissionPercentageDocument, baseOptions);
      }
export type UpdateCommissionPercentageMutationHookResult = ReturnType<typeof useUpdateCommissionPercentageMutation>;
export type UpdateCommissionPercentageMutationResult = ApolloReactCommon.MutationResult<UpdateCommissionPercentageMutation>;
export type UpdateCommissionPercentageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCommissionPercentageMutation, UpdateCommissionPercentageMutationVariables>;
export const PanelsAdjustQuotesDocument = gql`
    mutation panelsAdjustQuotes($input: PanelsAdjustQuotesInput!) {
  panelsAdjustQuotes(input: $input) {
    errors
  }
}
    `;
export type PanelsAdjustQuotesMutationFn = ApolloReactCommon.MutationFunction<PanelsAdjustQuotesMutation, PanelsAdjustQuotesMutationVariables>;
export type PanelsAdjustQuotesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PanelsAdjustQuotesMutation, PanelsAdjustQuotesMutationVariables>
    } & TChildProps;
export function withPanelsAdjustQuotes<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelsAdjustQuotesMutation,
  PanelsAdjustQuotesMutationVariables,
  PanelsAdjustQuotesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PanelsAdjustQuotesMutation, PanelsAdjustQuotesMutationVariables, PanelsAdjustQuotesProps<TChildProps, TDataName>>(PanelsAdjustQuotesDocument, {
      alias: 'panelsAdjustQuotes',
      ...operationOptions
    });
};

/**
 * __usePanelsAdjustQuotesMutation__
 *
 * To run a mutation, you first call `usePanelsAdjustQuotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePanelsAdjustQuotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [panelsAdjustQuotesMutation, { data, loading, error }] = usePanelsAdjustQuotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePanelsAdjustQuotesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PanelsAdjustQuotesMutation, PanelsAdjustQuotesMutationVariables>) {
        return ApolloReactHooks.useMutation<PanelsAdjustQuotesMutation, PanelsAdjustQuotesMutationVariables>(PanelsAdjustQuotesDocument, baseOptions);
      }
export type PanelsAdjustQuotesMutationHookResult = ReturnType<typeof usePanelsAdjustQuotesMutation>;
export type PanelsAdjustQuotesMutationResult = ApolloReactCommon.MutationResult<PanelsAdjustQuotesMutation>;
export type PanelsAdjustQuotesMutationOptions = ApolloReactCommon.BaseMutationOptions<PanelsAdjustQuotesMutation, PanelsAdjustQuotesMutationVariables>;
export const PanelsBindQuoteDocument = gql`
    mutation panelsBindQuote($input: PanelsBindQuoteInput!) {
  panelsBindQuote(input: $input) {
    quotation {
      id
      status
      statusMessage
      policyNumber
      documents {
        id
        category
        version
        ...DocumentForForm
      }
      participation {
        id
        discussion {
          id
          ...Messages
        }
      }
    }
    error
  }
}
    ${DocumentForFormFragmentDoc}
${MessagesFragmentDoc}`;
export type PanelsBindQuoteMutationFn = ApolloReactCommon.MutationFunction<PanelsBindQuoteMutation, PanelsBindQuoteMutationVariables>;
export type PanelsBindQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PanelsBindQuoteMutation, PanelsBindQuoteMutationVariables>
    } & TChildProps;
export function withPanelsBindQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelsBindQuoteMutation,
  PanelsBindQuoteMutationVariables,
  PanelsBindQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PanelsBindQuoteMutation, PanelsBindQuoteMutationVariables, PanelsBindQuoteProps<TChildProps, TDataName>>(PanelsBindQuoteDocument, {
      alias: 'panelsBindQuote',
      ...operationOptions
    });
};

/**
 * __usePanelsBindQuoteMutation__
 *
 * To run a mutation, you first call `usePanelsBindQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePanelsBindQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [panelsBindQuoteMutation, { data, loading, error }] = usePanelsBindQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePanelsBindQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PanelsBindQuoteMutation, PanelsBindQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<PanelsBindQuoteMutation, PanelsBindQuoteMutationVariables>(PanelsBindQuoteDocument, baseOptions);
      }
export type PanelsBindQuoteMutationHookResult = ReturnType<typeof usePanelsBindQuoteMutation>;
export type PanelsBindQuoteMutationResult = ApolloReactCommon.MutationResult<PanelsBindQuoteMutation>;
export type PanelsBindQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<PanelsBindQuoteMutation, PanelsBindQuoteMutationVariables>;
export const CreateCarrierPanelQuotationDocument = gql`
    mutation CreateCarrierPanelQuotation($input: CreateCarrierPanelQuotationInput!) {
  createCarrierPanelQuotation(input: $input) {
    quotation {
      id
      submission {
        id
      }
    }
  }
}
    `;
export type CreateCarrierPanelQuotationMutationFn = ApolloReactCommon.MutationFunction<CreateCarrierPanelQuotationMutation, CreateCarrierPanelQuotationMutationVariables>;
export type CreateCarrierPanelQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateCarrierPanelQuotationMutation, CreateCarrierPanelQuotationMutationVariables>
    } & TChildProps;
export function withCreateCarrierPanelQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCarrierPanelQuotationMutation,
  CreateCarrierPanelQuotationMutationVariables,
  CreateCarrierPanelQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCarrierPanelQuotationMutation, CreateCarrierPanelQuotationMutationVariables, CreateCarrierPanelQuotationProps<TChildProps, TDataName>>(CreateCarrierPanelQuotationDocument, {
      alias: 'createCarrierPanelQuotation',
      ...operationOptions
    });
};

/**
 * __useCreateCarrierPanelQuotationMutation__
 *
 * To run a mutation, you first call `useCreateCarrierPanelQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarrierPanelQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarrierPanelQuotationMutation, { data, loading, error }] = useCreateCarrierPanelQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCarrierPanelQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCarrierPanelQuotationMutation, CreateCarrierPanelQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCarrierPanelQuotationMutation, CreateCarrierPanelQuotationMutationVariables>(CreateCarrierPanelQuotationDocument, baseOptions);
      }
export type CreateCarrierPanelQuotationMutationHookResult = ReturnType<typeof useCreateCarrierPanelQuotationMutation>;
export type CreateCarrierPanelQuotationMutationResult = ApolloReactCommon.MutationResult<CreateCarrierPanelQuotationMutation>;
export type CreateCarrierPanelQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCarrierPanelQuotationMutation, CreateCarrierPanelQuotationMutationVariables>;
export const CreateManualPanelQuotationDocument = gql`
    mutation CreateManualPanelQuotation($input: CreateManualPanelQuotationInput!) {
  createManualPanelQuotation(input: $input) {
    quotation {
      id
      submission {
        id
      }
    }
  }
}
    `;
export type CreateManualPanelQuotationMutationFn = ApolloReactCommon.MutationFunction<CreateManualPanelQuotationMutation, CreateManualPanelQuotationMutationVariables>;
export type CreateManualPanelQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateManualPanelQuotationMutation, CreateManualPanelQuotationMutationVariables>
    } & TChildProps;
export function withCreateManualPanelQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateManualPanelQuotationMutation,
  CreateManualPanelQuotationMutationVariables,
  CreateManualPanelQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateManualPanelQuotationMutation, CreateManualPanelQuotationMutationVariables, CreateManualPanelQuotationProps<TChildProps, TDataName>>(CreateManualPanelQuotationDocument, {
      alias: 'createManualPanelQuotation',
      ...operationOptions
    });
};

/**
 * __useCreateManualPanelQuotationMutation__
 *
 * To run a mutation, you first call `useCreateManualPanelQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualPanelQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualPanelQuotationMutation, { data, loading, error }] = useCreateManualPanelQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManualPanelQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateManualPanelQuotationMutation, CreateManualPanelQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateManualPanelQuotationMutation, CreateManualPanelQuotationMutationVariables>(CreateManualPanelQuotationDocument, baseOptions);
      }
export type CreateManualPanelQuotationMutationHookResult = ReturnType<typeof useCreateManualPanelQuotationMutation>;
export type CreateManualPanelQuotationMutationResult = ApolloReactCommon.MutationResult<CreateManualPanelQuotationMutation>;
export type CreateManualPanelQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateManualPanelQuotationMutation, CreateManualPanelQuotationMutationVariables>;
export const CreateMultilinePanelSubmissionDocument = gql`
    mutation CreateMultilinePanelSubmission($input: CreateMultilinePanelSubmissionInput!) {
  createMultilinePanelSubmission(input: $input) {
    submission {
      id
      ...PanelSubmissionInfo
    }
  }
}
    ${PanelSubmissionInfoFragmentDoc}`;
export type CreateMultilinePanelSubmissionMutationFn = ApolloReactCommon.MutationFunction<CreateMultilinePanelSubmissionMutation, CreateMultilinePanelSubmissionMutationVariables>;
export type CreateMultilinePanelSubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateMultilinePanelSubmissionMutation, CreateMultilinePanelSubmissionMutationVariables>
    } & TChildProps;
export function withCreateMultilinePanelSubmission<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateMultilinePanelSubmissionMutation,
  CreateMultilinePanelSubmissionMutationVariables,
  CreateMultilinePanelSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateMultilinePanelSubmissionMutation, CreateMultilinePanelSubmissionMutationVariables, CreateMultilinePanelSubmissionProps<TChildProps, TDataName>>(CreateMultilinePanelSubmissionDocument, {
      alias: 'createMultilinePanelSubmission',
      ...operationOptions
    });
};

/**
 * __useCreateMultilinePanelSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateMultilinePanelSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultilinePanelSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultilinePanelSubmissionMutation, { data, loading, error }] = useCreateMultilinePanelSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMultilinePanelSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMultilinePanelSubmissionMutation, CreateMultilinePanelSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMultilinePanelSubmissionMutation, CreateMultilinePanelSubmissionMutationVariables>(CreateMultilinePanelSubmissionDocument, baseOptions);
      }
export type CreateMultilinePanelSubmissionMutationHookResult = ReturnType<typeof useCreateMultilinePanelSubmissionMutation>;
export type CreateMultilinePanelSubmissionMutationResult = ApolloReactCommon.MutationResult<CreateMultilinePanelSubmissionMutation>;
export type CreateMultilinePanelSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMultilinePanelSubmissionMutation, CreateMultilinePanelSubmissionMutationVariables>;
export const CreatePanelSubmissionDocument = gql`
    mutation CreatePanelSubmission($input: CreatePanelSubmissionInput!) {
  createPanelSubmission(input: $input) {
    submission {
      id
    }
  }
}
    `;
export type CreatePanelSubmissionMutationFn = ApolloReactCommon.MutationFunction<CreatePanelSubmissionMutation, CreatePanelSubmissionMutationVariables>;
export type CreatePanelSubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreatePanelSubmissionMutation, CreatePanelSubmissionMutationVariables>
    } & TChildProps;
export function withCreatePanelSubmission<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreatePanelSubmissionMutation,
  CreatePanelSubmissionMutationVariables,
  CreatePanelSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreatePanelSubmissionMutation, CreatePanelSubmissionMutationVariables, CreatePanelSubmissionProps<TChildProps, TDataName>>(CreatePanelSubmissionDocument, {
      alias: 'createPanelSubmission',
      ...operationOptions
    });
};

/**
 * __useCreatePanelSubmissionMutation__
 *
 * To run a mutation, you first call `useCreatePanelSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePanelSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPanelSubmissionMutation, { data, loading, error }] = useCreatePanelSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePanelSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePanelSubmissionMutation, CreatePanelSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePanelSubmissionMutation, CreatePanelSubmissionMutationVariables>(CreatePanelSubmissionDocument, baseOptions);
      }
export type CreatePanelSubmissionMutationHookResult = ReturnType<typeof useCreatePanelSubmissionMutation>;
export type CreatePanelSubmissionMutationResult = ApolloReactCommon.MutationResult<CreatePanelSubmissionMutation>;
export type CreatePanelSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePanelSubmissionMutation, CreatePanelSubmissionMutationVariables>;
export const DeclinePanelQuoteDocument = gql`
    mutation DeclinePanelQuote($input: DeclinePanelQuoteInput!) {
  declinePanelQuote(input: $input) {
    quotation {
      id
      participation {
        id
        quotations {
          nodes {
            id
            ...PanelQuotationInfo
          }
        }
      }
    }
  }
}
    ${PanelQuotationInfoFragmentDoc}`;
export type DeclinePanelQuoteMutationFn = ApolloReactCommon.MutationFunction<DeclinePanelQuoteMutation, DeclinePanelQuoteMutationVariables>;
export type DeclinePanelQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeclinePanelQuoteMutation, DeclinePanelQuoteMutationVariables>
    } & TChildProps;
export function withDeclinePanelQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeclinePanelQuoteMutation,
  DeclinePanelQuoteMutationVariables,
  DeclinePanelQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeclinePanelQuoteMutation, DeclinePanelQuoteMutationVariables, DeclinePanelQuoteProps<TChildProps, TDataName>>(DeclinePanelQuoteDocument, {
      alias: 'declinePanelQuote',
      ...operationOptions
    });
};

/**
 * __useDeclinePanelQuoteMutation__
 *
 * To run a mutation, you first call `useDeclinePanelQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclinePanelQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declinePanelQuoteMutation, { data, loading, error }] = useDeclinePanelQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclinePanelQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeclinePanelQuoteMutation, DeclinePanelQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeclinePanelQuoteMutation, DeclinePanelQuoteMutationVariables>(DeclinePanelQuoteDocument, baseOptions);
      }
export type DeclinePanelQuoteMutationHookResult = ReturnType<typeof useDeclinePanelQuoteMutation>;
export type DeclinePanelQuoteMutationResult = ApolloReactCommon.MutationResult<DeclinePanelQuoteMutation>;
export type DeclinePanelQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeclinePanelQuoteMutation, DeclinePanelQuoteMutationVariables>;
export const EditPanelQuotationDocument = gql`
    mutation EditPanelQuotation($input: EditPanelQuotationInput!) {
  editPanelQuotation(input: $input) {
    quotation {
      id
    }
  }
}
    `;
export type EditPanelQuotationMutationFn = ApolloReactCommon.MutationFunction<EditPanelQuotationMutation, EditPanelQuotationMutationVariables>;
export type EditPanelQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EditPanelQuotationMutation, EditPanelQuotationMutationVariables>
    } & TChildProps;
export function withEditPanelQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditPanelQuotationMutation,
  EditPanelQuotationMutationVariables,
  EditPanelQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EditPanelQuotationMutation, EditPanelQuotationMutationVariables, EditPanelQuotationProps<TChildProps, TDataName>>(EditPanelQuotationDocument, {
      alias: 'editPanelQuotation',
      ...operationOptions
    });
};

/**
 * __useEditPanelQuotationMutation__
 *
 * To run a mutation, you first call `useEditPanelQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPanelQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPanelQuotationMutation, { data, loading, error }] = useEditPanelQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPanelQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPanelQuotationMutation, EditPanelQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<EditPanelQuotationMutation, EditPanelQuotationMutationVariables>(EditPanelQuotationDocument, baseOptions);
      }
export type EditPanelQuotationMutationHookResult = ReturnType<typeof useEditPanelQuotationMutation>;
export type EditPanelQuotationMutationResult = ApolloReactCommon.MutationResult<EditPanelQuotationMutation>;
export type EditPanelQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<EditPanelQuotationMutation, EditPanelQuotationMutationVariables>;
export const EditRetailBrokerDocument = gql`
    mutation editRetailBroker($input: EditRetailBrokerInput!) {
  editRetailBroker(input: $input) {
    agencyCode
    deactivatedAt
    errors
  }
}
    `;
export type EditRetailBrokerMutationFn = ApolloReactCommon.MutationFunction<EditRetailBrokerMutation, EditRetailBrokerMutationVariables>;
export type EditRetailBrokerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EditRetailBrokerMutation, EditRetailBrokerMutationVariables>
    } & TChildProps;
export function withEditRetailBroker<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditRetailBrokerMutation,
  EditRetailBrokerMutationVariables,
  EditRetailBrokerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EditRetailBrokerMutation, EditRetailBrokerMutationVariables, EditRetailBrokerProps<TChildProps, TDataName>>(EditRetailBrokerDocument, {
      alias: 'editRetailBroker',
      ...operationOptions
    });
};

/**
 * __useEditRetailBrokerMutation__
 *
 * To run a mutation, you first call `useEditRetailBrokerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRetailBrokerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRetailBrokerMutation, { data, loading, error }] = useEditRetailBrokerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRetailBrokerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditRetailBrokerMutation, EditRetailBrokerMutationVariables>) {
        return ApolloReactHooks.useMutation<EditRetailBrokerMutation, EditRetailBrokerMutationVariables>(EditRetailBrokerDocument, baseOptions);
      }
export type EditRetailBrokerMutationHookResult = ReturnType<typeof useEditRetailBrokerMutation>;
export type EditRetailBrokerMutationResult = ApolloReactCommon.MutationResult<EditRetailBrokerMutation>;
export type EditRetailBrokerMutationOptions = ApolloReactCommon.BaseMutationOptions<EditRetailBrokerMutation, EditRetailBrokerMutationVariables>;
export const EditPanelSubmissionDocument = gql`
    mutation EditPanelSubmission($input: EditPanelSubmissionInput!) {
  editPanelSubmission(input: $input) {
    submission {
      id
    }
    errors
  }
}
    `;
export type EditPanelSubmissionMutationFn = ApolloReactCommon.MutationFunction<EditPanelSubmissionMutation, EditPanelSubmissionMutationVariables>;
export type EditPanelSubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EditPanelSubmissionMutation, EditPanelSubmissionMutationVariables>
    } & TChildProps;
export function withEditPanelSubmission<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditPanelSubmissionMutation,
  EditPanelSubmissionMutationVariables,
  EditPanelSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EditPanelSubmissionMutation, EditPanelSubmissionMutationVariables, EditPanelSubmissionProps<TChildProps, TDataName>>(EditPanelSubmissionDocument, {
      alias: 'editPanelSubmission',
      ...operationOptions
    });
};

/**
 * __useEditPanelSubmissionMutation__
 *
 * To run a mutation, you first call `useEditPanelSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPanelSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPanelSubmissionMutation, { data, loading, error }] = useEditPanelSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPanelSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPanelSubmissionMutation, EditPanelSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<EditPanelSubmissionMutation, EditPanelSubmissionMutationVariables>(EditPanelSubmissionDocument, baseOptions);
      }
export type EditPanelSubmissionMutationHookResult = ReturnType<typeof useEditPanelSubmissionMutation>;
export type EditPanelSubmissionMutationResult = ApolloReactCommon.MutationResult<EditPanelSubmissionMutation>;
export type EditPanelSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<EditPanelSubmissionMutation, EditPanelSubmissionMutationVariables>;
export const PanelsExportQuoteComparisonDocument = gql`
    mutation panelsExportQuoteComparison($input: PanelsExportQuoteComparisonInput!) {
  panelsExportQuoteComparison(input: $input) {
    errors
  }
}
    `;
export type PanelsExportQuoteComparisonMutationFn = ApolloReactCommon.MutationFunction<PanelsExportQuoteComparisonMutation, PanelsExportQuoteComparisonMutationVariables>;
export type PanelsExportQuoteComparisonProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PanelsExportQuoteComparisonMutation, PanelsExportQuoteComparisonMutationVariables>
    } & TChildProps;
export function withPanelsExportQuoteComparison<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelsExportQuoteComparisonMutation,
  PanelsExportQuoteComparisonMutationVariables,
  PanelsExportQuoteComparisonProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PanelsExportQuoteComparisonMutation, PanelsExportQuoteComparisonMutationVariables, PanelsExportQuoteComparisonProps<TChildProps, TDataName>>(PanelsExportQuoteComparisonDocument, {
      alias: 'panelsExportQuoteComparison',
      ...operationOptions
    });
};

/**
 * __usePanelsExportQuoteComparisonMutation__
 *
 * To run a mutation, you first call `usePanelsExportQuoteComparisonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePanelsExportQuoteComparisonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [panelsExportQuoteComparisonMutation, { data, loading, error }] = usePanelsExportQuoteComparisonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePanelsExportQuoteComparisonMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PanelsExportQuoteComparisonMutation, PanelsExportQuoteComparisonMutationVariables>) {
        return ApolloReactHooks.useMutation<PanelsExportQuoteComparisonMutation, PanelsExportQuoteComparisonMutationVariables>(PanelsExportQuoteComparisonDocument, baseOptions);
      }
export type PanelsExportQuoteComparisonMutationHookResult = ReturnType<typeof usePanelsExportQuoteComparisonMutation>;
export type PanelsExportQuoteComparisonMutationResult = ApolloReactCommon.MutationResult<PanelsExportQuoteComparisonMutation>;
export type PanelsExportQuoteComparisonMutationOptions = ApolloReactCommon.BaseMutationOptions<PanelsExportQuoteComparisonMutation, PanelsExportQuoteComparisonMutationVariables>;
export const PanelsIssuePolicyDocument = gql`
    mutation panelsIssuePolicy($input: PanelsIssuePolicyInput!) {
  panelsIssuePolicy(input: $input) {
    quotation {
      id
      status
      statusMessage
      documents {
        id
        category
        version
        ...DocumentForForm
      }
    }
    errors {
      policyNumber
    }
  }
}
    ${DocumentForFormFragmentDoc}`;
export type PanelsIssuePolicyMutationFn = ApolloReactCommon.MutationFunction<PanelsIssuePolicyMutation, PanelsIssuePolicyMutationVariables>;
export type PanelsIssuePolicyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PanelsIssuePolicyMutation, PanelsIssuePolicyMutationVariables>
    } & TChildProps;
export function withPanelsIssuePolicy<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelsIssuePolicyMutation,
  PanelsIssuePolicyMutationVariables,
  PanelsIssuePolicyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PanelsIssuePolicyMutation, PanelsIssuePolicyMutationVariables, PanelsIssuePolicyProps<TChildProps, TDataName>>(PanelsIssuePolicyDocument, {
      alias: 'panelsIssuePolicy',
      ...operationOptions
    });
};

/**
 * __usePanelsIssuePolicyMutation__
 *
 * To run a mutation, you first call `usePanelsIssuePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePanelsIssuePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [panelsIssuePolicyMutation, { data, loading, error }] = usePanelsIssuePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePanelsIssuePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PanelsIssuePolicyMutation, PanelsIssuePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<PanelsIssuePolicyMutation, PanelsIssuePolicyMutationVariables>(PanelsIssuePolicyDocument, baseOptions);
      }
export type PanelsIssuePolicyMutationHookResult = ReturnType<typeof usePanelsIssuePolicyMutation>;
export type PanelsIssuePolicyMutationResult = ApolloReactCommon.MutationResult<PanelsIssuePolicyMutation>;
export type PanelsIssuePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<PanelsIssuePolicyMutation, PanelsIssuePolicyMutationVariables>;
export const PanelsRequestToBindDocument = gql`
    mutation panelsRequestToBind($input: PanelsRequestToBindInput!) {
  panelsRequestToBind(input: $input) {
    quotation {
      id
      status
      statusMessage
      submission {
        id
        status
      }
    }
    error
  }
}
    `;
export type PanelsRequestToBindMutationFn = ApolloReactCommon.MutationFunction<PanelsRequestToBindMutation, PanelsRequestToBindMutationVariables>;
export type PanelsRequestToBindProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PanelsRequestToBindMutation, PanelsRequestToBindMutationVariables>
    } & TChildProps;
export function withPanelsRequestToBind<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelsRequestToBindMutation,
  PanelsRequestToBindMutationVariables,
  PanelsRequestToBindProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PanelsRequestToBindMutation, PanelsRequestToBindMutationVariables, PanelsRequestToBindProps<TChildProps, TDataName>>(PanelsRequestToBindDocument, {
      alias: 'panelsRequestToBind',
      ...operationOptions
    });
};

/**
 * __usePanelsRequestToBindMutation__
 *
 * To run a mutation, you first call `usePanelsRequestToBindMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePanelsRequestToBindMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [panelsRequestToBindMutation, { data, loading, error }] = usePanelsRequestToBindMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePanelsRequestToBindMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PanelsRequestToBindMutation, PanelsRequestToBindMutationVariables>) {
        return ApolloReactHooks.useMutation<PanelsRequestToBindMutation, PanelsRequestToBindMutationVariables>(PanelsRequestToBindDocument, baseOptions);
      }
export type PanelsRequestToBindMutationHookResult = ReturnType<typeof usePanelsRequestToBindMutation>;
export type PanelsRequestToBindMutationResult = ApolloReactCommon.MutationResult<PanelsRequestToBindMutation>;
export type PanelsRequestToBindMutationOptions = ApolloReactCommon.BaseMutationOptions<PanelsRequestToBindMutation, PanelsRequestToBindMutationVariables>;
export const SendToMarketsDocument = gql`
    mutation SendToMarkets($input: SendToMarketsInput!) {
  sendToMarkets(input: $input) {
    errors
    submission {
      id
      ...PanelSubmissionInfo
    }
  }
}
    ${PanelSubmissionInfoFragmentDoc}`;
export type SendToMarketsMutationFn = ApolloReactCommon.MutationFunction<SendToMarketsMutation, SendToMarketsMutationVariables>;
export type SendToMarketsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SendToMarketsMutation, SendToMarketsMutationVariables>
    } & TChildProps;
export function withSendToMarkets<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendToMarketsMutation,
  SendToMarketsMutationVariables,
  SendToMarketsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SendToMarketsMutation, SendToMarketsMutationVariables, SendToMarketsProps<TChildProps, TDataName>>(SendToMarketsDocument, {
      alias: 'sendToMarkets',
      ...operationOptions
    });
};

/**
 * __useSendToMarketsMutation__
 *
 * To run a mutation, you first call `useSendToMarketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendToMarketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendToMarketsMutation, { data, loading, error }] = useSendToMarketsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendToMarketsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendToMarketsMutation, SendToMarketsMutationVariables>) {
        return ApolloReactHooks.useMutation<SendToMarketsMutation, SendToMarketsMutationVariables>(SendToMarketsDocument, baseOptions);
      }
export type SendToMarketsMutationHookResult = ReturnType<typeof useSendToMarketsMutation>;
export type SendToMarketsMutationResult = ApolloReactCommon.MutationResult<SendToMarketsMutation>;
export type SendToMarketsMutationOptions = ApolloReactCommon.BaseMutationOptions<SendToMarketsMutation, SendToMarketsMutationVariables>;
export const PanelsUpdateSubmissionDocumentsDocument = gql`
    mutation panelsUpdateSubmissionDocuments($input: PanelsUpdateSubmissionDocumentsInput!) {
  panelsUpdateSubmissionDocuments(input: $input) {
    submission {
      id
      ...PanelsSubmissionFileCabinet
    }
    errors
  }
}
    ${PanelsSubmissionFileCabinetFragmentDoc}`;
export type PanelsUpdateSubmissionDocumentsMutationFn = ApolloReactCommon.MutationFunction<PanelsUpdateSubmissionDocumentsMutation, PanelsUpdateSubmissionDocumentsMutationVariables>;
export type PanelsUpdateSubmissionDocumentsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PanelsUpdateSubmissionDocumentsMutation, PanelsUpdateSubmissionDocumentsMutationVariables>
    } & TChildProps;
export function withPanelsUpdateSubmissionDocuments<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelsUpdateSubmissionDocumentsMutation,
  PanelsUpdateSubmissionDocumentsMutationVariables,
  PanelsUpdateSubmissionDocumentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PanelsUpdateSubmissionDocumentsMutation, PanelsUpdateSubmissionDocumentsMutationVariables, PanelsUpdateSubmissionDocumentsProps<TChildProps, TDataName>>(PanelsUpdateSubmissionDocumentsDocument, {
      alias: 'panelsUpdateSubmissionDocuments',
      ...operationOptions
    });
};

/**
 * __usePanelsUpdateSubmissionDocumentsMutation__
 *
 * To run a mutation, you first call `usePanelsUpdateSubmissionDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePanelsUpdateSubmissionDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [panelsUpdateSubmissionDocumentsMutation, { data, loading, error }] = usePanelsUpdateSubmissionDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePanelsUpdateSubmissionDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PanelsUpdateSubmissionDocumentsMutation, PanelsUpdateSubmissionDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<PanelsUpdateSubmissionDocumentsMutation, PanelsUpdateSubmissionDocumentsMutationVariables>(PanelsUpdateSubmissionDocumentsDocument, baseOptions);
      }
export type PanelsUpdateSubmissionDocumentsMutationHookResult = ReturnType<typeof usePanelsUpdateSubmissionDocumentsMutation>;
export type PanelsUpdateSubmissionDocumentsMutationResult = ApolloReactCommon.MutationResult<PanelsUpdateSubmissionDocumentsMutation>;
export type PanelsUpdateSubmissionDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<PanelsUpdateSubmissionDocumentsMutation, PanelsUpdateSubmissionDocumentsMutationVariables>;
export const ChangeParicipationNewOwnerDocument = gql`
    mutation changeParicipationNewOwner($input: ChangeParticipationWithNewOwnerInput!) {
  changeParticipationNewOwner(input: $input) {
    invitationToken
    participation {
      id
      user {
        id
        fullName
        company {
          id
          name
        }
      }
    }
    errors
  }
}
    `;
export type ChangeParicipationNewOwnerMutationFn = ApolloReactCommon.MutationFunction<ChangeParicipationNewOwnerMutation, ChangeParicipationNewOwnerMutationVariables>;
export type ChangeParicipationNewOwnerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeParicipationNewOwnerMutation, ChangeParicipationNewOwnerMutationVariables>
    } & TChildProps;
export function withChangeParicipationNewOwner<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeParicipationNewOwnerMutation,
  ChangeParicipationNewOwnerMutationVariables,
  ChangeParicipationNewOwnerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeParicipationNewOwnerMutation, ChangeParicipationNewOwnerMutationVariables, ChangeParicipationNewOwnerProps<TChildProps, TDataName>>(ChangeParicipationNewOwnerDocument, {
      alias: 'changeParicipationNewOwner',
      ...operationOptions
    });
};

/**
 * __useChangeParicipationNewOwnerMutation__
 *
 * To run a mutation, you first call `useChangeParicipationNewOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeParicipationNewOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeParicipationNewOwnerMutation, { data, loading, error }] = useChangeParicipationNewOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeParicipationNewOwnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeParicipationNewOwnerMutation, ChangeParicipationNewOwnerMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeParicipationNewOwnerMutation, ChangeParicipationNewOwnerMutationVariables>(ChangeParicipationNewOwnerDocument, baseOptions);
      }
export type ChangeParicipationNewOwnerMutationHookResult = ReturnType<typeof useChangeParicipationNewOwnerMutation>;
export type ChangeParicipationNewOwnerMutationResult = ApolloReactCommon.MutationResult<ChangeParicipationNewOwnerMutation>;
export type ChangeParicipationNewOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeParicipationNewOwnerMutation, ChangeParicipationNewOwnerMutationVariables>;
export const PcgAutoSaveSubmissionDocument = gql`
    mutation pcgAutoSaveSubmission($input: PCGAutoSaveSubmissionInput!) {
  pcgAutoSaveSubmission(input: $input) {
    errors {
      base
    }
    submission {
      id
      schemas
      name
      applicants {
        id
        firstName
        lastName
      }
      policyStartDate
      policyEndDate
      selectedCarriers {
        id
        agentCode
      }
      message
      emailSignOff
      placementTermsAccepted
      documents {
        id
        ...Document
      }
      review {
        applicants
        additionalDrivers
        coverages
      }
      products {
        nodes {
          id
        }
      }
      coverages {
        id
        type
        ... on PCGHomeCoverageType {
          address {
            id
            streetLine1
            streetLine2
            city
            zipCode
            state
            country
          }
        }
        ... on PCGAutoCoverageType {
          vehicles {
            year
            make
            model
            value
          }
          additionalDrivers {
            firstName
            lastName
            relationship
          }
          drivers
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}`;
export type PcgAutoSaveSubmissionMutationFn = ApolloReactCommon.MutationFunction<PcgAutoSaveSubmissionMutation, PcgAutoSaveSubmissionMutationVariables>;
export type PcgAutoSaveSubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgAutoSaveSubmissionMutation, PcgAutoSaveSubmissionMutationVariables>
    } & TChildProps;
export function withPcgAutoSaveSubmission<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgAutoSaveSubmissionMutation,
  PcgAutoSaveSubmissionMutationVariables,
  PcgAutoSaveSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgAutoSaveSubmissionMutation, PcgAutoSaveSubmissionMutationVariables, PcgAutoSaveSubmissionProps<TChildProps, TDataName>>(PcgAutoSaveSubmissionDocument, {
      alias: 'pcgAutoSaveSubmission',
      ...operationOptions
    });
};

/**
 * __usePcgAutoSaveSubmissionMutation__
 *
 * To run a mutation, you first call `usePcgAutoSaveSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgAutoSaveSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgAutoSaveSubmissionMutation, { data, loading, error }] = usePcgAutoSaveSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgAutoSaveSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgAutoSaveSubmissionMutation, PcgAutoSaveSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgAutoSaveSubmissionMutation, PcgAutoSaveSubmissionMutationVariables>(PcgAutoSaveSubmissionDocument, baseOptions);
      }
export type PcgAutoSaveSubmissionMutationHookResult = ReturnType<typeof usePcgAutoSaveSubmissionMutation>;
export type PcgAutoSaveSubmissionMutationResult = ApolloReactCommon.MutationResult<PcgAutoSaveSubmissionMutation>;
export type PcgAutoSaveSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgAutoSaveSubmissionMutation, PcgAutoSaveSubmissionMutationVariables>;
export const PcgBindEmailQuotationDocument = gql`
    mutation pcgBindEmailQuotation($input: PCGBindEmailQuotationInput!) {
  pcgBindEmailQuotation(input: $input) {
    quotation {
      id
      status
      documents {
        id
        category
        version
        ...DocumentForForm
      }
      participation {
        id
        discussion {
          id
          ...Messages
        }
      }
    }
    error
  }
}
    ${DocumentForFormFragmentDoc}
${MessagesFragmentDoc}`;
export type PcgBindEmailQuotationMutationFn = ApolloReactCommon.MutationFunction<PcgBindEmailQuotationMutation, PcgBindEmailQuotationMutationVariables>;
export type PcgBindEmailQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgBindEmailQuotationMutation, PcgBindEmailQuotationMutationVariables>
    } & TChildProps;
export function withPcgBindEmailQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgBindEmailQuotationMutation,
  PcgBindEmailQuotationMutationVariables,
  PcgBindEmailQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgBindEmailQuotationMutation, PcgBindEmailQuotationMutationVariables, PcgBindEmailQuotationProps<TChildProps, TDataName>>(PcgBindEmailQuotationDocument, {
      alias: 'pcgBindEmailQuotation',
      ...operationOptions
    });
};

/**
 * __usePcgBindEmailQuotationMutation__
 *
 * To run a mutation, you first call `usePcgBindEmailQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgBindEmailQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgBindEmailQuotationMutation, { data, loading, error }] = usePcgBindEmailQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgBindEmailQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgBindEmailQuotationMutation, PcgBindEmailQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgBindEmailQuotationMutation, PcgBindEmailQuotationMutationVariables>(PcgBindEmailQuotationDocument, baseOptions);
      }
export type PcgBindEmailQuotationMutationHookResult = ReturnType<typeof usePcgBindEmailQuotationMutation>;
export type PcgBindEmailQuotationMutationResult = ApolloReactCommon.MutationResult<PcgBindEmailQuotationMutation>;
export type PcgBindEmailQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgBindEmailQuotationMutation, PcgBindEmailQuotationMutationVariables>;
export const PcgBindManualQuotationDocument = gql`
    mutation pcgBindManualQuotation($input: PCGBindManualQuotationInput!) {
  pcgBindManualQuotation(input: $input) {
    quotation {
      id
      status
    }
    errors {
      base
    }
  }
}
    `;
export type PcgBindManualQuotationMutationFn = ApolloReactCommon.MutationFunction<PcgBindManualQuotationMutation, PcgBindManualQuotationMutationVariables>;
export type PcgBindManualQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgBindManualQuotationMutation, PcgBindManualQuotationMutationVariables>
    } & TChildProps;
export function withPcgBindManualQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgBindManualQuotationMutation,
  PcgBindManualQuotationMutationVariables,
  PcgBindManualQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgBindManualQuotationMutation, PcgBindManualQuotationMutationVariables, PcgBindManualQuotationProps<TChildProps, TDataName>>(PcgBindManualQuotationDocument, {
      alias: 'pcgBindManualQuotation',
      ...operationOptions
    });
};

/**
 * __usePcgBindManualQuotationMutation__
 *
 * To run a mutation, you first call `usePcgBindManualQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgBindManualQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgBindManualQuotationMutation, { data, loading, error }] = usePcgBindManualQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgBindManualQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgBindManualQuotationMutation, PcgBindManualQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgBindManualQuotationMutation, PcgBindManualQuotationMutationVariables>(PcgBindManualQuotationDocument, baseOptions);
      }
export type PcgBindManualQuotationMutationHookResult = ReturnType<typeof usePcgBindManualQuotationMutation>;
export type PcgBindManualQuotationMutationResult = ApolloReactCommon.MutationResult<PcgBindManualQuotationMutation>;
export type PcgBindManualQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgBindManualQuotationMutation, PcgBindManualQuotationMutationVariables>;
export const PcgCreateEmailQuotationDocument = gql`
    mutation pcgCreateEmailQuotation($input: PCGCreateEmailQuotationInput!) {
  pcgCreateEmailQuotation(input: $input) {
    quotation {
      id
    }
  }
}
    `;
export type PcgCreateEmailQuotationMutationFn = ApolloReactCommon.MutationFunction<PcgCreateEmailQuotationMutation, PcgCreateEmailQuotationMutationVariables>;
export type PcgCreateEmailQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgCreateEmailQuotationMutation, PcgCreateEmailQuotationMutationVariables>
    } & TChildProps;
export function withPcgCreateEmailQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgCreateEmailQuotationMutation,
  PcgCreateEmailQuotationMutationVariables,
  PcgCreateEmailQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgCreateEmailQuotationMutation, PcgCreateEmailQuotationMutationVariables, PcgCreateEmailQuotationProps<TChildProps, TDataName>>(PcgCreateEmailQuotationDocument, {
      alias: 'pcgCreateEmailQuotation',
      ...operationOptions
    });
};

/**
 * __usePcgCreateEmailQuotationMutation__
 *
 * To run a mutation, you first call `usePcgCreateEmailQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgCreateEmailQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgCreateEmailQuotationMutation, { data, loading, error }] = usePcgCreateEmailQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgCreateEmailQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgCreateEmailQuotationMutation, PcgCreateEmailQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgCreateEmailQuotationMutation, PcgCreateEmailQuotationMutationVariables>(PcgCreateEmailQuotationDocument, baseOptions);
      }
export type PcgCreateEmailQuotationMutationHookResult = ReturnType<typeof usePcgCreateEmailQuotationMutation>;
export type PcgCreateEmailQuotationMutationResult = ApolloReactCommon.MutationResult<PcgCreateEmailQuotationMutation>;
export type PcgCreateEmailQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgCreateEmailQuotationMutation, PcgCreateEmailQuotationMutationVariables>;
export const PcgCreateManualQuotationDocument = gql`
    mutation pcgCreateManualQuotation($input: PCGCreateManualQuotationInput!) {
  pcgCreateManualQuotation(input: $input) {
    quotation {
      id
    }
    errors {
      customCarrier
      carrier
      currentPolicy
    }
  }
}
    `;
export type PcgCreateManualQuotationMutationFn = ApolloReactCommon.MutationFunction<PcgCreateManualQuotationMutation, PcgCreateManualQuotationMutationVariables>;
export type PcgCreateManualQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgCreateManualQuotationMutation, PcgCreateManualQuotationMutationVariables>
    } & TChildProps;
export function withPcgCreateManualQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgCreateManualQuotationMutation,
  PcgCreateManualQuotationMutationVariables,
  PcgCreateManualQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgCreateManualQuotationMutation, PcgCreateManualQuotationMutationVariables, PcgCreateManualQuotationProps<TChildProps, TDataName>>(PcgCreateManualQuotationDocument, {
      alias: 'pcgCreateManualQuotation',
      ...operationOptions
    });
};

/**
 * __usePcgCreateManualQuotationMutation__
 *
 * To run a mutation, you first call `usePcgCreateManualQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgCreateManualQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgCreateManualQuotationMutation, { data, loading, error }] = usePcgCreateManualQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgCreateManualQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgCreateManualQuotationMutation, PcgCreateManualQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgCreateManualQuotationMutation, PcgCreateManualQuotationMutationVariables>(PcgCreateManualQuotationDocument, baseOptions);
      }
export type PcgCreateManualQuotationMutationHookResult = ReturnType<typeof usePcgCreateManualQuotationMutation>;
export type PcgCreateManualQuotationMutationResult = ApolloReactCommon.MutationResult<PcgCreateManualQuotationMutation>;
export type PcgCreateManualQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgCreateManualQuotationMutation, PcgCreateManualQuotationMutationVariables>;
export const PcgCreateSubmissionDocument = gql`
    mutation pcgCreateSubmission($input: PCGCreateSubmissionInput!) {
  pcgCreateSubmission(input: $input) {
    submission {
      id
    }
    errors {
      name
    }
  }
}
    `;
export type PcgCreateSubmissionMutationFn = ApolloReactCommon.MutationFunction<PcgCreateSubmissionMutation, PcgCreateSubmissionMutationVariables>;
export type PcgCreateSubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgCreateSubmissionMutation, PcgCreateSubmissionMutationVariables>
    } & TChildProps;
export function withPcgCreateSubmission<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgCreateSubmissionMutation,
  PcgCreateSubmissionMutationVariables,
  PcgCreateSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgCreateSubmissionMutation, PcgCreateSubmissionMutationVariables, PcgCreateSubmissionProps<TChildProps, TDataName>>(PcgCreateSubmissionDocument, {
      alias: 'pcgCreateSubmission',
      ...operationOptions
    });
};

/**
 * __usePcgCreateSubmissionMutation__
 *
 * To run a mutation, you first call `usePcgCreateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgCreateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgCreateSubmissionMutation, { data, loading, error }] = usePcgCreateSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgCreateSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgCreateSubmissionMutation, PcgCreateSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgCreateSubmissionMutation, PcgCreateSubmissionMutationVariables>(PcgCreateSubmissionDocument, baseOptions);
      }
export type PcgCreateSubmissionMutationHookResult = ReturnType<typeof usePcgCreateSubmissionMutation>;
export type PcgCreateSubmissionMutationResult = ApolloReactCommon.MutationResult<PcgCreateSubmissionMutation>;
export type PcgCreateSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgCreateSubmissionMutation, PcgCreateSubmissionMutationVariables>;
export const PcgDeclineToBindEmailQuotationDocument = gql`
    mutation pcgDeclineToBindEmailQuotation($input: PCGDeclineToBindEmailQuotationInput!) {
  pcgDeclineToBindEmailQuotation(input: $input) {
    quotation {
      id
      status
      documents {
        id
        category
        version
        ...DocumentForForm
      }
      participation {
        id
        discussion {
          id
          ...Messages
        }
      }
    }
    error
  }
}
    ${DocumentForFormFragmentDoc}
${MessagesFragmentDoc}`;
export type PcgDeclineToBindEmailQuotationMutationFn = ApolloReactCommon.MutationFunction<PcgDeclineToBindEmailQuotationMutation, PcgDeclineToBindEmailQuotationMutationVariables>;
export type PcgDeclineToBindEmailQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgDeclineToBindEmailQuotationMutation, PcgDeclineToBindEmailQuotationMutationVariables>
    } & TChildProps;
export function withPcgDeclineToBindEmailQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgDeclineToBindEmailQuotationMutation,
  PcgDeclineToBindEmailQuotationMutationVariables,
  PcgDeclineToBindEmailQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgDeclineToBindEmailQuotationMutation, PcgDeclineToBindEmailQuotationMutationVariables, PcgDeclineToBindEmailQuotationProps<TChildProps, TDataName>>(PcgDeclineToBindEmailQuotationDocument, {
      alias: 'pcgDeclineToBindEmailQuotation',
      ...operationOptions
    });
};

/**
 * __usePcgDeclineToBindEmailQuotationMutation__
 *
 * To run a mutation, you first call `usePcgDeclineToBindEmailQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgDeclineToBindEmailQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgDeclineToBindEmailQuotationMutation, { data, loading, error }] = usePcgDeclineToBindEmailQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgDeclineToBindEmailQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgDeclineToBindEmailQuotationMutation, PcgDeclineToBindEmailQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgDeclineToBindEmailQuotationMutation, PcgDeclineToBindEmailQuotationMutationVariables>(PcgDeclineToBindEmailQuotationDocument, baseOptions);
      }
export type PcgDeclineToBindEmailQuotationMutationHookResult = ReturnType<typeof usePcgDeclineToBindEmailQuotationMutation>;
export type PcgDeclineToBindEmailQuotationMutationResult = ApolloReactCommon.MutationResult<PcgDeclineToBindEmailQuotationMutation>;
export type PcgDeclineToBindEmailQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgDeclineToBindEmailQuotationMutation, PcgDeclineToBindEmailQuotationMutationVariables>;
export const PcgDeleteManualQuotationDocument = gql`
    mutation pcgDeleteManualQuotation($input: PCGDeleteManualQuotationInput!) {
  pcgDeleteManualQuotation(input: $input) {
    submission {
      id
      ...PCGSubmissionInfo
    }
    error
  }
}
    ${PcgSubmissionInfoFragmentDoc}`;
export type PcgDeleteManualQuotationMutationFn = ApolloReactCommon.MutationFunction<PcgDeleteManualQuotationMutation, PcgDeleteManualQuotationMutationVariables>;
export type PcgDeleteManualQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgDeleteManualQuotationMutation, PcgDeleteManualQuotationMutationVariables>
    } & TChildProps;
export function withPcgDeleteManualQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgDeleteManualQuotationMutation,
  PcgDeleteManualQuotationMutationVariables,
  PcgDeleteManualQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgDeleteManualQuotationMutation, PcgDeleteManualQuotationMutationVariables, PcgDeleteManualQuotationProps<TChildProps, TDataName>>(PcgDeleteManualQuotationDocument, {
      alias: 'pcgDeleteManualQuotation',
      ...operationOptions
    });
};

/**
 * __usePcgDeleteManualQuotationMutation__
 *
 * To run a mutation, you first call `usePcgDeleteManualQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgDeleteManualQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgDeleteManualQuotationMutation, { data, loading, error }] = usePcgDeleteManualQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgDeleteManualQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgDeleteManualQuotationMutation, PcgDeleteManualQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgDeleteManualQuotationMutation, PcgDeleteManualQuotationMutationVariables>(PcgDeleteManualQuotationDocument, baseOptions);
      }
export type PcgDeleteManualQuotationMutationHookResult = ReturnType<typeof usePcgDeleteManualQuotationMutation>;
export type PcgDeleteManualQuotationMutationResult = ApolloReactCommon.MutationResult<PcgDeleteManualQuotationMutation>;
export type PcgDeleteManualQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgDeleteManualQuotationMutation, PcgDeleteManualQuotationMutationVariables>;
export const PcgEditQuotationDocument = gql`
    mutation pcgEditQuotation($input: PCGEditQuotationInput!) {
  pcgEditQuotation(input: $input) {
    quotation {
      id
      currentPolicy
      status
      declinationReason
      carrier {
        id
        name
      }
      panelist {
        id
      }
      customCarrier
      currentPolicy
      review {
        quotes {
          quote {
            text
            icon
          }
          totalPremium
          status
        }
        totalPremium
      }
      quotedLines {
        nodes {
          id
          type
          ... on PCGValuablesQuotedLineType {
            coverages {
              name
              limit
              blanketLimit
            }
            totalPremium
            additionalFeatures {
              feature
              included
            }
          }
          ... on PCGHomeQuotedLineType {
            coverages {
              coverage
              homeCoverageLimit {
                value
                type
              }
              deductible {
                value
                type
              }
            }
            allOtherPerilDeductible
            enhancements {
              enhancement
              limit {
                value
                type
              }
              deductible {
                value
                type
              }
              premium {
                value
                type
              }
            }
            additionalOptions {
              option
              deductible {
                value
                type
              }
              premium {
                value
                type
              }
            }
            additionalFeatures {
              feature
              included
            }
            totalPremium
          }
          ... on PCGExcessQuotedLineType {
            coverages {
              coverage
              limit
            }
            totalPremium
            limitOptions {
              coverage
              limit
              totalPremium
            }
          }
          ... on PCGAutoQuotedLineType {
            agreedValues {
              vehicle
              value
            }
            combinedLimit
            bodilyInjuryAndPropertyDamage
            bodilyInjuryPerPerson
            bodilyInjuryEachAccident
            propertyDamage
            medicalPayments {
              type
              value
            }
            underinsuredMotorists {
              type
              value
            }
            extendedTowingAndLabor {
              type
              value
            }
            fullGlassCoverage {
              type
              value
            }
            additionalCoverages {
              name
              limit {
                type
                value
              }
            }
            collisionDeductible {
              type
              value
            }
            comprehensiveDeductible {
              type
              value
            }
            additionalFeatures {
              feature
              included
            }
            totalPremium
          }
        }
      }
    }
    errors {
      base
      customCarrier
      carrier
      currentPolicy
      valuableQuotedLines {
        base
        additionalFeatures {
          base
          feature
          included
        }
        coverages {
          base
          blanketLimit
          limit
          name
          premium
        }
      }
    }
  }
}
    `;
export type PcgEditQuotationMutationFn = ApolloReactCommon.MutationFunction<PcgEditQuotationMutation, PcgEditQuotationMutationVariables>;
export type PcgEditQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgEditQuotationMutation, PcgEditQuotationMutationVariables>
    } & TChildProps;
export function withPcgEditQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgEditQuotationMutation,
  PcgEditQuotationMutationVariables,
  PcgEditQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgEditQuotationMutation, PcgEditQuotationMutationVariables, PcgEditQuotationProps<TChildProps, TDataName>>(PcgEditQuotationDocument, {
      alias: 'pcgEditQuotation',
      ...operationOptions
    });
};

/**
 * __usePcgEditQuotationMutation__
 *
 * To run a mutation, you first call `usePcgEditQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgEditQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgEditQuotationMutation, { data, loading, error }] = usePcgEditQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgEditQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgEditQuotationMutation, PcgEditQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgEditQuotationMutation, PcgEditQuotationMutationVariables>(PcgEditQuotationDocument, baseOptions);
      }
export type PcgEditQuotationMutationHookResult = ReturnType<typeof usePcgEditQuotationMutation>;
export type PcgEditQuotationMutationResult = ApolloReactCommon.MutationResult<PcgEditQuotationMutation>;
export type PcgEditQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgEditQuotationMutation, PcgEditQuotationMutationVariables>;
export const PcgEditSubmissionDocument = gql`
    mutation pcgEditSubmission($input: PCGEditSubmissionInput!) {
  pcgEditSubmission(input: $input) {
    errors {
      applicants
      name
      message
      emailSignOff
      documents
      placementTermsAccepted
      policyStartDate
      policyEndDate
      selectedCarriers
    }
    submission {
      id
      schemas
      name
      applicants {
        id
        firstName
        lastName
      }
      policyStartDate
      policyEndDate
      selectedCarriers {
        id
        agentCode
      }
      message
      documents {
        id
        ...Document
      }
      products {
        nodes {
          id
        }
      }
      coverages {
        id
        type
        ... on PCGHomeCoverageType {
          address {
            id
            streetLine1
            streetLine2
            city
            zipCode
            state
            country
          }
        }
        ... on PCGAutoCoverageType {
          vehicles {
            year
            make
            model
            value
          }
          additionalDrivers {
            firstName
            lastName
            relationship
          }
          drivers
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}`;
export type PcgEditSubmissionMutationFn = ApolloReactCommon.MutationFunction<PcgEditSubmissionMutation, PcgEditSubmissionMutationVariables>;
export type PcgEditSubmissionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgEditSubmissionMutation, PcgEditSubmissionMutationVariables>
    } & TChildProps;
export function withPcgEditSubmission<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgEditSubmissionMutation,
  PcgEditSubmissionMutationVariables,
  PcgEditSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgEditSubmissionMutation, PcgEditSubmissionMutationVariables, PcgEditSubmissionProps<TChildProps, TDataName>>(PcgEditSubmissionDocument, {
      alias: 'pcgEditSubmission',
      ...operationOptions
    });
};

/**
 * __usePcgEditSubmissionMutation__
 *
 * To run a mutation, you first call `usePcgEditSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgEditSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgEditSubmissionMutation, { data, loading, error }] = usePcgEditSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgEditSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgEditSubmissionMutation, PcgEditSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgEditSubmissionMutation, PcgEditSubmissionMutationVariables>(PcgEditSubmissionDocument, baseOptions);
      }
export type PcgEditSubmissionMutationHookResult = ReturnType<typeof usePcgEditSubmissionMutation>;
export type PcgEditSubmissionMutationResult = ApolloReactCommon.MutationResult<PcgEditSubmissionMutation>;
export type PcgEditSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgEditSubmissionMutation, PcgEditSubmissionMutationVariables>;
export const PcgRequestToBindEmailQuotationDocument = gql`
    mutation pcgRequestToBindEmailQuotation($input: PCGRequestToBindEmailQuotationInput!) {
  pcgRequestToBindEmailQuotation(input: $input) {
    quotation {
      id
      status
      documents {
        id
        category
        version
        ...DocumentForForm
      }
      participation {
        id
        discussion {
          id
          ...Messages
        }
      }
    }
    errors {
      message
      documents
      base
    }
  }
}
    ${DocumentForFormFragmentDoc}
${MessagesFragmentDoc}`;
export type PcgRequestToBindEmailQuotationMutationFn = ApolloReactCommon.MutationFunction<PcgRequestToBindEmailQuotationMutation, PcgRequestToBindEmailQuotationMutationVariables>;
export type PcgRequestToBindEmailQuotationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgRequestToBindEmailQuotationMutation, PcgRequestToBindEmailQuotationMutationVariables>
    } & TChildProps;
export function withPcgRequestToBindEmailQuotation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgRequestToBindEmailQuotationMutation,
  PcgRequestToBindEmailQuotationMutationVariables,
  PcgRequestToBindEmailQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgRequestToBindEmailQuotationMutation, PcgRequestToBindEmailQuotationMutationVariables, PcgRequestToBindEmailQuotationProps<TChildProps, TDataName>>(PcgRequestToBindEmailQuotationDocument, {
      alias: 'pcgRequestToBindEmailQuotation',
      ...operationOptions
    });
};

/**
 * __usePcgRequestToBindEmailQuotationMutation__
 *
 * To run a mutation, you first call `usePcgRequestToBindEmailQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgRequestToBindEmailQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgRequestToBindEmailQuotationMutation, { data, loading, error }] = usePcgRequestToBindEmailQuotationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgRequestToBindEmailQuotationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgRequestToBindEmailQuotationMutation, PcgRequestToBindEmailQuotationMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgRequestToBindEmailQuotationMutation, PcgRequestToBindEmailQuotationMutationVariables>(PcgRequestToBindEmailQuotationDocument, baseOptions);
      }
export type PcgRequestToBindEmailQuotationMutationHookResult = ReturnType<typeof usePcgRequestToBindEmailQuotationMutation>;
export type PcgRequestToBindEmailQuotationMutationResult = ApolloReactCommon.MutationResult<PcgRequestToBindEmailQuotationMutation>;
export type PcgRequestToBindEmailQuotationMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgRequestToBindEmailQuotationMutation, PcgRequestToBindEmailQuotationMutationVariables>;
export const PcgSaveProposalConfigurationDocument = gql`
    mutation PCGSaveProposalConfiguration($input: PcgSaveProposalConfigurationInput!) {
  pcgSaveProposalConfiguration(input: $input) {
    success
  }
}
    `;
export type PcgSaveProposalConfigurationMutationFn = ApolloReactCommon.MutationFunction<PcgSaveProposalConfigurationMutation, PcgSaveProposalConfigurationMutationVariables>;
export type PcgSaveProposalConfigurationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgSaveProposalConfigurationMutation, PcgSaveProposalConfigurationMutationVariables>
    } & TChildProps;
export function withPcgSaveProposalConfiguration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgSaveProposalConfigurationMutation,
  PcgSaveProposalConfigurationMutationVariables,
  PcgSaveProposalConfigurationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgSaveProposalConfigurationMutation, PcgSaveProposalConfigurationMutationVariables, PcgSaveProposalConfigurationProps<TChildProps, TDataName>>(PcgSaveProposalConfigurationDocument, {
      alias: 'pcgSaveProposalConfiguration',
      ...operationOptions
    });
};

/**
 * __usePcgSaveProposalConfigurationMutation__
 *
 * To run a mutation, you first call `usePcgSaveProposalConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgSaveProposalConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgSaveProposalConfigurationMutation, { data, loading, error }] = usePcgSaveProposalConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgSaveProposalConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgSaveProposalConfigurationMutation, PcgSaveProposalConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgSaveProposalConfigurationMutation, PcgSaveProposalConfigurationMutationVariables>(PcgSaveProposalConfigurationDocument, baseOptions);
      }
export type PcgSaveProposalConfigurationMutationHookResult = ReturnType<typeof usePcgSaveProposalConfigurationMutation>;
export type PcgSaveProposalConfigurationMutationResult = ApolloReactCommon.MutationResult<PcgSaveProposalConfigurationMutation>;
export type PcgSaveProposalConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgSaveProposalConfigurationMutation, PcgSaveProposalConfigurationMutationVariables>;
export const PostBroadcastDocument = gql`
    mutation postBroadcast($input: PostBroadcastInput!) {
  postBroadcast(input: $input) {
    broadcast {
      ...Message
      worksheet {
        id
        broadcasts {
          nodes {
            ...Message
          }
        }
      }
    }
    errors
  }
}
    ${MessageFragmentDoc}`;
export type PostBroadcastMutationFn = ApolloReactCommon.MutationFunction<PostBroadcastMutation, PostBroadcastMutationVariables>;
export type PostBroadcastProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PostBroadcastMutation, PostBroadcastMutationVariables>
    } & TChildProps;
export function withPostBroadcast<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PostBroadcastMutation,
  PostBroadcastMutationVariables,
  PostBroadcastProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PostBroadcastMutation, PostBroadcastMutationVariables, PostBroadcastProps<TChildProps, TDataName>>(PostBroadcastDocument, {
      alias: 'postBroadcast',
      ...operationOptions
    });
};

/**
 * __usePostBroadcastMutation__
 *
 * To run a mutation, you first call `usePostBroadcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostBroadcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postBroadcastMutation, { data, loading, error }] = usePostBroadcastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostBroadcastMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostBroadcastMutation, PostBroadcastMutationVariables>) {
        return ApolloReactHooks.useMutation<PostBroadcastMutation, PostBroadcastMutationVariables>(PostBroadcastDocument, baseOptions);
      }
export type PostBroadcastMutationHookResult = ReturnType<typeof usePostBroadcastMutation>;
export type PostBroadcastMutationResult = ApolloReactCommon.MutationResult<PostBroadcastMutation>;
export type PostBroadcastMutationOptions = ApolloReactCommon.BaseMutationOptions<PostBroadcastMutation, PostBroadcastMutationVariables>;
export const PostMessageDocument = gql`
    mutation postMessage($input: PostMessageInput!) {
  postMessage(input: $input) {
    message {
      id
      author {
        id
        emailSignOff
      }
      discussion {
        id
        ...Messages
      }
    }
    errors
  }
}
    ${MessagesFragmentDoc}`;
export type PostMessageMutationFn = ApolloReactCommon.MutationFunction<PostMessageMutation, PostMessageMutationVariables>;
export type PostMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PostMessageMutation, PostMessageMutationVariables>
    } & TChildProps;
export function withPostMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PostMessageMutation,
  PostMessageMutationVariables,
  PostMessageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PostMessageMutation, PostMessageMutationVariables, PostMessageProps<TChildProps, TDataName>>(PostMessageDocument, {
      alias: 'postMessage',
      ...operationOptions
    });
};

/**
 * __usePostMessageMutation__
 *
 * To run a mutation, you first call `usePostMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postMessageMutation, { data, loading, error }] = usePostMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostMessageMutation, PostMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<PostMessageMutation, PostMessageMutationVariables>(PostMessageDocument, baseOptions);
      }
export type PostMessageMutationHookResult = ReturnType<typeof usePostMessageMutation>;
export type PostMessageMutationResult = ApolloReactCommon.MutationResult<PostMessageMutation>;
export type PostMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<PostMessageMutation, PostMessageMutationVariables>;
export const ProposeRevisionDocument = gql`
    mutation proposeRevision($input: ProposeRevisionInput!) {
  proposeRevision(input: $input) {
    revision {
      id
      quote {
        id
        status
        stage
        readableStatus: status(readable: true)
        ...LatestRevision
        ...QuoteMessagesSection
        ...QuoteInfoFragment
      }
    }
    errors
  }
}
    ${LatestRevisionFragmentDoc}
${QuoteMessagesSectionFragmentDoc}
${QuoteInfoFragmentFragmentDoc}`;
export type ProposeRevisionMutationFn = ApolloReactCommon.MutationFunction<ProposeRevisionMutation, ProposeRevisionMutationVariables>;
export type ProposeRevisionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ProposeRevisionMutation, ProposeRevisionMutationVariables>
    } & TChildProps;
export function withProposeRevision<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProposeRevisionMutation,
  ProposeRevisionMutationVariables,
  ProposeRevisionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ProposeRevisionMutation, ProposeRevisionMutationVariables, ProposeRevisionProps<TChildProps, TDataName>>(ProposeRevisionDocument, {
      alias: 'proposeRevision',
      ...operationOptions
    });
};

/**
 * __useProposeRevisionMutation__
 *
 * To run a mutation, you first call `useProposeRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProposeRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proposeRevisionMutation, { data, loading, error }] = useProposeRevisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProposeRevisionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProposeRevisionMutation, ProposeRevisionMutationVariables>) {
        return ApolloReactHooks.useMutation<ProposeRevisionMutation, ProposeRevisionMutationVariables>(ProposeRevisionDocument, baseOptions);
      }
export type ProposeRevisionMutationHookResult = ReturnType<typeof useProposeRevisionMutation>;
export type ProposeRevisionMutationResult = ApolloReactCommon.MutationResult<ProposeRevisionMutation>;
export type ProposeRevisionMutationOptions = ApolloReactCommon.BaseMutationOptions<ProposeRevisionMutation, ProposeRevisionMutationVariables>;
export const AcceptAnswerDocument = gql`
    mutation acceptAnswer($input: AcceptAnswerInput!) {
  acceptAnswer(input: $input) {
    answer {
      id
      acceptedAt
    }
    errors {
      id
    }
  }
}
    `;
export type AcceptAnswerMutationFn = ApolloReactCommon.MutationFunction<AcceptAnswerMutation, AcceptAnswerMutationVariables>;
export type AcceptAnswerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AcceptAnswerMutation, AcceptAnswerMutationVariables>
    } & TChildProps;
export function withAcceptAnswer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AcceptAnswerMutation,
  AcceptAnswerMutationVariables,
  AcceptAnswerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AcceptAnswerMutation, AcceptAnswerMutationVariables, AcceptAnswerProps<TChildProps, TDataName>>(AcceptAnswerDocument, {
      alias: 'acceptAnswer',
      ...operationOptions
    });
};

/**
 * __useAcceptAnswerMutation__
 *
 * To run a mutation, you first call `useAcceptAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAnswerMutation, { data, loading, error }] = useAcceptAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptAnswerMutation, AcceptAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptAnswerMutation, AcceptAnswerMutationVariables>(AcceptAnswerDocument, baseOptions);
      }
export type AcceptAnswerMutationHookResult = ReturnType<typeof useAcceptAnswerMutation>;
export type AcceptAnswerMutationResult = ApolloReactCommon.MutationResult<AcceptAnswerMutation>;
export type AcceptAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptAnswerMutation, AcceptAnswerMutationVariables>;
export const CreateAnswerDocument = gql`
    mutation createAnswer($input: CreateAnswerInput!) {
  createAnswer(input: $input) {
    answer {
      id
      documents {
        category
        version
        ...DocumentForForm
      }
    }
    errors {
      text
      base
      documents {
        attachment
        version
        category
        source
      }
    }
  }
}
    ${DocumentForFormFragmentDoc}`;
export type CreateAnswerMutationFn = ApolloReactCommon.MutationFunction<CreateAnswerMutation, CreateAnswerMutationVariables>;
export type CreateAnswerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateAnswerMutation, CreateAnswerMutationVariables>
    } & TChildProps;
export function withCreateAnswer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAnswerMutation,
  CreateAnswerMutationVariables,
  CreateAnswerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAnswerMutation, CreateAnswerMutationVariables, CreateAnswerProps<TChildProps, TDataName>>(CreateAnswerDocument, {
      alias: 'createAnswer',
      ...operationOptions
    });
};

/**
 * __useCreateAnswerMutation__
 *
 * To run a mutation, you first call `useCreateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnswerMutation, { data, loading, error }] = useCreateAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAnswerMutation, CreateAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAnswerMutation, CreateAnswerMutationVariables>(CreateAnswerDocument, baseOptions);
      }
export type CreateAnswerMutationHookResult = ReturnType<typeof useCreateAnswerMutation>;
export type CreateAnswerMutationResult = ApolloReactCommon.MutationResult<CreateAnswerMutation>;
export type CreateAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAnswerMutation, CreateAnswerMutationVariables>;
export const CreateFollowUpQuestionDocument = gql`
    mutation createFollowUpQuestion($input: CreateFollowUpQuestionInput!) {
  createFollowUpQuestion(input: $input) {
    question {
      id
      text
      section
      deadline
      documents {
        category
        version
        ...DocumentForForm
      }
    }
    errors {
      section
      text
      deadline
      documents {
        attachment
        version
        category
        source
      }
    }
  }
}
    ${DocumentForFormFragmentDoc}`;
export type CreateFollowUpQuestionMutationFn = ApolloReactCommon.MutationFunction<CreateFollowUpQuestionMutation, CreateFollowUpQuestionMutationVariables>;
export type CreateFollowUpQuestionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateFollowUpQuestionMutation, CreateFollowUpQuestionMutationVariables>
    } & TChildProps;
export function withCreateFollowUpQuestion<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateFollowUpQuestionMutation,
  CreateFollowUpQuestionMutationVariables,
  CreateFollowUpQuestionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateFollowUpQuestionMutation, CreateFollowUpQuestionMutationVariables, CreateFollowUpQuestionProps<TChildProps, TDataName>>(CreateFollowUpQuestionDocument, {
      alias: 'createFollowUpQuestion',
      ...operationOptions
    });
};

/**
 * __useCreateFollowUpQuestionMutation__
 *
 * To run a mutation, you first call `useCreateFollowUpQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFollowUpQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFollowUpQuestionMutation, { data, loading, error }] = useCreateFollowUpQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFollowUpQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFollowUpQuestionMutation, CreateFollowUpQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFollowUpQuestionMutation, CreateFollowUpQuestionMutationVariables>(CreateFollowUpQuestionDocument, baseOptions);
      }
export type CreateFollowUpQuestionMutationHookResult = ReturnType<typeof useCreateFollowUpQuestionMutation>;
export type CreateFollowUpQuestionMutationResult = ApolloReactCommon.MutationResult<CreateFollowUpQuestionMutation>;
export type CreateFollowUpQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFollowUpQuestionMutation, CreateFollowUpQuestionMutationVariables>;
export const CreateQuestionDocument = gql`
    mutation createQuestion($input: CreateQuestionInput!) {
  createQuestion(input: $input) {
    question {
      id
      text
      section
      deadline
      documents {
        category
        version
        ...DocumentForForm
      }
    }
    errors {
      section
      text
      deadline
      documents {
        attachment
        version
        category
        source
      }
    }
  }
}
    ${DocumentForFormFragmentDoc}`;
export type CreateQuestionMutationFn = ApolloReactCommon.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;
export type CreateQuestionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>
    } & TChildProps;
export function withCreateQuestion<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateQuestionMutation,
  CreateQuestionMutationVariables,
  CreateQuestionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateQuestionMutation, CreateQuestionMutationVariables, CreateQuestionProps<TChildProps, TDataName>>(CreateQuestionDocument, {
      alias: 'createQuestion',
      ...operationOptions
    });
};

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, baseOptions);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = ApolloReactCommon.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const DeleteAnswerDocument = gql`
    mutation deleteAnswer($input: DeleteAnswerInput!) {
  deleteAnswer(input: $input) {
    deletedId
    error
  }
}
    `;
export type DeleteAnswerMutationFn = ApolloReactCommon.MutationFunction<DeleteAnswerMutation, DeleteAnswerMutationVariables>;
export type DeleteAnswerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteAnswerMutation, DeleteAnswerMutationVariables>
    } & TChildProps;
export function withDeleteAnswer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAnswerMutation,
  DeleteAnswerMutationVariables,
  DeleteAnswerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAnswerMutation, DeleteAnswerMutationVariables, DeleteAnswerProps<TChildProps, TDataName>>(DeleteAnswerDocument, {
      alias: 'deleteAnswer',
      ...operationOptions
    });
};

/**
 * __useDeleteAnswerMutation__
 *
 * To run a mutation, you first call `useDeleteAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnswerMutation, { data, loading, error }] = useDeleteAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAnswerMutation, DeleteAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAnswerMutation, DeleteAnswerMutationVariables>(DeleteAnswerDocument, baseOptions);
      }
export type DeleteAnswerMutationHookResult = ReturnType<typeof useDeleteAnswerMutation>;
export type DeleteAnswerMutationResult = ApolloReactCommon.MutationResult<DeleteAnswerMutation>;
export type DeleteAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAnswerMutation, DeleteAnswerMutationVariables>;
export const DeleteQuestionDocument = gql`
    mutation deleteQuestion($input: DeleteQuestionInput!) {
  deleteQuestion(input: $input) {
    deletedId
    error
  }
}
    `;
export type DeleteQuestionMutationFn = ApolloReactCommon.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export type DeleteQuestionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>
    } & TChildProps;
export function withDeleteQuestion<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteQuestionMutation,
  DeleteQuestionMutationVariables,
  DeleteQuestionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteQuestionMutation, DeleteQuestionMutationVariables, DeleteQuestionProps<TChildProps, TDataName>>(DeleteQuestionDocument, {
      alias: 'deleteQuestion',
      ...operationOptions
    });
};

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, baseOptions);
      }
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>;
export type DeleteQuestionMutationResult = ApolloReactCommon.MutationResult<DeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export const EditAnswerDocument = gql`
    mutation editAnswer($input: EditAnswerInput!) {
  editAnswer(input: $input) {
    answer {
      id
      text
      documents {
        category
        version
        ...DocumentForForm
      }
    }
    errors {
      id
      text
      documents {
        attachment
        version
        category
        source
      }
    }
  }
}
    ${DocumentForFormFragmentDoc}`;
export type EditAnswerMutationFn = ApolloReactCommon.MutationFunction<EditAnswerMutation, EditAnswerMutationVariables>;
export type EditAnswerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EditAnswerMutation, EditAnswerMutationVariables>
    } & TChildProps;
export function withEditAnswer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditAnswerMutation,
  EditAnswerMutationVariables,
  EditAnswerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EditAnswerMutation, EditAnswerMutationVariables, EditAnswerProps<TChildProps, TDataName>>(EditAnswerDocument, {
      alias: 'editAnswer',
      ...operationOptions
    });
};

/**
 * __useEditAnswerMutation__
 *
 * To run a mutation, you first call `useEditAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAnswerMutation, { data, loading, error }] = useEditAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAnswerMutation, EditAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAnswerMutation, EditAnswerMutationVariables>(EditAnswerDocument, baseOptions);
      }
export type EditAnswerMutationHookResult = ReturnType<typeof useEditAnswerMutation>;
export type EditAnswerMutationResult = ApolloReactCommon.MutationResult<EditAnswerMutation>;
export type EditAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAnswerMutation, EditAnswerMutationVariables>;
export const EditQuestionDocument = gql`
    mutation editQuestion($input: EditQuestionInput!) {
  editQuestion(input: $input) {
    question {
      id
      section
      text
      deadline
      documents {
        category
        version
        ...DocumentForForm
      }
    }
    errors {
      section
      text
      deadline
      documents {
        attachment
        version
        category
        source
      }
    }
  }
}
    ${DocumentForFormFragmentDoc}`;
export type EditQuestionMutationFn = ApolloReactCommon.MutationFunction<EditQuestionMutation, EditQuestionMutationVariables>;
export type EditQuestionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EditQuestionMutation, EditQuestionMutationVariables>
    } & TChildProps;
export function withEditQuestion<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditQuestionMutation,
  EditQuestionMutationVariables,
  EditQuestionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EditQuestionMutation, EditQuestionMutationVariables, EditQuestionProps<TChildProps, TDataName>>(EditQuestionDocument, {
      alias: 'editQuestion',
      ...operationOptions
    });
};

/**
 * __useEditQuestionMutation__
 *
 * To run a mutation, you first call `useEditQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editQuestionMutation, { data, loading, error }] = useEditQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditQuestionMutation, EditQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<EditQuestionMutation, EditQuestionMutationVariables>(EditQuestionDocument, baseOptions);
      }
export type EditQuestionMutationHookResult = ReturnType<typeof useEditQuestionMutation>;
export type EditQuestionMutationResult = ApolloReactCommon.MutationResult<EditQuestionMutation>;
export type EditQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<EditQuestionMutation, EditQuestionMutationVariables>;
export const ReleaseAnswerDocument = gql`
    mutation releaseAnswer($input: ReleaseAnswerInput!) {
  releaseAnswer(input: $input) {
    answer {
      id
      releasedAt
    }
    errors {
      id
    }
  }
}
    `;
export type ReleaseAnswerMutationFn = ApolloReactCommon.MutationFunction<ReleaseAnswerMutation, ReleaseAnswerMutationVariables>;
export type ReleaseAnswerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ReleaseAnswerMutation, ReleaseAnswerMutationVariables>
    } & TChildProps;
export function withReleaseAnswer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReleaseAnswerMutation,
  ReleaseAnswerMutationVariables,
  ReleaseAnswerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ReleaseAnswerMutation, ReleaseAnswerMutationVariables, ReleaseAnswerProps<TChildProps, TDataName>>(ReleaseAnswerDocument, {
      alias: 'releaseAnswer',
      ...operationOptions
    });
};

/**
 * __useReleaseAnswerMutation__
 *
 * To run a mutation, you first call `useReleaseAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseAnswerMutation, { data, loading, error }] = useReleaseAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReleaseAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReleaseAnswerMutation, ReleaseAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<ReleaseAnswerMutation, ReleaseAnswerMutationVariables>(ReleaseAnswerDocument, baseOptions);
      }
export type ReleaseAnswerMutationHookResult = ReturnType<typeof useReleaseAnswerMutation>;
export type ReleaseAnswerMutationResult = ApolloReactCommon.MutationResult<ReleaseAnswerMutation>;
export type ReleaseAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<ReleaseAnswerMutation, ReleaseAnswerMutationVariables>;
export const ShareQuestionsDocument = gql`
    mutation shareQuestions($input: ShareQuestionableForAnsweringInput!) {
  shareQuestionableForAnswering(input: $input) {
    token {
      id
      expiresAt
      scope
      shareableType
      token
    }
    shareableLink
    errors {
      base
      email
      message
    }
  }
}
    `;
export type ShareQuestionsMutationFn = ApolloReactCommon.MutationFunction<ShareQuestionsMutation, ShareQuestionsMutationVariables>;
export type ShareQuestionsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ShareQuestionsMutation, ShareQuestionsMutationVariables>
    } & TChildProps;
export function withShareQuestions<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ShareQuestionsMutation,
  ShareQuestionsMutationVariables,
  ShareQuestionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ShareQuestionsMutation, ShareQuestionsMutationVariables, ShareQuestionsProps<TChildProps, TDataName>>(ShareQuestionsDocument, {
      alias: 'shareQuestions',
      ...operationOptions
    });
};

/**
 * __useShareQuestionsMutation__
 *
 * To run a mutation, you first call `useShareQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareQuestionsMutation, { data, loading, error }] = useShareQuestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareQuestionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShareQuestionsMutation, ShareQuestionsMutationVariables>) {
        return ApolloReactHooks.useMutation<ShareQuestionsMutation, ShareQuestionsMutationVariables>(ShareQuestionsDocument, baseOptions);
      }
export type ShareQuestionsMutationHookResult = ReturnType<typeof useShareQuestionsMutation>;
export type ShareQuestionsMutationResult = ApolloReactCommon.MutationResult<ShareQuestionsMutation>;
export type ShareQuestionsMutationOptions = ApolloReactCommon.BaseMutationOptions<ShareQuestionsMutation, ShareQuestionsMutationVariables>;
export const UnapproveAnswerDocument = gql`
    mutation unapproveAnswer($input: UnapproveAnswerInput!) {
  unapproveAnswer(input: $input) {
    answer {
      id
      acceptedAt
    }
    errors {
      id
    }
  }
}
    `;
export type UnapproveAnswerMutationFn = ApolloReactCommon.MutationFunction<UnapproveAnswerMutation, UnapproveAnswerMutationVariables>;
export type UnapproveAnswerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnapproveAnswerMutation, UnapproveAnswerMutationVariables>
    } & TChildProps;
export function withUnapproveAnswer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnapproveAnswerMutation,
  UnapproveAnswerMutationVariables,
  UnapproveAnswerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnapproveAnswerMutation, UnapproveAnswerMutationVariables, UnapproveAnswerProps<TChildProps, TDataName>>(UnapproveAnswerDocument, {
      alias: 'unapproveAnswer',
      ...operationOptions
    });
};

/**
 * __useUnapproveAnswerMutation__
 *
 * To run a mutation, you first call `useUnapproveAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnapproveAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unapproveAnswerMutation, { data, loading, error }] = useUnapproveAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnapproveAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnapproveAnswerMutation, UnapproveAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<UnapproveAnswerMutation, UnapproveAnswerMutationVariables>(UnapproveAnswerDocument, baseOptions);
      }
export type UnapproveAnswerMutationHookResult = ReturnType<typeof useUnapproveAnswerMutation>;
export type UnapproveAnswerMutationResult = ApolloReactCommon.MutationResult<UnapproveAnswerMutation>;
export type UnapproveAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<UnapproveAnswerMutation, UnapproveAnswerMutationVariables>;
export const UpdateQuotationSelectionDocument = gql`
    mutation updateQuotationSelection($input: QuotationsUpdateSelectionInput!) {
  updateQuotationSelection(input: $input) {
    quotation {
      id
      selected
    }
    error
  }
}
    `;
export type UpdateQuotationSelectionMutationFn = ApolloReactCommon.MutationFunction<UpdateQuotationSelectionMutation, UpdateQuotationSelectionMutationVariables>;
export type UpdateQuotationSelectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateQuotationSelectionMutation, UpdateQuotationSelectionMutationVariables>
    } & TChildProps;
export function withUpdateQuotationSelection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateQuotationSelectionMutation,
  UpdateQuotationSelectionMutationVariables,
  UpdateQuotationSelectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateQuotationSelectionMutation, UpdateQuotationSelectionMutationVariables, UpdateQuotationSelectionProps<TChildProps, TDataName>>(UpdateQuotationSelectionDocument, {
      alias: 'updateQuotationSelection',
      ...operationOptions
    });
};

/**
 * __useUpdateQuotationSelectionMutation__
 *
 * To run a mutation, you first call `useUpdateQuotationSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuotationSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuotationSelectionMutation, { data, loading, error }] = useUpdateQuotationSelectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuotationSelectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuotationSelectionMutation, UpdateQuotationSelectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQuotationSelectionMutation, UpdateQuotationSelectionMutationVariables>(UpdateQuotationSelectionDocument, baseOptions);
      }
export type UpdateQuotationSelectionMutationHookResult = ReturnType<typeof useUpdateQuotationSelectionMutation>;
export type UpdateQuotationSelectionMutationResult = ApolloReactCommon.MutationResult<UpdateQuotationSelectionMutation>;
export type UpdateQuotationSelectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuotationSelectionMutation, UpdateQuotationSelectionMutationVariables>;
export const ChangeQuoteOwnerNewUserDocument = gql`
    mutation changeQuoteOwnerNewUser($input: ChangeQuoteWithNewOwnerInput!) {
  changeQuoteOwnerNewUser(input: $input) {
    invitationToken
    quote {
      id
      reinsurer {
        id
        quotes {
          edges {
            node {
              id
            }
          }
        }
      }
    }
    errors
  }
}
    `;
export type ChangeQuoteOwnerNewUserMutationFn = ApolloReactCommon.MutationFunction<ChangeQuoteOwnerNewUserMutation, ChangeQuoteOwnerNewUserMutationVariables>;
export type ChangeQuoteOwnerNewUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangeQuoteOwnerNewUserMutation, ChangeQuoteOwnerNewUserMutationVariables>
    } & TChildProps;
export function withChangeQuoteOwnerNewUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangeQuoteOwnerNewUserMutation,
  ChangeQuoteOwnerNewUserMutationVariables,
  ChangeQuoteOwnerNewUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangeQuoteOwnerNewUserMutation, ChangeQuoteOwnerNewUserMutationVariables, ChangeQuoteOwnerNewUserProps<TChildProps, TDataName>>(ChangeQuoteOwnerNewUserDocument, {
      alias: 'changeQuoteOwnerNewUser',
      ...operationOptions
    });
};

/**
 * __useChangeQuoteOwnerNewUserMutation__
 *
 * To run a mutation, you first call `useChangeQuoteOwnerNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeQuoteOwnerNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeQuoteOwnerNewUserMutation, { data, loading, error }] = useChangeQuoteOwnerNewUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeQuoteOwnerNewUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeQuoteOwnerNewUserMutation, ChangeQuoteOwnerNewUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeQuoteOwnerNewUserMutation, ChangeQuoteOwnerNewUserMutationVariables>(ChangeQuoteOwnerNewUserDocument, baseOptions);
      }
export type ChangeQuoteOwnerNewUserMutationHookResult = ReturnType<typeof useChangeQuoteOwnerNewUserMutation>;
export type ChangeQuoteOwnerNewUserMutationResult = ApolloReactCommon.MutationResult<ChangeQuoteOwnerNewUserMutation>;
export type ChangeQuoteOwnerNewUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeQuoteOwnerNewUserMutation, ChangeQuoteOwnerNewUserMutationVariables>;
export const CreateBinderDocument = gql`
    mutation createBinder($input: CreateBinderInput!) {
  createBinder(input: $input) {
    quote {
      id
      ...QuoteInfoFragment
      ...QuoteMessagesSection
    }
    errors
  }
}
    ${QuoteInfoFragmentFragmentDoc}
${QuoteMessagesSectionFragmentDoc}`;
export type CreateBinderMutationFn = ApolloReactCommon.MutationFunction<CreateBinderMutation, CreateBinderMutationVariables>;
export type CreateBinderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateBinderMutation, CreateBinderMutationVariables>
    } & TChildProps;
export function withCreateBinder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBinderMutation,
  CreateBinderMutationVariables,
  CreateBinderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBinderMutation, CreateBinderMutationVariables, CreateBinderProps<TChildProps, TDataName>>(CreateBinderDocument, {
      alias: 'createBinder',
      ...operationOptions
    });
};

/**
 * __useCreateBinderMutation__
 *
 * To run a mutation, you first call `useCreateBinderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBinderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBinderMutation, { data, loading, error }] = useCreateBinderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBinderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBinderMutation, CreateBinderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBinderMutation, CreateBinderMutationVariables>(CreateBinderDocument, baseOptions);
      }
export type CreateBinderMutationHookResult = ReturnType<typeof useCreateBinderMutation>;
export type CreateBinderMutationResult = ApolloReactCommon.MutationResult<CreateBinderMutation>;
export type CreateBinderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBinderMutation, CreateBinderMutationVariables>;
export const CreateBinderWithQuoteDocument = gql`
    mutation createBinderWithQuote($input: CreateBinderWithQuoteInput!) {
  createBinderWithQuote(input: $input) {
    quote {
      id
      status
      readableStatus: status(readable: true)
      stage
      binder {
        nodes {
          id
          type
          url
        }
      }
      ...LatestRevision
      ...QuoteMessagesSection
    }
    errors
  }
}
    ${LatestRevisionFragmentDoc}
${QuoteMessagesSectionFragmentDoc}`;
export type CreateBinderWithQuoteMutationFn = ApolloReactCommon.MutationFunction<CreateBinderWithQuoteMutation, CreateBinderWithQuoteMutationVariables>;
export type CreateBinderWithQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateBinderWithQuoteMutation, CreateBinderWithQuoteMutationVariables>
    } & TChildProps;
export function withCreateBinderWithQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBinderWithQuoteMutation,
  CreateBinderWithQuoteMutationVariables,
  CreateBinderWithQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBinderWithQuoteMutation, CreateBinderWithQuoteMutationVariables, CreateBinderWithQuoteProps<TChildProps, TDataName>>(CreateBinderWithQuoteDocument, {
      alias: 'createBinderWithQuote',
      ...operationOptions
    });
};

/**
 * __useCreateBinderWithQuoteMutation__
 *
 * To run a mutation, you first call `useCreateBinderWithQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBinderWithQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBinderWithQuoteMutation, { data, loading, error }] = useCreateBinderWithQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBinderWithQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBinderWithQuoteMutation, CreateBinderWithQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBinderWithQuoteMutation, CreateBinderWithQuoteMutationVariables>(CreateBinderWithQuoteDocument, baseOptions);
      }
export type CreateBinderWithQuoteMutationHookResult = ReturnType<typeof useCreateBinderWithQuoteMutation>;
export type CreateBinderWithQuoteMutationResult = ApolloReactCommon.MutationResult<CreateBinderWithQuoteMutation>;
export type CreateBinderWithQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBinderWithQuoteMutation, CreateBinderWithQuoteMutationVariables>;
export const ManuallyCreateQuoteDocument = gql`
    mutation manuallyCreateQuote($input: ManualCreateQuoteInput!) {
  manuallyCreateQuote(input: $input) {
    worksheet {
      id
      quotes {
        nodes {
          id
          ...QuoteInfoFragment
        }
      }
    }
  }
}
    ${QuoteInfoFragmentFragmentDoc}`;
export type ManuallyCreateQuoteMutationFn = ApolloReactCommon.MutationFunction<ManuallyCreateQuoteMutation, ManuallyCreateQuoteMutationVariables>;
export type ManuallyCreateQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ManuallyCreateQuoteMutation, ManuallyCreateQuoteMutationVariables>
    } & TChildProps;
export function withManuallyCreateQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ManuallyCreateQuoteMutation,
  ManuallyCreateQuoteMutationVariables,
  ManuallyCreateQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ManuallyCreateQuoteMutation, ManuallyCreateQuoteMutationVariables, ManuallyCreateQuoteProps<TChildProps, TDataName>>(ManuallyCreateQuoteDocument, {
      alias: 'manuallyCreateQuote',
      ...operationOptions
    });
};

/**
 * __useManuallyCreateQuoteMutation__
 *
 * To run a mutation, you first call `useManuallyCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManuallyCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manuallyCreateQuoteMutation, { data, loading, error }] = useManuallyCreateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManuallyCreateQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ManuallyCreateQuoteMutation, ManuallyCreateQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<ManuallyCreateQuoteMutation, ManuallyCreateQuoteMutationVariables>(ManuallyCreateQuoteDocument, baseOptions);
      }
export type ManuallyCreateQuoteMutationHookResult = ReturnType<typeof useManuallyCreateQuoteMutation>;
export type ManuallyCreateQuoteMutationResult = ApolloReactCommon.MutationResult<ManuallyCreateQuoteMutation>;
export type ManuallyCreateQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<ManuallyCreateQuoteMutation, ManuallyCreateQuoteMutationVariables>;
export const PreBindEditDocument = gql`
    mutation preBindEdit($input: PreBindEditInput!) {
  preBindEdit(input: $input) {
    quote {
      id
      ...QuoteInfoFragment
    }
  }
}
    ${QuoteInfoFragmentFragmentDoc}`;
export type PreBindEditMutationFn = ApolloReactCommon.MutationFunction<PreBindEditMutation, PreBindEditMutationVariables>;
export type PreBindEditProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PreBindEditMutation, PreBindEditMutationVariables>
    } & TChildProps;
export function withPreBindEdit<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PreBindEditMutation,
  PreBindEditMutationVariables,
  PreBindEditProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PreBindEditMutation, PreBindEditMutationVariables, PreBindEditProps<TChildProps, TDataName>>(PreBindEditDocument, {
      alias: 'preBindEdit',
      ...operationOptions
    });
};

/**
 * __usePreBindEditMutation__
 *
 * To run a mutation, you first call `usePreBindEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreBindEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preBindEditMutation, { data, loading, error }] = usePreBindEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreBindEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PreBindEditMutation, PreBindEditMutationVariables>) {
        return ApolloReactHooks.useMutation<PreBindEditMutation, PreBindEditMutationVariables>(PreBindEditDocument, baseOptions);
      }
export type PreBindEditMutationHookResult = ReturnType<typeof usePreBindEditMutation>;
export type PreBindEditMutationResult = ApolloReactCommon.MutationResult<PreBindEditMutation>;
export type PreBindEditMutationOptions = ApolloReactCommon.BaseMutationOptions<PreBindEditMutation, PreBindEditMutationVariables>;
export const ViewedQuoteDocument = gql`
    mutation viewedQuote($input: ViewedQuoteInput!) {
  viewedQuote(input: $input) {
    quote {
      id
    }
  }
}
    `;
export type ViewedQuoteMutationFn = ApolloReactCommon.MutationFunction<ViewedQuoteMutation, ViewedQuoteMutationVariables>;
export type ViewedQuoteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ViewedQuoteMutation, ViewedQuoteMutationVariables>
    } & TChildProps;
export function withViewedQuote<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewedQuoteMutation,
  ViewedQuoteMutationVariables,
  ViewedQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ViewedQuoteMutation, ViewedQuoteMutationVariables, ViewedQuoteProps<TChildProps, TDataName>>(ViewedQuoteDocument, {
      alias: 'viewedQuote',
      ...operationOptions
    });
};

/**
 * __useViewedQuoteMutation__
 *
 * To run a mutation, you first call `useViewedQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewedQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewedQuoteMutation, { data, loading, error }] = useViewedQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewedQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ViewedQuoteMutation, ViewedQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<ViewedQuoteMutation, ViewedQuoteMutationVariables>(ViewedQuoteDocument, baseOptions);
      }
export type ViewedQuoteMutationHookResult = ReturnType<typeof useViewedQuoteMutation>;
export type ViewedQuoteMutationResult = ApolloReactCommon.MutationResult<ViewedQuoteMutation>;
export type ViewedQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<ViewedQuoteMutation, ViewedQuoteMutationVariables>;
export const ReadMessageDocument = gql`
    mutation readMessage($input: ReadMessageInput!) {
  readMessage(input: $input) {
    discussable {
      id
      ... on Discussable {
        discussion {
          id
          ...DiscussionParticipants
        }
      }
    }
    errors
  }
}
    ${DiscussionParticipantsFragmentDoc}`;
export type ReadMessageMutationFn = ApolloReactCommon.MutationFunction<ReadMessageMutation, ReadMessageMutationVariables>;
export type ReadMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ReadMessageMutation, ReadMessageMutationVariables>
    } & TChildProps;
export function withReadMessage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReadMessageMutation,
  ReadMessageMutationVariables,
  ReadMessageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ReadMessageMutation, ReadMessageMutationVariables, ReadMessageProps<TChildProps, TDataName>>(ReadMessageDocument, {
      alias: 'readMessage',
      ...operationOptions
    });
};

/**
 * __useReadMessageMutation__
 *
 * To run a mutation, you first call `useReadMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessageMutation, { data, loading, error }] = useReadMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadMessageMutation, ReadMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<ReadMessageMutation, ReadMessageMutationVariables>(ReadMessageDocument, baseOptions);
      }
export type ReadMessageMutationHookResult = ReturnType<typeof useReadMessageMutation>;
export type ReadMessageMutationResult = ApolloReactCommon.MutationResult<ReadMessageMutation>;
export type ReadMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadMessageMutation, ReadMessageMutationVariables>;
export const RequestBinderDocument = gql`
    mutation requestBinder($input: RequestBinderInput!) {
  requestBinder(input: $input) {
    quote {
      id
      status
      readableStatus: status(readable: true)
      stage
      binder {
        nodes {
          id
          url
          filename
          createdAt
        }
      }
      certificate {
        nodes {
          id
          url
          filename
          createdAt
        }
      }
    }
    errors
  }
}
    `;
export type RequestBinderMutationFn = ApolloReactCommon.MutationFunction<RequestBinderMutation, RequestBinderMutationVariables>;
export type RequestBinderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RequestBinderMutation, RequestBinderMutationVariables>
    } & TChildProps;
export function withRequestBinder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RequestBinderMutation,
  RequestBinderMutationVariables,
  RequestBinderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RequestBinderMutation, RequestBinderMutationVariables, RequestBinderProps<TChildProps, TDataName>>(RequestBinderDocument, {
      alias: 'requestBinder',
      ...operationOptions
    });
};

/**
 * __useRequestBinderMutation__
 *
 * To run a mutation, you first call `useRequestBinderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestBinderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestBinderMutation, { data, loading, error }] = useRequestBinderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestBinderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestBinderMutation, RequestBinderMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestBinderMutation, RequestBinderMutationVariables>(RequestBinderDocument, baseOptions);
      }
export type RequestBinderMutationHookResult = ReturnType<typeof useRequestBinderMutation>;
export type RequestBinderMutationResult = ApolloReactCommon.MutationResult<RequestBinderMutation>;
export type RequestBinderMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestBinderMutation, RequestBinderMutationVariables>;
export const CreateRevisionDocument = gql`
    mutation createRevision($input: CreateRevisionInput!) {
  createRevision(input: $input) {
    revision {
      id
      status
      quote {
        id
        status
        stage
        readableStatus: status(readable: true)
        ...LatestRevision
        ...QuoteMessagesSection
        revisions {
          nodes {
            id
            createdAt
            number
            isIncomplete
            zip {
              id
              url
            }
          }
        }
      }
    }
    errors
  }
}
    ${LatestRevisionFragmentDoc}
${QuoteMessagesSectionFragmentDoc}`;
export type CreateRevisionMutationFn = ApolloReactCommon.MutationFunction<CreateRevisionMutation, CreateRevisionMutationVariables>;
export type CreateRevisionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateRevisionMutation, CreateRevisionMutationVariables>
    } & TChildProps;
export function withCreateRevision<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateRevisionMutation,
  CreateRevisionMutationVariables,
  CreateRevisionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateRevisionMutation, CreateRevisionMutationVariables, CreateRevisionProps<TChildProps, TDataName>>(CreateRevisionDocument, {
      alias: 'createRevision',
      ...operationOptions
    });
};

/**
 * __useCreateRevisionMutation__
 *
 * To run a mutation, you first call `useCreateRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRevisionMutation, { data, loading, error }] = useCreateRevisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRevisionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRevisionMutation, CreateRevisionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRevisionMutation, CreateRevisionMutationVariables>(CreateRevisionDocument, baseOptions);
      }
export type CreateRevisionMutationHookResult = ReturnType<typeof useCreateRevisionMutation>;
export type CreateRevisionMutationResult = ApolloReactCommon.MutationResult<CreateRevisionMutation>;
export type CreateRevisionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRevisionMutation, CreateRevisionMutationVariables>;
export const DeleteRevisionDocument = gql`
    mutation deleteRevision($input: DeleteRevisionInput!) {
  deleteRevision(input: $input) {
    errors
  }
}
    `;
export type DeleteRevisionMutationFn = ApolloReactCommon.MutationFunction<DeleteRevisionMutation, DeleteRevisionMutationVariables>;
export type DeleteRevisionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteRevisionMutation, DeleteRevisionMutationVariables>
    } & TChildProps;
export function withDeleteRevision<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteRevisionMutation,
  DeleteRevisionMutationVariables,
  DeleteRevisionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteRevisionMutation, DeleteRevisionMutationVariables, DeleteRevisionProps<TChildProps, TDataName>>(DeleteRevisionDocument, {
      alias: 'deleteRevision',
      ...operationOptions
    });
};

/**
 * __useDeleteRevisionMutation__
 *
 * To run a mutation, you first call `useDeleteRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRevisionMutation, { data, loading, error }] = useDeleteRevisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRevisionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRevisionMutation, DeleteRevisionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRevisionMutation, DeleteRevisionMutationVariables>(DeleteRevisionDocument, baseOptions);
      }
export type DeleteRevisionMutationHookResult = ReturnType<typeof useDeleteRevisionMutation>;
export type DeleteRevisionMutationResult = ApolloReactCommon.MutationResult<DeleteRevisionMutation>;
export type DeleteRevisionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRevisionMutation, DeleteRevisionMutationVariables>;
export const SavePanelProposalConfigurationDocument = gql`
    mutation SavePanelProposalConfiguration($input: SavePanelProposalConfigurationInput!) {
  savePanelProposalConfiguration(input: $input) {
    panel {
      id
      name
      lineOfBusiness
      proposalConfiguration {
        id
        configuration
      }
    }
    errors
  }
}
    `;
export type SavePanelProposalConfigurationMutationFn = ApolloReactCommon.MutationFunction<SavePanelProposalConfigurationMutation, SavePanelProposalConfigurationMutationVariables>;
export type SavePanelProposalConfigurationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SavePanelProposalConfigurationMutation, SavePanelProposalConfigurationMutationVariables>
    } & TChildProps;
export function withSavePanelProposalConfiguration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SavePanelProposalConfigurationMutation,
  SavePanelProposalConfigurationMutationVariables,
  SavePanelProposalConfigurationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SavePanelProposalConfigurationMutation, SavePanelProposalConfigurationMutationVariables, SavePanelProposalConfigurationProps<TChildProps, TDataName>>(SavePanelProposalConfigurationDocument, {
      alias: 'savePanelProposalConfiguration',
      ...operationOptions
    });
};

/**
 * __useSavePanelProposalConfigurationMutation__
 *
 * To run a mutation, you first call `useSavePanelProposalConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePanelProposalConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePanelProposalConfigurationMutation, { data, loading, error }] = useSavePanelProposalConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePanelProposalConfigurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SavePanelProposalConfigurationMutation, SavePanelProposalConfigurationMutationVariables>) {
        return ApolloReactHooks.useMutation<SavePanelProposalConfigurationMutation, SavePanelProposalConfigurationMutationVariables>(SavePanelProposalConfigurationDocument, baseOptions);
      }
export type SavePanelProposalConfigurationMutationHookResult = ReturnType<typeof useSavePanelProposalConfigurationMutation>;
export type SavePanelProposalConfigurationMutationResult = ApolloReactCommon.MutationResult<SavePanelProposalConfigurationMutation>;
export type SavePanelProposalConfigurationMutationOptions = ApolloReactCommon.BaseMutationOptions<SavePanelProposalConfigurationMutation, SavePanelProposalConfigurationMutationVariables>;
export const SetQuoteReleasedStatusDocument = gql`
    mutation setQuoteReleasedStatus($input: SetQuoteReleasedStatusInput!) {
  setQuoteReleasedStatus(input: $input) {
    quote {
      id
      releasedAt
    }
    errors
  }
}
    `;
export type SetQuoteReleasedStatusMutationFn = ApolloReactCommon.MutationFunction<SetQuoteReleasedStatusMutation, SetQuoteReleasedStatusMutationVariables>;
export type SetQuoteReleasedStatusProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SetQuoteReleasedStatusMutation, SetQuoteReleasedStatusMutationVariables>
    } & TChildProps;
export function withSetQuoteReleasedStatus<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetQuoteReleasedStatusMutation,
  SetQuoteReleasedStatusMutationVariables,
  SetQuoteReleasedStatusProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SetQuoteReleasedStatusMutation, SetQuoteReleasedStatusMutationVariables, SetQuoteReleasedStatusProps<TChildProps, TDataName>>(SetQuoteReleasedStatusDocument, {
      alias: 'setQuoteReleasedStatus',
      ...operationOptions
    });
};

/**
 * __useSetQuoteReleasedStatusMutation__
 *
 * To run a mutation, you first call `useSetQuoteReleasedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetQuoteReleasedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setQuoteReleasedStatusMutation, { data, loading, error }] = useSetQuoteReleasedStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetQuoteReleasedStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetQuoteReleasedStatusMutation, SetQuoteReleasedStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<SetQuoteReleasedStatusMutation, SetQuoteReleasedStatusMutationVariables>(SetQuoteReleasedStatusDocument, baseOptions);
      }
export type SetQuoteReleasedStatusMutationHookResult = ReturnType<typeof useSetQuoteReleasedStatusMutation>;
export type SetQuoteReleasedStatusMutationResult = ApolloReactCommon.MutationResult<SetQuoteReleasedStatusMutation>;
export type SetQuoteReleasedStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<SetQuoteReleasedStatusMutation, SetQuoteReleasedStatusMutationVariables>;
export const SetCompanySettingsDocument = gql`
    mutation setCompanySettings($input: SetCompanySettingsInput!) {
  setCompanySettings(input: $input) {
    company {
      id
      emailSubmissionResponse
      emailSubmissionsManager {
        id
      }
    }
    errors
  }
}
    `;
export type SetCompanySettingsMutationFn = ApolloReactCommon.MutationFunction<SetCompanySettingsMutation, SetCompanySettingsMutationVariables>;
export type SetCompanySettingsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SetCompanySettingsMutation, SetCompanySettingsMutationVariables>
    } & TChildProps;
export function withSetCompanySettings<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetCompanySettingsMutation,
  SetCompanySettingsMutationVariables,
  SetCompanySettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SetCompanySettingsMutation, SetCompanySettingsMutationVariables, SetCompanySettingsProps<TChildProps, TDataName>>(SetCompanySettingsDocument, {
      alias: 'setCompanySettings',
      ...operationOptions
    });
};

/**
 * __useSetCompanySettingsMutation__
 *
 * To run a mutation, you first call `useSetCompanySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompanySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompanySettingsMutation, { data, loading, error }] = useSetCompanySettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCompanySettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCompanySettingsMutation, SetCompanySettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<SetCompanySettingsMutation, SetCompanySettingsMutationVariables>(SetCompanySettingsDocument, baseOptions);
      }
export type SetCompanySettingsMutationHookResult = ReturnType<typeof useSetCompanySettingsMutation>;
export type SetCompanySettingsMutationResult = ApolloReactCommon.MutationResult<SetCompanySettingsMutation>;
export type SetCompanySettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<SetCompanySettingsMutation, SetCompanySettingsMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    user {
      id
      firstName
      lastName
      email
      role
      locations
      linkedinUrl
      industries
      avatarUrl
      jobTitle
      phoneNumber
      linesOfBusiness
      aboutMe
      notificationSettings {
        mySubmissions {
          sentConfirmations
          received
          declined
          referred
          bound
          issued
        }
        retailerSubmissions {
          sentConfirmations
          received
          declined
          referred
          bound
          issued
        }
      }
      rinkAccount {
        id
        fullName
        firstName
        email
        lastName
        jobTitle
        avatarUrl
        companyName
      }
    }
    errors
  }
}
    `;
export type UpdateProfileMutationFn = ApolloReactCommon.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;
export type UpdateProfileProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>
    } & TChildProps;
export function withUpdateProfile<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
  UpdateProfileProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateProfileMutation, UpdateProfileMutationVariables, UpdateProfileProps<TChildProps, TDataName>>(UpdateProfileDocument, {
      alias: 'updateProfile',
      ...operationOptions
    });
};

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, baseOptions);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = ApolloReactCommon.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const AcceptConnectionDocument = gql`
    mutation acceptConnection($input: AcceptConnectionInput!) {
  acceptConnection(input: $input) {
    connection {
      id
      user1 {
        id
        ...NetworkContact
        networkConnectionInvitesSent {
          nodes {
            id
          }
        }
      }
      user2 {
        id
        ...NetworkContact
        networkContacts {
          nodes {
            ...NetworkContact
          }
        }
        networkConnectionInvitesReceived {
          nodes {
            id
            user1 {
              id
              ...NetworkContact
            }
          }
        }
      }
    }
    errors
  }
}
    ${NetworkContactFragmentDoc}`;
export type AcceptConnectionMutationFn = ApolloReactCommon.MutationFunction<AcceptConnectionMutation, AcceptConnectionMutationVariables>;
export type AcceptConnectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AcceptConnectionMutation, AcceptConnectionMutationVariables>
    } & TChildProps;
export function withAcceptConnection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AcceptConnectionMutation,
  AcceptConnectionMutationVariables,
  AcceptConnectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AcceptConnectionMutation, AcceptConnectionMutationVariables, AcceptConnectionProps<TChildProps, TDataName>>(AcceptConnectionDocument, {
      alias: 'acceptConnection',
      ...operationOptions
    });
};

/**
 * __useAcceptConnectionMutation__
 *
 * To run a mutation, you first call `useAcceptConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptConnectionMutation, { data, loading, error }] = useAcceptConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptConnectionMutation, AcceptConnectionMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptConnectionMutation, AcceptConnectionMutationVariables>(AcceptConnectionDocument, baseOptions);
      }
export type AcceptConnectionMutationHookResult = ReturnType<typeof useAcceptConnectionMutation>;
export type AcceptConnectionMutationResult = ApolloReactCommon.MutationResult<AcceptConnectionMutation>;
export type AcceptConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptConnectionMutation, AcceptConnectionMutationVariables>;
export const IgnoreConnectionDocument = gql`
    mutation ignoreConnection($input: IgnoreConnectionInput!) {
  ignoreConnection(input: $input) {
    connection {
      id
      user1 {
        id
        ...NetworkContact
        networkConnectionInvitesSent {
          nodes {
            id
          }
        }
      }
      user2 {
        id
        ...NetworkContact
        networkContacts {
          nodes {
            ...NetworkContact
          }
        }
        networkConnectionInvitesReceived {
          nodes {
            id
            user1 {
              id
              ...NetworkContact
            }
          }
        }
      }
    }
    errors
  }
}
    ${NetworkContactFragmentDoc}`;
export type IgnoreConnectionMutationFn = ApolloReactCommon.MutationFunction<IgnoreConnectionMutation, IgnoreConnectionMutationVariables>;
export type IgnoreConnectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<IgnoreConnectionMutation, IgnoreConnectionMutationVariables>
    } & TChildProps;
export function withIgnoreConnection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IgnoreConnectionMutation,
  IgnoreConnectionMutationVariables,
  IgnoreConnectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, IgnoreConnectionMutation, IgnoreConnectionMutationVariables, IgnoreConnectionProps<TChildProps, TDataName>>(IgnoreConnectionDocument, {
      alias: 'ignoreConnection',
      ...operationOptions
    });
};

/**
 * __useIgnoreConnectionMutation__
 *
 * To run a mutation, you first call `useIgnoreConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreConnectionMutation, { data, loading, error }] = useIgnoreConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIgnoreConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IgnoreConnectionMutation, IgnoreConnectionMutationVariables>) {
        return ApolloReactHooks.useMutation<IgnoreConnectionMutation, IgnoreConnectionMutationVariables>(IgnoreConnectionDocument, baseOptions);
      }
export type IgnoreConnectionMutationHookResult = ReturnType<typeof useIgnoreConnectionMutation>;
export type IgnoreConnectionMutationResult = ApolloReactCommon.MutationResult<IgnoreConnectionMutation>;
export type IgnoreConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<IgnoreConnectionMutation, IgnoreConnectionMutationVariables>;
export const InviteConnectionDocument = gql`
    mutation inviteConnection($input: InviteConnectionInput!) {
  inviteConnection(input: $input) {
    connection {
      id
      user1 {
        id
        networkContacts {
          nodes {
            ...NetworkContact
          }
        }
        networkConnectionInvitesReceived {
          nodes {
            id
            user1 {
              id
              ...NetworkContact
            }
          }
        }
        networkConnectionInvitesSent {
          nodes {
            id
            createdAt
            user2 {
              id
              ...NetworkContact
            }
          }
        }
      }
    }
    errors
  }
}
    ${NetworkContactFragmentDoc}`;
export type InviteConnectionMutationFn = ApolloReactCommon.MutationFunction<InviteConnectionMutation, InviteConnectionMutationVariables>;
export type InviteConnectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<InviteConnectionMutation, InviteConnectionMutationVariables>
    } & TChildProps;
export function withInviteConnection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InviteConnectionMutation,
  InviteConnectionMutationVariables,
  InviteConnectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, InviteConnectionMutation, InviteConnectionMutationVariables, InviteConnectionProps<TChildProps, TDataName>>(InviteConnectionDocument, {
      alias: 'inviteConnection',
      ...operationOptions
    });
};

/**
 * __useInviteConnectionMutation__
 *
 * To run a mutation, you first call `useInviteConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteConnectionMutation, { data, loading, error }] = useInviteConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteConnectionMutation, InviteConnectionMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteConnectionMutation, InviteConnectionMutationVariables>(InviteConnectionDocument, baseOptions);
      }
export type InviteConnectionMutationHookResult = ReturnType<typeof useInviteConnectionMutation>;
export type InviteConnectionMutationResult = ApolloReactCommon.MutationResult<InviteConnectionMutation>;
export type InviteConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteConnectionMutation, InviteConnectionMutationVariables>;
export const SignUpAndAcceptConnectionDocument = gql`
    mutation signUpAndAcceptConnection($input: SignUpAndAcceptConnectionInput!) {
  signUpAndAcceptConnection(input: $input) {
    user {
      id
      fullName
      networkContacts {
        nodes {
          id
        }
      }
      networkConnectionInvitesReceived {
        nodes {
          id
          user1 {
            id
          }
        }
      }
    }
    errors
  }
}
    `;
export type SignUpAndAcceptConnectionMutationFn = ApolloReactCommon.MutationFunction<SignUpAndAcceptConnectionMutation, SignUpAndAcceptConnectionMutationVariables>;
export type SignUpAndAcceptConnectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SignUpAndAcceptConnectionMutation, SignUpAndAcceptConnectionMutationVariables>
    } & TChildProps;
export function withSignUpAndAcceptConnection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SignUpAndAcceptConnectionMutation,
  SignUpAndAcceptConnectionMutationVariables,
  SignUpAndAcceptConnectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SignUpAndAcceptConnectionMutation, SignUpAndAcceptConnectionMutationVariables, SignUpAndAcceptConnectionProps<TChildProps, TDataName>>(SignUpAndAcceptConnectionDocument, {
      alias: 'signUpAndAcceptConnection',
      ...operationOptions
    });
};

/**
 * __useSignUpAndAcceptConnectionMutation__
 *
 * To run a mutation, you first call `useSignUpAndAcceptConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpAndAcceptConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpAndAcceptConnectionMutation, { data, loading, error }] = useSignUpAndAcceptConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpAndAcceptConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpAndAcceptConnectionMutation, SignUpAndAcceptConnectionMutationVariables>) {
        return ApolloReactHooks.useMutation<SignUpAndAcceptConnectionMutation, SignUpAndAcceptConnectionMutationVariables>(SignUpAndAcceptConnectionDocument, baseOptions);
      }
export type SignUpAndAcceptConnectionMutationHookResult = ReturnType<typeof useSignUpAndAcceptConnectionMutation>;
export type SignUpAndAcceptConnectionMutationResult = ApolloReactCommon.MutationResult<SignUpAndAcceptConnectionMutation>;
export type SignUpAndAcceptConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<SignUpAndAcceptConnectionMutation, SignUpAndAcceptConnectionMutationVariables>;
export const ToggleDeclineQuotedLayerDocument = gql`
    mutation toggleDeclineQuotedLayer($input: ToggleDeclineQuotedLayerInput!) {
  toggleDeclineQuotedLayer(input: $input) {
    quotedLayer {
      id
      ...QuotedLayerInfo
    }
    errors
  }
}
    ${QuotedLayerInfoFragmentDoc}`;
export type ToggleDeclineQuotedLayerMutationFn = ApolloReactCommon.MutationFunction<ToggleDeclineQuotedLayerMutation, ToggleDeclineQuotedLayerMutationVariables>;
export type ToggleDeclineQuotedLayerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ToggleDeclineQuotedLayerMutation, ToggleDeclineQuotedLayerMutationVariables>
    } & TChildProps;
export function withToggleDeclineQuotedLayer<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ToggleDeclineQuotedLayerMutation,
  ToggleDeclineQuotedLayerMutationVariables,
  ToggleDeclineQuotedLayerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ToggleDeclineQuotedLayerMutation, ToggleDeclineQuotedLayerMutationVariables, ToggleDeclineQuotedLayerProps<TChildProps, TDataName>>(ToggleDeclineQuotedLayerDocument, {
      alias: 'toggleDeclineQuotedLayer',
      ...operationOptions
    });
};

/**
 * __useToggleDeclineQuotedLayerMutation__
 *
 * To run a mutation, you first call `useToggleDeclineQuotedLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleDeclineQuotedLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleDeclineQuotedLayerMutation, { data, loading, error }] = useToggleDeclineQuotedLayerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleDeclineQuotedLayerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleDeclineQuotedLayerMutation, ToggleDeclineQuotedLayerMutationVariables>) {
        return ApolloReactHooks.useMutation<ToggleDeclineQuotedLayerMutation, ToggleDeclineQuotedLayerMutationVariables>(ToggleDeclineQuotedLayerDocument, baseOptions);
      }
export type ToggleDeclineQuotedLayerMutationHookResult = ReturnType<typeof useToggleDeclineQuotedLayerMutation>;
export type ToggleDeclineQuotedLayerMutationResult = ApolloReactCommon.MutationResult<ToggleDeclineQuotedLayerMutation>;
export type ToggleDeclineQuotedLayerMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleDeclineQuotedLayerMutation, ToggleDeclineQuotedLayerMutationVariables>;
export const TriggerSubmissionExportDocument = gql`
    mutation triggerSubmissionExport($input: TriggerSubmissionExportInput!) {
  triggerSubmissionExport(input: $input) {
    errors
  }
}
    `;
export type TriggerSubmissionExportMutationFn = ApolloReactCommon.MutationFunction<TriggerSubmissionExportMutation, TriggerSubmissionExportMutationVariables>;
export type TriggerSubmissionExportProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TriggerSubmissionExportMutation, TriggerSubmissionExportMutationVariables>
    } & TChildProps;
export function withTriggerSubmissionExport<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TriggerSubmissionExportMutation,
  TriggerSubmissionExportMutationVariables,
  TriggerSubmissionExportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TriggerSubmissionExportMutation, TriggerSubmissionExportMutationVariables, TriggerSubmissionExportProps<TChildProps, TDataName>>(TriggerSubmissionExportDocument, {
      alias: 'triggerSubmissionExport',
      ...operationOptions
    });
};

/**
 * __useTriggerSubmissionExportMutation__
 *
 * To run a mutation, you first call `useTriggerSubmissionExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerSubmissionExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerSubmissionExportMutation, { data, loading, error }] = useTriggerSubmissionExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTriggerSubmissionExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TriggerSubmissionExportMutation, TriggerSubmissionExportMutationVariables>) {
        return ApolloReactHooks.useMutation<TriggerSubmissionExportMutation, TriggerSubmissionExportMutationVariables>(TriggerSubmissionExportDocument, baseOptions);
      }
export type TriggerSubmissionExportMutationHookResult = ReturnType<typeof useTriggerSubmissionExportMutation>;
export type TriggerSubmissionExportMutationResult = ApolloReactCommon.MutationResult<TriggerSubmissionExportMutation>;
export type TriggerSubmissionExportMutationOptions = ApolloReactCommon.BaseMutationOptions<TriggerSubmissionExportMutation, TriggerSubmissionExportMutationVariables>;
export const UpdateBrokerCommentDocument = gql`
    mutation updateBrokerComment($input: UpdateBrokerCommentInput!) {
  updateBrokerComment(input: $input) {
    quote {
      id
      brokerComment
    }
  }
}
    `;
export type UpdateBrokerCommentMutationFn = ApolloReactCommon.MutationFunction<UpdateBrokerCommentMutation, UpdateBrokerCommentMutationVariables>;
export type UpdateBrokerCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateBrokerCommentMutation, UpdateBrokerCommentMutationVariables>
    } & TChildProps;
export function withUpdateBrokerComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBrokerCommentMutation,
  UpdateBrokerCommentMutationVariables,
  UpdateBrokerCommentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBrokerCommentMutation, UpdateBrokerCommentMutationVariables, UpdateBrokerCommentProps<TChildProps, TDataName>>(UpdateBrokerCommentDocument, {
      alias: 'updateBrokerComment',
      ...operationOptions
    });
};

/**
 * __useUpdateBrokerCommentMutation__
 *
 * To run a mutation, you first call `useUpdateBrokerCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrokerCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrokerCommentMutation, { data, loading, error }] = useUpdateBrokerCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrokerCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBrokerCommentMutation, UpdateBrokerCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBrokerCommentMutation, UpdateBrokerCommentMutationVariables>(UpdateBrokerCommentDocument, baseOptions);
      }
export type UpdateBrokerCommentMutationHookResult = ReturnType<typeof useUpdateBrokerCommentMutation>;
export type UpdateBrokerCommentMutationResult = ApolloReactCommon.MutationResult<UpdateBrokerCommentMutation>;
export type UpdateBrokerCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBrokerCommentMutation, UpdateBrokerCommentMutationVariables>;
export const UpdateLicenseInformationDocument = gql`
    mutation updateLicenseInformation($input: UpdateLicenseInformationInput!) {
  updateLicenseInformation(input: $input) {
    company {
      id
      name
      licenseInformation {
        surplus_lines_licenses {
          address {
            street_line
            state
            city
            zip_code
          }
          state
          expiration_date
          license_holder
          license_number
        }
      }
    }
    errors
  }
}
    `;
export type UpdateLicenseInformationMutationFn = ApolloReactCommon.MutationFunction<UpdateLicenseInformationMutation, UpdateLicenseInformationMutationVariables>;
export type UpdateLicenseInformationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateLicenseInformationMutation, UpdateLicenseInformationMutationVariables>
    } & TChildProps;
export function withUpdateLicenseInformation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateLicenseInformationMutation,
  UpdateLicenseInformationMutationVariables,
  UpdateLicenseInformationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateLicenseInformationMutation, UpdateLicenseInformationMutationVariables, UpdateLicenseInformationProps<TChildProps, TDataName>>(UpdateLicenseInformationDocument, {
      alias: 'updateLicenseInformation',
      ...operationOptions
    });
};

/**
 * __useUpdateLicenseInformationMutation__
 *
 * To run a mutation, you first call `useUpdateLicenseInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicenseInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicenseInformationMutation, { data, loading, error }] = useUpdateLicenseInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLicenseInformationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLicenseInformationMutation, UpdateLicenseInformationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLicenseInformationMutation, UpdateLicenseInformationMutationVariables>(UpdateLicenseInformationDocument, baseOptions);
      }
export type UpdateLicenseInformationMutationHookResult = ReturnType<typeof useUpdateLicenseInformationMutation>;
export type UpdateLicenseInformationMutationResult = ApolloReactCommon.MutationResult<UpdateLicenseInformationMutation>;
export type UpdateLicenseInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLicenseInformationMutation, UpdateLicenseInformationMutationVariables>;
export const CopyWorksheetDocument = gql`
    mutation copyWorksheet($input: CopyWorksheetInput!) {
  copyWorksheet(input: $input) {
    worksheet {
      ...SubmissionInfo
      owner {
        id
        ... on Cedent {
          worksheets {
            nodes {
              id
            }
          }
        }
        ... on Broker {
          worksheets {
            nodes {
              id
            }
          }
        }
      }
    }
    errors
  }
}
    ${SubmissionInfoFragmentDoc}`;
export type CopyWorksheetMutationFn = ApolloReactCommon.MutationFunction<CopyWorksheetMutation, CopyWorksheetMutationVariables>;
export type CopyWorksheetProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CopyWorksheetMutation, CopyWorksheetMutationVariables>
    } & TChildProps;
export function withCopyWorksheet<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CopyWorksheetMutation,
  CopyWorksheetMutationVariables,
  CopyWorksheetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CopyWorksheetMutation, CopyWorksheetMutationVariables, CopyWorksheetProps<TChildProps, TDataName>>(CopyWorksheetDocument, {
      alias: 'copyWorksheet',
      ...operationOptions
    });
};

/**
 * __useCopyWorksheetMutation__
 *
 * To run a mutation, you first call `useCopyWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyWorksheetMutation, { data, loading, error }] = useCopyWorksheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyWorksheetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyWorksheetMutation, CopyWorksheetMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyWorksheetMutation, CopyWorksheetMutationVariables>(CopyWorksheetDocument, baseOptions);
      }
export type CopyWorksheetMutationHookResult = ReturnType<typeof useCopyWorksheetMutation>;
export type CopyWorksheetMutationResult = ApolloReactCommon.MutationResult<CopyWorksheetMutation>;
export type CopyWorksheetMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyWorksheetMutation, CopyWorksheetMutationVariables>;
export const DeleteWorksheetDocument = gql`
    mutation deleteWorksheet($input: DeleteWorksheetInput!) {
  deleteWorksheet(input: $input) {
    errors
  }
}
    `;
export type DeleteWorksheetMutationFn = ApolloReactCommon.MutationFunction<DeleteWorksheetMutation, DeleteWorksheetMutationVariables>;
export type DeleteWorksheetProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteWorksheetMutation, DeleteWorksheetMutationVariables>
    } & TChildProps;
export function withDeleteWorksheet<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteWorksheetMutation,
  DeleteWorksheetMutationVariables,
  DeleteWorksheetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteWorksheetMutation, DeleteWorksheetMutationVariables, DeleteWorksheetProps<TChildProps, TDataName>>(DeleteWorksheetDocument, {
      alias: 'deleteWorksheet',
      ...operationOptions
    });
};

/**
 * __useDeleteWorksheetMutation__
 *
 * To run a mutation, you first call `useDeleteWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorksheetMutation, { data, loading, error }] = useDeleteWorksheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWorksheetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWorksheetMutation, DeleteWorksheetMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWorksheetMutation, DeleteWorksheetMutationVariables>(DeleteWorksheetDocument, baseOptions);
      }
export type DeleteWorksheetMutationHookResult = ReturnType<typeof useDeleteWorksheetMutation>;
export type DeleteWorksheetMutationResult = ApolloReactCommon.MutationResult<DeleteWorksheetMutation>;
export type DeleteWorksheetMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWorksheetMutation, DeleteWorksheetMutationVariables>;
export const EditSentWorksheetDocument = gql`
    mutation editSentWorksheet($input: EditSentWorksheetInput!) {
  editSentWorksheet(input: $input) {
    worksheet {
      ...SubmissionInfo
    }
    errors
  }
}
    ${SubmissionInfoFragmentDoc}`;
export type EditSentWorksheetMutationFn = ApolloReactCommon.MutationFunction<EditSentWorksheetMutation, EditSentWorksheetMutationVariables>;
export type EditSentWorksheetProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EditSentWorksheetMutation, EditSentWorksheetMutationVariables>
    } & TChildProps;
export function withEditSentWorksheet<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditSentWorksheetMutation,
  EditSentWorksheetMutationVariables,
  EditSentWorksheetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EditSentWorksheetMutation, EditSentWorksheetMutationVariables, EditSentWorksheetProps<TChildProps, TDataName>>(EditSentWorksheetDocument, {
      alias: 'editSentWorksheet',
      ...operationOptions
    });
};

/**
 * __useEditSentWorksheetMutation__
 *
 * To run a mutation, you first call `useEditSentWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSentWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSentWorksheetMutation, { data, loading, error }] = useEditSentWorksheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSentWorksheetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSentWorksheetMutation, EditSentWorksheetMutationVariables>) {
        return ApolloReactHooks.useMutation<EditSentWorksheetMutation, EditSentWorksheetMutationVariables>(EditSentWorksheetDocument, baseOptions);
      }
export type EditSentWorksheetMutationHookResult = ReturnType<typeof useEditSentWorksheetMutation>;
export type EditSentWorksheetMutationResult = ApolloReactCommon.MutationResult<EditSentWorksheetMutation>;
export type EditSentWorksheetMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSentWorksheetMutation, EditSentWorksheetMutationVariables>;
export const ExportWorksheetsDocument = gql`
    mutation exportWorksheets($input: ExportWorksheetsInput!) {
  exportWorksheet(input: $input) {
    clientMutationId
  }
}
    `;
export type ExportWorksheetsMutationFn = ApolloReactCommon.MutationFunction<ExportWorksheetsMutation, ExportWorksheetsMutationVariables>;
export type ExportWorksheetsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ExportWorksheetsMutation, ExportWorksheetsMutationVariables>
    } & TChildProps;
export function withExportWorksheets<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExportWorksheetsMutation,
  ExportWorksheetsMutationVariables,
  ExportWorksheetsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ExportWorksheetsMutation, ExportWorksheetsMutationVariables, ExportWorksheetsProps<TChildProps, TDataName>>(ExportWorksheetsDocument, {
      alias: 'exportWorksheets',
      ...operationOptions
    });
};

/**
 * __useExportWorksheetsMutation__
 *
 * To run a mutation, you first call `useExportWorksheetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportWorksheetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportWorksheetsMutation, { data, loading, error }] = useExportWorksheetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportWorksheetsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExportWorksheetsMutation, ExportWorksheetsMutationVariables>) {
        return ApolloReactHooks.useMutation<ExportWorksheetsMutation, ExportWorksheetsMutationVariables>(ExportWorksheetsDocument, baseOptions);
      }
export type ExportWorksheetsMutationHookResult = ReturnType<typeof useExportWorksheetsMutation>;
export type ExportWorksheetsMutationResult = ApolloReactCommon.MutationResult<ExportWorksheetsMutation>;
export type ExportWorksheetsMutationOptions = ApolloReactCommon.BaseMutationOptions<ExportWorksheetsMutation, ExportWorksheetsMutationVariables>;
export const RenewWorksheetDocument = gql`
    mutation renewWorksheet($input: RenewWorksheetInput!) {
  renewWorksheet(input: $input) {
    worksheet {
      ...SubmissionInfo
      owner {
        id
        ... on Cedent {
          worksheets {
            nodes {
              id
            }
          }
        }
        ... on Broker {
          worksheets {
            nodes {
              id
            }
          }
        }
      }
      previousPlacement {
        id
        renewal {
          id
        }
      }
      export {
        id
        url
        filename
        createdAt
      }
    }
    errors
  }
}
    ${SubmissionInfoFragmentDoc}`;
export type RenewWorksheetMutationFn = ApolloReactCommon.MutationFunction<RenewWorksheetMutation, RenewWorksheetMutationVariables>;
export type RenewWorksheetProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RenewWorksheetMutation, RenewWorksheetMutationVariables>
    } & TChildProps;
export function withRenewWorksheet<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RenewWorksheetMutation,
  RenewWorksheetMutationVariables,
  RenewWorksheetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RenewWorksheetMutation, RenewWorksheetMutationVariables, RenewWorksheetProps<TChildProps, TDataName>>(RenewWorksheetDocument, {
      alias: 'renewWorksheet',
      ...operationOptions
    });
};

/**
 * __useRenewWorksheetMutation__
 *
 * To run a mutation, you first call `useRenewWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewWorksheetMutation, { data, loading, error }] = useRenewWorksheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenewWorksheetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenewWorksheetMutation, RenewWorksheetMutationVariables>) {
        return ApolloReactHooks.useMutation<RenewWorksheetMutation, RenewWorksheetMutationVariables>(RenewWorksheetDocument, baseOptions);
      }
export type RenewWorksheetMutationHookResult = ReturnType<typeof useRenewWorksheetMutation>;
export type RenewWorksheetMutationResult = ApolloReactCommon.MutationResult<RenewWorksheetMutation>;
export type RenewWorksheetMutationOptions = ApolloReactCommon.BaseMutationOptions<RenewWorksheetMutation, RenewWorksheetMutationVariables>;
export const RequestApprovalDocument = gql`
    mutation requestApproval($input: RequestApprovalInput!) {
  requestApproval(input: $input) {
    worksheet {
      id
      status
    }
    errors
  }
}
    `;
export type RequestApprovalMutationFn = ApolloReactCommon.MutationFunction<RequestApprovalMutation, RequestApprovalMutationVariables>;
export type RequestApprovalProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RequestApprovalMutation, RequestApprovalMutationVariables>
    } & TChildProps;
export function withRequestApproval<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RequestApprovalMutation,
  RequestApprovalMutationVariables,
  RequestApprovalProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RequestApprovalMutation, RequestApprovalMutationVariables, RequestApprovalProps<TChildProps, TDataName>>(RequestApprovalDocument, {
      alias: 'requestApproval',
      ...operationOptions
    });
};

/**
 * __useRequestApprovalMutation__
 *
 * To run a mutation, you first call `useRequestApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestApprovalMutation, { data, loading, error }] = useRequestApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestApprovalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestApprovalMutation, RequestApprovalMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestApprovalMutation, RequestApprovalMutationVariables>(RequestApprovalDocument, baseOptions);
      }
export type RequestApprovalMutationHookResult = ReturnType<typeof useRequestApprovalMutation>;
export type RequestApprovalMutationResult = ApolloReactCommon.MutationResult<RequestApprovalMutation>;
export type RequestApprovalMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestApprovalMutation, RequestApprovalMutationVariables>;
export const UpdateAndSendWorksheetDocument = gql`
    mutation updateAndSendWorksheet($input: UpdateAndSendWorksheetInput!) {
  updateAndSendWorksheet(input: $input) {
    worksheet {
      ...SubmissionInfo
      export {
        id
        url
        filename
        createdAt
      }
    }
    errors
  }
}
    ${SubmissionInfoFragmentDoc}`;
export type UpdateAndSendWorksheetMutationFn = ApolloReactCommon.MutationFunction<UpdateAndSendWorksheetMutation, UpdateAndSendWorksheetMutationVariables>;
export type UpdateAndSendWorksheetProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateAndSendWorksheetMutation, UpdateAndSendWorksheetMutationVariables>
    } & TChildProps;
export function withUpdateAndSendWorksheet<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateAndSendWorksheetMutation,
  UpdateAndSendWorksheetMutationVariables,
  UpdateAndSendWorksheetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateAndSendWorksheetMutation, UpdateAndSendWorksheetMutationVariables, UpdateAndSendWorksheetProps<TChildProps, TDataName>>(UpdateAndSendWorksheetDocument, {
      alias: 'updateAndSendWorksheet',
      ...operationOptions
    });
};

/**
 * __useUpdateAndSendWorksheetMutation__
 *
 * To run a mutation, you first call `useUpdateAndSendWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAndSendWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAndSendWorksheetMutation, { data, loading, error }] = useUpdateAndSendWorksheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAndSendWorksheetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAndSendWorksheetMutation, UpdateAndSendWorksheetMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAndSendWorksheetMutation, UpdateAndSendWorksheetMutationVariables>(UpdateAndSendWorksheetDocument, baseOptions);
      }
export type UpdateAndSendWorksheetMutationHookResult = ReturnType<typeof useUpdateAndSendWorksheetMutation>;
export type UpdateAndSendWorksheetMutationResult = ApolloReactCommon.MutationResult<UpdateAndSendWorksheetMutation>;
export type UpdateAndSendWorksheetMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAndSendWorksheetMutation, UpdateAndSendWorksheetMutationVariables>;
export const UpdateWorksheetDocument = gql`
    mutation updateWorksheet($input: UpdateWorksheetInput!) {
  updateWorksheet(input: $input) {
    worksheet {
      ...SubmissionInfo
      export {
        id
        url
        filename
        createdAt
      }
    }
    errors
  }
}
    ${SubmissionInfoFragmentDoc}`;
export type UpdateWorksheetMutationFn = ApolloReactCommon.MutationFunction<UpdateWorksheetMutation, UpdateWorksheetMutationVariables>;
export type UpdateWorksheetProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateWorksheetMutation, UpdateWorksheetMutationVariables>
    } & TChildProps;
export function withUpdateWorksheet<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateWorksheetMutation,
  UpdateWorksheetMutationVariables,
  UpdateWorksheetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateWorksheetMutation, UpdateWorksheetMutationVariables, UpdateWorksheetProps<TChildProps, TDataName>>(UpdateWorksheetDocument, {
      alias: 'updateWorksheet',
      ...operationOptions
    });
};

/**
 * __useUpdateWorksheetMutation__
 *
 * To run a mutation, you first call `useUpdateWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorksheetMutation, { data, loading, error }] = useUpdateWorksheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorksheetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorksheetMutation, UpdateWorksheetMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorksheetMutation, UpdateWorksheetMutationVariables>(UpdateWorksheetDocument, baseOptions);
      }
export type UpdateWorksheetMutationHookResult = ReturnType<typeof useUpdateWorksheetMutation>;
export type UpdateWorksheetMutationResult = ApolloReactCommon.MutationResult<UpdateWorksheetMutation>;
export type UpdateWorksheetMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorksheetMutation, UpdateWorksheetMutationVariables>;
export const AnalyticsDocument = gql`
    query Analytics {
  viewer {
    user {
      id
      ... on Broker {
        analytics {
          averageReplyRate
          averageResponseTime
          averagePlacementTime
          totalCommission
        }
      }
    }
  }
}
    `;
export type AnalyticsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AnalyticsQuery, AnalyticsQueryVariables>
    } & TChildProps;
export function withAnalytics<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AnalyticsQuery,
  AnalyticsQueryVariables,
  AnalyticsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AnalyticsQuery, AnalyticsQueryVariables, AnalyticsProps<TChildProps, TDataName>>(AnalyticsDocument, {
      alias: 'analytics',
      ...operationOptions
    });
};

/**
 * __useAnalyticsQuery__
 *
 * To run a query within a React component, call `useAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnalyticsQuery, AnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument, baseOptions);
      }
export function useAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnalyticsQuery, AnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument, baseOptions);
        }
export type AnalyticsQueryHookResult = ReturnType<typeof useAnalyticsQuery>;
export type AnalyticsLazyQueryHookResult = ReturnType<typeof useAnalyticsLazyQuery>;
export type AnalyticsQueryResult = ApolloReactCommon.QueryResult<AnalyticsQuery, AnalyticsQueryVariables>;
export const AuthorizedMarketDocument = gql`
    query AuthorizedMarket($marketId: ID!) {
  viewer {
    user {
      id
      company {
        id
        authorizedMarket(marketId: $marketId) {
          id
          specialInstructions
          propertyApprovedLimit
          casualtyApprovedLimit
          reinsuranceCompany {
            id
            name
          }
          disabled
          contacts {
            nodes {
              id
              description
              reinsurer {
                id
                firstName
                lastName
                fullName
                email
                generalInbox
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type AuthorizedMarketProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AuthorizedMarketQuery, AuthorizedMarketQueryVariables>
    } & TChildProps;
export function withAuthorizedMarket<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AuthorizedMarketQuery,
  AuthorizedMarketQueryVariables,
  AuthorizedMarketProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AuthorizedMarketQuery, AuthorizedMarketQueryVariables, AuthorizedMarketProps<TChildProps, TDataName>>(AuthorizedMarketDocument, {
      alias: 'authorizedMarket',
      ...operationOptions
    });
};

/**
 * __useAuthorizedMarketQuery__
 *
 * To run a query within a React component, call `useAuthorizedMarketQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizedMarketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizedMarketQuery({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useAuthorizedMarketQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AuthorizedMarketQuery, AuthorizedMarketQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthorizedMarketQuery, AuthorizedMarketQueryVariables>(AuthorizedMarketDocument, baseOptions);
      }
export function useAuthorizedMarketLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthorizedMarketQuery, AuthorizedMarketQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthorizedMarketQuery, AuthorizedMarketQueryVariables>(AuthorizedMarketDocument, baseOptions);
        }
export type AuthorizedMarketQueryHookResult = ReturnType<typeof useAuthorizedMarketQuery>;
export type AuthorizedMarketLazyQueryHookResult = ReturnType<typeof useAuthorizedMarketLazyQuery>;
export type AuthorizedMarketQueryResult = ApolloReactCommon.QueryResult<AuthorizedMarketQuery, AuthorizedMarketQueryVariables>;
export const AuthorizedMarketsDocument = gql`
    query AuthorizedMarkets {
  viewer {
    user {
      id
      company {
        id
        authorizedMarkets {
          nodes {
            id
            disabled
            propertyApprovedLimit
            casualtyApprovedLimit
            contacts {
              nodes {
                id
                description
                reinsurer {
                  id
                  email
                  fullName
                  companyName
                  avatarUrl
                  pendingInvitation
                  newMarket
                  generalInbox
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type AuthorizedMarketsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AuthorizedMarketsQuery, AuthorizedMarketsQueryVariables>
    } & TChildProps;
export function withAuthorizedMarkets<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AuthorizedMarketsQuery,
  AuthorizedMarketsQueryVariables,
  AuthorizedMarketsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AuthorizedMarketsQuery, AuthorizedMarketsQueryVariables, AuthorizedMarketsProps<TChildProps, TDataName>>(AuthorizedMarketsDocument, {
      alias: 'authorizedMarkets',
      ...operationOptions
    });
};

/**
 * __useAuthorizedMarketsQuery__
 *
 * To run a query within a React component, call `useAuthorizedMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizedMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizedMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthorizedMarketsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthorizedMarketsQuery, AuthorizedMarketsQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthorizedMarketsQuery, AuthorizedMarketsQueryVariables>(AuthorizedMarketsDocument, baseOptions);
      }
export function useAuthorizedMarketsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthorizedMarketsQuery, AuthorizedMarketsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthorizedMarketsQuery, AuthorizedMarketsQueryVariables>(AuthorizedMarketsDocument, baseOptions);
        }
export type AuthorizedMarketsQueryHookResult = ReturnType<typeof useAuthorizedMarketsQuery>;
export type AuthorizedMarketsLazyQueryHookResult = ReturnType<typeof useAuthorizedMarketsLazyQuery>;
export type AuthorizedMarketsQueryResult = ApolloReactCommon.QueryResult<AuthorizedMarketsQuery, AuthorizedMarketsQueryVariables>;
export const AvailableTemplatesDocument = gql`
    query AvailableTemplates {
  viewer {
    user {
      id
      company {
        id
        templates {
          nodes {
            id
            name
            identifier
            processor
          }
        }
      }
    }
  }
}
    `;
export type AvailableTemplatesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AvailableTemplatesQuery, AvailableTemplatesQueryVariables>
    } & TChildProps;
export function withAvailableTemplates<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AvailableTemplatesQuery,
  AvailableTemplatesQueryVariables,
  AvailableTemplatesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AvailableTemplatesQuery, AvailableTemplatesQueryVariables, AvailableTemplatesProps<TChildProps, TDataName>>(AvailableTemplatesDocument, {
      alias: 'availableTemplates',
      ...operationOptions
    });
};

/**
 * __useAvailableTemplatesQuery__
 *
 * To run a query within a React component, call `useAvailableTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableTemplatesQuery, AvailableTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableTemplatesQuery, AvailableTemplatesQueryVariables>(AvailableTemplatesDocument, baseOptions);
      }
export function useAvailableTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableTemplatesQuery, AvailableTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableTemplatesQuery, AvailableTemplatesQueryVariables>(AvailableTemplatesDocument, baseOptions);
        }
export type AvailableTemplatesQueryHookResult = ReturnType<typeof useAvailableTemplatesQuery>;
export type AvailableTemplatesLazyQueryHookResult = ReturnType<typeof useAvailableTemplatesLazyQuery>;
export type AvailableTemplatesQueryResult = ApolloReactCommon.QueryResult<AvailableTemplatesQuery, AvailableTemplatesQueryVariables>;
export const BroadcastsDocument = gql`
    query Broadcasts($worksheetId: ID!) {
  viewer {
    user {
      id
      ... on Broker {
        id
        worksheet(worksheetId: $worksheetId) {
          id
          demo
          broadcasts {
            nodes {
              id
              ...Message
            }
          }
        }
      }
      ... on Cedent {
        id
        worksheet(worksheetId: $worksheetId) {
          id
          demo
          broadcasts {
            nodes {
              id
              ...Message
            }
          }
        }
      }
    }
  }
}
    ${MessageFragmentDoc}`;
export type BroadcastsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BroadcastsQuery, BroadcastsQueryVariables>
    } & TChildProps;
export function withBroadcasts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BroadcastsQuery,
  BroadcastsQueryVariables,
  BroadcastsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BroadcastsQuery, BroadcastsQueryVariables, BroadcastsProps<TChildProps, TDataName>>(BroadcastsDocument, {
      alias: 'broadcasts',
      ...operationOptions
    });
};

/**
 * __useBroadcastsQuery__
 *
 * To run a query within a React component, call `useBroadcastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBroadcastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBroadcastsQuery({
 *   variables: {
 *      worksheetId: // value for 'worksheetId'
 *   },
 * });
 */
export function useBroadcastsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<BroadcastsQuery, BroadcastsQueryVariables>) {
        return ApolloReactHooks.useQuery<BroadcastsQuery, BroadcastsQueryVariables>(BroadcastsDocument, baseOptions);
      }
export function useBroadcastsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BroadcastsQuery, BroadcastsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BroadcastsQuery, BroadcastsQueryVariables>(BroadcastsDocument, baseOptions);
        }
export type BroadcastsQueryHookResult = ReturnType<typeof useBroadcastsQuery>;
export type BroadcastsLazyQueryHookResult = ReturnType<typeof useBroadcastsLazyQuery>;
export type BroadcastsQueryResult = ApolloReactCommon.QueryResult<BroadcastsQuery, BroadcastsQueryVariables>;
export const BrokerDashboardDealsDocument = gql`
    query BrokerDashboardDeals($filter: String, $search: String, $order: String, $visibility: String, $cursor: String, $first: Int) {
  viewer {
    user {
      id
      ... on Broker {
        dashboard(filter: $filter, search: $search, visibility: $visibility, order: $order, after: $cursor, first: $first) {
          nodes {
            id
            ...DashboardInfo
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    }
  }
}
    ${DashboardInfoFragmentDoc}`;
export type BrokerDashboardDealsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BrokerDashboardDealsQuery, BrokerDashboardDealsQueryVariables>
    } & TChildProps;
export function withBrokerDashboardDeals<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BrokerDashboardDealsQuery,
  BrokerDashboardDealsQueryVariables,
  BrokerDashboardDealsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BrokerDashboardDealsQuery, BrokerDashboardDealsQueryVariables, BrokerDashboardDealsProps<TChildProps, TDataName>>(BrokerDashboardDealsDocument, {
      alias: 'brokerDashboardDeals',
      ...operationOptions
    });
};

/**
 * __useBrokerDashboardDealsQuery__
 *
 * To run a query within a React component, call `useBrokerDashboardDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokerDashboardDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokerDashboardDealsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *      order: // value for 'order'
 *      visibility: // value for 'visibility'
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useBrokerDashboardDealsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrokerDashboardDealsQuery, BrokerDashboardDealsQueryVariables>) {
        return ApolloReactHooks.useQuery<BrokerDashboardDealsQuery, BrokerDashboardDealsQueryVariables>(BrokerDashboardDealsDocument, baseOptions);
      }
export function useBrokerDashboardDealsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrokerDashboardDealsQuery, BrokerDashboardDealsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrokerDashboardDealsQuery, BrokerDashboardDealsQueryVariables>(BrokerDashboardDealsDocument, baseOptions);
        }
export type BrokerDashboardDealsQueryHookResult = ReturnType<typeof useBrokerDashboardDealsQuery>;
export type BrokerDashboardDealsLazyQueryHookResult = ReturnType<typeof useBrokerDashboardDealsLazyQuery>;
export type BrokerDashboardDealsQueryResult = ApolloReactCommon.QueryResult<BrokerDashboardDealsQuery, BrokerDashboardDealsQueryVariables>;
export const CarrierDashboardEntriesDocument = gql`
    query CarrierDashboardEntries($search: String, $order: String, $quoteStatus: String) {
  viewer {
    user {
      id
      ... on Reinsurer {
        dashboardEntries(search: $search, order: $order, quoteStatus: $quoteStatus) {
          nodes {
            id
            typename
            lineOfBusiness
            clientName
            riskName
            companyName
            outdatedAt
            isBroker
            keyPerils
            effectiveDate
            ownerFirstName
            ownerId
            ownerLastName
            policyNumber
            quotation {
              id
            }
            quoteStatus
            requestedAt
            revision {
              id
              quotedLayers {
                id
                ...QuotedLayerInfo
              }
            }
          }
        }
      }
    }
  }
}
    ${QuotedLayerInfoFragmentDoc}`;
export type CarrierDashboardEntriesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CarrierDashboardEntriesQuery, CarrierDashboardEntriesQueryVariables>
    } & TChildProps;
export function withCarrierDashboardEntries<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CarrierDashboardEntriesQuery,
  CarrierDashboardEntriesQueryVariables,
  CarrierDashboardEntriesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CarrierDashboardEntriesQuery, CarrierDashboardEntriesQueryVariables, CarrierDashboardEntriesProps<TChildProps, TDataName>>(CarrierDashboardEntriesDocument, {
      alias: 'carrierDashboardEntries',
      ...operationOptions
    });
};

/**
 * __useCarrierDashboardEntriesQuery__
 *
 * To run a query within a React component, call `useCarrierDashboardEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarrierDashboardEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarrierDashboardEntriesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      order: // value for 'order'
 *      quoteStatus: // value for 'quoteStatus'
 *   },
 * });
 */
export function useCarrierDashboardEntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CarrierDashboardEntriesQuery, CarrierDashboardEntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<CarrierDashboardEntriesQuery, CarrierDashboardEntriesQueryVariables>(CarrierDashboardEntriesDocument, baseOptions);
      }
export function useCarrierDashboardEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CarrierDashboardEntriesQuery, CarrierDashboardEntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CarrierDashboardEntriesQuery, CarrierDashboardEntriesQueryVariables>(CarrierDashboardEntriesDocument, baseOptions);
        }
export type CarrierDashboardEntriesQueryHookResult = ReturnType<typeof useCarrierDashboardEntriesQuery>;
export type CarrierDashboardEntriesLazyQueryHookResult = ReturnType<typeof useCarrierDashboardEntriesLazyQuery>;
export type CarrierDashboardEntriesQueryResult = ApolloReactCommon.QueryResult<CarrierDashboardEntriesQuery, CarrierDashboardEntriesQueryVariables>;
export const CedentDashboardEntriesDocument = gql`
    query CedentDashboardEntries($search: String, $order: String, $quoteStatus: String, $cursor: String, $first: Int) {
  viewer {
    user {
      id
      ... on Cedent {
        onboarding
        dashboardEntries(search: $search, order: $order, quoteStatus: $quoteStatus, after: $cursor, first: $first) {
          nodes {
            id
            brokered
            demo
            declined
            lineOfBusiness
            createdAt
            effectiveDate
            keyPerils
            policyType
            worksheetLineOfBusiness
            marketsInvited
            quoted
            bound
            issued
            kind
            name
            ownerId
            ownerName
            ownerCompanyId
            status
            totalBound
            responded
            totalRequested
            attachmentPoint
            perOccurrenceLimit
            policyLimit
            boundRatio
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    }
  }
}
    `;
export type CedentDashboardEntriesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CedentDashboardEntriesQuery, CedentDashboardEntriesQueryVariables>
    } & TChildProps;
export function withCedentDashboardEntries<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CedentDashboardEntriesQuery,
  CedentDashboardEntriesQueryVariables,
  CedentDashboardEntriesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CedentDashboardEntriesQuery, CedentDashboardEntriesQueryVariables, CedentDashboardEntriesProps<TChildProps, TDataName>>(CedentDashboardEntriesDocument, {
      alias: 'cedentDashboardEntries',
      ...operationOptions
    });
};

/**
 * __useCedentDashboardEntriesQuery__
 *
 * To run a query within a React component, call `useCedentDashboardEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCedentDashboardEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCedentDashboardEntriesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      order: // value for 'order'
 *      quoteStatus: // value for 'quoteStatus'
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCedentDashboardEntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CedentDashboardEntriesQuery, CedentDashboardEntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<CedentDashboardEntriesQuery, CedentDashboardEntriesQueryVariables>(CedentDashboardEntriesDocument, baseOptions);
      }
export function useCedentDashboardEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CedentDashboardEntriesQuery, CedentDashboardEntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CedentDashboardEntriesQuery, CedentDashboardEntriesQueryVariables>(CedentDashboardEntriesDocument, baseOptions);
        }
export type CedentDashboardEntriesQueryHookResult = ReturnType<typeof useCedentDashboardEntriesQuery>;
export type CedentDashboardEntriesLazyQueryHookResult = ReturnType<typeof useCedentDashboardEntriesLazyQuery>;
export type CedentDashboardEntriesQueryResult = ApolloReactCommon.QueryResult<CedentDashboardEntriesQuery, CedentDashboardEntriesQueryVariables>;
export const CedentQuoteMessagesQueryDocument = gql`
    query CedentQuoteMessagesQuery($worksheetId: ID!, $quoteId: ID!) {
  viewer {
    user {
      id
      ... on Cedent {
        worksheet(worksheetId: $worksheetId) {
          id
          broadcasts {
            nodes {
              id
              ...Message
            }
          }
          quote(quoteId: $quoteId) {
            id
            ...QuoteMessagesSection
          }
        }
      }
      ... on Broker {
        worksheet(worksheetId: $worksheetId) {
          id
          broadcasts {
            nodes {
              id
              ...Message
            }
          }
          quote(quoteId: $quoteId) {
            id
            ...QuoteMessagesSection
          }
        }
      }
    }
  }
}
    ${MessageFragmentDoc}
${QuoteMessagesSectionFragmentDoc}`;
export type CedentQuoteMessagesQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CedentQuoteMessagesQueryQuery, CedentQuoteMessagesQueryQueryVariables>
    } & TChildProps;
export function withCedentQuoteMessagesQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CedentQuoteMessagesQueryQuery,
  CedentQuoteMessagesQueryQueryVariables,
  CedentQuoteMessagesQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CedentQuoteMessagesQueryQuery, CedentQuoteMessagesQueryQueryVariables, CedentQuoteMessagesQueryProps<TChildProps, TDataName>>(CedentQuoteMessagesQueryDocument, {
      alias: 'cedentQuoteMessagesQuery',
      ...operationOptions
    });
};

/**
 * __useCedentQuoteMessagesQueryQuery__
 *
 * To run a query within a React component, call `useCedentQuoteMessagesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCedentQuoteMessagesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCedentQuoteMessagesQueryQuery({
 *   variables: {
 *      worksheetId: // value for 'worksheetId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useCedentQuoteMessagesQueryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CedentQuoteMessagesQueryQuery, CedentQuoteMessagesQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<CedentQuoteMessagesQueryQuery, CedentQuoteMessagesQueryQueryVariables>(CedentQuoteMessagesQueryDocument, baseOptions);
      }
export function useCedentQuoteMessagesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CedentQuoteMessagesQueryQuery, CedentQuoteMessagesQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CedentQuoteMessagesQueryQuery, CedentQuoteMessagesQueryQueryVariables>(CedentQuoteMessagesQueryDocument, baseOptions);
        }
export type CedentQuoteMessagesQueryQueryHookResult = ReturnType<typeof useCedentQuoteMessagesQueryQuery>;
export type CedentQuoteMessagesQueryLazyQueryHookResult = ReturnType<typeof useCedentQuoteMessagesQueryLazyQuery>;
export type CedentQuoteMessagesQueryQueryResult = ApolloReactCommon.QueryResult<CedentQuoteMessagesQueryQuery, CedentQuoteMessagesQueryQueryVariables>;
export const CompanyInvitationDocument = gql`
    query companyInvitation($signupToken: String!, $hostId: ID) {
  companyInvitation(signupToken: $signupToken, hostId: $hostId) {
    id
    logoUrl
    brandColor
    emailDomains
    companyName
    host
  }
}
    `;
export type CompanyInvitationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CompanyInvitationQuery, CompanyInvitationQueryVariables>
    } & TChildProps;
export function withCompanyInvitation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CompanyInvitationQuery,
  CompanyInvitationQueryVariables,
  CompanyInvitationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CompanyInvitationQuery, CompanyInvitationQueryVariables, CompanyInvitationProps<TChildProps, TDataName>>(CompanyInvitationDocument, {
      alias: 'companyInvitation',
      ...operationOptions
    });
};

/**
 * __useCompanyInvitationQuery__
 *
 * To run a query within a React component, call `useCompanyInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyInvitationQuery({
 *   variables: {
 *      signupToken: // value for 'signupToken'
 *      hostId: // value for 'hostId'
 *   },
 * });
 */
export function useCompanyInvitationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CompanyInvitationQuery, CompanyInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyInvitationQuery, CompanyInvitationQueryVariables>(CompanyInvitationDocument, baseOptions);
      }
export function useCompanyInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyInvitationQuery, CompanyInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyInvitationQuery, CompanyInvitationQueryVariables>(CompanyInvitationDocument, baseOptions);
        }
export type CompanyInvitationQueryHookResult = ReturnType<typeof useCompanyInvitationQuery>;
export type CompanyInvitationLazyQueryHookResult = ReturnType<typeof useCompanyInvitationLazyQuery>;
export type CompanyInvitationQueryResult = ApolloReactCommon.QueryResult<CompanyInvitationQuery, CompanyInvitationQueryVariables>;
export const GetCyberQuoteDocument = gql`
    query getCyberQuote($id: ID!) {
  cyber {
    quote(id: $id) {
      id
      limit
      status
      deductible
      premium
      indication
      fee
      surplusLinesTax
      totalCost
      admitted
      declinationReason
      signingUrl
      policyNumber
      comments
      externalCarrier {
        id
        name
      }
      coverages {
        name
        type
        limit
        deductible
        periodOfIndemnity
        waitingPeriod
      }
      endorsements {
        name
        limit
        coinsurance
      }
    }
  }
}
    `;
export type GetCyberQuoteProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCyberQuoteQuery, GetCyberQuoteQueryVariables>
    } & TChildProps;
export function withGetCyberQuote<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCyberQuoteQuery,
  GetCyberQuoteQueryVariables,
  GetCyberQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCyberQuoteQuery, GetCyberQuoteQueryVariables, GetCyberQuoteProps<TChildProps, TDataName>>(GetCyberQuoteDocument, {
      alias: 'getCyberQuote',
      ...operationOptions
    });
};

/**
 * __useGetCyberQuoteQuery__
 *
 * To run a query within a React component, call `useGetCyberQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCyberQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCyberQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCyberQuoteQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCyberQuoteQuery, GetCyberQuoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCyberQuoteQuery, GetCyberQuoteQueryVariables>(GetCyberQuoteDocument, baseOptions);
      }
export function useGetCyberQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCyberQuoteQuery, GetCyberQuoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCyberQuoteQuery, GetCyberQuoteQueryVariables>(GetCyberQuoteDocument, baseOptions);
        }
export type GetCyberQuoteQueryHookResult = ReturnType<typeof useGetCyberQuoteQuery>;
export type GetCyberQuoteLazyQueryHookResult = ReturnType<typeof useGetCyberQuoteLazyQuery>;
export type GetCyberQuoteQueryResult = ApolloReactCommon.QueryResult<GetCyberQuoteQuery, GetCyberQuoteQueryVariables>;
export const GetCyberQuoteEditDocument = gql`
    query getCyberQuoteEdit($id: ID!) {
  cyber {
    quote(id: $id) {
      id
      limit
      status
      deductible
      premium
      fee
      admitted
      comments
      externalCarrier {
        id
        name
      }
      coverages {
        name
        type
        limit
        deductible
      }
      endorsements {
        name
        limit
        coinsurance
      }
      documents {
        id
        ...DocumentForForm
      }
      submission {
        id
        ...CyberSubmissionInfo
      }
    }
  }
}
    ${DocumentForFormFragmentDoc}
${CyberSubmissionInfoFragmentDoc}`;
export type GetCyberQuoteEditProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCyberQuoteEditQuery, GetCyberQuoteEditQueryVariables>
    } & TChildProps;
export function withGetCyberQuoteEdit<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCyberQuoteEditQuery,
  GetCyberQuoteEditQueryVariables,
  GetCyberQuoteEditProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCyberQuoteEditQuery, GetCyberQuoteEditQueryVariables, GetCyberQuoteEditProps<TChildProps, TDataName>>(GetCyberQuoteEditDocument, {
      alias: 'getCyberQuoteEdit',
      ...operationOptions
    });
};

/**
 * __useGetCyberQuoteEditQuery__
 *
 * To run a query within a React component, call `useGetCyberQuoteEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCyberQuoteEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCyberQuoteEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCyberQuoteEditQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCyberQuoteEditQuery, GetCyberQuoteEditQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCyberQuoteEditQuery, GetCyberQuoteEditQueryVariables>(GetCyberQuoteEditDocument, baseOptions);
      }
export function useGetCyberQuoteEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCyberQuoteEditQuery, GetCyberQuoteEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCyberQuoteEditQuery, GetCyberQuoteEditQueryVariables>(GetCyberQuoteEditDocument, baseOptions);
        }
export type GetCyberQuoteEditQueryHookResult = ReturnType<typeof useGetCyberQuoteEditQuery>;
export type GetCyberQuoteEditLazyQueryHookResult = ReturnType<typeof useGetCyberQuoteEditLazyQuery>;
export type GetCyberQuoteEditQueryResult = ApolloReactCommon.QueryResult<GetCyberQuoteEditQuery, GetCyberQuoteEditQueryVariables>;
export const PanelSchemaDocument = gql`
    query panelSchema($panelId: ID!, $panelistIds: [ID!]!) {
  cyber {
    panel(panelId: $panelId) {
      id
      country
      schemas {
        new(panelistIds: $panelistIds)
      }
    }
  }
}
    `;
export type PanelSchemaProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PanelSchemaQuery, PanelSchemaQueryVariables>
    } & TChildProps;
export function withPanelSchema<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelSchemaQuery,
  PanelSchemaQueryVariables,
  PanelSchemaProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PanelSchemaQuery, PanelSchemaQueryVariables, PanelSchemaProps<TChildProps, TDataName>>(PanelSchemaDocument, {
      alias: 'panelSchema',
      ...operationOptions
    });
};

/**
 * __usePanelSchemaQuery__
 *
 * To run a query within a React component, call `usePanelSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePanelSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePanelSchemaQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      panelistIds: // value for 'panelistIds'
 *   },
 * });
 */
export function usePanelSchemaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PanelSchemaQuery, PanelSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<PanelSchemaQuery, PanelSchemaQueryVariables>(PanelSchemaDocument, baseOptions);
      }
export function usePanelSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PanelSchemaQuery, PanelSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PanelSchemaQuery, PanelSchemaQueryVariables>(PanelSchemaDocument, baseOptions);
        }
export type PanelSchemaQueryHookResult = ReturnType<typeof usePanelSchemaQuery>;
export type PanelSchemaLazyQueryHookResult = ReturnType<typeof usePanelSchemaLazyQuery>;
export type PanelSchemaQueryResult = ApolloReactCommon.QueryResult<PanelSchemaQuery, PanelSchemaQueryVariables>;
export const CyberQuotationDocument = gql`
    query CyberQuotation($participationId: ID!) {
  viewer {
    user {
      id
      ... on Reinsurer {
        participation(participationId: $participationId) {
          id
          documents {
            id
            ...Document
          }
          owner {
            id
            fullName
          }
          user {
            id
            fullName
          }
          discussion {
            id
            ...Messages
          }
          submission {
            id
            ...CyberSubmissionInfo
          }
          quotations {
            nodes {
              id
              ...CyberQuotationInfo
            }
          }
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}
${MessagesFragmentDoc}
${CyberSubmissionInfoFragmentDoc}
${CyberQuotationInfoFragmentDoc}`;
export type CyberQuotationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CyberQuotationQuery, CyberQuotationQueryVariables>
    } & TChildProps;
export function withCyberQuotation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberQuotationQuery,
  CyberQuotationQueryVariables,
  CyberQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CyberQuotationQuery, CyberQuotationQueryVariables, CyberQuotationProps<TChildProps, TDataName>>(CyberQuotationDocument, {
      alias: 'cyberQuotation',
      ...operationOptions
    });
};

/**
 * __useCyberQuotationQuery__
 *
 * To run a query within a React component, call `useCyberQuotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCyberQuotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCyberQuotationQuery({
 *   variables: {
 *      participationId: // value for 'participationId'
 *   },
 * });
 */
export function useCyberQuotationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CyberQuotationQuery, CyberQuotationQueryVariables>) {
        return ApolloReactHooks.useQuery<CyberQuotationQuery, CyberQuotationQueryVariables>(CyberQuotationDocument, baseOptions);
      }
export function useCyberQuotationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CyberQuotationQuery, CyberQuotationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CyberQuotationQuery, CyberQuotationQueryVariables>(CyberQuotationDocument, baseOptions);
        }
export type CyberQuotationQueryHookResult = ReturnType<typeof useCyberQuotationQuery>;
export type CyberQuotationLazyQueryHookResult = ReturnType<typeof useCyberQuotationLazyQuery>;
export type CyberQuotationQueryResult = ApolloReactCommon.QueryResult<CyberQuotationQuery, CyberQuotationQueryVariables>;
export const RetailBrokersDocument = gql`
    query retailBrokers {
  viewer {
    user {
      id
      company {
        id
        signupToken
        retailBrokerages {
          nodes {
            id
            name
            disabled
            agencyCode
            signupToken
            users {
              nodes {
                id
                jobTitle
                fullName
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type RetailBrokersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RetailBrokersQuery, RetailBrokersQueryVariables>
    } & TChildProps;
export function withRetailBrokers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RetailBrokersQuery,
  RetailBrokersQueryVariables,
  RetailBrokersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RetailBrokersQuery, RetailBrokersQueryVariables, RetailBrokersProps<TChildProps, TDataName>>(RetailBrokersDocument, {
      alias: 'retailBrokers',
      ...operationOptions
    });
};

/**
 * __useRetailBrokersQuery__
 *
 * To run a query within a React component, call `useRetailBrokersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailBrokersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailBrokersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRetailBrokersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RetailBrokersQuery, RetailBrokersQueryVariables>) {
        return ApolloReactHooks.useQuery<RetailBrokersQuery, RetailBrokersQueryVariables>(RetailBrokersDocument, baseOptions);
      }
export function useRetailBrokersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RetailBrokersQuery, RetailBrokersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RetailBrokersQuery, RetailBrokersQueryVariables>(RetailBrokersDocument, baseOptions);
        }
export type RetailBrokersQueryHookResult = ReturnType<typeof useRetailBrokersQuery>;
export type RetailBrokersLazyQueryHookResult = ReturnType<typeof useRetailBrokersLazyQuery>;
export type RetailBrokersQueryResult = ApolloReactCommon.QueryResult<RetailBrokersQuery, RetailBrokersQueryVariables>;
export const CyberSubmissionFileCabinetDocument = gql`
    query CyberSubmissionFileCabinet($submissionId: ID!) {
  cyber {
    submission(submissionId: $submissionId) {
      id
      ...CyberSubmissionFileCabinet
    }
  }
}
    ${CyberSubmissionFileCabinetFragmentDoc}`;
export type CyberSubmissionFileCabinetProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CyberSubmissionFileCabinetQuery, CyberSubmissionFileCabinetQueryVariables>
    } & TChildProps;
export function withCyberSubmissionFileCabinet<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberSubmissionFileCabinetQuery,
  CyberSubmissionFileCabinetQueryVariables,
  CyberSubmissionFileCabinetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CyberSubmissionFileCabinetQuery, CyberSubmissionFileCabinetQueryVariables, CyberSubmissionFileCabinetProps<TChildProps, TDataName>>(CyberSubmissionFileCabinetDocument, {
      alias: 'cyberSubmissionFileCabinet',
      ...operationOptions
    });
};

/**
 * __useCyberSubmissionFileCabinetQuery__
 *
 * To run a query within a React component, call `useCyberSubmissionFileCabinetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCyberSubmissionFileCabinetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCyberSubmissionFileCabinetQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useCyberSubmissionFileCabinetQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CyberSubmissionFileCabinetQuery, CyberSubmissionFileCabinetQueryVariables>) {
        return ApolloReactHooks.useQuery<CyberSubmissionFileCabinetQuery, CyberSubmissionFileCabinetQueryVariables>(CyberSubmissionFileCabinetDocument, baseOptions);
      }
export function useCyberSubmissionFileCabinetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CyberSubmissionFileCabinetQuery, CyberSubmissionFileCabinetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CyberSubmissionFileCabinetQuery, CyberSubmissionFileCabinetQueryVariables>(CyberSubmissionFileCabinetDocument, baseOptions);
        }
export type CyberSubmissionFileCabinetQueryHookResult = ReturnType<typeof useCyberSubmissionFileCabinetQuery>;
export type CyberSubmissionFileCabinetLazyQueryHookResult = ReturnType<typeof useCyberSubmissionFileCabinetLazyQuery>;
export type CyberSubmissionFileCabinetQueryResult = ApolloReactCommon.QueryResult<CyberSubmissionFileCabinetQuery, CyberSubmissionFileCabinetQueryVariables>;
export const CyberSubmissionDocument = gql`
    query CyberSubmission($submissionId: ID!) {
  cyber {
    submission(submissionId: $submissionId) {
      ...CyberSubmissionInfo
      panel {
        id
      }
    }
  }
}
    ${CyberSubmissionInfoFragmentDoc}`;
export type CyberSubmissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CyberSubmissionQuery, CyberSubmissionQueryVariables>
    } & TChildProps;
export function withCyberSubmission<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberSubmissionQuery,
  CyberSubmissionQueryVariables,
  CyberSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CyberSubmissionQuery, CyberSubmissionQueryVariables, CyberSubmissionProps<TChildProps, TDataName>>(CyberSubmissionDocument, {
      alias: 'cyberSubmission',
      ...operationOptions
    });
};

/**
 * __useCyberSubmissionQuery__
 *
 * To run a query within a React component, call `useCyberSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCyberSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCyberSubmissionQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useCyberSubmissionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CyberSubmissionQuery, CyberSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<CyberSubmissionQuery, CyberSubmissionQueryVariables>(CyberSubmissionDocument, baseOptions);
      }
export function useCyberSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CyberSubmissionQuery, CyberSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CyberSubmissionQuery, CyberSubmissionQueryVariables>(CyberSubmissionDocument, baseOptions);
        }
export type CyberSubmissionQueryHookResult = ReturnType<typeof useCyberSubmissionQuery>;
export type CyberSubmissionLazyQueryHookResult = ReturnType<typeof useCyberSubmissionLazyQuery>;
export type CyberSubmissionQueryResult = ApolloReactCommon.QueryResult<CyberSubmissionQuery, CyberSubmissionQueryVariables>;
export const CyberSubmissionForEditDocument = gql`
    query CyberSubmissionForEdit($submissionId: ID!, $panelId: ID!, $panelistIds: [ID!]) {
  cyber {
    submission(submissionId: $submissionId) {
      id
      country
      message
      ...CyberSubmissionInfo
      selectedPanelists
      additionalCarriers
      questions
      participations {
        nodes {
          id
          apiUser {
            id
            fullName
            firstName
          }
          user {
            id
            firstName
            api
            company {
              id
              name
            }
          }
        }
      }
    }
    panel(panelId: $panelId) {
      id
      schemas {
        edit(submissionId: $submissionId)
        firmup(submissionId: $submissionId)
        draft(submissionId: $submissionId, panelistIds: $panelistIds)
      }
    }
  }
}
    ${CyberSubmissionInfoFragmentDoc}`;
export type CyberSubmissionForEditProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CyberSubmissionForEditQuery, CyberSubmissionForEditQueryVariables>
    } & TChildProps;
export function withCyberSubmissionForEdit<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberSubmissionForEditQuery,
  CyberSubmissionForEditQueryVariables,
  CyberSubmissionForEditProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CyberSubmissionForEditQuery, CyberSubmissionForEditQueryVariables, CyberSubmissionForEditProps<TChildProps, TDataName>>(CyberSubmissionForEditDocument, {
      alias: 'cyberSubmissionForEdit',
      ...operationOptions
    });
};

/**
 * __useCyberSubmissionForEditQuery__
 *
 * To run a query within a React component, call `useCyberSubmissionForEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCyberSubmissionForEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCyberSubmissionForEditQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      panelId: // value for 'panelId'
 *      panelistIds: // value for 'panelistIds'
 *   },
 * });
 */
export function useCyberSubmissionForEditQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CyberSubmissionForEditQuery, CyberSubmissionForEditQueryVariables>) {
        return ApolloReactHooks.useQuery<CyberSubmissionForEditQuery, CyberSubmissionForEditQueryVariables>(CyberSubmissionForEditDocument, baseOptions);
      }
export function useCyberSubmissionForEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CyberSubmissionForEditQuery, CyberSubmissionForEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CyberSubmissionForEditQuery, CyberSubmissionForEditQueryVariables>(CyberSubmissionForEditDocument, baseOptions);
        }
export type CyberSubmissionForEditQueryHookResult = ReturnType<typeof useCyberSubmissionForEditQuery>;
export type CyberSubmissionForEditLazyQueryHookResult = ReturnType<typeof useCyberSubmissionForEditLazyQuery>;
export type CyberSubmissionForEditQueryResult = ApolloReactCommon.QueryResult<CyberSubmissionForEditQuery, CyberSubmissionForEditQueryVariables>;
export const CyberSubmissionForSubmitAddtlDocument = gql`
    query CyberSubmissionForSubmitAddtl($submissionId: ID!, $panelId: ID!, $panelistIds: [ID!]) {
  cyber {
    submission(submissionId: $submissionId) {
      id
      country
      message
      ...CyberSubmissionInfo
      selectedPanelists
      additionalCarriers
      questions
      participations {
        nodes {
          id
          apiUser {
            id
            fullName
            firstName
          }
          user {
            id
            firstName
            api
            company {
              id
              name
            }
          }
        }
      }
    }
    panel(panelId: $panelId) {
      id
      schemas {
        submitAdditional(submissionId: $submissionId, panelistIds: $panelistIds)
      }
      panelists {
        nodes {
          id
          type
          panelistConfiguration(panelId: $panelId) {
            preferred
          }
        }
      }
    }
  }
}
    ${CyberSubmissionInfoFragmentDoc}`;
export type CyberSubmissionForSubmitAddtlProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CyberSubmissionForSubmitAddtlQuery, CyberSubmissionForSubmitAddtlQueryVariables>
    } & TChildProps;
export function withCyberSubmissionForSubmitAddtl<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberSubmissionForSubmitAddtlQuery,
  CyberSubmissionForSubmitAddtlQueryVariables,
  CyberSubmissionForSubmitAddtlProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CyberSubmissionForSubmitAddtlQuery, CyberSubmissionForSubmitAddtlQueryVariables, CyberSubmissionForSubmitAddtlProps<TChildProps, TDataName>>(CyberSubmissionForSubmitAddtlDocument, {
      alias: 'cyberSubmissionForSubmitAddtl',
      ...operationOptions
    });
};

/**
 * __useCyberSubmissionForSubmitAddtlQuery__
 *
 * To run a query within a React component, call `useCyberSubmissionForSubmitAddtlQuery` and pass it any options that fit your needs.
 * When your component renders, `useCyberSubmissionForSubmitAddtlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCyberSubmissionForSubmitAddtlQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      panelId: // value for 'panelId'
 *      panelistIds: // value for 'panelistIds'
 *   },
 * });
 */
export function useCyberSubmissionForSubmitAddtlQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CyberSubmissionForSubmitAddtlQuery, CyberSubmissionForSubmitAddtlQueryVariables>) {
        return ApolloReactHooks.useQuery<CyberSubmissionForSubmitAddtlQuery, CyberSubmissionForSubmitAddtlQueryVariables>(CyberSubmissionForSubmitAddtlDocument, baseOptions);
      }
export function useCyberSubmissionForSubmitAddtlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CyberSubmissionForSubmitAddtlQuery, CyberSubmissionForSubmitAddtlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CyberSubmissionForSubmitAddtlQuery, CyberSubmissionForSubmitAddtlQueryVariables>(CyberSubmissionForSubmitAddtlDocument, baseOptions);
        }
export type CyberSubmissionForSubmitAddtlQueryHookResult = ReturnType<typeof useCyberSubmissionForSubmitAddtlQuery>;
export type CyberSubmissionForSubmitAddtlLazyQueryHookResult = ReturnType<typeof useCyberSubmissionForSubmitAddtlLazyQuery>;
export type CyberSubmissionForSubmitAddtlQueryResult = ApolloReactCommon.QueryResult<CyberSubmissionForSubmitAddtlQuery, CyberSubmissionForSubmitAddtlQueryVariables>;
export const CyberSubmissionQuotesDocument = gql`
    query CyberSubmissionQuotes($submissionId: ID!) {
  cyber {
    submission(submissionId: $submissionId) {
      id
      name
      panel {
        id
        useRetailSurplusLines
        displayTax
        configuration
        company {
          id
          name
        }
      }
      status
      limit
      deductible
      coverageStartDate
      lineOfBusiness
      revisedAt
      email
      country
      state
      customLabelForFees
      surplusLinesLicenseInfo {
        address {
          street_line
          state
          city
          zip_code
        }
        expiration_date
        license_holder
        license_number
        state
      }
      contactName
      owner {
        id
        fullName
        ...Avatar
        jobTitle
        company {
          id
          name
        }
      }
      documents {
        id
        category
        version
        ...DocumentForForm
      }
      collaborations {
        nodes {
          id
          type
          user {
            id
          }
          discussion {
            id
            ...Messages
          }
        }
      }
      participations {
        nodes {
          id
          documents {
            id
            ...Document
          }
          createdAt
          archivedAt
          apiUser {
            id
            fullName
            firstName
            avatarUrl
          }
          user {
            id
            firstName
            api
            company {
              id
              name
              logoUrl
              ambestRating {
                id
                rating
              }
            }
          }
          collaborator {
            id
            firstName
            api
            company {
              id
              name
              logoUrl
              ambestRating {
                id
                rating
              }
            }
          }
          discussion {
            id
            ...Messages
          }
          quotations {
            nodes {
              id
              ...CyberQuotationInfo
            }
          }
          subjectivities {
            nodes {
              id
              text
            }
          }
        }
      }
    }
  }
}
    ${AvatarFragmentDoc}
${DocumentForFormFragmentDoc}
${MessagesFragmentDoc}
${DocumentFragmentDoc}
${CyberQuotationInfoFragmentDoc}`;
export type CyberSubmissionQuotesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CyberSubmissionQuotesQuery, CyberSubmissionQuotesQueryVariables>
    } & TChildProps;
export function withCyberSubmissionQuotes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CyberSubmissionQuotesQuery,
  CyberSubmissionQuotesQueryVariables,
  CyberSubmissionQuotesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CyberSubmissionQuotesQuery, CyberSubmissionQuotesQueryVariables, CyberSubmissionQuotesProps<TChildProps, TDataName>>(CyberSubmissionQuotesDocument, {
      alias: 'cyberSubmissionQuotes',
      ...operationOptions
    });
};

/**
 * __useCyberSubmissionQuotesQuery__
 *
 * To run a query within a React component, call `useCyberSubmissionQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCyberSubmissionQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCyberSubmissionQuotesQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useCyberSubmissionQuotesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CyberSubmissionQuotesQuery, CyberSubmissionQuotesQueryVariables>) {
        return ApolloReactHooks.useQuery<CyberSubmissionQuotesQuery, CyberSubmissionQuotesQueryVariables>(CyberSubmissionQuotesDocument, baseOptions);
      }
export function useCyberSubmissionQuotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CyberSubmissionQuotesQuery, CyberSubmissionQuotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CyberSubmissionQuotesQuery, CyberSubmissionQuotesQueryVariables>(CyberSubmissionQuotesDocument, baseOptions);
        }
export type CyberSubmissionQuotesQueryHookResult = ReturnType<typeof useCyberSubmissionQuotesQuery>;
export type CyberSubmissionQuotesLazyQueryHookResult = ReturnType<typeof useCyberSubmissionQuotesLazyQuery>;
export type CyberSubmissionQuotesQueryResult = ApolloReactCommon.QueryResult<CyberSubmissionQuotesQuery, CyberSubmissionQuotesQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard($query: String, $order: String) {
  viewer {
    user {
      id
      ... on Reinsurer {
        search(query: $query, order: $order) {
          id
          ... on Worksheet {
            insurancePolicy {
              id
              number
            }
            createdAt
            currency
            reinsuranceEffectiveDate
            totalInsuredValue
            ...WorksheetCell
            ...CedingCompanyCell
            ...WorksheetStatusCell
            quotePath: quotes {
              nodes {
                pathId: id
                status
                revisions(last: 1) {
                  nodes {
                    id
                    attachmentPoint
                    layerLimit
                    participation
                    premium
                    netPremium
                    quote {
                      id
                      worksheet {
                        id
                        kind
                      }
                    }
                    quotedLayers {
                      id
                      ...QuotedLayerInfo
                    }
                  }
                }
                reinsurer {
                  id
                  firstName
                  lastName
                  company {
                    id
                  }
                }
              }
            }
          }
        }
      }
      ... on Cedent {
        onboarding
        dashboardEntries(search: $query, order: $order) {
          nodes {
            id
            brokered
            demo
            declined
            lineOfBusiness
            createdAt
            effectiveDate
            keyPerils
            marketsInvited
            quoted
            bound
            issued
            kind
            name
            ownerId
            ownerName
            ownerCompanyId
            status
            totalBound
            policyType
            worksheetLineOfBusiness
            responded
            totalRequested
            attachmentPoint
            perOccurrenceLimit
            policyLimit
            boundRatio
          }
        }
      }
    }
  }
}
    ${WorksheetCellFragmentDoc}
${CedingCompanyCellFragmentDoc}
${WorksheetStatusCellFragmentDoc}
${QuotedLayerInfoFragmentDoc}`;
export type DashboardProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DashboardQuery, DashboardQueryVariables>
    } & TChildProps;
export function withDashboard<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DashboardQuery,
  DashboardQueryVariables,
  DashboardProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DashboardQuery, DashboardQueryVariables, DashboardProps<TChildProps, TDataName>>(DashboardDocument, {
      alias: 'dashboard',
      ...operationOptions
    });
};

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *      query: // value for 'query'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
      }
export function useDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = ApolloReactCommon.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const ExportSubmissionDocument = gql`
    query ExportSubmission($worksheetId: ID!) {
  viewer {
    user {
      id
      ... on Cedent {
        worksheet(worksheetId: $worksheetId) {
          id
          ...WorksheetExport
        }
      }
      ... on Broker {
        worksheet(worksheetId: $worksheetId) {
          id
          ...WorksheetExport
        }
      }
    }
  }
}
    ${WorksheetExportFragmentDoc}`;
export type ExportSubmissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ExportSubmissionQuery, ExportSubmissionQueryVariables>
    } & TChildProps;
export function withExportSubmission<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExportSubmissionQuery,
  ExportSubmissionQueryVariables,
  ExportSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ExportSubmissionQuery, ExportSubmissionQueryVariables, ExportSubmissionProps<TChildProps, TDataName>>(ExportSubmissionDocument, {
      alias: 'exportSubmission',
      ...operationOptions
    });
};

/**
 * __useExportSubmissionQuery__
 *
 * To run a query within a React component, call `useExportSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSubmissionQuery({
 *   variables: {
 *      worksheetId: // value for 'worksheetId'
 *   },
 * });
 */
export function useExportSubmissionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ExportSubmissionQuery, ExportSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<ExportSubmissionQuery, ExportSubmissionQueryVariables>(ExportSubmissionDocument, baseOptions);
      }
export function useExportSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportSubmissionQuery, ExportSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExportSubmissionQuery, ExportSubmissionQueryVariables>(ExportSubmissionDocument, baseOptions);
        }
export type ExportSubmissionQueryHookResult = ReturnType<typeof useExportSubmissionQuery>;
export type ExportSubmissionLazyQueryHookResult = ReturnType<typeof useExportSubmissionLazyQuery>;
export type ExportSubmissionQueryResult = ApolloReactCommon.QueryResult<ExportSubmissionQuery, ExportSubmissionQueryVariables>;
export const GetRevisionInfoDocument = gql`
    query getRevisionInfo($quoteId: ID!) {
  viewer {
    reinsurer {
      id
      quote(quoteId: $quoteId) {
        id
        ...LatestRevision
        ...Layer
        worksheet {
          id
          keyPerils
          kind
          exclusions
          specialInstructions
          perOccurrenceLimit
          reinsuranceRequests {
            nodes {
              id
              proposedLayerPremium
              deductible
              proposedNetLayerPremium
              cedingCommission
            }
          }
          insurancePolicy {
            id
            coverages {
              nodes {
                id
                type
              }
            }
          }
          ...Tower
        }
      }
    }
  }
}
    ${LatestRevisionFragmentDoc}
${LayerFragmentDoc}
${TowerFragmentDoc}`;
export type GetRevisionInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetRevisionInfoQuery, GetRevisionInfoQueryVariables>
    } & TChildProps;
export function withGetRevisionInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetRevisionInfoQuery,
  GetRevisionInfoQueryVariables,
  GetRevisionInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetRevisionInfoQuery, GetRevisionInfoQueryVariables, GetRevisionInfoProps<TChildProps, TDataName>>(GetRevisionInfoDocument, {
      alias: 'getRevisionInfo',
      ...operationOptions
    });
};

/**
 * __useGetRevisionInfoQuery__
 *
 * To run a query within a React component, call `useGetRevisionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevisionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevisionInfoQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetRevisionInfoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRevisionInfoQuery, GetRevisionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRevisionInfoQuery, GetRevisionInfoQueryVariables>(GetRevisionInfoDocument, baseOptions);
      }
export function useGetRevisionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRevisionInfoQuery, GetRevisionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRevisionInfoQuery, GetRevisionInfoQueryVariables>(GetRevisionInfoDocument, baseOptions);
        }
export type GetRevisionInfoQueryHookResult = ReturnType<typeof useGetRevisionInfoQuery>;
export type GetRevisionInfoLazyQueryHookResult = ReturnType<typeof useGetRevisionInfoLazyQuery>;
export type GetRevisionInfoQueryResult = ApolloReactCommon.QueryResult<GetRevisionInfoQuery, GetRevisionInfoQueryVariables>;
export const LicenseInformationDocument = gql`
    query licenseInformation {
  viewer {
    user {
      id
      company {
        id
        name
        licenseInformation {
          surplus_lines_licenses {
            address {
              street_line
              state
              city
              zip_code
            }
            state
            expiration_date
            license_holder
            license_number
          }
        }
        surplusLinesSchema
      }
    }
  }
}
    `;
export type LicenseInformationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LicenseInformationQuery, LicenseInformationQueryVariables>
    } & TChildProps;
export function withLicenseInformation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LicenseInformationQuery,
  LicenseInformationQueryVariables,
  LicenseInformationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LicenseInformationQuery, LicenseInformationQueryVariables, LicenseInformationProps<TChildProps, TDataName>>(LicenseInformationDocument, {
      alias: 'licenseInformation',
      ...operationOptions
    });
};

/**
 * __useLicenseInformationQuery__
 *
 * To run a query within a React component, call `useLicenseInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useLicenseInformationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LicenseInformationQuery, LicenseInformationQueryVariables>) {
        return ApolloReactHooks.useQuery<LicenseInformationQuery, LicenseInformationQueryVariables>(LicenseInformationDocument, baseOptions);
      }
export function useLicenseInformationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LicenseInformationQuery, LicenseInformationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LicenseInformationQuery, LicenseInformationQueryVariables>(LicenseInformationDocument, baseOptions);
        }
export type LicenseInformationQueryHookResult = ReturnType<typeof useLicenseInformationQuery>;
export type LicenseInformationLazyQueryHookResult = ReturnType<typeof useLicenseInformationLazyQuery>;
export type LicenseInformationQueryResult = ApolloReactCommon.QueryResult<LicenseInformationQuery, LicenseInformationQueryVariables>;
export const ListBrokersDocument = gql`
    query ListBrokers {
  viewer {
    user {
      id
      company {
        id
        brokers {
          nodes {
            id
            fullName
            company {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type ListBrokersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ListBrokersQuery, ListBrokersQueryVariables>
    } & TChildProps;
export function withListBrokers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListBrokersQuery,
  ListBrokersQueryVariables,
  ListBrokersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ListBrokersQuery, ListBrokersQueryVariables, ListBrokersProps<TChildProps, TDataName>>(ListBrokersDocument, {
      alias: 'listBrokers',
      ...operationOptions
    });
};

/**
 * __useListBrokersQuery__
 *
 * To run a query within a React component, call `useListBrokersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBrokersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBrokersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListBrokersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListBrokersQuery, ListBrokersQueryVariables>) {
        return ApolloReactHooks.useQuery<ListBrokersQuery, ListBrokersQueryVariables>(ListBrokersDocument, baseOptions);
      }
export function useListBrokersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListBrokersQuery, ListBrokersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListBrokersQuery, ListBrokersQueryVariables>(ListBrokersDocument, baseOptions);
        }
export type ListBrokersQueryHookResult = ReturnType<typeof useListBrokersQuery>;
export type ListBrokersLazyQueryHookResult = ReturnType<typeof useListBrokersLazyQuery>;
export type ListBrokersQueryResult = ApolloReactCommon.QueryResult<ListBrokersQuery, ListBrokersQueryVariables>;
export const MarketDocument = gql`
    query Market($companyId: ID!) {
  viewer {
    user {
      id
      company {
        id
        marketFor(companyId: $companyId) {
          id
          standardExclusions
          boilerplateText
          defaultMessage
          dealSpecificExclusions
          dueDiligences
        }
      }
    }
  }
}
    `;
export type MarketProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MarketQuery, MarketQueryVariables>
    } & TChildProps;
export function withMarket<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MarketQuery,
  MarketQueryVariables,
  MarketProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MarketQuery, MarketQueryVariables, MarketProps<TChildProps, TDataName>>(MarketDocument, {
      alias: 'market',
      ...operationOptions
    });
};

/**
 * __useMarketQuery__
 *
 * To run a query within a React component, call `useMarketQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMarketQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MarketQuery, MarketQueryVariables>) {
        return ApolloReactHooks.useQuery<MarketQuery, MarketQueryVariables>(MarketDocument, baseOptions);
      }
export function useMarketLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MarketQuery, MarketQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MarketQuery, MarketQueryVariables>(MarketDocument, baseOptions);
        }
export type MarketQueryHookResult = ReturnType<typeof useMarketQuery>;
export type MarketLazyQueryHookResult = ReturnType<typeof useMarketLazyQuery>;
export type MarketQueryResult = ApolloReactCommon.QueryResult<MarketQuery, MarketQueryVariables>;
export const MlQuotationDocument = gql`
    query MLQuotation($participationId: ID!) {
  viewer {
    user {
      id
      ... on Broker {
        participation(participationId: $participationId) {
          id
          ...MLParticipation
        }
      }
      ... on Reinsurer {
        participation(participationId: $participationId) {
          id
          ...MLParticipation
        }
      }
    }
  }
}
    ${MlParticipationFragmentDoc}`;
export type MlQuotationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MlQuotationQuery, MlQuotationQueryVariables>
    } & TChildProps;
export function withMlQuotation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MlQuotationQuery,
  MlQuotationQueryVariables,
  MlQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MlQuotationQuery, MlQuotationQueryVariables, MlQuotationProps<TChildProps, TDataName>>(MlQuotationDocument, {
      alias: 'mlQuotation',
      ...operationOptions
    });
};

/**
 * __useMlQuotationQuery__
 *
 * To run a query within a React component, call `useMlQuotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMlQuotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlQuotationQuery({
 *   variables: {
 *      participationId: // value for 'participationId'
 *   },
 * });
 */
export function useMlQuotationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MlQuotationQuery, MlQuotationQueryVariables>) {
        return ApolloReactHooks.useQuery<MlQuotationQuery, MlQuotationQueryVariables>(MlQuotationDocument, baseOptions);
      }
export function useMlQuotationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MlQuotationQuery, MlQuotationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MlQuotationQuery, MlQuotationQueryVariables>(MlQuotationDocument, baseOptions);
        }
export type MlQuotationQueryHookResult = ReturnType<typeof useMlQuotationQuery>;
export type MlQuotationLazyQueryHookResult = ReturnType<typeof useMlQuotationLazyQuery>;
export type MlQuotationQueryResult = ApolloReactCommon.QueryResult<MlQuotationQuery, MlQuotationQueryVariables>;
export const NotificationEventsDocument = gql`
    query NotificationEvents($cursor: String) {
  viewer {
    user {
      id
      notificationEvents(after: $cursor, first: 20) {
        unreadCount
        nodes {
          id
          event
          status
          link
          occurredAt
          relatesTo {
            id
            ... on PanelSubmission {
              id
              name
              owner {
                id
                company {
                  id
                  name
                }
              }
            }
            ... on Question {
              id
              section
              text
              questionable {
                id
                ... on Participation {
                  submission {
                    id
                    type
                    name
                    ... on CyberSubmission {
                      panel {
                        id
                      }
                    }
                  }
                  quotations(last: 1) {
                    nodes {
                      id
                    }
                  }
                }
                ... on Quote {
                  id
                  ...QuoteInfoFragment
                }
                ... on Quotation {
                  id
                  participation {
                    id
                    submission {
                      id
                    }
                  }
                }
              }
            }
            ... on Subjectivity {
              id
              section
              text
              questionable {
                id
                ... on Participation {
                  submission {
                    id
                    type
                    name
                    ... on CyberSubmission {
                      panel {
                        id
                      }
                    }
                  }
                  quotations(last: 1) {
                    nodes {
                      id
                    }
                  }
                }
                ... on Quote {
                  id
                  ...QuoteInfoFragment
                }
                ... on Quotation {
                  id
                  participation {
                    id
                    submission {
                      id
                    }
                  }
                }
              }
            }
            ... on Quote {
              id
              reinsurer {
                id
                companyName
              }
              discussion {
                id
              }
              worksheet {
                id
                ...WorksheetFragment
              }
            }
            ... on Worksheet {
              id
              ...WorksheetFragment
            }
            ... on Revision {
              id
              quote {
                id
                reinsurer {
                  id
                  companyName
                }
                worksheet {
                  id
                  ...WorksheetFragment
                }
              }
            }
            ... on Broadcast {
              id
              subject
              type
              broadcastable {
                id
                ... on NFPRepsAndWarrantiesSubmission {
                  name
                  owner {
                    id
                    company {
                      id
                      name
                    }
                  }
                  participations {
                    nodes {
                      id
                      discussion {
                        id
                      }
                    }
                  }
                }
                ... on Worksheet {
                  ...WorksheetFragment
                  quotes {
                    nodes {
                      id
                      discussion {
                        id
                      }
                    }
                  }
                }
              }
            }
            ... on DirectMessage {
              id
              subject
              discussion {
                id
                discussable {
                  id
                  ... on Participation {
                    submission {
                      id
                      name
                      ... on PanelSubmission {
                        panel {
                          id
                          lineOfBusiness
                        }
                      }
                      ... on CyberSubmission {
                        panel {
                          id
                        }
                      }
                      ... on PCGSubmission {
                        panel {
                          id
                          lineOfBusiness
                        }
                      }
                    }
                    quotations(last: 1) {
                      nodes {
                        id
                      }
                    }
                  }
                  ... on Worksheet {
                    id
                    demo
                  }
                  ... on Quote {
                    id
                    worksheet {
                      id
                      demo
                    }
                  }
                }
              }
              author {
                id
                fullName
              }
            }
            ... on Participation {
              id
              submission {
                id
                name
                owner {
                  id
                  company {
                    id
                    name
                  }
                }
              }
              user {
                id
                company {
                  id
                  name
                }
              }
            }
            ... on NFPRepsAndWarrantiesQuotation {
              id
              participation {
                id
                user {
                  id
                  company {
                    id
                    name
                  }
                }
                submission {
                  id
                  name
                }
              }
            }
            ... on CyberSubmission {
              id
              name
              contactName
              panel {
                id
              }
            }
            ... on CyberQuotation {
              id
              participation {
                id
                apiUser {
                  id
                  firstName
                }
                user {
                  id
                  company {
                    id
                    name
                  }
                }
                submission {
                  id
                  name
                  owner {
                    id
                    company {
                      id
                      name
                    }
                  }
                  ... on CyberSubmission {
                    panel {
                      id
                    }
                  }
                }
              }
            }
            ... on PanelsQuotation {
              id
              participation {
                id
                user {
                  id
                  company {
                    id
                    name
                  }
                }
                submission {
                  id
                  name
                  owner {
                    id
                    company {
                      id
                      name
                    }
                  }
                  ... on PanelSubmission {
                    panel {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
}
    ${QuoteInfoFragmentFragmentDoc}
${WorksheetFragmentFragmentDoc}`;
export type NotificationEventsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NotificationEventsQuery, NotificationEventsQueryVariables>
    } & TChildProps;
export function withNotificationEvents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NotificationEventsQuery,
  NotificationEventsQueryVariables,
  NotificationEventsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NotificationEventsQuery, NotificationEventsQueryVariables, NotificationEventsProps<TChildProps, TDataName>>(NotificationEventsDocument, {
      alias: 'notificationEvents',
      ...operationOptions
    });
};

/**
 * __useNotificationEventsQuery__
 *
 * To run a query within a React component, call `useNotificationEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationEventsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useNotificationEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationEventsQuery, NotificationEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationEventsQuery, NotificationEventsQueryVariables>(NotificationEventsDocument, baseOptions);
      }
export function useNotificationEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationEventsQuery, NotificationEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationEventsQuery, NotificationEventsQueryVariables>(NotificationEventsDocument, baseOptions);
        }
export type NotificationEventsQueryHookResult = ReturnType<typeof useNotificationEventsQuery>;
export type NotificationEventsLazyQueryHookResult = ReturnType<typeof useNotificationEventsLazyQuery>;
export type NotificationEventsQueryResult = ApolloReactCommon.QueryResult<NotificationEventsQuery, NotificationEventsQueryVariables>;
export const PanelDocument = gql`
    query Panel($panelId: ID!) {
  viewer {
    user {
      id
      company {
        id
        panel(panelId: $panelId) {
          id
          name
          lineOfBusiness
          configuration
          displayTax
          manualSubjectivities
          externalCarriers {
            id
            name
            logoUrl
          }
          panelistConfigurations {
            nodes {
              disabled
              documents {
                id
                ...Document
              }
              wholesalerCompany {
                id
                name
                logoUrl
              }
              panelist {
                id
                firstName
                panelistConfiguration(panelId: $panelId) {
                  preferred
                  indicationOnly
                  carrierAuthentications {
                    nodes {
                      id
                      nickname
                      default
                    }
                  }
                }
              }
            }
          }
          panelists {
            nodes {
              id
              firstName
              mfaCorporateEmailRequired
              mfaCorporateNetworksRequired
              mfaRemoteNetworkAccessRequired
              websiteOptional
              indicationProvided
              lastName
              avatarUrl
              type
              ... on Reinsurer {
                medianReplyTime
                quoteRatio
                numberOfUwQuestions
                panelistConfiguration(panelId: $panelId) {
                  preferred
                  indicationOnly
                }
              }
              company {
                id
                logoUrl
                name
                ambestRating {
                  id
                  rating
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}`;
export type PanelProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PanelQuery, PanelQueryVariables>
    } & TChildProps;
export function withPanel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelQuery,
  PanelQueryVariables,
  PanelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PanelQuery, PanelQueryVariables, PanelProps<TChildProps, TDataName>>(PanelDocument, {
      alias: 'panel',
      ...operationOptions
    });
};

/**
 * __usePanelQuery__
 *
 * To run a query within a React component, call `usePanelQuery` and pass it any options that fit your needs.
 * When your component renders, `usePanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePanelQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function usePanelQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PanelQuery, PanelQueryVariables>) {
        return ApolloReactHooks.useQuery<PanelQuery, PanelQueryVariables>(PanelDocument, baseOptions);
      }
export function usePanelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PanelQuery, PanelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PanelQuery, PanelQueryVariables>(PanelDocument, baseOptions);
        }
export type PanelQueryHookResult = ReturnType<typeof usePanelQuery>;
export type PanelLazyQueryHookResult = ReturnType<typeof usePanelLazyQuery>;
export type PanelQueryResult = ApolloReactCommon.QueryResult<PanelQuery, PanelQueryVariables>;
export const PanelProposalConfigurationDocument = gql`
    query PanelProposalConfiguration($panelId: ID!) {
  viewer {
    user {
      id
      company {
        id
        panel(panelId: $panelId) {
          id
          name
          lineOfBusiness
          proposalConfiguration {
            id
            configuration
          }
        }
      }
    }
  }
}
    `;
export type PanelProposalConfigurationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PanelProposalConfigurationQuery, PanelProposalConfigurationQueryVariables>
    } & TChildProps;
export function withPanelProposalConfiguration<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelProposalConfigurationQuery,
  PanelProposalConfigurationQueryVariables,
  PanelProposalConfigurationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PanelProposalConfigurationQuery, PanelProposalConfigurationQueryVariables, PanelProposalConfigurationProps<TChildProps, TDataName>>(PanelProposalConfigurationDocument, {
      alias: 'panelProposalConfiguration',
      ...operationOptions
    });
};

/**
 * __usePanelProposalConfigurationQuery__
 *
 * To run a query within a React component, call `usePanelProposalConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePanelProposalConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePanelProposalConfigurationQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function usePanelProposalConfigurationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PanelProposalConfigurationQuery, PanelProposalConfigurationQueryVariables>) {
        return ApolloReactHooks.useQuery<PanelProposalConfigurationQuery, PanelProposalConfigurationQueryVariables>(PanelProposalConfigurationDocument, baseOptions);
      }
export function usePanelProposalConfigurationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PanelProposalConfigurationQuery, PanelProposalConfigurationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PanelProposalConfigurationQuery, PanelProposalConfigurationQueryVariables>(PanelProposalConfigurationDocument, baseOptions);
        }
export type PanelProposalConfigurationQueryHookResult = ReturnType<typeof usePanelProposalConfigurationQuery>;
export type PanelProposalConfigurationLazyQueryHookResult = ReturnType<typeof usePanelProposalConfigurationLazyQuery>;
export type PanelProposalConfigurationQueryResult = ApolloReactCommon.QueryResult<PanelProposalConfigurationQuery, PanelProposalConfigurationQueryVariables>;
export const PanelsDocument = gql`
    query Panels($onlyVisible: Boolean = true) {
  viewer {
    user {
      id
      company {
        id
        panels(onlyVisible: $onlyVisible) {
          nodes {
            id
            name
            lineOfBusiness
            configuration
            displayTax
          }
        }
      }
    }
  }
}
    `;
export type PanelsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PanelsQuery, PanelsQueryVariables>
    } & TChildProps;
export function withPanels<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelsQuery,
  PanelsQueryVariables,
  PanelsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PanelsQuery, PanelsQueryVariables, PanelsProps<TChildProps, TDataName>>(PanelsDocument, {
      alias: 'panels',
      ...operationOptions
    });
};

/**
 * __usePanelsQuery__
 *
 * To run a query within a React component, call `usePanelsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePanelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePanelsQuery({
 *   variables: {
 *      onlyVisible: // value for 'onlyVisible'
 *   },
 * });
 */
export function usePanelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PanelsQuery, PanelsQueryVariables>) {
        return ApolloReactHooks.useQuery<PanelsQuery, PanelsQueryVariables>(PanelsDocument, baseOptions);
      }
export function usePanelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PanelsQuery, PanelsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PanelsQuery, PanelsQueryVariables>(PanelsDocument, baseOptions);
        }
export type PanelsQueryHookResult = ReturnType<typeof usePanelsQuery>;
export type PanelsLazyQueryHookResult = ReturnType<typeof usePanelsLazyQuery>;
export type PanelsQueryResult = ApolloReactCommon.QueryResult<PanelsQuery, PanelsQueryVariables>;
export const EditQuoteSchemaDocument = gql`
    query editQuoteSchema($participationId: ID!, $quoteId: ID!) {
  panels {
    id
    participation(participationId: $participationId) {
      id
      submission {
        id
        ... on PanelSubmission {
          id
          panel {
            id
            schemas {
              editQuote(quoteId: $quoteId)
            }
          }
        }
      }
    }
  }
}
    `;
export type EditQuoteSchemaProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EditQuoteSchemaQuery, EditQuoteSchemaQueryVariables>
    } & TChildProps;
export function withEditQuoteSchema<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditQuoteSchemaQuery,
  EditQuoteSchemaQueryVariables,
  EditQuoteSchemaProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EditQuoteSchemaQuery, EditQuoteSchemaQueryVariables, EditQuoteSchemaProps<TChildProps, TDataName>>(EditQuoteSchemaDocument, {
      alias: 'editQuoteSchema',
      ...operationOptions
    });
};

/**
 * __useEditQuoteSchemaQuery__
 *
 * To run a query within a React component, call `useEditQuoteSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditQuoteSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditQuoteSchemaQuery({
 *   variables: {
 *      participationId: // value for 'participationId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useEditQuoteSchemaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EditQuoteSchemaQuery, EditQuoteSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<EditQuoteSchemaQuery, EditQuoteSchemaQueryVariables>(EditQuoteSchemaDocument, baseOptions);
      }
export function useEditQuoteSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditQuoteSchemaQuery, EditQuoteSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EditQuoteSchemaQuery, EditQuoteSchemaQueryVariables>(EditQuoteSchemaDocument, baseOptions);
        }
export type EditQuoteSchemaQueryHookResult = ReturnType<typeof useEditQuoteSchemaQuery>;
export type EditQuoteSchemaLazyQueryHookResult = ReturnType<typeof useEditQuoteSchemaLazyQuery>;
export type EditQuoteSchemaQueryResult = ApolloReactCommon.QueryResult<EditQuoteSchemaQuery, EditQuoteSchemaQueryVariables>;
export const EditMultilineSchemaDocument = gql`
    query editMultilineSchema($submissionId: ID!) {
  panels {
    id
    schemas {
      edit(submissionId: $submissionId)
    }
    submission(submissionId: $submissionId) {
      id
      ...PanelSubmissionInfo
    }
  }
}
    ${PanelSubmissionInfoFragmentDoc}`;
export type EditMultilineSchemaProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EditMultilineSchemaQuery, EditMultilineSchemaQueryVariables>
    } & TChildProps;
export function withEditMultilineSchema<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditMultilineSchemaQuery,
  EditMultilineSchemaQueryVariables,
  EditMultilineSchemaProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EditMultilineSchemaQuery, EditMultilineSchemaQueryVariables, EditMultilineSchemaProps<TChildProps, TDataName>>(EditMultilineSchemaDocument, {
      alias: 'editMultilineSchema',
      ...operationOptions
    });
};

/**
 * __useEditMultilineSchemaQuery__
 *
 * To run a query within a React component, call `useEditMultilineSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMultilineSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMultilineSchemaQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useEditMultilineSchemaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EditMultilineSchemaQuery, EditMultilineSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<EditMultilineSchemaQuery, EditMultilineSchemaQueryVariables>(EditMultilineSchemaDocument, baseOptions);
      }
export function useEditMultilineSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditMultilineSchemaQuery, EditMultilineSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EditMultilineSchemaQuery, EditMultilineSchemaQueryVariables>(EditMultilineSchemaDocument, baseOptions);
        }
export type EditMultilineSchemaQueryHookResult = ReturnType<typeof useEditMultilineSchemaQuery>;
export type EditMultilineSchemaLazyQueryHookResult = ReturnType<typeof useEditMultilineSchemaLazyQuery>;
export type EditMultilineSchemaQueryResult = ApolloReactCommon.QueryResult<EditMultilineSchemaQuery, EditMultilineSchemaQueryVariables>;
export const EditSchemaDocument = gql`
    query editSchema($panelId: ID!, $submissionId: ID!) {
  panels {
    id
    panel(panelId: $panelId) {
      id
      country
      lineOfBusiness
      schemas {
        edit(submissionId: $submissionId)
      }
    }
    submission(submissionId: $submissionId) {
      id
      ...PanelSubmissionInfo
    }
  }
}
    ${PanelSubmissionInfoFragmentDoc}`;
export type EditSchemaProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EditSchemaQuery, EditSchemaQueryVariables>
    } & TChildProps;
export function withEditSchema<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditSchemaQuery,
  EditSchemaQueryVariables,
  EditSchemaProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EditSchemaQuery, EditSchemaQueryVariables, EditSchemaProps<TChildProps, TDataName>>(EditSchemaDocument, {
      alias: 'editSchema',
      ...operationOptions
    });
};

/**
 * __useEditSchemaQuery__
 *
 * To run a query within a React component, call `useEditSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditSchemaQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useEditSchemaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EditSchemaQuery, EditSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<EditSchemaQuery, EditSchemaQueryVariables>(EditSchemaDocument, baseOptions);
      }
export function useEditSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditSchemaQuery, EditSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EditSchemaQuery, EditSchemaQueryVariables>(EditSchemaDocument, baseOptions);
        }
export type EditSchemaQueryHookResult = ReturnType<typeof useEditSchemaQuery>;
export type EditSchemaLazyQueryHookResult = ReturnType<typeof useEditSchemaLazyQuery>;
export type EditSchemaQueryResult = ApolloReactCommon.QueryResult<EditSchemaQuery, EditSchemaQueryVariables>;
export const GetCarrierQuoteDocument = gql`
    query getCarrierQuote($participationId: ID!, $quotationId: ID!) {
  panels {
    id
    participation(participationId: $participationId) {
      id
      externalCarrier {
        id
        name
      }
      quote(participationId: $participationId, quotationId: $quotationId) {
        id
        ...PanelQuotationInfo
      }
    }
  }
}
    ${PanelQuotationInfoFragmentDoc}`;
export type GetCarrierQuoteProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetCarrierQuoteQuery, GetCarrierQuoteQueryVariables>
    } & TChildProps;
export function withGetCarrierQuote<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCarrierQuoteQuery,
  GetCarrierQuoteQueryVariables,
  GetCarrierQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetCarrierQuoteQuery, GetCarrierQuoteQueryVariables, GetCarrierQuoteProps<TChildProps, TDataName>>(GetCarrierQuoteDocument, {
      alias: 'getCarrierQuote',
      ...operationOptions
    });
};

/**
 * __useGetCarrierQuoteQuery__
 *
 * To run a query within a React component, call `useGetCarrierQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarrierQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarrierQuoteQuery({
 *   variables: {
 *      participationId: // value for 'participationId'
 *      quotationId: // value for 'quotationId'
 *   },
 * });
 */
export function useGetCarrierQuoteQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCarrierQuoteQuery, GetCarrierQuoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCarrierQuoteQuery, GetCarrierQuoteQueryVariables>(GetCarrierQuoteDocument, baseOptions);
      }
export function useGetCarrierQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCarrierQuoteQuery, GetCarrierQuoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCarrierQuoteQuery, GetCarrierQuoteQueryVariables>(GetCarrierQuoteDocument, baseOptions);
        }
export type GetCarrierQuoteQueryHookResult = ReturnType<typeof useGetCarrierQuoteQuery>;
export type GetCarrierQuoteLazyQueryHookResult = ReturnType<typeof useGetCarrierQuoteLazyQuery>;
export type GetCarrierQuoteQueryResult = ApolloReactCommon.QueryResult<GetCarrierQuoteQuery, GetCarrierQuoteQueryVariables>;
export const NewCarrierQuoteSchemaDocument = gql`
    query newCarrierQuoteSchema($participationId: ID!) {
  panels {
    id
    participation(participationId: $participationId) {
      id
      schemas {
        newCarrierQuote(participationId: $participationId)
      }
    }
  }
}
    `;
export type NewCarrierQuoteSchemaProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NewCarrierQuoteSchemaQuery, NewCarrierQuoteSchemaQueryVariables>
    } & TChildProps;
export function withNewCarrierQuoteSchema<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewCarrierQuoteSchemaQuery,
  NewCarrierQuoteSchemaQueryVariables,
  NewCarrierQuoteSchemaProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NewCarrierQuoteSchemaQuery, NewCarrierQuoteSchemaQueryVariables, NewCarrierQuoteSchemaProps<TChildProps, TDataName>>(NewCarrierQuoteSchemaDocument, {
      alias: 'newCarrierQuoteSchema',
      ...operationOptions
    });
};

/**
 * __useNewCarrierQuoteSchemaQuery__
 *
 * To run a query within a React component, call `useNewCarrierQuoteSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewCarrierQuoteSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCarrierQuoteSchemaQuery({
 *   variables: {
 *      participationId: // value for 'participationId'
 *   },
 * });
 */
export function useNewCarrierQuoteSchemaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<NewCarrierQuoteSchemaQuery, NewCarrierQuoteSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<NewCarrierQuoteSchemaQuery, NewCarrierQuoteSchemaQueryVariables>(NewCarrierQuoteSchemaDocument, baseOptions);
      }
export function useNewCarrierQuoteSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewCarrierQuoteSchemaQuery, NewCarrierQuoteSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewCarrierQuoteSchemaQuery, NewCarrierQuoteSchemaQueryVariables>(NewCarrierQuoteSchemaDocument, baseOptions);
        }
export type NewCarrierQuoteSchemaQueryHookResult = ReturnType<typeof useNewCarrierQuoteSchemaQuery>;
export type NewCarrierQuoteSchemaLazyQueryHookResult = ReturnType<typeof useNewCarrierQuoteSchemaLazyQuery>;
export type NewCarrierQuoteSchemaQueryResult = ApolloReactCommon.QueryResult<NewCarrierQuoteSchemaQuery, NewCarrierQuoteSchemaQueryVariables>;
export const NewQuoteSchemaDocument = gql`
    query newQuoteSchema($panelId: ID!, $submissionId: ID!) {
  panels {
    id
    panel(panelId: $panelId) {
      id
      country
      schemas {
        newManualQuote(submissionId: $submissionId)
      }
    }
  }
}
    `;
export type NewQuoteSchemaProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NewQuoteSchemaQuery, NewQuoteSchemaQueryVariables>
    } & TChildProps;
export function withNewQuoteSchema<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewQuoteSchemaQuery,
  NewQuoteSchemaQueryVariables,
  NewQuoteSchemaProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NewQuoteSchemaQuery, NewQuoteSchemaQueryVariables, NewQuoteSchemaProps<TChildProps, TDataName>>(NewQuoteSchemaDocument, {
      alias: 'newQuoteSchema',
      ...operationOptions
    });
};

/**
 * __useNewQuoteSchemaQuery__
 *
 * To run a query within a React component, call `useNewQuoteSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewQuoteSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewQuoteSchemaQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useNewQuoteSchemaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<NewQuoteSchemaQuery, NewQuoteSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<NewQuoteSchemaQuery, NewQuoteSchemaQueryVariables>(NewQuoteSchemaDocument, baseOptions);
      }
export function useNewQuoteSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewQuoteSchemaQuery, NewQuoteSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewQuoteSchemaQuery, NewQuoteSchemaQueryVariables>(NewQuoteSchemaDocument, baseOptions);
        }
export type NewQuoteSchemaQueryHookResult = ReturnType<typeof useNewQuoteSchemaQuery>;
export type NewQuoteSchemaLazyQueryHookResult = ReturnType<typeof useNewQuoteSchemaLazyQuery>;
export type NewQuoteSchemaQueryResult = ApolloReactCommon.QueryResult<NewQuoteSchemaQuery, NewQuoteSchemaQueryVariables>;
export const NewSubmissionSchemaDocument = gql`
    query newSubmissionSchema($panelId: ID!) {
  panels {
    id
    panel(panelId: $panelId) {
      id
      country
      lineOfBusiness
      schemas {
        new(panelistIds: [])
      }
    }
  }
}
    `;
export type NewSubmissionSchemaProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NewSubmissionSchemaQuery, NewSubmissionSchemaQueryVariables>
    } & TChildProps;
export function withNewSubmissionSchema<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewSubmissionSchemaQuery,
  NewSubmissionSchemaQueryVariables,
  NewSubmissionSchemaProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NewSubmissionSchemaQuery, NewSubmissionSchemaQueryVariables, NewSubmissionSchemaProps<TChildProps, TDataName>>(NewSubmissionSchemaDocument, {
      alias: 'newSubmissionSchema',
      ...operationOptions
    });
};

/**
 * __useNewSubmissionSchemaQuery__
 *
 * To run a query within a React component, call `useNewSubmissionSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewSubmissionSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewSubmissionSchemaQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *   },
 * });
 */
export function useNewSubmissionSchemaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<NewSubmissionSchemaQuery, NewSubmissionSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<NewSubmissionSchemaQuery, NewSubmissionSchemaQueryVariables>(NewSubmissionSchemaDocument, baseOptions);
      }
export function useNewSubmissionSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewSubmissionSchemaQuery, NewSubmissionSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewSubmissionSchemaQuery, NewSubmissionSchemaQueryVariables>(NewSubmissionSchemaDocument, baseOptions);
        }
export type NewSubmissionSchemaQueryHookResult = ReturnType<typeof useNewSubmissionSchemaQuery>;
export type NewSubmissionSchemaLazyQueryHookResult = ReturnType<typeof useNewSubmissionSchemaLazyQuery>;
export type NewSubmissionSchemaQueryResult = ApolloReactCommon.QueryResult<NewSubmissionSchemaQuery, NewSubmissionSchemaQueryVariables>;
export const PanelParticipationDocument = gql`
    query panelParticipation($participationId: ID!) {
  panels {
    id
    participation(participationId: $participationId) {
      id
      user {
        id
      }
      submission {
        id
        ... on PanelSubmission {
          panel {
            id
            lineOfBusiness
          }
        }
        ... on CyberSubmission {
          panel {
            id
            lineOfBusiness
            manualSubjectivities
          }
        }
        ... on PCGSubmission {
          panel {
            id
            lineOfBusiness
          }
        }
      }
    }
  }
}
    `;
export type PanelParticipationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PanelParticipationQuery, PanelParticipationQueryVariables>
    } & TChildProps;
export function withPanelParticipation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelParticipationQuery,
  PanelParticipationQueryVariables,
  PanelParticipationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PanelParticipationQuery, PanelParticipationQueryVariables, PanelParticipationProps<TChildProps, TDataName>>(PanelParticipationDocument, {
      alias: 'panelParticipation',
      ...operationOptions
    });
};

/**
 * __usePanelParticipationQuery__
 *
 * To run a query within a React component, call `usePanelParticipationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePanelParticipationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePanelParticipationQuery({
 *   variables: {
 *      participationId: // value for 'participationId'
 *   },
 * });
 */
export function usePanelParticipationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PanelParticipationQuery, PanelParticipationQueryVariables>) {
        return ApolloReactHooks.useQuery<PanelParticipationQuery, PanelParticipationQueryVariables>(PanelParticipationDocument, baseOptions);
      }
export function usePanelParticipationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PanelParticipationQuery, PanelParticipationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PanelParticipationQuery, PanelParticipationQueryVariables>(PanelParticipationDocument, baseOptions);
        }
export type PanelParticipationQueryHookResult = ReturnType<typeof usePanelParticipationQuery>;
export type PanelParticipationLazyQueryHookResult = ReturnType<typeof usePanelParticipationLazyQuery>;
export type PanelParticipationQueryResult = ApolloReactCommon.QueryResult<PanelParticipationQuery, PanelParticipationQueryVariables>;
export const PanelsQuotationDocument = gql`
    query PanelsQuotation($participationId: ID!) {
  panels {
    id
    participation(participationId: $participationId) {
      id
      documents {
        id
        ...Document
      }
      owner {
        id
      }
      user {
        id
        fullName
        company {
          id
          name
          logoUrl
        }
      }
      discussion {
        id
        ...Messages
      }
      submission {
        id
        ...PanelSubmissionInfo
      }
      quotations {
        nodes {
          id
          ...PanelQuotationInfo
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}
${MessagesFragmentDoc}
${PanelSubmissionInfoFragmentDoc}
${PanelQuotationInfoFragmentDoc}`;
export type PanelsQuotationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PanelsQuotationQuery, PanelsQuotationQueryVariables>
    } & TChildProps;
export function withPanelsQuotation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelsQuotationQuery,
  PanelsQuotationQueryVariables,
  PanelsQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PanelsQuotationQuery, PanelsQuotationQueryVariables, PanelsQuotationProps<TChildProps, TDataName>>(PanelsQuotationDocument, {
      alias: 'panelsQuotation',
      ...operationOptions
    });
};

/**
 * __usePanelsQuotationQuery__
 *
 * To run a query within a React component, call `usePanelsQuotationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePanelsQuotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePanelsQuotationQuery({
 *   variables: {
 *      participationId: // value for 'participationId'
 *   },
 * });
 */
export function usePanelsQuotationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PanelsQuotationQuery, PanelsQuotationQueryVariables>) {
        return ApolloReactHooks.useQuery<PanelsQuotationQuery, PanelsQuotationQueryVariables>(PanelsQuotationDocument, baseOptions);
      }
export function usePanelsQuotationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PanelsQuotationQuery, PanelsQuotationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PanelsQuotationQuery, PanelsQuotationQueryVariables>(PanelsQuotationDocument, baseOptions);
        }
export type PanelsQuotationQueryHookResult = ReturnType<typeof usePanelsQuotationQuery>;
export type PanelsQuotationLazyQueryHookResult = ReturnType<typeof usePanelsQuotationLazyQuery>;
export type PanelsQuotationQueryResult = ApolloReactCommon.QueryResult<PanelsQuotationQuery, PanelsQuotationQueryVariables>;
export const PanelSubmissionDocument = gql`
    query PanelSubmission($submissionId: ID!) {
  panels {
    id
    submission(submissionId: $submissionId) {
      id
      ...PanelSubmissionInfo
    }
  }
}
    ${PanelSubmissionInfoFragmentDoc}`;
export type PanelSubmissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PanelSubmissionQuery, PanelSubmissionQueryVariables>
    } & TChildProps;
export function withPanelSubmission<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PanelSubmissionQuery,
  PanelSubmissionQueryVariables,
  PanelSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PanelSubmissionQuery, PanelSubmissionQueryVariables, PanelSubmissionProps<TChildProps, TDataName>>(PanelSubmissionDocument, {
      alias: 'panelSubmission',
      ...operationOptions
    });
};

/**
 * __usePanelSubmissionQuery__
 *
 * To run a query within a React component, call `usePanelSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePanelSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePanelSubmissionQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function usePanelSubmissionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PanelSubmissionQuery, PanelSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<PanelSubmissionQuery, PanelSubmissionQueryVariables>(PanelSubmissionDocument, baseOptions);
      }
export function usePanelSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PanelSubmissionQuery, PanelSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PanelSubmissionQuery, PanelSubmissionQueryVariables>(PanelSubmissionDocument, baseOptions);
        }
export type PanelSubmissionQueryHookResult = ReturnType<typeof usePanelSubmissionQuery>;
export type PanelSubmissionLazyQueryHookResult = ReturnType<typeof usePanelSubmissionLazyQuery>;
export type PanelSubmissionQueryResult = ApolloReactCommon.QueryResult<PanelSubmissionQuery, PanelSubmissionQueryVariables>;
export const EditPcgSubmissionSchemaDocument = gql`
    query editPcgSubmissionSchema($panelId: ID!, $submissionId: ID!) {
  pcg {
    panel(panelId: $panelId) {
      id
      country
      schemas {
        edit(submissionId: $submissionId)
      }
    }
  }
}
    `;
export type EditPcgSubmissionSchemaProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EditPcgSubmissionSchemaQuery, EditPcgSubmissionSchemaQueryVariables>
    } & TChildProps;
export function withEditPcgSubmissionSchema<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditPcgSubmissionSchemaQuery,
  EditPcgSubmissionSchemaQueryVariables,
  EditPcgSubmissionSchemaProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EditPcgSubmissionSchemaQuery, EditPcgSubmissionSchemaQueryVariables, EditPcgSubmissionSchemaProps<TChildProps, TDataName>>(EditPcgSubmissionSchemaDocument, {
      alias: 'editPcgSubmissionSchema',
      ...operationOptions
    });
};

/**
 * __useEditPcgSubmissionSchemaQuery__
 *
 * To run a query within a React component, call `useEditPcgSubmissionSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPcgSubmissionSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPcgSubmissionSchemaQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useEditPcgSubmissionSchemaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EditPcgSubmissionSchemaQuery, EditPcgSubmissionSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<EditPcgSubmissionSchemaQuery, EditPcgSubmissionSchemaQueryVariables>(EditPcgSubmissionSchemaDocument, baseOptions);
      }
export function useEditPcgSubmissionSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditPcgSubmissionSchemaQuery, EditPcgSubmissionSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EditPcgSubmissionSchemaQuery, EditPcgSubmissionSchemaQueryVariables>(EditPcgSubmissionSchemaDocument, baseOptions);
        }
export type EditPcgSubmissionSchemaQueryHookResult = ReturnType<typeof useEditPcgSubmissionSchemaQuery>;
export type EditPcgSubmissionSchemaLazyQueryHookResult = ReturnType<typeof useEditPcgSubmissionSchemaLazyQuery>;
export type EditPcgSubmissionSchemaQueryResult = ApolloReactCommon.QueryResult<EditPcgSubmissionSchemaQuery, EditPcgSubmissionSchemaQueryVariables>;
export const EditPcgQuoteSchemaDocument = gql`
    query editPcgQuoteSchema($panelId: ID!, $quoteId: ID!) {
  pcg {
    panel(panelId: $panelId) {
      id
      country
      schemas {
        editQuote(quoteId: $quoteId)
      }
    }
  }
}
    `;
export type EditPcgQuoteSchemaProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EditPcgQuoteSchemaQuery, EditPcgQuoteSchemaQueryVariables>
    } & TChildProps;
export function withEditPcgQuoteSchema<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditPcgQuoteSchemaQuery,
  EditPcgQuoteSchemaQueryVariables,
  EditPcgQuoteSchemaProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EditPcgQuoteSchemaQuery, EditPcgQuoteSchemaQueryVariables, EditPcgQuoteSchemaProps<TChildProps, TDataName>>(EditPcgQuoteSchemaDocument, {
      alias: 'editPcgQuoteSchema',
      ...operationOptions
    });
};

/**
 * __useEditPcgQuoteSchemaQuery__
 *
 * To run a query within a React component, call `useEditPcgQuoteSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPcgQuoteSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPcgQuoteSchemaQuery({
 *   variables: {
 *      panelId: // value for 'panelId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useEditPcgQuoteSchemaQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EditPcgQuoteSchemaQuery, EditPcgQuoteSchemaQueryVariables>) {
        return ApolloReactHooks.useQuery<EditPcgQuoteSchemaQuery, EditPcgQuoteSchemaQueryVariables>(EditPcgQuoteSchemaDocument, baseOptions);
      }
export function useEditPcgQuoteSchemaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditPcgQuoteSchemaQuery, EditPcgQuoteSchemaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EditPcgQuoteSchemaQuery, EditPcgQuoteSchemaQueryVariables>(EditPcgQuoteSchemaDocument, baseOptions);
        }
export type EditPcgQuoteSchemaQueryHookResult = ReturnType<typeof useEditPcgQuoteSchemaQuery>;
export type EditPcgQuoteSchemaLazyQueryHookResult = ReturnType<typeof useEditPcgQuoteSchemaLazyQuery>;
export type EditPcgQuoteSchemaQueryResult = ApolloReactCommon.QueryResult<EditPcgQuoteSchemaQuery, EditPcgQuoteSchemaQueryVariables>;
export const PcgExtractDocumentDocument = gql`
    mutation PCGExtractDocument($input: PCGExtractDocumentInput!) {
  PCGExtractDocument(input: $input) {
    submission {
      id
      ...PCGSubmissionInfo
    }
  }
}
    ${PcgSubmissionInfoFragmentDoc}`;
export type PcgExtractDocumentMutationFn = ApolloReactCommon.MutationFunction<PcgExtractDocumentMutation, PcgExtractDocumentMutationVariables>;
export type PcgExtractDocumentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PcgExtractDocumentMutation, PcgExtractDocumentMutationVariables>
    } & TChildProps;
export function withPcgExtractDocument<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgExtractDocumentMutation,
  PcgExtractDocumentMutationVariables,
  PcgExtractDocumentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PcgExtractDocumentMutation, PcgExtractDocumentMutationVariables, PcgExtractDocumentProps<TChildProps, TDataName>>(PcgExtractDocumentDocument, {
      alias: 'pcgExtractDocument',
      ...operationOptions
    });
};

/**
 * __usePcgExtractDocumentMutation__
 *
 * To run a mutation, you first call `usePcgExtractDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePcgExtractDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pcgExtractDocumentMutation, { data, loading, error }] = usePcgExtractDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePcgExtractDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PcgExtractDocumentMutation, PcgExtractDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<PcgExtractDocumentMutation, PcgExtractDocumentMutationVariables>(PcgExtractDocumentDocument, baseOptions);
      }
export type PcgExtractDocumentMutationHookResult = ReturnType<typeof usePcgExtractDocumentMutation>;
export type PcgExtractDocumentMutationResult = ApolloReactCommon.MutationResult<PcgExtractDocumentMutation>;
export type PcgExtractDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<PcgExtractDocumentMutation, PcgExtractDocumentMutationVariables>;
export const GetPcgQuoteDocument = gql`
    query getPCGQuote($id: ID!) {
  pcg {
    quotation(id: $id) {
      id
      currentPolicy
      status
      declinationReason
      carrier {
        id
        name
      }
      panelist {
        id
      }
      review {
        quotes {
          quote {
            text
            icon
          }
          totalPremium
          status
        }
        totalPremium
      }
      customCarrier
      quotedLines {
        nodes {
          id
          type
          ... on PCGValuablesQuotedLineType {
            coverages {
              name
              limit
              blanketLimit
            }
            totalPremium
            additionalFeatures {
              feature
              included
            }
          }
          ... on PCGHomeQuotedLineType {
            coverages {
              coverage
              homeCoverageLimit {
                value
                type
              }
              deductible {
                value
                type
              }
            }
            allOtherPerilDeductible
            enhancements {
              enhancement
              limit {
                value
                type
              }
              deductible {
                value
                type
              }
              premium {
                value
                type
              }
            }
            additionalOptions {
              option
              deductible {
                value
                type
              }
              premium {
                value
                type
              }
            }
            additionalFeatures {
              feature
              included
            }
            totalPremium
          }
          ... on PCGExcessQuotedLineType {
            coverages {
              coverage
              limit
            }
            totalPremium
            limitOptions {
              coverage
              limit
              totalPremium
            }
          }
          ... on PCGAutoQuotedLineType {
            agreedValues {
              vehicle
              value
            }
            combinedLimit
            bodilyInjuryAndPropertyDamage
            bodilyInjuryPerPerson
            bodilyInjuryEachAccident
            propertyDamage
            medicalPayments {
              type
              value
            }
            underinsuredMotorists {
              type
              value
            }
            extendedTowingAndLabor {
              type
              value
            }
            fullGlassCoverage {
              type
              value
            }
            additionalCoverages {
              name
              limit {
                type
                value
              }
            }
            collisionDeductible {
              type
              value
            }
            comprehensiveDeductible {
              type
              value
            }
            additionalFeatures {
              feature
              included
            }
            totalPremium
          }
        }
      }
    }
  }
}
    `;
export type GetPcgQuoteProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetPcgQuoteQuery, GetPcgQuoteQueryVariables>
    } & TChildProps;
export function withGetPcgQuote<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPcgQuoteQuery,
  GetPcgQuoteQueryVariables,
  GetPcgQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetPcgQuoteQuery, GetPcgQuoteQueryVariables, GetPcgQuoteProps<TChildProps, TDataName>>(GetPcgQuoteDocument, {
      alias: 'getPcgQuote',
      ...operationOptions
    });
};

/**
 * __useGetPcgQuoteQuery__
 *
 * To run a query within a React component, call `useGetPcgQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPcgQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPcgQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPcgQuoteQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPcgQuoteQuery, GetPcgQuoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPcgQuoteQuery, GetPcgQuoteQueryVariables>(GetPcgQuoteDocument, baseOptions);
      }
export function useGetPcgQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPcgQuoteQuery, GetPcgQuoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPcgQuoteQuery, GetPcgQuoteQueryVariables>(GetPcgQuoteDocument, baseOptions);
        }
export type GetPcgQuoteQueryHookResult = ReturnType<typeof useGetPcgQuoteQuery>;
export type GetPcgQuoteLazyQueryHookResult = ReturnType<typeof useGetPcgQuoteLazyQuery>;
export type GetPcgQuoteQueryResult = ApolloReactCommon.QueryResult<GetPcgQuoteQuery, GetPcgQuoteQueryVariables>;
export const GetPcgSubmissionDocument = gql`
    query getPCGSubmission($submissionId: ID!) {
  pcg {
    submission(submissionId: $submissionId) {
      id
      schemas
      name
      applicants {
        id
        firstName
        lastName
      }
      policyStartDate
      policyEndDate
      selectedCarriers {
        id
        agentCode
      }
      message
      emailSignOff
      placementTermsAccepted
      documents {
        id
        ...Document
      }
      review {
        applicants
        additionalDrivers
        coverages
      }
      quotations {
        nodes {
          id
          selected
          totalPremium
          carrier {
            id
            name
          }
        }
      }
      coverages {
        id
        type
        ... on PCGHomeCoverageType {
          address {
            id
            streetLine1
            streetLine2
            city
            zipCode
            state
            country
          }
        }
        ... on PCGAutoCoverageType {
          vehicles {
            year
            make
            model
            value
          }
          additionalDrivers {
            firstName
            lastName
            relationship
          }
          drivers
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}`;
export type GetPcgSubmissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetPcgSubmissionQuery, GetPcgSubmissionQueryVariables>
    } & TChildProps;
export function withGetPcgSubmission<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetPcgSubmissionQuery,
  GetPcgSubmissionQueryVariables,
  GetPcgSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetPcgSubmissionQuery, GetPcgSubmissionQueryVariables, GetPcgSubmissionProps<TChildProps, TDataName>>(GetPcgSubmissionDocument, {
      alias: 'getPcgSubmission',
      ...operationOptions
    });
};

/**
 * __useGetPcgSubmissionQuery__
 *
 * To run a query within a React component, call `useGetPcgSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPcgSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPcgSubmissionQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useGetPcgSubmissionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPcgSubmissionQuery, GetPcgSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPcgSubmissionQuery, GetPcgSubmissionQueryVariables>(GetPcgSubmissionDocument, baseOptions);
      }
export function useGetPcgSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPcgSubmissionQuery, GetPcgSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPcgSubmissionQuery, GetPcgSubmissionQueryVariables>(GetPcgSubmissionDocument, baseOptions);
        }
export type GetPcgSubmissionQueryHookResult = ReturnType<typeof useGetPcgSubmissionQuery>;
export type GetPcgSubmissionLazyQueryHookResult = ReturnType<typeof useGetPcgSubmissionLazyQuery>;
export type GetPcgSubmissionQueryResult = ApolloReactCommon.QueryResult<GetPcgSubmissionQuery, GetPcgSubmissionQueryVariables>;
export const PcgSubmissionDocument = gql`
    query PCGSubmission($submissionId: ID!) {
  pcg {
    submission(submissionId: $submissionId) {
      id
      ...PCGSubmissionInfo
    }
  }
}
    ${PcgSubmissionInfoFragmentDoc}`;
export type PcgSubmissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PcgSubmissionQuery, PcgSubmissionQueryVariables>
    } & TChildProps;
export function withPcgSubmission<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PcgSubmissionQuery,
  PcgSubmissionQueryVariables,
  PcgSubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PcgSubmissionQuery, PcgSubmissionQueryVariables, PcgSubmissionProps<TChildProps, TDataName>>(PcgSubmissionDocument, {
      alias: 'pcgSubmission',
      ...operationOptions
    });
};

/**
 * __usePcgSubmissionQuery__
 *
 * To run a query within a React component, call `usePcgSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePcgSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePcgSubmissionQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function usePcgSubmissionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PcgSubmissionQuery, PcgSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<PcgSubmissionQuery, PcgSubmissionQueryVariables>(PcgSubmissionDocument, baseOptions);
      }
export function usePcgSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PcgSubmissionQuery, PcgSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PcgSubmissionQuery, PcgSubmissionQueryVariables>(PcgSubmissionDocument, baseOptions);
        }
export type PcgSubmissionQueryHookResult = ReturnType<typeof usePcgSubmissionQuery>;
export type PcgSubmissionLazyQueryHookResult = ReturnType<typeof usePcgSubmissionLazyQuery>;
export type PcgSubmissionQueryResult = ApolloReactCommon.QueryResult<PcgSubmissionQuery, PcgSubmissionQueryVariables>;
export const PermissionsDocument = gql`
    query Permissions {
  viewer {
    user {
      id
      permissions {
        nodes {
          id
          area
          action
          metadata
        }
      }
    }
  }
}
    `;
export type PermissionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PermissionsQuery, PermissionsQueryVariables>
    } & TChildProps;
export function withPermissions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PermissionsQuery,
  PermissionsQueryVariables,
  PermissionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PermissionsQuery, PermissionsQueryVariables, PermissionsProps<TChildProps, TDataName>>(PermissionsDocument, {
      alias: 'permissions',
      ...operationOptions
    });
};

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, baseOptions);
      }
export function usePermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, baseOptions);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = ApolloReactCommon.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const ProvidePolicyFormPrefillQueryDocument = gql`
    query ProvidePolicyFormPrefillQuery($worksheetId: ID!) {
  viewer {
    user {
      id
      ... on Cedent {
        worksheet(worksheetId: $worksheetId) {
          id
          insurancePolicy {
            id
            number
          }
        }
      }
      ... on Broker {
        worksheet(worksheetId: $worksheetId) {
          id
          insurancePolicy {
            id
            number
          }
        }
      }
    }
  }
}
    `;
export type ProvidePolicyFormPrefillQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProvidePolicyFormPrefillQueryQuery, ProvidePolicyFormPrefillQueryQueryVariables>
    } & TChildProps;
export function withProvidePolicyFormPrefillQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProvidePolicyFormPrefillQueryQuery,
  ProvidePolicyFormPrefillQueryQueryVariables,
  ProvidePolicyFormPrefillQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProvidePolicyFormPrefillQueryQuery, ProvidePolicyFormPrefillQueryQueryVariables, ProvidePolicyFormPrefillQueryProps<TChildProps, TDataName>>(ProvidePolicyFormPrefillQueryDocument, {
      alias: 'providePolicyFormPrefillQuery',
      ...operationOptions
    });
};

/**
 * __useProvidePolicyFormPrefillQueryQuery__
 *
 * To run a query within a React component, call `useProvidePolicyFormPrefillQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvidePolicyFormPrefillQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvidePolicyFormPrefillQueryQuery({
 *   variables: {
 *      worksheetId: // value for 'worksheetId'
 *   },
 * });
 */
export function useProvidePolicyFormPrefillQueryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ProvidePolicyFormPrefillQueryQuery, ProvidePolicyFormPrefillQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<ProvidePolicyFormPrefillQueryQuery, ProvidePolicyFormPrefillQueryQueryVariables>(ProvidePolicyFormPrefillQueryDocument, baseOptions);
      }
export function useProvidePolicyFormPrefillQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProvidePolicyFormPrefillQueryQuery, ProvidePolicyFormPrefillQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProvidePolicyFormPrefillQueryQuery, ProvidePolicyFormPrefillQueryQueryVariables>(ProvidePolicyFormPrefillQueryDocument, baseOptions);
        }
export type ProvidePolicyFormPrefillQueryQueryHookResult = ReturnType<typeof useProvidePolicyFormPrefillQueryQuery>;
export type ProvidePolicyFormPrefillQueryLazyQueryHookResult = ReturnType<typeof useProvidePolicyFormPrefillQueryLazyQuery>;
export type ProvidePolicyFormPrefillQueryQueryResult = ApolloReactCommon.QueryResult<ProvidePolicyFormPrefillQueryQuery, ProvidePolicyFormPrefillQueryQueryVariables>;
export const QuestionsDocument = gql`
    query Questions($questionableId: ID!, $questionableType: QuestionableType!) {
  qAndA {
    questionable(questionableId: $questionableId, questionableType: $questionableType) {
      id
      __typename
      questions {
        nodes {
          id
          ...QuestionInfo
        }
      }
    }
  }
}
    ${QuestionInfoFragmentDoc}`;
export type QuestionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuestionsQuery, QuestionsQueryVariables>
    } & TChildProps;
export function withQuestions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuestionsQuery,
  QuestionsQueryVariables,
  QuestionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuestionsQuery, QuestionsQueryVariables, QuestionsProps<TChildProps, TDataName>>(QuestionsDocument, {
      alias: 'questions',
      ...operationOptions
    });
};

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      questionableId: // value for 'questionableId'
 *      questionableType: // value for 'questionableType'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, baseOptions);
      }
export function useQuestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, baseOptions);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = ApolloReactCommon.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const QuotationCompanyDocument = gql`
    query QuotationCompany($participationId: ID!) {
  viewer {
    user {
      id
      ... on Reinsurer {
        participation(participationId: $participationId) {
          id
          submission {
            id
            owner {
              id
              company {
                id
                name
                defaultBrand
                brandColor
                brandName
                logoUrl
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type QuotationCompanyProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuotationCompanyQuery, QuotationCompanyQueryVariables>
    } & TChildProps;
export function withQuotationCompany<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuotationCompanyQuery,
  QuotationCompanyQueryVariables,
  QuotationCompanyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuotationCompanyQuery, QuotationCompanyQueryVariables, QuotationCompanyProps<TChildProps, TDataName>>(QuotationCompanyDocument, {
      alias: 'quotationCompany',
      ...operationOptions
    });
};

/**
 * __useQuotationCompanyQuery__
 *
 * To run a query within a React component, call `useQuotationCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotationCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotationCompanyQuery({
 *   variables: {
 *      participationId: // value for 'participationId'
 *   },
 * });
 */
export function useQuotationCompanyQuery(baseOptions: ApolloReactHooks.QueryHookOptions<QuotationCompanyQuery, QuotationCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<QuotationCompanyQuery, QuotationCompanyQueryVariables>(QuotationCompanyDocument, baseOptions);
      }
export function useQuotationCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuotationCompanyQuery, QuotationCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuotationCompanyQuery, QuotationCompanyQueryVariables>(QuotationCompanyDocument, baseOptions);
        }
export type QuotationCompanyQueryHookResult = ReturnType<typeof useQuotationCompanyQuery>;
export type QuotationCompanyLazyQueryHookResult = ReturnType<typeof useQuotationCompanyLazyQuery>;
export type QuotationCompanyQueryResult = ApolloReactCommon.QueryResult<QuotationCompanyQuery, QuotationCompanyQueryVariables>;
export const QuoteCertificateNumberDocument = gql`
    query QuoteCertificateNumber($quoteId: ID!) {
  viewer {
    user {
      id
      ... on Reinsurer {
        quote(quoteId: $quoteId) {
          id
          certificateNumber
        }
      }
      ... on Broker {
        quote(quoteId: $quoteId) {
          id
          certificateNumber
          certificateDraft {
            id
            ...Document
          }
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}`;
export type QuoteCertificateNumberProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuoteCertificateNumberQuery, QuoteCertificateNumberQueryVariables>
    } & TChildProps;
export function withQuoteCertificateNumber<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuoteCertificateNumberQuery,
  QuoteCertificateNumberQueryVariables,
  QuoteCertificateNumberProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuoteCertificateNumberQuery, QuoteCertificateNumberQueryVariables, QuoteCertificateNumberProps<TChildProps, TDataName>>(QuoteCertificateNumberDocument, {
      alias: 'quoteCertificateNumber',
      ...operationOptions
    });
};

/**
 * __useQuoteCertificateNumberQuery__
 *
 * To run a query within a React component, call `useQuoteCertificateNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteCertificateNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteCertificateNumberQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useQuoteCertificateNumberQuery(baseOptions: ApolloReactHooks.QueryHookOptions<QuoteCertificateNumberQuery, QuoteCertificateNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<QuoteCertificateNumberQuery, QuoteCertificateNumberQueryVariables>(QuoteCertificateNumberDocument, baseOptions);
      }
export function useQuoteCertificateNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuoteCertificateNumberQuery, QuoteCertificateNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuoteCertificateNumberQuery, QuoteCertificateNumberQueryVariables>(QuoteCertificateNumberDocument, baseOptions);
        }
export type QuoteCertificateNumberQueryHookResult = ReturnType<typeof useQuoteCertificateNumberQuery>;
export type QuoteCertificateNumberLazyQueryHookResult = ReturnType<typeof useQuoteCertificateNumberLazyQuery>;
export type QuoteCertificateNumberQueryResult = ApolloReactCommon.QueryResult<QuoteCertificateNumberQuery, QuoteCertificateNumberQueryVariables>;
export const QuoteCompanyDocument = gql`
    query QuoteCompany($quoteId: ID!) {
  viewer {
    user {
      id
      ... on Reinsurer {
        quote(quoteId: $quoteId) {
          id
          worksheet {
            id
            kind
            owner {
              id
              company {
                id
                name
                defaultBrand
                brandColor
                brandName
                logoUrl
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type QuoteCompanyProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuoteCompanyQuery, QuoteCompanyQueryVariables>
    } & TChildProps;
export function withQuoteCompany<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuoteCompanyQuery,
  QuoteCompanyQueryVariables,
  QuoteCompanyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuoteCompanyQuery, QuoteCompanyQueryVariables, QuoteCompanyProps<TChildProps, TDataName>>(QuoteCompanyDocument, {
      alias: 'quoteCompany',
      ...operationOptions
    });
};

/**
 * __useQuoteCompanyQuery__
 *
 * To run a query within a React component, call `useQuoteCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteCompanyQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useQuoteCompanyQuery(baseOptions: ApolloReactHooks.QueryHookOptions<QuoteCompanyQuery, QuoteCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<QuoteCompanyQuery, QuoteCompanyQueryVariables>(QuoteCompanyDocument, baseOptions);
      }
export function useQuoteCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuoteCompanyQuery, QuoteCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuoteCompanyQuery, QuoteCompanyQueryVariables>(QuoteCompanyDocument, baseOptions);
        }
export type QuoteCompanyQueryHookResult = ReturnType<typeof useQuoteCompanyQuery>;
export type QuoteCompanyLazyQueryHookResult = ReturnType<typeof useQuoteCompanyLazyQuery>;
export type QuoteCompanyQueryResult = ApolloReactCommon.QueryResult<QuoteCompanyQuery, QuoteCompanyQueryVariables>;
export const PendingTemplateImportDocument = gql`
    query PendingTemplateImport($quoteId: ID!) {
  viewer {
    reinsurer {
      id
      quote(quoteId: $quoteId) {
        id
        ...QuoteInfoFragment
        templateImports(last: 1) {
          nodes {
            id
            completedAt
            error
            template {
              id
              name
            }
          }
        }
      }
    }
  }
}
    ${QuoteInfoFragmentFragmentDoc}`;
export type PendingTemplateImportProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PendingTemplateImportQuery, PendingTemplateImportQueryVariables>
    } & TChildProps;
export function withPendingTemplateImport<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PendingTemplateImportQuery,
  PendingTemplateImportQueryVariables,
  PendingTemplateImportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PendingTemplateImportQuery, PendingTemplateImportQueryVariables, PendingTemplateImportProps<TChildProps, TDataName>>(PendingTemplateImportDocument, {
      alias: 'pendingTemplateImport',
      ...operationOptions
    });
};

/**
 * __usePendingTemplateImportQuery__
 *
 * To run a query within a React component, call `usePendingTemplateImportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingTemplateImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingTemplateImportQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function usePendingTemplateImportQuery(baseOptions: ApolloReactHooks.QueryHookOptions<PendingTemplateImportQuery, PendingTemplateImportQueryVariables>) {
        return ApolloReactHooks.useQuery<PendingTemplateImportQuery, PendingTemplateImportQueryVariables>(PendingTemplateImportDocument, baseOptions);
      }
export function usePendingTemplateImportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PendingTemplateImportQuery, PendingTemplateImportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PendingTemplateImportQuery, PendingTemplateImportQueryVariables>(PendingTemplateImportDocument, baseOptions);
        }
export type PendingTemplateImportQueryHookResult = ReturnType<typeof usePendingTemplateImportQuery>;
export type PendingTemplateImportLazyQueryHookResult = ReturnType<typeof usePendingTemplateImportLazyQuery>;
export type PendingTemplateImportQueryResult = ApolloReactCommon.QueryResult<PendingTemplateImportQuery, PendingTemplateImportQueryVariables>;
export const QuoteImportProgressDocument = gql`
    query QuoteImportProgress($importId: ID!) {
  viewer {
    user {
      id
      templateImport(importId: $importId) {
        id
        error
        completedAt
        importable {
          id
        }
        template {
          id
          name
        }
      }
    }
  }
}
    `;
export type QuoteImportProgressProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuoteImportProgressQuery, QuoteImportProgressQueryVariables>
    } & TChildProps;
export function withQuoteImportProgress<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuoteImportProgressQuery,
  QuoteImportProgressQueryVariables,
  QuoteImportProgressProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuoteImportProgressQuery, QuoteImportProgressQueryVariables, QuoteImportProgressProps<TChildProps, TDataName>>(QuoteImportProgressDocument, {
      alias: 'quoteImportProgress',
      ...operationOptions
    });
};

/**
 * __useQuoteImportProgressQuery__
 *
 * To run a query within a React component, call `useQuoteImportProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteImportProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteImportProgressQuery({
 *   variables: {
 *      importId: // value for 'importId'
 *   },
 * });
 */
export function useQuoteImportProgressQuery(baseOptions: ApolloReactHooks.QueryHookOptions<QuoteImportProgressQuery, QuoteImportProgressQueryVariables>) {
        return ApolloReactHooks.useQuery<QuoteImportProgressQuery, QuoteImportProgressQueryVariables>(QuoteImportProgressDocument, baseOptions);
      }
export function useQuoteImportProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuoteImportProgressQuery, QuoteImportProgressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuoteImportProgressQuery, QuoteImportProgressQueryVariables>(QuoteImportProgressDocument, baseOptions);
        }
export type QuoteImportProgressQueryHookResult = ReturnType<typeof useQuoteImportProgressQuery>;
export type QuoteImportProgressLazyQueryHookResult = ReturnType<typeof useQuoteImportProgressLazyQuery>;
export type QuoteImportProgressQueryResult = ApolloReactCommon.QueryResult<QuoteImportProgressQuery, QuoteImportProgressQueryVariables>;
export const QuoteInfoDocument = gql`
    query QuoteInfo($quoteId: ID!) {
  viewer {
    user {
      id
      ... on Reinsurer {
        quote(quoteId: $quoteId) {
          id
          ...QuoteInfoFragment
          endorsementQuotes {
            id
            status
            additionalPremium
            returnPremium
            schemas {
              sentEndorsementQuote
              requestedEndorsementQuote
            }
            documents {
              nodes {
                id
                ...Document
              }
            }
            endorsement {
              id
              ...EndorsementInfo
            }
          }
          worksheet {
            id
            updatedAt
            currency
            multiTower
            canEndorse
            insurancePolicy {
              id
              number
            }
            owner {
              id
              fullName
              features
              company {
                id
                name
                defaultBrand
                brandColor
                brandName
                logoUrl
              }
            }
          }
        }
      }
      ... on Cedent {
        quote(quoteId: $quoteId) {
          id
          ...QuoteInfoFragment
          worksheet {
            id
            ...WorksheetInfo
          }
        }
      }
      ... on Broker {
        quote(quoteId: $quoteId) {
          id
          ...QuoteInfoFragment
          worksheet {
            id
            ...WorksheetInfo
          }
        }
      }
    }
  }
}
    ${QuoteInfoFragmentFragmentDoc}
${DocumentFragmentDoc}
${EndorsementInfoFragmentDoc}
${WorksheetInfoFragmentDoc}`;
export type QuoteInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuoteInfoQuery, QuoteInfoQueryVariables>
    } & TChildProps;
export function withQuoteInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuoteInfoQuery,
  QuoteInfoQueryVariables,
  QuoteInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuoteInfoQuery, QuoteInfoQueryVariables, QuoteInfoProps<TChildProps, TDataName>>(QuoteInfoDocument, {
      alias: 'quoteInfo',
      ...operationOptions
    });
};

/**
 * __useQuoteInfoQuery__
 *
 * To run a query within a React component, call `useQuoteInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteInfoQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useQuoteInfoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<QuoteInfoQuery, QuoteInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<QuoteInfoQuery, QuoteInfoQueryVariables>(QuoteInfoDocument, baseOptions);
      }
export function useQuoteInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuoteInfoQuery, QuoteInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuoteInfoQuery, QuoteInfoQueryVariables>(QuoteInfoDocument, baseOptions);
        }
export type QuoteInfoQueryHookResult = ReturnType<typeof useQuoteInfoQuery>;
export type QuoteInfoLazyQueryHookResult = ReturnType<typeof useQuoteInfoLazyQuery>;
export type QuoteInfoQueryResult = ApolloReactCommon.QueryResult<QuoteInfoQuery, QuoteInfoQueryVariables>;
export const QuoteLockedDocument = gql`
    query QuoteLocked($worksheetId: ID!, $quoteId: ID!) {
  viewer {
    user {
      id
      ... on Cedent {
        worksheet(worksheetId: $worksheetId) {
          id
          quote(quoteId: $quoteId) {
            id
            ...QuoteLocked
          }
        }
      }
      ... on Reinsurer {
        quote(quoteId: $quoteId) {
          id
          ...QuoteLocked
        }
      }
    }
  }
}
    ${QuoteLockedFragmentDoc}`;
export type QuoteLockedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuoteLockedQuery, QuoteLockedQueryVariables>
    } & TChildProps;
export function withQuoteLocked<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuoteLockedQuery,
  QuoteLockedQueryVariables,
  QuoteLockedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuoteLockedQuery, QuoteLockedQueryVariables, QuoteLockedProps<TChildProps, TDataName>>(QuoteLockedDocument, {
      alias: 'quoteLocked',
      ...operationOptions
    });
};

/**
 * __useQuoteLockedQuery__
 *
 * To run a query within a React component, call `useQuoteLockedQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteLockedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteLockedQuery({
 *   variables: {
 *      worksheetId: // value for 'worksheetId'
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useQuoteLockedQuery(baseOptions: ApolloReactHooks.QueryHookOptions<QuoteLockedQuery, QuoteLockedQueryVariables>) {
        return ApolloReactHooks.useQuery<QuoteLockedQuery, QuoteLockedQueryVariables>(QuoteLockedDocument, baseOptions);
      }
export function useQuoteLockedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuoteLockedQuery, QuoteLockedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuoteLockedQuery, QuoteLockedQueryVariables>(QuoteLockedDocument, baseOptions);
        }
export type QuoteLockedQueryHookResult = ReturnType<typeof useQuoteLockedQuery>;
export type QuoteLockedLazyQueryHookResult = ReturnType<typeof useQuoteLockedLazyQuery>;
export type QuoteLockedQueryResult = ApolloReactCommon.QueryResult<QuoteLockedQuery, QuoteLockedQueryVariables>;
export const ReinsuranceCompaniesDocument = gql`
    query ReinsuranceCompanies($search: String) {
  viewer {
    reinsuranceCompanies(search: $search) {
      id
      name
      disabled
    }
  }
}
    `;
export type ReinsuranceCompaniesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ReinsuranceCompaniesQuery, ReinsuranceCompaniesQueryVariables>
    } & TChildProps;
export function withReinsuranceCompanies<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReinsuranceCompaniesQuery,
  ReinsuranceCompaniesQueryVariables,
  ReinsuranceCompaniesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ReinsuranceCompaniesQuery, ReinsuranceCompaniesQueryVariables, ReinsuranceCompaniesProps<TChildProps, TDataName>>(ReinsuranceCompaniesDocument, {
      alias: 'reinsuranceCompanies',
      ...operationOptions
    });
};

/**
 * __useReinsuranceCompaniesQuery__
 *
 * To run a query within a React component, call `useReinsuranceCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReinsuranceCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReinsuranceCompaniesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useReinsuranceCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReinsuranceCompaniesQuery, ReinsuranceCompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<ReinsuranceCompaniesQuery, ReinsuranceCompaniesQueryVariables>(ReinsuranceCompaniesDocument, baseOptions);
      }
export function useReinsuranceCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReinsuranceCompaniesQuery, ReinsuranceCompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReinsuranceCompaniesQuery, ReinsuranceCompaniesQueryVariables>(ReinsuranceCompaniesDocument, baseOptions);
        }
export type ReinsuranceCompaniesQueryHookResult = ReturnType<typeof useReinsuranceCompaniesQuery>;
export type ReinsuranceCompaniesLazyQueryHookResult = ReturnType<typeof useReinsuranceCompaniesLazyQuery>;
export type ReinsuranceCompaniesQueryResult = ApolloReactCommon.QueryResult<ReinsuranceCompaniesQuery, ReinsuranceCompaniesQueryVariables>;
export const RenewalInfoDocument = gql`
    query RenewalInfo($worksheetId: ID!) {
  viewer {
    user {
      id
      company {
        id
        name
      }
      ... on Broker {
        worksheet(worksheetId: $worksheetId) {
          id
          name
          kind
          stages {
            draft
            issued
          }
          insurancePolicy {
            id
            coverages {
              nodes {
                id
                type
                kind
              }
            }
          }
          previousPlacement {
            id
            name
          }
          renewal {
            id
          }
        }
      }
      ... on Cedent {
        worksheet(worksheetId: $worksheetId) {
          id
          name
          kind
          stages {
            draft
            issued
          }
          insurancePolicy {
            id
            coverages {
              nodes {
                id
                type
                kind
              }
            }
          }
          previousPlacement {
            id
            name
          }
          renewal {
            id
          }
        }
      }
    }
  }
}
    `;
export type RenewalInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RenewalInfoQuery, RenewalInfoQueryVariables>
    } & TChildProps;
export function withRenewalInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RenewalInfoQuery,
  RenewalInfoQueryVariables,
  RenewalInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RenewalInfoQuery, RenewalInfoQueryVariables, RenewalInfoProps<TChildProps, TDataName>>(RenewalInfoDocument, {
      alias: 'renewalInfo',
      ...operationOptions
    });
};

/**
 * __useRenewalInfoQuery__
 *
 * To run a query within a React component, call `useRenewalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRenewalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRenewalInfoQuery({
 *   variables: {
 *      worksheetId: // value for 'worksheetId'
 *   },
 * });
 */
export function useRenewalInfoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RenewalInfoQuery, RenewalInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<RenewalInfoQuery, RenewalInfoQueryVariables>(RenewalInfoDocument, baseOptions);
      }
export function useRenewalInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RenewalInfoQuery, RenewalInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RenewalInfoQuery, RenewalInfoQueryVariables>(RenewalInfoDocument, baseOptions);
        }
export type RenewalInfoQueryHookResult = ReturnType<typeof useRenewalInfoQuery>;
export type RenewalInfoLazyQueryHookResult = ReturnType<typeof useRenewalInfoLazyQuery>;
export type RenewalInfoQueryResult = ApolloReactCommon.QueryResult<RenewalInfoQuery, RenewalInfoQueryVariables>;
export const ReportFiltersDocument = gql`
    query ReportFilters {
  viewer {
    lastAmbestImport {
      id
      importedAt
    }
    user {
      id
      ... on Cedent {
        reports {
          lineOfBusinesses
          markets
        }
      }
      ... on Broker {
        reports {
          lineOfBusinesses
          markets
        }
      }
      company {
        id
        name
        users {
          nodes {
            id
            fullName
          }
        }
        authorizedMarkets {
          nodes {
            id
            color
            reinsuranceCompany {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type ReportFiltersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ReportFiltersQuery, ReportFiltersQueryVariables>
    } & TChildProps;
export function withReportFilters<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportFiltersQuery,
  ReportFiltersQueryVariables,
  ReportFiltersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ReportFiltersQuery, ReportFiltersQueryVariables, ReportFiltersProps<TChildProps, TDataName>>(ReportFiltersDocument, {
      alias: 'reportFilters',
      ...operationOptions
    });
};

/**
 * __useReportFiltersQuery__
 *
 * To run a query within a React component, call `useReportFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportFiltersQuery, ReportFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportFiltersQuery, ReportFiltersQueryVariables>(ReportFiltersDocument, baseOptions);
      }
export function useReportFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportFiltersQuery, ReportFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportFiltersQuery, ReportFiltersQueryVariables>(ReportFiltersDocument, baseOptions);
        }
export type ReportFiltersQueryHookResult = ReturnType<typeof useReportFiltersQuery>;
export type ReportFiltersLazyQueryHookResult = ReturnType<typeof useReportFiltersLazyQuery>;
export type ReportFiltersQueryResult = ApolloReactCommon.QueryResult<ReportFiltersQuery, ReportFiltersQueryVariables>;
export const AmBestImportTimeDocument = gql`
    query AMBestImportTime {
  viewer {
    lastAmbestImport {
      id
      importedAt
    }
  }
}
    `;
export type AmBestImportTimeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AmBestImportTimeQuery, AmBestImportTimeQueryVariables>
    } & TChildProps;
export function withAmBestImportTime<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AmBestImportTimeQuery,
  AmBestImportTimeQueryVariables,
  AmBestImportTimeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AmBestImportTimeQuery, AmBestImportTimeQueryVariables, AmBestImportTimeProps<TChildProps, TDataName>>(AmBestImportTimeDocument, {
      alias: 'amBestImportTime',
      ...operationOptions
    });
};

/**
 * __useAmBestImportTimeQuery__
 *
 * To run a query within a React component, call `useAmBestImportTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmBestImportTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmBestImportTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useAmBestImportTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AmBestImportTimeQuery, AmBestImportTimeQueryVariables>) {
        return ApolloReactHooks.useQuery<AmBestImportTimeQuery, AmBestImportTimeQueryVariables>(AmBestImportTimeDocument, baseOptions);
      }
export function useAmBestImportTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AmBestImportTimeQuery, AmBestImportTimeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AmBestImportTimeQuery, AmBestImportTimeQueryVariables>(AmBestImportTimeDocument, baseOptions);
        }
export type AmBestImportTimeQueryHookResult = ReturnType<typeof useAmBestImportTimeQuery>;
export type AmBestImportTimeLazyQueryHookResult = ReturnType<typeof useAmBestImportTimeLazyQuery>;
export type AmBestImportTimeQueryResult = ApolloReactCommon.QueryResult<AmBestImportTimeQuery, AmBestImportTimeQueryVariables>;
export const AverageBoundTimeDocument = gql`
    query AverageBoundTime($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          averageBoundTime(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          averageBoundTime(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type AverageBoundTimeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AverageBoundTimeQuery, AverageBoundTimeQueryVariables>
    } & TChildProps;
export function withAverageBoundTime<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AverageBoundTimeQuery,
  AverageBoundTimeQueryVariables,
  AverageBoundTimeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AverageBoundTimeQuery, AverageBoundTimeQueryVariables, AverageBoundTimeProps<TChildProps, TDataName>>(AverageBoundTimeDocument, {
      alias: 'averageBoundTime',
      ...operationOptions
    });
};

/**
 * __useAverageBoundTimeQuery__
 *
 * To run a query within a React component, call `useAverageBoundTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageBoundTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageBoundTimeQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useAverageBoundTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AverageBoundTimeQuery, AverageBoundTimeQueryVariables>) {
        return ApolloReactHooks.useQuery<AverageBoundTimeQuery, AverageBoundTimeQueryVariables>(AverageBoundTimeDocument, baseOptions);
      }
export function useAverageBoundTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AverageBoundTimeQuery, AverageBoundTimeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AverageBoundTimeQuery, AverageBoundTimeQueryVariables>(AverageBoundTimeDocument, baseOptions);
        }
export type AverageBoundTimeQueryHookResult = ReturnType<typeof useAverageBoundTimeQuery>;
export type AverageBoundTimeLazyQueryHookResult = ReturnType<typeof useAverageBoundTimeLazyQuery>;
export type AverageBoundTimeQueryResult = ApolloReactCommon.QueryResult<AverageBoundTimeQuery, AverageBoundTimeQueryVariables>;
export const AverageReplyTimeDocument = gql`
    query AverageReplyTime($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          averageReplyTime(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          averageReplyTime(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type AverageReplyTimeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AverageReplyTimeQuery, AverageReplyTimeQueryVariables>
    } & TChildProps;
export function withAverageReplyTime<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AverageReplyTimeQuery,
  AverageReplyTimeQueryVariables,
  AverageReplyTimeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AverageReplyTimeQuery, AverageReplyTimeQueryVariables, AverageReplyTimeProps<TChildProps, TDataName>>(AverageReplyTimeDocument, {
      alias: 'averageReplyTime',
      ...operationOptions
    });
};

/**
 * __useAverageReplyTimeQuery__
 *
 * To run a query within a React component, call `useAverageReplyTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAverageReplyTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAverageReplyTimeQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useAverageReplyTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AverageReplyTimeQuery, AverageReplyTimeQueryVariables>) {
        return ApolloReactHooks.useQuery<AverageReplyTimeQuery, AverageReplyTimeQueryVariables>(AverageReplyTimeDocument, baseOptions);
      }
export function useAverageReplyTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AverageReplyTimeQuery, AverageReplyTimeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AverageReplyTimeQuery, AverageReplyTimeQueryVariables>(AverageReplyTimeDocument, baseOptions);
        }
export type AverageReplyTimeQueryHookResult = ReturnType<typeof useAverageReplyTimeQuery>;
export type AverageReplyTimeLazyQueryHookResult = ReturnType<typeof useAverageReplyTimeLazyQuery>;
export type AverageReplyTimeQueryResult = ApolloReactCommon.QueryResult<AverageReplyTimeQuery, AverageReplyTimeQueryVariables>;
export const BoundCoverageDocument = gql`
    query BoundCoverage($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          boundCoverage(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          boundCoverage(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type BoundCoverageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BoundCoverageQuery, BoundCoverageQueryVariables>
    } & TChildProps;
export function withBoundCoverage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BoundCoverageQuery,
  BoundCoverageQueryVariables,
  BoundCoverageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BoundCoverageQuery, BoundCoverageQueryVariables, BoundCoverageProps<TChildProps, TDataName>>(BoundCoverageDocument, {
      alias: 'boundCoverage',
      ...operationOptions
    });
};

/**
 * __useBoundCoverageQuery__
 *
 * To run a query within a React component, call `useBoundCoverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoundCoverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoundCoverageQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useBoundCoverageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BoundCoverageQuery, BoundCoverageQueryVariables>) {
        return ApolloReactHooks.useQuery<BoundCoverageQuery, BoundCoverageQueryVariables>(BoundCoverageDocument, baseOptions);
      }
export function useBoundCoverageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BoundCoverageQuery, BoundCoverageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BoundCoverageQuery, BoundCoverageQueryVariables>(BoundCoverageDocument, baseOptions);
        }
export type BoundCoverageQueryHookResult = ReturnType<typeof useBoundCoverageQuery>;
export type BoundCoverageLazyQueryHookResult = ReturnType<typeof useBoundCoverageLazyQuery>;
export type BoundCoverageQueryResult = ApolloReactCommon.QueryResult<BoundCoverageQuery, BoundCoverageQueryVariables>;
export const BoundCoverageByRatingDocument = gql`
    query BoundCoverageByRating($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          boundCoverageByRating(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          boundCoverageByRating(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type BoundCoverageByRatingProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BoundCoverageByRatingQuery, BoundCoverageByRatingQueryVariables>
    } & TChildProps;
export function withBoundCoverageByRating<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BoundCoverageByRatingQuery,
  BoundCoverageByRatingQueryVariables,
  BoundCoverageByRatingProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BoundCoverageByRatingQuery, BoundCoverageByRatingQueryVariables, BoundCoverageByRatingProps<TChildProps, TDataName>>(BoundCoverageByRatingDocument, {
      alias: 'boundCoverageByRating',
      ...operationOptions
    });
};

/**
 * __useBoundCoverageByRatingQuery__
 *
 * To run a query within a React component, call `useBoundCoverageByRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoundCoverageByRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoundCoverageByRatingQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useBoundCoverageByRatingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BoundCoverageByRatingQuery, BoundCoverageByRatingQueryVariables>) {
        return ApolloReactHooks.useQuery<BoundCoverageByRatingQuery, BoundCoverageByRatingQueryVariables>(BoundCoverageByRatingDocument, baseOptions);
      }
export function useBoundCoverageByRatingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BoundCoverageByRatingQuery, BoundCoverageByRatingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BoundCoverageByRatingQuery, BoundCoverageByRatingQueryVariables>(BoundCoverageByRatingDocument, baseOptions);
        }
export type BoundCoverageByRatingQueryHookResult = ReturnType<typeof useBoundCoverageByRatingQuery>;
export type BoundCoverageByRatingLazyQueryHookResult = ReturnType<typeof useBoundCoverageByRatingLazyQuery>;
export type BoundCoverageByRatingQueryResult = ApolloReactCommon.QueryResult<BoundCoverageByRatingQuery, BoundCoverageByRatingQueryVariables>;
export const SubmitToQuoteDocument = gql`
    query SubmitToQuote($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          submitToQuote(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          submitToQuote(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type SubmitToQuoteProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SubmitToQuoteQuery, SubmitToQuoteQueryVariables>
    } & TChildProps;
export function withSubmitToQuote<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitToQuoteQuery,
  SubmitToQuoteQueryVariables,
  SubmitToQuoteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SubmitToQuoteQuery, SubmitToQuoteQueryVariables, SubmitToQuoteProps<TChildProps, TDataName>>(SubmitToQuoteDocument, {
      alias: 'submitToQuote',
      ...operationOptions
    });
};

/**
 * __useSubmitToQuoteQuery__
 *
 * To run a query within a React component, call `useSubmitToQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmitToQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmitToQuoteQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useSubmitToQuoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubmitToQuoteQuery, SubmitToQuoteQueryVariables>) {
        return ApolloReactHooks.useQuery<SubmitToQuoteQuery, SubmitToQuoteQueryVariables>(SubmitToQuoteDocument, baseOptions);
      }
export function useSubmitToQuoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmitToQuoteQuery, SubmitToQuoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubmitToQuoteQuery, SubmitToQuoteQueryVariables>(SubmitToQuoteDocument, baseOptions);
        }
export type SubmitToQuoteQueryHookResult = ReturnType<typeof useSubmitToQuoteQuery>;
export type SubmitToQuoteLazyQueryHookResult = ReturnType<typeof useSubmitToQuoteLazyQuery>;
export type SubmitToQuoteQueryResult = ApolloReactCommon.QueryResult<SubmitToQuoteQuery, SubmitToQuoteQueryVariables>;
export const ExposureReportDocument = gql`
    query ExposureReport($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          exposureReportData(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              reinsurerCompanyName
              boundCoverage
              totalPremium
              rating
            }
          }
        }
      }
      ... on Broker {
        reports {
          exposureReportData(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              reinsurerCompanyName
              boundCoverage
              rating
              totalPremium
            }
          }
        }
      }
    }
  }
}
    `;
export type ExposureReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ExposureReportQuery, ExposureReportQueryVariables>
    } & TChildProps;
export function withExposureReport<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExposureReportQuery,
  ExposureReportQueryVariables,
  ExposureReportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ExposureReportQuery, ExposureReportQueryVariables, ExposureReportProps<TChildProps, TDataName>>(ExposureReportDocument, {
      alias: 'exposureReport',
      ...operationOptions
    });
};

/**
 * __useExposureReportQuery__
 *
 * To run a query within a React component, call `useExposureReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExposureReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExposureReportQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useExposureReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExposureReportQuery, ExposureReportQueryVariables>) {
        return ApolloReactHooks.useQuery<ExposureReportQuery, ExposureReportQueryVariables>(ExposureReportDocument, baseOptions);
      }
export function useExposureReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExposureReportQuery, ExposureReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExposureReportQuery, ExposureReportQueryVariables>(ExposureReportDocument, baseOptions);
        }
export type ExposureReportQueryHookResult = ReturnType<typeof useExposureReportQuery>;
export type ExposureReportLazyQueryHookResult = ReturnType<typeof useExposureReportLazyQuery>;
export type ExposureReportQueryResult = ApolloReactCommon.QueryResult<ExposureReportQuery, ExposureReportQueryVariables>;
export const NetPerMillionDocument = gql`
    query NetPerMillion($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          netPerMillion(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          netPerMillion(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type NetPerMillionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NetPerMillionQuery, NetPerMillionQueryVariables>
    } & TChildProps;
export function withNetPerMillion<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NetPerMillionQuery,
  NetPerMillionQueryVariables,
  NetPerMillionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NetPerMillionQuery, NetPerMillionQueryVariables, NetPerMillionProps<TChildProps, TDataName>>(NetPerMillionDocument, {
      alias: 'netPerMillion',
      ...operationOptions
    });
};

/**
 * __useNetPerMillionQuery__
 *
 * To run a query within a React component, call `useNetPerMillionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetPerMillionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetPerMillionQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useNetPerMillionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NetPerMillionQuery, NetPerMillionQueryVariables>) {
        return ApolloReactHooks.useQuery<NetPerMillionQuery, NetPerMillionQueryVariables>(NetPerMillionDocument, baseOptions);
      }
export function useNetPerMillionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NetPerMillionQuery, NetPerMillionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NetPerMillionQuery, NetPerMillionQueryVariables>(NetPerMillionDocument, baseOptions);
        }
export type NetPerMillionQueryHookResult = ReturnType<typeof useNetPerMillionQuery>;
export type NetPerMillionLazyQueryHookResult = ReturnType<typeof useNetPerMillionLazyQuery>;
export type NetPerMillionQueryResult = ApolloReactCommon.QueryResult<NetPerMillionQuery, NetPerMillionQueryVariables>;
export const PerformanceReportDocument = gql`
    query PerformanceReport($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          performanceReportData(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              reinsurerCompanyName
              averageReplyTime
              averageBoundTime
              submitToBind
              quoteToBind
              submitToQuote
              declination
            }
          }
        }
      }
      ... on Broker {
        reports {
          performanceReportData(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              reinsurerCompanyName
              averageReplyTime
              averageBoundTime
              submitToBind
              quoteToBind
              submitToQuote
              declination
            }
          }
        }
      }
    }
  }
}
    `;
export type PerformanceReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PerformanceReportQuery, PerformanceReportQueryVariables>
    } & TChildProps;
export function withPerformanceReport<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PerformanceReportQuery,
  PerformanceReportQueryVariables,
  PerformanceReportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PerformanceReportQuery, PerformanceReportQueryVariables, PerformanceReportProps<TChildProps, TDataName>>(PerformanceReportDocument, {
      alias: 'performanceReport',
      ...operationOptions
    });
};

/**
 * __usePerformanceReportQuery__
 *
 * To run a query within a React component, call `usePerformanceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceReportQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function usePerformanceReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PerformanceReportQuery, PerformanceReportQueryVariables>) {
        return ApolloReactHooks.useQuery<PerformanceReportQuery, PerformanceReportQueryVariables>(PerformanceReportDocument, baseOptions);
      }
export function usePerformanceReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PerformanceReportQuery, PerformanceReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PerformanceReportQuery, PerformanceReportQueryVariables>(PerformanceReportDocument, baseOptions);
        }
export type PerformanceReportQueryHookResult = ReturnType<typeof usePerformanceReportQuery>;
export type PerformanceReportLazyQueryHookResult = ReturnType<typeof usePerformanceReportLazyQuery>;
export type PerformanceReportQueryResult = ApolloReactCommon.QueryResult<PerformanceReportQuery, PerformanceReportQueryVariables>;
export const QuoteToBindDocument = gql`
    query QuoteToBind($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          quoteToBind(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          quoteToBind(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type QuoteToBindProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QuoteToBindQuery, QuoteToBindQueryVariables>
    } & TChildProps;
export function withQuoteToBind<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QuoteToBindQuery,
  QuoteToBindQueryVariables,
  QuoteToBindProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QuoteToBindQuery, QuoteToBindQueryVariables, QuoteToBindProps<TChildProps, TDataName>>(QuoteToBindDocument, {
      alias: 'quoteToBind',
      ...operationOptions
    });
};

/**
 * __useQuoteToBindQuery__
 *
 * To run a query within a React component, call `useQuoteToBindQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteToBindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteToBindQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useQuoteToBindQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QuoteToBindQuery, QuoteToBindQueryVariables>) {
        return ApolloReactHooks.useQuery<QuoteToBindQuery, QuoteToBindQueryVariables>(QuoteToBindDocument, baseOptions);
      }
export function useQuoteToBindLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuoteToBindQuery, QuoteToBindQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QuoteToBindQuery, QuoteToBindQueryVariables>(QuoteToBindDocument, baseOptions);
        }
export type QuoteToBindQueryHookResult = ReturnType<typeof useQuoteToBindQuery>;
export type QuoteToBindLazyQueryHookResult = ReturnType<typeof useQuoteToBindLazyQuery>;
export type QuoteToBindQueryResult = ApolloReactCommon.QueryResult<QuoteToBindQuery, QuoteToBindQueryVariables>;
export const SubmitToBindDocument = gql`
    query SubmitToBind($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          submitToBind(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          submitToBind(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type SubmitToBindProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SubmitToBindQuery, SubmitToBindQueryVariables>
    } & TChildProps;
export function withSubmitToBind<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitToBindQuery,
  SubmitToBindQueryVariables,
  SubmitToBindProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SubmitToBindQuery, SubmitToBindQueryVariables, SubmitToBindProps<TChildProps, TDataName>>(SubmitToBindDocument, {
      alias: 'submitToBind',
      ...operationOptions
    });
};

/**
 * __useSubmitToBindQuery__
 *
 * To run a query within a React component, call `useSubmitToBindQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmitToBindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmitToBindQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useSubmitToBindQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubmitToBindQuery, SubmitToBindQueryVariables>) {
        return ApolloReactHooks.useQuery<SubmitToBindQuery, SubmitToBindQueryVariables>(SubmitToBindDocument, baseOptions);
      }
export function useSubmitToBindLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmitToBindQuery, SubmitToBindQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubmitToBindQuery, SubmitToBindQueryVariables>(SubmitToBindDocument, baseOptions);
        }
export type SubmitToBindQueryHookResult = ReturnType<typeof useSubmitToBindQuery>;
export type SubmitToBindLazyQueryHookResult = ReturnType<typeof useSubmitToBindLazyQuery>;
export type SubmitToBindQueryResult = ApolloReactCommon.QueryResult<SubmitToBindQuery, SubmitToBindQueryVariables>;
export const DeclinationDocument = gql`
    query Declination($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          declination(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          declination(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type DeclinationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DeclinationQuery, DeclinationQueryVariables>
    } & TChildProps;
export function withDeclination<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeclinationQuery,
  DeclinationQueryVariables,
  DeclinationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DeclinationQuery, DeclinationQueryVariables, DeclinationProps<TChildProps, TDataName>>(DeclinationDocument, {
      alias: 'declination',
      ...operationOptions
    });
};

/**
 * __useDeclinationQuery__
 *
 * To run a query within a React component, call `useDeclinationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeclinationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeclinationQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useDeclinationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeclinationQuery, DeclinationQueryVariables>) {
        return ApolloReactHooks.useQuery<DeclinationQuery, DeclinationQueryVariables>(DeclinationDocument, baseOptions);
      }
export function useDeclinationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeclinationQuery, DeclinationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeclinationQuery, DeclinationQueryVariables>(DeclinationDocument, baseOptions);
        }
export type DeclinationQueryHookResult = ReturnType<typeof useDeclinationQuery>;
export type DeclinationLazyQueryHookResult = ReturnType<typeof useDeclinationLazyQuery>;
export type DeclinationQueryResult = ApolloReactCommon.QueryResult<DeclinationQuery, DeclinationQueryVariables>;
export const TopMarketsDocument = gql`
    query TopMarkets($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          topMarketReportData(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              reinsurerCompanyName
              netPerMillion
            }
          }
        }
      }
      ... on Broker {
        reports {
          topMarketReportData(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              reinsurerCompanyName
              netPerMillion
            }
          }
        }
      }
    }
  }
}
    `;
export type TopMarketsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TopMarketsQuery, TopMarketsQueryVariables>
    } & TChildProps;
export function withTopMarkets<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TopMarketsQuery,
  TopMarketsQueryVariables,
  TopMarketsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TopMarketsQuery, TopMarketsQueryVariables, TopMarketsProps<TChildProps, TDataName>>(TopMarketsDocument, {
      alias: 'topMarkets',
      ...operationOptions
    });
};

/**
 * __useTopMarketsQuery__
 *
 * To run a query within a React component, call `useTopMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopMarketsQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useTopMarketsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TopMarketsQuery, TopMarketsQueryVariables>) {
        return ApolloReactHooks.useQuery<TopMarketsQuery, TopMarketsQueryVariables>(TopMarketsDocument, baseOptions);
      }
export function useTopMarketsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TopMarketsQuery, TopMarketsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TopMarketsQuery, TopMarketsQueryVariables>(TopMarketsDocument, baseOptions);
        }
export type TopMarketsQueryHookResult = ReturnType<typeof useTopMarketsQuery>;
export type TopMarketsLazyQueryHookResult = ReturnType<typeof useTopMarketsLazyQuery>;
export type TopMarketsQueryResult = ApolloReactCommon.QueryResult<TopMarketsQuery, TopMarketsQueryVariables>;
export const TotalPremiumDocument = gql`
    query TotalPremium($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          totalPremium(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          totalPremium(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type TotalPremiumProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TotalPremiumQuery, TotalPremiumQueryVariables>
    } & TChildProps;
export function withTotalPremium<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TotalPremiumQuery,
  TotalPremiumQueryVariables,
  TotalPremiumProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TotalPremiumQuery, TotalPremiumQueryVariables, TotalPremiumProps<TChildProps, TDataName>>(TotalPremiumDocument, {
      alias: 'totalPremium',
      ...operationOptions
    });
};

/**
 * __useTotalPremiumQuery__
 *
 * To run a query within a React component, call `useTotalPremiumQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalPremiumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalPremiumQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useTotalPremiumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TotalPremiumQuery, TotalPremiumQueryVariables>) {
        return ApolloReactHooks.useQuery<TotalPremiumQuery, TotalPremiumQueryVariables>(TotalPremiumDocument, baseOptions);
      }
export function useTotalPremiumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TotalPremiumQuery, TotalPremiumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TotalPremiumQuery, TotalPremiumQueryVariables>(TotalPremiumDocument, baseOptions);
        }
export type TotalPremiumQueryHookResult = ReturnType<typeof useTotalPremiumQuery>;
export type TotalPremiumLazyQueryHookResult = ReturnType<typeof useTotalPremiumLazyQuery>;
export type TotalPremiumQueryResult = ApolloReactCommon.QueryResult<TotalPremiumQuery, TotalPremiumQueryVariables>;
export const TotalPremiumByRatingDocument = gql`
    query TotalPremiumByRating($dateRange: DateRangeInput, $reinsurerCompanyName: [String!], $ownerId: [String!], $lineOfBusiness: [String!], $clientName: [String!]) {
  viewer {
    user {
      id
      ... on Cedent {
        reports {
          totalPremiumByRating(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
      ... on Broker {
        reports {
          totalPremiumByRating(clientName: $clientName, reinsurerCompanyName: $reinsurerCompanyName, dateRange: $dateRange, ownerId: $ownerId, lineOfBusiness: $lineOfBusiness) {
            nodes {
              key
              value
            }
          }
        }
      }
    }
  }
}
    `;
export type TotalPremiumByRatingProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TotalPremiumByRatingQuery, TotalPremiumByRatingQueryVariables>
    } & TChildProps;
export function withTotalPremiumByRating<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TotalPremiumByRatingQuery,
  TotalPremiumByRatingQueryVariables,
  TotalPremiumByRatingProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TotalPremiumByRatingQuery, TotalPremiumByRatingQueryVariables, TotalPremiumByRatingProps<TChildProps, TDataName>>(TotalPremiumByRatingDocument, {
      alias: 'totalPremiumByRating',
      ...operationOptions
    });
};

/**
 * __useTotalPremiumByRatingQuery__
 *
 * To run a query within a React component, call `useTotalPremiumByRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalPremiumByRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalPremiumByRatingQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *      reinsurerCompanyName: // value for 'reinsurerCompanyName'
 *      ownerId: // value for 'ownerId'
 *      lineOfBusiness: // value for 'lineOfBusiness'
 *      clientName: // value for 'clientName'
 *   },
 * });
 */
export function useTotalPremiumByRatingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TotalPremiumByRatingQuery, TotalPremiumByRatingQueryVariables>) {
        return ApolloReactHooks.useQuery<TotalPremiumByRatingQuery, TotalPremiumByRatingQueryVariables>(TotalPremiumByRatingDocument, baseOptions);
      }
export function useTotalPremiumByRatingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TotalPremiumByRatingQuery, TotalPremiumByRatingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TotalPremiumByRatingQuery, TotalPremiumByRatingQueryVariables>(TotalPremiumByRatingDocument, baseOptions);
        }
export type TotalPremiumByRatingQueryHookResult = ReturnType<typeof useTotalPremiumByRatingQuery>;
export type TotalPremiumByRatingLazyQueryHookResult = ReturnType<typeof useTotalPremiumByRatingLazyQuery>;
export type TotalPremiumByRatingQueryResult = ApolloReactCommon.QueryResult<TotalPremiumByRatingQuery, TotalPremiumByRatingQueryVariables>;
export const ReportsSummaryDocument = gql`
    query ReportsSummary {
  viewer {
    lastAmbestImport {
      id
      importedAt
    }
    user {
      id
      ... on Broker {
        worksheets {
          ...ReportSummaryDetails
        }
        analytics {
          averageReplyRate
          averageResponseTime
          averagePlacementTime
          totalCommission
          totalRequested
          totalQuotes
          totalBinder
          totalCertificate
        }
      }
      ... on Cedent {
        dashboard {
          submissions {
            total
          }
          quotes {
            total
          }
          binders {
            total
          }
          certificates {
            total
          }
        }
        worksheets {
          ...ReportSummaryDetails
        }
        analytics {
          averageReplyRate
          averageResponseTime
          averagePlacementTime
          totalPremiumsPaid
        }
      }
    }
  }
}
    ${ReportSummaryDetailsFragmentDoc}`;
export type ReportsSummaryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ReportsSummaryQuery, ReportsSummaryQueryVariables>
    } & TChildProps;
export function withReportsSummary<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportsSummaryQuery,
  ReportsSummaryQueryVariables,
  ReportsSummaryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ReportsSummaryQuery, ReportsSummaryQueryVariables, ReportsSummaryProps<TChildProps, TDataName>>(ReportsSummaryDocument, {
      alias: 'reportsSummary',
      ...operationOptions
    });
};

/**
 * __useReportsSummaryQuery__
 *
 * To run a query within a React component, call `useReportsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportsSummaryQuery, ReportsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportsSummaryQuery, ReportsSummaryQueryVariables>(ReportsSummaryDocument, baseOptions);
      }
export function useReportsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportsSummaryQuery, ReportsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportsSummaryQuery, ReportsSummaryQueryVariables>(ReportsSummaryDocument, baseOptions);
        }
export type ReportsSummaryQueryHookResult = ReturnType<typeof useReportsSummaryQuery>;
export type ReportsSummaryLazyQueryHookResult = ReturnType<typeof useReportsSummaryLazyQuery>;
export type ReportsSummaryQueryResult = ApolloReactCommon.QueryResult<ReportsSummaryQuery, ReportsSummaryQueryVariables>;
export const RinkAccountDocument = gql`
    query RinkAccount {
  viewer {
    user {
      id
      rinkAccount {
        id
        ...Profile
        networkContacts {
          nodes {
            id
            ...NetworkContact
          }
        }
        networkConnectionInvitesReceived {
          nodes {
            id
            user1 {
              id
              ...NetworkContact
            }
          }
        }
        networkConnectionInvitesSent {
          nodes {
            id
            createdAt
            user2 {
              id
              ...NetworkContact
            }
          }
        }
      }
    }
  }
}
    ${ProfileFragmentDoc}
${NetworkContactFragmentDoc}`;
export type RinkAccountProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RinkAccountQuery, RinkAccountQueryVariables>
    } & TChildProps;
export function withRinkAccount<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RinkAccountQuery,
  RinkAccountQueryVariables,
  RinkAccountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RinkAccountQuery, RinkAccountQueryVariables, RinkAccountProps<TChildProps, TDataName>>(RinkAccountDocument, {
      alias: 'rinkAccount',
      ...operationOptions
    });
};

/**
 * __useRinkAccountQuery__
 *
 * To run a query within a React component, call `useRinkAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRinkAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRinkAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useRinkAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RinkAccountQuery, RinkAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<RinkAccountQuery, RinkAccountQueryVariables>(RinkAccountDocument, baseOptions);
      }
export function useRinkAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RinkAccountQuery, RinkAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RinkAccountQuery, RinkAccountQueryVariables>(RinkAccountDocument, baseOptions);
        }
export type RinkAccountQueryHookResult = ReturnType<typeof useRinkAccountQuery>;
export type RinkAccountLazyQueryHookResult = ReturnType<typeof useRinkAccountLazyQuery>;
export type RinkAccountQueryResult = ApolloReactCommon.QueryResult<RinkAccountQuery, RinkAccountQueryVariables>;
export const RinkProfilesDocument = gql`
    query RinkProfiles($profileIds: [ID!], $email: String) {
  viewer {
    rinkProfiles(profileIds: $profileIds, email: $email) {
      nodes {
        id
        ...Profile
        networkConnectionInvitesSent {
          nodes {
            id
            createdAt
            message
            user1 {
              id
              ...NetworkContact
            }
            user2 {
              id
              ...NetworkContact
            }
          }
        }
      }
    }
  }
}
    ${ProfileFragmentDoc}
${NetworkContactFragmentDoc}`;
export type RinkProfilesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RinkProfilesQuery, RinkProfilesQueryVariables>
    } & TChildProps;
export function withRinkProfiles<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RinkProfilesQuery,
  RinkProfilesQueryVariables,
  RinkProfilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RinkProfilesQuery, RinkProfilesQueryVariables, RinkProfilesProps<TChildProps, TDataName>>(RinkProfilesDocument, {
      alias: 'rinkProfiles',
      ...operationOptions
    });
};

/**
 * __useRinkProfilesQuery__
 *
 * To run a query within a React component, call `useRinkProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRinkProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRinkProfilesQuery({
 *   variables: {
 *      profileIds: // value for 'profileIds'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRinkProfilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RinkProfilesQuery, RinkProfilesQueryVariables>) {
        return ApolloReactHooks.useQuery<RinkProfilesQuery, RinkProfilesQueryVariables>(RinkProfilesDocument, baseOptions);
      }
export function useRinkProfilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RinkProfilesQuery, RinkProfilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RinkProfilesQuery, RinkProfilesQueryVariables>(RinkProfilesDocument, baseOptions);
        }
export type RinkProfilesQueryHookResult = ReturnType<typeof useRinkProfilesQuery>;
export type RinkProfilesLazyQueryHookResult = ReturnType<typeof useRinkProfilesLazyQuery>;
export type RinkProfilesQueryResult = ApolloReactCommon.QueryResult<RinkProfilesQuery, RinkProfilesQueryVariables>;
export const SubjectivitiesDocument = gql`
    query Subjectivities($subjectivitableId: ID!, $subjectivitableType: SubjectivitableType!) {
  qAndA {
    subjectivitable(subjectivitableId: $subjectivitableId, subjectivitableType: $subjectivitableType) {
      id
      __typename
      subjectivities {
        nodes {
          id
          ...SubjectivityInfo
        }
      }
    }
  }
}
    ${SubjectivityInfoFragmentDoc}`;
export type SubjectivitiesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SubjectivitiesQuery, SubjectivitiesQueryVariables>
    } & TChildProps;
export function withSubjectivities<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubjectivitiesQuery,
  SubjectivitiesQueryVariables,
  SubjectivitiesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SubjectivitiesQuery, SubjectivitiesQueryVariables, SubjectivitiesProps<TChildProps, TDataName>>(SubjectivitiesDocument, {
      alias: 'subjectivities',
      ...operationOptions
    });
};

/**
 * __useSubjectivitiesQuery__
 *
 * To run a query within a React component, call `useSubjectivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectivitiesQuery({
 *   variables: {
 *      subjectivitableId: // value for 'subjectivitableId'
 *      subjectivitableType: // value for 'subjectivitableType'
 *   },
 * });
 */
export function useSubjectivitiesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SubjectivitiesQuery, SubjectivitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<SubjectivitiesQuery, SubjectivitiesQueryVariables>(SubjectivitiesDocument, baseOptions);
      }
export function useSubjectivitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubjectivitiesQuery, SubjectivitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubjectivitiesQuery, SubjectivitiesQueryVariables>(SubjectivitiesDocument, baseOptions);
        }
export type SubjectivitiesQueryHookResult = ReturnType<typeof useSubjectivitiesQuery>;
export type SubjectivitiesLazyQueryHookResult = ReturnType<typeof useSubjectivitiesLazyQuery>;
export type SubjectivitiesQueryResult = ApolloReactCommon.QueryResult<SubjectivitiesQuery, SubjectivitiesQueryVariables>;
export const SubmissionDocument = gql`
    query Submission($worksheetId: ID!) {
  viewer {
    user {
      id
      ... on Cedent {
        worksheet(worksheetId: $worksheetId) {
          id
          ...SubmissionInfo
        }
      }
      ... on Broker {
        worksheet(worksheetId: $worksheetId) {
          id
          ...SubmissionInfo
        }
      }
    }
  }
}
    ${SubmissionInfoFragmentDoc}`;
export type SubmissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SubmissionQuery, SubmissionQueryVariables>
    } & TChildProps;
export function withSubmission<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmissionQuery,
  SubmissionQueryVariables,
  SubmissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SubmissionQuery, SubmissionQueryVariables, SubmissionProps<TChildProps, TDataName>>(SubmissionDocument, {
      alias: 'submission',
      ...operationOptions
    });
};

/**
 * __useSubmissionQuery__
 *
 * To run a query within a React component, call `useSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionQuery({
 *   variables: {
 *      worksheetId: // value for 'worksheetId'
 *   },
 * });
 */
export function useSubmissionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SubmissionQuery, SubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<SubmissionQuery, SubmissionQueryVariables>(SubmissionDocument, baseOptions);
      }
export function useSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmissionQuery, SubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubmissionQuery, SubmissionQueryVariables>(SubmissionDocument, baseOptions);
        }
export type SubmissionQueryHookResult = ReturnType<typeof useSubmissionQuery>;
export type SubmissionLazyQueryHookResult = ReturnType<typeof useSubmissionLazyQuery>;
export type SubmissionQueryResult = ApolloReactCommon.QueryResult<SubmissionQuery, SubmissionQueryVariables>;
export const TeamDocument = gql`
    query Team {
  viewer {
    user {
      id
      company {
        id
        name
        users {
          nodes {
            id
            fullName
            avatarUrl
            jobTitle
            disabled
            manager {
              id
            }
          }
        }
      }
    }
  }
}
    `;
export type TeamProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamQuery, TeamQueryVariables>
    } & TChildProps;
export function withTeam<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamQuery,
  TeamQueryVariables,
  TeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamQuery, TeamQueryVariables, TeamProps<TChildProps, TDataName>>(TeamDocument, {
      alias: 'team',
      ...operationOptions
    });
};

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamQuery, TeamQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamQuery, TeamQueryVariables>(TeamDocument, baseOptions);
      }
export function useTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamQuery, TeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamQuery, TeamQueryVariables>(TeamDocument, baseOptions);
        }
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = ApolloReactCommon.QueryResult<TeamQuery, TeamQueryVariables>;
export const CompanySettingsDocument = gql`
    query CompanySettings {
  viewer {
    user {
      id
      company {
        id
        incomingEmailAddress
        emailSubmissionResponse
        emailSubmissionsManager {
          id
          fullName
          email
        }
        intakeUser {
          id
          fullName
          email
        }
      }
    }
  }
}
    `;
export type CompanySettingsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CompanySettingsQuery, CompanySettingsQueryVariables>
    } & TChildProps;
export function withCompanySettings<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CompanySettingsQuery,
  CompanySettingsQueryVariables,
  CompanySettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CompanySettingsQuery, CompanySettingsQueryVariables, CompanySettingsProps<TChildProps, TDataName>>(CompanySettingsDocument, {
      alias: 'companySettings',
      ...operationOptions
    });
};

/**
 * __useCompanySettingsQuery__
 *
 * To run a query within a React component, call `useCompanySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanySettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanySettingsQuery, CompanySettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanySettingsQuery, CompanySettingsQueryVariables>(CompanySettingsDocument, baseOptions);
      }
export function useCompanySettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanySettingsQuery, CompanySettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanySettingsQuery, CompanySettingsQueryVariables>(CompanySettingsDocument, baseOptions);
        }
export type CompanySettingsQueryHookResult = ReturnType<typeof useCompanySettingsQuery>;
export type CompanySettingsLazyQueryHookResult = ReturnType<typeof useCompanySettingsLazyQuery>;
export type CompanySettingsQueryResult = ApolloReactCommon.QueryResult<CompanySettingsQuery, CompanySettingsQueryVariables>;
export const TerrorismQuotationDocument = gql`
    query TerrorismQuotation($participationId: ID!) {
  panels {
    id
    participation(participationId: $participationId) {
      id
      documents {
        id
        ...Document
      }
      owner {
        id
      }
      user {
        id
        fullName
        company {
          id
          name
          logoUrl
        }
      }
      discussion {
        id
        ...Messages
      }
      submission {
        id
        ...PanelSubmissionInfo
      }
      quotations {
        nodes {
          id
          ...PanelQuotationInfo
        }
      }
    }
  }
}
    ${DocumentFragmentDoc}
${MessagesFragmentDoc}
${PanelSubmissionInfoFragmentDoc}
${PanelQuotationInfoFragmentDoc}`;
export type TerrorismQuotationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TerrorismQuotationQuery, TerrorismQuotationQueryVariables>
    } & TChildProps;
export function withTerrorismQuotation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TerrorismQuotationQuery,
  TerrorismQuotationQueryVariables,
  TerrorismQuotationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TerrorismQuotationQuery, TerrorismQuotationQueryVariables, TerrorismQuotationProps<TChildProps, TDataName>>(TerrorismQuotationDocument, {
      alias: 'terrorismQuotation',
      ...operationOptions
    });
};

/**
 * __useTerrorismQuotationQuery__
 *
 * To run a query within a React component, call `useTerrorismQuotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerrorismQuotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerrorismQuotationQuery({
 *   variables: {
 *      participationId: // value for 'participationId'
 *   },
 * });
 */
export function useTerrorismQuotationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TerrorismQuotationQuery, TerrorismQuotationQueryVariables>) {
        return ApolloReactHooks.useQuery<TerrorismQuotationQuery, TerrorismQuotationQueryVariables>(TerrorismQuotationDocument, baseOptions);
      }
export function useTerrorismQuotationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TerrorismQuotationQuery, TerrorismQuotationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TerrorismQuotationQuery, TerrorismQuotationQueryVariables>(TerrorismQuotationDocument, baseOptions);
        }
export type TerrorismQuotationQueryHookResult = ReturnType<typeof useTerrorismQuotationQuery>;
export type TerrorismQuotationLazyQueryHookResult = ReturnType<typeof useTerrorismQuotationLazyQuery>;
export type TerrorismQuotationQueryResult = ApolloReactCommon.QueryResult<TerrorismQuotationQuery, TerrorismQuotationQueryVariables>;
export const UserInfoDocument = gql`
    query UserInfo {
  viewer {
    features {
      list {
        name
        enabled
      }
    }
    user {
      id
      ...UserInfo
      ...Permissions
    }
  }
}
    ${UserInfoFragmentDoc}
${PermissionsFragmentDoc}`;
export type UserInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserInfoQuery, UserInfoQueryVariables>
    } & TChildProps;
export function withUserInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserInfoQuery,
  UserInfoQueryVariables,
  UserInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserInfoQuery, UserInfoQueryVariables, UserInfoProps<TChildProps, TDataName>>(UserInfoDocument, {
      alias: 'userInfo',
      ...operationOptions
    });
};

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, baseOptions);
      }
export function useUserInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, baseOptions);
        }
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = ApolloReactCommon.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
export const WorksheetBrokerDocument = gql`
    query WorksheetBroker($worksheetId: ID!) {
  viewer {
    user {
      id
      ... on Cedent {
        worksheet(worksheetId: $worksheetId) {
          id
          ...WorksheetBrokerInfo
        }
      }
      ... on Broker {
        worksheet(worksheetId: $worksheetId) {
          id
          ...WorksheetBrokerInfo
        }
      }
    }
  }
}
    ${WorksheetBrokerInfoFragmentDoc}`;
export type WorksheetBrokerProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<WorksheetBrokerQuery, WorksheetBrokerQueryVariables>
    } & TChildProps;
export function withWorksheetBroker<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  WorksheetBrokerQuery,
  WorksheetBrokerQueryVariables,
  WorksheetBrokerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, WorksheetBrokerQuery, WorksheetBrokerQueryVariables, WorksheetBrokerProps<TChildProps, TDataName>>(WorksheetBrokerDocument, {
      alias: 'worksheetBroker',
      ...operationOptions
    });
};

/**
 * __useWorksheetBrokerQuery__
 *
 * To run a query within a React component, call `useWorksheetBrokerQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorksheetBrokerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorksheetBrokerQuery({
 *   variables: {
 *      worksheetId: // value for 'worksheetId'
 *   },
 * });
 */
export function useWorksheetBrokerQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WorksheetBrokerQuery, WorksheetBrokerQueryVariables>) {
        return ApolloReactHooks.useQuery<WorksheetBrokerQuery, WorksheetBrokerQueryVariables>(WorksheetBrokerDocument, baseOptions);
      }
export function useWorksheetBrokerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorksheetBrokerQuery, WorksheetBrokerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorksheetBrokerQuery, WorksheetBrokerQueryVariables>(WorksheetBrokerDocument, baseOptions);
        }
export type WorksheetBrokerQueryHookResult = ReturnType<typeof useWorksheetBrokerQuery>;
export type WorksheetBrokerLazyQueryHookResult = ReturnType<typeof useWorksheetBrokerLazyQuery>;
export type WorksheetBrokerQueryResult = ApolloReactCommon.QueryResult<WorksheetBrokerQuery, WorksheetBrokerQueryVariables>;
export const WorksheetInfoDocument = gql`
    query WorksheetInfo($worksheetId: ID!) {
  viewer {
    user {
      id
      ... on Cedent {
        worksheet(worksheetId: $worksheetId) {
          id
          kind
          status
          demo
          isQuotable
          participationComment
          owner {
            id
            fullName
            company {
              id
              name
            }
          }
          quotes {
            nodes {
              id
              ...QuotedQuote
            }
          }
          ...WorksheetSummary
        }
      }
    }
  }
}
    ${QuotedQuoteFragmentDoc}
${WorksheetSummaryFragmentDoc}`;
export type WorksheetInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<WorksheetInfoQuery, WorksheetInfoQueryVariables>
    } & TChildProps;
export function withWorksheetInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  WorksheetInfoQuery,
  WorksheetInfoQueryVariables,
  WorksheetInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, WorksheetInfoQuery, WorksheetInfoQueryVariables, WorksheetInfoProps<TChildProps, TDataName>>(WorksheetInfoDocument, {
      alias: 'worksheetInfo',
      ...operationOptions
    });
};

/**
 * __useWorksheetInfoQuery__
 *
 * To run a query within a React component, call `useWorksheetInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorksheetInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorksheetInfoQuery({
 *   variables: {
 *      worksheetId: // value for 'worksheetId'
 *   },
 * });
 */
export function useWorksheetInfoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<WorksheetInfoQuery, WorksheetInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<WorksheetInfoQuery, WorksheetInfoQueryVariables>(WorksheetInfoDocument, baseOptions);
      }
export function useWorksheetInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorksheetInfoQuery, WorksheetInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorksheetInfoQuery, WorksheetInfoQueryVariables>(WorksheetInfoDocument, baseOptions);
        }
export type WorksheetInfoQueryHookResult = ReturnType<typeof useWorksheetInfoQuery>;
export type WorksheetInfoLazyQueryHookResult = ReturnType<typeof useWorksheetInfoLazyQuery>;
export type WorksheetInfoQueryResult = ApolloReactCommon.QueryResult<WorksheetInfoQuery, WorksheetInfoQueryVariables>;
export const CreateOrUpdateTemplateDocument = gql`
    mutation createOrUpdateTemplate($input: CreateOrUpdateTemplateInput!) {
  createOrUpdateTemplate(input: $input) {
    document {
      id
      ...Document
    }
    errors
  }
}
    ${DocumentFragmentDoc}`;
export type CreateOrUpdateTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateOrUpdateTemplateMutation, CreateOrUpdateTemplateMutationVariables>;
export type CreateOrUpdateTemplateProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateOrUpdateTemplateMutation, CreateOrUpdateTemplateMutationVariables>
    } & TChildProps;
export function withCreateOrUpdateTemplate<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateOrUpdateTemplateMutation,
  CreateOrUpdateTemplateMutationVariables,
  CreateOrUpdateTemplateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateOrUpdateTemplateMutation, CreateOrUpdateTemplateMutationVariables, CreateOrUpdateTemplateProps<TChildProps, TDataName>>(CreateOrUpdateTemplateDocument, {
      alias: 'createOrUpdateTemplate',
      ...operationOptions
    });
};

/**
 * __useCreateOrUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTemplateMutation, { data, loading, error }] = useCreateOrUpdateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrUpdateTemplateMutation, CreateOrUpdateTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrUpdateTemplateMutation, CreateOrUpdateTemplateMutationVariables>(CreateOrUpdateTemplateDocument, baseOptions);
      }
export type CreateOrUpdateTemplateMutationHookResult = ReturnType<typeof useCreateOrUpdateTemplateMutation>;
export type CreateOrUpdateTemplateMutationResult = ApolloReactCommon.MutationResult<CreateOrUpdateTemplateMutation>;
export type CreateOrUpdateTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrUpdateTemplateMutation, CreateOrUpdateTemplateMutationVariables>;
export const UpdateTaggedStatusDocument = gql`
    mutation updateTaggedStatus($input: UpdateTaggedStatusInput!) {
  updateTaggedStatus(input: $input) {
    document {
      id
      ...Document
    }
    errors
  }
}
    ${DocumentFragmentDoc}`;
export type UpdateTaggedStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateTaggedStatusMutation, UpdateTaggedStatusMutationVariables>;
export type UpdateTaggedStatusProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateTaggedStatusMutation, UpdateTaggedStatusMutationVariables>
    } & TChildProps;
export function withUpdateTaggedStatus<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTaggedStatusMutation,
  UpdateTaggedStatusMutationVariables,
  UpdateTaggedStatusProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateTaggedStatusMutation, UpdateTaggedStatusMutationVariables, UpdateTaggedStatusProps<TChildProps, TDataName>>(UpdateTaggedStatusDocument, {
      alias: 'updateTaggedStatus',
      ...operationOptions
    });
};

/**
 * __useUpdateTaggedStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaggedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaggedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaggedStatusMutation, { data, loading, error }] = useUpdateTaggedStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaggedStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaggedStatusMutation, UpdateTaggedStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaggedStatusMutation, UpdateTaggedStatusMutationVariables>(UpdateTaggedStatusDocument, baseOptions);
      }
export type UpdateTaggedStatusMutationHookResult = ReturnType<typeof useUpdateTaggedStatusMutation>;
export type UpdateTaggedStatusMutationResult = ApolloReactCommon.MutationResult<UpdateTaggedStatusMutation>;
export type UpdateTaggedStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaggedStatusMutation, UpdateTaggedStatusMutationVariables>;
type Attachments_Broadcast_Fragment = (
  { __typename?: 'Broadcast' }
  & Pick<Broadcast, 'id'>
  & { attachments: (
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  ) }
);

type Attachments_DirectMessage_Fragment = (
  { __typename?: 'DirectMessage' }
  & Pick<DirectMessage, 'id'>
  & { attachments: (
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  ) }
);

export type AttachmentsFragment = Attachments_Broadcast_Fragment | Attachments_DirectMessage_Fragment;

type Avatar_Reinsurer_Fragment = (
  { __typename?: 'Reinsurer' }
  & Pick<Reinsurer, 'id' | 'fullName' | 'avatarUrl'>
);

type Avatar_Cedent_Fragment = (
  { __typename?: 'Cedent' }
  & Pick<Cedent, 'id' | 'fullName' | 'avatarUrl'>
);

type Avatar_Broker_Fragment = (
  { __typename?: 'Broker' }
  & Pick<Broker, 'id' | 'fullName' | 'avatarUrl'>
);

type Avatar_NfpRepsAndWarrantiesBroker_Fragment = (
  { __typename?: 'NFPRepsAndWarrantiesBroker' }
  & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'avatarUrl'>
);

export type AvatarFragment = Avatar_Reinsurer_Fragment | Avatar_Cedent_Fragment | Avatar_Broker_Fragment | Avatar_NfpRepsAndWarrantiesBroker_Fragment;

export type CedentQuoteActionsFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id' | 'status'>
  & { readableStatus: Quote['status'] }
  & { binder?: Maybe<(
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  )>, certificate?: Maybe<(
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  )> }
);

export type CyberQuotedLinesInfoFragment = (
  { __typename?: 'CyberQuotation' }
  & Pick<CyberQuotation, 'id'>
  & { quotedLines: (
    { __typename?: 'PanelsQuotedLineTypeConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'CyberQuotedLineType' }
      & Pick<CyberQuotedLineType, 'amountCommission' | 'annualPremium' | 'clientCost' | 'retailCommission' | 'retailCommissionPercentage' | 'totalCommissionPercentage' | 'wholesalerCommission' | 'wholesalerCommissionPercentage' | 'policyNumber' | 'id' | 'type'>
    ) | (
      { __typename?: 'DirectorsAndOfficersQuotedLineType' }
      & Pick<DirectorsAndOfficersQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'EmploymentPracticesLiabilityQuotedLineType' }
      & Pick<EmploymentPracticesLiabilityQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'FidelityCrimeQuotedLineType' }
      & Pick<FidelityCrimeQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'FiduciaryQuotedLineType' }
      & Pick<FiduciaryQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'SpecialRiskQuotedLineType' }
      & Pick<SpecialRiskQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'TerrorismQuotedLineType' }
      & Pick<TerrorismQuotedLineType, 'id' | 'type'>
    )>>> }
  ) }
);

type CyberSubmissionInfo_CyberSubmission_Fragment = (
  { __typename?: 'CyberSubmission' }
  & Pick<CyberSubmission, 'updatedAt' | 'coverageStartDate' | 'websites' | 'hasNoWebsite' | 'naicsCode' | 'cyberboxxIndustryCode' | 'chubbIndustryCode' | 'phoneNumber' | 'country' | 'contactName' | 'email' | 'numberOfEmployees' | 'numberOfRecords' | 'annualRevenue' | 'limit' | 'deductible' | 'streetLine1' | 'streetLine2' | 'city' | 'state' | 'status' | 'zipCode' | 'message' | 'selectedPanelists' | 'multiFactorAuthentication' | 'additionalCarriers' | 'questions' | 'id' | 'name'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, pdfExport?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'filename' | 'url'>
  )>, owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Reinsurer_Fragment
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Cedent_Fragment
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Broker_Fragment
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_NfpRepsAndWarrantiesBroker_Fragment
  ) }
);

type CyberSubmissionInfo_PanelSubmission_Fragment = (
  { __typename?: 'PanelSubmission' }
  & Pick<PanelSubmission, 'id' | 'name'>
  & { owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Reinsurer_Fragment
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Cedent_Fragment
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Broker_Fragment
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_NfpRepsAndWarrantiesBroker_Fragment
  ) }
);

type CyberSubmissionInfo_PcgSubmission_Fragment = (
  { __typename?: 'PCGSubmission' }
  & Pick<PcgSubmission, 'id' | 'name'>
  & { owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Reinsurer_Fragment
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Cedent_Fragment
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Broker_Fragment
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_NfpRepsAndWarrantiesBroker_Fragment
  ) }
);

type CyberSubmissionInfo_NfpRepsAndWarrantiesSubmission_Fragment = (
  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
  & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
  & { owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Reinsurer_Fragment
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Cedent_Fragment
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Broker_Fragment
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_NfpRepsAndWarrantiesBroker_Fragment
  ) }
);

export type CyberSubmissionInfoFragment = CyberSubmissionInfo_CyberSubmission_Fragment | CyberSubmissionInfo_PanelSubmission_Fragment | CyberSubmissionInfo_PcgSubmission_Fragment | CyberSubmissionInfo_NfpRepsAndWarrantiesSubmission_Fragment;

type CyberQuotationInfo_CyberQuotation_Fragment = (
  { __typename?: 'CyberQuotation' }
  & Pick<CyberQuotation, 'type' | 'selected' | 'indication' | 'statusMessage' | 'admitted' | 'declinationReason' | 'deductible' | 'fee' | 'tax' | 'limit' | 'requestedLimit' | 'requestedDeductible' | 'policyNumber' | 'premium' | 'signingUrl' | 'surplusLinesTax' | 'totalCost' | 'extractedAt' | 'reviewedAt' | 'coverageStartDate' | 'underwriterFirstName' | 'underwriterLastName' | 'underwriterEmail' | 'retailCommissionPercentage' | 'retailCommission' | 'id' | 'status'>
  & { endorsements?: Maybe<Array<Maybe<(
    { __typename?: 'CyberEndorsement' }
    & Pick<CyberEndorsement, 'name' | 'limit' | 'coinsurance'>
  )>>>, additionalFeatures?: Maybe<Array<Maybe<(
    { __typename?: 'CyberAdditionalFeature' }
    & Pick<CyberAdditionalFeature, 'title' | 'description'>
  )>>>, coverages?: Maybe<Array<Maybe<(
    { __typename?: 'CyberCoverage' }
    & Pick<CyberCoverage, 'name' | 'type' | 'limit' | 'deductible' | 'waitingPeriod'>
  )>>>, externalCarrier?: Maybe<(
    { __typename?: 'ExternalCarrier' }
    & Pick<ExternalCarrier, 'id' | 'name'>
  )>, documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, ambestRating?: Maybe<(
    { __typename?: 'AMBestRating' }
    & Pick<AmBestRating, 'id' | 'rating'>
  )> }
);

type CyberQuotationInfo_PcgQuotation_Fragment = (
  { __typename?: 'PCGQuotation' }
  & Pick<PcgQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, ambestRating?: Maybe<(
    { __typename?: 'AMBestRating' }
    & Pick<AmBestRating, 'id' | 'rating'>
  )> }
);

type CyberQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment = (
  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
  & Pick<NfpRepsAndWarrantiesQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, ambestRating?: Maybe<(
    { __typename?: 'AMBestRating' }
    & Pick<AmBestRating, 'id' | 'rating'>
  )> }
);

type CyberQuotationInfo_PanelsQuotation_Fragment = (
  { __typename?: 'PanelsQuotation' }
  & Pick<PanelsQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, ambestRating?: Maybe<(
    { __typename?: 'AMBestRating' }
    & Pick<AmBestRating, 'id' | 'rating'>
  )> }
);

type CyberQuotationInfo_MlQuotation_Fragment = (
  { __typename?: 'MLQuotation' }
  & Pick<MlQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, ambestRating?: Maybe<(
    { __typename?: 'AMBestRating' }
    & Pick<AmBestRating, 'id' | 'rating'>
  )> }
);

type CyberQuotationInfo_TerrorismQuotation_Fragment = (
  { __typename?: 'TerrorismQuotation' }
  & Pick<TerrorismQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, ambestRating?: Maybe<(
    { __typename?: 'AMBestRating' }
    & Pick<AmBestRating, 'id' | 'rating'>
  )> }
);

type CyberQuotationInfo_BopQuotation_Fragment = (
  { __typename?: 'BOPQuotation' }
  & Pick<BopQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, ambestRating?: Maybe<(
    { __typename?: 'AMBestRating' }
    & Pick<AmBestRating, 'id' | 'rating'>
  )> }
);

export type CyberQuotationInfoFragment = CyberQuotationInfo_CyberQuotation_Fragment | CyberQuotationInfo_PcgQuotation_Fragment | CyberQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment | CyberQuotationInfo_PanelsQuotation_Fragment | CyberQuotationInfo_MlQuotation_Fragment | CyberQuotationInfo_TerrorismQuotation_Fragment | CyberQuotationInfo_BopQuotation_Fragment;

export type CyberSubmissionFileCabinetFragment = (
  { __typename?: 'CyberSubmission' }
  & Pick<CyberSubmission, 'id' | 'name' | 'country'>
  & { owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'fullName'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'fullName'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'fullName'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
  ), documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, participations: (
    { __typename?: 'ParticipationConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>>, user?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      )>, quotations: (
        { __typename?: 'QuotationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CyberQuotation' }
          & Pick<CyberQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'PCGQuotation' }
          & Pick<PcgQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesQuotation' }
          & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'PanelsQuotation' }
          & Pick<PanelsQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'MLQuotation' }
          & Pick<MlQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'TerrorismQuotation' }
          & Pick<TerrorismQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'BOPQuotation' }
          & Pick<BopQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        )>>> }
      ) }
    )>>> }
  ) }
);

export type DiscussionParticipantsFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & { discussionParticipants: (
    { __typename?: 'DiscussionParticipantConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'DiscussionParticipant' }
      & Pick<DiscussionParticipant, 'id' | 'lastReadMessageId' | 'unreadCount'>
      & { participant: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ) }
    )>>> }
  ) }
);

export type DocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'url' | 'filename' | 'createdAt' | 'type' | 'tags' | 'category' | 'version' | 'hellosignTemplateId' | 'templateEditUrl' | 'templateEditUrlExpiresAt' | 'isTagged'>
  & { name: Document['filename'], size: Document['byteSize'] }
);

export type DocumentForFormFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'url' | 'type' | 'tags' | 'createdAt' | 'category' | 'version' | 'source'>
  & { name: Document['filename'], size: Document['byteSize'] }
);

export type EndorsementInfoFragment = (
  { __typename?: 'Endorsement' }
  & Pick<Endorsement, 'id' | 'name' | 'reason' | 'kind' | 'status' | 'reinsuranceEffectiveDate' | 'reinsuranceExpiryDate' | 'endorsementStartDate' | 'endorsementEndDate' | 'identifierNumber' | 'perils' | 'exclusions' | 'namedInsured' | 'totalInsuredValue' | 'createdAt'>
  & { documents: (
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  ), insurancePolicy: (
    { __typename?: 'InsurancePolicy' }
    & Pick<InsurancePolicy, 'id' | 'number' | 'effectiveDate' | 'expiryDate'>
    & { coverages: (
      { __typename?: 'PolicyCoverageConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'CasualtyCoverage' }
        & Pick<CasualtyCoverage, 'id' | 'limit'>
      ) | (
        { __typename?: 'PropertyCoverage' }
        & Pick<PropertyCoverage, 'id' | 'limit'>
      )>>> }
    ) }
  ), endorsementQuotes: Array<(
    { __typename?: 'EndorsementQuote' }
    & Pick<EndorsementQuote, 'id' | 'status' | 'additionalPremium' | 'returnPremium'>
    & { schemas?: Maybe<(
      { __typename?: 'EndorsementQuotesSchema' }
      & Pick<EndorsementQuotesSchema, 'sentEndorsementQuote' | 'requestedEndorsementQuote'>
    )>, documents: (
      { __typename?: 'DocumentConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>>> }
    ), quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & { reinsurer?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName'>
      )>, revisions: (
        { __typename?: 'RevisionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Revision' }
          & Pick<Revision, 'id' | 'layerLimit' | 'attachmentPoint' | 'participation'>
          & { quotedLayers: Array<(
            { __typename?: 'QuotedLayer' }
            & Pick<QuotedLayer, 'id'>
            & QuotedLayerInfoFragment
          )> }
        )>>> }
      ), reinsuranceRequests: (
        { __typename?: 'ReinsuranceRequestConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'ReinsuranceRequest' }
          & Pick<ReinsuranceRequest, 'id' | 'layerText'>
        )>>> }
      ), discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & { messages: (
          { __typename?: 'MessageConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Broadcast' }
            & Pick<Broadcast, 'id'>
          ) | (
            { __typename?: 'DirectMessage' }
            & Pick<DirectMessage, 'id'>
          )>>> }
        ) }
      )> }
    )> }
  )> }
);

export type LatestRevisionFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id' | 'status' | 'stage'>
  & { latestRevisionForEdit: (
    { __typename?: 'RevisionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'participation' | 'layerLimit' | 'attachmentPoint' | 'premium' | 'netPremium' | 'cedingCommission' | 'expiryDate' | 'deductible' | 'number' | 'keyPerils' | 'exclusions' | 'brokerageCommission' | 'subjectivities' | 'source' | 'isIncomplete'>
      & { customFields: Array<(
        { __typename?: 'DateField' }
        & Pick<DateField, 'name' | 'type'>
      ) | (
        { __typename?: 'DateTimeField' }
        & Pick<DateTimeField, 'name' | 'type'>
      ) | (
        { __typename?: 'DecimalField' }
        & Pick<DecimalField, 'name' | 'type'>
      ) | (
        { __typename?: 'IntegerField' }
        & Pick<IntegerField, 'name' | 'type'>
      ) | (
        { __typename?: 'MonetaryField' }
        & Pick<MonetaryField, 'name' | 'type'>
      ) | (
        { __typename?: 'SelectField' }
        & Pick<SelectField, 'name' | 'type'>
      ) | (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'name' | 'type'>
      )>, quotedLayers: Array<(
        { __typename?: 'QuotedLayer' }
        & Pick<QuotedLayer, 'id'>
        & QuotedLayerInfoFragment
      )>, documents: (
        { __typename?: 'DocumentConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'type'>
          & { name: Document['filename'], size: Document['byteSize'] }
        )>>> }
      ) }
    )>>> }
  ) }
);

type Message_Broadcast_Fragment = (
  { __typename?: 'Broadcast' }
  & Pick<Broadcast, 'type' | 'id' | 'body' | 'subject' | 'timestamp'>
  & { author: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
    & Avatar_Reinsurer_Fragment
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id'>
    & Avatar_Cedent_Fragment
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
    & Avatar_Broker_Fragment
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    & Avatar_NfpRepsAndWarrantiesBroker_Fragment
  ) }
  & Attachments_Broadcast_Fragment
);

type Message_DirectMessage_Fragment = (
  { __typename?: 'DirectMessage' }
  & Pick<DirectMessage, 'id' | 'body' | 'subject' | 'timestamp'>
  & { author: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
    & Avatar_Reinsurer_Fragment
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id'>
    & Avatar_Cedent_Fragment
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
    & Avatar_Broker_Fragment
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    & Avatar_NfpRepsAndWarrantiesBroker_Fragment
  ) }
  & Attachments_DirectMessage_Fragment
);

export type MessageFragment = Message_Broadcast_Fragment | Message_DirectMessage_Fragment;

export type MessagesFragment = (
  { __typename?: 'Discussion' }
  & Pick<Discussion, 'id'>
  & { messages: (
    { __typename?: 'MessageConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Broadcast' }
      & Pick<Broadcast, 'id'>
      & Message_Broadcast_Fragment
    ) | (
      { __typename?: 'DirectMessage' }
      & Pick<DirectMessage, 'id'>
      & Message_DirectMessage_Fragment
    )>>> }
  ) }
);

export type MlParticipationFragment = (
  { __typename?: 'Participation' }
  & Pick<Participation, 'id'>
  & { documents?: Maybe<Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>>, owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'fullName'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'fullName'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'fullName'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
  ), user?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'fullName'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'fullName'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'fullName'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
  )>, discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
    & MessagesFragment
  )>, submission: (
    { __typename?: 'CyberSubmission' }
    & Pick<CyberSubmission, 'id'>
  ) | (
    { __typename?: 'PanelSubmission' }
    & Pick<PanelSubmission, 'id'>
    & MlSubmissionInfoFragment
  ) | (
    { __typename?: 'PCGSubmission' }
    & Pick<PcgSubmission, 'id'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesSubmission' }
    & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
  ), quotations: (
    { __typename?: 'QuotationConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & MlQuotationInfo_CyberQuotation_Fragment
    ) | (
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
      & MlQuotationInfo_PcgQuotation_Fragment
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & MlQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
    ) | (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
      & MlQuotationInfo_PanelsQuotation_Fragment
    ) | (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & MlQuotationInfo_MlQuotation_Fragment
    ) | (
      { __typename?: 'TerrorismQuotation' }
      & Pick<TerrorismQuotation, 'id'>
      & MlQuotationInfo_TerrorismQuotation_Fragment
    ) | (
      { __typename?: 'BOPQuotation' }
      & Pick<BopQuotation, 'id'>
      & MlQuotationInfo_BopQuotation_Fragment
    )>>> }
  ) }
);

export type MlProductInfoFragment = (
  { __typename?: 'ManagementLiabilityProduct' }
  & Pick<ManagementLiabilityProduct, 'id' | 'number_of_employees' | 'annual_revenue' | 'fee_based_account' | 'first_time_buyer' | 'continuity_date' | 'effective_date'>
  & { coverages: Array<(
    { __typename?: 'BOPCoverage' }
    & Pick<BopCoverage, 'id' | 'type'>
  ) | (
    { __typename?: 'CyberGenericCoverage' }
    & Pick<CyberGenericCoverage, 'id' | 'type'>
  ) | (
    { __typename?: 'ManagementLiabilityDirectorsAndOfficersCoverage' }
    & Pick<ManagementLiabilityDirectorsAndOfficersCoverage, 'limit' | 'deductible' | 'request_higher_limit' | 'name' | 'id' | 'type'>
  ) | (
    { __typename?: 'ManagementLiabilityEmploymentPracticesLiabilityCoverage' }
    & Pick<ManagementLiabilityEmploymentPracticesLiabilityCoverage, 'limit' | 'deductible' | 'request_higher_limit' | 'name' | 'id' | 'type'>
  ) | (
    { __typename?: 'ManagementLiabilityFidelityCrimeCoverage' }
    & Pick<ManagementLiabilityFidelityCrimeCoverage, 'limit' | 'deductible' | 'request_higher_limit' | 'name' | 'id' | 'type'>
  ) | (
    { __typename?: 'ManagementLiabilityFiduciaryCoverage' }
    & Pick<ManagementLiabilityFiduciaryCoverage, 'limit' | 'deductible' | 'request_higher_limit' | 'name' | 'id' | 'type'>
  ) | (
    { __typename?: 'ManagementLiabilitySpecialRiskCoverage' }
    & Pick<ManagementLiabilitySpecialRiskCoverage, 'limit' | 'deductible' | 'request_higher_limit' | 'name' | 'id' | 'type'>
  ) | (
    { __typename?: 'TerrorismCoverage' }
    & Pick<TerrorismCoverage, 'id' | 'type'>
  )> }
);

type MlQuotationInfo_CyberQuotation_Fragment = (
  { __typename?: 'CyberQuotation' }
  & Pick<CyberQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )> }
);

type MlQuotationInfo_PcgQuotation_Fragment = (
  { __typename?: 'PCGQuotation' }
  & Pick<PcgQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )> }
);

type MlQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment = (
  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
  & Pick<NfpRepsAndWarrantiesQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )> }
);

type MlQuotationInfo_PanelsQuotation_Fragment = (
  { __typename?: 'PanelsQuotation' }
  & Pick<PanelsQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )> }
);

type MlQuotationInfo_MlQuotation_Fragment = (
  { __typename?: 'MLQuotation' }
  & Pick<MlQuotation, 'id' | 'type' | 'indication' | 'admitted' | 'currentPolicy' | 'declinationReason' | 'status' | 'annualPremium' | 'selected' | 'policyNumber' | 'retailCommissionPercentage' | 'retailCommission'>
  & { subjectivitiesAttributes?: Maybe<Array<(
    { __typename?: 'SubjectivitiesAttributesType' }
    & Pick<SubjectivitiesAttributesType, 'text'>
  )>>, documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )> }
  & MlQuotedLinesInfoFragment
);

type MlQuotationInfo_TerrorismQuotation_Fragment = (
  { __typename?: 'TerrorismQuotation' }
  & Pick<TerrorismQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )> }
);

type MlQuotationInfo_BopQuotation_Fragment = (
  { __typename?: 'BOPQuotation' }
  & Pick<BopQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )> }
);

export type MlQuotationInfoFragment = MlQuotationInfo_CyberQuotation_Fragment | MlQuotationInfo_PcgQuotation_Fragment | MlQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment | MlQuotationInfo_PanelsQuotation_Fragment | MlQuotationInfo_MlQuotation_Fragment | MlQuotationInfo_TerrorismQuotation_Fragment | MlQuotationInfo_BopQuotation_Fragment;

export type MlQuotedLinesInfoFragment = (
  { __typename?: 'MLQuotation' }
  & Pick<MlQuotation, 'id'>
  & { quotedLines: (
    { __typename?: 'PanelsQuotedLineTypeConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'CyberQuotedLineType' }
      & Pick<CyberQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'DirectorsAndOfficersQuotedLineType' }
      & Pick<DirectorsAndOfficersQuotedLineType, 'coverageName' | 'iconName' | 'quotedLineNames' | 'id' | 'type'>
      & { coverage: (
        { __typename?: 'BOPCoverage' }
        & Pick<BopCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'CyberGenericCoverage' }
        & Pick<CyberGenericCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityDirectorsAndOfficersCoverage' }
        & Pick<ManagementLiabilityDirectorsAndOfficersCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityEmploymentPracticesLiabilityCoverage' }
        & Pick<ManagementLiabilityEmploymentPracticesLiabilityCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFidelityCrimeCoverage' }
        & Pick<ManagementLiabilityFidelityCrimeCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFiduciaryCoverage' }
        & Pick<ManagementLiabilityFiduciaryCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilitySpecialRiskCoverage' }
        & Pick<ManagementLiabilitySpecialRiskCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'TerrorismCoverage' }
        & Pick<TerrorismCoverage, 'id' | 'type'>
      ), directorsAndOfficers?: Maybe<(
        { __typename?: 'DirectorsAndOfficersType' }
        & Pick<DirectorsAndOfficersType, 'limit' | 'retention'>
      )>, entityCoverage?: Maybe<(
        { __typename?: 'EntityCoverageType' }
        & Pick<EntityCoverageType, 'limit' | 'retention'>
      )>, indemnifiableLoss?: Maybe<(
        { __typename?: 'IndemnifiableLossType' }
        & Pick<IndemnifiableLossType, 'limit' | 'retention'>
      )>, nonIndemnifiableLoss?: Maybe<(
        { __typename?: 'NonIndemnifiableLossType' }
        & Pick<NonIndemnifiableLossType, 'limit' | 'retention'>
      )> }
    ) | (
      { __typename?: 'EmploymentPracticesLiabilityQuotedLineType' }
      & Pick<EmploymentPracticesLiabilityQuotedLineType, 'coverageName' | 'iconName' | 'quotedLineNames' | 'id' | 'type'>
      & { coverage: (
        { __typename?: 'BOPCoverage' }
        & Pick<BopCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'CyberGenericCoverage' }
        & Pick<CyberGenericCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityDirectorsAndOfficersCoverage' }
        & Pick<ManagementLiabilityDirectorsAndOfficersCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityEmploymentPracticesLiabilityCoverage' }
        & Pick<ManagementLiabilityEmploymentPracticesLiabilityCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFidelityCrimeCoverage' }
        & Pick<ManagementLiabilityFidelityCrimeCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFiduciaryCoverage' }
        & Pick<ManagementLiabilityFiduciaryCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilitySpecialRiskCoverage' }
        & Pick<ManagementLiabilitySpecialRiskCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'TerrorismCoverage' }
        & Pick<TerrorismCoverage, 'id' | 'type'>
      ), employmentPracticesLiability?: Maybe<(
        { __typename?: 'EmploymentPracticesLiabilityType' }
        & Pick<EmploymentPracticesLiabilityType, 'limit' | 'retention'>
      )>, employee?: Maybe<(
        { __typename?: 'EmployeeType' }
        & Pick<EmployeeType, 'limit' | 'retention'>
      )>, thirdParty?: Maybe<(
        { __typename?: 'ThirdPartyType' }
        & Pick<ThirdPartyType, 'limit' | 'retention'>
      )> }
    ) | (
      { __typename?: 'FidelityCrimeQuotedLineType' }
      & Pick<FidelityCrimeQuotedLineType, 'coverageName' | 'iconName' | 'quotedLineNames' | 'id' | 'type'>
      & { coverage: (
        { __typename?: 'BOPCoverage' }
        & Pick<BopCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'CyberGenericCoverage' }
        & Pick<CyberGenericCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityDirectorsAndOfficersCoverage' }
        & Pick<ManagementLiabilityDirectorsAndOfficersCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityEmploymentPracticesLiabilityCoverage' }
        & Pick<ManagementLiabilityEmploymentPracticesLiabilityCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFidelityCrimeCoverage' }
        & Pick<ManagementLiabilityFidelityCrimeCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFiduciaryCoverage' }
        & Pick<ManagementLiabilityFiduciaryCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilitySpecialRiskCoverage' }
        & Pick<ManagementLiabilitySpecialRiskCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'TerrorismCoverage' }
        & Pick<TerrorismCoverage, 'id' | 'type'>
      ), fidelityCrime?: Maybe<(
        { __typename?: 'FidelityCrimeType' }
        & Pick<FidelityCrimeType, 'limit' | 'retention'>
      )>, employeeDishonesty?: Maybe<(
        { __typename?: 'EmployeeDishonestyType' }
        & Pick<EmployeeDishonestyType, 'limit' | 'retention'>
      )>, onPremises?: Maybe<(
        { __typename?: 'OnPremisesType' }
        & Pick<OnPremisesType, 'limit' | 'retention'>
      )>, inTransite?: Maybe<(
        { __typename?: 'InTransiteType' }
        & Pick<InTransiteType, 'limit' | 'retention'>
      )>, forgeryOrAlteration?: Maybe<(
        { __typename?: 'ForgeryOrAlterationType' }
        & Pick<ForgeryOrAlterationType, 'limit' | 'retention'>
      )>, counterfeitMoney?: Maybe<(
        { __typename?: 'CounterfeitMoneyType' }
        & Pick<CounterfeitMoneyType, 'limit' | 'retention'>
      )>, computerSystem?: Maybe<(
        { __typename?: 'ComputerSystemType' }
        & Pick<ComputerSystemType, 'limit' | 'retention'>
      )>, fundsTransferFraud?: Maybe<(
        { __typename?: 'FundsTransferFraudType' }
        & Pick<FundsTransferFraudType, 'limit' | 'retention'>
      )>, impersonationFraud?: Maybe<(
        { __typename?: 'ImpersonationFraudType' }
        & Pick<ImpersonationFraudType, 'limit' | 'retention'>
      )> }
    ) | (
      { __typename?: 'FiduciaryQuotedLineType' }
      & Pick<FiduciaryQuotedLineType, 'coverageName' | 'iconName' | 'quotedLineNames' | 'id' | 'type'>
      & { coverage: (
        { __typename?: 'BOPCoverage' }
        & Pick<BopCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'CyberGenericCoverage' }
        & Pick<CyberGenericCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityDirectorsAndOfficersCoverage' }
        & Pick<ManagementLiabilityDirectorsAndOfficersCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityEmploymentPracticesLiabilityCoverage' }
        & Pick<ManagementLiabilityEmploymentPracticesLiabilityCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFidelityCrimeCoverage' }
        & Pick<ManagementLiabilityFidelityCrimeCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFiduciaryCoverage' }
        & Pick<ManagementLiabilityFiduciaryCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilitySpecialRiskCoverage' }
        & Pick<ManagementLiabilitySpecialRiskCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'TerrorismCoverage' }
        & Pick<TerrorismCoverage, 'id' | 'type'>
      ), fiduciary?: Maybe<(
        { __typename?: 'FiduciaryType' }
        & Pick<FiduciaryType, 'limit' | 'retention'>
      )> }
    ) | (
      { __typename?: 'SpecialRiskQuotedLineType' }
      & Pick<SpecialRiskQuotedLineType, 'coverageName' | 'iconName' | 'quotedLineNames' | 'id' | 'type'>
      & { coverage: (
        { __typename?: 'BOPCoverage' }
        & Pick<BopCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'CyberGenericCoverage' }
        & Pick<CyberGenericCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityDirectorsAndOfficersCoverage' }
        & Pick<ManagementLiabilityDirectorsAndOfficersCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityEmploymentPracticesLiabilityCoverage' }
        & Pick<ManagementLiabilityEmploymentPracticesLiabilityCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFidelityCrimeCoverage' }
        & Pick<ManagementLiabilityFidelityCrimeCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFiduciaryCoverage' }
        & Pick<ManagementLiabilityFiduciaryCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilitySpecialRiskCoverage' }
        & Pick<ManagementLiabilitySpecialRiskCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'TerrorismCoverage' }
        & Pick<TerrorismCoverage, 'id' | 'type'>
      ), specialRisk?: Maybe<(
        { __typename?: 'SpecialRiskType' }
        & Pick<SpecialRiskType, 'limit' | 'retention'>
      )> }
    ) | (
      { __typename?: 'TerrorismQuotedLineType' }
      & Pick<TerrorismQuotedLineType, 'id' | 'type'>
    )>>> }
  ) }
);

export type MlSubmissionInfoFragment = (
  { __typename?: 'PanelSubmission' }
  & Pick<PanelSubmission, 'id' | 'name' | 'status' | 'panel_info' | 'updatedAt' | 'message'>
  & { owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Reinsurer_Fragment
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Cedent_Fragment
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Broker_Fragment
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_NfpRepsAndWarrantiesBroker_Fragment
  ), panel?: Maybe<(
    { __typename?: 'Panel' }
    & Pick<Panel, 'id' | 'lineOfBusiness'>
  )>, applicant?: Maybe<(
    { __typename?: 'CommercialApplicant' }
    & Pick<CommercialApplicant, 'id' | 'company_name' | 'name' | 'email' | 'phone_number' | 'naics_code'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'street_line1' | 'street_line2' | 'city' | 'state' | 'zip_code'>
    )> }
  )>, email_carriers: Array<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id'>
  )>, documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'type'>
    & { name: Document['filename'], size: Document['byteSize'] }
  )>, products: Array<(
    { __typename?: 'BOPProduct' }
    & Pick<BopProduct, 'id' | 'status'>
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'lineOfBusiness'>
    )>, quotations?: Maybe<Array<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & MlQuotationInfo_CyberQuotation_Fragment
    ) | (
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
      & MlQuotationInfo_PcgQuotation_Fragment
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & MlQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
    ) | (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
      & MlQuotationInfo_PanelsQuotation_Fragment
    ) | (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & MlQuotationInfo_MlQuotation_Fragment
    ) | (
      { __typename?: 'TerrorismQuotation' }
      & Pick<TerrorismQuotation, 'id'>
      & MlQuotationInfo_TerrorismQuotation_Fragment
    ) | (
      { __typename?: 'BOPQuotation' }
      & Pick<BopQuotation, 'id'>
      & MlQuotationInfo_BopQuotation_Fragment
    )>> }
  ) | (
    { __typename?: 'CyberProduct' }
    & Pick<CyberProduct, 'id' | 'status'>
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'lineOfBusiness'>
    )>, quotations?: Maybe<Array<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & MlQuotationInfo_CyberQuotation_Fragment
    ) | (
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
      & MlQuotationInfo_PcgQuotation_Fragment
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & MlQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
    ) | (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
      & MlQuotationInfo_PanelsQuotation_Fragment
    ) | (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & MlQuotationInfo_MlQuotation_Fragment
    ) | (
      { __typename?: 'TerrorismQuotation' }
      & Pick<TerrorismQuotation, 'id'>
      & MlQuotationInfo_TerrorismQuotation_Fragment
    ) | (
      { __typename?: 'BOPQuotation' }
      & Pick<BopQuotation, 'id'>
      & MlQuotationInfo_BopQuotation_Fragment
    )>> }
  ) | (
    { __typename?: 'ManagementLiabilityProduct' }
    & Pick<ManagementLiabilityProduct, 'id' | 'status'>
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'lineOfBusiness'>
    )>, quotations?: Maybe<Array<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & MlQuotationInfo_CyberQuotation_Fragment
    ) | (
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
      & MlQuotationInfo_PcgQuotation_Fragment
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & MlQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
    ) | (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
      & MlQuotationInfo_PanelsQuotation_Fragment
    ) | (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & MlQuotationInfo_MlQuotation_Fragment
    ) | (
      { __typename?: 'TerrorismQuotation' }
      & Pick<TerrorismQuotation, 'id'>
      & MlQuotationInfo_TerrorismQuotation_Fragment
    ) | (
      { __typename?: 'BOPQuotation' }
      & Pick<BopQuotation, 'id'>
      & MlQuotationInfo_BopQuotation_Fragment
    )>> }
    & MlProductInfoFragment
  ) | (
    { __typename?: 'TerrorismProduct' }
    & Pick<TerrorismProduct, 'id' | 'status'>
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'lineOfBusiness'>
    )>, quotations?: Maybe<Array<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & MlQuotationInfo_CyberQuotation_Fragment
    ) | (
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
      & MlQuotationInfo_PcgQuotation_Fragment
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & MlQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
    ) | (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
      & MlQuotationInfo_PanelsQuotation_Fragment
    ) | (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & MlQuotationInfo_MlQuotation_Fragment
    ) | (
      { __typename?: 'TerrorismQuotation' }
      & Pick<TerrorismQuotation, 'id'>
      & MlQuotationInfo_TerrorismQuotation_Fragment
    ) | (
      { __typename?: 'BOPQuotation' }
      & Pick<BopQuotation, 'id'>
      & MlQuotationInfo_BopQuotation_Fragment
    )>> }
  )>, participations: (
    { __typename?: 'ParticipationConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>>, externalCarrier?: Maybe<(
        { __typename?: 'ExternalCarrier' }
        & Pick<ExternalCarrier, 'id' | 'name'>
      )>, user?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName' | 'email' | 'firstName' | 'lastName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName' | 'email' | 'firstName' | 'lastName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName' | 'email' | 'firstName' | 'lastName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'email' | 'firstName' | 'lastName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      )>, collaborator?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'firstName' | 'api'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'firstName' | 'api'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'firstName' | 'api'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'api'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      )>, discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & MessagesFragment
      )>, quotations: (
        { __typename?: 'QuotationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CyberQuotation' }
          & Pick<CyberQuotation, 'id'>
          & MlQuotationInfo_CyberQuotation_Fragment
        ) | (
          { __typename?: 'PCGQuotation' }
          & Pick<PcgQuotation, 'id'>
          & MlQuotationInfo_PcgQuotation_Fragment
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesQuotation' }
          & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          & MlQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
        ) | (
          { __typename?: 'PanelsQuotation' }
          & Pick<PanelsQuotation, 'id'>
          & MlQuotationInfo_PanelsQuotation_Fragment
        ) | (
          { __typename?: 'MLQuotation' }
          & Pick<MlQuotation, 'id'>
          & { participation: (
            { __typename?: 'Participation' }
            & Pick<Participation, 'id'>
            & { externalCarrier?: Maybe<(
              { __typename?: 'ExternalCarrier' }
              & Pick<ExternalCarrier, 'id' | 'name'>
            )> }
          ) }
          & MlQuotationInfo_MlQuotation_Fragment
        ) | (
          { __typename?: 'TerrorismQuotation' }
          & Pick<TerrorismQuotation, 'id'>
          & MlQuotationInfo_TerrorismQuotation_Fragment
        ) | (
          { __typename?: 'BOPQuotation' }
          & Pick<BopQuotation, 'id'>
          & MlQuotationInfo_BopQuotation_Fragment
        )>>> }
      ) }
    )>>> }
  ) }
);

export type NetworkContactFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'fullName' | 'firstName' | 'email' | 'lastName' | 'jobTitle' | 'avatarUrl' | 'companyName'>
);

type PanelQuotationInfo_CyberQuotation_Fragment = (
  { __typename?: 'CyberQuotation' }
  & Pick<CyberQuotation, 'id' | 'type' | 'selected' | 'admitted' | 'indication' | 'declinationReason' | 'status' | 'statusMessage' | 'deductible' | 'fee' | 'limit' | 'requestedLimit' | 'requestedDeductible' | 'policyNumber' | 'premium' | 'signingUrl' | 'surplusLinesTax' | 'totalCost' | 'extractedAt' | 'reviewedAt' | 'underwriterFirstName' | 'underwriterLastName' | 'underwriterEmail' | 'coverageStartDate' | 'retailCommissionPercentage' | 'retailCommission'>
  & { endorsements?: Maybe<Array<Maybe<(
    { __typename?: 'CyberEndorsement' }
    & Pick<CyberEndorsement, 'name' | 'limit' | 'coinsurance'>
  )>>>, additionalFeatures?: Maybe<Array<Maybe<(
    { __typename?: 'CyberAdditionalFeature' }
    & Pick<CyberAdditionalFeature, 'title' | 'description'>
  )>>>, coverages?: Maybe<Array<Maybe<(
    { __typename?: 'CyberCoverage' }
    & Pick<CyberCoverage, 'name' | 'type' | 'limit' | 'deductible' | 'waitingPeriod'>
  )>>>, externalCarrier?: Maybe<(
    { __typename?: 'ExternalCarrier' }
    & Pick<ExternalCarrier, 'id' | 'name'>
  )>, documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, participation: (
    { __typename?: 'Participation' }
    & Pick<Participation, 'id'>
    & { discussion?: Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
      & { participants: (
        { __typename?: 'UserConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        )>>> }
      ) }
    )>, user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    )> }
  ) }
  & CyberQuotedLinesInfoFragment
  & MlQuotationInfo_CyberQuotation_Fragment
);

type PanelQuotationInfo_PcgQuotation_Fragment = (
  { __typename?: 'PCGQuotation' }
  & Pick<PcgQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, participation: (
    { __typename?: 'Participation' }
    & Pick<Participation, 'id'>
    & { discussion?: Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
      & { participants: (
        { __typename?: 'UserConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        )>>> }
      ) }
    )>, user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    )> }
  ) }
  & MlQuotationInfo_PcgQuotation_Fragment
);

type PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment = (
  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
  & Pick<NfpRepsAndWarrantiesQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, participation: (
    { __typename?: 'Participation' }
    & Pick<Participation, 'id'>
    & { discussion?: Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
      & { participants: (
        { __typename?: 'UserConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        )>>> }
      ) }
    )>, user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    )> }
  ) }
  & MlQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
);

type PanelQuotationInfo_PanelsQuotation_Fragment = (
  { __typename?: 'PanelsQuotation' }
  & Pick<PanelsQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, participation: (
    { __typename?: 'Participation' }
    & Pick<Participation, 'id'>
    & { discussion?: Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
      & { participants: (
        { __typename?: 'UserConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        )>>> }
      ) }
    )>, user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    )> }
  ) }
  & MlQuotationInfo_PanelsQuotation_Fragment
);

type PanelQuotationInfo_MlQuotation_Fragment = (
  { __typename?: 'MLQuotation' }
  & Pick<MlQuotation, 'id' | 'status'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, participation: (
    { __typename?: 'Participation' }
    & Pick<Participation, 'id'>
    & { discussion?: Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
      & { participants: (
        { __typename?: 'UserConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        )>>> }
      ) }
    )>, user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    )> }
  ) }
  & MlQuotationInfo_MlQuotation_Fragment
);

type PanelQuotationInfo_TerrorismQuotation_Fragment = (
  { __typename?: 'TerrorismQuotation' }
  & Pick<TerrorismQuotation, 'id' | 'type' | 'declinationReason' | 'status' | 'admitted' | 'selected' | 'requestedLimit' | 'requestedDeductible' | 'limit' | 'deductible' | 'underwriterFirstName' | 'underwriterLastName' | 'underwriterEmail'>
  & { insuringAgreements?: Maybe<Array<Maybe<(
    { __typename?: 'TerrorismInsuringAgreement' }
    & Pick<TerrorismInsuringAgreement, 'name' | 'value'>
  )>>>, documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, participation: (
    { __typename?: 'Participation' }
    & Pick<Participation, 'id'>
    & { discussion?: Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
      & { participants: (
        { __typename?: 'UserConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        )>>> }
      ) }
    )>, user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    )> }
  ) }
  & TerrorismQuotedLinesInfoFragment
  & MlQuotationInfo_TerrorismQuotation_Fragment
);

type PanelQuotationInfo_BopQuotation_Fragment = (
  { __typename?: 'BOPQuotation' }
  & Pick<BopQuotation, 'id' | 'carrierName' | 'generalLiabilityPremium' | 'overallPropertyPremium' | 'status' | 'generalLiability' | 'property' | 'dynamic' | 'disclaimer' | 'portalUrl'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, participation: (
    { __typename?: 'Participation' }
    & Pick<Participation, 'id'>
    & { discussion?: Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id'>
      & { participants: (
        { __typename?: 'UserConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        )>>> }
      ) }
    )>, user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'lastName'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    )> }
  ) }
  & MlQuotationInfo_BopQuotation_Fragment
);

export type PanelQuotationInfoFragment = PanelQuotationInfo_CyberQuotation_Fragment | PanelQuotationInfo_PcgQuotation_Fragment | PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment | PanelQuotationInfo_PanelsQuotation_Fragment | PanelQuotationInfo_MlQuotation_Fragment | PanelQuotationInfo_TerrorismQuotation_Fragment | PanelQuotationInfo_BopQuotation_Fragment;

export type PanelSubmissionInfoFragment = (
  { __typename?: 'PanelSubmission' }
  & Pick<PanelSubmission, 'id' | 'name' | 'status' | 'panel_info' | 'updatedAt' | 'message'>
  & { owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Reinsurer_Fragment
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Cedent_Fragment
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Broker_Fragment
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_NfpRepsAndWarrantiesBroker_Fragment
  ), panel?: Maybe<(
    { __typename?: 'Panel' }
    & Pick<Panel, 'id' | 'lineOfBusiness' | 'configuration' | 'displayTax'>
  )>, collaborations: (
    { __typename?: 'CollaborationConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'BrokerInvite' }
      & Pick<BrokerInvite, 'id' | 'type'>
      & { user: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ), discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & { participants: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
          )>>> }
        ) }
        & MessagesFragment
      )> }
    ) | (
      { __typename?: 'BrokerCollaboration' }
      & Pick<BrokerCollaboration, 'id' | 'type'>
      & { user: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ), discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & { participants: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
          )>>> }
        ) }
        & MessagesFragment
      )> }
    ) | (
      { __typename?: 'ClientCollaboration' }
      & Pick<ClientCollaboration, 'id' | 'type'>
      & { user: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ), discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & { participants: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
          )>>> }
        ) }
        & MessagesFragment
      )> }
    ) | (
      { __typename?: 'MgaCollaboration' }
      & Pick<MgaCollaboration, 'id' | 'type'>
      & { user: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ), discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & { participants: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
          )>>> }
        ) }
        & MessagesFragment
      )> }
    ) | (
      { __typename?: 'WholesalerCollaboration' }
      & Pick<WholesalerCollaboration, 'id' | 'type'>
      & { user: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ), discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & { participants: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
          )>>> }
        ) }
        & MessagesFragment
      )> }
    )>>> }
  ), applicant?: Maybe<(
    { __typename?: 'CommercialApplicant' }
    & Pick<CommercialApplicant, 'id' | 'company_name' | 'naics_code' | 'name' | 'email' | 'phone_number'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'street_line1' | 'street_line2' | 'city' | 'state' | 'zip_code'>
    )> }
  )>, surplusLinesLicenseInfo?: Maybe<(
    { __typename?: 'SurplusLinesType' }
    & Pick<SurplusLinesType, 'expiration_date' | 'license_holder' | 'license_number' | 'state'>
    & { address?: Maybe<(
      { __typename?: 'LicenseAddressType' }
      & Pick<LicenseAddressType, 'street_line' | 'state' | 'city' | 'zip_code'>
    )> }
  )>, email_carriers: Array<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id'>
  )>, products: Array<(
    { __typename?: 'BOPProduct' }
    & Pick<BopProduct, 'step' | 'ivans_basic_appetite' | 'ivans_enhanced_appetite' | 'form_current_state' | 'skip_basic_appetite_capture' | 'id' | 'status'>
    & { basic_appetite: BopProduct['basic_appetite_data'], enhanced_appetite: BopProduct['enhanced_appetite_data'] }
    & { selectedPanelists?: Maybe<Array<(
      { __typename?: 'SelectedPanelistsType' }
      & Pick<SelectedPanelistsType, 'id' | 'auth_id'>
    )>>, basic_appetite_capture_error?: Maybe<(
      { __typename?: 'BasicAppetiteCaptureErrorType' }
      & Pick<BasicAppetiteCaptureErrorType, 'title' | 'subtitle' | 'errors' | 'message'>
    )>, enhanced_appetite_error?: Maybe<(
      { __typename?: 'EnhancedAppetiteErrorType' }
      & Pick<EnhancedAppetiteErrorType, 'title' | 'subtitle' | 'errors' | 'message'>
    )>, panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'lineOfBusiness' | 'configuration'>
      & { panelistConfigurations: (
        { __typename?: 'PanelistConfigurationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'PanelistConfiguration' }
          & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
          & { panelist: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'firstName' | 'mfaCorporateEmailRequired' | 'mfaCorporateNetworksRequired' | 'mfaRemoteNetworkAccessRequired' | 'websiteOptional' | 'indicationProvided' | 'lastName' | 'avatarUrl' | 'type'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'logoUrl' | 'name'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          ), carrierAuthentications?: Maybe<(
            { __typename?: 'CarrierAuthenticationConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'CarrierAuthentication' }
              & Pick<CarrierAuthentication, 'id' | 'nickname' | 'default'>
            )>>> }
          )> }
        )>>> }
      ) }
    )>, quotations?: Maybe<Array<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & PanelQuotationInfo_CyberQuotation_Fragment
    ) | (
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
      & PanelQuotationInfo_PcgQuotation_Fragment
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
    ) | (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
      & PanelQuotationInfo_PanelsQuotation_Fragment
    ) | (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & PanelQuotationInfo_MlQuotation_Fragment
    ) | (
      { __typename?: 'TerrorismQuotation' }
      & Pick<TerrorismQuotation, 'id'>
      & PanelQuotationInfo_TerrorismQuotation_Fragment
    ) | (
      { __typename?: 'BOPQuotation' }
      & Pick<BopQuotation, 'id'>
      & PanelQuotationInfo_BopQuotation_Fragment
    )>> }
  ) | (
    { __typename?: 'CyberProduct' }
    & Pick<CyberProduct, 'limit' | 'website' | 'deductible' | 'annual_revenue' | 'number_of_records' | 'selected_panelists' | 'coverage_start_date' | 'number_of_employees' | 'id' | 'status'>
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'lineOfBusiness' | 'configuration'>
      & { panelistConfigurations: (
        { __typename?: 'PanelistConfigurationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'PanelistConfiguration' }
          & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
          & { panelist: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'firstName' | 'mfaCorporateEmailRequired' | 'mfaCorporateNetworksRequired' | 'mfaRemoteNetworkAccessRequired' | 'websiteOptional' | 'indicationProvided' | 'lastName' | 'avatarUrl' | 'type'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'logoUrl' | 'name'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          ), carrierAuthentications?: Maybe<(
            { __typename?: 'CarrierAuthenticationConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'CarrierAuthentication' }
              & Pick<CarrierAuthentication, 'id' | 'nickname' | 'default'>
            )>>> }
          )> }
        )>>> }
      ) }
    )>, quotations?: Maybe<Array<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & PanelQuotationInfo_CyberQuotation_Fragment
    ) | (
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
      & PanelQuotationInfo_PcgQuotation_Fragment
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
    ) | (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
      & PanelQuotationInfo_PanelsQuotation_Fragment
    ) | (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & PanelQuotationInfo_MlQuotation_Fragment
    ) | (
      { __typename?: 'TerrorismQuotation' }
      & Pick<TerrorismQuotation, 'id'>
      & PanelQuotationInfo_TerrorismQuotation_Fragment
    ) | (
      { __typename?: 'BOPQuotation' }
      & Pick<BopQuotation, 'id'>
      & PanelQuotationInfo_BopQuotation_Fragment
    )>> }
  ) | (
    { __typename?: 'ManagementLiabilityProduct' }
    & Pick<ManagementLiabilityProduct, 'id' | 'status'>
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'lineOfBusiness' | 'configuration'>
      & { panelistConfigurations: (
        { __typename?: 'PanelistConfigurationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'PanelistConfiguration' }
          & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
          & { panelist: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'firstName' | 'mfaCorporateEmailRequired' | 'mfaCorporateNetworksRequired' | 'mfaRemoteNetworkAccessRequired' | 'websiteOptional' | 'indicationProvided' | 'lastName' | 'avatarUrl' | 'type'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'logoUrl' | 'name'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          ), carrierAuthentications?: Maybe<(
            { __typename?: 'CarrierAuthenticationConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'CarrierAuthentication' }
              & Pick<CarrierAuthentication, 'id' | 'nickname' | 'default'>
            )>>> }
          )> }
        )>>> }
      ) }
    )>, quotations?: Maybe<Array<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & PanelQuotationInfo_CyberQuotation_Fragment
    ) | (
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
      & PanelQuotationInfo_PcgQuotation_Fragment
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
    ) | (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
      & PanelQuotationInfo_PanelsQuotation_Fragment
    ) | (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & PanelQuotationInfo_MlQuotation_Fragment
    ) | (
      { __typename?: 'TerrorismQuotation' }
      & Pick<TerrorismQuotation, 'id'>
      & PanelQuotationInfo_TerrorismQuotation_Fragment
    ) | (
      { __typename?: 'BOPQuotation' }
      & Pick<BopQuotation, 'id'>
      & PanelQuotationInfo_BopQuotation_Fragment
    )>> }
    & MlProductInfoFragment
  ) | (
    { __typename?: 'TerrorismProduct' }
    & Pick<TerrorismProduct, 'carriers' | 'occupancy_type' | 'additional_active_assailant' | 'coverage_start_date' | 'coverage_end_date' | 'deductible' | 'general_liability' | 'general_liability_limit' | 'add_policy_limit' | 'limit' | 'target_price' | 'id' | 'status'>
    & { history?: Maybe<(
      { __typename?: 'HistoryType' }
      & Pick<HistoryType, 'number_of_previous_losses' | 'previous_losses' | 'previous_threat' | 'total_loss_value'>
    )>, locations?: Maybe<Array<(
      { __typename?: 'LocationsType' }
      & Pick<LocationsType, 'armed_guards' | 'business_interruption_limit' | 'city' | 'contents_stock' | 'crash_barrier_perimeter_fence' | 'property_damage_limit' | 'state' | 'street_number_and_name' | 'zip_code'>
    )>>, panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'lineOfBusiness' | 'configuration'>
      & { panelistConfigurations: (
        { __typename?: 'PanelistConfigurationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'PanelistConfiguration' }
          & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
          & { panelist: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'firstName' | 'mfaCorporateEmailRequired' | 'mfaCorporateNetworksRequired' | 'mfaRemoteNetworkAccessRequired' | 'websiteOptional' | 'indicationProvided' | 'lastName' | 'avatarUrl' | 'type'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'logoUrl' | 'name'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          ), carrierAuthentications?: Maybe<(
            { __typename?: 'CarrierAuthenticationConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'CarrierAuthentication' }
              & Pick<CarrierAuthentication, 'id' | 'nickname' | 'default'>
            )>>> }
          )> }
        )>>> }
      ) }
    )>, quotations?: Maybe<Array<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & PanelQuotationInfo_CyberQuotation_Fragment
    ) | (
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
      & PanelQuotationInfo_PcgQuotation_Fragment
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
    ) | (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
      & PanelQuotationInfo_PanelsQuotation_Fragment
    ) | (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & PanelQuotationInfo_MlQuotation_Fragment
    ) | (
      { __typename?: 'TerrorismQuotation' }
      & Pick<TerrorismQuotation, 'id'>
      & PanelQuotationInfo_TerrorismQuotation_Fragment
    ) | (
      { __typename?: 'BOPQuotation' }
      & Pick<BopQuotation, 'id'>
      & PanelQuotationInfo_BopQuotation_Fragment
    )>> }
  )>, participations: (
    { __typename?: 'ParticipationConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>>, externalCarrier?: Maybe<(
        { __typename?: 'ExternalCarrier' }
        & Pick<ExternalCarrier, 'id' | 'name'>
      )>, user?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName' | 'email' | 'firstName' | 'lastName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName' | 'email' | 'firstName' | 'lastName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName' | 'email' | 'firstName' | 'lastName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'email' | 'firstName' | 'lastName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      )>, collaborator?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'firstName' | 'api'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'firstName' | 'api'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'firstName' | 'api'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'api'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
          & { ambestRating?: Maybe<(
            { __typename?: 'AMBestRating' }
            & Pick<AmBestRating, 'id' | 'rating'>
          )> }
        ) }
      )>, discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & MessagesFragment
      )>, quotations: (
        { __typename?: 'QuotationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CyberQuotation' }
          & Pick<CyberQuotation, 'id'>
          & PanelQuotationInfo_CyberQuotation_Fragment
        ) | (
          { __typename?: 'PCGQuotation' }
          & Pick<PcgQuotation, 'id'>
          & PanelQuotationInfo_PcgQuotation_Fragment
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesQuotation' }
          & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          & PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
        ) | (
          { __typename?: 'PanelsQuotation' }
          & Pick<PanelsQuotation, 'id'>
          & PanelQuotationInfo_PanelsQuotation_Fragment
        ) | (
          { __typename?: 'MLQuotation' }
          & Pick<MlQuotation, 'id'>
          & PanelQuotationInfo_MlQuotation_Fragment
        ) | (
          { __typename?: 'TerrorismQuotation' }
          & Pick<TerrorismQuotation, 'id'>
          & PanelQuotationInfo_TerrorismQuotation_Fragment
        ) | (
          { __typename?: 'BOPQuotation' }
          & Pick<BopQuotation, 'id'>
          & PanelQuotationInfo_BopQuotation_Fragment
        )>>> }
      ) }
    )>>> }
  ) }
  & PanelsSubmissionFileCabinetFragment
);

export type PanelsSubmissionFileCabinetFragment = (
  { __typename?: 'PanelSubmission' }
  & Pick<PanelSubmission, 'id' | 'name'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, participations: (
    { __typename?: 'ParticipationConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>>, user?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      )>, quotations: (
        { __typename?: 'QuotationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CyberQuotation' }
          & Pick<CyberQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'PCGQuotation' }
          & Pick<PcgQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesQuotation' }
          & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'PanelsQuotation' }
          & Pick<PanelsQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'MLQuotation' }
          & Pick<MlQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'TerrorismQuotation' }
          & Pick<TerrorismQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        ) | (
          { __typename?: 'BOPQuotation' }
          & Pick<BopQuotation, 'id'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )> }
        )>>> }
      ) }
    )>>> }
  ) }
);

export type PcgSubmissionInfoFragment = (
  { __typename?: 'PCGSubmission' }
  & Pick<PcgSubmission, 'id' | 'name' | 'lineOfBusiness' | 'status'>
  & { coverages?: Maybe<Array<(
    { __typename?: 'PCGAutoCoverageType' }
    & Pick<PcgAutoCoverageType, 'id' | 'type'>
    & { vehicles?: Maybe<Array<(
      { __typename?: 'PCGAutoVehiclesType' }
      & Pick<PcgAutoVehiclesType, 'year' | 'make' | 'model'>
    )>> }
  ) | (
    { __typename?: 'PCGExcessCoverageType' }
    & Pick<PcgExcessCoverageType, 'id' | 'type'>
  ) | (
    { __typename?: 'PCGHomeCoverageType' }
    & Pick<PcgHomeCoverageType, 'id' | 'type'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'fullAddress' | 'streetLine1'>
    )> }
  ) | (
    { __typename?: 'PCGValuablesCoverageType' }
    & Pick<PcgValuablesCoverageType, 'id' | 'type'>
  )>>, owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Reinsurer_Fragment
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Cedent_Fragment
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_Broker_Fragment
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'jobTitle'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
    & Avatar_NfpRepsAndWarrantiesBroker_Fragment
  ), participations: (
    { __typename?: 'ParticipationConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id' | 'createdAt'>
      & { user?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'firstName' | 'email'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'firstName' | 'email'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'firstName' | 'email'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'email'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      )>, discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & MessagesFragment
      )>, quotations: (
        { __typename?: 'QuotationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CyberQuotation' }
          & Pick<CyberQuotation, 'id' | 'status' | 'selected'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'category' | 'version'>
            & DocumentForFormFragment
          )> }
        ) | (
          { __typename?: 'PCGQuotation' }
          & Pick<PcgQuotation, 'type' | 'declinationReason' | 'currentPolicy' | 'totalPremium' | 'id' | 'status' | 'selected'>
          & { externalCarrier?: Maybe<(
            { __typename?: 'ExternalCarrier' }
            & Pick<ExternalCarrier, 'id' | 'name' | 'logoUrl'>
          )>, quotedLines?: Maybe<(
            { __typename?: 'PCGQuotedLineTypeConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'PCGHomeQuotedLineType' }
              & Pick<PcgHomeQuotedLineType, 'totalPremium' | 'allOtherPerilDeductible' | 'id' | 'coverableId'>
              & { coverages?: Maybe<Array<(
                { __typename?: 'PCGHomeQuotedLineCoveragesType' }
                & Pick<PcgHomeQuotedLineCoveragesType, 'coverage'>
                & { homeCoverageLimit?: Maybe<(
                  { __typename?: 'MultiInputDropdownType' }
                  & Pick<MultiInputDropdownType, 'value' | 'type'>
                )>, deductible?: Maybe<(
                  { __typename?: 'MultiInputDropdownType' }
                  & Pick<MultiInputDropdownType, 'value' | 'type'>
                )> }
              )>>, additionalFeatures?: Maybe<Array<(
                { __typename?: 'PCGAdditionalFeature' }
                & Pick<PcgAdditionalFeature, 'feature' | 'included'>
              )>>, additionalOptions?: Maybe<Array<(
                { __typename?: 'PCGAdditionalOptionsType' }
                & Pick<PcgAdditionalOptionsType, 'option'>
                & { deductible?: Maybe<(
                  { __typename?: 'MultiInputDropdownType' }
                  & Pick<MultiInputDropdownType, 'value' | 'type'>
                )>, premium?: Maybe<(
                  { __typename?: 'MultiInputDropdownType' }
                  & Pick<MultiInputDropdownType, 'value' | 'type'>
                )> }
              )>>, enhancements?: Maybe<Array<(
                { __typename?: 'PCGHomeQuotedLineEnhancementsType' }
                & Pick<PcgHomeQuotedLineEnhancementsType, 'enhancement'>
                & { limit?: Maybe<(
                  { __typename?: 'MultiInputDropdownType' }
                  & Pick<MultiInputDropdownType, 'value' | 'type'>
                )>, deductible?: Maybe<(
                  { __typename?: 'MultiInputDropdownType' }
                  & Pick<MultiInputDropdownType, 'value' | 'type'>
                )>, premium?: Maybe<(
                  { __typename?: 'MultiInputDropdownType' }
                  & Pick<MultiInputDropdownType, 'value' | 'type'>
                )> }
              )>>, address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'id' | 'fullAddress'>
              )> }
            ) | (
              { __typename?: 'PCGValuablesQuotedLineType' }
              & Pick<PcgValuablesQuotedLineType, 'totalPremium' | 'id' | 'coverableId'>
              & { coverages?: Maybe<Array<(
                { __typename?: 'PCGValuablesQuotedLineCoveragesType' }
                & Pick<PcgValuablesQuotedLineCoveragesType, 'name' | 'limit' | 'blanketLimit'>
              )>>, additionalFeatures?: Maybe<Array<(
                { __typename?: 'PCGAdditionalFeature' }
                & Pick<PcgAdditionalFeature, 'feature' | 'included'>
              )>> }
            ) | (
              { __typename?: 'PCGAutoQuotedLineType' }
              & Pick<PcgAutoQuotedLineType, 'combinedLimit' | 'bodilyInjuryAndPropertyDamage' | 'bodilyInjuryPerPerson' | 'bodilyInjuryEachAccident' | 'propertyDamage' | 'totalPremium' | 'id' | 'coverableId'>
              & { agreedValues?: Maybe<Array<(
                { __typename?: 'PCGAutoAgreedValuesType' }
                & Pick<PcgAutoAgreedValuesType, 'vehicle' | 'value'>
              )>>, medicalPayments?: Maybe<(
                { __typename?: 'MultiInputDropdownType' }
                & Pick<MultiInputDropdownType, 'value' | 'type'>
              )>, underinsuredMotorists?: Maybe<(
                { __typename?: 'MultiInputDropdownType' }
                & Pick<MultiInputDropdownType, 'value' | 'type'>
              )>, extendedTowingAndLabor?: Maybe<(
                { __typename?: 'MultiInputDropdownType' }
                & Pick<MultiInputDropdownType, 'value' | 'type'>
              )>, fullGlassCoverage?: Maybe<(
                { __typename?: 'MultiInputDropdownType' }
                & Pick<MultiInputDropdownType, 'value' | 'type'>
              )>, collisionDeductible?: Maybe<(
                { __typename?: 'MultiInputDropdownType' }
                & Pick<MultiInputDropdownType, 'value' | 'type'>
              )>, comprehensiveDeductible?: Maybe<(
                { __typename?: 'MultiInputDropdownType' }
                & Pick<MultiInputDropdownType, 'value' | 'type'>
              )>, additionalCoverages?: Maybe<Array<(
                { __typename?: 'PCGAutoCoverageLimitsType' }
                & Pick<PcgAutoCoverageLimitsType, 'name' | 'premium'>
                & { limit?: Maybe<(
                  { __typename?: 'MultiInputDropdownType' }
                  & Pick<MultiInputDropdownType, 'value' | 'type'>
                )> }
              )>>, additionalFeatures?: Maybe<Array<(
                { __typename?: 'PCGAutoAdditionalFeaturesType' }
                & Pick<PcgAutoAdditionalFeaturesType, 'feature' | 'included'>
              )>> }
            ) | (
              { __typename?: 'PCGExcessQuotedLineType' }
              & Pick<PcgExcessQuotedLineType, 'totalPremium' | 'id' | 'coverableId'>
              & { coverages?: Maybe<Array<(
                { __typename?: 'PCGExcessQuotedLineCoveragesType' }
                & Pick<PcgExcessQuotedLineCoveragesType, 'coverage' | 'limit'>
              )>>, limitOptions?: Maybe<Array<(
                { __typename?: 'PCGExcessLimitOptionsType' }
                & Pick<PcgExcessLimitOptionsType, 'coverage' | 'limit' | 'totalPremium'>
              )>> }
            )>>> }
          )>, documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'category' | 'version'>
            & DocumentForFormFragment
          )> }
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesQuotation' }
          & Pick<NfpRepsAndWarrantiesQuotation, 'id' | 'status' | 'selected'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'category' | 'version'>
            & DocumentForFormFragment
          )> }
        ) | (
          { __typename?: 'PanelsQuotation' }
          & Pick<PanelsQuotation, 'id' | 'status' | 'selected'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'category' | 'version'>
            & DocumentForFormFragment
          )> }
        ) | (
          { __typename?: 'MLQuotation' }
          & Pick<MlQuotation, 'id' | 'status' | 'selected'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'category' | 'version'>
            & DocumentForFormFragment
          )> }
        ) | (
          { __typename?: 'TerrorismQuotation' }
          & Pick<TerrorismQuotation, 'id' | 'status' | 'selected'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'category' | 'version'>
            & DocumentForFormFragment
          )> }
        ) | (
          { __typename?: 'BOPQuotation' }
          & Pick<BopQuotation, 'id' | 'status' | 'selected'>
          & { documents: Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'category' | 'version'>
            & DocumentForFormFragment
          )> }
        )>>> }
      ) }
    )>>> }
  ) }
);

type Permissions_Reinsurer_Fragment = (
  { __typename?: 'Reinsurer' }
  & Pick<Reinsurer, 'id'>
  & { permissions: (
    { __typename?: 'PermissionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'area' | 'action' | 'metadata'>
    )>>> }
  ) }
);

type Permissions_Cedent_Fragment = (
  { __typename?: 'Cedent' }
  & Pick<Cedent, 'id'>
  & { permissions: (
    { __typename?: 'PermissionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'area' | 'action' | 'metadata'>
    )>>> }
  ) }
);

type Permissions_Broker_Fragment = (
  { __typename?: 'Broker' }
  & Pick<Broker, 'id'>
  & { permissions: (
    { __typename?: 'PermissionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'area' | 'action' | 'metadata'>
    )>>> }
  ) }
);

type Permissions_NfpRepsAndWarrantiesBroker_Fragment = (
  { __typename?: 'NFPRepsAndWarrantiesBroker' }
  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
  & { permissions: (
    { __typename?: 'PermissionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id' | 'area' | 'action' | 'metadata'>
    )>>> }
  ) }
);

export type PermissionsFragment = Permissions_Reinsurer_Fragment | Permissions_Cedent_Fragment | Permissions_Broker_Fragment | Permissions_NfpRepsAndWarrantiesBroker_Fragment;

export type ProfileFragment = (
  { __typename?: 'Account' }
  & Pick<Account, 'id' | 'firstName' | 'fullName' | 'jobTitle' | 'avatarUrl' | 'email' | 'phoneNumber' | 'linkedinUrl' | 'aboutMe' | 'bannerUrl' | 'locations' | 'linesOfBusiness' | 'industries' | 'companyName'>
);

type QAndA_Participation_Fragment = (
  { __typename?: 'Participation' }
  & Pick<Participation, 'id'>
  & { questions: (
    { __typename?: 'QuestionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & QuestionInfoFragment
    )>>> }
  ) }
);

type QAndA_Quote_Fragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id'>
  & { questions: (
    { __typename?: 'QuestionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & QuestionInfoFragment
    )>>> }
  ) }
);

type QAndA_NfpRepsAndWarrantiesQuotation_Fragment = (
  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
  & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
  & { questions: (
    { __typename?: 'QuestionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & QuestionInfoFragment
    )>>> }
  ) }
);

export type QAndAFragment = QAndA_Participation_Fragment | QAndA_Quote_Fragment | QAndA_NfpRepsAndWarrantiesQuotation_Fragment;

export type QuestionInfoFragment = (
  { __typename?: 'Question' }
  & Pick<Question, 'id' | 'text' | 'deadline' | 'section' | 'number'>
  & { documents?: Maybe<Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'category' | 'version'>
    & DocumentForFormFragment
  )>>, answer?: Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'text' | 'releasedAt' | 'acceptedAt'>
    & { documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'category' | 'version'>
      & DocumentForFormFragment
    )>> }
  )> }
);

export type SubjectivityInfoFragment = (
  { __typename?: 'Subjectivity' }
  & Pick<Subjectivity, 'id' | 'text' | 'deadline' | 'section' | 'number'>
  & { documents?: Maybe<Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'category' | 'version'>
    & DocumentForFormFragment
  )>>, answer?: Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'text' | 'acceptedAt'>
    & { documents?: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'category' | 'version'>
      & DocumentForFormFragment
    )>> }
  )> }
);

export type QuoteInfoFragmentFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id' | 'status' | 'releasedAt' | 'brokerComment' | 'companyName' | 'outdatedAt' | 'certificateNumber' | 'providerLastViewedAt' | 'manuallyTracked' | 'referenceNumber'>
  & { readableStatus: Quote['status'] }
  & { reinsurer?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'fullName' | 'companyName'>
    & Avatar_Reinsurer_Fragment
  )>, discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
    & { reinsurerMessages: (
      { __typename?: 'MessageConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Broadcast' }
        & Pick<Broadcast, 'id'>
        & { documents: (
          { __typename?: 'DocumentConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )>>> }
        ) }
        & Attachments_Broadcast_Fragment
      ) | (
        { __typename?: 'DirectMessage' }
        & Pick<DirectMessage, 'id'>
        & { documents: (
          { __typename?: 'DocumentConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )>>> }
        ) }
        & Attachments_DirectMessage_Fragment
      )>>> }
    ) }
    & MessagesFragment
  )>, revisions: (
    { __typename?: 'RevisionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'attachmentPoint' | 'brokerageCommission' | 'subjectivities' | 'cedingCommission' | 'deductible' | 'exclusions' | 'expiryDate' | 'isIncomplete' | 'keyPerils' | 'layerLimit' | 'netPremium' | 'number' | 'participation' | 'premium' | 'source' | 'status'>
      & { customFields: Array<(
        { __typename?: 'DateField' }
        & Pick<DateField, 'name' | 'type'>
      ) | (
        { __typename?: 'DateTimeField' }
        & Pick<DateTimeField, 'name' | 'type'>
      ) | (
        { __typename?: 'DecimalField' }
        & Pick<DecimalField, 'name' | 'type'>
      ) | (
        { __typename?: 'IntegerField' }
        & Pick<IntegerField, 'name' | 'type'>
      ) | (
        { __typename?: 'MonetaryField' }
        & Pick<MonetaryField, 'name' | 'type'>
      ) | (
        { __typename?: 'SelectField' }
        & Pick<SelectField, 'name' | 'type'>
      ) | (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'name' | 'type'>
      )>, quotedLayers: Array<(
        { __typename?: 'QuotedLayer' }
        & Pick<QuotedLayer, 'id'>
        & QuotedLayerInfoFragment
      )>, documents: (
        { __typename?: 'DocumentConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id'>
          & DocumentFragment
        )>>> }
      ), zip?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url'>
      )> }
    )>>> }
  ), cedentZip?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url'>
  )>, reinsurerZip?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url'>
  )>, owner?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'fullName'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
    ) }
  )>, worksheet: (
    { __typename?: 'Worksheet' }
    & Pick<Worksheet, 'id' | 'name' | 'demo' | 'kind' | 'address' | 'namedInsured' | 'industryType' | 'lineOfBusiness' | 'clientName' | 'showTower' | 'certificateNumber' | 'perOccurrenceLimit' | 'isBrokered' | 'lossHistory' | 'cope' | 'basisOfAcceptance' | 'source' | 'totalInsuredValue' | 'underwriterName' | 'reinsuranceEffectiveDate' | 'reinsuranceExpiryDate' | 'keyPerils' | 'exclusions' | 'updatedAt'>
    & { updatedFields?: Maybe<(
      { __typename?: 'UpdatedFields' }
      & { name?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, address?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, namedInsured?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, underwriterName?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, certificateNumber?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, clientName?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, description?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, industryType?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, customFields?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, insurancePolicy?: Maybe<(
        { __typename?: 'UpdatedFieldsInsurancePolicy' }
        & { expiryDate?: Maybe<(
          { __typename?: 'UpdatedFieldType' }
          & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
        )>, effectiveDate?: Maybe<(
          { __typename?: 'UpdatedFieldType' }
          & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
        )> }
      )>, participationComment?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, participationSubject?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, reinsuranceEffectiveDate?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, reinsuranceExpiryDate?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, lossHistory?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, cope?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, basisOfAcceptance?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, source?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, showTower?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )>, riskDocuments?: Maybe<(
        { __typename?: 'UpdatedFieldType' }
        & Pick<UpdatedFieldType, 'updatedAt' | 'oldValue'>
      )> }
    )>, insurancePolicy?: Maybe<(
      { __typename?: 'InsurancePolicy' }
      & Pick<InsurancePolicy, 'id' | 'number' | 'expiryDate' | 'effectiveDate'>
      & { coverages: (
        { __typename?: 'PolicyCoverageConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CasualtyCoverage' }
          & Pick<CasualtyCoverage, 'id' | 'type'>
        ) | (
          { __typename?: 'PropertyCoverage' }
          & Pick<PropertyCoverage, 'id' | 'type'>
        )>>> }
      ) }
    )>, owner: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'avatarUrl' | 'companyName' | 'fullName' | 'type'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'avatarUrl' | 'companyName' | 'fullName' | 'type'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'avatarUrl' | 'companyName' | 'fullName' | 'type'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'avatarUrl' | 'companyName' | 'fullName' | 'type'>
    ), broadcasts?: Maybe<(
      { __typename?: 'BroadcastConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Broadcast' }
        & Pick<Broadcast, 'id'>
        & Message_Broadcast_Fragment
      )>>> }
    )> }
    & WorksheetInformationDocumentsFragment
    & TowerFragment
    & TowerSummaryFragment
  ), appendices?: Maybe<(
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  )>, binder?: Maybe<(
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  )>, binderDraft?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, binderWordDraft?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, mergedBinder?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, certificateDraft?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, certificateWordDraft?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id'>
    & DocumentFragment
  )>, certificate?: Maybe<(
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  )> }
  & LayerFragment
  & WorksheetQuoteInformationDocumentsFragment
  & RevisionPerilsFragment
  & CedentQuoteActionsFragment
);

export type QuotedLayerInfoFragment = (
  { __typename?: 'QuotedLayer' }
  & Pick<QuotedLayer, 'id' | 'attachmentPoint' | 'cedingCommission' | 'deductible' | 'layerLimit' | 'netPremium' | 'note' | 'participation' | 'premium' | 'isIncomplete' | 'declinedAt' | 'keyPerils' | 'exclusions'>
  & { policyCoverage?: Maybe<(
    { __typename?: 'CasualtyCoverage' }
    & Pick<CasualtyCoverage, 'id'>
  ) | (
    { __typename?: 'PropertyCoverage' }
    & Pick<PropertyCoverage, 'id'>
  )> }
);

export type RevisionPerilsFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id'>
  & { revisionPerils: (
    { __typename?: 'RevisionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'keyPerils' | 'exclusions'>
      & { quotedLayers: Array<(
        { __typename?: 'QuotedLayer' }
        & Pick<QuotedLayer, 'id'>
        & QuotedLayerInfoFragment
      )> }
    )>>> }
  ) }
);

export type SubmissionInfoFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id' | 'name' | 'namedInsured' | 'namedInsuredAddress' | 'address' | 'locationDescription' | 'status' | 'currency' | 'issuingCompany' | 'description' | 'coverageType' | 'contractType' | 'industryType' | 'kind' | 'lineOfBusiness' | 'showTower' | 'lossHistory' | 'cope' | 'basisOfAcceptance' | 'source' | 'companyRetention' | 'policyType' | 'policyLinesOfBusiness' | 'reinsuranceEffectiveDate' | 'demo' | 'reinsuranceExpiryDate' | 'isRenewal' | 'totalInsuredValue' | 'isQuotable' | 'keyPerils' | 'exclusions' | 'isBrokered' | 'brokeredOrInvited' | 'brokerInvitationStatus' | 'participationSubject' | 'participationComment' | 'insuredPropertyMajorTypes' | 'majorCatastropheExposures' | 'multiTower' | 'canEndorse' | 'commissionPercentage' | 'clientName' | 'underwriterName' | 'certificateNumber' | 'internalReferenceNumber' | 'needByDate'>
  & { schemas?: Maybe<(
    { __typename?: 'WorksheetsSchema' }
    & Pick<WorksheetsSchema, 'autoLiabilityUnderwritingLoss' | 'endorsement'>
  )>, statementsOfValues: Array<(
    { __typename?: 'StatementOfValues' }
    & Pick<StatementOfValues, 'perils' | 'excludedPerils'>
    & { valueEntries: Array<(
      { __typename?: 'StatementOfValuesEntry' }
      & Pick<StatementOfValuesEntry, 'cover' | 'value'>
    )> }
  )>, sublimits: Array<(
    { __typename?: 'Sublimit' }
    & Pick<Sublimit, 'peril' | 'limit'>
  )>, customFields: Array<(
    { __typename?: 'DateField' }
    & Pick<DateField, 'name' | 'type'>
  ) | (
    { __typename?: 'DateTimeField' }
    & Pick<DateTimeField, 'name' | 'type'>
  ) | (
    { __typename?: 'DecimalField' }
    & Pick<DecimalField, 'name' | 'type'>
  ) | (
    { __typename?: 'IntegerField' }
    & Pick<IntegerField, 'name' | 'type'>
  ) | (
    { __typename?: 'MonetaryField' }
    & Pick<MonetaryField, 'name' | 'type'>
  ) | (
    { __typename?: 'SelectField' }
    & Pick<SelectField, 'name' | 'type'>
  ) | (
    { __typename?: 'StringField' }
    & Pick<StringField, 'value' | 'name' | 'type'>
  )>, client?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id'>
  )>, owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'type' | 'emailSignOff'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'logoUrl'>
    ) }
    & Avatar_Reinsurer_Fragment
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'type' | 'emailSignOff'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'logoUrl'>
    ) }
    & Avatar_Cedent_Fragment
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'type' | 'emailSignOff'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'logoUrl'>
    ) }
    & Avatar_Broker_Fragment
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'type' | 'emailSignOff'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name' | 'logoUrl'>
    ) }
    & Avatar_NfpRepsAndWarrantiesBroker_Fragment
  ), discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
    & MessagesFragment
  )>, documents: (
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  ), collaborations: Array<(
    { __typename?: 'BrokerInvite' }
    & Pick<BrokerInvite, 'id' | 'status' | 'type'>
    & { user: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    ) }
  ) | (
    { __typename?: 'BrokerCollaboration' }
    & Pick<BrokerCollaboration, 'id' | 'status' | 'type'>
    & { user: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    ) }
  ) | (
    { __typename?: 'ClientCollaboration' }
    & Pick<ClientCollaboration, 'id' | 'status' | 'type'>
    & { user: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    ) }
  ) | (
    { __typename?: 'MgaCollaboration' }
    & Pick<MgaCollaboration, 'id' | 'status' | 'type'>
    & { user: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    ) }
  ) | (
    { __typename?: 'WholesalerCollaboration' }
    & Pick<WholesalerCollaboration, 'id' | 'status' | 'type'>
    & { user: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    ) }
  )>, permissions: (
    { __typename?: 'EntityPermission' }
    & Pick<EntityPermission, 'edit' | 'manage'>
  ), insurancePolicy?: Maybe<(
    { __typename?: 'InsurancePolicy' }
    & Pick<InsurancePolicy, 'id' | 'number' | 'insurer' | 'description' | 'effectiveDate' | 'expiryDate' | 'premium' | 'selfInsuredRetention' | 'perOccurrenceLimit'>
    & { underlyingPolicies: (
      { __typename?: 'InsurancePolicyConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'InsurancePolicy' }
        & Pick<InsurancePolicy, 'id' | 'number' | 'description' | 'perOccurrenceLimit'>
        & { coverages: (
          { __typename?: 'PolicyCoverageConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'CasualtyCoverage' }
            & Pick<CasualtyCoverage, 'basis' | 'extendedReportingPeriod' | 'retroactiveDate' | 'keyPerils' | 'exclusions' | 'id' | 'kind' | 'type' | 'limit' | 'aggregateLimit' | 'perOccurrenceLimit' | 'deductible' | 'stringDeductible' | 'description' | 'comment'>
            & { underwriting?: Maybe<(
              { __typename?: 'CasualtyUnderwriting' }
              & Pick<CasualtyUnderwriting, 'id' | 'garaging' | 'radiusLocal' | 'radiusIntermediate' | 'radiusLongHaul' | 'additionalInformation' | 'hiredNonOwnedExposure' | 'excessOfAmount' | 'currentExposureTotal' | 'otherCurrentExposureTotal'>
              & { currentExposure?: Maybe<(
                { __typename?: 'CurrentExposureType' }
                & Pick<CurrentExposureType, 'ppt' | 'heavy' | 'xHeavy' | 'light' | 'tractors' | 'other' | 'vans' | 'buses'>
              )>, historicalExposure?: Maybe<Array<(
                { __typename?: 'HistoricalExposureType' }
                & Pick<HistoricalExposureType, 'year' | 'powerUnits'>
              )>>, largeLosses?: Maybe<Array<(
                { __typename?: 'LargeLossesType' }
                & Pick<LargeLossesType, 'dateOfLoss' | 'totalIncurred' | 'status' | 'description'>
              )>>, lossInformation?: Maybe<Array<(
                { __typename?: 'LossInformationType' }
                & Pick<LossInformationType, 'year' | 'numberOfClaims' | 'totalIncurred' | 'totalPaid' | 'valuationDate'>
              )>> }
            )>, documents?: Maybe<(
              { __typename?: 'DocumentConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Document' }
                & Pick<Document, 'id' | 'url' | 'type'>
                & { name: Document['filename'], size: Document['byteSize'] }
              )>>> }
            )>, lines?: Maybe<Array<(
              { __typename?: 'LineType' }
              & Pick<LineType, 'id' | 'attachmentPoint' | 'cedingCommission' | 'deductible' | 'layerLimit' | 'netPremium' | 'note' | 'participation' | 'premium'>
            )>>, customFields: Array<(
              { __typename?: 'DateField' }
              & Pick<DateField, 'name' | 'type'>
            ) | (
              { __typename?: 'DateTimeField' }
              & Pick<DateTimeField, 'name' | 'type'>
            ) | (
              { __typename?: 'DecimalField' }
              & Pick<DecimalField, 'name' | 'type'>
            ) | (
              { __typename?: 'IntegerField' }
              & Pick<IntegerField, 'name' | 'type'>
            ) | (
              { __typename?: 'MonetaryField' }
              & Pick<MonetaryField, 'name' | 'type'>
            ) | (
              { __typename?: 'SelectField' }
              & Pick<SelectField, 'name' | 'type'>
            ) | (
              { __typename?: 'StringField' }
              & Pick<StringField, 'value' | 'name' | 'type'>
            )> }
          ) | (
            { __typename?: 'PropertyCoverage' }
            & Pick<PropertyCoverage, 'keyPerils' | 'exclusions' | 'insuredPropertyMajorTypes' | 'majorCatastropheExposures' | 'id' | 'kind' | 'type' | 'limit' | 'aggregateLimit' | 'perOccurrenceLimit' | 'deductible' | 'stringDeductible' | 'description' | 'comment'>
            & { statementsOfValues: Array<(
              { __typename?: 'StatementOfValues' }
              & Pick<StatementOfValues, 'perils' | 'excludedPerils'>
              & { valueEntries: Array<(
                { __typename?: 'StatementOfValuesEntry' }
                & Pick<StatementOfValuesEntry, 'cover' | 'value'>
              )> }
            )>, sublimits: Array<(
              { __typename?: 'Sublimit' }
              & Pick<Sublimit, 'peril' | 'limit'>
            )>, documents?: Maybe<(
              { __typename?: 'DocumentConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Document' }
                & Pick<Document, 'id' | 'url' | 'type'>
                & { name: Document['filename'], size: Document['byteSize'] }
              )>>> }
            )>, lines?: Maybe<Array<(
              { __typename?: 'LineType' }
              & Pick<LineType, 'id' | 'attachmentPoint' | 'cedingCommission' | 'deductible' | 'layerLimit' | 'netPremium' | 'note' | 'participation' | 'premium'>
            )>>, customFields: Array<(
              { __typename?: 'DateField' }
              & Pick<DateField, 'name' | 'type'>
            ) | (
              { __typename?: 'DateTimeField' }
              & Pick<DateTimeField, 'name' | 'type'>
            ) | (
              { __typename?: 'DecimalField' }
              & Pick<DecimalField, 'name' | 'type'>
            ) | (
              { __typename?: 'IntegerField' }
              & Pick<IntegerField, 'name' | 'type'>
            ) | (
              { __typename?: 'MonetaryField' }
              & Pick<MonetaryField, 'name' | 'type'>
            ) | (
              { __typename?: 'SelectField' }
              & Pick<SelectField, 'name' | 'type'>
            ) | (
              { __typename?: 'StringField' }
              & Pick<StringField, 'value' | 'name' | 'type'>
            )> }
          )>>> }
        ) }
      )>>> }
    ), coverages: (
      { __typename?: 'PolicyCoverageConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'CasualtyCoverage' }
        & Pick<CasualtyCoverage, 'basis' | 'extendedReportingPeriod' | 'retroactiveDate' | 'keyPerils' | 'exclusions' | 'id' | 'kind' | 'type' | 'limit' | 'aggregateLimit' | 'perOccurrenceLimit' | 'deductible' | 'description' | 'comment'>
        & { underwriting?: Maybe<(
          { __typename?: 'CasualtyUnderwriting' }
          & Pick<CasualtyUnderwriting, 'id' | 'garaging' | 'radiusLocal' | 'radiusIntermediate' | 'radiusLongHaul' | 'additionalInformation' | 'hiredNonOwnedExposure' | 'excessOfAmount'>
          & { currentExposure?: Maybe<(
            { __typename?: 'CurrentExposureType' }
            & Pick<CurrentExposureType, 'ppt' | 'heavy' | 'xHeavy' | 'light' | 'tractors'>
          )>, historicalExposure?: Maybe<Array<(
            { __typename?: 'HistoricalExposureType' }
            & Pick<HistoricalExposureType, 'year' | 'powerUnits'>
          )>>, largeLosses?: Maybe<Array<(
            { __typename?: 'LargeLossesType' }
            & Pick<LargeLossesType, 'dateOfLoss' | 'totalIncurred' | 'status' | 'description'>
          )>>, lossInformation?: Maybe<Array<(
            { __typename?: 'LossInformationType' }
            & Pick<LossInformationType, 'year' | 'numberOfClaims' | 'totalIncurred' | 'totalPaid' | 'valuationDate'>
          )>> }
        )>, documents?: Maybe<(
          { __typename?: 'DocumentConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'url' | 'type'>
            & { name: Document['filename'], size: Document['byteSize'] }
          )>>> }
        )>, lines?: Maybe<Array<(
          { __typename?: 'LineType' }
          & Pick<LineType, 'id' | 'attachmentPoint' | 'cedingCommission' | 'deductible' | 'layerLimit' | 'netPremium' | 'note' | 'participation' | 'premium'>
          & { quotes: (
            { __typename?: 'QuoteConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & { reinsurer?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
              )> }
            )>>> }
          ) }
        )>>, customFields: Array<(
          { __typename?: 'DateField' }
          & Pick<DateField, 'name' | 'type'>
        ) | (
          { __typename?: 'DateTimeField' }
          & Pick<DateTimeField, 'name' | 'type'>
        ) | (
          { __typename?: 'DecimalField' }
          & Pick<DecimalField, 'name' | 'type'>
        ) | (
          { __typename?: 'IntegerField' }
          & Pick<IntegerField, 'name' | 'type'>
        ) | (
          { __typename?: 'MonetaryField' }
          & Pick<MonetaryField, 'name' | 'type'>
        ) | (
          { __typename?: 'SelectField' }
          & Pick<SelectField, 'name' | 'type'>
        ) | (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value' | 'name' | 'type'>
        )> }
      ) | (
        { __typename?: 'PropertyCoverage' }
        & Pick<PropertyCoverage, 'insuredPropertyMajorTypes' | 'majorCatastropheExposures' | 'id' | 'kind' | 'type' | 'limit' | 'aggregateLimit' | 'perOccurrenceLimit' | 'deductible' | 'description' | 'comment' | 'keyPerils' | 'exclusions'>
        & { statementsOfValues: Array<(
          { __typename?: 'StatementOfValues' }
          & Pick<StatementOfValues, 'perils' | 'excludedPerils'>
          & { valueEntries: Array<(
            { __typename?: 'StatementOfValuesEntry' }
            & Pick<StatementOfValuesEntry, 'cover' | 'value'>
          )> }
        )>, sublimits: Array<(
          { __typename?: 'Sublimit' }
          & Pick<Sublimit, 'peril' | 'limit'>
        )>, documents?: Maybe<(
          { __typename?: 'DocumentConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'url' | 'type'>
            & { name: Document['filename'], size: Document['byteSize'] }
          )>>> }
        )>, lines?: Maybe<Array<(
          { __typename?: 'LineType' }
          & Pick<LineType, 'id' | 'attachmentPoint' | 'cedingCommission' | 'deductible' | 'layerLimit' | 'netPremium' | 'note' | 'participation' | 'premium'>
          & { quotes: (
            { __typename?: 'QuoteConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & { reinsurer?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
              )> }
            )>>> }
          ) }
        )>>, customFields: Array<(
          { __typename?: 'DateField' }
          & Pick<DateField, 'name' | 'type'>
        ) | (
          { __typename?: 'DateTimeField' }
          & Pick<DateTimeField, 'name' | 'type'>
        ) | (
          { __typename?: 'DecimalField' }
          & Pick<DecimalField, 'name' | 'type'>
        ) | (
          { __typename?: 'IntegerField' }
          & Pick<IntegerField, 'name' | 'type'>
        ) | (
          { __typename?: 'MonetaryField' }
          & Pick<MonetaryField, 'name' | 'type'>
        ) | (
          { __typename?: 'SelectField' }
          & Pick<SelectField, 'name' | 'type'>
        ) | (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value' | 'name' | 'type'>
        )> }
      )>>> }
    ) }
  )>, endorsements: Array<(
    { __typename?: 'Endorsement' }
    & Pick<Endorsement, 'id'>
    & EndorsementInfoFragment
  )>, stages: (
    { __typename?: 'WorksheetStatus' }
    & Pick<WorksheetStatus, 'draft' | 'issued'>
  ), quotes: (
    { __typename?: 'QuoteConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & QuoteInfoFragmentFragment
    )>>> }
  ), quotesForTower: (
    { __typename?: 'QuoteConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & LayerFragment
    )>>> }
  ) }
  & TowerFragment
  & TowerSummaryFragment
);

export type TerrorismQuotedLinesInfoFragment = (
  { __typename?: 'TerrorismQuotation' }
  & Pick<TerrorismQuotation, 'id'>
  & { quotedLines: (
    { __typename?: 'PanelsQuotedLineTypeConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'CyberQuotedLineType' }
      & Pick<CyberQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'DirectorsAndOfficersQuotedLineType' }
      & Pick<DirectorsAndOfficersQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'EmploymentPracticesLiabilityQuotedLineType' }
      & Pick<EmploymentPracticesLiabilityQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'FidelityCrimeQuotedLineType' }
      & Pick<FidelityCrimeQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'FiduciaryQuotedLineType' }
      & Pick<FiduciaryQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'SpecialRiskQuotedLineType' }
      & Pick<SpecialRiskQuotedLineType, 'id' | 'type'>
    ) | (
      { __typename?: 'TerrorismQuotedLineType' }
      & Pick<TerrorismQuotedLineType, 'annualPremium' | 'maximumCommissionPercentage' | 'retailCommissionPercentage' | 'wholesalerCommissionPercentage' | 'totalCommissionPercentage' | 'retailCommission' | 'wholesalerCommission' | 'clientCost' | 'policyNumber' | 'taxAmount' | 'coverageStartDate' | 'id' | 'type'>
      & { coverage: (
        { __typename?: 'BOPCoverage' }
        & Pick<BopCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'CyberGenericCoverage' }
        & Pick<CyberGenericCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityDirectorsAndOfficersCoverage' }
        & Pick<ManagementLiabilityDirectorsAndOfficersCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityEmploymentPracticesLiabilityCoverage' }
        & Pick<ManagementLiabilityEmploymentPracticesLiabilityCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFidelityCrimeCoverage' }
        & Pick<ManagementLiabilityFidelityCrimeCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilityFiduciaryCoverage' }
        & Pick<ManagementLiabilityFiduciaryCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'ManagementLiabilitySpecialRiskCoverage' }
        & Pick<ManagementLiabilitySpecialRiskCoverage, 'id' | 'type'>
      ) | (
        { __typename?: 'TerrorismCoverage' }
        & Pick<TerrorismCoverage, 'id' | 'type'>
      ) }
    )>>> }
  ) }
);

export type LayerFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id' | 'stage'>
  & { reinsurer?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
  )>, latestRevision: (
    { __typename?: 'RevisionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'participation' | 'layerLimit' | 'attachmentPoint' | 'isIncomplete' | 'source' | 'brokerageCommission' | 'subjectivities'>
      & { quotedLayers: Array<(
        { __typename?: 'QuotedLayer' }
        & Pick<QuotedLayer, 'id'>
        & QuotedLayerInfoFragment
      )> }
    )>>> }
  ), reinsuranceRequests: (
    { __typename?: 'ReinsuranceRequestConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'ReinsuranceRequest' }
      & Pick<ReinsuranceRequest, 'id' | 'participation' | 'attachmentPoint' | 'layerLimit'>
      & { layerCoverage?: Maybe<(
        { __typename?: 'CasualtyCoverage' }
        & Pick<CasualtyCoverage, 'id'>
      ) | (
        { __typename?: 'PropertyCoverage' }
        & Pick<PropertyCoverage, 'id'>
      ) | (
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
      )> }
    )>>> }
  ) }
);

export type TowerFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id' | 'perOccurrenceLimit' | 'kind'>
  & { insurancePolicy?: Maybe<(
    { __typename?: 'InsurancePolicy' }
    & Pick<InsurancePolicy, 'id'>
    & { coverages: (
      { __typename?: 'PolicyCoverageConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'CasualtyCoverage' }
        & Pick<CasualtyCoverage, 'basis' | 'extendedReportingPeriod' | 'retroactiveDate' | 'keyPerils' | 'exclusions' | 'id' | 'kind' | 'type' | 'limit' | 'aggregateLimit' | 'perOccurrenceLimit' | 'deductible' | 'description' | 'comment'>
        & { lines?: Maybe<Array<(
          { __typename?: 'LineType' }
          & Pick<LineType, 'id' | 'attachmentPoint' | 'cedingCommission' | 'deductible' | 'layerLimit' | 'netPremium' | 'note' | 'participation' | 'premium'>
        )>>, customFields: Array<(
          { __typename?: 'DateField' }
          & Pick<DateField, 'name' | 'type'>
        ) | (
          { __typename?: 'DateTimeField' }
          & Pick<DateTimeField, 'name' | 'type'>
        ) | (
          { __typename?: 'DecimalField' }
          & Pick<DecimalField, 'name' | 'type'>
        ) | (
          { __typename?: 'IntegerField' }
          & Pick<IntegerField, 'name' | 'type'>
        ) | (
          { __typename?: 'MonetaryField' }
          & Pick<MonetaryField, 'name' | 'type'>
        ) | (
          { __typename?: 'SelectField' }
          & Pick<SelectField, 'name' | 'type'>
        ) | (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value' | 'name' | 'type'>
        )> }
      ) | (
        { __typename?: 'PropertyCoverage' }
        & Pick<PropertyCoverage, 'keyPerils' | 'exclusions' | 'insuredPropertyMajorTypes' | 'majorCatastropheExposures' | 'id' | 'kind' | 'type' | 'limit' | 'aggregateLimit' | 'perOccurrenceLimit' | 'deductible' | 'description' | 'comment'>
        & { statementsOfValues: Array<(
          { __typename?: 'StatementOfValues' }
          & Pick<StatementOfValues, 'perils' | 'excludedPerils'>
          & { valueEntries: Array<(
            { __typename?: 'StatementOfValuesEntry' }
            & Pick<StatementOfValuesEntry, 'cover' | 'value'>
          )> }
        )>, sublimits: Array<(
          { __typename?: 'Sublimit' }
          & Pick<Sublimit, 'peril' | 'limit'>
        )>, lines?: Maybe<Array<(
          { __typename?: 'LineType' }
          & Pick<LineType, 'id' | 'attachmentPoint' | 'cedingCommission' | 'deductible' | 'layerLimit' | 'netPremium' | 'note' | 'participation' | 'premium'>
        )>>, customFields: Array<(
          { __typename?: 'DateField' }
          & Pick<DateField, 'name' | 'type'>
        ) | (
          { __typename?: 'DateTimeField' }
          & Pick<DateTimeField, 'name' | 'type'>
        ) | (
          { __typename?: 'DecimalField' }
          & Pick<DecimalField, 'name' | 'type'>
        ) | (
          { __typename?: 'IntegerField' }
          & Pick<IntegerField, 'name' | 'type'>
        ) | (
          { __typename?: 'MonetaryField' }
          & Pick<MonetaryField, 'name' | 'type'>
        ) | (
          { __typename?: 'SelectField' }
          & Pick<SelectField, 'name' | 'type'>
        ) | (
          { __typename?: 'StringField' }
          & Pick<StringField, 'value' | 'name' | 'type'>
        )> }
      )>>> }
    ) }
  )>, unavailableLayers: (
    { __typename?: 'UnavailableLayerConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'UnavailableLayer' }
      & Pick<UnavailableLayer, 'id' | 'layerLimit' | 'attachmentPoint' | 'participation'>
    )>>> }
  ), reinsuranceRequests: (
    { __typename?: 'ReinsuranceRequestConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'ReinsuranceRequest' }
      & Pick<ReinsuranceRequest, 'id' | 'participation' | 'attachmentPoint' | 'layerLimit'>
    )>>> }
  ) }
);

export type TowerSummaryFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id' | 'description'>
);

type UserInfo_Reinsurer_Fragment = (
  { __typename?: 'Reinsurer' }
  & Pick<Reinsurer, 'id' | 'uuid' | 'type' | 'chatToken' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarUrl' | 'bannerUrl' | 'emailSignOff' | 'generalInbox' | 'features' | 'jobTitle' | 'phoneNumber' | 'onboarding' | 'pendingInvitation' | 'termsAndConditionsAcceptedAt'>
  & { manager?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id'>
  )>, notificationSettings?: Maybe<(
    { __typename?: 'NotificationSettingsType' }
    & { mySubmissions?: Maybe<(
      { __typename?: 'SubmissionNotificationSettingsType' }
      & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
    )>, retailerSubmissions?: Maybe<(
      { __typename?: 'SubmissionNotificationSettingsType' }
      & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
    )> }
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'type' | 'verifiedAt' | 'inInsurance' | 'defaultBrand' | 'brandColor' | 'brandName' | 'emailLogoUrl' | 'logoUrl' | 'defaultAttachment' | 'guest' | 'kind' | 'userType' | 'supportEmail' | 'dealVisibility' | 'incomingEmailAddress'>
    & { subscription: (
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'id' | 'plan' | 'featureLevel' | 'trialDaysRemaining' | 'seatsRemaining' | 'period'>
    ), panels?: Maybe<(
      { __typename?: 'PanelConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Panel' }
        & Pick<Panel, 'id' | 'lineOfBusiness' | 'name' | 'configuration' | 'displayTax'>
      )>>> }
    )>, pdfTemplates?: Maybe<(
      { __typename?: 'PdfTemplateConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'PdfTemplate' }
        & Pick<PdfTemplate, 'id' | 'name' | 'tags'>
      )>>> }
    )> }
  ) }
);

type UserInfo_Cedent_Fragment = (
  { __typename?: 'Cedent' }
  & Pick<Cedent, 'demoData' | 'placementsAvailable' | 'id' | 'uuid' | 'type' | 'chatToken' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarUrl' | 'bannerUrl' | 'emailSignOff' | 'generalInbox' | 'features' | 'jobTitle' | 'phoneNumber' | 'onboarding' | 'pendingInvitation' | 'termsAndConditionsAcceptedAt'>
  & { manager?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id'>
  )>, notificationSettings?: Maybe<(
    { __typename?: 'NotificationSettingsType' }
    & { mySubmissions?: Maybe<(
      { __typename?: 'SubmissionNotificationSettingsType' }
      & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
    )>, retailerSubmissions?: Maybe<(
      { __typename?: 'SubmissionNotificationSettingsType' }
      & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
    )> }
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'type' | 'verifiedAt' | 'inInsurance' | 'defaultBrand' | 'brandColor' | 'brandName' | 'emailLogoUrl' | 'logoUrl' | 'defaultAttachment' | 'guest' | 'kind' | 'userType' | 'supportEmail' | 'dealVisibility' | 'incomingEmailAddress'>
    & { subscription: (
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'id' | 'plan' | 'featureLevel' | 'trialDaysRemaining' | 'seatsRemaining' | 'period'>
    ), panels?: Maybe<(
      { __typename?: 'PanelConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Panel' }
        & Pick<Panel, 'id' | 'lineOfBusiness' | 'name' | 'configuration' | 'displayTax'>
      )>>> }
    )>, pdfTemplates?: Maybe<(
      { __typename?: 'PdfTemplateConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'PdfTemplate' }
        & Pick<PdfTemplate, 'id' | 'name' | 'tags'>
      )>>> }
    )> }
  ) }
);

type UserInfo_Broker_Fragment = (
  { __typename?: 'Broker' }
  & Pick<Broker, 'placementsAvailable' | 'demoData' | 'id' | 'uuid' | 'type' | 'chatToken' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarUrl' | 'bannerUrl' | 'emailSignOff' | 'generalInbox' | 'features' | 'jobTitle' | 'phoneNumber' | 'onboarding' | 'pendingInvitation' | 'termsAndConditionsAcceptedAt'>
  & { manager?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id'>
  )>, notificationSettings?: Maybe<(
    { __typename?: 'NotificationSettingsType' }
    & { mySubmissions?: Maybe<(
      { __typename?: 'SubmissionNotificationSettingsType' }
      & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
    )>, retailerSubmissions?: Maybe<(
      { __typename?: 'SubmissionNotificationSettingsType' }
      & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
    )> }
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'type' | 'verifiedAt' | 'inInsurance' | 'defaultBrand' | 'brandColor' | 'brandName' | 'emailLogoUrl' | 'logoUrl' | 'defaultAttachment' | 'guest' | 'kind' | 'userType' | 'supportEmail' | 'dealVisibility' | 'incomingEmailAddress'>
    & { subscription: (
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'id' | 'plan' | 'featureLevel' | 'trialDaysRemaining' | 'seatsRemaining' | 'period'>
    ), panels?: Maybe<(
      { __typename?: 'PanelConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Panel' }
        & Pick<Panel, 'id' | 'lineOfBusiness' | 'name' | 'configuration' | 'displayTax'>
      )>>> }
    )>, pdfTemplates?: Maybe<(
      { __typename?: 'PdfTemplateConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'PdfTemplate' }
        & Pick<PdfTemplate, 'id' | 'name' | 'tags'>
      )>>> }
    )> }
  ) }
);

type UserInfo_NfpRepsAndWarrantiesBroker_Fragment = (
  { __typename?: 'NFPRepsAndWarrantiesBroker' }
  & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'uuid' | 'type' | 'chatToken' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'avatarUrl' | 'bannerUrl' | 'emailSignOff' | 'generalInbox' | 'features' | 'jobTitle' | 'phoneNumber' | 'onboarding' | 'pendingInvitation' | 'termsAndConditionsAcceptedAt'>
  & { manager?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id'>
  )>, notificationSettings?: Maybe<(
    { __typename?: 'NotificationSettingsType' }
    & { mySubmissions?: Maybe<(
      { __typename?: 'SubmissionNotificationSettingsType' }
      & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
    )>, retailerSubmissions?: Maybe<(
      { __typename?: 'SubmissionNotificationSettingsType' }
      & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
    )> }
  )>, company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'type' | 'verifiedAt' | 'inInsurance' | 'defaultBrand' | 'brandColor' | 'brandName' | 'emailLogoUrl' | 'logoUrl' | 'defaultAttachment' | 'guest' | 'kind' | 'userType' | 'supportEmail' | 'dealVisibility' | 'incomingEmailAddress'>
    & { subscription: (
      { __typename?: 'Subscription' }
      & Pick<Subscription, 'id' | 'plan' | 'featureLevel' | 'trialDaysRemaining' | 'seatsRemaining' | 'period'>
    ), panels?: Maybe<(
      { __typename?: 'PanelConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Panel' }
        & Pick<Panel, 'id' | 'lineOfBusiness' | 'name' | 'configuration' | 'displayTax'>
      )>>> }
    )>, pdfTemplates?: Maybe<(
      { __typename?: 'PdfTemplateConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'PdfTemplate' }
        & Pick<PdfTemplate, 'id' | 'name' | 'tags'>
      )>>> }
    )> }
  ) }
);

export type UserInfoFragment = UserInfo_Reinsurer_Fragment | UserInfo_Cedent_Fragment | UserInfo_Broker_Fragment | UserInfo_NfpRepsAndWarrantiesBroker_Fragment;

export type WorksheetInfoFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id' | 'name' | 'demo' | 'currency' | 'multiTower'>
  & { owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    ) }
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    ) }
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    ) }
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    ) }
  ) }
);

export type WorksheetInformationDocumentsFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id'>
  & { documents: (
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  ) }
);

export type WorksheetQuoteInformationDocumentsFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
    & { cedentMessages: (
      { __typename?: 'MessageConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Broadcast' }
        & Pick<Broadcast, 'id'>
        & { documents: (
          { __typename?: 'DocumentConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )>>> }
        ) }
        & Attachments_Broadcast_Fragment
      ) | (
        { __typename?: 'DirectMessage' }
        & Pick<DirectMessage, 'id'>
        & { documents: (
          { __typename?: 'DocumentConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )>>> }
        ) }
        & Attachments_DirectMessage_Fragment
      )>>> }
    ) }
  )>, binderRequest?: Maybe<(
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  )>, policyRequest?: Maybe<(
    { __typename?: 'DocumentConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    )>>> }
  )> }
);

export type AcceptQuoteMutationVariables = Exact<{
  input: AcceptQuoteInput;
}>;


export type AcceptQuoteMutation = (
  { __typename?: 'Mutation' }
  & { acceptQuote?: Maybe<(
    { __typename?: 'AcceptQuotePayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'status' | 'stage'>
      & { readableStatus: Quote['status'] }
      & { binder?: Maybe<(
        { __typename?: 'DocumentConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'filename' | 'createdAt'>
        )>>> }
      )>, certificate?: Maybe<(
        { __typename?: 'DocumentConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'filename' | 'createdAt'>
        )>>> }
      )> }
    )> }
  )> }
);

export type AcceptQuoteProposalMutationVariables = Exact<{
  input: AcceptProposalInput;
}>;


export type AcceptQuoteProposalMutation = (
  { __typename?: 'Mutation' }
  & { acceptProposal?: Maybe<(
    { __typename?: 'AcceptProposalPayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'status'>
      & { readableStatus: Quote['status'] }
    )> }
  )> }
);

export type ApproveRevisionMutationVariables = Exact<{
  input: ApproveRevisionInput;
}>;


export type ApproveRevisionMutation = (
  { __typename?: 'Mutation' }
  & { approveRevision?: Maybe<(
    { __typename?: 'ApproveRevisionPayload' }
    & Pick<ApproveRevisionPayload, 'errors'>
    & { revision?: Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'status'>
      & { quote: (
        { __typename?: 'Quote' }
        & Pick<Quote, 'id' | 'status'>
      ) }
    )> }
  )> }
);

export type BopAppetiteMutationVariables = Exact<{
  input: BopAppetiteInput;
  submissionId: Scalars['ID'];
}>;


export type BopAppetiteMutation = (
  { __typename?: 'Mutation' }
  & { bopAppetite?: Maybe<(
    { __typename?: 'BOPAppetitePayload' }
    & { panels?: Maybe<(
      { __typename?: 'Panels' }
      & Pick<Panels, 'id'>
      & { schemas: (
        { __typename?: 'MultilinePanelsSchema' }
        & Pick<MultilinePanelsSchema, 'edit'>
      ), submission?: Maybe<(
        { __typename?: 'PanelSubmission' }
        & Pick<PanelSubmission, 'id'>
        & PanelSubmissionInfoFragment
      )> }
    )>, errors?: Maybe<(
      { __typename?: 'BOPAppetiteErrors' }
      & Pick<BopAppetiteErrors, 'base'>
    )> }
  )> }
);

export type BopReturnPreviousStateMutationVariables = Exact<{
  input: BopReturnPreviousStateInput;
  submissionId: Scalars['ID'];
}>;


export type BopReturnPreviousStateMutation = (
  { __typename?: 'Mutation' }
  & { bopReturnPreviousState?: Maybe<(
    { __typename?: 'BOPReturnPreviousStatePayload' }
    & { panels?: Maybe<(
      { __typename?: 'Panels' }
      & Pick<Panels, 'id'>
      & { schemas: (
        { __typename?: 'MultilinePanelsSchema' }
        & Pick<MultilinePanelsSchema, 'edit'>
      ), submission?: Maybe<(
        { __typename?: 'PanelSubmission' }
        & Pick<PanelSubmission, 'id'>
        & PanelSubmissionInfoFragment
      )> }
    )>, errors?: Maybe<(
      { __typename?: 'BOPReturnPreviousStateErrors' }
      & Pick<BopReturnPreviousStateErrors, 'base'>
    )> }
  )> }
);

export type AcceptBrokerInviteMutationVariables = Exact<{
  input: AcceptBrokerInviteInput;
}>;


export type AcceptBrokerInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptBrokerInvite?: Maybe<(
    { __typename?: 'AcceptBrokerInvitePayload' }
    & Pick<AcceptBrokerInvitePayload, 'errors'>
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & Pick<Worksheet, 'id'>
      & { owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ), collaborations: Array<(
        { __typename: 'BrokerInvite' }
        & Pick<BrokerInvite, 'id'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename: 'BrokerCollaboration' }
        & Pick<BrokerCollaboration, 'id'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename: 'ClientCollaboration' }
        & Pick<ClientCollaboration, 'id'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename: 'MgaCollaboration' }
        & Pick<MgaCollaboration, 'id'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename: 'WholesalerCollaboration' }
        & Pick<WholesalerCollaboration, 'id'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      )> }
    )> }
  )> }
);

export type ChangeQuoteOwnerMutationVariables = Exact<{
  input: ChangeQuoteOwnerInput;
}>;


export type ChangeQuoteOwnerMutation = (
  { __typename?: 'Mutation' }
  & { changeQuoteOwner?: Maybe<(
    { __typename?: 'ChangeQuoteOwnerPayload' }
    & Pick<ChangeQuoteOwnerPayload, 'errors'>
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & { reinsurer?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
        & { quotes: (
          { __typename?: 'QuoteConnection' }
          & { edges?: Maybe<Array<Maybe<(
            { __typename?: 'QuoteEdge' }
            & { node?: Maybe<(
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
            )> }
          )>>> }
        ) }
      )> }
    )> }
  )> }
);

export type ChangeReferenceNumberMutationVariables = Exact<{
  input: ChangeReferenceNumberInput;
}>;


export type ChangeReferenceNumberMutation = (
  { __typename?: 'Mutation' }
  & { changeReferenceNumber?: Maybe<(
    { __typename?: 'ChangeReferenceNumberPayload' }
    & Pick<ChangeReferenceNumberPayload, 'errors'>
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'referenceNumber'>
    )> }
  )> }
);

export type ChangeSubmissionOwnerMutationVariables = Exact<{
  input: ChangeSubmissionOwnerInput;
}>;


export type ChangeSubmissionOwnerMutation = (
  { __typename?: 'Mutation' }
  & { changeSubmissionOwner?: Maybe<(
    { __typename?: 'ChangeSubmissionOwnerPayload' }
    & Pick<ChangeSubmissionOwnerPayload, 'errors'>
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'id'>
      & { owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) }
    ) | (
      { __typename?: 'PanelSubmission' }
      & Pick<PanelSubmission, 'id'>
      & { owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) }
    ) | (
      { __typename?: 'PCGSubmission' }
      & Pick<PcgSubmission, 'id'>
      & { owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesSubmission' }
      & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
      & { owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) }
    )> }
  )> }
);

export type ChangePlacementOwnerMutationVariables = Exact<{
  input: ChangeOwnerInput;
}>;


export type ChangePlacementOwnerMutation = (
  { __typename?: 'Mutation' }
  & { changePlacementOwner?: Maybe<(
    { __typename?: 'ChangeOwnerPayload' }
    & Pick<ChangeOwnerPayload, 'errors'>
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & Pick<Worksheet, 'id'>
      & { owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
        & { worksheets: (
          { __typename?: 'WorksheetConnection' }
          & { edges?: Maybe<Array<Maybe<(
            { __typename?: 'WorksheetEdge' }
            & { node?: Maybe<(
              { __typename?: 'Worksheet' }
              & Pick<Worksheet, 'id'>
            )> }
          )>>> }
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
        & { worksheets: (
          { __typename?: 'WorksheetConnection' }
          & { edges?: Maybe<Array<Maybe<(
            { __typename?: 'WorksheetEdge' }
            & { node?: Maybe<(
              { __typename?: 'Worksheet' }
              & Pick<Worksheet, 'id'>
            )> }
          )>>> }
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ) }
    )> }
  )> }
);

export type CreateChargebeeCheckoutMutationVariables = Exact<{
  input: CreateChargebeeCheckoutInput;
}>;


export type CreateChargebeeCheckoutMutation = (
  { __typename?: 'Mutation' }
  & { createChargebeeCheckout?: Maybe<(
    { __typename?: 'CreateChargebeeCheckoutPayload' }
    & Pick<CreateChargebeeCheckoutPayload, 'checkout' | 'errors'>
  )> }
);

export type CreateChargebeePortalSessionMutationVariables = Exact<{
  input: CreateChargebeePortalSessionInput;
}>;


export type CreateChargebeePortalSessionMutation = (
  { __typename?: 'Mutation' }
  & { createChargebeePortalSession?: Maybe<(
    { __typename?: 'CreateChargebeePortalSessionPayload' }
    & Pick<CreateChargebeePortalSessionPayload, 'session' | 'errors'>
  )> }
);

export type ApproveQuoteMutationVariables = Exact<{
  input: ClientApprovalInput;
}>;


export type ApproveQuoteMutation = (
  { __typename?: 'Mutation' }
  & { approveQuote?: Maybe<(
    { __typename?: 'ClientApprovalPayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'status'>
    )> }
  )> }
);

export type ConfirmOutdatedQuoteMutationVariables = Exact<{
  input: ConfirmOutdatedQuoteInput;
}>;


export type ConfirmOutdatedQuoteMutation = (
  { __typename?: 'Mutation' }
  & { confirmOutdatedQuote?: Maybe<(
    { __typename?: 'ConfirmOutdatedQuotePayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'outdatedAt'>
    )> }
  )> }
);

export type AcceptCompanyInvitationMutationVariables = Exact<{
  input: AcceptCompanyInvitationInput;
}>;


export type AcceptCompanyInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptCompanyInvitation?: Maybe<(
    { __typename?: 'AcceptCompanyInvitationPayload' }
    & Pick<AcceptCompanyInvitationPayload, 'success' | 'errors'>
  )> }
);

export type CyberBindQuoteMutationVariables = Exact<{
  input: CyberBindQuoteInput;
}>;


export type CyberBindQuoteMutation = (
  { __typename?: 'Mutation' }
  & { cyberBindQuote?: Maybe<(
    { __typename?: 'CyberBindQuotePayload' }
    & Pick<CyberBindQuotePayload, 'error'>
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id' | 'status' | 'statusMessage' | 'policyNumber'>
      & { documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'category' | 'version'>
        & DocumentForFormFragment
      )>, participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { discussion?: Maybe<(
          { __typename?: 'Discussion' }
          & Pick<Discussion, 'id'>
          & MessagesFragment
        )> }
      ) }
    )> }
  )> }
);

export type CyberCreateManualQuoteMutationVariables = Exact<{
  input: CyberCreateManualQuoteInput;
}>;


export type CyberCreateManualQuoteMutation = (
  { __typename?: 'Mutation' }
  & { cyberCreateManualQuote?: Maybe<(
    { __typename?: 'CyberCreateManualQuotePayload' }
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & CyberQuotationInfo_CyberQuotation_Fragment
    )>, errors?: Maybe<(
      { __typename?: 'CyberCreateManualQuoteErrors' }
      & Pick<CyberCreateManualQuoteErrors, 'base' | 'admitted' | 'carrierName' | 'comments' | 'declinationReason' | 'deductible' | 'fee' | 'limit' | 'policyNumber' | 'premium'>
      & { endorsements?: Maybe<Array<(
        { __typename?: 'CyberCreateManualQuoteEndorsementsErrors' }
        & Pick<CyberCreateManualQuoteEndorsementsErrors, 'name' | 'limit' | 'coinsurance'>
      )>>, coverages?: Maybe<Array<(
        { __typename?: 'CyberCreateManualQuoteCoveragesErrors' }
        & Pick<CyberCreateManualQuoteCoveragesErrors, 'name' | 'limit' | 'deductible'>
      )>> }
    )> }
  )> }
);

export type CyberCreateShareableApplicationLinkMutationVariables = Exact<{
  input: CyberCreateShareableApplicationLinkInput;
}>;


export type CyberCreateShareableApplicationLinkMutation = (
  { __typename?: 'Mutation' }
  & { cyberCreateShareableApplicationLink?: Maybe<(
    { __typename?: 'CyberCreateShareableApplicationLinkPayload' }
    & Pick<CyberCreateShareableApplicationLinkPayload, 'shareableLink' | 'errors'>
  )> }
);

export type CyberCreateShareableLinkMutationVariables = Exact<{
  input: CyberCreateShareableLinkInput;
}>;


export type CyberCreateShareableLinkMutation = (
  { __typename?: 'Mutation' }
  & { cyberCreateShareableLink?: Maybe<(
    { __typename?: 'CyberCreateShareableLinkPayload' }
    & Pick<CyberCreateShareableLinkPayload, 'shareableLink' | 'errors'>
  )> }
);

export type CyberAdjustQuotesMutationVariables = Exact<{
  input: CyberAdjustQuotesInput;
}>;


export type CyberAdjustQuotesMutation = (
  { __typename?: 'Mutation' }
  & { cyberAdjustQuotes?: Maybe<(
    { __typename?: 'CyberAdjustQuotesPayload' }
    & Pick<CyberAdjustQuotesPayload, 'clientMutationId'>
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'annualRevenue' | 'city' | 'coverageStartDate' | 'createdAt' | 'deductible' | 'deletedAt' | 'description' | 'fields' | 'id' | 'lastEditedAt' | 'limit' | 'naicsCode' | 'name' | 'state' | 'status' | 'streetLine1' | 'streetLine2' | 'type' | 'updatedAt' | 'websites' | 'zipCode'>
    )>, errors?: Maybe<(
      { __typename?: 'CyberAdjustQuotesErrors' }
      & Pick<CyberAdjustQuotesErrors, 'id' | 'limit' | 'deductible'>
    )> }
  )> }
);

export type CyberArchiveQuoteMutationVariables = Exact<{
  input: CyberArchiveQuoteInput;
}>;


export type CyberArchiveQuoteMutation = (
  { __typename?: 'Mutation' }
  & { cyberArchiveQuote?: Maybe<(
    { __typename?: 'CyberArchiveQuotePayload' }
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id' | 'archivedAt'>
    )> }
  )> }
);

export type CyberCreateDraftSubmissionMutationVariables = Exact<{
  input: CyberCreateDraftSubmissionInput;
}>;


export type CyberCreateDraftSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { cyberCreateDraftSubmission?: Maybe<(
    { __typename?: 'CyberCreateDraftSubmissionPayload' }
    & Pick<CyberCreateDraftSubmissionPayload, 'clientMutationId'>
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'id' | 'country' | 'message' | 'name' | 'websites' | 'hasNoWebsite' | 'naicsCode' | 'cyberboxxIndustryCode' | 'chubbIndustryCode' | 'streetLine1' | 'streetLine2' | 'type' | 'status' | 'city' | 'state' | 'zipCode' | 'phoneNumber' | 'contactName' | 'email' | 'numberOfEmployees' | 'numberOfRecords' | 'annualRevenue' | 'limit' | 'deductible' | 'coverageStartDate' | 'multiFactorAuthentication' | 'selectedPanelists' | 'additionalCarriers' | 'questions'>
      & { documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
      )> }
    )>, errors?: Maybe<(
      { __typename?: 'CyberSubmissionErrors' }
      & Pick<CyberSubmissionErrors, 'id' | 'additionalCarriers' | 'annualRevenue' | 'base' | 'chubbIndustryCode' | 'city' | 'contactName' | 'country' | 'coverageStartDate' | 'cyberboxxIndustryCode' | 'deductible' | 'email' | 'hasNoWebsite' | 'limit' | 'message' | 'naicsCode' | 'name' | 'numberOfEmployees' | 'numberOfRecords' | 'phoneNumber' | 'multiFactorAuthentication' | 'selectedPanelists' | 'state' | 'status' | 'streetLine1' | 'streetLine2' | 'websites' | 'zipCode'>
    )> }
  )> }
);

export type CyberCreateQuoteMutationVariables = Exact<{
  input: CyberCreateQuoteInput;
}>;


export type CyberCreateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { cyberCreateQuote?: Maybe<(
    { __typename?: 'CyberCreateQuotePayload' }
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & { participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { quotations: (
          { __typename?: 'QuotationConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
          ) | (
            { __typename?: 'PCGQuotation' }
            & Pick<PcgQuotation, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
          )>>> }
        ) }
      ) }
      & CyberQuotationInfo_CyberQuotation_Fragment
    )>, errors?: Maybe<(
      { __typename?: 'CyberCreateQuoteErrors' }
      & Pick<CyberCreateQuoteErrors, 'base' | 'limit' | 'deductible' | 'premium' | 'fee' | 'admitted' | 'documents'>
      & { coverages?: Maybe<Array<(
        { __typename?: 'CyberCreateQuoteCoveragesErrors' }
        & Pick<CyberCreateQuoteCoveragesErrors, 'name' | 'limit' | 'deductible'>
      )>>, endorsements?: Maybe<Array<(
        { __typename?: 'CyberCreateQuoteEndorsementsErrors' }
        & Pick<CyberCreateQuoteEndorsementsErrors, 'name' | 'limit' | 'coinsurance'>
      )>> }
    )> }
  )> }
);

export type CyberExportSubmissionMutationVariables = Exact<{
  input: CyberExportSubmissionInput;
}>;


export type CyberExportSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { cyberExportSubmission?: Maybe<(
    { __typename?: 'CyberExportSubmissionPayload' }
    & Pick<CyberExportSubmissionPayload, 'errors'>
  )> }
);

export type CyberGenerateQuotesMutationVariables = Exact<{
  input: CyberGenerateQuotesInput;
}>;


export type CyberGenerateQuotesMutation = (
  { __typename?: 'Mutation' }
  & { cyberGenerateQuotes?: Maybe<(
    { __typename?: 'CyberGenerateQuotesPayload' }
    & Pick<CyberGenerateQuotesPayload, 'clientMutationId'>
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'id' | 'name' | 'type' | 'status' | 'naicsCode' | 'websites' | 'streetLine1' | 'streetLine2' | 'city' | 'state' | 'zipCode' | 'limit' | 'deductible' | 'coverageStartDate' | 'annualRevenue' | 'description' | 'fields' | 'lastEditedAt' | 'createdAt' | 'updatedAt' | 'deletedAt'>
    )>, errors?: Maybe<(
      { __typename?: 'CyberGenerateQuotesErrors' }
      & Pick<CyberGenerateQuotesErrors, 'annualRevenue' | 'base' | 'city' | 'coverageStartDate' | 'deductible' | 'documents' | 'limit' | 'message' | 'naicsCode' | 'numberOfEmployees' | 'numberOfRecords' | 'placementTermsAccepted' | 'name' | 'streetLine1' | 'streetLine2' | 'state' | 'zipCode' | 'websites'>
      & { additionalCarriers?: Maybe<Array<(
        { __typename?: 'CyberGenerateQuotesAdditionalCarriersErrors' }
        & Pick<CyberGenerateQuotesAdditionalCarriersErrors, 'id'>
      )>> }
    )> }
  )> }
);

export type CyberUpdateExtractedQuoteMutationVariables = Exact<{
  input: CyberUpdateExtractedQuoteInput;
}>;


export type CyberUpdateExtractedQuoteMutation = (
  { __typename?: 'Mutation' }
  & { cyberUpdateExtractedQuote?: Maybe<(
    { __typename?: 'CyberUpdateExtractedQuotePayload' }
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & { participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { quotations: (
          { __typename?: 'QuotationConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
          ) | (
            { __typename?: 'PCGQuotation' }
            & Pick<PcgQuotation, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
          )>>> }
        ) }
      ) }
      & CyberQuotationInfo_CyberQuotation_Fragment
    )>, errors?: Maybe<(
      { __typename?: 'CyberUpdateExtractedQuoteErrors' }
      & Pick<CyberUpdateExtractedQuoteErrors, 'base' | 'limit' | 'deductible' | 'premium' | 'fee' | 'admitted' | 'documents'>
      & { coverages?: Maybe<Array<(
        { __typename?: 'CyberUpdateExtractedQuoteCoveragesErrors' }
        & Pick<CyberUpdateExtractedQuoteCoveragesErrors, 'name' | 'limit' | 'deductible'>
      )>>, endorsements?: Maybe<Array<(
        { __typename?: 'CyberUpdateExtractedQuoteEndorsementsErrors' }
        & Pick<CyberUpdateExtractedQuoteEndorsementsErrors, 'name' | 'limit' | 'coinsurance'>
      )>> }
    )> }
  )> }
);

export type CyberUpdateQuoteMutationVariables = Exact<{
  input: CyberUpdateQuoteInput;
}>;


export type CyberUpdateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { cyberUpdateQuote?: Maybe<(
    { __typename?: 'CyberUpdateQuotePayload' }
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id'>
      & { participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { quotations: (
          { __typename?: 'QuotationConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
          ) | (
            { __typename?: 'PCGQuotation' }
            & Pick<PcgQuotation, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
          )>>> }
        ) }
      ) }
      & CyberQuotationInfo_CyberQuotation_Fragment
    )>, errors?: Maybe<(
      { __typename?: 'CyberUpdateQuoteErrors' }
      & Pick<CyberUpdateQuoteErrors, 'base' | 'limit' | 'deductible' | 'premium' | 'fee' | 'admitted' | 'documents'>
      & { coverages?: Maybe<Array<(
        { __typename?: 'CyberUpdateQuoteCoveragesErrors' }
        & Pick<CyberUpdateQuoteCoveragesErrors, 'name' | 'limit' | 'deductible'>
      )>>, endorsements?: Maybe<Array<(
        { __typename?: 'CyberUpdateQuoteEndorsementsErrors' }
        & Pick<CyberUpdateQuoteEndorsementsErrors, 'name' | 'limit' | 'coinsurance'>
      )>> }
    )> }
  )> }
);

export type CyberUpdateSubmissionMutationVariables = Exact<{
  input: CyberUpdateSubmissionInput;
}>;


export type CyberUpdateSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { cyberUpdateSubmission?: Maybe<(
    { __typename?: 'CyberUpdateSubmissionPayload' }
    & Pick<CyberUpdateSubmissionPayload, 'clientMutationId' | 'error'>
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'id'>
      & CyberSubmissionInfo_CyberSubmission_Fragment
    )>, errors?: Maybe<(
      { __typename?: 'CyberUpdateSubmissionErrors' }
      & Pick<CyberUpdateSubmissionErrors, 'annualRevenue' | 'base' | 'city' | 'coverageStartDate' | 'documents' | 'naicsCode' | 'numberOfEmployees' | 'numberOfRecords' | 'placementTermsAccepted' | 'name' | 'streetLine1' | 'streetLine2' | 'state' | 'zipCode' | 'websites'>
    )> }
  )> }
);

export type CyberUpdateSubmissionDocumentsMutationVariables = Exact<{
  input: CyberUpdateSubmissionDocumentsInput;
}>;


export type CyberUpdateSubmissionDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { cyberUpdateSubmissionDocuments?: Maybe<(
    { __typename?: 'CyberUpdateSubmissionDocumentsPayload' }
    & Pick<CyberUpdateSubmissionDocumentsPayload, 'errors'>
    & { submission: (
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'id'>
      & CyberSubmissionFileCabinetFragment
    ) }
  )> }
);

export type CyberUpdateSubmissionMarketsMutationVariables = Exact<{
  input: CyberUpdateSubmissionMarketsInput;
}>;


export type CyberUpdateSubmissionMarketsMutation = (
  { __typename?: 'Mutation' }
  & { cyberUpdateSubmissionMarkets?: Maybe<(
    { __typename?: 'CyberUpdateSubmissionMarketsPayload' }
    & Pick<CyberUpdateSubmissionMarketsPayload, 'clientMutationId' | 'error'>
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'id'>
      & CyberSubmissionInfo_CyberSubmission_Fragment
    )> }
  )> }
);

export type CyberDeclineQuoteMutationVariables = Exact<{
  input: CyberDeclineQuoteInput;
}>;


export type CyberDeclineQuoteMutation = (
  { __typename?: 'Mutation' }
  & { cyberDeclineQuote?: Maybe<(
    { __typename?: 'CyberDeclineQuotePayload' }
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id' | 'status' | 'declinationReason'>
      & { participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { quotations: (
          { __typename?: 'QuotationConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
          ) | (
            { __typename?: 'PCGQuotation' }
            & Pick<PcgQuotation, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
          )>>> }
        ) }
      ) }
    )> }
  )> }
);

export type CyberEmailShareableApplicationLinkMutationVariables = Exact<{
  input: EmailShareableApplicationLinkInput;
}>;


export type CyberEmailShareableApplicationLinkMutation = (
  { __typename?: 'Mutation' }
  & { cyberEmailShareableApplicationLink?: Maybe<(
    { __typename?: 'EmailShareableApplicationLinkPayload' }
    & Pick<EmailShareableApplicationLinkPayload, 'shareableLink' | 'errors'>
  )> }
);

export type CyberExportQuoteComparisonMutationVariables = Exact<{
  input: CyberExportQuoteComparisonInput;
}>;


export type CyberExportQuoteComparisonMutation = (
  { __typename?: 'Mutation' }
  & { cyberExportQuoteComparison?: Maybe<(
    { __typename?: 'CyberExportQuoteComparisonPayload' }
    & Pick<CyberExportQuoteComparisonPayload, 'errors'>
  )> }
);

export type InviteRetailBrokerMutationVariables = Exact<{
  input: InviteRetailBrokerInput;
}>;


export type InviteRetailBrokerMutation = (
  { __typename?: 'Mutation' }
  & { inviteRetailBroker?: Maybe<(
    { __typename?: 'InviteRetailBrokerPayload' }
    & Pick<InviteRetailBrokerPayload, 'errors'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )> }
  )> }
);

export type CyberIssuePolicyMutationVariables = Exact<{
  input: CyberIssuePolicyInput;
}>;


export type CyberIssuePolicyMutation = (
  { __typename?: 'Mutation' }
  & { cyberIssuePolicy?: Maybe<(
    { __typename?: 'CyberIssuePolicyPayload' }
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id' | 'status' | 'statusMessage'>
      & { documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'category' | 'version'>
        & DocumentForFormFragment
      )> }
    )>, errors?: Maybe<(
      { __typename?: 'CyberIssuePolicyErrors' }
      & Pick<CyberIssuePolicyErrors, 'policyNumber'>
    )> }
  )> }
);

export type CyberManualSubjectivitiesMutationVariables = Exact<{
  input: CyberManualSubjectivitiesInput;
}>;


export type CyberManualSubjectivitiesMutation = (
  { __typename?: 'Mutation' }
  & { cyberManualSubjectivities?: Maybe<(
    { __typename?: 'CyberManualSubjectivitiesPayload' }
    & Pick<CyberManualSubjectivitiesPayload, 'errors'>
    & { participation?: Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { subjectivities: (
        { __typename?: 'SubjectivityConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Subjectivity' }
          & Pick<Subjectivity, 'id' | 'text'>
        )>>> }
      ) }
    )> }
  )> }
);

export type CyberReinstateEmailQuotesMutationVariables = Exact<{
  input: CyberReinstateEmailQuotesInput;
}>;


export type CyberReinstateEmailQuotesMutation = (
  { __typename?: 'Mutation' }
  & { cyberReinstateEmailQuotes?: Maybe<(
    { __typename?: 'CyberReinstateEmailQuotesPayload' }
    & Pick<CyberReinstateEmailQuotesPayload, 'error'>
    & { quotes?: Maybe<Array<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id' | 'status'>
      & { participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { documents?: Maybe<Array<(
          { __typename?: 'Document' }
          & Pick<Document, 'id'>
          & DocumentFragment
        )>>, owner: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        ), user?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        )>, discussion?: Maybe<(
          { __typename?: 'Discussion' }
          & Pick<Discussion, 'id'>
          & MessagesFragment
        )>, submission: (
          { __typename?: 'CyberSubmission' }
          & Pick<CyberSubmission, 'id'>
          & CyberSubmissionInfo_CyberSubmission_Fragment
        ) | (
          { __typename?: 'PanelSubmission' }
          & Pick<PanelSubmission, 'id'>
          & CyberSubmissionInfo_PanelSubmission_Fragment
        ) | (
          { __typename?: 'PCGSubmission' }
          & Pick<PcgSubmission, 'id'>
          & CyberSubmissionInfo_PcgSubmission_Fragment
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesSubmission' }
          & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
          & CyberSubmissionInfo_NfpRepsAndWarrantiesSubmission_Fragment
        ), quotations: (
          { __typename?: 'QuotationConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
            & CyberQuotationInfo_CyberQuotation_Fragment
          ) | (
            { __typename?: 'PCGQuotation' }
            & Pick<PcgQuotation, 'id'>
            & CyberQuotationInfo_PcgQuotation_Fragment
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
            & CyberQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
            & CyberQuotationInfo_PanelsQuotation_Fragment
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
            & CyberQuotationInfo_MlQuotation_Fragment
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
            & CyberQuotationInfo_TerrorismQuotation_Fragment
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
            & CyberQuotationInfo_BopQuotation_Fragment
          )>>> }
        ) }
      ) }
    )>> }
  )> }
);

export type CyberRequestToBindMutationVariables = Exact<{
  input: CyberRequestToBindInput;
}>;


export type CyberRequestToBindMutation = (
  { __typename?: 'Mutation' }
  & { cyberRequestToBind?: Maybe<(
    { __typename?: 'CyberRequestToBindPayload' }
    & Pick<CyberRequestToBindPayload, 'error'>
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id' | 'status' | 'statusMessage'>
      & { submission: (
        { __typename?: 'CyberSubmission' }
        & Pick<CyberSubmission, 'id' | 'status'>
      ) }
    )> }
  )> }
);

export type CyberSuggestCodesSubmissionMutationVariables = Exact<{
  input: CyberSuggestCodesSubmissionInput;
}>;


export type CyberSuggestCodesSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { cyberSuggestCodesSubmission?: Maybe<(
    { __typename?: 'CyberSuggestCodesSubmissionPayload' }
    & Pick<CyberSuggestCodesSubmissionPayload, 'suggestions' | 'error'>
  )> }
);

export type CyberUpdateManualQuoteMutationVariables = Exact<{
  input: CyberUpdateManualQuoteInput;
}>;


export type CyberUpdateManualQuoteMutation = (
  { __typename?: 'Mutation' }
  & { cyberUpdateManualQuote?: Maybe<(
    { __typename?: 'CyberUpdateManualQuotePayload' }
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id' | 'limit' | 'deductible' | 'premium' | 'fee' | 'surplusLinesTax' | 'totalCost' | 'admitted' | 'declinationReason' | 'signingUrl' | 'policyNumber' | 'comments'>
      & { coverages?: Maybe<Array<Maybe<(
        { __typename?: 'CyberCoverage' }
        & Pick<CyberCoverage, 'name' | 'type' | 'limit' | 'deductible' | 'periodOfIndemnity' | 'waitingPeriod'>
      )>>>, documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentForFormFragment
      )>, endorsements?: Maybe<Array<Maybe<(
        { __typename?: 'CyberEndorsement' }
        & Pick<CyberEndorsement, 'name' | 'limit' | 'coinsurance'>
      )>>>, externalCarrier?: Maybe<(
        { __typename?: 'ExternalCarrier' }
        & Pick<ExternalCarrier, 'id' | 'name'>
      )> }
    )>, errors?: Maybe<(
      { __typename?: 'CyberUpdateManualQuoteErrors' }
      & Pick<CyberUpdateManualQuoteErrors, 'base' | 'admitted' | 'carrierName' | 'comments' | 'declinationReason' | 'deductible' | 'fee' | 'limit' | 'policyNumber' | 'premium'>
      & { coverages?: Maybe<Array<(
        { __typename?: 'CyberUpdateManualQuoteCoveragesErrors' }
        & Pick<CyberUpdateManualQuoteCoveragesErrors, 'name' | 'limit' | 'deductible'>
      )>>, endorsements?: Maybe<Array<(
        { __typename?: 'CyberUpdateManualQuoteEndorsementsErrors' }
        & Pick<CyberUpdateManualQuoteEndorsementsErrors, 'name' | 'limit' | 'coinsurance'>
      )>> }
    )> }
  )> }
);

export type DeclineQuoteProposalMutationVariables = Exact<{
  input: DeclineProposalInput;
}>;


export type DeclineQuoteProposalMutation = (
  { __typename?: 'Mutation' }
  & { declineProposal?: Maybe<(
    { __typename?: 'DeclineProposalPayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'status'>
      & { readableStatus: Quote['status'] }
      & { revisions: (
        { __typename?: 'RevisionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Revision' }
          & Pick<Revision, 'id' | 'attachmentPoint' | 'cedingCommission' | 'deductible' | 'exclusions' | 'expiryDate' | 'keyPerils' | 'layerLimit' | 'netPremium' | 'participation' | 'premium' | 'status' | 'createdAt' | 'number' | 'isIncomplete'>
          & { documents: (
            { __typename?: 'DocumentConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Document' }
              & Pick<Document, 'id'>
            )>>> }
          ), quotedLayers: Array<(
            { __typename?: 'QuotedLayer' }
            & QuotedLayerInfoFragment
          )>, zip?: Maybe<(
            { __typename?: 'Document' }
            & Pick<Document, 'id' | 'url'>
          )> }
        )>>> }
      ), worksheet: (
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
      ) }
      & LatestRevisionFragment
    )> }
  )> }
);

export type DeleteAuthorizedMarketContactMutationVariables = Exact<{
  input: DeleteAuthorizedMarketContactInput;
}>;


export type DeleteAuthorizedMarketContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteAuthorizedMarketContact?: Maybe<(
    { __typename?: 'DeleteAuthorizedMarketContactPayload' }
    & Pick<DeleteAuthorizedMarketContactPayload, 'errors'>
    & { authorizedMarket?: Maybe<(
      { __typename?: 'AuthorizedMarket' }
      & Pick<AuthorizedMarket, 'id' | 'disabled' | 'specialInstructions'>
      & { reinsuranceCompany: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ), contacts: (
        { __typename?: 'AuthorizedMarketContactConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'AuthorizedMarketContact' }
          & Pick<AuthorizedMarketContact, 'id' | 'description'>
          & { reinsurer: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'email' | 'firstName' | 'lastName' | 'fullName' | 'companyName' | 'avatarUrl' | 'pendingInvitation'>
          ) }
        )>>> }
      ) }
    )> }
  )> }
);

export type ForwardDocumentMutationVariables = Exact<{
  input: DocumentForwardInput;
}>;


export type ForwardDocumentMutation = (
  { __typename?: 'Mutation' }
  & { forwardDocument?: Maybe<(
    { __typename?: 'DocumentForwardPayload' }
    & { participations?: Maybe<Array<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
      )>> }
    )>>, errors?: Maybe<(
      { __typename?: 'ParticipationErrors' }
      & Pick<ParticipationErrors, 'id'>
    )> }
  )> }
);

export type UpdateParticipationDocumentsMutationVariables = Exact<{
  input: UpdateParticipationDocumentsInput;
}>;


export type UpdateParticipationDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { updateParticipationDocuments?: Maybe<(
    { __typename?: 'UpdateParticipationDocumentsPayload' }
    & Pick<UpdateParticipationDocumentsPayload, 'errors'>
    & { participation: (
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>> }
    ) }
  )> }
);

export type CompleteEndorsementMutationVariables = Exact<{
  input: CompleteEndorsementInput;
}>;


export type CompleteEndorsementMutation = (
  { __typename?: 'Mutation' }
  & { completeEndorsement?: Maybe<(
    { __typename?: 'CompleteEndorsementPayload' }
    & Pick<CompleteEndorsementPayload, 'errors'>
    & { endorsement?: Maybe<(
      { __typename?: 'Endorsement' }
      & Pick<Endorsement, 'id'>
      & EndorsementInfoFragment
    )> }
  )> }
);

export type CreateEndorsementMutationVariables = Exact<{
  input: CreateEndorsementInput;
}>;


export type CreateEndorsementMutation = (
  { __typename?: 'Mutation' }
  & { createEndorsement?: Maybe<(
    { __typename?: 'CreateEndorsementPayload' }
    & Pick<CreateEndorsementPayload, 'errors'>
    & { endorsement?: Maybe<(
      { __typename?: 'Endorsement' }
      & Pick<Endorsement, 'id'>
      & EndorsementInfoFragment
    )> }
  )> }
);

export type DropEndorsementMutationVariables = Exact<{
  input: DropEndorsementInput;
}>;


export type DropEndorsementMutation = (
  { __typename?: 'Mutation' }
  & { dropEndorsement?: Maybe<(
    { __typename?: 'DropEndorsementPayload' }
    & Pick<DropEndorsementPayload, 'errors'>
    & { endorsement?: Maybe<(
      { __typename?: 'Endorsement' }
      & Pick<Endorsement, 'id'>
      & EndorsementInfoFragment
    )> }
  )> }
);

export type StartEndorsementMutationVariables = Exact<{
  input: StartEndorsementInput;
}>;


export type StartEndorsementMutation = (
  { __typename?: 'Mutation' }
  & { startEndorsement?: Maybe<(
    { __typename?: 'StartEndorsementPayload' }
    & Pick<StartEndorsementPayload, 'errors'>
    & { endorsement?: Maybe<(
      { __typename?: 'Endorsement' }
      & Pick<Endorsement, 'id'>
      & EndorsementInfoFragment
    )> }
  )> }
);

export type UpdateEndorsementMutationVariables = Exact<{
  input: UpdateEndorsementInput;
}>;


export type UpdateEndorsementMutation = (
  { __typename?: 'Mutation' }
  & { updateEndorsement?: Maybe<(
    { __typename?: 'UpdateEndorsementPayload' }
    & Pick<UpdateEndorsementPayload, 'errors'>
    & { endorsement?: Maybe<(
      { __typename?: 'Endorsement' }
      & Pick<Endorsement, 'id'>
      & EndorsementInfoFragment
    )> }
  )> }
);

export type AcceptEndorsementQuoteMutationVariables = Exact<{
  input: AcceptEndorsementQuoteInput;
}>;


export type AcceptEndorsementQuoteMutation = (
  { __typename?: 'Mutation' }
  & { acceptEndorsementQuote?: Maybe<(
    { __typename?: 'AcceptEndorsementQuotePayload' }
    & Pick<AcceptEndorsementQuotePayload, 'errors'>
    & { endorsementQuote?: Maybe<(
      { __typename?: 'EndorsementQuote' }
      & Pick<EndorsementQuote, 'id' | 'status'>
      & { endorsement: (
        { __typename?: 'Endorsement' }
        & Pick<Endorsement, 'id'>
        & { worksheet: (
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        ) }
      ) }
    )> }
  )> }
);

export type CompleteEndorsementQuoteMutationVariables = Exact<{
  input: CompleteEndorsementQuoteInput;
}>;


export type CompleteEndorsementQuoteMutation = (
  { __typename?: 'Mutation' }
  & { completeEndorsementQuote?: Maybe<(
    { __typename?: 'CompleteEndorsementQuotePayload' }
    & Pick<CompleteEndorsementQuotePayload, 'errors'>
    & { endorsementQuote?: Maybe<(
      { __typename?: 'EndorsementQuote' }
      & Pick<EndorsementQuote, 'id' | 'status'>
      & { endorsement: (
        { __typename?: 'Endorsement' }
        & Pick<Endorsement, 'id'>
        & { worksheet: (
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        ) }
      ) }
    )> }
  )> }
);

export type DeclineEndorsementQuoteMutationVariables = Exact<{
  input: DeclineEndorsementQuoteInput;
}>;


export type DeclineEndorsementQuoteMutation = (
  { __typename?: 'Mutation' }
  & { declineEndorsementQuote?: Maybe<(
    { __typename?: 'DeclineEndorsementQuotePayload' }
    & Pick<DeclineEndorsementQuotePayload, 'errors'>
    & { endorsementQuote?: Maybe<(
      { __typename?: 'EndorsementQuote' }
      & Pick<EndorsementQuote, 'id' | 'status'>
      & { endorsement: (
        { __typename?: 'Endorsement' }
        & Pick<Endorsement, 'id'>
        & { worksheet: (
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        ) }
      ) }
    )> }
  )> }
);

export type SendEndorsementQuoteMutationVariables = Exact<{
  input: SendEndorsementQuoteInput;
}>;


export type SendEndorsementQuoteMutation = (
  { __typename?: 'Mutation' }
  & { sendEndorsementQuote?: Maybe<(
    { __typename?: 'SendEndorsementQuotePayload' }
    & Pick<SendEndorsementQuotePayload, 'errors'>
    & { endorsementQuote?: Maybe<(
      { __typename?: 'EndorsementQuote' }
      & Pick<EndorsementQuote, 'id' | 'status'>
      & { endorsement: (
        { __typename?: 'Endorsement' }
        & Pick<Endorsement, 'id'>
        & { worksheet: (
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        ) }
      ) }
    )> }
  )> }
);

export type ExportBmsQuotesMutationVariables = Exact<{
  input: ExportBmsQuotesInput;
}>;


export type ExportBmsQuotesMutation = (
  { __typename?: 'Mutation' }
  & { exportBmsQuotes?: Maybe<(
    { __typename?: 'ExportBmsQuotesPayload' }
    & Pick<ExportBmsQuotesPayload, 'errors'>
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & Pick<Worksheet, 'id'>
    )> }
  )> }
);

export type ExportQuotesMutationVariables = Exact<{
  input: ExportQuotesInput;
}>;


export type ExportQuotesMutation = (
  { __typename?: 'Mutation' }
  & { exportQuotes?: Maybe<(
    { __typename?: 'ExportQuotesPayload' }
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & Pick<Worksheet, 'id'>
    )> }
  )> }
);

export type ImportQuoteFromDocumentMutationVariables = Exact<{
  input: ImportQuoteExtractionDocumentInput;
}>;


export type ImportQuoteFromDocumentMutation = (
  { __typename?: 'Mutation' }
  & { importQuoteExtractionDocument?: Maybe<(
    { __typename?: 'ImportQuoteExtractionDocumentPayload' }
    & Pick<ImportQuoteExtractionDocumentPayload, 'errors'>
    & { import?: Maybe<(
      { __typename?: 'TemplateImport' }
      & Pick<TemplateImport, 'id' | 'completedAt'>
    )> }
  )> }
);

export type InviteBrokerMutationVariables = Exact<{
  input: InviteBrokerInput;
}>;


export type InviteBrokerMutation = (
  { __typename?: 'Mutation' }
  & { inviteBroker?: Maybe<(
    { __typename?: 'InviteBrokerPayload' }
    & Pick<InviteBrokerPayload, 'errors'>
    & { collaboration?: Maybe<(
      { __typename: 'BrokerInvite' }
      & Pick<BrokerInvite, 'id'>
      & { worksheet: (
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & { collaborations: Array<(
          { __typename: 'BrokerInvite' }
          & Pick<BrokerInvite, 'id'>
        ) | (
          { __typename: 'BrokerCollaboration' }
          & Pick<BrokerCollaboration, 'id'>
        ) | (
          { __typename: 'ClientCollaboration' }
          & Pick<ClientCollaboration, 'id'>
        ) | (
          { __typename: 'MgaCollaboration' }
          & Pick<MgaCollaboration, 'id'>
        ) | (
          { __typename: 'WholesalerCollaboration' }
          & Pick<WholesalerCollaboration, 'id'>
        )> }
      ), user: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id'>
          & { brokers: (
            { __typename?: 'BrokerConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'fullName'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            )>>> }
          ) }
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id'>
          & { brokers: (
            { __typename?: 'BrokerConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'fullName'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            )>>> }
          ) }
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id'>
          & { brokers: (
            { __typename?: 'BrokerConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'fullName'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            )>>> }
          ) }
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id'>
          & { brokers: (
            { __typename?: 'BrokerConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'fullName'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            )>>> }
          ) }
        ) }
      ) }
    )> }
  )> }
);

export type IssueCertificateMutationVariables = Exact<{
  input: IssueCertificateInput;
}>;


export type IssueCertificateMutation = (
  { __typename?: 'Mutation' }
  & { issueCertificate?: Maybe<(
    { __typename?: 'IssueCertificatePayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & QuoteInfoFragmentFragment
    )> }
  )> }
);

export type MarkAsDeclinedQuoteMutationVariables = Exact<{
  input: MarkAsDeclinedQuoteInput;
}>;


export type MarkAsDeclinedQuoteMutation = (
  { __typename?: 'Mutation' }
  & { markAsDeclinedQuote?: Maybe<(
    { __typename?: 'MarkAsDeclinedQuotePayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'status' | 'stage'>
      & { readableStatus: Quote['status'] }
    )> }
  )> }
);

export type OnboardingProfileCompletedMutationVariables = Exact<{ [key: string]: never; }>;


export type OnboardingProfileCompletedMutation = (
  { __typename?: 'Mutation' }
  & { onboardingProfileCompleted?: Maybe<(
    { __typename?: 'OnboardingProfileCompletedPayload' }
    & Pick<OnboardingProfileCompletedPayload, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'avatarUrl' | 'bannerUrl' | 'phoneNumber' | 'linesOfBusiness' | 'jobTitle' | 'onboarding'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'avatarUrl' | 'bannerUrl' | 'phoneNumber' | 'linesOfBusiness' | 'jobTitle' | 'onboarding'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'avatarUrl' | 'bannerUrl' | 'phoneNumber' | 'linesOfBusiness' | 'jobTitle' | 'onboarding'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'avatarUrl' | 'bannerUrl' | 'phoneNumber' | 'linesOfBusiness' | 'jobTitle' | 'onboarding'>
    )> }
  )> }
);

export type UpdateCommissionPercentageMutationVariables = Exact<{
  input: UpdateCommissionPercentageInput;
}>;


export type UpdateCommissionPercentageMutation = (
  { __typename?: 'Mutation' }
  & { updateCommissionPercentage?: Maybe<(
    { __typename?: 'UpdateCommissionPercentagePayload' }
    & Pick<UpdateCommissionPercentagePayload, 'errors'>
  )> }
);

export type PanelsAdjustQuotesMutationVariables = Exact<{
  input: PanelsAdjustQuotesInput;
}>;


export type PanelsAdjustQuotesMutation = (
  { __typename?: 'Mutation' }
  & { panelsAdjustQuotes?: Maybe<(
    { __typename?: 'PanelsAdjustQuotesPayload' }
    & Pick<PanelsAdjustQuotesPayload, 'errors'>
  )> }
);

export type PanelsBindQuoteMutationVariables = Exact<{
  input: PanelsBindQuoteInput;
}>;


export type PanelsBindQuoteMutation = (
  { __typename?: 'Mutation' }
  & { panelsBindQuote?: Maybe<(
    { __typename?: 'PanelsBindQuotePayload' }
    & Pick<PanelsBindQuotePayload, 'error'>
    & { quotation?: Maybe<(
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id' | 'status' | 'statusMessage' | 'policyNumber'>
      & { documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'category' | 'version'>
        & DocumentForFormFragment
      )>, participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { discussion?: Maybe<(
          { __typename?: 'Discussion' }
          & Pick<Discussion, 'id'>
          & MessagesFragment
        )> }
      ) }
    )> }
  )> }
);

export type CreateCarrierPanelQuotationMutationVariables = Exact<{
  input: CreateCarrierPanelQuotationInput;
}>;


export type CreateCarrierPanelQuotationMutation = (
  { __typename?: 'Mutation' }
  & { createCarrierPanelQuotation?: Maybe<(
    { __typename?: 'CreateCarrierPanelQuotationPayload' }
    & { quotation: (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
      & { submission: (
        { __typename?: 'PanelSubmission' }
        & Pick<PanelSubmission, 'id'>
      ) }
    ) }
  )> }
);

export type CreateManualPanelQuotationMutationVariables = Exact<{
  input: CreateManualPanelQuotationInput;
}>;


export type CreateManualPanelQuotationMutation = (
  { __typename?: 'Mutation' }
  & { createManualPanelQuotation?: Maybe<(
    { __typename?: 'CreateManualPanelQuotationPayload' }
    & { quotation: (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & { submission: (
        { __typename?: 'PanelSubmission' }
        & Pick<PanelSubmission, 'id'>
      ) }
    ) }
  )> }
);

export type CreateMultilinePanelSubmissionMutationVariables = Exact<{
  input: CreateMultilinePanelSubmissionInput;
}>;


export type CreateMultilinePanelSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { createMultilinePanelSubmission?: Maybe<(
    { __typename?: 'CreateMultilinePanelSubmissionPayload' }
    & { submission: (
      { __typename?: 'PanelSubmission' }
      & Pick<PanelSubmission, 'id'>
      & PanelSubmissionInfoFragment
    ) }
  )> }
);

export type CreatePanelSubmissionMutationVariables = Exact<{
  input: CreatePanelSubmissionInput;
}>;


export type CreatePanelSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { createPanelSubmission?: Maybe<(
    { __typename?: 'CreatePanelSubmissionPayload' }
    & { submission: (
      { __typename?: 'PanelSubmission' }
      & Pick<PanelSubmission, 'id'>
    ) }
  )> }
);

export type DeclinePanelQuoteMutationVariables = Exact<{
  input: DeclinePanelQuoteInput;
}>;


export type DeclinePanelQuoteMutation = (
  { __typename?: 'Mutation' }
  & { declinePanelQuote?: Maybe<(
    { __typename?: 'DeclinePanelQuotePayload' }
    & { quotation?: Maybe<(
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id'>
      & { participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { quotations: (
          { __typename?: 'QuotationConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
            & PanelQuotationInfo_CyberQuotation_Fragment
          ) | (
            { __typename?: 'PCGQuotation' }
            & Pick<PcgQuotation, 'id'>
            & PanelQuotationInfo_PcgQuotation_Fragment
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
            & PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
            & PanelQuotationInfo_PanelsQuotation_Fragment
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
            & PanelQuotationInfo_MlQuotation_Fragment
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
            & PanelQuotationInfo_TerrorismQuotation_Fragment
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
            & PanelQuotationInfo_BopQuotation_Fragment
          )>>> }
        ) }
      ) }
    )> }
  )> }
);

export type EditPanelQuotationMutationVariables = Exact<{
  input: EditPanelQuotationInput;
}>;


export type EditPanelQuotationMutation = (
  { __typename?: 'Mutation' }
  & { editPanelQuotation?: Maybe<(
    { __typename?: 'EditPanelQuotationPayload' }
    & { quotation?: Maybe<(
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id'>
    )> }
  )> }
);

export type EditRetailBrokerMutationVariables = Exact<{
  input: EditRetailBrokerInput;
}>;


export type EditRetailBrokerMutation = (
  { __typename?: 'Mutation' }
  & { editRetailBroker?: Maybe<(
    { __typename?: 'EditRetailBrokerPayload' }
    & Pick<EditRetailBrokerPayload, 'agencyCode' | 'deactivatedAt' | 'errors'>
  )> }
);

export type EditPanelSubmissionMutationVariables = Exact<{
  input: EditPanelSubmissionInput;
}>;


export type EditPanelSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { editPanelSubmission?: Maybe<(
    { __typename?: 'EditPanelSubmissionPayload' }
    & Pick<EditPanelSubmissionPayload, 'errors'>
    & { submission: (
      { __typename?: 'PanelSubmission' }
      & Pick<PanelSubmission, 'id'>
    ) }
  )> }
);

export type PanelsExportQuoteComparisonMutationVariables = Exact<{
  input: PanelsExportQuoteComparisonInput;
}>;


export type PanelsExportQuoteComparisonMutation = (
  { __typename?: 'Mutation' }
  & { panelsExportQuoteComparison?: Maybe<(
    { __typename?: 'PanelsExportQuoteComparisonPayload' }
    & Pick<PanelsExportQuoteComparisonPayload, 'errors'>
  )> }
);

export type PanelsIssuePolicyMutationVariables = Exact<{
  input: PanelsIssuePolicyInput;
}>;


export type PanelsIssuePolicyMutation = (
  { __typename?: 'Mutation' }
  & { panelsIssuePolicy?: Maybe<(
    { __typename?: 'PanelsIssuePolicyPayload' }
    & { quotation?: Maybe<(
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id' | 'status' | 'statusMessage'>
      & { documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'category' | 'version'>
        & DocumentForFormFragment
      )> }
    )>, errors?: Maybe<(
      { __typename?: 'PanelsIssuePolicyErrors' }
      & Pick<PanelsIssuePolicyErrors, 'policyNumber'>
    )> }
  )> }
);

export type PanelsRequestToBindMutationVariables = Exact<{
  input: PanelsRequestToBindInput;
}>;


export type PanelsRequestToBindMutation = (
  { __typename?: 'Mutation' }
  & { panelsRequestToBind?: Maybe<(
    { __typename?: 'PanelsRequestToBindPayload' }
    & Pick<PanelsRequestToBindPayload, 'error'>
    & { quotation?: Maybe<(
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id' | 'status' | 'statusMessage'>
      & { submission: (
        { __typename?: 'PanelSubmission' }
        & Pick<PanelSubmission, 'id' | 'status'>
      ) }
    )> }
  )> }
);

export type SendToMarketsMutationVariables = Exact<{
  input: SendToMarketsInput;
}>;


export type SendToMarketsMutation = (
  { __typename?: 'Mutation' }
  & { sendToMarkets?: Maybe<(
    { __typename?: 'SendToMarketsPayload' }
    & Pick<SendToMarketsPayload, 'errors'>
    & { submission?: Maybe<(
      { __typename?: 'PanelSubmission' }
      & Pick<PanelSubmission, 'id'>
      & PanelSubmissionInfoFragment
    )> }
  )> }
);

export type PanelsUpdateSubmissionDocumentsMutationVariables = Exact<{
  input: PanelsUpdateSubmissionDocumentsInput;
}>;


export type PanelsUpdateSubmissionDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { panelsUpdateSubmissionDocuments?: Maybe<(
    { __typename?: 'PanelsUpdateSubmissionDocumentsPayload' }
    & Pick<PanelsUpdateSubmissionDocumentsPayload, 'errors'>
    & { submission?: Maybe<(
      { __typename?: 'PanelSubmission' }
      & Pick<PanelSubmission, 'id'>
      & PanelsSubmissionFileCabinetFragment
    )> }
  )> }
);

export type ChangeParicipationNewOwnerMutationVariables = Exact<{
  input: ChangeParticipationWithNewOwnerInput;
}>;


export type ChangeParicipationNewOwnerMutation = (
  { __typename?: 'Mutation' }
  & { changeParticipationNewOwner?: Maybe<(
    { __typename?: 'ChangeParticipationWithNewOwnerPayload' }
    & Pick<ChangeParticipationWithNewOwnerPayload, 'invitationToken' | 'errors'>
    & { participation?: Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { user?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type PcgAutoSaveSubmissionMutationVariables = Exact<{
  input: PcgAutoSaveSubmissionInput;
}>;


export type PcgAutoSaveSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { pcgAutoSaveSubmission?: Maybe<(
    { __typename?: 'PCGAutoSaveSubmissionPayload' }
    & { errors?: Maybe<(
      { __typename?: 'PCGAutoSaveSubmissionErrors' }
      & Pick<PcgAutoSaveSubmissionErrors, 'base'>
    )>, submission?: Maybe<(
      { __typename?: 'PCGSubmission' }
      & Pick<PcgSubmission, 'id' | 'schemas' | 'name' | 'policyStartDate' | 'policyEndDate' | 'message' | 'emailSignOff' | 'placementTermsAccepted'>
      & { applicants?: Maybe<Array<(
        { __typename?: 'PCGApplicant' }
        & Pick<PcgApplicant, 'id' | 'firstName' | 'lastName'>
      )>>, selectedCarriers: Array<(
        { __typename?: 'EmailCarrierType' }
        & Pick<EmailCarrierType, 'id' | 'agentCode'>
      )>, documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>, review: (
        { __typename?: 'PCGSubmissionReview' }
        & Pick<PcgSubmissionReview, 'applicants' | 'additionalDrivers' | 'coverages'>
      ), products: (
        { __typename?: 'InsurancePolicyConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'InsurancePolicy' }
          & Pick<InsurancePolicy, 'id'>
        )>>> }
      ), coverages?: Maybe<Array<(
        { __typename?: 'PCGAutoCoverageType' }
        & Pick<PcgAutoCoverageType, 'drivers' | 'id' | 'type'>
        & { vehicles?: Maybe<Array<(
          { __typename?: 'PCGAutoVehiclesType' }
          & Pick<PcgAutoVehiclesType, 'year' | 'make' | 'model' | 'value'>
        )>>, additionalDrivers?: Maybe<Array<(
          { __typename?: 'PCGAutoAdditionalDriversType' }
          & Pick<PcgAutoAdditionalDriversType, 'firstName' | 'lastName' | 'relationship'>
        )>> }
      ) | (
        { __typename?: 'PCGExcessCoverageType' }
        & Pick<PcgExcessCoverageType, 'id' | 'type'>
      ) | (
        { __typename?: 'PCGHomeCoverageType' }
        & Pick<PcgHomeCoverageType, 'id' | 'type'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'streetLine1' | 'streetLine2' | 'city' | 'zipCode' | 'state' | 'country'>
        )> }
      ) | (
        { __typename?: 'PCGValuablesCoverageType' }
        & Pick<PcgValuablesCoverageType, 'id' | 'type'>
      )>> }
    )> }
  )> }
);

export type PcgBindEmailQuotationMutationVariables = Exact<{
  input: PcgBindEmailQuotationInput;
}>;


export type PcgBindEmailQuotationMutation = (
  { __typename?: 'Mutation' }
  & { pcgBindEmailQuotation?: Maybe<(
    { __typename?: 'PCGBindEmailQuotationPayload' }
    & Pick<PcgBindEmailQuotationPayload, 'error'>
    & { quotation?: Maybe<(
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id' | 'status'>
      & { documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'category' | 'version'>
        & DocumentForFormFragment
      )>, participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { discussion?: Maybe<(
          { __typename?: 'Discussion' }
          & Pick<Discussion, 'id'>
          & MessagesFragment
        )> }
      ) }
    )> }
  )> }
);

export type PcgBindManualQuotationMutationVariables = Exact<{
  input: PcgBindManualQuotationInput;
}>;


export type PcgBindManualQuotationMutation = (
  { __typename?: 'Mutation' }
  & { pcgBindManualQuotation?: Maybe<(
    { __typename?: 'PCGBindManualQuotationPayload' }
    & { quotation?: Maybe<(
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id' | 'status'>
    )>, errors?: Maybe<(
      { __typename?: 'PCGBindManualQuotationErrors' }
      & Pick<PcgBindManualQuotationErrors, 'base'>
    )> }
  )> }
);

export type PcgCreateEmailQuotationMutationVariables = Exact<{
  input: PcgCreateEmailQuotationInput;
}>;


export type PcgCreateEmailQuotationMutation = (
  { __typename?: 'Mutation' }
  & { pcgCreateEmailQuotation?: Maybe<(
    { __typename?: 'PCGCreateEmailQuotationPayload' }
    & { quotation?: Maybe<(
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
    )> }
  )> }
);

export type PcgCreateManualQuotationMutationVariables = Exact<{
  input: PcgCreateManualQuotationInput;
}>;


export type PcgCreateManualQuotationMutation = (
  { __typename?: 'Mutation' }
  & { pcgCreateManualQuotation?: Maybe<(
    { __typename?: 'PCGCreateManualQuotationPayload' }
    & { quotation?: Maybe<(
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id'>
    )>, errors?: Maybe<(
      { __typename?: 'PCGCreateManualQuotationErrors' }
      & Pick<PcgCreateManualQuotationErrors, 'customCarrier' | 'carrier' | 'currentPolicy'>
    )> }
  )> }
);

export type PcgCreateSubmissionMutationVariables = Exact<{
  input: PcgCreateSubmissionInput;
}>;


export type PcgCreateSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { pcgCreateSubmission?: Maybe<(
    { __typename?: 'PCGCreateSubmissionPayload' }
    & { submission?: Maybe<(
      { __typename?: 'PCGSubmission' }
      & Pick<PcgSubmission, 'id'>
    )>, errors?: Maybe<(
      { __typename?: 'PCGCreateSubmissionErrors' }
      & Pick<PcgCreateSubmissionErrors, 'name'>
    )> }
  )> }
);

export type PcgDeclineToBindEmailQuotationMutationVariables = Exact<{
  input: PcgDeclineToBindEmailQuotationInput;
}>;


export type PcgDeclineToBindEmailQuotationMutation = (
  { __typename?: 'Mutation' }
  & { pcgDeclineToBindEmailQuotation?: Maybe<(
    { __typename?: 'PCGDeclineToBindEmailQuotationPayload' }
    & Pick<PcgDeclineToBindEmailQuotationPayload, 'error'>
    & { quotation?: Maybe<(
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id' | 'status'>
      & { documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'category' | 'version'>
        & DocumentForFormFragment
      )>, participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { discussion?: Maybe<(
          { __typename?: 'Discussion' }
          & Pick<Discussion, 'id'>
          & MessagesFragment
        )> }
      ) }
    )> }
  )> }
);

export type PcgDeleteManualQuotationMutationVariables = Exact<{
  input: PcgDeleteManualQuotationInput;
}>;


export type PcgDeleteManualQuotationMutation = (
  { __typename?: 'Mutation' }
  & { pcgDeleteManualQuotation?: Maybe<(
    { __typename?: 'PCGDeleteManualQuotationPayload' }
    & Pick<PcgDeleteManualQuotationPayload, 'error'>
    & { submission?: Maybe<(
      { __typename?: 'PCGSubmission' }
      & Pick<PcgSubmission, 'id'>
      & PcgSubmissionInfoFragment
    )> }
  )> }
);

export type PcgEditQuotationMutationVariables = Exact<{
  input: PcgEditQuotationInput;
}>;


export type PcgEditQuotationMutation = (
  { __typename?: 'Mutation' }
  & { pcgEditQuotation?: Maybe<(
    { __typename?: 'PCGEditQuotationPayload' }
    & { quotation?: Maybe<(
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id' | 'currentPolicy' | 'status' | 'declinationReason' | 'customCarrier'>
      & { carrier?: Maybe<(
        { __typename?: 'ExternalCarrier' }
        & Pick<ExternalCarrier, 'id' | 'name'>
      )>, panelist?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      )>, review?: Maybe<(
        { __typename?: 'PCGReview' }
        & Pick<PcgReview, 'totalPremium'>
        & { quotes?: Maybe<Array<(
          { __typename?: 'PCGReviewStatusType' }
          & Pick<PcgReviewStatusType, 'totalPremium' | 'status'>
          & { quote?: Maybe<(
            { __typename?: 'PCGReviewQuoteType' }
            & Pick<PcgReviewQuoteType, 'text' | 'icon'>
          )> }
        )>> }
      )>, quotedLines?: Maybe<(
        { __typename?: 'PCGQuotedLineTypeConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'PCGHomeQuotedLineType' }
          & Pick<PcgHomeQuotedLineType, 'allOtherPerilDeductible' | 'totalPremium' | 'id' | 'type'>
          & { coverages?: Maybe<Array<(
            { __typename?: 'PCGHomeQuotedLineCoveragesType' }
            & Pick<PcgHomeQuotedLineCoveragesType, 'coverage'>
            & { homeCoverageLimit?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )>, deductible?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )> }
          )>>, enhancements?: Maybe<Array<(
            { __typename?: 'PCGHomeQuotedLineEnhancementsType' }
            & Pick<PcgHomeQuotedLineEnhancementsType, 'enhancement'>
            & { limit?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )>, deductible?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )>, premium?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )> }
          )>>, additionalOptions?: Maybe<Array<(
            { __typename?: 'PCGAdditionalOptionsType' }
            & Pick<PcgAdditionalOptionsType, 'option'>
            & { deductible?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )>, premium?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )> }
          )>>, additionalFeatures?: Maybe<Array<(
            { __typename?: 'PCGAdditionalFeature' }
            & Pick<PcgAdditionalFeature, 'feature' | 'included'>
          )>> }
        ) | (
          { __typename?: 'PCGValuablesQuotedLineType' }
          & Pick<PcgValuablesQuotedLineType, 'totalPremium' | 'id' | 'type'>
          & { coverages?: Maybe<Array<(
            { __typename?: 'PCGValuablesQuotedLineCoveragesType' }
            & Pick<PcgValuablesQuotedLineCoveragesType, 'name' | 'limit' | 'blanketLimit'>
          )>>, additionalFeatures?: Maybe<Array<(
            { __typename?: 'PCGAdditionalFeature' }
            & Pick<PcgAdditionalFeature, 'feature' | 'included'>
          )>> }
        ) | (
          { __typename?: 'PCGAutoQuotedLineType' }
          & Pick<PcgAutoQuotedLineType, 'combinedLimit' | 'bodilyInjuryAndPropertyDamage' | 'bodilyInjuryPerPerson' | 'bodilyInjuryEachAccident' | 'propertyDamage' | 'totalPremium' | 'id' | 'type'>
          & { agreedValues?: Maybe<Array<(
            { __typename?: 'PCGAutoAgreedValuesType' }
            & Pick<PcgAutoAgreedValuesType, 'vehicle' | 'value'>
          )>>, medicalPayments?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, underinsuredMotorists?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, extendedTowingAndLabor?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, fullGlassCoverage?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, additionalCoverages?: Maybe<Array<(
            { __typename?: 'PCGAutoCoverageLimitsType' }
            & Pick<PcgAutoCoverageLimitsType, 'name'>
            & { limit?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'type' | 'value'>
            )> }
          )>>, collisionDeductible?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, comprehensiveDeductible?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, additionalFeatures?: Maybe<Array<(
            { __typename?: 'PCGAutoAdditionalFeaturesType' }
            & Pick<PcgAutoAdditionalFeaturesType, 'feature' | 'included'>
          )>> }
        ) | (
          { __typename?: 'PCGExcessQuotedLineType' }
          & Pick<PcgExcessQuotedLineType, 'totalPremium' | 'id' | 'type'>
          & { coverages?: Maybe<Array<(
            { __typename?: 'PCGExcessQuotedLineCoveragesType' }
            & Pick<PcgExcessQuotedLineCoveragesType, 'coverage' | 'limit'>
          )>>, limitOptions?: Maybe<Array<(
            { __typename?: 'PCGExcessLimitOptionsType' }
            & Pick<PcgExcessLimitOptionsType, 'coverage' | 'limit' | 'totalPremium'>
          )>> }
        )>>> }
      )> }
    )>, errors?: Maybe<(
      { __typename?: 'PCGEditQuotationErrors' }
      & Pick<PcgEditQuotationErrors, 'base' | 'customCarrier' | 'carrier' | 'currentPolicy'>
      & { valuableQuotedLines?: Maybe<Array<(
        { __typename?: 'PCGEditQuotationValuableQuotedLinesAttributesErrors' }
        & Pick<PcgEditQuotationValuableQuotedLinesAttributesErrors, 'base'>
        & { additionalFeatures?: Maybe<Array<(
          { __typename?: 'PCGEditQuotationValuableQuotedLinesAttributesAdditionalFeaturesErrors' }
          & Pick<PcgEditQuotationValuableQuotedLinesAttributesAdditionalFeaturesErrors, 'base' | 'feature' | 'included'>
        )>>, coverages?: Maybe<Array<(
          { __typename?: 'PCGEditQuotationValuableQuotedLinesAttributesCoveragesErrors' }
          & Pick<PcgEditQuotationValuableQuotedLinesAttributesCoveragesErrors, 'base' | 'blanketLimit' | 'limit' | 'name' | 'premium'>
        )>> }
      )>> }
    )> }
  )> }
);

export type PcgEditSubmissionMutationVariables = Exact<{
  input: PcgEditSubmissionInput;
}>;


export type PcgEditSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { pcgEditSubmission?: Maybe<(
    { __typename?: 'PCGEditSubmissionPayload' }
    & { errors?: Maybe<(
      { __typename?: 'PCGEditSubmissionErrors' }
      & Pick<PcgEditSubmissionErrors, 'applicants' | 'name' | 'message' | 'emailSignOff' | 'documents' | 'placementTermsAccepted' | 'policyStartDate' | 'policyEndDate' | 'selectedCarriers'>
    )>, submission?: Maybe<(
      { __typename?: 'PCGSubmission' }
      & Pick<PcgSubmission, 'id' | 'schemas' | 'name' | 'policyStartDate' | 'policyEndDate' | 'message'>
      & { applicants?: Maybe<Array<(
        { __typename?: 'PCGApplicant' }
        & Pick<PcgApplicant, 'id' | 'firstName' | 'lastName'>
      )>>, selectedCarriers: Array<(
        { __typename?: 'EmailCarrierType' }
        & Pick<EmailCarrierType, 'id' | 'agentCode'>
      )>, documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>, products: (
        { __typename?: 'InsurancePolicyConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'InsurancePolicy' }
          & Pick<InsurancePolicy, 'id'>
        )>>> }
      ), coverages?: Maybe<Array<(
        { __typename?: 'PCGAutoCoverageType' }
        & Pick<PcgAutoCoverageType, 'drivers' | 'id' | 'type'>
        & { vehicles?: Maybe<Array<(
          { __typename?: 'PCGAutoVehiclesType' }
          & Pick<PcgAutoVehiclesType, 'year' | 'make' | 'model' | 'value'>
        )>>, additionalDrivers?: Maybe<Array<(
          { __typename?: 'PCGAutoAdditionalDriversType' }
          & Pick<PcgAutoAdditionalDriversType, 'firstName' | 'lastName' | 'relationship'>
        )>> }
      ) | (
        { __typename?: 'PCGExcessCoverageType' }
        & Pick<PcgExcessCoverageType, 'id' | 'type'>
      ) | (
        { __typename?: 'PCGHomeCoverageType' }
        & Pick<PcgHomeCoverageType, 'id' | 'type'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'streetLine1' | 'streetLine2' | 'city' | 'zipCode' | 'state' | 'country'>
        )> }
      ) | (
        { __typename?: 'PCGValuablesCoverageType' }
        & Pick<PcgValuablesCoverageType, 'id' | 'type'>
      )>> }
    )> }
  )> }
);

export type PcgRequestToBindEmailQuotationMutationVariables = Exact<{
  input: PcgRequestToBindEmailQuotationInput;
}>;


export type PcgRequestToBindEmailQuotationMutation = (
  { __typename?: 'Mutation' }
  & { pcgRequestToBindEmailQuotation?: Maybe<(
    { __typename?: 'PCGRequestToBindEmailQuotationPayload' }
    & { quotation?: Maybe<(
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id' | 'status'>
      & { documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'category' | 'version'>
        & DocumentForFormFragment
      )>, participation: (
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { discussion?: Maybe<(
          { __typename?: 'Discussion' }
          & Pick<Discussion, 'id'>
          & MessagesFragment
        )> }
      ) }
    )>, errors?: Maybe<(
      { __typename?: 'PCGRequestToBindEmailQuotationErrors' }
      & Pick<PcgRequestToBindEmailQuotationErrors, 'message' | 'documents' | 'base'>
    )> }
  )> }
);

export type PcgSaveProposalConfigurationMutationVariables = Exact<{
  input: PcgSaveProposalConfigurationInput;
}>;


export type PcgSaveProposalConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { pcgSaveProposalConfiguration?: Maybe<(
    { __typename?: 'PcgSaveProposalConfigurationPayload' }
    & Pick<PcgSaveProposalConfigurationPayload, 'success'>
  )> }
);

export type PostBroadcastMutationVariables = Exact<{
  input: PostBroadcastInput;
}>;


export type PostBroadcastMutation = (
  { __typename?: 'Mutation' }
  & { postBroadcast?: Maybe<(
    { __typename?: 'PostBroadcastPayload' }
    & Pick<PostBroadcastPayload, 'errors'>
    & { broadcast?: Maybe<(
      { __typename?: 'Broadcast' }
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & { broadcasts?: Maybe<(
          { __typename?: 'BroadcastConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Broadcast' }
            & Message_Broadcast_Fragment
          )>>> }
        )> }
      )> }
      & Message_Broadcast_Fragment
    )> }
  )> }
);

export type PostMessageMutationVariables = Exact<{
  input: PostMessageInput;
}>;


export type PostMessageMutation = (
  { __typename?: 'Mutation' }
  & { postMessage?: Maybe<(
    { __typename?: 'PostMessagePayload' }
    & Pick<PostMessagePayload, 'errors'>
    & { message?: Maybe<(
      { __typename?: 'DirectMessage' }
      & Pick<DirectMessage, 'id'>
      & { author: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'emailSignOff'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'emailSignOff'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'emailSignOff'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'emailSignOff'>
      ), discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & MessagesFragment
      )> }
    )> }
  )> }
);

export type ProposeRevisionMutationVariables = Exact<{
  input: ProposeRevisionInput;
}>;


export type ProposeRevisionMutation = (
  { __typename?: 'Mutation' }
  & { proposeRevision?: Maybe<(
    { __typename?: 'ProposeRevisionPayload' }
    & Pick<ProposeRevisionPayload, 'errors'>
    & { revision?: Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id'>
      & { quote: (
        { __typename?: 'Quote' }
        & Pick<Quote, 'id' | 'status' | 'stage'>
        & { readableStatus: Quote['status'] }
        & LatestRevisionFragment
        & QuoteMessagesSectionFragment
        & QuoteInfoFragmentFragment
      ) }
    )> }
  )> }
);

export type QuoteMessagesSectionFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id'>
  & { discussion?: Maybe<(
    { __typename?: 'Discussion' }
    & Pick<Discussion, 'id'>
    & MessagesFragment
  )>, worksheet: (
    { __typename?: 'Worksheet' }
    & Pick<Worksheet, 'id'>
    & { broadcasts?: Maybe<(
      { __typename?: 'BroadcastConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Broadcast' }
        & Pick<Broadcast, 'id'>
        & Message_Broadcast_Fragment
      )>>> }
    )> }
  ) }
);

export type AcceptAnswerMutationVariables = Exact<{
  input: AcceptAnswerInput;
}>;


export type AcceptAnswerMutation = (
  { __typename?: 'Mutation' }
  & { acceptAnswer?: Maybe<(
    { __typename?: 'AcceptAnswerPayload' }
    & { answer: (
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'acceptedAt'>
    ), errors?: Maybe<(
      { __typename?: 'AnswerErrors' }
      & Pick<AnswerErrors, 'id'>
    )> }
  )> }
);

export type CreateAnswerMutationVariables = Exact<{
  input: CreateAnswerInput;
}>;


export type CreateAnswerMutation = (
  { __typename?: 'Mutation' }
  & { createAnswer?: Maybe<(
    { __typename?: 'CreateAnswerPayload' }
    & { answer?: Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'category' | 'version'>
        & DocumentForFormFragment
      )>> }
    )>, errors?: Maybe<(
      { __typename?: 'AnswerErrors' }
      & Pick<AnswerErrors, 'text' | 'base'>
      & { documents?: Maybe<(
        { __typename?: 'DocumentErrors' }
        & Pick<DocumentErrors, 'attachment' | 'version' | 'category' | 'source'>
      )> }
    )> }
  )> }
);

export type CreateFollowUpQuestionMutationVariables = Exact<{
  input: CreateFollowUpQuestionInput;
}>;


export type CreateFollowUpQuestionMutation = (
  { __typename?: 'Mutation' }
  & { createFollowUpQuestion?: Maybe<(
    { __typename?: 'CreateFollowUpQuestionPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'text' | 'section' | 'deadline'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'category' | 'version'>
        & DocumentForFormFragment
      )>> }
    ), errors?: Maybe<(
      { __typename?: 'QuestionErrors' }
      & Pick<QuestionErrors, 'section' | 'text' | 'deadline'>
      & { documents?: Maybe<(
        { __typename?: 'DocumentErrors' }
        & Pick<DocumentErrors, 'attachment' | 'version' | 'category' | 'source'>
      )> }
    )> }
  )> }
);

export type CreateQuestionMutationVariables = Exact<{
  input: CreateQuestionInput;
}>;


export type CreateQuestionMutation = (
  { __typename?: 'Mutation' }
  & { createQuestion?: Maybe<(
    { __typename?: 'CreateQuestionPayload' }
    & { question?: Maybe<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'text' | 'section' | 'deadline'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'category' | 'version'>
        & DocumentForFormFragment
      )>> }
    )>, errors?: Maybe<(
      { __typename?: 'QuestionErrors' }
      & Pick<QuestionErrors, 'section' | 'text' | 'deadline'>
      & { documents?: Maybe<(
        { __typename?: 'DocumentErrors' }
        & Pick<DocumentErrors, 'attachment' | 'version' | 'category' | 'source'>
      )> }
    )> }
  )> }
);

export type DeleteAnswerMutationVariables = Exact<{
  input: DeleteAnswerInput;
}>;


export type DeleteAnswerMutation = (
  { __typename?: 'Mutation' }
  & { deleteAnswer?: Maybe<(
    { __typename?: 'DeleteAnswerPayload' }
    & Pick<DeleteAnswerPayload, 'deletedId' | 'error'>
  )> }
);

export type DeleteQuestionMutationVariables = Exact<{
  input: DeleteQuestionInput;
}>;


export type DeleteQuestionMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuestion?: Maybe<(
    { __typename?: 'DeleteQuestionPayload' }
    & Pick<DeleteQuestionPayload, 'deletedId' | 'error'>
  )> }
);

export type EditAnswerMutationVariables = Exact<{
  input: EditAnswerInput;
}>;


export type EditAnswerMutation = (
  { __typename?: 'Mutation' }
  & { editAnswer?: Maybe<(
    { __typename?: 'EditAnswerPayload' }
    & { answer?: Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'text'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'category' | 'version'>
        & DocumentForFormFragment
      )>> }
    )>, errors?: Maybe<(
      { __typename?: 'AnswerErrors' }
      & Pick<AnswerErrors, 'id' | 'text'>
      & { documents?: Maybe<(
        { __typename?: 'DocumentErrors' }
        & Pick<DocumentErrors, 'attachment' | 'version' | 'category' | 'source'>
      )> }
    )> }
  )> }
);

export type EditQuestionMutationVariables = Exact<{
  input: EditQuestionInput;
}>;


export type EditQuestionMutation = (
  { __typename?: 'Mutation' }
  & { editQuestion?: Maybe<(
    { __typename?: 'EditQuestionPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'section' | 'text' | 'deadline'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'category' | 'version'>
        & DocumentForFormFragment
      )>> }
    ), errors?: Maybe<(
      { __typename?: 'QuestionErrors' }
      & Pick<QuestionErrors, 'section' | 'text' | 'deadline'>
      & { documents?: Maybe<(
        { __typename?: 'DocumentErrors' }
        & Pick<DocumentErrors, 'attachment' | 'version' | 'category' | 'source'>
      )> }
    )> }
  )> }
);

export type ReleaseAnswerMutationVariables = Exact<{
  input: ReleaseAnswerInput;
}>;


export type ReleaseAnswerMutation = (
  { __typename?: 'Mutation' }
  & { releaseAnswer?: Maybe<(
    { __typename?: 'ReleaseAnswerPayload' }
    & { answer?: Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'releasedAt'>
    )>, errors?: Maybe<(
      { __typename?: 'AnswerErrors' }
      & Pick<AnswerErrors, 'id'>
    )> }
  )> }
);

export type ShareQuestionsMutationVariables = Exact<{
  input: ShareQuestionableForAnsweringInput;
}>;


export type ShareQuestionsMutation = (
  { __typename?: 'Mutation' }
  & { shareQuestionableForAnswering?: Maybe<(
    { __typename?: 'ShareQuestionableForAnsweringPayload' }
    & Pick<ShareQuestionableForAnsweringPayload, 'shareableLink'>
    & { token?: Maybe<(
      { __typename?: 'ShareToken' }
      & Pick<ShareToken, 'id' | 'expiresAt' | 'scope' | 'shareableType' | 'token'>
    )>, errors?: Maybe<(
      { __typename?: 'ShareQuestionableForAnsweringErrors' }
      & Pick<ShareQuestionableForAnsweringErrors, 'base' | 'email' | 'message'>
    )> }
  )> }
);

export type UnapproveAnswerMutationVariables = Exact<{
  input: UnapproveAnswerInput;
}>;


export type UnapproveAnswerMutation = (
  { __typename?: 'Mutation' }
  & { unapproveAnswer?: Maybe<(
    { __typename?: 'UnapproveAnswerPayload' }
    & { answer: (
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'acceptedAt'>
    ), errors?: Maybe<(
      { __typename?: 'AnswerErrors' }
      & Pick<AnswerErrors, 'id'>
    )> }
  )> }
);

export type UpdateQuotationSelectionMutationVariables = Exact<{
  input: QuotationsUpdateSelectionInput;
}>;


export type UpdateQuotationSelectionMutation = (
  { __typename?: 'Mutation' }
  & { updateQuotationSelection?: Maybe<(
    { __typename?: 'QuotationsUpdateSelectionPayload' }
    & Pick<QuotationsUpdateSelectionPayload, 'error'>
    & { quotation?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id' | 'selected'>
    ) | (
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id' | 'selected'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id' | 'selected'>
    ) | (
      { __typename?: 'PanelsQuotation' }
      & Pick<PanelsQuotation, 'id' | 'selected'>
    ) | (
      { __typename?: 'MLQuotation' }
      & Pick<MlQuotation, 'id' | 'selected'>
    ) | (
      { __typename?: 'TerrorismQuotation' }
      & Pick<TerrorismQuotation, 'id' | 'selected'>
    ) | (
      { __typename?: 'BOPQuotation' }
      & Pick<BopQuotation, 'id' | 'selected'>
    )> }
  )> }
);

export type ChangeQuoteOwnerNewUserMutationVariables = Exact<{
  input: ChangeQuoteWithNewOwnerInput;
}>;


export type ChangeQuoteOwnerNewUserMutation = (
  { __typename?: 'Mutation' }
  & { changeQuoteOwnerNewUser?: Maybe<(
    { __typename?: 'ChangeQuoteWithNewOwnerPayload' }
    & Pick<ChangeQuoteWithNewOwnerPayload, 'invitationToken' | 'errors'>
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & { reinsurer?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
        & { quotes: (
          { __typename?: 'QuoteConnection' }
          & { edges?: Maybe<Array<Maybe<(
            { __typename?: 'QuoteEdge' }
            & { node?: Maybe<(
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
            )> }
          )>>> }
        ) }
      )> }
    )> }
  )> }
);

export type CreateBinderMutationVariables = Exact<{
  input: CreateBinderInput;
}>;


export type CreateBinderMutation = (
  { __typename?: 'Mutation' }
  & { createBinder?: Maybe<(
    { __typename?: 'CreateBinderPayload' }
    & Pick<CreateBinderPayload, 'errors'>
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & QuoteInfoFragmentFragment
      & QuoteMessagesSectionFragment
    )> }
  )> }
);

export type CreateBinderWithQuoteMutationVariables = Exact<{
  input: CreateBinderWithQuoteInput;
}>;


export type CreateBinderWithQuoteMutation = (
  { __typename?: 'Mutation' }
  & { createBinderWithQuote?: Maybe<(
    { __typename?: 'CreateBinderWithQuotePayload' }
    & Pick<CreateBinderWithQuotePayload, 'errors'>
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'status' | 'stage'>
      & { readableStatus: Quote['status'] }
      & { binder?: Maybe<(
        { __typename?: 'DocumentConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'type' | 'url'>
        )>>> }
      )> }
      & LatestRevisionFragment
      & QuoteMessagesSectionFragment
    )> }
  )> }
);

export type ManuallyCreateQuoteMutationVariables = Exact<{
  input: ManualCreateQuoteInput;
}>;


export type ManuallyCreateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { manuallyCreateQuote?: Maybe<(
    { __typename?: 'ManualCreateQuotePayload' }
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & Pick<Worksheet, 'id'>
      & { quotes: (
        { __typename?: 'QuoteConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Quote' }
          & Pick<Quote, 'id'>
          & QuoteInfoFragmentFragment
        )>>> }
      ) }
    )> }
  )> }
);

export type PreBindEditMutationVariables = Exact<{
  input: PreBindEditInput;
}>;


export type PreBindEditMutation = (
  { __typename?: 'Mutation' }
  & { preBindEdit?: Maybe<(
    { __typename?: 'PreBindEditPayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & QuoteInfoFragmentFragment
    )> }
  )> }
);

export type ViewedQuoteMutationVariables = Exact<{
  input: ViewedQuoteInput;
}>;


export type ViewedQuoteMutation = (
  { __typename?: 'Mutation' }
  & { viewedQuote?: Maybe<(
    { __typename?: 'ViewedQuotePayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
    )> }
  )> }
);

export type ReadMessageMutationVariables = Exact<{
  input: ReadMessageInput;
}>;


export type ReadMessageMutation = (
  { __typename?: 'Mutation' }
  & { readMessage?: Maybe<(
    { __typename?: 'ReadMessagePayload' }
    & Pick<ReadMessagePayload, 'errors'>
    & { discussable: (
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & DiscussionParticipantsFragment
      )> }
    ) | (
      { __typename?: 'Worksheet' }
      & Pick<Worksheet, 'id'>
      & { discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & DiscussionParticipantsFragment
      )> }
    ) | (
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & { discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & DiscussionParticipantsFragment
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesSubmission' }
      & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
      & { discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & DiscussionParticipantsFragment
      )> }
    ) | (
      { __typename?: 'BrokerInvite' }
      & Pick<BrokerInvite, 'id'>
      & { discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & DiscussionParticipantsFragment
      )> }
    ) | (
      { __typename?: 'BrokerCollaboration' }
      & Pick<BrokerCollaboration, 'id'>
      & { discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & DiscussionParticipantsFragment
      )> }
    ) | (
      { __typename?: 'ClientCollaboration' }
      & Pick<ClientCollaboration, 'id'>
      & { discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & DiscussionParticipantsFragment
      )> }
    ) | (
      { __typename?: 'MgaCollaboration' }
      & Pick<MgaCollaboration, 'id'>
      & { discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & DiscussionParticipantsFragment
      )> }
    ) | (
      { __typename?: 'WholesalerCollaboration' }
      & Pick<WholesalerCollaboration, 'id'>
      & { discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & DiscussionParticipantsFragment
      )> }
    ) }
  )> }
);

export type RequestBinderMutationVariables = Exact<{
  input: RequestBinderInput;
}>;


export type RequestBinderMutation = (
  { __typename?: 'Mutation' }
  & { requestBinder?: Maybe<(
    { __typename?: 'RequestBinderPayload' }
    & Pick<RequestBinderPayload, 'errors'>
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'status' | 'stage'>
      & { readableStatus: Quote['status'] }
      & { binder?: Maybe<(
        { __typename?: 'DocumentConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'filename' | 'createdAt'>
        )>>> }
      )>, certificate?: Maybe<(
        { __typename?: 'DocumentConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id' | 'url' | 'filename' | 'createdAt'>
        )>>> }
      )> }
    )> }
  )> }
);

export type CreateRevisionMutationVariables = Exact<{
  input: CreateRevisionInput;
}>;


export type CreateRevisionMutation = (
  { __typename?: 'Mutation' }
  & { createRevision?: Maybe<(
    { __typename?: 'CreateRevisionPayload' }
    & Pick<CreateRevisionPayload, 'errors'>
    & { revision?: Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'status'>
      & { quote: (
        { __typename?: 'Quote' }
        & Pick<Quote, 'id' | 'status' | 'stage'>
        & { readableStatus: Quote['status'] }
        & { revisions: (
          { __typename?: 'RevisionConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Revision' }
            & Pick<Revision, 'id' | 'createdAt' | 'number' | 'isIncomplete'>
            & { zip?: Maybe<(
              { __typename?: 'Document' }
              & Pick<Document, 'id' | 'url'>
            )> }
          )>>> }
        ) }
        & LatestRevisionFragment
        & QuoteMessagesSectionFragment
      ) }
    )> }
  )> }
);

export type DeleteRevisionMutationVariables = Exact<{
  input: DeleteRevisionInput;
}>;


export type DeleteRevisionMutation = (
  { __typename?: 'Mutation' }
  & { deleteRevision?: Maybe<(
    { __typename?: 'DeleteRevisionPayload' }
    & Pick<DeleteRevisionPayload, 'errors'>
  )> }
);

export type SavePanelProposalConfigurationMutationVariables = Exact<{
  input: SavePanelProposalConfigurationInput;
}>;


export type SavePanelProposalConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { savePanelProposalConfiguration?: Maybe<(
    { __typename?: 'SavePanelProposalConfigurationPayload' }
    & Pick<SavePanelProposalConfigurationPayload, 'errors'>
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'name' | 'lineOfBusiness'>
      & { proposalConfiguration?: Maybe<(
        { __typename?: 'ProposalConfiguration' }
        & Pick<ProposalConfiguration, 'id' | 'configuration'>
      )> }
    )> }
  )> }
);

export type SetQuoteReleasedStatusMutationVariables = Exact<{
  input: SetQuoteReleasedStatusInput;
}>;


export type SetQuoteReleasedStatusMutation = (
  { __typename?: 'Mutation' }
  & { setQuoteReleasedStatus?: Maybe<(
    { __typename?: 'SetQuoteReleasedStatusPayload' }
    & Pick<SetQuoteReleasedStatusPayload, 'errors'>
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'releasedAt'>
    )> }
  )> }
);

export type SetCompanySettingsMutationVariables = Exact<{
  input: SetCompanySettingsInput;
}>;


export type SetCompanySettingsMutation = (
  { __typename?: 'Mutation' }
  & { setCompanySettings?: Maybe<(
    { __typename?: 'SetCompanySettingsPayload' }
    & Pick<SetCompanySettingsPayload, 'errors'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'emailSubmissionResponse'>
      & { emailSubmissionsManager?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      )> }
    )> }
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'UpdateProfilePayload' }
    & Pick<UpdateProfilePayload, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'firstName' | 'lastName' | 'email' | 'role' | 'locations' | 'linkedinUrl' | 'industries' | 'avatarUrl' | 'jobTitle' | 'phoneNumber' | 'linesOfBusiness' | 'aboutMe'>
      & { notificationSettings?: Maybe<(
        { __typename?: 'NotificationSettingsType' }
        & { mySubmissions?: Maybe<(
          { __typename?: 'SubmissionNotificationSettingsType' }
          & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
        )>, retailerSubmissions?: Maybe<(
          { __typename?: 'SubmissionNotificationSettingsType' }
          & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
        )> }
      )>, rinkAccount: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'fullName' | 'firstName' | 'email' | 'lastName' | 'jobTitle' | 'avatarUrl' | 'companyName'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'firstName' | 'lastName' | 'email' | 'role' | 'locations' | 'linkedinUrl' | 'industries' | 'avatarUrl' | 'jobTitle' | 'phoneNumber' | 'linesOfBusiness' | 'aboutMe'>
      & { notificationSettings?: Maybe<(
        { __typename?: 'NotificationSettingsType' }
        & { mySubmissions?: Maybe<(
          { __typename?: 'SubmissionNotificationSettingsType' }
          & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
        )>, retailerSubmissions?: Maybe<(
          { __typename?: 'SubmissionNotificationSettingsType' }
          & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
        )> }
      )>, rinkAccount: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'fullName' | 'firstName' | 'email' | 'lastName' | 'jobTitle' | 'avatarUrl' | 'companyName'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'firstName' | 'lastName' | 'email' | 'role' | 'locations' | 'linkedinUrl' | 'industries' | 'avatarUrl' | 'jobTitle' | 'phoneNumber' | 'linesOfBusiness' | 'aboutMe'>
      & { notificationSettings?: Maybe<(
        { __typename?: 'NotificationSettingsType' }
        & { mySubmissions?: Maybe<(
          { __typename?: 'SubmissionNotificationSettingsType' }
          & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
        )>, retailerSubmissions?: Maybe<(
          { __typename?: 'SubmissionNotificationSettingsType' }
          & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
        )> }
      )>, rinkAccount: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'fullName' | 'firstName' | 'email' | 'lastName' | 'jobTitle' | 'avatarUrl' | 'companyName'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'lastName' | 'email' | 'role' | 'locations' | 'linkedinUrl' | 'industries' | 'avatarUrl' | 'jobTitle' | 'phoneNumber' | 'linesOfBusiness' | 'aboutMe'>
      & { notificationSettings?: Maybe<(
        { __typename?: 'NotificationSettingsType' }
        & { mySubmissions?: Maybe<(
          { __typename?: 'SubmissionNotificationSettingsType' }
          & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
        )>, retailerSubmissions?: Maybe<(
          { __typename?: 'SubmissionNotificationSettingsType' }
          & Pick<SubmissionNotificationSettingsType, 'sentConfirmations' | 'received' | 'declined' | 'referred' | 'bound' | 'issued'>
        )> }
      )>, rinkAccount: (
        { __typename?: 'Account' }
        & Pick<Account, 'id' | 'fullName' | 'firstName' | 'email' | 'lastName' | 'jobTitle' | 'avatarUrl' | 'companyName'>
      ) }
    )> }
  )> }
);

export type AcceptConnectionMutationVariables = Exact<{
  input: AcceptConnectionInput;
}>;


export type AcceptConnectionMutation = (
  { __typename?: 'Mutation' }
  & { acceptConnection?: Maybe<(
    { __typename?: 'AcceptConnectionPayload' }
    & Pick<AcceptConnectionPayload, 'errors'>
    & { connection?: Maybe<(
      { __typename?: 'ConnectionRINK' }
      & Pick<ConnectionRink, 'id'>
      & { user1: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { networkConnectionInvitesSent: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id'>
          )>>> }
        ) }
        & NetworkContactFragment
      ), user2: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { networkContacts: (
          { __typename?: 'AccountConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Account' }
            & NetworkContactFragment
          )>>> }
        ), networkConnectionInvitesReceived: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id'>
            & { user1: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ) }
        & NetworkContactFragment
      ) }
    )> }
  )> }
);

export type IgnoreConnectionMutationVariables = Exact<{
  input: IgnoreConnectionInput;
}>;


export type IgnoreConnectionMutation = (
  { __typename?: 'Mutation' }
  & { ignoreConnection?: Maybe<(
    { __typename?: 'IgnoreConnectionPayload' }
    & Pick<IgnoreConnectionPayload, 'errors'>
    & { connection?: Maybe<(
      { __typename?: 'ConnectionRINK' }
      & Pick<ConnectionRink, 'id'>
      & { user1: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { networkConnectionInvitesSent: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id'>
          )>>> }
        ) }
        & NetworkContactFragment
      ), user2: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { networkContacts: (
          { __typename?: 'AccountConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Account' }
            & NetworkContactFragment
          )>>> }
        ), networkConnectionInvitesReceived: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id'>
            & { user1: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ) }
        & NetworkContactFragment
      ) }
    )> }
  )> }
);

export type InviteConnectionMutationVariables = Exact<{
  input: InviteConnectionInput;
}>;


export type InviteConnectionMutation = (
  { __typename?: 'Mutation' }
  & { inviteConnection?: Maybe<(
    { __typename?: 'InviteConnectionPayload' }
    & Pick<InviteConnectionPayload, 'errors'>
    & { connection?: Maybe<(
      { __typename?: 'ConnectionRINK' }
      & Pick<ConnectionRink, 'id'>
      & { user1: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { networkContacts: (
          { __typename?: 'AccountConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Account' }
            & NetworkContactFragment
          )>>> }
        ), networkConnectionInvitesReceived: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id'>
            & { user1: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ), networkConnectionInvitesSent: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id' | 'createdAt'>
            & { user2: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ) }
      ) }
    )> }
  )> }
);

export type SignUpAndAcceptConnectionMutationVariables = Exact<{
  input: SignUpAndAcceptConnectionInput;
}>;


export type SignUpAndAcceptConnectionMutation = (
  { __typename?: 'Mutation' }
  & { signUpAndAcceptConnection?: Maybe<(
    { __typename?: 'SignUpAndAcceptConnectionPayload' }
    & Pick<SignUpAndAcceptConnectionPayload, 'errors'>
    & { user?: Maybe<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'fullName'>
      & { networkContacts: (
        { __typename?: 'AccountConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Account' }
          & Pick<Account, 'id'>
        )>>> }
      ), networkConnectionInvitesReceived: (
        { __typename?: 'ConnectionRINKConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'ConnectionRINK' }
          & Pick<ConnectionRink, 'id'>
          & { user1: (
            { __typename?: 'Account' }
            & Pick<Account, 'id'>
          ) }
        )>>> }
      ) }
    )> }
  )> }
);

export type ToggleDeclineQuotedLayerMutationVariables = Exact<{
  input: ToggleDeclineQuotedLayerInput;
}>;


export type ToggleDeclineQuotedLayerMutation = (
  { __typename?: 'Mutation' }
  & { toggleDeclineQuotedLayer?: Maybe<(
    { __typename?: 'ToggleDeclineQuotedLayerPayload' }
    & Pick<ToggleDeclineQuotedLayerPayload, 'errors'>
    & { quotedLayer?: Maybe<(
      { __typename?: 'QuotedLayer' }
      & Pick<QuotedLayer, 'id'>
      & QuotedLayerInfoFragment
    )> }
  )> }
);

export type TriggerSubmissionExportMutationVariables = Exact<{
  input: TriggerSubmissionExportInput;
}>;


export type TriggerSubmissionExportMutation = (
  { __typename?: 'Mutation' }
  & { triggerSubmissionExport?: Maybe<(
    { __typename?: 'TriggerSubmissionExportPayload' }
    & Pick<TriggerSubmissionExportPayload, 'errors'>
  )> }
);

export type UpdateBrokerCommentMutationVariables = Exact<{
  input: UpdateBrokerCommentInput;
}>;


export type UpdateBrokerCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateBrokerComment?: Maybe<(
    { __typename?: 'UpdateBrokerCommentPayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'brokerComment'>
    )> }
  )> }
);

export type UpdateLicenseInformationMutationVariables = Exact<{
  input: UpdateLicenseInformationInput;
}>;


export type UpdateLicenseInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateLicenseInformation?: Maybe<(
    { __typename?: 'UpdateLicenseInformationPayload' }
    & Pick<UpdateLicenseInformationPayload, 'errors'>
    & { company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
      & { licenseInformation?: Maybe<(
        { __typename?: 'LicenseInformationType' }
        & { surplus_lines_licenses?: Maybe<Array<(
          { __typename?: 'SurplusLinesType' }
          & Pick<SurplusLinesType, 'state' | 'expiration_date' | 'license_holder' | 'license_number'>
          & { address?: Maybe<(
            { __typename?: 'LicenseAddressType' }
            & Pick<LicenseAddressType, 'street_line' | 'state' | 'city' | 'zip_code'>
          )> }
        )>> }
      )> }
    )> }
  )> }
);

export type CopyWorksheetMutationVariables = Exact<{
  input: CopyWorksheetInput;
}>;


export type CopyWorksheetMutation = (
  { __typename?: 'Mutation' }
  & { copyWorksheet?: Maybe<(
    { __typename?: 'CopyWorksheetPayload' }
    & Pick<CopyWorksheetPayload, 'errors'>
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & { owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
        & { worksheets: (
          { __typename?: 'WorksheetConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Worksheet' }
            & Pick<Worksheet, 'id'>
          )>>> }
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
        & { worksheets: (
          { __typename?: 'WorksheetConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Worksheet' }
            & Pick<Worksheet, 'id'>
          )>>> }
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ) }
      & SubmissionInfoFragment
    )> }
  )> }
);

export type DeleteWorksheetMutationVariables = Exact<{
  input: DeleteWorksheetInput;
}>;


export type DeleteWorksheetMutation = (
  { __typename?: 'Mutation' }
  & { deleteWorksheet?: Maybe<(
    { __typename?: 'DeleteWorksheetPayload' }
    & Pick<DeleteWorksheetPayload, 'errors'>
  )> }
);

export type EditSentWorksheetMutationVariables = Exact<{
  input: EditSentWorksheetInput;
}>;


export type EditSentWorksheetMutation = (
  { __typename?: 'Mutation' }
  & { editSentWorksheet?: Maybe<(
    { __typename?: 'EditSentWorksheetPayload' }
    & Pick<EditSentWorksheetPayload, 'errors'>
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & SubmissionInfoFragment
    )> }
  )> }
);

export type ExportWorksheetsMutationVariables = Exact<{
  input: ExportWorksheetsInput;
}>;


export type ExportWorksheetsMutation = (
  { __typename?: 'Mutation' }
  & { exportWorksheet?: Maybe<(
    { __typename?: 'ExportWorksheetsPayload' }
    & Pick<ExportWorksheetsPayload, 'clientMutationId'>
  )> }
);

export type RenewWorksheetMutationVariables = Exact<{
  input: RenewWorksheetInput;
}>;


export type RenewWorksheetMutation = (
  { __typename?: 'Mutation' }
  & { renewWorksheet?: Maybe<(
    { __typename?: 'RenewWorksheetPayload' }
    & Pick<RenewWorksheetPayload, 'errors'>
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & { owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
        & { worksheets: (
          { __typename?: 'WorksheetConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Worksheet' }
            & Pick<Worksheet, 'id'>
          )>>> }
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
        & { worksheets: (
          { __typename?: 'WorksheetConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Worksheet' }
            & Pick<Worksheet, 'id'>
          )>>> }
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ), previousPlacement?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & { renewal?: Maybe<(
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        )> }
      )>, export?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'filename' | 'createdAt'>
      )> }
      & SubmissionInfoFragment
    )> }
  )> }
);

export type RequestApprovalMutationVariables = Exact<{
  input: RequestApprovalInput;
}>;


export type RequestApprovalMutation = (
  { __typename?: 'Mutation' }
  & { requestApproval?: Maybe<(
    { __typename?: 'RequestApprovalPayload' }
    & Pick<RequestApprovalPayload, 'errors'>
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & Pick<Worksheet, 'id' | 'status'>
    )> }
  )> }
);

export type UpdateAndSendWorksheetMutationVariables = Exact<{
  input: UpdateAndSendWorksheetInput;
}>;


export type UpdateAndSendWorksheetMutation = (
  { __typename?: 'Mutation' }
  & { updateAndSendWorksheet?: Maybe<(
    { __typename?: 'UpdateAndSendWorksheetPayload' }
    & Pick<UpdateAndSendWorksheetPayload, 'errors'>
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & { export?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'filename' | 'createdAt'>
      )> }
      & SubmissionInfoFragment
    )> }
  )> }
);

export type UpdateWorksheetMutationVariables = Exact<{
  input: UpdateWorksheetInput;
}>;


export type UpdateWorksheetMutation = (
  { __typename?: 'Mutation' }
  & { updateWorksheet?: Maybe<(
    { __typename?: 'UpdateWorksheetPayload' }
    & Pick<UpdateWorksheetPayload, 'errors'>
    & { worksheet?: Maybe<(
      { __typename?: 'Worksheet' }
      & { export?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url' | 'filename' | 'createdAt'>
      )> }
      & SubmissionInfoFragment
    )> }
  )> }
);

export type AnalyticsQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { analytics: (
        { __typename?: 'Analytics' }
        & Pick<Analytics, 'averageReplyRate' | 'averageResponseTime' | 'averagePlacementTime' | 'totalCommission'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type AuthorizedMarketQueryVariables = Exact<{
  marketId: Scalars['ID'];
}>;


export type AuthorizedMarketQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { authorizedMarket?: Maybe<(
          { __typename?: 'AuthorizedMarket' }
          & Pick<AuthorizedMarket, 'id' | 'specialInstructions' | 'propertyApprovedLimit' | 'casualtyApprovedLimit' | 'disabled'>
          & { reinsuranceCompany: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
          ), contacts: (
            { __typename?: 'AuthorizedMarketContactConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'AuthorizedMarketContact' }
              & Pick<AuthorizedMarketContact, 'id' | 'description'>
              & { reinsurer: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'generalInbox'>
              ) }
            )>>> }
          ) }
        )> }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { authorizedMarket?: Maybe<(
          { __typename?: 'AuthorizedMarket' }
          & Pick<AuthorizedMarket, 'id' | 'specialInstructions' | 'propertyApprovedLimit' | 'casualtyApprovedLimit' | 'disabled'>
          & { reinsuranceCompany: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
          ), contacts: (
            { __typename?: 'AuthorizedMarketContactConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'AuthorizedMarketContact' }
              & Pick<AuthorizedMarketContact, 'id' | 'description'>
              & { reinsurer: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'generalInbox'>
              ) }
            )>>> }
          ) }
        )> }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { authorizedMarket?: Maybe<(
          { __typename?: 'AuthorizedMarket' }
          & Pick<AuthorizedMarket, 'id' | 'specialInstructions' | 'propertyApprovedLimit' | 'casualtyApprovedLimit' | 'disabled'>
          & { reinsuranceCompany: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
          ), contacts: (
            { __typename?: 'AuthorizedMarketContactConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'AuthorizedMarketContact' }
              & Pick<AuthorizedMarketContact, 'id' | 'description'>
              & { reinsurer: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'generalInbox'>
              ) }
            )>>> }
          ) }
        )> }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { authorizedMarket?: Maybe<(
          { __typename?: 'AuthorizedMarket' }
          & Pick<AuthorizedMarket, 'id' | 'specialInstructions' | 'propertyApprovedLimit' | 'casualtyApprovedLimit' | 'disabled'>
          & { reinsuranceCompany: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
          ), contacts: (
            { __typename?: 'AuthorizedMarketContactConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'AuthorizedMarketContact' }
              & Pick<AuthorizedMarketContact, 'id' | 'description'>
              & { reinsurer: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName' | 'lastName' | 'fullName' | 'email' | 'generalInbox'>
              ) }
            )>>> }
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type AuthorizedMarketsQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthorizedMarketsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { authorizedMarkets: (
          { __typename?: 'AuthorizedMarketConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'AuthorizedMarket' }
            & Pick<AuthorizedMarket, 'id' | 'disabled' | 'propertyApprovedLimit' | 'casualtyApprovedLimit'>
            & { contacts: (
              { __typename?: 'AuthorizedMarketContactConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'AuthorizedMarketContact' }
                & Pick<AuthorizedMarketContact, 'id' | 'description'>
                & { reinsurer: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id' | 'email' | 'fullName' | 'companyName' | 'avatarUrl' | 'pendingInvitation' | 'newMarket' | 'generalInbox'>
                ) }
              )>>> }
            ) }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { authorizedMarkets: (
          { __typename?: 'AuthorizedMarketConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'AuthorizedMarket' }
            & Pick<AuthorizedMarket, 'id' | 'disabled' | 'propertyApprovedLimit' | 'casualtyApprovedLimit'>
            & { contacts: (
              { __typename?: 'AuthorizedMarketContactConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'AuthorizedMarketContact' }
                & Pick<AuthorizedMarketContact, 'id' | 'description'>
                & { reinsurer: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id' | 'email' | 'fullName' | 'companyName' | 'avatarUrl' | 'pendingInvitation' | 'newMarket' | 'generalInbox'>
                ) }
              )>>> }
            ) }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { authorizedMarkets: (
          { __typename?: 'AuthorizedMarketConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'AuthorizedMarket' }
            & Pick<AuthorizedMarket, 'id' | 'disabled' | 'propertyApprovedLimit' | 'casualtyApprovedLimit'>
            & { contacts: (
              { __typename?: 'AuthorizedMarketContactConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'AuthorizedMarketContact' }
                & Pick<AuthorizedMarketContact, 'id' | 'description'>
                & { reinsurer: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id' | 'email' | 'fullName' | 'companyName' | 'avatarUrl' | 'pendingInvitation' | 'newMarket' | 'generalInbox'>
                ) }
              )>>> }
            ) }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { authorizedMarkets: (
          { __typename?: 'AuthorizedMarketConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'AuthorizedMarket' }
            & Pick<AuthorizedMarket, 'id' | 'disabled' | 'propertyApprovedLimit' | 'casualtyApprovedLimit'>
            & { contacts: (
              { __typename?: 'AuthorizedMarketContactConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'AuthorizedMarketContact' }
                & Pick<AuthorizedMarketContact, 'id' | 'description'>
                & { reinsurer: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id' | 'email' | 'fullName' | 'companyName' | 'avatarUrl' | 'pendingInvitation' | 'newMarket' | 'generalInbox'>
                ) }
              )>>> }
            ) }
          )>>> }
        ) }
      ) }
    )> }
  ) }
);

export type AvailableTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableTemplatesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { templates: (
          { __typename?: 'TemplateConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Template' }
            & Pick<Template, 'id' | 'name' | 'identifier' | 'processor'>
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { templates: (
          { __typename?: 'TemplateConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Template' }
            & Pick<Template, 'id' | 'name' | 'identifier' | 'processor'>
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { templates: (
          { __typename?: 'TemplateConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Template' }
            & Pick<Template, 'id' | 'name' | 'identifier' | 'processor'>
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { templates: (
          { __typename?: 'TemplateConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Template' }
            & Pick<Template, 'id' | 'name' | 'identifier' | 'processor'>
          )>>> }
        ) }
      ) }
    )> }
  ) }
);

export type BroadcastsQueryVariables = Exact<{
  worksheetId: Scalars['ID'];
}>;


export type BroadcastsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id' | 'demo'>
        & { broadcasts?: Maybe<(
          { __typename?: 'BroadcastConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Broadcast' }
            & Pick<Broadcast, 'id'>
            & Message_Broadcast_Fragment
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id' | 'demo'>
        & { broadcasts?: Maybe<(
          { __typename?: 'BroadcastConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Broadcast' }
            & Pick<Broadcast, 'id'>
            & Message_Broadcast_Fragment
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type DashboardInfoFragment = (
  { __typename?: 'BrokerDashboardEntry' }
  & Pick<BrokerDashboardEntry, 'id' | 'type' | 'ownerName' | 'ownerId' | 'ownerCompanyId' | 'ownerCompanyName' | 'name' | 'clientCompanyId' | 'clientCompanyName' | 'lineOfBusiness' | 'effectiveDate' | 'keyPerils' | 'policyLimit' | 'demo' | 'status' | 'isInvited' | 'isBrokered' | 'industryType' | 'receivedAt' | 'isRenewal'>
  & { submission?: Maybe<(
    { __typename?: 'CyberSubmission' }
    & Pick<CyberSubmission, 'id'>
    & { owner: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ), panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id'>
    )>, collaborations: (
      { __typename?: 'CollaborationConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BrokerInvite' }
        & Pick<BrokerInvite, 'id' | 'type'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename?: 'BrokerCollaboration' }
        & Pick<BrokerCollaboration, 'id' | 'type'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename?: 'ClientCollaboration' }
        & Pick<ClientCollaboration, 'id' | 'type'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename?: 'MgaCollaboration' }
        & Pick<MgaCollaboration, 'id' | 'type'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename?: 'WholesalerCollaboration' }
        & Pick<WholesalerCollaboration, 'id' | 'type'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      )>>> }
    ) }
  ) | (
    { __typename?: 'PanelSubmission' }
    & Pick<PanelSubmission, 'id'>
    & { products: Array<(
      { __typename?: 'BOPProduct' }
      & Pick<BopProduct, 'id'>
    ) | (
      { __typename?: 'CyberProduct' }
      & Pick<CyberProduct, 'limit' | 'coverage_start_date' | 'id'>
    ) | (
      { __typename?: 'ManagementLiabilityProduct' }
      & Pick<ManagementLiabilityProduct, 'id'>
    ) | (
      { __typename?: 'TerrorismProduct' }
      & Pick<TerrorismProduct, 'limit' | 'coverage_start_date' | 'totalInsuredValue' | 'id'>
    )>, owner: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ), panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id'>
    )>, collaborations: (
      { __typename?: 'CollaborationConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BrokerInvite' }
        & Pick<BrokerInvite, 'id' | 'type'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename?: 'BrokerCollaboration' }
        & Pick<BrokerCollaboration, 'id' | 'type'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename?: 'ClientCollaboration' }
        & Pick<ClientCollaboration, 'id' | 'type'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename?: 'MgaCollaboration' }
        & Pick<MgaCollaboration, 'id' | 'type'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      ) | (
        { __typename?: 'WholesalerCollaboration' }
        & Pick<WholesalerCollaboration, 'id' | 'type'>
        & { user: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id'>
        ) }
      )>>> }
    ) }
  ) | (
    { __typename?: 'PCGSubmission' }
    & Pick<PcgSubmission, 'id'>
    & { owner: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ), panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id'>
    )> }
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesSubmission' }
    & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
    & { owner: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type BrokerDashboardDealsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type BrokerDashboardDealsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { dashboard: (
        { __typename?: 'BrokerDashboardEntryConnection' }
        & Pick<BrokerDashboardEntryConnection, 'totalCount'>
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'BrokerDashboardEntry' }
          & Pick<BrokerDashboardEntry, 'id'>
          & DashboardInfoFragment
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
        ) }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type CarrierDashboardEntriesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  quoteStatus?: Maybe<Scalars['String']>;
}>;


export type CarrierDashboardEntriesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { dashboardEntries: (
        { __typename?: 'CarrierDashboardEntryConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CarrierDashboardEntry' }
          & Pick<CarrierDashboardEntry, 'id' | 'typename' | 'lineOfBusiness' | 'clientName' | 'riskName' | 'companyName' | 'outdatedAt' | 'isBroker' | 'keyPerils' | 'effectiveDate' | 'ownerFirstName' | 'ownerId' | 'ownerLastName' | 'policyNumber' | 'quoteStatus' | 'requestedAt'>
          & { quotation?: Maybe<(
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
          ) | (
            { __typename?: 'PCGQuotation' }
            & Pick<PcgQuotation, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
          )>, revision?: Maybe<(
            { __typename?: 'Revision' }
            & Pick<Revision, 'id'>
            & { quotedLayers: Array<(
              { __typename?: 'QuotedLayer' }
              & Pick<QuotedLayer, 'id'>
              & QuotedLayerInfoFragment
            )> }
          )> }
        )>>> }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type CedentDashboardEntriesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  quoteStatus?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type CedentDashboardEntriesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'onboarding' | 'id'>
      & { dashboardEntries: (
        { __typename?: 'CedentDashboardEntryConnection' }
        & Pick<CedentDashboardEntryConnection, 'totalCount'>
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CedentDashboardEntry' }
          & Pick<CedentDashboardEntry, 'id' | 'brokered' | 'demo' | 'declined' | 'lineOfBusiness' | 'createdAt' | 'effectiveDate' | 'keyPerils' | 'policyType' | 'worksheetLineOfBusiness' | 'marketsInvited' | 'quoted' | 'bound' | 'issued' | 'kind' | 'name' | 'ownerId' | 'ownerName' | 'ownerCompanyId' | 'status' | 'totalBound' | 'responded' | 'totalRequested' | 'attachmentPoint' | 'perOccurrenceLimit' | 'policyLimit' | 'boundRatio'>
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
        ) }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type CedentQuoteMessagesQueryQueryVariables = Exact<{
  worksheetId: Scalars['ID'];
  quoteId: Scalars['ID'];
}>;


export type CedentQuoteMessagesQueryQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & { broadcasts?: Maybe<(
          { __typename?: 'BroadcastConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Broadcast' }
            & Pick<Broadcast, 'id'>
            & Message_Broadcast_Fragment
          )>>> }
        )>, quote?: Maybe<(
          { __typename?: 'Quote' }
          & Pick<Quote, 'id'>
          & QuoteMessagesSectionFragment
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & { broadcasts?: Maybe<(
          { __typename?: 'BroadcastConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Broadcast' }
            & Pick<Broadcast, 'id'>
            & Message_Broadcast_Fragment
          )>>> }
        )>, quote?: Maybe<(
          { __typename?: 'Quote' }
          & Pick<Quote, 'id'>
          & QuoteMessagesSectionFragment
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type CompanyInvitationQueryVariables = Exact<{
  signupToken: Scalars['String'];
  hostId?: Maybe<Scalars['ID']>;
}>;


export type CompanyInvitationQuery = (
  { __typename?: 'Query' }
  & { companyInvitation?: Maybe<(
    { __typename?: 'CompanyInvitation' }
    & Pick<CompanyInvitation, 'id' | 'logoUrl' | 'brandColor' | 'emailDomains' | 'companyName' | 'host'>
  )> }
);

export type GetCyberQuoteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCyberQuoteQuery = (
  { __typename?: 'Query' }
  & { cyber: (
    { __typename?: 'Cyber' }
    & { quote?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id' | 'limit' | 'status' | 'deductible' | 'premium' | 'indication' | 'fee' | 'surplusLinesTax' | 'totalCost' | 'admitted' | 'declinationReason' | 'signingUrl' | 'policyNumber' | 'comments'>
      & { externalCarrier?: Maybe<(
        { __typename?: 'ExternalCarrier' }
        & Pick<ExternalCarrier, 'id' | 'name'>
      )>, coverages?: Maybe<Array<Maybe<(
        { __typename?: 'CyberCoverage' }
        & Pick<CyberCoverage, 'name' | 'type' | 'limit' | 'deductible' | 'periodOfIndemnity' | 'waitingPeriod'>
      )>>>, endorsements?: Maybe<Array<Maybe<(
        { __typename?: 'CyberEndorsement' }
        & Pick<CyberEndorsement, 'name' | 'limit' | 'coinsurance'>
      )>>> }
    )> }
  ) }
);

export type GetCyberQuoteEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCyberQuoteEditQuery = (
  { __typename?: 'Query' }
  & { cyber: (
    { __typename?: 'Cyber' }
    & { quote?: Maybe<(
      { __typename?: 'CyberQuotation' }
      & Pick<CyberQuotation, 'id' | 'limit' | 'status' | 'deductible' | 'premium' | 'fee' | 'admitted' | 'comments'>
      & { externalCarrier?: Maybe<(
        { __typename?: 'ExternalCarrier' }
        & Pick<ExternalCarrier, 'id' | 'name'>
      )>, coverages?: Maybe<Array<Maybe<(
        { __typename?: 'CyberCoverage' }
        & Pick<CyberCoverage, 'name' | 'type' | 'limit' | 'deductible'>
      )>>>, endorsements?: Maybe<Array<Maybe<(
        { __typename?: 'CyberEndorsement' }
        & Pick<CyberEndorsement, 'name' | 'limit' | 'coinsurance'>
      )>>>, documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentForFormFragment
      )>, submission: (
        { __typename?: 'CyberSubmission' }
        & Pick<CyberSubmission, 'id'>
        & CyberSubmissionInfo_CyberSubmission_Fragment
      ) }
    )> }
  ) }
);

export type PanelSchemaQueryVariables = Exact<{
  panelId: Scalars['ID'];
  panelistIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type PanelSchemaQuery = (
  { __typename?: 'Query' }
  & { cyber: (
    { __typename?: 'Cyber' }
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'country'>
      & { schemas: (
        { __typename?: 'PanelsSchema' }
        & Pick<PanelsSchema, 'new'>
      ) }
    )> }
  ) }
);

export type CyberQuotationQueryVariables = Exact<{
  participationId: Scalars['ID'];
}>;


export type CyberQuotationQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { participation?: Maybe<(
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { documents?: Maybe<Array<(
          { __typename?: 'Document' }
          & Pick<Document, 'id'>
          & DocumentFragment
        )>>, owner: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        ), user?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        )>, discussion?: Maybe<(
          { __typename?: 'Discussion' }
          & Pick<Discussion, 'id'>
          & MessagesFragment
        )>, submission: (
          { __typename?: 'CyberSubmission' }
          & Pick<CyberSubmission, 'id'>
          & CyberSubmissionInfo_CyberSubmission_Fragment
        ) | (
          { __typename?: 'PanelSubmission' }
          & Pick<PanelSubmission, 'id'>
          & CyberSubmissionInfo_PanelSubmission_Fragment
        ) | (
          { __typename?: 'PCGSubmission' }
          & Pick<PcgSubmission, 'id'>
          & CyberSubmissionInfo_PcgSubmission_Fragment
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesSubmission' }
          & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
          & CyberSubmissionInfo_NfpRepsAndWarrantiesSubmission_Fragment
        ), quotations: (
          { __typename?: 'QuotationConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
            & CyberQuotationInfo_CyberQuotation_Fragment
          ) | (
            { __typename?: 'PCGQuotation' }
            & Pick<PcgQuotation, 'id'>
            & CyberQuotationInfo_PcgQuotation_Fragment
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
            & CyberQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
            & CyberQuotationInfo_PanelsQuotation_Fragment
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
            & CyberQuotationInfo_MlQuotation_Fragment
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
            & CyberQuotationInfo_TerrorismQuotation_Fragment
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
            & CyberQuotationInfo_BopQuotation_Fragment
          )>>> }
        ) }
      )> }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type RetailBrokersQueryVariables = Exact<{ [key: string]: never; }>;


export type RetailBrokersQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'signupToken'>
        & { retailBrokerages?: Maybe<(
          { __typename?: 'RetailBrokerageConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'RetailBrokerage' }
            & Pick<RetailBrokerage, 'id' | 'name' | 'disabled' | 'agencyCode' | 'signupToken'>
            & { users: (
              { __typename?: 'RetailBrokerageUserConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'RetailBrokerageUser' }
                & Pick<RetailBrokerageUser, 'id' | 'jobTitle' | 'fullName'>
              )>>> }
            ) }
          )>>> }
        )> }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'signupToken'>
        & { retailBrokerages?: Maybe<(
          { __typename?: 'RetailBrokerageConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'RetailBrokerage' }
            & Pick<RetailBrokerage, 'id' | 'name' | 'disabled' | 'agencyCode' | 'signupToken'>
            & { users: (
              { __typename?: 'RetailBrokerageUserConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'RetailBrokerageUser' }
                & Pick<RetailBrokerageUser, 'id' | 'jobTitle' | 'fullName'>
              )>>> }
            ) }
          )>>> }
        )> }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'signupToken'>
        & { retailBrokerages?: Maybe<(
          { __typename?: 'RetailBrokerageConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'RetailBrokerage' }
            & Pick<RetailBrokerage, 'id' | 'name' | 'disabled' | 'agencyCode' | 'signupToken'>
            & { users: (
              { __typename?: 'RetailBrokerageUserConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'RetailBrokerageUser' }
                & Pick<RetailBrokerageUser, 'id' | 'jobTitle' | 'fullName'>
              )>>> }
            ) }
          )>>> }
        )> }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'signupToken'>
        & { retailBrokerages?: Maybe<(
          { __typename?: 'RetailBrokerageConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'RetailBrokerage' }
            & Pick<RetailBrokerage, 'id' | 'name' | 'disabled' | 'agencyCode' | 'signupToken'>
            & { users: (
              { __typename?: 'RetailBrokerageUserConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'RetailBrokerageUser' }
                & Pick<RetailBrokerageUser, 'id' | 'jobTitle' | 'fullName'>
              )>>> }
            ) }
          )>>> }
        )> }
      ) }
    )> }
  ) }
);

export type CyberSubmissionFileCabinetQueryVariables = Exact<{
  submissionId: Scalars['ID'];
}>;


export type CyberSubmissionFileCabinetQuery = (
  { __typename?: 'Query' }
  & { cyber: (
    { __typename?: 'Cyber' }
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'id'>
      & CyberSubmissionFileCabinetFragment
    )> }
  ) }
);

export type CyberSubmissionQueryVariables = Exact<{
  submissionId: Scalars['ID'];
}>;


export type CyberSubmissionQuery = (
  { __typename?: 'Query' }
  & { cyber: (
    { __typename?: 'Cyber' }
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & { panel?: Maybe<(
        { __typename?: 'Panel' }
        & Pick<Panel, 'id'>
      )> }
      & CyberSubmissionInfo_CyberSubmission_Fragment
    )> }
  ) }
);

export type CyberSubmissionForEditQueryVariables = Exact<{
  submissionId: Scalars['ID'];
  panelId: Scalars['ID'];
  panelistIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CyberSubmissionForEditQuery = (
  { __typename?: 'Query' }
  & { cyber: (
    { __typename?: 'Cyber' }
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'id' | 'country' | 'message' | 'selectedPanelists' | 'additionalCarriers' | 'questions'>
      & { participations: (
        { __typename?: 'ParticipationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Participation' }
          & Pick<Participation, 'id'>
          & { apiUser?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName' | 'firstName'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName' | 'firstName'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName' | 'firstName'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'firstName'>
          )>, user?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          )> }
        )>>> }
      ) }
      & CyberSubmissionInfo_CyberSubmission_Fragment
    )>, panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id'>
      & { schemas: (
        { __typename?: 'PanelsSchema' }
        & Pick<PanelsSchema, 'edit' | 'firmup' | 'draft'>
      ) }
    )> }
  ) }
);

export type CyberSubmissionForSubmitAddtlQueryVariables = Exact<{
  submissionId: Scalars['ID'];
  panelId: Scalars['ID'];
  panelistIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CyberSubmissionForSubmitAddtlQuery = (
  { __typename?: 'Query' }
  & { cyber: (
    { __typename?: 'Cyber' }
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'id' | 'country' | 'message' | 'selectedPanelists' | 'additionalCarriers' | 'questions'>
      & { participations: (
        { __typename?: 'ParticipationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Participation' }
          & Pick<Participation, 'id'>
          & { apiUser?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName' | 'firstName'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName' | 'firstName'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName' | 'firstName'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'firstName'>
          )>, user?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          )> }
        )>>> }
      ) }
      & CyberSubmissionInfo_CyberSubmission_Fragment
    )>, panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id'>
      & { schemas: (
        { __typename?: 'PanelsSchema' }
        & Pick<PanelsSchema, 'submitAdditional'>
      ), panelists: (
        { __typename?: 'ReinsurerConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'type'>
          & { panelistConfiguration?: Maybe<(
            { __typename?: 'PanelistConfiguration' }
            & Pick<PanelistConfiguration, 'preferred'>
          )> }
        )>>> }
      ) }
    )> }
  ) }
);

export type CyberSubmissionQuotesQueryVariables = Exact<{
  submissionId: Scalars['ID'];
}>;


export type CyberSubmissionQuotesQuery = (
  { __typename?: 'Query' }
  & { cyber: (
    { __typename?: 'Cyber' }
    & { submission?: Maybe<(
      { __typename?: 'CyberSubmission' }
      & Pick<CyberSubmission, 'id' | 'name' | 'status' | 'limit' | 'deductible' | 'coverageStartDate' | 'lineOfBusiness' | 'revisedAt' | 'email' | 'country' | 'state' | 'customLabelForFees' | 'contactName'>
      & { panel?: Maybe<(
        { __typename?: 'Panel' }
        & Pick<Panel, 'id' | 'useRetailSurplusLines' | 'displayTax' | 'configuration'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      )>, surplusLinesLicenseInfo?: Maybe<(
        { __typename?: 'SurplusLinesType' }
        & Pick<SurplusLinesType, 'expiration_date' | 'license_holder' | 'license_number' | 'state'>
        & { address?: Maybe<(
          { __typename?: 'LicenseAddressType' }
          & Pick<LicenseAddressType, 'street_line' | 'state' | 'city' | 'zip_code'>
        )> }
      )>, owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName' | 'jobTitle'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
        & Avatar_Reinsurer_Fragment
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName' | 'jobTitle'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
        & Avatar_Cedent_Fragment
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName' | 'jobTitle'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
        & Avatar_Broker_Fragment
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'jobTitle'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
        & Avatar_NfpRepsAndWarrantiesBroker_Fragment
      ), documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'category' | 'version'>
        & DocumentForFormFragment
      )>, collaborations: (
        { __typename?: 'CollaborationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'BrokerInvite' }
          & Pick<BrokerInvite, 'id' | 'type'>
          & { user: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
          ), discussion?: Maybe<(
            { __typename?: 'Discussion' }
            & Pick<Discussion, 'id'>
            & MessagesFragment
          )> }
        ) | (
          { __typename?: 'BrokerCollaboration' }
          & Pick<BrokerCollaboration, 'id' | 'type'>
          & { user: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
          ), discussion?: Maybe<(
            { __typename?: 'Discussion' }
            & Pick<Discussion, 'id'>
            & MessagesFragment
          )> }
        ) | (
          { __typename?: 'ClientCollaboration' }
          & Pick<ClientCollaboration, 'id' | 'type'>
          & { user: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
          ), discussion?: Maybe<(
            { __typename?: 'Discussion' }
            & Pick<Discussion, 'id'>
            & MessagesFragment
          )> }
        ) | (
          { __typename?: 'MgaCollaboration' }
          & Pick<MgaCollaboration, 'id' | 'type'>
          & { user: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
          ), discussion?: Maybe<(
            { __typename?: 'Discussion' }
            & Pick<Discussion, 'id'>
            & MessagesFragment
          )> }
        ) | (
          { __typename?: 'WholesalerCollaboration' }
          & Pick<WholesalerCollaboration, 'id' | 'type'>
          & { user: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
          ), discussion?: Maybe<(
            { __typename?: 'Discussion' }
            & Pick<Discussion, 'id'>
            & MessagesFragment
          )> }
        )>>> }
      ), participations: (
        { __typename?: 'ParticipationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Participation' }
          & Pick<Participation, 'id' | 'createdAt' | 'archivedAt'>
          & { documents?: Maybe<Array<(
            { __typename?: 'Document' }
            & Pick<Document, 'id'>
            & DocumentFragment
          )>>, apiUser?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName' | 'firstName' | 'avatarUrl'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName' | 'firstName' | 'avatarUrl'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName' | 'firstName' | 'avatarUrl'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'firstName' | 'avatarUrl'>
          )>, user?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'logoUrl'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'logoUrl'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'logoUrl'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'logoUrl'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          )>, collaborator?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'logoUrl'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'logoUrl'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'logoUrl'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName' | 'api'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'logoUrl'>
              & { ambestRating?: Maybe<(
                { __typename?: 'AMBestRating' }
                & Pick<AmBestRating, 'id' | 'rating'>
              )> }
            ) }
          )>, discussion?: Maybe<(
            { __typename?: 'Discussion' }
            & Pick<Discussion, 'id'>
            & MessagesFragment
          )>, quotations: (
            { __typename?: 'QuotationConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'CyberQuotation' }
              & Pick<CyberQuotation, 'id'>
              & CyberQuotationInfo_CyberQuotation_Fragment
            ) | (
              { __typename?: 'PCGQuotation' }
              & Pick<PcgQuotation, 'id'>
              & CyberQuotationInfo_PcgQuotation_Fragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesQuotation' }
              & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
              & CyberQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
            ) | (
              { __typename?: 'PanelsQuotation' }
              & Pick<PanelsQuotation, 'id'>
              & CyberQuotationInfo_PanelsQuotation_Fragment
            ) | (
              { __typename?: 'MLQuotation' }
              & Pick<MlQuotation, 'id'>
              & CyberQuotationInfo_MlQuotation_Fragment
            ) | (
              { __typename?: 'TerrorismQuotation' }
              & Pick<TerrorismQuotation, 'id'>
              & CyberQuotationInfo_TerrorismQuotation_Fragment
            ) | (
              { __typename?: 'BOPQuotation' }
              & Pick<BopQuotation, 'id'>
              & CyberQuotationInfo_BopQuotation_Fragment
            )>>> }
          ), subjectivities: (
            { __typename?: 'SubjectivityConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Subjectivity' }
              & Pick<Subjectivity, 'id' | 'text'>
            )>>> }
          ) }
        )>>> }
      ) }
    )> }
  ) }
);

export type DashboardQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
}>;


export type DashboardQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { search: Array<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'createdAt' | 'currency' | 'reinsuranceEffectiveDate' | 'totalInsuredValue' | 'id'>
        & { insurancePolicy?: Maybe<(
          { __typename?: 'InsurancePolicy' }
          & Pick<InsurancePolicy, 'id' | 'number'>
        )>, quotePath: (
          { __typename?: 'QuoteConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Quote' }
            & Pick<Quote, 'status'>
            & { pathId: Quote['id'] }
            & { revisions: (
              { __typename?: 'RevisionConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'Revision' }
                & Pick<Revision, 'id' | 'attachmentPoint' | 'layerLimit' | 'participation' | 'premium' | 'netPremium'>
                & { quote: (
                  { __typename?: 'Quote' }
                  & Pick<Quote, 'id'>
                  & { worksheet: (
                    { __typename?: 'Worksheet' }
                    & Pick<Worksheet, 'id' | 'kind'>
                  ) }
                ), quotedLayers: Array<(
                  { __typename?: 'QuotedLayer' }
                  & Pick<QuotedLayer, 'id'>
                  & QuotedLayerInfoFragment
                )> }
              )>>> }
            ), reinsurer?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id' | 'firstName' | 'lastName'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id'>
              ) }
            )> }
          )>>> }
        ) }
        & WorksheetCellFragment
        & CedingCompanyCellFragment
        & WorksheetStatusCellFragment
      )> }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'onboarding' | 'id'>
      & { dashboardEntries: (
        { __typename?: 'CedentDashboardEntryConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CedentDashboardEntry' }
          & Pick<CedentDashboardEntry, 'id' | 'brokered' | 'demo' | 'declined' | 'lineOfBusiness' | 'createdAt' | 'effectiveDate' | 'keyPerils' | 'marketsInvited' | 'quoted' | 'bound' | 'issued' | 'kind' | 'name' | 'ownerId' | 'ownerName' | 'ownerCompanyId' | 'status' | 'totalBound' | 'policyType' | 'worksheetLineOfBusiness' | 'responded' | 'totalRequested' | 'attachmentPoint' | 'perOccurrenceLimit' | 'policyLimit' | 'boundRatio'>
        )>>> }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type WorksheetCellFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id' | 'createdAt' | 'name' | 'status' | 'issuingCompany' | 'brokeredOrInvited' | 'isBrokered' | 'demo' | 'kind' | 'policyLimit' | 'perOccurrenceLimit' | 'totalReinsuranceRequested' | 'lineOfBusiness'>
  & { collaborators: Array<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'fullName'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'fullName'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'fullName'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
  )>, reinsurerQuote: (
    { __typename?: 'QuoteConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & { latestRevision: (
        { __typename?: 'RevisionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Revision' }
          & Pick<Revision, 'id' | 'status' | 'layerLimit' | 'participation'>
          & { quotedLayers: Array<(
            { __typename?: 'QuotedLayer' }
            & Pick<QuotedLayer, 'id' | 'layerLimit' | 'participation'>
          )> }
        )>>> }
      ) }
    )>>> }
  ) }
);

export type CedingCompanyCellFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id'>
  & { owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'fullName' | 'companyName'>
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'fullName' | 'companyName'>
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'fullName' | 'companyName'>
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'companyName'>
  ) }
);

export type WorksheetStatusCellFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id'>
  & { stages: (
    { __typename?: 'WorksheetStatus' }
    & Pick<WorksheetStatus, 'draft' | 'submitted' | 'quoted' | 'bound' | 'issued'>
  ) }
);

export type ParticipatingMarketsCellFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id'>
  & { quotes: (
    { __typename?: 'QuoteConnection' }
    & Pick<QuoteConnection, 'totalCount'>
  ), quoted: (
    { __typename?: 'QuoteConnection' }
    & Pick<QuoteConnection, 'totalCount'>
  ), bound: (
    { __typename?: 'QuoteConnection' }
    & Pick<QuoteConnection, 'totalCount'>
  ), issued: (
    { __typename?: 'QuoteConnection' }
    & Pick<QuoteConnection, 'totalCount'>
  ) }
);

export type ReinsuranceRemainingCellFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id'>
  & { reinsuranceRequests: (
    { __typename?: 'ReinsuranceRequestConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'ReinsuranceRequest' }
      & Pick<ReinsuranceRequest, 'id' | 'participation' | 'attachmentPoint' | 'layerLimit'>
    )>>> }
  ), completedQuotes: (
    { __typename?: 'QuoteConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & { latestRevision: (
        { __typename?: 'RevisionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Revision' }
          & Pick<Revision, 'id' | 'participation' | 'layerLimit' | 'attachmentPoint' | 'isIncomplete'>
          & { quotedLayers: Array<(
            { __typename?: 'QuotedLayer' }
            & Pick<QuotedLayer, 'id'>
            & QuotedLayerInfoFragment
          )> }
        )>>> }
      ) }
    )>>> }
  ) }
);

export type ExportSubmissionQueryVariables = Exact<{
  worksheetId: Scalars['ID'];
}>;


export type ExportSubmissionQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & WorksheetExportFragment
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & WorksheetExportFragment
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type WorksheetExportFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id'>
  & { export?: Maybe<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'url' | 'filename' | 'createdAt'>
  )> }
);

export type GetRevisionInfoQueryVariables = Exact<{
  quoteId: Scalars['ID'];
}>;


export type GetRevisionInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { reinsurer?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id'>
        & { worksheet: (
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id' | 'keyPerils' | 'kind' | 'exclusions' | 'specialInstructions' | 'perOccurrenceLimit'>
          & { reinsuranceRequests: (
            { __typename?: 'ReinsuranceRequestConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'ReinsuranceRequest' }
              & Pick<ReinsuranceRequest, 'id' | 'proposedLayerPremium' | 'deductible' | 'proposedNetLayerPremium' | 'cedingCommission'>
            )>>> }
          ), insurancePolicy?: Maybe<(
            { __typename?: 'InsurancePolicy' }
            & Pick<InsurancePolicy, 'id'>
            & { coverages: (
              { __typename?: 'PolicyCoverageConnection' }
              & { nodes?: Maybe<Array<Maybe<(
                { __typename?: 'CasualtyCoverage' }
                & Pick<CasualtyCoverage, 'id' | 'type'>
              ) | (
                { __typename?: 'PropertyCoverage' }
                & Pick<PropertyCoverage, 'id' | 'type'>
              )>>> }
            ) }
          )> }
          & TowerFragment
        ) }
        & LatestRevisionFragment
        & LayerFragment
      )> }
    )> }
  ) }
);

export type LicenseInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type LicenseInformationQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name' | 'surplusLinesSchema'>
        & { licenseInformation?: Maybe<(
          { __typename?: 'LicenseInformationType' }
          & { surplus_lines_licenses?: Maybe<Array<(
            { __typename?: 'SurplusLinesType' }
            & Pick<SurplusLinesType, 'state' | 'expiration_date' | 'license_holder' | 'license_number'>
            & { address?: Maybe<(
              { __typename?: 'LicenseAddressType' }
              & Pick<LicenseAddressType, 'street_line' | 'state' | 'city' | 'zip_code'>
            )> }
          )>> }
        )> }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name' | 'surplusLinesSchema'>
        & { licenseInformation?: Maybe<(
          { __typename?: 'LicenseInformationType' }
          & { surplus_lines_licenses?: Maybe<Array<(
            { __typename?: 'SurplusLinesType' }
            & Pick<SurplusLinesType, 'state' | 'expiration_date' | 'license_holder' | 'license_number'>
            & { address?: Maybe<(
              { __typename?: 'LicenseAddressType' }
              & Pick<LicenseAddressType, 'street_line' | 'state' | 'city' | 'zip_code'>
            )> }
          )>> }
        )> }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name' | 'surplusLinesSchema'>
        & { licenseInformation?: Maybe<(
          { __typename?: 'LicenseInformationType' }
          & { surplus_lines_licenses?: Maybe<Array<(
            { __typename?: 'SurplusLinesType' }
            & Pick<SurplusLinesType, 'state' | 'expiration_date' | 'license_holder' | 'license_number'>
            & { address?: Maybe<(
              { __typename?: 'LicenseAddressType' }
              & Pick<LicenseAddressType, 'street_line' | 'state' | 'city' | 'zip_code'>
            )> }
          )>> }
        )> }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name' | 'surplusLinesSchema'>
        & { licenseInformation?: Maybe<(
          { __typename?: 'LicenseInformationType' }
          & { surplus_lines_licenses?: Maybe<Array<(
            { __typename?: 'SurplusLinesType' }
            & Pick<SurplusLinesType, 'state' | 'expiration_date' | 'license_holder' | 'license_number'>
            & { address?: Maybe<(
              { __typename?: 'LicenseAddressType' }
              & Pick<LicenseAddressType, 'street_line' | 'state' | 'city' | 'zip_code'>
            )> }
          )>> }
        )> }
      ) }
    )> }
  ) }
);

export type ListBrokersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListBrokersQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { brokers: (
          { __typename?: 'BrokerConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { brokers: (
          { __typename?: 'BrokerConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { brokers: (
          { __typename?: 'BrokerConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { brokers: (
          { __typename?: 'BrokerConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          )>>> }
        ) }
      ) }
    )> }
  ) }
);

export type MarketQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type MarketQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { marketFor: (
          { __typename?: 'AuthorizedMarket' }
          & Pick<AuthorizedMarket, 'id' | 'standardExclusions' | 'boilerplateText' | 'defaultMessage' | 'dealSpecificExclusions' | 'dueDiligences'>
        ) }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { marketFor: (
          { __typename?: 'AuthorizedMarket' }
          & Pick<AuthorizedMarket, 'id' | 'standardExclusions' | 'boilerplateText' | 'defaultMessage' | 'dealSpecificExclusions' | 'dueDiligences'>
        ) }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { marketFor: (
          { __typename?: 'AuthorizedMarket' }
          & Pick<AuthorizedMarket, 'id' | 'standardExclusions' | 'boilerplateText' | 'defaultMessage' | 'dealSpecificExclusions' | 'dueDiligences'>
        ) }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { marketFor: (
          { __typename?: 'AuthorizedMarket' }
          & Pick<AuthorizedMarket, 'id' | 'standardExclusions' | 'boilerplateText' | 'defaultMessage' | 'dealSpecificExclusions' | 'dueDiligences'>
        ) }
      ) }
    )> }
  ) }
);

export type MlQuotationQueryVariables = Exact<{
  participationId: Scalars['ID'];
}>;


export type MlQuotationQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { participation?: Maybe<(
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & MlParticipationFragment
      )> }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { participation?: Maybe<(
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & MlParticipationFragment
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type NotificationEventsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
}>;


export type NotificationEventsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { notificationEvents: (
        { __typename?: 'NotificationEventConnection' }
        & Pick<NotificationEventConnection, 'unreadCount'>
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'NotificationEvent' }
          & Pick<NotificationEvent, 'id' | 'event' | 'status' | 'link' | 'occurredAt'>
          & { relatesTo?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Participation' }
            & Pick<Participation, 'id'>
            & { submission: (
              { __typename?: 'CyberSubmission' }
              & Pick<CyberSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'PanelSubmission' }
              & Pick<PanelSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'PCGSubmission' }
              & Pick<PcgSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesSubmission' }
              & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ), user?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            )> }
          ) | (
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'section' | 'text'>
            & { questionable?: Maybe<(
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'type' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )> }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'type' | 'name'>
              ), quotations: (
                { __typename?: 'QuotationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'CyberQuotation' }
                  & Pick<CyberQuotation, 'id'>
                ) | (
                  { __typename?: 'PCGQuotation' }
                  & Pick<PcgQuotation, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                  & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                ) | (
                  { __typename?: 'PanelsQuotation' }
                  & Pick<PanelsQuotation, 'id'>
                ) | (
                  { __typename?: 'MLQuotation' }
                  & Pick<MlQuotation, 'id'>
                ) | (
                  { __typename?: 'TerrorismQuotation' }
                  & Pick<TerrorismQuotation, 'id'>
                ) | (
                  { __typename?: 'BOPQuotation' }
                  & Pick<BopQuotation, 'id'>
                )>>> }
              ) }
            ) | (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & QuoteInfoFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesQuotation' }
              & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
              & { participation: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id'>
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id'>
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
                ) }
              ) }
            )> }
          ) | (
            { __typename?: 'Subjectivity' }
            & Pick<Subjectivity, 'id' | 'section' | 'text'>
            & { questionable?: Maybe<(
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'type' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )> }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'type' | 'name'>
              ), quotations: (
                { __typename?: 'QuotationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'CyberQuotation' }
                  & Pick<CyberQuotation, 'id'>
                ) | (
                  { __typename?: 'PCGQuotation' }
                  & Pick<PcgQuotation, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                  & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                ) | (
                  { __typename?: 'PanelsQuotation' }
                  & Pick<PanelsQuotation, 'id'>
                ) | (
                  { __typename?: 'MLQuotation' }
                  & Pick<MlQuotation, 'id'>
                ) | (
                  { __typename?: 'TerrorismQuotation' }
                  & Pick<TerrorismQuotation, 'id'>
                ) | (
                  { __typename?: 'BOPQuotation' }
                  & Pick<BopQuotation, 'id'>
                )>>> }
              ) }
            ) | (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & QuoteInfoFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesQuotation' }
              & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
              & { participation: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id'>
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id'>
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
                ) }
              ) }
            )> }
          ) | (
            { __typename?: 'Worksheet' }
            & Pick<Worksheet, 'id'>
            & WorksheetFragmentFragment
          ) | (
            { __typename?: 'Broadcast' }
            & Pick<Broadcast, 'id' | 'subject' | 'type'>
            & { broadcastable: (
              { __typename?: 'Worksheet' }
              & Pick<Worksheet, 'id'>
              & { quotes: (
                { __typename?: 'QuoteConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'Quote' }
                  & Pick<Quote, 'id'>
                  & { discussion?: Maybe<(
                    { __typename?: 'Discussion' }
                    & Pick<Discussion, 'id'>
                  )> }
                )>>> }
              ) }
              & WorksheetFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesSubmission' }
              & Pick<NfpRepsAndWarrantiesSubmission, 'name' | 'id'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ), participations: (
                { __typename?: 'ParticipationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'Participation' }
                  & Pick<Participation, 'id'>
                  & { discussion?: Maybe<(
                    { __typename?: 'Discussion' }
                    & Pick<Discussion, 'id'>
                  )> }
                )>>> }
              ) }
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Quote' }
            & Pick<Quote, 'id'>
            & { reinsurer?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id' | 'companyName'>
            )>, discussion?: Maybe<(
              { __typename?: 'Discussion' }
              & Pick<Discussion, 'id'>
            )>, worksheet: (
              { __typename?: 'Worksheet' }
              & Pick<Worksheet, 'id'>
              & WorksheetFragmentFragment
            ) }
          ) | (
            { __typename?: 'Revision' }
            & Pick<Revision, 'id'>
            & { quote: (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & { reinsurer?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'companyName'>
              )>, worksheet: (
                { __typename?: 'Worksheet' }
                & Pick<Worksheet, 'id'>
                & WorksheetFragmentFragment
              ) }
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { apiUser?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName'>
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id' | 'firstName'>
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id' | 'firstName'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName'>
              )>, user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )>, owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'CyberSubmission' }
            & Pick<CyberSubmission, 'id' | 'name' | 'contactName'>
            & { panel?: Maybe<(
              { __typename?: 'Panel' }
              & Pick<Panel, 'id'>
            )> }
          ) | (
            { __typename?: 'PanelSubmission' }
            & Pick<PanelSubmission, 'id' | 'name'>
            & { owner: (
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) }
          ) | (
            { __typename?: 'PCGSubmission' }
            & Pick<PcgSubmission, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesSubmission' }
            & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
          ) | (
            { __typename?: 'DirectMessage' }
            & Pick<DirectMessage, 'id' | 'subject'>
            & { discussion?: Maybe<(
              { __typename?: 'Discussion' }
              & Pick<Discussion, 'id'>
              & { discussable: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id'>
                  )> }
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id' | 'lineOfBusiness'>
                  )> }
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id' | 'lineOfBusiness'>
                  )> }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                ), quotations: (
                  { __typename?: 'QuotationConnection' }
                  & { nodes?: Maybe<Array<Maybe<(
                    { __typename?: 'CyberQuotation' }
                    & Pick<CyberQuotation, 'id'>
                  ) | (
                    { __typename?: 'PCGQuotation' }
                    & Pick<PcgQuotation, 'id'>
                  ) | (
                    { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                    & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                  ) | (
                    { __typename?: 'PanelsQuotation' }
                    & Pick<PanelsQuotation, 'id'>
                  ) | (
                    { __typename?: 'MLQuotation' }
                    & Pick<MlQuotation, 'id'>
                  ) | (
                    { __typename?: 'TerrorismQuotation' }
                    & Pick<TerrorismQuotation, 'id'>
                  ) | (
                    { __typename?: 'BOPQuotation' }
                    & Pick<BopQuotation, 'id'>
                  )>>> }
                ) }
              ) | (
                { __typename?: 'Worksheet' }
                & Pick<Worksheet, 'id' | 'demo'>
              ) | (
                { __typename?: 'Quote' }
                & Pick<Quote, 'id'>
                & { worksheet: (
                  { __typename?: 'Worksheet' }
                  & Pick<Worksheet, 'id' | 'demo'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
              ) | (
                { __typename?: 'BrokerInvite' }
                & Pick<BrokerInvite, 'id'>
              ) | (
                { __typename?: 'BrokerCollaboration' }
                & Pick<BrokerCollaboration, 'id'>
              ) | (
                { __typename?: 'ClientCollaboration' }
                & Pick<ClientCollaboration, 'id'>
              ) | (
                { __typename?: 'MgaCollaboration' }
                & Pick<MgaCollaboration, 'id'>
              ) | (
                { __typename?: 'WholesalerCollaboration' }
                & Pick<WholesalerCollaboration, 'id'>
              ) }
            )>, author: (
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id' | 'fullName'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id' | 'fullName'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'fullName'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
              ) }
            ) }
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )>, owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage'>
        ) }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { notificationEvents: (
        { __typename?: 'NotificationEventConnection' }
        & Pick<NotificationEventConnection, 'unreadCount'>
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'NotificationEvent' }
          & Pick<NotificationEvent, 'id' | 'event' | 'status' | 'link' | 'occurredAt'>
          & { relatesTo?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Participation' }
            & Pick<Participation, 'id'>
            & { submission: (
              { __typename?: 'CyberSubmission' }
              & Pick<CyberSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'PanelSubmission' }
              & Pick<PanelSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'PCGSubmission' }
              & Pick<PcgSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesSubmission' }
              & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ), user?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            )> }
          ) | (
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'section' | 'text'>
            & { questionable?: Maybe<(
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'type' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )> }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'type' | 'name'>
              ), quotations: (
                { __typename?: 'QuotationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'CyberQuotation' }
                  & Pick<CyberQuotation, 'id'>
                ) | (
                  { __typename?: 'PCGQuotation' }
                  & Pick<PcgQuotation, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                  & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                ) | (
                  { __typename?: 'PanelsQuotation' }
                  & Pick<PanelsQuotation, 'id'>
                ) | (
                  { __typename?: 'MLQuotation' }
                  & Pick<MlQuotation, 'id'>
                ) | (
                  { __typename?: 'TerrorismQuotation' }
                  & Pick<TerrorismQuotation, 'id'>
                ) | (
                  { __typename?: 'BOPQuotation' }
                  & Pick<BopQuotation, 'id'>
                )>>> }
              ) }
            ) | (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & QuoteInfoFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesQuotation' }
              & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
              & { participation: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id'>
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id'>
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
                ) }
              ) }
            )> }
          ) | (
            { __typename?: 'Subjectivity' }
            & Pick<Subjectivity, 'id' | 'section' | 'text'>
            & { questionable?: Maybe<(
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'type' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )> }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'type' | 'name'>
              ), quotations: (
                { __typename?: 'QuotationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'CyberQuotation' }
                  & Pick<CyberQuotation, 'id'>
                ) | (
                  { __typename?: 'PCGQuotation' }
                  & Pick<PcgQuotation, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                  & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                ) | (
                  { __typename?: 'PanelsQuotation' }
                  & Pick<PanelsQuotation, 'id'>
                ) | (
                  { __typename?: 'MLQuotation' }
                  & Pick<MlQuotation, 'id'>
                ) | (
                  { __typename?: 'TerrorismQuotation' }
                  & Pick<TerrorismQuotation, 'id'>
                ) | (
                  { __typename?: 'BOPQuotation' }
                  & Pick<BopQuotation, 'id'>
                )>>> }
              ) }
            ) | (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & QuoteInfoFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesQuotation' }
              & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
              & { participation: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id'>
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id'>
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
                ) }
              ) }
            )> }
          ) | (
            { __typename?: 'Worksheet' }
            & Pick<Worksheet, 'id'>
            & WorksheetFragmentFragment
          ) | (
            { __typename?: 'Broadcast' }
            & Pick<Broadcast, 'id' | 'subject' | 'type'>
            & { broadcastable: (
              { __typename?: 'Worksheet' }
              & Pick<Worksheet, 'id'>
              & { quotes: (
                { __typename?: 'QuoteConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'Quote' }
                  & Pick<Quote, 'id'>
                  & { discussion?: Maybe<(
                    { __typename?: 'Discussion' }
                    & Pick<Discussion, 'id'>
                  )> }
                )>>> }
              ) }
              & WorksheetFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesSubmission' }
              & Pick<NfpRepsAndWarrantiesSubmission, 'name' | 'id'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ), participations: (
                { __typename?: 'ParticipationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'Participation' }
                  & Pick<Participation, 'id'>
                  & { discussion?: Maybe<(
                    { __typename?: 'Discussion' }
                    & Pick<Discussion, 'id'>
                  )> }
                )>>> }
              ) }
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Quote' }
            & Pick<Quote, 'id'>
            & { reinsurer?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id' | 'companyName'>
            )>, discussion?: Maybe<(
              { __typename?: 'Discussion' }
              & Pick<Discussion, 'id'>
            )>, worksheet: (
              { __typename?: 'Worksheet' }
              & Pick<Worksheet, 'id'>
              & WorksheetFragmentFragment
            ) }
          ) | (
            { __typename?: 'Revision' }
            & Pick<Revision, 'id'>
            & { quote: (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & { reinsurer?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'companyName'>
              )>, worksheet: (
                { __typename?: 'Worksheet' }
                & Pick<Worksheet, 'id'>
                & WorksheetFragmentFragment
              ) }
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { apiUser?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName'>
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id' | 'firstName'>
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id' | 'firstName'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName'>
              )>, user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )>, owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'CyberSubmission' }
            & Pick<CyberSubmission, 'id' | 'name' | 'contactName'>
            & { panel?: Maybe<(
              { __typename?: 'Panel' }
              & Pick<Panel, 'id'>
            )> }
          ) | (
            { __typename?: 'PanelSubmission' }
            & Pick<PanelSubmission, 'id' | 'name'>
            & { owner: (
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) }
          ) | (
            { __typename?: 'PCGSubmission' }
            & Pick<PcgSubmission, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesSubmission' }
            & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
          ) | (
            { __typename?: 'DirectMessage' }
            & Pick<DirectMessage, 'id' | 'subject'>
            & { discussion?: Maybe<(
              { __typename?: 'Discussion' }
              & Pick<Discussion, 'id'>
              & { discussable: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id'>
                  )> }
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id' | 'lineOfBusiness'>
                  )> }
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id' | 'lineOfBusiness'>
                  )> }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                ), quotations: (
                  { __typename?: 'QuotationConnection' }
                  & { nodes?: Maybe<Array<Maybe<(
                    { __typename?: 'CyberQuotation' }
                    & Pick<CyberQuotation, 'id'>
                  ) | (
                    { __typename?: 'PCGQuotation' }
                    & Pick<PcgQuotation, 'id'>
                  ) | (
                    { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                    & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                  ) | (
                    { __typename?: 'PanelsQuotation' }
                    & Pick<PanelsQuotation, 'id'>
                  ) | (
                    { __typename?: 'MLQuotation' }
                    & Pick<MlQuotation, 'id'>
                  ) | (
                    { __typename?: 'TerrorismQuotation' }
                    & Pick<TerrorismQuotation, 'id'>
                  ) | (
                    { __typename?: 'BOPQuotation' }
                    & Pick<BopQuotation, 'id'>
                  )>>> }
                ) }
              ) | (
                { __typename?: 'Worksheet' }
                & Pick<Worksheet, 'id' | 'demo'>
              ) | (
                { __typename?: 'Quote' }
                & Pick<Quote, 'id'>
                & { worksheet: (
                  { __typename?: 'Worksheet' }
                  & Pick<Worksheet, 'id' | 'demo'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
              ) | (
                { __typename?: 'BrokerInvite' }
                & Pick<BrokerInvite, 'id'>
              ) | (
                { __typename?: 'BrokerCollaboration' }
                & Pick<BrokerCollaboration, 'id'>
              ) | (
                { __typename?: 'ClientCollaboration' }
                & Pick<ClientCollaboration, 'id'>
              ) | (
                { __typename?: 'MgaCollaboration' }
                & Pick<MgaCollaboration, 'id'>
              ) | (
                { __typename?: 'WholesalerCollaboration' }
                & Pick<WholesalerCollaboration, 'id'>
              ) }
            )>, author: (
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id' | 'fullName'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id' | 'fullName'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'fullName'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
              ) }
            ) }
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )>, owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage'>
        ) }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { notificationEvents: (
        { __typename?: 'NotificationEventConnection' }
        & Pick<NotificationEventConnection, 'unreadCount'>
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'NotificationEvent' }
          & Pick<NotificationEvent, 'id' | 'event' | 'status' | 'link' | 'occurredAt'>
          & { relatesTo?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Participation' }
            & Pick<Participation, 'id'>
            & { submission: (
              { __typename?: 'CyberSubmission' }
              & Pick<CyberSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'PanelSubmission' }
              & Pick<PanelSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'PCGSubmission' }
              & Pick<PcgSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesSubmission' }
              & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ), user?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            )> }
          ) | (
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'section' | 'text'>
            & { questionable?: Maybe<(
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'type' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )> }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'type' | 'name'>
              ), quotations: (
                { __typename?: 'QuotationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'CyberQuotation' }
                  & Pick<CyberQuotation, 'id'>
                ) | (
                  { __typename?: 'PCGQuotation' }
                  & Pick<PcgQuotation, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                  & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                ) | (
                  { __typename?: 'PanelsQuotation' }
                  & Pick<PanelsQuotation, 'id'>
                ) | (
                  { __typename?: 'MLQuotation' }
                  & Pick<MlQuotation, 'id'>
                ) | (
                  { __typename?: 'TerrorismQuotation' }
                  & Pick<TerrorismQuotation, 'id'>
                ) | (
                  { __typename?: 'BOPQuotation' }
                  & Pick<BopQuotation, 'id'>
                )>>> }
              ) }
            ) | (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & QuoteInfoFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesQuotation' }
              & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
              & { participation: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id'>
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id'>
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
                ) }
              ) }
            )> }
          ) | (
            { __typename?: 'Subjectivity' }
            & Pick<Subjectivity, 'id' | 'section' | 'text'>
            & { questionable?: Maybe<(
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'type' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )> }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'type' | 'name'>
              ), quotations: (
                { __typename?: 'QuotationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'CyberQuotation' }
                  & Pick<CyberQuotation, 'id'>
                ) | (
                  { __typename?: 'PCGQuotation' }
                  & Pick<PcgQuotation, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                  & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                ) | (
                  { __typename?: 'PanelsQuotation' }
                  & Pick<PanelsQuotation, 'id'>
                ) | (
                  { __typename?: 'MLQuotation' }
                  & Pick<MlQuotation, 'id'>
                ) | (
                  { __typename?: 'TerrorismQuotation' }
                  & Pick<TerrorismQuotation, 'id'>
                ) | (
                  { __typename?: 'BOPQuotation' }
                  & Pick<BopQuotation, 'id'>
                )>>> }
              ) }
            ) | (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & QuoteInfoFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesQuotation' }
              & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
              & { participation: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id'>
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id'>
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
                ) }
              ) }
            )> }
          ) | (
            { __typename?: 'Worksheet' }
            & Pick<Worksheet, 'id'>
            & WorksheetFragmentFragment
          ) | (
            { __typename?: 'Broadcast' }
            & Pick<Broadcast, 'id' | 'subject' | 'type'>
            & { broadcastable: (
              { __typename?: 'Worksheet' }
              & Pick<Worksheet, 'id'>
              & { quotes: (
                { __typename?: 'QuoteConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'Quote' }
                  & Pick<Quote, 'id'>
                  & { discussion?: Maybe<(
                    { __typename?: 'Discussion' }
                    & Pick<Discussion, 'id'>
                  )> }
                )>>> }
              ) }
              & WorksheetFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesSubmission' }
              & Pick<NfpRepsAndWarrantiesSubmission, 'name' | 'id'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ), participations: (
                { __typename?: 'ParticipationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'Participation' }
                  & Pick<Participation, 'id'>
                  & { discussion?: Maybe<(
                    { __typename?: 'Discussion' }
                    & Pick<Discussion, 'id'>
                  )> }
                )>>> }
              ) }
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Quote' }
            & Pick<Quote, 'id'>
            & { reinsurer?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id' | 'companyName'>
            )>, discussion?: Maybe<(
              { __typename?: 'Discussion' }
              & Pick<Discussion, 'id'>
            )>, worksheet: (
              { __typename?: 'Worksheet' }
              & Pick<Worksheet, 'id'>
              & WorksheetFragmentFragment
            ) }
          ) | (
            { __typename?: 'Revision' }
            & Pick<Revision, 'id'>
            & { quote: (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & { reinsurer?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'companyName'>
              )>, worksheet: (
                { __typename?: 'Worksheet' }
                & Pick<Worksheet, 'id'>
                & WorksheetFragmentFragment
              ) }
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { apiUser?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName'>
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id' | 'firstName'>
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id' | 'firstName'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName'>
              )>, user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )>, owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'CyberSubmission' }
            & Pick<CyberSubmission, 'id' | 'name' | 'contactName'>
            & { panel?: Maybe<(
              { __typename?: 'Panel' }
              & Pick<Panel, 'id'>
            )> }
          ) | (
            { __typename?: 'PanelSubmission' }
            & Pick<PanelSubmission, 'id' | 'name'>
            & { owner: (
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) }
          ) | (
            { __typename?: 'PCGSubmission' }
            & Pick<PcgSubmission, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesSubmission' }
            & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
          ) | (
            { __typename?: 'DirectMessage' }
            & Pick<DirectMessage, 'id' | 'subject'>
            & { discussion?: Maybe<(
              { __typename?: 'Discussion' }
              & Pick<Discussion, 'id'>
              & { discussable: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id'>
                  )> }
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id' | 'lineOfBusiness'>
                  )> }
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id' | 'lineOfBusiness'>
                  )> }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                ), quotations: (
                  { __typename?: 'QuotationConnection' }
                  & { nodes?: Maybe<Array<Maybe<(
                    { __typename?: 'CyberQuotation' }
                    & Pick<CyberQuotation, 'id'>
                  ) | (
                    { __typename?: 'PCGQuotation' }
                    & Pick<PcgQuotation, 'id'>
                  ) | (
                    { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                    & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                  ) | (
                    { __typename?: 'PanelsQuotation' }
                    & Pick<PanelsQuotation, 'id'>
                  ) | (
                    { __typename?: 'MLQuotation' }
                    & Pick<MlQuotation, 'id'>
                  ) | (
                    { __typename?: 'TerrorismQuotation' }
                    & Pick<TerrorismQuotation, 'id'>
                  ) | (
                    { __typename?: 'BOPQuotation' }
                    & Pick<BopQuotation, 'id'>
                  )>>> }
                ) }
              ) | (
                { __typename?: 'Worksheet' }
                & Pick<Worksheet, 'id' | 'demo'>
              ) | (
                { __typename?: 'Quote' }
                & Pick<Quote, 'id'>
                & { worksheet: (
                  { __typename?: 'Worksheet' }
                  & Pick<Worksheet, 'id' | 'demo'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
              ) | (
                { __typename?: 'BrokerInvite' }
                & Pick<BrokerInvite, 'id'>
              ) | (
                { __typename?: 'BrokerCollaboration' }
                & Pick<BrokerCollaboration, 'id'>
              ) | (
                { __typename?: 'ClientCollaboration' }
                & Pick<ClientCollaboration, 'id'>
              ) | (
                { __typename?: 'MgaCollaboration' }
                & Pick<MgaCollaboration, 'id'>
              ) | (
                { __typename?: 'WholesalerCollaboration' }
                & Pick<WholesalerCollaboration, 'id'>
              ) }
            )>, author: (
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id' | 'fullName'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id' | 'fullName'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'fullName'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
              ) }
            ) }
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )>, owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage'>
        ) }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { notificationEvents: (
        { __typename?: 'NotificationEventConnection' }
        & Pick<NotificationEventConnection, 'unreadCount'>
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'NotificationEvent' }
          & Pick<NotificationEvent, 'id' | 'event' | 'status' | 'link' | 'occurredAt'>
          & { relatesTo?: Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
          ) | (
            { __typename?: 'Participation' }
            & Pick<Participation, 'id'>
            & { submission: (
              { __typename?: 'CyberSubmission' }
              & Pick<CyberSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'PanelSubmission' }
              & Pick<PanelSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'PCGSubmission' }
              & Pick<PcgSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesSubmission' }
              & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) }
            ), user?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            )> }
          ) | (
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'section' | 'text'>
            & { questionable?: Maybe<(
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'type' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )> }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'type' | 'name'>
              ), quotations: (
                { __typename?: 'QuotationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'CyberQuotation' }
                  & Pick<CyberQuotation, 'id'>
                ) | (
                  { __typename?: 'PCGQuotation' }
                  & Pick<PcgQuotation, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                  & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                ) | (
                  { __typename?: 'PanelsQuotation' }
                  & Pick<PanelsQuotation, 'id'>
                ) | (
                  { __typename?: 'MLQuotation' }
                  & Pick<MlQuotation, 'id'>
                ) | (
                  { __typename?: 'TerrorismQuotation' }
                  & Pick<TerrorismQuotation, 'id'>
                ) | (
                  { __typename?: 'BOPQuotation' }
                  & Pick<BopQuotation, 'id'>
                )>>> }
              ) }
            ) | (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & QuoteInfoFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesQuotation' }
              & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
              & { participation: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id'>
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id'>
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
                ) }
              ) }
            )> }
          ) | (
            { __typename?: 'Subjectivity' }
            & Pick<Subjectivity, 'id' | 'section' | 'text'>
            & { questionable?: Maybe<(
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'type' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )> }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'type' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'type' | 'name'>
              ), quotations: (
                { __typename?: 'QuotationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'CyberQuotation' }
                  & Pick<CyberQuotation, 'id'>
                ) | (
                  { __typename?: 'PCGQuotation' }
                  & Pick<PcgQuotation, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                  & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                ) | (
                  { __typename?: 'PanelsQuotation' }
                  & Pick<PanelsQuotation, 'id'>
                ) | (
                  { __typename?: 'MLQuotation' }
                  & Pick<MlQuotation, 'id'>
                ) | (
                  { __typename?: 'TerrorismQuotation' }
                  & Pick<TerrorismQuotation, 'id'>
                ) | (
                  { __typename?: 'BOPQuotation' }
                  & Pick<BopQuotation, 'id'>
                )>>> }
              ) }
            ) | (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & QuoteInfoFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesQuotation' }
              & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
              & { participation: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id'>
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id'>
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id'>
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
                ) }
              ) }
            )> }
          ) | (
            { __typename?: 'Worksheet' }
            & Pick<Worksheet, 'id'>
            & WorksheetFragmentFragment
          ) | (
            { __typename?: 'Broadcast' }
            & Pick<Broadcast, 'id' | 'subject' | 'type'>
            & { broadcastable: (
              { __typename?: 'Worksheet' }
              & Pick<Worksheet, 'id'>
              & { quotes: (
                { __typename?: 'QuoteConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'Quote' }
                  & Pick<Quote, 'id'>
                  & { discussion?: Maybe<(
                    { __typename?: 'Discussion' }
                    & Pick<Discussion, 'id'>
                  )> }
                )>>> }
              ) }
              & WorksheetFragmentFragment
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesSubmission' }
              & Pick<NfpRepsAndWarrantiesSubmission, 'name' | 'id'>
              & { owner: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ), participations: (
                { __typename?: 'ParticipationConnection' }
                & { nodes?: Maybe<Array<Maybe<(
                  { __typename?: 'Participation' }
                  & Pick<Participation, 'id'>
                  & { discussion?: Maybe<(
                    { __typename?: 'Discussion' }
                    & Pick<Discussion, 'id'>
                  )> }
                )>>> }
              ) }
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
          ) | (
            { __typename?: 'Quote' }
            & Pick<Quote, 'id'>
            & { reinsurer?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id' | 'companyName'>
            )>, discussion?: Maybe<(
              { __typename?: 'Discussion' }
              & Pick<Discussion, 'id'>
            )>, worksheet: (
              { __typename?: 'Worksheet' }
              & Pick<Worksheet, 'id'>
              & WorksheetFragmentFragment
            ) }
          ) | (
            { __typename?: 'Revision' }
            & Pick<Revision, 'id'>
            & { quote: (
              { __typename?: 'Quote' }
              & Pick<Quote, 'id'>
              & { reinsurer?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'companyName'>
              )>, worksheet: (
                { __typename?: 'Worksheet' }
                & Pick<Worksheet, 'id'>
                & WorksheetFragmentFragment
              ) }
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
          ) | (
            { __typename?: 'CyberQuotation' }
            & Pick<CyberQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { apiUser?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName'>
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id' | 'firstName'>
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id' | 'firstName'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'firstName'>
              )>, user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )>, owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'CyberSubmission' }
            & Pick<CyberSubmission, 'id' | 'name' | 'contactName'>
            & { panel?: Maybe<(
              { __typename?: 'Panel' }
              & Pick<Panel, 'id'>
            )> }
          ) | (
            { __typename?: 'PanelSubmission' }
            & Pick<PanelSubmission, 'id' | 'name'>
            & { owner: (
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
              & { company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name'>
              ) }
            ) }
          ) | (
            { __typename?: 'PCGSubmission' }
            & Pick<PcgSubmission, 'id'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesSubmission' }
            & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
          ) | (
            { __typename?: 'DirectMessage' }
            & Pick<DirectMessage, 'id' | 'subject'>
            & { discussion?: Maybe<(
              { __typename?: 'Discussion' }
              & Pick<Discussion, 'id'>
              & { discussable: (
                { __typename?: 'Participation' }
                & Pick<Participation, 'id'>
                & { submission: (
                  { __typename?: 'CyberSubmission' }
                  & Pick<CyberSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id'>
                  )> }
                ) | (
                  { __typename?: 'PanelSubmission' }
                  & Pick<PanelSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id' | 'lineOfBusiness'>
                  )> }
                ) | (
                  { __typename?: 'PCGSubmission' }
                  & Pick<PcgSubmission, 'id' | 'name'>
                  & { panel?: Maybe<(
                    { __typename?: 'Panel' }
                    & Pick<Panel, 'id' | 'lineOfBusiness'>
                  )> }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                  & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                ), quotations: (
                  { __typename?: 'QuotationConnection' }
                  & { nodes?: Maybe<Array<Maybe<(
                    { __typename?: 'CyberQuotation' }
                    & Pick<CyberQuotation, 'id'>
                  ) | (
                    { __typename?: 'PCGQuotation' }
                    & Pick<PcgQuotation, 'id'>
                  ) | (
                    { __typename?: 'NFPRepsAndWarrantiesQuotation' }
                    & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
                  ) | (
                    { __typename?: 'PanelsQuotation' }
                    & Pick<PanelsQuotation, 'id'>
                  ) | (
                    { __typename?: 'MLQuotation' }
                    & Pick<MlQuotation, 'id'>
                  ) | (
                    { __typename?: 'TerrorismQuotation' }
                    & Pick<TerrorismQuotation, 'id'>
                  ) | (
                    { __typename?: 'BOPQuotation' }
                    & Pick<BopQuotation, 'id'>
                  )>>> }
                ) }
              ) | (
                { __typename?: 'Worksheet' }
                & Pick<Worksheet, 'id' | 'demo'>
              ) | (
                { __typename?: 'Quote' }
                & Pick<Quote, 'id'>
                & { worksheet: (
                  { __typename?: 'Worksheet' }
                  & Pick<Worksheet, 'id' | 'demo'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
              ) | (
                { __typename?: 'BrokerInvite' }
                & Pick<BrokerInvite, 'id'>
              ) | (
                { __typename?: 'BrokerCollaboration' }
                & Pick<BrokerCollaboration, 'id'>
              ) | (
                { __typename?: 'ClientCollaboration' }
                & Pick<ClientCollaboration, 'id'>
              ) | (
                { __typename?: 'MgaCollaboration' }
                & Pick<MgaCollaboration, 'id'>
              ) | (
                { __typename?: 'WholesalerCollaboration' }
                & Pick<WholesalerCollaboration, 'id'>
              ) }
            )>, author: (
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id' | 'fullName'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id' | 'fullName'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id' | 'fullName'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesQuotation' }
            & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
              ) }
            ) }
          ) | (
            { __typename?: 'PanelsQuotation' }
            & Pick<PanelsQuotation, 'id'>
            & { participation: (
              { __typename?: 'Participation' }
              & Pick<Participation, 'id'>
              & { user?: Maybe<(
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Cedent' }
                & Pick<Cedent, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'Broker' }
                & Pick<Broker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesBroker' }
                & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                & { company: (
                  { __typename?: 'Company' }
                  & Pick<Company, 'id' | 'name'>
                ) }
              )>, submission: (
                { __typename?: 'CyberSubmission' }
                & Pick<CyberSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PanelSubmission' }
                & Pick<PanelSubmission, 'id' | 'name'>
                & { panel?: Maybe<(
                  { __typename?: 'Panel' }
                  & Pick<Panel, 'id'>
                )>, owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'PCGSubmission' }
                & Pick<PcgSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) | (
                { __typename?: 'NFPRepsAndWarrantiesSubmission' }
                & Pick<NfpRepsAndWarrantiesSubmission, 'id' | 'name'>
                & { owner: (
                  { __typename?: 'Reinsurer' }
                  & Pick<Reinsurer, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Cedent' }
                  & Pick<Cedent, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'Broker' }
                  & Pick<Broker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) | (
                  { __typename?: 'NFPRepsAndWarrantiesBroker' }
                  & Pick<NfpRepsAndWarrantiesBroker, 'id'>
                  & { company: (
                    { __typename?: 'Company' }
                    & Pick<Company, 'id' | 'name'>
                  ) }
                ) }
              ) }
            ) }
          ) | (
            { __typename?: 'MLQuotation' }
            & Pick<MlQuotation, 'id'>
          ) | (
            { __typename?: 'TerrorismQuotation' }
            & Pick<TerrorismQuotation, 'id'>
          ) | (
            { __typename?: 'BOPQuotation' }
            & Pick<BopQuotation, 'id'>
          )> }
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage'>
        ) }
      ) }
    )> }
  ) }
);

export type WorksheetFragmentFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id' | 'name' | 'demo'>
  & { insurancePolicy?: Maybe<(
    { __typename?: 'InsurancePolicy' }
    & Pick<InsurancePolicy, 'id' | 'insurer'>
  )> }
);

export type PanelQueryVariables = Exact<{
  panelId: Scalars['ID'];
}>;


export type PanelQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'name' | 'lineOfBusiness' | 'configuration' | 'displayTax' | 'manualSubjectivities'>
          & { externalCarriers?: Maybe<Array<(
            { __typename?: 'ExternalCarrier' }
            & Pick<ExternalCarrier, 'id' | 'name' | 'logoUrl'>
          )>>, panelistConfigurations: (
            { __typename?: 'PanelistConfigurationConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'PanelistConfiguration' }
              & Pick<PanelistConfiguration, 'disabled'>
              & { documents?: Maybe<Array<(
                { __typename?: 'Document' }
                & Pick<Document, 'id'>
                & DocumentFragment
              )>>, wholesalerCompany?: Maybe<(
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name' | 'logoUrl'>
              )>, panelist: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName'>
                & { panelistConfiguration?: Maybe<(
                  { __typename?: 'PanelistConfiguration' }
                  & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
                  & { carrierAuthentications?: Maybe<(
                    { __typename?: 'CarrierAuthenticationConnection' }
                    & { nodes?: Maybe<Array<Maybe<(
                      { __typename?: 'CarrierAuthentication' }
                      & Pick<CarrierAuthentication, 'id' | 'nickname' | 'default'>
                    )>>> }
                  )> }
                )> }
              ) }
            )>>> }
          ), panelists: (
            { __typename?: 'ReinsurerConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'medianReplyTime' | 'quoteRatio' | 'numberOfUwQuestions' | 'id' | 'firstName' | 'mfaCorporateEmailRequired' | 'mfaCorporateNetworksRequired' | 'mfaRemoteNetworkAccessRequired' | 'websiteOptional' | 'indicationProvided' | 'lastName' | 'avatarUrl' | 'type'>
              & { panelistConfiguration?: Maybe<(
                { __typename?: 'PanelistConfiguration' }
                & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
              )>, company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'logoUrl' | 'name'>
                & { ambestRating?: Maybe<(
                  { __typename?: 'AMBestRating' }
                  & Pick<AmBestRating, 'id' | 'rating'>
                )> }
              ) }
            )>>> }
          ) }
        )> }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'name' | 'lineOfBusiness' | 'configuration' | 'displayTax' | 'manualSubjectivities'>
          & { externalCarriers?: Maybe<Array<(
            { __typename?: 'ExternalCarrier' }
            & Pick<ExternalCarrier, 'id' | 'name' | 'logoUrl'>
          )>>, panelistConfigurations: (
            { __typename?: 'PanelistConfigurationConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'PanelistConfiguration' }
              & Pick<PanelistConfiguration, 'disabled'>
              & { documents?: Maybe<Array<(
                { __typename?: 'Document' }
                & Pick<Document, 'id'>
                & DocumentFragment
              )>>, wholesalerCompany?: Maybe<(
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name' | 'logoUrl'>
              )>, panelist: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName'>
                & { panelistConfiguration?: Maybe<(
                  { __typename?: 'PanelistConfiguration' }
                  & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
                  & { carrierAuthentications?: Maybe<(
                    { __typename?: 'CarrierAuthenticationConnection' }
                    & { nodes?: Maybe<Array<Maybe<(
                      { __typename?: 'CarrierAuthentication' }
                      & Pick<CarrierAuthentication, 'id' | 'nickname' | 'default'>
                    )>>> }
                  )> }
                )> }
              ) }
            )>>> }
          ), panelists: (
            { __typename?: 'ReinsurerConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'medianReplyTime' | 'quoteRatio' | 'numberOfUwQuestions' | 'id' | 'firstName' | 'mfaCorporateEmailRequired' | 'mfaCorporateNetworksRequired' | 'mfaRemoteNetworkAccessRequired' | 'websiteOptional' | 'indicationProvided' | 'lastName' | 'avatarUrl' | 'type'>
              & { panelistConfiguration?: Maybe<(
                { __typename?: 'PanelistConfiguration' }
                & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
              )>, company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'logoUrl' | 'name'>
                & { ambestRating?: Maybe<(
                  { __typename?: 'AMBestRating' }
                  & Pick<AmBestRating, 'id' | 'rating'>
                )> }
              ) }
            )>>> }
          ) }
        )> }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'name' | 'lineOfBusiness' | 'configuration' | 'displayTax' | 'manualSubjectivities'>
          & { externalCarriers?: Maybe<Array<(
            { __typename?: 'ExternalCarrier' }
            & Pick<ExternalCarrier, 'id' | 'name' | 'logoUrl'>
          )>>, panelistConfigurations: (
            { __typename?: 'PanelistConfigurationConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'PanelistConfiguration' }
              & Pick<PanelistConfiguration, 'disabled'>
              & { documents?: Maybe<Array<(
                { __typename?: 'Document' }
                & Pick<Document, 'id'>
                & DocumentFragment
              )>>, wholesalerCompany?: Maybe<(
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name' | 'logoUrl'>
              )>, panelist: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName'>
                & { panelistConfiguration?: Maybe<(
                  { __typename?: 'PanelistConfiguration' }
                  & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
                  & { carrierAuthentications?: Maybe<(
                    { __typename?: 'CarrierAuthenticationConnection' }
                    & { nodes?: Maybe<Array<Maybe<(
                      { __typename?: 'CarrierAuthentication' }
                      & Pick<CarrierAuthentication, 'id' | 'nickname' | 'default'>
                    )>>> }
                  )> }
                )> }
              ) }
            )>>> }
          ), panelists: (
            { __typename?: 'ReinsurerConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'medianReplyTime' | 'quoteRatio' | 'numberOfUwQuestions' | 'id' | 'firstName' | 'mfaCorporateEmailRequired' | 'mfaCorporateNetworksRequired' | 'mfaRemoteNetworkAccessRequired' | 'websiteOptional' | 'indicationProvided' | 'lastName' | 'avatarUrl' | 'type'>
              & { panelistConfiguration?: Maybe<(
                { __typename?: 'PanelistConfiguration' }
                & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
              )>, company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'logoUrl' | 'name'>
                & { ambestRating?: Maybe<(
                  { __typename?: 'AMBestRating' }
                  & Pick<AmBestRating, 'id' | 'rating'>
                )> }
              ) }
            )>>> }
          ) }
        )> }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'name' | 'lineOfBusiness' | 'configuration' | 'displayTax' | 'manualSubjectivities'>
          & { externalCarriers?: Maybe<Array<(
            { __typename?: 'ExternalCarrier' }
            & Pick<ExternalCarrier, 'id' | 'name' | 'logoUrl'>
          )>>, panelistConfigurations: (
            { __typename?: 'PanelistConfigurationConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'PanelistConfiguration' }
              & Pick<PanelistConfiguration, 'disabled'>
              & { documents?: Maybe<Array<(
                { __typename?: 'Document' }
                & Pick<Document, 'id'>
                & DocumentFragment
              )>>, wholesalerCompany?: Maybe<(
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'name' | 'logoUrl'>
              )>, panelist: (
                { __typename?: 'Reinsurer' }
                & Pick<Reinsurer, 'id' | 'firstName'>
                & { panelistConfiguration?: Maybe<(
                  { __typename?: 'PanelistConfiguration' }
                  & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
                  & { carrierAuthentications?: Maybe<(
                    { __typename?: 'CarrierAuthenticationConnection' }
                    & { nodes?: Maybe<Array<Maybe<(
                      { __typename?: 'CarrierAuthentication' }
                      & Pick<CarrierAuthentication, 'id' | 'nickname' | 'default'>
                    )>>> }
                  )> }
                )> }
              ) }
            )>>> }
          ), panelists: (
            { __typename?: 'ReinsurerConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'medianReplyTime' | 'quoteRatio' | 'numberOfUwQuestions' | 'id' | 'firstName' | 'mfaCorporateEmailRequired' | 'mfaCorporateNetworksRequired' | 'mfaRemoteNetworkAccessRequired' | 'websiteOptional' | 'indicationProvided' | 'lastName' | 'avatarUrl' | 'type'>
              & { panelistConfiguration?: Maybe<(
                { __typename?: 'PanelistConfiguration' }
                & Pick<PanelistConfiguration, 'preferred' | 'indicationOnly'>
              )>, company: (
                { __typename?: 'Company' }
                & Pick<Company, 'id' | 'logoUrl' | 'name'>
                & { ambestRating?: Maybe<(
                  { __typename?: 'AMBestRating' }
                  & Pick<AmBestRating, 'id' | 'rating'>
                )> }
              ) }
            )>>> }
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type PanelProposalConfigurationQueryVariables = Exact<{
  panelId: Scalars['ID'];
}>;


export type PanelProposalConfigurationQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'name' | 'lineOfBusiness'>
          & { proposalConfiguration?: Maybe<(
            { __typename?: 'ProposalConfiguration' }
            & Pick<ProposalConfiguration, 'id' | 'configuration'>
          )> }
        )> }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'name' | 'lineOfBusiness'>
          & { proposalConfiguration?: Maybe<(
            { __typename?: 'ProposalConfiguration' }
            & Pick<ProposalConfiguration, 'id' | 'configuration'>
          )> }
        )> }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'name' | 'lineOfBusiness'>
          & { proposalConfiguration?: Maybe<(
            { __typename?: 'ProposalConfiguration' }
            & Pick<ProposalConfiguration, 'id' | 'configuration'>
          )> }
        )> }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'name' | 'lineOfBusiness'>
          & { proposalConfiguration?: Maybe<(
            { __typename?: 'ProposalConfiguration' }
            & Pick<ProposalConfiguration, 'id' | 'configuration'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export type PanelsQueryVariables = Exact<{
  onlyVisible?: Maybe<Scalars['Boolean']>;
}>;


export type PanelsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panels?: Maybe<(
          { __typename?: 'PanelConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Panel' }
            & Pick<Panel, 'id' | 'name' | 'lineOfBusiness' | 'configuration' | 'displayTax'>
          )>>> }
        )> }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panels?: Maybe<(
          { __typename?: 'PanelConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Panel' }
            & Pick<Panel, 'id' | 'name' | 'lineOfBusiness' | 'configuration' | 'displayTax'>
          )>>> }
        )> }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panels?: Maybe<(
          { __typename?: 'PanelConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Panel' }
            & Pick<Panel, 'id' | 'name' | 'lineOfBusiness' | 'configuration' | 'displayTax'>
          )>>> }
        )> }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id'>
        & { panels?: Maybe<(
          { __typename?: 'PanelConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Panel' }
            & Pick<Panel, 'id' | 'name' | 'lineOfBusiness' | 'configuration' | 'displayTax'>
          )>>> }
        )> }
      ) }
    )> }
  ) }
);

export type EditQuoteSchemaQueryVariables = Exact<{
  participationId: Scalars['ID'];
  quoteId: Scalars['ID'];
}>;


export type EditQuoteSchemaQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { participation?: Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { submission: (
        { __typename?: 'CyberSubmission' }
        & Pick<CyberSubmission, 'id'>
      ) | (
        { __typename?: 'PanelSubmission' }
        & Pick<PanelSubmission, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id'>
          & { schemas: (
            { __typename?: 'PanelsSchema' }
            & Pick<PanelsSchema, 'editQuote'>
          ) }
        )> }
      ) | (
        { __typename?: 'PCGSubmission' }
        & Pick<PcgSubmission, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesSubmission' }
        & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
      ) }
    )> }
  ) }
);

export type EditMultilineSchemaQueryVariables = Exact<{
  submissionId: Scalars['ID'];
}>;


export type EditMultilineSchemaQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { schemas: (
      { __typename?: 'MultilinePanelsSchema' }
      & Pick<MultilinePanelsSchema, 'edit'>
    ), submission?: Maybe<(
      { __typename?: 'PanelSubmission' }
      & Pick<PanelSubmission, 'id'>
      & PanelSubmissionInfoFragment
    )> }
  ) }
);

export type EditSchemaQueryVariables = Exact<{
  panelId: Scalars['ID'];
  submissionId: Scalars['ID'];
}>;


export type EditSchemaQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'country' | 'lineOfBusiness'>
      & { schemas: (
        { __typename?: 'PanelsSchema' }
        & Pick<PanelsSchema, 'edit'>
      ) }
    )>, submission?: Maybe<(
      { __typename?: 'PanelSubmission' }
      & Pick<PanelSubmission, 'id'>
      & PanelSubmissionInfoFragment
    )> }
  ) }
);

export type GetCarrierQuoteQueryVariables = Exact<{
  participationId: Scalars['ID'];
  quotationId: Scalars['ID'];
}>;


export type GetCarrierQuoteQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { participation?: Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { externalCarrier?: Maybe<(
        { __typename?: 'ExternalCarrier' }
        & Pick<ExternalCarrier, 'id' | 'name'>
      )>, quote?: Maybe<(
        { __typename?: 'CyberQuotation' }
        & Pick<CyberQuotation, 'id'>
        & PanelQuotationInfo_CyberQuotation_Fragment
      ) | (
        { __typename?: 'PCGQuotation' }
        & Pick<PcgQuotation, 'id'>
        & PanelQuotationInfo_PcgQuotation_Fragment
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesQuotation' }
        & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
        & PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
      ) | (
        { __typename?: 'PanelsQuotation' }
        & Pick<PanelsQuotation, 'id'>
        & PanelQuotationInfo_PanelsQuotation_Fragment
      ) | (
        { __typename?: 'MLQuotation' }
        & Pick<MlQuotation, 'id'>
        & PanelQuotationInfo_MlQuotation_Fragment
      ) | (
        { __typename?: 'TerrorismQuotation' }
        & Pick<TerrorismQuotation, 'id'>
        & PanelQuotationInfo_TerrorismQuotation_Fragment
      ) | (
        { __typename?: 'BOPQuotation' }
        & Pick<BopQuotation, 'id'>
        & PanelQuotationInfo_BopQuotation_Fragment
      )> }
    )> }
  ) }
);

export type NewCarrierQuoteSchemaQueryVariables = Exact<{
  participationId: Scalars['ID'];
}>;


export type NewCarrierQuoteSchemaQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { participation?: Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { schemas?: Maybe<(
        { __typename?: 'ParticipationsSchema' }
        & Pick<ParticipationsSchema, 'newCarrierQuote'>
      )> }
    )> }
  ) }
);

export type NewQuoteSchemaQueryVariables = Exact<{
  panelId: Scalars['ID'];
  submissionId: Scalars['ID'];
}>;


export type NewQuoteSchemaQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'country'>
      & { schemas: (
        { __typename?: 'PanelsSchema' }
        & Pick<PanelsSchema, 'newManualQuote'>
      ) }
    )> }
  ) }
);

export type NewSubmissionSchemaQueryVariables = Exact<{
  panelId: Scalars['ID'];
}>;


export type NewSubmissionSchemaQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'country' | 'lineOfBusiness'>
      & { schemas: (
        { __typename?: 'PanelsSchema' }
        & Pick<PanelsSchema, 'new'>
      ) }
    )> }
  ) }
);

export type PanelParticipationQueryVariables = Exact<{
  participationId: Scalars['ID'];
}>;


export type PanelParticipationQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { participation?: Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { user?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      )>, submission: (
        { __typename?: 'CyberSubmission' }
        & Pick<CyberSubmission, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'lineOfBusiness' | 'manualSubjectivities'>
        )> }
      ) | (
        { __typename?: 'PanelSubmission' }
        & Pick<PanelSubmission, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'lineOfBusiness'>
        )> }
      ) | (
        { __typename?: 'PCGSubmission' }
        & Pick<PcgSubmission, 'id'>
        & { panel?: Maybe<(
          { __typename?: 'Panel' }
          & Pick<Panel, 'id' | 'lineOfBusiness'>
        )> }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesSubmission' }
        & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
      ) }
    )> }
  ) }
);

export type PanelsQuotationQueryVariables = Exact<{
  participationId: Scalars['ID'];
}>;


export type PanelsQuotationQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { participation?: Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>>, owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ), user?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      )>, discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & MessagesFragment
      )>, submission: (
        { __typename?: 'CyberSubmission' }
        & Pick<CyberSubmission, 'id'>
      ) | (
        { __typename?: 'PanelSubmission' }
        & Pick<PanelSubmission, 'id'>
        & PanelSubmissionInfoFragment
      ) | (
        { __typename?: 'PCGSubmission' }
        & Pick<PcgSubmission, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesSubmission' }
        & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
      ), quotations: (
        { __typename?: 'QuotationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CyberQuotation' }
          & Pick<CyberQuotation, 'id'>
          & PanelQuotationInfo_CyberQuotation_Fragment
        ) | (
          { __typename?: 'PCGQuotation' }
          & Pick<PcgQuotation, 'id'>
          & PanelQuotationInfo_PcgQuotation_Fragment
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesQuotation' }
          & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          & PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
        ) | (
          { __typename?: 'PanelsQuotation' }
          & Pick<PanelsQuotation, 'id'>
          & PanelQuotationInfo_PanelsQuotation_Fragment
        ) | (
          { __typename?: 'MLQuotation' }
          & Pick<MlQuotation, 'id'>
          & PanelQuotationInfo_MlQuotation_Fragment
        ) | (
          { __typename?: 'TerrorismQuotation' }
          & Pick<TerrorismQuotation, 'id'>
          & PanelQuotationInfo_TerrorismQuotation_Fragment
        ) | (
          { __typename?: 'BOPQuotation' }
          & Pick<BopQuotation, 'id'>
          & PanelQuotationInfo_BopQuotation_Fragment
        )>>> }
      ) }
    )> }
  ) }
);

export type PanelSubmissionQueryVariables = Exact<{
  submissionId: Scalars['ID'];
}>;


export type PanelSubmissionQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { submission?: Maybe<(
      { __typename?: 'PanelSubmission' }
      & Pick<PanelSubmission, 'id'>
      & PanelSubmissionInfoFragment
    )> }
  ) }
);

export type EditPcgSubmissionSchemaQueryVariables = Exact<{
  panelId: Scalars['ID'];
  submissionId: Scalars['ID'];
}>;


export type EditPcgSubmissionSchemaQuery = (
  { __typename?: 'Query' }
  & { pcg: (
    { __typename?: 'PCG' }
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'country'>
      & { schemas: (
        { __typename?: 'PanelsSchema' }
        & Pick<PanelsSchema, 'edit'>
      ) }
    )> }
  ) }
);

export type EditPcgQuoteSchemaQueryVariables = Exact<{
  panelId: Scalars['ID'];
  quoteId: Scalars['ID'];
}>;


export type EditPcgQuoteSchemaQuery = (
  { __typename?: 'Query' }
  & { pcg: (
    { __typename?: 'PCG' }
    & { panel?: Maybe<(
      { __typename?: 'Panel' }
      & Pick<Panel, 'id' | 'country'>
      & { schemas: (
        { __typename?: 'PanelsSchema' }
        & Pick<PanelsSchema, 'editQuote'>
      ) }
    )> }
  ) }
);

export type PcgExtractDocumentMutationVariables = Exact<{
  input: PcgExtractDocumentInput;
}>;


export type PcgExtractDocumentMutation = (
  { __typename?: 'Mutation' }
  & { PCGExtractDocument?: Maybe<(
    { __typename?: 'PCGExtractDocumentPayload' }
    & { submission: (
      { __typename?: 'PCGSubmission' }
      & Pick<PcgSubmission, 'id'>
      & PcgSubmissionInfoFragment
    ) }
  )> }
);

export type GetPcgQuoteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPcgQuoteQuery = (
  { __typename?: 'Query' }
  & { pcg: (
    { __typename?: 'PCG' }
    & { quotation?: Maybe<(
      { __typename?: 'PCGQuotation' }
      & Pick<PcgQuotation, 'id' | 'currentPolicy' | 'status' | 'declinationReason' | 'customCarrier'>
      & { carrier?: Maybe<(
        { __typename?: 'ExternalCarrier' }
        & Pick<ExternalCarrier, 'id' | 'name'>
      )>, panelist?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      )>, review?: Maybe<(
        { __typename?: 'PCGReview' }
        & Pick<PcgReview, 'totalPremium'>
        & { quotes?: Maybe<Array<(
          { __typename?: 'PCGReviewStatusType' }
          & Pick<PcgReviewStatusType, 'totalPremium' | 'status'>
          & { quote?: Maybe<(
            { __typename?: 'PCGReviewQuoteType' }
            & Pick<PcgReviewQuoteType, 'text' | 'icon'>
          )> }
        )>> }
      )>, quotedLines?: Maybe<(
        { __typename?: 'PCGQuotedLineTypeConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'PCGHomeQuotedLineType' }
          & Pick<PcgHomeQuotedLineType, 'allOtherPerilDeductible' | 'totalPremium' | 'id' | 'type'>
          & { coverages?: Maybe<Array<(
            { __typename?: 'PCGHomeQuotedLineCoveragesType' }
            & Pick<PcgHomeQuotedLineCoveragesType, 'coverage'>
            & { homeCoverageLimit?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )>, deductible?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )> }
          )>>, enhancements?: Maybe<Array<(
            { __typename?: 'PCGHomeQuotedLineEnhancementsType' }
            & Pick<PcgHomeQuotedLineEnhancementsType, 'enhancement'>
            & { limit?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )>, deductible?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )>, premium?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )> }
          )>>, additionalOptions?: Maybe<Array<(
            { __typename?: 'PCGAdditionalOptionsType' }
            & Pick<PcgAdditionalOptionsType, 'option'>
            & { deductible?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )>, premium?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'value' | 'type'>
            )> }
          )>>, additionalFeatures?: Maybe<Array<(
            { __typename?: 'PCGAdditionalFeature' }
            & Pick<PcgAdditionalFeature, 'feature' | 'included'>
          )>> }
        ) | (
          { __typename?: 'PCGValuablesQuotedLineType' }
          & Pick<PcgValuablesQuotedLineType, 'totalPremium' | 'id' | 'type'>
          & { coverages?: Maybe<Array<(
            { __typename?: 'PCGValuablesQuotedLineCoveragesType' }
            & Pick<PcgValuablesQuotedLineCoveragesType, 'name' | 'limit' | 'blanketLimit'>
          )>>, additionalFeatures?: Maybe<Array<(
            { __typename?: 'PCGAdditionalFeature' }
            & Pick<PcgAdditionalFeature, 'feature' | 'included'>
          )>> }
        ) | (
          { __typename?: 'PCGAutoQuotedLineType' }
          & Pick<PcgAutoQuotedLineType, 'combinedLimit' | 'bodilyInjuryAndPropertyDamage' | 'bodilyInjuryPerPerson' | 'bodilyInjuryEachAccident' | 'propertyDamage' | 'totalPremium' | 'id' | 'type'>
          & { agreedValues?: Maybe<Array<(
            { __typename?: 'PCGAutoAgreedValuesType' }
            & Pick<PcgAutoAgreedValuesType, 'vehicle' | 'value'>
          )>>, medicalPayments?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, underinsuredMotorists?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, extendedTowingAndLabor?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, fullGlassCoverage?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, additionalCoverages?: Maybe<Array<(
            { __typename?: 'PCGAutoCoverageLimitsType' }
            & Pick<PcgAutoCoverageLimitsType, 'name'>
            & { limit?: Maybe<(
              { __typename?: 'MultiInputDropdownType' }
              & Pick<MultiInputDropdownType, 'type' | 'value'>
            )> }
          )>>, collisionDeductible?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, comprehensiveDeductible?: Maybe<(
            { __typename?: 'MultiInputDropdownType' }
            & Pick<MultiInputDropdownType, 'type' | 'value'>
          )>, additionalFeatures?: Maybe<Array<(
            { __typename?: 'PCGAutoAdditionalFeaturesType' }
            & Pick<PcgAutoAdditionalFeaturesType, 'feature' | 'included'>
          )>> }
        ) | (
          { __typename?: 'PCGExcessQuotedLineType' }
          & Pick<PcgExcessQuotedLineType, 'totalPremium' | 'id' | 'type'>
          & { coverages?: Maybe<Array<(
            { __typename?: 'PCGExcessQuotedLineCoveragesType' }
            & Pick<PcgExcessQuotedLineCoveragesType, 'coverage' | 'limit'>
          )>>, limitOptions?: Maybe<Array<(
            { __typename?: 'PCGExcessLimitOptionsType' }
            & Pick<PcgExcessLimitOptionsType, 'coverage' | 'limit' | 'totalPremium'>
          )>> }
        )>>> }
      )> }
    )> }
  ) }
);

export type GetPcgSubmissionQueryVariables = Exact<{
  submissionId: Scalars['ID'];
}>;


export type GetPcgSubmissionQuery = (
  { __typename?: 'Query' }
  & { pcg: (
    { __typename?: 'PCG' }
    & { submission?: Maybe<(
      { __typename?: 'PCGSubmission' }
      & Pick<PcgSubmission, 'id' | 'schemas' | 'name' | 'policyStartDate' | 'policyEndDate' | 'message' | 'emailSignOff' | 'placementTermsAccepted'>
      & { applicants?: Maybe<Array<(
        { __typename?: 'PCGApplicant' }
        & Pick<PcgApplicant, 'id' | 'firstName' | 'lastName'>
      )>>, selectedCarriers: Array<(
        { __typename?: 'EmailCarrierType' }
        & Pick<EmailCarrierType, 'id' | 'agentCode'>
      )>, documents: Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>, review: (
        { __typename?: 'PCGSubmissionReview' }
        & Pick<PcgSubmissionReview, 'applicants' | 'additionalDrivers' | 'coverages'>
      ), quotations?: Maybe<(
        { __typename?: 'PCGQuotationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'PCGQuotation' }
          & Pick<PcgQuotation, 'id' | 'selected' | 'totalPremium'>
          & { carrier?: Maybe<(
            { __typename?: 'ExternalCarrier' }
            & Pick<ExternalCarrier, 'id' | 'name'>
          )> }
        )>>> }
      )>, coverages?: Maybe<Array<(
        { __typename?: 'PCGAutoCoverageType' }
        & Pick<PcgAutoCoverageType, 'drivers' | 'id' | 'type'>
        & { vehicles?: Maybe<Array<(
          { __typename?: 'PCGAutoVehiclesType' }
          & Pick<PcgAutoVehiclesType, 'year' | 'make' | 'model' | 'value'>
        )>>, additionalDrivers?: Maybe<Array<(
          { __typename?: 'PCGAutoAdditionalDriversType' }
          & Pick<PcgAutoAdditionalDriversType, 'firstName' | 'lastName' | 'relationship'>
        )>> }
      ) | (
        { __typename?: 'PCGExcessCoverageType' }
        & Pick<PcgExcessCoverageType, 'id' | 'type'>
      ) | (
        { __typename?: 'PCGHomeCoverageType' }
        & Pick<PcgHomeCoverageType, 'id' | 'type'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'streetLine1' | 'streetLine2' | 'city' | 'zipCode' | 'state' | 'country'>
        )> }
      ) | (
        { __typename?: 'PCGValuablesCoverageType' }
        & Pick<PcgValuablesCoverageType, 'id' | 'type'>
      )>> }
    )> }
  ) }
);

export type PcgSubmissionQueryVariables = Exact<{
  submissionId: Scalars['ID'];
}>;


export type PcgSubmissionQuery = (
  { __typename?: 'Query' }
  & { pcg: (
    { __typename?: 'PCG' }
    & { submission?: Maybe<(
      { __typename?: 'PCGSubmission' }
      & Pick<PcgSubmission, 'id'>
      & PcgSubmissionInfoFragment
    )> }
  ) }
);

export type PermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { permissions: (
        { __typename?: 'PermissionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id' | 'area' | 'action' | 'metadata'>
        )>>> }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { permissions: (
        { __typename?: 'PermissionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id' | 'area' | 'action' | 'metadata'>
        )>>> }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { permissions: (
        { __typename?: 'PermissionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id' | 'area' | 'action' | 'metadata'>
        )>>> }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { permissions: (
        { __typename?: 'PermissionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id' | 'area' | 'action' | 'metadata'>
        )>>> }
      ) }
    )> }
  ) }
);

export type ProvidePolicyFormPrefillQueryQueryVariables = Exact<{
  worksheetId: Scalars['ID'];
}>;


export type ProvidePolicyFormPrefillQueryQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & { insurancePolicy?: Maybe<(
          { __typename?: 'InsurancePolicy' }
          & Pick<InsurancePolicy, 'id' | 'number'>
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & { insurancePolicy?: Maybe<(
          { __typename?: 'InsurancePolicy' }
          & Pick<InsurancePolicy, 'id' | 'number'>
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type QuestionsQueryVariables = Exact<{
  questionableId: Scalars['ID'];
  questionableType: QuestionableType;
}>;


export type QuestionsQuery = (
  { __typename?: 'Query' }
  & { qAndA: (
    { __typename?: 'QAndA' }
    & { questionable?: Maybe<(
      { __typename: 'Participation' }
      & Pick<Participation, 'id'>
      & { questions: (
        { __typename?: 'QuestionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Question' }
          & Pick<Question, 'id'>
          & QuestionInfoFragment
        )>>> }
      ) }
    ) | (
      { __typename: 'Quote' }
      & Pick<Quote, 'id'>
      & { questions: (
        { __typename?: 'QuestionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Question' }
          & Pick<Question, 'id'>
          & QuestionInfoFragment
        )>>> }
      ) }
    ) | (
      { __typename: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & { questions: (
        { __typename?: 'QuestionConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Question' }
          & Pick<Question, 'id'>
          & QuestionInfoFragment
        )>>> }
      ) }
    )> }
  ) }
);

export type QuotationCompanyQueryVariables = Exact<{
  participationId: Scalars['ID'];
}>;


export type QuotationCompanyQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { participation?: Maybe<(
        { __typename?: 'Participation' }
        & Pick<Participation, 'id'>
        & { submission: (
          { __typename?: 'CyberSubmission' }
          & Pick<CyberSubmission, 'id'>
          & { owner: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) }
        ) | (
          { __typename?: 'PanelSubmission' }
          & Pick<PanelSubmission, 'id'>
          & { owner: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) }
        ) | (
          { __typename?: 'PCGSubmission' }
          & Pick<PcgSubmission, 'id'>
          & { owner: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) }
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesSubmission' }
          & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
          & { owner: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) }
        ) }
      )> }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type QuoteCertificateNumberQueryVariables = Exact<{
  quoteId: Scalars['ID'];
}>;


export type QuoteCertificateNumberQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id' | 'certificateNumber'>
      )> }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id' | 'certificateNumber'>
        & { certificateDraft?: Maybe<(
          { __typename?: 'Document' }
          & Pick<Document, 'id'>
          & DocumentFragment
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type QuoteCompanyQueryVariables = Exact<{
  quoteId: Scalars['ID'];
}>;


export type QuoteCompanyQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id'>
        & { worksheet: (
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id' | 'kind'>
          & { owner: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) }
        ) }
      )> }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type PendingTemplateImportQueryVariables = Exact<{
  quoteId: Scalars['ID'];
}>;


export type PendingTemplateImportQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { reinsurer?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id'>
        & { templateImports: (
          { __typename?: 'TemplateImportConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'TemplateImport' }
            & Pick<TemplateImport, 'id' | 'completedAt' | 'error'>
            & { template: (
              { __typename?: 'Template' }
              & Pick<Template, 'id' | 'name'>
            ) }
          )>>> }
        ) }
        & QuoteInfoFragmentFragment
      )> }
    )> }
  ) }
);

export type QuoteImportProgressQueryVariables = Exact<{
  importId: Scalars['ID'];
}>;


export type QuoteImportProgressQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { templateImport?: Maybe<(
        { __typename?: 'TemplateImport' }
        & Pick<TemplateImport, 'id' | 'error' | 'completedAt'>
        & { importable?: Maybe<(
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        ) | (
          { __typename?: 'Quote' }
          & Pick<Quote, 'id'>
        )>, template: (
          { __typename?: 'Template' }
          & Pick<Template, 'id' | 'name'>
        ) }
      )> }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { templateImport?: Maybe<(
        { __typename?: 'TemplateImport' }
        & Pick<TemplateImport, 'id' | 'error' | 'completedAt'>
        & { importable?: Maybe<(
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        ) | (
          { __typename?: 'Quote' }
          & Pick<Quote, 'id'>
        )>, template: (
          { __typename?: 'Template' }
          & Pick<Template, 'id' | 'name'>
        ) }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { templateImport?: Maybe<(
        { __typename?: 'TemplateImport' }
        & Pick<TemplateImport, 'id' | 'error' | 'completedAt'>
        & { importable?: Maybe<(
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        ) | (
          { __typename?: 'Quote' }
          & Pick<Quote, 'id'>
        )>, template: (
          { __typename?: 'Template' }
          & Pick<Template, 'id' | 'name'>
        ) }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { templateImport?: Maybe<(
        { __typename?: 'TemplateImport' }
        & Pick<TemplateImport, 'id' | 'error' | 'completedAt'>
        & { importable?: Maybe<(
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        ) | (
          { __typename?: 'Quote' }
          & Pick<Quote, 'id'>
        )>, template: (
          { __typename?: 'Template' }
          & Pick<Template, 'id' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type QuoteInfoQueryVariables = Exact<{
  quoteId: Scalars['ID'];
}>;


export type QuoteInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id'>
        & { endorsementQuotes?: Maybe<Array<(
          { __typename?: 'EndorsementQuote' }
          & Pick<EndorsementQuote, 'id' | 'status' | 'additionalPremium' | 'returnPremium'>
          & { schemas?: Maybe<(
            { __typename?: 'EndorsementQuotesSchema' }
            & Pick<EndorsementQuotesSchema, 'sentEndorsementQuote' | 'requestedEndorsementQuote'>
          )>, documents: (
            { __typename?: 'DocumentConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'Document' }
              & Pick<Document, 'id'>
              & DocumentFragment
            )>>> }
          ), endorsement: (
            { __typename?: 'Endorsement' }
            & Pick<Endorsement, 'id'>
            & EndorsementInfoFragment
          ) }
        )>>, worksheet: (
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id' | 'updatedAt' | 'currency' | 'multiTower' | 'canEndorse'>
          & { insurancePolicy?: Maybe<(
            { __typename?: 'InsurancePolicy' }
            & Pick<InsurancePolicy, 'id' | 'number'>
          )>, owner: (
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName' | 'features'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName' | 'features'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName' | 'features'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'features'>
            & { company: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name' | 'defaultBrand' | 'brandColor' | 'brandName' | 'logoUrl'>
            ) }
          ) }
        ) }
        & QuoteInfoFragmentFragment
      )> }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id'>
        & { worksheet: (
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
          & WorksheetInfoFragment
        ) }
        & QuoteInfoFragmentFragment
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id'>
        & { worksheet: (
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
          & WorksheetInfoFragment
        ) }
        & QuoteInfoFragmentFragment
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type QuoteLockedQueryVariables = Exact<{
  worksheetId: Scalars['ID'];
  quoteId: Scalars['ID'];
}>;


export type QuoteLockedQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { quote?: Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id'>
        & QuoteLockedFragment
      )> }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & { quote?: Maybe<(
          { __typename?: 'Quote' }
          & Pick<Quote, 'id'>
          & QuoteLockedFragment
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type QuoteLockedFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id'>
  & { revisions: (
    { __typename?: 'RevisionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'number'>
      & { zip?: Maybe<(
        { __typename?: 'Document' }
        & Pick<Document, 'id' | 'url'>
      )> }
    )>>> }
  ), reinsurer?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'fullName'>
  )> }
);

export type ReinsuranceCompaniesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type ReinsuranceCompaniesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { reinsuranceCompanies: Array<(
      { __typename?: 'ReinsuranceCompany' }
      & Pick<ReinsuranceCompany, 'id' | 'name' | 'disabled'>
    )> }
  ) }
);

export type RenewalInfoQueryVariables = Exact<{
  worksheetId: Scalars['ID'];
}>;


export type RenewalInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id' | 'name' | 'kind'>
        & { stages: (
          { __typename?: 'WorksheetStatus' }
          & Pick<WorksheetStatus, 'draft' | 'issued'>
        ), insurancePolicy?: Maybe<(
          { __typename?: 'InsurancePolicy' }
          & Pick<InsurancePolicy, 'id'>
          & { coverages: (
            { __typename?: 'PolicyCoverageConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'CasualtyCoverage' }
              & Pick<CasualtyCoverage, 'id' | 'type' | 'kind'>
            ) | (
              { __typename?: 'PropertyCoverage' }
              & Pick<PropertyCoverage, 'id' | 'type' | 'kind'>
            )>>> }
          ) }
        )>, previousPlacement?: Maybe<(
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id' | 'name'>
        )>, renewal?: Maybe<(
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        )> }
      )>, company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id' | 'name' | 'kind'>
        & { stages: (
          { __typename?: 'WorksheetStatus' }
          & Pick<WorksheetStatus, 'draft' | 'issued'>
        ), insurancePolicy?: Maybe<(
          { __typename?: 'InsurancePolicy' }
          & Pick<InsurancePolicy, 'id'>
          & { coverages: (
            { __typename?: 'PolicyCoverageConnection' }
            & { nodes?: Maybe<Array<Maybe<(
              { __typename?: 'CasualtyCoverage' }
              & Pick<CasualtyCoverage, 'id' | 'type' | 'kind'>
            ) | (
              { __typename?: 'PropertyCoverage' }
              & Pick<PropertyCoverage, 'id' | 'type' | 'kind'>
            )>>> }
          ) }
        )>, previousPlacement?: Maybe<(
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id' | 'name'>
        )>, renewal?: Maybe<(
          { __typename?: 'Worksheet' }
          & Pick<Worksheet, 'id'>
        )> }
      )>, company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type ReportFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportFiltersQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { lastAmbestImport: (
      { __typename?: 'AMBestImport' }
      & Pick<AmBestImport, 'id' | 'importedAt'>
    ), user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { users: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
          )>>> }
        ), authorizedMarkets: (
          { __typename?: 'AuthorizedMarketConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'AuthorizedMarket' }
            & Pick<AuthorizedMarket, 'id' | 'color'>
            & { reinsuranceCompany: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & Pick<Report, 'lineOfBusinesses' | 'markets'>
      )>, company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { users: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
          )>>> }
        ), authorizedMarkets: (
          { __typename?: 'AuthorizedMarketConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'AuthorizedMarket' }
            & Pick<AuthorizedMarket, 'id' | 'color'>
            & { reinsuranceCompany: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & Pick<Report, 'lineOfBusinesses' | 'markets'>
      )>, company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { users: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
          )>>> }
        ), authorizedMarkets: (
          { __typename?: 'AuthorizedMarketConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'AuthorizedMarket' }
            & Pick<AuthorizedMarket, 'id' | 'color'>
            & { reinsuranceCompany: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { users: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName'>
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName'>
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName'>
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
          )>>> }
        ), authorizedMarkets: (
          { __typename?: 'AuthorizedMarketConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'AuthorizedMarket' }
            & Pick<AuthorizedMarket, 'id' | 'color'>
            & { reinsuranceCompany: (
              { __typename?: 'Company' }
              & Pick<Company, 'id' | 'name'>
            ) }
          )>>> }
        ) }
      ) }
    )> }
  ) }
);

export type AmBestImportTimeQueryVariables = Exact<{ [key: string]: never; }>;


export type AmBestImportTimeQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { lastAmbestImport: (
      { __typename?: 'AMBestImport' }
      & Pick<AmBestImport, 'id' | 'importedAt'>
    ) }
  ) }
);

export type AverageBoundTimeQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AverageBoundTimeQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { averageBoundTime?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { averageBoundTime?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type AverageReplyTimeQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AverageReplyTimeQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { averageReplyTime?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { averageReplyTime?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type BoundCoverageQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type BoundCoverageQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { boundCoverage?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { boundCoverage?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type BoundCoverageByRatingQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type BoundCoverageByRatingQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { boundCoverageByRating?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { boundCoverageByRating?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type SubmitToQuoteQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SubmitToQuoteQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { submitToQuote?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { submitToQuote?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type ExposureReportQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ExposureReportQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { exposureReportData?: Maybe<(
          { __typename?: 'ExposureReportDataTypeConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ExposureReportDataType' }
            & Pick<ExposureReportDataType, 'reinsurerCompanyName' | 'boundCoverage' | 'totalPremium' | 'rating'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { exposureReportData?: Maybe<(
          { __typename?: 'ExposureReportDataTypeConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ExposureReportDataType' }
            & Pick<ExposureReportDataType, 'reinsurerCompanyName' | 'boundCoverage' | 'rating' | 'totalPremium'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type NetPerMillionQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type NetPerMillionQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { netPerMillion?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { netPerMillion?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type PerformanceReportQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PerformanceReportQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { performanceReportData?: Maybe<(
          { __typename?: 'PerformanceReportDataConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'PerformanceReportData' }
            & Pick<PerformanceReportData, 'reinsurerCompanyName' | 'averageReplyTime' | 'averageBoundTime' | 'submitToBind' | 'quoteToBind' | 'submitToQuote' | 'declination'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { performanceReportData?: Maybe<(
          { __typename?: 'PerformanceReportDataConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'PerformanceReportData' }
            & Pick<PerformanceReportData, 'reinsurerCompanyName' | 'averageReplyTime' | 'averageBoundTime' | 'submitToBind' | 'quoteToBind' | 'submitToQuote' | 'declination'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type QuoteToBindQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type QuoteToBindQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { quoteToBind?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { quoteToBind?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type SubmitToBindQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SubmitToBindQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { submitToBind?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { submitToBind?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type DeclinationQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DeclinationQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { declination?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { declination?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type TopMarketsQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type TopMarketsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { topMarketReportData?: Maybe<(
          { __typename?: 'TopMarketDataTypeConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'TopMarketDataType' }
            & Pick<TopMarketDataType, 'reinsurerCompanyName' | 'netPerMillion'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { topMarketReportData?: Maybe<(
          { __typename?: 'TopMarketDataTypeConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'TopMarketDataType' }
            & Pick<TopMarketDataType, 'reinsurerCompanyName' | 'netPerMillion'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type TotalPremiumQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type TotalPremiumQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { totalPremium?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { totalPremium?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type TotalPremiumByRatingQueryVariables = Exact<{
  dateRange?: Maybe<DateRangeInput>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ownerId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type TotalPremiumByRatingQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { totalPremiumByRating?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { reports?: Maybe<(
        { __typename?: 'Report' }
        & { totalPremiumByRating?: Maybe<(
          { __typename?: 'ReportSeriesConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ReportSeries' }
            & Pick<ReportSeries, 'key' | 'value'>
          )>>> }
        )> }
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type ReportsSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportsSummaryQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { lastAmbestImport: (
      { __typename?: 'AMBestImport' }
      & Pick<AmBestImport, 'id' | 'importedAt'>
    ), user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { dashboard: (
        { __typename?: 'Dashboard' }
        & { submissions: (
          { __typename?: 'DashboardSubmissions' }
          & Pick<DashboardSubmissions, 'total'>
        ), quotes: (
          { __typename?: 'DashboardQuotes' }
          & Pick<DashboardQuotes, 'total'>
        ), binders: (
          { __typename?: 'DashboardBinders' }
          & Pick<DashboardBinders, 'total'>
        ), certificates: (
          { __typename?: 'DashboardCertificates' }
          & Pick<DashboardCertificates, 'total'>
        ) }
      ), worksheets: (
        { __typename?: 'WorksheetConnection' }
        & ReportSummaryDetailsFragment
      ), analytics: (
        { __typename?: 'Analytics' }
        & Pick<Analytics, 'averageReplyRate' | 'averageResponseTime' | 'averagePlacementTime' | 'totalPremiumsPaid'>
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { worksheets: (
        { __typename?: 'WorksheetConnection' }
        & ReportSummaryDetailsFragment
      ), analytics: (
        { __typename?: 'Analytics' }
        & Pick<Analytics, 'averageReplyRate' | 'averageResponseTime' | 'averagePlacementTime' | 'totalCommission' | 'totalRequested' | 'totalQuotes' | 'totalBinder' | 'totalCertificate'>
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type ReportSummaryDetailsFragment = (
  { __typename?: 'WorksheetConnection' }
  & { nodes?: Maybe<Array<Maybe<(
    { __typename?: 'Worksheet' }
    & Pick<Worksheet, 'id' | 'name'>
    & { owner: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'fullName'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
    ), insurancePolicy?: Maybe<(
      { __typename?: 'InsurancePolicy' }
      & Pick<InsurancePolicy, 'id'>
      & { coverages: (
        { __typename?: 'PolicyCoverageConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CasualtyCoverage' }
          & Pick<CasualtyCoverage, 'id' | 'type'>
        ) | (
          { __typename?: 'PropertyCoverage' }
          & Pick<PropertyCoverage, 'id' | 'type'>
        )>>> }
      ) }
    )>, completedQuotes: (
      { __typename?: 'QuoteConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id' | 'averagePlacementTime' | 'averageResponseTime' | 'averageReplyRate'>
        & { analysis?: Maybe<(
          { __typename?: 'QuoteAnalysis' }
          & Pick<QuoteAnalysis, 'id' | 'premiumPerMillion'>
        )>, reinsurer?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
            & { ambestRating?: Maybe<(
              { __typename?: 'AMBestRating' }
              & Pick<AmBestRating, 'id' | 'rating' | 'naicCompanyNumber'>
            )> }
          ) }
        )>, latestRevision: (
          { __typename?: 'RevisionConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Revision' }
            & Pick<Revision, 'id' | 'participation' | 'layerLimit' | 'attachmentPoint' | 'isIncomplete' | 'premium'>
            & { quotedLayers: Array<(
              { __typename?: 'QuotedLayer' }
              & Pick<QuotedLayer, 'id'>
              & QuotedLayerInfoFragment
            )> }
          )>>> }
        ) }
      )>>> }
    ) }
  )>>> }
);

export type RinkAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type RinkAccountQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { rinkAccount: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { networkContacts: (
          { __typename?: 'AccountConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Account' }
            & Pick<Account, 'id'>
            & NetworkContactFragment
          )>>> }
        ), networkConnectionInvitesReceived: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id'>
            & { user1: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ), networkConnectionInvitesSent: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id' | 'createdAt'>
            & { user2: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ) }
        & ProfileFragment
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { rinkAccount: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { networkContacts: (
          { __typename?: 'AccountConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Account' }
            & Pick<Account, 'id'>
            & NetworkContactFragment
          )>>> }
        ), networkConnectionInvitesReceived: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id'>
            & { user1: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ), networkConnectionInvitesSent: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id' | 'createdAt'>
            & { user2: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ) }
        & ProfileFragment
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { rinkAccount: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { networkContacts: (
          { __typename?: 'AccountConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Account' }
            & Pick<Account, 'id'>
            & NetworkContactFragment
          )>>> }
        ), networkConnectionInvitesReceived: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id'>
            & { user1: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ), networkConnectionInvitesSent: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id' | 'createdAt'>
            & { user2: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ) }
        & ProfileFragment
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { rinkAccount: (
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { networkContacts: (
          { __typename?: 'AccountConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Account' }
            & Pick<Account, 'id'>
            & NetworkContactFragment
          )>>> }
        ), networkConnectionInvitesReceived: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id'>
            & { user1: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ), networkConnectionInvitesSent: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id' | 'createdAt'>
            & { user2: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ) }
        & ProfileFragment
      ) }
    )> }
  ) }
);

export type RinkProfilesQueryVariables = Exact<{
  profileIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
}>;


export type RinkProfilesQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { rinkProfiles?: Maybe<(
      { __typename?: 'AccountConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Account' }
        & Pick<Account, 'id'>
        & { networkConnectionInvitesSent: (
          { __typename?: 'ConnectionRINKConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'ConnectionRINK' }
            & Pick<ConnectionRink, 'id' | 'createdAt' | 'message'>
            & { user1: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ), user2: (
              { __typename?: 'Account' }
              & Pick<Account, 'id'>
              & NetworkContactFragment
            ) }
          )>>> }
        ) }
        & ProfileFragment
      )>>> }
    )> }
  ) }
);

export type SubjectivitiesQueryVariables = Exact<{
  subjectivitableId: Scalars['ID'];
  subjectivitableType: SubjectivitableType;
}>;


export type SubjectivitiesQuery = (
  { __typename?: 'Query' }
  & { qAndA: (
    { __typename?: 'QAndA' }
    & { subjectivitable?: Maybe<(
      { __typename: 'Participation' }
      & Pick<Participation, 'id'>
      & { subjectivities: (
        { __typename?: 'SubjectivityConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Subjectivity' }
          & Pick<Subjectivity, 'id'>
          & SubjectivityInfoFragment
        )>>> }
      ) }
    ) | (
      { __typename: 'Quote' }
      & Pick<Quote, 'id'>
      & { subjectivities: (
        { __typename?: 'SubjectivityConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Subjectivity' }
          & Pick<Subjectivity, 'id'>
          & SubjectivityInfoFragment
        )>>> }
      ) }
    ) | (
      { __typename: 'NFPRepsAndWarrantiesQuotation' }
      & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
      & { subjectivities: (
        { __typename?: 'SubjectivityConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'Subjectivity' }
          & Pick<Subjectivity, 'id'>
          & SubjectivityInfoFragment
        )>>> }
      ) }
    )> }
  ) }
);

export type SubmissionQueryVariables = Exact<{
  worksheetId: Scalars['ID'];
}>;


export type SubmissionQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & SubmissionInfoFragment
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & SubmissionInfoFragment
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type TeamQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { users: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { users: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { users: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          )>>> }
        ) }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
        & { users: (
          { __typename?: 'UserConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Reinsurer' }
            & Pick<Reinsurer, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'Cedent' }
            & Pick<Cedent, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'Broker' }
            & Pick<Broker, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          ) | (
            { __typename?: 'NFPRepsAndWarrantiesBroker' }
            & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'avatarUrl' | 'jobTitle' | 'disabled'>
            & { manager?: Maybe<(
              { __typename?: 'Reinsurer' }
              & Pick<Reinsurer, 'id'>
            ) | (
              { __typename?: 'Cedent' }
              & Pick<Cedent, 'id'>
            ) | (
              { __typename?: 'Broker' }
              & Pick<Broker, 'id'>
            ) | (
              { __typename?: 'NFPRepsAndWarrantiesBroker' }
              & Pick<NfpRepsAndWarrantiesBroker, 'id'>
            )> }
          )>>> }
        ) }
      ) }
    )> }
  ) }
);

export type CompanySettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanySettingsQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'incomingEmailAddress' | 'emailSubmissionResponse'>
        & { emailSubmissionsManager?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'email'>
        )>, intakeUser?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'email'>
        )> }
      ) }
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'incomingEmailAddress' | 'emailSubmissionResponse'>
        & { emailSubmissionsManager?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'email'>
        )>, intakeUser?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'email'>
        )> }
      ) }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'incomingEmailAddress' | 'emailSubmissionResponse'>
        & { emailSubmissionsManager?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'email'>
        )>, intakeUser?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'email'>
        )> }
      ) }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'incomingEmailAddress' | 'emailSubmissionResponse'>
        & { emailSubmissionsManager?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'email'>
        )>, intakeUser?: Maybe<(
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName' | 'email'>
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName' | 'email'>
        )> }
      ) }
    )> }
  ) }
);

export type TerrorismQuotationQueryVariables = Exact<{
  participationId: Scalars['ID'];
}>;


export type TerrorismQuotationQuery = (
  { __typename?: 'Query' }
  & { panels: (
    { __typename?: 'Panels' }
    & Pick<Panels, 'id'>
    & { participation?: Maybe<(
      { __typename?: 'Participation' }
      & Pick<Participation, 'id'>
      & { documents?: Maybe<Array<(
        { __typename?: 'Document' }
        & Pick<Document, 'id'>
        & DocumentFragment
      )>>, owner: (
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id'>
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id'>
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      ), user?: Maybe<(
        { __typename?: 'Reinsurer' }
        & Pick<Reinsurer, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'Cedent' }
        & Pick<Cedent, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'Broker' }
        & Pick<Broker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesBroker' }
        & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name' | 'logoUrl'>
        ) }
      )>, discussion?: Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id'>
        & MessagesFragment
      )>, submission: (
        { __typename?: 'CyberSubmission' }
        & Pick<CyberSubmission, 'id'>
      ) | (
        { __typename?: 'PanelSubmission' }
        & Pick<PanelSubmission, 'id'>
        & PanelSubmissionInfoFragment
      ) | (
        { __typename?: 'PCGSubmission' }
        & Pick<PcgSubmission, 'id'>
      ) | (
        { __typename?: 'NFPRepsAndWarrantiesSubmission' }
        & Pick<NfpRepsAndWarrantiesSubmission, 'id'>
      ), quotations: (
        { __typename?: 'QuotationConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CyberQuotation' }
          & Pick<CyberQuotation, 'id'>
          & PanelQuotationInfo_CyberQuotation_Fragment
        ) | (
          { __typename?: 'PCGQuotation' }
          & Pick<PcgQuotation, 'id'>
          & PanelQuotationInfo_PcgQuotation_Fragment
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesQuotation' }
          & Pick<NfpRepsAndWarrantiesQuotation, 'id'>
          & PanelQuotationInfo_NfpRepsAndWarrantiesQuotation_Fragment
        ) | (
          { __typename?: 'PanelsQuotation' }
          & Pick<PanelsQuotation, 'id'>
          & PanelQuotationInfo_PanelsQuotation_Fragment
        ) | (
          { __typename?: 'MLQuotation' }
          & Pick<MlQuotation, 'id'>
          & PanelQuotationInfo_MlQuotation_Fragment
        ) | (
          { __typename?: 'TerrorismQuotation' }
          & Pick<TerrorismQuotation, 'id'>
          & PanelQuotationInfo_TerrorismQuotation_Fragment
        ) | (
          { __typename?: 'BOPQuotation' }
          & Pick<BopQuotation, 'id'>
          & PanelQuotationInfo_BopQuotation_Fragment
        )>>> }
      ) }
    )> }
  ) }
);

export type UserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { features?: Maybe<(
      { __typename?: 'Features' }
      & { list: Array<(
        { __typename?: 'Feature' }
        & Pick<Feature, 'name' | 'enabled'>
      )> }
    )>, user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
      & UserInfo_Reinsurer_Fragment
      & Permissions_Reinsurer_Fragment
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & UserInfo_Cedent_Fragment
      & Permissions_Cedent_Fragment
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & UserInfo_Broker_Fragment
      & Permissions_Broker_Fragment
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
      & UserInfo_NfpRepsAndWarrantiesBroker_Fragment
      & Permissions_NfpRepsAndWarrantiesBroker_Fragment
    )> }
  ) }
);

export type WorksheetBrokerQueryVariables = Exact<{
  worksheetId: Scalars['ID'];
}>;


export type WorksheetBrokerQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & WorksheetBrokerInfoFragment
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id'>
        & WorksheetBrokerInfoFragment
      )> }
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type WorksheetBrokerInfoFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id' | 'isBrokered' | 'brokeredOrInvited'>
  & { owner: (
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'fullName'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'Cedent' }
    & Pick<Cedent, 'id' | 'fullName'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'Broker' }
    & Pick<Broker, 'id' | 'fullName'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'NFPRepsAndWarrantiesBroker' }
    & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ) }
  ), collaborations: Array<(
    { __typename: 'BrokerInvite' }
    & Pick<BrokerInvite, 'id'>
    & { user: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'fullName'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
    ) }
  ) | (
    { __typename: 'BrokerCollaboration' }
    & Pick<BrokerCollaboration, 'id'>
    & { user: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'fullName'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
    ) }
  ) | (
    { __typename: 'ClientCollaboration' }
    & Pick<ClientCollaboration, 'id'>
    & { user: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'fullName'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
    ) }
  ) | (
    { __typename: 'MgaCollaboration' }
    & Pick<MgaCollaboration, 'id'>
    & { user: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'fullName'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
    ) }
  ) | (
    { __typename: 'WholesalerCollaboration' }
    & Pick<WholesalerCollaboration, 'id'>
    & { user: (
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id' | 'fullName'>
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id' | 'fullName'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
    ) }
  )> }
);

export type WorksheetInfoQueryVariables = Exact<{
  worksheetId: Scalars['ID'];
}>;


export type WorksheetInfoQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'Viewer' }
    & { user?: Maybe<(
      { __typename?: 'Reinsurer' }
      & Pick<Reinsurer, 'id'>
    ) | (
      { __typename?: 'Cedent' }
      & Pick<Cedent, 'id'>
      & { worksheet?: Maybe<(
        { __typename?: 'Worksheet' }
        & Pick<Worksheet, 'id' | 'kind' | 'status' | 'demo' | 'isQuotable' | 'participationComment'>
        & { owner: (
          { __typename?: 'Reinsurer' }
          & Pick<Reinsurer, 'id' | 'fullName'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
          ) }
        ) | (
          { __typename?: 'Cedent' }
          & Pick<Cedent, 'id' | 'fullName'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
          ) }
        ) | (
          { __typename?: 'Broker' }
          & Pick<Broker, 'id' | 'fullName'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
          ) }
        ) | (
          { __typename?: 'NFPRepsAndWarrantiesBroker' }
          & Pick<NfpRepsAndWarrantiesBroker, 'id' | 'fullName'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
          ) }
        ), quotes: (
          { __typename?: 'QuoteConnection' }
          & { nodes?: Maybe<Array<Maybe<(
            { __typename?: 'Quote' }
            & Pick<Quote, 'id'>
            & QuotedQuoteFragment
          )>>> }
        ) }
        & WorksheetSummaryFragment
      )> }
    ) | (
      { __typename?: 'Broker' }
      & Pick<Broker, 'id'>
    ) | (
      { __typename?: 'NFPRepsAndWarrantiesBroker' }
      & Pick<NfpRepsAndWarrantiesBroker, 'id'>
    )> }
  ) }
);

export type QuotedQuoteFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id' | 'status' | 'stage'>
  & { worksheet: (
    { __typename?: 'Worksheet' }
    & Pick<Worksheet, 'id' | 'policyLimit' | 'perOccurrenceLimit'>
  ), reinsurer?: Maybe<(
    { __typename?: 'Reinsurer' }
    & Pick<Reinsurer, 'id' | 'companyName'>
    & Avatar_Reinsurer_Fragment
  )>, revisions: (
    { __typename?: 'RevisionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'layerLimit' | 'attachmentPoint' | 'participation' | 'premium' | 'netPremium' | 'expiryDate' | 'isIncomplete'>
      & { quotedLayers: Array<(
        { __typename?: 'QuotedLayer' }
        & Pick<QuotedLayer, 'id'>
        & QuotedLayerInfoFragment
      )> }
    )>>> }
  ) }
  & QuoteInformationPerilsFragment
);

export type QuoteInformationPerilsFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id'>
  & { worksheet: (
    { __typename?: 'Worksheet' }
    & Pick<Worksheet, 'id' | 'lineOfBusiness' | 'keyPerils' | 'exclusions'>
    & { insurancePolicy?: Maybe<(
      { __typename?: 'InsurancePolicy' }
      & Pick<InsurancePolicy, 'id'>
      & { coverages: (
        { __typename?: 'PolicyCoverageConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'CasualtyCoverage' }
          & Pick<CasualtyCoverage, 'id' | 'type'>
        ) | (
          { __typename?: 'PropertyCoverage' }
          & Pick<PropertyCoverage, 'id' | 'type'>
        )>>> }
      ) }
    )> }
  ), revisionPerils: (
    { __typename?: 'RevisionConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Revision' }
      & Pick<Revision, 'id' | 'keyPerils' | 'exclusions'>
      & { quotedLayers: Array<(
        { __typename?: 'QuotedLayer' }
        & Pick<QuotedLayer, 'id'>
        & QuotedLayerInfoFragment
      )> }
    )>>> }
  ) }
);

export type WorksheetSummaryFragment = (
  { __typename?: 'Worksheet' }
  & Pick<Worksheet, 'id' | 'description' | 'policyNumber' | 'reinsuranceEffectiveDate' | 'reinsuranceExpiryDate'>
  & { quotesForTower: (
    { __typename?: 'QuoteConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id'>
      & LayerFragment
    )>>> }
  ) }
  & TowerFragment
  & TowerSummaryFragment
);

export type CreateOrUpdateTemplateMutationVariables = Exact<{
  input: CreateOrUpdateTemplateInput;
}>;


export type CreateOrUpdateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateTemplate?: Maybe<(
    { __typename?: 'CreateOrUpdateTemplatePayload' }
    & Pick<CreateOrUpdateTemplatePayload, 'errors'>
    & { document: (
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    ) }
  )> }
);

export type UpdateTaggedStatusMutationVariables = Exact<{
  input: UpdateTaggedStatusInput;
}>;


export type UpdateTaggedStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateTaggedStatus?: Maybe<(
    { __typename?: 'UpdateTaggedStatusPayload' }
    & Pick<UpdateTaggedStatusPayload, 'errors'>
    & { document: (
      { __typename?: 'Document' }
      & Pick<Document, 'id'>
      & DocumentFragment
    ) }
  )> }
);

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Currency Scalar (e.g $ 1,000.00) */
  Currency: any;
  /** ISO 8601 Datetime */
  DateTime: any;
  /** ISO 8601 Date */
  Date: any;
  /** Longer text, such as would be shown in a text area */
  Text: any;
  /** Decimal Scalar (e.g 795.12364) */
  Decimal: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** JSON */
  JSON: any;
  /** A percentage (from 0 to 100) */
  Percentage: any;
  Upload: any;
  /** A valid email domain, transported as a string */
  EmailDomain: any;
  /** A valid local part of an email (the bit before the @), transported as a string */
  EmailLocalPart: any;
  /** A valid email, transported as a string */
  Email: any;
};


/** Autogenerated input type of AcceptAnswer */
export type AcceptAnswerInput = {
  answerId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AcceptAnswer */
export type AcceptAnswerPayload = {
  __typename?: 'AcceptAnswerPayload';
  answer: Answer;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<AnswerErrors>;
};

/** Autogenerated input type of AcceptBrokerInvite */
export type AcceptBrokerInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  inviteId: Scalars['ID'];
};

/** Autogenerated return type of AcceptBrokerInvite */
export type AcceptBrokerInvitePayload = {
  __typename?: 'AcceptBrokerInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  worksheet?: Maybe<Worksheet>;
};

/** Autogenerated input type of AcceptCertificate */
export type AcceptCertificateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of AcceptCertificate */
export type AcceptCertificatePayload = {
  __typename?: 'AcceptCertificatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of AcceptCompanyInvitation */
export type AcceptCompanyInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailDomain: Scalars['EmailDomain'];
  emailLocalPart: Scalars['EmailLocalPart'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  signupToken: Scalars['String'];
  skipOnboarding?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of AcceptCompanyInvitation */
export type AcceptCompanyInvitationPayload = {
  __typename?: 'AcceptCompanyInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of AcceptConnection */
export type AcceptConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of AcceptConnection */
export type AcceptConnectionPayload = {
  __typename?: 'AcceptConnectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  connection?: Maybe<ConnectionRink>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of AcceptEndorsementQuote */
export type AcceptEndorsementQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementQuoteId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  premiumAmount: Scalars['Currency'];
  premiumType: Scalars['String'];
  response: Scalars['String'];
};

/** Autogenerated return type of AcceptEndorsementQuote */
export type AcceptEndorsementQuotePayload = {
  __typename?: 'AcceptEndorsementQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementQuote?: Maybe<EndorsementQuote>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of AcceptInvite */
export type AcceptInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated return type of AcceptInvite */
export type AcceptInvitePayload = {
  __typename?: 'AcceptInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of AcceptProposal */
export type AcceptProposalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of AcceptProposal */
export type AcceptProposalPayload = {
  __typename?: 'AcceptProposalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of AcceptQuote */
export type AcceptQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of AcceptQuote */
export type AcceptQuotePayload = {
  __typename?: 'AcceptQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of AcceptTerms */
export type AcceptTermsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  isAgreed: Scalars['Boolean'];
};

/** Autogenerated return type of AcceptTerms */
export type AcceptTermsPayload = {
  __typename?: 'AcceptTermsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** Rink account type */
export type Account = {
  __typename?: 'Account';
  aboutMe?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  ignoredNetworkContacts: AccountConnection;
  industries?: Maybe<Array<Scalars['String']>>;
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  linesOfBusiness?: Maybe<Array<Scalars['String']>>;
  linkedinUrl?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['String']>>;
  networkConnectionInvitesReceived: ConnectionRinkConnection;
  networkConnectionInvitesSent: ConnectionRinkConnection;
  networkContacts: AccountConnection;
  phoneNumber: Scalars['String'];
  type: Scalars['String'];
};


/** Rink account type */
export type AccountIgnoredNetworkContactsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Rink account type */
export type AccountNetworkConnectionInvitesReceivedArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Rink account type */
export type AccountNetworkConnectionInvitesSentArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Rink account type */
export type AccountNetworkContactsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Account. */
export type AccountConnection = {
  __typename?: 'AccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Account>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: 'AccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Account>;
};

/** Autogenerated input type of AddDemoPlacements */
export type AddDemoPlacementsInput = {
  cedentEmail: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reinsurerEmails: Array<Scalars['String']>;
};

/** Autogenerated return type of AddDemoPlacements */
export type AddDemoPlacementsPayload = {
  __typename?: 'AddDemoPlacementsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of AddDemoQuotes */
export type AddDemoQuotesInput = {
  cedentEmails: Array<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  reinsurerEmail: Scalars['String'];
};

/** Autogenerated return type of AddDemoQuotes */
export type AddDemoQuotesPayload = {
  __typename?: 'AddDemoQuotesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Attributes for adding an authorized market to a sent worksheet */
export type AdditionalMarketInput = {
  /** Message for reinsurer providing the quote */
  personalMessage?: Maybe<Scalars['String']>;
  /** Message for reinsurer providing the quote */
  personalSubject?: Maybe<Scalars['String']>;
  /** The IDs of reinsurance requests on the worksheet to invite this reinsurer to */
  reinsuranceRequests: Array<Scalars['ID']>;
  /** The ID of the reinsurer providing the quote */
  reinsurerId: Scalars['ID'];
};

/** Address */
export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  fullAddress: Scalars['String'];
  full_address: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  state: Scalars['String'];
  state_code: Scalars['String'];
  streetLine1: Scalars['String'];
  streetLine2?: Maybe<Scalars['String']>;
  street_line1: Scalars['String'];
  street_line2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  zip_code: Scalars['String'];
};

/** Address Input Type */
export type AddressInputType = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** An import of data from AMBest */
export type AmBestImport = {
  __typename?: 'AMBestImport';
  id: Scalars['ID'];
  importedAt: Scalars['DateTime'];
};

/** An AMBest Rating */
export type AmBestRating = {
  __typename?: 'AMBestRating';
  companyNumber: Scalars['Int'];
  id: Scalars['ID'];
  naicCompanyNumber?: Maybe<Scalars['Int']>;
  rating: Scalars['String'];
};

/** Analytics object for users */
export type Analytics = {
  __typename?: 'Analytics';
  averagePlacementTime: Scalars['Int'];
  averageReplyRate: Scalars['Int'];
  averageResponseTime: Scalars['Int'];
  totalBinder: Scalars['Int'];
  totalBinderAwaitingResponse: Scalars['Int'];
  totalBinderRequested: Scalars['Int'];
  totalCertificate: Scalars['Int'];
  totalCommission: Scalars['Currency'];
  totalInsurancePolicyRequested: Scalars['Int'];
  totalPlacements: Scalars['Int'];
  totalPremiumsPaid: Scalars['Int'];
  totalProposalsDeclined: Scalars['Int'];
  totalQuotes: Scalars['Int'];
  totalQuotesAwaitingResponse: Scalars['Int'];
  totalQuotesCounterOffered: Scalars['Int'];
  totalQuotesRequested: Scalars['Int'];
  totalQuotesReviewed: Scalars['Int'];
  totalRequested: Scalars['Int'];
  totalSubmissions: Scalars['Int'];
};

/** Answer */
export type Answer = {
  __typename?: 'Answer';
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']>;
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  documents?: Maybe<Array<Document>>;
  id: Scalars['ID'];
  notificationEvents: NotificationEventConnection;
  question: QuestionBase;
  releasedAt?: Maybe<Scalars['Date']>;
  text: Scalars['Text'];
  updatedAt: Scalars['ISO8601DateTime'];
  version: Scalars['Int'];
};


/** Answer */
export type AnswerNotificationEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Errors for Answer */
export type AnswerErrors = {
  __typename?: 'AnswerErrors';
  acceptedAt?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentErrors>;
  id?: Maybe<Scalars['String']>;
  releasedAt?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** Attributes for Answer */
export type AnswerInput = {
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']>;
  documents?: Maybe<Array<DocumentInput>>;
  id?: Maybe<Scalars['ID']>;
  releasedAt?: Maybe<Scalars['ISO8601DateTime']>;
  text: Scalars['Text'];
};

/** Autogenerated input type of ApproveRevision */
export type ApproveRevisionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  revisionId: Scalars['ID'];
};

/** Autogenerated return type of ApproveRevision */
export type ApproveRevisionPayload = {
  __typename?: 'ApproveRevisionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  message?: Maybe<DirectMessage>;
  revision?: Maybe<Revision>;
};

/** An authorized market in Relay */
export type AuthorizedMarket = {
  __typename?: 'AuthorizedMarket';
  boilerplateText?: Maybe<Scalars['String']>;
  casualtyApprovedLimit?: Maybe<Scalars['Currency']>;
  cedingCompany: Company;
  color?: Maybe<Scalars['String']>;
  contacts: AuthorizedMarketContactConnection;
  dealSpecificExclusions?: Maybe<Array<Scalars['String']>>;
  defaultMessage?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  dueDiligences?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  propertyApprovedLimit?: Maybe<Scalars['Currency']>;
  reinsuranceCompany: Company;
  specialInstructions?: Maybe<Scalars['String']>;
  standardExclusions?: Maybe<Scalars['String']>;
};


/** An authorized market in Relay */
export type AuthorizedMarketContactsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for AuthorizedMarket. */
export type AuthorizedMarketConnection = {
  __typename?: 'AuthorizedMarketConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AuthorizedMarketEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AuthorizedMarket>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An authorized market contact in Relay */
export type AuthorizedMarketContact = {
  __typename?: 'AuthorizedMarketContact';
  authorizedMarket: AuthorizedMarket;
  description?: Maybe<Scalars['String']>;
  generalInbox: Scalars['Boolean'];
  id: Scalars['ID'];
  reinsurer: Reinsurer;
};

/** The connection type for AuthorizedMarketContact. */
export type AuthorizedMarketContactConnection = {
  __typename?: 'AuthorizedMarketContactConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AuthorizedMarketContactEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AuthorizedMarketContact>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AuthorizedMarketContactEdge = {
  __typename?: 'AuthorizedMarketContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AuthorizedMarketContact>;
};

/** An edge in a connection. */
export type AuthorizedMarketEdge = {
  __typename?: 'AuthorizedMarketEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AuthorizedMarket>;
};

/** An authorized market error in Relay */
export type AuthorizedMarketError = {
  __typename?: 'AuthorizedMarketError';
  authorizedMarket?: Maybe<Array<Scalars['String']>>;
  authorizedMarketContacts?: Maybe<Array<Array<Scalars['String']>>>;
};

/** Attributes for creating or updating an authorized market and its contacts */
export type AuthorizedMarketWithContactsInput = {
  casualtyApprovedLimit?: Maybe<Scalars['Currency']>;
  contacts: Array<ContactInput>;
  propertyApprovedLimit?: Maybe<Scalars['Currency']>;
  reinsuranceCompanyId?: Maybe<Scalars['ID']>;
  reinsuranceCompanyName?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
};

export type BasicAppetiteCaptureErrorType = {
  __typename?: 'BasicAppetiteCaptureErrorType';
  errors?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BopAppetiteErrors = {
  __typename?: 'BOPAppetiteErrors';
  attributes?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  submissionId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of BOPAppetite */
export type BopAppetiteInput = {
  attributes: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['String'];
};

/** Autogenerated return type of BOPAppetite */
export type BopAppetitePayload = {
  __typename?: 'BOPAppetitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<BopAppetiteErrors>;
  panels?: Maybe<Panels>;
};

/** BOP Coverage */
export type BopCoverage = Coverage & {
  __typename?: 'BOPCoverage';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type BopProduct = Product & {
  __typename?: 'BOPProduct';
  basic_appetite_capture_error?: Maybe<BasicAppetiteCaptureErrorType>;
  basic_appetite_data?: Maybe<Scalars['JSON']>;
  basic_form_values?: Maybe<Scalars['String']>;
  coverages: Array<BopCoverage>;
  created_at: Scalars['ISO8601DateTime'];
  custom_fields: Scalars['JSON'];
  deleted_at?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['ISO8601Date']>;
  enhanced_appetite_data?: Maybe<Scalars['JSON']>;
  enhanced_appetite_error?: Maybe<EnhancedAppetiteErrorType>;
  expiry_date?: Maybe<Scalars['ISO8601Date']>;
  fields?: Maybe<Scalars['JSON']>;
  form_current_state?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insurable_type?: Maybe<Scalars['String']>;
  insurer: Scalars['String'];
  ivans_basic_appetite?: Maybe<Scalars['JSON']>;
  ivans_enhanced_appetite?: Maybe<Scalars['JSON']>;
  number: Scalars['String'];
  panel?: Maybe<Panel>;
  parent_policy?: Maybe<InsurancePolicy>;
  policy_expiration_date?: Maybe<Scalars['ISO8601Date']>;
  policy_start_date?: Maybe<Scalars['ISO8601Date']>;
  premium?: Maybe<Scalars['Decimal']>;
  program_structure?: Maybe<Scalars['String']>;
  quotations?: Maybe<Array<Quotation>>;
  selected_panelists?: Maybe<Array<SelectedPanelistsType>>;
  self_insured_retention?: Maybe<Scalars['Decimal']>;
  skip_basic_appetite_capture?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['String']>;
  surplusLinesLicenseInfo?: Maybe<SurplusLinesType>;
  type?: Maybe<Scalars['String']>;
  underlying_policies: Array<InsurancePolicy>;
  updated_at: Scalars['ISO8601DateTime'];
  worksheet?: Maybe<Worksheet>;
};

/** BOP Quotation */
export type BopQuotation = NotificationRelatesTo & Quotation & {
  __typename?: 'BOPQuotation';
  admitted?: Maybe<Scalars['Boolean']>;
  ambestRating?: Maybe<AmBestRating>;
  apiData?: Maybe<Scalars['JSON']>;
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  binders: DocumentConnection;
  boundAt?: Maybe<Scalars['ISO8601DateTime']>;
  carrierName?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  createdAt: Scalars['ISO8601DateTime'];
  declinationReason?: Maybe<Scalars['String']>;
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  derivedData: Scalars['JSON'];
  disclaimer?: Maybe<Scalars['String']>;
  discussion?: Maybe<Discussion>;
  documents: Array<Document>;
  dynamic?: Maybe<Scalars['Boolean']>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  extractedAt?: Maybe<Scalars['ISO8601DateTime']>;
  failedAt?: Maybe<Scalars['ISO8601DateTime']>;
  fields: Scalars['JSON'];
  generalLiability?: Maybe<Scalars['JSON']>;
  generalLiabilityPremium?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  indication: Scalars['Boolean'];
  limit?: Maybe<Scalars['Currency']>;
  overallPropertyPremium?: Maybe<Scalars['JSON']>;
  owner: User;
  participation: Participation;
  policies: DocumentConnection;
  policyNumber?: Maybe<Scalars['String']>;
  portalUrl?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['JSON']>;
  questions: QuestionConnection;
  quotedAt?: Maybe<Scalars['ISO8601DateTime']>;
  quotedLines: Array<PanelsQuotedLineType>;
  requestedDeductible?: Maybe<Scalars['Currency']>;
  requestedLimit?: Maybe<Scalars['Currency']>;
  retailCommission?: Maybe<Scalars['Currency']>;
  retailCommissionPercentage?: Maybe<Scalars['Decimal']>;
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selected?: Maybe<Scalars['Boolean']>;
  selectedOptionId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  subjectivities: SubjectivityConnection;
  submission: PanelSubmission;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};


/** BOP Quotation */
export type BopQuotationBindersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** BOP Quotation */
export type BopQuotationPoliciesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** BOP Quotation */
export type BopQuotationQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** BOP Quotation */
export type BopQuotationSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BopReturnPreviousStateErrors = {
  __typename?: 'BOPReturnPreviousStateErrors';
  attributes?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  submissionId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of BOPReturnPreviousState */
export type BopReturnPreviousStateInput = {
  attributes: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['String'];
};

/** Autogenerated return type of BOPReturnPreviousState */
export type BopReturnPreviousStatePayload = {
  __typename?: 'BOPReturnPreviousStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<BopReturnPreviousStateErrors>;
  panels?: Maybe<Panels>;
};

/** A broadcast sent through Relay */
export type Broadcast = Message & NotificationRelatesTo & {
  __typename?: 'Broadcast';
  attachments: DocumentConnection;
  author: User;
  body: Scalars['String'];
  broadcastable: Broadcastable;
  documents: DocumentConnection;
  id: Scalars['ID'];
  subject: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: BroadcastType;
  worksheet?: Maybe<Worksheet>;
};


/** A broadcast sent through Relay */
export type BroadcastAttachmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A broadcast sent through Relay */
export type BroadcastDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A discussable entity in Relay */
export type Broadcastable = {
  broadcasts?: Maybe<BroadcastConnection>;
  id: Scalars['ID'];
};


/** A discussable entity in Relay */
export type BroadcastableBroadcastsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Broadcast. */
export type BroadcastConnection = {
  __typename?: 'BroadcastConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BroadcastEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Broadcast>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BroadcastEdge = {
  __typename?: 'BroadcastEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Broadcast>;
};

export enum BroadcastType {
  Broadcast = 'Broadcast',
  SubmissionUpdate = 'SubmissionUpdate'
}

/** Relay Broker */
export type Broker = NotificationRelatesTo & User & {
  __typename?: 'Broker';
  aboutMe?: Maybe<Scalars['String']>;
  analytics: Analytics;
  api: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  chatToken?: Maybe<Scalars['String']>;
  company: Company;
  /** @deprecated Company name is accessible through the related company object */
  companyName: Scalars['String'];
  dashboard: BrokerDashboardEntryConnection;
  demoData: Scalars['Boolean'];
  directReports: UserConnection;
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  emailSignOff?: Maybe<Scalars['Text']>;
  externallyManaged: Scalars['Boolean'];
  features: Scalars['JSON'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  generalInbox: Scalars['Boolean'];
  id: Scalars['ID'];
  indicationProvided?: Maybe<Scalars['Boolean']>;
  industries?: Maybe<Array<Scalars['String']>>;
  invitedBy?: Maybe<User>;
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  linesOfBusiness?: Maybe<Array<Scalars['String']>>;
  linkedinUrl?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['String']>>;
  manager?: Maybe<User>;
  mfaCorporateEmailRequired?: Maybe<Scalars['Boolean']>;
  mfaCorporateNetworksRequired?: Maybe<Scalars['Boolean']>;
  mfaRemoteNetworkAccessRequired?: Maybe<Scalars['Boolean']>;
  notificationEvents: NotificationEventConnection;
  notificationSettings?: Maybe<NotificationSettingsType>;
  /** @deprecated Replaced by the onboarding field */
  onboardedAt?: Maybe<Scalars['Date']>;
  onboarding?: Maybe<Scalars['String']>;
  participation?: Maybe<Participation>;
  pendingInvitation: Scalars['Boolean'];
  permissions: PermissionConnection;
  phoneNumber: Scalars['String'];
  placementsAvailable: Scalars['Boolean'];
  quote?: Maybe<Quote>;
  reports?: Maybe<Report>;
  rinkAccount: Account;
  role?: Maybe<Array<Scalars['String']>>;
  search: Array<Worksheet>;
  templateImport?: Maybe<TemplateImport>;
  templateImports: TemplateImportConnection;
  termsAndConditionsAcceptedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  uuid: Scalars['ID'];
  websiteOptional?: Maybe<Scalars['Boolean']>;
  worksheet?: Maybe<Worksheet>;
  worksheets: WorksheetConnection;
};


/** Relay Broker */
export type BrokerDashboardArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['String']>;
};


/** Relay Broker */
export type BrokerDirectReportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Broker */
export type BrokerNotificationEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Broker */
export type BrokerParticipationArgs = {
  participationId: Scalars['ID'];
};


/** Relay Broker */
export type BrokerPermissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Broker */
export type BrokerQuoteArgs = {
  quoteId: Scalars['ID'];
};


/** Relay Broker */
export type BrokerSearchArgs = {
  order?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


/** Relay Broker */
export type BrokerTemplateImportArgs = {
  importId: Scalars['ID'];
};


/** Relay Broker */
export type BrokerTemplateImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Broker */
export type BrokerWorksheetArgs = {
  worksheetId: Scalars['ID'];
};


/** Relay Broker */
export type BrokerWorksheetsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  brokerIds?: Maybe<Array<Scalars['ID']>>;
  coverageTypes?: Maybe<Array<Scalars['String']>>;
  first?: Maybe<Scalars['Int']>;
  includeDemo?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  withinDateRange?: Maybe<DateRangeInput>;
};

/** A broker working on a placement */
export type BrokerCollaboration = Collaboration & Discussable & {
  __typename?: 'BrokerCollaboration';
  collaborative: Collaborative;
  discussion?: Maybe<Discussion>;
  id: Scalars['ID'];
  status: Scalars['String'];
  type: Scalars['String'];
  user: User;
  /** @deprecated Migrating to using collaborative */
  worksheet: Worksheet;
};

/** The connection type for Broker. */
export type BrokerConnection = {
  __typename?: 'BrokerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BrokerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Broker>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** A placement on a broker's dashboard */
export type BrokerDashboardEntry = {
  __typename?: 'BrokerDashboardEntry';
  client: User;
  clientCompanyId?: Maybe<Scalars['ID']>;
  clientCompanyName?: Maybe<Scalars['String']>;
  commissionPercentage?: Maybe<Scalars['Decimal']>;
  demo: Scalars['Boolean'];
  effectiveDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  industryType?: Maybe<Array<Scalars['String']>>;
  isBrokered: Scalars['Boolean'];
  isInvited: Scalars['Boolean'];
  isRenewal: Scalars['Boolean'];
  keyPerils?: Maybe<Array<Scalars['String']>>;
  lineOfBusiness: LineOfBusiness;
  name: Scalars['String'];
  owner: User;
  ownerCompanyId: Scalars['ID'];
  ownerCompanyName: Scalars['String'];
  ownerId: Scalars['ID'];
  ownerName: Scalars['String'];
  policyLimit?: Maybe<Scalars['Currency']>;
  receivedAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  submission?: Maybe<Submission>;
  /** @deprecated Replaced by effective_date */
  targetDate?: Maybe<Scalars['Date']>;
  type: Scalars['String'];
  worksheet: Worksheet;
};

/** The connection type for BrokerDashboardEntry. */
export type BrokerDashboardEntryConnection = {
  __typename?: 'BrokerDashboardEntryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BrokerDashboardEntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BrokerDashboardEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BrokerDashboardEntryEdge = {
  __typename?: 'BrokerDashboardEntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BrokerDashboardEntry>;
};

/** An edge in a connection. */
export type BrokerEdge = {
  __typename?: 'BrokerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Broker>;
};

/** Broker invitation status type in Relay */
export enum BrokerInvitationStatusType {
  AcceptedByBroker = 'acceptedByBroker',
  AwaitingBrokerResponse = 'awaitingBrokerResponse',
  DeclinedByBroker = 'declinedByBroker'
}

/** An invitation to a broker to collaborate on a placement */
export type BrokerInvite = Collaboration & Discussable & {
  __typename?: 'BrokerInvite';
  collaborative: Collaborative;
  discussion?: Maybe<Discussion>;
  id: Scalars['ID'];
  status: Scalars['String'];
  type: Scalars['String'];
  user: User;
  /** @deprecated Migrating to using collaborative */
  worksheet: Worksheet;
};

export type CarrierAuthentication = {
  __typename?: 'CarrierAuthentication';
  default?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  nickname: Scalars['String'];
};

/** The connection type for CarrierAuthentication. */
export type CarrierAuthenticationConnection = {
  __typename?: 'CarrierAuthenticationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CarrierAuthenticationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CarrierAuthentication>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CarrierAuthenticationEdge = {
  __typename?: 'CarrierAuthenticationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CarrierAuthentication>;
};

/** A placement on a carrier's dashboard */
export type CarrierDashboardEntry = {
  __typename?: 'CarrierDashboardEntry';
  clientName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isBroker?: Maybe<Scalars['Boolean']>;
  keyPerils?: Maybe<Array<Scalars['String']>>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  outdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  ownerFirstName?: Maybe<Scalars['String']>;
  ownerId: Scalars['ID'];
  ownerLastName?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  quotation?: Maybe<Quotation>;
  quoteStatus?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['ISO8601DateTime']>;
  revision?: Maybe<Revision>;
  riskName?: Maybe<Scalars['String']>;
  typename?: Maybe<Scalars['Text']>;
  user: Reinsurer;
};

/** The connection type for CarrierDashboardEntry. */
export type CarrierDashboardEntryConnection = {
  __typename?: 'CarrierDashboardEntryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CarrierDashboardEntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CarrierDashboardEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CarrierDashboardEntryEdge = {
  __typename?: 'CarrierDashboardEntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CarrierDashboardEntry>;
};

/** Attributes to invite a carrier */
export type CarrierInvite = {
  id: Scalars['ID'];
  personalMessage?: Maybe<Scalars['String']>;
};

/** What a casualty insurance policy covers */
export type CasualtyCoverage = PolicyCoverage & {
  __typename?: 'CasualtyCoverage';
  aggregateLimit?: Maybe<Scalars['Currency']>;
  basis?: Maybe<PolicyCoverageBasis>;
  comment?: Maybe<Scalars['String']>;
  customFields: Array<Field>;
  deductible?: Maybe<Scalars['Currency']>;
  defenseIncludedInLimit?: Maybe<Scalars['Boolean']>;
  description: Scalars['String'];
  documents?: Maybe<DocumentConnection>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  exposure?: Maybe<Scalars['Currency']>;
  extendedReportingPeriod?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keyPerils?: Maybe<Array<Scalars['String']>>;
  kind?: Maybe<PolicyCoverageKind>;
  limit?: Maybe<Scalars['Currency']>;
  lines?: Maybe<Array<LineType>>;
  perOccurrenceLimit?: Maybe<Scalars['Currency']>;
  policy: InsurancePolicy;
  productsCompletedOpsAggregate?: Maybe<Scalars['Currency']>;
  ratingBasis?: Maybe<Scalars['String']>;
  retroactiveDate?: Maybe<Scalars['Date']>;
  stringDeductible?: Maybe<Scalars['String']>;
  type?: Maybe<LineOfBusiness>;
  underwriting?: Maybe<CasualtyUnderwriting>;
};


/** What a casualty insurance policy covers */
export type CasualtyCoverageDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The underwriting for a casualty policy */
export type CasualtyUnderwriting = {
  __typename?: 'CasualtyUnderwriting';
  additionalInformation?: Maybe<Scalars['String']>;
  currentExposure?: Maybe<CurrentExposureType>;
  currentExposureTotal?: Maybe<Scalars['Int']>;
  excessOfAmount?: Maybe<Scalars['Currency']>;
  garaging?: Maybe<Scalars['String']>;
  hiredNonOwnedExposure?: Maybe<Scalars['String']>;
  historicalExposure?: Maybe<Array<HistoricalExposureType>>;
  id: Scalars['ID'];
  largeLosses?: Maybe<Array<LargeLossesType>>;
  lossInformation?: Maybe<Array<LossInformationType>>;
  otherCurrentExposureTotal?: Maybe<Scalars['Int']>;
  radiusIntermediate?: Maybe<Scalars['Int']>;
  radiusLocal?: Maybe<Scalars['Int']>;
  radiusLongHaul?: Maybe<Scalars['Int']>;
};

/** Relay Cedent */
export type Cedent = NotificationRelatesTo & User & {
  __typename?: 'Cedent';
  aboutMe?: Maybe<Scalars['String']>;
  analytics: Analytics;
  api: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  chatToken?: Maybe<Scalars['String']>;
  company: Company;
  /** @deprecated Company name is accessible through the related company object */
  companyName: Scalars['String'];
  dashboard: Dashboard;
  dashboardEntries: CedentDashboardEntryConnection;
  demoData: Scalars['Boolean'];
  directReports: UserConnection;
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  emailSignOff?: Maybe<Scalars['Text']>;
  externallyManaged: Scalars['Boolean'];
  features: Scalars['JSON'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  generalInbox: Scalars['Boolean'];
  id: Scalars['ID'];
  indicationProvided?: Maybe<Scalars['Boolean']>;
  industries?: Maybe<Array<Scalars['String']>>;
  invitedBy?: Maybe<User>;
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  linesOfBusiness?: Maybe<Array<Scalars['String']>>;
  linkedinUrl?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['String']>>;
  manager?: Maybe<User>;
  mfaCorporateEmailRequired?: Maybe<Scalars['Boolean']>;
  mfaCorporateNetworksRequired?: Maybe<Scalars['Boolean']>;
  mfaRemoteNetworkAccessRequired?: Maybe<Scalars['Boolean']>;
  notificationEvents: NotificationEventConnection;
  notificationSettings?: Maybe<NotificationSettingsType>;
  /** @deprecated Replaced by the onboarding field */
  onboardedAt?: Maybe<Scalars['Date']>;
  onboarding?: Maybe<Scalars['String']>;
  pendingInvitation: Scalars['Boolean'];
  permissions: PermissionConnection;
  phoneNumber: Scalars['String'];
  placementsAvailable: Scalars['Boolean'];
  quote?: Maybe<Quote>;
  reinsurers: ReinsurerConnection;
  reports?: Maybe<Report>;
  rinkAccount: Account;
  role?: Maybe<Array<Scalars['String']>>;
  search: Array<Worksheet>;
  templateImport?: Maybe<TemplateImport>;
  templateImports: TemplateImportConnection;
  termsAndConditionsAcceptedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  uuid: Scalars['ID'];
  websiteOptional?: Maybe<Scalars['Boolean']>;
  worksheet?: Maybe<Worksheet>;
  worksheets: WorksheetConnection;
};


/** Relay Cedent */
export type CedentDashboardEntriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  quoteStatus?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


/** Relay Cedent */
export type CedentDirectReportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Cedent */
export type CedentNotificationEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Cedent */
export type CedentPermissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Cedent */
export type CedentQuoteArgs = {
  quoteId: Scalars['ID'];
};


/** Relay Cedent */
export type CedentReinsurersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Cedent */
export type CedentSearchArgs = {
  order?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


/** Relay Cedent */
export type CedentTemplateImportArgs = {
  importId: Scalars['ID'];
};


/** Relay Cedent */
export type CedentTemplateImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Cedent */
export type CedentWorksheetArgs = {
  worksheetId: Scalars['ID'];
};


/** Relay Cedent */
export type CedentWorksheetsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  cedentIds?: Maybe<Array<Scalars['ID']>>;
  coverageTypes?: Maybe<Array<Scalars['String']>>;
  first?: Maybe<Scalars['Int']>;
  includeDemo?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  withinDateRange?: Maybe<DateRangeInput>;
};

/** A placement on a cedent's dashboard */
export type CedentDashboardEntry = {
  __typename?: 'CedentDashboardEntry';
  attachmentPoint?: Maybe<Scalars['Decimal']>;
  bound?: Maybe<Scalars['Int']>;
  boundRatio?: Maybe<Scalars['Decimal']>;
  brokered?: Maybe<Scalars['Boolean']>;
  cedent: User;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  declined?: Maybe<Scalars['Int']>;
  demo?: Maybe<Scalars['Boolean']>;
  effectiveDate?: Maybe<Scalars['ISO8601Date']>;
  id?: Maybe<Scalars['ID']>;
  issued?: Maybe<Scalars['Int']>;
  keyPerils: Array<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  marketsInvited?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  ownerCompanyId?: Maybe<Scalars['Int']>;
  ownerId?: Maybe<Scalars['Int']>;
  ownerName?: Maybe<Scalars['Text']>;
  perOccurrenceLimit?: Maybe<Scalars['Decimal']>;
  policyLimit?: Maybe<Scalars['Decimal']>;
  policyType?: Maybe<Scalars['String']>;
  quoted?: Maybe<Scalars['Int']>;
  requested?: Maybe<Scalars['Int']>;
  responded?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  totalBound?: Maybe<Scalars['Decimal']>;
  totalRequested?: Maybe<Scalars['Decimal']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  worksheet: Worksheet;
  worksheetLineOfBusiness?: Maybe<Scalars['String']>;
};

/** The connection type for CedentDashboardEntry. */
export type CedentDashboardEntryConnection = {
  __typename?: 'CedentDashboardEntryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CedentDashboardEntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CedentDashboardEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CedentDashboardEntryEdge = {
  __typename?: 'CedentDashboardEntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CedentDashboardEntry>;
};

/** Coverage retained by the cedent on a given placement */
export type CedentRetention = {
  __typename?: 'CedentRetention';
  attachmentPoint: Scalars['Currency'];
  id: Scalars['ID'];
  layerLimit: Scalars['Currency'];
  participation: Scalars['Percentage'];
  worksheet: Worksheet;
};

/** The connection type for CedentRetention. */
export type CedentRetentionConnection = {
  __typename?: 'CedentRetentionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CedentRetentionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CedentRetention>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CedentRetentionEdge = {
  __typename?: 'CedentRetentionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CedentRetention>;
};

/** Autogenerated input type of ChangeOwner */
export type ChangeOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of ChangeOwner */
export type ChangeOwnerPayload = {
  __typename?: 'ChangeOwnerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

/** Autogenerated input type of ChangeParticipationOwner */
export type ChangeParticipationOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newOwnerId: Scalars['ID'];
  participationId: Scalars['ID'];
};

/** Autogenerated return type of ChangeParticipationOwner */
export type ChangeParticipationOwnerPayload = {
  __typename?: 'ChangeParticipationOwnerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  participation?: Maybe<Participation>;
};

/** Autogenerated input type of ChangeParticipationWithNewOwner */
export type ChangeParticipationWithNewOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newOwnerAttributes: TeamMemberInput;
  participationId: Scalars['ID'];
};

/** Autogenerated return type of ChangeParticipationWithNewOwner */
export type ChangeParticipationWithNewOwnerPayload = {
  __typename?: 'ChangeParticipationWithNewOwnerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  invitationToken?: Maybe<Scalars['String']>;
  participation?: Maybe<Participation>;
};

/** Autogenerated input type of ChangePassword */
export type ChangePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirmation: Scalars['String'];
};

/** Autogenerated return type of ChangePassword */
export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of ChangeQuoteOwner */
export type ChangeQuoteOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Autogenerated return type of ChangeQuoteOwner */
export type ChangeQuoteOwnerPayload = {
  __typename?: 'ChangeQuoteOwnerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of ChangeQuoteWithNewOwner */
export type ChangeQuoteWithNewOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newOwnerAttributes: TeamMemberInput;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of ChangeQuoteWithNewOwner */
export type ChangeQuoteWithNewOwnerPayload = {
  __typename?: 'ChangeQuoteWithNewOwnerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  invitationToken?: Maybe<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of ChangeReferenceNumber */
export type ChangeReferenceNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
  referenceNumber: Scalars['String'];
};

/** Autogenerated return type of ChangeReferenceNumber */
export type ChangeReferenceNumberPayload = {
  __typename?: 'ChangeReferenceNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of ChangeSubmissionOwner */
export type ChangeSubmissionOwnerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  newOwnerId: Scalars['ID'];
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of ChangeSubmissionOwner */
export type ChangeSubmissionOwnerPayload = {
  __typename?: 'ChangeSubmissionOwnerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  submission?: Maybe<Submission>;
};

/** Autogenerated input type of ClientApproval */
export type ClientApprovalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of ClientApproval */
export type ClientApprovalPayload = {
  __typename?: 'ClientApprovalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** A client collaborating with a broker on a placement */
export type ClientCollaboration = Collaboration & Discussable & {
  __typename?: 'ClientCollaboration';
  collaborative: Collaborative;
  discussion?: Maybe<Discussion>;
  id: Scalars['ID'];
  status: Scalars['String'];
  type: Scalars['String'];
  user: User;
  /** @deprecated Migrating to using collaborative */
  worksheet: Worksheet;
};

export enum ClientType {
  Corporation = 'CORPORATION',
  PrivateEquity = 'PRIVATE_EQUITY'
}

/** A user collaborating on a worksheet */
export type Collaboration = {
  collaborative: Collaborative;
  discussion?: Maybe<Discussion>;
  id: Scalars['ID'];
  status: Scalars['String'];
  type: Scalars['String'];
  user: User;
  /** @deprecated Migrating to using collaborative */
  worksheet: Worksheet;
};

/** The connection type for Collaboration. */
export type CollaborationConnection = {
  __typename?: 'CollaborationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CollaborationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Collaboration>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CollaborationEdge = {
  __typename?: 'CollaborationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Collaboration>;
};

/** A resource that can be collaborated on */
export type Collaborative = {
  collaborations: Array<Collaboration>;
  collaborators: Array<User>;
  id: Scalars['ID'];
};

/** Applicant for commercial insurance */
export type CommercialApplicant = {
  __typename?: 'CommercialApplicant';
  address?: Maybe<Address>;
  company_name?: Maybe<Scalars['String']>;
  created_at: Scalars['ISO8601DateTime'];
  deleted_at?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  fields: Scalars['JSON'];
  id: Scalars['ID'];
  naics_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['ISO8601DateTime'];
};

/** A company using Relay */
export type Company = {
  __typename?: 'Company';
  ambestRating?: Maybe<AmBestRating>;
  authorizedMarket?: Maybe<AuthorizedMarket>;
  authorizedMarkets: AuthorizedMarketConnection;
  brandColor?: Maybe<Scalars['String']>;
  brandName: Scalars['String'];
  brokers: BrokerConnection;
  clients: CompanyConnection;
  dealVisibility?: Maybe<Scalars['String']>;
  defaultAttachment?: Maybe<Scalars['Currency']>;
  defaultBrand?: Maybe<Scalars['String']>;
  emailLogoUrl?: Maybe<Scalars['String']>;
  emailSubmissionResponse: Scalars['String'];
  emailSubmissionsManager?: Maybe<User>;
  guest: Scalars['Boolean'];
  id: Scalars['ID'];
  inInsurance: Scalars['Boolean'];
  incomingEmailAddress?: Maybe<Scalars['String']>;
  intakeUser?: Maybe<User>;
  kind: CompanyKindType;
  licenseInformation?: Maybe<LicenseInformationType>;
  logoUrl?: Maybe<Scalars['String']>;
  marketFor: AuthorizedMarket;
  name: Scalars['String'];
  panel?: Maybe<Panel>;
  panels?: Maybe<PanelConnection>;
  pdfTemplates?: Maybe<PdfTemplateConnection>;
  retailBrokerages?: Maybe<RetailBrokerageConnection>;
  signupToken?: Maybe<Scalars['String']>;
  subscription: Subscription;
  supportEmail?: Maybe<Scalars['String']>;
  surplusLinesSchema: Scalars['JSON'];
  teams: TeamConnection;
  templates: TemplateConnection;
  /** @deprecated With the introduction of company kinds(e.g. WholesaleBrokerage), the intent of this fieldbecame unclear and ambiguous. Renamed to user_type to better indicate the use of a defaultuser type for new users. */
  type: CompanyType;
  user?: Maybe<User>;
  userType: CompanyUserType;
  users: UserConnection;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  wholesaleBrokerages?: Maybe<CompanyConnection>;
};


/** A company using Relay */
export type CompanyAuthorizedMarketArgs = {
  marketId: Scalars['ID'];
};


/** A company using Relay */
export type CompanyAuthorizedMarketsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A company using Relay */
export type CompanyBrokersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A company using Relay */
export type CompanyClientsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A company using Relay */
export type CompanyMarketForArgs = {
  companyId: Scalars['ID'];
};


/** A company using Relay */
export type CompanyPanelArgs = {
  panelId: Scalars['ID'];
};


/** A company using Relay */
export type CompanyPanelsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  onlyVisible?: Maybe<Scalars['Boolean']>;
};


/** A company using Relay */
export type CompanyPdfTemplatesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A company using Relay */
export type CompanyRetailBrokeragesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A company using Relay */
export type CompanyTeamsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A company using Relay */
export type CompanyTemplatesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A company using Relay */
export type CompanyUserArgs = {
  userId: Scalars['ID'];
};


/** A company using Relay */
export type CompanyUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A company using Relay */
export type CompanyWholesaleBrokeragesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Company. */
export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CompanyEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Company>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Company>;
};

/** Invitation */
export type CompanyInvitation = {
  __typename?: 'CompanyInvitation';
  brandColor?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  emailDomains?: Maybe<Array<Scalars['String']>>;
  host?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
};

/** A company type in Relay */
export enum CompanyKindType {
  Carrier = 'Carrier',
  Mga = 'MGA',
  RetailBrokerage = 'RetailBrokerage',
  WholesaleBrokerage = 'WholesaleBrokerage'
}

/** Company settings attributes */
export type CompanySettingsInput = {
  /** The message sent to external placement submitters */
  emailSubmissionResponse: Scalars['String'];
  /** The receipient for email submissions */
  emailSubmissionsManagerId?: Maybe<Scalars['ID']>;
  /** The intake user for submission */
  intakeUserId?: Maybe<Scalars['ID']>;
};

/** A company type in Relay */
export enum CompanyType {
  Broker = 'Broker',
  Cedent = 'Cedent',
  InsuranceBroker = 'InsuranceBroker',
  Insurer = 'Insurer',
  Mga = 'MGA',
  NfpRepsAndWarrantiesBroker = 'NFPRepsAndWarrantiesBroker',
  ReinsuranceBroker = 'ReinsuranceBroker',
  Reinsurer = 'Reinsurer',
  User = 'User'
}

/** The default type of users created on this company */
export enum CompanyUserType {
  Broker = 'Broker',
  Cedent = 'Cedent',
  InsuranceBroker = 'InsuranceBroker',
  Insurer = 'Insurer',
  Mga = 'MGA',
  NfpRepsAndWarrantiesBroker = 'NFPRepsAndWarrantiesBroker',
  ReinsuranceBroker = 'ReinsuranceBroker',
  Reinsurer = 'Reinsurer',
  User = 'User'
}

/** Autogenerated input type of CompleteEndorsement */
export type CompleteEndorsementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementId: Scalars['ID'];
};

/** Autogenerated return type of CompleteEndorsement */
export type CompleteEndorsementPayload = {
  __typename?: 'CompleteEndorsementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsement?: Maybe<Endorsement>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of CompleteEndorsementQuote */
export type CompleteEndorsementQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  endorsementQuoteId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CompleteEndorsementQuote */
export type CompleteEndorsementQuotePayload = {
  __typename?: 'CompleteEndorsementQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementQuote?: Maybe<EndorsementQuote>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of CompleteRolePlay */
export type CompleteRolePlayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CompleteRolePlay */
export type CompleteRolePlayPayload = {
  __typename?: 'CompleteRolePlayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

export type ComputerSystemType = {
  __typename?: 'ComputerSystemType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Autogenerated input type of ConfirmOutdatedQuote */
export type ConfirmOutdatedQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of ConfirmOutdatedQuote */
export type ConfirmOutdatedQuotePayload = {
  __typename?: 'ConfirmOutdatedQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** RINK Connection */
export type ConnectionRink = {
  __typename?: 'ConnectionRINK';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  message?: Maybe<Scalars['Text']>;
  status: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user1: Account;
  user2: Account;
};

/** The connection type for ConnectionRINK. */
export type ConnectionRinkConnection = {
  __typename?: 'ConnectionRINKConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ConnectionRinkEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ConnectionRink>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ConnectionRinkEdge = {
  __typename?: 'ConnectionRINKEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ConnectionRink>;
};

/** Attributes for recording contacts for authorized markets */
export type ContactInput = {
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  generalInbox?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CopyWorksheet */
export type CopyWorksheetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CopyWorksheet */
export type CopyWorksheetPayload = {
  __typename?: 'CopyWorksheetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

export type CounterfeitMoneyType = {
  __typename?: 'CounterfeitMoneyType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

export type Coverage = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** The connection type for Coverage. */
export type CoverageConnection = {
  __typename?: 'CoverageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CoverageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Coverage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CoverageEdge = {
  __typename?: 'CoverageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Coverage>;
};

/** Attributes for recording coverages on a worksheet */
export type CoverageInput = {
  attachmentPoint: Scalars['Currency'];
  cedingCommission?: Maybe<Scalars['Percentage']>;
  deductible?: Maybe<Scalars['Currency']>;
  layerLimit: Scalars['Currency'];
  /** A freeform note about this coverage */
  note?: Maybe<Scalars['String']>;
  participation: Scalars['Percentage'];
  proposedLayerPremium?: Maybe<Scalars['Currency']>;
  proposedNetLayerPremium?: Maybe<Scalars['Currency']>;
  /** The IDs of the reinsurers providing the quotes */
  reinsurerIds?: Maybe<Array<Scalars['ID']>>;
};

export enum CoverageType {
  CyberCrime = 'CyberCrime',
  FirstParty = 'FirstParty',
  ThirdParty = 'ThirdParty'
}

/** Autogenerated input type of CreateAndSendWorksheet */
export type CreateAndSendWorksheetInput = {
  attributes: WorksheetAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAndSendWorksheet */
export type CreateAndSendWorksheetPayload = {
  __typename?: 'CreateAndSendWorksheetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

/** Autogenerated input type of CreateAnswer */
export type CreateAnswerInput = {
  attributes: AnswerInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  questionId: Scalars['ID'];
};

/** Autogenerated return type of CreateAnswer */
export type CreateAnswerPayload = {
  __typename?: 'CreateAnswerPayload';
  answer?: Maybe<Answer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<AnswerErrors>;
};

/** Autogenerated input type of CreateAuthorizedMarketContact */
export type CreateAuthorizedMarketContactInput = {
  attributes: ContactInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAuthorizedMarketContact */
export type CreateAuthorizedMarketContactPayload = {
  __typename?: 'CreateAuthorizedMarketContactPayload';
  authorizedMarketContact?: Maybe<AuthorizedMarketContact>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: AuthorizedMarketError;
};

/** Autogenerated input type of CreateAuthorizedMarket */
export type CreateAuthorizedMarketInput = {
  attributes: AuthorizedMarketWithContactsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateAuthorizedMarket */
export type CreateAuthorizedMarketPayload = {
  __typename?: 'CreateAuthorizedMarketPayload';
  authorizedMarket?: Maybe<AuthorizedMarket>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: AuthorizedMarketError;
};

/** Autogenerated input type of CreateBinder */
export type CreateBinderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  files: Scalars['Upload'];
  message?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of CreateBinder */
export type CreateBinderPayload = {
  __typename?: 'CreateBinderPayload';
  certificateNumber?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  message?: Maybe<DirectMessage>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of CreateBinderWithQuote */
export type CreateBinderWithQuoteInput = {
  attachmentPoint?: Maybe<Scalars['Currency']>;
  brokerageCommission?: Maybe<Scalars['Percentage']>;
  cedingCommission?: Maybe<Scalars['Percentage']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentInput>>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  files: Scalars['Upload'];
  keyPerils?: Maybe<Array<Scalars['String']>>;
  layerLimit?: Maybe<Scalars['Currency']>;
  message?: Maybe<Scalars['String']>;
  netPremium?: Maybe<Scalars['Currency']>;
  participation?: Maybe<Scalars['Percentage']>;
  premium?: Maybe<Scalars['Currency']>;
  quoteId: Scalars['ID'];
  quotedLayers?: Maybe<Array<QuotedLayerInput>>;
  subjectivities?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of CreateBinderWithQuote */
export type CreateBinderWithQuotePayload = {
  __typename?: 'CreateBinderWithQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  message?: Maybe<DirectMessage>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of CreateCarrierPanelQuotation */
export type CreateCarrierPanelQuotationInput = {
  attributes: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  participationId: Scalars['ID'];
};

/** Autogenerated return type of CreateCarrierPanelQuotation */
export type CreateCarrierPanelQuotationPayload = {
  __typename?: 'CreateCarrierPanelQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotation: PanelsQuotation;
};

/** Autogenerated input type of CreateChargebeeCheckout */
export type CreateChargebeeCheckoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  period: Scalars['String'];
  plan: Scalars['String'];
};

/** Autogenerated return type of CreateChargebeeCheckout */
export type CreateChargebeeCheckoutPayload = {
  __typename?: 'CreateChargebeeCheckoutPayload';
  checkout?: Maybe<Scalars['JSON']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of CreateChargebeePortalSession */
export type CreateChargebeePortalSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateChargebeePortalSession */
export type CreateChargebeePortalSessionPayload = {
  __typename?: 'CreateChargebeePortalSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  session?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CreateEndorsement */
export type CreateEndorsementInput = {
  attributes: EndorsementInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of CreateEndorsement */
export type CreateEndorsementPayload = {
  __typename?: 'CreateEndorsementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsement?: Maybe<Endorsement>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of CreateFollowUpQuestion */
export type CreateFollowUpQuestionInput = {
  attributes: QuestionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  initialQuestionId: Scalars['ID'];
  ownerId: Scalars['ID'];
  questionableId: Scalars['ID'];
  questionableScope?: Maybe<QuestionableScope>;
  questionableType: QuestionableType;
};

/** Autogenerated return type of CreateFollowUpQuestion */
export type CreateFollowUpQuestionPayload = {
  __typename?: 'CreateFollowUpQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<QuestionErrors>;
  question: Question;
};

/** Autogenerated input type of CreateManualPanelQuotation */
export type CreateManualPanelQuotationInput = {
  attributes: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of CreateManualPanelQuotation */
export type CreateManualPanelQuotationPayload = {
  __typename?: 'CreateManualPanelQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotation: MlQuotation;
};

/** Autogenerated input type of CreateMultilinePanelSubmission */
export type CreateMultilinePanelSubmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  panelIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of CreateMultilinePanelSubmission */
export type CreateMultilinePanelSubmissionPayload = {
  __typename?: 'CreateMultilinePanelSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submission: PanelSubmission;
};

/** Autogenerated input type of CreateOrUpdateTemplate */
export type CreateOrUpdateTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document: DocumentInput;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of CreateOrUpdateTemplate */
export type CreateOrUpdateTemplatePayload = {
  __typename?: 'CreateOrUpdateTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document: Document;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of CreatePanelSubmission */
export type CreatePanelSubmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  panelId: Scalars['ID'];
};

/** Autogenerated return type of CreatePanelSubmission */
export type CreatePanelSubmissionPayload = {
  __typename?: 'CreatePanelSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submission: PanelSubmission;
};

/** Autogenerated input type of CreateQuestion */
export type CreateQuestionInput = {
  attributes: QuestionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ownerId: Scalars['ID'];
  questionableId: Scalars['ID'];
  questionableScope?: Maybe<QuestionableScope>;
  questionableType: QuestionableType;
};

/** Autogenerated return type of CreateQuestion */
export type CreateQuestionPayload = {
  __typename?: 'CreateQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<QuestionErrors>;
  question?: Maybe<Question>;
  subjectivity?: Maybe<Question>;
};

/** Autogenerated input type of CreateQuote */
export type CreateQuoteInput = {
  attachmentPoint?: Maybe<Scalars['Currency']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  files?: Maybe<Scalars['Upload']>;
  layerLimit?: Maybe<Scalars['Currency']>;
  participation?: Maybe<Scalars['Percentage']>;
  premium?: Maybe<Scalars['Currency']>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of CreateQuote */
export type CreateQuotePayload = {
  __typename?: 'CreateQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of CreateRevision */
export type CreateRevisionInput = {
  approveExtraction?: Maybe<Scalars['Boolean']>;
  attachmentPoint?: Maybe<Scalars['Currency']>;
  brokerageCommission?: Maybe<Scalars['Percentage']>;
  cedingCommission?: Maybe<Scalars['Percentage']>;
  certificateNumber?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<FieldInput>>;
  deductible?: Maybe<Scalars['Currency']>;
  documents?: Maybe<Array<DocumentInput>>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Scalars['Upload']>;
  keyPerils?: Maybe<Array<Scalars['String']>>;
  layerLimit?: Maybe<Scalars['Currency']>;
  message?: Maybe<Scalars['String']>;
  netPremium?: Maybe<Scalars['Currency']>;
  participation?: Maybe<Scalars['Percentage']>;
  premium?: Maybe<Scalars['Currency']>;
  quoteId: Scalars['ID'];
  quotedLayers?: Maybe<Array<QuotedLayerInput>>;
  reusedFiles?: Maybe<Array<UploadedDocumentInput>>;
  subjectivities?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of CreateRevision */
export type CreateRevisionPayload = {
  __typename?: 'CreateRevisionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  message?: Maybe<DirectMessage>;
  revision?: Maybe<Revision>;
};

/** Autogenerated input type of CreateWorksheet */
export type CreateWorksheetInput = {
  attributes: WorksheetDraftAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateWorksheet */
export type CreateWorksheetPayload = {
  __typename?: 'CreateWorksheetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};


/** Attributes for creating or updating current exposure on underwriting */
export type CurrentExposureInputType = {
  buses?: Maybe<Scalars['Int']>;
  heavy?: Maybe<Scalars['Int']>;
  light?: Maybe<Scalars['Int']>;
  medium?: Maybe<Scalars['Int']>;
  other?: Maybe<Scalars['Int']>;
  ppt?: Maybe<Scalars['Int']>;
  tractors?: Maybe<Scalars['Int']>;
  vans?: Maybe<Scalars['Int']>;
  xHeavy?: Maybe<Scalars['Int']>;
};

/** Current exposure object on auto_underwriting */
export type CurrentExposureType = {
  __typename?: 'CurrentExposureType';
  buses?: Maybe<Scalars['Int']>;
  heavy?: Maybe<Scalars['Int']>;
  light?: Maybe<Scalars['Int']>;
  medium?: Maybe<Scalars['Int']>;
  other?: Maybe<Scalars['Int']>;
  ppt?: Maybe<Scalars['Int']>;
  tractors?: Maybe<Scalars['Int']>;
  vans?: Maybe<Scalars['Int']>;
  xHeavy?: Maybe<Scalars['Int']>;
};

/** The query root for Cyber */
export type Cyber = {
  __typename?: 'Cyber';
  panel?: Maybe<Panel>;
  quote?: Maybe<CyberQuotation>;
  submission?: Maybe<CyberSubmission>;
  submissions?: Maybe<CyberSubmissionConnection>;
};


/** The query root for Cyber */
export type CyberPanelArgs = {
  panelId: Scalars['ID'];
};


/** The query root for Cyber */
export type CyberQuoteArgs = {
  id: Scalars['ID'];
};


/** The query root for Cyber */
export type CyberSubmissionArgs = {
  submissionId: Scalars['ID'];
};


/** The query root for Cyber */
export type CyberSubmissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Cyber Additional Feature */
export type CyberAdditionalFeature = {
  __typename?: 'CyberAdditionalFeature';
  description: Scalars['String'];
  title: Scalars['String'];
};

export type CyberAdjustQuotesErrors = {
  __typename?: 'CyberAdjustQuotesErrors';
  base?: Maybe<Scalars['String']>;
  carriers?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberAdjustQuotes */
export type CyberAdjustQuotesInput = {
  attributes: CyberSubmissionAdjustInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CyberAdjustQuotes */
export type CyberAdjustQuotesPayload = {
  __typename?: 'CyberAdjustQuotesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberAdjustQuotesErrors>;
  submission?: Maybe<CyberSubmission>;
};

/** Autogenerated input type of CyberArchiveQuote */
export type CyberArchiveQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
  unarchive?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CyberArchiveQuote */
export type CyberArchiveQuotePayload = {
  __typename?: 'CyberArchiveQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  quotation?: Maybe<CyberQuotation>;
};

/** Autogenerated input type of CyberBindQuote */
export type CyberBindQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  message?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of CyberBindQuote */
export type CyberBindQuotePayload = {
  __typename?: 'CyberBindQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  quotation?: Maybe<CyberQuotation>;
};

/** Cyber Coverage */
export type CyberCoverage = {
  __typename?: 'CyberCoverage';
  deductible?: Maybe<Scalars['Currency']>;
  limit?: Maybe<Scalars['Currency']>;
  name?: Maybe<Scalars['String']>;
  periodOfIndemnity?: Maybe<Scalars['String']>;
  type?: Maybe<CoverageType>;
  waitingPeriod?: Maybe<Scalars['String']>;
};

/** Cyber Coverage Attributes */
export type CyberCoverageInputType = {
  deductible?: Maybe<Scalars['Currency']>;
  limit?: Maybe<Scalars['Currency']>;
  name: Scalars['String'];
  type: CoverageType;
  waitingPeriod?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberCreateDraftSubmission */
export type CyberCreateDraftSubmissionInput = {
  attributes: CyberSubmissionDraftInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  panelId: Scalars['ID'];
};

/** Autogenerated return type of CyberCreateDraftSubmission */
export type CyberCreateDraftSubmissionPayload = {
  __typename?: 'CyberCreateDraftSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberSubmissionErrors>;
  submission?: Maybe<CyberSubmission>;
};

export type CyberCreateManualQuoteCoveragesErrors = {
  __typename?: 'CyberCreateManualQuoteCoveragesErrors';
  base?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CyberCreateManualQuoteEndorsementsErrors = {
  __typename?: 'CyberCreateManualQuoteEndorsementsErrors';
  base?: Maybe<Scalars['String']>;
  coinsurance?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CyberCreateManualQuoteErrors = {
  __typename?: 'CyberCreateManualQuoteErrors';
  admitted?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  carrierName?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  coverages?: Maybe<Array<CyberCreateManualQuoteCoveragesErrors>>;
  declinationReason?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['String']>;
  endorsements?: Maybe<Array<CyberCreateManualQuoteEndorsementsErrors>>;
  fee?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberCreateManualQuote */
export type CyberCreateManualQuoteInput = {
  attributes: CyberManualQuotationInputType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of CyberCreateManualQuote */
export type CyberCreateManualQuotePayload = {
  __typename?: 'CyberCreateManualQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberCreateManualQuoteErrors>;
  quotation?: Maybe<CyberQuotation>;
};

export type CyberCreateQuoteCoveragesErrors = {
  __typename?: 'CyberCreateQuoteCoveragesErrors';
  base?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CyberCreateQuoteEndorsementsErrors = {
  __typename?: 'CyberCreateQuoteEndorsementsErrors';
  base?: Maybe<Scalars['String']>;
  coinsurance?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CyberCreateQuoteErrors = {
  __typename?: 'CyberCreateQuoteErrors';
  admitted?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  coverages?: Maybe<Array<CyberCreateQuoteCoveragesErrors>>;
  deductible?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['String']>;
  endorsements?: Maybe<Array<CyberCreateQuoteEndorsementsErrors>>;
  fee?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberCreateQuote */
export type CyberCreateQuoteInput = {
  attributes: CyberQuotationInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  participationId: Scalars['ID'];
};

/** Autogenerated return type of CyberCreateQuote */
export type CyberCreateQuotePayload = {
  __typename?: 'CyberCreateQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberCreateQuoteErrors>;
  quotation?: Maybe<CyberQuotation>;
};

/** Autogenerated input type of CyberCreateShareableApplicationLink */
export type CyberCreateShareableApplicationLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Autogenerated return type of CyberCreateShareableApplicationLink */
export type CyberCreateShareableApplicationLinkPayload = {
  __typename?: 'CyberCreateShareableApplicationLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
  shareableLink?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberCreateShareableLink */
export type CyberCreateShareableLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  panelId: Scalars['ID'];
  useBrokerEmail?: Maybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of CyberCreateShareableLink */
export type CyberCreateShareableLinkPayload = {
  __typename?: 'CyberCreateShareableLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
  shareableLink?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberDeclineQuote */
export type CyberDeclineQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  participationId: Scalars['ID'];
  quotationId?: Maybe<Scalars['ID']>;
  reason?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CyberDeclineQuote */
export type CyberDeclineQuotePayload = {
  __typename?: 'CyberDeclineQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotation?: Maybe<CyberQuotation>;
};

/** Cyber Draft Submission Record */
export type CyberDraftSubmissionRecord = {
  annualRevenue?: Maybe<Scalars['String']>;
  brokerName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['Date']>;
  deductible?: Maybe<Scalars['Currency']>;
  dedupKey?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Currency']>;
  naicsCode?: Maybe<Scalars['String']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfRecords?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  websites?: Maybe<Array<Scalars['String']>>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Cyber Endorsement */
export type CyberEndorsement = {
  __typename?: 'CyberEndorsement';
  coinsurance?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Currency']>;
  name: Scalars['String'];
};

/** Cyber Endorsement Attributes */
export type CyberEndorsementInputType = {
  coinsurance?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Currency']>;
  name: Scalars['String'];
};

/** Autogenerated input type of CyberExportQuoteComparison */
export type CyberExportQuoteComparisonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  exportTimeZone?: Maybe<Scalars['String']>;
  exportType?: Maybe<Scalars['String']>;
  participationIds?: Maybe<Array<Scalars['ID']>>;
  quotationIds?: Maybe<Array<Scalars['ID']>>;
  submissionId: Scalars['ID'];
  synopsis?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CyberExportQuoteComparison */
export type CyberExportQuoteComparisonPayload = {
  __typename?: 'CyberExportQuoteComparisonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of CyberExportSubmission */
export type CyberExportSubmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of CyberExportSubmission */
export type CyberExportSubmissionPayload = {
  __typename?: 'CyberExportSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type CyberGenerateQuotesAdditionalCarriersErrors = {
  __typename?: 'CyberGenerateQuotesAdditionalCarriersErrors';
  base?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CyberGenerateQuotesErrors = {
  __typename?: 'CyberGenerateQuotesErrors';
  additionalCarriers?: Maybe<Array<CyberGenerateQuotesAdditionalCarriersErrors>>;
  annualRevenue?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['String']>;
  hasNoWebsite?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  multiFactorAuthentication?: Maybe<Scalars['String']>;
  naicsCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfEmployees?: Maybe<Scalars['String']>;
  numberOfRecords?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placementTermsAccepted?: Maybe<Scalars['String']>;
  selectedPanelists?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  websites?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberGenerateQuotes */
export type CyberGenerateQuotesInput = {
  attributes: CyberSubmissionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  panelId: Scalars['ID'];
};

/** Autogenerated return type of CyberGenerateQuotes */
export type CyberGenerateQuotesPayload = {
  __typename?: 'CyberGenerateQuotesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberGenerateQuotesErrors>;
  submission?: Maybe<CyberSubmission>;
};

/** Cyber Generic Coverage */
export type CyberGenericCoverage = Coverage & {
  __typename?: 'CyberGenericCoverage';
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** Autogenerated input type of CyberImportDraftSubmissions */
export type CyberImportDraftSubmissionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  records: Array<CyberDraftSubmissionRecord>;
};

/** Autogenerated return type of CyberImportDraftSubmissions */
export type CyberImportDraftSubmissionsPayload = {
  __typename?: 'CyberImportDraftSubmissionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CyberIssuePolicyErrors = {
  __typename?: 'CyberIssuePolicyErrors';
  base?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberIssuePolicy */
export type CyberIssuePolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  message?: Maybe<Scalars['String']>;
  policyNumber: Scalars['String'];
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of CyberIssuePolicy */
export type CyberIssuePolicyPayload = {
  __typename?: 'CyberIssuePolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberIssuePolicyErrors>;
  quotation?: Maybe<CyberQuotation>;
};

/** Defines attributes used to add/adjust manually entered cyber quotations */
export type CyberManualQuotationInputType = {
  admitted: Scalars['Boolean'];
  carrierName: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  coverages?: Maybe<Array<Maybe<CyberCoverageInputType>>>;
  declinationReason?: Maybe<Scalars['String']>;
  deductible: Scalars['Currency'];
  documents?: Maybe<Array<DocumentInput>>;
  endorsements?: Maybe<Array<Maybe<CyberEndorsementInputType>>>;
  fee?: Maybe<Scalars['Currency']>;
  limit: Scalars['Currency'];
  policyNumber?: Maybe<Scalars['String']>;
  premium: Scalars['Currency'];
  status: Scalars['String'];
};

/** Autogenerated input type of CyberManualSubjectivities */
export type CyberManualSubjectivitiesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  destroyIds?: Maybe<Array<Scalars['ID']>>;
  participationId: Scalars['ID'];
  subjectivities: Array<QuestionInput>;
};

/** Autogenerated return type of CyberManualSubjectivities */
export type CyberManualSubjectivitiesPayload = {
  __typename?: 'CyberManualSubjectivitiesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  participation?: Maybe<Participation>;
};

export type CyberProduct = Product & {
  __typename?: 'CyberProduct';
  annual_revenue?: Maybe<Scalars['Currency']>;
  coverage_start_date?: Maybe<Scalars['ISO8601Date']>;
  coverages: Array<CyberGenericCoverage>;
  created_at: Scalars['ISO8601DateTime'];
  custom_fields: Scalars['JSON'];
  cyber_carrier_statement_of_fact?: Maybe<Scalars['Boolean']>;
  deductible?: Maybe<Scalars['Currency']>;
  deleted_at?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['ISO8601Date']>;
  expiry_date?: Maybe<Scalars['ISO8601Date']>;
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  insurable_type?: Maybe<Scalars['String']>;
  insurer: Scalars['String'];
  limit?: Maybe<Scalars['Currency']>;
  number: Scalars['String'];
  number_of_employees?: Maybe<Scalars['Int']>;
  number_of_records?: Maybe<Scalars['String']>;
  panel?: Maybe<Panel>;
  parent_policy?: Maybe<InsurancePolicy>;
  premium?: Maybe<Scalars['Decimal']>;
  program_structure?: Maybe<Scalars['String']>;
  quotations?: Maybe<Array<Quotation>>;
  selected_panelists?: Maybe<Array<Scalars['String']>>;
  self_insured_retention?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['String']>;
  surplusLinesLicenseInfo?: Maybe<SurplusLinesType>;
  type?: Maybe<Scalars['String']>;
  underlying_policies: Array<InsurancePolicy>;
  updated_at: Scalars['ISO8601DateTime'];
  website?: Maybe<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

/** Cyber Quotation */
export type CyberQuotation = NotificationRelatesTo & Quotation & {
  __typename?: 'CyberQuotation';
  additionalFeatures?: Maybe<Array<Maybe<CyberAdditionalFeature>>>;
  admitted?: Maybe<Scalars['Boolean']>;
  ambestRating?: Maybe<AmBestRating>;
  apiData?: Maybe<Scalars['JSON']>;
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  binders: DocumentConnection;
  boundAt?: Maybe<Scalars['ISO8601DateTime']>;
  comments?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['Date']>;
  coverages?: Maybe<Array<Maybe<CyberCoverage>>>;
  createdAt: Scalars['ISO8601DateTime'];
  cyberQuotedLine?: Maybe<CyberQuotedLineType>;
  declinationReason?: Maybe<Scalars['String']>;
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Currency']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  derivedData: Scalars['JSON'];
  derivedDeductible?: Maybe<Scalars['Currency']>;
  derivedLimit?: Maybe<Scalars['Currency']>;
  derivedPremium?: Maybe<Scalars['Currency']>;
  derivedTax?: Maybe<Scalars['Currency']>;
  discussion?: Maybe<Discussion>;
  documents: Array<Document>;
  endorsements?: Maybe<Array<Maybe<CyberEndorsement>>>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  externalCarrier?: Maybe<ExternalCarrier>;
  extractedAt?: Maybe<Scalars['DateTime']>;
  failedAt?: Maybe<Scalars['ISO8601DateTime']>;
  fee?: Maybe<Scalars['Currency']>;
  fields: Scalars['JSON'];
  id: Scalars['ID'];
  indication: Scalars['Boolean'];
  limit?: Maybe<Scalars['Currency']>;
  owner: User;
  participation: Participation;
  policies: DocumentConnection;
  policyNumber?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['Currency']>;
  questions: QuestionConnection;
  quotedAt?: Maybe<Scalars['ISO8601DateTime']>;
  quotedLines: PanelsQuotedLineTypeConnection;
  requestedDeductible?: Maybe<Scalars['Currency']>;
  requestedLimit?: Maybe<Scalars['Currency']>;
  retailCommission?: Maybe<Scalars['Currency']>;
  retailCommissionPercentage?: Maybe<Scalars['Decimal']>;
  reviewedAt?: Maybe<Scalars['DateTime']>;
  selected?: Maybe<Scalars['Boolean']>;
  selectedOptionId?: Maybe<Scalars['ID']>;
  signingUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  subjectivities: SubjectivityConnection;
  submission: CyberSubmission;
  surplusLinesTax?: Maybe<Scalars['Currency']>;
  tax?: Maybe<Scalars['Currency']>;
  totalCost?: Maybe<Scalars['Currency']>;
  type: Scalars['String'];
  underwriterEmail?: Maybe<Scalars['String']>;
  underwriterFirstName?: Maybe<Scalars['String']>;
  underwriterLastName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};


/** Cyber Quotation */
export type CyberQuotationBindersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Quotation */
export type CyberQuotationPoliciesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Quotation */
export type CyberQuotationQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Quotation */
export type CyberQuotationQuotedLinesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Quotation */
export type CyberQuotationSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for CyberQuotation. */
export type CyberQuotationConnection = {
  __typename?: 'CyberQuotationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CyberQuotationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CyberQuotation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CyberQuotationEdge = {
  __typename?: 'CyberQuotationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CyberQuotation>;
};

/** Attributes for a cyber quotation */
export type CyberQuotationInput = {
  admitted?: Maybe<Scalars['Boolean']>;
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  coverages?: Maybe<Array<CyberCoverageInputType>>;
  declinationReason?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['Currency']>;
  derivedDeductible?: Maybe<Scalars['Currency']>;
  derivedLimit?: Maybe<Scalars['Currency']>;
  derivedPremium?: Maybe<Scalars['Currency']>;
  derivedTax?: Maybe<Scalars['Currency']>;
  documents?: Maybe<Array<DocumentInput>>;
  endorsements?: Maybe<Array<CyberEndorsementInputType>>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  extractedAt?: Maybe<Scalars['ISO8601DateTime']>;
  failedAt?: Maybe<Scalars['ISO8601DateTime']>;
  fee?: Maybe<Scalars['Currency']>;
  limit?: Maybe<Scalars['Currency']>;
  policyNumber?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['Currency']>;
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<Scalars['String']>;
};

/** Cyber Quoted Line */
export type CyberQuotedLineType = PanelsQuotedLineType & {
  __typename?: 'CyberQuotedLineType';
  amountCommission?: Maybe<Scalars['Currency']>;
  annualPremium?: Maybe<Scalars['Currency']>;
  clientCost?: Maybe<Scalars['Currency']>;
  id: Scalars['ID'];
  policyNumber?: Maybe<Scalars['ID']>;
  retailCommission?: Maybe<Scalars['Currency']>;
  retailCommissionPercentage?: Maybe<Scalars['Decimal']>;
  totalCommissionPercentage?: Maybe<Scalars['Decimal']>;
  type: Scalars['String'];
  wholesalerCommission?: Maybe<Scalars['Currency']>;
  wholesalerCommissionPercentage?: Maybe<Scalars['Decimal']>;
};

/** Autogenerated input type of CyberReinstateEmailQuotes */
export type CyberReinstateEmailQuotesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  participationId: Scalars['ID'];
};

/** Autogenerated return type of CyberReinstateEmailQuotes */
export type CyberReinstateEmailQuotesPayload = {
  __typename?: 'CyberReinstateEmailQuotesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<CyberQuotation>>;
};

/** Autogenerated input type of CyberRequestToBind */
export type CyberRequestToBindInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['Date']>;
  documents?: Maybe<Array<DocumentInput>>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of CyberRequestToBind */
export type CyberRequestToBindPayload = {
  __typename?: 'CyberRequestToBindPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  quotation?: Maybe<CyberQuotation>;
};

/** Cyber Submission */
export type CyberSubmission = NotificationRelatesTo & Shareable & Submission & {
  __typename?: 'CyberSubmission';
  additionalCarriers?: Maybe<Scalars['JSON']>;
  annualRevenue?: Maybe<Scalars['Currency']>;
  broadcasts: BroadcastConnection;
  brokerEmail?: Maybe<Scalars['String']>;
  chubbIndustryCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  collaborations: CollaborationConnection;
  collaborators: UserConnection;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  createdAt: Scalars['ISO8601DateTime'];
  customLabelForFees?: Maybe<Scalars['String']>;
  cyberboxxIndustryCode?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['Currency']>;
  dedupKey?: Maybe<Scalars['String']>;
  defaultMessage?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  discussion?: Maybe<Discussion>;
  documents: Array<Document>;
  email?: Maybe<Scalars['String']>;
  emailCarriers: UserConnection;
  fields: Scalars['JSON'];
  hasNoWebsite?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isShareableFormSubmission?: Maybe<Scalars['Boolean']>;
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  limit?: Maybe<Scalars['Currency']>;
  lineOfBusiness: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  multiFactorAuthentication?: Maybe<Array<Scalars['String']>>;
  naicsCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfRecords?: Maybe<Scalars['String']>;
  owner: User;
  panel?: Maybe<Panel>;
  participants: UserConnection;
  participations: ParticipationConnection;
  pdfExport?: Maybe<Document>;
  phoneNumber?: Maybe<Scalars['String']>;
  placementTermsAccepted?: Maybe<Scalars['Boolean']>;
  products: Array<Product>;
  proposals: DocumentConnection;
  questions?: Maybe<Scalars['JSON']>;
  quotations: CyberQuotationConnection;
  revisedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selectedPanelists?: Maybe<Array<Scalars['String']>>;
  shareToken: ShareToken;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  surplusLinesLicenseInfo?: Maybe<SurplusLinesType>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  websites?: Maybe<Array<Scalars['String']>>;
  zipCode?: Maybe<Scalars['String']>;
};


/** Cyber Submission */
export type CyberSubmissionBroadcastsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Submission */
export type CyberSubmissionCollaborationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Submission */
export type CyberSubmissionCollaboratorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Submission */
export type CyberSubmissionEmailCarriersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Submission */
export type CyberSubmissionParticipantsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Submission */
export type CyberSubmissionParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Submission */
export type CyberSubmissionProposalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Cyber Submission */
export type CyberSubmissionQuotationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Attributes for Adjust Cyber submission */
export type CyberSubmissionAdjustInput = {
  carriers?: Maybe<Array<Scalars['String']>>;
  deductible?: Maybe<Scalars['Currency']>;
  id?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Currency']>;
};

/** The connection type for CyberSubmission. */
export type CyberSubmissionConnection = {
  __typename?: 'CyberSubmissionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CyberSubmissionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CyberSubmission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Attributes for a draft cyber submission */
export type CyberSubmissionDraftInput = {
  additionalCarriers?: Maybe<Scalars['JSON']>;
  annualRevenue?: Maybe<Scalars['Currency']>;
  brokerEmail?: Maybe<Scalars['String']>;
  chubbIndustryCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  cyberboxxIndustryCode?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['Currency']>;
  dedupKey?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentInput>>;
  email?: Maybe<Scalars['String']>;
  hasNoWebsite?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  isShareableFormSubmission?: Maybe<Scalars['Boolean']>;
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  limit?: Maybe<Scalars['Currency']>;
  message?: Maybe<Scalars['String']>;
  multiFactorAuthentication?: Maybe<Array<Scalars['String']>>;
  naicsCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfRecords?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placementTermsAccepted?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['JSON']>;
  revisedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selectedPanelists?: Maybe<Array<Scalars['String']>>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  websites?: Maybe<Array<Scalars['String']>>;
  zipCode?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type CyberSubmissionEdge = {
  __typename?: 'CyberSubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CyberSubmission>;
};

/** Errors for a Cyber submission */
export type CyberSubmissionErrors = {
  __typename?: 'CyberSubmissionErrors';
  additionalCarriers?: Maybe<Scalars['String']>;
  annualRevenue?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  brokerEmail?: Maybe<Scalars['String']>;
  chubbIndustryCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['String']>;
  cyberboxxIndustryCode?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  dedupKey?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentErrors>;
  email?: Maybe<Scalars['String']>;
  hasNoWebsite?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isShareableFormSubmission?: Maybe<Scalars['String']>;
  lastEditedAt?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  multiFactorAuthentication?: Maybe<Scalars['String']>;
  naicsCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfEmployees?: Maybe<Scalars['String']>;
  numberOfRecords?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placementTermsAccepted?: Maybe<Scalars['String']>;
  questions?: Maybe<Scalars['String']>;
  revisedAt?: Maybe<Scalars['String']>;
  selectedPanelists?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  websites?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Attributes for a Cyber submission */
export type CyberSubmissionInput = {
  additionalCarriers?: Maybe<Array<CarrierInvite>>;
  annualRevenue?: Maybe<Scalars['Currency']>;
  brokerEmail?: Maybe<Scalars['String']>;
  chubbIndustryCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  cyberboxxIndustryCode?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['Currency']>;
  dedupKey?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documents?: Array<DocumentInput>;
  email?: Maybe<Scalars['String']>;
  hasNoWebsite?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  isShareableFormSubmission?: Maybe<Scalars['Boolean']>;
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  limit?: Maybe<Scalars['Currency']>;
  message?: Scalars['String'];
  multiFactorAuthentication?: Maybe<Array<Scalars['String']>>;
  naicsCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfRecords?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placementTermsAccepted?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['JSON']>;
  revisedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selectedPanelists?: Array<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  websites?: Maybe<Array<Scalars['String']>>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Attributes for Updating Cyber submission */
export type CyberSubmissionUpdateInput = {
  additionalCarriers?: Maybe<Scalars['JSON']>;
  annualRevenue?: Maybe<Scalars['Currency']>;
  brokerEmail?: Maybe<Scalars['String']>;
  chubbIndustryCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  cyberboxxIndustryCode?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['Currency']>;
  dedupKey?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documents?: Array<DocumentInput>;
  email?: Maybe<Scalars['String']>;
  hasNoWebsite?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  isShareableFormSubmission?: Maybe<Scalars['Boolean']>;
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  limit?: Maybe<Scalars['Currency']>;
  message?: Maybe<Scalars['String']>;
  multiFactorAuthentication?: Maybe<Array<Scalars['String']>>;
  naicsCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfRecords?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placementTermsAccepted?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['JSON']>;
  revisedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selectedPanelists?: Maybe<Array<Scalars['String']>>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  websites?: Maybe<Array<Scalars['String']>>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Attributes for inviting additional markets for Cyber submission */
export type CyberSubmissionUpdateMarketsInput = {
  additionalCarriers?: Maybe<Scalars['JSON']>;
  carrierStatementOfFactAccepted?: Maybe<Scalars['Boolean']>;
  chubbIndustryCode?: Maybe<Scalars['String']>;
  cyberboxxIndustryCode?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['Currency']>;
  documents?: Array<DocumentInput>;
  id?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Currency']>;
  message?: Maybe<Scalars['String']>;
  multiFactorAuthentication?: Maybe<Array<Scalars['String']>>;
  placementTermsAccepted?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['JSON']>;
  selectedPanelists?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of CyberSuggestCodesSubmission */
export type CyberSuggestCodesSubmissionInput = {
  address?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  panelId: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  stub?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CyberSuggestCodesSubmission */
export type CyberSuggestCodesSubmissionPayload = {
  __typename?: 'CyberSuggestCodesSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  suggestions?: Maybe<Array<Scalars['String']>>;
};

export type CyberUpdateExtractedQuoteCoveragesErrors = {
  __typename?: 'CyberUpdateExtractedQuoteCoveragesErrors';
  base?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CyberUpdateExtractedQuoteEndorsementsErrors = {
  __typename?: 'CyberUpdateExtractedQuoteEndorsementsErrors';
  base?: Maybe<Scalars['String']>;
  coinsurance?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CyberUpdateExtractedQuoteErrors = {
  __typename?: 'CyberUpdateExtractedQuoteErrors';
  admitted?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  coverages?: Maybe<Array<CyberUpdateExtractedQuoteCoveragesErrors>>;
  deductible?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['String']>;
  endorsements?: Maybe<Array<CyberUpdateExtractedQuoteEndorsementsErrors>>;
  fee?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberUpdateExtractedQuote */
export type CyberUpdateExtractedQuoteInput = {
  attributes: CyberQuotationInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of CyberUpdateExtractedQuote */
export type CyberUpdateExtractedQuotePayload = {
  __typename?: 'CyberUpdateExtractedQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberUpdateExtractedQuoteErrors>;
  quotation?: Maybe<CyberQuotation>;
};

export type CyberUpdateManualQuoteCoveragesErrors = {
  __typename?: 'CyberUpdateManualQuoteCoveragesErrors';
  base?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CyberUpdateManualQuoteEndorsementsErrors = {
  __typename?: 'CyberUpdateManualQuoteEndorsementsErrors';
  base?: Maybe<Scalars['String']>;
  coinsurance?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CyberUpdateManualQuoteErrors = {
  __typename?: 'CyberUpdateManualQuoteErrors';
  admitted?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  carrierName?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  coverages?: Maybe<Array<CyberUpdateManualQuoteCoveragesErrors>>;
  declinationReason?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['String']>;
  endorsements?: Maybe<Array<CyberUpdateManualQuoteEndorsementsErrors>>;
  fee?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberUpdateManualQuote */
export type CyberUpdateManualQuoteInput = {
  attributes: CyberManualQuotationInputType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of CyberUpdateManualQuote */
export type CyberUpdateManualQuotePayload = {
  __typename?: 'CyberUpdateManualQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberUpdateManualQuoteErrors>;
  quotation?: Maybe<CyberQuotation>;
};

export type CyberUpdateQuoteCoveragesErrors = {
  __typename?: 'CyberUpdateQuoteCoveragesErrors';
  base?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CyberUpdateQuoteEndorsementsErrors = {
  __typename?: 'CyberUpdateQuoteEndorsementsErrors';
  base?: Maybe<Scalars['String']>;
  coinsurance?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CyberUpdateQuoteErrors = {
  __typename?: 'CyberUpdateQuoteErrors';
  admitted?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  coverages?: Maybe<Array<CyberUpdateQuoteCoveragesErrors>>;
  deductible?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['String']>;
  endorsements?: Maybe<Array<CyberUpdateQuoteEndorsementsErrors>>;
  fee?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberUpdateQuote */
export type CyberUpdateQuoteInput = {
  attributes: CyberQuotationInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of CyberUpdateQuote */
export type CyberUpdateQuotePayload = {
  __typename?: 'CyberUpdateQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberUpdateQuoteErrors>;
  quotation?: Maybe<CyberQuotation>;
};

/** Autogenerated input type of CyberUpdateSubmissionDocuments */
export type CyberUpdateSubmissionDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of CyberUpdateSubmissionDocuments */
export type CyberUpdateSubmissionDocumentsPayload = {
  __typename?: 'CyberUpdateSubmissionDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  submission: CyberSubmission;
};

export type CyberUpdateSubmissionErrors = {
  __typename?: 'CyberUpdateSubmissionErrors';
  annualRevenue?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['String']>;
  hasNoWebsite?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  naicsCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfEmployees?: Maybe<Scalars['String']>;
  numberOfRecords?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placementTermsAccepted?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
  websites?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberUpdateSubmission */
export type CyberUpdateSubmissionInput = {
  attributes: CyberSubmissionUpdateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CyberUpdateSubmissionMarketsErrors = {
  __typename?: 'CyberUpdateSubmissionMarketsErrors';
  base?: Maybe<Scalars['String']>;
  deductible?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  multiFactorAuthentication?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CyberUpdateSubmissionMarkets */
export type CyberUpdateSubmissionMarketsInput = {
  attributes: CyberSubmissionUpdateMarketsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CyberUpdateSubmissionMarkets */
export type CyberUpdateSubmissionMarketsPayload = {
  __typename?: 'CyberUpdateSubmissionMarketsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberUpdateSubmissionMarketsErrors>;
  submission?: Maybe<CyberSubmission>;
};

/** Autogenerated return type of CyberUpdateSubmission */
export type CyberUpdateSubmissionPayload = {
  __typename?: 'CyberUpdateSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  errors?: Maybe<CyberUpdateSubmissionErrors>;
  submission?: Maybe<CyberSubmission>;
};

/** Dashboard object for users */
export type Dashboard = {
  __typename?: 'Dashboard';
  binders: DashboardBinders;
  certificates: DashboardCertificates;
  quotes: DashboardQuotes;
  submissions: DashboardSubmissions;
};

/** Binders info object for the dashboard */
export type DashboardBinders = {
  __typename?: 'DashboardBinders';
  confirmed: Scalars['Int'];
  total: Scalars['Int'];
};

/** Certificates info object for the dashboard */
export type DashboardCertificates = {
  __typename?: 'DashboardCertificates';
  boundTotal: Scalars['Int'];
  reinsurerSent: Scalars['Int'];
  total: Scalars['Int'];
  totalPremiumsPaid: Scalars['Int'];
};

/** Quotes info object for the dashboard */
export type DashboardQuotes = {
  __typename?: 'DashboardQuotes';
  new: Scalars['Int'];
  revised: Scalars['Int'];
  total: Scalars['Int'];
};

/** Submissions info object for the dashboard */
export type DashboardSubmissions = {
  __typename?: 'DashboardSubmissions';
  accepted: Scalars['Int'];
  actionable: Scalars['Int'];
  active: Scalars['Int'];
  completed: Scalars['Int'];
  counterProposalFromCedent: Scalars['Int'];
  drafts: Scalars['Int'];
  pending: Scalars['Int'];
  total: Scalars['Int'];
};


export type DateField = Field & {
  __typename?: 'DateField';
  name: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['DateTime']>;
};

/** Attributes for selecting with date range */
export type DateRangeInput = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type DateTimeField = Field & {
  __typename?: 'DateTimeField';
  name: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['DateTime']>;
};


export type DecimalField = Field & {
  __typename?: 'DecimalField';
  name: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['Decimal']>;
};

/** Autogenerated input type of DeclineEndorsementQuote */
export type DeclineEndorsementQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementQuoteId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  response: Scalars['String'];
};

/** Autogenerated return type of DeclineEndorsementQuote */
export type DeclineEndorsementQuotePayload = {
  __typename?: 'DeclineEndorsementQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementQuote?: Maybe<EndorsementQuote>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of DeclinePanelQuote */
export type DeclinePanelQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  participationId: Scalars['ID'];
};

/** Autogenerated return type of DeclinePanelQuote */
export type DeclinePanelQuotePayload = {
  __typename?: 'DeclinePanelQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotation?: Maybe<MlQuotation>;
};

/** Autogenerated input type of DeclineProposal */
export type DeclineProposalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of DeclineProposal */
export type DeclineProposalPayload = {
  __typename?: 'DeclineProposalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  message?: Maybe<DirectMessage>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of DeclineQuote */
export type DeclineQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of DeclineQuote */
export type DeclineQuotePayload = {
  __typename?: 'DeclineQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  message?: Maybe<DirectMessage>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of DeleteAnswer */
export type DeleteAnswerInput = {
  answerId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteAnswer */
export type DeleteAnswerPayload = {
  __typename?: 'DeleteAnswerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['ID']>;
  error?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteAuthorizedMarketContact */
export type DeleteAuthorizedMarketContactInput = {
  authorizedMarketContactId: Scalars['ID'];
  authorizedMarketId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteAuthorizedMarketContact */
export type DeleteAuthorizedMarketContactPayload = {
  __typename?: 'DeleteAuthorizedMarketContactPayload';
  authorizedMarket?: Maybe<AuthorizedMarket>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of DeleteDemoData */
export type DeleteDemoDataInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteDemoData */
export type DeleteDemoDataPayload = {
  __typename?: 'DeleteDemoDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of DeleteQuestion */
export type DeleteQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  questionId: Scalars['ID'];
};

/** Autogenerated return type of DeleteQuestion */
export type DeleteQuestionPayload = {
  __typename?: 'DeleteQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['ID']>;
  error?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DeleteRevision */
export type DeleteRevisionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  revisionId: Scalars['ID'];
};

/** Autogenerated return type of DeleteRevision */
export type DeleteRevisionPayload = {
  __typename?: 'DeleteRevisionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of DeleteWorksheet */
export type DeleteWorksheetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of DeleteWorksheet */
export type DeleteWorksheetPayload = {
  __typename?: 'DeleteWorksheetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Demo placement information from the website widget */
export type DemoPlacementInput = {
  layers: Array<LayerInput>;
  limit: Scalars['Currency'];
  type: Scalars['String'];
};

/** A direct message sent through Relay */
export type DirectMessage = Message & NotificationRelatesTo & {
  __typename?: 'DirectMessage';
  attachments: DocumentConnection;
  author: User;
  body: Scalars['String'];
  discussion?: Maybe<Discussion>;
  documents: DocumentConnection;
  id: Scalars['ID'];
  subject: Scalars['String'];
  timestamp: Scalars['DateTime'];
};


/** A direct message sent through Relay */
export type DirectMessageAttachmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A direct message sent through Relay */
export type DirectMessageDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Management Liability Director And Officers Quoted Line Type */
export type DirectorsAndOfficersQuotedLineType = PanelsQuotedLineType & {
  __typename?: 'DirectorsAndOfficersQuotedLineType';
  attachmentPoint?: Maybe<Scalars['Decimal']>;
  cedingCommission?: Maybe<Scalars['Decimal']>;
  coverableScope?: Maybe<Scalars['String']>;
  coverableType?: Maybe<Scalars['String']>;
  coverage: Coverage;
  coverageName: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Decimal']>;
  directorsAndOfficers?: Maybe<DirectorsAndOfficersType>;
  entityCoverage?: Maybe<EntityCoverageType>;
  exclusions: Array<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  groupableType?: Maybe<Scalars['String']>;
  iconName: Scalars['String'];
  id: Scalars['ID'];
  indemnifiableLoss?: Maybe<IndemnifiableLossType>;
  keyPerils: Array<Scalars['String']>;
  layerLimit?: Maybe<Scalars['Decimal']>;
  netPremium?: Maybe<Scalars['Decimal']>;
  nonIndemnifiableLoss?: Maybe<NonIndemnifiableLossType>;
  note: Scalars['Text'];
  participation?: Maybe<Scalars['Decimal']>;
  premium?: Maybe<Scalars['Decimal']>;
  proposedLayerPremium?: Maybe<Scalars['Decimal']>;
  quotation: PanelsQuotation;
  quotedLineNames: Scalars['JSON'];
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type DirectorsAndOfficersType = {
  __typename?: 'DirectorsAndOfficersType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Autogenerated input type of DisableAuthorizedMarket */
export type DisableAuthorizedMarketInput = {
  authorizedMarketId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
};

/** Autogenerated return type of DisableAuthorizedMarket */
export type DisableAuthorizedMarketPayload = {
  __typename?: 'DisableAuthorizedMarketPayload';
  authorizedMarket?: Maybe<AuthorizedMarket>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** A discussable entity in Relay */
export type Discussable = {
  discussion?: Maybe<Discussion>;
  id: Scalars['ID'];
};

export enum DiscussableType {
  BrokerCollaboration = 'BrokerCollaboration',
  ClientCollaboration = 'ClientCollaboration',
  MgaCollaboration = 'MgaCollaboration',
  Participation = 'Participation',
  Quote = 'Quote',
  WholesalerCollaboration = 'WholesalerCollaboration',
  Worksheet = 'Worksheet'
}

/** A series of messages sent through Relay related to a single quote */
export type Discussion = {
  __typename?: 'Discussion';
  discussable: Discussable;
  discussionParticipants: DiscussionParticipantConnection;
  id: Scalars['ID'];
  messages: MessageConnection;
  participants: UserConnection;
};


/** A series of messages sent through Relay related to a single quote */
export type DiscussionDiscussionParticipantsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A series of messages sent through Relay related to a single quote */
export type DiscussionMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  authorType?: Maybe<UserType>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


/** A series of messages sent through Relay related to a single quote */
export type DiscussionParticipantsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A link between a discussion and a user */
export type DiscussionParticipant = {
  __typename?: 'DiscussionParticipant';
  id: Scalars['ID'];
  lastReadMessageId?: Maybe<Scalars['Int']>;
  participant: User;
  unreadCount: Scalars['Int'];
};

/** The connection type for DiscussionParticipant. */
export type DiscussionParticipantConnection = {
  __typename?: 'DiscussionParticipantConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DiscussionParticipantEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DiscussionParticipant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DiscussionParticipantEdge = {
  __typename?: 'DiscussionParticipantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DiscussionParticipant>;
};

/** A downloadable document in Relay */
export type Document = {
  __typename?: 'Document';
  attachment?: Maybe<Document>;
  byteSize: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  filename: Scalars['String'];
  hellosignTemplateId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTagged?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  templateEditUrl?: Maybe<Scalars['String']>;
  templateEditUrlExpiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  type: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

/** The connection type for Document. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Document>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Document>;
};

/** Errors for updating a Document */
export type DocumentErrors = {
  __typename?: 'DocumentErrors';
  _destroy?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of DocumentForward */
export type DocumentForwardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  participantIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of DocumentForward */
export type DocumentForwardPayload = {
  __typename?: 'DocumentForwardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ParticipationErrors>;
  participations?: Maybe<Array<Participation>>;
};

/** Attributes for updating a Document */
export type DocumentInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  attachment?: Maybe<Scalars['Upload']>;
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of DropEndorsement */
export type DropEndorsementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementId: Scalars['ID'];
};

/** Autogenerated return type of DropEndorsement */
export type DropEndorsementPayload = {
  __typename?: 'DropEndorsementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsement?: Maybe<Endorsement>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of EditAnswer */
export type EditAnswerInput = {
  answerId: Scalars['ID'];
  attributes: AnswerInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EditAnswer */
export type EditAnswerPayload = {
  __typename?: 'EditAnswerPayload';
  answer?: Maybe<Answer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<AnswerErrors>;
};

/** Autogenerated input type of EditPanelQuotation */
export type EditPanelQuotationInput = {
  attributes: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of EditPanelQuotation */
export type EditPanelQuotationPayload = {
  __typename?: 'EditPanelQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotation?: Maybe<PanelsQuotation>;
};

/** Autogenerated input type of EditPanelSubmission */
export type EditPanelSubmissionInput = {
  attributes: Scalars['JSON'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents?: Array<DocumentInput>;
  feSchema?: Maybe<Scalars['JSON']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of EditPanelSubmission */
export type EditPanelSubmissionPayload = {
  __typename?: 'EditPanelSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  submission: PanelSubmission;
};

/** Autogenerated input type of EditQuestion */
export type EditQuestionInput = {
  attributes: QuestionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  questionId: Scalars['ID'];
};

/** Autogenerated return type of EditQuestion */
export type EditQuestionPayload = {
  __typename?: 'EditQuestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<QuestionErrors>;
  question: Question;
};

/** Autogenerated input type of EditRetailBroker */
export type EditRetailBrokerInput = {
  active: Scalars['Boolean'];
  agencyCode: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
};

/** Autogenerated return type of EditRetailBroker */
export type EditRetailBrokerPayload = {
  __typename?: 'EditRetailBrokerPayload';
  agencyCode?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of EditSentWorksheet */
export type EditSentWorksheetInput = {
  attributes: EditWorksheetInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  outdateQuotes?: Maybe<Scalars['Boolean']>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of EditSentWorksheet */
export type EditSentWorksheetPayload = {
  __typename?: 'EditSentWorksheetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

/** Attributes for updating a sent worksheet */
export type EditWorksheetInput = {
  address?: Maybe<Scalars['String']>;
  basisOfAcceptance?: Maybe<Scalars['String']>;
  certificateNumber?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  contractType?: Maybe<Scalars['String']>;
  cope?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<FieldInput>>;
  description?: Maybe<Scalars['String']>;
  industryType?: Maybe<Array<Scalars['String']>>;
  insuranceCoverage?: Maybe<Scalars['String']>;
  insurancePolicyAttributes?: Maybe<EditWorksheetInsurancePolicyInput>;
  internalReferenceNumber?: Maybe<Scalars['String']>;
  locationDescription?: Maybe<Scalars['String']>;
  lossHistory?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  namedInsured: Scalars['String'];
  namedInsuredAddress?: Maybe<Scalars['String']>;
  participationComment?: Maybe<Scalars['String']>;
  participationSubject?: Maybe<Scalars['String']>;
  reinsuranceEffectiveDate?: Maybe<Scalars['ISO8601DateTime']>;
  reinsuranceExpiryDate?: Maybe<Scalars['ISO8601DateTime']>;
  riskDocuments?: Maybe<Scalars['Upload']>;
  showTower?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  underwriterName?: Maybe<Scalars['String']>;
};

/** Attributes for creating or updating insurance policies */
export type EditWorksheetInsurancePolicyInput = {
  effectiveDate?: Maybe<Scalars['ISO8601DateTime']>;
  expiryDate?: Maybe<Scalars['ISO8601DateTime']>;
};


/** Email Carrier Input Type */
export type EmailCarrierInputType = {
  agentCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Email Carrier Type */
export type EmailCarrierType = {
  __typename?: 'EmailCarrierType';
  agentCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};



/** Autogenerated input type of EmailShareableApplicationLink */
export type EmailShareableApplicationLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  message: Scalars['String'];
  submissionId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Autogenerated return type of EmailShareableApplicationLink */
export type EmailShareableApplicationLinkPayload = {
  __typename?: 'EmailShareableApplicationLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
  shareableLink?: Maybe<Scalars['String']>;
};

export type EmployeeDishonestyType = {
  __typename?: 'EmployeeDishonestyType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

export type EmployeeType = {
  __typename?: 'EmployeeType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Management Liability Employment Practices Liability Quoted Line Type */
export type EmploymentPracticesLiabilityQuotedLineType = PanelsQuotedLineType & {
  __typename?: 'EmploymentPracticesLiabilityQuotedLineType';
  attachmentPoint?: Maybe<Scalars['Decimal']>;
  cedingCommission?: Maybe<Scalars['Decimal']>;
  coverableScope?: Maybe<Scalars['String']>;
  coverableType?: Maybe<Scalars['String']>;
  coverage: Coverage;
  coverageName: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Decimal']>;
  employee?: Maybe<EmployeeType>;
  employmentPracticesLiability?: Maybe<EmploymentPracticesLiabilityType>;
  exclusions: Array<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  groupableType?: Maybe<Scalars['String']>;
  iconName: Scalars['String'];
  id: Scalars['ID'];
  keyPerils: Array<Scalars['String']>;
  layerLimit?: Maybe<Scalars['Decimal']>;
  netPremium?: Maybe<Scalars['Decimal']>;
  note: Scalars['Text'];
  participation?: Maybe<Scalars['Decimal']>;
  premium?: Maybe<Scalars['Decimal']>;
  proposedLayerPremium?: Maybe<Scalars['Decimal']>;
  quotation: PanelsQuotation;
  quotedLineNames: Scalars['JSON'];
  thirdParty?: Maybe<ThirdPartyType>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type EmploymentPracticesLiabilityType = {
  __typename?: 'EmploymentPracticesLiabilityType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Relay Endorsement */
export type Endorsement = {
  __typename?: 'Endorsement';
  createdAt: Scalars['DateTime'];
  documents: DocumentConnection;
  endorsementEndDate?: Maybe<Scalars['String']>;
  endorsementQuotes: Array<EndorsementQuote>;
  endorsementStartDate: Scalars['String'];
  exclusions?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  identifierNumber: Scalars['Int'];
  insurancePolicy: InsurancePolicy;
  kind: Scalars['String'];
  name: Scalars['String'];
  namedInsured: Scalars['String'];
  perils?: Maybe<Array<Scalars['String']>>;
  reason: Scalars['String'];
  reinsuranceEffectiveDate?: Maybe<Scalars['String']>;
  reinsuranceExpiryDate?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  timestamp: Scalars['DateTime'];
  totalInsuredValue?: Maybe<Scalars['String']>;
  worksheet: Worksheet;
};


/** Relay Endorsement */
export type EndorsementDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Attributes for manipulating an Endorsement */
export type EndorsementInput = {
  documents?: Maybe<Array<DocumentInput>>;
  endorsementEndDate?: Maybe<Scalars['String']>;
  endorsementQuotesAttributes: Array<EndorsementQuoteInput>;
  endorsementStartDate: Scalars['String'];
  exclusions: Array<Scalars['String']>;
  identifierNumber: Scalars['Int'];
  insurancePolicyAttributes?: Maybe<InsurancePolicyInput>;
  kind: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  namedInsured: Scalars['String'];
  perils: Array<Scalars['String']>;
  reason: Scalars['String'];
  reinsuranceEffectiveDate?: Maybe<Scalars['String']>;
  reinsuranceExpiryDate?: Maybe<Scalars['String']>;
  totalInsurableValue: Scalars['String'];
};

/** The Quotes on the Endorsement */
export type EndorsementQuote = {
  __typename?: 'EndorsementQuote';
  additionalPremium?: Maybe<Scalars['Int']>;
  documents: DocumentConnection;
  endorsement: Endorsement;
  id: Scalars['ID'];
  quote?: Maybe<Quote>;
  returnPremium?: Maybe<Scalars['Int']>;
  schemas?: Maybe<EndorsementQuotesSchema>;
  status: Scalars['String'];
  timestamp: Scalars['DateTime'];
};


/** The Quotes on the Endorsement */
export type EndorsementQuoteDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Attributes for manipulating an Endorsement Quote */
export type EndorsementQuoteInput = {
  endorsableQuoteId: Scalars['ID'];
  endorsableQuoteType: Scalars['String'];
  status: Scalars['String'];
};

/** Endorsement Quote form schemas */
export type EndorsementQuotesSchema = {
  __typename?: 'EndorsementQuotesSchema';
  requestedEndorsementQuote: Scalars['JSON'];
  sentEndorsementQuote: Scalars['JSON'];
};

export type EnhancedAppetiteErrorType = {
  __typename?: 'EnhancedAppetiteErrorType';
  errors?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type EntityCoverageType = {
  __typename?: 'EntityCoverageType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** A permission granted to a given user for a given record */
export type EntityPermission = {
  __typename?: 'EntityPermission';
  edit: Scalars['Boolean'];
  manage: Scalars['Boolean'];
};

/** A historical event in the lifecycle of a worksheet or quote */
export type Entry = {
  __typename?: 'Entry';
  actionUrl?: Maybe<Scalars['String']>;
  callToAction?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  recordId: Scalars['String'];
  timestamp: Scalars['DateTime'];
  user?: Maybe<User>;
};

/** The connection type for Entry. */
export type EntryConnection = {
  __typename?: 'EntryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Entry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EntryEdge = {
  __typename?: 'EntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Entry>;
};

/** Autogenerated input type of ExportBmsQuotes */
export type ExportBmsQuotesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of ExportBmsQuotes */
export type ExportBmsQuotesPayload = {
  __typename?: 'ExportBmsQuotesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

/** File format for export */
export enum ExportFormatType {
  Csv = 'csv',
  Excel = 'excel'
}

/** Autogenerated input type of ExportQuotes */
export type ExportQuotesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of ExportQuotes */
export type ExportQuotesPayload = {
  __typename?: 'ExportQuotesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

/** Autogenerated input type of ExportWorksheetDetails */
export type ExportWorksheetDetailsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of ExportWorksheetDetails */
export type ExportWorksheetDetailsPayload = {
  __typename?: 'ExportWorksheetDetailsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ExportWorksheets */
export type ExportWorksheetsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  daysInPast?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  limitMax?: Maybe<Scalars['Currency']>;
  limitMin?: Maybe<Scalars['Currency']>;
  lineOfBusiness?: Maybe<Array<LineOfBusiness>>;
  marketIds?: Maybe<Array<Scalars['ID']>>;
  sirMax?: Maybe<Scalars['Currency']>;
  sirMin?: Maybe<Scalars['Currency']>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<Array<QuoteStatuses>>;
  submissionId?: Maybe<Scalars['ID']>;
  type: ExportFormatType;
  worksheetId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ExportWorksheets */
export type ExportWorksheetsPayload = {
  __typename?: 'ExportWorksheetsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Exposure Report data object for the analytics section */
export type ExposureReportDataType = {
  __typename?: 'ExposureReportDataType';
  boundCoverage?: Maybe<Scalars['Decimal']>;
  rating: Scalars['String'];
  reinsurerCompanyName: Scalars['String'];
  totalPremium?: Maybe<Scalars['Decimal']>;
};

/** The connection type for ExposureReportDataType. */
export type ExposureReportDataTypeConnection = {
  __typename?: 'ExposureReportDataTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExposureReportDataTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ExposureReportDataType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ExposureReportDataTypeEdge = {
  __typename?: 'ExposureReportDataTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ExposureReportDataType>;
};

/** External Carrier */
export type ExternalCarrier = {
  __typename?: 'ExternalCarrier';
  id: Scalars['ID'];
  logoUrl: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

/** A Feature Flag */
export type Feature = {
  __typename?: 'Feature';
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

/** Feature Flags */
export type Features = {
  __typename?: 'Features';
  demoUi: Scalars['Boolean'];
  list: Array<Feature>;
};

/** Management Liability Fidelity Crime Quoted Line Type */
export type FidelityCrimeQuotedLineType = PanelsQuotedLineType & {
  __typename?: 'FidelityCrimeQuotedLineType';
  attachmentPoint?: Maybe<Scalars['Decimal']>;
  cedingCommission?: Maybe<Scalars['Decimal']>;
  computerSystem?: Maybe<ComputerSystemType>;
  counterfeitMoney?: Maybe<CounterfeitMoneyType>;
  coverableScope?: Maybe<Scalars['String']>;
  coverableType?: Maybe<Scalars['String']>;
  coverage: Coverage;
  coverageName: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Decimal']>;
  employeeDishonesty?: Maybe<EmployeeDishonestyType>;
  exclusions: Array<Scalars['String']>;
  fidelityCrime?: Maybe<FidelityCrimeType>;
  fields?: Maybe<Scalars['JSON']>;
  forgeryOrAlteration?: Maybe<ForgeryOrAlterationType>;
  fundsTransferFraud?: Maybe<FundsTransferFraudType>;
  groupableType?: Maybe<Scalars['String']>;
  iconName: Scalars['String'];
  id: Scalars['ID'];
  impersonationFraud?: Maybe<ImpersonationFraudType>;
  inTransite?: Maybe<InTransiteType>;
  keyPerils: Array<Scalars['String']>;
  layerLimit?: Maybe<Scalars['Decimal']>;
  netPremium?: Maybe<Scalars['Decimal']>;
  note: Scalars['Text'];
  onPremises?: Maybe<OnPremisesType>;
  participation?: Maybe<Scalars['Decimal']>;
  premium?: Maybe<Scalars['Decimal']>;
  proposedLayerPremium?: Maybe<Scalars['Decimal']>;
  quotation: PanelsQuotation;
  quotedLineNames: Scalars['JSON'];
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type FidelityCrimeType = {
  __typename?: 'FidelityCrimeType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Management Liability Fiduciary Quoted Line Type */
export type FiduciaryQuotedLineType = PanelsQuotedLineType & {
  __typename?: 'FiduciaryQuotedLineType';
  attachmentPoint?: Maybe<Scalars['Decimal']>;
  cedingCommission?: Maybe<Scalars['Decimal']>;
  coverableScope?: Maybe<Scalars['String']>;
  coverableType?: Maybe<Scalars['String']>;
  coverage: Coverage;
  coverageName: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Decimal']>;
  exclusions: Array<Scalars['String']>;
  fiduciary?: Maybe<FiduciaryType>;
  fields?: Maybe<Scalars['JSON']>;
  groupableType?: Maybe<Scalars['String']>;
  iconName: Scalars['String'];
  id: Scalars['ID'];
  keyPerils: Array<Scalars['String']>;
  layerLimit?: Maybe<Scalars['Decimal']>;
  netPremium?: Maybe<Scalars['Decimal']>;
  note: Scalars['Text'];
  participation?: Maybe<Scalars['Decimal']>;
  premium?: Maybe<Scalars['Decimal']>;
  proposedLayerPremium?: Maybe<Scalars['Decimal']>;
  quotation: PanelsQuotation;
  quotedLineNames: Scalars['JSON'];
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type FiduciaryType = {
  __typename?: 'FiduciaryType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Relay Field */
export type Field = {
  name: Scalars['String'];
  type: Scalars['String'];
};

/** Attributes for creating a field */
export type FieldInput = {
  /** The ID of the field */
  name: Scalars['String'];
  /** The field type, from FlexiFields::TYPES */
  type?: Maybe<Scalars['String']>;
  /** The value of the field */
  value: Scalars['String'];
};

export type ForgeryOrAlterationType = {
  __typename?: 'ForgeryOrAlterationType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

export type FundsTransferFraudType = {
  __typename?: 'FundsTransferFraudType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Attributes for creating or updating historical exposure on underwriting */
export type HistoricalExposureInputType = {
  powerUnits?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['String']>;
};

/** Historical exposure object on auto_underwriting */
export type HistoricalExposureType = {
  __typename?: 'HistoricalExposureType';
  powerUnits?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['String']>;
};

/** History for a Relay Worksheet or Quote */
export type History = {
  __typename?: 'History';
  entries: EntryConnection;
  worksheet: Worksheet;
};


/** History for a Relay Worksheet or Quote */
export type HistoryEntriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type HistoryType = {
  __typename?: 'HistoryType';
  number_of_previous_losses?: Maybe<Scalars['String']>;
  previous_losses?: Maybe<Scalars['String']>;
  previous_threat?: Maybe<Scalars['String']>;
  total_loss_value?: Maybe<Scalars['Currency']>;
};

/** Autogenerated input type of IgnoreConnection */
export type IgnoreConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of IgnoreConnection */
export type IgnoreConnectionPayload = {
  __typename?: 'IgnoreConnectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  connection?: Maybe<ConnectionRink>;
  errors: Array<Scalars['String']>;
};

export type ImpersonationFraudType = {
  __typename?: 'ImpersonationFraudType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Something that can have data imported into Relay */
export type Importable = {
  id: Scalars['ID'];
  templateImports: TemplateImportConnection;
};


/** Something that can have data imported into Relay */
export type ImportableTemplateImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of ImportPlacement */
export type ImportPlacementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
  worksheetId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ImportPlacement */
export type ImportPlacementPayload = {
  __typename?: 'ImportPlacementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  import?: Maybe<TemplateImport>;
};

/** Autogenerated input type of ImportQuoteExtractionDocument */
export type ImportQuoteExtractionDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of ImportQuoteExtractionDocument */
export type ImportQuoteExtractionDocumentPayload = {
  __typename?: 'ImportQuoteExtractionDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  import?: Maybe<TemplateImport>;
};

export type IndemnifiableLossType = {
  __typename?: 'IndemnifiableLossType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

export enum Industry {
  AerospaceDefense = 'AEROSPACE___DEFENSE',
  Automobiles = 'AUTOMOBILES',
  BanksTraditional = 'BANKS__TRADITIONAL_',
  Construction = 'CONSTRUCTION',
  ConsumerGoodsRetail = 'CONSUMER_GOODS___RETAIL',
  ConsumerServices = 'CONSUMER_SERVICES',
  FinancialServices = 'FINANCIAL_SERVICES',
  FoodBeverage = 'FOOD___BEVERAGE',
  Healthcare = 'HEALTHCARE',
  Insurance = 'INSURANCE',
  Materials = 'MATERIALS',
  Media = 'MEDIA',
  OilGas = 'OIL___GAS',
  Pharma = 'PHARMA',
  PowerUtilities = 'POWER___UTILITIES',
  ProfessionalServices = 'PROFESSIONAL_SERVICES',
  RealEstate = 'REAL_ESTATE',
  SoftwareServices = 'SOFTWARE___SERVICES',
  TechnologyHardwareIt = 'TECHNOLOGY_HARDWARE___IT',
  Telecom = 'TELECOM',
  Transportation = 'TRANSPORTATION'
}

/** An insurance policy */
export type InsurancePolicy = {
  __typename?: 'InsurancePolicy';
  /** @deprecated Deprecated in favour of coverages which is required to support multiple coverages on the same policy */
  coverage: PolicyCoverage;
  coverages: PolicyCoverageConnection;
  customFields: Array<Field>;
  description?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['Date']>;
  expiryDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  insurer: Scalars['String'];
  number: Scalars['String'];
  parentPolicy?: Maybe<InsurancePolicy>;
  perOccurrenceLimit?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['Currency']>;
  programStructure?: Maybe<Scalars['String']>;
  selfInsuredRetention?: Maybe<Scalars['Currency']>;
  underlyingPolicies: InsurancePolicyConnection;
  worksheet: Worksheet;
};


/** An insurance policy */
export type InsurancePolicyCoveragesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An insurance policy */
export type InsurancePolicyUnderlyingPoliciesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for InsurancePolicy. */
export type InsurancePolicyConnection = {
  __typename?: 'InsurancePolicyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InsurancePolicyEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InsurancePolicy>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InsurancePolicyEdge = {
  __typename?: 'InsurancePolicyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<InsurancePolicy>;
};

/** Attributes for creating or updating insurance policies */
export type InsurancePolicyInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  coveragesAttributes?: Maybe<Array<PolicyCoverageInput>>;
  customFields?: Maybe<Array<FieldInput>>;
  description?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['ISO8601DateTime']>;
  expiryDate?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['ID']>;
  insurer?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['Currency']>;
  programStructure?: Maybe<Scalars['String']>;
  selfInsuredRetention?: Maybe<Scalars['Currency']>;
  underlyingPoliciesAttributes?: Maybe<Array<InsurancePolicyInput>>;
};

/** A sub-type of insurance */
export type InsuranceSubtype = {
  __typename?: 'InsuranceSubtype';
  name: Scalars['String'];
};

/** The connection type for InsuranceSubtype. */
export type InsuranceSubtypeConnection = {
  __typename?: 'InsuranceSubtypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InsuranceSubtypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InsuranceSubtype>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InsuranceSubtypeEdge = {
  __typename?: 'InsuranceSubtypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<InsuranceSubtype>;
};

/** A type of insurance */
export type InsuranceType = {
  __typename?: 'InsuranceType';
  name: Scalars['String'];
  subtypes: InsuranceSubtypeConnection;
};


/** A type of insurance */
export type InsuranceTypeSubtypesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for InsuranceType. */
export type InsuranceTypeConnection = {
  __typename?: 'InsuranceTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InsuranceTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InsuranceType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type InsuranceTypeEdge = {
  __typename?: 'InsuranceTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<InsuranceType>;
};

export type IntegerField = Field & {
  __typename?: 'IntegerField';
  name: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type InTransiteType = {
  __typename?: 'InTransiteType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Autogenerated input type of InviteAdditionalMarkets */
export type InviteAdditionalMarketsInput = {
  attachments?: Maybe<Scalars['Upload']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailSignOff?: Maybe<Scalars['String']>;
  isAgreed: Scalars['Boolean'];
  logo?: Maybe<Scalars['Upload']>;
  participationComment?: Maybe<Scalars['String']>;
  participationSubject?: Maybe<Scalars['String']>;
  reinsurers: Array<AdditionalMarketInput>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of InviteAdditionalMarkets */
export type InviteAdditionalMarketsPayload = {
  __typename?: 'InviteAdditionalMarketsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

/** Autogenerated input type of InviteBroker */
export type InviteBrokerInput = {
  brokerEmail?: Maybe<Scalars['String']>;
  brokerId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of InviteBroker */
export type InviteBrokerPayload = {
  __typename?: 'InviteBrokerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collaboration?: Maybe<BrokerInvite>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of InviteConnection */
export type InviteConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  message: Scalars['String'];
};

/** Autogenerated return type of InviteConnection */
export type InviteConnectionPayload = {
  __typename?: 'InviteConnectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  connection?: Maybe<ConnectionRink>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of InviteRetailBroker */
export type InviteRetailBrokerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  invitation: RetailBrokerInvitationInput;
};

/** Autogenerated return type of InviteRetailBroker */
export type InviteRetailBrokerPayload = {
  __typename?: 'InviteRetailBrokerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of InviteUser */
export type InviteUserInput = {
  attributes: TeamMemberInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of InviteUser */
export type InviteUserPayload = {
  __typename?: 'InviteUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};



/** Autogenerated input type of IssueCertificate */
export type IssueCertificateInput = {
  certificateNumber: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  files: Scalars['Upload'];
  message?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of IssueCertificate */
export type IssueCertificatePayload = {
  __typename?: 'IssueCertificatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  message?: Maybe<DirectMessage>;
  quote?: Maybe<Quote>;
};


/** Attributes for creating or updating large losses on underwriting */
export type LargeLossesInputType = {
  dateOfLoss?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalIncurred?: Maybe<Scalars['Currency']>;
};

/** Large Losses object on auto_underwriting */
export type LargeLossesType = {
  __typename?: 'LargeLossesType';
  dateOfLoss?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalIncurred?: Maybe<Scalars['Currency']>;
};

/** Objects which may be related to a reinsurance may be related to */
export type LayerCoverage = CasualtyCoverage | PropertyCoverage | Worksheet;

/** Layer information */
export type LayerInput = {
  attachmentPoint: Scalars['Currency'];
  layerLimit: Scalars['Currency'];
  participation: Scalars['Percentage'];
};

export type LicenseAddressType = {
  __typename?: 'LicenseAddressType';
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street_line?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** License Information Type */
export type LicenseInformationType = {
  __typename?: 'LicenseInformationType';
  surplus_lines_licenses?: Maybe<Array<SurplusLinesType>>;
};

/** Attributes for creating or updating a policy coverage line */
export type LineInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  attachmentPoint: Scalars['Currency'];
  cedingCommission?: Maybe<Scalars['Percentage']>;
  deductible?: Maybe<Scalars['Currency']>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  keyPerils?: Maybe<Array<Scalars['String']>>;
  layerLimit: Scalars['Currency'];
  netPremium?: Maybe<Scalars['Currency']>;
  /** A freeform note about this coverage */
  note?: Maybe<Scalars['String']>;
  participation: Scalars['Percentage'];
  policyCoverageId?: Maybe<Scalars['ID']>;
  premium?: Maybe<Scalars['Currency']>;
  /** The IDs of the reinsurers providing the quotes */
  reinsurerIds?: Maybe<Array<Scalars['ID']>>;
};

export enum LineOfBusiness {
  BusinessOwner = 'Business_Owner',
  Casualty = 'Casualty',
  Cyber = 'Cyber',
  ManagementLiability = 'Management_Liability',
  MultipleLines = 'Multiple_Lines',
  PrivateClients = 'Private_Clients',
  Property = 'Property',
  Terrorism = 'Terrorism'
}

/** A line of a policy coverage */
export type LineType = {
  __typename?: 'LineType';
  attachmentPoint: Scalars['Currency'];
  cedingCommission?: Maybe<Scalars['Percentage']>;
  declinedAt?: Maybe<Scalars['DateTime']>;
  deductible?: Maybe<Scalars['Currency']>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isIncomplete: Scalars['Boolean'];
  keyPerils?: Maybe<Array<Scalars['String']>>;
  layerLimit: Scalars['Currency'];
  netPremium?: Maybe<Scalars['Currency']>;
  note: Scalars['String'];
  participation: Scalars['Percentage'];
  premium?: Maybe<Scalars['Currency']>;
  quotes: QuoteConnection;
};


/** A line of a policy coverage */
export type LineTypeQuotesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LocationsType = {
  __typename?: 'LocationsType';
  armed_guards?: Maybe<Scalars['Boolean']>;
  business_interruption_limit?: Maybe<Scalars['Currency']>;
  city?: Maybe<Scalars['String']>;
  contents_stock?: Maybe<Scalars['Currency']>;
  crash_barrier_perimeter_fence?: Maybe<Scalars['Boolean']>;
  property_damage_limit?: Maybe<Scalars['Currency']>;
  state?: Maybe<Scalars['String']>;
  street_number_and_name?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** Attributes for creating or updating loss infromation on underwriting */
export type LossInformationInputType = {
  numberOfClaims?: Maybe<Scalars['Int']>;
  totalIncurred?: Maybe<Scalars['Currency']>;
  totalPaid?: Maybe<Scalars['Currency']>;
  valuationDate?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

/** Loss Information object on auto_underwriting */
export type LossInformationType = {
  __typename?: 'LossInformationType';
  numberOfClaims?: Maybe<Scalars['Int']>;
  totalIncurred?: Maybe<Scalars['Currency']>;
  totalPaid?: Maybe<Scalars['Currency']>;
  valuationDate?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type ManagementLiabilityDirectorsAndOfficersCoverage = Coverage & {
  __typename?: 'ManagementLiabilityDirectorsAndOfficersCoverage';
  deductible?: Maybe<Scalars['Currency']>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Currency']>;
  name: Scalars['String'];
  request_higher_limit?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
};

export type ManagementLiabilityEmploymentPracticesLiabilityCoverage = Coverage & {
  __typename?: 'ManagementLiabilityEmploymentPracticesLiabilityCoverage';
  deductible?: Maybe<Scalars['Currency']>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Currency']>;
  name: Scalars['String'];
  request_higher_limit?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
};

export type ManagementLiabilityFidelityCrimeCoverage = Coverage & {
  __typename?: 'ManagementLiabilityFidelityCrimeCoverage';
  deductible?: Maybe<Scalars['Currency']>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Currency']>;
  name: Scalars['String'];
  request_higher_limit?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
};

export type ManagementLiabilityFiduciaryCoverage = Coverage & {
  __typename?: 'ManagementLiabilityFiduciaryCoverage';
  deductible?: Maybe<Scalars['Currency']>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Currency']>;
  name: Scalars['String'];
  request_higher_limit?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
};

export type ManagementLiabilityProduct = Product & {
  __typename?: 'ManagementLiabilityProduct';
  annual_revenue?: Maybe<Scalars['Currency']>;
  continuity_date?: Maybe<Scalars['ISO8601Date']>;
  coverages: Array<Coverage>;
  created_at: Scalars['ISO8601DateTime'];
  custom_fields: Scalars['JSON'];
  deleted_at?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['ISO8601Date']>;
  expiry_date?: Maybe<Scalars['ISO8601Date']>;
  fee_based_account?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<Scalars['JSON']>;
  first_time_buyer?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  insurable_type?: Maybe<Scalars['String']>;
  insurer: Scalars['String'];
  number: Scalars['String'];
  number_of_employees?: Maybe<Scalars['Int']>;
  panel?: Maybe<Panel>;
  parent_policy?: Maybe<InsurancePolicy>;
  premium?: Maybe<Scalars['Decimal']>;
  program_structure?: Maybe<Scalars['String']>;
  quotations?: Maybe<Array<Quotation>>;
  self_insured_retention?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['String']>;
  surplusLinesLicenseInfo?: Maybe<SurplusLinesType>;
  type?: Maybe<Scalars['String']>;
  underlying_policies: Array<InsurancePolicy>;
  updated_at: Scalars['ISO8601DateTime'];
  worksheet?: Maybe<Worksheet>;
};

export type ManagementLiabilitySpecialRiskCoverage = Coverage & {
  __typename?: 'ManagementLiabilitySpecialRiskCoverage';
  deductible?: Maybe<Scalars['Currency']>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Currency']>;
  name: Scalars['String'];
  request_higher_limit?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
};

/** Autogenerated input type of ManageUser */
export type ManageUserInput = {
  attributes: TeamMemberInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of ManageUser */
export type ManageUserPayload = {
  __typename?: 'ManageUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of ManageUserPermissions */
export type ManageUserPermissionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  permissions: Array<PermissionInput>;
  userId: Scalars['ID'];
};

/** Autogenerated return type of ManageUserPermissions */
export type ManageUserPermissionsPayload = {
  __typename?: 'ManageUserPermissionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of ManualCreateQuote */
export type ManualCreateQuoteInput = {
  attachmentPoint?: Maybe<Scalars['Currency']>;
  binderFiles?: Maybe<Scalars['Upload']>;
  brokerageCommission?: Maybe<Scalars['Percentage']>;
  cedingCommission?: Maybe<Scalars['Percentage']>;
  certificateNumber?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  deductible?: Maybe<Scalars['Currency']>;
  documents?: Maybe<Array<DocumentInput>>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  files?: Maybe<Scalars['Upload']>;
  keyPerils?: Maybe<Array<Scalars['String']>>;
  layerLimit?: Maybe<Scalars['Currency']>;
  netPremium?: Maybe<Scalars['Currency']>;
  participation?: Maybe<Scalars['Percentage']>;
  policyFiles?: Maybe<Scalars['Upload']>;
  premium?: Maybe<Scalars['Currency']>;
  quoteId?: Maybe<Scalars['ID']>;
  quotedLayers?: Maybe<Array<QuotedLayerInput>>;
  reusedBinderFiles?: Maybe<Array<UploadedDocumentInput>>;
  reusedFiles?: Maybe<Array<UploadedDocumentInput>>;
  reusedPolicyFiles?: Maybe<Array<UploadedDocumentInput>>;
  status?: Maybe<Scalars['String']>;
  subjectivities?: Maybe<Array<Scalars['String']>>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of ManualCreateQuote */
export type ManualCreateQuotePayload = {
  __typename?: 'ManualCreateQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

/** Autogenerated input type of MarkAsDeclinedQuote */
export type MarkAsDeclinedQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of MarkAsDeclinedQuote */
export type MarkAsDeclinedQuotePayload = {
  __typename?: 'MarkAsDeclinedQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** The marketplace for reinsurance */
export type Marketplace = {
  __typename?: 'Marketplace';
  listing?: Maybe<Worksheet>;
  listings: WorksheetConnection;
  search: WorksheetConnection;
};


/** The marketplace for reinsurance */
export type MarketplaceListingArgs = {
  worksheetId: Scalars['ID'];
};


/** The marketplace for reinsurance */
export type MarketplaceListingsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  insuranceTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  last?: Maybe<Scalars['Int']>;
  totalInsuredValue?: Maybe<Scalars['String']>;
};


/** The marketplace for reinsurance */
export type MarketplaceSearchArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query: Scalars['String'];
};

/** A message sent through Relay */
export type Message = {
  attachments: DocumentConnection;
  author: User;
  body: Scalars['String'];
  documents: DocumentConnection;
  id: Scalars['ID'];
  subject: Scalars['String'];
  timestamp: Scalars['DateTime'];
};


/** A message sent through Relay */
export type MessageAttachmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A message sent through Relay */
export type MessageDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Message. */
export type MessageConnection = {
  __typename?: 'MessageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MessageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Message>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  unreadCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Message>;
};

/** A MGA collaborating with a broker on a placement */
export type MgaCollaboration = Collaboration & Discussable & {
  __typename?: 'MgaCollaboration';
  collaborative: Collaborative;
  discussion?: Maybe<Discussion>;
  id: Scalars['ID'];
  status: Scalars['String'];
  type: Scalars['String'];
  user: User;
  /** @deprecated Migrating to using collaborative */
  worksheet: Worksheet;
};

/** Autogenerated input type of MigrateToCognito */
export type MigrateToCognitoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

/** Autogenerated return type of MigrateToCognito */
export type MigrateToCognitoPayload = {
  __typename?: 'MigrateToCognitoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** ML Quotation */
export type MlQuotation = NotificationRelatesTo & Quotation & {
  __typename?: 'MLQuotation';
  admitted?: Maybe<Scalars['Boolean']>;
  ambestRating?: Maybe<AmBestRating>;
  annualPremium?: Maybe<Scalars['Currency']>;
  apiData?: Maybe<Scalars['JSON']>;
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  binders: DocumentConnection;
  boundAt?: Maybe<Scalars['ISO8601DateTime']>;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentPolicy?: Maybe<Scalars['Boolean']>;
  declinationReason?: Maybe<Scalars['String']>;
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  derivedData: Scalars['JSON'];
  discussion?: Maybe<Discussion>;
  documents: Array<Document>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  extractedAt?: Maybe<Scalars['ISO8601DateTime']>;
  failedAt?: Maybe<Scalars['ISO8601DateTime']>;
  fields: Scalars['JSON'];
  id: Scalars['ID'];
  indication: Scalars['Boolean'];
  owner: User;
  participation: Participation;
  policies: DocumentConnection;
  policyNumber?: Maybe<Scalars['String']>;
  questions: QuestionConnection;
  quotedAt?: Maybe<Scalars['ISO8601DateTime']>;
  quotedLines: PanelsQuotedLineTypeConnection;
  requestedDeductible?: Maybe<Scalars['Currency']>;
  requestedLimit?: Maybe<Scalars['Currency']>;
  retailCommission?: Maybe<Scalars['Currency']>;
  retailCommissionPercentage?: Maybe<Scalars['Decimal']>;
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selected?: Maybe<Scalars['Boolean']>;
  selectedOptionId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  subjectivities: SubjectivityConnection;
  subjectivitiesAttributes?: Maybe<Array<SubjectivitiesAttributesType>>;
  submission: PanelSubmission;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};


/** ML Quotation */
export type MlQuotationBindersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** ML Quotation */
export type MlQuotationPoliciesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** ML Quotation */
export type MlQuotationQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** ML Quotation */
export type MlQuotationQuotedLinesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** ML Quotation */
export type MlQuotationSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MonetaryField = Field & {
  __typename?: 'MonetaryField';
  name: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['Currency']>;
};

export enum Month {
  April = 'April',
  August = 'August',
  December = 'December',
  February = 'February',
  January = 'January',
  July = 'July',
  June = 'June',
  March = 'March',
  May = 'May',
  November = 'November',
  October = 'October',
  September = 'September'
}

/** A graqhql type for the multi input type */
export type MultiInputDropdownInputType = {
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** A graqhql type for the multi type */
export type MultiInputDropdownType = {
  __typename?: 'MultiInputDropdownType';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Multiline Panel form schemas */
export type MultilinePanelsSchema = {
  __typename?: 'MultilinePanelsSchema';
  edit: Scalars['JSON'];
  new: Scalars['JSON'];
};


/** Multiline Panel form schemas */
export type MultilinePanelsSchemaEditArgs = {
  submissionId: Scalars['ID'];
};


/** Multiline Panel form schemas */
export type MultilinePanelsSchemaNewArgs = {
  panelIds: Array<Scalars['ID']>;
};

/** The mutation root for this schema */
export type Mutation = {
  __typename?: 'Mutation';
  /** Extract a quote from a PCG submission */
  PCGExtractDocument?: Maybe<PcgExtractDocumentPayload>;
  /** Accept an Answer */
  acceptAnswer?: Maybe<AcceptAnswerPayload>;
  /** Accept an invite to broker a placement */
  acceptBrokerInvite?: Maybe<AcceptBrokerInvitePayload>;
  /** Accept a certificate */
  acceptCertificate?: Maybe<AcceptCertificatePayload>;
  /** Accept a company invite & authenticate a user */
  acceptCompanyInvitation?: Maybe<AcceptCompanyInvitationPayload>;
  /** Accept someone to connect on RINK */
  acceptConnection?: Maybe<AcceptConnectionPayload>;
  /** Accept an Endorsement Quote */
  acceptEndorsementQuote?: Maybe<AcceptEndorsementQuotePayload>;
  /** Accept an invite & authenticate a user */
  acceptInvite?: Maybe<AcceptInvitePayload>;
  /** Accept a Counter-Proposal */
  acceptProposal?: Maybe<AcceptProposalPayload>;
  /** Accept a quote */
  acceptQuote?: Maybe<AcceptQuotePayload>;
  /** Accept terms and conditions for a user */
  acceptTerms?: Maybe<AcceptTermsPayload>;
  /** Add demo placements */
  addDemoPlacements?: Maybe<AddDemoPlacementsPayload>;
  /** Add demo quotes */
  addDemoQuotes?: Maybe<AddDemoQuotesPayload>;
  /** Approve a quote by client */
  approveQuote?: Maybe<ClientApprovalPayload>;
  /** Approve a revision of a quote */
  approveRevision?: Maybe<ApproveRevisionPayload>;
  /** Bind an NFP Reps & Warranties Quote */
  bindQuote?: Maybe<NfpRepsAndWarrantiesQuotationBindPayload>;
  /** Get basic, basic capture, and enhanced capture appetite from IVANS api */
  bopAppetite?: Maybe<BopAppetitePayload>;
  /** Return to previous BOP form state */
  bopReturnPreviousState?: Maybe<BopReturnPreviousStatePayload>;
  /** Cancel an NFP Small Deals Platform R&W Submission */
  cancelDeal?: Maybe<NfpRepsAndWarrantiesSubmissionCancelPayload>;
  /** Create new user and change the owner of a participation */
  changeParticipationNewOwner?: Maybe<ChangeParticipationWithNewOwnerPayload>;
  /** Change the owner of a participation */
  changeParticipationOwner?: Maybe<ChangeParticipationOwnerPayload>;
  /** Change the password of the current user */
  changePassword?: Maybe<ChangePasswordPayload>;
  /** Change the owner of a worksheet */
  changePlacementOwner?: Maybe<ChangeOwnerPayload>;
  /** Change the owner of a quote */
  changeQuoteOwner?: Maybe<ChangeQuoteOwnerPayload>;
  /** Create new user and change the owner of a quote */
  changeQuoteOwnerNewUser?: Maybe<ChangeQuoteWithNewOwnerPayload>;
  /** Change reference number for bound quotation */
  changeReferenceNumber?: Maybe<ChangeReferenceNumberPayload>;
  /** Change the owner of a submission */
  changeSubmissionOwner?: Maybe<ChangeSubmissionOwnerPayload>;
  /** Close an NFP Small Deals Platform R&W Submission */
  closeSubmission?: Maybe<NfpRepsAndWarrantiesSubmissionClosePayload>;
  /** Complete an endorsement */
  completeEndorsement?: Maybe<CompleteEndorsementPayload>;
  /** Receive signed document for an Endorsement Quote */
  completeEndorsementQuote?: Maybe<CompleteEndorsementQuotePayload>;
  /** Change the current user to be onboarded */
  completeRolePlay?: Maybe<CompleteRolePlayPayload>;
  /** Allows for user to confirm whether a quote is outdated */
  confirmOutdatedQuote?: Maybe<ConfirmOutdatedQuotePayload>;
  /** Create a new draft submission from an existing submission */
  copyWorksheet?: Maybe<CopyWorksheetPayload>;
  /** Create a new submission and send it to reinsurers */
  createAndSendWorksheet?: Maybe<CreateAndSendWorksheetPayload>;
  /** Create an Answer */
  createAnswer?: Maybe<CreateAnswerPayload>;
  /** Create a new authorized market */
  createAuthorizedMarket?: Maybe<CreateAuthorizedMarketPayload>;
  /** Create a new authorized market reinsurer for a cedent. */
  createAuthorizedMarketContact?: Maybe<CreateAuthorizedMarketContactPayload>;
  /** Bind an accepted quote */
  createBinder?: Maybe<CreateBinderPayload>;
  /** Complete and bind an accepted quote */
  createBinderWithQuote?: Maybe<CreateBinderWithQuotePayload>;
  /** Create a quotation for a given submission for a carrier outside of Relay */
  createCarrierPanelQuotation?: Maybe<CreateCarrierPanelQuotationPayload>;
  /** Access checkout for specific subscriptioin */
  createChargebeeCheckout?: Maybe<CreateChargebeeCheckoutPayload>;
  /** Access billing information */
  createChargebeePortalSession?: Maybe<CreateChargebeePortalSessionPayload>;
  /** Create an endorsement */
  createEndorsement?: Maybe<CreateEndorsementPayload>;
  /** Create a FollowUp Question */
  createFollowUpQuestion?: Maybe<CreateFollowUpQuestionPayload>;
  /** Create a quotation for a given submission for a carrier outside of Relay */
  createManualPanelQuotation?: Maybe<CreateManualPanelQuotationPayload>;
  /** Create a submission for the given panels */
  createMultilinePanelSubmission?: Maybe<CreateMultilinePanelSubmissionPayload>;
  /** Create a Signature Template via HelloSign API */
  createOrUpdateTemplate?: Maybe<CreateOrUpdateTemplatePayload>;
  /** Create a submission for a given panel */
  createPanelSubmission?: Maybe<CreatePanelSubmissionPayload>;
  /** Create a Question */
  createQuestion?: Maybe<CreateQuestionPayload>;
  /** Create a quote */
  createQuote?: Maybe<CreateQuotePayload>;
  /** Create a new revision of a quote */
  createRevision?: Maybe<CreateRevisionPayload>;
  /** Create an NFP Small Deals Platform R&W Submission */
  createSubmission?: Maybe<NfpRepsAndWarrantiesSubmissionCreatePayload>;
  /** Create a new draft submission */
  createWorksheet?: Maybe<CreateWorksheetPayload>;
  /** Adjust a Cyber Submission and generate new quotes */
  cyberAdjustQuotes?: Maybe<CyberAdjustQuotesPayload>;
  /** Archive or unarchive a quote for a cyber submission */
  cyberArchiveQuote?: Maybe<CyberArchiveQuotePayload>;
  /** Create a quote for a cyber submission */
  cyberBindQuote?: Maybe<CyberBindQuotePayload>;
  /** Create or update a draft Cyber Submission */
  cyberCreateDraftSubmission?: Maybe<CyberCreateDraftSubmissionPayload>;
  /** Add quote manually entered based on a quote that was generated outside of Relay */
  cyberCreateManualQuote?: Maybe<CyberCreateManualQuotePayload>;
  /** Create a quote for a cyber submission */
  cyberCreateQuote?: Maybe<CyberCreateQuotePayload>;
  /** Create a shareable link for a draft application */
  cyberCreateShareableApplicationLink?: Maybe<CyberCreateShareableApplicationLinkPayload>;
  /** Create a shareable link for a user */
  cyberCreateShareableLink?: Maybe<CyberCreateShareableLinkPayload>;
  /** Decline to quote a cyber submission */
  cyberDeclineQuote?: Maybe<CyberDeclineQuotePayload>;
  /** Email a shareable link for a draft application */
  cyberEmailShareableApplicationLink?: Maybe<EmailShareableApplicationLinkPayload>;
  /** Export Cyber Quote Comparison */
  cyberExportQuoteComparison?: Maybe<CyberExportQuoteComparisonPayload>;
  /** Export Cyber Submission */
  cyberExportSubmission?: Maybe<CyberExportSubmissionPayload>;
  /** Create a Cyber Submission and generate quotes */
  cyberGenerateQuotes?: Maybe<CyberGenerateQuotesPayload>;
  /** Import multiple cyber draft submissions */
  cyberImportDraftSubmissions?: Maybe<CyberImportDraftSubmissionsPayload>;
  /** Issue a policy for a bound cyber quote */
  cyberIssuePolicy?: Maybe<CyberIssuePolicyPayload>;
  /** Edit manual subjectivities of a cyber participation */
  cyberManualSubjectivities?: Maybe<CyberManualSubjectivitiesPayload>;
  /** Update all email quotes for specific carrier a cyber submission */
  cyberReinstateEmailQuotes?: Maybe<CyberReinstateEmailQuotesPayload>;
  /** Request to bind a cyber quote */
  cyberRequestToBind?: Maybe<CyberRequestToBindPayload>;
  /** Update a Cyber Submission and generate suggested codes */
  cyberSuggestCodesSubmission?: Maybe<CyberSuggestCodesSubmissionPayload>;
  /** Update a quote extracted from carrier pdf */
  cyberUpdateExtractedQuote?: Maybe<CyberUpdateExtractedQuotePayload>;
  /** Update quote manually entered based on a quote that was generated outside of Relay */
  cyberUpdateManualQuote?: Maybe<CyberUpdateManualQuotePayload>;
  /** Update a quote for a cyber submission */
  cyberUpdateQuote?: Maybe<CyberUpdateQuotePayload>;
  /** Update a Cyber Submission and re-generate quotes */
  cyberUpdateSubmission?: Maybe<CyberUpdateSubmissionPayload>;
  /** Update documents for a submission in Cyber */
  cyberUpdateSubmissionDocuments?: Maybe<CyberUpdateSubmissionDocumentsPayload>;
  /** Update a Cyber Submission and re-generate quotes depending on additional markets */
  cyberUpdateSubmissionMarkets?: Maybe<CyberUpdateSubmissionMarketsPayload>;
  /** Decline an Endorsement Quote */
  declineEndorsementQuote?: Maybe<DeclineEndorsementQuotePayload>;
  /** Decline a request for an NFP Reps & Warranties NDA */
  declineNDA?: Maybe<NfpRepsAndWarrantiesNdaDeclinePayload>;
  /** Decline to quote a panel submission */
  declinePanelQuote?: Maybe<DeclinePanelQuotePayload>;
  /** Decline a counter-proposal */
  declineProposal?: Maybe<DeclineProposalPayload>;
  /** Decline a quote */
  declineQuote?: Maybe<DeclineQuotePayload>;
  /** Decline a request for an NFP Reps & Warranties Quote */
  declineToQuote?: Maybe<NfpRepsAndWarrantiesQuotationDeclinePayload>;
  /** Delete an Answer */
  deleteAnswer?: Maybe<DeleteAnswerPayload>;
  /** Delete a reference from a reinsurer to an existing authorized market for a cedent. */
  deleteAuthorizedMarketContact?: Maybe<DeleteAuthorizedMarketContactPayload>;
  /** Delete Demo Worksheets */
  deleteDemoData?: Maybe<DeleteDemoDataPayload>;
  /** Delete a Question */
  deleteQuestion?: Maybe<DeleteQuestionPayload>;
  /** Delete a worksheet */
  deleteRevision?: Maybe<DeleteRevisionPayload>;
  /** Delete an NFP Small Deals Platform R&W Submission */
  deleteSubmission?: Maybe<NfpRepsAndWarrantiesSubmissionDeletePayload>;
  /** Delete a worksheet */
  deleteWorksheet?: Maybe<DeleteWorksheetPayload>;
  /** Disable an authorized market */
  disableAuthorizedMarket?: Maybe<DisableAuthorizedMarketPayload>;
  /** Drop an endorsement */
  dropEndorsement?: Maybe<DropEndorsementPayload>;
  /** Edit an Answer */
  editAnswer?: Maybe<EditAnswerPayload>;
  /** Edit a quotations for a given panel */
  editPanelQuotation?: Maybe<EditPanelQuotationPayload>;
  /** Edit a submission for a given panel */
  editPanelSubmission?: Maybe<EditPanelSubmissionPayload>;
  /** Edit a Question */
  editQuestion?: Maybe<EditQuestionPayload>;
  editRetailBroker?: Maybe<EditRetailBrokerPayload>;
  /** Edit and send a Submission already sent to markets */
  editSentWorksheet?: Maybe<EditSentWorksheetPayload>;
  /** Execute an NFP Reps & Warranties Quote */
  executeNDA?: Maybe<NfpRepsAndWarrantiesNdaExecutePayload>;
  /** Export all quotes as PDF and Word to BMS users */
  exportBmsQuotes?: Maybe<ExportBmsQuotesPayload>;
  /** Export Quotes as PDF */
  exportQuotation?: Maybe<NfpRepsAndWarrantiesQuotationExportPayload>;
  /** Export all quotes as PDF */
  exportQuotes?: Maybe<ExportQuotesPayload>;
  /** Export NFP Small Deals Platform R&W Submission as PDF */
  exportSubmission?: Maybe<NfpRepsAndWarrantiesSubmissionExportPayload>;
  /** Export all NFP quotes for a submission */
  exportSubmissionQuotes?: Maybe<NfpRepsAndWarrantiesQuotesExportPayload>;
  /** Export worksheets */
  exportWorksheet?: Maybe<ExportWorksheetsPayload>;
  /** Detailed Export worksheet */
  exportWorksheetDetails?: Maybe<ExportWorksheetDetailsPayload>;
  /** Finalize an NFP Small Deals Platform R&W Submission */
  finalizeSubmission?: Maybe<NfpRepsAndWarrantiesSubmissionFinalizePayload>;
  /** Forward a Document */
  forwardDocument?: Maybe<DocumentForwardPayload>;
  /** Generate the client report for an NFP Small Deals Platform R&W Submission */
  generateClientReport?: Maybe<NfpRepsAndWarrantiesClientReportGeneratePayload>;
  /** Ignore someone's connection request on RINK */
  ignoreConnection?: Maybe<IgnoreConnectionPayload>;
  /** Import a new placement from a template */
  importPlacement?: Maybe<ImportPlacementPayload>;
  /** Add import extraction document to table */
  importQuoteExtractionDocument?: Maybe<ImportQuoteExtractionDocumentPayload>;
  /** Archive and export all info for insurer */
  insurerExport?: Maybe<NfpRepsAndWarrantiesInsurerExportPayload>;
  /** Invite more reinsurers */
  inviteAdditionalMarkets?: Maybe<InviteAdditionalMarketsPayload>;
  /** Invite a broker to collaborate on a placement */
  inviteBroker?: Maybe<InviteBrokerPayload>;
  /** Invite someone to connect on RINK */
  inviteConnection?: Maybe<InviteConnectionPayload>;
  /** Invite an insurer to participate in an NFP Small Deals Platform R&W Submission */
  inviteParticipants?: Maybe<NfpRepsAndWarrantiesSubmissionInvitePayload>;
  /** Invite a retail broker as a wholesaler */
  inviteRetailBroker?: Maybe<InviteRetailBrokerPayload>;
  /** Invite a new user */
  inviteUser?: Maybe<InviteUserPayload>;
  /** Issue the certificate of reinsurance */
  issueCertificate?: Maybe<IssueCertificatePayload>;
  /** Issue an NFP Reps & Warranties Policy */
  issuePolicy?: Maybe<NfpRepsAndWarrantiesQuotationIssuePolicyPayload>;
  /** Change the profile of a user */
  manageUser?: Maybe<ManageUserPayload>;
  /** Set a users permissions */
  manageUserPermissions?: Maybe<ManageUserPermissionsPayload>;
  /** Create a new quote manually */
  manuallyCreateQuote?: Maybe<ManualCreateQuotePayload>;
  /** Mark as declined a quote */
  markAsDeclinedQuote?: Maybe<MarkAsDeclinedQuotePayload>;
  /** Authenticate a user and migrate them to Cognito */
  migrateUser?: Maybe<MigrateToCognitoPayload>;
  /** Change notification status to Actioned */
  notificationActioned?: Maybe<NotificationActionedPayload>;
  /** Mark all notifications as Actioned */
  notificationAllActioned?: Maybe<NotificationAllActionedPayload>;
  /** Change notification status to viewed */
  notificationViewed?: Maybe<NotificationViewedPayload>;
  /** Onboard a user */
  onboardUser?: Maybe<OnboardUserPayload>;
  /** Mark onboarding as profile completed */
  onboardingProfileCompleted?: Maybe<OnboardingProfileCompletedPayload>;
  /** Generate orphaned panel types */
  panelOrphanage?: Maybe<PanelOrphanagePayload>;
  /** Adjust a Multiline Submission and generate new quotes */
  panelsAdjustQuotes?: Maybe<PanelsAdjustQuotesPayload>;
  /** Create a quote for a panels submission */
  panelsBindQuote?: Maybe<PanelsBindQuotePayload>;
  /** Export Panels Quote Comparison */
  panelsExportQuoteComparison?: Maybe<PanelsExportQuoteComparisonPayload>;
  /** Issue a policy for a bound panels quote */
  panelsIssuePolicy?: Maybe<PanelsIssuePolicyPayload>;
  /** Request to bind a Panels quote */
  panelsRequestToBind?: Maybe<PanelsRequestToBindPayload>;
  /** Update documents for a submission */
  panelsUpdateSubmissionDocuments?: Maybe<PanelsUpdateSubmissionDocumentsPayload>;
  /** Auto save a PCG submission */
  pcgAutoSaveSubmission?: Maybe<PcgAutoSaveSubmissionPayload>;
  /** Update status of a PCG email quotation from binding to bound  */
  pcgBindEmailQuotation?: Maybe<PcgBindEmailQuotationPayload>;
  /** Update the status of a PCG quotation from quoted to bound */
  pcgBindManualQuotation?: Maybe<PcgBindManualQuotationPayload>;
  /** Create a new PCG email quotation */
  pcgCreateEmailQuotation?: Maybe<PcgCreateEmailQuotationPayload>;
  /** Create a new PCG manual quotation */
  pcgCreateManualQuotation?: Maybe<PcgCreateManualQuotationPayload>;
  /** Create a new PCG submission */
  pcgCreateSubmission?: Maybe<PcgCreateSubmissionPayload>;
  /** Decline to bind a  PCG email quotation */
  pcgDeclineToBindEmailQuotation?: Maybe<PcgDeclineToBindEmailQuotationPayload>;
  /** Delete a PCG manual quotation */
  pcgDeleteManualQuotation?: Maybe<PcgDeleteManualQuotationPayload>;
  /** Edit an existing PCG quotation */
  pcgEditQuotation?: Maybe<PcgEditQuotationPayload>;
  /** Edit an existing PCG submission */
  pcgEditSubmission?: Maybe<PcgEditSubmissionPayload>;
  /** Extract a quote from a PCG submission */
  pcgExtractDoc?: Maybe<PcgExtractDocumentPayload>;
  /** Update status of a PCG email quotation from quoted to binding */
  pcgRequestToBindEmailQuotation?: Maybe<PcgRequestToBindEmailQuotationPayload>;
  /** Save PCG Proposal Configuration */
  pcgSaveProposalConfiguration?: Maybe<PcgSaveProposalConfigurationPayload>;
  /** Post a broadcast on a worksheet to all participating markets */
  postBroadcast?: Maybe<PostBroadcastPayload>;
  /** Post a message on a quote discussion */
  postMessage?: Maybe<PostMessagePayload>;
  /** Post a broadcast to all deal participants */
  postSubmissionBroadcast?: Maybe<NfpRepsAndWarrantiesPostBroadcastPayload>;
  /** Edit quote before binding */
  preBindEdit?: Maybe<PreBindEditPayload>;
  /** Cedent proposal of a new revision of a quote */
  proposeRevision?: Maybe<ProposeRevisionPayload>;
  /** Provide the insurance policy number */
  providePolicy?: Maybe<ProvidePolicyPayload>;
  /** Create or update an NFP Reps & Warranties Quote */
  provideQuote?: Maybe<NfpRepsAndWarrantiesQuotationCreateOrUpdatePayload>;
  /** Updates the last message a user read */
  readMessage?: Maybe<ReadMessagePayload>;
  /** Release an Answer */
  releaseAnswer?: Maybe<ReleaseAnswerPayload>;
  /** Create a new draft submission from an existing submission */
  renewWorksheet?: Maybe<RenewWorksheetPayload>;
  /** Request approval from a manager */
  requestApproval?: Maybe<RequestApprovalPayload>;
  /** Request to bind after accepting counter-proposal */
  requestBinder?: Maybe<RequestBinderPayload>;
  /** Request to Bind an NFP Small Deals Platform R&W Submission */
  requestToBindSubmission?: Maybe<NfpRepsAndWarrantiesSubmissionRequestToBindPayload>;
  /** Reset the client report for an NFP Small Deals Platform R&W Submission */
  resetClientReport?: Maybe<NfpRepsAndWarrantiesClientReportResetPayload>;
  /** Revise an NFP Reps & Warranties NDA */
  reviseNDA?: Maybe<NfpRepsAndWarrantiesNdaRevisePayload>;
  /** Save Panel Proposal Configuration */
  savePanelProposalConfiguration?: Maybe<SavePanelProposalConfigurationPayload>;
  /** Select an NFP Reps & Warranties Quote */
  selectQuote?: Maybe<NfpRepsAndWarrantiesQuotationSelectPayload>;
  /** Send client report for an NFP Small Deals Platform R&W Submission */
  sendClientReport?: Maybe<NfpRepsAndWarrantiesClientReportSendPayload>;
  /** Send document to sign an Endorsement Quote */
  sendEndorsementQuote?: Maybe<SendEndorsementQuotePayload>;
  /** Send a draft submission out to markets for quotes */
  sendToMarkets?: Maybe<SendToMarketsPayload>;
  /** Set company settings */
  setCompanySettings?: Maybe<SetCompanySettingsPayload>;
  /** Set the internal comment for an NFP Reps & Warranties Quote */
  setQuoteComment?: Maybe<NfpRepsAndWarrantiesQuotationSetInternalCommentPayload>;
  /** Mark a quote as released or not released */
  setQuoteReleasedStatus?: Maybe<SetQuoteReleasedStatusPayload>;
  /** Share a questionable with a third party so they can answer questions */
  shareQuestionableForAnswering?: Maybe<ShareQuestionableForAnsweringPayload>;
  /** Sign up user via self-serve */
  signUp?: Maybe<SignUpPayload>;
  /** Sign up new user from a RINK connection invitation */
  signUpAndAcceptConnection?: Maybe<SignUpAndAcceptConnectionPayload>;
  /** Star or unstar an NFP Reps & Warranties Quote */
  starQuote?: Maybe<NfpRepsAndWarrantiesQuotationStarPayload>;
  /** Start an endorsement */
  startEndorsement?: Maybe<StartEndorsementPayload>;
  /** toggle the declined_at of a quoted layer */
  toggleDeclineQuotedLayer?: Maybe<ToggleDeclineQuotedLayerPayload>;
  /** Trigger submission export job */
  triggerSubmissionExport?: Maybe<TriggerSubmissionExportPayload>;
  /** Unapprove an Answer */
  unapproveAnswer?: Maybe<UnapproveAnswerPayload>;
  /** Update a draft submission and send it to reinsurers */
  updateAndSendWorksheet?: Maybe<UpdateAndSendWorksheetPayload>;
  /** Update an authorized market */
  updateAuthorizedMarket?: Maybe<UpdateAuthorizedMarketPayload>;
  /** Update broker comment on a quote */
  updateBrokerComment?: Maybe<UpdateBrokerCommentPayload>;
  /** Update the client report for an NFP Small Deals Platform R&W Submission */
  updateClientReport?: Maybe<NfpRepsAndWarrantiesClientReportUpdatePayload>;
  /** Update the commission percentage for a given quoted line */
  updateCommissionPercentage?: Maybe<UpdateCommissionPercentagePayload>;
  /** Update an endorsement */
  updateEndorsement?: Maybe<UpdateEndorsementPayload>;
  /** Update Surplus Lines License Information */
  updateLicenseInformation?: Maybe<UpdateLicenseInformationPayload>;
  /** Update documents for a participation */
  updateParticipationDocuments?: Maybe<UpdateParticipationDocumentsPayload>;
  /** Change the profile of the current user */
  updateProfile?: Maybe<UpdateProfilePayload>;
  /** Select / unselect a quote for a submission */
  updateQuotationSelection?: Maybe<QuotationsUpdateSelectionPayload>;
  /** Update an NFP Small Deals Platform R&W Submission */
  updateSubmission?: Maybe<NfpRepsAndWarrantiesSubmissionUpdatePayload>;
  /** Update Tagged Status via HelloSign API */
  updateTaggedStatus?: Maybe<UpdateTaggedStatusPayload>;
  /** Update a submission */
  updateWorksheet?: Maybe<UpdateWorksheetPayload>;
  /** Upload New Documents to an NFP Reps & Warranties Quotation */
  uploadDocuments?: Maybe<NfpRepsAndWarrantiesQuotationUploadDocumentsPayload>;
  /** Update Provider Last Viewed At */
  viewedQuote?: Maybe<ViewedQuotePayload>;
};


/** The mutation root for this schema */
export type MutationPcgExtractDocumentArgs = {
  input: PcgExtractDocumentInput;
};


/** The mutation root for this schema */
export type MutationAcceptAnswerArgs = {
  input: AcceptAnswerInput;
};


/** The mutation root for this schema */
export type MutationAcceptBrokerInviteArgs = {
  input: AcceptBrokerInviteInput;
};


/** The mutation root for this schema */
export type MutationAcceptCertificateArgs = {
  input: AcceptCertificateInput;
};


/** The mutation root for this schema */
export type MutationAcceptCompanyInvitationArgs = {
  input: AcceptCompanyInvitationInput;
};


/** The mutation root for this schema */
export type MutationAcceptConnectionArgs = {
  input: AcceptConnectionInput;
};


/** The mutation root for this schema */
export type MutationAcceptEndorsementQuoteArgs = {
  input: AcceptEndorsementQuoteInput;
};


/** The mutation root for this schema */
export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput;
};


/** The mutation root for this schema */
export type MutationAcceptProposalArgs = {
  input: AcceptProposalInput;
};


/** The mutation root for this schema */
export type MutationAcceptQuoteArgs = {
  input: AcceptQuoteInput;
};


/** The mutation root for this schema */
export type MutationAcceptTermsArgs = {
  input: AcceptTermsInput;
};


/** The mutation root for this schema */
export type MutationAddDemoPlacementsArgs = {
  input: AddDemoPlacementsInput;
};


/** The mutation root for this schema */
export type MutationAddDemoQuotesArgs = {
  input: AddDemoQuotesInput;
};


/** The mutation root for this schema */
export type MutationApproveQuoteArgs = {
  input: ClientApprovalInput;
};


/** The mutation root for this schema */
export type MutationApproveRevisionArgs = {
  input: ApproveRevisionInput;
};


/** The mutation root for this schema */
export type MutationBindQuoteArgs = {
  input: NfpRepsAndWarrantiesQuotationBindInput;
};


/** The mutation root for this schema */
export type MutationBopAppetiteArgs = {
  input: BopAppetiteInput;
};


/** The mutation root for this schema */
export type MutationBopReturnPreviousStateArgs = {
  input: BopReturnPreviousStateInput;
};


/** The mutation root for this schema */
export type MutationCancelDealArgs = {
  input: NfpRepsAndWarrantiesSubmissionCancelInput;
};


/** The mutation root for this schema */
export type MutationChangeParticipationNewOwnerArgs = {
  input: ChangeParticipationWithNewOwnerInput;
};


/** The mutation root for this schema */
export type MutationChangeParticipationOwnerArgs = {
  input: ChangeParticipationOwnerInput;
};


/** The mutation root for this schema */
export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


/** The mutation root for this schema */
export type MutationChangePlacementOwnerArgs = {
  input: ChangeOwnerInput;
};


/** The mutation root for this schema */
export type MutationChangeQuoteOwnerArgs = {
  input: ChangeQuoteOwnerInput;
};


/** The mutation root for this schema */
export type MutationChangeQuoteOwnerNewUserArgs = {
  input: ChangeQuoteWithNewOwnerInput;
};


/** The mutation root for this schema */
export type MutationChangeReferenceNumberArgs = {
  input: ChangeReferenceNumberInput;
};


/** The mutation root for this schema */
export type MutationChangeSubmissionOwnerArgs = {
  input: ChangeSubmissionOwnerInput;
};


/** The mutation root for this schema */
export type MutationCloseSubmissionArgs = {
  input: NfpRepsAndWarrantiesSubmissionCloseInput;
};


/** The mutation root for this schema */
export type MutationCompleteEndorsementArgs = {
  input: CompleteEndorsementInput;
};


/** The mutation root for this schema */
export type MutationCompleteEndorsementQuoteArgs = {
  input: CompleteEndorsementQuoteInput;
};


/** The mutation root for this schema */
export type MutationCompleteRolePlayArgs = {
  input: CompleteRolePlayInput;
};


/** The mutation root for this schema */
export type MutationConfirmOutdatedQuoteArgs = {
  input: ConfirmOutdatedQuoteInput;
};


/** The mutation root for this schema */
export type MutationCopyWorksheetArgs = {
  input: CopyWorksheetInput;
};


/** The mutation root for this schema */
export type MutationCreateAndSendWorksheetArgs = {
  input: CreateAndSendWorksheetInput;
};


/** The mutation root for this schema */
export type MutationCreateAnswerArgs = {
  input: CreateAnswerInput;
};


/** The mutation root for this schema */
export type MutationCreateAuthorizedMarketArgs = {
  input: CreateAuthorizedMarketInput;
};


/** The mutation root for this schema */
export type MutationCreateAuthorizedMarketContactArgs = {
  input: CreateAuthorizedMarketContactInput;
};


/** The mutation root for this schema */
export type MutationCreateBinderArgs = {
  input: CreateBinderInput;
};


/** The mutation root for this schema */
export type MutationCreateBinderWithQuoteArgs = {
  input: CreateBinderWithQuoteInput;
};


/** The mutation root for this schema */
export type MutationCreateCarrierPanelQuotationArgs = {
  input: CreateCarrierPanelQuotationInput;
};


/** The mutation root for this schema */
export type MutationCreateChargebeeCheckoutArgs = {
  input: CreateChargebeeCheckoutInput;
};


/** The mutation root for this schema */
export type MutationCreateChargebeePortalSessionArgs = {
  input: CreateChargebeePortalSessionInput;
};


/** The mutation root for this schema */
export type MutationCreateEndorsementArgs = {
  input: CreateEndorsementInput;
};


/** The mutation root for this schema */
export type MutationCreateFollowUpQuestionArgs = {
  input: CreateFollowUpQuestionInput;
};


/** The mutation root for this schema */
export type MutationCreateManualPanelQuotationArgs = {
  input: CreateManualPanelQuotationInput;
};


/** The mutation root for this schema */
export type MutationCreateMultilinePanelSubmissionArgs = {
  input: CreateMultilinePanelSubmissionInput;
};


/** The mutation root for this schema */
export type MutationCreateOrUpdateTemplateArgs = {
  input: CreateOrUpdateTemplateInput;
};


/** The mutation root for this schema */
export type MutationCreatePanelSubmissionArgs = {
  input: CreatePanelSubmissionInput;
};


/** The mutation root for this schema */
export type MutationCreateQuestionArgs = {
  input: CreateQuestionInput;
};


/** The mutation root for this schema */
export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};


/** The mutation root for this schema */
export type MutationCreateRevisionArgs = {
  input: CreateRevisionInput;
};


/** The mutation root for this schema */
export type MutationCreateSubmissionArgs = {
  input: NfpRepsAndWarrantiesSubmissionCreateInput;
};


/** The mutation root for this schema */
export type MutationCreateWorksheetArgs = {
  input: CreateWorksheetInput;
};


/** The mutation root for this schema */
export type MutationCyberAdjustQuotesArgs = {
  input: CyberAdjustQuotesInput;
};


/** The mutation root for this schema */
export type MutationCyberArchiveQuoteArgs = {
  input: CyberArchiveQuoteInput;
};


/** The mutation root for this schema */
export type MutationCyberBindQuoteArgs = {
  input: CyberBindQuoteInput;
};


/** The mutation root for this schema */
export type MutationCyberCreateDraftSubmissionArgs = {
  input: CyberCreateDraftSubmissionInput;
};


/** The mutation root for this schema */
export type MutationCyberCreateManualQuoteArgs = {
  input: CyberCreateManualQuoteInput;
};


/** The mutation root for this schema */
export type MutationCyberCreateQuoteArgs = {
  input: CyberCreateQuoteInput;
};


/** The mutation root for this schema */
export type MutationCyberCreateShareableApplicationLinkArgs = {
  input: CyberCreateShareableApplicationLinkInput;
};


/** The mutation root for this schema */
export type MutationCyberCreateShareableLinkArgs = {
  input: CyberCreateShareableLinkInput;
};


/** The mutation root for this schema */
export type MutationCyberDeclineQuoteArgs = {
  input: CyberDeclineQuoteInput;
};


/** The mutation root for this schema */
export type MutationCyberEmailShareableApplicationLinkArgs = {
  input: EmailShareableApplicationLinkInput;
};


/** The mutation root for this schema */
export type MutationCyberExportQuoteComparisonArgs = {
  input: CyberExportQuoteComparisonInput;
};


/** The mutation root for this schema */
export type MutationCyberExportSubmissionArgs = {
  input: CyberExportSubmissionInput;
};


/** The mutation root for this schema */
export type MutationCyberGenerateQuotesArgs = {
  input: CyberGenerateQuotesInput;
};


/** The mutation root for this schema */
export type MutationCyberImportDraftSubmissionsArgs = {
  input: CyberImportDraftSubmissionsInput;
};


/** The mutation root for this schema */
export type MutationCyberIssuePolicyArgs = {
  input: CyberIssuePolicyInput;
};


/** The mutation root for this schema */
export type MutationCyberManualSubjectivitiesArgs = {
  input: CyberManualSubjectivitiesInput;
};


/** The mutation root for this schema */
export type MutationCyberReinstateEmailQuotesArgs = {
  input: CyberReinstateEmailQuotesInput;
};


/** The mutation root for this schema */
export type MutationCyberRequestToBindArgs = {
  input: CyberRequestToBindInput;
};


/** The mutation root for this schema */
export type MutationCyberSuggestCodesSubmissionArgs = {
  input: CyberSuggestCodesSubmissionInput;
};


/** The mutation root for this schema */
export type MutationCyberUpdateExtractedQuoteArgs = {
  input: CyberUpdateExtractedQuoteInput;
};


/** The mutation root for this schema */
export type MutationCyberUpdateManualQuoteArgs = {
  input: CyberUpdateManualQuoteInput;
};


/** The mutation root for this schema */
export type MutationCyberUpdateQuoteArgs = {
  input: CyberUpdateQuoteInput;
};


/** The mutation root for this schema */
export type MutationCyberUpdateSubmissionArgs = {
  input: CyberUpdateSubmissionInput;
};


/** The mutation root for this schema */
export type MutationCyberUpdateSubmissionDocumentsArgs = {
  input: CyberUpdateSubmissionDocumentsInput;
};


/** The mutation root for this schema */
export type MutationCyberUpdateSubmissionMarketsArgs = {
  input: CyberUpdateSubmissionMarketsInput;
};


/** The mutation root for this schema */
export type MutationDeclineEndorsementQuoteArgs = {
  input: DeclineEndorsementQuoteInput;
};


/** The mutation root for this schema */
export type MutationDeclineNdaArgs = {
  input: NfpRepsAndWarrantiesNdaDeclineInput;
};


/** The mutation root for this schema */
export type MutationDeclinePanelQuoteArgs = {
  input: DeclinePanelQuoteInput;
};


/** The mutation root for this schema */
export type MutationDeclineProposalArgs = {
  input: DeclineProposalInput;
};


/** The mutation root for this schema */
export type MutationDeclineQuoteArgs = {
  input: DeclineQuoteInput;
};


/** The mutation root for this schema */
export type MutationDeclineToQuoteArgs = {
  input: NfpRepsAndWarrantiesQuotationDeclineInput;
};


/** The mutation root for this schema */
export type MutationDeleteAnswerArgs = {
  input: DeleteAnswerInput;
};


/** The mutation root for this schema */
export type MutationDeleteAuthorizedMarketContactArgs = {
  input: DeleteAuthorizedMarketContactInput;
};


/** The mutation root for this schema */
export type MutationDeleteDemoDataArgs = {
  input: DeleteDemoDataInput;
};


/** The mutation root for this schema */
export type MutationDeleteQuestionArgs = {
  input: DeleteQuestionInput;
};


/** The mutation root for this schema */
export type MutationDeleteRevisionArgs = {
  input: DeleteRevisionInput;
};


/** The mutation root for this schema */
export type MutationDeleteSubmissionArgs = {
  input: NfpRepsAndWarrantiesSubmissionDeleteInput;
};


/** The mutation root for this schema */
export type MutationDeleteWorksheetArgs = {
  input: DeleteWorksheetInput;
};


/** The mutation root for this schema */
export type MutationDisableAuthorizedMarketArgs = {
  input: DisableAuthorizedMarketInput;
};


/** The mutation root for this schema */
export type MutationDropEndorsementArgs = {
  input: DropEndorsementInput;
};


/** The mutation root for this schema */
export type MutationEditAnswerArgs = {
  input: EditAnswerInput;
};


/** The mutation root for this schema */
export type MutationEditPanelQuotationArgs = {
  input: EditPanelQuotationInput;
};


/** The mutation root for this schema */
export type MutationEditPanelSubmissionArgs = {
  input: EditPanelSubmissionInput;
};


/** The mutation root for this schema */
export type MutationEditQuestionArgs = {
  input: EditQuestionInput;
};


/** The mutation root for this schema */
export type MutationEditRetailBrokerArgs = {
  input: EditRetailBrokerInput;
};


/** The mutation root for this schema */
export type MutationEditSentWorksheetArgs = {
  input: EditSentWorksheetInput;
};


/** The mutation root for this schema */
export type MutationExecuteNdaArgs = {
  input: NfpRepsAndWarrantiesNdaExecuteInput;
};


/** The mutation root for this schema */
export type MutationExportBmsQuotesArgs = {
  input: ExportBmsQuotesInput;
};


/** The mutation root for this schema */
export type MutationExportQuotationArgs = {
  input: NfpRepsAndWarrantiesQuotationExportInput;
};


/** The mutation root for this schema */
export type MutationExportQuotesArgs = {
  input: ExportQuotesInput;
};


/** The mutation root for this schema */
export type MutationExportSubmissionArgs = {
  input: NfpRepsAndWarrantiesSubmissionExportInput;
};


/** The mutation root for this schema */
export type MutationExportSubmissionQuotesArgs = {
  input: NfpRepsAndWarrantiesQuotesExportInput;
};


/** The mutation root for this schema */
export type MutationExportWorksheetArgs = {
  input: ExportWorksheetsInput;
};


/** The mutation root for this schema */
export type MutationExportWorksheetDetailsArgs = {
  input: ExportWorksheetDetailsInput;
};


/** The mutation root for this schema */
export type MutationFinalizeSubmissionArgs = {
  input: NfpRepsAndWarrantiesSubmissionFinalizeInput;
};


/** The mutation root for this schema */
export type MutationForwardDocumentArgs = {
  input: DocumentForwardInput;
};


/** The mutation root for this schema */
export type MutationGenerateClientReportArgs = {
  input: NfpRepsAndWarrantiesClientReportGenerateInput;
};


/** The mutation root for this schema */
export type MutationIgnoreConnectionArgs = {
  input: IgnoreConnectionInput;
};


/** The mutation root for this schema */
export type MutationImportPlacementArgs = {
  input: ImportPlacementInput;
};


/** The mutation root for this schema */
export type MutationImportQuoteExtractionDocumentArgs = {
  input: ImportQuoteExtractionDocumentInput;
};


/** The mutation root for this schema */
export type MutationInsurerExportArgs = {
  input: NfpRepsAndWarrantiesInsurerExportInput;
};


/** The mutation root for this schema */
export type MutationInviteAdditionalMarketsArgs = {
  input: InviteAdditionalMarketsInput;
};


/** The mutation root for this schema */
export type MutationInviteBrokerArgs = {
  input: InviteBrokerInput;
};


/** The mutation root for this schema */
export type MutationInviteConnectionArgs = {
  input: InviteConnectionInput;
};


/** The mutation root for this schema */
export type MutationInviteParticipantsArgs = {
  input: NfpRepsAndWarrantiesSubmissionInviteInput;
};


/** The mutation root for this schema */
export type MutationInviteRetailBrokerArgs = {
  input: InviteRetailBrokerInput;
};


/** The mutation root for this schema */
export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


/** The mutation root for this schema */
export type MutationIssueCertificateArgs = {
  input: IssueCertificateInput;
};


/** The mutation root for this schema */
export type MutationIssuePolicyArgs = {
  input: NfpRepsAndWarrantiesQuotationIssuePolicyInput;
};


/** The mutation root for this schema */
export type MutationManageUserArgs = {
  input: ManageUserInput;
};


/** The mutation root for this schema */
export type MutationManageUserPermissionsArgs = {
  input: ManageUserPermissionsInput;
};


/** The mutation root for this schema */
export type MutationManuallyCreateQuoteArgs = {
  input: ManualCreateQuoteInput;
};


/** The mutation root for this schema */
export type MutationMarkAsDeclinedQuoteArgs = {
  input: MarkAsDeclinedQuoteInput;
};


/** The mutation root for this schema */
export type MutationMigrateUserArgs = {
  input: MigrateToCognitoInput;
};


/** The mutation root for this schema */
export type MutationNotificationActionedArgs = {
  input: NotificationActionedInput;
};


/** The mutation root for this schema */
export type MutationNotificationAllActionedArgs = {
  input: NotificationAllActionedInput;
};


/** The mutation root for this schema */
export type MutationNotificationViewedArgs = {
  input: NotificationViewedInput;
};


/** The mutation root for this schema */
export type MutationOnboardUserArgs = {
  input: OnboardUserInput;
};


/** The mutation root for this schema */
export type MutationOnboardingProfileCompletedArgs = {
  input: OnboardingProfileCompletedInput;
};


/** The mutation root for this schema */
export type MutationPanelOrphanageArgs = {
  input: PanelOrphanageInput;
};


/** The mutation root for this schema */
export type MutationPanelsAdjustQuotesArgs = {
  input: PanelsAdjustQuotesInput;
};


/** The mutation root for this schema */
export type MutationPanelsBindQuoteArgs = {
  input: PanelsBindQuoteInput;
};


/** The mutation root for this schema */
export type MutationPanelsExportQuoteComparisonArgs = {
  input: PanelsExportQuoteComparisonInput;
};


/** The mutation root for this schema */
export type MutationPanelsIssuePolicyArgs = {
  input: PanelsIssuePolicyInput;
};


/** The mutation root for this schema */
export type MutationPanelsRequestToBindArgs = {
  input: PanelsRequestToBindInput;
};


/** The mutation root for this schema */
export type MutationPanelsUpdateSubmissionDocumentsArgs = {
  input: PanelsUpdateSubmissionDocumentsInput;
};


/** The mutation root for this schema */
export type MutationPcgAutoSaveSubmissionArgs = {
  input: PcgAutoSaveSubmissionInput;
};


/** The mutation root for this schema */
export type MutationPcgBindEmailQuotationArgs = {
  input: PcgBindEmailQuotationInput;
};


/** The mutation root for this schema */
export type MutationPcgBindManualQuotationArgs = {
  input: PcgBindManualQuotationInput;
};


/** The mutation root for this schema */
export type MutationPcgCreateEmailQuotationArgs = {
  input: PcgCreateEmailQuotationInput;
};


/** The mutation root for this schema */
export type MutationPcgCreateManualQuotationArgs = {
  input: PcgCreateManualQuotationInput;
};


/** The mutation root for this schema */
export type MutationPcgCreateSubmissionArgs = {
  input: PcgCreateSubmissionInput;
};


/** The mutation root for this schema */
export type MutationPcgDeclineToBindEmailQuotationArgs = {
  input: PcgDeclineToBindEmailQuotationInput;
};


/** The mutation root for this schema */
export type MutationPcgDeleteManualQuotationArgs = {
  input: PcgDeleteManualQuotationInput;
};


/** The mutation root for this schema */
export type MutationPcgEditQuotationArgs = {
  input: PcgEditQuotationInput;
};


/** The mutation root for this schema */
export type MutationPcgEditSubmissionArgs = {
  input: PcgEditSubmissionInput;
};


/** The mutation root for this schema */
export type MutationPcgExtractDocArgs = {
  input: PcgExtractDocumentInput;
};


/** The mutation root for this schema */
export type MutationPcgRequestToBindEmailQuotationArgs = {
  input: PcgRequestToBindEmailQuotationInput;
};


/** The mutation root for this schema */
export type MutationPcgSaveProposalConfigurationArgs = {
  input: PcgSaveProposalConfigurationInput;
};


/** The mutation root for this schema */
export type MutationPostBroadcastArgs = {
  input: PostBroadcastInput;
};


/** The mutation root for this schema */
export type MutationPostMessageArgs = {
  input: PostMessageInput;
};


/** The mutation root for this schema */
export type MutationPostSubmissionBroadcastArgs = {
  input: NfpRepsAndWarrantiesPostBroadcastInput;
};


/** The mutation root for this schema */
export type MutationPreBindEditArgs = {
  input: PreBindEditInput;
};


/** The mutation root for this schema */
export type MutationProposeRevisionArgs = {
  input: ProposeRevisionInput;
};


/** The mutation root for this schema */
export type MutationProvidePolicyArgs = {
  input: ProvidePolicyInput;
};


/** The mutation root for this schema */
export type MutationProvideQuoteArgs = {
  input: NfpRepsAndWarrantiesQuotationCreateOrUpdateInput;
};


/** The mutation root for this schema */
export type MutationReadMessageArgs = {
  input: ReadMessageInput;
};


/** The mutation root for this schema */
export type MutationReleaseAnswerArgs = {
  input: ReleaseAnswerInput;
};


/** The mutation root for this schema */
export type MutationRenewWorksheetArgs = {
  input: RenewWorksheetInput;
};


/** The mutation root for this schema */
export type MutationRequestApprovalArgs = {
  input: RequestApprovalInput;
};


/** The mutation root for this schema */
export type MutationRequestBinderArgs = {
  input: RequestBinderInput;
};


/** The mutation root for this schema */
export type MutationRequestToBindSubmissionArgs = {
  input: NfpRepsAndWarrantiesSubmissionRequestToBindInput;
};


/** The mutation root for this schema */
export type MutationResetClientReportArgs = {
  input: NfpRepsAndWarrantiesClientReportResetInput;
};


/** The mutation root for this schema */
export type MutationReviseNdaArgs = {
  input: NfpRepsAndWarrantiesNdaReviseInput;
};


/** The mutation root for this schema */
export type MutationSavePanelProposalConfigurationArgs = {
  input: SavePanelProposalConfigurationInput;
};


/** The mutation root for this schema */
export type MutationSelectQuoteArgs = {
  input: NfpRepsAndWarrantiesQuotationSelectInput;
};


/** The mutation root for this schema */
export type MutationSendClientReportArgs = {
  input: NfpRepsAndWarrantiesClientReportSendInput;
};


/** The mutation root for this schema */
export type MutationSendEndorsementQuoteArgs = {
  input: SendEndorsementQuoteInput;
};


/** The mutation root for this schema */
export type MutationSendToMarketsArgs = {
  input: SendToMarketsInput;
};


/** The mutation root for this schema */
export type MutationSetCompanySettingsArgs = {
  input: SetCompanySettingsInput;
};


/** The mutation root for this schema */
export type MutationSetQuoteCommentArgs = {
  input: NfpRepsAndWarrantiesQuotationSetInternalCommentInput;
};


/** The mutation root for this schema */
export type MutationSetQuoteReleasedStatusArgs = {
  input: SetQuoteReleasedStatusInput;
};


/** The mutation root for this schema */
export type MutationShareQuestionableForAnsweringArgs = {
  input: ShareQuestionableForAnsweringInput;
};


/** The mutation root for this schema */
export type MutationSignUpArgs = {
  input: SignUpInput;
};


/** The mutation root for this schema */
export type MutationSignUpAndAcceptConnectionArgs = {
  input: SignUpAndAcceptConnectionInput;
};


/** The mutation root for this schema */
export type MutationStarQuoteArgs = {
  input: NfpRepsAndWarrantiesQuotationStarInput;
};


/** The mutation root for this schema */
export type MutationStartEndorsementArgs = {
  input: StartEndorsementInput;
};


/** The mutation root for this schema */
export type MutationToggleDeclineQuotedLayerArgs = {
  input: ToggleDeclineQuotedLayerInput;
};


/** The mutation root for this schema */
export type MutationTriggerSubmissionExportArgs = {
  input: TriggerSubmissionExportInput;
};


/** The mutation root for this schema */
export type MutationUnapproveAnswerArgs = {
  input: UnapproveAnswerInput;
};


/** The mutation root for this schema */
export type MutationUpdateAndSendWorksheetArgs = {
  input: UpdateAndSendWorksheetInput;
};


/** The mutation root for this schema */
export type MutationUpdateAuthorizedMarketArgs = {
  input: UpdateAuthorizedMarketInput;
};


/** The mutation root for this schema */
export type MutationUpdateBrokerCommentArgs = {
  input: UpdateBrokerCommentInput;
};


/** The mutation root for this schema */
export type MutationUpdateClientReportArgs = {
  input: NfpRepsAndWarrantiesClientReportUpdateInput;
};


/** The mutation root for this schema */
export type MutationUpdateCommissionPercentageArgs = {
  input: UpdateCommissionPercentageInput;
};


/** The mutation root for this schema */
export type MutationUpdateEndorsementArgs = {
  input: UpdateEndorsementInput;
};


/** The mutation root for this schema */
export type MutationUpdateLicenseInformationArgs = {
  input: UpdateLicenseInformationInput;
};


/** The mutation root for this schema */
export type MutationUpdateParticipationDocumentsArgs = {
  input: UpdateParticipationDocumentsInput;
};


/** The mutation root for this schema */
export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


/** The mutation root for this schema */
export type MutationUpdateQuotationSelectionArgs = {
  input: QuotationsUpdateSelectionInput;
};


/** The mutation root for this schema */
export type MutationUpdateSubmissionArgs = {
  input: NfpRepsAndWarrantiesSubmissionUpdateInput;
};


/** The mutation root for this schema */
export type MutationUpdateTaggedStatusArgs = {
  input: UpdateTaggedStatusInput;
};


/** The mutation root for this schema */
export type MutationUpdateWorksheetArgs = {
  input: UpdateWorksheetInput;
};


/** The mutation root for this schema */
export type MutationUploadDocumentsArgs = {
  input: NfpRepsAndWarrantiesQuotationUploadDocumentsInput;
};


/** The mutation root for this schema */
export type MutationViewedQuoteArgs = {
  input: ViewedQuoteInput;
};

/** The query root for the NFP Small Deals Platform */
export type NfpRepsAndWarranties = {
  __typename?: 'NFPRepsAndWarranties';
  brokerDashboard: NfpRepsAndWarrantiesBrokerDashboard;
  insurerDashboard: NfpRepsAndWarrantiesInsurerDashboard;
  participation?: Maybe<Participation>;
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
  submissions: NfpRepsAndWarrantiesSubmissionConnection;
};


/** The query root for the NFP Small Deals Platform */
export type NfpRepsAndWarrantiesBrokerDashboardArgs = {
  visibility?: Scalars['String'];
};


/** The query root for the NFP Small Deals Platform */
export type NfpRepsAndWarrantiesInsurerDashboardArgs = {
  visibility?: Scalars['String'];
};


/** The query root for the NFP Small Deals Platform */
export type NfpRepsAndWarrantiesParticipationArgs = {
  participationId: Scalars['ID'];
};


/** The query root for the NFP Small Deals Platform */
export type NfpRepsAndWarrantiesSubmissionArgs = {
  submissionId: Scalars['ID'];
};


/** The query root for the NFP Small Deals Platform */
export type NfpRepsAndWarrantiesSubmissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** NFP Small Deals Platform Reps & Warranties Advisor */
export type NfpRepsAndWarrantiesAdvisor = {
  __typename?: 'NFPRepsAndWarrantiesAdvisor';
  contacts: Array<NfpRepsAndWarrantiesAdvisorContact>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  kind: Scalars['String'];
  organizationName: Scalars['String'];
  reportExpected?: Maybe<Scalars['String']>;
  submission: NfpRepsAndWarrantiesSubmission;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** NFP Small Deals Platform Reps & Warranties Advisor Contact */
export type NfpRepsAndWarrantiesAdvisorContact = {
  __typename?: 'NFPRepsAndWarrantiesAdvisorContact';
  advisor: NfpRepsAndWarrantiesAdvisor;
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Errors for an NFP Reps & Warranties Submission Advisor Contact */
export type NfpRepsAndWarrantiesAdvisorContactErrors = {
  __typename?: 'NFPRepsAndWarrantiesAdvisorContactErrors';
  _destroy?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

/** Attributes for an NFP Reps & Warranties Submission Advisor Contact */
export type NfpRepsAndWarrantiesAdvisorContactInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
};

/** Errors for an NFP Reps & Warranties Submission Advisor */
export type NfpRepsAndWarrantiesAdvisorErrors = {
  __typename?: 'NFPRepsAndWarrantiesAdvisorErrors';
  _destroy?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  contacts?: Maybe<NfpRepsAndWarrantiesAdvisorContactErrors>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  reportExpected?: Maybe<Scalars['String']>;
};

/** Attributes for an NFP Reps & Warranties Submission Advisor */
export type NfpRepsAndWarrantiesAdvisorInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  contacts?: Maybe<Array<NfpRepsAndWarrantiesAdvisorContactInput>>;
  id?: Maybe<Scalars['ID']>;
  kind: Scalars['String'];
  organizationName: Scalars['String'];
  reportExpected?: Maybe<Scalars['String']>;
};

/** NFP Small Deals Platform Reps & Warranties Best Coverage Info */
export type NfpRepsAndWarrantiesBestCoverage = {
  __typename?: 'NFPRepsAndWarrantiesBestCoverage';
  coverage: Scalars['String'];
  insurer: Scalars['String'];
};

/** Attributes for binding an NFP Reps & Warranties Quotation */
export type NfpRepsAndWarrantiesBindInput = {
  documents?: Maybe<Array<DocumentInput>>;
  message: Scalars['String'];
};

/** An NFP Small Deals Platform Reps & Warranties Broker */
export type NfpRepsAndWarrantiesBroker = User & {
  __typename?: 'NFPRepsAndWarrantiesBroker';
  aboutMe?: Maybe<Scalars['String']>;
  api: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  chatToken?: Maybe<Scalars['String']>;
  company: Company;
  /** @deprecated Company name is accessible through the related company object */
  companyName: Scalars['String'];
  directReports: UserConnection;
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  emailSignOff?: Maybe<Scalars['Text']>;
  externallyManaged: Scalars['Boolean'];
  features: Scalars['JSON'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  generalInbox: Scalars['Boolean'];
  id: Scalars['ID'];
  indicationProvided?: Maybe<Scalars['Boolean']>;
  industries?: Maybe<Array<Scalars['String']>>;
  invitedBy?: Maybe<User>;
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  linesOfBusiness?: Maybe<Array<Scalars['String']>>;
  linkedinUrl?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['String']>>;
  manager?: Maybe<User>;
  mfaCorporateEmailRequired?: Maybe<Scalars['Boolean']>;
  mfaCorporateNetworksRequired?: Maybe<Scalars['Boolean']>;
  mfaRemoteNetworkAccessRequired?: Maybe<Scalars['Boolean']>;
  notificationEvents: NotificationEventConnection;
  notificationSettings?: Maybe<NotificationSettingsType>;
  /** @deprecated Replaced by the onboarding field */
  onboardedAt?: Maybe<Scalars['Date']>;
  onboarding?: Maybe<Scalars['String']>;
  pendingInvitation: Scalars['Boolean'];
  permissions: PermissionConnection;
  phoneNumber: Scalars['String'];
  reinsurers: ReinsurerConnection;
  reports?: Maybe<Report>;
  rinkAccount: Account;
  role?: Maybe<Array<Scalars['String']>>;
  search: Array<Worksheet>;
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
  submissions: NfpRepsAndWarrantiesSubmissionConnection;
  templateImport?: Maybe<TemplateImport>;
  templateImports: TemplateImportConnection;
  termsAndConditionsAcceptedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  uuid: Scalars['ID'];
  websiteOptional?: Maybe<Scalars['Boolean']>;
};


/** An NFP Small Deals Platform Reps & Warranties Broker */
export type NfpRepsAndWarrantiesBrokerDirectReportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An NFP Small Deals Platform Reps & Warranties Broker */
export type NfpRepsAndWarrantiesBrokerNotificationEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An NFP Small Deals Platform Reps & Warranties Broker */
export type NfpRepsAndWarrantiesBrokerPermissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An NFP Small Deals Platform Reps & Warranties Broker */
export type NfpRepsAndWarrantiesBrokerReinsurersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An NFP Small Deals Platform Reps & Warranties Broker */
export type NfpRepsAndWarrantiesBrokerSearchArgs = {
  order?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


/** An NFP Small Deals Platform Reps & Warranties Broker */
export type NfpRepsAndWarrantiesBrokerSubmissionArgs = {
  submissionId: Scalars['ID'];
};


/** An NFP Small Deals Platform Reps & Warranties Broker */
export type NfpRepsAndWarrantiesBrokerSubmissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An NFP Small Deals Platform Reps & Warranties Broker */
export type NfpRepsAndWarrantiesBrokerTemplateImportArgs = {
  importId: Scalars['ID'];
};


/** An NFP Small Deals Platform Reps & Warranties Broker */
export type NfpRepsAndWarrantiesBrokerTemplateImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The query object for the Broker dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesBrokerDashboard = {
  __typename?: 'NFPRepsAndWarrantiesBrokerDashboard';
  analytics: NfpRepsAndWarrantiesBrokerDashboardAnalytics;
  statuses: NfpRepsAndWarrantiesDashboardStatuses;
  submissions: NfpRepsAndWarrantiesSubmissionConnection;
};


/** The query object for the Broker dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesBrokerDashboardAnalyticsArgs = {
  period?: Maybe<Scalars['String']>;
};


/** The query object for the Broker dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesBrokerDashboardStatusesArgs = {
  period?: Maybe<Scalars['String']>;
};


/** The query object for the Broker dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesBrokerDashboardSubmissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

/** Submission analytics on the Broker dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesBrokerDashboardAnalytics = {
  __typename?: 'NFPRepsAndWarrantiesBrokerDashboardAnalytics';
  totalLimitBound: Scalars['Currency'];
  totalPremiumBound: Scalars['Currency'];
  totalRevenueBound: Scalars['Currency'];
  totalRevenueNotBound: Scalars['Currency'];
};

/** NFP Small Deals Platform Reps & Warranties Client Report */
export type NfpRepsAndWarrantiesClientReport = {
  __typename?: 'NFPRepsAndWarrantiesClientReport';
  bestCoverage: Array<NfpRepsAndWarrantiesBestCoverage>;
  createdAt: Scalars['ISO8601DateTime'];
  deemedQualificationAndOtherCoverageCommentary?: Maybe<Scalars['Text']>;
  detailedCoverageEnhancements: Array<NfpRepsAndWarrantiesCoverageEnhancementReport>;
  detailedDeemedQualificationAndOtherCoverageCommentary: Array<NfpRepsAndWarrantiesDetailedDeemedQualificationAndOtherCoverageCommentary>;
  detailedExclusionsAndEnhancedDueDiligence: Array<NfpRepsAndWarrantiesDetailedExclusionsAndEnhancedDueDiligence>;
  exclusionsAndEnhancedDueDiligence?: Maybe<Scalars['Text']>;
  executiveSummary?: Maybe<Scalars['Text']>;
  id: Scalars['ID'];
  importantInformation?: Maybe<Scalars['Text']>;
  insurerResponses: Array<NfpRepsAndWarrantiesInsurerResponsesType>;
  introduction?: Maybe<Scalars['Text']>;
  rolloverCoverage?: Maybe<Scalars['String']>;
  selectedOption: NfpRepsAndWarrantiesOption;
  submission: NfpRepsAndWarrantiesSubmission;
  submissionMaterialsAndAssumptions?: Maybe<Scalars['Text']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Errors for an NFP Reps & Warranties Submission Client Report */
export type NfpRepsAndWarrantiesClientReportErrors = {
  __typename?: 'NFPRepsAndWarrantiesClientReportErrors';
  base?: Maybe<Scalars['String']>;
  bestCoverage?: Maybe<Scalars['String']>;
  deemedQualificationAndOtherCoverageCommentary?: Maybe<Scalars['String']>;
  detailedCoverageEnhancements?: Maybe<Scalars['String']>;
  detailedDeemedQualificationAndOtherCoverageCommentary?: Maybe<Scalars['String']>;
  detailedExclusionsAndEnhancedDueDiligence?: Maybe<Scalars['String']>;
  exclusionsAndEnhancedDueDiligence?: Maybe<Scalars['String']>;
  executiveSummary?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  importantInformation?: Maybe<Scalars['String']>;
  insurerResponses?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  rolloverCoverage?: Maybe<Scalars['String']>;
  submissionMaterialsAndAssumptions?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesClientReportGenerate */
export type NfpRepsAndWarrantiesClientReportGenerateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  selectedOptionId: Scalars['ID'];
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesClientReportGenerate */
export type NfpRepsAndWarrantiesClientReportGeneratePayload = {
  __typename?: 'NFPRepsAndWarrantiesClientReportGeneratePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientReport?: Maybe<NfpRepsAndWarrantiesClientReport>;
  error?: Maybe<Scalars['String']>;
};

/** Attributes for an NFP Reps & Warranties Submission Client Report */
export type NfpRepsAndWarrantiesClientReportInput = {
  bestCoverage: Scalars['JSON'];
  deemedQualificationAndOtherCoverageCommentary?: Maybe<Scalars['Text']>;
  detailedCoverageEnhancements?: Maybe<Scalars['JSON']>;
  detailedDeemedQualificationAndOtherCoverageCommentary: Array<Scalars['JSON']>;
  detailedExclusionsAndEnhancedDueDiligence: Array<Scalars['JSON']>;
  exclusionsAndEnhancedDueDiligence?: Maybe<Scalars['Text']>;
  executiveSummary?: Maybe<Scalars['Text']>;
  id?: Maybe<Scalars['ID']>;
  importantInformation?: Maybe<Scalars['Text']>;
  insurerResponses: Array<Scalars['JSON']>;
  introduction?: Maybe<Scalars['Text']>;
  rolloverCoverage?: Maybe<Scalars['String']>;
  submissionMaterialsAndAssumptions?: Maybe<Scalars['Text']>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesClientReportReset */
export type NfpRepsAndWarrantiesClientReportResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesClientReportReset */
export type NfpRepsAndWarrantiesClientReportResetPayload = {
  __typename?: 'NFPRepsAndWarrantiesClientReportResetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesClientReportSend */
export type NfpRepsAndWarrantiesClientReportSendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesClientReportSend */
export type NfpRepsAndWarrantiesClientReportSendPayload = {
  __typename?: 'NFPRepsAndWarrantiesClientReportSendPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesClientReportUpdate */
export type NfpRepsAndWarrantiesClientReportUpdateInput = {
  attributes: NfpRepsAndWarrantiesClientReportInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesClientReportUpdate */
export type NfpRepsAndWarrantiesClientReportUpdatePayload = {
  __typename?: 'NFPRepsAndWarrantiesClientReportUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientReport?: Maybe<NfpRepsAndWarrantiesClientReport>;
  errors?: Maybe<NfpRepsAndWarrantiesClientReportErrors>;
};

/** NFP Small Deals Platform Reps & Warranties Coverage Comment */
export type NfpRepsAndWarrantiesCoverageComment = {
  __typename?: 'NFPRepsAndWarrantiesCoverageComment';
  comment?: Maybe<Scalars['String']>;
  insurer: Scalars['String'];
};

/** A coverage enhancement for a Reps & Warranties submission */
export type NfpRepsAndWarrantiesCoverageEnhancement = {
  __typename?: 'NFPRepsAndWarrantiesCoverageEnhancement';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  submission: NfpRepsAndWarrantiesSubmission;
  text?: Maybe<Scalars['Text']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Errors for a coverage enhancement for a Reps & Warranties submission */
export type NfpRepsAndWarrantiesCoverageEnhancementErrors = {
  __typename?: 'NFPRepsAndWarrantiesCoverageEnhancementErrors';
  _destroy?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Attributes for a coverage enhancement for a Reps & Warranties submission */
export type NfpRepsAndWarrantiesCoverageEnhancementInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['Text']>;
  title?: Maybe<Scalars['String']>;
};

/** NFP Small Deals Platform Reps & Warranties Coverage Enhancement Report Info */
export type NfpRepsAndWarrantiesCoverageEnhancementReport = {
  __typename?: 'NFPRepsAndWarrantiesCoverageEnhancementReport';
  comments?: Maybe<Array<NfpRepsAndWarrantiesCoverageComment>>;
  coverageEnhancementId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

/** The statuses of submissions on the dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesDashboardStatuses = {
  __typename?: 'NFPRepsAndWarrantiesDashboardStatuses';
  bound: Scalars['Int'];
  closed: Scalars['Int'];
  draft: Scalars['Int'];
  issued: Scalars['Int'];
  quoted: Scalars['Int'];
  sent: Scalars['Int'];
  underwriting: Scalars['Int'];
};

/** NFP Small Deals Platform Reps & Warranties Detailed Coverage Commentary */
export type NfpRepsAndWarrantiesDetailedDeemedQualificationAndOtherCoverageCommentary = {
  __typename?: 'NFPRepsAndWarrantiesDetailedDeemedQualificationAndOtherCoverageCommentary';
  commentary?: Maybe<Scalars['String']>;
  insurer: Scalars['String'];
};

/** NFP Small Deals Platform Reps & Warranties Detailed Exclusions & Enhanced Due Diligence */
export type NfpRepsAndWarrantiesDetailedExclusionsAndEnhancedDueDiligence = {
  __typename?: 'NFPRepsAndWarrantiesDetailedExclusionsAndEnhancedDueDiligence';
  dueDiligence: Array<Scalars['String']>;
  exclusions: Array<Scalars['String']>;
  insurer: Scalars['String'];
};

/** The query object for the insurer dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesInsurerDashboard = {
  __typename?: 'NFPRepsAndWarrantiesInsurerDashboard';
  analytics: NfpRepsAndWarrantiesInsurerDashboardAnalytics;
  requests: ParticipationConnection;
  statuses: NfpRepsAndWarrantiesDashboardStatuses;
};


/** The query object for the insurer dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesInsurerDashboardAnalyticsArgs = {
  period?: Maybe<Scalars['String']>;
};


/** The query object for the insurer dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesInsurerDashboardRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


/** The query object for the insurer dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesInsurerDashboardStatusesArgs = {
  period?: Maybe<Scalars['String']>;
};

/** Submission analytics on the Insurer dashboard for the Small Deals Platform */
export type NfpRepsAndWarrantiesInsurerDashboardAnalytics = {
  __typename?: 'NFPRepsAndWarrantiesInsurerDashboardAnalytics';
  quoteSelectionRate: Scalars['Percentage'];
  totalLimitBound: Scalars['Currency'];
  totalNetPremiumsBound: Scalars['Currency'];
};

/** Autogenerated input type of NFPRepsAndWarrantiesInsurerExport */
export type NfpRepsAndWarrantiesInsurerExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  participationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesInsurerExport */
export type NfpRepsAndWarrantiesInsurerExportPayload = {
  __typename?: 'NFPRepsAndWarrantiesInsurerExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** NFP Small Deals Platform Reps & Warranties Insurer Responses */
export type NfpRepsAndWarrantiesInsurerResponsesType = {
  __typename?: 'NFPRepsAndWarrantiesInsurerResponsesType';
  declined: Scalars['Boolean'];
  id: Scalars['ID'];
  quoted: Scalars['Boolean'];
  status: Scalars['String'];
};

/** Attributes for binding an NFP Reps & Warranties Quotation */
export type NfpRepsAndWarrantiesIssuePolicyInput = {
  documents?: Maybe<Array<DocumentInput>>;
  message: Scalars['String'];
};

/** Autogenerated input type of NFPRepsAndWarrantiesNDADecline */
export type NfpRepsAndWarrantiesNdaDeclineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  participationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesNDADecline */
export type NfpRepsAndWarrantiesNdaDeclinePayload = {
  __typename?: 'NFPRepsAndWarrantiesNDADeclinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ParticipationErrors>;
  participation?: Maybe<Participation>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesNDAExecute */
export type NfpRepsAndWarrantiesNdaExecuteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentInput>>;
  participationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesNDAExecute */
export type NfpRepsAndWarrantiesNdaExecutePayload = {
  __typename?: 'NFPRepsAndWarrantiesNDAExecutePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ParticipationErrors>;
  participation?: Maybe<Participation>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesNDARevise */
export type NfpRepsAndWarrantiesNdaReviseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentInput>>;
  participationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesNDARevise */
export type NfpRepsAndWarrantiesNdaRevisePayload = {
  __typename?: 'NFPRepsAndWarrantiesNDARevisePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ParticipationErrors>;
  participation?: Maybe<Participation>;
};

/** NFP Small Deals Platform Reps & Warranties Option */
export type NfpRepsAndWarrantiesOption = {
  __typename?: 'NFPRepsAndWarrantiesOption';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  label: Scalars['String'];
  limitAmount?: Maybe<Scalars['Decimal']>;
  limitPercentage?: Maybe<Scalars['Decimal']>;
  retentionType: Scalars['String'];
  submission: NfpRepsAndWarrantiesSubmission;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Errors for an NFP Reps & Warranties Submission Option */
export type NfpRepsAndWarrantiesOptionErrors = {
  __typename?: 'NFPRepsAndWarrantiesOptionErrors';
  _destroy?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  limitAmount?: Maybe<Scalars['String']>;
  limitPercentage?: Maybe<Scalars['String']>;
  retentionType?: Maybe<Scalars['String']>;
};

/** Attributes for an NFP Reps & Warranties Submission Option */
export type NfpRepsAndWarrantiesOptionInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  label: Scalars['String'];
  limitAmount?: Maybe<Scalars['Decimal']>;
  limitPercentage?: Maybe<Scalars['Decimal']>;
  retentionType: Scalars['String'];
};

/** Autogenerated input type of NFPRepsAndWarrantiesPostBroadcast */
export type NfpRepsAndWarrantiesPostBroadcastInput = {
  attachments?: Maybe<Scalars['Upload']>;
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  submissionId: Scalars['ID'];
  type?: Maybe<BroadcastType>;
};

/** Autogenerated return type of NFPRepsAndWarrantiesPostBroadcast */
export type NfpRepsAndWarrantiesPostBroadcastPayload = {
  __typename?: 'NFPRepsAndWarrantiesPostBroadcastPayload';
  broadcast?: Maybe<Broadcast>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** NFP Small Deals Platform Reps & Warranties Quotation */
export type NfpRepsAndWarrantiesQuotation = NotificationRelatesTo & Questionable & Quotation & Shareable & Subjectivitable & {
  __typename?: 'NFPRepsAndWarrantiesQuotation';
  ambestRating?: Maybe<AmBestRating>;
  dealSpecificExclusions?: Maybe<Array<Scalars['String']>>;
  documents: Array<Document>;
  dropdownAfter: Scalars['String'];
  enhancedDueDiligenceAreas?: Maybe<Array<Scalars['String']>>;
  expectedRolloverCovered: Scalars['Boolean'];
  id: Scalars['ID'];
  indication: Scalars['Boolean'];
  insurerInternalComment?: Maybe<Scalars['String']>;
  internalComment: Scalars['Text'];
  maximumNcdRollover: Scalars['Percentage'];
  otherCoverageCommentary?: Maybe<Scalars['String']>;
  owner: User;
  participation: Participation;
  questions: QuestionConnection;
  quotationCoverageEnhancements: Array<NfpRepsAndWarrantiesQuotationCoverageEnhancement>;
  quotationOptions: Array<NfpRepsAndWarrantiesQuotationOption>;
  selected?: Maybe<Scalars['Boolean']>;
  selectedOptionId?: Maybe<Scalars['ID']>;
  shareToken: ShareToken;
  standardBoilerplateText?: Maybe<Scalars['String']>;
  standardExclusions?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subjectivities: SubjectivityConnection;
  submission: Submission;
  underwritingFee: Scalars['Currency'];
  updatedAt: Scalars['ISO8601DateTime'];
};


/** NFP Small Deals Platform Reps & Warranties Quotation */
export type NfpRepsAndWarrantiesQuotationQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** NFP Small Deals Platform Reps & Warranties Quotation */
export type NfpRepsAndWarrantiesQuotationSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesQuotationBind */
export type NfpRepsAndWarrantiesQuotationBindInput = {
  attributes: NfpRepsAndWarrantiesBindInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesQuotationBind */
export type NfpRepsAndWarrantiesQuotationBindPayload = {
  __typename?: 'NFPRepsAndWarrantiesQuotationBindPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  quotation?: Maybe<NfpRepsAndWarrantiesQuotation>;
};

/** NFP Small Deals Platform Reps & Warranties Quotation Coverage Enhancements */
export type NfpRepsAndWarrantiesQuotationCoverageEnhancement = {
  __typename?: 'NFPRepsAndWarrantiesQuotationCoverageEnhancement';
  comment?: Maybe<Scalars['Text']>;
  coverageEnhancement: NfpRepsAndWarrantiesCoverageEnhancement;
  covered: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  premium?: Maybe<Scalars['Decimal']>;
  quotation: NfpRepsAndWarrantiesQuotation;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Errors for an NFP Reps & Warranties Quotation Coverage Enhancement */
export type NfpRepsAndWarrantiesQuotationCoverageEnhancementErrors = {
  __typename?: 'NFPRepsAndWarrantiesQuotationCoverageEnhancementErrors';
  base?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  coverageEnhancementId?: Maybe<Scalars['String']>;
  covered?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
};

/** Attributes for an NFP Reps & Warranties Quotation Coverage Enhancement */
export type NfpRepsAndWarrantiesQuotationCoverageEnhancementInput = {
  comment?: Maybe<Scalars['Text']>;
  coverageEnhancementId: Scalars['ID'];
  covered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  premium?: Maybe<Scalars['Decimal']>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesQuotationCreateOrUpdate */
export type NfpRepsAndWarrantiesQuotationCreateOrUpdateInput = {
  attributes: NfpRepsAndWarrantiesQuotationInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesQuotationCreateOrUpdate */
export type NfpRepsAndWarrantiesQuotationCreateOrUpdatePayload = {
  __typename?: 'NFPRepsAndWarrantiesQuotationCreateOrUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<NfpRepsAndWarrantiesQuotationErrors>;
  quotation?: Maybe<NfpRepsAndWarrantiesQuotation>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesQuotationDecline */
export type NfpRepsAndWarrantiesQuotationDeclineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  participationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesQuotationDecline */
export type NfpRepsAndWarrantiesQuotationDeclinePayload = {
  __typename?: 'NFPRepsAndWarrantiesQuotationDeclinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ParticipationErrors>;
  participation?: Maybe<Participation>;
};

/** Errors for updating an NFP Reps & Warranties Quotation */
export type NfpRepsAndWarrantiesQuotationErrors = {
  __typename?: 'NFPRepsAndWarrantiesQuotationErrors';
  base?: Maybe<Scalars['String']>;
  dealSpecificExclusions?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentErrors>;
  dropdownAfter?: Maybe<Scalars['String']>;
  enhancedDueDiligenceAreas?: Maybe<Scalars['String']>;
  expectedRolloverCovered?: Maybe<Scalars['String']>;
  insurerInternalComment?: Maybe<Scalars['String']>;
  internalComment?: Maybe<Scalars['String']>;
  maximumNcdRollover?: Maybe<Scalars['String']>;
  otherCoverageCommentary?: Maybe<Scalars['String']>;
  quotationCoverageEnhancements?: Maybe<NfpRepsAndWarrantiesQuotationCoverageEnhancementErrors>;
  quotationOptions?: Maybe<NfpRepsAndWarrantiesQuotationOptionErrors>;
  standardBoilerplateText?: Maybe<Scalars['String']>;
  standardExclusions?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  underwritingFee?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesQuotationExport */
export type NfpRepsAndWarrantiesQuotationExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesQuotationExport */
export type NfpRepsAndWarrantiesQuotationExportPayload = {
  __typename?: 'NFPRepsAndWarrantiesQuotationExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Attributes for updating an NFP Reps & Warranties Quotation */
export type NfpRepsAndWarrantiesQuotationInput = {
  dealSpecificExclusions?: Maybe<Array<Scalars['String']>>;
  documents?: Maybe<Array<DocumentInput>>;
  dropdownAfter: Scalars['String'];
  enhancedDueDiligenceAreas?: Maybe<Array<Scalars['String']>>;
  expectedRolloverCovered: Scalars['Boolean'];
  insurerInternalComment?: Maybe<Scalars['String']>;
  maximumNcdRollover: Scalars['Percentage'];
  otherCoverageCommentary?: Maybe<Scalars['String']>;
  quotationCoverageEnhancements: Array<NfpRepsAndWarrantiesQuotationCoverageEnhancementInput>;
  quotationOptions: Array<NfpRepsAndWarrantiesQuotationOptionInput>;
  standardBoilerplateText?: Maybe<Scalars['String']>;
  standardExclusions?: Maybe<Scalars['String']>;
  status?: Maybe<NfpRepsAndWarrantiesQuotationStatuses>;
  underwritingFee: Scalars['Currency'];
};

/** Autogenerated input type of NFPRepsAndWarrantiesQuotationIssuePolicy */
export type NfpRepsAndWarrantiesQuotationIssuePolicyInput = {
  attributes: NfpRepsAndWarrantiesIssuePolicyInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesQuotationIssuePolicy */
export type NfpRepsAndWarrantiesQuotationIssuePolicyPayload = {
  __typename?: 'NFPRepsAndWarrantiesQuotationIssuePolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  quotation?: Maybe<NfpRepsAndWarrantiesQuotation>;
};

/** NFP Small Deals Platform Reps & Warranties Quotation Option */
export type NfpRepsAndWarrantiesQuotationOption = {
  __typename?: 'NFPRepsAndWarrantiesQuotationOption';
  createdAt: Scalars['ISO8601DateTime'];
  dropdownRetention?: Maybe<Scalars['Decimal']>;
  dropdownRetentionAmount: Scalars['Currency'];
  estimatedCost: Scalars['Currency'];
  id: Scalars['ID'];
  initialRetention?: Maybe<Scalars['Decimal']>;
  initialRetentionAmount: Scalars['Currency'];
  label?: Maybe<Scalars['String']>;
  limitAmount?: Maybe<Scalars['Decimal']>;
  limitPercentage?: Maybe<Scalars['Decimal']>;
  option?: Maybe<NfpRepsAndWarrantiesOption>;
  premium: Scalars['Decimal'];
  premiumRate: Scalars['Decimal'];
  quotation: NfpRepsAndWarrantiesQuotation;
  retentionType: Scalars['String'];
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Errors for an NFP Reps & Warranties Quotation Option */
export type NfpRepsAndWarrantiesQuotationOptionErrors = {
  __typename?: 'NFPRepsAndWarrantiesQuotationOptionErrors';
  _destroy?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  dropdownRetention?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initialRetention?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  limitAmount?: Maybe<Scalars['String']>;
  limitPercentage?: Maybe<Scalars['String']>;
  optionId?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
  retentionType?: Maybe<Scalars['String']>;
};

/** Attributes for an NFP Reps & Warranties Quotation Option */
export type NfpRepsAndWarrantiesQuotationOptionInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  dropdownRetention?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['ID']>;
  initialRetention?: Maybe<Scalars['Decimal']>;
  label?: Maybe<Scalars['String']>;
  limitAmount?: Maybe<Scalars['Decimal']>;
  limitPercentage?: Maybe<Scalars['Decimal']>;
  optionId?: Maybe<Scalars['ID']>;
  premium?: Maybe<Scalars['Currency']>;
  retentionType: Scalars['String'];
};

/** Autogenerated input type of NFPRepsAndWarrantiesQuotationSelect */
export type NfpRepsAndWarrantiesQuotationSelectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesQuotationSelect */
export type NfpRepsAndWarrantiesQuotationSelectPayload = {
  __typename?: 'NFPRepsAndWarrantiesQuotationSelectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<NfpRepsAndWarrantiesQuotationErrors>;
  quotation?: Maybe<NfpRepsAndWarrantiesQuotation>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesQuotationSetInternalComment */
export type NfpRepsAndWarrantiesQuotationSetInternalCommentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  internalComment?: Maybe<Scalars['Text']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesQuotationSetInternalComment */
export type NfpRepsAndWarrantiesQuotationSetInternalCommentPayload = {
  __typename?: 'NFPRepsAndWarrantiesQuotationSetInternalCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<NfpRepsAndWarrantiesQuotationErrors>;
  quotation?: Maybe<NfpRepsAndWarrantiesQuotation>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesQuotationStar */
export type NfpRepsAndWarrantiesQuotationStarInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesQuotationStar */
export type NfpRepsAndWarrantiesQuotationStarPayload = {
  __typename?: 'NFPRepsAndWarrantiesQuotationStarPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<NfpRepsAndWarrantiesQuotationErrors>;
  quotation?: Maybe<NfpRepsAndWarrantiesQuotation>;
};

/** The statuses of quotations for the Small Deals Platform */
export enum NfpRepsAndWarrantiesQuotationStatuses {
  Drafted = 'drafted',
  Quoted = 'quoted',
  Selected = 'selected',
  Starred = 'starred'
}

/** Autogenerated input type of NFPRepsAndWarrantiesQuotationUploadDocuments */
export type NfpRepsAndWarrantiesQuotationUploadDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesQuotationUploadDocuments */
export type NfpRepsAndWarrantiesQuotationUploadDocumentsPayload = {
  __typename?: 'NFPRepsAndWarrantiesQuotationUploadDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  quotation?: Maybe<NfpRepsAndWarrantiesQuotation>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesQuotesExport */
export type NfpRepsAndWarrantiesQuotesExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesQuotesExport */
export type NfpRepsAndWarrantiesQuotesExportPayload = {
  __typename?: 'NFPRepsAndWarrantiesQuotesExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Attributes for requesting to bind an NFP Reps & Warranties Submission */
export type NfpRepsAndWarrantiesRequestToBindInput = {
  closedAt?: Maybe<Scalars['Date']>;
  message: Scalars['String'];
  selectedOptionId: Scalars['ID'];
  signedAt: Scalars['Date'];
};

/** NFP Small Deals Platform Reps & Warranties Submission */
export type NfpRepsAndWarrantiesSubmission = Broadcastable & Discussable & NotificationRelatesTo & Submission & {
  __typename?: 'NFPRepsAndWarrantiesSubmission';
  acquisitionStructure?: Maybe<Scalars['String']>;
  advisors: Array<NfpRepsAndWarrantiesAdvisor>;
  agreementGoverningLaw: State;
  bindProbability?: Maybe<Scalars['Decimal']>;
  broadcasts?: Maybe<BroadcastConnection>;
  brokerageFee?: Maybe<Scalars['Percentage']>;
  brokeragePayaway?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  clientNda?: Maybe<Document>;
  clientReport?: Maybe<NfpRepsAndWarrantiesClientReport>;
  clientType?: Maybe<ClientType>;
  closedAt?: Maybe<Scalars['ISO8601DateTime']>;
  coverageEnhancements: Array<NfpRepsAndWarrantiesCoverageEnhancement>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['Text']>;
  discussion?: Maybe<Discussion>;
  documents: Array<Document>;
  exclusivity?: Maybe<Scalars['String']>;
  exclusivityDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  indemnityStructure?: Maybe<Scalars['String']>;
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  name: Scalars['String'];
  namedInsured?: Maybe<Scalars['String']>;
  nonBindingIndicationDate?: Maybe<Scalars['ISO8601Date']>;
  nonBindingIndicationDateDetails?: Maybe<Scalars['String']>;
  noteOnMarketExclusions?: Maybe<Scalars['Text']>;
  operationsDetails?: Maybe<Scalars['Text']>;
  options: Array<NfpRepsAndWarrantiesOption>;
  otherMaterials?: Maybe<Scalars['String']>;
  owner: User;
  participations: ParticipationConnection;
  pdfExport?: Maybe<Document>;
  policyTerm?: Maybe<Scalars['Text']>;
  policyType?: Maybe<PolicyType>;
  producers?: Maybe<Array<Scalars['String']>>;
  region?: Maybe<Region>;
  retentionDetails?: Maybe<Scalars['Text']>;
  revenue?: Maybe<Scalars['Currency']>;
  revenueMonth?: Maybe<Month>;
  rolloverComment?: Maybe<Scalars['Text']>;
  scopeOfCoverages?: Maybe<Scalars['Text']>;
  seller?: Maybe<Scalars['String']>;
  sellerRollover?: Maybe<Scalars['Percentage']>;
  signAndCloseDateDetails?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<Scalars['String']>;
  surplusTaxState: State;
  target?: Maybe<Scalars['String']>;
  targetBindDate?: Maybe<Scalars['ISO8601Date']>;
  targetCloseDate?: Maybe<Scalars['ISO8601Date']>;
  targetDescription?: Maybe<Scalars['Text']>;
  targetEnterpriseValue?: Maybe<Scalars['Currency']>;
  targetIndustry?: Maybe<Industry>;
  targetSignDate?: Maybe<Scalars['ISO8601Date']>;
  type: Scalars['String'];
  underwriterDocuments: Array<Document>;
  updatedAt: Scalars['ISO8601DateTime'];
};


/** NFP Small Deals Platform Reps & Warranties Submission */
export type NfpRepsAndWarrantiesSubmissionBroadcastsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** NFP Small Deals Platform Reps & Warranties Submission */
export type NfpRepsAndWarrantiesSubmissionParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type NfpRepsAndWarrantiesSubmissionCancelErrors = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionCancelErrors';
  base?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  submissionId?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesSubmissionCancel */
export type NfpRepsAndWarrantiesSubmissionCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesSubmissionCancel */
export type NfpRepsAndWarrantiesSubmissionCancelPayload = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionCancelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<NfpRepsAndWarrantiesSubmissionCancelErrors>;
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesSubmissionClose */
export type NfpRepsAndWarrantiesSubmissionCloseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesSubmissionClose */
export type NfpRepsAndWarrantiesSubmissionClosePayload = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionClosePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<NfpRepsAndWarrantiesSubmissionErrors>;
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
};

/** The connection type for NFPRepsAndWarrantiesSubmission. */
export type NfpRepsAndWarrantiesSubmissionConnection = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NfpRepsAndWarrantiesSubmissionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NfpRepsAndWarrantiesSubmission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of NFPRepsAndWarrantiesSubmissionCreate */
export type NfpRepsAndWarrantiesSubmissionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of NFPRepsAndWarrantiesSubmissionCreate */
export type NfpRepsAndWarrantiesSubmissionCreatePayload = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<NfpRepsAndWarrantiesSubmissionErrors>;
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesSubmissionDelete */
export type NfpRepsAndWarrantiesSubmissionDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesSubmissionDelete */
export type NfpRepsAndWarrantiesSubmissionDeletePayload = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type NfpRepsAndWarrantiesSubmissionEdge = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NfpRepsAndWarrantiesSubmission>;
};

/** Errors for updating an NFP Reps & Warranties Submission */
export type NfpRepsAndWarrantiesSubmissionErrors = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionErrors';
  acquisitionStructure?: Maybe<Scalars['String']>;
  advisors?: Maybe<NfpRepsAndWarrantiesAdvisorErrors>;
  agreementGoverningLaw?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  bindProbability?: Maybe<Scalars['String']>;
  brokerageFee?: Maybe<Scalars['String']>;
  brokeragePayaway?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  clientNda?: Maybe<Scalars['String']>;
  clientType?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['String']>;
  coverageEnhancements?: Maybe<NfpRepsAndWarrantiesCoverageEnhancementErrors>;
  disclaimer?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentErrors>;
  exclusivity?: Maybe<Scalars['String']>;
  exclusivityDate?: Maybe<Scalars['String']>;
  indemnityStructure?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  namedInsured?: Maybe<Scalars['String']>;
  nonBindingIndicationDate?: Maybe<Scalars['String']>;
  nonBindingIndicationDateDetails?: Maybe<Scalars['String']>;
  noteOnMarketExclusions?: Maybe<Scalars['String']>;
  operationsDetails?: Maybe<Scalars['String']>;
  options?: Maybe<NfpRepsAndWarrantiesOptionErrors>;
  otherMaterials?: Maybe<Scalars['String']>;
  policyTerm?: Maybe<Scalars['String']>;
  policyType?: Maybe<Scalars['String']>;
  producers?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  retentionDetails?: Maybe<Scalars['String']>;
  revenueMonth?: Maybe<Scalars['String']>;
  rolloverComment?: Maybe<Scalars['String']>;
  scopeOfCoverages?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  sellerRollover?: Maybe<Scalars['String']>;
  signAndCloseDateDetails?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['String']>;
  surplusTaxState?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  targetBindDate?: Maybe<Scalars['String']>;
  targetCloseDate?: Maybe<Scalars['String']>;
  targetDescription?: Maybe<Scalars['String']>;
  targetEnterpriseValue?: Maybe<Scalars['String']>;
  targetIndustry?: Maybe<Scalars['String']>;
  targetSignDate?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesSubmissionExport */
export type NfpRepsAndWarrantiesSubmissionExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesSubmissionExport */
export type NfpRepsAndWarrantiesSubmissionExportPayload = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesSubmissionFinalize */
export type NfpRepsAndWarrantiesSubmissionFinalizeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesSubmissionFinalize */
export type NfpRepsAndWarrantiesSubmissionFinalizePayload = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionFinalizePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<NfpRepsAndWarrantiesSubmissionErrors>;
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
};

/** Attributes for updating an NFP Reps & Warranties Submission */
export type NfpRepsAndWarrantiesSubmissionInput = {
  acquisitionStructure?: Maybe<Scalars['String']>;
  advisors: Array<NfpRepsAndWarrantiesAdvisorInput>;
  agreementGoverningLaw?: Maybe<State>;
  bindProbability?: Maybe<Scalars['Decimal']>;
  brokerageFee?: Maybe<Scalars['Percentage']>;
  brokeragePayaway?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['Text']>;
  clientNda?: Maybe<Scalars['Upload']>;
  clientType?: Maybe<ClientType>;
  closedAt?: Maybe<Scalars['Date']>;
  coverageEnhancements: Array<NfpRepsAndWarrantiesCoverageEnhancementInput>;
  disclaimer?: Maybe<Scalars['Text']>;
  documents?: Maybe<Array<DocumentInput>>;
  exclusivity?: Maybe<Scalars['String']>;
  exclusivityDate?: Maybe<Scalars['Date']>;
  indemnityStructure?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  namedInsured?: Maybe<Scalars['String']>;
  nonBindingIndicationDate?: Maybe<Scalars['Date']>;
  nonBindingIndicationDateDetails?: Maybe<Scalars['String']>;
  noteOnMarketExclusions?: Maybe<Scalars['Text']>;
  operationsDetails?: Maybe<Scalars['Text']>;
  options: Array<NfpRepsAndWarrantiesOptionInput>;
  otherMaterials?: Maybe<Scalars['String']>;
  policyTerm?: Maybe<Scalars['Text']>;
  policyType?: Maybe<PolicyType>;
  producers?: Maybe<Array<Scalars['String']>>;
  region?: Maybe<Region>;
  retentionDetails?: Maybe<Scalars['Text']>;
  revenueMonth?: Maybe<Month>;
  rolloverComment?: Maybe<Scalars['Text']>;
  scopeOfCoverages?: Maybe<Scalars['Text']>;
  seller?: Maybe<Scalars['String']>;
  sellerRollover?: Maybe<Scalars['Percentage']>;
  signAndCloseDateDetails?: Maybe<Scalars['String']>;
  signedAt?: Maybe<Scalars['Date']>;
  surplusTaxState?: Maybe<State>;
  target?: Maybe<Scalars['String']>;
  targetBindDate?: Maybe<Scalars['Date']>;
  targetCloseDate?: Maybe<Scalars['Date']>;
  targetDescription?: Maybe<Scalars['Text']>;
  targetEnterpriseValue?: Maybe<Scalars['Currency']>;
  targetIndustry?: Maybe<Industry>;
  targetSignDate?: Maybe<Scalars['Date']>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesSubmissionInvite */
export type NfpRepsAndWarrantiesSubmissionInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  message: Scalars['Text'];
  participantIds: Array<Scalars['ID']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesSubmissionInvite */
export type NfpRepsAndWarrantiesSubmissionInvitePayload = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ParticipationErrors>;
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesSubmissionRequestToBind */
export type NfpRepsAndWarrantiesSubmissionRequestToBindInput = {
  attributes: NfpRepsAndWarrantiesRequestToBindInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesSubmissionRequestToBind */
export type NfpRepsAndWarrantiesSubmissionRequestToBindPayload = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionRequestToBindPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<NfpRepsAndWarrantiesSubmissionErrors>;
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
};

/** Autogenerated input type of NFPRepsAndWarrantiesSubmissionUpdate */
export type NfpRepsAndWarrantiesSubmissionUpdateInput = {
  attributes: NfpRepsAndWarrantiesSubmissionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of NFPRepsAndWarrantiesSubmissionUpdate */
export type NfpRepsAndWarrantiesSubmissionUpdatePayload = {
  __typename?: 'NFPRepsAndWarrantiesSubmissionUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<NfpRepsAndWarrantiesSubmissionErrors>;
  submission?: Maybe<NfpRepsAndWarrantiesSubmission>;
};

export type NonIndemnifiableLossType = {
  __typename?: 'NonIndemnifiableLossType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Autogenerated input type of NotificationActioned */
export type NotificationActionedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  notificationEventId: Scalars['ID'];
};

/** Autogenerated return type of NotificationActioned */
export type NotificationActionedPayload = {
  __typename?: 'NotificationActionedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  notificationEvent?: Maybe<NotificationEvent>;
};

/** Autogenerated input type of NotificationAllActioned */
export type NotificationAllActionedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of NotificationAllActioned */
export type NotificationAllActionedPayload = {
  __typename?: 'NotificationAllActionedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** Notifications for a user */
export type NotificationEvent = {
  __typename?: 'NotificationEvent';
  event: Scalars['String'];
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  occurredAt: Scalars['DateTime'];
  relatesTo?: Maybe<NotificationRelatesTo>;
  status: Scalars['String'];
  triggeredBy: User;
  user: User;
};

/** The connection type for NotificationEvent. */
export type NotificationEventConnection = {
  __typename?: 'NotificationEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NotificationEventEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NotificationEvent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  unreadCount: Scalars['Int'];
};

/** An edge in a connection. */
export type NotificationEventEdge = {
  __typename?: 'NotificationEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NotificationEvent>;
};

/** Entity that the notification is associated with */
export type NotificationRelatesTo = {
  id: Scalars['ID'];
};

/** Notification settings input */
export type NotificationSettingsInputType = {
  mySubmissions?: Maybe<SubmissionNotificationSettingsInputType>;
  retailerSubmissions?: Maybe<SubmissionNotificationSettingsInputType>;
};

/** Notification settings Type */
export type NotificationSettingsType = {
  __typename?: 'NotificationSettingsType';
  mySubmissions?: Maybe<SubmissionNotificationSettingsType>;
  retailerSubmissions?: Maybe<SubmissionNotificationSettingsType>;
};

/** Autogenerated input type of NotificationViewed */
export type NotificationViewedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  notificationEventIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of NotificationViewed */
export type NotificationViewedPayload = {
  __typename?: 'NotificationViewedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  notificationEvents?: Maybe<Array<NotificationEvent>>;
};

/** Autogenerated input type of OnboardingProfileCompleted */
export type OnboardingProfileCompletedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of OnboardingProfileCompleted */
export type OnboardingProfileCompletedPayload = {
  __typename?: 'OnboardingProfileCompletedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of OnboardUser */
export type OnboardUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** Autogenerated return type of OnboardUser */
export type OnboardUserPayload = {
  __typename?: 'OnboardUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

export type OnPremisesType = {
  __typename?: 'OnPremisesType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Panels owned by company */
export type Panel = Shareable & {
  __typename?: 'Panel';
  brandColor?: Maybe<Scalars['String']>;
  company: Company;
  configuration?: Maybe<Scalars['JSON']>;
  country: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  defaultBrand?: Maybe<Scalars['String']>;
  defaultMessage?: Maybe<Scalars['String']>;
  displayTax?: Maybe<Scalars['Boolean']>;
  documentFolder?: Maybe<Scalars['String']>;
  externalCarriers?: Maybe<Array<ExternalCarrier>>;
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  lineOfBusiness?: Maybe<Scalars['String']>;
  logoUrl: Scalars['String'];
  manualSubjectivities?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  panelistConfigurations: PanelistConfigurationConnection;
  panelists: ReinsurerConnection;
  products: ProductConnection;
  proposalConfiguration?: Maybe<ProposalConfiguration>;
  r6Calls?: Maybe<Scalars['Int']>;
  renderMethod?: Maybe<Scalars['String']>;
  schemas: PanelsSchema;
  shareToken: ShareToken;
  submissions: SubmissionConnection;
  syncConfiguration?: Maybe<Scalars['JSON']>;
  unlockable?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
  useRetailSurplusLines?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
};


/** Panels owned by company */
export type PanelPanelistConfigurationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panels owned by company */
export type PanelPanelistsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panels owned by company */
export type PanelProductsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panels owned by company */
export type PanelSubmissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Panel. */
export type PanelConnection = {
  __typename?: 'PanelConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PanelEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Panel>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PanelEdge = {
  __typename?: 'PanelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Panel>;
};

/** Panelist Configuration */
export type PanelistConfiguration = {
  __typename?: 'PanelistConfiguration';
  carrierAuthentications?: Maybe<CarrierAuthenticationConnection>;
  disabled?: Maybe<Scalars['Boolean']>;
  documents?: Maybe<Array<Document>>;
  indicationOnly?: Maybe<Scalars['Boolean']>;
  panelist: Reinsurer;
  preferred?: Maybe<Scalars['Boolean']>;
  wholesalerCompany?: Maybe<Company>;
};


/** Panelist Configuration */
export type PanelistConfigurationCarrierAuthenticationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for PanelistConfiguration. */
export type PanelistConfigurationConnection = {
  __typename?: 'PanelistConfigurationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PanelistConfigurationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PanelistConfiguration>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PanelistConfigurationEdge = {
  __typename?: 'PanelistConfigurationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PanelistConfiguration>;
};

/** Autogenerated input type of PanelOrphanage */
export type PanelOrphanageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PanelOrphanage */
export type PanelOrphanagePayload = {
  __typename?: 'PanelOrphanagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotation: TerrorismQuotation;
};

/** The query root for Panels */
export type Panels = {
  __typename?: 'Panels';
  id: Scalars['ID'];
  panel?: Maybe<Panel>;
  participation?: Maybe<Participation>;
  schemas: MultilinePanelsSchema;
  submission?: Maybe<PanelSubmission>;
  submissions?: Maybe<PanelSubmissionConnection>;
};


/** The query root for Panels */
export type PanelsPanelArgs = {
  panelId: Scalars['ID'];
};


/** The query root for Panels */
export type PanelsParticipationArgs = {
  participationId: Scalars['ID'];
};


/** The query root for Panels */
export type PanelsSubmissionArgs = {
  submissionId: Scalars['ID'];
};


/** The query root for Panels */
export type PanelsSubmissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of PanelsAdjustQuotes */
export type PanelsAdjustQuotesInput = {
  carriers?: Maybe<Array<Scalars['String']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deductible: Scalars['Currency'];
  limit: Scalars['Currency'];
  lineOfBusiness: Scalars['String'];
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of PanelsAdjustQuotes */
export type PanelsAdjustQuotesPayload = {
  __typename?: 'PanelsAdjustQuotesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
  submission?: Maybe<PanelSubmission>;
};

/** Autogenerated input type of PanelsBindQuote */
export type PanelsBindQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  message?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of PanelsBindQuote */
export type PanelsBindQuotePayload = {
  __typename?: 'PanelsBindQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  quotation?: Maybe<PanelsQuotation>;
};

/** Autogenerated input type of PanelsExportQuoteComparison */
export type PanelsExportQuoteComparisonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  exportType?: Maybe<Scalars['String']>;
  quotationIds?: Maybe<Array<Scalars['ID']>>;
  recommendations?: Maybe<Array<RecommendedCarrierInput>>;
  submissionId: Scalars['ID'];
  synopsis?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PanelsExportQuoteComparison */
export type PanelsExportQuoteComparisonPayload = {
  __typename?: 'PanelsExportQuoteComparisonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
};

export type PanelsIssuePolicyErrors = {
  __typename?: 'PanelsIssuePolicyErrors';
  base?: Maybe<Scalars['String']>;
  policyNumber?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PanelsIssuePolicy */
export type PanelsIssuePolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  message?: Maybe<Scalars['String']>;
  policyNumber: Scalars['String'];
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of PanelsIssuePolicy */
export type PanelsIssuePolicyPayload = {
  __typename?: 'PanelsIssuePolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<PanelsIssuePolicyErrors>;
  quotation?: Maybe<PanelsQuotation>;
};

/** Panels Quotation */
export type PanelsQuotation = NotificationRelatesTo & Quotation & {
  __typename?: 'PanelsQuotation';
  admitted?: Maybe<Scalars['Boolean']>;
  ambestRating?: Maybe<AmBestRating>;
  apiData?: Maybe<Scalars['JSON']>;
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  binders: DocumentConnection;
  boundAt?: Maybe<Scalars['ISO8601DateTime']>;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  createdAt: Scalars['ISO8601DateTime'];
  declinationReason?: Maybe<Scalars['String']>;
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  derivedData: Scalars['JSON'];
  discussion?: Maybe<Discussion>;
  documents: Array<Document>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  extractedAt?: Maybe<Scalars['ISO8601DateTime']>;
  failedAt?: Maybe<Scalars['ISO8601DateTime']>;
  fields: Scalars['JSON'];
  id: Scalars['ID'];
  indication: Scalars['Boolean'];
  owner: User;
  participation: Participation;
  policies: DocumentConnection;
  policyNumber?: Maybe<Scalars['String']>;
  questions: QuestionConnection;
  quotedAt?: Maybe<Scalars['ISO8601DateTime']>;
  quotedLines: PanelsQuotedLineTypeConnection;
  requestedDeductible?: Maybe<Scalars['Currency']>;
  requestedLimit?: Maybe<Scalars['Currency']>;
  retailCommission?: Maybe<Scalars['Currency']>;
  retailCommissionPercentage?: Maybe<Scalars['Decimal']>;
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selected?: Maybe<Scalars['Boolean']>;
  selectedOptionId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  subjectivities: SubjectivityConnection;
  submission: PanelSubmission;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};


/** Panels Quotation */
export type PanelsQuotationBindersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panels Quotation */
export type PanelsQuotationPoliciesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panels Quotation */
export type PanelsQuotationQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panels Quotation */
export type PanelsQuotationQuotedLinesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panels Quotation */
export type PanelsQuotationSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Panels Quoted Line Type */
export type PanelsQuotedLineType = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** The connection type for PanelsQuotedLineType. */
export type PanelsQuotedLineTypeConnection = {
  __typename?: 'PanelsQuotedLineTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PanelsQuotedLineTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PanelsQuotedLineType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PanelsQuotedLineTypeEdge = {
  __typename?: 'PanelsQuotedLineTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PanelsQuotedLineType>;
};

/** Autogenerated input type of PanelsRequestToBind */
export type PanelsRequestToBindInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  coverageStartDate?: Maybe<Scalars['Date']>;
  quotationId: Scalars['ID'];
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of PanelsRequestToBind */
export type PanelsRequestToBindPayload = {
  __typename?: 'PanelsRequestToBindPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  quotation?: Maybe<PanelsQuotation>;
};

/** Panel form schemas */
export type PanelsSchema = {
  __typename?: 'PanelsSchema';
  draft: Scalars['JSON'];
  edit: Scalars['JSON'];
  editClientDraft: Scalars['JSON'];
  editQuote?: Maybe<Scalars['JSON']>;
  firmup: Scalars['JSON'];
  new: Scalars['JSON'];
  newClientDraft: Scalars['JSON'];
  newManualQuote?: Maybe<Scalars['JSON']>;
  panelists?: Maybe<ReinsurerConnection>;
  submitAdditional: Scalars['JSON'];
};


/** Panel form schemas */
export type PanelsSchemaDraftArgs = {
  panelistIds?: Maybe<Array<Scalars['ID']>>;
  submissionId: Scalars['ID'];
};


/** Panel form schemas */
export type PanelsSchemaEditArgs = {
  submissionId: Scalars['ID'];
};


/** Panel form schemas */
export type PanelsSchemaEditClientDraftArgs = {
  submissionId: Scalars['ID'];
};


/** Panel form schemas */
export type PanelsSchemaEditQuoteArgs = {
  quoteId: Scalars['ID'];
};


/** Panel form schemas */
export type PanelsSchemaFirmupArgs = {
  submissionId: Scalars['ID'];
};


/** Panel form schemas */
export type PanelsSchemaNewArgs = {
  panelistIds: Array<Scalars['ID']>;
};


/** Panel form schemas */
export type PanelsSchemaNewManualQuoteArgs = {
  submissionId: Scalars['ID'];
};


/** Panel form schemas */
export type PanelsSchemaPanelistsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panel form schemas */
export type PanelsSchemaSubmitAdditionalArgs = {
  panelistIds?: Maybe<Array<Scalars['ID']>>;
  submissionId: Scalars['ID'];
};

/** Panel Submission */
export type PanelSubmission = NotificationRelatesTo & Submission & {
  __typename?: 'PanelSubmission';
  applicant?: Maybe<CommercialApplicant>;
  broadcasts: BroadcastConnection;
  collaborations: CollaborationConnection;
  collaborators: UserConnection;
  createdAt: Scalars['ISO8601DateTime'];
  dedupKey?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  discussion?: Maybe<Discussion>;
  documents: Array<Document>;
  emailCarriers: UserConnection;
  email_carriers: Array<User>;
  fields: Scalars['JSON'];
  id: Scalars['ID'];
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner: User;
  panel?: Maybe<Panel>;
  panel_info?: Maybe<Scalars['JSON']>;
  participants: UserConnection;
  participations: ParticipationConnection;
  pdfExport?: Maybe<Document>;
  products: Array<Product>;
  proposals: DocumentConnection;
  status?: Maybe<Scalars['String']>;
  surplusLinesLicenseInfo?: Maybe<SurplusLinesType>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};


/** Panel Submission */
export type PanelSubmissionBroadcastsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panel Submission */
export type PanelSubmissionCollaborationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panel Submission */
export type PanelSubmissionCollaboratorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panel Submission */
export type PanelSubmissionEmailCarriersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panel Submission */
export type PanelSubmissionParticipantsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panel Submission */
export type PanelSubmissionParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Panel Submission */
export type PanelSubmissionProposalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for PanelSubmission. */
export type PanelSubmissionConnection = {
  __typename?: 'PanelSubmissionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PanelSubmissionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PanelSubmission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PanelSubmissionEdge = {
  __typename?: 'PanelSubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PanelSubmission>;
};

/** Autogenerated input type of PanelsUpdateSubmissionDocuments */
export type PanelsUpdateSubmissionDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of PanelsUpdateSubmissionDocuments */
export type PanelsUpdateSubmissionDocumentsPayload = {
  __typename?: 'PanelsUpdateSubmissionDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  submission?: Maybe<PanelSubmission>;
};

/** A participation or request to participate in a submission */
export type Participation = Discussable & NotificationRelatesTo & Questionable & Subjectivitable & {
  __typename?: 'Participation';
  agentCode?: Maybe<Scalars['String']>;
  apiUser?: Maybe<User>;
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  broker?: Maybe<User>;
  clientNdaStatus?: Maybe<Scalars['String']>;
  collaboration?: Maybe<Collaboration>;
  collaborator?: Maybe<User>;
  coverages: CoverageConnection;
  createdAt: Scalars['ISO8601DateTime'];
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  discussion?: Maybe<Discussion>;
  documents?: Maybe<Array<Document>>;
  externalCarrier?: Maybe<ExternalCarrier>;
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  invitationMessage?: Maybe<Scalars['String']>;
  owner: User;
  participationableType?: Maybe<Scalars['String']>;
  products: ProductConnection;
  questions: QuestionConnection;
  quotation?: Maybe<Quotation>;
  quotations: QuotationConnection;
  quote?: Maybe<Quotation>;
  schemas?: Maybe<ParticipationsSchema>;
  subjectivities: SubjectivityConnection;
  submission: Submission;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};


/** A participation or request to participate in a submission */
export type ParticipationCoveragesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A participation or request to participate in a submission */
export type ParticipationProductsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A participation or request to participate in a submission */
export type ParticipationQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A participation or request to participate in a submission */
export type ParticipationQuotationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A participation or request to participate in a submission */
export type ParticipationQuoteArgs = {
  participationId: Scalars['ID'];
  quotationId: Scalars['ID'];
};


/** A participation or request to participate in a submission */
export type ParticipationSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Participation. */
export type ParticipationConnection = {
  __typename?: 'ParticipationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ParticipationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Participation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ParticipationEdge = {
  __typename?: 'ParticipationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Participation>;
};

/** Errors for a participation or request to participate in a submission */
export type ParticipationErrors = {
  __typename?: 'ParticipationErrors';
  agentCode?: Maybe<Scalars['String']>;
  archivedAt?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  clientNdaStatus?: Maybe<Scalars['String']>;
  declinedAt?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentErrors>;
  id?: Maybe<Scalars['String']>;
  invitationMessage?: Maybe<Scalars['String']>;
  participationableType?: Maybe<Scalars['String']>;
  signatures?: Maybe<Array<Scalars['String']>>;
};

/** Panel's Participation form schemas */
export type ParticipationsSchema = {
  __typename?: 'ParticipationsSchema';
  new: Scalars['JSON'];
  newCarrierQuote?: Maybe<Scalars['JSON']>;
};


/** Panel's Participation form schemas */
export type ParticipationsSchemaNewArgs = {
  participationId: Array<Scalars['ID']>;
};


/** Panel's Participation form schemas */
export type ParticipationsSchemaNewCarrierQuoteArgs = {
  participationId: Scalars['ID'];
};

/** The query root for PCG */
export type Pcg = {
  __typename?: 'PCG';
  panel?: Maybe<Panel>;
  quotation?: Maybe<PcgQuotation>;
  submission?: Maybe<PcgSubmission>;
  submissions?: Maybe<PcgSubmissionConnection>;
};


/** The query root for PCG */
export type PcgPanelArgs = {
  panelId: Scalars['ID'];
};


/** The query root for PCG */
export type PcgQuotationArgs = {
  id: Scalars['ID'];
};


/** The query root for PCG */
export type PcgSubmissionArgs = {
  submissionId: Scalars['ID'];
};


/** The query root for PCG */
export type PcgSubmissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** PCG Additional Features */
export type PcgAdditionalFeature = {
  __typename?: 'PCGAdditionalFeature';
  feature?: Maybe<Scalars['String']>;
  included?: Maybe<Scalars['String']>;
};

/** PCG Additional Feature Input */
export type PcgAdditionalFeatureInput = {
  feature?: Maybe<Scalars['String']>;
  included?: Maybe<Scalars['String']>;
};

/** PCG Home Additional Options Input Type */
export type PcgAdditionalOptionsInputType = {
  deductible?: Maybe<MultiInputDropdownInputType>;
  option?: Maybe<Scalars['String']>;
  premium?: Maybe<MultiInputDropdownInputType>;
};

/** PCG Home Additional Options Type */
export type PcgAdditionalOptionsType = {
  __typename?: 'PCGAdditionalOptionsType';
  deductible?: Maybe<MultiInputDropdownType>;
  option?: Maybe<Scalars['String']>;
  premium?: Maybe<MultiInputDropdownType>;
};

/** PCG Applicant */
export type PcgApplicant = {
  __typename?: 'PCGApplicant';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

/** PCG Applicant Input */
export type PcgApplicantInput = {
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
};

/** PCG Auto Additional Drivers Input */
export type PcgAutoAdditionalDriversInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

/** PCG Auto Additional Drivers Type */
export type PcgAutoAdditionalDriversType = {
  __typename?: 'PCGAutoAdditionalDriversType';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

/** PCG Auto Additional Features Input */
export type PcgAutoAdditionalFeaturesInput = {
  feature?: Maybe<Scalars['String']>;
  included?: Maybe<Scalars['String']>;
};

/** PCG Auto Additional Features Type */
export type PcgAutoAdditionalFeaturesType = {
  __typename?: 'PCGAutoAdditionalFeaturesType';
  feature?: Maybe<Scalars['String']>;
  included?: Maybe<Scalars['String']>;
};

/** PCG Auto Agreed Values Input Type */
export type PcgAutoAgreedValuesInputType = {
  value?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Scalars['String']>;
};

/** PCG Auto Agreed Values Type */
export type PcgAutoAgreedValuesType = {
  __typename?: 'PCGAutoAgreedValuesType';
  value?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Scalars['String']>;
};

/** PCG Auto Coverage Input */
export type PcgAutoCoverageInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  additionalDrivers?: Maybe<Array<PcgAutoAdditionalDriversInput>>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  vehicles?: Maybe<Array<PcgAutoVehiclesInput>>;
};

/** PCG Auto Coverage Limits Input Type */
export type PcgAutoCoverageLimitsInput = {
  limit?: Maybe<MultiInputDropdownInputType>;
  name?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
};

/** PCG Auto Coverage Limits Type */
export type PcgAutoCoverageLimitsType = {
  __typename?: 'PCGAutoCoverageLimitsType';
  limit?: Maybe<MultiInputDropdownType>;
  name?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
};

/** PCG Coverage Type */
export type PcgAutoCoverageType = PcgCoverageType & {
  __typename?: 'PCGAutoCoverageType';
  additionalDrivers?: Maybe<Array<PcgAutoAdditionalDriversType>>;
  drivers?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  type: Scalars['String'];
  vehicles?: Maybe<Array<PcgAutoVehiclesType>>;
};

/** PCG Auto Quoted Line Input Type */
export type PcgAutoQuotedLineInput = {
  additionalCoverages?: Maybe<Array<PcgAutoCoverageLimitsInput>>;
  additionalFeatures?: Maybe<Array<PcgAutoAdditionalFeaturesInput>>;
  agreedValues?: Maybe<Array<PcgAutoAgreedValuesInputType>>;
  bodilyInjuryAndPropertyDamage?: Maybe<Scalars['String']>;
  bodilyInjuryEachAccident?: Maybe<Scalars['String']>;
  bodilyInjuryPerPerson?: Maybe<Scalars['String']>;
  collisionDeductible?: Maybe<MultiInputDropdownInputType>;
  combinedLimit?: Maybe<Scalars['Boolean']>;
  comprehensiveDeductible?: Maybe<MultiInputDropdownInputType>;
  extendedTowingAndLabor?: Maybe<MultiInputDropdownInputType>;
  fullGlassCoverage?: Maybe<MultiInputDropdownInputType>;
  id?: Maybe<Scalars['ID']>;
  medicalPayments?: Maybe<MultiInputDropdownInputType>;
  propertyDamage?: Maybe<Scalars['String']>;
  totalPremium?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  underinsuredMotorists?: Maybe<MultiInputDropdownInputType>;
};

/** PCG Auto Quoted Line Type */
export type PcgAutoQuotedLineType = PcgQuotedLineType & {
  __typename?: 'PCGAutoQuotedLineType';
  additionalCoverages?: Maybe<Array<PcgAutoCoverageLimitsType>>;
  additionalFeatures?: Maybe<Array<PcgAutoAdditionalFeaturesType>>;
  agreedValues?: Maybe<Array<PcgAutoAgreedValuesType>>;
  attachmentPoint?: Maybe<Scalars['Decimal']>;
  bodilyInjuryAndPropertyDamage?: Maybe<Scalars['String']>;
  bodilyInjuryEachAccident?: Maybe<Scalars['String']>;
  bodilyInjuryPerPerson?: Maybe<Scalars['String']>;
  cedingCommission?: Maybe<Scalars['Decimal']>;
  collisionDeductible?: Maybe<MultiInputDropdownType>;
  combinedLimit?: Maybe<Scalars['Boolean']>;
  comprehensiveDeductible?: Maybe<MultiInputDropdownType>;
  coverableId: Scalars['ID'];
  coverableScope?: Maybe<Scalars['String']>;
  coverableType?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Decimal']>;
  exclusions: Array<Scalars['String']>;
  extendedTowingAndLabor?: Maybe<MultiInputDropdownType>;
  fields?: Maybe<Scalars['JSON']>;
  fullGlassCoverage?: Maybe<MultiInputDropdownType>;
  groupableType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keyPerils: Array<Scalars['String']>;
  layerLimit?: Maybe<Scalars['Decimal']>;
  medicalPayments?: Maybe<MultiInputDropdownType>;
  netPremium?: Maybe<Scalars['Decimal']>;
  note: Scalars['Text'];
  participation?: Maybe<Scalars['Decimal']>;
  pcgCoverage: PcgCoverageType;
  premium?: Maybe<Scalars['Decimal']>;
  propertyDamage?: Maybe<Scalars['String']>;
  proposedLayerPremium?: Maybe<Scalars['Decimal']>;
  quotation: PcgQuotation;
  totalPremium?: Maybe<Scalars['Currency']>;
  type: Scalars['String'];
  underinsuredMotorists?: Maybe<MultiInputDropdownType>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PcgAutoSaveSubmissionErrors = {
  __typename?: 'PCGAutoSaveSubmissionErrors';
  base?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PCGAutoSaveSubmission */
export type PcgAutoSaveSubmissionInput = {
  attributes: PcgSubmissionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PCGAutoSaveSubmission */
export type PcgAutoSaveSubmissionPayload = {
  __typename?: 'PCGAutoSaveSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<PcgAutoSaveSubmissionErrors>;
  submission?: Maybe<PcgSubmission>;
};

/** PCG Auto Vehicles Input */
export type PcgAutoVehiclesInput = {
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Currency']>;
  year?: Maybe<Scalars['String']>;
};

/** PCG Auto Vehicles Type */
export type PcgAutoVehiclesType = {
  __typename?: 'PCGAutoVehiclesType';
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Currency']>;
  year?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PCGBindEmailQuotation */
export type PcgBindEmailQuotationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  id: Scalars['ID'];
  policyNumber?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PCGBindEmailQuotation */
export type PcgBindEmailQuotationPayload = {
  __typename?: 'PCGBindEmailQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  quotation?: Maybe<PcgQuotation>;
};

export type PcgBindManualQuotationErrors = {
  __typename?: 'PCGBindManualQuotationErrors';
  base?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PCGBindManualQuotation */
export type PcgBindManualQuotationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of PCGBindManualQuotation */
export type PcgBindManualQuotationPayload = {
  __typename?: 'PCGBindManualQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<PcgBindManualQuotationErrors>;
  quotation?: Maybe<PcgQuotation>;
};

/** PCG Coverage Input */
export type PcgCoverageInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};

/** PCG Coverage Type */
export type PcgCoverageType = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** Autogenerated input type of PCGCreateEmailQuotation */
export type PcgCreateEmailQuotationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  participationId: Scalars['ID'];
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of PCGCreateEmailQuotation */
export type PcgCreateEmailQuotationPayload = {
  __typename?: 'PCGCreateEmailQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotation?: Maybe<PcgQuotation>;
};

export type PcgCreateManualQuotationErrors = {
  __typename?: 'PCGCreateManualQuotationErrors';
  base?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  currentPolicy?: Maybe<Scalars['String']>;
  customCarrier?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PCGCreateManualQuotation */
export type PcgCreateManualQuotationInput = {
  attributes: PcgQuotationInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of PCGCreateManualQuotation */
export type PcgCreateManualQuotationPayload = {
  __typename?: 'PCGCreateManualQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<PcgCreateManualQuotationErrors>;
  quotation?: Maybe<PcgQuotation>;
};

export type PcgCreateSubmissionErrors = {
  __typename?: 'PCGCreateSubmissionErrors';
  base?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PCGCreateSubmission */
export type PcgCreateSubmissionInput = {
  attributes: PcgSubmissionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  panelId: Scalars['ID'];
};

/** Autogenerated return type of PCGCreateSubmission */
export type PcgCreateSubmissionPayload = {
  __typename?: 'PCGCreateSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<PcgCreateSubmissionErrors>;
  submission?: Maybe<PcgSubmission>;
};

/** Autogenerated input type of PCGDeclineToBindEmailQuotation */
export type PcgDeclineToBindEmailQuotationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of PCGDeclineToBindEmailQuotation */
export type PcgDeclineToBindEmailQuotationPayload = {
  __typename?: 'PCGDeclineToBindEmailQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  quotation?: Maybe<PcgQuotation>;
};

/** Autogenerated input type of PCGDeleteManualQuotation */
export type PcgDeleteManualQuotationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of PCGDeleteManualQuotation */
export type PcgDeleteManualQuotationPayload = {
  __typename?: 'PCGDeleteManualQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  submission?: Maybe<PcgSubmission>;
};

export type PcgEditQuotationErrors = {
  __typename?: 'PCGEditQuotationErrors';
  base?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  currentPolicy?: Maybe<Scalars['String']>;
  customCarrier?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  valuableQuotedLines?: Maybe<Array<PcgEditQuotationValuableQuotedLinesAttributesErrors>>;
};

/** Autogenerated input type of PCGEditQuotation */
export type PcgEditQuotationInput = {
  attributes: PcgQuotationInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PCGEditQuotation */
export type PcgEditQuotationPayload = {
  __typename?: 'PCGEditQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<PcgEditQuotationErrors>;
  quotation?: Maybe<PcgQuotation>;
};

export type PcgEditQuotationValuableQuotedLinesAttributesAdditionalFeaturesErrors = {
  __typename?: 'PCGEditQuotationValuableQuotedLinesAttributesAdditionalFeaturesErrors';
  base?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  included?: Maybe<Scalars['String']>;
};

export type PcgEditQuotationValuableQuotedLinesAttributesCoveragesErrors = {
  __typename?: 'PCGEditQuotationValuableQuotedLinesAttributesCoveragesErrors';
  base?: Maybe<Scalars['String']>;
  blanketLimit?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  premium?: Maybe<Scalars['String']>;
};

export type PcgEditQuotationValuableQuotedLinesAttributesErrors = {
  __typename?: 'PCGEditQuotationValuableQuotedLinesAttributesErrors';
  additionalFeatures?: Maybe<Array<PcgEditQuotationValuableQuotedLinesAttributesAdditionalFeaturesErrors>>;
  base?: Maybe<Scalars['String']>;
  coverages?: Maybe<Array<PcgEditQuotationValuableQuotedLinesAttributesCoveragesErrors>>;
};

export type PcgEditSubmissionErrors = {
  __typename?: 'PCGEditSubmissionErrors';
  applicants?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['String']>;
  emailSignOff?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  placementTermsAccepted?: Maybe<Scalars['String']>;
  policyEndDate?: Maybe<Scalars['String']>;
  policyStartDate?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  selectedCarriers?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PCGEditSubmission */
export type PcgEditSubmissionInput = {
  attributes: PcgSubmissionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PCGEditSubmission */
export type PcgEditSubmissionPayload = {
  __typename?: 'PCGEditSubmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<PcgEditSubmissionErrors>;
  submission?: Maybe<PcgSubmission>;
};

/** PCG Excess Coverage Input */
export type PcgExcessCoverageInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};

/** PCG Excess Coverage Type */
export type PcgExcessCoverageType = PcgCoverageType & {
  __typename?: 'PCGExcessCoverageType';
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** PCG Excess Limit Options Input */
export type PcgExcessLimitOptionsInput = {
  coverage?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Currency']>;
  totalPremium?: Maybe<Scalars['String']>;
};

/** PCG Excess Limit Options Type */
export type PcgExcessLimitOptionsType = {
  __typename?: 'PCGExcessLimitOptionsType';
  coverage?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  totalPremium?: Maybe<Scalars['String']>;
};

/** PCG Excess Quoted Line Coverages Input */
export type PcgExcessQuotedLineCoveragesInput = {
  coverage?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Currency']>;
};

/** PCG Excess Quoted Line Coverages Type */
export type PcgExcessQuotedLineCoveragesType = {
  __typename?: 'PCGExcessQuotedLineCoveragesType';
  coverage?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
};

/** PCG Excess Quoted Line Input */
export type PcgExcessQuotedLineInput = {
  coverages?: Maybe<Array<PcgExcessQuotedLineCoveragesInput>>;
  id?: Maybe<Scalars['ID']>;
  limitOptions?: Maybe<Array<PcgExcessLimitOptionsInput>>;
  totalPremium?: Maybe<Scalars['Currency']>;
  type?: Maybe<Scalars['String']>;
};

/** PCG Excess Quoted Line Type */
export type PcgExcessQuotedLineType = PcgQuotedLineType & {
  __typename?: 'PCGExcessQuotedLineType';
  attachmentPoint?: Maybe<Scalars['Decimal']>;
  cedingCommission?: Maybe<Scalars['Decimal']>;
  coverableId: Scalars['ID'];
  coverableScope?: Maybe<Scalars['String']>;
  coverableType?: Maybe<Scalars['String']>;
  coverages?: Maybe<Array<PcgExcessQuotedLineCoveragesType>>;
  createdAt: Scalars['ISO8601DateTime'];
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Decimal']>;
  exclusions: Array<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  groupableType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keyPerils: Array<Scalars['String']>;
  layerLimit?: Maybe<Scalars['Decimal']>;
  limitOptions?: Maybe<Array<PcgExcessLimitOptionsType>>;
  netPremium?: Maybe<Scalars['Decimal']>;
  note: Scalars['Text'];
  participation?: Maybe<Scalars['Decimal']>;
  pcgCoverage: PcgCoverageType;
  premium?: Maybe<Scalars['Decimal']>;
  proposedLayerPremium?: Maybe<Scalars['Decimal']>;
  quotation: PcgQuotation;
  totalPremium?: Maybe<Scalars['Currency']>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated input type of PCGExtractDocument */
export type PcgExtractDocumentInput = {
  carrier: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currentPolicy?: Maybe<Scalars['Boolean']>;
  documents: Array<DocumentInput>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of PCGExtractDocument */
export type PcgExtractDocumentPayload = {
  __typename?: 'PCGExtractDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  submission: PcgSubmission;
};

/** PCG Home Coverage Input */
export type PcgHomeCoverageInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInputType>;
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};

/** PCG Home Coverage Type */
export type PcgHomeCoverageType = PcgCoverageType & {
  __typename?: 'PCGHomeCoverageType';
  address?: Maybe<Address>;
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** PCG Home Quoted Line Coverages Input Type */
export type PcgHomeQuotedLineCoveragesInputType = {
  coverage?: Maybe<Scalars['String']>;
  deductible?: Maybe<MultiInputDropdownInputType>;
  homeCoverageLimit?: Maybe<MultiInputDropdownInputType>;
};

/** PCG Home Quoted Line Coverages Type */
export type PcgHomeQuotedLineCoveragesType = {
  __typename?: 'PCGHomeQuotedLineCoveragesType';
  coverage?: Maybe<Scalars['String']>;
  deductible?: Maybe<MultiInputDropdownType>;
  homeCoverageLimit?: Maybe<MultiInputDropdownType>;
};

/** PCG Home Quoted Line Enhancements Input Type */
export type PcgHomeQuotedLineEnhancementsInputType = {
  deductible?: Maybe<MultiInputDropdownInputType>;
  enhancement?: Maybe<Scalars['String']>;
  limit?: Maybe<MultiInputDropdownInputType>;
  premium?: Maybe<MultiInputDropdownInputType>;
};

/** PCG Home Quoted Line Enhancements Type */
export type PcgHomeQuotedLineEnhancementsType = {
  __typename?: 'PCGHomeQuotedLineEnhancementsType';
  deductible?: Maybe<MultiInputDropdownType>;
  enhancement?: Maybe<Scalars['String']>;
  limit?: Maybe<MultiInputDropdownType>;
  premium?: Maybe<MultiInputDropdownType>;
};

/** PCG Home Quoted Line Input Type */
export type PcgHomeQuotedLineInputType = {
  additionalFeatures?: Maybe<Array<PcgAdditionalFeatureInput>>;
  additionalOptions?: Maybe<Array<PcgAdditionalOptionsInputType>>;
  allOtherPerilDeductible?: Maybe<Scalars['Currency']>;
  coverages?: Maybe<Array<PcgHomeQuotedLineCoveragesInputType>>;
  enhancements?: Maybe<Array<PcgHomeQuotedLineEnhancementsInputType>>;
  id?: Maybe<Scalars['ID']>;
  totalPremium?: Maybe<Scalars['Currency']>;
  type?: Maybe<Scalars['String']>;
};

/** PCG Home Quoted Line Type */
export type PcgHomeQuotedLineType = PcgQuotedLineType & {
  __typename?: 'PCGHomeQuotedLineType';
  additionalFeatures?: Maybe<Array<PcgAdditionalFeature>>;
  additionalOptions?: Maybe<Array<PcgAdditionalOptionsType>>;
  address?: Maybe<Address>;
  allOtherPerilDeductible?: Maybe<Scalars['Currency']>;
  attachmentPoint?: Maybe<Scalars['Decimal']>;
  cedingCommission?: Maybe<Scalars['Decimal']>;
  coverableId: Scalars['ID'];
  coverableScope?: Maybe<Scalars['String']>;
  coverableType?: Maybe<Scalars['String']>;
  coverages?: Maybe<Array<PcgHomeQuotedLineCoveragesType>>;
  createdAt: Scalars['ISO8601DateTime'];
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Decimal']>;
  enhancements?: Maybe<Array<PcgHomeQuotedLineEnhancementsType>>;
  exclusions: Array<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  groupableType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keyPerils: Array<Scalars['String']>;
  layerLimit?: Maybe<Scalars['Decimal']>;
  netPremium?: Maybe<Scalars['Decimal']>;
  note: Scalars['Text'];
  participation?: Maybe<Scalars['Decimal']>;
  pcgCoverage: PcgCoverageType;
  premium?: Maybe<Scalars['Decimal']>;
  proposedLayerPremium?: Maybe<Scalars['Decimal']>;
  quotation: PcgQuotation;
  totalPremium?: Maybe<Scalars['Currency']>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** PCG Product Input Type */
export type PcgProductInputType = {
  autoCoverage?: Maybe<PcgAutoCoverageInput>;
  excessLiability?: Maybe<PcgExcessCoverageInput>;
  homes?: Maybe<Array<PcgHomeCoverageInput>>;
  valuable?: Maybe<PcgCoverageInput>;
};

/** PCG Quotation */
export type PcgQuotation = Quotation & {
  __typename?: 'PCGQuotation';
  ambestRating?: Maybe<AmBestRating>;
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  binders: DocumentConnection;
  boundAt?: Maybe<Scalars['ISO8601DateTime']>;
  carrier?: Maybe<ExternalCarrier>;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentPolicy: Scalars['Boolean'];
  customCarrier?: Maybe<Scalars['Boolean']>;
  declinationReason?: Maybe<Scalars['String']>;
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  derivedData: Scalars['JSON'];
  discussion?: Maybe<Discussion>;
  documents: Array<Document>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  extractedAt?: Maybe<Scalars['ISO8601DateTime']>;
  extractedCoverages?: Maybe<Scalars['JSON']>;
  failedAt?: Maybe<Scalars['ISO8601DateTime']>;
  fields: Scalars['JSON'];
  homeQuotedLines: Array<PcgHomeQuotedLineType>;
  id: Scalars['ID'];
  indication: Scalars['Boolean'];
  owner: User;
  panelist?: Maybe<Reinsurer>;
  participation: Participation;
  policies: DocumentConnection;
  policyNumber?: Maybe<Scalars['String']>;
  questions: QuestionConnection;
  quotedAt?: Maybe<Scalars['ISO8601DateTime']>;
  quotedLines?: Maybe<PcgQuotedLineTypeConnection>;
  review?: Maybe<PcgReview>;
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selected?: Maybe<Scalars['Boolean']>;
  selectedOptionId?: Maybe<Scalars['ID']>;
  status: Scalars['String'];
  subjectivities: SubjectivityConnection;
  submission: Submission;
  totalPremium?: Maybe<Scalars['Currency']>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
  valuableQuotedLines: Array<PcgValuablesQuotedLineType>;
};


/** PCG Quotation */
export type PcgQuotationBindersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Quotation */
export type PcgQuotationPoliciesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Quotation */
export type PcgQuotationQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Quotation */
export type PcgQuotationQuotedLinesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Quotation */
export type PcgQuotationSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for PCGQuotation. */
export type PcgQuotationConnection = {
  __typename?: 'PCGQuotationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PcgQuotationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PcgQuotation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PcgQuotationEdge = {
  __typename?: 'PCGQuotationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PcgQuotation>;
};

/** PCG Quotation Input */
export type PcgQuotationInput = {
  automobiles?: Maybe<Array<PcgAutoQuotedLineInput>>;
  carrier?: Maybe<Scalars['String']>;
  currentPolicy?: Maybe<Scalars['Boolean']>;
  customCarrier?: Maybe<Scalars['String']>;
  declinationReason?: Maybe<Scalars['String']>;
  excessLiability?: Maybe<PcgExcessQuotedLineInput>;
  homes?: Maybe<Array<PcgHomeQuotedLineInputType>>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  valuable?: Maybe<Array<PcgValuableQuotedLineInput>>;
};

/** PCG Quoted Line Type */
export type PcgQuotedLineType = {
  coverableId: Scalars['ID'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** The connection type for PCGQuotedLineType. */
export type PcgQuotedLineTypeConnection = {
  __typename?: 'PCGQuotedLineTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PcgQuotedLineTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PcgQuotedLineType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PcgQuotedLineTypeEdge = {
  __typename?: 'PCGQuotedLineTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PcgQuotedLineType>;
};

export type PcgRequestToBindEmailQuotationErrors = {
  __typename?: 'PCGRequestToBindEmailQuotationErrors';
  base?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PCGRequestToBindEmailQuotation */
export type PcgRequestToBindEmailQuotationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentInput>>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PCGRequestToBindEmailQuotation */
export type PcgRequestToBindEmailQuotationPayload = {
  __typename?: 'PCGRequestToBindEmailQuotationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<PcgRequestToBindEmailQuotationErrors>;
  quotation?: Maybe<PcgQuotation>;
};

/** PCG Review */
export type PcgReview = {
  __typename?: 'PCGReview';
  quotes?: Maybe<Array<PcgReviewStatusType>>;
  totalPremium?: Maybe<Scalars['String']>;
};

/** PCG Review Quote Type */
export type PcgReviewQuoteType = {
  __typename?: 'PCGReviewQuoteType';
  icon?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** PCG Review Status Type */
export type PcgReviewStatusType = {
  __typename?: 'PCGReviewStatusType';
  quote?: Maybe<PcgReviewQuoteType>;
  status?: Maybe<Scalars['Boolean']>;
  totalPremium?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PcgSaveProposalConfiguration */
export type PcgSaveProposalConfigurationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  proposalConfiguration: Scalars['JSON'];
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of PcgSaveProposalConfiguration */
export type PcgSaveProposalConfigurationPayload = {
  __typename?: 'PcgSaveProposalConfigurationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['String']>;
};

/** PCG Submission */
export type PcgSubmission = NotificationRelatesTo & Submission & {
  __typename?: 'PCGSubmission';
  applicants?: Maybe<Array<PcgApplicant>>;
  areNewDocuments?: Maybe<Scalars['Boolean']>;
  broadcasts: BroadcastConnection;
  collaborations: CollaborationConnection;
  collaborators: UserConnection;
  coverages?: Maybe<Array<PcgCoverageType>>;
  createdAt: Scalars['ISO8601DateTime'];
  dedupKey?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  discussion?: Maybe<Discussion>;
  documents: Array<Document>;
  emailSignOff?: Maybe<Scalars['String']>;
  fields: Scalars['JSON'];
  id: Scalars['ID'];
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  lineOfBusiness: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  owner: User;
  panel?: Maybe<Panel>;
  participants: UserConnection;
  participations: ParticipationConnection;
  pdfExport?: Maybe<Document>;
  placementTermsAccepted?: Maybe<Scalars['Boolean']>;
  policyEndDate?: Maybe<Scalars['Date']>;
  policyStartDate?: Maybe<Scalars['Date']>;
  products: InsurancePolicyConnection;
  proposals: DocumentConnection;
  quotations?: Maybe<PcgQuotationConnection>;
  review: PcgSubmissionReview;
  schemas: Scalars['JSON'];
  selectedCarriers: Array<EmailCarrierType>;
  status?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};


/** PCG Submission */
export type PcgSubmissionBroadcastsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Submission */
export type PcgSubmissionCollaborationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Submission */
export type PcgSubmissionCollaboratorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Submission */
export type PcgSubmissionParticipantsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Submission */
export type PcgSubmissionParticipationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Submission */
export type PcgSubmissionProductsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Submission */
export type PcgSubmissionProposalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** PCG Submission */
export type PcgSubmissionQuotationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for PCGSubmission. */
export type PcgSubmissionConnection = {
  __typename?: 'PCGSubmissionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PcgSubmissionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PcgSubmission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PcgSubmissionEdge = {
  __typename?: 'PCGSubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PcgSubmission>;
};

/** Attributes for a PCG submission */
export type PcgSubmissionInput = {
  applicants?: Maybe<Array<PcgApplicantInput>>;
  areNewDocuments?: Maybe<Scalars['Boolean']>;
  coverages?: Maybe<Array<PcgCoverageInput>>;
  dedupKey?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentInput>>;
  emailSignOff?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  placementTermsAccepted?: Maybe<Scalars['Boolean']>;
  policyEndDate?: Maybe<Scalars['Date']>;
  policyStartDate?: Maybe<Scalars['Date']>;
  product?: Maybe<PcgProductInputType>;
  selectedCarriers?: Maybe<Array<EmailCarrierInputType>>;
  status?: Maybe<Scalars['String']>;
};

/** PCG Submission Review */
export type PcgSubmissionReview = {
  __typename?: 'PCGSubmissionReview';
  additionalDrivers: Array<Scalars['String']>;
  applicants: Array<Scalars['String']>;
  coverages: Array<Scalars['String']>;
};

/** PCG Valuable Quoted Line Input */
export type PcgValuableQuotedLineInput = {
  additionalFeatures?: Maybe<Array<PcgAdditionalFeatureInput>>;
  coverages?: Maybe<Array<PcgValuablesQuotedLineCoveragesInput>>;
  id?: Maybe<Scalars['ID']>;
  totalPremium?: Maybe<Scalars['Currency']>;
  type?: Maybe<Scalars['String']>;
};

/** PCG Valuables Coverage Type */
export type PcgValuablesCoverageType = PcgCoverageType & {
  __typename?: 'PCGValuablesCoverageType';
  id: Scalars['ID'];
  type: Scalars['String'];
};

/** PCG Valuables Quoted Line Coverages Input */
export type PcgValuablesQuotedLineCoveragesInput = {
  blanketLimit?: Maybe<Scalars['Currency']>;
  limit?: Maybe<Scalars['Currency']>;
  name?: Maybe<Scalars['String']>;
};

/** PCG Valuables Quoted Line Coverages Type */
export type PcgValuablesQuotedLineCoveragesType = {
  __typename?: 'PCGValuablesQuotedLineCoveragesType';
  blanketLimit?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** PCG Valuables Quoted Line Type */
export type PcgValuablesQuotedLineType = PcgQuotedLineType & {
  __typename?: 'PCGValuablesQuotedLineType';
  additionalFeatures?: Maybe<Array<PcgAdditionalFeature>>;
  attachmentPoint?: Maybe<Scalars['Decimal']>;
  cedingCommission?: Maybe<Scalars['Decimal']>;
  coverableId: Scalars['ID'];
  coverableScope?: Maybe<Scalars['String']>;
  coverableType?: Maybe<Scalars['String']>;
  coverages?: Maybe<Array<PcgValuablesQuotedLineCoveragesType>>;
  createdAt: Scalars['ISO8601DateTime'];
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Decimal']>;
  exclusions: Array<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  groupableType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keyPerils: Array<Scalars['String']>;
  layerLimit?: Maybe<Scalars['Decimal']>;
  netPremium?: Maybe<Scalars['Decimal']>;
  note: Scalars['Text'];
  participation?: Maybe<Scalars['Decimal']>;
  pcgCoverage: PcgCoverageType;
  premium?: Maybe<Scalars['Decimal']>;
  proposedLayerPremium?: Maybe<Scalars['Decimal']>;
  quotation: PcgQuotation;
  totalPremium?: Maybe<Scalars['Currency']>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** A pdf templated used by Relay entities */
export type PdfTemplate = {
  __typename?: 'PdfTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  processor: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
};

/** The connection type for PdfTemplate. */
export type PdfTemplateConnection = {
  __typename?: 'PdfTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PdfTemplateEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PdfTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PdfTemplateEdge = {
  __typename?: 'PdfTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PdfTemplate>;
};


/** Performance Report data object for the analytics section */
export type PerformanceReportData = {
  __typename?: 'PerformanceReportData';
  averageBoundTime?: Maybe<Scalars['Decimal']>;
  averageReplyTime?: Maybe<Scalars['Decimal']>;
  declination?: Maybe<Scalars['Decimal']>;
  quoteToBind?: Maybe<Scalars['Decimal']>;
  reinsurerCompanyName: Scalars['String'];
  submitToBind?: Maybe<Scalars['Decimal']>;
  submitToQuote?: Maybe<Scalars['Decimal']>;
};

/** The connection type for PerformanceReportData. */
export type PerformanceReportDataConnection = {
  __typename?: 'PerformanceReportDataConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PerformanceReportDataEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PerformanceReportData>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PerformanceReportDataEdge = {
  __typename?: 'PerformanceReportDataEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PerformanceReportData>;
};

/** A permission granted to a given user */
export type Permission = {
  __typename?: 'Permission';
  action: Scalars['String'];
  area: Scalars['String'];
  id: Scalars['ID'];
  metadata: Scalars['JSON'];
  user: User;
};

/** The connection type for Permission. */
export type PermissionConnection = {
  __typename?: 'PermissionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PermissionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Permission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PermissionEdge = {
  __typename?: 'PermissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Permission>;
};

/** Attributes for granting a permission to a user */
export type PermissionInput = {
  /** The action the user can perform */
  action: Scalars['String'];
  /** The area the user can act upon */
  area: Scalars['String'];
  /** Any metadata for this action */
  metadata?: Maybe<Scalars['JSON']>;
};

/** What every insurance policy covers */
export type PolicyCoverage = {
  aggregateLimit?: Maybe<Scalars['Currency']>;
  comment?: Maybe<Scalars['String']>;
  customFields: Array<Field>;
  deductible?: Maybe<Scalars['Currency']>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentConnection>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  exposure?: Maybe<Scalars['Currency']>;
  id: Scalars['ID'];
  keyPerils?: Maybe<Array<Scalars['String']>>;
  kind?: Maybe<PolicyCoverageKind>;
  limit?: Maybe<Scalars['Currency']>;
  lines?: Maybe<Array<LineType>>;
  perOccurrenceLimit?: Maybe<Scalars['Currency']>;
  policy: InsurancePolicy;
  productsCompletedOpsAggregate?: Maybe<Scalars['Currency']>;
  ratingBasis?: Maybe<Scalars['String']>;
  stringDeductible?: Maybe<Scalars['String']>;
  type?: Maybe<LineOfBusiness>;
};


/** What every insurance policy covers */
export type PolicyCoverageDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum PolicyCoverageBasis {
  ClaimsMade = 'CLAIMS_MADE',
  NotSpecified = 'NOT_SPECIFIED',
  Occurrence = 'OCCURRENCE'
}

/** The connection type for PolicyCoverage. */
export type PolicyCoverageConnection = {
  __typename?: 'PolicyCoverageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PolicyCoverageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PolicyCoverage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PolicyCoverageEdge = {
  __typename?: 'PolicyCoverageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PolicyCoverage>;
};

/** Attributes for creating or updating policy coverages */
export type PolicyCoverageInput = {
  _destroy?: Maybe<Scalars['Boolean']>;
  aggregateLimit?: Maybe<Scalars['Currency']>;
  basis?: Maybe<PolicyCoverageBasis>;
  comment?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<FieldInput>>;
  deductible?: Maybe<Scalars['Currency']>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentInput>>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  exposure?: Maybe<Scalars['Currency']>;
  extendedReportingPeriod?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  insuredPropertyMajorTypes?: Maybe<Array<Scalars['String']>>;
  keyPerils?: Maybe<Array<Scalars['String']>>;
  kind?: Maybe<PolicyCoverageKind>;
  limit?: Maybe<Scalars['Currency']>;
  linesAttributes?: Maybe<Array<LineInput>>;
  majorCatastropheExposures?: Maybe<Array<Scalars['String']>>;
  perOccurrenceLimit?: Maybe<Scalars['Currency']>;
  productsCompletedOpsAggregate?: Maybe<Scalars['Currency']>;
  ratingBasis?: Maybe<Scalars['String']>;
  retroactiveDate?: Maybe<Scalars['Date']>;
  selfInsuredRetention?: Maybe<Scalars['Currency']>;
  statementsOfValues?: Maybe<Array<StatementOfValuesInput>>;
  stringDeductible?: Maybe<Scalars['String']>;
  sublimits?: Maybe<Array<SublimitInput>>;
  type?: Maybe<LineOfBusiness>;
  underwritingAttributes?: Maybe<UnderwritingInput>;
};

export enum PolicyCoverageKind {
  ExcessUmbrella = 'EXCESS_UMBRELLA',
  Primary = 'PRIMARY'
}

export enum PolicyType {
  BuySide = 'BUY_SIDE',
  SellerFlip = 'SELLER_FLIP',
  SellSide = 'SELL_SIDE'
}

/** The query root for Portal */
export type Portal = {
  __typename?: 'Portal';
  panel: Panel;
};


/** The query root for Portal */
export type PortalPanelArgs = {
  shareToken: Scalars['String'];
};

/** Autogenerated input type of PostBroadcast */
export type PostBroadcastInput = {
  attachments?: Maybe<Scalars['Upload']>;
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentInput>>;
  subject: Scalars['String'];
  type?: Maybe<BroadcastType>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of PostBroadcast */
export type PostBroadcastPayload = {
  __typename?: 'PostBroadcastPayload';
  broadcast?: Maybe<Broadcast>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of PostMessage */
export type PostMessageInput = {
  attachments?: Maybe<Scalars['Upload']>;
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  discussableId: Scalars['ID'];
  discussableType: DiscussableType;
  documents?: Maybe<Array<DocumentInput>>;
  signOff?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
};

/** Autogenerated return type of PostMessage */
export type PostMessagePayload = {
  __typename?: 'PostMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  message?: Maybe<DirectMessage>;
};

/** Autogenerated input type of PreBindEdit */
export type PreBindEditInput = {
  appendices: Scalars['Upload'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of PreBindEdit */
export type PreBindEditPayload = {
  __typename?: 'PreBindEditPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

export type Product = {
  coverages: Array<Coverage>;
  id: Scalars['ID'];
  panel?: Maybe<Panel>;
  quotations?: Maybe<Array<Quotation>>;
  status?: Maybe<Scalars['String']>;
  surplusLinesLicenseInfo?: Maybe<SurplusLinesType>;
};

/** The connection type for Product. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Product>;
};

/** What a property insurance policy covers */
export type PropertyCoverage = PolicyCoverage & {
  __typename?: 'PropertyCoverage';
  aggregateLimit?: Maybe<Scalars['Currency']>;
  comment?: Maybe<Scalars['String']>;
  customFields: Array<Field>;
  deductible?: Maybe<Scalars['Currency']>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentConnection>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  exposure?: Maybe<Scalars['Currency']>;
  id: Scalars['ID'];
  insuredPropertyMajorTypes: Array<Scalars['String']>;
  keyPerils?: Maybe<Array<Scalars['String']>>;
  kind?: Maybe<PolicyCoverageKind>;
  limit?: Maybe<Scalars['Currency']>;
  lines?: Maybe<Array<LineType>>;
  majorCatastropheExposures: Array<Scalars['String']>;
  perOccurrenceLimit?: Maybe<Scalars['Currency']>;
  policy: InsurancePolicy;
  productsCompletedOpsAggregate?: Maybe<Scalars['Currency']>;
  ratingBasis?: Maybe<Scalars['String']>;
  statementsOfValues: Array<StatementOfValues>;
  stringDeductible?: Maybe<Scalars['String']>;
  sublimits: Array<Sublimit>;
  type?: Maybe<LineOfBusiness>;
};


/** What a property insurance policy covers */
export type PropertyCoverageDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A configuration used to build client proposal */
export type ProposalConfiguration = {
  __typename?: 'ProposalConfiguration';
  configuration: Scalars['JSON'];
  id: Scalars['ID'];
};

/** Autogenerated input type of ProposeRevision */
export type ProposeRevisionInput = {
  attachmentPoint?: Maybe<Scalars['Currency']>;
  brokerageCommission?: Maybe<Scalars['Percentage']>;
  cedingCommission?: Maybe<Scalars['Percentage']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<FieldInput>>;
  deductible?: Maybe<Scalars['Currency']>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  keyPerils?: Maybe<Array<Scalars['String']>>;
  layerLimit?: Maybe<Scalars['Currency']>;
  message?: Maybe<Scalars['String']>;
  netPremium?: Maybe<Scalars['Currency']>;
  participation?: Maybe<Scalars['Percentage']>;
  premium?: Maybe<Scalars['Currency']>;
  quoteId: Scalars['ID'];
  quotedLayers?: Maybe<Array<QuotedLayerInput>>;
  subjectivities?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of ProposeRevision */
export type ProposeRevisionPayload = {
  __typename?: 'ProposeRevisionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  message?: Maybe<DirectMessage>;
  revision?: Maybe<Revision>;
};

/** Autogenerated input type of ProvidePolicy */
export type ProvidePolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  policyNumber: Scalars['String'];
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of ProvidePolicy */
export type ProvidePolicyPayload = {
  __typename?: 'ProvidePolicyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** The query root for QAndA */
export type QAndA = {
  __typename?: 'QAndA';
  questionable?: Maybe<Questionable>;
  subjectivitable?: Maybe<Subjectivitable>;
};


/** The query root for QAndA */
export type QAndAQuestionableArgs = {
  questionableId: Scalars['ID'];
  questionableType: QuestionableType;
};


/** The query root for QAndA */
export type QAndASubjectivitableArgs = {
  subjectivitableId: Scalars['ID'];
  subjectivitableType: SubjectivitableType;
};

/** The query root for this schema */
export type Query = {
  __typename?: 'Query';
  companyInvitation?: Maybe<CompanyInvitation>;
  cyber: Cyber;
  insuranceTypes?: Maybe<InsuranceTypeConnection>;
  panels: Panels;
  pcg: Pcg;
  portal: Portal;
  qAndA: QAndA;
  repsAndWarranties: NfpRepsAndWarranties;
  shared: Shareable;
  viewer: Viewer;
};


/** The query root for this schema */
export type QueryCompanyInvitationArgs = {
  hostId?: Maybe<Scalars['ID']>;
  signupToken: Scalars['String'];
};


/** The query root for this schema */
export type QueryInsuranceTypesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Question */
export type Question = NotificationRelatesTo & QuestionBase & {
  __typename?: 'Question';
  answer?: Maybe<Answer>;
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  deadline?: Maybe<Scalars['ISO8601DateTime']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  documents?: Maybe<Array<Document>>;
  id: Scalars['ID'];
  number: Scalars['String'];
  owner: User;
  questionable?: Maybe<Questionable>;
  questionableScope: Scalars['Text'];
  questionableType: Scalars['String'];
  section: Scalars['String'];
  text: Scalars['Text'];
  updatedAt: Scalars['ISO8601DateTime'];
  version: Scalars['Int'];
};

/** A questionable entity in Relay */
export type Questionable = {
  id: Scalars['ID'];
  questions: QuestionConnection;
};


/** A questionable entity in Relay */
export type QuestionableQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum QuestionableScope {
  Question = 'Question',
  Subjectivity = 'Subjectivity'
}

export enum QuestionableType {
  NfpRepsAndWarrantiesQuotation = 'NFPRepsAndWarrantiesQuotation',
  Participation = 'Participation',
  Quote = 'Quote'
}

/** QuestionBase */
export type QuestionBase = {
  documents?: Maybe<Array<Document>>;
  questionable?: Maybe<Questionable>;
};

/** The connection type for Question. */
export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuestionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Question>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Question>;
};

/** Errors for a Question */
export type QuestionErrors = {
  __typename?: 'QuestionErrors';
  base?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentErrors>;
  id?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** Attributes for a Question */
export type QuestionInput = {
  deadline?: Maybe<Scalars['ISO8601DateTime']>;
  documents?: Maybe<Array<DocumentInput>>;
  id?: Maybe<Scalars['ID']>;
  section: Scalars['String'];
  text: Scalars['Text'];
};

/** Relay Quotation */
export type Quotation = {
  ambestRating?: Maybe<AmBestRating>;
  documents: Array<Document>;
  id: Scalars['ID'];
  indication: Scalars['Boolean'];
  owner: User;
  participation: Participation;
  questions: QuestionConnection;
  selected?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  subjectivities: SubjectivityConnection;
  submission: Submission;
};


/** Relay Quotation */
export type QuotationQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Quotation */
export type QuotationSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Quotation. */
export type QuotationConnection = {
  __typename?: 'QuotationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuotationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Quotation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type QuotationEdge = {
  __typename?: 'QuotationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Quotation>;
};

/** Autogenerated input type of QuotationsUpdateSelection */
export type QuotationsUpdateSelectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  isSelected: Scalars['Boolean'];
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of QuotationsUpdateSelection */
export type QuotationsUpdateSelectionPayload = {
  __typename?: 'QuotationsUpdateSelectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  quotation?: Maybe<Quotation>;
};

/** Relay Quote */
export type Quote = Discussable & Importable & NotificationRelatesTo & Questionable & Subjectivitable & {
  __typename?: 'Quote';
  analysis?: Maybe<QuoteAnalysis>;
  appendices?: Maybe<DocumentConnection>;
  averagePlacementTime: Scalars['Int'];
  averageReplyRate: Scalars['Int'];
  averageResponseTime: Scalars['Int'];
  binder?: Maybe<DocumentConnection>;
  binderDraft?: Maybe<Document>;
  binderRequest?: Maybe<DocumentConnection>;
  binderWordDraft?: Maybe<Document>;
  brokerComment?: Maybe<Scalars['String']>;
  cedentZip?: Maybe<Document>;
  certificate?: Maybe<DocumentConnection>;
  certificateDraft?: Maybe<Document>;
  certificateNumber?: Maybe<Scalars['String']>;
  certificateWordDraft?: Maybe<Document>;
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discussion?: Maybe<Discussion>;
  endorsementQuotes?: Maybe<Array<EndorsementQuote>>;
  history: History;
  id: Scalars['ID'];
  manuallyTracked: Scalars['Boolean'];
  mergedBinder?: Maybe<Document>;
  outdatedAt?: Maybe<Scalars['Date']>;
  owner?: Maybe<Reinsurer>;
  permissions: EntityPermission;
  personalMessage?: Maybe<Scalars['String']>;
  personalSubject?: Maybe<Scalars['String']>;
  policyRequest?: Maybe<DocumentConnection>;
  providerLastViewedAt?: Maybe<Scalars['DateTime']>;
  questions: QuestionConnection;
  referenceNumber?: Maybe<Scalars['String']>;
  reinsuranceRequests: ReinsuranceRequestConnection;
  reinsurer?: Maybe<Reinsurer>;
  reinsurerZip?: Maybe<Document>;
  releasedAt?: Maybe<Scalars['DateTime']>;
  revisions: RevisionConnection;
  stage: Scalars['String'];
  status: Scalars['String'];
  statuses: WorksheetStatus;
  subjectivities: SubjectivityConnection;
  templateImports: TemplateImportConnection;
  updatedAt: Scalars['DateTime'];
  worksheet: Worksheet;
};


/** Relay Quote */
export type QuoteAppendicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Quote */
export type QuoteBinderArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Quote */
export type QuoteBinderRequestArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Quote */
export type QuoteCertificateArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Quote */
export type QuotePolicyRequestArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Quote */
export type QuoteQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Quote */
export type QuoteReinsuranceRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Quote */
export type QuoteRevisionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Quote */
export type QuoteStatusArgs = {
  readable?: Maybe<Scalars['Boolean']>;
};


/** Relay Quote */
export type QuoteSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Quote */
export type QuoteTemplateImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Relay Quote Analysis */
export type QuoteAnalysis = {
  __typename?: 'QuoteAnalysis';
  amountReinsured?: Maybe<Scalars['Currency']>;
  id: Scalars['ID'];
  premiumPerMillion?: Maybe<Scalars['Currency']>;
  quote: Quote;
  reinsurer: Reinsurer;
  status: Scalars['String'];
  worksheet: Worksheet;
};

/** The connection type for QuoteAnalysis. */
export type QuoteAnalysisConnection = {
  __typename?: 'QuoteAnalysisConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuoteAnalysisEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<QuoteAnalysis>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type QuoteAnalysisEdge = {
  __typename?: 'QuoteAnalysisEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<QuoteAnalysis>;
};

/** The connection type for Quote. */
export type QuoteConnection = {
  __typename?: 'QuoteConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuoteEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Quote>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A layer of quote by an insurer or reinsurer */
export type QuotedLayer = {
  __typename?: 'QuotedLayer';
  attachmentPoint: Scalars['Currency'];
  cedingCommission?: Maybe<Scalars['Percentage']>;
  declinedAt?: Maybe<Scalars['DateTime']>;
  deductible?: Maybe<Scalars['Currency']>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isIncomplete: Scalars['Boolean'];
  keyPerils?: Maybe<Array<Scalars['String']>>;
  layerLimit: Scalars['Currency'];
  netPremium?: Maybe<Scalars['Currency']>;
  note: Scalars['String'];
  participation: Scalars['Percentage'];
  policyCoverage?: Maybe<PolicyCoverage>;
  premium?: Maybe<Scalars['Currency']>;
};

/** Attributes for creating or updating a quoted layer */
export type QuotedLayerInput = {
  attachmentPoint: Scalars['Currency'];
  cedingCommission?: Maybe<Scalars['Percentage']>;
  deductible?: Maybe<Scalars['Currency']>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  keyPerils?: Maybe<Array<Scalars['String']>>;
  layerLimit: Scalars['Currency'];
  netPremium?: Maybe<Scalars['Currency']>;
  note?: Maybe<Scalars['String']>;
  participation: Scalars['Percentage'];
  policyCoverageId?: Maybe<Scalars['ID']>;
  premium?: Maybe<Scalars['Currency']>;
};

/** An edge in a connection. */
export type QuoteEdge = {
  __typename?: 'QuoteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Quote>;
};

/** Attributes for creating a worksheet quote */
export type QuoteInput = {
  /** Email message for reinsurer providing the quote */
  personalMessage?: Maybe<Scalars['String']>;
  /** Email subject for reinsurer providing the quote */
  personalSubject?: Maybe<Scalars['String']>;
  /** Email of the reinsurer providing the quote */
  reinsurerEmail?: Maybe<Scalars['String']>;
  /** The ID of the reinsurer providing the quote */
  reinsurerId?: Maybe<Scalars['ID']>;
};

/** The statuses of quotes for F1 */
export enum QuoteStatuses {
  Accepted = 'accepted',
  AwaitingResponse = 'awaiting_response',
  BinderOfAcknowledgement = 'binder_of_acknowledgement',
  BinderRequested = 'binder_requested',
  CertificateOfReinsuranceAccepted = 'certificate_of_reinsurance_accepted',
  CertificateOfReinsuranceIssued = 'certificate_of_reinsurance_issued',
  ClientApproved = 'client_approved',
  Declined = 'declined',
  InsurancePolicyRequested = 'insurance_policy_requested',
  ProposalAccepted = 'proposal_accepted',
  ProposalDeclined = 'proposal_declined',
  Proposed = 'proposed',
  Requested = 'requested'
}

/** Autogenerated input type of ReadMessage */
export type ReadMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  messageId: Scalars['ID'];
};

/** Autogenerated return type of ReadMessage */
export type ReadMessagePayload = {
  __typename?: 'ReadMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  discussable: Discussable;
  errors: Array<Scalars['String']>;
};

/** Attributes for recommended carriers */
export type RecommendedCarrierInput = {
  lineOfBusiness?: Maybe<Scalars['String']>;
  quotationId?: Maybe<Scalars['ID']>;
};

export enum Region {
  Canada = 'CANADA',
  Central = 'CENTRAL',
  GreatBritain = 'GREAT_BRITAIN',
  MidAtlantic = 'MID_ATLANTIC',
  Northeast = 'NORTHEAST',
  Southeast = 'SOUTHEAST',
  TagTeam = 'TAG_TEAM',
  West = 'WEST'
}

/** A reinsurance company */
export type ReinsuranceCompany = {
  __typename?: 'ReinsuranceCompany';
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

/** Reinsurance requested by the cedent */
export type ReinsuranceRequest = {
  __typename?: 'ReinsuranceRequest';
  attachmentPoint: Scalars['Currency'];
  cedingCommission?: Maybe<Scalars['Percentage']>;
  deductible?: Maybe<Scalars['Currency']>;
  id: Scalars['ID'];
  layerCoverage?: Maybe<LayerCoverage>;
  layerLimit: Scalars['Currency'];
  layerText: Scalars['String'];
  note: Scalars['String'];
  participation: Scalars['Percentage'];
  proposedLayerPremium?: Maybe<Scalars['Currency']>;
  proposedNetLayerPremium?: Maybe<Scalars['Currency']>;
  quotes: QuoteConnection;
  /** @deprecated Worksheet can now be accessed */
  worksheet: Worksheet;
};


/** Reinsurance requested by the cedent */
export type ReinsuranceRequestQuotesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for ReinsuranceRequest. */
export type ReinsuranceRequestConnection = {
  __typename?: 'ReinsuranceRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReinsuranceRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ReinsuranceRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ReinsuranceRequestEdge = {
  __typename?: 'ReinsuranceRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ReinsuranceRequest>;
};

/** Relay Reinsurer */
export type Reinsurer = NotificationRelatesTo & User & {
  __typename?: 'Reinsurer';
  aboutMe?: Maybe<Scalars['String']>;
  analytics: Analytics;
  api: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  chatToken?: Maybe<Scalars['String']>;
  company: Company;
  /** @deprecated Company name is accessible through the related company object */
  companyName: Scalars['String'];
  dashboard: Dashboard;
  dashboardEntries: CarrierDashboardEntryConnection;
  directReports: UserConnection;
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  emailSignOff?: Maybe<Scalars['Text']>;
  externallyManaged: Scalars['Boolean'];
  features: Scalars['JSON'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  generalInbox: Scalars['Boolean'];
  id: Scalars['ID'];
  indicationProvided?: Maybe<Scalars['Boolean']>;
  industries?: Maybe<Array<Scalars['String']>>;
  invitedBy?: Maybe<User>;
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  linesOfBusiness?: Maybe<Array<Scalars['String']>>;
  linkedinUrl?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['String']>>;
  manager?: Maybe<User>;
  marketplace: Marketplace;
  medianReplyTime?: Maybe<Scalars['Decimal']>;
  mfaCorporateEmailRequired?: Maybe<Scalars['Boolean']>;
  mfaCorporateNetworksRequired?: Maybe<Scalars['Boolean']>;
  mfaRemoteNetworkAccessRequired?: Maybe<Scalars['Boolean']>;
  newMarket: Scalars['Boolean'];
  notificationEvents: NotificationEventConnection;
  notificationSettings?: Maybe<NotificationSettingsType>;
  numberOfUwQuestions?: Maybe<Scalars['Decimal']>;
  /** @deprecated Replaced by the onboarding field */
  onboardedAt?: Maybe<Scalars['Date']>;
  onboarding?: Maybe<Scalars['String']>;
  panelistConfiguration?: Maybe<PanelistConfiguration>;
  participation?: Maybe<Participation>;
  pendingInvitation: Scalars['Boolean'];
  permissions: PermissionConnection;
  phoneNumber: Scalars['String'];
  quote?: Maybe<Quote>;
  quoteAnalyses: QuoteAnalysisConnection;
  quoteRatio?: Maybe<Scalars['Decimal']>;
  quotes: QuoteConnection;
  reports?: Maybe<Report>;
  rinkAccount: Account;
  role?: Maybe<Array<Scalars['String']>>;
  search: Array<Worksheet>;
  templateImport?: Maybe<TemplateImport>;
  templateImports: TemplateImportConnection;
  termsAndConditionsAcceptedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  uuid: Scalars['ID'];
  websiteOptional?: Maybe<Scalars['Boolean']>;
  wholesalerCompany?: Maybe<Company>;
  worksheet?: Maybe<Worksheet>;
};


/** Relay Reinsurer */
export type ReinsurerDashboardEntriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  quoteStatus?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


/** Relay Reinsurer */
export type ReinsurerDirectReportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Reinsurer */
export type ReinsurerNotificationEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Reinsurer */
export type ReinsurerPanelistConfigurationArgs = {
  panelId: Scalars['ID'];
};


/** Relay Reinsurer */
export type ReinsurerParticipationArgs = {
  participationId: Scalars['ID'];
};


/** Relay Reinsurer */
export type ReinsurerPermissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Reinsurer */
export type ReinsurerQuoteArgs = {
  quoteId: Scalars['ID'];
};


/** Relay Reinsurer */
export type ReinsurerQuoteAnalysesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Reinsurer */
export type ReinsurerQuotesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Reinsurer */
export type ReinsurerSearchArgs = {
  order?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


/** Relay Reinsurer */
export type ReinsurerTemplateImportArgs = {
  importId: Scalars['ID'];
};


/** Relay Reinsurer */
export type ReinsurerTemplateImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Reinsurer */
export type ReinsurerWorksheetArgs = {
  worksheetId: Scalars['ID'];
};

/** The connection type for Reinsurer. */
export type ReinsurerConnection = {
  __typename?: 'ReinsurerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReinsurerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Reinsurer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ReinsurerEdge = {
  __typename?: 'ReinsurerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Reinsurer>;
};

/** Autogenerated input type of ReleaseAnswer */
export type ReleaseAnswerInput = {
  answerId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ReleaseAnswer */
export type ReleaseAnswerPayload = {
  __typename?: 'ReleaseAnswerPayload';
  answer?: Maybe<Answer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<AnswerErrors>;
};

/** Autogenerated input type of RenewWorksheet */
export type RenewWorksheetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of RenewWorksheet */
export type RenewWorksheetPayload = {
  __typename?: 'RenewWorksheetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

/** Report object for the analytics section */
export type Report = {
  __typename?: 'Report';
  averageBoundTime?: Maybe<ReportSeriesConnection>;
  averageReplyTime?: Maybe<ReportSeriesConnection>;
  boundCoverage?: Maybe<ReportSeriesConnection>;
  boundCoverageByRating?: Maybe<ReportSeriesConnection>;
  declination?: Maybe<ReportSeriesConnection>;
  exposureReportData?: Maybe<ExposureReportDataTypeConnection>;
  lineOfBusinesses: Array<Scalars['String']>;
  markets: Array<Scalars['String']>;
  netPerMillion?: Maybe<ReportSeriesConnection>;
  performanceReportData?: Maybe<PerformanceReportDataConnection>;
  quoteToBind?: Maybe<ReportSeriesConnection>;
  submitToBind?: Maybe<ReportSeriesConnection>;
  submitToQuote?: Maybe<ReportSeriesConnection>;
  topMarketReportData?: Maybe<TopMarketDataTypeConnection>;
  totalPremium?: Maybe<ReportSeriesConnection>;
  totalPremiumByRating?: Maybe<ReportSeriesConnection>;
};


/** Report object for the analytics section */
export type ReportAverageBoundTimeArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportAverageReplyTimeArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportBoundCoverageArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportBoundCoverageByRatingArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportDeclinationArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportExposureReportDataArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportNetPerMillionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportPerformanceReportDataArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportQuoteToBindArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportSubmitToBindArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportSubmitToQuoteArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportTopMarketReportDataArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportTotalPremiumArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};


/** Report object for the analytics section */
export type ReportTotalPremiumByRatingArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Array<Scalars['String']>>;
  dateRange?: Maybe<DateRangeInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lineOfBusiness?: Maybe<Array<Scalars['String']>>;
  ownerId?: Maybe<Array<Scalars['String']>>;
  reinsurerCompanyName?: Maybe<Array<Scalars['String']>>;
};

/** Report series object for the analytics section */
export type ReportSeries = {
  __typename?: 'ReportSeries';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Decimal']>;
};

/** The connection type for ReportSeries. */
export type ReportSeriesConnection = {
  __typename?: 'ReportSeriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReportSeriesEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ReportSeries>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ReportSeriesEdge = {
  __typename?: 'ReportSeriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ReportSeries>;
};

/** Autogenerated input type of RequestApproval */
export type RequestApprovalInput = {
  attributes: WorksheetAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of RequestApproval */
export type RequestApprovalPayload = {
  __typename?: 'RequestApprovalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};

/** Autogenerated input type of RequestBinder */
export type RequestBinderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of RequestBinder */
export type RequestBinderPayload = {
  __typename?: 'RequestBinderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** The view of a retail brokerage using Relay */
export type RetailBrokerage = {
  __typename?: 'RetailBrokerage';
  agencyCode?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  signupToken?: Maybe<Scalars['String']>;
  users: RetailBrokerageUserConnection;
};


/** The view of a retail brokerage using Relay */
export type RetailBrokerageUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for RetailBrokerage. */
export type RetailBrokerageConnection = {
  __typename?: 'RetailBrokerageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RetailBrokerageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RetailBrokerage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RetailBrokerageEdge = {
  __typename?: 'RetailBrokerageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RetailBrokerage>;
};

/** The view of a retail brokerage using Relay */
export type RetailBrokerageUser = {
  __typename?: 'RetailBrokerageUser';
  fullName: Scalars['String'];
  id: Scalars['ID'];
  jobTitle: Scalars['String'];
};

/** The connection type for RetailBrokerageUser. */
export type RetailBrokerageUserConnection = {
  __typename?: 'RetailBrokerageUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RetailBrokerageUserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RetailBrokerageUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RetailBrokerageUserEdge = {
  __typename?: 'RetailBrokerageUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RetailBrokerageUser>;
};

/** Invite attributes */
export type RetailBrokerInvitationInput = {
  /** Reference code for the wholesaler to refer to this relationship. */
  agencyCode?: Maybe<Scalars['String']>;
  /** The email address to invite. */
  email: Scalars['Email'];
  /** Name of brokerage */
  name: Scalars['String'];
};

/** Relay Quote Revision */
export type Revision = NotificationRelatesTo & {
  __typename?: 'Revision';
  attachmentPoint?: Maybe<Scalars['Currency']>;
  brokerageCommission?: Maybe<Scalars['Percentage']>;
  cedingCommission?: Maybe<Scalars['Percentage']>;
  createdAt: Scalars['DateTime'];
  customFields: Array<Field>;
  deductible?: Maybe<Scalars['Currency']>;
  documents: DocumentConnection;
  exclusions?: Maybe<Array<Scalars['String']>>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isIncomplete: Scalars['Boolean'];
  keyPerils?: Maybe<Array<Scalars['String']>>;
  layerLimit?: Maybe<Scalars['Currency']>;
  netPremium?: Maybe<Scalars['Currency']>;
  number: Scalars['Int'];
  participation?: Maybe<Scalars['Percentage']>;
  premium?: Maybe<Scalars['Currency']>;
  quote: Quote;
  quotedLayers: Array<QuotedLayer>;
  source: Scalars['String'];
  status: Scalars['String'];
  subjectivities?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['DateTime'];
  zip?: Maybe<Document>;
};


/** Relay Quote Revision */
export type RevisionDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Revision. */
export type RevisionConnection = {
  __typename?: 'RevisionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RevisionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Revision>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RevisionEdge = {
  __typename?: 'RevisionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Revision>;
};

/** Autogenerated input type of SavePanelProposalConfiguration */
export type SavePanelProposalConfigurationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  panelId: Scalars['ID'];
  proposalConfiguration: Scalars['JSON'];
};

/** Autogenerated return type of SavePanelProposalConfiguration */
export type SavePanelProposalConfigurationPayload = {
  __typename?: 'SavePanelProposalConfigurationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  panel?: Maybe<Panel>;
};

export type SelectedPanelistsType = {
  __typename?: 'SelectedPanelistsType';
  auth_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type SelectField = Field & {
  __typename?: 'SelectField';
  name: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of SendEndorsementQuote */
export type SendEndorsementQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementQuoteId: Scalars['ID'];
  endorsementsToSign: Array<Scalars['Upload']>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SendEndorsementQuote */
export type SendEndorsementQuotePayload = {
  __typename?: 'SendEndorsementQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementQuote?: Maybe<EndorsementQuote>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of SendToMarkets */
export type SendToMarketsInput = {
  attributes?: Maybe<Scalars['JSON']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents?: Array<DocumentInput>;
  feSchema?: Maybe<Scalars['JSON']>;
  submissionId: Scalars['ID'];
};

/** Autogenerated return type of SendToMarkets */
export type SendToMarketsPayload = {
  __typename?: 'SendToMarketsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  submission?: Maybe<PanelSubmission>;
};

/** Autogenerated input type of SetCompanySettings */
export type SetCompanySettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companySettings: CompanySettingsInput;
};

/** Autogenerated return type of SetCompanySettings */
export type SetCompanySettingsPayload = {
  __typename?: 'SetCompanySettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of SetQuoteReleasedStatus */
export type SetQuoteReleasedStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
  released: Scalars['Boolean'];
};

/** Autogenerated return type of SetQuoteReleasedStatus */
export type SetQuoteReleasedStatusPayload = {
  __typename?: 'SetQuoteReleasedStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** A shareable entity in Relay */
export type Shareable = {
  id: Scalars['ID'];
  shareToken: ShareToken;
};

export type ShareQuestionableContactInfoInput = {
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ShareQuestionableForAnsweringErrors = {
  __typename?: 'ShareQuestionableForAnsweringErrors';
  base?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ShareQuestionableForAnswering */
export type ShareQuestionableForAnsweringInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contactInfo: ShareQuestionableContactInfoInput;
  questionableId: Scalars['ID'];
  questionableType: Scalars['String'];
};

/** Autogenerated return type of ShareQuestionableForAnswering */
export type ShareQuestionableForAnsweringPayload = {
  __typename?: 'ShareQuestionableForAnsweringPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<ShareQuestionableForAnsweringErrors>;
  shareableLink?: Maybe<Scalars['String']>;
  token?: Maybe<ShareToken>;
};

/** A share token in Relay */
export type ShareToken = {
  __typename?: 'ShareToken';
  createdAt: Scalars['ISO8601DateTime'];
  creator: User;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  fields: Scalars['JSON'];
  id: Scalars['ID'];
  scope: Scalars['String'];
  shareableType?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  useBrokerEmail?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of SignUpAndAcceptConnection */
export type SignUpAndAcceptConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  type: Scalars['String'];
  userUuid: Scalars['ID'];
};

/** Autogenerated return type of SignUpAndAcceptConnection */
export type SignUpAndAcceptConnectionPayload = {
  __typename?: 'SignUpAndAcceptConnectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<Account>;
};

/** Autogenerated input type of SignUp */
export type SignUpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  cognitoId: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  demoPlacement?: Maybe<DemoPlacementInput>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  type: Scalars['String'];
};

/** Autogenerated return type of SignUp */
export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** Management Liability Special Risk Quoted Line Type */
export type SpecialRiskQuotedLineType = PanelsQuotedLineType & {
  __typename?: 'SpecialRiskQuotedLineType';
  attachmentPoint?: Maybe<Scalars['Decimal']>;
  cedingCommission?: Maybe<Scalars['Decimal']>;
  coverableScope?: Maybe<Scalars['String']>;
  coverableType?: Maybe<Scalars['String']>;
  coverage: Coverage;
  coverageName: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Decimal']>;
  exclusions: Array<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  groupableType?: Maybe<Scalars['String']>;
  iconName: Scalars['String'];
  id: Scalars['ID'];
  keyPerils: Array<Scalars['String']>;
  layerLimit?: Maybe<Scalars['Decimal']>;
  netPremium?: Maybe<Scalars['Decimal']>;
  note: Scalars['Text'];
  participation?: Maybe<Scalars['Decimal']>;
  premium?: Maybe<Scalars['Decimal']>;
  proposedLayerPremium?: Maybe<Scalars['Decimal']>;
  quotation: PanelsQuotation;
  quotedLineNames: Scalars['JSON'];
  specialRisk?: Maybe<SpecialRiskType>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SpecialRiskType = {
  __typename?: 'SpecialRiskType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Autogenerated input type of StartEndorsement */
export type StartEndorsementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementId: Scalars['ID'];
};

/** Autogenerated return type of StartEndorsement */
export type StartEndorsementPayload = {
  __typename?: 'StartEndorsementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsement?: Maybe<Endorsement>;
  errors: Array<Scalars['String']>;
};

export enum State {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  AmericanSamoa = 'AMERICAN_SAMOA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  FederatedStatesOfMicronesia = 'FEDERATED_STATES_OF_MICRONESIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Guam = 'GUAM',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  MarshallIslands = 'MARSHALL_ISLANDS',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthernMarianaIslands = 'NORTHERN_MARIANA_ISLANDS',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  Palau = 'PALAU',
  Pennsylvania = 'PENNSYLVANIA',
  PuertoRico = 'PUERTO_RICO',
  RhodeIsland = 'RHODE_ISLAND',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Unknown = 'UNKNOWN',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  VirginIslands = 'VIRGIN_ISLANDS',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING'
}

/** Statement of values */
export type StatementOfValues = {
  __typename?: 'StatementOfValues';
  excludedPerils?: Maybe<Array<Scalars['String']>>;
  perils?: Maybe<Array<Scalars['String']>>;
  valueEntries: Array<StatementOfValuesEntry>;
};

/** An entry of statement of values */
export type StatementOfValuesEntry = {
  __typename?: 'StatementOfValuesEntry';
  cover: Scalars['String'];
  value: Scalars['String'];
};

/** Attributes for an entry of statement of values */
export type StatementOfValuesEntryInput = {
  /** The cover */
  cover: Scalars['String'];
  /** The value */
  value: Scalars['Currency'];
};

/** Attributes for statement of values */
export type StatementOfValuesInput = {
  excludedPerils?: Maybe<Array<Scalars['String']>>;
  perils?: Maybe<Array<Scalars['String']>>;
  valueEntries: Array<StatementOfValuesEntryInput>;
};

export type StringField = Field & {
  __typename?: 'StringField';
  name: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** A subjectivitable entity in Relay */
export type Subjectivitable = {
  id: Scalars['ID'];
  subjectivities: SubjectivityConnection;
};


/** A subjectivitable entity in Relay */
export type SubjectivitableSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum SubjectivitableType {
  NfpRepsAndWarrantiesQuotation = 'NFPRepsAndWarrantiesQuotation',
  Participation = 'Participation',
  Quote = 'Quote'
}

export type SubjectivitiesAttributesType = {
  __typename?: 'SubjectivitiesAttributesType';
  text?: Maybe<Scalars['String']>;
};

/** Subjectivity */
export type Subjectivity = NotificationRelatesTo & QuestionBase & {
  __typename?: 'Subjectivity';
  answer?: Maybe<Answer>;
  author: User;
  createdAt: Scalars['ISO8601DateTime'];
  deadline?: Maybe<Scalars['ISO8601DateTime']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  documents?: Maybe<Array<Document>>;
  id: Scalars['ID'];
  number: Scalars['String'];
  owner: User;
  questionable?: Maybe<Questionable>;
  questionableScope: Scalars['Text'];
  questionableType: Scalars['String'];
  section: Scalars['String'];
  text: Scalars['Text'];
  updatedAt: Scalars['ISO8601DateTime'];
  version: Scalars['Int'];
};

/** The connection type for Subjectivity. */
export type SubjectivityConnection = {
  __typename?: 'SubjectivityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubjectivityEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Subjectivity>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SubjectivityEdge = {
  __typename?: 'SubjectivityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Subjectivity>;
};

/** Reader attributes for sublimits */
export type Sublimit = {
  __typename?: 'Sublimit';
  limit: Scalars['Currency'];
  peril: Scalars['String'];
};

/** Attributes for sublimits */
export type SublimitInput = {
  /** The value of the sublimit */
  limit: Scalars['Currency'];
  /** The value of selected peril */
  peril: Scalars['String'];
};

/** Relay Submission */
export type Submission = {
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastEditedAt?: Maybe<Scalars['ISO8601DateTime']>;
  name: Scalars['String'];
  owner: User;
  pdfExport?: Maybe<Document>;
  status?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The connection type for Submission. */
export type SubmissionConnection = {
  __typename?: 'SubmissionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubmissionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Submission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SubmissionEdge = {
  __typename?: 'SubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Submission>;
};

/** Submission Notification settings input */
export type SubmissionNotificationSettingsInputType = {
  bound?: Maybe<Scalars['Boolean']>;
  declined?: Maybe<Scalars['Boolean']>;
  issued?: Maybe<Scalars['Boolean']>;
  received?: Maybe<Scalars['Boolean']>;
  referred?: Maybe<Scalars['Boolean']>;
  sentConfirmations?: Maybe<Scalars['Boolean']>;
};

/** Submission Notification settings Type */
export type SubmissionNotificationSettingsType = {
  __typename?: 'SubmissionNotificationSettingsType';
  bound?: Maybe<Scalars['Boolean']>;
  declined?: Maybe<Scalars['Boolean']>;
  issued?: Maybe<Scalars['Boolean']>;
  received?: Maybe<Scalars['Boolean']>;
  referred?: Maybe<Scalars['Boolean']>;
  sentConfirmations?: Maybe<Scalars['Boolean']>;
};

/** A subscription to Relay */
export type Subscription = {
  __typename?: 'Subscription';
  featureLevel: SubscriptionPlan;
  id: Scalars['ID'];
  period: Scalars['String'];
  plan: SubscriptionPlan;
  seatsRemaining?: Maybe<Scalars['Int']>;
  trialDaysRemaining: Scalars['Int'];
};

export enum SubscriptionPlan {
  Enterprise = 'enterprise',
  Grow = 'grow',
  Guest = 'guest',
  Launch = 'launch',
  Scale = 'scale'
}

export type SurplusLinesType = {
  __typename?: 'SurplusLinesType';
  address?: Maybe<LicenseAddressType>;
  expiration_date?: Maybe<Scalars['Date']>;
  license_holder?: Maybe<Scalars['String']>;
  license_number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** A team within a company */
export type Team = {
  __typename?: 'Team';
  company: Company;
  createdAt: Scalars['ISO8601DateTime'];
  fields: Scalars['JSON'];
  geography?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lineOfBusiness?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  users: UserConnection;
};


/** A team within a company */
export type TeamUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Team. */
export type TeamConnection = {
  __typename?: 'TeamConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TeamEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Team>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TeamEdge = {
  __typename?: 'TeamEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Team>;
};

/** Attributes to describe a team member */
export type TeamMemberInput = {
  avatar?: Maybe<Scalars['Upload']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['ID']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** A template for importing submissions */
export type Template = {
  __typename?: 'Template';
  company: Company;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  imports: TemplateImportConnection;
  name: Scalars['String'];
  processor: Scalars['String'];
};


/** A template for importing submissions */
export type TemplateImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Template. */
export type TemplateConnection = {
  __typename?: 'TemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TemplateEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Template>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Template>;
};

/** A template import */
export type TemplateImport = {
  __typename?: 'TemplateImport';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  importable?: Maybe<Importable>;
  template: Template;
  updatedAt: Scalars['DateTime'];
  user: User;
  /** @deprecated Use the polymorphic importable assocation instead */
  worksheet?: Maybe<Worksheet>;
};

/** The connection type for TemplateImport. */
export type TemplateImportConnection = {
  __typename?: 'TemplateImportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TemplateImportEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TemplateImport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TemplateImportEdge = {
  __typename?: 'TemplateImportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TemplateImport>;
};

/** Terrorism Coverage */
export type TerrorismCoverage = Coverage & {
  __typename?: 'TerrorismCoverage';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type TerrorismInsuringAgreement = {
  __typename?: 'TerrorismInsuringAgreement';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TerrorismProduct = Product & {
  __typename?: 'TerrorismProduct';
  add_policy_limit?: Maybe<Scalars['Boolean']>;
  additional_active_assailant?: Maybe<Scalars['Boolean']>;
  carriers?: Maybe<Array<Scalars['String']>>;
  coverage_end_date?: Maybe<Scalars['ISO8601Date']>;
  coverage_start_date?: Maybe<Scalars['ISO8601Date']>;
  coverages: Array<TerrorismCoverage>;
  created_at: Scalars['ISO8601DateTime'];
  custom_fields: Scalars['JSON'];
  deductible?: Maybe<Scalars['Currency']>;
  deleted_at?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['ISO8601Date']>;
  expiry_date?: Maybe<Scalars['ISO8601Date']>;
  fields?: Maybe<Scalars['JSON']>;
  general_liability?: Maybe<Scalars['Boolean']>;
  general_liability_limit?: Maybe<Scalars['Currency']>;
  history?: Maybe<HistoryType>;
  id: Scalars['ID'];
  insurable_type?: Maybe<Scalars['String']>;
  insurer: Scalars['String'];
  limit?: Maybe<Scalars['Currency']>;
  locations?: Maybe<Array<LocationsType>>;
  number: Scalars['String'];
  occupancy_type?: Maybe<Scalars['String']>;
  panel?: Maybe<Panel>;
  parent_policy?: Maybe<InsurancePolicy>;
  premium?: Maybe<Scalars['Decimal']>;
  program_structure?: Maybe<Scalars['String']>;
  quotations?: Maybe<Array<Quotation>>;
  self_insured_retention?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['String']>;
  surplusLinesLicenseInfo?: Maybe<SurplusLinesType>;
  target_price?: Maybe<Scalars['Currency']>;
  totalInsuredValue?: Maybe<Scalars['Currency']>;
  type?: Maybe<Scalars['String']>;
  underlying_policies: Array<InsurancePolicy>;
  updated_at: Scalars['ISO8601DateTime'];
  worksheet?: Maybe<Worksheet>;
};

/** Terrorism Quotation */
export type TerrorismQuotation = NotificationRelatesTo & Quotation & {
  __typename?: 'TerrorismQuotation';
  admitted?: Maybe<Scalars['Boolean']>;
  ambestRating?: Maybe<AmBestRating>;
  apiData?: Maybe<Scalars['JSON']>;
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  binders: DocumentConnection;
  boundAt?: Maybe<Scalars['ISO8601DateTime']>;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  createdAt: Scalars['ISO8601DateTime'];
  declinationReason?: Maybe<Scalars['String']>;
  declinedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Currency']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  derivedData: Scalars['JSON'];
  discussion?: Maybe<Discussion>;
  documents: Array<Document>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>;
  extractedAt?: Maybe<Scalars['ISO8601DateTime']>;
  failedAt?: Maybe<Scalars['ISO8601DateTime']>;
  fields: Scalars['JSON'];
  id: Scalars['ID'];
  indication: Scalars['Boolean'];
  insuringAgreements?: Maybe<Array<Maybe<TerrorismInsuringAgreement>>>;
  limit?: Maybe<Scalars['Currency']>;
  owner: User;
  participation: Participation;
  policies: DocumentConnection;
  policyNumber?: Maybe<Scalars['String']>;
  questions: QuestionConnection;
  quotedAt?: Maybe<Scalars['ISO8601DateTime']>;
  quotedLines: PanelsQuotedLineTypeConnection;
  requestedDeductible?: Maybe<Scalars['Currency']>;
  requestedLimit?: Maybe<Scalars['Currency']>;
  retailCommission?: Maybe<Scalars['Currency']>;
  retailCommissionPercentage?: Maybe<Scalars['Decimal']>;
  reviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selected?: Maybe<Scalars['Boolean']>;
  selectedOptionId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  subjectivities: SubjectivityConnection;
  submission: PanelSubmission;
  type: Scalars['String'];
  underwriterEmail?: Maybe<Scalars['String']>;
  underwriterFirstName?: Maybe<Scalars['String']>;
  underwriterLastName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user?: Maybe<User>;
};


/** Terrorism Quotation */
export type TerrorismQuotationBindersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Terrorism Quotation */
export type TerrorismQuotationPoliciesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Terrorism Quotation */
export type TerrorismQuotationQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Terrorism Quotation */
export type TerrorismQuotationQuotedLinesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Terrorism Quotation */
export type TerrorismQuotationSubjectivitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Terrorism Quoted Line */
export type TerrorismQuotedLineType = PanelsQuotedLineType & {
  __typename?: 'TerrorismQuotedLineType';
  annualPremium?: Maybe<Scalars['Currency']>;
  attachment_point?: Maybe<Scalars['Decimal']>;
  ceding_commission?: Maybe<Scalars['Decimal']>;
  clientCost?: Maybe<Scalars['Currency']>;
  coverable_scope?: Maybe<Scalars['String']>;
  coverable_type?: Maybe<Scalars['String']>;
  coverage: Coverage;
  coverageStartDate?: Maybe<Scalars['ISO8601Date']>;
  created_at: Scalars['ISO8601DateTime'];
  declined_at?: Maybe<Scalars['ISO8601DateTime']>;
  deductible?: Maybe<Scalars['Decimal']>;
  exclusions: Array<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  groupable_type?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key_perils: Array<Scalars['String']>;
  layer_limit?: Maybe<Scalars['Decimal']>;
  maximumCommissionPercentage?: Maybe<Scalars['Decimal']>;
  net_premium?: Maybe<Scalars['Decimal']>;
  note: Scalars['Text'];
  participation?: Maybe<Scalars['Decimal']>;
  policyNumber?: Maybe<Scalars['ID']>;
  premium?: Maybe<Scalars['Decimal']>;
  proposed_layer_premium?: Maybe<Scalars['Decimal']>;
  quotation: TerrorismQuotation;
  retailCommission?: Maybe<Scalars['Currency']>;
  retailCommissionPercentage?: Maybe<Scalars['Decimal']>;
  taxAmount?: Maybe<Scalars['Currency']>;
  totalCommissionPercentage?: Maybe<Scalars['Decimal']>;
  type: Scalars['String'];
  updated_at: Scalars['ISO8601DateTime'];
  wholesalerCommission?: Maybe<Scalars['Currency']>;
  wholesalerCommissionPercentage?: Maybe<Scalars['Decimal']>;
};


export type ThirdPartyType = {
  __typename?: 'ThirdPartyType';
  checkbox?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Currency']>;
  retention?: Maybe<Scalars['Currency']>;
};

/** Autogenerated input type of ToggleDeclineQuotedLayer */
export type ToggleDeclineQuotedLayerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quotedLayerId: Scalars['ID'];
  revisionId: Scalars['ID'];
};

/** Autogenerated return type of ToggleDeclineQuotedLayer */
export type ToggleDeclineQuotedLayerPayload = {
  __typename?: 'ToggleDeclineQuotedLayerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quotedLayer?: Maybe<QuotedLayer>;
};

/** Top Market Report data object for the analytics section */
export type TopMarketDataType = {
  __typename?: 'TopMarketDataType';
  netPerMillion?: Maybe<Scalars['Decimal']>;
  reinsurerCompanyName: Scalars['String'];
};

/** The connection type for TopMarketDataType. */
export type TopMarketDataTypeConnection = {
  __typename?: 'TopMarketDataTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TopMarketDataTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TopMarketDataType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TopMarketDataTypeEdge = {
  __typename?: 'TopMarketDataTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TopMarketDataType>;
};

/** Autogenerated input type of TriggerSubmissionExport */
export type TriggerSubmissionExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of TriggerSubmissionExport */
export type TriggerSubmissionExportPayload = {
  __typename?: 'TriggerSubmissionExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of UnapproveAnswer */
export type UnapproveAnswerInput = {
  answerId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UnapproveAnswer */
export type UnapproveAnswerPayload = {
  __typename?: 'UnapproveAnswerPayload';
  answer: Answer;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<AnswerErrors>;
};

/** Coverage claimed by others on a given placement */
export type UnavailableLayer = {
  __typename?: 'UnavailableLayer';
  attachmentPoint: Scalars['Currency'];
  id: Scalars['ID'];
  layerLimit: Scalars['Currency'];
  participation: Scalars['Percentage'];
};

/** The connection type for UnavailableLayer. */
export type UnavailableLayerConnection = {
  __typename?: 'UnavailableLayerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UnavailableLayerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UnavailableLayer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UnavailableLayerEdge = {
  __typename?: 'UnavailableLayerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UnavailableLayer>;
};

/** Attributes for creating or updating underwriting */
export type UnderwritingInput = {
  additionalInformation?: Maybe<Scalars['String']>;
  currentExposure?: Maybe<CurrentExposureInputType>;
  excessOfAmount?: Maybe<Scalars['Currency']>;
  garaging?: Maybe<Scalars['String']>;
  hiredNonOwnedExposure?: Maybe<Scalars['String']>;
  historicalExposure?: Maybe<Array<HistoricalExposureInputType>>;
  id?: Maybe<Scalars['ID']>;
  largeLosses?: Maybe<Array<LargeLossesInputType>>;
  lossInformation?: Maybe<Array<LossInformationInputType>>;
  radiusIntermediate?: Maybe<Scalars['Int']>;
  radiusLocal?: Maybe<Scalars['Int']>;
  radiusLongHaul?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of UpdateAndSendWorksheet */
export type UpdateAndSendWorksheetInput = {
  attributes: WorksheetAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of UpdateAndSendWorksheet */
export type UpdateAndSendWorksheetPayload = {
  __typename?: 'UpdateAndSendWorksheetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  invalidReinsurers?: Maybe<Array<Reinsurer>>;
  worksheet?: Maybe<Worksheet>;
};

/** Autogenerated input type of UpdateAuthorizedMarket */
export type UpdateAuthorizedMarketInput = {
  attributes: AuthorizedMarketWithContactsInput;
  authorizedMarketId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAuthorizedMarket */
export type UpdateAuthorizedMarketPayload = {
  __typename?: 'UpdateAuthorizedMarketPayload';
  authorizedMarket?: Maybe<AuthorizedMarket>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: AuthorizedMarketError;
};

/** Autogenerated input type of UpdateBrokerComment */
export type UpdateBrokerCommentInput = {
  brokerComment?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of UpdateBrokerComment */
export type UpdateBrokerCommentPayload = {
  __typename?: 'UpdateBrokerCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** Autogenerated input type of UpdateCommissionPercentage */
export type UpdateCommissionPercentageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  commissionPercentage: Scalars['String'];
  quotationId: Scalars['ID'];
};

/** Autogenerated return type of UpdateCommissionPercentage */
export type UpdateCommissionPercentagePayload = {
  __typename?: 'UpdateCommissionPercentagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
  quotation?: Maybe<PanelsQuotation>;
};

/** Updated fields on a worksheet */
export type UpdatedFields = {
  __typename?: 'UpdatedFields';
  address?: Maybe<UpdatedFieldType>;
  basisOfAcceptance?: Maybe<UpdatedFieldType>;
  certificateNumber?: Maybe<UpdatedFieldType>;
  clientName?: Maybe<UpdatedFieldType>;
  contractType?: Maybe<UpdatedFieldType>;
  cope?: Maybe<UpdatedFieldType>;
  customFields?: Maybe<UpdatedFieldType>;
  description?: Maybe<UpdatedFieldType>;
  industryType?: Maybe<UpdatedFieldType>;
  insuranceCoverage?: Maybe<UpdatedFieldType>;
  insurancePolicy?: Maybe<UpdatedFieldsInsurancePolicy>;
  internalReferenceNumber?: Maybe<UpdatedFieldType>;
  locationDescription?: Maybe<UpdatedFieldType>;
  lossHistory?: Maybe<UpdatedFieldType>;
  name?: Maybe<UpdatedFieldType>;
  namedInsured?: Maybe<UpdatedFieldType>;
  namedInsuredAddress?: Maybe<UpdatedFieldType>;
  participationComment?: Maybe<UpdatedFieldType>;
  participationSubject?: Maybe<UpdatedFieldType>;
  reinsuranceEffectiveDate?: Maybe<UpdatedFieldType>;
  reinsuranceExpiryDate?: Maybe<UpdatedFieldType>;
  riskDocuments?: Maybe<UpdatedFieldType>;
  showTower?: Maybe<UpdatedFieldType>;
  source?: Maybe<UpdatedFieldType>;
  specialInstructions?: Maybe<UpdatedFieldType>;
  underwriterName?: Maybe<UpdatedFieldType>;
};

/** Updated fields on a insurance policy */
export type UpdatedFieldsInsurancePolicy = {
  __typename?: 'UpdatedFieldsInsurancePolicy';
  effectiveDate?: Maybe<UpdatedFieldType>;
  expiryDate?: Maybe<UpdatedFieldType>;
};

/** Updated field value on a worksheet's updated_fields */
export type UpdatedFieldType = {
  __typename?: 'UpdatedFieldType';
  oldValue?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
};

/** Autogenerated input type of UpdateEndorsement */
export type UpdateEndorsementInput = {
  attributes: EndorsementInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsementId: Scalars['ID'];
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of UpdateEndorsement */
export type UpdateEndorsementPayload = {
  __typename?: 'UpdateEndorsementPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  endorsement?: Maybe<Endorsement>;
  errors: Array<Scalars['String']>;
};

/** Autogenerated input type of UpdateLicenseInformation */
export type UpdateLicenseInformationInput = {
  attributes?: Maybe<Scalars['JSON']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateLicenseInformation */
export type UpdateLicenseInformationPayload = {
  __typename?: 'UpdateLicenseInformationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of UpdateParticipationDocuments */
export type UpdateParticipationDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documents: Array<DocumentInput>;
  participantId: Scalars['ID'];
};

/** Autogenerated return type of UpdateParticipationDocuments */
export type UpdateParticipationDocumentsPayload = {
  __typename?: 'UpdateParticipationDocumentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Scalars['String']>>;
  participation: Participation;
};

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  aboutMe?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  industries?: Maybe<Array<Scalars['String']>>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  linesOfBusiness?: Maybe<Array<Scalars['String']>>;
  linkedinUrl?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['String']>>;
  notificationSettings?: Maybe<NotificationSettingsInputType>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of UpdateProfile */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateTaggedStatus */
export type UpdateTaggedStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentId: Scalars['ID'];
};

/** Autogenerated return type of UpdateTaggedStatus */
export type UpdateTaggedStatusPayload = {
  __typename?: 'UpdateTaggedStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  document: Document;
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated input type of UpdateWorksheet */
export type UpdateWorksheetInput = {
  attributes: WorksheetDraftAttributesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  worksheetId: Scalars['ID'];
};

/** Autogenerated return type of UpdateWorksheet */
export type UpdateWorksheetPayload = {
  __typename?: 'UpdateWorksheetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  worksheet?: Maybe<Worksheet>;
};


/** Existing uploaded docuemnt */
export type UploadedDocumentInput = {
  byteSize?: Maybe<Scalars['Int']>;
  filename: Scalars['String'];
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};

/** Relay User */
export type User = {
  aboutMe?: Maybe<Scalars['String']>;
  api: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  chatToken?: Maybe<Scalars['String']>;
  company: Company;
  /** @deprecated Company name is accessible through the related company object */
  companyName: Scalars['String'];
  directReports: UserConnection;
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  emailSignOff?: Maybe<Scalars['Text']>;
  externallyManaged: Scalars['Boolean'];
  features: Scalars['JSON'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  generalInbox: Scalars['Boolean'];
  id: Scalars['ID'];
  indicationProvided?: Maybe<Scalars['Boolean']>;
  industries?: Maybe<Array<Scalars['String']>>;
  invitedBy?: Maybe<User>;
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  linesOfBusiness?: Maybe<Array<Scalars['String']>>;
  linkedinUrl?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Scalars['String']>>;
  manager?: Maybe<User>;
  mfaCorporateEmailRequired?: Maybe<Scalars['Boolean']>;
  mfaCorporateNetworksRequired?: Maybe<Scalars['Boolean']>;
  mfaRemoteNetworkAccessRequired?: Maybe<Scalars['Boolean']>;
  notificationEvents: NotificationEventConnection;
  notificationSettings?: Maybe<NotificationSettingsType>;
  /** @deprecated Replaced by the onboarding field */
  onboardedAt?: Maybe<Scalars['Date']>;
  onboarding?: Maybe<Scalars['String']>;
  pendingInvitation: Scalars['Boolean'];
  permissions: PermissionConnection;
  phoneNumber: Scalars['String'];
  reports?: Maybe<Report>;
  rinkAccount: Account;
  role?: Maybe<Array<Scalars['String']>>;
  search: Array<Worksheet>;
  templateImport?: Maybe<TemplateImport>;
  templateImports: TemplateImportConnection;
  termsAndConditionsAcceptedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  uuid: Scalars['ID'];
  websiteOptional?: Maybe<Scalars['Boolean']>;
};


/** Relay User */
export type UserDirectReportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay User */
export type UserNotificationEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay User */
export type UserPermissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay User */
export type UserSearchArgs = {
  order?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


/** Relay User */
export type UserTemplateImportArgs = {
  importId: Scalars['ID'];
};


/** Relay User */
export type UserTemplateImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/** A user type in Relay */
export enum UserType {
  Broker = 'Broker',
  Cedent = 'Cedent',
  Reinsurer = 'Reinsurer'
}

/** Autogenerated input type of ViewedQuote */
export type ViewedQuoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quoteId: Scalars['ID'];
};

/** Autogenerated return type of ViewedQuote */
export type ViewedQuotePayload = {
  __typename?: 'ViewedQuotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<Scalars['String']>;
  quote?: Maybe<Quote>;
};

/** Data related to the current user session */
export type Viewer = {
  __typename?: 'Viewer';
  brokers: BrokerConnection;
  cedent?: Maybe<Cedent>;
  features?: Maybe<Features>;
  lastAmbestImport: AmBestImport;
  reinsuranceCompanies: Array<ReinsuranceCompany>;
  reinsurer?: Maybe<Reinsurer>;
  rinkProfiles?: Maybe<AccountConnection>;
  user?: Maybe<User>;
};


/** Data related to the current user session */
export type ViewerBrokersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Data related to the current user session */
export type ViewerReinsuranceCompaniesArgs = {
  search?: Maybe<Scalars['String']>;
};


/** Data related to the current user session */
export type ViewerRinkProfilesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  profileIds?: Maybe<Array<Scalars['ID']>>;
};

/** A wholesaler working on a retailer's placement */
export type WholesalerCollaboration = Collaboration & Discussable & {
  __typename?: 'WholesalerCollaboration';
  collaborative: Collaborative;
  discussion?: Maybe<Discussion>;
  id: Scalars['ID'];
  status: Scalars['String'];
  type: Scalars['String'];
  user: User;
  /** @deprecated Migrating to using collaborative */
  worksheet: Worksheet;
};

/** Relay Worksheet */
export type Worksheet = Broadcastable & Collaborative & Discussable & Importable & NotificationRelatesTo & {
  __typename?: 'Worksheet';
  address?: Maybe<Scalars['String']>;
  aggregateLimit?: Maybe<Scalars['Currency']>;
  averageLocationValue?: Maybe<Scalars['Currency']>;
  basisOfAcceptance?: Maybe<Scalars['String']>;
  broadcasts?: Maybe<BroadcastConnection>;
  brokerInvitationStatus?: Maybe<BrokerInvitationStatusType>;
  brokeredOrInvited: Scalars['Boolean'];
  canEndorse?: Maybe<Scalars['Boolean']>;
  /** @deprecated Replaced by owner */
  cedent: Cedent;
  /** @deprecated Replaced by reinsurance_requests */
  cedentRetentions: CedentRetentionConnection;
  certificateNumber?: Maybe<Scalars['String']>;
  client?: Maybe<User>;
  clientName?: Maybe<Scalars['String']>;
  collaborations: Array<Collaboration>;
  collaborators: Array<User>;
  commentUpdateWarning?: Maybe<Scalars['Boolean']>;
  commissionPercentage?: Maybe<Scalars['Decimal']>;
  companyRetention?: Maybe<Scalars['Currency']>;
  contractType?: Maybe<Scalars['String']>;
  cope?: Maybe<Scalars['Text']>;
  coverageType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  customFields: Array<Field>;
  /** @deprecated Deductible can now be accessed via the policy */
  deductible?: Maybe<Scalars['String']>;
  demo: Scalars['Boolean'];
  description?: Maybe<Scalars['Text']>;
  discussion?: Maybe<Discussion>;
  documents: DocumentConnection;
  editedCommentAt?: Maybe<Scalars['DateTime']>;
  endorsements: Array<Endorsement>;
  exclusions: Array<Scalars['String']>;
  export?: Maybe<Document>;
  history: History;
  id: Scalars['ID'];
  industryType?: Maybe<Array<Scalars['String']>>;
  insuranceCoverage?: Maybe<Scalars['String']>;
  insurancePolicy?: Maybe<InsurancePolicy>;
  insuranceType?: Maybe<Scalars['String']>;
  insuredPropertyMajorTypes: Array<Scalars['String']>;
  internalComment: Scalars['Text'];
  internalReferenceNumber?: Maybe<Scalars['String']>;
  isAgreed: Scalars['Boolean'];
  isBrokered: Scalars['Boolean'];
  isQuotable: Scalars['Boolean'];
  isRenewal?: Maybe<Scalars['Boolean']>;
  /** @deprecated Issuing company can now be accessed via the policy as `insurer` */
  issuingCompany?: Maybe<Scalars['String']>;
  keyPerils: Array<Scalars['String']>;
  kind: WorksheetKind;
  lineOfBusiness: LineOfBusiness;
  locationDescription?: Maybe<Scalars['String']>;
  lossHistory?: Maybe<Scalars['Text']>;
  lossRatio?: Maybe<Scalars['Decimal']>;
  majorCatastropheExposures: Array<Scalars['String']>;
  maximumLocationValue?: Maybe<Scalars['Currency']>;
  multiTower?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  namedInsured: Scalars['String'];
  namedInsuredAddress?: Maybe<Scalars['String']>;
  needByDate?: Maybe<Scalars['Date']>;
  numberOfLocations?: Maybe<Scalars['Int']>;
  numberOfLosses?: Maybe<Scalars['Int']>;
  numberOfSignificantLosses?: Maybe<Scalars['Int']>;
  owner: User;
  participationComment?: Maybe<Scalars['Text']>;
  participationSubject?: Maybe<Scalars['String']>;
  perOccurrenceLimit?: Maybe<Scalars['Currency']>;
  permissions: EntityPermission;
  /** @deprecated Policy effective date can now be accessed via the policy */
  policyEffectiveDate?: Maybe<Scalars['Date']>;
  /** @deprecated Policy expiry date can now be accessed via the policy */
  policyExpiryDate?: Maybe<Scalars['Date']>;
  policyLimit?: Maybe<Scalars['Currency']>;
  policyLinesOfBusiness: Array<LineOfBusiness>;
  /** @deprecated Policy number can now be accessed via the policy */
  policyNumber: Scalars['String'];
  policyType: PolicyCoverageKind;
  previousPlacement?: Maybe<Worksheet>;
  quote?: Maybe<Quote>;
  quoteAnalyses: QuoteAnalysisConnection;
  quotes: QuoteConnection;
  reinsuranceEffectiveDate?: Maybe<Scalars['Date']>;
  reinsuranceExpiryDate?: Maybe<Scalars['Date']>;
  reinsuranceRequests: ReinsuranceRequestConnection;
  renewal?: Maybe<Worksheet>;
  reviewers: Array<Reinsurer>;
  schemas?: Maybe<WorksheetsSchema>;
  showTower: Scalars['Boolean'];
  source?: Maybe<Scalars['String']>;
  specialInstructions: Scalars['String'];
  stages: WorksheetStatus;
  statementsOfValues: Array<StatementOfValues>;
  status: Scalars['String'];
  sublimits: Array<Sublimit>;
  templateImports: TemplateImportConnection;
  totalInsuredValue?: Maybe<Scalars['String']>;
  totalReinsuranceRequested: Scalars['Decimal'];
  unavailableLayers: UnavailableLayerConnection;
  underwriterName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedFields?: Maybe<UpdatedFields>;
  version: Scalars['Int'];
  zip?: Maybe<Document>;
};


/** Relay Worksheet */
export type WorksheetBroadcastsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Worksheet */
export type WorksheetCedentRetentionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Worksheet */
export type WorksheetDocumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Worksheet */
export type WorksheetQuoteArgs = {
  quoteId: Scalars['ID'];
};


/** Relay Worksheet */
export type WorksheetQuoteAnalysesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Worksheet */
export type WorksheetQuotesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Scalars['String']>>;
  last?: Maybe<Scalars['Int']>;
  reinsurerCompanyIds?: Maybe<Array<Scalars['ID']>>;
};


/** Relay Worksheet */
export type WorksheetReinsuranceRequestsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Worksheet */
export type WorksheetStatusArgs = {
  readable?: Maybe<Scalars['Boolean']>;
};


/** Relay Worksheet */
export type WorksheetTemplateImportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Relay Worksheet */
export type WorksheetUnavailableLayersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Attributes for creating or updating a worksheet */
export type WorksheetAttributesInput = {
  address?: Maybe<Scalars['String']>;
  basisOfAcceptance?: Maybe<Scalars['String']>;
  certificateNumber?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  commentUpdateWarning?: Maybe<Scalars['Boolean']>;
  commissionPercentage?: Maybe<Scalars['Percentage']>;
  companyRetention?: Maybe<Scalars['Currency']>;
  contractType?: Maybe<Scalars['String']>;
  cope?: Maybe<Scalars['String']>;
  coverageType?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  customFields?: Maybe<Array<FieldInput>>;
  deductible?: Maybe<Scalars['Currency']>;
  description?: Maybe<Scalars['String']>;
  editedCommentAt?: Maybe<Scalars['ISO8601DateTime']>;
  emailSignOff?: Maybe<Scalars['String']>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  industryType?: Maybe<Array<Scalars['String']>>;
  insuranceCoverage?: Maybe<Scalars['String']>;
  insurancePolicyAttributes?: Maybe<InsurancePolicyInput>;
  insuredPropertyMajorTypes: Array<Scalars['String']>;
  internalComment?: Maybe<Scalars['String']>;
  internalReferenceNumber?: Maybe<Scalars['String']>;
  isAgreed?: Maybe<Scalars['Boolean']>;
  isRenewal?: Maybe<Scalars['Boolean']>;
  keyPerils: Array<Scalars['String']>;
  kind?: Maybe<WorksheetKind>;
  lineOfBusiness: LineOfBusiness;
  locationDescription?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  lossHistory?: Maybe<Scalars['String']>;
  majorCatastropheExposures: Array<Scalars['String']>;
  name: Scalars['String'];
  namedInsured: Scalars['String'];
  namedInsuredAddress?: Maybe<Scalars['String']>;
  needByDate?: Maybe<Scalars['ISO8601DateTime']>;
  participationComment?: Maybe<Scalars['String']>;
  participationSubject?: Maybe<Scalars['String']>;
  policyType: PolicyCoverageKind;
  previousPlacementId?: Maybe<Scalars['ID']>;
  quotesAttributes: Array<QuoteInput>;
  reinsuranceEffectiveDate: Scalars['ISO8601DateTime'];
  reinsuranceExpiryDate: Scalars['ISO8601DateTime'];
  reinsuranceRequests?: Maybe<Array<CoverageInput>>;
  riskDocuments: Scalars['Upload'];
  selfInsuredRetention?: Maybe<Scalars['Currency']>;
  showTower?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  statementsOfValues?: Maybe<Array<StatementOfValuesInput>>;
  sublimits?: Maybe<Array<SublimitInput>>;
  totalInsuredValue?: Maybe<Scalars['Currency']>;
  underwriterName?: Maybe<Scalars['String']>;
};

/** The connection type for Worksheet. */
export type WorksheetConnection = {
  __typename?: 'WorksheetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WorksheetEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Worksheet>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Attributes for creating or updating a draft worksheet */
export type WorksheetDraftAttributesInput = {
  address?: Maybe<Scalars['String']>;
  basisOfAcceptance?: Maybe<Scalars['String']>;
  certificateNumber?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  commentUpdateWarning?: Maybe<Scalars['Boolean']>;
  commissionPercentage?: Maybe<Scalars['Percentage']>;
  companyRetention?: Maybe<Scalars['Currency']>;
  contractType?: Maybe<Scalars['String']>;
  cope?: Maybe<Scalars['String']>;
  coverageType?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<FieldInput>>;
  deductible?: Maybe<Scalars['Currency']>;
  description?: Maybe<Scalars['String']>;
  editedCommentAt?: Maybe<Scalars['ISO8601DateTime']>;
  emailSignOff?: Maybe<Scalars['String']>;
  exclusions?: Maybe<Array<Scalars['String']>>;
  industryType?: Maybe<Array<Scalars['String']>>;
  insuranceCoverage?: Maybe<Scalars['String']>;
  insurancePolicyAttributes?: Maybe<InsurancePolicyInput>;
  insuredPropertyMajorTypes?: Maybe<Array<Scalars['String']>>;
  internalComment?: Maybe<Scalars['String']>;
  internalReferenceNumber?: Maybe<Scalars['String']>;
  isAgreed?: Maybe<Scalars['Boolean']>;
  isRenewal?: Maybe<Scalars['Boolean']>;
  keyPerils?: Maybe<Array<Scalars['String']>>;
  kind?: Maybe<WorksheetKind>;
  lineOfBusiness: LineOfBusiness;
  locationDescription?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  lossHistory?: Maybe<Scalars['String']>;
  majorCatastropheExposures?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  namedInsured: Scalars['String'];
  namedInsuredAddress?: Maybe<Scalars['String']>;
  needByDate?: Maybe<Scalars['ISO8601DateTime']>;
  participationComment?: Maybe<Scalars['String']>;
  participationSubject?: Maybe<Scalars['String']>;
  policyType: PolicyCoverageKind;
  previousPlacementId?: Maybe<Scalars['ID']>;
  quotesAttributes?: Maybe<Array<QuoteInput>>;
  reinsuranceEffectiveDate?: Maybe<Scalars['ISO8601DateTime']>;
  reinsuranceExpiryDate?: Maybe<Scalars['ISO8601DateTime']>;
  reinsuranceRequests?: Maybe<Array<CoverageInput>>;
  riskDocuments?: Maybe<Scalars['Upload']>;
  selfInsuredRetention?: Maybe<Scalars['Currency']>;
  showTower?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  specialInstructions?: Maybe<Scalars['String']>;
  statementsOfValues?: Maybe<Array<StatementOfValuesInput>>;
  sublimits?: Maybe<Array<SublimitInput>>;
  totalInsuredValue?: Maybe<Scalars['Currency']>;
  underwriterName?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type WorksheetEdge = {
  __typename?: 'WorksheetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Worksheet>;
};

export enum WorksheetKind {
  Insurance = 'Insurance',
  Reinsurance = 'Reinsurance'
}

/** Worksheet form schemas */
export type WorksheetsSchema = {
  __typename?: 'WorksheetsSchema';
  autoLiabilityUnderwritingLoss: Scalars['JSON'];
  endorsement: Scalars['JSON'];
};

/** Tracks the current status of a worksheet */
export type WorksheetStatus = {
  __typename?: 'WorksheetStatus';
  awaitingApproval: Scalars['Boolean'];
  bound: Scalars['Int'];
  cancelled: Scalars['Int'];
  draft: Scalars['Boolean'];
  issued: Scalars['Int'];
  quoted: Scalars['Int'];
  submitted: Scalars['Int'];
};
