import React from 'react'
import { v4 as uuid } from 'uuid'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons'
import styled from 'styled-components'
import { truncateFileName } from 'util/document'
import { Column, Note, Table, TR, TD, Flex, Icon, ExternalLink } from 'atoms'
import Filename from 'molecules/DocumentUpload/Filename'
import UploadButton from 'molecules/DocumentUpload/UploadButton'

const Name = styled.span`
  flex-grow: 1;
  margin: 0 0.5rem;
  overflow-wrap: anywhere;
`

const Trash = ({ remove }) => {
  return (
    <Flex p={1}>
      <Icon data-testid="trash" icon={faTrash} onClick={remove} />
    </Flex>
  )
}

const F1Documents = ({ formData: documents = [], onChange, schema }) => {
  const upload = newDocs => {
    onChange([...documents, ...newDocs])
  }

  const onUpload = accepted => {
    upload(
      accepted.map(file => ({
        id: uuid(),
        attachment: file,
        category: "Signed",
        version: 1,
      }))
    )
  }

  const remove = index => {
    onChange(
      documents.map((doc, docIndex) =>
        docIndex === index ? { ...doc, _destroy: true } : doc
      )
    )
  }

  return (
    <Column pr={1}>
      <Flex flexDirection="column">
        <Table>
          {documents.map((file, index) => {
            if (file._destroy) return null

            return (
              <TR key={index}>
                {file.category === schema.unremovable ? (
                  <>
                    {schema.note &&
                      <Note>
                        {schema.note}
                      </Note>
                    }
                    <Flex>
                      <ExternalLink display="flex" href={file.attachment.url}>
                        <Icon icon={faPaperclip} fontSize="large" />
                        <Name>{truncateFileName(file.attachment.filename, 80)}</Name>
                      </ExternalLink>
                      <span>{schema.subtitle}</span>
                    </Flex>
                  </>
                ) : (
                  <>
                    <TD p="0px">
                      <Filename maxLength={80} document={file} />
                    </TD>
                    <TD p="0px">
                      {' '}
                      <Trash remove={() => remove(index)} />
                    </TD>
                  </>
                )}
              </TR>
            )
          })}
        </Table>
        <UploadButton onUpload={onUpload} label="Attach Document" secondary />
      </Flex>
    </Column>
  )
}

export default F1Documents
