import styled from 'styled-components'
import { flexbox, space } from 'styled-system'

const RightSideBarBorderless = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding-left: 22px;
  font-size: 14px;
  align-self: normal;
  ${flexbox}
  ${space}
`

const RightSideBar = styled(RightSideBarBorderless)`
  border-left: 1px solid ${props => props.theme.colors.lightGrey};
`

export { RightSideBarBorderless }
export default RightSideBar
