import React from 'react'
import styled from 'styled-components'

import Loading from 'components/Loading'
import BaseButton from 'components/common/Button'

import { Text } from 'atoms'

const Button = styled(BaseButton)`
  min-width: 100px;
  width: auto;
  margin-left: 1rem;
`

const LoadingContainer = styled.div`
  height: 47px;
`

const LoadingButton = ({ loading, onClick, buttonText }) => {
  return (
    <Button disabled={loading} onClick={onClick}>
      {loading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <Text>{`${buttonText}`}</Text>
      )}
    </Button>
  )
}

export default LoadingButton
