import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    marginTop: 8,
    marginBottom: 0,
  },
})

const TableRow = ({ properties, uiSchema }) => {
  const classes = useStyles()

  return (
    <>
      <Grid container={true} spacing={2} className={classes.root}>
        {properties.map((element, index) => {
          const ui = uiSchema[element.name]
          return (
            <Grid item={true} xs={ui?.cols || 12} key={index}>
              {element.content}
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default TableRow
