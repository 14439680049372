/*

Note: If any colors are being changed here, please also reflect
those changes in ~/web/src/atoms/form/DateSelect/react-datepicker.css

*/

const colors = {
  // STATIC
  black: '#000000',
  darkGray: '#555555',
  darkSlateGray: '#2F504F',
  lightGray: '#dddddd',
  white: '#FFFFFF',
  lightGreen: '#D6FAEB',
  red: '#C24040',

  // TEXT
  primaryText: '#434F5C',
  primaryTextLight: '#7A8A90',
  primaryTextMid: '#777777',
  primaryTextDark: '#1F1F1F',
  primaryTextInactive: '#9F9F9F',
  buttonText: '#1F1F1F',
  success: '#45C9BB',
  error: '#C24040',
  link: '#357FFA',
  disabled: '#999999',
  inactive: '#C5C3C0',
  navDisabled: '#7A8A90',

  // BRAND
  header: '#434F5C',
  primaryAction: '#FFC000',
  primaryActionText: '#1F1F1F',
  primary: '#2F4C54',
  secondaryBlue: '#CCDBDD',
  secondaryYellow: '#FFC000',
  lightGrey: '#E1E0E3',
  lightBlue: '#EEF7FF',
  message: '#636F74',
  panelTransition: '#EAF6FF',
  purple: '#9C6CFF',

  // STATUSES
  cedentRetained: '#EDE6D8',
  draft: '#FFD38D',
  submission: '#434F5C',
  quote: '#3EB7AF',
  binder: '#029557',
  certificate: '#357FFA',
  statusBackground: '#DDDFE2',
  // BACKGROUNDS
  background: '#F9F7F3',
  secondaryBackground: '#EDEAE1',
  errorBackground: '#FFE1E7',
  warningBackground: '#FCF3E5',
  isOutdatedPinBackground: '#C4C4C4',
  blueGradient: 'linear-gradient(345deg, #FFFFFF,#357FFA 1700%)',
  greenGradient: 'linear-gradient(345deg, #FFFFFF,#8FCE00 1700%)',
  panelTextBackground: '#F5F8F8',
  panelBackground:
    'linear-gradient(138.01deg, #EAF6FF 24.21%, #FFFFFF 47.93%, #FFFFFF 58.25%)',

  // CONTROLS
  control: '#434F5C',
  dropdown: '#DBD7CF',
  borderLine: '#D2CDC3',

  toast: {
    success: {
      bg: '#D6FAEB',
      text: '#307870',
      bar: '#48D5C6',
    },
    info: {
      bg: '#FCF3E5',
      bar: '#FFD38D',
      text: '#434F5C',
    },
    error: {
      bg: '#FFE1E7',
      bar: '#C24040',
      text: '#434F5C',
    },
  },
  launcherForm: {
    button: {
      borderColor: '#B1CFFF',
      hoverBackground: '#EEF7FF',
      selectedBackground: '#434F5C',
      color: '#357FFA',
      hoverColor: '#434F5C',
    },
  },
  inviteMarkets: {
    backgroundColor: '#F2EFE9',
  },
}

export default colors
