import React from 'react'
import { Dialog as MUIDialog } from '@material-ui/core'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { Icon, Flex, Div } from 'atoms'

const Dialog = ({ showDialog, onClose, bodyText, children, ...rest }) => {
  return (
    <MUIDialog
      {...rest}
      open={showDialog}
      onBackdropClick={onClose}
      disableScrollLock>
      {onClose ? (
        <Div position="absolute" right={8} top={8} color="colors.darkGray">
          <Icon onClick={onClose} display="inline" mr="5px" icon={faTimes} />
        </Div>
      ) : null}
      <Flex p={40} flexDirection="column">
        {bodyText || children}
      </Flex>
    </MUIDialog>
  )
}

export default Dialog
