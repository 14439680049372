/*
 * Public: Scroll to the first element on the page with a 'data-scroll'
 * attribute matching the provided type.
 *
 * type - the type of element you wish to scroll to as a string
 *
 * Returns a function to use in a callback, for example Redux Form's
 * `onSubmitFail` option.
 */
const defaultOpts = { behavior: 'smooth', block: 'nearest' }

const scrollTo = type => () => {
  const targets = document.querySelectorAll(`[data-scroll="${type}"]`)
  if (targets.length === 0) return undefined
  targets[0].scrollIntoView(defaultOpts)
}

const scrollToWithOpts =
  type =>
  (opts = defaultOpts) => {
    const targets = document.querySelectorAll(`[data-scroll="${type}"]`)
    if (targets.length === 0) return undefined
    targets[0].scrollIntoView(opts)
  }

const scrollToMuiError = () => {
  const targets = document.querySelectorAll(`.Mui-error`)
  if (targets.length === 0) return undefined
  targets[0].scrollIntoView(defaultOpts)
}

export { scrollTo, scrollToWithOpts, scrollToMuiError }
