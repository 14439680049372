export const cloneFiles = files => {
  if (files) {
    return files.map(d =>
      Object.prototype.hasOwnProperty.call(d, 'slice')
        ? new File([d.slice()], d.name, {
            type: d.type,
            lastModified: d.lastModified,
          })
        : d
    )
  } else {
    return files
  }
}
