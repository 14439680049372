import React, { useState } from 'react'
import groupBy from 'lodash/groupBy'
import moment from 'moment-timezone'
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  faCheck,
  faPlus,
  faQuestion,
  faExclamation,
  faFileImport,
  faShare,
  faDownload,
  faBell,
  faTrash,
  faEdit,
} from '@fortawesome/pro-solid-svg-icons'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons'

import { useCurrentUser } from 'hooks'
import nestFollowUpQuestions from 'util/nestFollowUpQuestions'
import { qandaTranslator } from 'util/translations'
import QAndAFilters from './QAndAFilters'
import ContextMenu from 'molecules/ContextMenu'
import { GenericContextMenu } from 'molecules/ContextMenu'
import ExportModal from 'components/QAndA/Export/ExportModal'
import ShareModal from 'components/QAndA/Share/ShareModal'
import useQueryParams from 'hooks/queryParams'
import {
  useDeleteQuestionMutation,
  useAcceptAnswerMutation,
  useDeleteAnswerMutation,
  useReleaseAnswerMutation,
  useShareQuestionsMutation,
  useUnapproveAnswerMutation,
} from 'graphql/api'
import {
  SectionTitle,
  Panel,
  IconCircle,
  Icon,
  Column,
  Flex,
  Div,
  Text,
  IconLink,
  ToggleLink,
  Button,
} from 'atoms'
import DocumentsTable from 'molecules/DocumentUpload/DocumentsTable'

export const QUESTION = 'Question'
export const SUBJECTIVITY = 'Subjectivity'

const QuestionRow = ({
  question,
  answer,
  accepted,
  getContextOptions,
  context,
  history,
  url,
  canAnswer,
  scope = QUESTION,
}) => {
  const options = getContextOptions(question, context)

  const [showDocuments, toggleDocuments] = useState(false)
  const translator = qandaTranslator(scope)
  const totalDocuments =
    (question && question.documents.length) +
    (answer && answer.documents.length)

  const released = answer && !!answer.releasedAt
  return (
    <Flex>
      {accepted ? (
        <IconCircle disabled bg="binder">
          <Icon icon={faCheck} color="white" />
        </IconCircle>
      ) : released ? (
        <IconCircle disabled bg="primaryText">
          <Icon icon={faBell} color="white" />
        </IconCircle>
      ) : answer ? (
        <IconCircle bg="primaryText">
          <Icon icon={faExclamation} color="white" />
        </IconCircle>
      ) : (
        <IconCircle disabled bg="primaryText">
          <Icon icon={faQuestion} color="white" />
        </IconCircle>
      )}

      <Flex flexDirection="column">
        <Flex justifyContent="space-between">
          <Text fontWeight={2}>
            {translator('Q')}
            {question.number}: {question.text}
            {question.deadline && !accepted && (
              <Text fontWeight={0}>{`(Due ${moment(
                question.deadline
              ).fromNow()})`}</Text>
            )}
          </Text>
          <Flex
            flexDirection="row"
            alignItems="flex-start"
            ml="auto"
            width="auto">
            {totalDocuments > 0 && (
              <ToggleLink
                fontWeight={2}
                width="auto"
                mr={0}
                onClick={() => toggleDocuments(!showDocuments)}
                showing={showDocuments}>
                {`${
                  showDocuments ? 'Hide' : 'Show'
                } ${totalDocuments} Document${totalDocuments > 1 ? 's' : ''}`}
              </ToggleLink>
            )}
            {options.length > 0 && (
              <ContextMenu
                data-testid="options-context-menu"
                right
                size="small"
                options={options}
              />
            )}
          </Flex>
        </Flex>
        {question.documents.length > 0 && showDocuments && (
          <Div mb={0}>
            <DocumentsTable
              documents={question.documents}
              hideTable={showDocuments}
            />
          </Div>
        )}
        {canAnswer && (
          <Div>
            <IconLink
              onClick={() =>
                history.push(
                  `${url}/${translator('questions')}/${question.id}/answers/new`
                )
              }
              icon={faPlus}
              className="keyValueInputAdd">
              {translator('addAnswer')}
            </IconLink>
          </Div>
        )}
        {answer && (
          <Column mt={accepted && 0}>
            <Text>{`${translator('A')}: ${question.answer.text}`}</Text>
            {answer.documents.length > 0 && showDocuments && (
              <Div mt={0}>
                <DocumentsTable
                  documents={answer.documents}
                  hideTable={showDocuments}
                />
              </Div>
            )}
          </Column>
        )}
      </Flex>
    </Flex>
  )
}

const hasAnsweredFollowUps = question => {
  if (!question.followUps) {
    return false
  } else {
    return question.followUps.some(q => !!q.answer)
  }
}

const QAndA = ({
  asker,
  responder,
  questions,
  scope = QUESTION,
  context,
  questionable,
  enableFollowUp = true,
}) => {
  const translator = qandaTranslator(scope)
  const location = useLocation()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { filter } = useQueryParams()
  const user = useCurrentUser()
  const [deleteQuestion] = useDeleteQuestionMutation({
    refetchQueries: ['Questions'],
  })
  const [deleteAnswer] = useDeleteAnswerMutation()
  const [acceptAnswer] = useAcceptAnswerMutation()
  const [releaseAnswer] = useReleaseAnswerMutation()
  const [shareQuestions] = useShareQuestionsMutation()
  const [unapproveAnswer] = useUnapproveAnswerMutation()

  const [activeTab, setActiveTab] = useState(filter || 'all')
  const [showExport, setShowExport] = useState(false)
  const [shareableLink, setShareableLink] = useState()

  const canAsk = asker && asker.id === user.id && context === 'asker'

  const retractQuestion = question => {
    const retractAlert = window.confirm(
      `Retract this ${translator('question')}?`
    )
    if (retractAlert) {
      deleteQuestion({
        variables: {
          input: {
            questionId: question.id,
          },
        },
        refetchQueries: [translator('Questions')],
      }).then(({ data }) => {
        if (data.deleteQuestion.error) {
          toast.error(data.deleteQuestion.error)
        } else {
          toast.success(`Your ${translator('question')} has been retracted`)
        }
      })
    }
  }

  const retractAnswer = answer => {
    const retractAlert = window.confirm(`Retract this ${'answer'}?`)
    if (retractAlert) {
      deleteAnswer({
        variables: {
          input: {
            answerId: answer.id,
          },
        },
        refetchQueries: [translator('Questions')],
      }).then(({ data }) => {
        if (data.deleteAnswer.error) {
          toast.error(data.deleteAnswer.error)
        } else {
          toast.success(`Your ${translator('answer')} has been retracted`)
        }
      })
    }
  }

  const shareQuestion = () => {
    shareQuestions({
      variables: {
        input: {
          questionableId: questionable.id,
          questionableType: 'NFP::RepsAndWarranties::Quotation',
          contactInfo: {
            //no longer required in the future
            email: 'test@example.com',
            message: 'message',
          },
        },
      },
    }).then(({ data }) => {
      if (data.shareQuestionableForAnswering.shareableLink) {
        setShareableLink(data.shareQuestionableForAnswering.shareableLink)
      } else {
        toast.error(data.shareQuestionableForAnswering.errors['base'])
      }
    })
  }

  const accept = answer => {
    acceptAnswer({
      variables: {
        input: {
          answerId: answer.id,
        },
      },
    }).then(({ data }) => {
      if (data.acceptAnswer.error) {
        toast.error(data.acceptAnswer.error)
      } else {
        toast.success(`You have approved the ${translator('answer')}`)
      }
    })
  }

  const unapprove = answer => {
    unapproveAnswer({
      variables: {
        input: {
          answerId: answer.id,
        },
      },
    }).then(({ data }) => {
      if (data.unapproveAnswer.error) {
        toast.error(data.unapproveAnswer.error)
      } else {
        toast.success(`You have unapproved the ${translator('answer')}`)
      }
    })
  }

  const release = answer => {
    releaseAnswer({
      variables: {
        input: {
          answerId: answer.id,
        },
      },
    }).then(({ data }) => {
      if (data.releaseAnswer.error) {
        toast.error(data.releaseAnswer.error)
      } else {
        toast.success(`You have released the ${translator('answer')}`)
      }
    })
  }

  const getContextOptions = question => {
    const answer = question.answer
    const accepted = answer && !!answer.acceptedAt
    const released = answer && !!answer.releasedAt

    if (accepted) {
      if (canAsk && scope === QUESTION) {
        return [
          {
            title: translator('unapproveAnswer'),
            icon: faCheck,
            onClick: () => unapprove(question.answer),
            autoClose: true,
          },
        ]
      }
      return []
    } else if (canAsk) {
      const options = []
      if (answer) {
        options.push({
          title: translator('approveAnswer'),
          icon: faCheck,
          onClick: () => accept(question.answer),

          autoClose: true,
        })
      } else {
        options.push({
          title: translator('editQuestion'),
          icon: faEdit,
          onClick: () =>
            history.push(
              `${url}/${translator('questions')}/${question.id}/edit`
            ),
          autoClose: true,
        })
      }
      if (!hasAnsweredFollowUps(question) && !answer) {
        options.push({
          title: translator('retractQuestion'),
          icon: faTrash,
          onClick: () => retractQuestion(question),
          autoClose: true,
        })
      }
      return options
    } else {
      const options = []
      if (!accepted && !released && answer) {
        options.push({
          title: translator('releaseAnswer'),
          icon: faCheck,
          onClick: () => release(question.answer),
          autoClose: true,
        })
      }
      if (answer && responder && responder.id === user.id) {
        options.push({
          title: translator('editAnswer'),
          icon: faEdit,
          onClick: () =>
            history.replace(
              `${url}/${translator('questions')}/${question.id}/answers/${
                answer.id
              }/edit`
            ),
          autoClose: true,
        })
        options.push({
          title: translator('retractAnswer'),
          icon: faTrash,
          onClick: () => retractAnswer(question.answer),
          autoClose: true,
        })
        return options
      } else if (!answer && responder && responder.id === user.id) {
        options.push({
          title: translator('addAnswer'),
          icon: faPlus,
          onClick: () =>
            history.replace(
              `${url}/${translator('questions')}/${question.id}/answers/new`
            ),
          autoClose: true,
        })
        return options
      } else {
        return []
      }
    }
  }

  const getAddQuestionContextOptions = () => {
    const options = []

    if (scope === SUBJECTIVITY) {
      if (canAsk) {
        options.push({
          title: translator('bulkImportQuestion'),
          icon: faFileImport,
          onClick: () => history.replace(`${url}/subjectivities/import`),
          autoClose: true,
        })
      }
    } else {
      if (canAsk) {
        options.push({
          title: translator('bulkImportQuestion'),
          icon: faFileImport,
          onClick: () => history.replace(`${url}/import`),
          autoClose: true,
        })
      } else if (questionable.__typename === 'NFPRepsAndWarrantiesQuotation') {
        options.push({
          title: translator('shareQuestion'),
          icon: faShare,
          onClick: () => shareQuestion(),
          autoClose: true,
        })
      }
    }

    options.push({
      title: translator('exportQuestion'),
      icon: faDownload,
      onClick: () => setShowExport(true),
      autoClose: true,
    })

    return options
  }

  const shouldShow = question => {
    if (activeTab === 'awaiting' && !question.answer) {
      return true
    } else if (
      activeTab === 'toApprove' &&
      !!question.answer &&
      !question.answer.acceptedAt
    ) {
      return true
    } else if (activeTab === 'all') {
      return true
    } else {
      return false
    }
  }
  const urlParamsQuery = new URLSearchParams(location.search)

  const groupedQuestions = Object.entries(groupBy(questions, 'section'))

  const filteredQuestions = groupedQuestions
    .map(questionSet => [
      questionSet[0],
      nestFollowUpQuestions(questionSet[1]).filter(
        question =>
          shouldShow(question) ||
          (question.followUps && question.followUps.some(shouldShow))
      ),
    ])
    .filter(questionSet => questionSet[1].length)

  const addQuestionOptions = getAddQuestionContextOptions(questions)

  const handleTabClick = tab => {
    setActiveTab(tab)
    urlParamsQuery.set('filter', tab)
    history.replace({
      pathname: url,
      search: urlParamsQuery.toString(),
    })
  }

  return (
    <>
      <Flex>
        <QAndAFilters
          activeTab={activeTab}
          scope={scope}
          handleTabClick={handleTabClick}
          questions={questions}
        />

        {canAsk && scope === QUESTION && (
          <Button
            onClick={() =>
              history.push(`${url}/${translator('questions')}/new`)
            }
            mr={0}
            px={2}
            data-testid="add-question-top"
            secondary>
            Add Question
          </Button>
        )}

        {addQuestionOptions.length > 0 && (
          <GenericContextMenu right options={addQuestionOptions}>
            <Button
              mr={0}
              minWidth={50}
              height={50}
              data-testid="import-context-menu"
              secondary>
              <Icon icon={faEllipsisV} fontSize="m" />
            </Button>
          </GenericContextMenu>
        )}
      </Flex>
      {groupedQuestions.length === 0 ? (
        <Panel p={2} mt={2} mb={2}>
          <Flex justifyContent="center">
            <Text>No {translator('Questions')} Found</Text>
          </Flex>
        </Panel>
      ) : (
        filteredQuestions.map(questionSet => (
          <Column key={questionSet[0]}>
            <Flex alignItems="baseline">
              <SectionTitle mr={0}>{questionSet[0]}</SectionTitle>
            </Flex>
            {questionSet[1].map(question => (
              <Panel key={question.id} p={2} mb={2}>
                <QuestionRow
                  mt={0}
                  scope={scope}
                  accepted={question.answer && !!question.answer.acceptedAt}
                  question={question}
                  getContextOptions={getContextOptions}
                  context={context}
                  history={history}
                  url={url}
                  answer={question.answer}
                  canAnswer={
                    !question.answer &&
                    context === 'responder' &&
                    user.id === responder.id
                  }
                />
                <Div mt={1}>
                  {question.followUps &&
                    question.followUps.map(followUpQuestion => (
                      <Div key={followUpQuestion.id} ml={2} mt={0}>
                        <QuestionRow
                          scope={scope}
                          ml={2}
                          mt={0}
                          accepted={
                            followUpQuestion.answer &&
                            !!followUpQuestion.answer.acceptedAt
                          }
                          question={followUpQuestion}
                          getContextOptions={getContextOptions}
                          context={context}
                          history={history}
                          url={url}
                          answer={followUpQuestion.answer}
                          canAnswer={
                            !followUpQuestion.answer &&
                            context === 'responder' &&
                            user.id === responder.id
                          }
                        />
                      </Div>
                    ))}
                </Div>
                {canAsk && enableFollowUp && (
                  <IconLink
                    mt={0}
                    onClick={() =>
                      history.push(`${url}/questions/${question.id}/follow-up`)
                    }
                    icon={faPlus}
                    className="keyValueInputAdd">
                    Add Follow-Up Question
                  </IconLink>
                )}
              </Panel>
            ))}
          </Column>
        ))
      )}
      {canAsk && (
        <IconLink
          onClick={() => history.push(`${url}/${translator('questions')}/new`)}
          icon={faPlus}
          className="keyValueInputAdd">
          Add {translator('Question')}
        </IconLink>
      )}
      {showExport && (
        <ExportModal
          questions={filteredQuestions}
          scope={scope}
          onClose={() => {
            setShowExport(false)
          }}
        />
      )}
      {shareableLink && (
        <ShareModal
          shareableLink={shareableLink}
          onClose={() => {
            setShareableLink()
          }}
        />
      )}
    </>
  )
}

export default QAndA
