import styled from 'styled-components'

const LeftSideBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: inherit
  border-right: 1px solid ${props => props.theme.colors.lightGrey};
  align-self: normal;
`

export default LeftSideBar
