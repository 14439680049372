import React from 'react'
import { useCurrentUser } from 'hooks'
import { Subtitle, Panel } from 'atoms'
import { useIsWholesalerGuest } from 'components/contexts/wholesalerSubmission'
import Messages from 'molecules/Messages'
import PostMessageForm from 'components/forms/PostMessageForm'

const Conversation = ({ submission, participation, getConvoDetails }) => {
  const user = useCurrentUser()
  const isWholesalerGuest = useIsWholesalerGuest()
  const messages = participation.discussion?.messages?.nodes
  const { isOwner, subject, recipient } = getConvoDetails(
    user,
    submission,
    participation
  )

  const owners = isOwner ? 'Your' : `${participation.user.fullName}'s`
  return (
    isOwner &&
    !isWholesalerGuest && (
      <>
        <Subtitle>
          {owners} Conversation with the {recipient}
        </Subtitle>
        <Panel p={2}>
          <PostMessageForm discussable={participation} subject={subject} />

          <Messages messages={messages} />
        </Panel>
      </>
    )
  )
}

export default Conversation
