import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Modal from 'components/common/Modal'
import Exit from 'components/common/Exit'
import { Flex } from 'atoms'

const Nav = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const EnhancedModal = ({
  onClose = null,
  children,
  testId,
  showExit = true,
  wide = false,
  light = false,
  persistant = false,
  ...rest
}) => {
  const history = useHistory()
  const handleClose = () => {
    if (onClose) {
      onClose()
    } else {
      history.goBack()
    }
  }

  return (
    <Modal
      persistant={persistant}
      light={light}
      data-testid={testId}
      wide={wide}
      handleClose={handleClose}
      {...rest}>
      <Flex position="relative" flexDirection="column">
        {showExit && (
          <Nav>
            <Exit onClick={handleClose} />
          </Nav>
        )}
        <div onClick={e => e.stopPropagation()}>{children}</div>
      </Flex>
    </Modal>
  )
}

export default EnhancedModal
