import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import unionBy from 'lodash/unionBy'
import { transparentize } from 'polished'
import { v4 as uuid } from 'uuid'
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons'

import { Flex, Div, Icon } from 'atoms'
import { colors } from 'components/theme'
import DocumentUploaderTable from './DocumentUploaderTable'
import { getNextVersion } from './index'

const Container = styled(Flex)`
  cursor: pointer;
`

const onAddDocuments = (
  attachments,
  currentDocuments = [],
  onChange,
  defaultCategory
) => {
  const version = getNextVersion(currentDocuments, defaultCategory)
  const newAttachments = unionBy(
    currentDocuments,
    attachments
      .filter(f => f)
      .map(file => ({
        id: uuid(),
        attachment: file,
        name: file.name,
        category: defaultCategory,
        version,
      })),
    f => `${f.name}:${f.category}`
  )
  onChange(newAttachments)
}

const onRemoveDocument = (attachment, currentDocuments = [], onChange) => {
  const newAttachments = currentDocuments.map(doc => {
    if (doc && doc.id === attachment.id) {
      return { ...attachment, _destroy: true }
    } else {
      return doc
    }
  })
  onChange(newAttachments)
}

const DocumentUploader = ({ input, meta, allCategories, defaultCategory }) => {
  const { name, onChange, value: documents } = input

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 1024 * 1024 * 50,
    onDrop: accepted =>
      onAddDocuments(accepted, documents, onChange, defaultCategory),
  })

  return (
    <>
      <Container
        position="relative"
        borderStyle="dashed"
        backgroundColor={
          isDragActive ? transparentize(0.1, 'white') : 'transparent'
        }
        borderColor={isDragActive ? 'green' : colors.link}
        justifyContent="center"
        alignItems="center"
        py={2}
        mb={1}
        {...getRootProps()}>
        <input data-testid="file-input" {...getInputProps()} />
        <Div>Drag and drop a file here</Div>
        <Div mx={1}>OR</Div>
        <Div>
          <Flex
            color="white"
            borderRadius={4}
            py="0.5rem"
            alignItems="center"
            px={1}
            backgroundColor={colors.link}>
            <Icon mr="0.5rem" icon={faPaperclip} />
            Select File to Upload
          </Flex>
        </Div>
      </Container>
      <DocumentUploaderTable
        allCategories={allCategories}
        meta={meta}
        keyName={name}
        onRemove={doc => onRemoveDocument(doc, documents, onChange)}
      />
    </>
  )
}

export default DocumentUploader
