import { colors, fonts } from 'components/theme'
import noop from 'lodash/noop'
import React from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
  color: ${props => (props.light ? colors.primaryTextLight : colors.link)};
  font-size: ${props => (props.large ? '16px' : '12px')};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  font-family: ${fonts.primary};
`

export const Terms = {
  SubscriptionTerms: {
    text: 'Terms and Conditions',
    url: 'https://www.relayplatform.com/relay-platform-terms',
  },
  PrivacyPolicy: {
    text: 'Privacy Policy',
    url: 'https://www.relayplatform.com/privacy-policy',
  },
  ServiceLevelTerms: {
    text: 'Service Level Terms',
    url: 'https://www.relayplatform.com/relay-platform-service-level-terms',
  },
}

export const Link = props => {
  const { children, href, onClick = noop, target = '_blank', ...rest } = props

  return (
    <StyledLink target={target} href={href} onClick={onClick} {...rest}>
      {children}
    </StyledLink>
  )
}

const LegalLink = ({ agreement, text = null, ...rest }) => {
  const term = Terms[agreement]
  if (!term) {
    return null
  }

  return (
    <Link target="_blank" href={term.url} {...rest}>
      {text || term.text}
    </Link>
  )
}

export default LegalLink
