import React from 'react'

import { money } from 'atoms/$'
import { Text, Table, TD, TR, TH } from 'atoms'

const ProductOptionsTable = ({ headings, options, monetary }) => {
  return (
    <Table
      header={
        <TR>
          {headings.map(heading => (
            <TH p="0px" key={heading}>
              {heading}
            </TH>
          ))}
        </TR>
      }>
      {options.map((option, index) => (
        <TR key={index}>
          {option.map((entry, col) => (
            <TD p="0px" key={entry}>
              <Text>{monetary[col] ? money(entry) : entry}</Text>
            </TD>
          ))}
        </TR>
      ))}
    </Table>
  )
}

export default ProductOptionsTable
