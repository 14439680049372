import React from 'react'
import { v4 as uuid } from 'uuid'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'

import { Flex, Label, Error, Table, TR, TH, TD, Icon } from 'atoms'
import Filename from './Filename'
import UploadButton from './UploadButton'

const Trash = ({ remove }) => {
  return (
    <Flex p={1}>
      <Icon data-testid="trash" icon={faTrash} onClick={remove} />
    </Flex>
  )
}

// This is similar to the SimpleUploader, but without the final form logic and
// with the category and version fields removed (for now)

const BasicUploader = ({
  label,
  note,
  required,
  defaultCategory,
  defaultVersion = 1,
  generateId = false,
  documents = [],
  upload,
  remove,
  error,
  ...props
}) => {
  // To prevent a race condition, generateId should be enabled for any forms that autosave.
  const onUpload = accepted => {
    upload(
      accepted.map(file => ({
        id: generateId ? uuid() : null,
        attachment: file,
        category: defaultCategory,
        version: defaultVersion,
      }))
    )
  }

  const filteredDocs = documents.filter(file => !file._destroy)

  if (filteredDocs.length > 0) {
    return (
      <Flex flexDirection="column" {...props}>
        <Table
          header={
            <TR>
              <TH p="0px">{label}</TH>
              <TH p="0px" width="1.5rem"></TH>
            </TR>
          }>
          {documents.map((file, index) => {
            if (file._destroy) return null

            return (
              <TR key={index}>
                <TD p="0px">
                  <Filename maxLength={80} document={file} />
                </TD>
                {!file.tags?.includes('_important') ? (
                  <TD p="0px">
                    {' '}
                    <Trash remove={() => remove(index)} />
                  </TD>
                ) : (
                  <TD />
                )}
              </TR>
            )
          })}
          {error && <Error>{error}</Error>}
        </Table>
        <UploadButton onUpload={onUpload} label="Attach Document" secondary />
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" {...props}>
      {label && (
        <Label mb={1} required={required} note={note}>
          {label}
        </Label>
      )}
      <UploadButton onUpload={onUpload} />
      {error && <Error>{error}</Error>}
    </Flex>
  )
}

export default BasicUploader
