import React from 'react'
import { useFormState } from 'react-final-form'

import { useAutoSave } from 'hooks'
import { Edited, Saved } from 'atoms'

const AutoSave = ({ children, ...options }) => {
  useAutoSave(options)
  return children
}

const SavedStatus = props => {
  const { dirty } = useFormState({ subscription: { dirty: true } })
  if (dirty) {
    return <Edited {...props} />
  }
  return <Saved {...props} />
}

export default AutoSave
export { SavedStatus }
