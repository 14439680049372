import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  EnhancedModal,
  Title,
  Text,
  Button,
  ButtonContainer,
  Column,
  Row,
} from 'atoms'
import { useOnClickOutside, usePanel, useFeature } from 'hooks'
import { Form } from 'react-final-form'
import { ShowConfig } from 'pages/shared/panels'

const BrokerDeclineModal = () => {
  const history = useHistory()
  const ref = useOnClickOutside(() => history.goBack())
  const panel = usePanel()
  const { submissionId, quoteId, ...params } = useParams()
  const panelId = params.panelId || panel.id
  const isMultiline = useFeature('multilineCyber')

  const lineOfBusiness =
    isMultiline && panel.lineOfBusiness === 'Cyber'
      ? 'Multiline Cyber'
      : panel.lineOfBusiness

  const config = ShowConfig.config(lineOfBusiness)

  const { declineQuote } = config.mutations

  const [declineEmailQuote] = declineQuote.mutation({
    onCompleted: data => {
      const error = declineQuote.getError(data)
      const returnPath = isMultiline
        ? `/panels/submissions/${submissionId}`
        : `/panels/${panelId}/submissions/${submissionId}`
      if (error) {
        toast.error(error)
        history.push(returnPath)
      } else {
        toast.success('Declined')
        history.push(returnPath)
      }
    },
  })

  const handleDecline = () => {
    declineEmailQuote(
      declineQuote.getVariables({
        quotationId: quoteId,
      })
    )
  }

  return (
    <EnhancedModal ref={ref} bg="white" showExit={false} light>
      <Form onSubmit={handleDecline}>
        {({ handleSubmit }) => (
          <Column height="100%" justifyContent="space-between">
            <Title fontWeight={2} mb={2} mt="none">
              Quote Declined
            </Title>
            <Row>
              <Text fontWeight={2} fontSize="m">
                Confirm this quote is declined?
              </Text>
            </Row>
            <ButtonContainer mt={3}>
              <Button tertiary onClick={history.goBack}>
                Cancel
              </Button>
              <Button onClick={handleSubmit}>
                <Text>Declined</Text>
              </Button>
            </ButtonContainer>
          </Column>
        )}
      </Form>
    </EnhancedModal>
  )
}

export default BrokerDeclineModal
