import React from 'react'
import styled from 'styled-components'

const LoadingContainer = styled.div`
  display: block;
  width: 100%;
  font-size: 2rem;
`

const LoadingText = styled.span`
  width: 100%;
  box-sizing: border-box;
  color: grey
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 400;
  font-size: 1.5em;
`

const Loader = styled.div`
  > span:nth-of-type(1) {
    animation: fadeInOut 2s ease 0s infinite;
  }
  > span:nth-of-type(2) {
    animation: fadeInOut 2s ease 0.1s infinite;
  }
  > span:nth-of-type(3) {
    animation: fadeInOut 2s ease 0.2s infinite;
  }

  @keyframes fadeInOut {
    0%,
    10%,
    60%,
    100% {
      opacity: 0;
    }

    20%,
    50% {
      opacity: 1;
    }
  }
`

const Loading = () => (
  <LoadingContainer>
    <LoadingText>
      <Loader data-testid="loading">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </Loader>
    </LoadingText>
  </LoadingContainer>
)

export default Loading
