import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  Quotation,
  QuotationsNav,
  QuotationEditButton,
  quoteStatus,
  notQuoteStatus,
} from 'pages/shared/panels'
import { QUOTE_STATUSES } from 'panels/constants'

const showQuotationIndex = (quotations, quoteId) => {
  for (let i = 0; i < quotations.length; i++) {
    let quotation = quotations[i]
    if (quotation.id === quoteId) {
      return i
    }
  }
  return -1
}

const Quotations = ({
  config,
  submission,
  participation,
  editUrl,
  basePath,
  userType,
}) => {
  const quotations = participation.quotations.nodes.sort(
    (a, b) => parseInt(a.id) - parseInt(b.id)
  )
  const country = participation.submission?.country
  const [visibleQuotations, setVisibleQuotations] = useState([])
  const { quoteId } = useParams()
  const bound = quotations.find(quote =>
    quoteStatus(quote, QUOTE_STATUSES.bound)
  )
  const issued = quotations.find(quote =>
    quoteStatus(quote, QUOTE_STATUSES.issued)
  )
  const [index, setIndex] = useState(
    showQuotationIndex(visibleQuotations, quoteId || quotations[0]?.id)
  )

  useEffect(() => {
    const notDeclined = quotations.filter(quote =>
      notQuoteStatus(quote, QUOTE_STATUSES.declined)
    )
    setVisibleQuotations(notDeclined.length > 0 ? notDeclined : quotations)
  }, [quotations])

  useEffect(() => {
    const quotationId = quoteId || visibleQuotations[0]?.id
    setIndex(showQuotationIndex(visibleQuotations, quotationId))
  }, [visibleQuotations, quoteId])

  if (index < 0) return null
  const current = visibleQuotations[index]
  const isOutdated = quoteStatus(current, QUOTE_STATUSES.outdated)

  return (
    <Quotation
      config={config}
      title="Quote(s)"
      submission={submission}
      quotation={current}
      country={country}
      bound={bound}
      issued={issued}
      isOutdated={isOutdated}
      userType={userType}>
      <QuotationsNav
        basePath={basePath}
        index={index}
        quotations={visibleQuotations}
        isOutdated={isOutdated}
      />
      <QuotationEditButton
        participation={participation}
        quotation={current}
        isOutdated={isOutdated}
        editUrl={editUrl}
        data-testid="quote-actions"
      />
    </Quotation>
  )
}

export default Quotations
