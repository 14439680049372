import GA from 'react-ga'

const isTest = process.env.NODE_ENV === 'test'
const hasGA = !!process.env.REACT_APP_GOOGLE_ANALYTICS_ID

if (!isTest && hasGA) {
  GA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
}

const reportPageView = () => {
  if (isTest || !hasGA) return
  if (typeof window !== 'undefined') {
    GA.pageview(window.location.pathname + window.location.search)
  }
}

const setUser = userId => {
  if (isTest || !hasGA) return
  GA.set({ userId })
}

const clearUser = () => {
  if (isTest || !hasGA) return
  GA.set({ userId: undefined })
}

const reportEvent = (category, action, args) => {
  if (isTest || !hasGA) return
  GA.event({
    category,
    action,
    ...args,
  })
}

export { reportPageView, setUser, clearUser, reportEvent }
