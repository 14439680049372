import React, { useRef } from 'react'
import styled from 'styled-components'
import { Div } from 'atoms'
import { useCurrentUser } from 'hooks'
import { LinkBorder } from 'atoms/StyledNavLink'

const StyledLink = styled(Div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 10px 10px 5px 10px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme, isWhitelabel }) =>
    isWhitelabel ? theme.colors.whitelabelText : theme.colors.white};
  transition: all 0.05s ease-in-out;

  &.active {
    border-bottom: 2px solid
      ${({ theme, isWhitelabel }) =>
        isWhitelabel ? theme.colors.whitelabelText : theme.colors.background};
  }

  &:hover {
    transform: scale(1.2);
    border: none;
  }
`

const MyForm = ({ isWhitelabel }) => {
  const currentUser = useCurrentUser()
  const ref = useRef()
  const handleSubmit = () => {
    ref.current.submit()
  }
  return (
    <LinkBorder isWhitelabel={isWhitelabel}>
      <form
        action="https://eriskhub.com/relay-sso.php"
        method="post"
        name="eRHSSO"
        target="_blank"
        ref={ref}>
        <input name="REQUESTER" type="hidden" value="Relay-sso-test" />
        <input name="EMAIL" type="hidden" value={currentUser.email} />
        <input name="USERID" type="hidden" value={currentUser.email} />
        <input name="FNAME" type="hidden" value={currentUser.firstName} />
        <input name="LNAME" type="hidden" value={currentUser.lastName} />
        <input
          name="ORGANIZATION"
          type="hidden"
          value={currentUser.company.name}
        />
      </form>
      <StyledLink onClick={handleSubmit} isWhitelabel={isWhitelabel}>
        Learn
      </StyledLink>
    </LinkBorder>
  )
}

export default MyForm
