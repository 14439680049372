import { useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { diff } from 'deep-object-diff'
import * as Sentry from '@sentry/react'

const meld = (server, sent, current, resolvers = {}) => {
  const allKeys = Object.keys({ ...server, ...current })
  return allKeys.reduce((result, key) => {
    // If we have a custom resolver for this key, use it
    // eslint-disable-next-line no-prototype-builtins
    if (resolvers.hasOwnProperty(key) && typeof resolvers[key] === 'function') {
      result[key] = resolvers[key](server[key], sent[key], current[key])
      return result
    }

    // If the server added this key and we don't have it on the
    // frontend, use the new value from the server.
    // If the current value has been changed since we sent the data, keep the
    // latest changes made by the user, otherwise the server takes precedence.
    if (
      typeof current === 'undefined' ||
      typeof sent === 'undefined' ||
      isEqual(sent[key], current[key]) ||
      current[key] === undefined
    ) {
      result[key] = server[key]
    } else {
      result[key] = current[key]
    }
    // If there's a nested object, meld it recursively
    if (
      typeof result[key] === 'object' &&
      result[key] !== null &&
      !Array.isArray(result[key])
    ) {
      result[key] = meld(
        server[key],
        sent && sent[key], // check for nested objects
        current && current[key],
        resolvers[key] || {}
      )
    }
    return result
  }, {})
}

const saverFactory =
  ({
    backendValues,
    getFormData,
    getFormErrors,
    save,
    setSaving,
    autosaveResolvers,
  }) =>
  ({ controls, form }) => {
    const sentValues = { ...form.formData }
    const changes = diff(backendValues.current, sentValues)
    const matchesBackend = backendValues.current !== null && isEmpty(changes)
    if (matchesBackend) return

    setSaving(true)
    save(form.formData)
      .then(result => {
        const serverErrors = getFormErrors(result)
        setSaving(false)
        if (!isEmpty(serverErrors)) {
          controls.setErrors(serverErrors)
        } else {
          const serverValues = getFormData(result)
          backendValues.current = serverValues
          const currentValues = controls.getValues()
          const updatedFormData = meld(
            serverValues,
            sentValues,
            currentValues,
            autosaveResolvers
          )
          controls.setFields(updatedFormData)
        }
      })
      .catch(error => {
        console.error(error)
        Sentry.captureException(error)
      })
  }

const useAutoSave = ({ save, debounceTime, ...rest }) => {
  const backendValues = useRef(null)
  const [saving, setSaving] = useState(false)
  const pendingSave = useRef(null)
  const saveFn = saverFactory({
    save,
    backendValues,
    setSaving,
    ...rest,
  })
  const debouncedSave = useRef(debounce(saveFn, debounceTime))
  const saveFunc = args => {
    if (saving) {
      // Save in progress, deferring
      if (pendingSave.current) {
        clearTimeout(pendingSave.current)
      }
      pendingSave.current = setTimeout(() => {
        // 'Executing deferred save'
        return debouncedSave.current(args)
      }, 1000)
    } else {
      debouncedSave.current(args)
    }
  }

  return [saveFunc, { saving }]
}

export default useAutoSave
