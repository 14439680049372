import { css } from 'styled-components'

import colors from './colors'

const ieFixes = `
  select::-ms-expand {
    display: none;
  }

  button {
    border: 0;
  }
`

const appcues = `
  .beacon-inner {
    fill: #ffcd7e !important;
  }

  .beacon-outer {
    stroke: #ffcd7e !important;
  }

  .hotspot-animation-pulse {
    -webkit-animation: appcues-beacon-pulse 1s ease-out 2s infinite !important;
    -moz-animation: appcues-beacon-pulse 1s ease-out 2s infinite !important;
    animation: appcues-beacon-pulse 1s ease-out 2s infinite !important;
  }

  @keyframes appcues-beacon-pulse {
    0% {
      stroke-width: 22;
      -moz-transform: scale(0.1);
      -ms-transform: scale(0.1);
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
    }

    50% {
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
      stroke-width: 0.5;
    }

    100% {
      stroke-width: 0;
    }
  }
`

const AntdPopover = `
  .antd-popover-green {
    .ant-popover-content {
      .ant-popover-arrow {
            display: none;
      }
      .ant-popover-inner {
        background: ${colors.lightGreen};
        border: 1px solid ${colors.success};

        .ant-popover-inner-content {
          .successContainer {
            display: flex;
          }
        }
      }

    }
  }
`

const AntdTooltip = `
 .antd-tooltip-default {
  .ant-tooltip-inner {
    padding: 15px 20px;
    color: ${colors.primaryText};
    background-color: ${colors.white};
    box-shadow: 0 5px 15px 2px rgba(112, 111, 107, 0.25);
  }

  .ant-tooltip-arrow-content{
    background-color: ${colors.white}!important;
  }
 }
`

const AntdTabs = `
  .ant-tabs-tab{
    color: #434F5C;
  }
  .ant-tabs{
    .ant-tabs-nav {
      margin-bottom: 32px;
    }
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #d2cdc3;
  }
`

const AntdDivider = `
  .ant-divider{
    background-color: #d2cdc3;
  }
`

const AntdCheckbox = `
  .ant-checkbox{
    .ant-checkbox-inner {
      border: 1px solid rgba(67, 79, 92, 0.5);
    }
  }
`

const AntdInput = `
  .ant-form-item{
    margin-bottom: 16px;
  }
`

const AntdSelect = `
  .ant-select-selection-item{
    color: #434F5C;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color:#EAF6FF;
  }
`

const AntdButton = `
  .ant-btn{
    height: 40px;
  }
  .ant-btn-primary{
    box-shadow: none;
    height: 40px;
  }
`

const richTextEditor = css`
  .richTextEditor {
    width: calc(100% - 1rem);
    height: 250px;
    resize: vertical;
    margin: 0.5rem;
    padding: 0 0.5rem;
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 300;
    font-size: 16px;
    color: ${props => props.theme.colors.primaryText};
    outline: none;
    box-shadow: 1px 2px 2px inset #eee;
    border-width: 1px;
    border-style: solid;
    border-radius: 0.25rem;
    border-color: #c2c4c6;
    background-color: white !important;
    overflow: hidden;

    &:hover,
    &:focus {
      border-color: #afb1b3;
    }
  }
`

export default css`
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600');

  html {
    overflow-y: auto;
    overflow-x: hidden;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    color: ${colors.primaryText};
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  ${richTextEditor}

  ${ieFixes}

  ${appcues}

  ${AntdPopover}

  ${AntdTabs}

  ${AntdDivider}

  ${AntdCheckbox}

  ${AntdInput}

  ${AntdButton}

  ${AntdSelect}

  ${AntdTooltip}
`
