import useCurrentUser from './currentUser'

const states = ['started', 'setup_profile', 'in_progress', 'completed']

const useOnboarding = progress => {
  const currentUser = useCurrentUser()
  const onboarding = currentUser ? currentUser.onboarding : 'completed'
  return states.indexOf(progress) === states.indexOf(onboarding)
}

export default useOnboarding
