import BindContent from './BindContent'
import QuoteContent from './QuoteContent'

const noop = () => {}

import {
  usePanelsQuotationQuery,
  useCyberRequestToBindMutation,
} from 'graphql/api'
import { queryDefaults } from '../utils'

const queries = {
  participationQuotations: {
    ...queryDefaults.participationQuotations,
    query: usePanelsQuotationQuery,
    getParticipation: data => data.panels.participation,
  },
}

const mutations = {
  requestToBind: {
    mutation: useCyberRequestToBindMutation,
    getVariables: ({ quotationId, coverageStartDate }) => ({
      variables: {
        input: {
          quotationId,
          coverageStartDate,
        },
      },
    }),
    getError: data => data.cyberRequestToBind.error,
  },
  emailQuote: {
    mutation: () => [
      noop,
      {
        loading: false,
      },
    ],
  },
}

const getters = {
  refetchQueries: ['CyberQuotation'],
}

const components = {
  BindContent: BindContent,
  QuoteContent: QuoteContent,
}

export default {
  queries,
  mutations,
  components,
  getters,
}
