import { Link as ReactRouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { typography, space, color, layout, flexbox } from 'styled-system'

const defaults = {
  fontSize: 'm',
  fontFamily: 'primary',
  color: 'primaryText',
}

const textOverflow = ({ textOverflow }) => {
  if (textOverflow) {
    return {
      textOverflow: textOverflow,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }
  }
}

const textTransform = ({ textTransform }) =>
  textTransform && `text-transform: ${textTransform};`

const Text = styled.span(
  typography,
  space,
  color,
  layout,
  flexbox,
  textOverflow,
  textTransform
)
Text.defaultProps = defaults
Text.displayName = 'Text'

const P = styled.p(typography, space, color, layout, flexbox)
P.defaultProps = defaults
P.displayName = 'Paragraph'

const Title = styled.h1(typography, space, color, layout, flexbox)
Title.defaultProps = { ...defaults, fontSize: 'xxl' }
Title.displayName = 'Title'

const Subtitle = styled.h2(typography, space, color, layout, flexbox)
Subtitle.defaultProps = { ...defaults, fontSize: 'xl' }
Subtitle.displayName = 'Subtitle'

const SectionTitle = styled.h3(typography, space, color, layout, flexbox)
SectionTitle.defaultProps = { ...defaults, fontSize: 'l' }
SectionTitle.displayName = 'SectionTitle'

const Note = styled.span(typography, space, color, layout, flexbox)
Note.defaultProps = {
  fontFamily: 'primary',
  color: 'primaryTextLight',
  fontSize: 'm',
}
Note.displayName = 'Note'

const Link = styled(ReactRouterLink)`
  text-decoration: none;
  color: ${props => props.theme.colors.link};
  font-weight: 600;
  ${space} ${color} ${typography} ${layout} ${flexbox};
`
Link.displayName = 'Link'

export { Title, Subtitle, SectionTitle, Note, Link, P }
export default Text
