import React, { useState, useContext } from 'react'
import { makeStyles, Button } from '@material-ui/core'
import { faArrowsV } from '@fortawesome/pro-light-svg-icons'
import { $, Div, Icon, Text } from 'atoms'
import RetailCommissionSlider from 'FlexiForm/fields/RetailCommissionSlider'
import { colors } from 'components/theme'
import WholesalerSubmissionContext from 'components/contexts/wholesalerSubmission'
import { QUOTE_STATUSES } from 'panels/constants'

const useStyles = makeStyles({
  sliderButton: {
    marginLeft: '5px',
    color: colors.link,
  },
  sliderButtonLabel: {
    textTransform: 'none',
  },
  textRow: {
    display: 'flex',
    alignItems: 'center',
  },
  percentage: {
    margin: '0 5px',
  },
})

const RetailCommissionRenderer = ({
  quote,
  children: quotedLine,
  criterion: { isAdjustable },
}) => {
  const classes = useStyles()
  const [openForm, setOpenForm] = useState(false)
  const openModal = () => {
    setOpenForm(true)
  }

  const closeForm = () => {
    setOpenForm(false)
  }
  const isOutdated = quote.status === QUOTE_STATUSES.outdated
  const quotationId = quote.id
  const retailCommissionPercentage = quotedLine.retailCommissionPercentage * 100
  const { hasWholesaler, userIsWholesaler } = useContext(
    WholesalerSubmissionContext
  )
  const isRetailer = hasWholesaler && !userIsWholesaler
  return (
    <Text className={classes.textRow}>
      <$ fallback="—">{quotedLine.retailCommission}</$>
      {quotedLine.retailCommissionPercentage !== null && (
        <Div className={classes.percentage}>
          ({retailCommissionPercentage.toFixed(2)}%)
        </Div>
      )}

      {isAdjustable && !isOutdated && isRetailer && (
        <>
          <Button
            classes={{
              label: classes.sliderButtonLabel,
              root: classes.sliderButton,
            }}
            startIcon={<Icon icon={faArrowsV} />}
            onClick={openModal}>
            Adjust
          </Button>
          <RetailCommissionSlider
            openForm={openForm}
            closeForm={closeForm}
            quotationId={quotationId}
            quotedLine={quotedLine}
          />
        </>
      )}
    </Text>
  )
}

export default RetailCommissionRenderer
