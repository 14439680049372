import { useContext } from 'react'
import SubmissionConfig from './SubmissionConfig'
import QuotationConfig from './QuotationConfig'

import MLConfig from './ML'
import CyberConfig from './Cyber'
import TerrorismConfig from './Terrorism'
import MultilineCyberConfig from './MultilineCyber'
import BOPConfig from './BOP'
import PCGConfig from './PCG'
import WholesalerSubmissionContext from 'components/contexts/wholesalerSubmission'
import {
  useDeclinePanelQuoteMutation,
  usePanelsBindQuoteMutation,
  usePanelsIssuePolicyMutation,
  usePanelsRequestToBindMutation,
} from 'graphql/api'
import { getConfig } from 'pages/shared/panels/config'

const getDefaultConfig = lineOfBusiness => ({
  queries: {},
  components: {},
  mutations: {
    declineQuote: {
      mutation: useDeclinePanelQuoteMutation,
    },
    issuePolicy: {
      mutation: usePanelsIssuePolicyMutation,
      getError: data => data.panelsIssuePolicy.errors,
    },
    bindQuote: {
      mutation: usePanelsBindQuoteMutation,
      getError: data => data.panelsBindQuote.errors,
    },
    requestToBind: {
      mutation: usePanelsRequestToBindMutation,
      getVariables: ({ submissionId, quotationId, coverageStartDate }) => ({
        variables: {
          input: {
            submissionId,
            quotationId,
            coverageStartDate,
          },
        },
      }),
      getError: data => data.panelsRequestToBind.error,
    },
  },
  getters: {
    getSubmission: SubmissionConfig.model(lineOfBusiness),
    getQuotationById: (quoteId, submission) => {
      return submission.quotations.find(quote => quote.id === quoteId)
    },
    getProductOptions: QuotationConfig.productOptions(lineOfBusiness),
  },
})

const config = lineOfBusiness => {
  const defaultConfig = getDefaultConfig(lineOfBusiness)
  switch (lineOfBusiness) {
    case 'Terrorism':
      return getConfig(defaultConfig, TerrorismConfig.Config)
    case 'Management Liability':
      return getConfig(defaultConfig, MLConfig.Config)
    case 'Cyber':
      return getConfig(defaultConfig, CyberConfig.Config)
    case 'Multiline Cyber':
      return getConfig(defaultConfig, MultilineCyberConfig.Config)
    case 'Business_Owner':
      return getConfig(defaultConfig, BOPConfig.Config)
    case 'Private_Clients':
      return getConfig(defaultConfig, PCGConfig.Config)
    default:
      return defaultConfig
  }
}

const convoDetails = userType => {
  switch (userType) {
    case 'broker': {
      const { hasWholesaler, userIsWholesaler } = useContext(
        WholesalerSubmissionContext
      )
      return (user, submission) => ({
        isOwner:
          user.id === submission.owner.id ||
          (hasWholesaler && userIsWholesaler),
        subject: `RE: Submission for ${submission.name}`,
        recipient: 'Underwriter',
      })
    }
    case 'reinsurer':
      return (user, _, participation) => ({
        isOwner: user.id === participation.user.id,
        subject: '',
        recipient: 'Broker',
      })
    default:
      return (user, _, participation) => ({
        isOwner: user.id === participation.user.id,
        subject: '',
        recipient: 'Unknown',
      })
  }
}

export default { config, convoDetails }
