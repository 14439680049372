import Applicant from './Applicant'
import QuoteContent from './QuoteContent'
import QuoteSummary from './QuoteSummary'
import {
  useMlQuotationQuery,
  useCyberReinstateEmailQuotesMutation,
} from 'graphql/api'
import { queryDefaults } from '../utils'

const queries = {
  participationQuotations: {
    ...queryDefaults.participationQuotations,
    query: useMlQuotationQuery,
    getParticipation: data => data.viewer.user.participation,
  },
}

const mutations = {
  emailQuote: {
    mutation: useCyberReinstateEmailQuotesMutation,
  },
}

const getters = {
  refetchQueries: ['MLQuotation'],
}

const components = {
  ApplicantContent: Applicant,
  QuoteContent: QuoteContent,
  QuoteSummary: QuoteSummary,
  BindContent: QuoteSummary,
}

const config = {
  queries,
  mutations,
  components,
  getters,
}

export default config
