import React from 'react'

import { Flex, Label } from 'atoms'

const TwoColumnTitleRenderer = ({ criterion }) => (
  <Flex>
    <Flex flexBasis="50%">
      <Label>{criterion.titles[0]}</Label>
    </Flex>
    <Flex flexBasis="50%">
      <Label>{criterion.titles[1]}</Label>
    </Flex>
  </Flex>
)

export default TwoColumnTitleRenderer
