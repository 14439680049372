import React from 'react'
import styled from 'styled-components'
import { color, typography } from 'styled-system'
import { transparentize } from 'polished'
import get from 'lodash/get'

import { Flex, Label, Error, Text } from 'atoms'

export const Input = styled.input`
  min-width: 50%;
  width: 100%;
  flex-grow: 1;
  padding: 7px 0;
  box-sizing: border-box;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 300;
  font-size: 18px;
  color: ${props => props.theme.colors.primaryText};
  outline: none;
  border-width: 0 0 ${props => (props.disabled ? 0 : '1px')} 0;
  border-style: solid;
  border-color: ${({ touched, error, theme }) =>
    touched && error
      ? theme.colors.error
      : transparentize(0.7, theme.colors.primaryText)};
  background: ${props =>
    props.bg
      ? `${get(props.theme.colors, props.bg)} !important`
      : props.highlight
      ? `${props.theme.colors.lightGreen} !important`
      : 'transparent'};

  &::placeholder {
    color: ${props => props.theme.colors.primaryTextInactive};
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.primaryText};
  }

  ${color}
  ${typography}
`

export const WhiteInput = styled(Input)`
  border-width: ${props => (props.disabled ? 0 : '1px')};
  border-color: ${({ touched, error, theme }) =>
    touched && error
      ? theme.colors.error
      : transparentize(0.7, theme.colors.link)};
  background: ${({ theme }) => theme.colors.white} !important;
  border-radius: ${({ theme }) => theme.radii[1]};
  padding: 0.5rem;
  text-align: center;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => transparentize(0.1, theme.colors.link)};
  }

  ${color}
  ${typography}
`

const Symbol = styled(Text)`
  flex-grow: 0;
  color: ${props => props.theme.colors.primaryTextInactive};
`

Input.displayName = 'StyledInput'

const TextInput = React.forwardRef(
  (
    {
      input,
      meta,
      label,
      note,
      required,
      disabled,
      autoFocus,
      symbol,
      box,
      bg,
      textAlign,
      ...rest
    },
    ref
  ) => {
    return (
      <Flex flexDirection="column" {...rest}>
        <Flex>
          {label && (
            <Label htmlFor={input.name} note={note} required={required}>
              {label}
            </Label>
          )}
        </Flex>
        <Flex flexDirection="row" alignItems="baseline">
          {box ? (
            <WhiteInput
              ref={ref}
              type="text"
              id={input.name}
              disabled={disabled}
              touched={meta.touched}
              error={meta.error || meta.submitError}
              textAlign={textAlign}
              {...input}
            />
          ) : (
            <Input
              ref={ref}
              type="text"
              id={input.name}
              disabled={disabled}
              touched={meta.touched}
              error={meta.error || meta.submitError}
              bg={bg}
              autoFocus={autoFocus}
              {...rest}
              {...input}
            />
          )}
          {symbol && <Symbol>{symbol}</Symbol>}
        </Flex>
        {(meta.error || meta.submitError) && meta.touched && (
          <Error>{meta.error || meta.submitError}</Error>
        )}
      </Flex>
    )
  }
)

TextInput.displayName = 'TextInput'

export default TextInput
