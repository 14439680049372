import { mapJson, mapFields } from 'util/jsonpath'

export const QuotedLinesMapping = {
  ...mapFields([
    '__typename',
    'amountCommission',
    'annualPremium',
    'clientCost',
    'retailCommission',
    'retailCommissionPercentage',
    'totalCommissionPercentage',
    'wholesalerCommission',
    'wholesalerCommissionPercentage',
    'policyNumber',
  ]),
}

export const EndorsementMapping = {
  ...mapFields(['name', 'limit']),
}

export const AdditionalFeaturesMapping = {
  ...mapFields(['description', 'title']),
}

export const CoverageMapping = {
  ...mapFields(['name', 'type', 'limit', 'deductible', 'waitingPeriod']),
}

export const QuotationMapping = {
  ...mapFields([
    '__typename',
    'admitted',
    'carrierName',
    'coverageStartDate',
    'declinationReason',
    'deductible',
    'documents',
    'fee',
    'id',
    'indication',
    'limit',
    'participationId',
    'policyNumber',
    'premium',
    'requestedAt',
    'requestedDeductible',
    'requestedLimit',
    'selected',
    'signingUrl',
    'status',
    'statusMessage',
    'submissionId',
    'submissionOwner',
    'surplusLinesTax',
    'totalCost',
    'underwriterEmail',
    'underwriterFirstName',
    'underwriterLastName',
  ]),
  ambestRating: 'user.company.ambestRating.rating',
  carrier: ['user.company.name', 'externalCarrier.name'],
  externalCarrier: 'externalCarrier.name',
  isApi: 'collaborator.api',
  isManual: quotation => quotation.type === 'Cyber::ManualQuotation',
  isCarrier: quotation => quotation.type === 'Cyber::CarrierQuotation',
  logoUrl: ['user.company.logoUrl', 'externalCarrier.logoUrl'],
  isExtracted: quotation =>
    quotation.extractedAt !== null && quotation.reviewedAt === null,
  isOutdated: quotation => quotation.status === 'outdated',
  additionalFeatures: quotation => {
    if (quotation.additionalFeatures) {
      return quotation.additionalFeatures.map(af =>
        mapJson(af || {}, AdditionalFeaturesMapping)
      )
    }
    return []
  },
  endorsements: quotation => {
    if (quotation.endorsements) {
      return quotation.endorsements.map(endorsement =>
        mapJson(endorsement || {}, EndorsementMapping)
      )
    }
    return []
  },
  coverages: quotation => {
    if (quotation.coverages) {
      return quotation.coverages.map(coverage =>
        mapJson(coverage || {}, CoverageMapping)
      )
    }
    return []
  },
  quotedLines: quotation => {
    if (quotation.quotedLines) {
      return (
        quotation.quotedLines.nodes
          .filter(line => line.__typename === 'CyberQuotedLineType')
          ?.map(line => mapJson(line || {}, QuotedLinesMapping)) || []
      )
    }
  },
}
