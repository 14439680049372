import React from 'react'

import { Flex, $, Text } from 'atoms'

const Fallback = () => (
  <Flex>
    <Flex flexBasis="50%">—</Flex> <Flex flexBasis="50%">—</Flex>
  </Flex>
)

const LimitAndDeductibleRenderer = ({ children = {}, quotation }) => {
  // FIXME: This is a hack because we've not got the final mapping of insuring
  // agreements from NFP yet. - HJM 2020-12-01

  const fallback =
    quotation?.company?.name === 'CFC' ? (
      <Flex>
        <Text>See Quote Document</Text>
      </Flex>
    ) : (
      <Fallback />
    )
  if (!children) return fallback
  const { limit, deductible, waitingPeriod } = children

  let period

  if (parseInt(waitingPeriod).toString() === waitingPeriod) {
    period = waitingPeriod + ' hours'
  } else {
    period = waitingPeriod
  }

  return (
    <Flex>
      <Flex flexBasis="50%">
        <$ fallback="—">{limit}</$>
      </Flex>
      <Flex flexBasis="50%">
        <$ fallback="—">{deductible}</$>
        {waitingPeriod && <Flex ml="3px">{` / ${period}`}</Flex>}
      </Flex>
    </Flex>
  )
}

export default LimitAndDeductibleRenderer
