import { useState, useEffect } from 'react'

import currencies from 'data/currencies'
import { currencyFormatter, currencyNormalizer } from 'util/fieldNormalizers'

const useCurrencyValue = (initial = undefined, currencyCode = 'USD') => {
  const currency = currencies[currencyCode]
  const [value, setValue] = useState(initial)
  const [formatted, setFormatted] = useState(currencyFormatter(value, currency))
  const [normalized, setNormalized] = useState(currencyNormalizer(value))

  useEffect(() => {
    const normalized = currencyNormalizer(value)
    setNormalized(normalized)
    setFormatted(currencyFormatter(normalized, currency))
  }, [value])

  return { normalized, formatted, setValue }
}

export default useCurrencyValue
