import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export default function notificationActionedMutation() {
  return graphql(
    gql`
      mutation notificationActioned($input: NotificationActionedInput!) {
        notificationActioned(input: $input) {
          notificationEvent {
            id
            status
            event
            relatesTo {
              id
            }
          }
        }
      }
    `,
    {
      props: ({ mutate }) => ({
        notificationActioned: input => mutate({ variables: input }),
      }),
    }
  )
}
