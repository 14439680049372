const STATUS_TYPE = {
  UPLOAD: 'upload',
  REQUESTED: 'requested',
  ACCEPTED: 'accepted',
  CLIENT_APPROVED: 'client_approved',
  REJECTED: 'rejected',
  DECLINED: 'declined',
  DECLINED_OUTDATED: 'declined_outdated',
  DECLINATION_CONFIRMED: 'declination_confirmed',
  PROPOSED: 'proposed',
  OUTDATED: 'outdated',
  DRAFT: 'draft',
  QUOTED: 'quoted',
  AWAITING: 'awaiting_response',
  AWAITING_APPROVAL: 'awaiting_approval',
  OPEN_FOR_QUOTES: 'open_for_quotes',
  PROPOSAL_ACCEPTED: 'proposal_accepted',
  PROPOSAL_DECLINED: 'proposal_declined',
  BINDER_REQUESTED: 'binder_requested',
  BOUND: 'bound',
  POLICY_REQUESTED: 'insurance_policy_requested',
  ISSUED: 'issued',
  CERTIFICATE: 'certificate_of_reinsurance_issued',
  CERTIFICATE_ACCEPTED: 'certificate_of_reinsurance_accepted',
}

export const PendingStatuses = [STATUS_TYPE.REQUESTED, STATUS_TYPE.RECEIVED]

export const CompletedStatuses = [
  STATUS_TYPE.CERTIFICATE,
  STATUS_TYPE.CERTIFICATE_ACCEPTED,
]

export const AcceptedStatuses = [
  STATUS_TYPE.PROPOSAL_ACCEPTED,
  STATUS_TYPE.ACCEPTED,
]

export const BoundStatuses = [
  STATUS_TYPE.BOUND,
  STATUS_TYPE.BINDER_REQUESTED,
  STATUS_TYPE.POLICY_REQUESTED,
]

export const CommittedStatuses = [
  STATUS_TYPE.ACCEPTED,
  STATUS_TYPE.POLICY_REQUESTED,
  STATUS_TYPE.CERTIFICATE,
  STATUS_TYPE.CERTIFICATE_ACCEPTED,
  STATUS_TYPE.DECLINED,
]

export const AcceptableStatuses = [
  STATUS_TYPE.AWAITING,
  STATUS_TYPE.CLIENT_APPROVED,
]

export default STATUS_TYPE
