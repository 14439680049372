import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { RetryLink } from 'apollo-link-retry'
import QueueLink from 'apollo-link-queue'
import ApolloSentryLink from 'apollo-sentry-link'
import * as Sentry from '@sentry/react'
import { Auth } from 'aws-amplify'
import qs from 'query-string'

import introspectionQueryResultData from './fragmentTypes.json'
import { stripTypenames } from 'util/graphql'

const defaultApiUrl = `${window.config.API_URL}/graphql`
const canOverrideUrl = process.env.REACT_APP_ENV !== 'production'

const offlineLink = new QueueLink()

const printDebugInfoFor = ({ message, path, extensions, variables }) => {
  if (process.env.REACT_APP_ENABLE_GRAPHQL_DEBUG === 'false') return
  /* eslint-disable no-console */
  console.group()
  console.log(message)
  console.table(path)
  console.table(extensions)
  if (variables.length > 0) {
    console.table(variables)
  }
  console.groupEnd()
  /* eslint-enable no-console */
}

const reportGraphqlErrorToSentry = ({
  message,
  locations,
  path,
  extensions,
  variables,
}) => {
  if (process.env.NODE_ENV === 'development') {
    printDebugInfoFor({ message, path, extensions, variables })
  } else {
    Sentry.withScope(scope => {
      scope.setExtra('graphql_path', path)
      scope.setExtra('graphql_extensions', extensions)
      scope.setExtra('graphql_locations', locations)
      scope.setExtra('graphql_variables', variables)
      Sentry.captureMessage(message)
    })
  }
}

const handleErrors = ({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (message && message === 'User not authenticated') {
        Auth.signOut()
      }
      if (extensions && extensions.code === 'ACCOUNT_DISABLED') {
        // do nothing
      } else {
        reportGraphqlErrorToSentry({
          message,
          locations,
          path,
          extensions,
          variables: operation.variables,
        })
      }
    })
  }
  if (networkError) {
    if (process.env.NODE_ENV === 'development') {
      console.error(networkError)
    } else {
      Sentry.captureException(networkError)
    }
  }
}

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = stripTypenames(operation.variables, '__typename')
    return forward ? forward(operation) : null
  }
})

const authLink = setContext((_, { headers }) => {
  return Auth.currentSession()
    .then(({ accessToken }) => {
      return accessToken.jwtToken
    })
    .catch(err => {
      const invitationToken =
        localStorage.getItem('invitationToken') ||
        qs.parseUrl(window.location.href).query.invitationToken

      if (invitationToken === null) {
        Sentry.withScope(scope => {
          scope.setExtra(
            'comment',
            'Refreshing Cognito token failed and no invitationToken was set'
          )
          Sentry.captureException(err)
          Auth.signOut()
        })
      } else {
        return invitationToken
      }
    })
    .then(token => {
      if (!token) {
        return { headers }
      } else {
        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      }
    })
})

const createClient = apiUrl =>
  new ApolloClient({
    link: ApolloLink.from(
      [
        process.env.NODE_ENV !== 'development' && new RetryLink(),
        offlineLink,
        authLink,
        removeTypenameMiddleware,
        onError(handleErrors),
        ApolloSentryLink,
        createUploadLink({ uri: apiUrl }),
      ].filter(Boolean)
    ),
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData,
      }),
    }),
  })

const GraphqlProvider = ({ token, children }) => {
  const relayApiUrl = localStorage.getItem('relay.api.url')
  if (canOverrideUrl && relayApiUrl) {
    /* eslint-disable no-console */
    console.log(`Using ${relayApiUrl} instead of ${defaultApiUrl}`)
    /* eslint-enable no-console */
  }

  useEffect(() => {
    if (token) {
      localStorage.setItem('invitationToken', token)
    }
  }, [token])

  useEffect(() => {
    const goOffline = () => offlineLink.close()
    const goOnline = () => offlineLink.open()

    window.addEventListener('offline', goOffline)
    window.addEventListener('online', goOnline)

    return () => {
      window.removeEventListener('offline', goOffline)
      window.removeEventListener('online', goOnline)
    }
  })

  const client = createClient(
    canOverrideUrl && relayApiUrl ? relayApiUrl : defaultApiUrl
  )

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

GraphqlProvider.propTypes = {
  token: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default GraphqlProvider
