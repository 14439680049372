import startCase from 'lodash/startCase'
import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'
import formCustomisation from 'cyber/schemas/applicantSchemas'
import { lineKeys, mapApplicantAddressFields } from '../utils'
import SubmissionModel from 'models/panels/multiline'

const schemas = (_, schemas, editSubmission) => {
  Object.entries(schemas.schema.properties).forEach(([key, property]) => {
    const icons = {
      applicant: 'file-alt',
      cyber: 'fingerprint',
      review: 'paper-plane',
    }
    if (editSubmission) {
      property.tab = startCase(key)
      property.icon = icons[property.tab]
    } else {
      property.icon = icons[key]
      property.tab = startCase(key)
    }
    if (property.title.length === 0) {
      property.title = property.tab
    }
  })
  return schemas
}

const formatData = formData => {
  const modifiedFormData = { ...formData }
  modifiedFormData.applicant.company_name =
    modifiedFormData.applicant.company_name || 'Draft Cyber Application'
  modifiedFormData.applicant.address = mapApplicantAddressFields(
    modifiedFormData.applicant.address
  )
  return modifiedFormData
}

const afterChange = (formData, updateFormData) => newFormData => {
  updateFormData(newFormData)
}

const model = participation => {
  const submissionModel = new SubmissionModel()
  return submissionModel.build(participation.submission)
}

const deepRemoveNulls = obj =>
  JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? undefined : v)))

const submissionData = ({ products, panel_info, ...submission }) => {
  const modifiedSubmission = { ...submission }
  modifiedSubmission.panel_info = JSON.parse(panel_info)
  products?.forEach(product => {
    const key = lineKeys[product.__typename]
    modifiedSubmission[key] = product
  })
  return deepRemoveNulls(modifiedSubmission)
}

const afterSubmit = async (
  isDraft,
  { sendToMarkets, submissionId, documents }
) => {
  if (!isDraft) {
    const result = await sendToMarkets({
      variables: {
        input: {
          submissionId,
          documents: documents || [],
        },
      },
    })

    if (result.data.sendToMarkets.errors) {
      Sentry.captureMessage('Error sending panel submission to markets', {
        extra: { errors: result.data.sendToMarkets.errors },
      })
      toast.error('An error occurred when finalising this application')
      return
    }

    toast.success('Application sent')
  }
}

const flexiForm = (formData, updateFormData) => {
  return {
    enableDraft: formData.status === 'draft',
    submitLabel: null,
    multiline: true,
    onChange: afterChange(formData, updateFormData),
    ...formCustomisation,
  }
}

const panel = { p: '0px' }

export default {
  schemas,
  formatData,
  submissionData,
  afterSubmit,
  model,
  flexiForm,
  panel,
}
