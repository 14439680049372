import React from 'react'
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from '@fortawesome/pro-solid-svg-icons'

import { Row, Icon } from 'atoms'
import { QuotationHeader, QuoteActions } from 'pages/shared/panels'
import { useHistory } from 'react-router-dom'

const QuotationsNav = ({ basePath, index, quotations, isOutdated }) => {
  const history = useHistory()
  const current = quotations[index]
  const goToQuoteAtIndex = quoteIndex => {
    const quoteId = quotations[quoteIndex].id
    history.push(`${basePath}/quotes/${quoteId}`)
  }

  const isPrev = index > 0
  const isNext = index < quotations.length - 1
  const prevQuote = () => goToQuoteAtIndex(index - 1)
  const nextQuote = () => goToQuoteAtIndex(index + 1)

  return (
    <Row alignItems="center">
      <Icon
        mr={2}
        color={isPrev ? 'primaryText' : 'inactive'}
        fontSize="xxl"
        icon={faArrowCircleLeft}
        alt="Previous"
        onClick={isPrev ? prevQuote : null}
      />
      <QuotationHeader
        title={`Quote ${index + 1} of ${quotations.length}`}
        quotation={current}
        isOutdated={isOutdated}
      />
      <QuoteActions quotation={current} />
      <Icon
        ml={2}
        color={isNext ? 'primaryText' : 'inactive'}
        fontSize="xxl"
        icon={faArrowCircleRight}
        alt="Next"
        onClick={isNext ? nextQuote : null}
      />
    </Row>
  )
}

export default QuotationsNav
