import React from 'react'
import { Tooltip, withStyles } from '@material-ui/core'
import { Tooltip as AntTooltip, Popover } from 'antd'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'

import { Div, Icon } from 'atoms'
import { colors } from 'components/theme'

const LightTooltip = withStyles({
  arrow: {
    color: colors.white,
  },
  tooltip: {
    backgroundColor: colors.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    fontSize: '1rem',
  },
})(Tooltip)

export const AntdTooltip = ({
  content = null,
  title,
  icon = faQuestionCircle,
  maxContentWidth,
  color,
  noIcon = false,
  children = null,
  ...rest
}) => {
  return (
    <>
      {content ? (
        <Popover
          content={<Div width={maxContentWidth}>{content}</Div>}
          title={title}
          {...rest}>
          <Icon color={color || colors.primary} icon={icon} />
        </Popover>
      ) : noIcon && children ? (
        <AntTooltip
          title={title}
          overlayClassName="antd-tooltip-default"
          {...rest}>
          {children}
        </AntTooltip>
      ) : (
        <AntTooltip title={title} {...rest}>
          {!noIcon && <Icon color={color || colors.primary} icon={icon} />}
        </AntTooltip>
      )}
    </>
  )
}

export default LightTooltip
