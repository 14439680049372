import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { Alert } from 'antd'

import { useCurrentUser } from 'hooks'
import { EnhancedModal, Button, Div, Subtitle } from 'atoms'
import NewUserForm from './NewUserForm'

const ReassignModal = ({ changeOwner, onClose }) => {
  return (
    <Form onSubmit={changeOwner}>
      {({ handleSubmit }) => (
        <EnhancedModal onClose={onClose}>
          <Div p={2}>
            <Subtitle mt={0}>Create My Profile</Subtitle>
            <NewUserForm onSubmit={handleSubmit} onClose={onClose} />
          </Div>
        </EnhancedModal>
      )}
    </Form>
  )
}

const GeneralInboxAlert = ({ changeOwner = () => {} }) => {
  const [modalOpened, setModalOpened] = useState(false)
  const user = useCurrentUser()
  const onClick = () => setModalOpened(true)
  const onClose = () => setModalOpened(false)

  return (
    <>
      {modalOpened && (
        <ReassignModal onClose={onClose} changeOwner={changeOwner} />
      )}
      <Alert
        banner
        message={`Is "${user.email}" your email address?\rClick here to create an account and reassign it to yourself.`}
        action={
          <Button secondary light onClick={onClick}>
            Reassign to me
          </Button>
        }
      />
    </>
  )
}

export default GeneralInboxAlert
