import React from 'react'

import { Flex, $ } from 'atoms'

const Fallback = () => (
  <Flex>
    <Flex flexBasis="50%">—</Flex> <Flex flexBasis="50%">—</Flex>
  </Flex>
)


const LimitAndCoinsuranceRenderer = ({ children = {} }) => {

  const fallback = <Fallback />
  if (!children) return fallback
  const { limit, coinsurance } = children

  return (
    <Flex flexBasis="100%">
      <$ fallback="—">{limit}</$>
      {coinsurance && <Flex ml="3px">{` / ${coinsurance} Co-insurance`}</Flex>}
    </Flex>
  )
}

export default LimitAndCoinsuranceRenderer
