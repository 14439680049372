import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import set from 'lodash/set'
import merge from 'lodash/merge'

import { NotificationEventsDocument } from 'graphql/api'

export default function notificationViewedMutation() {
  return graphql(
    gql`
      mutation notificationViewed($input: NotificationViewedInput!) {
        notificationViewed(input: $input) {
          notificationEvents {
            id
            status
            user {
              id
              notificationEvents {
                unreadCount
              }
            }
          }
          errors
        }
      }
    `,
    {
      props: ({ mutate }) => ({
        notificationViewed: input =>
          mutate({
            variables: input,
            update: (cache, { data: { notificationViewed } }) => {
              const existing = cache.readQuery({
                query: NotificationEventsDocument,
              })
              if (notificationViewed.notificationEvents.length > 0) {
                cache.writeQuery({
                  query: NotificationEventsDocument,
                  data: merge(
                    existing,
                    set(
                      {},
                      'viewer.user.notificationEvents.unreadCount',
                      notificationViewed.notificationEvents[0].user
                        .notificationEvents.unreadCount
                    )
                  ),
                })
              }
            },
          }),
      }),
    }
  )
}
