/**
 * useEffect Official Documentation: https://github.com/reactjs/reactjs.org/blob/master/content/docs/hooks-reference.md#useeffect-useeffect
 */

import { useRef } from 'react'

import { isPresent } from 'util/langUtil'
import { deepDiff } from 'util/objectUtil'

const useEffectWithDeepEqualsComparison = (
  callback,
  nextComplexProps = {},
  { keysToPick = [] } = {}
) => {
  const prevPropsRef = useRef({ ...nextComplexProps })
  const prevProps = { ...prevPropsRef.current }

  const diff = deepDiff(prevProps, nextComplexProps, { keysToPick })

  if (isPresent(diff)) {
    prevPropsRef.current = nextComplexProps
    callback({ diff, prevProps })
  }
}

export default useEffectWithDeepEqualsComparison
