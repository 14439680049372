import React from 'react'
import * as Sentry from '@sentry/react'
import { parseISO, format, addMinutes } from 'date-fns'

const DATE_FORMAT = 'MMM d, yyyy'
const DATE_FORMAT_NO_YEAR = 'MMM d'
const DATETIME_FORMAT = 'MMM d, yyyy h:mm:ss aaaa'

const formatDate = (date, isDateTime, isMonthAndDayOnly, isEST = false) => {
  let formattedDate = ''
  if (!date) {
    return '—'
  }

  // dates in backend are saved in EST, but date-fns assumes they are in UTC
  // so they are always rolled back 5 hours when formated because of the assumed timezone delta
  const dateObj = isEST ? utcToCurrentTimeZone(date) : parseISO(date)

  try {
    if (isDateTime) {
      formattedDate = format(dateObj, DATETIME_FORMAT)
    } else if (isMonthAndDayOnly) {
      formattedDate = format(dateObj, DATE_FORMAT_NO_YEAR)
    } else {
      formattedDate = format(dateObj, DATE_FORMAT)
    }
    return formattedDate === 'Invalid date' ? '—' : formattedDate
  } catch (error) {
    Sentry.captureException(error)
    return null
  }
}

const utcToCurrentTimeZone = date => {
  let new_date = new Date(date)
  return addMinutes(new_date, new_date.getTimezoneOffset())
}

const DateString = ({
  date,
  isDateTime = false,
  isMonthAndDayOnly = false,
  isEST = false,
}) => <>{formatDate(date, isDateTime, isMonthAndDayOnly, isEST)}</>

export default DateString
