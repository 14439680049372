import * as yup from 'yup'

const MATCH_DOMAIN_NAME =
  /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/
const MATCH_US_ZIP = /^\d{5}(-\d{4})?$/
const MATCH_NAICS_CODE = /A\d{6}z/

const isDomainName = function () {
  return this.matches(MATCH_DOMAIN_NAME, {
    name: 'domainName',
    message: { key: 'invalid_domain_name' },
    excludeEmptyString: false,
  })
}

const isUSZipCode = function () {
  return this.matches(MATCH_US_ZIP, {
    name: 'usZipCode',
    message: { key: 'invalid_zip_code' },
    excludeEmptyString: false,
  })
}

const isNaicsCode = function () {
  return this.matches(MATCH_NAICS_CODE, {
    name: 'naicsCode',
    message: { key: 'invalid_naics_code' },
    excludeEmptyString: false,
  })
}

export const addStringMethods = yup => {
  yup.addMethod(yup.string, 'domainName', isDomainName)
  yup.addMethod(yup.string, 'usZipCode', isUSZipCode)
  yup.addMethod(yup.string, 'naicsCode', isNaicsCode)
}

export default yup
