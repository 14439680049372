import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Flex, Label, Error } from 'atoms'

const Button = styled.button`
  min-width: 60px;
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-family: ${props => props.theme.fonts.primary};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  ${({ selected, theme }) =>
    selected
      ? `
    background-color: ${theme.colors.primaryText};
    color: ${theme.colors.white};
`
      : `
    background-color: ${theme.colors.white};
    color: ${theme.colors.primaryText};
`}
  ${space};
`

const ChoiceButtons = ({
  input,
  meta,
  label,
  options,
  required,
  note,
  ...style
}) => {
  return (
    <Flex flexDirection="column" {...style}>
      <Label note={note} required={required}>
        {label}
      </Label>
      <Flex pt="7px">
        {options.map(({ label, value }) => (
          <Button
            px={1}
            key={label}
            selected={input.value === value}
            mr={1}
            onClick={() => input.onChange(value)}>
            {label}
          </Button>
        ))}
      </Flex>
      {meta.touched && meta.error && (
        <Flex mt={0}>
          <Error>{meta.error}</Error>
        </Flex>
      )}
    </Flex>
  )
}

export default ChoiceButtons
