import React from 'react'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import arrayMutators from 'final-form-arrays'
import pick from 'lodash/pick'

import QuestionForm from './QuestionForm'
import { EnhancedModal, Subtitle, Div } from 'atoms'
import { useEditQuestionMutation } from 'graphql/api'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'

const EditQuestionModal = ({
  scope = QUESTION,
  sections,
  questions,
  onClose,
  onCompleted,
}) => {
  const { questionId } = useParams()
  const translator = qandaTranslator(scope)
  const [editQuestion] = useEditQuestionMutation({
    refetchQueries: translator('Question') === 'Subjectivity' && [
      'Subjectivities',
    ],
  })
  const handleSubmit = values => {
    editQuestion({
      variables: {
        input: {
          questionId,
          attributes: {
            ...values.attributes,
            documents:
              values.attributes.documents &&
              values.attributes.documents.map(doc =>
                pick(doc, [
                  '_destroy',
                  'attachment',
                  'category',
                  'id',
                  'source',
                  'version',
                  'tags,',
                ])
              ),
          },
        },
      },
    }).then(({ data }) => {
      if (data.editQuestion.errors) {
        toast.error(data.editQuestion.errors)
        return data.editQuestion.errors
      } else {
        onCompleted()
      }
    })
  }
  const question = questions.find(question => question.id === questionId)
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        attributes: {
          text: question.text,
          deadline: question.deadline,
          section: question.section,
          documents: question.documents,
        },
      }}
      mutators={arrayMutators}>
      {({ handleSubmit }) => (
        <EnhancedModal>
          <Div p={2}>
            <Subtitle>
              Edit {translator('Question')} ({translator('Q')}
              {question.number})
            </Subtitle>
            <QuestionForm
              sections={sections}
              scope={scope}
              onClose={onClose}
              onSubmit={handleSubmit}
              documentCategory={translator('Question')}
            />
          </Div>
        </EnhancedModal>
      )}
    </Form>
  )
}

export default EditQuestionModal
