import React from 'react'
import styled from 'styled-components'

import { Div } from 'atoms'

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: transparent;
  border-radius: 0;
  border-width: 0 0;
  position: relative;
  z-index: 99;

  ${props =>
    props.right &&
    `
    & > * {
      position: relative;
      right: 0;
      left: auto !important;
    }
  `};
`
const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  top: 100%;
  position: absolute;
  top: 2px;
  left: 10px;
`

const Menu = React.forwardRef(
  ({ right, options, onClose, ItemComponent, ...rest }, ref) => (
    <Div {...rest}>
      <DropdownContainer ref={ref} right={right}>
        <MenuList>
          {options.map((option, index) => (
            <ItemComponent
              key={option.eventName || index}
              {...option}
              closeMenu={onClose}
            />
          ))}
        </MenuList>
      </DropdownContainer>
    </Div>
  )
)

Menu.displayName = 'Menu'

export default Menu
