import React from 'react'
import moment from 'moment-timezone'

const DATE_FORMAT = 'h:mm MMM DD, YYYY'

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 min',
    mm: '%d mins',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
})

const RelativeDate = ({ className, date }) => {
  if (date)
    return (
      <span className={className} title={moment(date).format(DATE_FORMAT)}>
        {moment(date).fromNow()}
      </span>
    )
  return <>-</>
}

export default RelativeDate
