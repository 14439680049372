import React, { Suspense, lazy } from 'react'

import TextArea from 'atoms/form/TextArea'
import { useFeature } from 'components/common/FeatureSwitch'
import { Loading } from 'atoms'

const RealRichText = lazy(() => import('./RichText'))

const RichText = props => {
  const enabled = useFeature('richtext')
  if (enabled) {
    return (
      <Suspense fallback={<Loading />}>
        <RealRichText {...props} />
      </Suspense>
    )
  }
  return <TextArea {...props} />
}

export default RichText
