import { faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import SubmissionModel from 'models/panels/managementLiability'
import { removeKeyFromUiOrder } from 'pages/shared/panels/config'
import { lineKeys } from '../utils'

const schemas = (submission, schemas, newSubmission = false) => {
  if (submission.status !== 'draft') {
    delete schemas.schema.properties.email_carriers
    delete schemas.uiSchema.email_carriers
    removeKeyFromUiOrder(schemas, 'email_carriers')
  }
  if (newSubmission) {
    schemas.schema.properties.management_liability.required = []
  }
  // json-schema has an issue with "should be equal to one of the allowed values" for dropdown
  // this fix removes the validation error
  // related discussion: https://github.com/Limenius/liform-react/issues/47
  schemas.schema.properties.panel_info.properties.nfp_branch.enum = [
    '',
    ...schemas.schema.properties.panel_info.properties.nfp_branch.enum,
  ]
  if (schemas.schema.properties.panel_info.properties.region_code.enum) {
    schemas.schema.properties.panel_info.properties.region_code.enum = [
      '',
      ...schemas.schema.properties.panel_info.properties.region_code.enum,
    ]
  }

  delete schemas.schema.properties.review
  removeKeyFromUiOrder(schemas, 'review')
  if (schemas.uiSchema.panel_info) {
    delete schemas.uiSchema.panel_info.nfp_branch['ui:widget']
  }
  return schemas
}

const formatData = (formData, isEdit) => {
  if (formData.panel_info.nfp_branch === '') {
    formData.panel_info.nfp_branch = undefined
  }
  if (formData.panel_info.region_code === '') {
    formData.panel_info.region_code = undefined
  }
  if (isEdit) {
    delete formData.participations
    delete formData.owner
    delete formData.panel
  } else {
    const mlQuotes = {
      type: 'ManagementLiability::Product',
      ...formData.management_liability,
      coverages_attributes: formData.management_liability.coverages,
    }
    formData.name = formData.applicant.company_name
    formData.applicant.address_attributes = formData.applicant.address
    delete formData.applicant.address
    delete mlQuotes.coverages
    formData.products_attributes = [mlQuotes]
    delete formData.management_liability
    delete formData.review
  }
}

const model = participation => {
  const submissionModel = new SubmissionModel()
  return submissionModel.build(participation.submission)
}

const submissionData = ({ products, panel_info, ...submission }) => {
  submission.panel_info = JSON.parse(panel_info)
  products.forEach(product => {
    const key = lineKeys[product.__typename]
    submission[key] = product
  })
  const carriers = {}
  submission.email_carriers = []
  submission.participations.nodes.map(participation => {
    const userId = participation.user?.id
    if (userId && !carriers[userId]) {
      submission.email_carriers.push({
        id: participation.user.id,
      })
      carriers[userId] = true
    }
  })
  return submission
}

const panel = { gradient: 5, certificate: true }

const flexiForm = () => ({
  submitLabel: 'Send Application',
  submitIcon: faAngleRight,
})

const renderNav = (submission, panelId) => {
  return submission.status !== 'draft'
    ? {
        path: `/panels/${panelId}/submissions/${submission.id}`,
        label: 'Return to Comparator',
      }
    : {
        path: `/submissions`,
        label: 'Return to Dashboard',
      }
}

export default {
  schemas,
  formatData,
  submissionData,
  model,
  panel,
  flexiForm,
  renderNav,
}
