import * as Sentry from '@sentry/react'
import { NotificationConfig } from 'pages/shared/panels'

const submissionPath = demo => (demo ? 'demo_submissions' : 'submissions')

const panelId = object => {
  const related = object.relatesTo
  const submission =
    related.questionable?.submission ||
    related.discussion?.discussable.submission ||
    related.participation?.submission ||
    related.submission
  return submission.panel?.id
}

const worksheetLink = (userType, object) => {
  if (userType === 'Reinsurer') return undefined

  switch (object.event) {
    case 'Worksheet.ApprovalRequested':
      return `/${submissionPath(object.relatesTo.demo)}/${
        object.relatesTo.id
      }/edit/summary`
    case 'Worksheet.Created':
      return `/${submissionPath(object.relatesTo.demo)}/${
        object.relatesTo.id
      }/edit`
    default:
      return `/${submissionPath(object.relatesTo.demo)}/${object.relatesTo.id}`
  }
}

const quoteLink = (userType, object) => {
  switch (userType) {
    case 'Cedent':
    case 'Broker':
      return `/${submissionPath(object.relatesTo.worksheet.demo)}/${
        object.relatesTo.worksheet.id
      }/quotes/${object.relatesTo.id}`
    case 'Reinsurer':
      return `/quotes/${object.relatesTo.id}`
    default:
      return undefined
  }
}

const broadcastLink = (userType, object) => {
  const broadcast = object.relatesTo
  switch (userType) {
    case 'Cedent':
    case 'Broker':
      return `/${submissionPath(broadcast.broadcastable.demo)}/${
        broadcast.broadcastable.id
      }/broadcast`
    case 'NFP::RepsAndWarranties::Broker':
      return `/submissions/${broadcast.broadcastable.id}/broadcasts`
    case 'Reinsurer':
      if (
        broadcast.broadcastable.__typename === 'NFPRepsAndWarrantiesSubmission'
      ) {
        return `/quotes/${broadcast.broadcastable.id}`
      }
      return `/quotes/${broadcast.broadcastable.quotes.nodes[0].id}?msgId=${broadcast.id}`
    default:
      return undefined
  }
}

const messageLink = (userType, object) => {
  if (!object.relatesTo.discussion) {
    return ''
  }
  const message = object.relatesTo
  const discussable = message.discussion.discussable
  const lineOfBusiness =
    discussable.submission?.panel?.lineOfBusiness || 'Multiple Lines'
  if (discussable.__typename.match(/Collaboration$/)) return object.link

  if (
    discussable.__typename === 'Participation' &&
    discussable.submission.__typename === 'CyberSubmission'
  ) {
    if (userType === 'Reinsurer') {
      return participationLink(userType, discussable)
    }
    if (discussable.quotations.nodes[0]) {
      return cyberQuotationLink(
        userType,
        {
          ...discussable.quotations.nodes[0],
          participation: discussable,
        },
        panelId(object)
      )
    } else {
      return cyberParticipationLink(userType, discussable, panelId(object))
    }
  }
  const links = NotificationConfig(userType, lineOfBusiness)
  switch (userType) {
    case 'Cedent':
    case 'Broker':
      return links.messageReceived(discussable, message, submissionPath)
    case 'Reinsurer':
      return links.messageReceived(discussable, message)
    case 'NFP::RepsAndWarranties::Broker':
      return `/submissions/${discussable.submission.id}/quotes/${discussable.id}?msgId=${message.id}`
    default:
      return undefined
  }
}

const revisionLink = (userType, object) => {
  switch (userType) {
    case 'Cedent':
    case 'Broker':
      return `/${submissionPath(object.relatesTo.quote.worksheet.demo)}/${
        object.relatesTo.quote.worksheet.id
      }/quotes/${object.relatesTo.quote.id}`
    case 'Reinsurer':
      return `/quotes/${object.relatesTo.quote.id}`
    default:
      return undefined
  }
}

const questionLink = (userType, object) => {
  const questionable = object.relatesTo.questionable
  switch (userType) {
    case 'Cedent':
    case 'Broker':
      if (questionable?.submission?.type === 'Cyber::Submission') {
        return `${panelSubmissionPath(panelId(object))}/${
          questionable.submission.id
        }/quotes/${questionable.quotations.nodes[0].id}/q-and-a`
      }
      return `/submissions/${questionable.worksheet.id}/quotes/${questionable.id}/q-and-a`
    case 'NFP::RepsAndWarranties::Broker':
      return `/submissions/${questionable.participation.submission.id}/quotes/${questionable.participation.id}/q-and-a`
    case 'Reinsurer':
      if (questionable?.submission?.type === 'Cyber::Submission') {
        return `/quotes/cyber/${questionable.id}`
      }
      return `/quotes/${
        questionable.participation?.submission?.id || questionable.id
      }/q-and-a`
    default:
      return undefined
  }
}

const subjectivityLink = (userType, object) => {
  const questionable = object.relatesTo.questionable
  switch (userType) {
    case 'Cedent':
    case 'Broker':
      if (questionable?.submission?.type === 'Cyber::Submission') {
        return `${panelSubmissionPath(panelId(object))}/${
          questionable.submission.id
        }/quotes/${questionable.quotations.nodes[0].id}/subjectivities`
      }
      return `/submissions/${questionable.worksheet.id}/quotes/${questionable.id}/subjectivities`
    case 'NFP::RepsAndWarranties::Broker':
      return `/submissions/${questionable.participation.submission.id}/quotes/${questionable.participation.id}/subjectivities`
    case 'Reinsurer':
      if (questionable?.submission?.type === 'Cyber::Submission') {
        return `/quotes/cyber/${questionable.id}`
      }
      return `/quotes/${
        questionable.participation?.submission?.id || questionable.id
      }/subjectivities`
    default:
      return undefined
  }
}

const nfpSubmissionLink = (userType, sub) => {
  if (userType === 'NFP::RepsAndWarranties::Broker') {
    return `/submissions/${sub.id}`
  }
  return `/quotes/${sub.id}`
}

const cyberQuotationLink = (userType, quotation, panelId) => {
  if (userType === 'Reinsurer') {
    return `/quotes/cyber/${quotation.participation.id}`
  }
  if (!panelId) {
    // Multiline
    // TODO: Implement a quote view for multiline
    return `${panelSubmissionPath(panelId)}/${
      quotation.participation.submission.id
    }`
  }
  return `/panels/${panelId}/submissions/${quotation.participation.submission.id}/quotes/${quotation.id}`
}

const cyberParticipationLink = (userType, part, panelId) => {
  return `${panelSubmissionPath(panelId)}/${
    part.submission.id
  }/participations/${part.id}`
}

// multiline panels submissions have no panelId and no nesting
const panelSubmissionPath = panelId =>
  panelId ? `/panels/${panelId}/submissions` : `/panels/submissions`

const participationLink = (userType, part) => {
  if (userType === 'NFP::RepsAndWarranties::Broker') {
    return `/submissions/${part.submission.id}/quotes/${part.id}`
  }
  if (part.submission.__typename === 'CyberSubmission') {
    return `/quotes/cyber/${part.id}`
  }
  if (part.submission.__typename === 'PanelSubmission') {
    return `/quotes/panels/${part.id}`
  }
  return `/quotes/${part.submission.id}`
}

/**
⚠️ IMPORTANT: Please do not add/modify any future links above.

As of June 24, 2021, notification links are now being generated by the API.

If you are modifying the link for a particular entity, please do the following:
  1. Configure app_url_helper.rb to retrieve the proper link for your notification
    + email (if any). Add the appropriate specs as well.
  2. Remove the case from the case/switch statement below. For example, if you
    are adding a link for Worksheet, remove the Worksheet case from below. This
    will allow the linkNavigator to fall back on the link generated by the
    backend (the default case)
*/

const linkNavigator = (userType, entity) => {
  if (!entity.relatesTo) return undefined
  try {
    switch (entity.relatesTo.__typename) {
      case 'Worksheet':
        return worksheetLink(userType, entity)
      case 'Revision':
        return revisionLink(userType, entity)
      case 'Quote':
        return quoteLink(userType, entity)
      case 'Broadcast':
        return broadcastLink(userType, entity)
      case 'DirectMessage':
        return messageLink(userType, entity)
      case 'Question':
        return questionLink(userType, entity)
      case 'Subjectivity':
        return subjectivityLink(userType, entity)
      case 'Participation':
        return participationLink(userType, entity.relatesTo)
      case 'NFPRepsAndWarrantiesQuotation':
        return participationLink(userType, entity.relatesTo.participation)
      case 'NFPRepsAndWarrantiesSubmission':
        return nfpSubmissionLink(userType, entity.relatesTo)
      default:
        return entity.link
    }
  } catch (e) {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test'
    )
      throw e
    Sentry.captureException(e)
    return undefined
  }
}

export default linkNavigator
