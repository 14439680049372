import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

import { Row, Text, Loading, Panel } from 'atoms'
import FlexiForm from 'FlexiForm'
import { PageLayout, QuotationConfig } from 'pages/shared/panels'
import {
  useEditQuoteSchemaQuery,
  useGetCarrierQuoteQuery,
  useEditPanelQuotationMutation,
} from 'graphql/api'

const Edit = ({
  lineOfBusiness,
  participationId,
  returnUrl,
  isManual,
  userType,
}) => {
  const { quoteId } = useParams()
  const history = useHistory()
  const [formData, updateFormData] = useState({})
  const { data, loading, error } = useEditQuoteSchemaQuery({
    variables: {
      participationId: participationId,
      quoteId: quoteId,
    },
  })
  const {
    data: quoteData,
    loading: quoteLoading,
    error: quoteError,
  } = useGetCarrierQuoteQuery({
    variables: {
      quotationId: quoteId,
      participationId: participationId,
    },
  })
  const [editQuotation] = useEditPanelQuotationMutation()
  if (error) throw error
  if (quoteError) throw quoteError
  if (loading || quoteLoading) return <Loading />

  const panel = data.panels.participation.submission.panel
  let schemas = JSON.parse(panel.schemas.editQuote)
  const initialValues = quoteData.panels.participation

  schemas = QuotationConfig.schemas(
    lineOfBusiness,
    schemas,
    userType,
    formData,
    updateFormData,
    isManual,
    true
  )

  const handleSubmit = async () => {
    await editQuotation({
      variables: {
        input: {
          submissionId: data.panels.participation.submission.id,
          quotationId: quoteId,
          attributes: {
            ...formData,
            status: formData.status.toLowerCase(),
          },
        },
      },
    })
    toast.success('Successfully updated quote')
    // TODO: add error handling
    history.push(returnUrl)
  }

  return (
    <PageLayout
      nav={{
        path: returnUrl,
        label: 'Return to Application',
      }}>
      <Panel p={3}>
        <FlexiForm
          {...schemas}
          initialValues={QuotationConfig.formatQuotationData(
            lineOfBusiness,
            initialValues,
            userType,
            isManual
          )}
          skipRules={lineOfBusiness === 'Management Liability'}
          onChange={updateFormData}
          onSubmit={handleSubmit}
          submitLabel={
            <Row alignItems="center">
              <Text>Revise Quote</Text>
            </Row>
          }
        />
      </Panel>
    </PageLayout>
  )
}

export default Edit
