import { Button as ButtonAntd } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { layout, space } from 'styled-system'

import { bool, element, string, oneOf, oneOfType } from 'prop-types'

const Button = props => {
  const { htmlType = 'button', type = 'primary', children, ...rest } = props

  return (
    <ButtonAntd type={type} htmlType={htmlType} {...rest}>
      {children}
    </ButtonAntd>
  )
}

Button.propTypes = {
  children: oneOfType([element, string]),
  type: oneOf(['dashed', 'default', 'ghost', 'link', 'primary', 'text']),
  size: oneOf(['large']),
  disabled: bool,
  loading: bool,
}

const StyledButton = styled(Button)`
  ${space}
  ${layout}
`

export default StyledButton
