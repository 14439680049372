import React from 'react'

import { Row } from 'atoms'
import Field from 'molecules/Field'
import { money } from 'atoms/$'
import { formatDate } from 'util/date'

const BindContent = ({ submission, quotation }) => {
  return (
    <>
      <Row>
        <Field label="Applicant" value={submission.name} />
        <Field label="Limit" value={money(quotation.limit)} />
        <Field label="Deductible" value={money(quotation.deductible)} />
      </Row>
      <Row mt={2} mb={3}>
        <Field label="Premium" value={money(quotation.premium)} width="33.3%" />
        <Field label="Fee" value={money(quotation.fee)} width="33.3%" />
        <Field
          label="Coverage Start Date"
          value={formatDate(quotation.coverageStartDate, 'MMMM DD, YYYY')}
          width="33.3%"
        />
      </Row>
    </>
  )
}

export default BindContent
