import React, { useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { transparentize } from 'polished'
import { withStateHandlers, compose } from 'recompose'
import { useBranding } from 'hooks'

import { colors, fonts } from '../../theme'
import SettingsMenuIcon, { WhitelabelSettingsIcon } from './SettingsMenuIcon'
import Icon from 'components/Icon'
import useOnClickOutside from 'hooks/onClickOutside'

const DropdownContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: transparent;
  border-radius: 0;
  border-width: 0 0;
  position: relative;
`

const MenuHeader = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
`

const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  ${props => (props.left ? 'left: 0px' : 'right: 0px')};
  top: 100%;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 5px 5px 20px 0px ${transparentize(0.8, colors.primary)};
`

const MenuItem = styled.li`
  padding: 12px 20px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  list-style: none;
  background: ${colors.white};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 12%);
  font-family: ${fonts.primary};
  color: ${colors.primaryText}
  font-size: 15px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    background: ${props => props.theme.colors.secondaryBackground};
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
  }

  & > :first-child {
    margin-right: 8px;
    align-items: center;
  }

  & > :only-child {
    margin-right: 0px;
  }
`

/**
 * Provides a dropdown menu that defaults to hidden, triggered to display when clicking the `SettingsMenuIcon`.
 * The `options` array expects a dictionary for each item, with values for `name` (String) and `action` (function).
 */
const SettingsMenuDropdown = ({
  testID,
  options,
  open,
  toggle,
  close,
  itemLabelComponent,
  left,
  iconColor,
}) => {
  const ref = useRef(null)
  useOnClickOutside(ref, close)
  const { isWhitelabel } = useBranding()

  return (
    <DropdownContainer>
      <MenuHeader data-testid={testID} onClick={toggle}>
        {itemLabelComponent ||
          (isWhitelabel ? (
            <WhitelabelSettingsIcon />
          ) : (
            <SettingsMenuIcon open={open} iconColor={iconColor} />
          ))}
      </MenuHeader>
      {open && (
        <MenuList left={left} ref={ref}>
          {options.filter(Boolean).map(({ action, name, icon, to }) => {
            if (to) {
              return (
                <MenuItem as={Link} to={to} onClick={toggle} key={name}>
                  {icon && <Icon icon={icon} />} <div>{name}</div>
                </MenuItem>
              )
            } else {
              return (
                <MenuItem
                  onClick={e => {
                    toggle()
                    action(e)
                  }}
                  key={name}>
                  {icon && <Icon icon={icon} />} <div>{name}</div>
                </MenuItem>
              )
            }
          })}
        </MenuList>
      )}
    </DropdownContainer>
  )
}

export default compose(
  withStateHandlers(
    { open: false },
    {
      toggle:
        ({ open }) =>
        () => ({ open: !open }),
      close: () => () => ({ open: false }),
    }
  )
)(SettingsMenuDropdown)
