import React from 'react'

import { UserType, CompanyKindType } from 'graphql/api'

export function isAuthenticated(user) {
  return user.currentUser !== undefined
}

export function isInvited(user) {
  return user.currentUser !== undefined && user.currentUser.pendingInvitation
}

export function acceptedTerms(user) {
  return (
    user.currentUser !== undefined &&
    user.currentUser.termsAndConditionsAcceptedAt
  )
}

export function isSettingUpProfile(user) {
  return (
    user.currentUser !== undefined &&
    user.currentUser.onboarding === 'setup_profile'
  )
}

export function isLoggedIn(user) {
  return user.currentUser !== undefined && !isInvited(user)
}

export function isInsurance(user) {
  return user?.currentUser?.company?.inInsurance || user?.company?.inInsurance
}

export function isGeneralInbox(user) {
  return user.currentUser !== undefined && user.currentUser.generalInbox
}

const UserContext = React.createContext({
  currentUser: undefined,
  logout: undefined,
})
UserContext.displayName = 'UserContext'

export const withUser = Wrapped => {
  const hoc = props => (
    <UserContext.Consumer>
      {user => <Wrapped {...props} user={user} />}
    </UserContext.Consumer>
  )
  hoc.displayName = `withUser(${Wrapped.displayName})`
  return hoc
}

const OnlyType = type => {
  const Component = ({ children }) => (
    <UserContext.Consumer>
      {user => {
        if (isAuthenticated(user) && user.currentUser.type === type) {
          return children
        }
      }}
    </UserContext.Consumer>
  )
  Component.displayName = `OnlyType(${type})`
  return Component
}
OnlyType.displayName = 'OnlyType'

const NotType = type => {
  const Component = ({ children }) => (
    <UserContext.Consumer>
      {user => {
        if (isAuthenticated(user) && user.currentUser.type !== type) {
          return children
        }
      }}
    </UserContext.Consumer>
  )
  Component.displayName = `NotType(${type})`
  return Component
}
NotType.displayName = 'NotType'

export const LoggedInOnly = ({ children }) => (
  <UserContext.Consumer>
    {user => {
      if (isLoggedIn(user)) {
        return children
      }
    }}
  </UserContext.Consumer>
)

export const GeneralInboxOnly = ({ children }) => (
  <UserContext.Consumer>
    {user => {
      if (isGeneralInbox(user)) {
        return children
      }
    }}
  </UserContext.Consumer>
)

export const CompletedProfileOnly = ({ children }) => (
  <UserContext.Consumer>
    {user => {
      if (!isSettingUpProfile(user)) {
        return children
      }
    }}
  </UserContext.Consumer>
)

export const InvitedOnly = ({ children }) => (
  <UserContext.Consumer>
    {user => {
      if (isInvited(user)) {
        return children
      }
    }}
  </UserContext.Consumer>
)

export const NotAuthenticated = ({ children }) => (
  <UserContext.Consumer>
    {user => {
      if (!isAuthenticated(user)) {
        return children
      }
    }}
  </UserContext.Consumer>
)
export const TermsNotAccepted = ({ children }) => (
  <UserContext.Consumer>
    {user => {
      if (!acceptedTerms(user)) {
        return children
      }
    }}
  </UserContext.Consumer>
)

export const TermsAccepted = ({ children }) => (
  <UserContext.Consumer>
    {user => {
      if (acceptedTerms(user)) {
        return children
      }
    }}
  </UserContext.Consumer>
)

export const InsuranceOnly = ({ children }) => (
  <UserContext.Consumer>
    {user => {
      if (user.currentUser.company.inInsurance) {
        return children
      }
    }}
  </UserContext.Consumer>
)

export const ReinsuranceOnly = ({ children }) => (
  <UserContext.Consumer>
    {user => {
      if (!user.currentUser.company.inInsurance) {
        return children
      }
    }}
  </UserContext.Consumer>
)

const OnlyCompanyType = type => {
  const Component = ({ children }) => (
    <UserContext.Consumer>
      {user => {
        if (isAuthenticated(user) && user.currentUser.company.kind === type) {
          return children
        }
      }}
    </UserContext.Consumer>
  )
  Component.displayName = `OnlyCompanyType(${type})`
  return Component
}
OnlyCompanyType.displayName = 'OnlyCompanyType'

const NotCompanyType = type => {
  const Component = ({ children }) => (
    <UserContext.Consumer>
      {user => {
        if (isAuthenticated(user) && user.currentUser.company.kind !== type) {
          return children
        }
      }}
    </UserContext.Consumer>
  )
  Component.displayName = `NotCompanyType(${type})`
  return Component
}
NotCompanyType.displayName = 'NotCompanyType'

export const WholesaleBrokerageOnly = OnlyCompanyType(
  CompanyKindType.WholesaleBrokerage
)
export const NotWholesaleBrokerage = NotCompanyType(
  CompanyKindType.WholesaleBrokerage
)

export const CedentOnly = OnlyType(UserType.Cedent)
export const ReinsurerOnly = OnlyType(UserType.Reinsurer)
export const BrokerOnly = OnlyType(UserType.Broker)
export const NotCedent = NotType(UserType.Cedent)
export const NotReinsurer = NotType(UserType.Reinsurer)
export const NotBroker = NotType(UserType.Broker)
export const NotNFPBroker = NotType('NFP::RepsAndWarranties::Broker')

export default UserContext
