import React from 'react'

import { first } from 'util/lodash'
import { money } from 'atoms/$'
import { displayDate } from 'util/date'
import { SIC } from 'util/industryCodes'
import Field from 'molecules/Field'
import { Column, Row, SectionTitle } from 'atoms'
import {
  ProductOptionsTable,
  AddressColumn,
  MLConfig,
} from 'pages/shared/panels'
import DocumentsTable from 'molecules/DocumentUpload/DocumentsTable'

const Applicant = ({ submission }) => {
  const address = submission.applicantAddress
  const products = first(submission.products)
  const allDocuments = [...submission.documents]

  return (
    <>
      <SectionTitle mt={1}>Applicant</SectionTitle>
      <Row>
        <Column flex={2}>
          <Row>
            <Field
              label="Industry"
              value={SIC[submission.applicant.naics_code]}
            />
          </Row>
          <Row mt={2}>
            <Field
              label="Number of Employees"
              value={products.numberOfEmployees}
            />
          </Row>
        </Column>
        <Column flex={2}>
          <Row>
            <Field
              label="Annual Revenue"
              value={money(products.annualRevenue)}
            />
          </Row>
        </Column>
        <AddressColumn address={address} />
      </Row>
      <SectionTitle mt={2}>Coverage Requested</SectionTitle>
      <Row>
        <Field
          label="Fee-based Account"
          value={products?.feeBasedAccount ? 'Yes' : 'No'}
        />
        <Field
          label="Continuity Date"
          value={displayDate(products?.continuityDate)}
        />
        <Field
          label="Effective Date"
          value={displayDate(products?.effectiveDate)}
        />
        <Field />
      </Row>
      <br />
      <Row>
        <ProductOptionsTable
          {...MLConfig.Quotation.productOptions(submission)}
        />
      </Row>
      <SectionTitle mt={2}>Application Documents</SectionTitle>
      <DocumentsTable documents={allDocuments} />
    </>
  )
}

export default Applicant
