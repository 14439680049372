import React from 'react'
import {
  cyberInsuringAgreements,
  firstPartyInsuringAgreements,
  thirdPartyInsuringAgreements,
  endorsements,
  additionalFeatures,
} from '../cyber/cyberConstants'
import {
  AdditionalFeaturesTextRenderer,
  AdmittedRenderer,
  BindButtonRenderer,
  DocumentRenderer,
  LimitAndDeductibleRenderer,
  LimitRenderer,
  MoneyRenderer,
  NavButtonRenderer,
  RetailCommissionRenderer,
  StatusRenderer,
  TextRenderer,
  TwoColumnTitleRenderer,
  UnderwriterRenderer,
  WholesalerCommissionWithPercentageRenderer,
} from 'molecules/PanelQuoteComparator/renderers'
import { styleNavButtonRenderer } from 'molecules/PanelQuoteComparator/renderers/NavButtonRenderer'
import { QUOTE_STATUSES } from 'panels/constants'
import { Column, Row, Note } from 'atoms'
import AMBestToolTip from 'molecules/AMBestToolTip'
import { useAmBestImportTimeQuery } from 'graphql/api'
import { displayDate } from 'util/date'
import { parseTooltipDefinitions, isQuotePotentiallyBindable } from '../utils'

const hasStatus = status => quotations =>
  quotations.some(entry => status === entry.status)

const AMBestLabel = () => {
  const { data } = useAmBestImportTimeQuery()

  const hasData = data?.viewer?.lastAmbestImport

  return (
    <Column>
      <Row>
        AM Best Rating
        <AMBestToolTip />
      </Row>
      {hasData ? (
        <Note fontSize="s" fontWeight="0">
          Last Updated {displayDate(data.viewer.lastAmbestImport.importedAt)}
        </Note>
      ) : null}
    </Column>
  )
}

// eslint-disable-next-line no-unused-vars
const criteria = ({ country, currentUser, panelConfiguration }) => {
  const customTooltipDefinitions = parseTooltipDefinitions(
    panelConfiguration?.tooltip_definitions
  )
  return {
    context: {
      bindable: quotations =>
        quotations.some(entry => isQuotePotentiallyBindable(entry)),
      binding: hasStatus(QUOTE_STATUSES.binding),
      bound: hasStatus(QUOTE_STATUSES.bound),
      issued: hasStatus(QUOTE_STATUSES.issued),
      referred: hasStatus(QUOTE_STATUSES.referred),
      extracted: hasStatus(QUOTE_STATUSES.extracted),
      statusMessage: quotations =>
        quotations.some(entry => entry.statusMessage),
      nonAPICarriers: quotations =>
        quotations.some(entry => !entry.isApi && !entry.externalCarrier),
      userIsWholesaler: (_quotes, { wholesalerContext }) =>
        wholesalerContext?.userIsWholesaler,
      hasWholesaler: (_quotes, { wholesalerContext }) =>
        wholesalerContext?.hasWholesaler,
      isPanelOwner: (_quotes, { wholesalerContext }) =>
        wholesalerContext?.isPanelOwner,
      wholesalerCollaborations: (_quotes, { wholesalerContext }) =>
        wholesalerContext?.wholesalerCollaborations,
      isCollaboratingWholesaler: (_quotes, { wholesalerContext }) => {
        const { collaboratingWholesalerIds } = wholesalerContext
        return collaboratingWholesalerIds.includes(currentUser.id)
      },
    },
    sections: [
      {
        criteria: [
          {
            key: 'requestedLimit',
            label: 'Limit',
            renderer: MoneyRenderer,
          },
          {
            key: 'requestedDeductible',
            label: 'Deductible',
            renderer: MoneyRenderer,
          },
          {
            key: 'ambestRating',
            label: <AMBestLabel />,
            renderer: TextRenderer,
            fallback: 'Not Rated',
          },
          {
            key: 'admitted',
            label: 'Admitted / Non-Admitted',
            renderer: AdmittedRenderer,
            showIf: () => country == 'US',
          },
          {
            key: 'quotedLines[0].clientCost',
            label: 'Cost for Insured',
            renderer: MoneyRenderer,
            props: { enhanced: true },
          },
          {
            key: 'quotedLines[0].annualPremium',
            label: 'Annual Premium (Net)',
            renderer: MoneyRenderer,
          },
          {
            key: 'quotedLines[0]',
            label: 'Retailer Commission',
            renderer: RetailCommissionRenderer,
            isAdjustable: false,
          },
          {
            key: 'quotedLines[0]',
            label: 'Wholesaler Commission',
            renderer: WholesalerCommissionWithPercentageRenderer,
            showIf: ({ context }) => {
              const { userIsWholesaler } = context
              return userIsWholesaler
            },
          },
          {
            key: 'fee',
            label: 'Fee',
            renderer: MoneyRenderer,
          },
          { key: 'documents', label: 'Documents', renderer: DocumentRenderer },
          {
            key: 'quotedLines[0].policyNumber',
            label: 'Policy Number',
            renderer: TextRenderer,
            props: { fontWeight: 2 },
            showIf: ({ context }) => context.bound || context.issued,
          },
          // Buttons and statuses
          {
            renderer: NavButtonRenderer,
            label: 'View Details',
            secondary: true,
            to: ({ quote }) => `${quote.submissionId}/quotes/${quote.id}`,
            show: ({ quote }) =>
              !quote.externalCarrier && !quote.isApi && !quote.isExtracted,
            showIf: ({ context }) => context.nonAPICarriers,
          },
          {
            renderer: BindButtonRenderer,
            label: 'Bind Quote',
            showIf: ({ context }) => context.bindable,
            show: ({ quote }) => isQuotePotentiallyBindable(quote),
            to: ({ quote }) => `${quote.submissionId}/quotes/${quote.id}/bind`,
          },
          // TODO: Once we want to support a retailer messaging multiple wholesalers, we will
          // need to turn this into a select or something where the retailer can choose which
          // wholesaler to message
          {
            renderer: styleNavButtonRenderer({
              style: { width: '200px', maxWidth: '75%' },
              lockable: false,
            }),
            label: props =>
              `Message ${props?.wholesalerCompanyName || 'Wholesaler'}`,
            secondary: true,
            showIf: ({ context }) => {
              // display if:
              // - user is a retailer
              // - retailer is the owner of the submission
              const { hasWholesaler, userIsWholesaler, submissionOwnerId } =
                context
              return (
                hasWholesaler &&
                !userIsWholesaler &&
                submissionOwnerId === currentUser.id
              )
            },
            to: ({ quote, context }) => {
              const collaboration = context.wholesalerCollaborations.find(
                collaboration => {
                  return (
                    collaboration?.user?.company?.id ===
                    quote.wholesalerCompanyId
                  )
                }
              )
              return `${quote.submissionId}/collaborations/${collaboration.id}/discussion`
            },
          },
          {
            renderer: styleNavButtonRenderer({
              style: { width: '200px', maxWidth: '75%' },
              lockable: false,
            }),
            label: 'Message Retailer',
            secondary: true,
            showIf: ({ context }) => {
              // display if:
              // - the user is a wholesaler
              // - the current user is the collaborating wholesaler
              const {
                hasWholesaler,
                userIsWholesaler,
                isCollaboratingWholesaler,
              } = context
              return (
                hasWholesaler && userIsWholesaler && isCollaboratingWholesaler
              )
            },
            to: ({ quote, context }) => {
              return `${quote.submissionId}/collaborations/${context.wholesalerCollaborations[0].id}/discussion`
            },
          },
          {
            renderer: UnderwriterRenderer,
            label: 'Underwriter',
            path: ({ submissionId }, quotation) =>
              `/panels/submissions/${submissionId}/participations/${quotation.participationId}`,
            showIf: ({ context }) => {
              // display if:
              // - Retailer submission: The user is NOT a retailer and is the current collaborating wholesaler
              // - Wholesaler submission: The current user owns the submission
              const {
                hasWholesaler,
                userIsWholesaler,
                isCollaboratingWholesaler,
                submissionOwnerId,
              } = context
              return (
                !(hasWholesaler && !userIsWholesaler) &&
                (isCollaboratingWholesaler ||
                  submissionOwnerId === currentUser.id)
              )
            },
          },
          {
            renderer: StatusRenderer,
            showIf: ({ context }) =>
              context.binding ||
              context.bound ||
              context.issued ||
              context.referred,
          },
          {
            key: 'statusMessage',
            renderer: TextRenderer,
            props: { fontWeight: 2 },
            showIf: ({ context }) => context.statusMessage,
          },
        ],
      },
      {
        title: 'Insuring Agreements',
        criteria: [
          {
            label: 'Third Party',
            renderer: TwoColumnTitleRenderer,
            titles: ['Limit', 'Deduct / Waiting'],
          },
          ...thirdPartyInsuringAgreements(
            country,
            customTooltipDefinitions['Third Party Insuring Agreements'] || {}
          ).map(({ label, key, tooltipContent }) => {
            return {
              label,
              key,
              renderer: LimitAndDeductibleRenderer,
              tooltipContent,
            }
          }),
          {
            label: 'First Party',
            renderer: TwoColumnTitleRenderer,
            titles: ['Limit', 'Deduct / Waiting'],
          },
          ...firstPartyInsuringAgreements(
            country,
            customTooltipDefinitions['First Party Insuring Agreements'] || {}
          ).map(({ label, key, tooltipContent }) => ({
            label,
            key,
            renderer: LimitAndDeductibleRenderer,
            tooltipContent,
          })),
          {
            label: 'Cyber Crime',
            renderer: TwoColumnTitleRenderer,
            titles: ['Limit', 'Deduct / Waiting'],
          },
          ...cyberInsuringAgreements(
            country,
            customTooltipDefinitions['Cyber Insuring Agreements'] || {}
          ).map(({ label, key, tooltipContent }) => ({
            label,
            key,
            renderer: LimitAndDeductibleRenderer,
            tooltipContent,
          })),
        ],
      },
      {
        title: 'Endorsements',
        criteria: [
          ...endorsements(customTooltipDefinitions['Endorsements'] || {}).map(
            ({ label, key, tooltipContent }) => ({
              label,
              key,
              renderer: LimitRenderer,
              tooltipContent,
            })
          ),
        ],
      },
      {
        title: 'Additional Features',
        showIf: () => country == 'CA',
        criteria: [
          ...additionalFeatures(
            customTooltipDefinitions['Additional Features'] || {}
          ).map(({ label, key, tooltipContent }) => ({
            label,
            key,
            renderer: AdditionalFeaturesTextRenderer,
            tooltipContent,
          })),
        ],
      },
    ],
  }
}

export default criteria
