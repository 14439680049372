import React from 'react'
import styled from 'styled-components'

const Legend = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & span {
    color: red;
    font-size: 18px;
    font-weight: 600;
    margin-right: 0.25rem;
  }
`

const RequiredLegend = () => (
  <Legend>
    <span>*</span> Required
  </Legend>
)

export default RequiredLegend
