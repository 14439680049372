import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  faPlus,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-solid-svg-icons'

import { IconLink, Icon, IconCircle, Div, Text, Flex } from 'atoms'
import { colors } from 'components/theme'

const FancyBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGrey};
  border-radius: 4px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  cursor: ${props => (props.disabled ? 'none' : 'pointer')};
`

const FancyText = styled.div`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 14px;
  color: ${props => props.theme.colors.primaryText};

  & h4 {
    padding: 0;
    margin: 0;
    font-weight: normal;
    color: ${props => props.theme.colors.link};
    line-height: 24px;
  }
`

const LinkText = styled(Text)`
  cursor: pointer;
  color: ${props => props.theme.colors.link};
`

const ToggleLink = ({ children, onClick, showing, ...rest }) => (
  <Flex onClick={onClick} {...rest} alignItems="center">
    <LinkText>{children}</LinkText>
    <Icon
      ml={0}
      color={colors.link}
      icon={showing ? faChevronUp : faChevronDown}
    />
  </Flex>
)

const PlusToggle = ({
  show,
  text,
  children,
  disabled,
  onClick,
  icon = faPlus,
  ...rest
}) => {
  const [showFields, toggleShow] = useState(show)
  const clickHandler = evt => {
    if (disabled) {
      return null
    }
    toggleShow(!showFields)
    return onClick && onClick(evt)
  }
  return (
    <Div fontSize="s" {...rest}>
      {showFields ? (
        children
      ) : (
        <IconLink onClick={clickHandler} icon={icon}>
          {text}
        </IconLink>
      )}
    </Div>
  )
}

const FancyPlusToggle = ({
  show,
  title,
  text,
  children,
  disabled = false,
  onClick,
  icon = faPlus,
  ...rest
}) => {
  const [showFields, toggleShow] = useState(show)
  const clickHandler = evt => {
    if (disabled) {
      return null
    }
    toggleShow(!showFields)
    return onClick && onClick(evt)
  }
  useEffect(() => {
    toggleShow(show)
  }, [show])

  return (
    <Div fontSize="s" {...rest}>
      {showFields ? (
        children
      ) : (
        <FancyBox onClick={clickHandler}>
          <div>
            <IconCircle disabled={disabled}>
              <Icon icon={icon} color="white" />
            </IconCircle>
          </div>
          <FancyText>
            <h4>{title}</h4>
            <p>{text}</p>
          </FancyText>
        </FancyBox>
      )}
    </Div>
  )
}

export default PlusToggle
export { FancyPlusToggle, ToggleLink }

// NOTE: To use plusToggle, 'show' logic must be correctly defined within the show prop.
// i.e. if a form field's value is a string, use input.value.length > 0
