import React from 'react'
import styled from 'styled-components'

import { Flex, Label, Error } from 'atoms'

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  height: 16px;
  width: 16px;
  background-color: ${props => props.theme.colors.primaryText};
  border-radius: 4px;
  border: 2px solid ${props => props.theme.colors.link};
  flex-shrink: 0;
`

const Checkbox = ({
  label,
  input: { onChange, value, name },
  meta = {},
  ...rest
}) => (
  <Flex {...rest} flexDirection="column">
    <Flex alignItems="baseline">
      <Input
        name={name}
        id={name}
        onChange={onChange}
        checked={!!value}
        {...rest}
      />
      <Label ml={0} htmlFor={name}>
        {label}
      </Label>
    </Flex>
    {meta.touched && meta.error && <Error>{meta.error}</Error>}
  </Flex>
)

export default Checkbox
