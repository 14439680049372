import relayLogo from 'images/relay-logo-white.svg'

export default {
  brand: 'relay',
  brandName: 'Relay',
  name: 'Relay',
  logo: relayLogo,
  logoAlt: 'Relay Platform',
  cobranded: false,
  showHelp: true,
  allowSignup: true,
  billable: true,
}
