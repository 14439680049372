import React from 'react'
import { Widgets } from '@rjsf/material-ui'
import { ThemeProvider, createMuiTheme } from '@material-ui/core'
import { muiTheme as theme } from 'components/theme'
import { Div } from 'atoms'

theme.props.MuiCheckbox = {
  color: 'primary',
}

const muiTheme = createMuiTheme(theme)

const Checkbox = props => {
  const styles = props.options?.styles || {}

  return (
    <Div {...styles}>
      <ThemeProvider  theme={muiTheme}>
        <Widgets.CheckboxWidget  {...props} disabled={props.schema.disabled} />
      </ThemeProvider>
    </Div>
  )
}

export default Checkbox
