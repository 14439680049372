import jp, { mapJson, mapFields } from 'util/jsonpath'
import { compact, first, startCase, camelObject } from 'util/lodash'

import {
  transformCoverages,
  filteredDirectorsAndOfficersQuotedLine,
} from './utils'
import * as icons from '@fortawesome/pro-solid-svg-icons'

export const ApplicantMapping = {
  ...mapFields([
    'id',
    'status',
    'company_name',
    'naics_code',
    'name',
    'email',
    'phone_number',
  ]),
}

export const QuotedLinesCoverageMapping = {
  ...mapFields(['name', 'limit', 'retention']),
}

export const QuotedLinesMapping = {
  ...mapFields(['type', 'coverageName']),
  icon: line => icons[line.iconName],
  coverages: line => {
    return transformCoverages(line).map(coverage =>
      mapJson(coverage || {}, QuotedLinesCoverageMapping)
    )
  },
}

export const CoverageMapping = {
  ...mapFields(['id', 'type', 'name']),
}

export const QuotationMapping = (user, emailMapping) => ({
  ...mapFields([
    'id',
    'participationId',
    'submissionId',
    'carrierName',
    'admitted',
    'annualPremium',
    'currentPolicy',
    'requestedAt',
    'declinationReason',
    'indication',
    'retailCommissionPercentage',
    'retailCommission',
    'selected',
    'subjectivitiesAttributes',
    'documents',
    'policyNumber',
    '__typename',
  ]),
  __typename: quotation => quotation.__typename || 'MLQuotation',
  underwriterEmail: quotation => emailMapping[quotation.id]?.email,
  underwriterFirstName: quotation => emailMapping[quotation.id]?.firstName,
  underwriterLastName: quotation => emailMapping[quotation.id]?.lastName,
  isManual: () => !user,
  isCarrier: () => Boolean(user),
  quotedLines: quotation => {
    if (quotation.quotedLines) {
      return quotation.quotedLines.nodes.map(line =>
        mapJson(line || {}, QuotedLinesMapping)
      )
    }
  },
  status: quotation => startCase(quotation.status),
  carrier: ['user.company.name', 'externalCarrier.name'],
  logoUrl: ['user.company.logoUrl', 'externalCarrier.logoUrl'],
  limit: quotation => filteredDirectorsAndOfficersQuotedLine(quotation).limit,
  deductible: quotation =>
    filteredDirectorsAndOfficersQuotedLine(quotation).retention,
})

export const SubmissionMapping = {
  ...mapFields([
    'id',
    'name',
    'products',
    'applicant',
    'updatedAt',
    'owner',
    'documents',
  ]),
  applicant: submission =>
    mapJson(submission.applicant || {}, ApplicantMapping),
  panelId: 'panel.id',
  lineOfBusiness: 'panel.lineOfBusiness',
  applicantAddress: submission => {
    return camelObject(submission.applicant?.address)
  },
  participationId: submission => {
    const participations = jp.query(submission, 'participations.nodes[*]')
    return first(compact(participations))?.id
  },
  coverages: submission => {
    const products = jp.query(submission, 'products[*]')
    return products.map(({ coverages }) => {
      return coverages.map(coverage => mapJson(coverage, CoverageMapping))
    })
  },
  participations: submission => jp.query(submission, 'participations.nodes[*]'),
  products: submission => {
    const products = jp.query(submission, 'products[*]')
    return products.map(product => {
      return camelObject(product)
    })
  },
  quotations: submission => {
    let quotationsList = []
    const participations = jp.query(submission, 'participations.nodes[*]')
    let emailMapping = {}
    participations.forEach(participation => {
      participation?.quotations?.nodes.forEach(quote => {
        const user = participation.user
        emailMapping[quote.id] = {
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
        }
      })
    }),
      compact(participations).forEach(
        ({
          id: participationId,
          createdAt,
          quotations,
          user,
          externalCarrier,
        }) => {
          if (quotations.nodes.length > 0) {
            quotations.nodes.forEach(quotation => {
              quotationsList.push(
                mapJson(
                  {
                    ...quotation,
                    participationId,
                    requestedAt: createdAt,
                    submissionId: submission.id,
                    submissionOwner: submission.owner.fullName,
                    carrierName: user?.fullName || externalCarrier?.name,
                  },
                  QuotationMapping(user, emailMapping)
                )
              )
            })
          } else {
            quotationsList.push(
              mapJson(
                {
                  status: 'pending',
                  participationId,
                  requestedAt: createdAt,
                  submissionId: submission.id,
                  submissionOwner: submission.owner.fullName,
                  carrierName: user?.fullName,
                },
                QuotationMapping(user, emailMapping)
              )
            )
          }
        }
      )
    return quotationsList
  },
}
