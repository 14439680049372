import React from 'react'
import { Link } from 'react-router-dom'
import { faLock, faEdit } from '@fortawesome/pro-solid-svg-icons'

import { Flex, Text, IconButton } from 'atoms'

export const styleNavButtonRenderer = ({ style, lockable }) => {
  const NavButtonRenderer = ({
    criterion: { to, label, cellText, secondary, tertiary, edit },
    children: entry,
    locked,
    context,
    show = true,
  }) => {
    if (!show) return null

    const disabled = lockable && locked

    const buttonText = () => {
      if (cellText) {
        return typeof cellText === 'function' ? cellText(entry) : cellText
      } else {
        return typeof label === 'function' ? label(entry) : label
      }
    }
    return (
      <Flex justifyContent="center">
        <IconButton
          maxWidth="50%"
          width="120px"
          icon={disabled ? faLock : edit ? faEdit : ''}
          disabled={disabled}
          secondary={secondary}
          tertiary={tertiary}
          as={Link}
          to={to({ quote: entry, context })}
          style={style}>
          <Text>{buttonText()}</Text>
        </IconButton>
      </Flex>
    )
  }
  return NavButtonRenderer
}

export default styleNavButtonRenderer({ style: {}, lockable: true })
