import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

import { colors } from 'components/theme'

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 57.5px 0 57.5px 33px;
  border-color: transparent transparent transparent
    ${props => props.brandColor || colors.primaryText};
`
const Rectangle = styled.div`
  height: 115px;
  width: 370px;
  background-color: ${props => props.brandColor || colors.primaryText};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const MiniTriangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 0;
  border-color: transparent
    ${props => (props.brandColor ? darken(0.1, props.brandColor) : '#2e2e2e')}
    transparent transparent;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 99;
  width: ${props => props.width || '405px'};
  position: relative;
  right: 55px;
`

const Horizontal = styled.div`
  display: flex;
`

const Content = styled.div`
  margin-left: 50px;
`

const PlanWrapper = ({ children, brandColor, width }) => (
  <Container width={width}>
    <Horizontal>
      <Rectangle brandColor={brandColor}>
        <Content>{children}</Content>
      </Rectangle>
      <Triangle brandColor={brandColor} />
    </Horizontal>
    <MiniTriangle brandColor={brandColor} />
  </Container>
)

export default PlanWrapper
