import styled from 'styled-components'

import { ButtonBox } from 'components/submission/Layout'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.marginTop && `margin-top: ${props.marginTop};`}
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}

  ${props =>
    props.gap &&
    `
    & > * {
      margin-right: ${props.gap};
    }
    & > :last-child {
      margin-right: 0;
    }
  `}

  ${props => props.justify && `justify-content: ${props.justify};`}
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.marginTop && `margin-top: ${props.marginTop};`}
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${props => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight};`}

  ${props =>
    props.gap &&
    `
    & > * {
      margin-bottom: ${props.gap};
    }
    & > :last-child {
      margin-bottom: 0;
    }
  `}

  ${props => props.alignItems && `align-items: ${props.alignItems};`}
`

const Void = styled.div`
  width: ${props => props.width};
`

const DarkContainer = styled.div`
  background-color: ${props => props.theme.colors.secondaryBackground};

  & input,
  & textarea {
    background-color: ${props => props.theme.colors.secondaryBackground};
  }
`

const LightContainer = styled.div`
  background-color: ${props => props.theme.colors.white};

  & input,
  & textarea {
    background-color: ${props => props.theme.colors.white};
  }
`

const Form = styled.form`
  width: 512px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &:before,
  &:after {
    content: '';
    display: block;
  }
`

const ModalForm = styled(Form)`
  width: 600px;
  height: 100%;
  padding: 1rem;
  display: flex;
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.primaryText};
  flex-direction: column;

  & input,
  & > * {
    width: 100%;
    margin-bottom: 1rem;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
`

const ApprovalContainer = styled.div`
  padding: 1rem;
  border: 1px solid ${props => props.theme.colors.toast.info.bar};
  border-radius: 0.25rem;
  background-color: ${props => props.theme.colors.toast.info.bg};

  & h3 {
    margin-top: 0;
  }

  svg {
    margin-right: 0.5rem;
  }

  ${ButtonBox} {
    justify-content: center;

    & > * {
      margin: 0 0.5rem;
    }
  }
`

const SettingsPage = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 850px;
  min-height: calc(100% - 60px);
  margin: 0 60px;
`

export {
  Row,
  Column,
  Void,
  DarkContainer,
  LightContainer,
  Form,
  ModalForm,
  ApprovalContainer,
  SettingsPage,
}
