import React from 'react'
import styled from 'styled-components'
import LegalLink from 'components/LegalLink'
import { submissionTranslator } from 'util/translations'

const CheckboxText = styled.label`
  font-weight: 300;
`

const Star = styled.span`
  color: red;
  font-size: 18px;
`

const PlacementTerms = ({ name, worksheetKind }) => {
  const translator = submissionTranslator({ kind: worksheetKind })
  return (
    <CheckboxText htmlFor={name}>
      I agree to share placement data over email and via Relay with the invited{' '}
      {translator('role')}s. See{' '}
      <LegalLink large agreement="SubscriptionTerms" /> and{' '}
      <LegalLink large agreement="PrivacyPolicy" />.<Star>*</Star>
    </CheckboxText>
  )
}

export default PlacementTerms
