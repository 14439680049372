import merge from 'lodash/merge'
import pick from 'lodash/pick'
import { Children, cloneElement } from 'react'
import { utils as rjsfUtils } from '@rjsf/core'

import { isPresent } from './langUtil'

const { deepEquals } = rjsfUtils

export const shouldRender = (
  prevProps,
  nextProps,
  { keysToPick = [] } = {}
) => {
  const objA = isPresent(keysToPick) ? pick(prevProps, keysToPick) : prevProps
  const objB = isPresent(keysToPick) ? pick(nextProps, keysToPick) : nextProps

  return !deepEquals(objA, objB)
}

const addMissingProps = (child, otherProps) => {
  const props = merge({}, child.props, { ...otherProps })
  return cloneElement(child, { ...props })
}
const addPropsToChildElements = ({ children, props }) => {
  const elements = Children.toArray(children)

  if (elements.length === 0) return children
  if (elements.length === 1) return addMissingProps(elements[0], props)

  return elements.map(element => addMissingProps(element, props))
}

export { addPropsToChildElements }
