export const countUnanswered = (questions, formData) => {
  const notRequiredQuestions = ['operationsInclude']
  const notRequiredCount = notRequiredQuestions.reduce(
    (total, question) => (question in questions ? total + 1 : total),
    0
  )

  const hiddenQuestions = ['enabledChubbNewYork']
  const hiddenAnsweredCount = hiddenQuestions.reduce(
    (total, question) => (question in formData ? total + 1 : total),
    0
  )

  const totalQuestionsCount = hiddenQuestions.reduce(
    (total, hiddenQuestion) =>
      hiddenQuestion in questions ? total - 1 : total,
    Object.keys(questions).length
  )

  const answeredCount = Object.values(formData).reduce(
    (total, answer) =>
      Boolean(answer) &&
      answer !== 'UN' &&
      !(Array.isArray(answer) && answer.length == 0)
        ? total + 1
        : total,
    0
  )
  return (
    totalQuestionsCount -
    (answeredCount - hiddenAnsweredCount) -
    notRequiredCount
  )
}
