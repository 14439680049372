import { diff as deepObjectDiff } from 'deep-object-diff'

import { dottedPick } from './dottedUtil'
import { dottedOmit } from './dottedUtil'
import { isPresent } from './langUtil'

export const deepDiff = (
  objA,
  objB,
  { keysToOmit = [], keysToPick = [] } = {}
) => {
  const pickedObjA = isPresent(keysToPick) ? dottedPick(objA, keysToPick) : objA

  const pickedObjB = isPresent(keysToPick) ? dottedPick(objB, keysToPick) : objB

  const reducedObjA = isPresent(keysToOmit)
    ? dottedOmit(pickedObjA, keysToOmit)
    : pickedObjA

  const reducedObjB = isPresent(keysToOmit)
    ? dottedOmit(pickedObjB, keysToOmit)
    : pickedObjB

  return deepObjectDiff(reducedObjA, reducedObjB)
}

export const isDeepDifferent = (
  objA,
  objB,
  { keysToOmit = [], keysToPick = [] } = {}
) => isPresent(deepDiff(objA, objB, { keysToOmit, keysToPick }))

export const isEmptyObject = obj =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object
