import React from 'react'
import { toast } from 'react-toastify'
import { useHistory, useRouteMatch, Route } from 'react-router-dom'
import AddAnswerModal from 'components/QAndA/Answer/AddAnswerModal'
import EditAnswerModal from 'components/QAndA/Answer/EditAnswerModal'
import QAndA from 'components/QAndA/QAndA'
import SubjectivitiesEdit from 'cyber/Quotation/SubjectivitiesEdit'
import { useSubjectivitiesQuery, usePanelParticipationQuery } from 'graphql/api'

import { Column, Text, Loading, Subtitle } from 'atoms'

const AnswererSubjectivity = ({ submission, subjectivitable }) => {
  const participation = usePanelParticipationQuery({
    variables: {
      participationId: subjectivitable.id,
    },
  })

  const { loading, error, data } = useSubjectivitiesQuery({
    variables: {
      subjectivitableId: subjectivitable.id,
      subjectivitableType: subjectivitable.__typename,
    },
    fetchPolicy: 'cache-and-network',
  })
  const history = useHistory()
  const { path, url } = useRouteMatch()

  if (loading) return <Loading />
  if (error) throw error
  const showManualSubjectivities = participation?.data?.panels?.participation?.submission?.panel?.manualSubjectivities
  const subjectivities = data.qAndA.subjectivitable.subjectivities.nodes

  return subjectivities.length === 0 && !showManualSubjectivities ? (
    <Text>No subjectivities.</Text>
  ) : subjectivities.length > 0 && !showManualSubjectivities ? (
    <>
      <Column>
        <Subtitle>Subjectivities</Subtitle>
        <Route
          path={`${path}/subjectivities/:questionId/answers/:answerId/edit`}>
          <EditAnswerModal
            questions={subjectivities}
            onClose={() => history.replace(url)}
            scope={'Subjectivity'}
            onCompleted={() => {
              history.replace(url)
              toast.success('Your response has been saved')
            }}
          />
        </Route>
        <Route path={`${path}/subjectivities/:questionId/answers/new`}>
          <AddAnswerModal
            scope={'Subjectivity'}
            questions={subjectivities}
            onClose={() => history.replace(url)}
            onCompleted={() => {
              history.replace(url)
              toast.success('Your response has been saved')
            }}
          />
        </Route>
        <QAndA
          questions={subjectivities}
          responder={submission.owner}
          scope="Subjectivity"
          enableFollowUp={false}
          context="responder"
        />
      </Column>
    </>
  ) : (
    <Column>
      <SubjectivitiesEdit
        subId={submission.id}
        subjList={subjectivities}
        subjectivitable={subjectivitable}
      />
    </Column>
  )
}

export default AnswererSubjectivity
