import React from 'react'
import { faPlusCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'
import { Grid, makeStyles } from '@material-ui/core'

import { Flex, Icon, Div, Text, Divider, Subtitle } from 'atoms'

const AddContainer = styled(Div)`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
`

const useStyles = makeStyles({
  root: {
    marginBottom: 0,
  },
})

const Table = ({ canAdd, items, onAddClick, uiSchema, className, title }) => {
  const classes = useStyles()
  return (
    <Div className={className}>
      {(uiSchema['ui:title'] || title) && (
        <Subtitle m="0px">{title.toUpperCase()}</Subtitle>
      )}
      {uiSchema.headers && (
        <>
          <Grid
            container
            spacing={2}
            className={classes.root}
            alignItems="center">
            {uiSchema.headers.map((header, index) => {
              return (
                <Grid key={index} item xs={header.cols || '1'}>
                  <Text fontWeight={2} fontSize={header.size || 'm'}>
                    {header.title}{' '}
                  </Text>
                </Grid>
              )
            })}
          </Grid>
          <Divider />
        </>
      )}
      {items.map(element => (
        <Div key={element.key}>
          <Flex alignItems="center">
            <Div flex={1}>{element.children}</Div>
            {element.hasRemove && (
              <Icon
                data-testid="remove"
                ml={0}
                icon={faTimesCircle}
                onClick={element.onDropIndexClick(element.index)}
              />
            )}
          </Flex>
        </Div>
      ))}
      {canAdd && (
        <AddContainer color="link" onClick={onAddClick} data-testid="add">
          <Icon icon={faPlusCircle} mr={0} /> {uiSchema['addLabel']}
        </AddContainer>
      )}
    </Div>
  )
}

export default Table
