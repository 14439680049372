import React, { createRef, useRef, useState } from 'react'
import { Checkbox, FormGroup } from '@material-ui/core'
import { CurrencyDropdownWidget } from 'pages/shared/panels'
import { useSubmission } from 'hooks'
import { Row, Column, Text } from 'atoms'
import styled from 'styled-components'

const options = {
  limit: [500000, 1000000, 2000000, 3000000, 5000000],
  deductible: [1000, 2500, 5000, 10000, 15000, 25000, 50000, 100000],
}

const AVAILABLE_FIELDS = 3

const Label = styled(Text)`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  margin: 0.4rem 0.5rem;
`

// eslint-disable-next-line no-unused-vars
const SimpleCoverageSelector = ({
  schema,
  onChange,
  formData = [],
  errorSchema,
}) => {
  if (!formData || !formData.length) return null
  const {
    items: {
      properties: { type, limit, deductible, request_higher_limit },
    },
  } = schema
  const { status } = useSubmission()
  const loadState = () => {
    let mapping = {}
    const result = {
      type: type.enum,
      limit: [],
      deductible: [],
      request_higher_limit: [],
    }
    formData.forEach(entry => {
      mapping[entry.type] = entry
    })
    result.type.forEach(entry => {
      if (mapping[entry]) {
        const values = mapping[entry]
        result.limit.push(values.limit)
        result.deductible.push(values.deductible)
        result.request_higher_limit.push(values.request_higher_limit || false)
      } else {
        result.limit.push(null)
        result.deductible.push(null)
        result.request_higher_limit.push(false)
      }
    })
    return result
  }
  const inputRefs = useRef([])
  const [coverageData, setCoverageData] = useState(loadState())

  const onCheck = value => () => {
    const item = formData.find(item => item.type === value)
    const index = coverageData.type.indexOf(value)
    if (isChecked(value)) {
      updateCheckedRef(index, false)
      if (item.id) {
        onChange([
          ...formData.filter(x => x !== item),
          { ...item, _destroy: true },
        ])
      } else {
        onChange(formData.filter(x => x !== item))
      }
    } else {
      updateCheckedRef(index, true)
      if (item?._destroy) {
        const { _destroy, ...restored } = item
        onChange([...formData.filter(x => x !== item), restored])
      } else {
        const coverageIndex = coverageData.type.indexOf(value)
        onChange([
          ...formData,
          {
            type: value,
            limit: coverageData.limit[coverageIndex],
            deductible: coverageData.deductible[coverageIndex],
            request_higher_limit:
              coverageData.request_higher_limit[coverageIndex],
          },
        ])
      }
    }
  }

  const setRef = (coverageIndex, index, checked) => ref => {
    inputRefs.current[coverageIndex * AVAILABLE_FIELDS + index] = {
      checked,
      ref,
    }
  }

  const updateCheckedRef = (coverageIndex, checked = false) => {
    for (let i = 0; i < AVAILABLE_FIELDS; i++) {
      inputRefs.current[coverageIndex * AVAILABLE_FIELDS + i].checked = checked
    }
  }

  const isChecked = value => {
    const item = formData.find(item => item.type === value)
    return item ? !item._destroy : false
  }

  const updateValueAtIndex = (type, index) => value => {
    const entryCopy = [...coverageData[type]]
    entryCopy[index] = value
    setCoverageData({ ...coverageData, [type]: entryCopy })
    const coverage = coverageData.type[index]
    let newValue = []
    formData.forEach(entry => {
      if (entry.type === coverage) {
        const entryCopy = { ...entry }
        entryCopy[type] = value === '' ? null : value
        newValue.push(entryCopy)
      } else {
        newValue.push(entry)
      }
    })
    onChange(newValue)
  }

  const onCheckHigherLimit = index => () => {
    const higherLimitsCopy = [...coverageData.request_higher_limit]
    higherLimitsCopy[index] = !higherLimitsCopy[index]
    setCoverageData({ ...coverageData, request_higher_limit: higherLimitsCopy })
    const coverage = coverageData.type[index]
    let newValue = []
    formData.forEach(entry => {
      if (entry.type === coverage) {
        const entryCopy = { ...entry }
        entryCopy.request_higher_limit = higherLimitsCopy[index]
        newValue.push(entryCopy)
      } else {
        newValue.push(entry)
      }
    })
    onChange(newValue)
  }

  const fieldErrors = (type, index) => {
    if (!coverageData[type][index] && isChecked(coverageData.type[index])) {
      if (errorSchema?.[index]?.[type]) {
        return errorSchema[index][type].__errors
      }
    }
    return []
  }

  const MonetaryField = ({
    inputRef,
    index,
    label,
    type,
    disabled,
    fieldIndex,
  }) => (
    <Column m="0.4rem 0.25rem" width="90%">
      <CurrencyDropdownWidget
        inputRef={inputRef}
        formIndex={index * AVAILABLE_FIELDS + fieldIndex}
        formRefs={inputRefs.current}
        value={disabled ? '' : coverageData[type][index]}
        onChange={updateValueAtIndex(type, index)}
        name={label}
        label={label}
        size="large"
        disabled={disabled}
        errors={fieldErrors(type, index)}
        required={schema.items.required.includes(type)}
        options={{ enumOptions: options[type].map(i => ({ value: i })) }}
      />
    </Column>
  )
  return (
    <FormGroup>
      <Row width="100%">
        <Column></Column>
        <Column width="5.3rem">
          <Text fontSize="1" textAlign="center">
            {request_higher_limit.title}
          </Text>
        </Column>
      </Row>
      {type.enum.map((value, index) => {
        const ref = createRef()
        const checkDisabled = index === 0 || status !== 'draft'
        const checked = isChecked(value)
        return (
          <Row key={value} width="100%">
            <Column width="1.5rem">
              <Checkbox
                disabled={checkDisabled}
                inputRef={ref}
                checked={checked}
                onChange={onCheck(value)}
                name={value}
              />
            </Column>
            <Column>
              <Label
                disabled={checkDisabled}
                fontSize={3}
                onClick={() => ref.current.click()}>
                {type.enumNames[index]}
              </Label>
            </Column>
            <MonetaryField
              fieldIndex={0}
              inputRef={setRef(index, 0, checked)}
              index={index}
              label={limit.title}
              type="limit"
              disabled={!checked}
            />
            <MonetaryField
              fieldIndex={1}
              inputRef={setRef(index, 1, checked)}
              index={index}
              label={deductible.title}
              type="deductible"
              disabled={!checked}
            />
            <Column width="300px">
              <Checkbox
                inputRef={setRef(index, 2, checked)}
                disabled={!checked}
                checked={
                  checked ? coverageData.request_higher_limit[index] : false
                }
                onChange={onCheckHigherLimit(index)}
                name={`${value}-higher-limit`}
              />
            </Column>
          </Row>
        )
      })}
    </FormGroup>
  )
}

export default SimpleCoverageSelector
