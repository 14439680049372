import React from 'react'
import styled from 'styled-components'
import { space, layout } from 'styled-system'
import {
  faExclamationCircle,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons'

import { colors } from 'components/theme'
import { Icon, Error as InlineError } from 'atoms'

const Container = styled.div`
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  min-height: 15px;
`

const WarningContainer = styled(Container)`
  background-color: ${props => props.theme.colors.warningBackground};
  border: 1px solid ${props => props.theme.colors.secondaryYellow};
  ${space}
  ${layout}
`

const WarningText = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 14px;
  margin: 0;
  align-self: center;
`

const ErrorContainer = styled(Container)`
  background-color: ${props => props.theme.colors.errorBackground};
  border: 1px solid ${props => props.theme.colors.error};
  ${space}
`

const ErrorText = styled.p`
  color: ${props => props.theme.colors.error};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`

const InfoContainer = styled(Container)`
  background-color: ${props => props.theme.colors.primaryText};
  padding: 3px 5px;
  ${space}
  ${layout}
`

const InfoText = styled.p`
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 14px;
  margin: 0;
  align-self: center;
`

const StyledIcon = styled(Icon)`
  margin-right: 5px;
  margin-top: ${props =>
    props.simple ? '4px' : props.long ? '5px' : props.small ? '0px' : '2.5px'};
  font-size: 14px;
  align-items: center;
`

const ErrorList = styled.div`
  flex-direction: column;
`

const Warning = ({ children, className, long, ...rest }) => (
  <WarningContainer className={className} {...rest}>
    <div>
      <StyledIcon
        long={long}
        icon={faExclamationCircle}
        color={colors.secondaryYellow}
      />
    </div>
    <WarningText>{children}</WarningText>
  </WarningContainer>
)

const Error = ({ children, className, long, simple, small, ...rest }) => {
  if (Array.isArray(children) && children.length > 1) {
    const errors = children
    return (
      <ErrorContainer className={className} data-scroll="error" {...rest}>
        <div>
          <StyledIcon
            simple={simple}
            long={long}
            small={small}
            icon={faTimesCircle}
            color={colors.error}
          />
        </div>
        <ErrorList>
          <ErrorText>We encountered the following problems:</ErrorText>
          {errors.map(err => (
            <InlineError key={err}>{err}</InlineError>
          ))}
        </ErrorList>
      </ErrorContainer>
    )
  } else {
    return (
      <ErrorContainer className={className} data-scroll="error" {...rest}>
        <div>
          <StyledIcon
            simple={simple}
            long={long}
            small={small}
            icon={faTimesCircle}
            color={colors.error}
          />
        </div>
        <ErrorText>{children}</ErrorText>
      </ErrorContainer>
    )
  }
}

const InfoBox = ({ children, className, long, ...rest }) => (
  <InfoContainer className={className} {...rest}>
    <StyledIcon
      small
      long={long}
      icon={faExclamationCircle}
      color={colors.white}
    />
    <InfoText>{children}</InfoText>
  </InfoContainer>
)

export { Warning, Error, InfoBox, WarningContainer }
