import React from 'react'
import { Pill } from 'atoms'

const STATUSES = {
  pending: 'Pending',
  quoted: 'Quoted',
  declination_confirmed: 'Declined',
  declined: 'Declined',
  declined_outdated: 'Declined',
  binding: 'Binder Requested',
  bound: 'Bound',
  issued: 'Policy Issued',
  extracted: 'Extracted from PDF',
  outdated: 'Outdated',
  referred: 'Referred',
}

const StatusPill = ({ status, isOutdated }) => {
  return (
    <Pill
      ml={2}
      mr="auto"
      bg={
        status === 'bound'
          ? 'binder'
          : status === 'issued'
          ? 'certificate'
          : isOutdated ||
            status === 'declined_outdated' ||
            status === 'declination_confirmed'
          ? 'primary'
          : 'quote'
      }
      color="white">
      {STATUSES[status]?.toUpperCase()}
    </Pill>
  )
}

export default StatusPill
