import { setLocale } from 'yup'
import errors from './errors'
import * as yup from 'yup'

import { addStringMethods } from './yup-string-exts'
import { addBooleanMethods } from './yup-boolean-exts'

setLocale(errors)
addStringMethods(yup)
addBooleanMethods(yup)

export * from 'yup'
