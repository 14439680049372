import { useEffect } from 'react'

const usePageVisibility = onChange => {
  useEffect(() => {
    const listener = () => {
      onChange(document.hidden)
    }
    document.addEventListener('visibilitychange', listener)
    return () => {
      document.removeEventListener('visibilitychange', listener)
    }
  })
}

export default usePageVisibility
