import { default as Documents } from './Documents'
import { default as Modal } from './Modal'
import { default as CyberModal } from './CyberModal/CyberModal'
import { default as LocationField } from './CoveredLocation/LocationField/index'
import { default as F1Documents } from './f1/Documents'
import { default as MultiPageReview } from './MultiPageReview'
import { default as FirmUpModal } from './FirmUpModal'
import { default as TitleField } from './TitleField'
import { default as MultiSchemaField } from './MultiSchemaField'
import { default as MultiPageField } from './MultiPageField'
import { default as SimpleCoverageSelector } from './SimpleCoverageSelector'
import { default as MultiInputTypeDropdown } from './MultiInputTypeDropdown'
import SubtitledCheckbox from './SubtitledCheckbox'
import { default as PlacementTermsCheckbox } from 'components/react-json-schema-form/Widgets/CheckboxWidget/PlacementTermsCheckbox'
import { default as Popover } from './Popover'
import InviteCarrierField from 'components/react-json-schema-form/Fields/InviteCarriersField'
import { default as SelectPanelistsField } from 'components/react-json-schema-form/Fields/SelectPanelistsField'
import { default as MultiStepFormField } from 'FlexiForm/fields/MultiStepFormField'

import LabelWithIcon from './LabelWithIcon'
import { Theme as AntdTheme } from '@rjsf/antd'
import { withSideHelperText } from 'FlexiForm/widgets/WithSideHelperText'
import BulkEdit from './BulkEdit'
import SelectSectionCheckbox from './SelectSectionCheckbox'
import DisplayCarrier from './DisplayCarrierField'
import DocumentsReadOnly from './DocumentsReadOnly'
import HiddenField from './HiddenField'
import MultiDropdownSelect from './MultiDropdownSelect'
import {
  SelectAPICarriersField,
  SelectEmailCarriersField,
  SelectExternalCarriersField,
} from './Carriers'
import SelectPanelistsWithAuthentications from 'components/react-json-schema-form/Fields/SelectPanelistsField/FieldWithAuthentication'
import Alert from './Alert'

const { BooleanField: BooleanFieldAntd } = AntdTheme.fields
BooleanFieldAntd.displayName = 'BooleanFieldAntd'

export default {
  modal: Modal,
  cyberModal: CyberModal,
  coveredLocation: LocationField,
  multiPageReview: MultiPageReview,
  firmUpModal: FirmUpModal,
  popover: Popover,
  documents: Documents,
  bulkEdit: BulkEdit,
  TitleField: TitleField,
  BooleanField: withSideHelperText(BooleanFieldAntd),
  OneOfField: MultiSchemaField,
  SimpleCoverageSelector: SimpleCoverageSelector,
  InviteCarrierField: InviteCarrierField,
  panelists: SelectPanelistsField,
  stepper: MultiStepFormField,
  multipage: MultiPageField,
  multiInputTypeDropdown: MultiInputTypeDropdown,
  labelWithIcon: LabelWithIcon,
  placementTermsCheckbox: PlacementTermsCheckbox,
  selectSectionCheckbox: SelectSectionCheckbox,
  displayCarrierField: DisplayCarrier,
  documentsReadOnly: DocumentsReadOnly,
  hidden: HiddenField,
  selectAPICarriersField: SelectAPICarriersField,
  selectEmailCarriersField: SelectEmailCarriersField,
  SelectExternalCarriersField: SelectExternalCarriersField,
  MultiDropdownSelect: MultiDropdownSelect,
  f1Documents: F1Documents,
  SubtitledCheckbox: SubtitledCheckbox,
  panelistsWithAuthentication: SelectPanelistsWithAuthentications,
  alert: Alert,
}
