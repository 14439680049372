import { isNotProd } from './env-util'

export const jsonFormat = (_key, value) => {
  if (typeof value === 'object' && value !== null) {
    return Object.entries(value)
      .sort()
      .reduce((o, [k, v]) => {
        o[k] = v
        return o
      }, {})
  }
  return value
}

export const nonProdConsoleError = exception => {
  if (isNotProd) console.error(exception)
}

export const nonProdConsoleWarn = message => {
  if (isNotProd) console.warn(message)
}

export const nonProdThrowError = message => {
  if (isNotProd) throw message
}
