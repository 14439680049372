import React from 'react'

import { SectionTitle, Text, Table, TR, TH, TD, Column } from 'atoms'
import { money } from 'atoms/$'

const CoveragesTable = ({ coverages, col1Header, col2Header, col1Type }) => {
  return (
    <Table
      header={
        <TR>
          <TH width={col2Header ? '50%' : '75%'} p="0 0.5rem 0 0">
            Name
          </TH>
          <TH p="0 0.5rem 0 0">{col1Header}</TH>
          {col2Header && <TH p="0 0.5rem 0 0">{col2Header}</TH>}
        </TR>
      }>
      {coverages.map((coverage, i) => {
        return (
          <TR key={`quote-${coverage.quote_id}-${i}`}>
            <TD p="0 0.5rem 0 0">{coverage.name}</TD>
            <TD p="0 0.5rem 0 0">
              {col1Type === 'String'
                ? coverage.col1
                : money(coverage.col1, { fallback: '—' })}
            </TD>
            {col2Header && (
              <TD p="0 0.5rem 0 0">
                {money(coverage.col2, { fallback: '—' })}
              </TD>
            )}
          </TR>
        )
      })}
    </Table>
  )
}

const Coverages = ({
  coverages,
  emptyLabel,
  sections,
  col1Header,
  col2Header,
  col1Type,
}) => {
  if (!coverages || coverages.length === 0) return <Text>{emptyLabel}</Text>
  const filterCoverages = type =>
    coverages.filter(coverage => coverage.type === type)

  return (
    <Column>
      {sections.map((section, i) => {
        return (
          <React.Fragment key={`coverage-${i}`}>
            <SectionTitle>{section.title}</SectionTitle>
            <CoveragesTable
              coverages={filterCoverages(section.type)}
              col1Header={col1Header}
              col2Header={col2Header}
              col1Type={col1Type}
            />
          </React.Fragment>
        )
      })}
    </Column>
  )
}

export default Coverages
