import React from 'react'

import { FieldTemplate as OriginalFieldTemplate } from '@rjsf/material-ui'
import { emptySection, emptyArraySection } from './utils'

const FieldTemplate = ({ schema, ...props }) => {
  if (emptyArraySection(schema) || emptySection(schema)) {
    return null
  }

  return <OriginalFieldTemplate schema={schema} {...props} />
}

export default FieldTemplate
