import styled from 'styled-components'

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  max-height: 80%;
  overflow: auto;
  transform: translate(-50%, -50%);
`

export default ModalWrapper
