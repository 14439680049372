import React from 'react'
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { unansweredDataTestId, unansweredStyle } from './utils'
import { useSubmission, usePanel } from 'hooks'
import { Panel } from 'atoms'
import isEqual from 'lodash/isEqual'

const Checkboxes = props => {
  const { id, value, onChange, schema, options } = props
  const notRequiredQuestion = 'Operations include'
  const isUnanswered = !value.length && schema.title !== notRequiredQuestion
  const { firmup = false } = options
  const dataTestId = unansweredDataTestId(isUnanswered, firmup)
  const style = unansweredStyle(isUnanswered, firmup)

  const submission = useSubmission()
  const panel = usePanel()

  const handleChange = event => {
    if (event.target.checked) {
      onChange([...value, event.target.name])
    } else {
      onChange(value.filter(val => val !== event.target.name))
    }
  }

  const showWarning = () => {
    if (options.warningType === 'submitAdditional') {
      const submissionVal = submission?.questions[id.replace('root_', '')]
      const apiUsers = submission.participations.nodes
        .filter(p => p.apiUser)
        .map(p => p.apiUser.id)

      const existingAnswer =
        submissionVal?.length && !isEqual(submissionVal, value)
      const prevUnanswered =
        !submissionVal?.length &&
        !!value.length &&
        (schema.carriers.includes('ALL') ||
          !!panel.panelists.nodes
            .filter(el => apiUsers.includes(el.id))
            .find(el => schema.carriers?.includes(el.firstName.toLowerCase())))

      return !!existingAnswer || !!prevUnanswered
    } else {
      return false
    }
  }

  return (
    <Box flexDirection="column" data-testid={dataTestId} style={style}>
      <Typography variant="body1">{schema.title}</Typography>
      {schema.items.enumNames.map((label, index) => {
        const itemValue = schema.items.enum[index]
        return (
          <Box key={itemValue}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={value.includes(itemValue)}
                  onChange={handleChange}
                  name={itemValue}
                />
              }
              label={label}
            />
          </Box>
        )
      })}
      {showWarning() && (
        <Panel info gradient={25} m="1rem" p="1rem">
          {options.warningMsg}
        </Panel>
      )}

      {/* Add an invisible input to support the form filler extension */}
      <input
        id={id}
        style={{ display: 'none' }}
        value={value}
        onChange={evt => {
          let value = evt.target.value
          if (typeof value === 'string') {
            value = value.split(',')
          }
          onChange(value)
        }}
      />
    </Box>
  )
}

export default Checkboxes
