import React, { useState, useEffect } from 'react'
import arrayMutators from 'final-form-arrays'
import { toast } from 'react-toastify'
import { useHistory, useRouteMatch, Route } from 'react-router-dom'
import { Form } from 'react-final-form'

import AddAnswerModal from 'components/QAndA/Answer/AddAnswerModal'
import EditAnswerModal from 'components/QAndA/Answer/EditAnswerModal'
import QAndA from 'components/QAndA/QAndA'
import { Panel, Column, Text, Subtitle, Loading } from 'atoms'
import { useQuestionsQuery } from 'graphql/api'
import { DetailedUploader } from 'molecules/DocumentUpload'
import { useBranding } from 'hooks'

const AnswererQAndA = ({ submission, questionable }) => {
  const { isNFP } = useBranding()
  const [handleSubmitErrors, setHandleSubmitErrors] = useState(null)
  const { loading, error, data } = useQuestionsQuery({
    variables: {
      questionableId: questionable.id,
      questionableType: questionable.__typename,
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (handleSubmitErrors) {
      history.replace(url)
      toast.error(handleSubmitErrors?.base)
    }
  }, [handleSubmitErrors])

  const history = useHistory()
  const { path, url } = useRouteMatch()

  if (loading) return <Loading />
  if (error) throw error

  const questions = data.qAndA.questionable.questions.nodes
  const documents = submission.documents.nodes || submission.documents
  const noop = () => ({})
  return (
    <Column>
      {isNFP && (
        <>
          <Subtitle>Underwriting Call Agenda</Subtitle>
          <Column mt={1}>
            <Form
              mutators={arrayMutators}
              initialValues={{ documents: documents }}
              onSubmit={noop}>
              {() => (
                <Panel p={2} mb={2}>
                  <DetailedUploader
                    readOnly
                    categories={['Underwriting Call Agenda']}
                    name="documents"
                  />
                </Panel>
              )}
            </Form>
          </Column>
        </>
      )}
      {questions.length === 0 ? (
        <Text>No questions yet.</Text>
      ) : (
        <>
          <Subtitle>Follow-up Q &amp; A</Subtitle>
          <Route path={`${path}/questions/:questionId/answers/:answerId/edit`}>
            <EditAnswerModal
              questions={questions}
              onClose={() => history.replace(url)}
              onCompleted={() => {
                history.replace(url)
                toast.success('Your answer has been saved')
              }}
            />
          </Route>
          <Route path={`${path}/questions/:questionId/answers/new`}>
            <AddAnswerModal
              questions={questions}
              onClose={() => history.replace(url)}
              setHandleSubmitErrors={setHandleSubmitErrors}
              onCompleted={() => {
                history.replace(url)
                toast.success('Your answer has been saved')
              }}
            />
          </Route>
          <QAndA
            questions={questions}
            responder={submission.owner}
            questionable={questionable}
            context="responder"
          />
        </>
      )}
    </Column>
  )
}

export default AnswererQAndA
