import styled from 'styled-components'
import { color, space, layout, flexbox } from 'styled-system'

const Pill = styled.div`
  height: ${({ small }) => (small ? '1rem' : '2rem')};
  border-radius: 1rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: ${({ lineHeight }) => lineHeight || '2rem'};
  display: flex;
  align-items: center;
  justify-items: center;
  vertical-align: center;
  font-weight: 600;
  flex-grow: 0%;
  ${props =>
    props.border && `border: 1px solid ${props.theme.colors[props.color]};`}

  ${space}
  ${color}
  ${layout}
  ${flexbox}
`
Pill.defaultProps = {
  bg: 'link',
  color: 'white',
  px: 1,
  py: '0',
}

export default Pill
