import flatten from 'lodash/flatten'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isPlainObject from 'lodash/isPlainObject'
import last from 'lodash/last'
import set from 'lodash/set'
import startsWith from 'lodash/startsWith'

export const deepKeys = obj => {
  let first = true
  let next = true
  const _depth = []

  while (next) {
    if (first) {
      _depth.push(Object.keys(obj))
      first = false
    }

    const _keys = last(_depth)
    let _halfway = []
    next = false

    _keys.forEach(key => {
      const _halfObj = get(obj, key)

      if (isPlainObject(_halfObj) || isArray(_halfObj)) {
        next = true
        const _arr = Object.keys(_halfObj).map(childKey => `${key}.${childKey}`)
        _halfway.push(_arr)
      } else _halfway.push(key)
    })

    _depth.push(flatten(_halfway))
  }

  return last(_depth)
}

export const compactObject = obj => {
  const _paths = deepKeys(obj)
  const newObj = {}
  _paths.forEach(function (p) {
    const val = get(obj, p)
    if (!isNil(val)) {
      set(newObj, p, val)
    }
  })
  return newObj
}

export const mapObject = (obj, fn) => {
  const _paths = deepKeys(obj)
  const newObj = {}

  _paths.forEach(function (p, i) {
    let key
    let val = get(obj, p)
    const resArr = fn(val, p, i, obj)
    if (isArray(resArr)) {
      if (resArr.length > 1) {
        return set(newObj, resArr[0], resArr[1])
      } else {
        return set(newObj, p, resArr)
      }
    } else if (isPlainObject(resArr)) {
      key = Object.keys(resArr)[0]
      val = resArr[key]
      return set(newObj, key, val)
    } else {
      return set(newObj, p, resArr)
    }
  })

  return newObj
}

export const rejectObject = (obj, fn) => {
  const newObj = mapObject(obj, function (val, p, i, obj) {
    let flag
    if (isFunction(fn)) {
      if (fn(val, p, i, obj)) {
        return null
      } else {
        return val
      }
    } else if (isArray(fn)) {
      flag = fn.some(function (_path) {
        return !!startsWith(p, _path)
      })
      if (flag) {
        return null
      } else {
        return val
      }
    } else {
      return null
    }
  })
  return compactObject(newObj)
}

export const filterObject = (obj, fn) => {
  const newObj = mapObject(obj, function (val, p, i, obj) {
    let flag
    if (isFunction(fn)) {
      if (fn(val, p, i, obj)) {
        return val
      } else {
        return null
      }
    } else if (isArray(fn)) {
      flag = fn.some(function (_path) {
        return !!startsWith(p, _path)
      })
      if (flag) {
        return val
      } else {
        return null
      }
    } else {
      return null
    }
  })
  return compactObject(newObj)
}
