import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
// The above two lines must be the first lines in this file.

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import smoothScroll from 'smoothscroll-polyfill'
import { AppContainer } from 'react-hot-loader'

import App from './App'
import { injectZendesk } from 'util/zendesk'
import { initialize } from 'util/hotjar'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_COMMIT_REF,
    environment: process.env.REACT_APP_ENV,
  })
}

smoothScroll.polyfill()

if (process.env.REACT_APP_ZENDESK_KEY) {
  injectZendesk(process.env.REACT_APP_ZENDESK_KEY)
}

if (process.env.REACT_APP_HOTJAR_SITE_ID) {
  initialize(process.env.REACT_APP_HOTJAR_SITE_ID)
}

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root')
  )
}

render(App)

ReactDOM.render(<App />, document.getElementById('root'))
