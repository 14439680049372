import React from 'react'
import {
  Button,
  Icon,
  Div,
  ButtonContainer,
  Table,
  TR,
  TH,
  TD,
  Text,
} from 'atoms'
import { Field } from 'react-final-form'
import { Dropdown } from 'atoms/form'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'
import { faChevronLeft, faFileImport } from '@fortawesome/pro-regular-svg-icons'
import { required } from 'util/validators'
import { convertToOption } from 'util/forms'
import { Loading } from 'atoms'

const ImportQuestionPreviewForm = ({
  scope = QUESTION,
  questions,
  sections = [],
  onSubmit,
  onBack,
}) => {
  const translator = qandaTranslator(scope)
  const sectionChoices = sections.map(convertToOption)
  let [loading, setLoading] = React.useState(false)

  return (
    <>
      <Table
        mb={2}
        header={
          <TR>
            <TH width="70%" p="0px">
              Question
            </TH>
            <TH width="30%" p="0px 0.5rem">
              Section
            </TH>
          </TR>
        }>
        {(questions || []).map((question, index) => {
          return (
            <TR key={`${question}-${index}`}>
              <TD verticalAlign="center" p="0px">
                <Text>{question}</Text>
                <Field
                  hidden
                  name={`attributes[${index}].text`}
                  required
                  component="input"
                  disabled
                  initialValue={question}
                />
              </TD>
              <TD p="0px">
                <Field
                  name={`attributes[${index}].section`}
                  disabled={loading}
                  required
                  validate={required({
                    message: 'Enter Section',
                  })}
                  component={Dropdown}
                  creatable
                  options={sectionChoices}
                />
              </TD>
            </TR>
          )
        })}
      </Table>

      <ButtonContainer justifyContent="space-between">
        <Button onClick={onBack} light>
          <Icon ml={0} mr={0} icon={faChevronLeft} />
          {translator('editQuestions')}
        </Button>

        {loading ? (
          <Div display="flex" minWidth={148} mt={-25}>
            <Loading />
          </Div>
        ) : (
          <Button
            onClick={() => {
              setLoading(true)
              onSubmit()
            }}
            primary>
            <Icon ml={0} mr={0} icon={faFileImport} />
            {translator('finalizeImport')}
          </Button>
        )}
      </ButtonContainer>
    </>
  )
}

export default ImportQuestionPreviewForm
