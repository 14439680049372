import React, { useState } from 'react'
import styled from 'styled-components'
import {
  faCommentDots,
  faCommentPlus,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'

import Icon from 'components/Icon'
import { Column, Row, Button } from 'atoms'
import { TextInput } from 'atoms/form'
import { useOnClickOutside } from 'hooks'

export const PopUp = styled(Column)`
  padding: 1rem;
  background-color: #fff;
  border-radius: 4px;
  width: 300px;
  margin-left: -145px;
  boxshadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  z-index: 1000;
`

const Comment = props => {
  const [showInput, setShowInput] = useState(false)

  const clearInput = () => {
    setShowInput(false)
    props.input.onChange(null)
  }

  const toggleInput = () => {
    setShowInput(!showInput)
  }

  const close = () => {
    setShowInput(false)
  }

  const commentIcon = (icon, color) => {
    return <Icon color={color} onClick={toggleInput} fontSize="4" icon={icon} />
  }

  const ref = useOnClickOutside(close)

  return (
    <Column ref={ref} position="absolute" top="1rem" ml={2} width="50px">
      <Row>
        {props.input.value
          ? commentIcon(faCommentDots, 'primaryText')
          : props.isCommentRequired
          ? commentIcon(faCommentPlus, 'error')
          : commentIcon(faCommentPlus, 'link')}
      </Row>
      {showInput && (
        <PopUp my="0">
          <Row py="0">
            <TextInput box {...props} />
          </Row>
          <Row justifyContent="space-between" alignItems="center">
            <Icon
              onClick={clearInput}
              fontSize="3"
              icon={faTrash}
              color="error"
            />
            <Button onClick={close} height="40px">
              Add Comment
            </Button>
          </Row>
        </PopUp>
      )}
    </Column>
  )
}

export default Comment
