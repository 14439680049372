import React from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useFieldArray } from 'react-final-form-arrays'
import { space } from 'styled-system'

import { colors } from 'components/theme'

const Container = styled.div`
  width: fit-content;
  cursor: pointer;
  ${space}
`

const Link = styled.span`
  color: ${colors.link};
`

const UploadLink = ({
  className,
  name,
  dropzoneOptions,
  children,
  onUpload,
  ...rest
}) => {
  const { fields } = useFieldArray(name)
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: accepted => onUpload(accepted, fields),
    ...dropzoneOptions,
  })

  return (
    <Container className={className} {...getRootProps()}>
      <input data-testid="file-input" {...getInputProps()} />
      <Link {...rest}>{children}</Link>
    </Container>
  )
}

export default UploadLink
