import React from 'react'
import { toast } from 'react-toastify'
import { useHistory, useRouteMatch, Route } from 'react-router-dom'
import { faFileImport, faEllipsisV } from '@fortawesome/pro-solid-svg-icons'

import AddQuestionModal from 'components/QAndA/Question/AddQuestionModal'
import QAndA from 'components/QAndA/QAndA'
import EditQuestionModal from 'components/QAndA/Question/EditQuestionModal'
import SubjectivitiesEdit from 'cyber/Quotation/SubjectivitiesEdit'
import { useSubjectivitiesQuery } from 'graphql/api'
import { Panel, Flex, Button, Loading, Icon } from 'atoms'
import ImportModal from 'components/QAndA/Import/ImportModal'
import { GenericContextMenu } from 'molecules/ContextMenu'
import { usePanelParticipationQuery } from 'graphql/api'

const RequesterSubjectivity = ({ submission, subjectivitable }) => {
  const participation = usePanelParticipationQuery({
    variables: {
      participationId: subjectivitable.id,
    },
  })

  const { loading, error, data, refetch } = useSubjectivitiesQuery({
    variables: {
      subjectivitableId: subjectivitable.id,
      subjectivitableType: subjectivitable.__typename,
    },
    fetchPolicy: 'cache-and-network',
  })

  const history = useHistory()
  const { path, url } = useRouteMatch()

  if (loading) return <Loading />
  if (error) throw error
  const showManualSubjectivities = participation?.data?.panels?.participation?.submission?.panel?.manualSubjectivities
  const subjectivities = data.qAndA.subjectivitable.subjectivities.nodes
  const addSubjectivityOptions = [
    {
      title: 'Bulk Import Subjectivities',
      icon: faFileImport,
      onClick: () => history.replace(`${url}/subjectivities/import`),
      autoClose: true,
    },
  ]

  return (
    <>
      <Route path={`${path}/subjectivities/:questionId/edit`}>
        <EditQuestionModal
          scope="Subjectivity"
          sections={Array.from(
            new Set(subjectivities.map(subjectivity => subjectivity.section))
          )}
          questions={subjectivities}
          onCompleted={() => {
            history.replace(url)
            toast.success('Your subjectivity has been saved')
          }}
          onClose={() => history.replace(url)}></EditQuestionModal>
      </Route>
      <Route path={`${path}/subjectivities/new`}>
        <AddQuestionModal
          scope="Subjectivity"
          sections={Array.from(
            new Set(subjectivities.map(subjectivity => subjectivity.section))
          )}
          initialValues={{
            ownerId: submission.owner.id,
            questionableId: subjectivitable.id,
            questionableType: subjectivitable.__typename,
            questionableScope: 'Subjectivity',
          }}
          onCompleted={() => {
            history.replace(url)
            toast.success('Your subjectivity has been saved')
          }}
          onClose={() => history.replace(url)}></AddQuestionModal>
      </Route>
      <Route path={`${path}/subjectivities/import`}>
        <ImportModal
          scope="Subjectivity"
          sections={Array.from(
            new Set(subjectivities.map(question => question.section))
          )}
          initialValues={{
            ownerId: submission.owner.id,
            questionableId: subjectivitable.id,
            questionableType: subjectivitable.__typename,
          }}
          onClose={() => history.replace(url)}
          onCompleted={(savedQuestionCount, totalQuestionCount) => {
            refetch().then(() => {
              history.replace(url)
              if (savedQuestionCount == totalQuestionCount) {
                toast.success('Your question has been saved')
              } else {
                toast.error(
                  `${
                    totalQuestionCount - savedQuestionCount
                  } subjectivities failed to save`
                )
              }
            })
          }}
        />
      </Route>
      {subjectivities.length === 0 && !showManualSubjectivities && (
        <>
          <Flex mb={1}>
            <Button
              onClick={() => history.push(`${url}/subjectivities/new`)}
              mr={0}
              ml="auto"
              px={2}
              data-testid="add-subjectivity-top"
              secondary>
              Add Subjectivity
            </Button>
            {addSubjectivityOptions.length > 0 && (
              <GenericContextMenu right options={addSubjectivityOptions}>
                <Button
                  mr={0}
                  minWidth={50}
                  height={50}
                  data-testid="import-context-menu"
                  secondary>
                  <Icon icon={faEllipsisV} fontSize="m" />
                </Button>
              </GenericContextMenu>
            )}
          </Flex>
          <Panel p={2} mb={2}>
            <Flex justifyContent="center">
              <Button
                onClick={() => history.push(`${url}/subjectivities/new`)}
                secondary>
                Add Subjectivity
              </Button>
            </Flex>
          </Panel>
        </>
      )}
      {!showManualSubjectivities ? (
        <QAndA
          asker={subjectivitable.owner}
          questions={subjectivities}
          scope="Subjectivity"
          enableFollowUp={false}
          context="asker"
        />
      ) : (
        <SubjectivitiesEdit
          subId={submission.id}
          subjList={subjectivities}
          subjectivitable={subjectivitable}
        />
      )}
    </>
  )
}

export default RequesterSubjectivity
