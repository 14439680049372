import { PolicyCoverageKind, WorksheetKind, LineOfBusiness } from 'graphql/api'
import { currencyFormatter } from 'util/fieldNormalizers'
import { formatDate, f1DateFormat } from 'util/date'
import currencies from 'data/currencies'

const CASUALTY_EMAIL_SIGNOFF = `You can enter your quote through our digital quote form, or reply directly to this email.
  If we accept your quote, we may need to bind through our online placement system.`

const PROPERTY_EMAIL_SIGNOFF = `You can enter your quote through our digital quote form, or reply directly to this email.`

export const PLACEMENT_TYPE = {
  [WorksheetKind.Insurance]: {
    [LineOfBusiness.Property]: {
      [PolicyCoverageKind.ExcessUmbrella]:
        'insurance for an excess property placement',
      [PolicyCoverageKind.Primary]:
        'insurance for a primary property placement',
    },
    [LineOfBusiness.Casualty]: {
      [PolicyCoverageKind.ExcessUmbrella]:
        'insurance for an excess casualty placement',
      [PolicyCoverageKind.Primary]:
        'insurance for a primary casualty placement',
    },
  },
  [WorksheetKind.Reinsurance]: {
    [LineOfBusiness.Property]: {
      [PolicyCoverageKind.ExcessUmbrella]:
        'reinsurance for an excess property placement',
      [PolicyCoverageKind.Primary]:
        'reinsurance for a primary property placement',
    },
    [LineOfBusiness.Casualty]: {
      [PolicyCoverageKind.ExcessUmbrella]:
        'reinsurance for an excess casualty placement',
      [PolicyCoverageKind.Primary]:
        'reinsurance for a primary casualty placement',
    },
  },
}

export const defaultCommentFor = (worksheetKind, line, coverageKind) => {
  return `Greetings!

This is {{firstName}} {{lastName}} from {{company.name}}. I am looking for ${PLACEMENT_TYPE[worksheetKind][line][coverageKind]}.

The placement details are on Relay, which is our new administration system for managing risk transfers.`
}

export const defaultParticipationFooter = owner => {
  const { fullName, jobTitle, company, phoneNumber } = owner
  return `
<p>Best Regards,</p>
<p><br></p>
<p>${fullName}</p>
<p>${jobTitle ? jobTitle : '[Enter Your Title]'}</p>
<p>${company?.name}</p>
<p>[Enter Your Address]</p>
<p>${phoneNumber ? phoneNumber : '[Enter Your Phone Number]'}</p>
  `
}

export const resetParticipationSubject = ({
  currency,
  insurancePolicyAttributes,
  kind,
  lineOfBusiness,
  totalInsuredValue,
}) => {
  const currObj = currencies[currency]

  const limit = insurancePolicyAttributes?.coveragesAttributes?.length
    ? insurancePolicyAttributes?.coveragesAttributes[0].limit
    : insurancePolicyAttributes?.perOccurrenceLimit || totalInsuredValue

  return limit
    ? `Requesting ${currencyFormatter(limit, currObj)}${
        kind === 'Reinsurance' ? ' reinsurance for ' : ' in '
      }${lineOfBusiness ? lineOfBusiness : ''}`
    : 'New Quote Request'
}

export const defaultParticipationComment = (
  kind,
  lineOfBusiness
) => {
  let htmlString = `<p><span style="color: rgb(240, 102, 102);">[Message will refresh to retrieve data for template below]</span></p>
<p>We are pleased to present the ${lineOfBusiness} ${kind} submission:</p>`

  const synopsis = '<p>Synopsis of placement:</p>'
  const perils = '<li>Perils:</li>'
  const term = '<li>Term:</li>'
  const totalInsured = '<li>Total Insured Values:</li>'
  const deduc = '<li>Deductible:</li>'
  const coverages = '<li>Coverages:</li>'
  const retention = '<li>Retention:</li>'
  const layers = '<li>Requested Limit:</li>'
  const naics = '<li>NAICS:<ul><li> </li></ul></li>'

  htmlString += synopsis
  htmlString += '<ul>'

  if (lineOfBusiness === 'Casualty') {
    if (kind === 'Reinsurance') {
      htmlString = htmlString + term + layers + coverages + naics
    } else {
      htmlString =
        htmlString + term + layers + deduc + retention + coverages + naics
    }
  } else {
    if (kind === 'Reinsurance') {
      htmlString = htmlString + perils + term + totalInsured + layers
    } else {
      htmlString =
        htmlString + perils + term + totalInsured + deduc + layers
    }
  }

  htmlString += '<li>Need by date: </li>'
  htmlString += '</ul>'

  htmlString += `<p>The following documents have been attached:</p>
<ul><li> </li></ul>
<p>${lineOfBusiness === 'Casualty' ? CASUALTY_EMAIL_SIGNOFF : PROPERTY_EMAIL_SIGNOFF}</p>`

  return htmlString
}

export const resetParticipationComment = (
  worksheet,
  isBrokered,
  reinsurerId = null
) => {
  const {
    namedInsured,
    currency,
    description,
    lineOfBusiness,
    contractType,
    kind,
    keyPerils,
    insurancePolicyAttributes,
    reinsuranceEffectiveDate,
    reinsuranceExpiryDate,
    totalInsuredValue,
    reinsuranceRequests,
    industryType,
    riskDocuments,
    insurancePolicy,
    needByDate,
  } = worksheet

  const insurer = insurancePolicyAttributes?.insurer

  const selfInsuredRetention =
    insurancePolicyAttributes?.selfInsuredRetention ||
    insurancePolicy?.selfInsuredRetention

  const coveragesAttributes = insurancePolicyAttributes
    ?.underlyingPoliciesAttributes?.length
    ? insurancePolicyAttributes.underlyingPoliciesAttributes
    : insurancePolicyAttributes?.coveragesAttributes?.length
    ? insurancePolicyAttributes.coveragesAttributes
    : insurancePolicy?.coverages?.nodes

  const deductible = coveragesAttributes?.length
    ? coveragesAttributes[0].deductible
    : null

  const currObj = currencies[currency]

  const perils = keyPerils?.length
    ? `<li>Perils: <strong>${keyPerils
        .map(el => (el.value ? el.value : el))
        .join(', ')}</strong></li>`
    : ''

  const term =
    reinsuranceEffectiveDate && reinsuranceExpiryDate
      ? `<li>Term: <strong>${
          formatDate(reinsuranceEffectiveDate, f1DateFormat) +
          ' - ' +
          formatDate(reinsuranceExpiryDate, f1DateFormat)
        }</strong></li>`
      : ''

  const totalInsured = totalInsuredValue
    ? `<li>Total Insured Values: <strong>${currencyFormatter(
        totalInsuredValue,
        currObj
      )}</strong></li>`
    : ``

  // NOTE: remove for now, reconsider in multitower (insurancePolicy.perOccurrenceLimit)
  // const totalLimit = perOccurrenceLimit
  //   ? `<li>Total Limit Desired: <strong>${currencyFormatter(
  //       perOccurrenceLimit,
  //       currObj
  //     )}</strong></li>`
  //   : ''

  const retention = selfInsuredRetention
    ? `<li>Retention: <strong>${currencyFormatter(
        selfInsuredRetention,
        currObj
      )}</strong></li>`
    : ''

  const layers = layersStr(reinsuranceRequests, currObj, reinsurerId)

  const naics = !industryType?.length
    ? ''
    : industryType.length > 1
    ? `<li>NAICS:</li>${industryType
        .map(el => (el.value ? el.value : el))
        .map(
          value =>
            `<li class="ql-indent-1"><strong>${value.slice(
              0,
              value.indexOf(' ')
            )}</strong>${value.slice(value.indexOf(' '))}</li>`
        )
        .join('')}`
    : `<li>NAICS: ${industryType
        .map(el => (el.value ? el.value : el))
        .map(
          value =>
            `<strong>${value.slice(
              0,
              value.indexOf(' ')
            )}</strong>${value.slice(value.indexOf(' '))}`
        )
        .join('')}</li>`

  const coverages = coveragesAttributes?.length
    ? `<li>Coverages: <strong>${coveragesAttributes
        .map(el => (el.coveragesAttributes ? el.coveragesAttributes[0] : el))
        .map(el => el?.description)
        .join(', ')}</strong></li>`
    : ``

  const deduc = deductible
    ? `<li>Deductible: <strong>${currencyFormatter(
        deductible,
        currObj
      )}</strong></li>`
    : ``

  const quoteBy = needByDate ? `<li>Need by date: <strong>${formatDate(needByDate, f1DateFormat)}</strong></li>` : ''
  const synopsis = description ? `<p>Synopsis of placement: ${description}</p>` : ''
  const line = lineOfBusiness ? lineOfBusiness + ' ' : ''
  const contract = contractType ? contractType + ' ' : ''
  const reOrInsur = kind ? kind + ' ' : ''

  let htmlString = `
    <p>We are pleased to present the ${
      line + contract + reOrInsur
    }submission for ${namedInsured || ''}${
    isBrokered ? ` on behalf of ${insurer ? insurer : ''}` : ''
  }:</p>`

  htmlString += synopsis
  htmlString += '<ul>'

  if (lineOfBusiness === 'Casualty') {
    if (kind === 'Reinsurance') {
      htmlString = htmlString + term + layers + coverages + naics
    } else {
      htmlString =
        htmlString + term + layers + deduc + retention + coverages + naics
    }
  } else {
    if (kind === 'Reinsurance') {
      htmlString = htmlString + perils + term + totalInsured + layers
    } else {
      htmlString =
        htmlString + perils + term + totalInsured + deduc + layers
    }
  }

  htmlString += quoteBy
  htmlString += '</ul>'

  if (riskDocuments?.length) htmlString += documentsStr(riskDocuments)

  htmlString += `<p>${lineOfBusiness === 'Casualty' ? CASUALTY_EMAIL_SIGNOFF : PROPERTY_EMAIL_SIGNOFF} </p>`

  return htmlString
}

const layersStr = (reinsuranceRequests, currObj, reinsurerId = null) => {
  const reinsReqs = reinsuranceRequests?.nodes
    ? reinsuranceRequests.nodes
    : reinsuranceRequests
  const selectedReqs = !reinsurerId
    ? reinsReqs
    : reinsReqs.filter(el => el?.reinsurers?.includes(reinsurerId))

  return !selectedReqs?.length
    ? ''
    : selectedReqs.length > 1
    ? `<li>Requested Layers:</li>${selectedReqs
        .map(
          el =>
            `<li class="ql-indent-1"><strong>${
              currencyFormatter(el.layerLimit, currObj) +
              ' xs ' +
              currencyFormatter(el.attachmentPoint, currObj)
            }</strong>${proposedLayerPremium(el)}</li>`
        )
        .join('')}`
    : selectedReqs[0].attachmentPoint === '0'
    ? `<li>Requested Limit: <strong>${currencyFormatter(
        selectedReqs[0].layerLimit,
        currObj
      )}</strong>${proposedLayerPremium(selectedReqs[0])}</li>`
    : `<li>Requested Layer: <strong>${
        currencyFormatter(selectedReqs[0].layerLimit, currObj) +
        ' xs ' +
        currencyFormatter(selectedReqs[0].attachmentPoint, currObj)
      }</strong>${proposedLayerPremium(selectedReqs[0], currObj)}</li>`
}

const documentsStr = docs => {
  return `<p>The following documents have been attached:</p><ul>${docs
    .map(el => `<li>${el.name}</li>`)
    .join('')}</ul>
  `
}

const proposedLayerPremium = (el, currObj) => {
  if (el.proposedLayerPremium) {
    return `, proposed Layer Premium: <strong>${currencyFormatter(el.proposedLayerPremium, currObj)} (${el.layerPremiumType})</strong>`
  } else {
    return ''
  }
}
