import React from 'react'
import { faClock } from '@fortawesome/pro-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { Popover } from 'antd'

import { $, Text, Icon, Row } from 'atoms'
import { colors } from 'components/theme'

const PremiumRenderer = ({ children: entry, ...rest }) => {
  const disableColor = rest.locked ? { color: '#AAAAAA' } : {}

  const indicationMessage = (
    <Row alignItems="center" ml="1.5rem" color={colors.primaryTextLight}>
      <Icon icon={faExclamationCircle} />
      <Text color="inherit" fontWeight="400" ml="0.3rem">
        Non-bindable indication
      </Text>
    </Row>
  )

  const referredMessage = entry =>
    entry.premium ? (
      <Popover
        trigger="hover"
        content={
          <Text>
            This quote is a non-bindable indication pending underwriter review.
            The quote could change.
          </Text>
        }>
        <Row alignItems="center" ml="1.5rem" color={colors.primaryTextLight}>
          <Icon icon={faClock} />
          <Text color="inherit" fontWeight="400" ml="0.3rem" textOverflow>
            Underwriter review
          </Text>
        </Row>
      </Popover>
    ) : (
      <Row alignItems="center" ml="1.5rem" color={colors.primaryTextLight}>
        <Icon icon={faClock} />
        <Text color="inherit" fontWeight="400" ml="0.3rem" textOverflow>
          Underwriter review
        </Text>
      </Row>
    )
  return (
    <Row fontWeight="700" fontSize="18px">
      <$ fallback="—" {...rest} {...disableColor}>
        {entry?.premium}
      </$>
      {entry?.indication
        ? indicationMessage
        : rest.status === 'referred' && referredMessage(entry)}
    </Row>
  )
}

export default PremiumRenderer
