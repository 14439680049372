import { transparentize } from 'polished'
import global from './global'
import colors from './colors'
import fonts from './fonts'
import amplify from './amplify'

const breaks = {
  lg: {
    min: 'min-width: 1091px',
  },
  med: {
    max: 'max-width: 1090px',
    min: 'min-width: 451px',
  },
  sm: {
    max: 'max-width: 450px',
  },
}

const fontSizes = ['12px', '14px', '16px', '20px', '24px', '36px']
fontSizes.xs = fontSizes[0]
fontSizes.s = fontSizes[1]
fontSizes.m = fontSizes[2]
fontSizes.l = fontSizes[3]
fontSizes.xl = fontSizes[4]
fontSizes.xxl = fontSizes[5]

const spacing = ['0.5rem', '1rem', '2rem', '4rem', '8rem']
spacing.none = '0'

const shadows = {
  large: `5px 5px 20px 0px ${transparentize(0.8, colors.primary)}`,
  small: `0px 2px 12px 0px ${transparentize(0.8, colors.primary)}`,
}

export default {
  colors,
  fonts,
  fontSizes,
  fontWeights: [300, 400, 600],
  breaks,
  space: spacing,
  radii: ['0px', '4px', '8px', '12px', '24px'],
  borderWidths: [1, 2, 4, 8],
  shadows,
}
export { default as MaterialTheme, theme as muiTheme } from './material'

export { global, colors, fonts, amplify }
