import React from 'react'
import styled from 'styled-components'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'

import { Text, Icon, Pill } from 'atoms'
import { Popover } from 'antd'

const StyledPill = styled(Pill)`
  background: darkGray;
  margin-top: 10px;
  height: 20px;
  width: fit-content;
`

const NotEligibleCarrier = ({
  panelist,
  missingWebsite,
  requestingIndication,
  missingEmailMfa,
  missingCorporateNetworkMfa,
  missingRemoteNetworkMfa,
}) => {
  const mfaRequired =
    missingEmailMfa || missingCorporateNetworkMfa || missingRemoteNetworkMfa

  let message = ''
  if (missingWebsite) {
    message = `${panelist.firstName} is not able to quote on any applications that do not have a website.`
  } else if (requestingIndication) {
    message = `${panelist.firstName} is not eligible for quick indication.`
  } else if (mfaRequired) {
    message = `${panelist.firstName} requires the applicant to be  multifactor authenticated.`
  }

  return (
    <Popover placement="top" trigger="hover" content={<Text>{message}</Text>}>
      <StyledPill>
        <Icon icon={faExclamationTriangle} mr={0} />
        Not Eligible
      </StyledPill>
    </Popover>
  )
}

export default NotEligibleCarrier
