import React from 'react'
import { Field } from 'react-final-form'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

import { Button, Icon, Div, Label, ButtonContainer } from 'atoms'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'
import { RichText } from 'atoms/form'

const ImportQuestionForm = ({ scope = QUESTION, onSubmit, onClose }) => {
  const translator = qandaTranslator(scope)

  return (
    <>
      <Div mb={0}>
        <Label>{translator('enterQuestion')}</Label>
      </Div>
      <Field
        textareaStyles={{ height: '33vh' }}
        component={RichText}
        name="text"
        required
        toolbarDisabled={true}
        placeholder="Type or paste in your questions."
        mb={2}
      />
      <ButtonContainer justifyContent="space-between">
        <Button onClick={onClose} light>
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          primary
          data-testid="qanda-import-question-preview">
          {translator('previewImport')}
          <Icon ml={0} icon={faChevronRight} />
        </Button>
      </ButtonContainer>
    </>
  )
}

export default ImportQuestionForm
