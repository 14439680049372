import React, { useMemo } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { EnhancedModal, Subtitle, Div } from 'atoms'
import { useCreateFollowUpQuestionMutation } from 'graphql/api'
import QuestionForm from './QuestionForm'

const AddFollowUpQuestionModal = ({
  initialValues,
  sections,
  onCompleted,
  onClose,
  questions,
}) => {
  const [createFollowUpQuestion] = useCreateFollowUpQuestionMutation()
  const { questionId } = useParams()
  const handleSubmit = values => {
    createFollowUpQuestion({
      variables: {
        input: {
          ...values,
        },
      },
      refetchQueries: ['Questions'],
    }).then(({ data }) => {
      if (data.createFollowUpQuestion.errors) {
        toast.error(data.createFollowUpQuestion.errors)
        return data.createFollowUpQuestion.errors
      } else {
        onCompleted()
      }
    })
  }
  const initialQuestion = questions.find(question => question.id === questionId)
  const initialVals = useMemo(
    () => ({
      initialQuestionId: questionId,
      attributes: {
        section: initialQuestion.section,
      },
      ...initialValues,
    }),
    [initialQuestion.section, questionId, initialValues]
  )

  return (
    <Form
      onSubmit={handleSubmit}
      mutators={arrayMutators}
      initialValues={initialVals}>
      {({ handleSubmit }) => (
        <EnhancedModal onClose={onClose}>
          <Div p={2}>
            <Subtitle>Add Follow-Up Question</Subtitle>
            <QuestionForm
              followUp
              sections={sections}
              onClose={onClose}
              onSubmit={handleSubmit}
              documentCategory="Question"
            />
          </Div>
        </EnhancedModal>
      )}
    </Form>
  )
}

export default AddFollowUpQuestionModal
