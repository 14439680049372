import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from './Button'

const NavBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.align && `justify-content: ${props.align};`} ${Button} {
    width: 200px;
  }
`

const NavBar = ({ align = 'none', children }) => {
  const alignMap = {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center',
    none: 'space-between',
  }

  return <NavBarContainer align={alignMap[align]}>{children}</NavBarContainer>
}

NavBar.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
}

export default NavBar
