import styled from 'styled-components'
import { darken } from 'polished'
import { colors, fonts } from '../theme'

const border = ({ disabled, light, tertiary }) =>
  `${tertiary ? '1px' : '4px'} solid ${
    tertiary
      ? colors.disabled
      : disabled || light
      ? colors.secondaryBlue
      : colors.secondaryYellow
  }`

const ExternalButton = styled.a`
  min-width: 140px;
  height: 50px;
  font-family: ${fonts.secondary};
  font-weight: 600;
  font-size: 16px;
  color: ${colors.primaryTextDark};
  background: ${props =>
    props.tertiary || props.light || props.secondary
      ? 'none'
      : colors.secondaryYellow};
  border: ${props => `${border(props)}`};
  border-radius: 24px;
  box-sizing: border-box;
  outline: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 24px;
  padding: 0 1rem;
  white-space: nowrap;
  flex-shrink: 0;

  ${props =>
    !props.disabled &&
    `
      &:hover {
        background: ${darken(
          0.03,
          props.tertiary || props.light || props.secondary
            ? colors.white
            : colors.secondaryYellow
        )};
        border-color: ${darken(
          0.03,
          props.light
            ? colors.secondaryBlue
            : props.tertiary
            ? colors.disabled
            : colors.secondaryYellow
        )};
      }

      &:active {
        background: ${darken(
          0.05,
          props.light || props.secondary ? colors.white : colors.secondaryYellow
        )};
        border-color: ${darken(
          0.05,
          props.light ? colors.secondaryBlue : colors.secondaryYellow
        )};
      }
  `}
`

export default ExternalButton
