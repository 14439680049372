import React from 'react'
import {
  faShieldAlt,
  faUserFriends,
  faCheckCircle,
  faEnvelope,
  faFileInvoice,
  faHandshake,
  faIdBadge,
} from '@fortawesome/pro-solid-svg-icons'

import SettingsMenuDropdown from './SettingsMenuDropdown'
import { useCurrentUser, usePermission } from 'hooks'
import { isGuest } from 'hooks/currentUser'
import { companyTranslator } from 'util/translations'
import { capitalize } from 'util/string'
import StyledNavLink from 'atoms/StyledNavLink'
import { useFeature } from 'components/common/FeatureSwitch'
import { Plan } from 'types'
import { CompanyKindType } from 'graphql/api'
import { isBMS } from 'hooks/currentUser'

const ManageMenu = () => {
  const currentUser = useCurrentUser()
  const role = companyTranslator({ type: currentUser.company.type })(
    'submission',
    'role'
  )
  if (!currentUser) return null
  const [canManageTeam] = usePermission('manage', 'team')
  const configProposalEnabled = useFeature('configurableProposal')
  const emailQuotingDisabled = useFeature('emailQuotingDisabled')
  const panels = currentUser.company.panels?.nodes.filter(
    panel => panel.lineOfBusiness === 'Cyber'
  )
  const canConfigureProposals = panels?.length > 0 && canManageTeam
  const menuItems = [
    {
      name: 'Team Members',
      icon: faUserFriends,
      to: '/manage/team',
    },
    {
      name: 'Permissions',
      icon: faShieldAlt,
      to: `/manage/team/${currentUser.id}/edit/permissions`,
    },
  ]

  if (currentUser.type !== 'Reinsurer') {
    if (
      currentUser.company?.subscription?.plan !== Plan.Guest &&
      !isGuest(currentUser) &&
      !emailQuotingDisabled
    ) {
      menuItems.splice(2, 0, {
        name: `${currentUser.type !== 'Broker' ? 'Approved' : ''} ${capitalize(
          role
        )}s`,
        to: '/manage/markets',
        icon: faCheckCircle,
      })
    }
    if (!isGuest(currentUser)) {
      menuItems.splice(3, 0, {
        name: 'Submission Intake',
        to: '/manage/company',
        icon: faEnvelope,
      })
    }
    if (configProposalEnabled && canConfigureProposals) {
      menuItems.splice(4, 0, {
        name: 'Client Proposal',
        to: '/manage/proposal',
        icon: faFileInvoice,
      })
    }

    if (currentUser?.company?.kind === CompanyKindType.WholesaleBrokerage) {
      menuItems.push({
        name: 'Retail Brokers',
        icon: faHandshake,
        to: `/manage/retailers`,
      })
    }

    if (
      [
        CompanyKindType.WholesaleBrokerage,
        CompanyKindType.RetailBrokerage,
      ].includes(currentUser?.company?.kind) && !isBMS(currentUser)
    ) {
      menuItems.push({
        name: 'License Information',
        icon: faIdBadge,
        to: `/manage/license-info`,
      })
    }
  }

  return (
    <SettingsMenuDropdown
      testID="manageMenu"
      left
      options={menuItems}
      itemLabelComponent={
        <StyledNavLink to="/manage" onClick={e => e.preventDefault()}>
          Manage
        </StyledNavLink>
      }
    />
  )
}

export default ManageMenu
