import React from 'react'
import styled from 'styled-components'
import unionBy from 'lodash/unionBy'
import { faTrash, faPaperclip } from '@fortawesome/pro-solid-svg-icons'

// TODO: move to atoms
import { FileButton, FileLink } from 'components/common/Uploaders'
import { Icon, Flex, Label, Error } from 'atoms'

const Filename = styled.span`
  flex-grow: 1;
  margin: 0 1rem;
  overflow-wrap: anywhere;
`

const Document = ({ name, remove }) => {
  return (
    <Flex>
      <Icon icon={faPaperclip} />
      <Filename>{name}</Filename>
      <Icon icon={faTrash} onClick={remove} />
    </Flex>
  )
}

export const UploadButton = ({
  input,
  label = 'Upload Document',
  ...props
}) => {
  const onDrop = accepted => {
    input.onChange(unionBy(accepted, input.value, 'name'))
  }

  return (
    <FileButton
      secondary
      onClick={e => e.preventDefault()}
      onDrop={onDrop}
      {...props}>
      {label}
    </FileButton>
  )
}

const Uploader = ({
  input,
  meta,
  label,
  note,
  required,
  multipleFiles,
  ...props
}) => {
  const onDrop = accepted => {
    if (multipleFiles) {
      input.onChange(unionBy(input.value, accepted, 'name'))
    } else {
      input.onChange(accepted)
    }
  }

  const files = [input.value].flat().filter(Boolean)

  if (files.length > 0) {
    return (
      <Flex flexDirection="column" {...props}>
        {label && (
          <Label mb={1} required={required} note={note}>
            {label}
          </Label>
        )}
        {files.map(file => {
          return (
            <Document
              key={file.name}
              name={file.name}
              remove={() => {
                input.onChange(files.filter(f => f.name !== file.name))
              }}
            />
          )
        })}
        {meta.touched && meta.error && <Error>{meta.error}</Error>}
        {multipleFiles && (
          <FileLink mt={1} onDrop={onDrop}>
            Add Another
          </FileLink>
        )}
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" {...props}>
      {label && (
        <Label mb={1} required={required} note={note}>
          {label}
        </Label>
      )}
      <UploadButton input={input} />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </Flex>
  )
}

export default Uploader
