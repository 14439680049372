import { useState } from 'react'

const useToggle = initial => {
  const [state, setState] = useState(initial)

  const show = () => setState(true)
  const hide = () => setState(false)
  const toggle = () => setState(state => !state)

  return [state, toggle, show, hide]
}

export default useToggle
