import React from 'react'
import { faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons'

import { Flex, Label, Error, Icon, IconLink, TagLabel } from 'atoms'
import { RichText } from 'atoms/form'
import { useFeature } from 'components/common/FeatureSwitch'

const RemovableTextArea = ({
  input,
  testId,
  remove,
  initialSize,
  ...style
}) => {
  return (
    <Flex {...style} alignItems="center">
      <RichText
        data-testid={testId}
        initialSize={initialSize}
        input={input}
        meta={{}}></RichText>
      {remove && <Icon ml={1} icon={faTrash} onClick={remove} />}
    </Flex>
  )
}

const MultipleTextArea = ({
  input,
  meta,
  label,
  linkText = 'Add Another',
  appendable = true,
  removable = true,
  children,
  testId,
  hideError = false,
  required = false,
  toolTipContent = null,
  ...style
}) => {
  const enabledRichText = useFeature('richtext')
  const addTextArea = () => {
    const newValue = [...input.value, '']
    return input.onChange(newValue)
  }
  const change = index => event => {
    const newValue = [...input.value]
    enabledRichText
      ? newValue.splice(index, 1, event)
      : newValue.splice(index, 1, event.target.value)
    return input.onChange(newValue)
  }
  const remove = index => () => {
    const newValue = [...input.value]
    newValue.splice(index, 1)
    const change = input.onChange(newValue)
    input.onBlur(input.value)
    return change
  }

  const showError = !hideError && meta.touched && meta.error
  return (
    <Flex flexDirection="column" {...style}>
      {label && (
        <Label required={required} toolTipContent={toolTipContent}>
          <Flex>{label}</Flex>
        </Label>
      )}
      {input.value.map((text, index) => {
        return (
          <Flex mb={1} key={index}>
            <TagLabel>{index + 1}</TagLabel>
            <RemovableTextArea
              testId={testId}
              number={index + 1}
              input={{
                onChange: change(index),
                value: text,
              }}
              remove={removable && remove(index)}
            />
          </Flex>
        )
      })}
      {showError && (
        <Flex mb={1} ml="1.5rem">
          <Error>{meta.error}</Error>
        </Flex>
      )}
      {children}
      {appendable && (
        <IconLink onClick={addTextArea} icon={faPlus}>
          {linkText}
        </IconLink>
      )}
    </Flex>
  )
}
export default MultipleTextArea
