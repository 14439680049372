import React, { useMemo } from 'react'
import styled from 'styled-components'
import intersection from 'lodash/intersection'

import { Column, Row, Subtitle, Panel, Note } from 'atoms'

import { colors } from 'components/theme'
import { useSubmission } from 'hooks'
import usePanel from 'hooks/panel'
import { usePanels } from 'hooks/panels'
import Panelist from './Panelist'
import { useFlexiForm } from 'FlexiForm'
import { getAdditionalCarriers, getPreferredCarriers } from 'cyber/utils'

const GradientRow = styled(Row)`
  background-image: ${({ gradientColor }) =>
    gradientColor || colors.blueGradient};
  border-radius: 0.5rem;
`

const SelectPanelistsField = props => {
  const { formData: selectedPanelists = [], onChange, uiSchema } = props
  const { formData } = useFlexiForm()
  const submission = useSubmission()
  const hookPanel = usePanel() || usePanels()
  let panelists = hookPanel.panelists?.nodes || []
  const initialPanelists = submission?.participations?.nodes
    .filter(p => p.apiUser)
    .map(p => p.apiUser.id)

  // Adds wholesaler name to the carriers that go through wholesaler
  const byWholesaler = hookPanel.panelistConfigurations?.nodes.filter(
    pc => pc.wholesalerCompany
  )
  if (byWholesaler?.length > 0) {
    byWholesaler.forEach(record => {
      const index = panelists.findIndex(p => p.id === record.panelist.id)
      if (index !== -1) {
        panelists.splice(index, 1, {
          wholesaler: record.wholesalerCompany.name,
          ...panelists[index],
        })
      }
    })
  }

  const handleChange = id => () => {
    let checked = selectedPanelists.includes(id)
    let selectedValues
    if (!checked) {
      selectedValues = [...selectedPanelists, id]
    } else {
      selectedValues = selectedPanelists.filter(v => v !== id)
    }
    onChange(selectedValues)
  }

  const preferredCarriers = useMemo(() =>
    getPreferredCarriers(panelists).sort(
      (a, b) => a.company.name.localeCompare(b.company.name),
      [panelists]
    )
  )
  const additionalCarriers = useMemo(() =>
    getAdditionalCarriers(panelists).sort(
      (a, b) => a.company.name.localeCompare(b.company.name),
      [panelists]
    )
  )

  return (
    <Column className="preferred-carriers">
      {preferredCarriers.length > 0 && (
        <>
          <Subtitle m={0}>
            PREFERRED CARRIERS (
            {
              intersection(
                selectedPanelists,
                preferredCarriers.map(x => x.id)
              ).length
            }
            )
          </Subtitle>
          {uiSchema.message && <Note mx={0}>{uiSchema.message}</Note>}

          <GradientRow
            justifyContent={
              preferredCarriers.length > 3 ? 'center' : 'flex-start'
            }
            alignItems="flex-end"
            flexWrap="wrap"
            pt={1}
            pl={1}
            mt={0}>
            {preferredCarriers.map(p => (
              <Panelist
                key={p.id}
                mr={1}
                pb={2}
                firstName={p.firstName}
                lastName={p.lastName}
                id={p.id}
                logo={p.avatarUrl || p.company.logoUrl}
                onChange={handleChange(p.id)}
                checked={selectedPanelists?.includes(p.id)}
                hasNoWebsite={formData?.hasNoWebsite}
                requestingIndication={formData?.questions?.indication}
                mfa={formData?.multiFactorAuthentication || []}
                panelist={p}
                wholesaler={p.wholesaler}
              />
            ))}
          </GradientRow>
        </>
      )}
      {additionalCarriers.length > 0 && (
        <>
          <Subtitle m={0}>
            ADDITIONAL CARRIERS (
            {
              intersection(
                selectedPanelists,
                additionalCarriers.map(x => x.id)
              ).length
            }
            )
          </Subtitle>
          <GradientRow
            justifyContent={
              additionalCarriers.length > 3 ? 'center' : 'flex-start'
            }
            alignItems="flex-end"
            flexWrap="wrap"
            pt={1}
            pl={1}
            mt={0}
            gradientColor={colors.greenGradient}>
            {additionalCarriers.map(p => (
              <Panelist
                key={p.id}
                mr={1}
                pb={2}
                firstName={p.firstName}
                lastName={p.lastName}
                id={p.id}
                logo={p.avatarUrl || p.company.logoUrl}
                onChange={handleChange(p.id)}
                checked={selectedPanelists?.includes(p.id)}
                hasNoWebsite={formData?.hasNoWebsite}
                requestingIndication={formData?.questions?.indication}
                mfa={formData?.multiFactorAuthentication || []}
                panelist={p}
                wholesaler={p.wholesaler}
              />
            ))}
          </GradientRow>
        </>
      )}
      {uiSchema.warning?.type === 'submitAdditional' &&
        initialPanelists &&
        initialPanelists.some(e => !selectedPanelists.includes(e)) && (
          <Panel info gradient={25} m="2rem 1rem 0 1rem" p="1rem">
            {uiSchema.warning.message}
          </Panel>
        )}
    </Column>
  )
}

export default SelectPanelistsField
