import React from 'react'
import { ButtonGroup as Group, Button, FormLabel, Box } from '@material-ui/core'
import { unansweredDataTestId, unansweredStyle } from './utils'

import { useSubmission, usePanel } from 'hooks'
import { Panel } from 'atoms'

const ButtonGroup = ({
  id,
  label,
  value,
  onChange,
  options,
  required,
  schema,
  uiSchema,
}) => {
  const submission = useSubmission()
  const panel = usePanel()
  const styles = uiSchema ? uiSchema['ui:styles'] : {}
  const showWarning = () => {
    if (options.warningType === 'submitAdditional') {
      const submissionVal = submission?.questions[id.replace('root_', '')]

      const existingAnswer = !!submissionVal && submissionVal !== value
      const apiUsers = submission.participations.nodes
        .filter(p => p.apiUser)
        .map(p => p.apiUser.id)
      const prevUnanswered =
        !submissionVal &&
        value &&
        (schema.carriers.includes('ALL') ||
          !!panel.panelists.nodes
            .filter(el => apiUsers.includes(el.id))
            .find(el => schema.carriers?.includes(el.firstName.toLowerCase())))

      return !!existingAnswer || !!prevUnanswered
    } else {
      return false
    }
  }

  const isUnanswered = !value
  const { firmup = false } = options
  const dataTestId = unansweredDataTestId(isUnanswered, firmup)
  const style = unansweredStyle(isUnanswered, firmup)
  const isFirmAnswer = option => {
    return option.value !== 'UN'
  }
  return (
    <Box
      flexDirection="column"
      display="flex"
      data-testid={dataTestId}
      style={style}>
      <Box mb={1}>
        <FormLabel htmlFor={id} required={required} style={styles?.label}>
          {label}
        </FormLabel>
      </Box>
      <Group color="primary">
        {options.enumOptions.map(
          option =>
            (!firmup || (isFirmAnswer(option) && firmup)) && (
              <Button
                key={option.value}
                disableElevation
                variant={value === option.value ? 'contained' : 'outlined'}
                onClick={() => onChange(option.value)}
                color="secondary">
                {option.label}
              </Button>
            )
        )}
      </Group>
      {showWarning() && (
        <Panel info gradient={25} m="1rem" p="1rem">
          {options.warningMsg}
        </Panel>
      )}

      {/* Add an invisible input to support the form filler extension */}
      <input
        id={id}
        style={{ display: 'none' }}
        value={value}
        onChange={evt => {
          onChange(evt.target.value)
        }}
      />
    </Box>
  )
}

export default ButtonGroup
