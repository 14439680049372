import React, { Suspense, lazy } from 'react'

import { Loading } from 'atoms'

const LocationField = lazy(() => import('./LocationField'))

const LazyLocationField = props => {
  return (
    <Suspense fallback={<Loading />}>
      <LocationField {...props} />
    </Suspense>
  )
}

export default LazyLocationField
