import React from 'react'

import { Column, Label, Text } from 'atoms'

const Field = ({ label, value, note, ...rest }) => (
  <Column {...rest}>
    <Label mb={0} note={note}>
      {label}
    </Label>
    <Text>{value}</Text>
  </Column>
)

export default Field
