import React from 'react'
import { ExternalLink, Icon, Row } from 'atoms'
import { faShare } from '@fortawesome/pro-regular-svg-icons'

const LinkRenderer = ({ children, criterion: { title, label } }) => {
  if (!children) return null
  return (
    <ExternalLink target="_blank" href={children} title={label}>
      <Row alignItems="center">
        <Icon mr="5px" icon={faShare} />
        {title || label}
      </Row>
    </ExternalLink>
  )
}

export default LinkRenderer
