import React from 'react'

import { Column, Row, ToggleLink, SectionTitle, Text } from 'atoms'
import Field from 'molecules/Field'
import { Coverages } from 'pages/shared/panels'
import { money } from 'atoms/$'
import { useToggle } from 'hooks'
import DocumentsTable from 'molecules/DocumentUpload/DocumentsTable'

export const getSections = quote => {
  let sections = {}
  quote[0].quotedLines.map(
    line =>
      (sections[line.coverageName] = {
        type: line.type,
        title: line.coverageName,
      })
  )
  return Object.keys(sections)
    .sort()
    .map(key => sections[key])
}

export const getCoverages = quote => {
  let coverages = []
  quote[0].quotedLines.forEach(line => {
    const type = line.type
    line.coverages.forEach(entry => {
      coverages.push({
        ...entry,
        col1: entry.limit,
        col2: entry.retention,
        type: type,
      })
    })
  })
  return coverages
}

const QuoteContent = ({ quotation, submission }) => {
  const [showDetails, toggleDetails] = useToggle(false)
  let quote = submission.quotations.filter(q => q.id == quotation.id)
  const subjectivities = quote[0].subjectivitiesAttributes

  return (
    <>
      <Row mt={2} mb={2}>
        <Field
          label="Premium"
          value={money(quote[0].annualPremium)}
          width="33.3%"
        />
        <Field
          label="Admitted?"
          value={quote[0].admitted ? 'Yes' : 'No'}
          width="33.3%"
        />
      </Row>
      {subjectivities.length > 0 && (
        <Column mb={2}>
          <SectionTitle fontSize={16}>Subjectivities</SectionTitle>
          <Row mb={2}>
            <ul>
              {subjectivities.map((subjectivity, index) => (
                <li key={index}>
                  <Text>{subjectivity.text}</Text>
                </li>
              ))}
            </ul>
          </Row>
        </Column>
      )}

      <Column mb={2}>
        <ToggleLink onClick={toggleDetails}>
          {showDetails ? 'Hide' : 'Show'} Coverages
        </ToggleLink>
        {showDetails && (
          <Row mt={1}>
            <Coverages
              coverages={getCoverages(quote)}
              sections={getSections(quote)}
              emptyLabel="No quoted lines provided."
              col1Header="Limit"
              col2Header="Retention"
            />
          </Row>
        )}
      </Column>
      <DocumentsTable documents={quotation.documents} />
    </>
  )
}

export default QuoteContent
