const worksheetProperties = object => ({ worksheetId: object.id })

const quoteProperties = object => ({
  worksheetId: object.worksheet.id,
  quoteId: object.id,
  discussionId: object.discussion ? object.discussion.id : null,
})

const broadcastProperties = object => {
  const discussable = (
    object.broadcastable.quotes || object.broadcastable.participations
  ).nodes[0]
  return {
    quoteId: discussable.id,
    worksheetId: object.broadcastable.id,
    broadcastId: object.id,
    discussionId: discussable.discussion ? discussable.discussion.id : null,
  }
}

const directMessageProperties = object => ({
  messageId: object.id,
})

const notificationsAnalyticsProvider = entity => {
  if (!entity) return undefined

  switch (entity.__typename) {
    case 'Worksheet':
      return worksheetProperties(entity)
    case 'Quote':
      return quoteProperties(entity)
    case 'Broadcast':
      return broadcastProperties(entity)
    case 'DirectMessage':
      return directMessageProperties(entity)
    default:
      return undefined
  }
}

export default notificationsAnalyticsProvider
