import React from 'react'
import { transparentize } from 'polished'

import { Flex, Label, Error } from 'atoms'
import {
  LazyRSSelect as Select,
  LazyRSCreatable as Creatable,
} from 'atoms/react-select'

import { colors, fonts } from 'components/theme'
import { convertToOption } from 'util/forms'

const defaultStyle = ({ touched, error }) => ({
  control: currentStyle => ({
    ...currentStyle,
    fontFamily: fonts.secondary,
    fontSize: '18px',
    color: colors.primaryText,
    border: '0px',
    borderBottom: `1px solid ${
      touched && error ? colors.error : transparentize(0.7, colors.primaryText)
    }`,
    '&:hover': {
      borderBottom: `1px solid ${colors.primaryText}`,
    },
    borderRadius: 0,
    background: 'transparent',
  }),
  indicatorSeparator: () => {},
  dropdownIndicator: current => ({ ...current, color: '#7D7D86' }),
  menu: current => ({
    ...current,
    zIndex: 1000,
  }),
  singleValue: current => ({
    ...current,
    color: colors.primaryText,
    fontFamily: fonts.secondary,
  }),
})

const Dropdown = ({
  options = [],
  label,
  required,
  note,
  creatable = false,
  input: { name, value, onChange, onBlur, ...otherInputProps },
  meta: { touched, error } = {},
  styles = defaultStyle({ touched, error }),
  afterChange = () => {},
  disabled,
  ...rest
}) => {
  const Component = creatable ? Creatable : Select
  let selected = options.find(option => option.value === value)

  let currentOptions = options
  if (creatable && !selected && value) {
    currentOptions.push(convertToOption(value))
    selected = convertToOption(value)
  }

  return (
    <Flex flexDirection="column" {...rest}>
      {label && (
        <Label htmlFor={name} note={note} required={required}>
          {label}
        </Label>
      )}
      <Component
        creatable
        isDisabled={disabled}
        isSearchable
        inputId={name}
        value={selected}
        onBlur={() => onBlur && onBlur(value)}
        onChange={option => {
          // if dropdown is cleared, the option will be null
          onChange(option && option.value)
          afterChange(option && option.value)
        }}
        {...otherInputProps}
        components={import('react-select').then(({ makeAnimated }) => {
          makeAnimated()
        })}
        options={currentOptions}
        isOptionDisabled={opt => opt.disabled}
        styles={styles}
        {...rest}
      />
      {error && <Error>{touched && error}</Error>}
    </Flex>
  )
}

export default Dropdown
