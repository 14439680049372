import React from 'react'

import { Flex, Label } from 'atoms'

const ThreeColumnTitleRenderer = ({ criterion }) => {
  const basis = 100 * (1 / criterion.titles.length)
  return (
    <Flex>
      {criterion.titles.map((title, index) => {
        return (
          <Flex flexBasis={`${basis}%`} key={`${title}-${index}`}>
            <Label>{title}</Label>
          </Flex>
        )
      })}
    </Flex>
  )
}

ThreeColumnTitleRenderer.labelStyles = {
  fontSize: 'l',
}

export default ThreeColumnTitleRenderer
