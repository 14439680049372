import React from 'react'
import { Link } from 'react-router-dom'
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'

import { Flex, Text, IconButton, Icon } from 'atoms'

export const styleBindButtonRenderer = ({ style, lockable }) => {
  const BindButtonRenderer = ({
    criterion: { to, label, secondary },
    children: entry,
    locked,
    show = true,
  }) => {
    if (!show) return null

    const disabled = lockable && locked
    const indication = entry.indication

    return (
      <Flex justifyContent="center">
        <IconButton
          maxWidth={indication ? '90%' : '50%'}
          width={indication ? '263px' : '120px'}
          icon={disabled ? faLock : ''}
          disabled={disabled}
          secondary={secondary}
          as={Link}
          to={to(entry)}
          tertiary={indication ? true : false}
          style={indication ? { borderColor: '#D2CDC3' } : style}>
          {indication ? (
            <>
              <Icon icon={faQuestionCircle} />
              <Text ml="0.3rem">How do I bind?</Text>
            </>
          ) : (
            <Text>{label}</Text>
          )}
        </IconButton>
      </Flex>
    )
  }
  return BindButtonRenderer
}

export default styleBindButtonRenderer({ style: {}, lockable: true })
