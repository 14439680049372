import _dot from 'dot-object'

// DO NOT import from `util/lodash` as this will cause a circular dependency
// due to the fact that `util/lodash` depends on `dottedUtil`
import isEmpty from 'lodash/isEmpty'
import isRegExp from 'lodash/isRegExp'
import cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'
import set from 'lodash/set'
import uniq from 'lodash/uniq'
import unset from 'lodash/unset'

import { isPresent } from './langUtil'
import { rejectObject } from './objelity'

export const undot = (dottedObj, { separator = '.' } = {}) => {
  if (separator === '.') return _dot.object(dottedObj)

  return new _dot(separator).object(dottedObj)
}

export const dot = (
  obj,
  { separator = '.', keepArray = false, useBrackets = true } = {}
) => {
  _dot.keepArray = keepArray
  _dot.useBrackets = useBrackets

  const dottedObj = _dot.dot(obj)
  if (separator === '.') return dottedObj

  return Object.entries(dottedObj).reduce((memo, [_key, value]) => {
    const key = _key.replace(/\./g, separator)
    return { ...memo, [key]: value }
  }, {})
}

export const dottedKeys = (
  obj,
  { ignoreArrayIndexKeys = false, separator = '.' } = {}
) => {
  const keys = Object.keys(dot(obj, { separator }))

  return uniq(
    keys.map(dottedKey =>
      ignoreArrayIndexKeys ? dottedKey.replace(/(\[\d+])/g, '.*') : dottedKey
    )
  )
}

export const dottedOmit = (obj, keys) => {
  const objPaths = dottedKeys(dot(obj))
  const pathsToOmit = objPaths
    .filter(path => keys.includes(path) || matchesSearchPaths(path, keys))
    .map(path =>
      path
        .split(/\[(\d+)]/g)
        .join('.')
        .replace(/\.\./g, '.')
        .replace(/\.$/, '')
    )

  return rejectObject(cloneDeep(obj), pathsToOmit)
}

const searchInPathRegex = search => new RegExp(`^\\b${search}\\b`)

export const matchesSearchPaths = (path, pathsToSearch) => {
  const matches = pathsToSearch.filter(
    search =>
      (isRegExp(search) && search.test(path)) ||
      path.match(searchInPathRegex(search))
  )

  return isPresent(matches)
}

export const dottedPick = (obj, keys) => {
  const objPaths = dottedKeys(obj)

  const pathsToKeep = objPaths.filter(
    path => keys.includes(path) || matchesSearchPaths(path, keys)
  )
  return pick({ ...obj }, pathsToKeep)
}

export const dottedTransformKeys = (obj, transformKeys = {}) => {
  if (isEmpty(transformKeys)) return obj

  const mutableData = { ...obj }
  const pickedObj = dottedPick(dot(mutableData), Object.keys(transformKeys))
  Object.entries(pickedObj).forEach(([dottedPath, value]) => {
    const path = transformKeys[dottedPath]
    set(mutableData, path, value)
    unset(mutableData, dottedPath)
  })

  return mutableData
}
