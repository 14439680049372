import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export default function notificationAllActionedMutation() {
  return graphql(
    gql`
      mutation notificationAllActioned($input: NotificationAllActionedInput!) {
        notificationAllActioned(input: $input) {
          user {
            id
            notificationEvents(first: 20) {
              unreadCount
              nodes {
                id
                status
              }
            }
          }
          errors
        }
      }
    `,
    {
      props: ({ mutate }) => ({
        notificationAllActioned: () =>
          mutate({
            variables: { input: {} },
          }),
      }),
    }
  )
}
