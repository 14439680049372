const custom = {
  invalid_domain_name: 'Invalid URL',
  invalid_zip_code: 'Invalid zip code',
  invalid_naics_code: 'Invalid naics code',
  terms_accepted: 'Terms must be accepted',
  form_submitted: 'Underwriting Questionnaire should be filled',
}

export default {
  ...custom,
  mixed: {
    required: 'Is required',
    oneOf: 'Must be one of the following values: {{values}}',
    notOneOf: 'Must not be one of the following values: {{values}}',
    notType: 'Must be a {{type}}',
  },
  string: {
    length: 'Must be exactly {{length}} characters',
    min: 'Must be at least {{min}} characters',
    max: 'Must be at most {{max}} characters',
    matches: 'Must match the following: "{{regex}}"',
    email: 'Must be a valid email',
    url: 'Must be a valid URL',
    trim: 'Must be a trimmed string',
    lowercase: 'Must be a lowercase string',
    uppercase: 'Must be a upper case string',
  },
  number: {
    min: 'Must be greater than or equal to {{min}}',
    max: 'Must be less than or equal to {{max}}',
    lessThan: 'Must be less than {{less}}',
    moreThan: 'Must be greater than {{more}}',
    notEqual: 'Must be not equal to {{notEqual}}',
    positive: 'Must be a positive number',
    negative: 'Must be a negative number',
    integer: 'Must be an integer',
  },
  boolean: {},
  object: {
    noUnknown: 'Field cannot have keys not specified in the object shape',
  },
  array: {
    min: 'Must have at least {{min}} items',
    max: 'Must have less than or equal to {{max}} items',
  },
  date: {
    invalid_date_past_days_in_future:
      "Can't be more than {{days}} days in the future",
    invalid_date_in_past: "Can't be a date in the past",
  },
}
