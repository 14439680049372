import React from 'react'

import Filename from './Filename'
import { CategoryPill } from './Category'
import { Flex, Table, TR, TH, TD, Text, DateString } from 'atoms'

const DocumentsTable = ({ documents, children }) => {
  if (!documents || documents.length === 0) {
    return <Text>No documents have been uploaded</Text>
  }
  return (
    <Table
      header={
        <TR>
          <TH p="0px" color="primaryTextMid" minWidth="35%">
            DOCUMENT
          </TH>
          <TH p="0px" color="primaryTextMid">
            TYPE
          </TH>
          <TH p="0px" color="primaryTextMid" width="10%">
            VERSION
          </TH>
          <TH p="0px" color="primaryTextMid" width="15%">
            WHEN
          </TH>
        </TR>
      }>
      {documents.map((document, index) => {
        if (document._destroy) return null
        return (
          <TR key={index}>
            <TD p="0px" overflow="hidden">
              <Filename document={document} />
            </TD>
            <TD p="0px">
              <Flex>
                <CategoryPill document={document} />
              </Flex>
            </TD>
            <TD p="0px">
              <Text>{document.version}</Text>
            </TD>
            <TD p="0px">
              <DateString date={document.createdAt} />
            </TD>
          </TR>
        )
      })}
      {children}
    </Table>
  )
}

export default DocumentsTable
