import styled from 'styled-components'

const Legend = styled.p`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 12px;
  color: ${props => props.theme.colors.primary};
  align-self: ${props => (props.side === 'left' ? 'flex-start' : 'flex-end')};
`

export default Legend
