import React from 'react'
import styled from 'styled-components'
import { Flex, Label, Text } from 'atoms'

const Preformatted = styled(Text)`
  white-space: pre-wrap;
`

const SummaryField = ({
  show = true,
  name,
  children,
  fallback = '-',
  ...rest
}) =>
  show ? (
    <Flex flexDirection="column" {...rest}>
      <Label>{name}</Label>
      <Preformatted>{children || fallback}</Preformatted>
    </Flex>
  ) : null

export default SummaryField
