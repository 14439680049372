import React from 'react'
import * as Sentry from '@sentry/react'

import PillRenderer from './PillRenderer'
import {
  faCheckCircle,
  faClock,
  faUserTie,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { QUOTE_STATUSES } from 'panels/constants'

const constructReferralPhrase = carrier => {
  switch (carrier) {
    case 'Corvus':
    case 'Coalition':
    case 'CFC':
    case 'Wingman':
      return 'An underwriter will review your request within 4–24 hours. Your underwriter will reach out for any additional information required.'
    case 'At-Bay':
      return 'An underwriter will review your request within 4–48 hours. For a faster response, forward supplemental applicant information to your underwriter.'
    case 'Chubb':
      return 'An underwriter will review your request within 24-48 hours. Your underwriter will reach out for any additional information required.'
    case 'Cowbell':
      Sentry.captureException(`Cowbell has been "referred" to underwriter`)
      return 'Referred to Underwriter'
    default:
      return 'Referred to Underwriter'
  }
}

const StatusRenderer = ({ children: quotation }) => {
  const getCriterion = status => {
    const criteriaIssued = {
      status: 'Issued',
      icon: faCheckCircle,
      fg: 'white',
      bg: 'certificate',
      showIf: ({ context }) => context.issued,
    }

    const criteriaReferred = {
      status: constructReferralPhrase(quotation.carrier),
      icon: faUserTie,
      fg: 'primaryText',
      bg: 'white',
      border: true,
      height: 'fit-content',
      lineHeight: 'normal',
      showIf: ({ context }) => context.referred,
    }

    const criteriaBound = {
      status: 'Bound',
      icon: faCheckCircle,
      fg: 'white',
      bg: 'binder',
      showIf: ({ context }) => context.bound,
    }

    const criteriaBinding = {
      status: 'Requested to Bind',
      icon: faClock,
      fg: 'primaryText',
      bg: 'white',
      border: true,
      showIf: ({ context }) => context.binding,
    }

    const criteriaOutdated = {
      status: 'Outdated',
      icon: faTimesCircle,
      fg: 'primaryText',
      bg: 'white',
      border: true,
      showIf: ({ context }) => context.outdated,
    }

    switch (status) {
      case QUOTE_STATUSES.binding:
        return criteriaBinding
      case QUOTE_STATUSES.bound:
        return criteriaBound
      case QUOTE_STATUSES.issued:
        return criteriaIssued
      case QUOTE_STATUSES.referred:
        return criteriaReferred
      case QUOTE_STATUSES.outdated:
        return criteriaOutdated
      default:
        return null
    }
  }

  let criterion = getCriterion(quotation.status)
  if (!criterion) return <></>
  return (
    <PillRenderer criterion={{ ...criterion, ...{ show: () => true } }}>
      {quotation}
    </PillRenderer>
  )
}

export default StatusRenderer
