import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  EnhancedModal,
  Title,
  Text,
  IconButton,
  Button,
  ButtonContainer,
  Column,
  Row,
} from 'atoms'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { useOnClickOutside, usePanel, useFeature } from 'hooks'
import arrayMutators from 'final-form-arrays'
import { Form, Field as FormField } from 'react-final-form'
import SimpleUploader from 'molecules/DocumentUpload/SimpleUploader'
import { required } from 'util/validators'
import { TextInput } from 'atoms/form'
import { ShowConfig } from 'pages/shared/panels'

const BrokerBindModal = () => {
  const history = useHistory()
  const ref = useOnClickOutside(() => history.goBack())
  const panel = usePanel()
  const { submissionId, quoteId, ...params } = useParams()
  const panelId = params.panelId || panel.id
  const isMultiline = useFeature('multilineCyber')

  const lineOfBusiness =
    isMultiline && panel.lineOfBusiness === 'Cyber'
      ? 'Multiline Cyber'
      : panel.lineOfBusiness

  const config = ShowConfig.config(lineOfBusiness)

  const { bindQuote } = config.mutations

  const [bindEmailQuote] = bindQuote.mutation({
    onCompleted: data => {
      const error = bindQuote.getError(data)
      const returnPath = isMultiline
        ? `/panels/submissions/${submissionId}`
        : `/panels/${panelId}/submissions/${submissionId}`
      if (error) {
        toast.error(error)
        history.push(returnPath)
      } else {
        toast.success('Bound')
        history.push(returnPath)
      }
    },
  })

  const handleBind = formValues => {
    bindEmailQuote(
      bindQuote.getVariables({
        quotationId: quoteId,
        PolicyNumber: formValues['policyNumber'],
        documents: formValues['documents'],
      })
    )
  }

  return (
    <EnhancedModal ref={ref} bg="white" showExit={false} light>
      <Form mutators={arrayMutators} onSubmit={handleBind}>
        {({ handleSubmit }) => (
          <Column height="100%" justifyContent="space-between">
            <Title fontWeight={2} mb={2} mt="none">
              Received Binding Documents?
            </Title>
            <Row>
              <Text fontWeight={2} fontSize="m">
                If the carrier has sent you binding documentation, you can
                upload it below and set the status of the quote to bound.
              </Text>
            </Row>
            <Row mt={2}>
              <SimpleUploader
                name="documents"
                mb={1}
                required
                validate={required()}
                defaultCategory="Binder"
                canBeBlank={false}
              />
            </Row>
            <Row mt={1}>
              <Column width="50%">
                <FormField
                  name="policyNumber"
                  placeholder="Policy Number"
                  component={TextInput}
                />
              </Column>
            </Row>
            <ButtonContainer mt={3}>
              <Button tertiary onClick={history.goBack}>
                Cancel
              </Button>
              <IconButton icon={faCheckCircle} onClick={handleSubmit}>
                <Text>Done</Text>
              </IconButton>
            </ButtonContainer>
          </Column>
        )}
      </Form>
    </EnhancedModal>
  )
}

export default BrokerBindModal
