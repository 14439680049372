import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Loading, Text } from 'atoms'
import {
  ShowLayoutWrapper,
  ReinsurerShow,
  BrokerShow,
} from 'pages/shared/panels'

const ShowLayout = props => {
  const { config, userType } = props
  const { participationQuotations } = config.queries
  const { getSubmission } = config.getters
  const participationId = props.participationId || useParams().participationId

  const { loading, error, data } = participationQuotations.query({
    variables: { participationId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const { emailQuote } = config.mutations
  const [reinstateEmailQuotes, { loading: mutationLoading }] =
    emailQuote.mutation()

  const { pathname: url } = useLocation()

  useEffect(() => {
    if (/valid$/.test(url)) {
      reinstateEmailQuotes({ variables: { input: { participationId } } })
    }
  }, [])

  if (error) throw error
  if (loading || mutationLoading) {
    return <Loading />
  }

  const participation = participationQuotations.getParticipation(data)
  const submission = getSubmission(participation)
  const broker = participationQuotations.getBroker(participation)
  const carrier = participationQuotations.getCarrier(participation)

  const Wrapper = ({ Component }) => (
    <ShowLayoutWrapper
      {...props}
      participation={participation}
      submission={submission}
      broker={broker}
      carrier={carrier}
      Component={Component}
    />
  )
  switch (userType) {
    case 'broker':
      return <Wrapper Component={BrokerShow} />
    case 'reinsurer':
      return <Wrapper Component={ReinsurerShow} />
    default:
      return <Wrapper Component={() => <Text>Unknown userType</Text>} />
  }
}

export default ShowLayout
