import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { space } from 'styled-system'

import NavBar from 'components/common/NavBar'
import { colors, fonts } from 'components/theme'

const Exit = styled.a`
  font-family: ${fonts.secondary}};
  font-size: 18px;
  color: ${colors.primary};
  text-decoration: none;
  height: 20px;
  &:visited {
    color: ${colors.primary};
  }
  cursor: pointer;

  ${space}
`

const PageNav = ({ history, label = 'Back to main', to, ...rest }) => (
  <NavBar>
    <Exit
      onClick={() =>
        to
          ? history.push(to)
          : history.length > 1
          ? history.goBack()
          : history.push('/')
      }
      {...rest}>
      &#10094; {label}
    </Exit>
  </NavBar>
)

PageNav.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }),
}

export default compose(withRouter)(PageNav)
