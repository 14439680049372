import React, { useState, useEffect, useMemo } from 'react'

import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import currencies from 'data/currencies'
import { formatter, normalizer } from 'util/currency'

const PARTIAL_CENTS = precision => new RegExp(`\\.[0-9]{0,${precision - 1}}0?$`)
const N_DECIMAL_PLACES = n => new RegExp(`\\.[0-9]{${n},}$`)

const emptyArray = []

const CurrencyDropdown = ({
  id,
  currency: _currency = 'USD',
  rawErrors = emptyArray,
  value,
  label,
  required,
  onChange,
  onBlur,
  uiSchema: { 'ui:options': enumOptions },
}) => {
  const currency = currencies[_currency || 'USD']

  const formattedValue = formatter(value, currency)

  const [error, setError] = useState(rawErrors[0])
  const options = useMemo(
    () =>
      (enumOptions || []).map(amount => {
        return {
          value: normalizer(amount),
          label: formatter(amount),
        }
      }),
    [enumOptions]
  )

  useEffect(() => {
    if (rawErrors?.length > 0) {
      setError(rawErrors)
    } else {
      setError('')
    }
  }, [rawErrors])

  const parseMoney = value => {
    if (N_DECIMAL_PLACES(currency.precision + 1).test(value)) return undefined
    const normalized = normalizer(value)
    if (normalized.length < 18) {
      onChange(normalized)
      if (normalized.length > 14) {
        // Show a soft error to the user warning them that the number is most
        // likely incorrect due to it's magnitude.
        setError('The value is too large')
      } else {
        setError('')
      }
      if (PARTIAL_CENTS(currency.precision).test(value)) {
        return value
      } else {
        return formatter(value, currency)
      }
    } else {
      // If the number is 20 digits or more we just stop accepting input. The
      // backend will store 20 digit value without error so autosave will
      // continue working.
      setError('The value is too large')
      return
    }
  }

  const filterOptions = (options, { inputValue }) => {
    const val = `${parseInt(normalizer(inputValue) || value)}`
    if (isNaN(val)) return options

    const result = options.filter(option => option.value.startsWith(val))
    return result
  }

  const handleChange = (_event, newValue) => {
    if (typeof newValue === 'string') {
      parseMoney(newValue)
    }
  }

  const handleInputChange = (_event, value) => {
    parseMoney(value)
  }

  const handleEnterPressed = event => {
    // TODO: Investigate whether react-window can automatically open up to
    // previously selected value - there is a small bug where the user has to
    // mouseOver the list if the previous value is outside of the original
    // "window", otherwise there is no textValue as there is no element with
    // data-focus=true

    if (event.key == 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      const textValue =
        document.querySelectorAll('[data-focus=true]')[0]?.innerText

      if (textValue === undefined) return

      const selectedValue = options.find(
        opt => opt.value === textValue || opt.label === textValue
      )
      parseMoney(selectedValue?.value)
    }
  }

  return (
    <Autocomplete
      id={id}
      freeSolo
      renderInput={params => (
        <TextField
          error={!!error}
          required={required}
          {...params}
          label={label}
        />
      )}
      renderOption={option => option.label}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.label
      }
      filterOptions={filterOptions}
      onKeyUp={handleEnterPressed}
      options={options}
      value={formattedValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onBlur={onBlur}
    />
  )
}

export default CurrencyDropdown
