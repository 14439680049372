import React from 'react'
import styled from 'styled-components'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { Icon } from 'atoms'

const Loader = styled.div`
  display: flex;
  > div:nth-of-type(1) {
    animation: fadeInOut 2s ease 0s infinite;
  }
  > div:nth-of-type(2) {
    animation: fadeInOut 2s ease 0.1s infinite;
  }
  > div:nth-of-type(3) {
    animation: fadeInOut 2s ease 0.2s infinite;
  }

  @keyframes fadeInOut {
    0%,
    10%,
    60%,
    100% {
      opacity: 0;
    }

    20%,
    50% {
      opacity: 1;
    }
  }
`

const SmallLoading = () => (
  <Loader data-testid="loading">
    <Icon icon={faCircle} ml="0.5em" color="grey" fontSize="10px" />
    <Icon icon={faCircle} ml="0.5em" color="grey" fontSize="10px" />
    <Icon icon={faCircle} ml="0.5em" color="grey" fontSize="10px" />
  </Loader>
)

export default SmallLoading
