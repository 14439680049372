import React from 'react'
import Button from 'components/common/Button'
import styled from 'styled-components'
import { rgba } from 'polished'

const StyledButton = styled(Button)`
  border-radius: 5px;
  box-shadow: 0px 2px 12px 0px ${rgba(0, 0, 0, 0.24)};
  background-color: ${props => props.theme.colors.primaryText};
  border-color: ${props => props.theme.colors.primaryText};
  color: ${props => props.theme.colors.white};
  cursor: default;
`
const BlackButton = ({ children }) => (
  <StyledButton danger>{children}</StyledButton>
)
export default BlackButton
