import React from 'react'
import { Form, Field } from 'react-final-form'
import { toast } from 'react-toastify'
import arrayMutators from 'final-form-arrays'
import { faClipboard } from '@fortawesome/pro-regular-svg-icons'

import { EnhancedModal, Subtitle, Div, Icon, Button } from 'atoms'
import { TextInput } from 'atoms/form'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'

const ShareModal = ({ scope = QUESTION, shareableLink, onClose }) => {
  const translator = qandaTranslator(scope)
  const textFieldRef = React.useRef(null)

  const copyToClipboard = e => {
    textFieldRef.current.select()
    document.execCommand('copy')
    e.target.focus()
    toast.success(`${translator('copied')}`)
  }

  return (
    <Form onSubmit={() => {}} mutators={arrayMutators}>
      {() => (
        <EnhancedModal onClose={onClose}>
          <Div p={2}>
            <Subtitle>{translator('shareQuestion')}</Subtitle>
            <Field
              ref={textFieldRef}
              name="token"
              initialValue={`${shareableLink}`}
              readOnly
              component={TextInput}
            />
            <Button ml="auto" mt={1} onClick={copyToClipboard} primary>
              <Icon mr={0} icon={faClipboard} />
              {translator('copyLink')}
            </Button>
          </Div>
        </EnhancedModal>
      )}
    </Form>
  )
}

export default ShareModal
