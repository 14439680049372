import Applicant from './Applicant'
import QuoteContent from './QuoteContent'
import QuoteSummary from './QuoteSummary'
import BindContent from './BindContent'

import {
  useCyberQuotationQuery,
  useCyberReinstateEmailQuotesMutation,
  useCyberDeclineQuoteMutation,
  useCyberBindQuoteMutation,
  useCyberIssuePolicyMutation,
  useCyberRequestToBindMutation,
} from 'graphql/api'
import { queryDefaults } from '../utils'

const queries = {
  participationQuotations: {
    ...queryDefaults.participationQuotations,
    query: useCyberQuotationQuery,
    getParticipation: data => data.viewer.user.participation,
  },
}

const mutations = {
  emailQuote: {
    mutation: useCyberReinstateEmailQuotesMutation,
  },
  declineQuote: {
    mutation: useCyberDeclineQuoteMutation,
  },
  issuePolicy: {
    mutation: useCyberIssuePolicyMutation,
    getError: data => data.cyberIssuePolicy.errors,
  },
  bindQuote: {
    mutation: useCyberBindQuoteMutation,
    getError: data => data.cyberBindQuote.errors,
  },
  requestToBind: {
    mutation: useCyberRequestToBindMutation,
    getError: data => data.cyberRequestToBind.error,
  },
}

const getters = {
  refetchQueries: ['CyberQuotation'],
}

const components = {
  ApplicantContent: Applicant,
  QuoteContent: QuoteContent,
  QuoteSummary: QuoteSummary,
  BindContent: BindContent,
}

const config = {
  queries,
  mutations,
  getters,
  components,
}

export default config
