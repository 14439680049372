import { useEffect, useState } from 'react'
import sortBy from 'lodash/sortBy'

import { QUOTE_STATUSES } from 'panels/constants'
import { usePanel } from 'hooks'
import { useFeature } from 'components/common/FeatureSwitch'

export const TERRORISM_CARRIERS = ['Aegis']
export const MULTILINE_CYBER_CARRIERS = ['CFC']

const STATUS_ORDER = {
  referred: 1,
  quoted: 1,
  pending: 2,
  declined: 3,
  failed: 4,
}

const filterDeclinedNonCarrierQuotations = quotations => {
  let res = []
  quotations.forEach(quotation => {
    let existing = res.find(q => q.participationId == quotation.participationId)
    if (
      quotation.status != QUOTE_STATUSES.declined ||
      quotation.isApi ||
      quotation.externalCarrier ||
      !existing
    ) {
      res.push(quotation)
    }
  })
  return res
}

const filterArchivedQuotations = submission => {
  let res = []
  submission.quotations.forEach(quotation => {
    const participation = submission.data?.participations?.nodes.find(
      participation => participation.id === quotation.participationId
    )
    if (!quotation.archivedAt && !participation?.archivedAt) {
      res.push(quotation)
    }
  })
  return res
}

const useFilterQuotes = submission => {
  const [filter, setFilter] = useState({})
  const [filteredQuotations, setFilteredQuotations] = useState([])
  const [totalQuotations, setTotalQuotations] = useState([])
  const [filteredCoverageMaps, setfilteredCoverageMaps] = useState([])
  const panel = usePanel()
  const isMultiline = useFeature('multilineCyber')

  useEffect(() => {
    let quotations = filterArchivedQuotations(submission)

    quotations = quotations.map(quotation => {
      if (!quotation.dynamic) return quotation

      const generalLiability =
        quotation.generalLiability && JSON.parse(quotation.generalLiability)
      const generalLiabilityPremium =
        quotation.generalLiabilityPremium &&
        JSON.parse(quotation.generalLiabilityPremium)
      const overallPropertyPremium =
        quotation.overallPropertyPremium &&
        JSON.parse(quotation.overallPropertyPremium)
      const property = quotation.property && JSON.parse(quotation.property)

      return {
        ...quotation,
        generalLiability,
        generalLiabilityPremium,
        overallPropertyPremium,
        property,
      }
    })

    if (quotations) {
      quotations = sortBy(quotations, [
        quote => STATUS_ORDER[quote.status?.toLowerCase()] || 0,
        quote => parseInt(quote.limit || Number.MAX_SAFE_INTEGER),
        quote => parseInt(quote.deductible || Number.MAX_SAFE_INTEGER),
        quote => parseInt(quote.totalCost || Number.MAX_SAFE_INTEGER),
      ])

      quotations = filterDeclinedNonCarrierQuotations(quotations)
      if (panel?.lineOfBusiness === 'Terrorism') {
        quotations = quotations.filter(quote =>
          TERRORISM_CARRIERS.includes(quote.carrier)
        )
      } else if (isMultiline && panel?.lineOfBusiness === 'Cyber') {
        quotations = quotations.filter(quote =>
          MULTILINE_CYBER_CARRIERS.includes(quote.carrier)
        )
      }

      // If the quote goes through a wholesaler, the wholesaler logo is displayed
      let filtered = quotations.map(quotation => {
        // Quotation does not have carrier id, so it checks for name instead
        const carrierData = panel?.panelistConfigurations?.nodes.find(
          p => p.panelist.firstName === quotation.carrier
        )

        return {
          underWholesaler: !!carrierData?.wholesalerCompany,
          wholesalerLogoUrl: carrierData?.wholesalerCompany?.logoUrl,
          wholesalerCompanyId: carrierData?.wholesalerCompany?.id,
          wholesalerCompanyName: carrierData?.wholesalerCompany?.name,
          ...quotation,
        }
      })

      if (filter.limit) {
        filtered = filtered.filter(quote => quote.limit === filter.limit)
      }

      if (filter.deductible) {
        filtered = filtered.filter(
          quote => quote.deductible === filter.deductible
        )
      }

      filtered.sort((a, b) => b.currentPolicy - a.currentPolicy)

      let coverageMaps = filtered.reduce((coverageMaps, quote) => {
        if (quote.coverages)
          coverageMaps[quote.id] = quote.coverages.reduce(
            (coverageMap, coverage, index) => {
              if (coverage.name) coverageMap[coverage.name] = index
              return coverageMap
            },
            {}
          )
        return coverageMaps
      }, {})

      setFilteredQuotations(filtered)
      setTotalQuotations(quotations)
      setfilteredCoverageMaps(coverageMaps)
    }
  }, [filter, submission.quotations])

  return {
    filter,
    setFilter,
    filteredQuotations,
    totalQuotations,
    filteredCoverageMaps,
  }
}
export default useFilterQuotes
