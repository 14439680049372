import React from 'react'

import { FileButton } from 'components/common/Uploaders'

const UploadButton = ({ onUpload, label = 'Upload Document', ...props }) => {
  return (
    <FileButton
      secondary
      onClick={e => e.preventDefault()}
      onDrop={onUpload}
      {...props}>
      {label}
    </FileButton>
  )
}

export default UploadButton
