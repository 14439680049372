import styled, { css } from 'styled-components'

import { colors, fonts } from './index'

const headingDefaults = css`
  font-family: ${fonts.primary};
  color: ${colors.primaryText};
  font-weight: normal;
`

export const H1 = styled.h1`
  ${headingDefaults};
  font-weight: 600;
  font-size: 2rem;
  margin-top: 35px;
`
export const H2 = styled.h2`
  ${headingDefaults};
`
export const H3 = styled.h3`
  ${headingDefaults};
`
export const H4 = styled.h4`
  ${headingDefaults};
`
export const H5 = styled.h5`
  ${headingDefaults};
`
export const H6 = styled.h6`
  ${headingDefaults};
`

export const Small = styled.small`
  font-family: ${fonts.primary};
  font-weight: 400;
`

export const Paragraph = styled.p`
  font-family: ${fonts.primary};
  color: ${colors.primaryText};
  font-weight: 400;
`

export const Lead = styled(Paragraph)`
  font-size: 20px;
`
