import React from 'react'

import { SectionTitle, Column, Text, Table, TR, TH, TD } from 'atoms'
import { money } from 'atoms/$'

const Endorsements = ({ endorsements }) => {
  if (!endorsements || endorsements.length === 0)
    return <Text>No endorsement information provided.</Text>

  return (
    <Column>
      <SectionTitle>Endorsements</SectionTitle>
      <Table
        header={
          <TR>
            <TH width="75%" p="0 0.5rem 0 0">
              Name
            </TH>
            <TH p="0 0.5rem 0 0">Limit</TH>
          </TR>
        }>
        {endorsements.map(endorsement => {
          return (
            endorsement && (
              <TR key={endorsement.name}>
                <TD p="0 0.5rem 0 0">{endorsement.name}</TD>
                <TD p="0 0.5rem 0 0">
                  {money(endorsement.limit, { fallback: '—' })}
                  {endorsement.coinsurance && ` / ${endorsement.coinsurance} Co-insurance`}
                </TD>
              </TR>
            )
          )
        })}
      </Table>
    </Column>
  )
}

export default Endorsements
