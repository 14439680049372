import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatDate } from 'util/date'
import {
  EnhancedModal,
  Title,
  Button,
  ButtonContainer,
  Column,
  WarningBox,
} from 'atoms'
import { useOnClickOutside, usePanel, useFeature } from 'hooks'
import { QUOTE_STATUSES } from 'panels/constants'
import { ShowConfig } from 'pages/shared/panels'

const Bind = ({ submission, setIsPolling }) => {
  const history = useHistory()
  const ref = useOnClickOutside(() => history.goBack())
  const panel = usePanel()
  const { submissionId, quoteId, ...params } = useParams()
  const panelId = params.panelId || panel.id
  const isMultiline = useFeature('multilineCyber')

  const lineOfBusiness =
    isMultiline && panel.lineOfBusiness === 'Cyber'
      ? 'Multiline Cyber'
      : panel.lineOfBusiness
  const config = ShowConfig.config(lineOfBusiness)

  const { BindContent } = config.components
  const { getQuotationById } = config.getters
  const { requestToBind } = config.mutations

  useEffect(() => setIsPolling(false), [])

  const [requestBinding] = requestToBind.mutation({
    onCompleted: data => {
      const error = requestToBind.getError(data)
      const returnPath = isMultiline
        ? `/panels/submissions/${submissionId}`
        : `/panels/${panelId}/submissions/${submissionId}`
      if (error) {
        toast.error(error)
        history.push(returnPath)
      } else {
        toast.success('Binder requested')
        history.push(returnPath)
      }
    },
  })

  const quotation = getQuotationById(quoteId, submission)
  const currentDate = formatDate(new Date(), 'YYYY-MM-DD')
  const startDate =
    quotation.coverageStartDate ||
    formatDate(quotation.quotedLines[0]?.coverageStartDate, 'YYYY-MM-DD') ||
    currentDate
  const [coverageStartDate, setCoverageStartDate] = useState(startDate)

  const handleDateChange = e => {
    e.preventDefault()
    e.stopPropagation()
    setCoverageStartDate(e.target.value)
  }

  const handleRequestToBind = () =>
    requestBinding(
      requestToBind.getVariables({
        submissionId,
        quotationId: quoteId,
        coverageStartDate,
      })
    )

  const name = quotation.carrierName || quotation.carrier

  const isIndication = quotation.indication
  const isReferred = quotation.status === QUOTE_STATUSES.referred

  return (
    <EnhancedModal ref={ref} bg="white" showExit={false} light>
      <Column height="100%" justifyContent="space-between">
        <Title fontWeight={2} mb={2} mt="none">
          {isIndication ? 'How to Bind' : `Bind Quote from ${name}`}
        </Title>
        <>
          {isReferred && (
            <WarningBox mb={2}>
              This quote has been referred out to an underwriter for review. It
              may not be possible to bind at this time.
            </WarningBox>
          )}
          <BindContent
            submission={submission}
            quotation={quotation}
            coverageStartDate={coverageStartDate}
            handleDateChange={handleDateChange}
          />
        </>
        <ButtonContainer>
          <Button tertiary onClick={history.goBack}>
            {isIndication ? 'Close' : 'Cancel'}
          </Button>
          {isIndication ? (
            <Button
              onClick={() =>
                history.push(
                  `/panels/${panelId}/submissions/${submissionId}/edit`
                )
              }>
              Edit Application
            </Button>
          ) : (
            <Button onClick={handleRequestToBind} htmlType="button">
              Bind Now
            </Button>
          )}
        </ButtonContainer>
      </Column>
    </EnhancedModal>
  )
}

export default Bind
