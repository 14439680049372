import React from 'react'

import { Subtitle, Panel, Row, Text } from 'atoms'

const FALLBACK_DECLINATION_REASON =
  'The industry this company operates in is outside of our underwriting appetite.'

const Declined = ({ quotation }) => {
  return (
    <>
      <Subtitle mb={1}>Request Declined</Subtitle>
      <Panel mb={2}>
        <Row justifyContent="center" alignItems="center">
          <Text>
            {quotation.declinationReason || FALLBACK_DECLINATION_REASON}
          </Text>
        </Row>
      </Panel>
    </>
  )
}

export default Declined
