import React, { Suspense, lazy } from 'react'
import { Loading } from 'atoms'

const RSSelect = lazy(() => import('react-select'))
const RSCreatable = lazy(() => import('./Creatable'))

const LazyRSSelect = props => {
  return (
    <Suspense fallback={<Loading />}>
      <RSSelect {...props} />
    </Suspense>
  )
}

export const LazyRSCreatable = props => {
  return (
    <Suspense fallback={<Loading />}>
      <RSCreatable {...props} />
    </Suspense>
  )
}

export default LazyRSSelect
