import styled from 'styled-components'

const InputContainer = styled.div`
  width: ${props => props.width || '100%'};
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 50px;
`
InputContainer.displayName = 'InputContainer'

export default InputContainer
