import React from 'react'
import { Grid, Tooltip, withStyles } from '@material-ui/core'
import { colors } from 'components/theme'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import { Icon, Text } from 'atoms'

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: colors.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    fontSize: '1rem',
  },
})(Tooltip)

export const withSideHelperText = Wrapped => {
  const hoc = props => {
    const uiConfig = props.uiSchema['ui:config']

    return uiConfig?.['fieldCols'] ? (
      <Grid container spacing={2}>
        <Grid item xs={uiConfig['fieldCols']}>
          <Wrapped {...props} fullWidth={true} />
        </Grid>
        <Grid
          container
          item
          xs={12 - uiConfig['fieldCols']}
          alignItems="center">
          <LightTooltip
            arrow
            title={
              <Text color={colors.primaryTextMid}>
                {uiConfig['helpTooltip']}
              </Text>
            }>
            <Text color={colors.primaryTextMid}>
              <Icon mr="8px" icon={faQuestionCircle} display="inline" />

              {uiConfig['helpLabel']}
            </Text>
          </LightTooltip>
        </Grid>
      </Grid>
    ) : (
      <Wrapped {...props} />
    )
  }
  hoc.displayName = `withSideHelperText(${Wrapped.displayName})`
  return hoc
}
