import { readableColor } from 'polished'
import theme from 'components/theme'
import relay from './relay'
import nfp, { DOMAINS as NFP_DOMAINS } from './nfp'
import appund, { DOMAINS as APPUND_DOMAINS } from './appund'

export { default as relay } from './relay'
export { default as nfp, DOMAINS as NFP_DOMAINS } from './nfp'

export const RELAY = 'relay'
export const NFP = 'nfp'
export const APPUND = 'appund'

const domainMappings = [
  [NFP_DOMAINS, NFP],
  [APPUND_DOMAINS, APPUND],
]

export const loadUrlBranding = () => {
  const domainBrand = domainMappings.find(([list, branding]) => {
    if (list.includes(window.location.hostname)) {
      return branding
    }
  })
  // If we've found a brand based on the domain, return their name
  if (domainBrand) {
    return domainBrand[1]
  }

  return RELAY
}

export const loadBranding = name => {
  switch (name) {
    case NFP:
      return nfp
    case APPUND:
      return appund
    default:
      return relay
  }
}

export const loadWhitelabel = company => {
  const { brandColor, brandName, name, logoUrl } = company

  const headerColor = brandColor ? brandColor : theme.colors.header

  const whitelabelText = readableColor(
    headerColor,
    theme.colors.primaryText,
    theme.colors.white,
    true
  )
  return {
    brand: 'Whitelabelled',
    brandName,
    name: name,
    logo: logoUrl,
    logoAlt: name,
    cobranded: false,
    showHelp: true,
    allowSignup: true,
    billable: true,
    theme: {
      ...theme,
      colors: {
        ...theme.colors,
        header: headerColor,
        whitelabelText: whitelabelText,
      },
    },
  }
}
