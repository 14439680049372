import React from 'react'
import PropTypes from 'prop-types'
import { Amplitude } from '@amplitude/react-amplitude'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { isEmpty } from 'ramda'
import map from 'lodash/map'
import get from 'lodash/get'
import set from 'lodash/set'
import noop from 'lodash/noop'
import startCase from 'lodash/startCase'

import { Row, Column } from 'components/shared/Layout'
import FieldLabel from 'components/forms/FieldLabel'
import InputWidget from 'components/forms/widgets/InputWidget'

const form =
  ({
    form,
    analyticsEvent,
    eventProps = {},
    preventAnalyticsEvent = false,
    ...options
  }) =>
  Component => {
    const EnhancedForm = reduxForm({
      ...options,
      form,
      analyticsEvent,
    })(Component)
    const Enhanced = props => (
      <Amplitude eventProperties={{ form, analyticsEvent, ...eventProps }}>
        {({ instrument }) => (
          <EnhancedForm
            {...props}
            onSubmitFail={
              preventAnalyticsEvent
                ? options.onSubmitFail || props.onSubmitFail || noop
                : instrument(
                    'FormSubmitFailed',
                    options.onSubmitFail || props.onSubmitFail || noop
                  )
            }
            onSubmitSuccess={
              preventAnalyticsEvent
                ? options.onSubmitSuccess || props.onSubmitSuccess || noop
                : instrument(
                    'FormSubmitSuccess',
                    options.onSubmitSuccess || props.onSubmitSuccess || noop
                  )
            }
          />
        )}
      </Amplitude>
    )
    Enhanced.displayName = `form(${Component.displayName})`
    Enhanced.propTypes = {
      onSubmitFail: PropTypes.func,
      onSubmitSuccess: PropTypes.func,
    }
    return Enhanced
  }

const submitValidations = validator => fields =>
  new Promise((resolve, reject) => {
    const errors = validator(fields)
    if (isEmpty(errors)) {
      resolve(fields)
    } else {
      reject(new SubmissionError(errors))
    }
  })

const requireField = (field, message, fieldName = '_error') => {
  if (!field || field.length === 0) {
    throw new SubmissionError({
      [fieldName]: [message],
    })
  }
}

const fieldIncomplete = val => {
  if (val === null || val === undefined) {
    return true
  } else if (typeof val === 'object' && !(val instanceof Array)) {
    return !val.value
  } else {
    return val.length === 0
  }
}

const isRequired = val => fieldIncomplete(val) && 'Required'

const validateRequiredFields =
  (fields, errors = {}) =>
  values =>
    fields.reduce((acc, key) => {
      if (typeof key === 'string' || key instanceof String) {
        if (fieldIncomplete(get(values, key))) {
          return {
            ...acc,
            ...set({}, key, 'Required'),
          }
        }
      } else {
        return { ...acc, ...key(values) }
      }

      return acc
    }, errors)

// Example
// key = cedentRetention
// fields = [layerLimit]
// errors: {
//    'cedentRetention[0].layerLimit': 'Required'
// }
//
const validateArrayRequiredField =
  (key, fields, errors = {}) =>
  values =>
    (values[key] || []).reduce((acc, nestedObject, index) => {
      const errors = validateRequiredFields(fields)(nestedObject)
      if (Object.keys(errors).length === 0) return acc

      return {
        ...acc,
        [key]: {
          ...acc[key],
          [index]: {
            ...(acc[key] || [])[index],
            ...errors,
          },
        },
      }
    }, errors)

const normalizeEmptyString = v => (v === '' ? null : v)
const normalizeNumber = val => (isNaN(val) ? null : val)

const convertToOption = value => ({
  value: value,
  label: value,
})

const extractValue = option => {
  return option.value
}

const enumToOptions = enumerable => {
  return Object.keys(enumerable).map(key => ({
    label: startCase(key),
    value: enumerable[key],
  }))
}

const TemplatizedFormFields = ({ wide, fields }) => (
  <>
    {map(fields, row => (
      <Row stretch={wide}>
        {map(row, ({ title, required, ...value }, key) => (
          <Column wide={Object.keys(row).length === 1}>
            <FieldLabel required={required} htmlFor={key}>
              {title}
            </FieldLabel>
            <Field
              component={InputWidget}
              type={'text'}
              id={key}
              name={key}
              autoComplete="off"
              {...value}
            />
          </Column>
        ))}
      </Row>
    ))}
  </>
)

export {
  TemplatizedFormFields,
  isRequired,
  fieldIncomplete,
  requireField,
  validateRequiredFields,
  validateArrayRequiredField,
  submitValidations,
  form,
  normalizeEmptyString,
  convertToOption,
  extractValue,
  enumToOptions,
  normalizeNumber,
}
