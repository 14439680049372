import React from 'react'

import { Loading, PageWrapper, Row } from 'atoms'
import PageNav from 'components/shared/PageNav'
import { error404, NotFound } from 'pages'
import { any, bool, shape, string } from 'prop-types'

const PageLayout = ({ nav, loading, error, children, secondaryAction }) => {
  if (loading) return <Loading />

  if (error) {
    if (error.message === error404) return <NotFound showHeader={false} />
    throw error
  }

  return (
    <PageWrapper narrow>
      <Row>
        {nav && <PageNav mb={2} to={nav.path} label={nav.label} />}
        {secondaryAction}
      </Row>
      {children}
    </PageWrapper>
  )
}

PageLayout.propTypes = {
  nav: shape({ path: string, label: string }),
  loading: bool,
  error: any,
}

export default PageLayout
