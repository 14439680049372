import React, { useState, useEffect } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import startCase from 'lodash/startCase'
import { Button, Box, Tabs, Tab } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { capitalize, get } from 'util/lodash'
import {
  faAngleRight,
  faSave,
  faAngleLeft,
} from '@fortawesome/pro-solid-svg-icons'

import getIcon from 'FlexiForm/utils/getIcons'
import truncate from 'util/truncate'
import PanelContext from 'components/contexts/panel'
import { Icon, Column, Row, Loading } from 'atoms'
import { colors } from 'components/theme'
import { usePanels } from 'hooks/panels'
import { useFlexiForm } from 'FlexiForm'
import { useBackButton, useNextButton } from 'FlexiForm/hooks'

const overrides = {
  MuiTouchRipple: {
    root: {
      color: colors.primary,
      borderTopLeftRadius: '30px',
      borderBottomLeftRadius: '30px',
      marginLeft: '1.25rem',
    },
  },
}

const MultiPageField = ({
  uiSchema,
  schema,
  rules,
  formData,
  onChange,
  errorSchema,
  idSchema,
  formContext,
  ...props
}) => {
  const {
    onSubmit,
    enableDraft,
    handleSaveDraft,
    draftLabel,
    handleAutoSave,
    controls,
    submitLabel,
    submitIcon,
  } = useFlexiForm()
  const { pathname: location } = useLocation()
  const { url, path } = useRouteMatch()
  const history = useHistory()
  const panels = usePanels()
  const pagesAndTabs = Object.entries(schema.properties).map(part => [
    part[0],
    part[1].tab,
  ])
  const pages = pagesAndTabs.map(p => p[0])

  const splitPath = history.location.pathname.split('/')
  const [currentTab, setTab] = useState(
    Math.max(0, pages.indexOf(splitPath[splitPath.length - 1]))
  )
  const {
    loading: backbuttonLoading,
    backButtonTitle,
    onBack,
  } = useBackButton(formData, currentTab, pages)
  const {
    loading: nextbuttonLoading,
    nextButtonTitle,
    onNext,
  } = useNextButton(formData, currentTab, pages)
  const loading = backbuttonLoading || nextbuttonLoading
  const SchemaField = props.registry.fields.SchemaField

  // TODO: Replace this with something that doesn't rely on this only being
  // used for product line based forms.
  formContext.switchTab = key => {
    history.push(`${url}/${key}`)
    setTab(pages.indexOf(key))
  }
  formContext.productLines = pages.slice(1, -1)

  if (pages.length === 0) {
    return null
  }

  useEffect(() => {
    const page = location.split(`${url}/`).join('')
    const pageIndex = pages.indexOf(page)
    if (pageIndex > -1) setTab(pageIndex)
  }, [location])

  const handleNext = key => () => {
    const valid = controls.validate(key)
    if (valid && typeof onNext === 'function') {
      const formData = controls.getValues()
      onNext(formData)
    } else if (valid) {
      handleAutoSave({ force: true })
      const nextPage = pages[Math.max(0, currentTab + 1)]
      history.push(`${url}/${nextPage}`)
    }
  }

  const handleBack = async () => {
    if (typeof onBack === 'function') {
      const formData = controls.getValues()
      onBack(formData)
    } else {
      const prevPage = pages[Math.max(0, currentTab - 1)]
      history.push(`${url}/${prevPage}`)
    }
  }

  const handleChange = key => newData => {
    const formData = controls.getValues()
    onChange({ ...formData, [key]: newData })
  }

  const handleSubmit = key => {
    return () => {
      if (controls.validate(key)) {
        const formData = controls.getValues()
        onSubmit({ formData })
      }
    }
  }

  const isFirstPage = currentTab === 0
  const isLastPage = currentTab === pages.length - 1

  const pageUiSchema = page => uiSchema[page] || {}

  return (
    <Row>
      <Column py={3} width="30%" background={colors.background}>
        <ThemeProvider
          theme={theme => ({
            ...theme,
            overrides: { ...theme.overrides, ...overrides },
          })}>
          <Tabs
            value={currentTab}
            onChange={() => handleAutoSave({ force: true })}
            orientation="vertical">
            {Object.entries(schema.properties).map(([key, property], index) => {
              const tabName =
                get(uiSchema, [key, 'ui:title']) ||
                property.tab ||
                capitalize(key)
              return (
                <Tab
                  icon={<Icon mt="6px" mr={1} icon={getIcon(property.icon)} />}
                  label={truncate(tabName, 20)}
                  wrapper={{ flexDirection: 'row' }}
                  value={index}
                  onClick={() => history.push(`${url}/${key}`)}
                  key={property.tab}
                />
              )
            })}
            {formContext.additionalSidebarLinks.map((SidebarLink, index) => (
              <SidebarLink key={index} />
            ))}
          </Tabs>
        </ThemeProvider>
      </Column>
      <Column p={3}>
        <Switch>
          {Object.entries(schema.properties).map(([key, property]) => {
            const companyName = formData.applicant?.company_name
            const title = companyName
              ? `${property.title} for ${companyName}`
              : property.title

            const currentPanel = panels.find(
              panel => panel.lineOfBusiness.toLowerCase() === key
            )

            const prevTab = pagesAndTabs[Math.max(currentTab - 1, 0)][1]
            const nextTab =
              !isLastPage && pagesAndTabs[Math.max(currentTab + 1, 0)][1]
            const prevTitle =
              backButtonTitle ||
              pageUiSchema(key)['ui:backLink'] ||
              pageUiSchema(prevTab)['ui:title'] ||
              startCase(prevTab)
            const nextTitle =
              nextButtonTitle ||
              pageUiSchema(key)['ui:nextLink'] ||
              startCase(nextTab)

            const form = (
              <Route key={key} path={`${path}/${key}`}>
                <PanelContext.Provider value={currentPanel}>
                  <SchemaField
                    buttonsMarginTop={2}
                    schema={{ ...property, title }}
                    uiSchema={pageUiSchema(key)}
                    formData={formData[key]}
                    onChange={handleChange(key)}
                    errorSchema={errorSchema[key]}
                    idSchema={idSchema[key]}
                    {...props}
                  />
                  <Box display="flex" flexDirection="row" mt={3}>
                    {!isFirstPage && (
                      <Box mr="auto">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleBack}
                          startIcon={<Icon icon={faAngleLeft} />}>
                          {`Prev: ${prevTitle}`}
                        </Button>
                      </Box>
                    )}
                    <Box display="flex" flexDirection="row" ml="auto">
                      {enableDraft && (
                        <Box mr={1}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleSaveDraft}
                            startIcon={<Icon icon={faSave} />}>
                            {draftLabel || 'Save Draft'}
                          </Button>
                        </Box>
                      )}
                      {isLastPage ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit(key)}
                          startIcon={submitIcon && <Icon icon={submitIcon} />}
                          ml="auto">
                          {submitLabel || 'Submit'}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext(key)}
                          endIcon={<Icon icon={faAngleRight} />}
                          ml="auto">
                          {`Next: ${nextTitle}`}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </PanelContext.Provider>
              </Route>
            )
            if (loading) {
              return <Loading />
            } else {
              return form
            }
          })}
          <Redirect to={`${path}/${pages[0]}`} />
        </Switch>
      </Column>
    </Row>
  )
}

export default MultiPageField
