import startCase from 'lodash/startCase'
import kebabCase from 'lodash/kebabCase'

export const pluralise = (count, word) =>
  count > 1 || count === 0 ? `${word}s` : word

export const lower = value => value && value.toLowerCase()

export const upper = value => value && value.toUpperCase()

export const capitalize = value =>
  value && value.charAt(0).toUpperCase() + value.slice(1)

export const capitalizePhrase = value =>
  value && value.split(' ').map(capitalize).join(' ')

export const titleCase = value => startCase(kebabCase(value))
