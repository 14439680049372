import React from 'react'
import styled from 'styled-components'
import { Step, Stepper, StepLabel } from '@material-ui/core'

const Label = styled(StepLabel)`
  span {
    font-size: 1.5rem;
    font-weight: 700;
  }
`

const MultiStepFormField = ({ schema }) => {
  const { activeStep, steps } = schema.additional_properties

  const getActiveStep = () => steps.indexOf(activeStep)

  return (
    <Stepper activeStep={getActiveStep()}>
      {steps.map(label => {
        const stepProps = {}
        const labelProps = {}

        return (
          <Step key={label} {...stepProps}>
            <Label {...labelProps} Step>
              {label}
            </Label>
          </Step>
        )
      })}
    </Stepper>
  )
}

export default MultiStepFormField
