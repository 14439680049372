import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { faBell } from '@fortawesome/pro-regular-svg-icons'

import _Icon from 'components/Icon'
import { colors } from 'components/theme'
import { useBranding } from 'hooks'

const Icon = styled(_Icon)`
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.05s ease-in-out;
  color: ${({ iconColor, theme, isWhitelabel }) =>
    isWhitelabel
      ? theme.colors.whitelabelText
      : colors[iconColor || 'secondaryBlue']};
  &:hover {
    transform: scale(1.2);
  }
`

const NotificationsMenuIcon = ({ animate, iconColor }) => {
  const { isWhitelabel } = useBranding()
  return (
    <motion.div animate={animate}>
      <Icon icon={faBell} iconColor={iconColor} isWhitelabel={isWhitelabel} />
    </motion.div>
  )
}

export default NotificationsMenuIcon
