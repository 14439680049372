import React from 'react'
import { Field } from 'react-final-form'

import { Flex } from 'atoms'
import { TextInput } from 'atoms/form'

const Version = ({ name, ...props }) => {
  const formatDefault = value => (value === null ? '1' : parseInt(value))
  const parseValue = value => (isNaN(parseInt(value)) ? null : parseInt(value))

  return (
    <Flex {...props} width="8ch">
      <Field
        name={`${name}.version`}
        parse={parseValue}
        formatOnBlur
        format={formatDefault}
        component={TextInput}
        box
        borderRadius={1}
      />
    </Flex>
  )
}

export default Version
