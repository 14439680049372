import React from 'react'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { IconButton, InputAdornment } from "@material-ui/core";
import { DatePicker } from '@material-ui/pickers'
import { utils } from '@rjsf/core'
import { Clear as ClearIcon } from "@material-ui/icons";

const { getDisplayLabel } = utils

const FORMAT = 'yyyy-MM-dd'

const DateWidget = ({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  schema,
  uiSchema,
  rawErrors = [],
  /* eslint-disable no-unused-vars */
  options,
  type,
  formContext,
  /* eslint-enable no-unused-vars */
  ...textFieldProps
}) => {
  const handleChange = date => {
    onChange(format(date, FORMAT))
  }
  const _onBlur = ({ target: { value } }) => onBlur(id, value)
  const _onFocus = ({ target: { value } }) => onFocus(id, value)

  const displayLabel = getDisplayLabel(schema, uiSchema)
  const uiConfig = uiSchema['ui:config']

  const date = value ? parseISO(value) : null

  const onDateClear = e => {
    e.stopPropagation()
    onChange(null)
  }

  return (
    <>
      <DatePicker
        InputProps={{
          endAdornment: date && schema.clearable &&(
            <InputAdornment position="end">
              <IconButton onClick={onDateClear}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        id={`${id}_picker`}
        autoOk
        minDate={uiConfig?.minimumDate}
        maxDate={uiConfig?.maximumDate}
        variant="inline"
        inputVariant="outlined"
        placeholder={placeholder}
        label={displayLabel ? label || schema.title : false}
        autoFocus={autofocus}
        required={required}
        disabled={disabled || readonly}
        value={date}
        error={rawErrors.length > 0}
        onChange={handleChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        format="MMM d, yyyy"
        {...textFieldProps}
      />
      {/* Add an invisible input to support the form filler extension */}
      <input
        id={id}
        style={{ display: 'none' }}
        value={value}
        onChange={evt => {
          onChange(evt.target.value)
        }}
      />
    </>
  )
}

export default DateWidget
