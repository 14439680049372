import truncate from './truncate'
import * as Sentry from '@sentry/react'
import { isBroker } from 'hooks/currentUser'

const getQuestionableName = data => {
  if (!data) return undefined
  switch (data.__typename) {
    case 'Quote':
      return data?.worksheet?.name
    case 'Participation':
      return data?.submission?.name
    case 'CyberQuotation':
      return data?.participation?.submission?.name
    default:
      if (process.env.REACT_APP_ENV !== 'production') {
        Sentry.captureMessage(
          `Missing notification for questionable name for GraphQL type ${data.__typename}`
        )
      }
      return ''
  }
}

const getNotificationSubText = (data, event, user) => {
  if (!data) return undefined
  switch (data.__typename) {
    case 'Broadcast':
      switch (data.broadcastable.__typename) {
        case 'Worksheet':
          return `${data.broadcastable.insurancePolicy.insurer} (${data.broadcastable.name})`
        case 'NFPRepsAndWarrantiesSubmission':
          return `${data.broadcastable.owner.company.name} (${data.broadcastable.name})`
        default:
          return data.subject
      }
    case 'DirectMessage':
      return `${data.author.fullName} (${data.subject})`
    case 'Quote':
      return `${data.reinsurer.companyName} (${data.worksheet.name})`
    case 'Revision':
      return `${data.quote.worksheet.insurancePolicy.insurer} (${data.quote.worksheet.name})`
    case 'Worksheet':
      return `${data.insurancePolicy.insurer} (${data.name})`
    case 'Question':
      return `${getQuestionableName(data.questionable)}: ${
        data.section
      } - "${truncate(data.text)}"`
    case 'Subjectivity':
      return `${getQuestionableName(data.questionable)}: ${
        data.section
      } - "${truncate(data.text)}"`
    case 'Participation':
      return (event == 'Quote.Requested' || event == 'Submission.Edited')
        ? `${data.submission.name} (${data.submission.owner?.company?.name})`
        : `${data.submission.name} (${data.user?.company?.name})`
    case 'NFPRepsAndWarrantiesSubmission':
      return `${data.name}`
    case 'NFPRepsAndWarrantiesQuotation':
      return `${data.participation.submission.name} (${data.participation.user.company.name})`
    case 'CyberQuotation':
      return `${data.participation.submission.name} (${
        isBroker(user)
          ? (data.participation.apiUser?.firstName || data.participation.user?.company?.name)
          : data.participation.submission.owner?.company?.name
      })`
    case 'PanelsQuotation':
      return `${data.participation.submission.name} (${
        isBroker(user)
          ? data.participation.user?.company?.name
          : data.participation.submission.owner?.company?.name
      })`
    case 'PanelSubmission':
      return `${data.name} (${data.owner?.company?.name})`
    case 'CyberSubmission':
      return `${data.name} - ${data.contactName}`
    default:
      if (process.env.REACT_APP_ENV !== 'production') {
        Sentry.captureMessage(
          `Missing notification subtext for GraphQL type ${data.__typename}`
        )
      }
      return ''
  }
}

export default getNotificationSubText
