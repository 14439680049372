import React from 'react'
import { Flex, Icon, Text } from 'atoms'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

const Saved = ({ text = 'Saved', ...props }) => {
  return (
    <Flex width="auto" alignItems="baseline" {...props}>
      <Icon icon={faCheck} mr={1} />
      <Text>{text}</Text>
    </Flex>
  )
}

export default Saved
