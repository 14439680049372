import React, { Suspense, lazy } from 'react'

import { Loading } from 'atoms'

const Auth = lazy(() => import('./Auth'))

const LazyAuth = props => {
  return (
    <Suspense fallback={<Loading />}>
      <Auth {...props} />
    </Suspense>
  )
}

export default LazyAuth
