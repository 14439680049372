import styled from 'styled-components'
import { colors, fonts } from '../theme'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 36px;
  width: ${props => (props.stretch ? 'auto' : '512px')};
  justify-content: space-between;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 1 auto;
  width: ${props => (props.wide ? '100%' : '50%')};

  &:nth-child(2) {
    margin-left: 2rem;
  }
`

const ButtonBox = styled.div`
  margin-top: 36px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.stretch ? '' : props.wide ? '940' : '675')}px;
`

const SubRow = styled(Row)`
  margin-top: 0px;
  ${props => (props.hidden ? 'display: none' : '')}
`

const Legend = styled.p`
  font-family: ${fonts.secondary};
  font-size: 12px;
  color: ${colors.primary};
  align-self: ${props => (props.side === 'left' ? 'flex-start' : 'flex-end')};
`

export { Wrapper, Row, Column, ButtonBox, SubRow, Legend }
