import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Div, Flex, Subtitle } from 'atoms'

export const TabContainer = styled(Div).attrs({
  marginBottom: 1,
})`
  border-bottom: 1px solid ${props => props.theme.colors.secondaryBlue};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const TabLink = styled(NavLink)`
  display: flex;
  align-items: baseline;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family ${props => props.theme.fonts.secondary};
  color ${props => props.theme.colors.primaryText};
  padding: 0 0.5rem 0.15625rem 0.5rem;
  min-width: 3rem;

  &:not(:last-child) {
    margin-right: 4rem;
  }

  &.active {
    border-bottom: 0.25rem ${props => props.theme.colors.primaryText} solid;
    font-weight: 600;
  }

  ${props => props.right && `margin-left: auto;`}
`

const Tabs = ({ children, title }) => {
  return (
    <Flex flexDirection="column" mt={title ? 1 : 3}>
      {title && <Subtitle mt={0}>{title}</Subtitle>}
      <TabContainer>{children}</TabContainer>
    </Flex>
  )
}

export default Tabs
