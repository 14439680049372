import React from 'react'
import styled from 'styled-components'

import { colors } from 'components/theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 99;
  width: 170px;
  position: relative;
  margin-right: 16px;
`
const Horizontal = styled.div`
  display: flex;
`
const Rectangle = styled.div`
  height: 50px;
  width: 165px;
  background-color: ${colors.primaryAction};
  color: ${colors.primaryActionText};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`

const Content = styled.div`
  margin-left: 15px;
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 12px;
  border-color: transparent transparent transparent ${colors.primaryAction};
`

const PointerShape = ({ children }) => (
  <Container>
    <Horizontal>
      <Rectangle>
        <Content>
          <strong>{children}</strong>
        </Content>
      </Rectangle>
      <Triangle />
    </Horizontal>
  </Container>
)

export default PointerShape
