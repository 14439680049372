import { useContext } from 'react'

import PermissionsContext from '../components/contexts/permissions'

const usePermission = (action, area, options) => {
  const permissionContext = useContext(PermissionsContext)
  const permission = permissionContext.find(
    permission => permission.area === area && permission.action === action
  )
  let allowed = !!permission

  if (options && options.for) {
    allowed = allowed && !!options.for.permissions[action]
  }

  if (permission && allowed) {
    return [true, permission.metadata]
  } else {
    return [false, undefined]
  }
}

export default usePermission
