import React from 'react'
import { Box, Grid, makeStyles, Paper, Button } from '@material-ui/core'
import {
  faFire,
  faFingerprint,
  faList,
  faBriefcase,
} from '@fortawesome/pro-solid-svg-icons'
import GetMultiPageReviewFields from './GetMultiPageReviewFields'
import { colors } from 'components/theme'
import { Div, Icon, Text } from 'atoms'
import { titleCase } from 'util/string'
import { useFlexiForm } from 'FlexiForm'

const useStyles = makeStyles({
  summaryField: {
    height: 'auto',
    width: '100%',
    minHeight: '125px',
    marginTop: '25px',
  },
  icon: {
    fontSize: 32,
    marginBottom: 10,
  },
  editButton: {
    textTransform: 'none',
    borderRadius: 50,
    border: `2px solid ${colors.link}`,
    height: 48,
    width: 140,
    fontWeight: 600,
  },
  termCheckBox: {
    position: 'relative',
  },
})

const resolveIcon = product => {
  switch (product) {
    case 'terrorism':
      return faFire
    case 'cyber':
      return faFingerprint
    case 'business_owner':
      return faBriefcase
    default:
      return faList
  }
}

const ProductLineSummary = ({ product, data, handleEditDetails, panelId }) => {
  const classes = useStyles()

  return (
    <Grid
      component={Paper}
      className={classes.summaryField}
      elevation={2}
      key={product}
      container>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="info.main"
        color="white"
        width={160}
        justifyContent="center"
        alignItems="center">
        <Icon icon={resolveIcon(product)} className={classes.icon} />
        <Text color="white" fontSize="14px">
          {titleCase(product)}
        </Text>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        my={1}
        mx={2}>
        <GetMultiPageReviewFields
          product={product}
          data={data}
          panelId={panelId}
        />
      </Box>

      <Box
        display="flex"
        my={1}
        mx={2}
        flexGrow={1}
        alignItems="center"
        justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={handleEditDetails}
          className={classes.editButton}>
          Edit Details
        </Button>
      </Box>
    </Grid>
  )
}

const MultiPageReview = ({ formContext }) => {
  const { controls } = useFlexiForm()
  const editDetails = productLine => {
    formContext.switchTab(productLine)
  }
  const formData = controls.getValues()

  return (
    <Div>
      {formContext.productLines.map(productLine => (
        <ProductLineSummary
          key={productLine}
          product={productLine}
          data={formData[productLine]}
          // TODO CH remove the panelId fallback once everything is multiline
          panelId={formData[productLine]?.panel?.id || formData?.panel?.id}
          handleEditDetails={() => {
            editDetails(productLine)
          }}
        />
      ))}
    </Div>
  )
}

export default MultiPageReview
