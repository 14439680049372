import React from 'react'
import { useAmBestImportTimeQuery } from 'graphql/api'
import { Column, Row, Note } from 'atoms'
import AMBestToolTip from 'molecules/AMBestToolTip'
import { displayDate } from 'util/date'

const AMBestLabel = () => {
  const { data } = useAmBestImportTimeQuery()

  const hasData = data?.viewer?.lastAmbestImport

  return (
    <Column>
      <Row>
        AM Best Rating
        <AMBestToolTip />
      </Row>
      {hasData ? (
        <Note fontSize="s" fontWeight="0">
          Last Updated {displayDate(data.viewer.lastAmbestImport.importedAt)}
        </Note>
      ) : null}
    </Column>
  )
}

export default AMBestLabel
