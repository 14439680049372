import translations from './translations.json'

import errors from './errors.translations'

const { cyber, common } = translations

export default {
  cyber,
  common,
  errors,
}
