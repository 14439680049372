import React from 'react'

import Field from 'molecules/Field'
import { Column, Row, Text } from 'atoms'

const AddressColumn = ({ address }) => {
  return (
    <Column flex={1}>
      <Row>
        <Field
          label="Address"
          value={
            <Column>
              {[
                address.streetLine1,
                address.streetLine2,
                address.city,
                address.state,
                address.zipCode,
              ]
                .filter(Boolean)
                .map(line => (
                  <Text key={line}>{line}</Text>
                ))}
            </Column>
          }
        />
      </Row>
    </Column>
  )
}

export default AddressColumn
