import React from 'react'

import { money } from 'atoms/$'
import { Column, Row, ToggleLink } from 'atoms'
import Field from 'molecules/Field'
import DocumentsTable from 'molecules/DocumentUpload/DocumentsTable'
import filteredCoverages from 'cyber/shared/filteredCoverages'
import { useToggle } from 'hooks'
import { Coverages } from 'pages/shared/panels'
import { formatDate } from 'util/date'

export const QuoteContent = ({ quotation, country }) => {
  const [showDetails, toggleDetails] = useToggle(false)
  const agreements = {
    sections: () => [
      {
        title: 'Third Party Insuring Agreements',
        type: 'ThirdParty',
      },
      {
        title: 'First Party Insuring Agreements',
        type: 'FirstParty',
      },
      {
        title: 'Cyber Crime Insuring Agreements',
        type: 'CyberCrime',
      },
    ],
    coverages: () =>
      filteredCoverages(country, quotation.coverages).map(coverage => ({
        ...coverage,
        col1: coverage.limit,
        col2: coverage.deductible,
      })),
  }
  const endorsements = {
    sections: () => [
      {
        title: 'Endorsements',
      },
    ],
    coverages: () => {
      return quotation.endorsements.map(endorsement => ({
        ...endorsement,
        col1: endorsement.limit,
      }))
    },
  }

  const opaqueContentStatus = [
    'outdated',
    'declined_outdated',
    'declination_confirmed',
  ]

  return (
    <div
      style={{
        opacity: opaqueContentStatus.includes(quotation.status) && 0.5,
      }}>
      <Row mt={2}>
        <Field label="Limit" value={money(quotation.limit)} width="33.3%" />
        <Field
          label="Deductible"
          value={money(quotation.deductible)}
          width="33.3%"
        />
        {country == 'US' && (
          <Field
            label="Admitted?"
            value={quotation.admitted ? 'Yes' : 'No'}
            width="33.3%"
          />
        )}
      </Row>
      <Row mt={2} mb={2}>
        <Field label="Premium" value={money(quotation.premium)} width="33.3%" />
        <Field label="Fee" value={money(quotation.fee)} width="33.3%" />
        <Field
          label="Coverage Start Date"
          value={formatDate(quotation.coverageStartDate, 'MMMM DD, YYYY')}
          width="33.3%"
        />
      </Row>
      {quotation.policyNumber && (
        <Row mt={2} mb={2}>
          <Field label="Policy Number" value={quotation.policyNumber} />
        </Row>
      )}
      <Column mb={2}>
        <ToggleLink onClick={toggleDetails}>
          {showDetails ? 'Hide' : 'Show'} Insuring Agreements &amp; Endorsements
        </ToggleLink>
        {showDetails && (
          <Row mt={1}>
            <Column
              pr={2}
              borderRightWidth="1px"
              borderRightColor="secondaryBlue"
              borderRightStyle="solid">
              <Coverages
                coverages={agreements.coverages()}
                sections={agreements.sections()}
                type="insuring agreement"
                col1Header="Limit"
                col2Header="Deductible"
              />
            </Column>
            <Column pl={2}>
              <Coverages
                coverages={endorsements.coverages()}
                sections={endorsements.sections()}
                emptyLabel="No endorsements provided."
                col1Header="Limit"
              />
            </Column>
          </Row>
        )}
      </Column>
      <DocumentsTable documents={quotation.documents} />
    </div>
  )
}

export default QuoteContent
