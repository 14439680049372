import React from 'react'
import { useParams } from 'react-router-dom'

import {
  Quotation,
  QuotationHeader,
  QuotationEditButton,
} from 'pages/shared/panels'
import { Row } from 'atoms'
import { QUOTE_STATUSES } from 'panels/constants'
import { useFeature } from 'hooks'

const DONE_STATES = [QUOTE_STATUSES.issued, QUOTE_STATUSES.bound]

const BrokerShow = ({
  config,
  submission,
  participation,
  editUrl,
  userType,
}) => {
  const isMultiline = useFeature('multilineCyber')
  const { quoteId } = useParams()
  if (!quoteId) return null
  const quotation = submission.quotations.find(quote => quote.id === quoteId)

  const status = quotation.status.toLowerCase()
  const isOutdated = status === 'outdated'
  const isCompleted = status =>
    [QUOTE_STATUSES.binding, ...DONE_STATES].includes(status)

  return (
    <Quotation
      config={config}
      title="Quote"
      submission={submission}
      quotation={quotation}
      userType={userType}
      bound={status === 'bound'}
      issued={status === 'issued'}>
      <Row alignItems="center">
        <QuotationHeader
          title={`Quote for ${quotation.carrierName}`}
          quotation={quotation}
          isOutdated={isOutdated}
        />
        {!isMultiline && !isCompleted(status) && (
          <QuotationEditButton
            participation={participation}
            quotation={quotation}
            outdated={isOutdated}
            editUrl={editUrl}
          />
        )}
      </Row>
    </Quotation>
  )
}

export default BrokerShow
