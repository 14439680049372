import React from 'react'
import { Form, Field as FormField } from 'react-final-form'
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import arrayMutators from 'final-form-arrays'

import { Title, EnhancedModal, Button, ButtonContainer } from 'atoms'
import { TextInput, RichText } from 'atoms/form'
import { required } from 'util/validators'
import SimpleUploader from 'molecules/DocumentUpload/SimpleUploader'
import styled from 'styled-components'

const PaddedFormField = styled(FormField)`
  padding-top: 20px;
`

const BindModal = ({ config, submission, quotation, userType }) => {
  const { refetchQueries } = config.getters
  const { QuoteSummary } = config.components
  const { bindQuote } = config.mutations
  const [bindQuotation] = bindQuote.mutation()
  const history = useHistory()
  const { url } = useRouteMatch()

  const handleSubmit = async formValues => {
    const { data } = await bindQuotation({
      variables: { input: { ...formValues, quotationId: quotation.id } },
      refetchQueries,
    })
    const error = bindQuote.getError(data)
    if (error) {
      if (error.policyNumber) {
        toast.error('The Policy Number must be provided')
      } else {
        toast.error(error.base || 'An error occurred')
      }
    } else {
      toast.success('Your quote has been bound')
      history.replace(url.replace(/\/bind/, ''))
    }
  }

  if (userType === 'reinsurer' && quotation.status !== 'binding')
    return <Redirect to={url.replace(/\/bind/, '')} />

  return (
    <EnhancedModal>
      <Title>Bind Quote</Title>
      <QuoteSummary submission={submission} quotation={quotation} />
      <Form mutators={arrayMutators} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <>
            <FormField
              name="policyNumber"
              label="Policy Number"
              mb={1}
              component={TextInput}
            />
            <SimpleUploader
              label="Binder"
              name="documents"
              mb={1}
              required
              validate={required()}
              defaultCategory="Binder"
              canBeBlank={false}
            />
            <PaddedFormField
              name="message"
              label="Message"
              component={RichText}
            />
            <ButtonContainer>
              <Button onClick={handleSubmit}>Bind</Button>
            </ButtonContainer>
          </>
        )}
      </Form>
    </EnhancedModal>
  )
}

export default BindModal
