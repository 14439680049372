import React from 'react'
import styled from 'styled-components'

import { Column, Row, Subtitle } from 'atoms'

import { colors } from 'components/theme'
import { transparentize } from 'polished'
import usePanel from 'hooks/panel'
import { usePanels } from 'hooks/panels'
import PanelistWithAuthentication from './PanelistWithAuthentication'

const GradientRow = styled(Row)`
  background-image: linear-gradient(
    to bottom,
    ${transparentize(0.9, colors.link)},
    rgba(0, 0, 0, 0) 90%
  );
  border-radius: 0.5rem;
`
// The form data is expected to be an array of objects. Each object has two properties id and auth_id
// [{id:"4",auth_id:"6"},{id:"5",auth_id:"6"}]
const SelectPanelistsWithAuthentications = props => {
  const { formData: selectedPanelists = [], onChange } = props
  const hookPanel = usePanel() || usePanels()

  const panelists =
    hookPanel.panelistConfigurations?.nodes.map(config => ({
      ...config.panelist,
      ...config,
    })) || []

  const handleChange = (id, value) => {
    const checked = selectedPanelists.find(panelist => panelist.id === id)
    let selectedValues
    if (!checked) {
      selectedValues = [...selectedPanelists, { id, auth_id: value }]
    } else {
      selectedValues = selectedPanelists.filter(v => v.id !== id)
    }
    onChange(selectedValues)
  }
  const handleInputChange = (id, value) => {
    const checked = selectedPanelists.find(panelist => panelist.id === id)
    if (checked) {
      const selectedValues = selectedPanelists.map(panelist =>
        panelist.id === id ? { id, auth_id: value } : panelist
      )
      onChange(selectedValues)
    }
  }

  return (
    <Column className="preferred-carriers">
      <Subtitle m={0}>
        PREFERRED CARRIERS ({selectedPanelists.length || 0})
      </Subtitle>
      <GradientRow
        justifyContent={panelists.length > 3 ? 'center' : 'flex-start'}
        alignItems="flex-end"
        flexWrap="wrap"
        pt={1}
        pl={1}
        mt={0}>
        {panelists.map(p => (
          <PanelistWithAuthentication
            key={p.id}
            mr={1}
            pb={2}
            panelist={p}
            person={p}
            firstName={p.firstName}
            lastName={p.lastName}
            logo={p.avatarUrl || p.company?.logoUrl}
            checked={selectedPanelists.find(panelist => panelist.id === p.id)}
            onChange={handleChange}
            onInputChange={handleInputChange}
            authentications={p.carrierAuthentications.nodes}
          />
        ))}
      </GradientRow>
    </Column>
  )
}

export default SelectPanelistsWithAuthentications
