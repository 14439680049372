import React from 'react'

import { Column, Row, ToggleLink } from 'atoms'
import Field from 'molecules/Field'
import { Coverages } from 'pages/shared/panels'
import { money } from 'atoms/$'
import { formatDate } from 'util/date'
import { useToggle } from 'hooks'
import DocumentsTable from 'molecules/DocumentUpload/DocumentsTable'
import Endorsements from 'cyber/Carrier/Endorsements'

export const getSections = () => [
  { title: 'Third Party Insuring Agreements', type: 'ThirdParty' },
  { title: 'First Party Insuring Agreements', type: 'FirstParty' },
  { title: 'Cyber Crime Insuring Agreements', type: 'CyberCrime' },
]

export const getCoverages = quotation =>
  quotation.coverages.map(coverage => ({
    quote_id: quotation.id,
    col1: coverage.limit,
    col2: coverage.deductible,
    ...coverage,
  }))

const QuoteContent = ({ quotation }) => {
  const [showDetails, toggleDetails] = useToggle(false)
  const endorsements = quotation.endorsements.filter(
    endorsement => endorsement.name && endorsement.limit
  )

  return (
    <>
      <Row mt={2}>
        <Field label="Limit" value={money(quotation.limit)} width="33.3%" />
        <Field
          label="Deductible"
          value={money(quotation.deductible)}
          width="33.3%"
        />
        <Field
          label="Admitted?"
          value={quotation.admitted ? 'Yes' : 'No'}
          width="33.3%"
        />
      </Row>
      <Row mt={2} mb={2}>
        <Field
          label="Cost for insured"
          value={money(quotation.quotedLines[0].clientCost)}
          width="33.3%"
        />
        <Field
          label="Annual Premium"
          value={money(quotation.quotedLines[0].annualPremium)}
          width="33.3%"
        />
        <Field label="Fee" value={money(quotation.fee)} width="33.3%" />
      </Row>
      <Row mt={2} mb={2}>
        <Field
          label="Policy Effective Date"
          value={formatDate(quotation.coverageStartDate, 'MMMM DD, YYYY')}
          width="33.3%"
        />
        {quotation.quotedLines[0].policyNumber && (
          <Field
            label="Policy Number"
            value={quotation.quotedLines[0].policyNumber}
          />
        )}
      </Row>

      <Column mb={2}>
        <ToggleLink onClick={toggleDetails}>
          {showDetails ? 'Hide' : 'Show'} Insuring Agreements & Endorsements
        </ToggleLink>
        {showDetails && (
          <Row mt={1}>
            <Column
              pr={2}
              borderRightWidth="1px"
              borderRightColor="secondaryBlue"
              borderRightStyle="solid">
              <Coverages
                coverages={getCoverages(quotation)}
                sections={getSections()}
                emptyLabel="No insuring agreements provided."
                col1Header="Limit"
                col2Header="Deductible"
              />
            </Column>
            <Column pl={2}>
              <Endorsements endorsements={endorsements}></Endorsements>
            </Column>
          </Row>
        )}
      </Column>
      <DocumentsTable documents={quotation.documents} />
    </>
  )
}

export default QuoteContent
