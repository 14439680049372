import isEqual from 'lodash/isEqual'

export const transformErrors =
  (patterns = {}) =>
  errors => {
    return errors.map(error => transform(error, patterns))
  }

const transform = (error, patterns) => {
  if (error.message === 'is a required property') {
    error.message = 'is required'
  }
  if (error.message === 'should be string') {
    error.message = 'is required'
  }
  if (error.message === 'should NOT be shorter than 1 characters') {
    error.message = 'is required'
  }
  if (error.message === 'should be object') {
    error.message = 'is required'
  }
  if (
    error.message === 'should match exactly one schema in oneOf' ||
    error.message === 'should match "then" schema'
  ) {
    error.message = ''
  }
  if (error.message === 'should NOT have more than 0 items') {
    error.message = ''
  }
  // it will only change error message for required boolean field
  if (isEqual(error.params?.allowedValues, [true])) {
    error.message = 'is required'
  }
  if (/should match pattern/.test(error.message)) {
    if (Object.keys(patterns).includes(error.params.pattern)) {
      error.message = patterns[error.params.pattern]
    } else {
      error.message = 'is not in the correct format'
    }
  }
  if (/should match format/.test(error.message)) {
    const match = error.message.match(/should match format "(?<format>[^"]+)"/)
    error.message = `invalid ${match.groups.format}`
  }

  if (/should be >= 0/.test(error.message)) {
    error.message = `can't be negative`
  }
  return error
}
