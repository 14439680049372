import React from 'react'

import {
  TextRenderer,
  AdmittedRenderer,
  NavButtonRenderer,
  UnderwriterRenderer,
  BindButtonRenderer,
  PercentRenderer,
} from 'molecules/PanelQuoteComparator/renderers'
import { QUOTE_STATUSES } from 'panels/constants'
import renderQuotedLines from './ml/quotedLines'
import PremiumRenderer from 'molecules/PanelQuoteComparator/renderers/ml/PremiumRenderer'
import AMBestLabel from 'molecules/AMBestLabel'
import isEmpty from 'lodash/isEmpty'
import { NON_BINDABLE_STATES } from '../utils'

const QUOTED_LINES = [
  'DirectorsAndOfficersQuotedLine',
  'EmploymentPracticesLiabilityQuotedLine',
  'FidelityCrimeQuotedLine',
  'FiduciaryQuotedLine',
  'SpecialRiskQuotedLine',
]

const hasStatus = status => entries =>
  entries.some(entry => status === entry.status.toLowerCase())

const criteria = ({ qLines, showCommission }) => {
  return {
    context: {
      bindable: entries =>
        !entries.some(entry =>
          NON_BINDABLE_STATES.includes(entry.status.toLowerCase())
        ),
      isQuoted: entries => {
        let mapping = {}
        entries.forEach(entry => {
          if (entry.id) {
            mapping[entry.id] = true
          }
        })
        return mapping
      },
      binding: hasStatus(QUOTE_STATUSES.binding),
      bound: hasStatus(QUOTE_STATUSES.bound),
      issued: hasStatus(QUOTE_STATUSES.issued),
      referred: hasStatus(QUOTE_STATUSES.referred),
      extracted: hasStatus(QUOTE_STATUSES.extracted),
      pending: hasStatus(QUOTE_STATUSES.pending),
      statusMessage: entries => entries.some(entry => entry.statusMessage),
      nonAPICarriers: entries =>
        entries.some(entry => !entry.isApi && !entry.externalCarrier),
    },
    sections: [
      {
        criteria: [
          {
            key: 'status',
            label: 'Status',
            renderer: TextRenderer,
          },
          {
            key: 'ambestRating',
            label: <AMBestLabel />,
            renderer: TextRenderer,
            fallback: 'NR',
          },
          {
            key: 'retailCommissionPercentage',
            label: 'Retailer Commission',
            renderer: PercentRenderer,
            decimal: true,
            showIf: () => showCommission,
          },
          {
            key: 'admitted',
            label: 'Admitted',
            renderer: AdmittedRenderer,
          },
          {
            key: 'annualPremium',
            label: 'Total Annual Premium',
            renderer: PremiumRenderer,
            showIf: ({ context }) => !isEmpty(context.isQuoted),
          },
          {
            key: 'policyNumber',
            label: 'Policy Number',
            renderer: TextRenderer,
            props: { fontWeight: 2 },
            showIf: ({ context }) => context.bound || context.issued,
          },
          {
            label: 'Subjectivities',
            renderer: NavButtonRenderer,
            cellText: 'View/Edit',
            tertiary: true,
            edit: true,
            to: ({ quote }) => {
              return `${quote.submissionId}/quotes/${quote.id}/edit`
            },
          },
          {
            renderer: NavButtonRenderer,
            label: 'View Details',
            secondary: true,
            to: ({ quote }) => `${quote.submissionId}/quotes/${quote.id}`,
            show: ({ quote }) =>
              Boolean(quote.id) &&
              !quote.isManual &&
              !quote.isApi &&
              !quote.isExtracted,
            showIf: ({ context }) =>
              context.nonAPICarriers && !isEmpty(context.isQuoted),
          },
          {
            renderer: BindButtonRenderer,
            label: 'Bind Quote',
            showIf: ({ context }) =>
              context.bindable && !isEmpty(context.isQuoted),
            show: ({ quote }) =>
              quote.id !== undefined &&
              !quote.isManual &&
              (quote.indication || quote.status !== QUOTE_STATUSES.referred),
            to: quote => `${quote.submissionId}/quotes/${quote.id}/bind`,
          },
          {
            renderer: UnderwriterRenderer,
            label: 'Underwriter',
            path: ({ panelId, submissionId }, quotation) =>
              `/panels/${panelId}/submissions/${submissionId}/quotes/${quotation.id}`,
            showIf: ({ context }) => !isEmpty(context.isQuoted),
          },
        ],
      },
      () => {
        return QUOTED_LINES.map(ql => renderQuotedLines(ql, qLines))
      },
    ],
  }
}

export default criteria
