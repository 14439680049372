import React, { useContext } from 'react'

const WholesalerSubmissionContext = React.createContext({
  wholesalerCollaborations: [],
  hasWholesaler: false,
  userIsWholesaler: false,
  collaboratingWholesalerIds: [],
  submissionOwnerId: null,
  isPanelOwner: true
})

export const useIsWholesalerGuest = () => {
  const { hasWholesaler, userIsWholesaler } = useContext(
    WholesalerSubmissionContext
  )
  return hasWholesaler && !userIsWholesaler
}

export default WholesalerSubmissionContext
