import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { usePanelQuery } from 'graphql/api'

import { Column, Loading, Subtitle } from 'atoms'
import { Carrier as CarrierDiv, GradientRow } from './components'
import { get, set } from 'util/localStorage'
import { TextField } from '@material-ui/core'
import { useFeature } from 'hooks'

export const getCarriers = panel => {
  if (
    !panel ||
    !Object.keys(panel).includes('panelists', 'panelistConfigurations')
  ) {
    return []
  }
  return panel.panelists.nodes
    .filter(panelist => panelist.type === 'Reinsurer')
    .map(panelist => ({
      id: panelist.id,
      name: panelist.firstName,
      logoUrl: panelist.company.logoUrl,
      disabled: getDisabled(
        panel.panelistConfigurations.nodes,
        panelist.firstName
      ),
    }))
}

export const getDisabled = (panelistConfigurations, firstName) => {
  if (
    !panelistConfigurations ||
    !firstName ||
    !Array.isArray(panelistConfigurations)
  ) {
    return false
  }

  const foundConfiguration = panelistConfigurations.find(
    config => config.panelist && config.panelist.firstName === firstName
  )

  return foundConfiguration === undefined ? false : foundConfiguration.disabled
}

const Carrier = ({
  id,
  carrier,
  onChange,
  selectedCarriers,
  localStorageKey,
  showAgentCode,
  agentCodeRequired = false,
  error = false,
}) => {
  const checked = selectedCarriers.find(carrier => carrier.id === id)
  const isMounted = useRef(false)
  const [agentCode, setAgentCode] = useState(() =>
    checked ? checked.agentCode : get(localStorageKey, '')
  )
  const displayError = error && agentCodeRequired && !!checked && !agentCode
  const handleChange = (id, agentCode) => {
    if (!checked) {
      onChange([...selectedCarriers, { id, agentCode }])
    } else {
      onChange(selectedCarriers.filter(carrier => carrier.id !== id))
    }
  }
  useEffect(() => {
    if (isMounted.current) {
      set(localStorageKey, agentCode)
      if (checked) {
        onChange(
          selectedCarriers.map(carrier =>
            carrier.id === id ? { id, agentCode } : carrier
          )
        )
      }
    } else {
      isMounted.current = true
    }
  }, [agentCode])

  if (!showAgentCode) {
    return (
      <CarrierDiv
        mr={2}
        name={carrier.name}
        id={carrier.id}
        logo={carrier.logoUrl}
        onChange={() => handleChange(id, '')}
        checked={!!checked}
        disabled={carrier.disabled}
      />
    )
  } else {
    return (
      <CarrierDiv
        name={carrier.name}
        id={carrier.id}
        logo={carrier.logoUrl}
        onChange={() => handleChange(id, agentCode)}
        checked={!!checked}
        disabled={carrier.disabled}>
        <TextField
          label="Agent Code"
          required={agentCodeRequired}
          style={{ width: '120px', marginBottom: '1rem' }}
          value={agentCode}
          onChange={e => setAgentCode(e.target.value)}
          error={displayError}
          helperText={displayError && 'is required'}
        />
      </CarrierDiv>
    )
  }
}
const SelectEmailCarriersField = ({
  formData: selectedCarriers = [],
  onChange,
  uiSchema,
  schema: { items: schema },
  errorSchema = {},
}) => {
  const styles = uiSchema['ui:styles'] || {}
  const { panelId } = useParams()
  const { data, loading, queryError } = usePanelQuery({
    variables: { panelId },
  })
  const showAgentCode = useFeature('emailAgentCode')
  const agentCodeRequired = schema?.required?.includes('agentCode')
  const error = Object.keys(errorSchema).length > 0

  if (queryError) {
    throw error
  }

  if (loading) {
    return <Loading />
  }
  const carriers = getCarriers(
    data.viewer.user.company && data.viewer.user.company.panel
  )
  return (
    <Column>
      <Subtitle m={0}>SELECTED CARRIERS ({selectedCarriers.length})</Subtitle>
      <GradientRow
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
        p={2}
        pr={'0'}
        mt={0}
        {...styles}>
        {carriers.map(carrier => (
          <Carrier
            key={carrier.id}
            carrier={carrier}
            id={carrier.id}
            agentCodeRequired={agentCodeRequired}
            showAgentCode={showAgentCode}
            onChange={onChange}
            error={error}
            selectedCarriers={selectedCarriers}
            localStorageKey={`agentCode-panel${panelId}-carrier${carrier.id}`}
            disabled={carrier.disabled}
          />
        ))}
      </GradientRow>
    </Column>
  )
}

export default SelectEmailCarriersField
