import React, { useContext } from 'react'

import FeaturesContext from '../contexts/features'

export const useFeature = (feature, enabled = true) => {
  const context = useContext(FeaturesContext)

  return (
    context?.features &&
    (enabled ? context.features[feature] : !context.features[feature])
  )
}

const FeatureSwitch = ({ children, withFeature, withoutFeature }) => {
  if (withFeature === withoutFeature) {
    throw new Error(
      'Only one of withFeature and withoutFeature should be applied'
    )
  }

  return (
    <FeaturesContext.Consumer>
      {context =>
        context &&
        context.features &&
        ((withFeature && context.features[withFeature]) ||
          (withoutFeature && !context.features[withoutFeature])) &&
        children
      }
    </FeaturesContext.Consumer>
  )
}

export default FeatureSwitch
