export { default as DetailedUploader } from './DetailedUploader'
export { default as SimpleUploader } from './SimpleUploader'
export { default as BasicUploader } from './BasicUploader'
export { default as DocumentUploader } from './DocumentUploader'

export const getNextVersion = (allDocs, category) => {
  const docsInCat = allDocs.filter(doc => doc.category === category)
  if (!docsInCat || docsInCat.length === 0) {
    return 1
  }

  return docsInCat.reduce((acc, doc) => Math.max(doc.version, acc), 1) + 1
}
