import React from 'react'
import styled from 'styled-components'

import { Checkbox } from '@material-ui/core'
import { faSquare } from '@fortawesome/pro-regular-svg-icons'
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons'

import { Column, Row, Div, Text, Icon } from 'atoms'

export const Img = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

export const GradientRow = styled(Row)`
  border-radius: 0.5rem;
`

export const CarrierDiv = styled(Div)`
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 0.5rem;

  &:hover {
    background: ${props => props.theme.colors.lightGrey};
  }
`

export const Carrier = ({
  logo,
  checked,
  onChange,
  name,
  children,
  disabled,
  ...rest
}) => (
  <Column width="auto" mr={2} mb={1}>
    <Text textAlign="center" mb={0} fontSize="3" fontWeight={2}>
      {name}
    </Text>
    {children}
    <CarrierDiv
      p={0}
      background={disabled ? 'lightGrey' : 'white'}
      borderWidth="2px"
      borderStyle="solid"
      borderColor={'primaryText'}>
      <Img src={logo} onClick={disabled ? null : onChange} />
      <Div position="absolute" top="-1rem" left="-1rem">
        <Checkbox
          disabled={disabled}
          color="primary"
          icon={
            <Icon
              bg={disabled ? 'lightGray' : 'white'}
              color="primaryText"
              icon={faSquare}
              fontSize="xl"
            />
          }
          checkedIcon={
            <Icon
              bg={disabled ? 'lightGray' : 'white'}
              color="primaryText"
              icon={faCheckSquare}
              fontSize="xl"
            />
          }
          id={rest.id}
          checked={checked}
          onChange={onChange}
        />
      </Div>
    </CarrierDiv>
  </Column>
)
