import styled from 'styled-components'
import { typography, space, color } from 'styled-system'

const Amount = styled.span`
  ${color}
  ${typography}
  ${space}
`

Amount.defaultProps = {
  m: '0px',
  fontSize: 2,
  fontWeight: 2,
  color: 'primary',
  fontFamily: 'secondary',
}

export default Amount
