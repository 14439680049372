import React from 'react'
import { EnhancedModal, Subtitle, Div } from 'atoms'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'
import arrayMutators from 'final-form-arrays'

import mapDocs from 'util/mapDocs'
import AnswerForm from './AnswerForm'
import { useEditAnswerMutation } from 'graphql/api'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'

const EditAnswerModal = ({
  scope = QUESTION,
  questions,
  onClose,
  onCompleted,
}) => {
  const { questionId } = useParams()
  const question = questions.find(question => question.id === questionId)
  const answer = question.answer
  const translator = qandaTranslator(scope)
  const [editAnswer] = useEditAnswerMutation({
    refetchQueries: translator('Question') === 'Subjectivity' && [
      'Subjectivities',
    ],
  })
  const handleSubmit = values => {
    editAnswer({
      variables: {
        input: {
          answerId: answer.id,
          attributes: {
            ...values.attributes,
            documents:
              values.attributes.documents &&
              mapDocs(values.attributes.documents),
          },
        },
      },
    }).then(({ data }) => {
      if (data.editAnswer.errors) {
        toast.error(data.editAnswer.errors)
        return data.editAnswer.errors
      } else {
        onCompleted()
      }
    })
  }
  return (
    <Form
      mutators={arrayMutators}
      onSubmit={handleSubmit}
      initialValues={{
        attributes: {
          text: answer.text,
          documents: answer.documents,
        },
      }}>
      {({ handleSubmit }) => (
        <EnhancedModal>
          <Div p={2}>
            <Subtitle>Edit {translator('Answer')}</Subtitle>
            <AnswerForm
              question={question}
              scope={scope}
              onClose={onClose}
              onCompleted={onCompleted}
              documentCategory={translator('Answer')}
              onSubmit={handleSubmit}
            />
          </Div>
        </EnhancedModal>
      )}
    </Form>
  )
}

export default EditAnswerModal
