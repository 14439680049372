import React from 'react'

import { Column } from 'atoms'
import Document from 'molecules/Document'

const DocumentRenderer = ({ children, carrier, country }) => {
  let documents = [...children]

  if (country == 'CA' && carrier == 'CFC') {
    documents.push({
      name: 'CFC Risk Management Services.pdf',
      type: 'application/pdf',
      url: `${location.protocol}//${location.host}/pdfs/CFC%20Risk%20Management%20Services.pdf`,
      filename: 'CFC Risk Management.pdf',
    })
  }

  return (
    <Column>
      {documents.map(doc => {
        return (
          <Document
            alignItems="center"
            showTime={false}
            key={doc.id}
            document={doc}
          />
        )
      })}
    </Column>
  )
}

export default DocumentRenderer
