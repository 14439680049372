import React from 'react'
import * as Sentry from '@sentry/react'

import { Error } from 'atoms'

export const DevError = props => {
  if (process.env.NODE_ENV === 'development') {
    return <Error {...props} />
  } else {
    Sentry.captureMessage(props.children)
  }
  return <Error>Something went wrong</Error>
}

export default Error
