import { useContext } from 'react'

import BrandingContext from 'components/contexts/branding'
import { RELAY, NFP } from 'components/theme/branding'

const useBranding = () => {
  const { brand, ...context } = useContext(BrandingContext)
  return {
    ...context,
    brand: brand,
    brandName: context.brandName,
    isNFP: brand === NFP,
    isRelay: brand === RELAY,
    isWhitelabel: brand === 'Whitelabelled',
  }
}

export default useBranding
