import React from 'react'
import styled from 'styled-components'
import { branch, renderComponent, compose, mapProps } from 'recompose'
import { useHistory } from 'react-router-dom'

import { colors, fonts } from '../components/theme'
import { H1 } from '../components/theme/typography'
import _Button from '../components/common/Button'
import _ExternalButton from 'components/common/ExternalButton'
import img from '../images/ripped.png'
import Header from '../components/header/Header'
import UserContext, { isLoggedIn } from 'components/contexts/user'
import { Flex } from 'atoms'

const Container = styled.div`
  height: 100vh;
  background-color: ${colors.background};
  background-image: url(${img});
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
  background-position: calc(50% - 40px) calc(50% + 80px);
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
  text-align: center;
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 6em;
`

const Title = styled(H1)`
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 1em;
  font-family: ${fonts.primary};
`

const Button = styled(_Button)`
  width: 300px;
`

const ExternalButton = styled(_ExternalButton)`
  width: 300px;
`

const NotFound = ({ showHeader = true, entity = 'page' }) => {
  const history = useHistory()

  if (!showHeader) {
    return (
      <Flex justifyContent="center">
        <ContentContainer>
          <Title>There is no such {entity} (404)</Title>
          <ButtonContainer>
            <UserContext.Consumer>
              {user =>
                isLoggedIn(user) ? (
                  <Button primary onClick={() => history.push('/')}>
                    Go To Dashboard
                  </Button>
                ) : (
                  <ExternalButton href="https://relayplatform.com">
                    Learn About Relay
                  </ExternalButton>
                )
              }
            </UserContext.Consumer>
          </ButtonContainer>
        </ContentContainer>
      </Flex>
    )
  }

  return (
    <Container>
      <Header />
      <ContentContainer>
        <Title>There is no such {entity} (404)</Title>
        <ButtonContainer>
          <UserContext.Consumer>
            {user =>
              isLoggedIn(user) ? (
                <Button primary onClick={() => history.push('/')}>
                  Go To Dashboard
                </Button>
              ) : (
                <ExternalButton href="https://relayplatform.com">
                  Learn About Relay
                </ExternalButton>
              )
            }
          </UserContext.Consumer>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )
}

export const handleNotFound = (condition, props = {}) => {
  return branch(
    condition,
    compose(
      mapProps(() => props),
      renderComponent(NotFound)
    )
  )
}

export const error404 =
  'Network error: Response not successful: Received status code 404'

export default NotFound
