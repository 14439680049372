import React from 'react'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

import Icon from 'atoms/Icon'
import Div from 'atoms/Div'
import { Flex, Text } from 'atoms'

const CheckList = ({ list, title = 'Checklist' }) => {
  return (
    <Div>
      <Div mb={1} fontSize="1" fontWeight="2">
        {title}
      </Div>
      {list.map((item, index) => (
        <Flex key={index} alignItems="flex-start">
          <Icon icon={faCheck} mr={1} mt="0.2rem" />
          <Text fontSize="1" fontWeight="1">
            {item}
          </Text>
        </Flex>
      ))}
    </Div>
  )
}

CheckList.displayName = 'CheckList'

export default CheckList
