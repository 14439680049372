import React from 'react'
import styled from 'styled-components'

import { colors, fonts } from '../components/theme'
import Button from '../components/common/Button'

const Container = styled.div`
  height: 100vh;
  font-family: ${fonts.primary};
  color: ${colors.primaryText};
  background-color: ${colors.background};
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center
  align-items: center;
`

const BarebonesErrorPage = () => (
  <Container>
    <h1>Oh no!</h1>
    <p>Sorry, something went wrong!</p>
    <Button onClick={() => window.zE.activate()}>Contact Support</Button>
  </Container>
)

export default BarebonesErrorPage
