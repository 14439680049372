import React from 'react'

import { $, Row } from 'atoms'

const PremiumRenderer = props => {
  const disableColor = props.locked ? { color: '#AAAAAA' } : {}

  return (
    <Row fontWeight="700" fontSize="18px">
      <$ fallback="—" {...props} {...disableColor} />
    </Row>
  )
}

export default PremiumRenderer
