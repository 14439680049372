import at from 'lodash/at'
import capitalize from 'lodash/capitalize'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import debounce from 'lodash/debounce'
import difference from 'lodash/difference'
import drop from 'lodash/drop'
import each from 'lodash/each'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import first from 'lodash/first'
import lowerFirst from 'lodash/lowerFirst'
import flattenDeep from 'lodash/flattenDeep'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import identity from 'lodash/identity'
import indexOf from 'lodash/indexOf'
import intersection from 'lodash/intersection'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import isPlainObject from 'lodash/isPlainObject'
import isRegExp from 'lodash/isRegExp'
import isString from 'lodash/isString'
import join from 'lodash/join'
import kebabCase from 'lodash/kebabCase'
import last from 'lodash/last'
import map from 'lodash/map'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import merge from 'lodash/merge'
import mergeWith from 'lodash/mergeWith'
import noop from 'lodash/noop'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import range from 'lodash/range'
import reduce from 'lodash/reduce'
import remove from 'lodash/remove'
import replace from 'lodash/replace'
import reverse from 'lodash/reverse'
import set from 'lodash/set'
import slice from 'lodash/slice'
import sortBy from 'lodash/sortBy'
import split from 'lodash/split'
import startCase from 'lodash/startCase'
import tap from 'lodash/tap'
import thru from 'lodash/thru'
import toPairs from 'lodash/toPairs'
import fromPairs from 'lodash/fromPairs'
import toUpper from 'lodash/toUpper'
import trim from 'lodash/trim'
import truncate from 'lodash/truncate'
import union from 'lodash/union'
import unionBy from 'lodash/unionBy'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import unset from 'lodash/unset'
import without from 'lodash/without'
import zipObject from 'lodash/zipObject'
import camelCase from 'lodash/camelCase'

import {
  dot,
  undot,
  dottedKeys,
  dottedPick,
  dottedOmit,
  dottedTransformKeys,
} from '../dottedUtil'
import { isPresent } from '../langUtil'

// Much more performant version to lodash's omit
// reference: https://levelup.gitconnected.com/omit-is-being-removed-in-lodash-5-c1db1de61eaf
const omit = (originalObject = {}, keysToOmit) => {
  const clonedObject = { ...originalObject }

  const keys = isArray(keysToOmit) ? keysToOmit : [keysToOmit]
  for (const path of keys) {
    unset(clonedObject, path)
  }

  return clonedObject
}

// Only imports lodash methods actually used across the application
class LodashChain {
  constructor(value) {
    this.value = cloneDeep(value)
  }

  get(path, defaultValue = undefined) {
    this.value = get(this.value, path, defaultValue)
    return this
  }

  set(path, value) {
    set(this.value, path, value)
    return this
  }

  pick(paths) {
    this.value = pick(this.value, paths)
    return this
  }

  map(iteratee = identity) {
    this.value = map(this.value, iteratee)
    return this
  }

  join(separator = ',') {
    this.value = join(this.value, separator)
    return this
  }

  split(separator = ',') {
    this.value = split(this.value, separator)
    return this
  }

  first() {
    this.value = first(this.value)
    return this
  }

  lowerFirst() {
    this.value = lowerFirst(this.value)
    return this
  }

  camelCase() {
    this.value = camelCase(this.value)
    return this
  }

  last() {
    this.value = last(this.value)
    return this
  }

  capitalize() {
    this.value = capitalize(this.value)
    return this
  }

  uniq() {
    this.value = uniq(this.value)
    return this
  }

  trim(chars = undefined) {
    this.value = trim(this.value, chars)
    return this
  }

  truncate({ length = 30, omission = '...', separator = undefined } = {}) {
    this.value = truncate(this.value, { length, omission, separator })
    return this
  }

  replace(pattern, replacement) {
    this.value = replace(this.value, pattern, replacement)
    return this
  }

  without(values) {
    this.value = without(this.value, values)
    return this
  }

  compact() {
    this.value = compact(this.value)
    return this
  }

  groupBy(iteratee) {
    this.value = groupBy(this.value, iteratee)
    return this
  }

  cloneDeep() {
    this.value = cloneDeep(this.value)
    return this
  }

  isEmpty() {
    this.value = isEmpty(this.value)
    return this
  }

  isPresent() {
    this.value = isPresent(this.value)
    return this
  }

  isNumber() {
    this.value = isNumber(this.value)
    return this
  }

  isEqual(other) {
    this.value = isEqual(this.value, other)
    return this
  }

  union(otherArray) {
    this.value = union(this.value, otherArray)
    return this
  }

  unionBy(iteratee) {
    this.value = unionBy(...this.value, iteratee)
    return this
  }

  intersection(other) {
    this.value = intersection(this.value, other)
    return this
  }

  mapValues(iteratee) {
    this.value = mapValues(this.value, iteratee)
    return this
  }

  mapKeys(iteratee) {
    this.value = mapKeys(this.value, iteratee)
    return this
  }

  indexOf(value, fromIndex = 0) {
    this.value = indexOf(this.value, value, fromIndex)
    return this
  }

  remove(predicate) {
    this.value = remove(this.value, predicate)
    return this
  }

  debounce(waitInMs = 0, options = {}) {
    const { leading = false, trailing = true, ...rest } = options

    this.value = debounce(this.value, waitInMs, { leading, trailing, ...rest })
    return this
  }

  merge(other) {
    this.value = merge(this.value, other)
    return this
  }

  mergeWith(otherObject, customizer) {
    this.value = mergeWith(this.value, otherObject, customizer)
    return this
  }

  filter(predicate) {
    this.value = filter(this.value, predicate)
    return this
  }

  flattenDeep() {
    this.value = flattenDeep(this.value)
    return this
  }

  orderBy(iteratees = identity, orders = []) {
    this.value = orderBy(this.value, iteratees, orders)
    return this
  }

  sortBy(iteratees = identity) {
    this.value = sortBy(this.value, iteratees)
    return this
  }

  pickBy(iteratees = identity) {
    this.value = pickBy(this.value, iteratees)
    return this
  }

  reduce(iteratees, accumulator = {}) {
    this.value = reduce(this.value, iteratees, accumulator)
    return this
  }

  difference(otherArray) {
    this.value = difference(this.value, otherArray)
    return this
  }

  isPlainObject() {
    this.value = isPlainObject(this.value)
    return this
  }

  isArray() {
    this.value = isArray(this.value)
    return this
  }

  isBoolean() {
    this.value = isBoolean(this.value)
    return this
  }

  isString() {
    this.value = isString(this.value)
    return this
  }

  isNil() {
    this.value = isNil(this.value)
    return this
  }

  isNull() {
    this.value = isNull(this.value)
    return this
  }

  concat(...values) {
    this.value = concat(this.value, ...values)
    return this
  }

  drop(n = 1) {
    this.value = drop(this.value, n)
    return this
  }

  slice(start = 0, end = this.value.length) {
    this.value = slice(this.value, start, end)
    return this
  }

  startCase() {
    this.value = startCase(this.value)
    return this
  }

  kebabCase() {
    this.value = kebabCase(this.value)
    return this
  }

  find(predicate = identity, fromIndex = 0) {
    this.value = find(this.value, predicate, fromIndex)
    return this
  }

  findIndex(predicate = identity, fromIndex = 0) {
    this.value = findIndex(this.value, predicate, fromIndex)
    return this
  }

  toPairs() {
    this.value = toPairs(this.value)
    return this
  }

  fromPairs() {
    this.value = fromPairs(this.value)
    return this
  }

  uniqBy(iteratee) {
    this.value = uniqBy(this.value, iteratee)
    return this
  }

  range(end = undefined, step = undefined) {
    this.value = range(this.value, end, step)
    return this
  }

  zipObject(values) {
    this.value = zipObject(this.value, values)
    return this
  }

  isRegExp() {
    this.value = isRegExp(this.value)
    return this
  }

  at(...paths) {
    this.value = at(this.value, ...paths)
    return this
  }

  omit(...keys) {
    this.value = omit(this.value, ...keys)
    return this
  }

  partition(predicate = identity) {
    this.value = partition(this.value, predicate)
    return this
  }

  tap(interceptor) {
    this.value = tap(this.value, interceptor)
    return this
  }

  thru(interceptor) {
    this.value = thru(this.value, interceptor)
    return this
  }

  reverse() {
    this.value = reverse(this.value)
    return this
  }

  dot({ separator = '.', keepArray = false, useBrackets = true } = {}) {
    this.value = dot(this.value, { separator, keepArray, useBrackets })
    return this
  }

  undot({ separator = '.' } = {}) {
    this.value = undot(this.value, { separator })
    return this
  }

  dottedKeys({ ignoreArrayIndexKeys = false, separator = '.' } = {}) {
    this.value = dottedKeys(this.value, { ignoreArrayIndexKeys, separator })
    return this
  }

  keys() {
    this.value = Object.keys(this.value)
    return this
  }

  values() {
    this.value = Object.values(this.value)
    return this
  }

  dottedPick(keys) {
    this.value = dottedPick(this.value, keys)
    return this
  }

  dottedOmit(keys) {
    this.value = dottedOmit(this.value, keys)
    return this
  }

  forEach(iteratee = identity) {
    this.value = forEach(this.value, iteratee)
    return this
  }

  dottedTransformKeys(keysToTransform = {}) {
    if (isEmpty(keysToTransform)) return this

    this.value = dottedTransformKeys(this.value, keysToTransform)
    return this
  }
}

const camelObject = data => {
  return mapKeys(data, (_, k) => camelCase(k))
}

function chain(...initialValue) {
  if (arguments.length >= 2) {
    return new LodashChain(initialValue)
  }

  return new LodashChain(initialValue[0])
}

export default LodashChain

export {
  chain,
  get,
  set,
  unset,
  pick,
  compact,
  map,
  identity,
  join,
  uniq,
  trim,
  replace,
  without,
  isEmpty,
  isEqual,
  isNumber,
  unionBy,
  union,
  mapValues,
  mapKeys,
  indexOf,
  intersection,
  truncate,
  remove,
  debounce,
  filter,
  flattenDeep,
  orderBy,
  sortBy,
  pickBy,
  reduce,
  difference,
  isPlainObject,
  isNil,
  isArray,
  isBoolean,
  isString,
  isNull,
  concat,
  drop,
  slice,
  find,
  findIndex,
  merge,
  mergeWith,
  startCase,
  kebabCase,
  toPairs,
  fromPairs,
  uniqBy,
  range,
  zipObject,
  isRegExp,
  isObject,
  isFunction,
  omit,
  at,
  each,
  noop,
  cloneDeep,
  clone,
  partition,
  reverse,
  tap,
  thru,
  dot,
  undot,
  dottedKeys,
  dottedPick,
  dottedOmit,
  dottedTransformKeys,
  isPresent,
  first,
  lowerFirst,
  last,
  capitalize,
  toUpper,
  camelCase,
  camelObject,
}
