import React, { useState, useEffect } from 'react'

import { Typography, Grid, TextField } from '@material-ui/core'
import currencies from 'data/currencies'

import { formatter, normalizer } from 'util/currency'

const PARTIAL_CENTS = precision => new RegExp(`\\.[0-9]{0,${precision - 1}}0?$`)
const N_DECIMAL_PLACES = n => new RegExp(`\\.[0-9]{${n},}$`)

const Currency = ({
  id,
  currency: _currency = 'USD',
  rawErrors = [],
  value,
  label,
  required,
  onChange,
  onBlur,
  uiSchema,
  schema,
  fullWidth,
}) => {
  const disabled = schema.disabled
  const currency = currencies[_currency || 'USD']

  const separateLabel = uiSchema['ui:labelPosition'] === 'separate'
  const { 'ui:styles': styles = {} } = uiSchema

  const [currentValue, setValue] = useState(formatter(value, currency))

  const [error, setError] = useState(rawErrors[0])

  useEffect(() => {
    if (value !== normalizer(currentValue)) {
      const newValue = formatter(value, currency)
      setValue(newValue === '' ? null : newValue)
    }
  }, [value, currentValue])

  useEffect(() => {
    if (rawErrors?.length > 0) {
      setError(rawErrors[0])
    } else {
      setError('')
    }
  }, [rawErrors])

  const handleChange = event => {
    if (N_DECIMAL_PLACES(currency.precision + 1).test(event.target.value))
      return undefined
    const normalized = normalizer(event.target.value)
    if (normalized.length < 18) {
      onChange(normalized ? normalized : undefined)
      if (normalized.length > 14) {
        // Show a soft error to the user warning them that the number is most
        // likely incorrect due to it's magnitude.
        setError('The value is too large')
      } else {
        setError('')
      }
      if (PARTIAL_CENTS(currency.precision).test(event.target.value)) {
        setValue(event.target.value)
      } else {
        setValue(formatter(event.target.value, currency))
      }
    } else {
      // If the number is 20 digits or more we just stop accepting input. The
      // backend will store 20 digit value without error so autosave will
      // continue working.
      setError('The value is too large')
    }
  }

  const handleBlur = () => {
    setValue(formatter(currentValue, currency))
    if (onBlur) {
      return onBlur(normalizer(currentValue, currency))
    }
  }

  const field = (
    <TextField
      value={currentValue}
      id={id}
      label={separateLabel ? null : label}
      required={required}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      disabled={disabled}
      fullWidth={!!fullWidth}
    />
  )
  const opacity = disabled ? 0.5 : 1
  if (separateLabel) {
    return (
      <Grid
        container
        alignItems="baseline"
        justify="space-between"
        style={{ opacity: opacity }}>
        <Typography component="label" htmlFor={id} style={styles}>
          {label}
        </Typography>
        {field}
      </Grid>
    )
  }

  return field
}

export default Currency
