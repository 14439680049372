import React from 'react'
// import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Box, Checkbox, FormControlLabel } from '@material-ui/core'

const Heading = styled.span`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
}
`

// const Clickable = styled.span`
//   cursor: pointer;
//   color: ${props => props.theme.colors.link};
//   margin-left: 20px;
//   display: inline-block;
// `

const SelectSectionCheckbox = ({ schema, formContext, onChange, ...props }) => {
  const checked = props.formData
  // const { formData } = formContext
  const {
    title,
    // formatData,
    // update,
    itemValue,
  } = schema.config
  // const [data, updateData] = useState(formatData(formData))
  // const [selected, updateSelected] = useState(data)
  // const [selectedAll, updateSelectedAll] = useState(false)

  // const allSelected = data => {
  //   const keys = Object.keys(data)
  //   const selectedList = []
  //   keys.map(key => {
  //     if (data[key]) {
  //       selectedList.push(key)
  //     }
  //   })
  //   return keys.length == selectedList.length
  // }

  // useEffect(() => {
  // const newData = formatData(formData)
  // updateData(newData)
  // updateSelected(newData)
  // updateSelectedAll(allSelected(newData))
  // }, [formData])

  // const selectSection = event => {
  //   event.preventDefault()
  //   let newSelected = {}
  //   Object.keys(selected).forEach(key => {
  //     newSelected[key] = !selectedAll
  //   })
  //   onChange(!selectedAll)
  //   update(formData, newSelected)
  //   updateSelectedAll(allSelected(newSelected))
  // }

  const handleChange = event => {
    const newValue = event.target.checked
    onChange(newValue)
  }

  return (
    <Box key={itemValue}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={checked}
            onChange={handleChange}
            name={itemValue}
          />
        }
        label={
          <>
            <Heading>{title}</Heading>
            {/* <Clickable onClick={selectSection}>
              {selectedAll ? 'Deselect section' : 'Select section'}
            </Clickable> */}
          </>
        }
      />
    </Box>
  )
}

export default SelectSectionCheckbox
