import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import _toNumber from 'lodash/toNumber'
import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'
import isObject from 'lodash/isObject'
import { isArray } from 'lodash'

export const isBlank = arg => {
  if (isNil(arg)) return true
  if (isString(arg)) return isEmpty(arg.trim())
  if (isNumber(arg)) return arg === 0
  if (isObject(arg)) return isEmpty(arg)
  return false
}

export const isPresent = arg => !isBlank(arg)

export const stripNonNumericCharacters = value => {
  if (!isString(value)) return value

  const NON_NUMBER_REGEX = /[^0-9.\\+-]/g

  return value.replace(NON_NUMBER_REGEX, '')
}

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const capitalizeAfterHyphen = string =>
  string.replace(/-[a-z]/g, match => match.toUpperCase())

export const toNumber = value => {
  const number = isString(value)
    ? stripNonNumericCharacters(value)
    : value || '0.00'

  return _toNumber(number)
}

export const toBoolean = bool => {
  const truthyValues = ['1', 'yes', 'true']
  const falseyValues = ['0', 'no', 'false', '']

  if (isBoolean(bool)) {
    return bool
  }

  if (isNil(bool)) return false

  const isStringType = isString(bool)
  if (isStringType && truthyValues.includes(bool.toLowerCase().trim()))
    return true
  if (isStringType && falseyValues.includes(bool.toLowerCase().trim()))
    return false

  return bool
}

export const arrListed = arr => {
  if (!isArray(arr) || arr.length === 0) return ''
  return arr.reduce(
    (a, b, i, array) => a + (i < array.length - 1 ? ', ' : ', and ') + b
  )
}
