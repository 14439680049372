import jp, { mapJson, mapFields } from 'util/jsonpath'
import { pluck, sum } from 'ramda'

export const EndorsementMapping = {
  ...mapFields(['name', 'limit']),
}

export const CoverageMapping = {
  ...mapFields([
    'blanketLimit',
    'limit',
    'name',
    'premium',
    'coverage',
    'deductible',
    'homeCoverageLimit',
  ]),
}

export const EnhancementMapping = {
  ...mapFields(['limit', 'premium', 'deductible', 'enhancement']),
}

export const LimitOptionMapping = {
  ...mapFields(['limit', 'totalPremium', 'coverage']),
}

export const AdditionalOptionMapping = {
  ...mapFields(['deductible', 'premium', 'option']),
}

export const autoLineMapping = line => {
  return {
    agreedValues: line.agreedValues,
    combinedLimit: line.combinedLimit,
    bodilyInjuryAndPropertyDamage: line.bodilyInjuryAndPropertyDamage,
    bodilyInjuryPerPerson: line.bodilyInjuryPerPerson,
    bodilyInjuryEachAccident: line.bodilyInjuryEachAccident,
    propertyDamage: line.propertyDamage,
    medicalPayments: line.medicalPayments,
    underinsuredMotorists: line.underinsuredMotorists,
    extendedTowingAndLabor: line.extendedTowingAndLabor,
    fullGlassCoverage: line.fullGlassCoverage,
    collisionDeductible: line.collisionDeductible,
    comprehensiveDeductible: line.comprehensiveDeductible,
    additionalCoverages: line.additionalCoverages,
  }
}

export const QuotedLinesMapping = {
  ...mapFields(['__typename', 'additionalFeatures', 'coverableId']),
  coverages: line => {
    if (line.coverages) {
      return line.coverages.map(coverage =>
        mapJson(coverage || {}, CoverageMapping)
      )
    }
  },
  enhancements: line => {
    if (line.enhancements) {
      return line.enhancements.map(enhancement =>
        mapJson(enhancement || {}, EnhancementMapping)
      )
    }
  },
  limitOptions: line => {
    if (line.limitOptions) {
      return line.limitOptions.map(option =>
        mapJson(option || {}, LimitOptionMapping)
      )
    }
  },
  additionalOptions: line => {
    if (line.additionalOptions) {
      return line.additionalOptions.map(option =>
        mapJson(option || {}, AdditionalOptionMapping)
      )
    }
  },
  totalLinePremium: line =>
    line.coverages
      ? sum(pluck('premium', line.coverages).map(x => parseInt(x)))
      : 0,

  auto: line =>
    line.__typename === 'PCGAutoQuotedLineType' ? autoLineMapping(line) : null,

  totalPremium: line => (line.totalPremium ? parseInt(line.totalPremium) : 0),
  address: line => line.address,
  allOtherPerilDeductible: line =>
    line.allOtherPerilDeductible ? parseInt(line.allOtherPerilDeductible) : 0,
}

export const QuotationMapping = {
  ...mapFields([
    'id',
    'participationId',
    'submissionId',
    'submissionOwner',
    'status',
    'currentPolicy',
    'selected',
    'declinationReason',
    'coverages',
    'documents',
    'totalPremium',
  ]),
  isPCG: quotation => {
    return quotation.__typename === 'PCGQuotation'
  },
  quotedLines: quotation => {
    if (quotation.quotedLines) {
      return quotation.quotedLines.nodes
        .map(line => mapJson(line || {}, QuotedLinesMapping))
        .sort((lineA, lineB) => lineA.coverableId - lineB.coverableId)
    }
  },
  isExtracted: quotation =>
    quotation.type === 'PCG::EmailQuotation' && quotation.status == 'outdated',
  carrier: ['user.company.name', 'externalCarrier.name'],
  logoUrl: ['user.company.logoUrl', 'externalCarrier.logoUrl'],
  underwriterEmail: ['user.email'],
  underwriterFirstName: ['user.firstName'],
  underwriterLastName: () => '',
  isManual: quotation => quotation.type === 'PCG::ManualQuotation',
  isCarrier: quotation =>
    quotation.type === 'PCG::EmailQuotation' || quotation.user,
  isDeletable: quotation => quotation.type === 'PCG::ManualQuotation',
}

export const SubmissionMapping = {
  ...mapFields(['id', 'country', 'name', 'lineOfBusiness']),
  panelId: 'panel.id',
  quotations: submission => {
    const participations = jp.query(submission, 'participations.nodes[*]')
    const coverages = submission?.coverages
    return participations.reduce(
      (memo, { id: participationId, createdAt, user, quotations }) => {
        if (quotations.nodes.length > 0) {
          return memo.concat(
            quotations.nodes.map(quotation => {
              return mapJson(
                {
                  ...quotation,
                  participationId,
                  requestedAt: createdAt,
                  submissionId: submission.id,
                  submissionOwner: submission.owner.fullName,
                  user,
                },
                QuotationMapping
              )
            })
          )
        } else {
          return memo.concat([
            mapJson(
              {
                status: 'pending',
                participationId,
                requestedAt: createdAt,
                submissionId: submission.id,
                submissionOwner: submission.owner.fullName,
                user,
                coverages,
              },
              QuotationMapping
            ),
          ])
        }
      },
      []
    )
  },
}
