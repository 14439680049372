import React, { useState, useEffect } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import { Button, Row } from 'atoms'

const SubmissionActions = ({ participation }) => {
  const { url } = useRouteMatch()

  const quotations = participation.quotations.nodes
  const bound = quotations.find(quote => quote.status === 'bound')

  if (bound) return null

  const basePath = url.replace(/(.*panels\/\d+).*/, '$1')

  return (
    <Row>
      {quotations.length === 0 && (
        <Button secondary as={Link} to={`${basePath}/decline`} mr={1}>
          Decline
        </Button>
      )}
      <Button as={Link} to={`${basePath}/quotes/new`}>
        Provide Quote
      </Button>
    </Row>
  )
}

const QuoteActions = ({ quotation }) => {
  const { url } = useRouteMatch()
  const [status, setStatus] = useState(quotation.status)
  const [quoteId, setQuoteId] = useState(quotation.id)
  const binderRequested = status === 'binding'
  const bound = status === 'bound'

  useEffect(() => {
    setStatus(quotation.status)
    setQuoteId(quotation.id)
  }, [quotation])

  const bindUrl = url.endsWith(`quotes/${quoteId}`)
    ? `${url}/bind`
    : `${url}/quotes/${quoteId}/bind`
  const issueUrl = bindUrl.replace('bind', 'issue')

  if (binderRequested) {
    return (
      <Row>
        <Button as={Link} to={bindUrl}>
          Bind Quote
        </Button>
      </Row>
    )
  }

  if (bound) {
    return (
      <Row>
        <Button as={Link} to={issueUrl}>
          Issue Policy
        </Button>
      </Row>
    )
  }

  return null
}

export { SubmissionActions, QuoteActions }
