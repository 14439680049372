import React from 'react'
import numeral from 'numeral'

const FORMAT = '0.0[00]%'
const SHORT = '0.[00]%'

const Percent = ({ fallback = '—', children, short }) => {
  if (children === undefined || children === null || children === '') {
    return <>{fallback}</>
  }

  const value = numeral(children.toString())

  if (
    value.value() === null ||
    value.value().toString() === 'NaN' ||
    value.format(FORMAT) === 'NaN%' ||
    value.format(SHORT) === 'NaN%'
  ) {
    return <>{fallback}</>
  }

  if (short) {
    return <span title={`${children * 100}%`}>{value.format(SHORT)}</span>
  }

  return <span title={`${children * 100}%`}>{value.format(FORMAT)}</span>
}

export const percent = (value, options = {}) => (
  <Percent {...options}>{value}</Percent>
)

export default Percent
