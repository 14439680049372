const CANADA_COVERAGES = [
  'Management Liability SEO',
  'Court Attendance Costs',
  'Theft of Funds Held In Escrow',
  'Unlimited Reinstatements/Each and Every Claim Basis',
  'Separate Limit for Incident Response Costs',
  'Additional Extra Expense',
  'Restoration of Computer Systems',
  'Data Recreation',
  'Customer Push Payment Fraud',
  'Corporate Identity Theft',
  'Theft of Personal Funds of SEO’s',
]

const filteredCoverages = (country, coverages) => {
  switch (country) {
    case 'CA':
      return coverages.filter(c => c && c.name !== 'TCPA Defense Cost Coverage')
    case 'US':
      return coverages.filter(c => c && !CANADA_COVERAGES.includes(c.name))
    default:
      return coverages.filter(c => c && !CANADA_COVERAGES.includes(c.name))
  }
}

export default filteredCoverages
