import React, {
  useState,
  useRef,
  useContext,
  forwardRef,
  useEffect,
} from 'react'
import Engine from 'json-rules-engine-simplified'
import applyRules from 'rjsf-conditionals'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { withTheme } from '@rjsf/core'
import { Theme as MaterialUITheme } from '@rjsf/material-ui'
import useEventCallback from 'use-event-callback'
import { dequal } from 'dequal'

import { usePrevious } from 'hooks'
import ObjectFieldTemplate from './overrides/rjsf/ObjectFieldTemplate'
import FieldTemplate from './overrides/rjsf/FieldTemplate'
import coreWidgets from './widgets'
import coreFields from './fields'
import { Loading, Flex, Div, Icon } from 'atoms'
import { Button } from '@material-ui/core'
const Form = withTheme(MaterialUITheme)

import Context from './context'
import { transformErrors } from './errors'
import templates from './templates'
import getTemplates from './utils/getTemplates'
import useAutoSave from './autosave'
import { scrollToMuiError } from 'util/scroll'
import Theme from './Theme'

const noop = () => {}

const toErrorSchemaEntry = value => {
  if (Array.isArray(value)) {
    return value.map(toErrorSchemaEntry)
  } else if (typeof value === 'object') {
    return toErrorSchema(value)
  } else {
    return { __errors: [value] }
  }
}

const toErrorSchema = errors => {
  return Object.entries(errors).reduce((acc, [key, value]) => {
    acc[key] = toErrorSchemaEntry(value)
    return acc
  }, {})
}

const emptyArray = []
const emptyObject = {}

const FlexiForm = forwardRef(
  (
    {
      buttonsMarginTop,
      schema,
      uiSchema = emptyObject,
      fields = emptyObject,
      widgets = emptyObject,
      enableDraft = false,
      autosave = false,
      autosaveResolvers = emptyObject,
      beforeSubmit = () => true,
      submitWarning = noop,
      onSubmit,
      onCancel,
      onSaveDraft = noop,
      initialValues,
      onChange = noop,
      submitLabel = 'Submit',
      submitIcon = null,
      submitButtonPadding = null,
      draftLabel = 'Save Draft',
      showSubmit = true,
      debounceTime = 1000,
      getFormData,
      getFormErrors,
      showErrorList = false,
      patterns = emptyObject,
      debug = false,
      multiform = false,
      rules = emptyArray,
      additionalSidebarLinks = emptyArray,
      children,
      additionalAction,
      skipRules = false,
      ...props
    },
    forwardedRef
  ) => {
    const [saveIndicator, setSaveIndicator] = useState(false)
    const internalRef = useRef()
    const ref = forwardedRef || internalRef
    const [formData, setFormData] = useState(initialValues)
    const [_rerender, setRerender] = useState(0)
    const [flags, setFlags] = useState({})
    const previousInitialValues = usePrevious(initialValues)
    const [extraErrors, setExtraErrors] = useState(emptyObject)
    const [autoSave, { saving }] = useAutoSave({
      save: formData => onSaveDraft(formData, { navigate: false }),
      debounceTime,
      getFormData,
      getFormErrors,
      autosaveResolvers,
    })
    const uiSchemaWithTemplates = getTemplates(uiSchema, templates)
    const FormRef = useRef(
      applyRules(schema, uiSchemaWithTemplates, rules, Engine)(Form)
    )
    const [submitting, setSubmitting] = useState(false)
    const prevSchema = usePrevious(schema)

    useDeepCompareEffect(() => {
      if (debug) {
        /* eslint-disable no-console */
        console.log('Schema', schema)
        console.log('UI Schema', uiSchema)
        /* eslint-enable no-console */
      }

      // don't update if this is the first render.
      if (typeof prevSchema !== 'undefined') {
        FormRef.current = applyRules(
          schema,
          uiSchemaWithTemplates,
          rules,
          Engine
        )(Form)
        setRerender(rerender => rerender + 1)
      }
    }, [schema, uiSchemaWithTemplates, rules])

    useEffect(() => {
      const formChanged = !dequal(initialValues, formData)
      const initialValuesChanged = !dequal(initialValues, previousInitialValues)
      if (!saving && formChanged && initialValuesChanged) {
        setSaveIndicator(false)
        setFormData(initialValues)
      }
    }, [initialValues])

    const setField = (field, value) => {
      const form = ref.current
      if (!form.state) throw 'setField: Form not found'

      const errors = form.state.errorSchema
      form.onChange({ ...formData, ...{ [field]: value } }, errors)
    }

    const setFields = newValues => {
      const form = ref.current
      if (!form.state) throw 'setFields: Form not found'

      const errors = form.state.errorSchema
      form.onChange(newValues, errors)
    }

    const setError = (field, error) => {
      setExtraErrors(errors => ({ ...errors, [field]: { __errors: [error] } }))
      scrollToMuiError()
    }

    const setErrors = errors => {
      setExtraErrors(toErrorSchema(errors))
      scrollToMuiError()
    }

    const clearErrors = () => {
      const form = ref.current
      if (!form.onChange) throw 'onChange: Form not found'

      form.onChange(form.state.formData, {})
    }

    const getValues = () => {
      const form = ref.current
      if (!form?.state) return formData // form not initialized yet

      return form.state.formData
    }
    const getSchema = () => {
      const form = ref.current
      if (!form?.state) return schema // form not initialized yet
      return form.state.schema
    }

    const validate = (key = undefined) => {
      const form = ref.current
      if (!form.validate) throw 'validate: Form not found'

      const { errorSchema, errors } = form.validate(
        form.state.formData,
        form.state.schema
      )
      if (key) {
        // key only exists when using multi page form.
        const newErrors = !dequal(errorSchema[key], form.state.errorSchema[key])
        //This will ensure errors are only displayed on the current page the user is on
        if (newErrors && typeof errorSchema[key] === 'undefined') {
          setExtraErrors({})
        } else if (newErrors && typeof errorSchema[key] === 'object') {
          setExtraErrors({ [key]: errorSchema[key] })
        }
        scrollToMuiError()
        return !errorSchema[key]
      } else {
        const newErrors = !dequal(errorSchema, form.state.errorSchema)
        if (newErrors) setExtraErrors(errorSchema)
        scrollToMuiError()
        return errors.length === 0
      }
    }

    const controls = {
      clearErrors,
      setFields,
      setField,
      setError,
      setErrors,
      getValues,
      validate,
      setFlag: (flag, value) => setFlags({ ...flags, [flag]: value }),
      getFlag: key => flags[key],
      getSchema,
    }

    const handleSubmit = form => {
      if (!beforeSubmit(controls)) return false
      setSubmitting(true)
      const result = onSubmit(form.formData, form, controls)
      if (!!result && typeof result.then === 'function') {
        return result.finally(() => {
          setSubmitting(false)
        })
      } else {
        setSubmitting(false)
        return result
      }
    }

    const handleCancel = event => {
      event.preventDefault()
      onCancel()
    }

    const handleSaveDraft = (event, options = {}) => {
      event.preventDefault()
      const formData = getValues()
      if (enableDraft) {
        return onSaveDraft(formData, { navigate: true, ...options, controls })
      }
    }

    const nestedFormHandleAutoSave = ({ force = true }) => {
      if (!autosave) {
        return
      }
      if (force && !saving) {
        const form = ref.current
        if (form.state) {
          autoSave({
            form: form.state,
            controls,
          })
        } else {
          console.warn('Attempted to autosave but form reference was not found')
        }
      }
    }

    const handleChange = useEventCallback(form => {
      const hasChanged = !dequal(form.formData, formData)
      if (hasChanged) {
        setFormData(form.formData)
        onChange(form.formData, form, controls)
        if (autosave && !saving) {
          setSaveIndicator(true)
          autoSave({
            form,
            controls,
          })
        }
      }
    })

    if (!schema) {
      return <Loading />
    }
    // Temporarily Fix. for ML only  forms skip using rules and use the regular form.
    // Other teams, please do not use the skipRules prop as this will be removed when ML is refactored
    const WrappedForm = skipRules ? Form : FormRef.current

    return (
      <Context.Provider
        value={{
          formData,
          controls,
          onSubmit: handleSubmit,
          onSaveDraft,
          handleSaveDraft,
          draftLabel,
          enableDraft,
          widgets,
          fields,
          saveIndicator,
          handleAutoSave: nestedFormHandleAutoSave,
          autosave,
          submitLabel,
          submitIcon,
        }}>
        <Theme>
          <Div position="relative">
            {additionalAction && (
              <Flex
                justifyContent="flex-end"
                flex="1"
                position="absolute"
                zIndex="1">
                {React.cloneElement(additionalAction, {
                  handleSaveDraft: handleSaveDraft,
                })}
              </Flex>
            )}

            <WrappedForm
              schema={schema}
              uiSchema={uiSchemaWithTemplates}
              style={{ marginBottom: '16px' }}
              noHtml5Validate
              transformErrors={transformErrors(patterns)}
              showErrorList={debug || showErrorList}
              extraErrors={extraErrors}
              widgets={{ ...coreWidgets, ...widgets }}
              fields={{ ...coreFields, ...fields }}
              onChange={handleChange}
              onSubmit={handleSubmit}
              onError={scrollToMuiError}
              formData={formData}
              ref={ref}
              formContext={{ additionalSidebarLinks }}
              ObjectFieldTemplate={ObjectFieldTemplate}
              FieldTemplate={FieldTemplate}
              {...props}>
              {children}
              {submitWarning && submitWarning(flags.submitWarning)}
              <Flex justifyContent="flex-end" mt={buttonsMarginTop || '0px'}>
                {!multiform && enableDraft && draftLabel && (
                  <Div mr={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleSaveDraft}>
                      {draftLabel}
                    </Button>
                  </Div>
                )}
                {onCancel && (
                  <Button
                    onClick={handleCancel}
                    style={{ borderWidth: '1px', borderColor: '#D2CDC3' }}
                    mr={1}
                    secondary>
                    Cancel
                  </Button>
                )}
                {!multiform && showSubmit && submitLabel && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    padding={submitButtonPadding}
                    endIcon={
                      submitIcon && (
                        <Icon
                          data-testid={submitIcon.iconName}
                          icon={submitIcon}
                        />
                      )
                    }>
                    {submitLabel}
                  </Button>
                )}
              </Flex>
            </WrappedForm>
          </Div>
        </Theme>
      </Context.Provider>
    )
  }
)
FlexiForm.displayName = 'FlexiForm'

const useFlexiForm = () => {
  return useContext(Context)
}

export { useFlexiForm }
export default FlexiForm
