import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'hooks'

const usePageChange = callback => {
  const location = useLocation()
  const prevLoc = usePrevious(location)

  useEffect(() => {
    if (prevLoc && prevLoc.pathname !== location.pathname) {
      callback(location)
    }
  }, [location])
}

export default usePageChange
