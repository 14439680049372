export const Plan = {
  Free: 'free',
  Guest: 'guest',
  Launch: 'launch',
  Grow: 'grow',
  Fit: 'fit',
  plus: 'plus',
  Scale: 'scale',
  Enterprise: 'enterprise',
}

export const SubscriptionPeriods = {
  Monthly: 'monthly',
  Annual: 'annual',
  Biennial: 'biennial',
  Triennial: 'triennial',
}

export const OnboardingStates = {
  Started: 'started',
  InProgress: 'in_progress',
  Completed: 'completed',
}
