import { chain, get } from 'util/lodash'

const TRANSFORM_KEYS = { 'externalCarrier.name': 'carrierName' }
const KEYS_TO_OMIT = ['externalCarrier', 'id']

export const cyberQuoteToFormData = data => {
  const quote = get(data, 'cyber.quote')
  if (!quote) return {}

  return chain(quote).dottedTransformKeys(TRANSFORM_KEYS).omit(KEYS_TO_OMIT)
    .value
}

export const isQuoted = quotation =>
  quotation.id && !['pending', 'declined'].includes(quotation.status)

export const filterDocs = documents => {
  return documents.reduce((result, doc) => {
    if (doc.id) {
      result.push({
        id: doc.id,
        version: doc.version,
        category: doc.category,
        source: doc.source,
        _destroy: doc._destroy,
      })
    } else {
      result.push({
        attachment: doc.attachment,
        version: doc.version,
        category: doc.category,
        source: doc.source,
        _destroy: doc._destroy,
      })
    }
    return result
  }, [])
}

export const getPreferredCarriers = panelists =>
  panelists.filter(p => !!p.panelistConfiguration?.preferred)

export const getAdditionalCarriers = panelists =>
  panelists.filter(
    x => !x.panelistConfiguration?.preferred && x.type === 'CarrierAPI'
  )
