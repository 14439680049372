import React, { useState } from 'react'
import styled from 'styled-components'
import { Dialog, Box, Button } from '@material-ui/core'
import { faList } from '@fortawesome/pro-solid-svg-icons'

import { Icon, Subtitle } from 'atoms'

const IconInButton = styled(Icon)`
  font-size: 12px !important; // Override the Material UI button icon sizing
`

// eslint-disable-next-line no-unused-vars
const ModalField = ({ uiSchema, schema, onChange, formData, ...props }) => {
  const [open, setOpen] = useState(false)

  if (Object.keys(schema.properties).length === 0) {
    return null
  }

  // Remove the ui:field so it's not an infinitely nested modal.
  const newUiSchema = { ...uiSchema }
  delete newUiSchema['ui:field']

  const handleChange = formData => {
    onChange(formData)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const SchemaField = props.registry.fields.SchemaField

  return (
    <>
      <Subtitle>{schema.title.toUpperCase()}</Subtitle>
      <Box width="auto">
        <Button
          color="primary"
          size="large"
          variant="outlined"
          startIcon={<IconInButton icon={faList} fontSize="s" />}
          onClick={() => setOpen(isOpen => !isOpen)}>
          Show {schema.title}
        </Button>
      </Box>
      <Dialog keepMounted open={open} onClose={() => setOpen(false)}>
        <Box p={8} pt={0}>
          <SchemaField
            {...props}
            schema={schema}
            uiSchema={newUiSchema}
            onChange={handleChange}
            formData={formData}
          />
          <Box display="flex" flexDirection="row-reverse">
            <Button variant="outlined" onClick={handleClose}>
              Close {schema.title}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default ModalField
