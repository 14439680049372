import styled from 'styled-components'

const PageWrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  max-width: ${props => (props.narrow ? '1030px' : '1320px')};
  padding-bottom: 100px;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  ${props => (props.center ? 'align-items: center;' : '')};
`

export default PageWrapper
