import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { Div } from 'atoms'
import { colors } from '../../theme'

const SettingsMenuContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30px;
  height: 40px;
  cursor: pointer;
  background: transparent;
`

const MiddleLine = styled(motion.div).attrs(props => ({
  initial: 'normal',
  variants: {
    normal: {
      translateX: 0,
      backgroundColor: colors[props.iconColor || 'white'],
    },
    hover: {
      translateX: 4,
      backgroundColor: colors[props.iconColor || 'link'],
    },
  },
}))`
  width: 24px;
  height: 4px;
  margin-bottom: 6px;
`

const TopLine = styled(motion.div).attrs(props => ({
  initial: 'normal',
  variants: {
    normal: {
      translateX: 0,
      backgroundColor: colors[props.iconColor || 'white'],
    },
    hover: {
      translateX: -4,
      backgroundColor: colors[props.iconColor || 'link'],
    },
  },
}))`
  width: 24px;
  height: 4px;
  margin-bottom: 6px;
`

const BottomLine = styled(TopLine)`
  margin-bottom: 0px;
`

const SettingsMenuIcon = ({ open, iconColor }) => {
  const [hovered, setHovered] = useState(false)
  return (
    <SettingsMenuContainer
      animate={hovered || open ? 'hover' : 'normal'}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <TopLine iconColor={iconColor} />
      <MiddleLine iconColor={iconColor} />
      <BottomLine iconColor={iconColor} />
    </SettingsMenuContainer>
  )
}

const WhitelabelIconContainer = styled(Div)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30px;
  height: 40px;
  &:hover {
    transform: scale(1.1);
  }
`
const WhitelabelIconLine = styled(Div)`
  width: 24px;
  height: 4px;
  background-color: ${props => props.theme.colors.whitelabelText};
`

const WhitelabelSettingsIcon = () => {
  return (
    <WhitelabelIconContainer>
      <WhitelabelIconLine mb="6px" />
      <WhitelabelIconLine mb="6px" />
      <WhitelabelIconLine mb="0px" />
    </WhitelabelIconContainer>
  )
}

export default SettingsMenuIcon

export { WhitelabelSettingsIcon }
