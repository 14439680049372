import { mapJson } from 'util/jsonpath'
import { immerable, produce } from 'immer'

class ApplicationModel {
  [immerable] = true

  constructor({ mapping }) {
    this.data = null
    this.attributes = {}
    this.mapping = mapping
  }

  build(data) {
    if (!data) return this

    return produce(this, draft => {
      draft.data = data
      draft.attributes = mapJson(data, this.mapping)
    })
  }
}

export default ApplicationModel
