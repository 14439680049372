import React from 'react'
import { FilterButton, Flex } from 'atoms'

import { QUESTION } from './QAndA'
import { qandaTranslator } from 'util/translations'

const QAndAFilters = ({
  scope = QUESTION,
  questions,
  activeTab,
  handleTabClick,
}) => {
  const translator = qandaTranslator(scope)
  return (
    <Flex alignItems="center">
      <FilterButton
        onClick={() => handleTabClick('all')}
        label="All"
        selected={activeTab === 'all'}
        amount={questions.length}
        mr={0}
      />
      <FilterButton
        onClick={() => handleTabClick('awaiting')}
        selected={activeTab === 'awaiting'}
        label={`Awaiting ${translator('Answer')}`}
        amount={questions.filter(question => !question.answer).length}
        mr={0}
      />
      <FilterButton
        onClick={() => handleTabClick('toApprove')}
        selected={activeTab === 'toApprove'}
        label="To Be Approved"
        amount={
          questions.filter(
            question => !!question.answer && !question.answer.acceptedAt
          ).length
        }
      />
    </Flex>
  )
}

export default QAndAFilters
