import React from 'react'
import styled from 'styled-components'
import * as Sentry from '@sentry/react'

const ErrorText = styled.p`
  margin: 0 0 0.2rem 0;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: normal;
  color: ${props => props.theme.colors.error};
  margin-top: 2px;
`

const ErrorDot = styled.span`
  color: ${props => props.theme.colors.error};
  display: inline;
  margin-left: -1em;
  margin-right: 0.4em;
`

const Error = ({ children }) => {
  try {
    return (
      <>
        {children && (
          <ErrorText>
            <ErrorDot data-scroll="error">{'\u25CF'}</ErrorDot>
            {children}
          </ErrorText>
        )}
      </>
    )
  } catch (error) {
    Sentry.captureException(error)
    return (
      <ErrorText>
        <ErrorDot data-scroll="error">{'\u25CF'}</ErrorDot>
        An error occurred
      </ErrorText>
    )
  }
}

export default Error
