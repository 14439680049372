import React from 'react'
import PlacementTerms from 'components/forms/PlacementTerms'

import TermsAndConditionsCheckbox from '../TermsAndConditionsCheckbox'

const PlacementTermsCheckbox = props => {
  return (
    <TermsAndConditionsCheckbox {...props}>
      <PlacementTerms name="isAgreed" worksheetKind="Insurance" />
    </TermsAndConditionsCheckbox>
  )
}

export default PlacementTermsCheckbox
