import styled from 'styled-components'
import get from 'lodash/get'

const TagLabel = styled.label`
  border-radius: 4px 0 0 4px;
  background-color: ${props =>
    get(props.theme.colors, props.color || 'primaryText')};
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  align-self: flex-start;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.1rem;
  flex-shrink: 0;
`

export default TagLabel
