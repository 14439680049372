import { default as CheckboxWidget } from '@rjsf/antd/dist/cjs/widgets/CheckboxWidget'

import React from 'react'
import styled from 'styled-components'

const TermsAndConditionsCheckbox = props => {
  const CheckboxWidgetWithStyles = styled.div`
    padding-bottom: 27px;
  `

  const StylesWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 25px;
  `

  const { children, value, inputRef = { current: undefined } } = props

  return (
    <CheckboxWidgetWithStyles ref={r => (inputRef.current = r)}>
      <CheckboxWidget
        {...props}
        value={value}
        label={<StylesWrapper>{children}</StylesWrapper>}
      />
    </CheckboxWidgetWithStyles>
  )
}

export default TermsAndConditionsCheckbox
