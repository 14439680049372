import React from 'react'
import styled from 'styled-components'
import { useField } from 'react-final-form'
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons'

import { Flex, Icon, ExternalLink } from 'atoms'
import { truncateFileName } from 'util/document'
import { PDFViewerLink } from 'molecules/PDFViewer'
import { IS_IE } from 'components/NoMoreIE'

const Name = styled.span`
  flex-grow: 1;
  margin: 0 0.5rem;
  overflow-wrap: anywhere;
`

const Filename = ({ document, maxLength = 30 }) => {
  if (!document) return null
  const isPDF = document && /.*\.pdf(\?.*)?/i.test(document.url)

  const attachment = document.name ? document : document.attachment

  return isPDF && !IS_IE ? (
    <PDFViewerLink document={attachment} largeIcon maxLength={maxLength} />
  ) : document.url ? (
    <ExternalLink href={document.url}>
      <Flex>
        <Icon icon={faPaperclip} fontSize="large" />
        <Name>{truncateFileName(attachment.name, maxLength)}</Name>
      </Flex>
    </ExternalLink>
  ) : (
    <Flex>
      <Icon icon={faPaperclip} fontSize="large" />
      <Name>{truncateFileName(attachment.name, maxLength)}</Name>
    </Flex>
  )
}

export const FilenameWithField = ({ name, ...rest }) => {
  const { input } = useField(name)
  return input.value && <Filename {...rest} document={input.value} />
}

export default Filename
