import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { space } from 'styled-system'
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons'

import Icon from 'components/Icon'
import _ExternalLink from 'components/common/ExternalLink'
import { DocumentFragmentDoc } from 'graphql/api'
import { PDFViewerLink } from 'molecules/PDFViewer'
import { truncateFileName } from 'util/document'
import { IS_IE } from 'components/NoMoreIE'

const Container = styled.div`
  width: 100%;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
  ${space}
`

const Panel = styled.div`
  flex: 3;
  color: ${props => props.theme.colors.primaryText};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 14px;

  &:last-child {
    flex: 1;
    text-align: right;
  }
`

const ExternalLink = styled(_ExternalLink)`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :first-child {
    margin-right: 0.5rem;
  }
`

const Document = ({ document, title, maxLength, showTime = true, ...rest }) => {
  const isPDF = /.*\.pdf(\?.*)?/i.test(document.url)

  return (
    <Container {...rest}>
      <Panel>
        {isPDF && !IS_IE ? (
          <PDFViewerLink maxLength={maxLength} document={document} />
        ) : (
          <ExternalLink
            href={document.url}
            title={document.filename || document.name}>
            <Icon icon={faPaperclip} fontSize="m" />
            {truncateFileName(document.filename || document.name, maxLength)}
          </ExternalLink>
        )}
      </Panel>
      {title && <Panel>{title}</Panel>}
      {showTime && <Panel>{moment(document.createdAt).fromNow()}</Panel>}
    </Container>
  )
}

export const DocumentFragment = DocumentFragmentDoc

export default Document
