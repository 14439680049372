import React from 'react'
import styled from 'styled-components'
import { faFile } from '@fortawesome/pro-solid-svg-icons'

import { Flex, Icon } from 'atoms'

const A = styled.a`
  color: inherit;
  text-decoration: inherit;
  width: 25%;
`

const FileBlock = ({ file }) => (
  <A href={file.url} title="Download">
    <Flex
      bg="secondaryBackground"
      key={file.name}
      borderRadius={1}
      alignItems="center"
      p={1}
      mr={1}
      mb={1}>
      <Icon icon={faFile} mr={1} />
      {file.name}
    </Flex>
  </A>
)

export default FileBlock
