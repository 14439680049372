import React from 'react'
import uniq from 'lodash/uniq'
import { capitalizeFirstLetter, capitalizeAfterHyphen } from 'util/langUtil'
import { Div } from 'atoms'
import { colors } from 'components/theme'
import { useHover } from 'hooks'
import { Tooltip, withStyles } from '@material-ui/core'

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: colors.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    fontSize: '1rem',
    padding: '1rem',
  },
})(Tooltip)

const CyberModalTemplate = props => {
  const [hoverRef, isHovered] = useHover()
  const { schema, children, hidden } = props
  const listFormatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction',
  })
  const helperText = schema?.helper_text?.length > 0 ? schema.helper_text : ''

  const carriersText = listFormatter.format(
    uniq(schema.carriers)?.map(c =>
      capitalizeAfterHyphen(capitalizeFirstLetter(c))
    )
  )
  const showHover = isHovered && !hidden && carriersText
  if (hidden) return children

  return (
    <LightTooltip
      placement="left"
      arrow
      open={Boolean(helperText && showHover)}
      followCursor={true}
      title={helperText}>
      <Div
        ref={hoverRef}
        className="MuiFormControl-root MuiFormControl-fullWidth"
        style={{
          background: showHover && colors.blueGradient,
          position: 'relative',
          top: '0',
          border: showHover ? `1px solid #CADEFA` : '1px solid transparent',
          zIndex: showHover && '100',
          padding: '10px',
          marginBottom: '-42px',
          borderRadius: '10px',
          boxSize: 'border-box',
        }}>
        {children}
        {carriersText && (
          <Div
            mt="5px"
            color={colors.primaryTextMid}
            style={{
              visibility: !showHover && 'hidden',
            }}>
            {`Required by ${carriersText}`}
          </Div>
        )}
      </Div>
    </LightTooltip>
  )
}

export default CyberModalTemplate
