import { flatten, unflatten } from 'flat'
import { isEmpty } from 'lodash/lang'

const getTemplates = (uiSchema, templates) => {
  if (isEmpty(uiSchema)) {
    return {}
  } else {
    const flatObj = flatten(uiSchema)
    const templateKeys = Object.keys(flatObj).filter(key =>
      /(ObjectFieldTemplate|ArrayFieldTemplate)$/.test(key)
    )

    if (templateKeys.length === 0) {
      return uiSchema
    }

    for (const key of templateKeys) {
      const template = flatObj[key]
      if (/ObjectFieldTemplate$/.test(key)) {
        const templateComponent =
          typeof template === 'function'
            ? template
            : templates.objectFieldTemplates[template]
        flatObj[key] = templateComponent
      } else if (/ArrayFieldTemplate$/.test(key)) {
        const templateComponent =
          typeof template === 'function'
            ? template
            : templates.arrayFieldTemplates[template]
        flatObj[key] = templateComponent
      }
    }
    return unflatten(flatObj)
  }
}

export default getTemplates
