import styled from 'styled-components'

const SideNav = styled.div`
  display: flex;
  position: sticky;
  top: 15px;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 250px;
  text-align: ${props => (props.textAlign ? props.textAlign : 'right')};
`

export default SideNav
