import React from 'react'
import styled from 'styled-components'
import { space, color } from 'styled-system'
import { Div, Column, Text } from 'atoms'

const Circle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.link};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  font-weight: 600;
  flex-grow: 0%;

  ${space}
  ${color}
`

const CircleCounter = ({ label, children, mr, ml, mt, mb, ...rest }) => {
  return (
    <Div>
      <Column mr={mr} ml={ml} mt={mt} mb={mb} alignItems="center">
        <Text mb={0}>{label}</Text>
        <Circle {...rest}>{children || 0}</Circle>
      </Column>
    </Div>
  )
}

export default CircleCounter
