import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Heading = styled.span`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
}
`

const Clickable = styled.span`
  cursor: pointer;
  color: ${props => props.theme.colors.link};
  margin-left: 20px;
  display: inline-block;
`

const CheckboxHeading = ({ title, data, update }) => {
  const [selected, updateSelected] = useState(data)
  const [selectedAll, updateSelectedAll] = useState(false)

  const allSelected = data => {
    const keys = Object.keys(data)
    const selectedList = []
    keys.map(key => {
      if (data[key]) {
        selectedList.push(key)
      }
    })
    return keys.length == selectedList.length
  }

  useEffect(() => {
    updateSelected(data)
    updateSelectedAll(allSelected(data))
  }, [data])

  const selectSection = event => {
    event.preventDefault()
    let newSelected = {}
    Object.keys(selected).forEach(key => {
      newSelected[key] = !selectedAll
    })
    update(newSelected)
    updateSelectedAll(allSelected(newSelected))
  }

  return (
    <>
      <Heading>{title}</Heading>
      <Clickable onClick={selectSection}>
        {selectedAll ? 'Deselect section' : 'Select section'}
      </Clickable>
    </>
  )
}

export default CheckboxHeading
