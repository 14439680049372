import React from 'react'
import getIcon from 'FlexiForm/utils/getIcons'
import { Icon, Flex, Text } from 'atoms'

// use this field with type object and set one property to icon.
const LabelWithIcon = ({ formData: data = {} }) => {
  const { icon, text } = data
  return (
    <Flex height="40px" alignItems="center">
      <Icon icon={getIcon(icon)} mr={0} />
      <Text>{text}</Text>
    </Flex>
  )
}

export default LabelWithIcon
