import React from 'react'
import { useParams } from 'react-router'
import { usePanelQuery } from 'graphql/api'

import { Column, Loading, Subtitle } from 'atoms'
import { Carrier, GradientRow } from './components'

const getCarriers = (data, api) => {
  if (api) {
    return data.viewer.user.company.panel.panelists.nodes
      .filter(panelist => panelist.type === 'CarrierAPI')
      .map(panelist => ({
        id: panelist.id,
        name: `${panelist.firstName} ${panelist.lastName}`,
        logoUrl: panelist.avatarUrl || panelist.company.logoUrl,
      }))
  } else {
    return data.viewer.user.company.panel.externalCarriers
  }
}
const SelectCarriersField = ({
  formData: selectedCarriers = [],
  onChange,
  uiSchema,
  api,
}) => {
  const styles = uiSchema['ui:styles'] || {}
  const { panelId } = useParams()
  const { data, loading, error } = usePanelQuery({ variables: { panelId } })

  const handleChange = id => () => {
    let checked = selectedCarriers.includes(id)

    if (!checked) {
      onChange([...selectedCarriers, id])
    } else {
      onChange(selectedCarriers.filter(v => v !== id))
    }
  }

  if (error) {
    throw error
  }

  if (loading) {
    return <Loading />
  }
  const carriers = getCarriers(data, api)

  return (
    <Column>
      <Subtitle m={0}>SELECTED CARRIERS ({selectedCarriers.length})</Subtitle>
      <GradientRow
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
        p={2}
        pr={'0'}
        mt={0}
        {...styles}>
        {carriers.map(carrier => (
          <Carrier
            key={carrier.id}
            name={carrier.name}
            id={carrier.id}
            logo={carrier.logoUrl}
            onChange={handleChange(carrier.id)}
            checked={selectedCarriers.includes(carrier.id)}
          />
        ))}
      </GradientRow>
    </Column>
  )
}

export const SelectAPICarriersField = props => (
  <SelectCarriersField api={true} {...props} />
)

export const SelectExternalCarriersField = props => (
  <SelectCarriersField api={false} {...props} />
)
