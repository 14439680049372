import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'
import isEqual from 'lodash/isEqual'
import { Select } from 'antd'
import { faPlusCircle, faTimes } from '@fortawesome/pro-solid-svg-icons'

import {
  Subtitle,
  Loading,
  Column,
  Fieldset,
  Flex,
  Avatar,
  SectionTitle,
  Text,
  IconCircle,
  Icon,
} from 'atoms'
import { useAuthorizedMarketsQuery } from 'graphql/api'
import { submissionTranslator } from 'util/translations'
import CreateAuthorizedMarketContactForm from 'components/forms/CreateAuthorizedMarketContactForm'
import { colors } from 'components/theme'
import usePanel from 'hooks/panel'
import { getPreferredCarriers } from 'cyber/utils'

const HiddenTag = () => {
  return null
}

const StyledContainer = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 24px;
  flex-shrink: 0;
  border: none;
  justify-content: flex-start;
  width: auto;
  font-size: 18px;
  font-weight: normal;
  & > span {
    margin-left: 10px;
    color: ${colors.link};
  }
`

const Carrier = ({ carrier, remove }) => (
  <Fieldset p={2} mt={1}>
    <Flex alignItems="center">
      <Avatar size="large" user={carrier} />
      <Flex ml={1} flexDirection="column">
        <SectionTitle m="0px">{carrier.companyName}</SectionTitle>
        <Text color="primarytextMid">{carrier.fullName}</Text>
      </Flex>
      <IconCircle
        ml={1}
        onClick={remove}
        borderColor="secondaryBlue"
        bg="transparent">
        <Icon icon={faTimes} />
      </IconCircle>
    </Flex>
  </Fieldset>
)

const InviteCarriersField = ({
  name,
  formData: values = [],
  onChange,
  onFocus,
  onBlur,
  readonly,
  required,
  autofocus,
  disabled,
  schema,
}) => {
  const [selected, setSelected] = useState(() =>
    values.map(insurer => insurer.id)
  )
  const [addedMarkets, setAddedMarkets] = useState([])
  const [showModal, setShowModal] = useState(false)

  const { data, loading, error } = useAuthorizedMarketsQuery({
    fetchPolicy: 'cache-and-network',
  })
  const panel = usePanel()
  const panelists = panel.panelists.nodes

  useEffect(() => {
    const ids = values.map(insurer => insurer.id)
    if (!isEqual(ids, selected)) {
      setSelected(ids)
    }
  }, [values])

  if (loading) return <Loading />

  if (error) throw error

  const preferredEmailCarriers = getPreferredCarriers(panelists)
    .filter(p => p.type !== 'CarrierAPI')
    .map(x => x.id)
  const markets = data.viewer.user.company.authorizedMarkets.nodes.flatMap(
    market => market.contacts.nodes
  )
  const filteredMarkets = sortBy(
    markets
      .filter(market => !selected.includes(market.reinsurer.id))
      .filter(market => !preferredEmailCarriers.includes(market.reinsurer.id)),
    market => [market.reinsurer.companyName, market.reinsurer.fullName]
  )

  const translator = submissionTranslator({ kind: 'insurance' })

  const remove = idToRemove => () =>
    onChange(values.filter(selected => selected.id !== idToRemove))

  const onSelect = addedId => {
    const market = markets.find(market => market.reinsurer.id === addedId)
    onChange([
      ...values,
      { id: market?.reinsurer.id, personalMessage: undefined },
    ])
  }

  return (
    <Column>
      <Subtitle>
        {schema.title.toUpperCase()} ({values.length})
      </Subtitle>
      <Flex alignItems="center">
        <Select
          showSearch
          name={name}
          showArrow
          mode="multiple"
          value={selected}
          placeholder=""
          label="Select Carriers"
          autoFocus={autofocus}
          required={required}
          disabled={readonly || disabled}
          tagRender={HiddenTag}
          onSelect={onSelect}
          onFocus={onFocus}
          onBlur={onBlur}
          filterOption={(input, option) =>
            option.children.join().toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          style={{ width: '65%' }}>
          {filteredMarkets.map(market => (
            <Select.Option key={market.id} value={market.reinsurer.id}>
              {market.reinsurer.companyName} — {market.reinsurer.fullName}
            </Select.Option>
          ))}
        </Select>
        <Text mx={2}>OR</Text>
        <StyledContainer ml="auto" light onClick={() => setShowModal(true)}>
          <Icon icon={faPlusCircle} color={colors.link} fontSize="20px" />
          <span>Add New Carrier</span>
        </StyledContainer>
      </Flex>
      {values.map(selected => {
        const carrier = [...markets, ...addedMarkets].find(
          market => market.reinsurer?.id === selected.id
        )
        return !carrier ? null : (
          <Carrier
            key={selected.id}
            carrier={carrier.reinsurer}
            remove={remove(selected.id)}
          />
        )
      })}
      {showModal && (
        <CreateAuthorizedMarketContactForm
          translator={translator}
          onConfirm={market => {
            setShowModal(false)
            setAddedMarkets([...addedMarkets, market])
            onChange([
              ...values,
              { id: market.reinsurer.id, personalMessage: undefined },
            ])
          }}
          onCancel={() => {
            setShowModal(false)
          }}
        />
      )}
    </Column>
  )
}

export default InviteCarriersField
