export const CYBER_CRITERIA = [
  {
    label: 'Limit',
    key: 'limit',
  },
]

export const THIRD_PARTY_INSURING_AGREEMENTS = [
  {
    label: 'Media Liability',
    key: 'coverages[0]',
    defaultTooltipContent:
      'Provides coverage against defamation, invasion of privacy, copyright infringement, plagiarism, intentional torts and related liabilities.',
  },
  {
    label: 'Network Security and Privacy Liability',
    key: 'coverages[1]',
    defaultTooltipContent:
      "Provides coverage for loss relating to the failure to protect a customer's personally identifiable information via theft, unauthorized access, viruses, or denial of service attacks.",
  },
  {
    label: 'Payment Card Loss',
    key: 'coverages[2]',
    defaultTooltipContent:
      'Provides coverage for fines and penalties assessed against the Insured for failure to comply with Payment Card Industry Data Security Standards and related defense costs that are incurred.',
  },
  {
    label: 'Regulatory Proceedings',
    key: 'coverages[3]',
    defaultTooltipContent:
      'Provides coverage for the payment of regulatory fines and penalties that are levied against the Insured by state and/or federal regulatory agencies (as a result of the breach).',
  },
  {
    label: 'Technology Errors & Omissions',
    key: 'coverages[4]',
    defaultTooltipContent:
      "Provides coverage for expenses and damages that the Insured is obligated to pay resulting from a claim alleging any negligent act, error, omission, or misrepresentation in the Insured's rendering or failure to render technology services that the Insured has agreed to perform for others in exchange for a fee.",
  },
  {
    label: 'Management Liability SEO',
    key: 'coverages[5]',
    country: ['CA'],
    defaultTooltipContent:
      'Provides coverage for management liability claims arising out of cyber events (acting as excess coverage where the Insured already has coverage in place or as primary when the Insured does not have coverage in place).',
  },
  {
    label: 'Court Attendance Costs',
    key: 'coverages[6]',
    country: ['CA'],
    defaultTooltipContent:
      'Covers the costs of attending court in connection with a claim to which the Insured is entitled under the policy.',
  },
  {
    label: 'Theft of Funds Held In Escrow',
    key: 'coverages[7]',
    country: ['CA'],
    defaultTooltipContent:
      'The criminal taking or misappropriation by a hacker, but not an employee or supervised contractor of yours, of money, securities, or property belonging to a third party, from a bank account held by you on their behalf.',
  },
  {
    label: 'TCPA Defense Cost Coverage',
    key: 'coverages[8]',
    country: ['US'],
    defaultTooltipContent:
      'Provides coverage for defense costs incurred by the Insured to defend against a claim for an actual or alleged violation of the Telephone Consumer Protection Act.',
  },
]

export const FIRST_PARTY_INSURING_AGREEMENTS = [
  {
    label: 'Unlimited Reinstatements/Each and Every Claim Basis',
    key: 'coverages[9]',
    country: ['CA'],
    defaultTooltipContent:
      'Ensures that limits are fully reinstated following each first party loss, allowing the Insured to claim up to the full limit for each and every separate first party loss they incur. This differs from an aggregate limit which is eroded with each loss that is incurred.',
  },
  {
    label: 'Cyber Incident Response Team',
    key: 'coverages[10]',
    defaultTooltipContent:
      'Provides coverage for the cost of utilizing breach response vendors (ex: crisis response, legal, forensics).',
  },
  {
    label: 'Separate Limit for Incident Response Costs',
    key: 'coverages[11]',
    country: ['CA'],
    defaultTooltipContent:
      'Incident response costs have a separate limit in place from the main policy limit, effectively giving the Insured two limits for each individual claim.',
  },
  {
    label: 'Business Interruption Loss',
    key: 'coverages[12]',
    defaultTooltipContent:
      'Provides coverage for loss of income and extra expenses incurred due to a Network Security and/or Privacy incident.',
  },
  {
    label: 'Additional Extra Expense',
    key: 'coverages[13]',
    country: ['CA'],
    defaultTooltipContent:
      'Covers additional expenditure incurred in excess of the economic limit usually imposed under traditional extra expense cover found under business interruption loss sections.',
  },
  {
    label: 'Contingent Business Interruption Loss',
    key: 'coverages[14]',
    defaultTooltipContent:
      "Provides coverage for an insured's loss of income due to an interruption in the service of a third-party provider.",
  },
  {
    label: 'Business Interruption - System Failure',
    key: 'coverages[15]',
    defaultTooltipContent:
      'Provides coverage for loss of income and extra expenses incurred due to System Failure.',
  },
  {
    label: 'Contingent Business Interruption Loss - System Failure',
    key: 'coverages[16]',
    defaultTooltipContent:
      "Provides coverage for an insured's loss of income due to an interruption in the service of a third-party provider due to System Failure.",
  },
  {
    label: 'Reputational Harm',
    key: 'coverages[17]',
    defaultTooltipContent:
      'Provides coverage for loss of revenue over a specified period due to reputation damage relating to a covered cybersecurity incident.',
  },
  {
    label: 'Restoration of Computer Systems',
    key: 'coverages[18]',
    country: ['CA'],
    defaultTooltipContent:
      'The costs incurred to recover and reconstitute applications and computer systems following a cyber event.',
  },
  {
    label: 'Digital Data Recovery',
    key: 'coverages[19]',
    defaultTooltipContent:
      'Provides coverage for the costs associated with recovering lost data.',
  },
  {
    label: 'Data Recreation',
    key: 'coverages[20]',
    country: ['CA'],
    defaultTooltipContent:
      "Covers the costs of re-creating or re-entering data from scratch when recovery or restoration from back-ups isn't possible.",
  },
  {
    label: 'Network Extortion',
    key: 'coverages[21]',
    defaultTooltipContent:
      'Covers the Insured for reasonable and necessary expenses incurred as a result of a network extortion threat. This coverage includes "ransom" payments to the party thought to be behind the threat.',
  },
]

export const CYBER_INSURING_AGREEMENTS = [
  {
    label: 'Computer Fraud',
    key: 'coverages[22]',
    defaultTooltipContent:
      'Provides coverage for direct loss of money, securities and other property resulting directly from the use of any computer to fraudulently transfer insured property.',
  },
  {
    label: 'Funds Transfer Fraud',
    key: 'coverages[23]',
    defaultTooltipContent:
      "Provides coverage for loss of Money or Securities resulting directly from the transfer of Money or Securities to an account beyond the Insured Entity's control, by a Financial Institution that relied upon a written, electronic, telegraphic, cable, or teletype instruction that was issued without the Insured Entity's knowledge or consent.",
  },
  {
    label: 'Vendor or Client Payment Fraud',
    key: 'coverages[24]',
    defaultTooltipContent:
      'Covers money owed to the Insured Entity but not collected for services rendered or goods delivered to a Client, or the amount the Insured Entity paid a Vendor for goods or services the Insured Entity did not receive; directly caused by an instruction that intentionally misleads a Vendor or Client.',
  },
  {
    label: 'Customer Push Payment Fraud',
    key: 'coverages[25]',
    country: ['CA'],
    defaultTooltipContent:
      "Covers the costs of reimbursing the Insured's customers for their financial loss arising from fraudulent communications designed to impersonate the Insured.",
  },
  {
    label: 'Corporate Identity Theft',
    key: 'coverages[26]',
    country: ['CA'],
    defaultTooltipContent:
      "Covers the cost of reimbursing the Insured for direct financial loss incurred as a result of the fraudulent misuse of the Insured's electronic identity.",
  },
  {
    label: 'Telecommunications Theft',
    key: 'coverages[27]',
    defaultTooltipContent:
      "Provides coverage for financial loss resulting from unauthorized third-party access and use of the Insured's telecommunications services.",
  },
  {
    label: 'Social Engineering Fraud',
    key: 'coverages[28]',

    defaultTooltipContent:
      'Provides coverage for losses sustained by means of social engineering. Social Engineering is a general term for when a malicious actor intentionally misleads an employee into sending money based on fraudulent information that is provided to the employee in a written or verbal communication.',
  },
  {
    label: "Theft of Personal Funds of SEO's",
    key: 'coverages[29]',
    country: ['CA'],
    defaultTooltipContent:
      "Payment to any SEO for personal financial loss as a direct result of any third party compromising the company's network security.",
  },
]

export const ADDITIONAL_FEATURES = [
  {
    label: 'Deductible',
    key: 'additionalFeatures[0]',
    defaultTooltipContent: 'Deductible',
  },
  {
    label: 'Full Retroactive Coverage',
    key: 'additionalFeatures[1]',
    defaultTooltipContent: 'No retroactive date applied to policies',
  },
  {
    label: 'Actions of Rogue Employee',
    key: 'additionalFeatures[2]',
    defaultTooltipContent:
      'Coverage for cyber attacks or privacy breaches caused by rogue employees',
  },
  {
    label: 'Risk Management Services',
    key: 'additionalFeatures[3]',
    defaultTooltipContent: 'Additional risk management services',
  },
]

export const ENDORSEMENTS = [
  {
    label: 'Bodily Injury',
    key: 'endorsements[0]',
    defaultTooltipContent:
      'Coverage for damages and fees resulting from Claims made against the Insured alleging bodily injury to a third party resulting from a security failure / breach.',
  },
  {
    label: 'Bricking Coverage',
    key: 'endorsements[1]',
    defaultTooltipContent:
      "Covers the reasonable and necessary external fees and costs to replace any component of the Insured's computer system that is no longer functional due to a bricking attack.",
  },
  {
    label: 'Hardware or Equipment Betterment',
    key: 'endorsements[2]',
    defaultTooltipContent:
      "Provides coverage for a reasonable upgrade of the Insured's computer system as recommend by a qualified provider.",
  },
  {
    label: 'Preventative Shutdown Endorsement',
    key: 'endorsements[3]',
    defaultTooltipContent:
      "Extends the Business Interruption insuring agreement to include interruptions caused by intentional shutdowns of the Insured's Computer System to prevent or reduce the spread of malicious code.",
  },
  {
    label: 'Ransomware Encounter',
    key: 'endorsements[4]',
    defaultTooltipContent:
      "",
  },
  {
    label: 'Widespread Event Endorsement',
    key: 'endorsements[5]',
    defaultTooltipContent:
      "",
  },
]
