export const QUOTE_STATUSES = {
  pending: 'pending',
  quoted: 'quoted',
  referred: 'referred',
  declined: 'declined',
  declinationConfirmed: 'declination_confirmed',
  declinedOutdated: 'declined_outdated',
  binding: 'binding',
  bound: 'bound',
  issued: 'issued',
  extracted: 'extracted',
  outdated: 'outdated',
  failed: 'failed',
}

export const POLL_INTERVALS = {
  superFast: 1000, // 1 seconds
  fast: 10000, // 10 seconds
  medium: 30000, // 30 seconds
  slow: 60000, // 60 seconds
  superSlow: 5 * 60000, // 5 minutes
}

export const DONE_STATES = [QUOTE_STATUSES.issued, QUOTE_STATUSES.bound]
export const COMPLETED_STATES = [QUOTE_STATUSES.binding, ...DONE_STATES]
export const ISSUED = QUOTE_STATUSES.issued
export const NON_EDITABLE_STATES = [
  QUOTE_STATUSES.bound,
  QUOTE_STATUSES.issued,
  QUOTE_STATUSES.binding,
  QUOTE_STATUSES.outdated,
  QUOTE_STATUSES.declinationConfirmed,
  QUOTE_STATUSES.declinedOutdated,
]
export const SELECTABLE_STATUSES = [
  QUOTE_STATUSES.quoted,
  QUOTE_STATUSES.referred,
  QUOTE_STATUSES.binding,
  QUOTE_STATUSES.bound,
  QUOTE_STATUSES.issued,
]
