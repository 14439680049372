import React from 'react'

import { Icon, Text, Flex } from 'atoms'
import {
  MultiTitleRenderer,
  MultiColumnRenderer,
  MoneyRenderer,
} from '../../../renderers'

const renderQuotedLines = (type, qLines) => {
  let qlData
  const quotedLineType = `ManagementLiability::${type}`
  const showQuotedLine = qLines
    .map(ql => {
      let result = Boolean(ql)
      if (result && !qlData) {
        qlData = ql.filter(line => line.type === quotedLineType)[0]
        result = qlData?.type === quotedLineType
      }
      return result
    })
    .includes(true)
  const coverages = qlData?.coverages || []

  const renderTitle = ({ icon, coverageName }) => {
    return (
      <Flex alignItems="center">
        <Icon icon={icon} color="white" mr={0} />
        <Text fontWeight={2} mr={2} color="white">
          {coverageName}
        </Text>
      </Flex>
    )
  }

  return {
    showIf: () => showQuotedLine,
    title: renderTitle(qlData || {}),
    criteria: [
      {
        label: '',
        renderer: MultiTitleRenderer,
        titles: ['Limit', 'Retention'],
      },
      () => {
        const coverageNames = coverages.map(cov => cov.name)
        const finder = (quotation, criterion) => {
          return quotation?.quotedLines
            ?.find(ql => ql.type === quotedLineType)
            ?.coverages.find(coverage => coverage.name === criterion.label)
        }
        return coverageNames.map(coverageName => ({
          label: coverageName,
          renderer: MultiColumnRenderer,
          finder,
          columns: [
            { renderer: MoneyRenderer, key: 'limit' },
            { renderer: MoneyRenderer, key: 'retention' },
          ],
        }))
      },
    ],
  }
}

export default renderQuotedLines
