import { AutoComplete } from 'antd'
import currencies from 'data/currencies'
import { useCurrencyValue } from 'hooks'
import noop from 'lodash/noop'
import React, { useEffect, useState, useRef } from 'react'

import { currencyFormatter } from 'util/fieldNormalizers'
import styled from 'styled-components'
import { Select } from 'rc-select/lib/Select'
import { Div, Text } from 'atoms'

const ErrorText = styled.small`
  color: #c24040;
`
const Field = styled(AutoComplete)`
  ${Select} {
    border: ${props => (props.status === 'error' ? 'red 1px solid' : '')};
    border-radius: 4px;
  }
  width: 100%;
`
const RedText = styled(Text)`
  color: #c24040;
`

let focused = false
const CurrencyDropdownWidget = props => {
  const {
    inputRef = useRef(null),
    formIndex: _formIndex,
    formRefs: _formRefs,
    currency: _currency = 'USD',
    onChange = noop,
    options: { enumOptions },
    autofocus,
    disabled,
    label,
    name,
    readonly,
    required,
    size,
    errors = [],
  } = props

  const [formIndex, setFormIndex] = useState(_formIndex)
  const [formRefs, setFormRefs] = useState(_formRefs)
  const currency = currencies[_currency || 'USD']
  const { normalized, formatted, setValue } = useCurrencyValue(
    props.value,
    currency
  )

  useEffect(() => {
    if (normalized !== props.value && !focused) {
      onChange(normalized)
    }
  }, [normalized])

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    setFormRefs(_formRefs)
  }, [_formRefs])

  useEffect(() => {
    setFormIndex(_formIndex)
  }, [_formIndex])

  const handleBlur = () => {
    focused = false
    onChange(normalized)
  }

  const handleFocus = () => {
    focused = true
  }

  const handleKeyDown = event => {
    switch (event.key) {
      case 'Tab':
        event.preventDefault()
        if (focused) {
          let nextIndex = formIndex + 1
          while (nextIndex < formRefs?.length) {
            if (formRefs?.[nextIndex]?.checked) {
              break
            }
            nextIndex++
          }
          formRefs?.[formIndex]?.ref?.blur()
          setTimeout(() => {
            formRefs?.[nextIndex]?.ref?.focus()
          }, 20)
        }
        return
      case 'Escape':
        if (focused) {
          event.preventDefault()
          formRefs?.[formIndex]?.ref?.blur()
        }
        return
    }
  }

  const options = enumOptions.map(({ value }) => ({
    label: currencyFormatter(value, currency),
    value: String(value),
  }))
  const placeholder = required ? `${label}*` : label

  return (
    <>
      <Field
        ref={inputRef}
        name={name}
        value={formatted}
        placeholder={
          errors.length > 0 ? (
            <RedText>{placeholder}</RedText>
          ) : (
            <Text>{placeholder}</Text>
          )
        }
        size={size}
        label={label}
        autoFocus={autofocus}
        required={required}
        disabled={readonly || disabled}
        onChange={setValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        options={options}
        status={errors.length > 0 ? 'error' : ''}
        allowClear
      />
      {errors.length > 0 && (
        <Div>
          {errors.map(error => (
            <ErrorText key={error}>{error}</ErrorText>
          ))}
        </Div>
      )}
    </>
  )
}

export default CurrencyDropdownWidget
