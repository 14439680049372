/* eslint-disable no-use-before-define */
import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Div } from 'atoms'

const MultiDropdownSelect = ({
  formData,
  schema,
  label = schema.title,
  placeholder = schema.placeholder,
  onChange,
}) => {
  const handleInputChange = (event, value) => {
    onChange(value)
  }

  return (
    <Div>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={schema.options}
        onChange={handleInputChange}
        defaultValue={formData}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    </Div>
  )
}

export default MultiDropdownSelect
