import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { readableColor } from 'polished'
import { useAnimation } from 'framer-motion'
import { useNotificationEventsQuery } from 'graphql/api'
import { withRouter } from 'react-router-dom'
import { ErrorBoundary } from '@sentry/react'
import { compose } from 'recompose'
import { Amplitude } from '@amplitude/react-amplitude'
import MarkAllAsRead from './MarkAllAsRead'
import _RelativeDate from 'components/common/RelativeDate'
import UserContext from 'components/contexts/user'
import _Icon from 'components/Icon'
import Loading from 'components/Loading'
import getNotificationSubtext from 'util/getNotificationSubText'
import linkNavigator from 'util/linkNavigator'
import translateNotification from 'util/translateNotification'
import NotificationsMenuIcon from './NotificationsMenuIcon'
import LoadMoreNotifications from './LoadMoreNotifications'
import notificationsAnalyticsProvider from 'util/notificationsAnalyticsProvider'
import notificationActionedMutation from 'components/mutations/notifications/notificationActioned'
import notificationViewedMutation from 'components/mutations/notifications/notificationViewed'
import { colors } from 'components/theme'
import { scrollTo } from 'util/scroll'
import truncate from 'util/truncate'
import useOnClickOutside from 'hooks/onClickOutside'
import useCurrentUser from 'hooks/currentUser'
import { useBranding } from 'hooks'

const DropdownContainer = styled.div`
  background: transparent;
  border-radius: 0;
  border-width: 0 0;
  z-index: 1000;
  position: relative;
  display: flex;
  align-items: center;
  width: 100px;
`

const MenuHeader = styled.div.attrs({ id: 'notifications-menu' })`
  background: transparent;
  position: relative;
  height: 36px;
`

const DropdownRow = styled.div`
  display: flex;
  padding-left: 14px;
  padding-right: 14px;
  background-color: ${props => props.theme.colors.white};
  &:hover {
    background-color: ${props => props.theme.colors.lightGrey};
  }
  align-items: center;
  cursor: ${props => (props.clickable ? 'pointer' : 'regular')};
  height: 55px;
`

const NotificationList = styled.div`
  height: 400px;
  width: 475px;
  overflow-y: auto;
`

const NotificationBox = styled.div`
  position: relative;
  top: 245px;
  overflow: visible;
  ${props => (props.right ? 'right: 490px' : 'right: 50px')};
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.24);
`

const ActionsAndFilters = styled.div`
  margin-top: 0.5em;
`

const Count = styled.div`
  border-radius: 50%;
  background-color: ${({ theme, isWhitelabel }) =>
    isWhitelabel ? theme.colors.whitelabelText : theme.colors.white};
  color: ${({ theme, isWhitelabel }) =>
    isWhitelabel
      ? readableColor(
          theme.colors.whitelabelText,
          theme.colors.white,
          theme.colors.primaryText
        )
      : theme.colors.primaryText};
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 16px;
  position: absolute;
  top: 3px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props =>
    props.status === 'actioned' ? 'rgba(0,0,0,0)' : props.background};
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`

const EventAndCo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-overflow: ellipsis;
  max-height: 100%;
`

const EventSubText = styled.div`
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
`

const RelativeDate = styled(_RelativeDate)`
  padding-left: 1rem;
  justify-self: flex-end;
  flex-shrink: 0;
`

const EventName = styled.a`
  text-decoration: none;
  font-weight: ${props => (props.status === 'Actioned' ? 300 : 600)};
  color: ${props =>
    props.status === 'Actioned'
      ? props.theme.colors.primaryText
      : props.theme.colors.link};
`

const Icon = styled(_Icon)`
  font-size: 12px;
`

const ShowAll = styled.p`
  text-align: center;
  color: ${props => props.theme.colors.primaryText};
`
const ZeroState = styled.div`
  height: 450px;
  width: 475px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.primaryText};
  font-family: ${props => props.theme.fonts.primary};
`

const POLL_INTERVAL = 300000 // Every 5 mins
const MAX_DESKTOP_SUBTEXT_LENGTH = 42

const Row = ({ item, history, userType, toggle, notificationActioned }) => {
  const notificationProperties = translateNotification(item.event)
  const user = useCurrentUser()
  const rowLink = linkNavigator(userType, item)
  const subText = truncate(
    getNotificationSubtext(item.relatesTo, item.event, user),
    MAX_DESKTOP_SUBTEXT_LENGTH
  )

  return (
    notificationProperties && (
      <ErrorBoundary fallback={null}>
        <Amplitude
          eventProperties={{
            notificationName: notificationProperties.text,
            ...notificationsAnalyticsProvider(item.relatesTo),
          }}>
          {({ instrument }) => (
            <DropdownRow
              clickable={rowLink}
              onClick={instrument('NotificationClicked', () => {
                rowLink && history.push(rowLink)
                if (
                  notificationProperties.name === 'Message.Received' ||
                  notificationProperties.name === 'Broadcast.Received'
                ) {
                  setTimeout(scrollTo(`message:${item.relatesTo.id}`), 0)
                }
                toggle()
                notificationActioned({
                  input: {
                    notificationEventId: item.id,
                  },
                })
              })}>
              <Circle
                background={
                  item.status === 'Actioned'
                    ? colors.white
                    : notificationProperties.color
                }
                status={item.status}>
                <Icon
                  icon={notificationProperties.icon}
                  color={
                    item.status !== 'Actioned' ? colors.white : colors.disabled
                  }
                />
              </Circle>
              <EventAndCo>
                <EventName status={item.status}>
                  {notificationProperties.text}
                </EventName>
                <EventSubText>{subText}</EventSubText>
              </EventAndCo>
              <RelativeDate date={item.occurredAt} />
            </DropdownRow>
          )}
        </Amplitude>
      </ErrorBoundary>
    )
  )
}

const NotificationsMenuDropdown = ({
  history,
  notificationViewed,
  notificationActioned,
  right,
  iconColor,
}) => {
  const controls = useAnimation()
  const animateBell = () => {
    controls.start({
      transition: { duration: 1.5 },
      rotate: [
        0, 30, -28, 34, -32, 30, -28, 26, -24, 22, -20, 18, -16, 14, -12, 10,
        -8, 6, -4, 2, -1, 1, 0, 0,
      ],
      times: [
        0, 0.01, 0.03, 0.05, 0.07, 0.09, 0.11, 0.13, 0.15, 0.17, 0.19, 0.21,
        0.23, 0.25, 0.27, 0.29, 0.31, 0.33, 0.35, 0.37, 0.39, 0.41, 0.43, 1,
      ],
    })
    setTimeout(() => controls.stop(), 5000)
  }
  const { isWhitelabel } = useBranding()

  const [numberOfUnreadNotifications, setNumberOfUnreadNotifications] =
    useState(0)
  const ref = useRef()
  const {
    loading,
    error,
    data,
    fetchMore,
    startPolling,
    stopPolling,
    refetch,
  } = useNotificationEventsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: POLL_INTERVAL,
  })

  const [isOpen, setOpen] = useState(false)
  useOnClickOutside(ref, () => setOpen(false))
  useEffect(() => {
    const unread = data?.viewer?.user?.notificationEvents?.unreadCount
    if (unread && unread > numberOfUnreadNotifications) {
      animateBell()
      setTimeout(() => controls.stop(), 5000)
    }
    setNumberOfUnreadNotifications(unread)
  }, [data])

  const close = () => {
    startPolling(POLL_INTERVAL)
    setOpen(false)
  }
  const open = () => {
    refetch()
    stopPolling()
    setOpen(true)
  }

  const toggle = () => {
    isOpen ? close() : open()
  }

  if (error) throw error

  const notifications = data?.viewer?.user?.notificationEvents

  if (!notifications) {
    return null
  }

  return (
    <Amplitude
      eventProperties={{
        unread: notifications && notifications.unreadCount,
      }}>
      {({ instrument }) => (
        <UserContext.Consumer>
          {user => (
            <DropdownContainer>
              <MenuHeader
                onClick={instrument('NotificationCenterOpened', () => {
                  toggle()
                  const viewed = notifications.nodes
                  if (viewed && viewed.length > 0) {
                    notificationViewed({
                      input: {
                        notificationEventIds: viewed.map(
                          notification => notification.id
                        ),
                      },
                    })
                  }
                })}>
                <NotificationsMenuIcon
                  animate={controls}
                  iconColor={iconColor}
                />
                {numberOfUnreadNotifications > 0 && (
                  <Count isWhitelabel={isWhitelabel}>
                    {numberOfUnreadNotifications > 99
                      ? '99+'
                      : numberOfUnreadNotifications}
                  </Count>
                )}
              </MenuHeader>
              {isOpen && (
                <NotificationBox right={right} ref={ref}>
                  {loading && !notifications ? (
                    <Loading />
                  ) : notifications.nodes.length > 0 ? (
                    <>
                      <ActionsAndFilters>
                        <MarkAllAsRead instrument={instrument} />
                      </ActionsAndFilters>
                      <NotificationList>
                        {notifications.nodes.map(notification => (
                          <Row
                            key={notification.id}
                            item={notification}
                            history={history}
                            userType={user.currentUser.type}
                            toggle={toggle}
                            notificationActioned={notificationActioned}
                          />
                        ))}
                        {notifications.pageInfo.hasNextPage ? (
                          <LoadMoreNotifications
                            fetchMore={fetchMore}
                            notifications={notifications}
                            notificationViewed={notificationViewed}
                          />
                        ) : (
                          <ShowAll>End of List</ShowAll>
                        )}
                      </NotificationList>
                    </>
                  ) : (
                    <ZeroState>
                      <p>No New Notifications</p>
                    </ZeroState>
                  )}
                </NotificationBox>
              )}
            </DropdownContainer>
          )}
        </UserContext.Consumer>
      )}
    </Amplitude>
  )
}

export default compose(
  withRouter,
  notificationViewedMutation(),
  notificationActionedMutation()
)(NotificationsMenuDropdown)
