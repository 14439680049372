import React from 'react'

import { Row } from 'atoms'
import Field from 'molecules/Field'
import { money } from 'atoms/$'

const BindContent = ({ submission, quotation }) => {
  return (
    <>
      <Row>
        <Field label="Named Insured" value={submission.name} />
      </Row>
      <Row>
        <Field label="Limit" value={money(quotation.limit)} />
        <Field label="Deductible" value={money(quotation.deductible)} />
        <Field label="Premium" value={money(quotation.premium)} />
      </Row>
    </>
  )
}

export default BindContent
