import React, { useState, useEffect } from 'react'

import { Flex, Error, Icon, Row, Text } from 'atoms'
import { colors } from 'components/theme'

const ErrorOnly = ({
  meta,
  bg,
  errorMessage,
  showBeforeTouch = false,
  ...props
}) => {
  const [error, setError] = useState(meta.error || meta.submitError)

  useEffect(() => {
    if (meta.error || meta.submitError) {
      setError(meta.error || meta.submitError)
    } else {
      setError('')
    }
  }, [meta.error, meta.submitError])

  return (
    <Flex flexDirection="column" {...props} bg={bg}>
      {(meta.touched || showBeforeTouch) &&
        (errorMessage ? (
          <Row alignItems="baseline">
            <Icon icon="exclamation-circle" color={colors.error} mr={0} />
            <Text color={colors.error}>{errorMessage}</Text>
          </Row>
        ) : (
          error && <Error>{error}</Error>
        ))}
    </Flex>
  )
}

export default ErrorOnly
