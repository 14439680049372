import {
  MoneyRenderer,
  MultiColumnRenderer,
  MultiTitleRenderer,
  TextRenderer,
  TitleRenderer,
  MultiTypeRenderer,
} from '../../../renderers'
import { uniq } from 'util/lodash'

const getAutoLine = quotation => {
  return quotation.quotedLines?.find(
    ql => ql.__typename === 'PCGAutoQuotedLineType'
  )
}

const getAutoLines = quotations => {
  return quotations
    .flatMap(quote =>
      quote.quotedLines?.filter(ql => ql.__typename === 'PCGAutoQuotedLineType')
    )
    .filter(Boolean)
}
const getVehicles = quotations => {
  const autos = getAutoLines(quotations)
  const allVehicles = autos
    .map(al => al.auto.agreedValues)
    .filter(Boolean)
    .sort((a, b) => b.length - a.length) // sort from max to min
  return allVehicles[0].map(vehicle => vehicle.vehicle).sort()
}
const getAgreedValue = ({ quoteIdx, vehicleIdx, quotations }) => {
  return (quotation, criterion) => {
    const agreedValues = getAutoLine(quotation)?.auto?.agreedValues?.filter(
      vehicle => vehicle.vehicle === criterion.label
    )
    if (!agreedValues) return undefined
    if (agreedValues.length === 1) return agreedValues[0]
    const vehicle = agreedValues[vehicleIdx]
    if (quoteIdx < quotations.length) {
      quoteIdx++
    } else {
      quoteIdx = 1
      vehicleIdx = vehicleIdx < agreedValues.length - 1 ? vehicleIdx + 1 : 0
    }

    return vehicle
  }
}
const getAdditionalCoverages = quotations => {
  const autos = getAutoLines(quotations)
  const allCoverages = autos
    .flatMap(al => al.auto?.additionalCoverages)
    .filter(Boolean)
  return uniq(allCoverages.map(coverage => coverage.name))
}
const getAdditionalFeatures = quotations => {
  const autos = getAutoLines(quotations)
  const allFeatures = autos.flatMap(al => al.additionalFeatures).filter(Boolean)
  return uniq(allFeatures.map(feature => feature.feature))
}
const renderAutoCoverage = () => {
  return [
    {
      label: 'Auto',
      renderer: TitleRenderer,
      icon: 'car',
    },
    quotations => {
      const auto = quotations[0].coverages.filter(
        coverage => coverage.type === 'PCG::AutoCoverage'
      )

      const vehicles = auto[0].vehicles || []
      return vehicles.map(vehicle => ({
        label: `${vehicle.make} ${vehicle.model} ${vehicle.year}`,
        renderer: TextRenderer,
      }))
    },
  ]
}
const renderAutoQuotedLine = (quotedLine, idx) => {
  return [
    {
      __typename: 'PCGAutoQuotedLineType',
      label: 'Auto',
      renderer: TitleRenderer,
      icon: 'car',
    },
    {
      label: 'Agreed Values',
      renderer: MultiTitleRenderer,
      titles: ['Value'],
    },
    quotations => {
      const vehicles = getVehicles(quotations)

      const finder = getAgreedValue({ quoteIdx: 1, vehicleIdx: 0, quotations })
      return vehicles.map(vehicle => ({
        label: vehicle,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [{ renderer: MoneyRenderer, key: 'value' }],
      }))
    },
    {
      label: 'Coverage Limits',
      renderer: MultiTitleRenderer,
      titles: ['Limit'],
    },
    {
      key: `quotedLines[${idx}].auto.bodilyInjuryAndPropertyDamage`,
      indent: true,
      label: 'Bodily Injury And Property Damage',
      renderer: MoneyRenderer,
    },
    {
      key: `quotedLines[${idx}].auto.bodilyInjuryPerPerson`,
      indent: true,
      label: 'Bodily Injury Per Person',
      renderer: MoneyRenderer,
    },
    {
      key: `quotedLines[${idx}].auto.bodilyInjuryEachAccident`,
      indent: true,
      label: 'Bodily Injury Each Accident',
      renderer: MoneyRenderer,
    },
    {
      key: `quotedLines[${idx}].auto.propertyDamage`,
      label: 'Property Damage',
      indent: true,
      renderer: MoneyRenderer,
    },
    {
      key: `quotedLines[${idx}].auto.medicalPayments`,
      indent: true,
      label: 'Medical Payments',
      renderer: MultiTypeRenderer,
    },
    {
      key: `quotedLines[${idx}].auto.underinsuredMotorists`,
      indent: true,
      label: 'Underinsured Motorists',
      renderer: MultiTypeRenderer,
    },
    {
      key: `quotedLines[${idx}].auto.extendedTowingAndLabor`,
      indent: true,
      label: 'Extended Towing and Labor',
      renderer: MultiTypeRenderer,
    },
    {
      key: `quotedLines[${idx}].auto.fullGlassCoverage`,
      indent: true,
      label: 'Full Glass Coverage',
      renderer: MultiTypeRenderer,
    },

    quotations => {
      const coverages = getAdditionalCoverages(quotations)

      const finder = (quotation, criterion) => {
        const auto = getAutoLine(quotation)
        return auto?.auto?.additionalCoverages?.find(
          coverage => coverage.name === criterion.label
        )
      }

      return coverages.map(coverage => ({
        label: coverage,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [
          {
            renderer: MultiTypeRenderer,
            key: 'limit',
            props: {},
          },
        ],
      }))
    },
    {
      label: 'Deductibles',
      renderer: MultiTitleRenderer,
      titles: ['Limit'],
    },
    {
      key: `quotedLines[${idx}].auto.collisionDeductible`,
      indent: true,
      label: 'Collision Deductible',
      renderer: MultiTypeRenderer,
    },
    {
      key: `quotedLines[${idx}].auto.comprehensiveDeductible`,
      indent: true,
      label: 'Comprehensive Deductible',
      renderer: MultiTypeRenderer,
    },

    {
      label: 'AdditionalFeatures',
      renderer: MultiTitleRenderer,
      titles: ['Included?'],
    },
    quotations => {
      const features = getAdditionalFeatures(quotations)

      const finder = (quotation, criterion) => {
        const auto = getAutoLine(quotation)
        return auto?.additionalFeatures?.find(
          feature => feature.feature === criterion.label
        )
      }
      return features.map(feature => ({
        label: feature,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [{ renderer: TextRenderer, key: 'included', props: {} }],
      }))
    },

    {
      key: `quotedLines[${idx}].totalPremium`,
      label: 'TOTAL PREMIUM',
      renderer: MoneyRenderer,
    },
  ]
}

export {
  renderAutoQuotedLine,
  renderAutoCoverage,
  getAutoLine,
  getAutoLines,
  getAgreedValue,
  getVehicles,
  getAdditionalCoverages,
  getAdditionalFeatures,
}
