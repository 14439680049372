import React from 'react'

import { Flex, Icon, Pill, Text } from 'atoms'

const PillRenderer = ({
  criterion: {
    icon,
    status,
    border,
    fg = 'primaryText',
    bg = 'certificate',
    ...rest
  },
}) => (
  <Flex textAlign="center" alignItems="center" justifyContent="center">
    <Pill bg={bg} color={fg} border={border} {...rest}>
      <Icon fontSize="l" mr={1} icon={icon} color={fg} />
      <Text color={fg}>{status}</Text>
    </Pill>
  </Flex>
)

export default PillRenderer
