import React, { useRef, useState } from 'react'
import { bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Modal, Typography } from 'antd'

import { Div } from 'atoms'
import { Link } from 'components/LegalLink'
import noop from 'lodash/noop'

import TermsAndConditionsCheckbox from '../TermsAndConditionsCheckbox'

const { Text: TextAntd } = Typography

const Text = styled(TextAntd)`
  display: block;
  padding-bottom: 10px;
`

const Star = styled.span`
  color: red;
  font-size: 18px;
`

const StatementOfWorkModal = props => {
  const {
    show,
    onOk = noop,
    onCancel = noop,
    afterCloseScrollToContainerRef = { current: undefined },
  } = props

  const { t } = useTranslation()

  const {
    title,
    content: { text, list },
  } = t('translations:cyber.carrierStatementOfFact.modal')

  return (
    <Modal
      title={<Text strong>{title}</Text>}
      visible={show}
      onOk={onOk}
      onCancel={onCancel}
      afterClose={() => {
        afterCloseScrollToContainerRef.current &&
          afterCloseScrollToContainerRef.current.scrollIntoView({
            block: 'nearest',
            inline: 'nearest',
          })
      }}
      okText="Accept"
      cancelText="Decline"
      zIndex={10000}>
      <Div px={1}>
        <Text>{text}</Text>
        <ul>
          {list.map((item, index) => {
            const key = `modal-content-list-item-${index}`
            return <li key={key}>{item}</li>
          })}
        </ul>
      </Div>
    </Modal>
  )
}

StatementOfWorkModal.propTypes = {
  show: bool,
  onOk: func,
  onCancel: func,
}

const CarrierStatementOfFactCheckbox = props => {
  const { value, onChange } = props

  const [checked, setChecked] = useState(value)
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const { termsText, summary } = t('translations:cyber.carrierStatementOfFact')

  const checkboxRef = useRef()

  const handleCloseModal = isChecked => {
    handleChange(isChecked)
    setShowModal(false)
  }

  const handleChange = isChecked => {
    setChecked(isChecked)
    onChange(isChecked)
  }

  return (
    <>
      <TermsAndConditionsCheckbox
        {...props}
        inputRef={checkboxRef}
        value={checked}
        onChange={handleChange}>
        {summary}
        <Link
          large
          onClick={() => {
            setShowModal(true)
          }}>
          {termsText}
        </Link>
        .<Star>*</Star>
      </TermsAndConditionsCheckbox>
      <StatementOfWorkModal
        show={showModal}
        onOk={() => handleCloseModal(true)}
        onCancel={() => handleCloseModal(false)}
        afterCloseScrollToContainerRef={checkboxRef}
      />
    </>
  )
}

export default CarrierStatementOfFactCheckbox
