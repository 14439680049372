import SelectPanelistsField from 'components/react-json-schema-form/Fields/SelectPanelistsField'
import InviteCarrierField from 'components/react-json-schema-form/Fields/InviteCarriersField'
import { default as CarrierStatementOfFactCheckbox } from 'components/react-json-schema-form/Widgets/CheckboxWidget/CarrierStatementOfFactCheckbox'
import { default as PlacementTermsCheckbox } from 'components/react-json-schema-form/Widgets/CheckboxWidget/PlacementTermsCheckbox'

const fields = {
  panelists: SelectPanelistsField,
  carriers: InviteCarrierField,
}

const customFormats = {
  'ZIP code': /\d{5}/,
  URL: /[a-z0-9]\.[a-z]/,
}

const widgets = {
  'carrier-statement-of-fact-checkbox': CarrierStatementOfFactCheckbox,
  'placement-terms-checkbox': PlacementTermsCheckbox,
}

export { customFormats, fields, widgets }
export default { customFormats, fields, widgets }
