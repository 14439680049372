export const isEnv = env => env === process.env.NODE_ENV

const isDevEnv = () => isEnv('development')
const isProdEnv = () => isEnv('production')

const env = () => ({
  isDev: isDevEnv(),
  isNotDev: !isDevEnv(),
  isProd: isProdEnv(),
  isNotProd: !isProdEnv(),
})

export const { isDev, isNotDev, isProd, isNotProd } = env()
