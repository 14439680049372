import React from 'react'

import Filename from 'molecules/DocumentUpload/Filename'
import { Column, Subtitle, Text } from 'atoms'

const DocumentsReadOnly = ({ formData: documents = [], schema }) => {
  const title = schema.title
  return (
    <Column>
      {title && (
        <Subtitle m="0px" mb={0}>
          {title.toUpperCase()}
        </Subtitle>
      )}

      {documents.length > 0 ? (
        documents.map((file, index) => {
          if (file._destroy) return null

          return <Filename key={index} maxLength={80} document={file} />
        })
      ) : (
        <Text>No documents uploaded</Text>
      )}
    </Column>
  )
}

export default DocumentsReadOnly
