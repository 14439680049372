import React from 'react'
import unionBy from 'lodash/unionBy'
import { faTrash, faDownload } from '@fortawesome/pro-solid-svg-icons'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'

import { FileButton, FileLink } from 'components/common/Uploaders'
import { Column } from 'components/common/Layout'
import { Row, Div } from 'atoms'
import ContextMenu from 'molecules/ContextMenu'
import Icon from 'components/Icon'
import Error from 'components/common/Error'
import { AcceptedStatuses, BoundStatuses } from 'util/statusType'
import NewDocument from 'molecules/Document.js'
import { colors } from 'components/theme'

const WordDraftAndTrashMenu = ({ wordDraft, remove }) => (
  <ContextMenu
    size="small"
    right
    options={[
      {
        title: 'Download as Word',
        icon: faDownload,
        onClick: () => window.open(wordDraft.url || null),
      },
      { title: 'Remove', icon: faTrash, onClick: remove },
    ]}
  />
)

export const Document = ({
  document,
  remove,
  docInfo,
  binderFileRequired,
  wordDraft,
}) => {
  const brokerBinding = binderFileRequired
  return (
    <Row alignItems="center">
      <NewDocument
        onClick={e => e.preventDefault()}
        document={document}
        showTime={false}></NewDocument>
      <Div
        px={0}
        flex={1}
        fontSize={0}
        fontWeight={0}
        color={colors.primaryTextMid}>
        {docInfo}
      </Div>
      {brokerBinding && docInfo === '(Autogenerated)' ? (
        <WordDraftAndTrashMenu
          data-testid={'wordAndTrash'}
          wordDraft={wordDraft}
          remove={remove}
        />
      ) : (
        <Icon icon={faTrash} onClick={remove} data-testid={'trash'} />
      )}
    </Row>
  )
}

export const UploadLink = ({ input, label = 'Upload Document', ...props }) => {
  const onDrop = accepted => {
    input.onChange(unionBy(accepted, input.value, 'name'))
  }
  return (
    <FileLink onClick={e => e.preventDefault()} onDrop={onDrop} {...props}>
      <Row padding="4px 0px" alignItems="center">
        <Icon icon={faPlusCircle} color={colors.link} mr={'.5rem'} />
        {label}
      </Row>
    </FileLink>
  )
}

export const UploadButton = ({
  input,
  label = 'Upload Document',
  ...props
}) => {
  const onDrop = accepted => {
    input.onChange(unionBy(accepted, input.value, 'name'))
  }

  return (
    <FileButton onClick={e => e.preventDefault()} onDrop={onDrop} {...props}>
      {label}
    </FileButton>
  )
}

const Uploader = ({
  input,
  meta,
  multipleFiles,
  renderWhenEmpty,
  status,
  binderFileRequired,
  binderWordDraft,
  certificateWordDraft,
  docInfo,
  ...rest
}) => {
  const onDrop = accepted => {
    input.onChange(unionBy(accepted, input.value, 'name'))
  }
  if (input.value && input.value.length > 0) {
    return (
      <Column gap="0.5rem" marginTop="1rem">
        {input.value.map(file => {
          return (
            <Document
              document={file}
              key={file.name}
              binderFileRequired={binderFileRequired}
              wordDraft={
                file.url && BoundStatuses.includes(status)
                  ? certificateWordDraft
                  : file.url && AcceptedStatuses.includes(status)
                  ? binderWordDraft
                  : null
              }
              remove={() => {
                input.onChange(input.value.filter(f => f.name !== file.name))
              }}
              docInfo={file.url ? docInfo : null}
            />
          )
        })}
        {meta.touched && meta.error && <Error>{meta.error}</Error>}
        {multipleFiles && <FileLink onDrop={onDrop}>Add Another</FileLink>}
      </Column>
    )
  }

  if (renderWhenEmpty) {
    return renderWhenEmpty({ input, meta, ...rest })
  }

  if (meta.touched && meta.error) {
    return <Error>{meta.error}</Error>
  }

  return null
}

export default Uploader
