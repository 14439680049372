import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBopReturnPreviousStateMutation } from 'graphql/api'

const useBackButton = (formData, currentTab, pages) => {
  const { submissionId } = useParams()
  const [loading, setLoading] = useState(false)
  const [bopMutation] = useBopReturnPreviousStateMutation()
  const currentPage = pages[currentTab]

  if (currentPage === 'business_owner') {
    const { backButtonTitle, onBack } = bopBackButton({
      submissionId,
      setLoading,
      mutation: bopMutation,
      formCurrentState: formData[currentPage].form_current_state,
      skipBasicCapture: formData[currentPage].skip_basic_appetite_capture,
    })
    return {
      backButtonTitle,
      onBack,
      loading,
    }
  } else {
    return {
      onBack: undefined,
      backButtonTitle: undefined,
      loading: false,
    }
  }
}

const bopBackButton = ({
  formCurrentState,
  setLoading,
  mutation,
  submissionId,
  skipBasicCapture,
}) => {
  let backButtonTitle
  if (formCurrentState === 'basic_appetite') {
    backButtonTitle = 'Carriers Selection'
  } else if (formCurrentState === 'basic_appetite_capture') {
    backButtonTitle = 'Basic Appetite'
  } else if (
    formCurrentState === 'enhanced_appetite' &&
    skipBasicCapture === false
  ) {
    backButtonTitle = 'Basic Appetite Capture'
  } else if (
    formCurrentState === 'enhanced_appetite' &&
    skipBasicCapture === true
  ) {
    backButtonTitle = 'Basic Appetite'
  }
  const onBack = async formData => {
    setLoading(true)
    await mutation({
      variables: {
        submissionId,
        input: {
          attributes: { formData },
          submissionId: submissionId,
        },
      },
    })
    setLoading(false)
  }
  return { backButtonTitle, onBack: backButtonTitle ? onBack : undefined }
}

export default useBackButton
