export const cognitoBrandingOverrides = {
  appund: {
    'us-east-1_UCtTVbiEM': {
      userPoolWebClientId: '6sq3lt27nsuit6ajsoe2ij6qji',
    },
    'us-east-1_giCVtHZq2': {
      userPoolWebClientId: '7do82g7gp2gh1f128sl4uveaj4',
    },
  },
}
