import { readableColor } from 'polished'
import merge from 'lodash/merge'

import theme from 'components/theme'
import auiLogo from 'images/logos/wholesalers/auiLogo.png'
import {
  customerSSORedirectURI,
  customerSSOCognitoOverrides,
} from 'relay/config'

export const DOMAINS = [
  'appund.relay.test',
  'appund.demo.relayplatform.com',
  'appund.relayplatform.com',
]
const headerColor = '#ffffff'

const whitelabelText = readableColor(
  headerColor,
  theme.colors.primaryText,
  theme.colors.white,
  true
)

const subdomain = 'appund'

export default {
  brand: subdomain,
  name: 'Appalachian Underwriters, Inc.',
  portalName: 'Xpress Cyber',
  logo: auiLogo,
  logoAlt: 'Appalachian Underwriters, Inc.',
  cobranded: false,
  showHelp: true,
  allowSignup: false,
  singleSignOnOnly: true,
  billable: true,
  theme: {
    ...theme,
    colors: {
      ...theme.colors,
      header: headerColor,
      whitelabelText: whitelabelText,
    },
  },
  amplify: {
    Auth: merge(
      {
        oauth: {
          redirectSignIn: customerSSORedirectURI(subdomain),
          redirectSignOut: customerSSORedirectURI(subdomain),
        },
      },
      customerSSOCognitoOverrides(subdomain)
    ),
  },
}
