import React from 'react'
import styled from 'styled-components'

const LoadMoreLink = styled.p`
  cursor: pointer;
  color: ${props => props.theme.colors.link};
  text-align: center;
`

const LoadMoreNotifications = ({
  notifications,
  fetchMore,
  notificationViewed,
}) => (
  <LoadMoreLink
    onClick={() => {
      fetchMore({
        variables: {
          cursor: notifications.pageInfo.endCursor,
        },
        updateQuery: (previous, { fetchMoreResult }) => {
          const newResults =
            fetchMoreResult.viewer.user.notificationEvents.nodes
          const pageInfo =
            fetchMoreResult.viewer.user.notificationEvents.pageInfo
          notificationViewed({
            input: {
              notificationEventIds: newResults.map(
                notification => notification.id
              ),
            },
          })
          return newResults.length
            ? {
                viewer: {
                  ...previous.viewer,
                  user: {
                    ...previous.viewer.user,
                    notificationEvents: {
                      ...previous.viewer.user.notificationEvents,
                      nodes: [
                        ...previous.viewer.user.notificationEvents.nodes,
                        ...newResults,
                      ],
                      pageInfo,
                    },
                  },
                },
              }
            : previous
        },
      })
    }}>
    Load More
  </LoadMoreLink>
)

export default LoadMoreNotifications
