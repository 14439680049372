import React from 'react'
import styled from 'styled-components'

import genericAvatar from 'images/avatar.svg'
import { AvatarFragmentDoc } from 'graphql/api'
import { isDummyReinsurer } from '../components/submission/utils'

const avatarColors = [
  '#4E9EEF',
  '#1B7655',
  '#4D58BD',
  '#4D89B5',
  '#4DB6BD',
  '#56BD4D',
  '#5D8E59',
  '#7A34B1',
  '#9A5833',
  '#A30062',
  '#A56FFC',
  '#E19A59',
  '#EF4EAF',
]

const scale = size => {
  switch (size) {
    case 'xlarge':
      return { container: 104, font: 24 }
    case 'large':
      return { container: 64, font: 14 }
    default:
      return { container: 48, font: 10 }
  }
}

const UserAvatar = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: ${props => scale(props.size).container}px;
  width: ${props => scale(props.size).container}px;
  border: ${props => (props.thickBorder ? '3px' : '1px')} solid
    ${props =>
      props.thickBorder
        ? props.theme.colors.white
        : props.theme.colors.secondaryBlue};
  border-radius: 50%;
  background-image: url('${props => props.src}');
  background-color: ${props => props.color || 'white'};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: ${props =>
    props.thickBorder ? ' 0 0 15px rgba(0, 0, 0, 0.25);' : 'none'};
`

const FallbackAvatar = styled(UserAvatar)`
  background-size: 66%;
`

const Initials = styled.span`
  color: ${props => props.color};
  text-align: center;
  font-size: ${props => scale(props.size).font}px;
  margin-top: 45%;
  font-weight: 600;
`

const AvatarContainer = styled.div`
  margin: ${props => !props.edit && '0px 24px 0px 24px'};
`

export const userToColor = user => {
  if (!user?.id || isDummyReinsurer(user)) return '#747474'
  const seed = parseInt(user.id)
    ? parseInt(user.id)
    : parseInt(user.id.replace(/\D/g, ''))
  return avatarColors[seed % avatarColors.length]
}

const initials = user => {
  if (typeof user !== 'object') return ''
  if (user.fullName && user.fullName.split(' ').length > 1) {
    return user.fullName
      .split(' ')
      .map(word => word[0])
      .join('')
  } else if (user.firstName && user.lastName) {
    return `${user.firstName[0]}${user.lastName[0]}`
  }
  return ''
}

const DefaultAvatar = ({ size, user, thickBorder = false }) => {
  const color = userToColor(user)

  return (
    <FallbackAvatar
      size={size}
      src={genericAvatar}
      color={color}
      thickBorder={thickBorder}>
      <Initials size={size} color={color}>
        {initials(user)}
      </Initials>
    </FallbackAvatar>
  )
}

const Avatar = ({ user, size, edit, thickBorder = false }) => {
  const avatar =
    user && user.avatarUrl ? (
      <UserAvatar size={size} src={user.avatarUrl} thickBorder={thickBorder} />
    ) : (
      <DefaultAvatar size={size} user={user} thickBorder={thickBorder} />
    )

  return size ? <AvatarContainer edit={edit}>{avatar}</AvatarContainer> : avatar
}

Avatar.fragment = AvatarFragmentDoc

export default Avatar
