import reinsurerNotificationLinks from './reinsurer'
import brokerNotificationLinks from './broker'

const notificationLinks = (userType, lineOfBusiness) => {
  switch (userType) {
    case 'Reinsurer':
      return reinsurerNotificationLinks(lineOfBusiness)
    case 'Broker':
      return brokerNotificationLinks(lineOfBusiness)
    default:
      return brokerNotificationLinks(lineOfBusiness)
  }
}

export default notificationLinks
