import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Icon from 'components/Icon'
import { Amplitude } from '@amplitude/react-amplitude'

const StyledItem = styled.li`
  padding: 8px 20px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  list-style: none;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 12%);
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.primaryText};
  font-size: 15px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  text-decoration: none;
`

const Title = styled.div`
  font-size: 16px;
  line-height: 32px;
  color: ${props => props.theme.colors.link};
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 8px;
  }
`

const Description = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.primaryTextMid};
`

const Body = ({ title, icon, description }) => (
  <>
    <Title>
      {icon && <Icon icon={icon} />} {title}
    </Title>
    <Description>{description}</Description>
  </>
)

const MenuItem = ({
  icon,
  title,
  description,
  confirmationMessage,
  onClick,
  eventName,
  autoClose = false,
  closeMenu,
  to,
}) => {
  const clickHandler = e => {
    let confirm = true

    if (confirmationMessage) {
      confirm = window.confirm(confirmationMessage)
    }

    if (confirm) {
      onClick()
    }

    if (autoClose) {
      closeMenu()
    }

    e.stopPropagation()
  }

  return (
    <Amplitude>
      {({ instrument }) => {
        const props = to
          ? { as: Link, to, onClick: closeMenu }
          : {
              onClick: instrument(
                eventName || 'ContextMenuItemClicked',
                clickHandler
              ),
            }
        return (
          <StyledItem {...props} key={title}>
            <Body title={title} description={description} icon={icon} />
          </StyledItem>
        )
      }}
    </Amplitude>
  )
}

export default MenuItem
