import React from 'react'
import { transparentize } from 'polished'

import { colors, fonts } from 'components/theme'
import { Flex, Label, Error } from 'atoms'
import {
  LazyRSSelect as Select,
  LazyRSCreatable as Creatable,
} from 'atoms/react-select'

const MultiSelect = ({
  input: { name, value, onChange, ...otherInputProps },
  meta: { touched, error },
  exclude,
  creatable,
  shouldHighlight,
  label,
  required,
  ...rest
}) => {
  const Component = creatable ? Creatable : Select
  return (
    <Flex {...rest} flexDirection="column">
      {label && (
        <Label htmlFor={name} required={required}>
          {label}
        </Label>
      )}
      <Component
        isMulti
        isSearchable
        inputId={name}
        value={value}
        onChange={onChange}
        {...otherInputProps}
        styles={{
          control: currentStyle => ({
            ...currentStyle,
            fontFamily: fonts.secondary,
            fontSize: '18px',
            color: colors.primaryText,
            border: '0px',
            borderBottom: `1px solid ${
              touched && error
                ? colors.error
                : transparentize(0.7, colors.primaryText)
            }`,
            '&:hover': {
              borderBottom: `1px solid ${colors.primaryText}`,
            },
            borderRadius: 0,
            background: `${
              shouldHighlight ? colors.lightGreen : 'transparent'
            }`,
          }),
          indicatorSeparator: () => {},
          dropdownIndicator: current => ({ ...current, color: '#7D7D86' }),
          singleValue: current => ({
            ...current,
            color: colors.primaryText,
            fontFamily: fonts.secondary,
          }),
          menu: styles => ({
            ...styles,
            fontSize: '16px',
            zIndex: 100,
          }),
          multiValue: styles => ({
            ...styles,
            height: '22px',
            borderRadius: '25px',
            marginRight: '8px',
            marginBottom: '4px',
            backgroundColor: `${exclude ? colors.error : colors.control}`,
            textDecoration: `${exclude ? 'line-through' : 'none'}`,
            outline: 'none',
            color: colors.white,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            ':hover': {
              backgroundColor: 'initial',
              color: colors.primaryTextLight,
              div: {
                color: colors.primaryTextLight,
              },
              border: `1px solid ${colors.secondaryBlue}`,
            },
          }),
          multiValueLabel: () => ({
            color: colors.white,
            fontFamily: fonts.secondary,
            fontWeight: 'bold',
            padding: '0 1.5rem 0 1rem',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            transform: 'translateY(-1px)',
          }),
          multiValueRemove: () => ({
            color: colors.white,
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: '0.5rem',
          }),
        }}
        {...rest}
      />
      {error && touched && <Error>{error}</Error>}
    </Flex>
  )
}

export default MultiSelect
