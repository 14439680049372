import React from 'react'

import NotificationsMenuDropdown from './NotificationsMenuDropdown'
import { LoggedInOnly } from 'components/contexts/user'

const NotificationsMenu = props => (
  <LoggedInOnly>
    <NotificationsMenuDropdown {...props} />
  </LoggedInOnly>
)

export default NotificationsMenu
