import React from 'react'

import { $, Flex } from 'atoms'

const AdditionalFeaturesTextRenderer = ({ children = {} }) => {
  const { description } = children
  return <Flex>{description || <$ fallback="—" />}</Flex>
}

export default AdditionalFeaturesTextRenderer
