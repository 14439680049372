import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import {
  makeStyles,
  withStyles,
  Paper,
  Box,
  Modal,
  Button,
  Slider,
  TextField,
} from '@material-ui/core'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { useUpdateCommissionPercentageMutation } from 'graphql/api'
import { toast } from 'react-toastify'
import { useParams, useHistory } from 'react-router-dom'
import { colors } from 'components/theme'
import { Div, Text, Icon } from 'atoms'
import { formatter } from 'util/percentage'

const useStyles = makeStyles({
  popUp: {
    position: 'relative',
    top: '50%',
    left: '55%',
    maxWidth: '25%',
    minWidth: '25%',
    overflow: 'auto',
    transform: 'translate(-50%, -55%)',
  },

  input: {
    width: 90,
    marginRight: '10px',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  title: {
    display: 'block',
    fontSize: '1.5rem',
    fontWeight: '600',
    color: colors.primaryText,
  },
  submitButton: {
    color: colors.link,
    textTransform: 'none',
    borderColor: colors.link,
    borderRadius: 48,
    fontWeight: '600',
    fontSize: '1rem',
    border: '1.5px solid',
    height: 36,
  },
  closeIcon: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    fontSize: '1.6rem',
  },
  noBorder: {
    border: 'none',
  },
  disabledText: {
    color: colors.primaryText,
  },
  msg: {
    color: colors.primaryTextMid,
    fontSize: '14px',
  },
  error: {
    color: colors.error,
    fontSize: '14px',
  },
  cost: {
    fontWeight: '600',
  },
})

const CommissionSlider = withStyles({
  root: {
    color: colors.link,
  },
  valueLabel: {
    '& *': {
      background: 'transparent',
      color: colors.black,
    },
  },
})(Slider)

const NumberFormatPercentage = props => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      suffix="%"
    />
  )
}

const RetailCommissionSlider = ({
  openForm,
  closeForm,
  quotationId,
  quotedLine,
}) => {
  const classes = useStyles()
  const { submissionId } = useParams()
  const history = useHistory()
  const [percentage, setPercentage] = useState(
    Number(quotedLine.retailCommissionPercentage) * 100
  )
  //Gross Premium before adjustment
  const originalGrossPremium =
    Number(quotedLine.wholesalerCommission) /
    quotedLine.wholesalerCommissionPercentage
  const calculateCommission = percentage =>
    (percentage / 100) * originalGrossPremium
  const [commission, setCommission] = useState(
    calculateCommission(Number(quotedLine.retailCommissionPercentage) * 100)
  )
  const [percentageError, setPercentageError] = useState(false)
  const [UpdateCommissionPercentage] = useUpdateCommissionPercentageMutation()

  const commissionRange =
    (quotedLine.maximumCommissionPercentage -
      quotedLine.wholesalerCommissionPercentage) *
    100

  const calculateCostForClient = percentage =>
    calculateCommission(percentage) +
    originalGrossPremium *
      (1 -
        quotedLine.maximumCommissionPercentage +
        Number(quotedLine.wholesalerCommissionPercentage))

  //round to two decimal places as toFixed() doesn't round the number properly
  const round = num => Math.round(num * 100) / 100

  const updatePercentage = percentage => {
    if (percentage < 0) percentage = 0
    else if (percentage > commissionRange) percentage = commissionRange
    const commission = calculateCommission(percentage)
    setPercentage(round(percentage))
    setCommission(round(commission))
  }

  const handleSliderChange = (event, newValue) => {
    setPercentageError(false)
    updatePercentage(newValue)
  }

  const handlePercentageChanged = event => {
    setPercentageError(false)
    setPercentage(Number(event.target.value))
  }

  const handlePercentageBlur = () => {
    setPercentageError(false)
    updatePercentage(percentage)
  }

  const onSubmit = async () => {
    //it gave chance for onBlur to run
    await new Promise(r => setTimeout(r, 200))
    if (percentage % 0.25 !== 0) {
      setPercentageError(true)
      return
    }
    const result = await UpdateCommissionPercentage({
      variables: {
        input: {
          quotationId,
          commissionPercentage: `${
            percentage + quotedLine.wholesalerCommissionPercentage * 100
          }`,
        },
      },
    })

    if (result.data.updateCommissionPercentage.errors) {
      toast.error('An error occurred when finalising this update')
      return
    }
    toast.success('Update request sent')
    history.push(`/panels/submissions/${submissionId}`)
  }

  return (
    <Modal open={openForm} onClose={closeForm} disableScrollLock>
      <Div className={classes.popUp}>
        <Paper>
          <Icon
            icon={faTimes}
            className={classes.closeIcon}
            onClick={closeForm}
          />
          <Box p={3}>
            <Box p={1}>
              <Text className={classes.title}>Retail Commission</Text>
              <Div className={percentageError ? classes.error : classes.msg}>
                (Adjust in increments of 0.25%)
              </Div>
            </Box>
            <Box display="flex" alignItems="center">
              <Box p={1}>
                <Div>${commission.toFixed(2)}</Div>
              </Box>
              <Box p={1}>
                <Div>=</Div>
              </Box>
              <Box p={1}>
                <TextField
                  className={classes.input}
                  value={round(percentage)}
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  onChange={handlePercentageChanged}
                  onBlur={handlePercentageBlur}
                />
              </Box>
            </Box>
          </Box>
          <Box p={3}>
            <Box p={1}>
              <CommissionSlider
                value={round(percentage)}
                onChange={handleSliderChange}
                aria-labelledby="discrete-slider-small-steps"
                valueLabelDisplay="on"
                valueLabelFormat={value => formatter(value)}
                step={0.25}
                marks
                min={0}
                max={commissionRange}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" p={3}>
            <Box p={1} flexGrow={1}>
              <Text>
                Cost for Insured:{' '}
                <Text className={classes.cost}>
                  ${calculateCostForClient(percentage).toFixed(2)}
                </Text>
              </Text>
            </Box>
            <Box>
              <Button
                variant="outlined"
                classes={{
                  root: classes.submitButton,
                }}
                onClick={onSubmit}>
                Apply
              </Button>
            </Box>
          </Box>
        </Paper>
      </Div>
    </Modal>
  )
}

export default RetailCommissionSlider
