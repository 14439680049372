import React from 'react'

import { Row } from 'atoms'
import Field from 'molecules/Field'
import { money } from 'atoms/$'
import { MLConfig, ProductOptionsTable } from 'pages/shared/panels'
import styled from 'styled-components'

const PaddedRow = styled(Row)`
  margin-bottom: 30px;
`

const QuoteSummary = ({ submission, quotation }) => {
  const { productOptions } = MLConfig.Quotation
  return (
    <>
      <PaddedRow>
        <Field label="Applicant" value={submission.name} />
        <Field label="Annual Premium" value={money(quotation.annualPremium)} />
      </PaddedRow>
      <PaddedRow>
        <ProductOptionsTable {...productOptions(submission)} />
      </PaddedRow>
    </>
  )
}

export default QuoteSummary
