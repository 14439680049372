import React from 'react'
import { EnhancedModal, Subtitle, Div } from 'atoms'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { toast } from 'react-toastify'

import ImportQuestionForm from './ImportQuestionForm.js'
import ImportQuestionPreviewForm from './ImportQuestionPreviewForm.js'
import { useCreateQuestionMutation } from 'graphql/api'

import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'

const ImportModal = ({
  initialValues,
  sections,
  onCompleted,
  scope = QUESTION,
  onClose,
}) => {
  const translator = qandaTranslator(scope)
  let [previewImport, setPreviewImport] = React.useState(false)
  let [questions, setQuestions] = React.useState([])

  const [createQuestion] = useCreateQuestionMutation()

  const onBack = () => {
    setQuestions([])
    setPreviewImport(false)
  }

  const handlePreviewSubmit = values => {
    //since we are using richtext for styling, we need to strip out the html tags
    const questions = (values.text || '')
      .replace(/<(p|li)>/, '')
      .replace(/&nbsp;/g, '')
      .split(/<\/(?:p|li)>/)
      .map(question => {
        return question.replace(/(<([^>]+)>)/gi, '')
      })
      .filter(question => {
        return question.trim().length > 0
      })

    setQuestions(questions)
    setPreviewImport(true)
  }

  const handleImportSubmit = async values => {
    let successCount = 0
    for (const attribute of values.attributes || []) {
      try {
        const { data } = await createQuestion({
          variables: {
            input: {
              attributes: attribute,
              ...initialValues,
              questionableScope: scope,
            },
          },
        })

        if (data.createQuestion.errors) {
          toast.error(data.createQuestion.errors)
          return data.createQuestion.errors
        } else {
          successCount += 1
        }
      } catch (error) {
        //retry handled by apollo retry link
        continue
      }
    }

    onCompleted(successCount, (values.attributes || []).length)
  }

  return (
    <Form
      onSubmit={previewImport ? handleImportSubmit : handlePreviewSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize={false}
      mutators={arrayMutators}>
      {({ handleSubmit }) => (
        <EnhancedModal onClose={onClose}>
          <Div p={2}>
            <Subtitle>
              {previewImport
                ? translator('previewBulkImport')
                : translator('bulkImportQuestion')}
            </Subtitle>
            {previewImport ? (
              <ImportQuestionPreviewForm
                scope={scope}
                onBack={onBack}
                questions={questions}
                sections={sections}
                onSubmit={handleSubmit}
              />
            ) : (
              <ImportQuestionForm
                scope={scope}
                onClose={onClose}
                onSubmit={handleSubmit}
              />
            )}
          </Div>
        </EnhancedModal>
      )}
    </Form>
  )
}

export default ImportModal
