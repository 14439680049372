import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Paper, Modal, Box, Button, Dialog, Badge } from '@material-ui/core'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'

import { Icon, Subtitle, Text, Flex, Button as StyledButton } from 'atoms'
import ModalWrapper from 'components/common/ModalWrapper'
import { scrollToWithOpts } from 'util/scroll'
import { pluralise } from 'util/string.js'

import Form, { useFlexiForm } from 'FlexiForm'
import FirmUpContext from 'components/contexts/firmUp'
import { countUnanswered } from 'FlexiForm/utils'

const IconInButton = styled(Icon)`
  font-size: 12px !important; // Override the Material UI button icon sizing
`

// eslint-disable-next-line no-unused-vars
const FirmUpModalField = ({
  uiSchema,
  schema,
  onChange,
  formData,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const { setUnfirmNum } = useContext(FirmUpContext)
  let { unfirmNum } = useContext(FirmUpContext)

  const context = useFlexiForm()
  useEffect(() => {
    scrollToWithOpts('unanswered')({ behavior: 'smooth', block: 'center' })
  })
  useEffect(() => {
    unfirmNum = countUnanswered(schema.properties, formData)
    setUnfirmNum(unfirmNum)
  }, [formData])

  if (Object.keys(schema.properties).length === 0) {
    return null
  }

  const newUiSchema = {
    ...uiSchema,
  }
  // Remove the ui:field so it's not an infinitely nested modal.
  delete newUiSchema['ui:field']

  const handleChange = formData => {
    onChange(formData)
  }

  // force user to close questions modal via dialog even when clicking outside
  const handleClose = () => {
    handleSubmit(formData)
  }

  const handleSubmit = formData => {
    onChange(formData)
    if (unfirmNum > 0) {
      setDialogOpen(true)
      context.controls.setFlag('submitWarning', true)
    } else {
      context.controls.setFlag('submitWarning', false)
      setOpen(false)
    }
  }

  return (
    <>
      <Subtitle>{schema.title.toUpperCase()}</Subtitle>
      <Box width="auto">
        <Badge color="error" badgeContent={unfirmNum}>
          <Button
            data-testid="open-questions-modal"
            size="large"
            variant="outlined"
            startIcon={<IconInButton icon={faExclamationCircle} fontSize="s" />}
            onClick={() => setOpen(isOpen => !isOpen)}>
            {`${unfirmNum} ${pluralise(unfirmNum, 'Question')}`} to Finalize
          </Button>
        </Badge>
      </Box>
      <Modal keepMounted open={open} onClose={handleClose}>
        <ModalWrapper>
          <Paper>
            <Box p={8} pt={4}>
              <Form
                {...props}
                schema={schema}
                uiSchema={newUiSchema}
                onSubmit={handleSubmit}
                onChange={handleChange}
                submitLabel={`Close ${schema.title}`}
                initialValues={formData}
                omitExtraData={true}
                liveOmit={true}
              />
            </Box>
          </Paper>
          <Dialog open={dialogOpen}>
            <Flex p={40} flexDirection="column">
              <Text>Answers are incomplete.</Text>
              <Flex mt="1rem">
                <StyledButton mr="3rem" onClick={() => setDialogOpen(false)}>
                  Keep Editing
                </StyledButton>
                <StyledButton
                  onClick={() => {
                    setOpen(false)
                    setDialogOpen(false)
                  }}>
                  Close Questions
                </StyledButton>
              </Flex>
            </Flex>
          </Dialog>
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default FirmUpModalField
