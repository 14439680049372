import { connect } from 'react-redux'
import { formValueSelector, defaultShouldValidate } from 'redux-form'
import pick from 'lodash/pick'
import { WorksheetKind, PolicyCoverageKind, LineOfBusiness } from 'graphql/api'
import { isEmptyObject } from 'util/objectUtil'

const selector = formValueSelector('CREATE_WORKSHEET')

const withCoverage = connect(state => ({
  type: selector(state, 'lineOfBusiness'),
  policyKind: selector(state, 'policyType'),
  kind: selector(state, 'kind'),
  policyKinds: selector(state, 'policyLinesOfBusiness'),
}))

export const CasualtyOnly = withCoverage(({ type, children }) =>
  type === LineOfBusiness.Casualty ? children : null
)

export const CasualtyCoveragePresent = withCoverage(
  ({ policyKinds, children }) =>
    policyKinds && policyKinds.includes(LineOfBusiness.Casualty)
      ? children
      : null
)

export const PropertyCoveragePresent = withCoverage(
  ({ policyKinds, children }) =>
    policyKinds && policyKinds.includes(LineOfBusiness.Property)
      ? children
      : null
)

export const PropertyOnly = withCoverage(({ type, children }) => {
  return type === LineOfBusiness.Property ? children : null
})

export const PrimaryOnly = withCoverage(({ policyKind, children }) =>
  policyKind === PolicyCoverageKind.Primary ? children : null
)

export const ExcessUmbrellaOnly = withCoverage(({ policyKind, children }) =>
  policyKind === PolicyCoverageKind.ExcessUmbrella ? children : null
)

export const InsuranceOnly = withCoverage(({ kind, children }) =>
  kind === WorksheetKind.Insurance ? children : null
)

export const ReinsuranceOnly = withCoverage(({ kind, children }) =>
  kind === WorksheetKind.Reinsurance ? children : null
)

export const isDummyReinsurer = user => user.id === user.companyName

const findNegativeValues = values => {
  if (!values) return undefined
  // eslint-disable-next-line no-unused-vars
  return Object.entries(values).find(([key, value]) => {
    return value < 0
  })
}

export const dateToFarInTheFuture = values => {
  const {
    reinsuranceEffectiveDate,
    reinsuranceExpiryDate,
    insurancePolicyAttributes,
  } = pick(values, [
    'reinsuranceEffectiveDate',
    'reinsuranceExpiryDate',
    'insurancePolicyAttributes',
  ])
  const tooFarInTheFuture = new Date('3000-01-01')

  const dates = {
    reinsuranceEffectiveDate,
    reinsuranceExpiryDate,
    effectiveDate: insurancePolicyAttributes?.effectiveDate,
    expiryDate: insurancePolicyAttributes?.expiryDate,
  }

  // eslint-disable-next-line no-unused-vars
  return Object.entries(dates).find(([key, value]) => {
    const date = new Date(value)
    return date >= tooFarInTheFuture
  })
}

export const validateSubmissionForm = values => {
  const errors = {}

  if (isEmptyObject(values)) return errors

  const futureDate = dateToFarInTheFuture(values)
  const negativeCurrentExposures = checkNegativeCurrentExposuresValues(values)
  const negativeRadiusValues = checkNegativeRadiusValues(values)
  const negativeHistoricalExposures = checkNegativeHistoricalExposure(values)
  const negativeLossInformation = checkNegativeLossInformationValues(values)

  const generalErrors = [
    negativeLossInformation &&
      `Loss Information - ${negativeLossInformation[0]} is less than zero`,
    negativeCurrentExposures &&
      `Current Exposures - ${negativeCurrentExposures[0]} is less than zero`,
    negativeRadiusValues &&
      `Radius - ${negativeRadiusValues[0]} is less than zero`,
    negativeHistoricalExposures &&
      `Historical Exposure - ${negativeHistoricalExposures[0]} is less than zero`,
    futureDate && `${futureDate[0]} on this submission set after the year 3000`,
  ].filter(Boolean)

  errors._error = generalErrors.length > 0 && generalErrors

  return errors
}

export const checkNegativeCurrentExposuresValues = values => {
  if (isEmptyObject(values)) return undefined
  const isUnderwritingPresent =
    values?.insurancePolicyAttributes?.coveragesAttributes[0]?.underwriting
  const coverage = values?.insurancePolicyAttributes?.coveragesAttributes[0]

  if (!isUnderwritingPresent || coverage?._destroy) return undefined

  const { insurancePolicyAttributes } = pick(values, [
    'insurancePolicyAttributes',
  ])
  const currentExposures =
    insurancePolicyAttributes?.coveragesAttributes[0]?.underwriting
      ?.currentExposure
  return findNegativeValues(currentExposures)
}

export const checkNegativeRadiusValues = values => {
  if (isEmptyObject(values)) return undefined
  const isUnderwritingPresent =
    values?.insurancePolicyAttributes?.coveragesAttributes[0]?.underwriting
  const coverage = values?.insurancePolicyAttributes?.coveragesAttributes[0]

  if (!isUnderwritingPresent || coverage?._destroy) return undefined

  const { insurancePolicyAttributes } = pick(values, [
    'insurancePolicyAttributes',
  ])
  const radiusEntries = {}
  const underwritingObject =
    insurancePolicyAttributes?.coveragesAttributes[0]?.underwriting
  radiusEntries['local'] = underwritingObject.radiusLocal
  radiusEntries['intermediate'] = underwritingObject.radiusIntermediate
  radiusEntries['longHaul'] = underwritingObject.radiusLongHaul
  return findNegativeValues(radiusEntries)
}

export const checkNegativeHistoricalExposure = values => {
  if (isEmptyObject(values)) return undefined
  const isUnderwritingPresent =
    values?.insurancePolicyAttributes?.coveragesAttributes[0]?.underwriting
  const coverage = values?.insurancePolicyAttributes?.coveragesAttributes[0]

  if (!isUnderwritingPresent || coverage?._destroy) return undefined

  const { insurancePolicyAttributes } = pick(values, [
    'insurancePolicyAttributes',
  ])
  const historicalExposures =
    insurancePolicyAttributes?.coveragesAttributes[0]?.underwriting
      ?.historicalExposure
  if (!historicalExposures) return undefined
  const arr = []
  historicalExposures.forEach(value => {
    Object.entries(value).find(([key, fieldValue]) => {
      if (fieldValue < 0) {
        arr.push(key)
      }
    })
  })
  if (arr.length === 0) {
    return undefined
  }
  return arr
}

export const checkNegativeLossInformationValues = values => {
  if (isEmptyObject(values)) return undefined
  const isUnderwritingPresent =
    values?.insurancePolicyAttributes?.coveragesAttributes[0]?.underwriting
  const coverage = values?.insurancePolicyAttributes?.coveragesAttributes[0]

  if (!isUnderwritingPresent || coverage?._destroy) return undefined

  const { insurancePolicyAttributes } = pick(values, [
    'insurancePolicyAttributes',
  ])
  const lossInformation =
    insurancePolicyAttributes?.coveragesAttributes[0]?.underwriting
      ?.lossInformation
  if (!lossInformation) return undefined
  const arr = []
  lossInformation.forEach(value => {
    Object.entries(value).find(([key, fieldValue]) => {
      if (fieldValue < 0) {
        arr.push(key)
      }
    })
  })
  if (arr.length === 0) {
    return undefined
  }
  return arr
}

export const shouldValidateForm = ({
  values,
  initialRender,
  nextProps,
  lastFieldValidatorKeys,
  fieldValidatorKeys,
  structure,
}) => {
  if (isEmptyObject(values)) return false
  const futureDate = dateToFarInTheFuture(values)
  const negativeCurrentExposures = checkNegativeCurrentExposuresValues(values)
  const negativeHistoricalExposures = checkNegativeHistoricalExposure(values)
  const negativeRadius = checkNegativeRadiusValues(values)
  const negativeLossInfo = checkNegativeLossInformationValues(values)
  return (
    !!futureDate ||
    !!negativeCurrentExposures ||
    !!negativeHistoricalExposures ||
    !!negativeRadius ||
    !!negativeLossInfo ||
    defaultShouldValidate({
      values,
      initialRender,
      lastFieldValidatorKeys,
      fieldValidatorKeys,
      nextProps,
      structure,
    })
  )
}

export const perOccurrenceLimitLabelName = (labelName, companyTranslator) => {
  switch (labelName) {
    case 'General Liability':
      return companyTranslator('submission', 'perOccurrence.generalLiability')
    case 'Commercial Auto':
      return companyTranslator('submission', 'perOccurrence.commercialAuto')
    case 'Excess/Umbrella':
      return companyTranslator('submission', 'perOccurrence.excessUmbrella')
    default:
      return 'Per Occurrence Limit'
  }
}
