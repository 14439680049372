import React from 'react'
import { Text, $ } from 'atoms'
import { usePanelQuery } from 'graphql/api'
import { pluralise } from 'util/string'

const calculateTotalInsurableValue = locations =>
  locations
    .map(
      location =>
        parseFloat(location.property_damage_limit) +
        parseFloat(location.business_interruption_limit) +
        parseFloat(location.contents_stock || 0)
    )
    .reduce((a, b) => a + b, 0)

const SummaryTitle = ({ children }) => (
  <Text fontSize="14px" fontWeight="600">
    {children}
  </Text>
)

const SummaryValue = ({ children }) => (
  <Text fontSize="14px" fontWeight="400" paddingLeft="5px">
    {children}
  </Text>
)

const LimitSummaryItem = ({ product, title, data }) => {
  const hasValue = data?.limit && parseInt(data?.limit)
  const fallback =
    title === 'Limit' && product === 'terrorism' && data?.locations?.length > 0
      ? 'See TIV'
      : 'N/A'
  return (
    <SummaryTitle>
      {title}:
      <SummaryValue>{hasValue ? <$>{data?.limit}</$> : fallback}</SummaryValue>
    </SummaryTitle>
  )
}

const SummaryItem = ({ title, value }) => {
  const hasValue = value && parseInt(value)

  return (
    <SummaryTitle>
      {title}:<SummaryValue>{hasValue ? <$>{value}</$> : 'N/A'}</SummaryValue>
    </SummaryTitle>
  )
}

const TotalInsurableValue = ({ locations }) => {
  const title =
    locations.length > 0
      ? `TIV for ${locations.length} ${pluralise(locations.length, 'location')}`
      : 'TIV'
  return (
    <SummaryItem
      title={title}
      value={calculateTotalInsurableValue(locations ?? [])}
    />
  )
}

const getCyberPanelistNames = (selectedPanelists, panelId) => {
  if (!selectedPanelists || !panelId) {
    return []
  }

  const { data, loading, error } = usePanelQuery({
    variables: { panelId },
  })

  if (error) {
    throw error
  }

  if (loading) {
    return []
  }

  const panelists = data.viewer.user.company.panel.panelists.nodes
  return selectedPanelists.map(
    panelistId => panelists.find(p => p.id === panelistId).firstName
  )
}

const carriers = (product, data, panelId) => {
  switch (product) {
    case 'terrorism': {
      return data.carriers
    }
    case 'cyber': {
      return getCyberPanelistNames(data.selected_panelists, panelId)
    }
    default: {
      return []
    }
  }
}

const CarrierList = ({ carriers }) => {
  return carriers && carriers.length > 0 ? (
    <SummaryTitle>
      {`${pluralise(carriers.length, 'Carrier')}: `}
      <SummaryValue>{carriers.join(', ')}</SummaryValue>
    </SummaryTitle>
  ) : null
}

const GetMultiPageReviewFields = ({ product, data, panelId }) => {
  switch (product) {
    case 'terrorism':
      return (
        <>
          <LimitSummaryItem product={product} title="Limit" data={data} />
          <SummaryItem title="Deductible" value={data?.deductible} />
          {data?.locations && (
            <TotalInsurableValue
              locations={data.locations?.filter(
                location => !!location.street_number_and_name
              )}
            />
          )}
          <CarrierList carriers={carriers(product, data, panelId)} />
        </>
      )
    case 'business_owner':
      return <></>
    default:
      return (
        <>
          <LimitSummaryItem product={product} title="Limit" data={data} />
          <SummaryItem title="Deductible" value={data?.deductible} />
          <CarrierList carriers={carriers(product, data, panelId)} />
        </>
      )
  }
}

export default GetMultiPageReviewFields
