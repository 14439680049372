const broker = {
  messageReceived: (discussable, message) => {
    if (discussable.__typename === 'Participation') {
      return `/panels/${discussable.submission.panel.id}/submissions/${discussable.submission.id}/participations/${discussable.id}`
    } else {
      return `/quotes/${discussable.id}?msgId=${message.id}`
    }
  },
}
export default { broker }
