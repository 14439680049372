import styled from 'styled-components'
import {
  typography,
  space,
  color,
  flexbox,
  layout,
  border,
  shadow,
  position,
  background,
} from 'styled-system'

const Flex = styled.div`
  display: flex;
  width: 100%;
  ${typography} ${space} ${color} ${layout} ${flexbox} ${border} ${shadow} ${position} ${background}
`
const FlexAutoWidth = styled.div`
  display: flex;
  ${typography} ${space} ${color} ${layout} ${flexbox} ${border} ${shadow} ${position} ${background}
`
Flex.displayName = 'Flex'
FlexAutoWidth.displayName = 'FlexAutoWidth'

const Column = styled(Flex)``
const Row = styled(FlexAutoWidth)``

Column.defaultProps = {
  flexDirection: 'column',
}

Row.defaultProps = {
  flexDirection: 'row',
}
Column.displayName = 'Column'
Row.displayName = 'Row'

export { Column, FlexAutoWidth, Row }
export default Flex
