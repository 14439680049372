import React, { useState, useEffect } from 'react'

import { Flex, Error, Label } from 'atoms'
import { Input } from 'atoms/form'
import { currencyFormatter, currencyNormalizer } from 'util/fieldNormalizers'
import currencies from 'data/currencies'
import { useSubmission } from 'hooks'
import InputWidget from 'components/forms/widgets/InputWidget'

const PARTIAL_CENTS = precision => new RegExp(`\\.[0-9]{0,${precision - 1}}0?$`)
const N_DECIMAL_PLACES = n => new RegExp(`\\.[0-9]{${n},}$`)

const MoneyInput = ({
  input,
  meta,
  label,
  required,
  note,
  disabled,
  bg,
  onKeyUp = () => {},
  autoFocus,
  id = '',
  name = '',
  allowText = false,
  ...props
}) => {
  if (allowText) return InputWidget(input, meta, props)

  const submission = useSubmission()
  const currency = currencies[submission?.currency || 'USD']
  const [currentValue, setValue] = useState(
    currencyFormatter(input.value, currency)
  )
  const [error, setError] = useState(meta.error || meta.submitError)

  useEffect(() => {
    if (input.value !== currencyNormalizer(currentValue)) {
      setValue(currencyFormatter(input.value, currency))
    }
  }, [input.value, currentValue])

  useEffect(() => {
    if (meta.error || meta.submitError) {
      setError(meta.error || meta.submitError)
    } else {
      setError('')
    }
  }, [meta.error, meta.submitError])

  const handleChange = event => {
    if (N_DECIMAL_PLACES(currency.precision + 1).test(event.target.value))
      return undefined
    const normalized = currencyNormalizer(event.target.value)
    if (normalized.length < 18) {
      input.onChange(normalized)
      if (normalized.length > 14) {
        // Show a soft error to the user warning them that the number is most
        // likely incorrect due to it's magnitude.
        setError('The value is too large')
      } else {
        setError('')
      }
      if (PARTIAL_CENTS(currency.precision).test(event.target.value)) {
        setValue(event.target.value)
      } else {
        setValue(currencyFormatter(event.target.value, currency))
      }
    } else {
      // If the number is 20 digits or more we just stop accepting input. The
      // backend will store 20 digit value without error so autosave will
      // continue working.
      setError('The value is too large')
    }
  }

  const handleBlur = () => {
    setValue(currencyFormatter(currentValue, currency))
    if (input.onBlur) {
      return input.onBlur(currencyNormalizer(currentValue, currency))
    }
  }

  return (
    <Flex flexDirection="column" {...props} bg={bg}>
      {label && (
        <Label htmlFor={input.name} required={required} note={note}>
          {label}
        </Label>
      )}
      <Input
        autoFocus={autoFocus}
        onChange={handleChange}
        onFocus={input.onFocus}
        onBlur={handleBlur}
        onKeyUp={onKeyUp}
        touched={meta.touched}
        error={error}
        value={currentValue}
        disabled={disabled}
        type="text"
        placeholder={currency.symbol}
        bg={bg}
        id={id}
        name={name}
      />
      {meta.touched && error && <Error>{error}</Error>}
    </Flex>
  )
}

export default MoneyInput
