const reinsurer = {
  messageReceived: (discussable, message) => {
    if (discussable.__typename === 'Participation') {
      return `/quotes/panels/${discussable.id}`
    } else {
      return `/quotes/${discussable.id}?msgId=${message.id}`
    }
  },
}

const broker = {
  messageReceived: (discussable, message) => {
    if (discussable.__typename === 'Participation') {
      const quoteId = discussable.quotations.nodes[0]?.id
      if (quoteId) {
        return `/panels/${discussable.submission.panel.id}/submissions/${discussable.submission.id}/quotes/${quoteId}?msgId=${message.id}`
      } else {
        return `/panels/${discussable.submission.panel.id}/submissions/${discussable.submission.id}/participations/${discussable.id}?msgId=${message.id}`
      }
    } else {
      return `/quotes/${discussable.id}?msgId=${message.id}`
    }
  },
}
export default { reinsurer, broker }
