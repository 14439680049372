import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import ExitSvg from '../../images/exit-icon.svg'

const ExitIcon = styled.img`
  position: sticky;
  top: 0;
  left: 100%;
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
`

const Exit = ({ history, onClick }) => (
  <ExitIcon
    src={ExitSvg}
    onClick={() => {
      onClick ? onClick() : history.goBack()
    }}
  />
)

Exit.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Exit)
