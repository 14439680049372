import {
  PremiumRenderer,
  AdmittedRenderer,
  LimitDeductibleAndPremiumRenderer,
  TwoColumnTitleRenderer,
  MultiTitleRenderer,
  TextModalRenderer,
  LinkRenderer,
} from 'molecules/PanelQuoteComparator/renderers'
import { QUOTE_STATUSES } from 'panels/constants'
import { NON_BINDABLE_STATES } from '../utils'

const hasStatus = status => entries =>
  entries.some(entry => status === entry.status)

const criteria = ({ country, filteredQuotations = [] }) => {
  const { generalLiability, property } = filteredQuotations.reduce(
    (prev, current) => {
      const mappedLiability = {}
      const generalLiability = current.generalLiability
      generalLiability &&
        Object.entries(generalLiability).forEach(([key, value]) => {
          mappedLiability[key] = {
            label: value.coverage,
            key: `generalLiability.${key}`,
            renderer: LimitDeductibleAndPremiumRenderer,
            columns: ['limit', 'premium'],
          }
        })

      const mappedProperty = {}
      const property = current.property
      property &&
        Object.entries(property).forEach(([key, value]) => {
          mappedProperty[key] = {
            label: value.coverage,
            key: `property.${key}`,
            renderer: LimitDeductibleAndPremiumRenderer,
            columns: ['limit', 'deductible', 'premium'],
          }
        })

      return {
        generalLiability: { ...prev.generalLiability, ...mappedLiability },
        property: { ...prev.property, ...mappedProperty },
      }
    },
    { generalLiability: {}, property: {} }
  )

  const generalLiabilityValues = Object.values(generalLiability)
  const propertyValues = Object.values(property)

  return {
    context: {
      bindable: entries =>
        !entries.some(entry => NON_BINDABLE_STATES.includes(entry.status)),
      binding: hasStatus(QUOTE_STATUSES.binding),
      bound: hasStatus(QUOTE_STATUSES.bound),
      issued: hasStatus(QUOTE_STATUSES.issued),
      referred: hasStatus(QUOTE_STATUSES.referred),
      extracted: hasStatus(QUOTE_STATUSES.extracted),
      statusMessage: entries => entries.some(entry => entry.statusMessage),
      nonAPICarriers: entries =>
        entries.some(entry => !entry.isApi && !entry.externalCarrier),
    },
    sections: [
      {
        criteria: [
          {
            key: 'admitted',
            label: 'Admitted / Non-Admitted',
            renderer: AdmittedRenderer,
            showIf: () => country == 'US',
          },
          {
            key: 'generalLiabilityPremium',
            label: 'General Liability Premium',
            renderer: PremiumRenderer,
          },
          {
            key: 'overallPropertyPremium',
            label: 'Overall Property Premium',
            renderer: PremiumRenderer,
          },
          {
            key: 'disclaimer',
            label: 'Carrier Disclaimer',
            title: 'Carrier Legal Disclaimers',
            button: 'Legal Disclaimers',
            renderer: TextModalRenderer,
          },
          {
            key: 'portalUrl',
            label: 'Carrier URL',
            title: 'Portal',
            renderer: LinkRenderer,
          },
        ],
      },
      {
        title: 'General Liability',
        criteria: [
          {
            renderer: TwoColumnTitleRenderer,
            titles: ['Limit', 'Premium'],
          },
          ...generalLiabilityValues,
        ],
      },
      {
        title: 'Property',
        criteria: [
          {
            renderer: MultiTitleRenderer,
            titles: ['Limit', 'Deductible', 'Premium'],
          },
          ...propertyValues,
        ],
      },
    ],
  }
}

export default criteria
