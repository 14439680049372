import moment from 'moment-timezone'

const parseValue = val => {
  if (!val) return val
  const parsed = moment(val, 'M/D/YYYY', true)
  return parsed.isValid() ? parsed.toISOString() : val
}

const isValidDate = val => {
  if (!val) return undefined
  const parsed = moment(val, moment.ISO_8601, true)
  return parsed.isValid()
    ? undefined
    : 'The date must be in the format mm/dd/yyyy'
}

const f1DateFormat = 'MMM DD, yyyy'

const displayDate = date => {
  if (!date) return date
  let formatted
  if (navigator.language) {
    formatted = moment(date, moment.ISO_8601, true)
      .toDate()
      .toLocaleDateString(navigator.language, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
  } else {
    formatted = moment(date, moment.ISO_8601, true).format('D MMM YYYY')
  }
  return /invalid date/i.test(formatted) ? date : formatted
}

const formatDate = (
  dateStr,
  outFormatStr,
  inFormatStr = '',
  strict = false
) => {
  if (!dateStr) return dateStr
  if (strict && !inFormatStr) return dateStr

  if (!strict) {
    if (moment(dateStr, moment.ISO_8601).isValid()) {
      return moment(dateStr).format(outFormatStr)
    }
  }
  const m = moment(dateStr, inFormatStr, true)
  return m.isValid() ? m.format(outFormatStr) : dateStr
}

const pastDate = dateStr => {
  return moment().isAfter(dateStr, 'day')
}

export {
  parseValue,
  displayDate,
  isValidDate,
  formatDate,
  pastDate,
  f1DateFormat,
}
