const emptyArraySection = schema => {
  return (
    schema.type === 'array' &&
    Object.prototype.hasOwnProperty.call(schema.items, 'properties') &&
    Object.keys(schema.items.properties).length === 0
  )
}

const emptySection = schema =>
  schema.type === 'object' && Object.keys(schema.properties || {}).length === 0

export { emptyArraySection, emptySection }
