import React from 'react'
import styled from 'styled-components'
import {
  typography,
  space,
  color,
  flexbox,
  layout,
  border,
  background,
  position,
  shadow,
} from 'styled-system'

const cursor = ({ cursor = null }) => (cursor ? `cursor: ${cursor}` : '')

const Div = styled.div(
  typography,
  space,
  color,
  layout,
  flexbox,
  border,
  background,
  position,
  shadow,
  cursor
)
Div.displayName = 'Div'

//!important used to override quill.snow.css styles
const RTContainer = styled(Div)`
  margin: 0px;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  resize: none !important;
  background-color: transparent !important;
  & > * {
    background-color: transparent !important;
  }
  & > p {
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
`

const RichTextDiv = props => <RTContainer className="ql-editor" {...props} />

export { RichTextDiv }
export default Div
