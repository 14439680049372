import React from 'react'
import styled from 'styled-components'
import {
  space,
  color,
  typography,
  layout,
  border,
  shadow,
  position,
  background,
} from 'styled-system'

const TableContainer = styled.table`
  border-collapse: ${props => (props.compact ? 'collapse' : 'separate')};
  border-spacing: ${props => props.spacing};
  table-layout: ${props => props.tableLayout || 'fixed'};
  ${space}
  ${layout}
  ${color}
  ${border}
  ${shadow}
`
TableContainer.defaultProps = {
  width: '100%',
  spacing: '0px 0.5rem',
}

const TR = styled.tr(border)
const TD = styled.td(
  space,
  color,
  typography,
  layout,
  position,
  border,
  background
)
TD.defaultProps = {
  font: 'primary',
  color: 'primaryText',
  p: 1,
  fontSize: 'm',
}
const TH = styled.th(
  space,
  color,
  typography,
  layout,
  position,
  border,
  background
)
TH.defaultProps = {
  font: 'primary',
  color: 'primaryText',
  p: 1,
  fontSize: 'm',
  fontWeight: 2,
}

const Table = ({ header, children, ...rest }) => {
  return (
    <TableContainer {...rest}>
      <thead>{header}</thead>
      <tbody>{children}</tbody>
    </TableContainer>
  )
}

export default Table
export { TR, TD, TH }
