import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Row, Column, Text, $, Img } from 'atoms'

const BindContent = ({
  submission,
  quotation,
  coverageStartDate,
  handleDateChange,
}) => {
  const quotedLine = quotation.quotedLines?.[0]
  return (
    <Row>
      <Column flex="1">
        <Row mb={2}>
          <Column>
            <Text fontSize="s">Named Insured</Text>
            <Text fontWeight={2} fontSize="m">
              {submission.name}
            </Text>
          </Column>
        </Row>
        <Row justifyContent="space-between" mb={1}>
          <Column>
            <Text fontSize="s">Limit</Text>
            <Text fontWeight={2} fontSize="m">
              <$>{quotation.requestedLimit}</$>
            </Text>
          </Column>
          <Column>
            <Text fontSize="s">Deductible</Text>
            <Text fontWeight={2} fontSize="m">
              <$>{quotation.requestedDeductible}</$>
            </Text>
          </Column>
          <Column>
            <Text fontSize="s">Cost for Insured</Text>
            <Text fontWeight={2} fontSize="m">
              <$>{quotedLine?.clientCost}</$>
            </Text>
          </Column>
        </Row>
        <Row>
          <Column minWidth="25%" width="auto" mb={1} mr={1}>
            <Text fontSize="s">Policy Effective Date</Text>
            <TextField
              id="date"
              type="date"
              defaultValue={coverageStartDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Column>
        </Row>
      </Column>
      <Column flex="0" alignItems="center" mx={0}>
        {quotation.logoUrl && (
          <Img
            data-testid="logo"
            width="150px"
            pt={0}
            src={quotation.logoUrl}
            title={`${submission.name} Logo`}
          />
        )}
      </Column>
    </Row>
  )
}

export default BindContent
