import React, { useMemo } from 'react'
import { Button, Icon } from 'atoms'
import { Field } from 'react-final-form'
import { toast } from 'react-toastify'
import { faClipboard } from '@fortawesome/pro-regular-svg-icons'

import { TextArea } from 'atoms/form'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'

const ExportQuestionForm = ({ scope = QUESTION, questions }) => {
  const translator = qandaTranslator(scope)
  const textAreaRef = React.useRef(null)

  const questionText = useMemo(() => {
    const prefix = scope === QUESTION ? 'Q' : 'S'
    return questions
      .reduce((flattenedString, curSection) => {
        let sectionQuestions = `${(curSection[0] || '').replace(
          /^\s+|\s+$/g,
          ''
        )}\n\n`

        const flattenQuestion = question => {
          let flattenedQuestions = `${prefix}${question.number || ''}: ${(
            question.text || ''
          ).replace(/^\s+|\s+$/g, '')}\n`

          if (question.answer) {
            let flattenedAnswer = `A${question.number || ''}: ${(
              question.answer.text || ''
            ).replace(/^\s+|\s+$/g, '')}\n`
            flattenedQuestions = `${flattenedQuestions}${flattenedAnswer}`
          }

          let followUps = question.followUps || []
          followUps.forEach(followUp => {
            flattenedQuestions = `${flattenedQuestions}${prefix}${
              followUp.number || ''
            }: ${(followUp.text || '').replace(/^\s+|\s+$/g, '')}\n`

            if (followUp.answer) {
              let flattenedAnswer = `A${followUp.number || ''}: ${(
                followUp.answer.text || ''
              ).replace(/^\s+|\s+$/g, '')}\n`
              flattenedQuestions = `${flattenedQuestions}${flattenedAnswer}`
            }
          })
          return flattenedQuestions
        }

        const questions = Array.isArray(curSection[1]) ? curSection[1] : []
        questions.forEach(question => {
          sectionQuestions = `${sectionQuestions}${flattenQuestion(question)}`
        })

        sectionQuestions = sectionQuestions.replace(/^\s+|\s+$/g, '')
        return `${flattenedString}${sectionQuestions}\n\n`
      }, '')
      .replace(/^\s+|\s+$/g, '')
  }, [questions])

  const copyToClipboard = e => {
    textAreaRef.current.select()
    document.execCommand('copy')
    e.target.focus()
    toast.success(`${translator('copied')}`)
  }

  return (
    <>
      <Field
        ref={textAreaRef}
        textareaStyles={{ height: '33vh' }}
        component={TextArea}
        name="attributes.text"
        required
        initialValue={questionText}
        mb={2}
      />
      <Button ml="auto" onClick={copyToClipboard} primary>
        <Icon mr={0} icon={faClipboard} />
        {translator('copyQuestions')}
      </Button>
    </>
  )
}

export default ExportQuestionForm
