import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useBranding } from 'hooks'
import { Div } from 'atoms'

const StyledLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 10px 10px 5px 10px;
  text-decoration: none;
  color: ${({ theme, isWhitelabel }) =>
    isWhitelabel ? theme.colors.whitelabelText : theme.colors.white};
  transition: all 0.05s ease-in-out;

  &.active {
    border-bottom: 2px solid
      ${({ theme, isWhitelabel }) =>
        isWhitelabel ? theme.colors.whitelabelText : theme.colors.background};
  }

  &:hover {
    transform: scale(1.2);
    border: none;
  }
`

export const LinkBorder = styled(Div)`
  height: 100%;
  margin: 0 15px;
  bottom-border: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid
      ${({ theme, isWhitelabel }) =>
        isWhitelabel ? theme.colors.whitelabelText : theme.colors.background};
  }
`

const StyledNavLink = props => {
  const { isWhitelabel } = useBranding()
  return (
    <LinkBorder isWhitelabel={isWhitelabel}>
      <StyledLink {...props} isWhitelabel={isWhitelabel} />
    </LinkBorder>
  )
}

export default StyledNavLink
