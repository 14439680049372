import { useContext } from 'react'
import { PolicyCoverageKind } from 'graphql/api'

import SubmissionContext from 'components/contexts/submission'

const useSubmission = () => {
  return useContext(SubmissionContext)
}

const useWorksheetKind = () => {
  const value = useContext(SubmissionContext)
  return value && value.kind
}

const useLineOfBusiness = () => {
  const value = useContext(SubmissionContext)
  return value && value.lineOfBusiness
}

const usePolicyType = () => {
  const value = useContext(SubmissionContext)
  return value && value.policyType
}
const usePolicyCoverages = () => {
  const value = useContext(SubmissionContext)
  const isExcess =
    value && value.policyType === PolicyCoverageKind.ExcessUmbrella
  return (
    value &&
    (isExcess
      ? value.insurancePolicyAttributes.underlyingPoliciesAttributes[0]
          .coveragesAttributes
      : value.insurancePolicyAttributes.coveragesAttributes)
  )
}

export default useSubmission
export {
  useWorksheetKind,
  useLineOfBusiness,
  usePolicyType,
  usePolicyCoverages,
}
