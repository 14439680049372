import merge from 'lodash/merge'
import { Children, cloneElement } from 'react'

const addMissingProps = (child, otherProps) => {
  const props = merge({}, child.props, { ...otherProps })
  return cloneElement(child, { ...props })
}
const addPropsToChildElements = ({ children, props }) => {
  const elements = Children.toArray(children)

  if (elements.length === 0) return children
  if (elements.length === 1) return addMissingProps(elements[0], props)

  return elements.map(element => addMissingProps(element, props))
}

export { addPropsToChildElements }
