import React from 'react'

import { SIC } from 'util/industryCodes'
import { Column, Row, SectionTitle } from 'atoms'
import Field from 'molecules/Field'
import { money } from 'components/$'
import { AddressColumn } from 'pages/shared/panels'
import DocumentsTable from 'molecules/DocumentUpload/DocumentsTable'

const CyberApplicant = ({ submission }) => {
  return (
    <>
      <SectionTitle mt={1}>Applicant</SectionTitle>
      <Row>
        <Column flex={2}>
          <Row>
            <Field label="Industry" value={SIC[submission.naicsCode]} />
            <Field
              label="Website(s)"
              value={submission.websites?.join(', ') || 'None Provided'}
            />
          </Row>
          <Row mt={2}>
            <Field
              label="Number of Employees"
              value={submission.numberOfEmployees}
            />
            <Field
              label="Number of Records"
              value={submission.numberOfRecords}
            />
          </Row>
          <Row mt={2}>
            <Field
              label="Annual Revenue"
              value={money(submission.annualRevenue)}
            />
            {submission.totalAssets && (
              <Field
                label="Total Assets"
                value={money(submission.totalAssets)}
              />
            )}
          </Row>
        </Column>
        <AddressColumn address={submission} />
      </Row>
      <SectionTitle mt={2}>Application Documents</SectionTitle>
      <DocumentsTable documents={submission.documents} />
    </>
  )
}

export default CyberApplicant
