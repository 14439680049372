import React, { useEffect } from 'react'
import styled from 'styled-components'
import { faRedo } from '@fortawesome/pro-solid-svg-icons'

import { Button, Text, Flex } from 'atoms'
import Icon from 'atoms/Icon'
import { colors } from 'components/theme'
import PointerShape from 'components/common/PointerShape'

const StyledButton = styled(Button)`
  border: none;
  justify-content: flex-start;
  font-weight: normal;
  width: auto;
  font-size: 18px;
  & > span {
    margin-left: 10px;
    color: ${colors.link};
  }
`

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 15px;
  background-color: #fff;
  height: 50px;
  margin: 0px auto;
  max-width: 1320px;
  border-radius: 5px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 99;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const Container = styled.div`
  padding-top: 20px;
  width: 100%;
  background: ${props => props.theme.colors.header};
  @media (max-width: 1320px) {
    padding: 1rem 1rem 0;
  }
`

export const refreshPage = () => {
  window.location.reload()
}

const SoftwareUpdateVersionNotification = () => {
  const [isNotificationVisible, setIsNotificationVisible] =
    React.useState(false)

  useEffect(() => {
    let currentVersion
    const getVersion = () => fetch('/versions.json', { cache: 'no-cache' })

    getVersion()
      .then(res => res.json())
      .then(data => (currentVersion = data))
      .catch(() => {
        console.warn(
          'Unable to check software version - failed to fetch /versions.json'
        )
      })

    // fetch versions.json periodically every 5 minutes and compare the latest deployed version with the current version
    const interval = setInterval(() => {
      getVersion()
        .then(version => version.json())
        .then(newVersion => {
          if (newVersion != currentVersion) {
            setIsNotificationVisible(true)
          }
        })
        .catch(err => {
          console.warn('Failed to check software version.', err)
        })
    }, 5 * 60 * 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    isNotificationVisible && (
      <Container>
        <NotificationContainer>
          <Flex justifyContent="space-between">
            <PointerShape>SOFTWARE UPDATE</PointerShape>
            <TextContainer>
              <Text>
                There has been a software update,{' '}
                <strong>and the page must be reloaded</strong>. Please save your
                changes and refresh the page.
              </Text>
            </TextContainer>
            <StyledButton light onClick={refreshPage}>
              <Icon icon={faRedo} color={colors.link} />
              <span>Refresh Page</span>
            </StyledButton>
          </Flex>
        </NotificationContainer>
      </Container>
    )
  )
}

export default SoftwareUpdateVersionNotification
