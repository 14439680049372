import { renderHomeCoverage, renderHomeQuotedLine } from './pcg/home'
import {
  renderValuablesCoverage,
  renderValuablesQuotedLine,
} from './pcg/valuables'
import { renderExcessCoverage, renderExcessQuotedLine } from './pcg/excess'
import { renderAutoCoverage, renderAutoQuotedLine } from './pcg/auto'
import {
  MoneyRenderer,
  StatusRenderer,
  UnderwriterRenderer,
  BindButtonRenderer,
  DocumentRenderer,
  ConfirmBindingButtonRenderer,
} from '../../renderers'
import { QUOTE_STATUSES } from 'panels/constants'
import { NON_BINDABLE_STATES } from '../utils'

const quoteStatus = (quotation, status) => {
  return quotation.status.toLowerCase() === status
}
// The sorting of these quoted lines unfortunately isn't alphabetical or a specific order that can easily be sorted, hence the custom solution.
const sortQuotedLinesOrder = [
  'PCGHomeQuotedLineType',
  'PCGValuablesQuotedLineType',
  'PCGAutoQuotedLineType',
  'PCGExcessQuotedLineType',
]
const sortCoveragesOrder = [
  'PCG::HomeCoverage',
  'PCG::ValuablesCoverage',
  'PCG::AutoCoverage',
  'PCG::ExcessLiabilityCoverage',
]
const sortedQuotedLines = (items, lineOrder) => {
  let lines = []
  lineOrder.map(lineType => {
    items.map(ql => {
      if (lineType === ql[0].__typename) {
        lines.push(ql)
      }
    })
  })
  return lines
}

const sortCoverages = (coverages, coveragesOrder) => {
  const result = coveragesOrder.flatMap(type => {
    return coverages.filter(cov => cov.type === type)
  })

  return result
}
const renderQuotedLines = (quotations, maxIdx) => {
  let lines = []
  quotations[maxIdx].quotedLines?.map((line, idx) => {
    switch (line.__typename) {
      case 'PCGHomeQuotedLineType':
        lines.push(renderHomeQuotedLine(line, idx))
        break
      case 'PCGValuablesQuotedLineType':
        lines.push(renderValuablesQuotedLine(line, idx))
        break
      case 'PCGAutoQuotedLineType':
        lines.push(renderAutoQuotedLine(line, idx))
        break
      case 'PCGExcessQuotedLineType':
        lines.push(renderExcessQuotedLine(line, idx))
        break
      default:
        null
    }
  })
  return lines
}
const renderCoverages = quotations => {
  const sortedCoverages = sortCoverages(
    quotations[0].coverages,
    sortCoveragesOrder
  )

  const result = []
  sortedCoverages.map(coverage => {
    switch (coverage.type) {
      case 'PCG::HomeCoverage':
        result.push(renderHomeCoverage(coverage))
        break
      case 'PCG::ValuablesCoverage':
        result.push(renderValuablesCoverage())
        break
      case 'PCG::AutoCoverage':
        result.push(...renderAutoCoverage())
        break
      case 'PCG::ExcessLiabilityCoverage':
        result.push(renderExcessCoverage())
        break
      default:
        null
    }
  })
  return result
}

const hasStatus = status => entries =>
  entries.some(entry => status === entry.status)

const criteria = () => {
  return {
    context: {
      bindable: entries =>
        !entries.some(entry => NON_BINDABLE_STATES.includes(entry.status)),
      binding: hasStatus(QUOTE_STATUSES.binding),
      bound: hasStatus(QUOTE_STATUSES.bound),
      issued: hasStatus(QUOTE_STATUSES.issued),
      outdated: hasStatus(QUOTE_STATUSES.outdated),
    },
    sections: [
      quotations => {
        let quotationLines = []
        let coverages = []
        /* The following line and using quotations[maxIdx] on line :21 is to get the
         length of the quotation with the most quoted lines, then mapping over those.
         That avoids issues of having one quotation with 1 quoted line and 1 with 7, and
         then having 8 sections in the comparator. The comparator should only ever have
         an amount of sections that equals the most quoted lines for a quotation.
         */
        let maxIdx = quotations?.reduce(
          (maxIdx, quote, idx, arr) =>
            quote.quotedLines?.length > arr[maxIdx].quotedLines?.length
              ? idx
              : maxIdx,
          0
        )
        if (quotations.length) {
          quotationLines = renderQuotedLines(quotations, maxIdx)
          coverages = !quotationLines.length ? renderCoverages(quotations) : []
        }

        return {
          criteria: [
            {
              key: 'totalPremium',
              label: 'Total Premium',
              renderer: MoneyRenderer,
            },
            ...sortedQuotedLines(quotationLines, sortQuotedLinesOrder),
            ...coverages,
            {
              key: 'documents',
              label: 'Documents',
              renderer: DocumentRenderer,
            },
            {
              renderer: UnderwriterRenderer,
              label: 'Message Underwriter',
              path: ({ panelId, submissionId }, quotation) =>
                `/panels/${panelId}/submissions/${submissionId}/participations/${quotation.participationId}`,
            },
            {
              renderer: BindButtonRenderer,
              label: 'Bind Quote',
              showIf: ({ context }) => context.bindable,
              show: quotation => quotation.id && quotation.isCarrier,
              to: quote => `${quote.submissionId}/quotes/${quote.id}/bind`,
            },
            {
              renderer: ConfirmBindingButtonRenderer,
              label: 'Bind Quote',
              showIf: ({ context }) => context.binding,
              quoteActions: ['bind', 'decline'],
              show: quotation =>
                quotation.id &&
                quotation.isCarrier &&
                quoteStatus(quotation, QUOTE_STATUSES.binding),
              to: quote => `${quote.submissionId}/quotes/${quote.id}`,
            },
            {
              renderer: StatusRenderer,
              showIf: ({ context }) =>
                context.bound || context.issued || context.outdated,
            },
          ],
        }
      },
    ],
  }
}

export default criteria
