import React from 'react'
import { Label, ButtonContainer, Button, Div, Text } from 'atoms'
import { Field } from 'react-final-form'
import { TextArea } from 'atoms/form'
import { required } from 'util/validators'
import { SimpleUploader } from 'molecules/DocumentUpload'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'

const AnswerForm = ({
  scope = QUESTION,
  onSubmit,
  question,
  onClose,
  documentCategory,
}) => {
  const translator = qandaTranslator(scope)
  return (
    <>
      <Div mb={2}>
        <Label>{translator('Question')}</Label>
        <Text>
          {translator('Q')}
          {question.number}: {question.text}
        </Text>
      </Div>
      <Field
        component={TextArea}
        name="attributes.text"
        label={translator('Answer')}
        required
        validate={required()}
        mb={2}
      />
      <SimpleUploader
        label="Documents"
        name="attributes.documents"
        defaultCategory={documentCategory}
      />
      <ButtonContainer justifyContent="space-between">
        <Button onClick={onClose} light>
          Cancel
        </Button>
        <Button onClick={onSubmit} primary>
          Send {translator('Answer')}
        </Button>
      </ButtonContainer>
    </>
  )
}

export default AnswerForm
