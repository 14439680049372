export const ignoreList = {
  formData: {
    entry: [
      'admitted',
      'current_policy',
      'carrier',
      'bulk_edit',
      'subjectivities_attributes',
      'annual_premium',
      'status',
    ],
    subEntry: [
      'coverable_id',
      'coverable_type',
      'type',
      'id',
      'quotedLineNames',
      'iconName',
      'coverageName',
    ],
  },
  schemas: {
    entry: [
      'admitted',
      'status',
      'annual_premium',
      'carrier',
      'current_policy',
      'subjectivities_attributes',
      'bulk_edit',
    ],
    subEntry: [
      'type',
      'id',
      '__typename',
      'coverage',
      'quotedLineNames',
      'iconName',
      'coverageName',
    ],
  },
}

export const bulkEditOptions = {
  limit: [500000, 1000000, 2000000, 3000000, 5000000],
  retention: [1000, 2500, 5000, 10000, 15000, 25000, 50000, 100000],
}

export const formDataMapping = {
  specialRisk: {
    section: 'special_risk',
    key: 'special_risk',
  },
  computerSystem: {
    section: 'fidelity_crime',
    key: 'computer_system',
  },
  counterfeitMoney: {
    section: 'fidelity_crime',
    key: 'counterfeit_money',
  },
  employeeDishonesty: {
    section: 'fidelity_crime',
    key: 'employee_dishonesty',
  },
  fidelityCrime: {
    section: 'fidelity_crime',
    key: 'fidelity_crime',
  },
  forgeryOrAlteration: {
    section: 'fidelity_crime',
    key: 'forgery_or_alteration',
  },
  fundsTransferFraud: {
    section: 'fidelity_crime',
    key: 'funds_transfer_fraud',
  },
  impersonationFraud: {
    section: 'fidelity_crime',
    key: 'impersonation_fraud',
  },
  inTransite: {
    section: 'fidelity_crime',
    key: 'in_transite',
  },
  onPremises: {
    section: 'fidelity_crime',
    key: 'on_premises',
  },
  fiduciary: {
    section: 'fiduciary',
    key: 'fiduciary',
  },
  employee: {
    section: 'employment_practices_liability',
    key: 'employee',
  },
  employmentPracticesLiability: {
    section: 'employment_practices_liability',
    key: 'employment_practices_liability',
  },
  thirdParty: {
    section: 'employment_practices_liability',
    key: 'third_party',
  },
  directorsAndOfficers: {
    section: 'directors_and_officers',
    key: 'directors_and_officers',
  },
  entityCoverage: {
    section: 'directors_and_officers',
    key: 'entity_coverage',
  },
  indemnifiableLoss: {
    section: 'directors_and_officers',
    key: 'indemnifiable_loss',
  },
  nonIndemnifiableLoss: {
    section: 'directors_and_officers',
    key: 'non_indemnifiable_loss',
  },
}

export const schemasToFormDataMapping = {
  directors_and_officers: {
    type: 'ManagementLiability::DirectorsAndOfficersQuotedLine',
    coverable_type: 'ManagementLiability::DirectorsAndOfficersCoverage',
  },
  employment_practices_liability: {
    type: 'ManagementLiability::EmploymentPracticesLiabilityQuotedLine',
    coverable_type: 'ManagementLiability::EmploymentPracticesLiabilityCoverage',
  },
  fiduciary: {
    type: 'ManagementLiability::FiduciaryQuotedLine',
    coverable_type: 'ManagementLiability::FiduciaryCoverage',
  },
  fidelity_crime: {
    type: 'ManagementLiability::FidelityCrimeQuotedLine',
    coverable_type: 'ManagementLiability::FidelityCrimeCoverage',
  },
  special_risk: {
    type: 'ManagementLiability::SpecialRiskQuotedLine',
    coverable_type: 'ManagementLiability::SpecialRiskCoverage',
  },
}
