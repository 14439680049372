import BN from 'bignumber.js'

import { USD } from 'data/currencies'

import compact from 'lodash/compact'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import numeral from 'numeral'
import { isBlank, stripNonNumericCharacters } from './langUtil'

export const CURRENCY_SYMBOL_MATCH_REGEX = /(\s?[£€\\$]|KD)\s?/
export const NUMERIC_SHORTCUT_MATCH_REGEX = /^(\d?\.?\d+[kmbt])(\d*(\.\d+))?/
const BigNumber = BN.clone({ ROUNDING_MODE: BN.ROUND_DOWN })

export const currencyToBigDecimalNumber = ({
  currencyAmount,
  decimalPlaces = 2,
}) => {
  const amount = stripNonNumericCharacters(currencyAmount)
  return new BigNumber(amount || 0.0).decimalPlaces(decimalPlaces).toNumber()
}

const stripThousandSeparators = value =>
  isString(value) ? value.replace(/,/g, '') : value

const normalizedShortcutAmount = value => {
  const matches = compact(value.match(NUMERIC_SHORTCUT_MATCH_REGEX) || [])

  const [amountWithShortcut, leftoverAmount] =
    matches.length < 3 ? [matches[0], ''] : matches.slice(1, 3)

  const unit = currencyNormalizer(amountWithShortcut).split('.')[0]
  return `${unit}${leftoverAmount}`
}

export const convertNumericShortcuts = (_value, precision) => {
  const value = stripThousandSeparators(_value)
  if (!NUMERIC_SHORTCUT_MATCH_REGEX.test(value)) return value
  const normalizedValue = normalizedShortcutAmount(value)

  return currencyToBigDecimalNumber({
    currencyAmount: normalizedValue,
    decimalPlaces: precision,
  })
}

const removeUnsupportedCurrencyCharacters = value =>
  isString(value)
    ? stripThousandSeparators(value.replace(CURRENCY_SYMBOL_MATCH_REGEX, ''))
    : value

export const normalizeCurrencyWithPrecision = (
  _value = '',
  precisionOrCurrency = USD
) => {
  if (isBlank(_value)) return ''

  const value = removeUnsupportedCurrencyCharacters(_value)
  const precision = get(precisionOrCurrency, 'precision', precisionOrCurrency)

  if (NUMERIC_SHORTCUT_MATCH_REGEX.test(value))
    return convertNumericShortcuts(value, precision)

  const number = isNumber(value)
    ? String(value)
    : stripNonNumericCharacters(value) || ''

  const [unit = '', places = ''] = number.split('.')

  if (places.length <= precision) return number

  const truncatedPlaces = places.substring(0, precision)
  return [unit, truncatedPlaces].join('.')
}

export function currencyFormatter(value, currency = USD) {
  if (!value) return ''

  return `${currency.symbol}${numeral(value).format(
    `0,0[.]${Array(currency.precision).fill('0').join('')}`
  )}`
}

export function currencyNormalizer(value) {
  return value && /[0-9]/.test(value) ? numeral(value).format('0.0[0000]') : ''
}

export function percentNormalizer(value, previousValue) {
  return !/^\d{0,2}\.?\d*$|^$/.test(value) ||
    new BigNumber(value).isGreaterThan(100)
    ? previousValue
    : value
}
