import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { faTimesCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faSave } from '@fortawesome/pro-solid-svg-icons'
import TextField from '@material-ui/core/TextField'

import {
  useCyberSubmissionQuotesQuery,
  useCyberManualSubjectivitiesMutation,
} from 'graphql/api'
import {
  Loading,
  Subtitle,
  Button,
  ButtonContainer,
  Column,
  Clickable,
  Icon,
  Row,
} from 'atoms'
import { colors } from 'components/theme'

const SubjectivitiesEdit = props => {
  const history = useHistory()

  const [subjectivities, setSubjectivities] = useState([])
  const [destroyIds, setdestroyIds] = useState([])

  const { submissionId, quoteId } = useParams()

  const { data, loading, error } = useCyberSubmissionQuotesQuery({
    variables: { submissionId: submissionId || props.subId },
    fetchPolicy: 'cache-and-network',
  })

  if (error) throw error

  if (loading && !data) {
    return <Loading />
  }
  const submission = data?.cyber?.submission
  const participation = submission.participations.nodes.find(participation =>
    participation.quotations.nodes.find(quotation => quotation.id === quoteId)
  )

  useEffect(() => {
    participation
      ? setSubjectivities(
          participation?.subjectivities.nodes.map(subj => {
            return { id: subj.id, text: subj.text }
          })
        )
      : setSubjectivities(
          props?.subjList?.map(subj => {
            return { id: subj.id, text: subj.text }
          })
        )
  }, [submission])

  const [updateSubjectivities] = useCyberManualSubjectivitiesMutation({
    onCompleted: data => {
      if (data.cyberManualSubjectivities.errors) {
        toast.error(data.cyberManualSubjectivities.errors)
        history.goBack()
      } else {
        setSubjectivities(
          data?.cyberManualSubjectivities?.participation?.subjectivities.nodes.map(
            subj => {
              return { id: subj.id, text: subj.text }
            }
          )
        )
        setdestroyIds([])
        toast.success('Subjectivities were updated')
        if (history.location.pathname.includes('/subjectivities-edit')) {
          history.goBack()
        }
      }
    },
  })

  const handleUpdateSubjectivities = () => {
    subjectivities.forEach(subj => (subj.section = 'manual'))
    updateSubjectivities({
      variables: {
        input: {
          participationId: participation?.id || props.subjectivitable.id,
          subjectivities: subjectivities,
          destroyIds: destroyIds,
        },
      },
    })
  }

  const handleChange = e => {
    const fieldId = parseInt(e.target.id)
    const newSubj = subjectivities.map((subj, idx) => {
      return idx === fieldId ? { ...subj, text: e.target.value } : subj
    })
    setSubjectivities(newSubj)
  }
  const handleRemoveSubj = fieldIdx => {
    const removed = subjectivities[fieldIdx]
    if (removed.id) setdestroyIds([removed.id, ...destroyIds])
    const newSubj = subjectivities.filter((_subj, idx) => idx !== fieldIdx)
    setSubjectivities(newSubj)
  }
  const handleAddSubj = () => {
    const newSubj = [...subjectivities]
    newSubj.push({ text: '' })
    setSubjectivities(newSubj)
  }

  return (
    <Column
      height="100%"
      justifyContent="space-between"
      background={'white'}
      p={2}>
      <Subtitle mb={2} mt="none">
        Subjectivities
      </Subtitle>
      <Row>
        <Column flex="1">
          {subjectivities?.map((subj, idx) => (
            <Row key={subj.id} py={0} alignItems="center">
              <TextField
                id={idx}
                value={subj.text}
                onChange={handleChange}
                fullWidth
                multiline
              />
              <Icon
                icon={faTimesCircle}
                clickable
                ml={1}
                onClick={() => handleRemoveSubj(idx)}
              />
            </Row>
          ))}
          <Row>
            <Clickable
              color={colors.link}
              alignItems="center"
              onClick={handleAddSubj}>
              <Icon icon={faPlusCircle} mx={0} />
              Add Subjectivity
            </Clickable>
          </Row>
        </Column>
      </Row>
      <ButtonContainer>
        <Button tertiary onClick={history.goBack}>
          Cancel
        </Button>
        <Button onClick={handleUpdateSubjectivities} htmlType="button">
          <Icon icon={faSave} mx={0} />
          Save
        </Button>
      </ButtonContainer>
    </Column>
  )
}

export default SubjectivitiesEdit
