import React from 'react'
import { $, Text } from 'atoms'

const MoneyRenderer = props => {
  const disableColor = props.locked ? { color: '#AAAAAA' } : {}
  const enhanced = props.criterion?.props?.enhanced
    ? { fontWeight: 'bold' }
    : {}

  return (
    <Text {...disableColor} {...enhanced}>
      <$ fallback="—" {...props} />
    </Text>
  )
}

export default MoneyRenderer
