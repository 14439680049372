import React from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { EnhancedModal, Subtitle, Div } from 'atoms'
import ExportQuestionForm from './ExportQuestionForm.js'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'

const ExportModal = ({ scope = QUESTION, questions, onClose }) => {
  const translator = qandaTranslator(scope)

  return (
    <Form onSubmit={() => {}} mutators={arrayMutators}>
      {() => (
        <EnhancedModal onClose={onClose}>
          <Div p={2}>
            <Subtitle>{translator('exportQuestion')}</Subtitle>
            <ExportQuestionForm
              scope={scope}
              onClose={onClose}
              questions={questions}
            />
          </Div>
        </EnhancedModal>
      )}
    </Form>
  )
}

export default ExportModal
