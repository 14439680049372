import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'

const Box = styled.div`
  background-color: ${props =>
    props.excluded
      ? props.theme.colors.error
      : transparentize(0.2, props.theme.colors.secondaryBlue)};
  text-decoration: ${props => (props.excluded ? 'line-through' : 'none')}
  font-family: ${props => props.theme.fonts.secondary};
  color: ${props =>
    props.excluded ? props.theme.colors.white : props.theme.colors.message};
  font-size: 14px;
  text-align: center;
  padding: 8px 4px;
  border-radius: 4px;
  margin: 6px 6px 0 0;
  box-sizing: border-box;
  min-width: 150px;
`

const Value = styled.span`
  font-weight: bold;
`

const PerilBox = ({ peril, excluded }) => (
  <Box excluded={excluded}>
    <Value>{peril}</Value>
  </Box>
)

export default PerilBox
