import * as yup from 'yup'

const isAccepted = function () {
  return this.test({
    name: 'terms_accepted',
    message: { key: 'terms_accepted' },
    test: value => value === true,
  })
}

const isSubmitted = function () {
  return this.test({
    name: 'form_submitted',
    message: { key: 'form_submitted' },
    test: value => value === true,
  })
}

export const addBooleanMethods = yup => {
  yup.addMethod(yup.boolean, 'isAccepted', isAccepted)
  yup.addMethod(yup.boolean, 'isSubmitted', isSubmitted)
}

export default yup
