import React, { useState } from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'

import ExternalLink from 'components/common/ExternalLink'
import Button from 'components/common/Button'
import _Icon from 'components/Icon'

const Icon = styled(_Icon)`
  cursor: pointer;
  color: ${props => props.theme.colors.disabled};
  margin-left: 5px;
`

const TipDiv = styled.div`
  width: 600px;
  font-weight: 300;
  padding: 1em;
`

const Paragraph = styled.p`
  font-weight: 300;
  &:first-of-type {
    margin-top: 0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
`

const AMBEST_URL = 'http://www.ambest.com/ratings/index.html'

const TipWindow = ({ toggle }) => {
  return (
    <TipDiv>
      <Paragraph>
        A.M. Best&apos;s Credit Ratings&#8482; are under continuous review and
        subject to change and/or affirmation. For the latest Best&apos;s Credit
        Ratings&#8482; and Best&apos;s Credit Reports (which include Best&apos;s
        Credit Ratings&#8482;), visit the A.M. Best website at{' '}
        <ExternalLink target="_blank" href="http://ambest.com">
          http://ambest.com
        </ExternalLink>
        . See Guide to Best&apos;s Credit Ratings&#8482; for explanation of use
        and charges.
      </Paragraph>
      <Paragraph>
        A.M. Best&apos;s Credit Ratings&#8482; reproduced herein appear under
        license from A.M. Best and do not constitute, either expressly or
        impliedly, an endorsement of Relay Platform Inc. or its recommendations,
        formulas, criteria or comparisons to any other ratings, rating scales or
        rating organizations that are published or referenced herein. A.M. Best
        is not responsible for transcription errors made in presenting
        Best&apos;s Credit Ratings&#8482;. Best&apos;s Credit Ratings&#8482; are
        proprietary and may not be reproduced or distributed without the express
        written permission of A.M. Best Company.
      </Paragraph>
      <Paragraph>
        A.M. Best&apos;s Financial Strength Rating opinion addresses the
        relative ability of an insurer to meet its ongoing insurance
        obligations. It is not a warranty of a company&apos;s financial strength
        and ability to meet its obligations to policyholders. View our Important
        Notice: Best&apos;s Credit Ratings for a disclaimer notice and complete
        details at{' '}
        <ExternalLink target="_blank" href={AMBEST_URL}>
          {AMBEST_URL}
        </ExternalLink>
      </Paragraph>
      <ButtonContainer>
        <Button onClick={() => toggle(false)}>Close</Button>
      </ButtonContainer>
    </TipDiv>
  )
}

const AMBestToolTip = () => {
  const [showTip, toggleTip] = useState(false)
  return (
    <Popover
      content={<TipWindow toggle={toggleTip} />}
      trigger="click"
      visible={showTip}
      onVisibleChange={() => toggleTip(!showTip)}>
      <Icon icon={faQuestionCircle} onClick={() => toggleTip(!showTip)} />
    </Popover>
  )
}

export default AMBestToolTip
