import CyberConfig from './Cyber'
import MultilineCyberConfig from './MultilineCyber'
import MLConfig from './ML'
import TerrorismConfig from './Terrorism'

import {
  useNewQuoteSchemaQuery,
  useCreateManualPanelQuotationMutation,
  useCreateCarrierPanelQuotationMutation,
  useNewCarrierQuoteSchemaQuery,
  usePcgDeleteManualQuotationMutation,
  useCyberArchiveQuoteMutation,
} from 'graphql/api'
import { checkForDoneQuotations } from 'pages/shared/panels/config'

const productOptions = lineOfBusiness => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Quotation.productOptions
    case 'Cyber':
      return CyberConfig.Quotation.productOptions
    case 'Multiline Cyber':
      return MultilineCyberConfig.Quotation.productOptions
    default:
      return {}
  }
}

const schemas = (
  lineOfBusiness,
  schemas,
  userType,
  formData,
  updateFormData,
  manual = false,
  editQuotation = false
) => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Quotation.schemas(
        schemas,
        userType,
        formData,
        updateFormData,
        manual,
        editQuotation
      )
    case 'Terrorism':
      return TerrorismConfig.Quotation.schemas(
        schemas,
        userType,
        formData,
        updateFormData,
        manual,
        editQuotation
      )
    case 'Multiline Cyber':
      return MultilineCyberConfig.Quotation.schemas(
        schemas,
        userType,
        formData,
        updateFormData,
        manual,
        editQuotation
      )
    default:
      return schemas
  }
}

const prefillQuotationData = (
  lineOfBusiness,
  schemas,
  submission,
  userType
  // isManual = false,
) => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Quotation.prefillQuotationData(
        schemas,
        submission,
        userType
      )
    default:
      return {}
  }
}

const formatQuotationData = (
  lineOfBusiness,
  formData,
  userType,
  manual = false
) => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Quotation.formatQuotationData(formData, userType, manual)
    default:
      return formData.quote
  }
}

const createQuoteMutation = (
  isManual,
  { participationId, submissionId } = {}
) => {
  if (isManual) {
    return () => {
      const [useMutation] = useCreateManualPanelQuotationMutation()
      return formData =>
        useMutation({
          variables: {
            input: {
              submissionId: submissionId,
              attributes: formData,
            },
          },
        })
    }
  } else {
    return () => {
      const [useMutation] = useCreateCarrierPanelQuotationMutation()
      return formData =>
        useMutation({
          variables: {
            input: {
              participationId,
              attributes: formData,
            },
          },
        })
    }
  }
}

const createQuoteSchema = (
  isManual,
  { participationId, panel, submissionId } = {}
) => {
  if (isManual) {
    return () =>
      useNewQuoteSchemaQuery({
        variables: {
          panelId: panel.id,
          submissionId: submissionId,
        },
      })
  } else {
    return () =>
      useNewCarrierQuoteSchemaQuery({
        variables: {
          participationId: participationId,
        },
      })
  }
}

const editRoute = (lineOfBusiness, url, quotation) => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return {
        pathname: quotation.id
          ? `${url}/quotes/${quotation.id}/edit`
          : `${url}/quotes/new-carrier`,
        state: {
          quotation,
        },
      }
    case 'Private_Clients':
      return {
        pathname: quotation.id
          ? `${url}/quotes/${quotation.id}/edit`
          : `${url}/quotes/new`,
        state: {
          participationId: quotation?.participationId,
        },
      }
    default:
      return {
        pathname: `${url}/quotes/${quotation.id}/edit`,
      }
  }
}

const getSchema = isManual => {
  if (isManual) {
    return data => data.panels.panel.schemas.newManualQuote
  } else {
    return data => data.panels.participation.schemas.newCarrierQuote
  }
}

const doneStatus = (lineOfBusiness, quotations, quotationId) => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Quotation.doneStatus(quotations, quotationId)
    default:
      return checkForDoneQuotations(quotations, quotationId)
  }
}
const deleteQuoteMutation = lineOfBusiness => {
  switch (lineOfBusiness) {
    case 'Private_Clients':
      return usePcgDeleteManualQuotationMutation()
    default:
      return [() => null]
  }
}
const archiveQuotationMutation = lineOfBusiness => {
  switch (lineOfBusiness) {
    case 'Cyber':
      return useCyberArchiveQuoteMutation()
    default:
      return [() => null]
  }
}

export default {
  productOptions,
  schemas,
  formatQuotationData,
  createQuoteMutation,
  createQuoteSchema,
  editRoute,
  getSchema,
  prefillQuotationData,
  doneStatus,
  deleteQuoteMutation,
  archiveQuotationMutation,
}
