import { useReducer, useEffect, useRef, useContext } from 'react'
import * as Sentry from '@sentry/react'
import { Auth, Hub } from 'aws-amplify'
import { useApolloClient } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import RinkChatContext from 'components/contexts/rinkChat'
import { useBranding } from 'hooks'
import relayBrand from 'components/theme/branding/relay'

const amplifyAuthReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_USER_DATA_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case 'FETCH_USER_DATA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.payload.user,
      }
    case 'FETCH_USER_DATA_FAILURE':
      return { ...state, isLoading: false, isError: true }
    case 'RESET_USER_DATA':
      return { ...state, user: null }
    default:
      throw new Error()
  }
}

export const useSwitchToken = () => {
  const { handleSignout } = useAuth()
  const history = useHistory()
  const { setBranding, brand } = useBranding()

  const rinkChatContext = useContext(RinkChatContext)
  return [
    token =>
      handleSignout().then(() => {
        if (brand === 'Whitelabelled') {
          setBranding(relayBrand)
        }
        if (rinkChatContext) rinkChatContext.client.disconnect()
        history.replace(`${location.pathname}?invitationToken=${token}`)
        window.location.reload()
      }),
  ]
}

export const useLogout = () => {
  const { handleSignout } = useAuth()
  const history = useHistory()
  const { setBranding, brand } = useBranding()

  const rinkChatContext = useContext(RinkChatContext)
  return [
    () =>
      handleSignout().then(() => {
        if (brand === 'Whitelabelled') {
          setBranding(relayBrand)
        }
        if (rinkChatContext) rinkChatContext.client.disconnect()
        history.push('/')
      }),
  ]
}

const useAuth = () => {
  const initialState = {
    isLoading: true,
    isError: false,
    user: null,
  }
  const [state, dispatch] = useReducer(amplifyAuthReducer, initialState)
  let isMounted = useRef(false)

  const fetchUserData = async () => {
    if (isMounted.current) {
      dispatch({ type: 'FETCH_USER_DATA_INIT' })
    }
    try {
      if (isMounted.current) {
        const data = await Auth.currentAuthenticatedUser()
        dispatch({
          type: 'FETCH_USER_DATA_SUCCESS',
          payload: { user: data },
        })
      }
    } catch (error) {
      if (isMounted.current) {
        dispatch({ type: 'FETCH_USER_DATA_FAILURE' })
      }
    }
  }

  const onAuthEvent = ({ payload }) => {
    switch (payload.event) {
      case 'signIn':
        if (isMounted.current) {
          dispatch({
            type: 'FETCH_USER_DATA_SUCCESS',
            payload: { user: payload.data },
          })
        }
        break
      case 'signOut':
        if (isMounted.current) {
          dispatch({ type: 'RESET_USER_DATA' })
        }
        break
      // no default
    }
  }

  useEffect(() => {
    isMounted.current = true

    Hub.listen('auth', onAuthEvent)
    fetchUserData()

    return () => {
      Hub.remove('auth')
      isMounted.current = false
    }
  }, [isMounted])

  const client = useApolloClient()

  const handleSignout = async () => {
    try {
      await Auth.signOut()
      client.resetStore()
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error signing out user ', error)
      } else {
        Sentry.captureMessage(error)
      }
    }
  }

  return { state, handleSignout }
}

export default useAuth
