import numeral from 'numeral'

import currencies from 'data/currencies.json'

const USD = currencies['USD']

export function formatter(value, currency = USD) {
  if (!value) return ''

  return `${currency.symbol}${numeral(value).format(
    `0,0[.]${Array(currency.precision).fill('0').join('')}`
  )}`
}

export function normalizer(value) {
  return value && /[0-9]/.test(value) ? numeral(value).format('0.0[0000]') : ''
}
