import styled from 'styled-components'
import {
  typography,
  space,
  color,
  flexbox,
  layout,
  border,
  background,
  position,
  shadow,
} from 'styled-system'

const Img = styled.img(
  typography,
  space,
  color,
  layout,
  flexbox,
  border,
  background,
  position,
  shadow
)
Img.displayName = 'Img'

export default Img
