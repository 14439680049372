import React from 'react'

export const formatFeatures = featureList =>
  featureList.list.reduce(
    (acc, { name, enabled }) => ({ ...acc, [name]: enabled }),
    {}
  )

const FeaturesContext = React.createContext(undefined)
FeaturesContext.displayName = 'FeaturesContext'

export default FeaturesContext
