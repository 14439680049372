import React from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import colors from './colors'
import fonts from './fonts'

const palette = {
  primary: { main: colors.primaryText },
  secondary: { main: colors.secondaryYellow },
  error: { main: colors.error },
  info: { main: colors.link },
  text: {
    primary: colors.primaryText,
    secondary: colors.primaryTextMid,
  },
}

const preventSubmitOnEnter = event => {
  if (event.key == 'Enter') {
    event.preventDefault()
    event.stopPropagation()
  }
}

const theme = {
  palette,
  typography: {
    fontFamily: [fonts.primary],
    h1: { fontSize: '6rem' },
    h2: { fontSize: '4rem', fontWeight: '600' },
    h3: { fontSize: '3rem', fontWeight: '600' },
    h4: { fontSize: '2.5rem', fontWeight: '600' },
    h5: { fontSize: '2rem', fontWeight: '600' },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
      onKeyDown: preventSubmitOnEnter,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: colors.background,
        },
      },
    },
  },
}

const muiTheme = createMuiTheme(theme)

const MaterialTheme = ({ children }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default MaterialTheme
export { theme }
