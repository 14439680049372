import { MLConfig, getConfig } from 'pages/shared/panels/config'

const defaultLinks = {
  messageReceived: (discussable, message) => {
    if (discussable.__typename === 'Participation') {
      return `/quotes/${discussable.submission.id}?msgId=${message.id}`
    } else {
      return `/quotes/${discussable.id}?msgId=${message.id}`
    }
  },
}

const reinsurerNotificationLinks = lineOfBusiness => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return getConfig(defaultLinks, MLConfig.Notifications.reinsurer)
    default:
      return defaultLinks
  }
}

export default reinsurerNotificationLinks
