import { reset, SubmissionError, formValueSelector } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { toast } from 'react-toastify'

import { withPostMessage } from 'graphql/api'
import PostMessageForm from 'components/forms/MessageForm'
import { reportEvent } from 'util/analytics'
import { cloneFiles } from 'util/files'
import { form } from 'util/forms'
import { withUser } from 'components/contexts/user'
import { defaultParticipationFooter } from 'components/submission/utils/participationComment'

const afterSubmit = (_, dispatch) => dispatch(reset('POST_MESSAGE'))

const submitHandler = (
  { subject, body, attachments, signOff },
  _,
  { mutate, discussable }
) => {
  return mutate({
    variables: {
      input: {
        subject: subject,
        body: body,
        discussableId: discussable.id,
        discussableType: discussable.__typename,
        attachments: cloneFiles(attachments),
        signOff: signOff,
      },
    },
    refetchQueries: [
      'CedentQuoteMessagesQuery',
      'ReinsurerQuoteMessagesQuery',
      'WorksheetDetailsQuery',
    ],
  })
    .catch(() => {
      throw new SubmissionError({
        _error: 'Oops, looks like something went wrong. Try again.',
      })
    })
    .then(({ data }) => {
      if (data.postMessage.errors.length !== 0) {
        throw new SubmissionError({
          _error: data.postMessage.errors[0],
        })
      } else {
        reportEvent('Message', 'Created', {
          value: data.postMessage.message.id,
        })
        toast.success('Your message has been sent')
      }
    })
}

const selector = formValueSelector('POST_MESSAGE')

export default compose(
  withRouter,
  withPostMessage(),
  withUser,
  withProps(({ subject, body, user }) => ({
    initialValues: {
      subject: subject || '',
      body: body || '',
      attachments: [],
      signOff:
        user.currentUser.emailSignOff ||
        defaultParticipationFooter(user.currentUser),
    },
  })),
  connect(state => ({
    attachments: selector(state, 'attachments'),
  })),
  form({
    form: 'POST_MESSAGE',
    preventAnalyticsEvent: true,
    initialValues: {
      subject: '',
      body: '',
      attachments: [],
    },
    onSubmit: submitHandler,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
  })
)(PostMessageForm)
