import React from 'react'

import Button from './Button'
import Icon from './Icon'

const IconButton = ({
  icon,
  transform = undefined,
  title = undefined,
  children = undefined,
  iconPosition = 'left',
  ...rest
}) => (
  <Button {...rest}>
    {iconPosition === 'left' ? (
      <>
        <Icon mr={0} icon={icon} transform={transform} /> {title} {children}
      </>
    ) : (
      <>
        {title} {children} <Icon ml={8} icon={icon} transform={transform} />
      </>
    )}
  </Button>
)

export default IconButton
