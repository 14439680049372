import React from 'react'
import { Form, Field as FormField } from 'react-final-form'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import arrayMutators from 'final-form-arrays'

import { Title, EnhancedModal, Button, ButtonContainer, Row } from 'atoms'
import { TextArea } from 'atoms/form'
import Field from 'molecules/Field'
import { required } from 'util/validators'
import { ProductOptionsTable } from 'pages/shared/panels'

const DeclineModal = ({ config, submission, location }) => {
  const { declineQuote } = config.mutations
  const { refetchQueries, getProductOptions } = config.getters
  const { participationId } = useParams()
  const [declineQuotation] = declineQuote.mutation()
  const history = useHistory()
  const { url } = useRouteMatch()

  const handleSubmit = async formValues => {
    await declineQuotation({
      variables: {
        input: {
          ...formValues,
          participationId: participationId,
          quotationId: location?.state?.quotationId,
          reason: location?.state?.reason,
        },
      },
      refetchQueries,
    })
    toast.success('You have declined the request')
    history.replace(url.replace(/\/decline/, ''))
  }

  return (
    <EnhancedModal>
      <Title>Decline Quote</Title>
      <Row>
        <Field label="Applicant" value={submission.name} />
      </Row>
      <Row mb={2}>
        <ProductOptionsTable {...getProductOptions(submission)} />
      </Row>
      <Form mutators={arrayMutators} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <>
            <FormField
              name="message"
              label="Reason"
              required
              validate={required()}
              component={TextArea}
            />
            <ButtonContainer>
              <Button onClick={handleSubmit}>Decline</Button>
            </ButtonContainer>
          </>
        )}
      </Form>
    </EnhancedModal>
  )
}

export default DeclineModal
