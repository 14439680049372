import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import DOMPurify from 'dompurify'
import { Waypoint } from 'react-waypoint'
import { faInfo, faEnvelope } from '@fortawesome/pro-solid-svg-icons'

import { Flex, Avatar, DateString, RichTextDiv } from 'atoms'

import useQueryParams from 'hooks/queryParams'
import { MessageFragmentDoc, BroadcastType } from 'graphql/api'
import FontAwesomeIcon from 'components/Icon'
import Document from 'molecules/Document'
import { useCurrentUser, useBranding } from 'hooks'
import { useReadMessageMutation } from 'graphql/api'

const Container = styled.div`
  width: calc(100% - 20px);
  margin-top: 24px;
`

const Heading = styled.div`
  width: 864px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Subject = styled.div`
  width: 808px;
  color: ${props => props.theme.colors.primaryText};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  display: flex:
  flex-direction: column;
`

const Timestamp = styled.div`
  width: 100%;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  color: ${props => props.theme.colors.message};
`

const Body = styled.div`
  background: ${props => props.theme.colors.secondaryBackground};
  border-radius: 8px;
  color: ${props => props.theme.colors.primaryText};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.43;
  margin: 14px 0px 48px 0px;
  padding: 24px;
  ${props => !props.html && `white-space: pre-line;`}
`

const Panel = styled.div`
  flex: 1;
  color: ${props => props.theme.colors.primaryText};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 14px;
  margin-top: 18px;
  display: flex;
  flex-direction: row;
`

const BroadcastPill = styled.span`
  background-color: ${props => props.theme.colors.lightGrey};
  align-items: center;
  display: flex;
  color: ${props => props.theme.colors.primaryText};
  border-radius: 24px;
  padding: 0 1em;
  margin-right: 0.5em;
  font-weight: 600;
  font-size: 12px;
  margin-left: 1em;

  & > :first-child {
    margin-right: 5px;
  }
`

const IS_HTML = /<\/?[a-z][\s\S]*>/i

const Message = ({ message, userToLastMsgId }) => {
  const { isP3 } = useBranding()
  const [readMessage] = useReadMessageMutation()
  const user = useCurrentUser()
  const msgId = useQueryParams().msgId
  const self = useRef(null)

  useEffect(() => {
    if (msgId && msgId === message.id && self.current) {
      self.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [message, msgId, self])

  const messageIsHTML = IS_HTML.test(message.body)

  const _handleWaypointEnter = () => {
    const lastReadMsgId = userToLastMsgId[user.id]
    if (!lastReadMsgId || +message.id > +lastReadMsgId) {
      readMessage({
        variables: {
          input: {
            messageId: +message.id,
          },
        },
      })
    }
  }

  return (
    <Container ref={self} data-scroll={`message:${message.id}`}>
      {isP3 && <Waypoint onEnter={() => _handleWaypointEnter()} />}
      <Heading>
        <Avatar user={message.author} />
        <Subject>
          <Flex>
            {message.subject}
            {message.__typename === 'Broadcast' &&
              (message.type === BroadcastType.SubmissionUpdate ? (
                <BroadcastPill>
                  <FontAwesomeIcon icon={faInfo} />
                  Placement Update
                </BroadcastPill>
              ) : (
                <BroadcastPill>
                  <FontAwesomeIcon icon={faEnvelope} />
                  Broadcast to All Markets
                </BroadcastPill>
              ))}
          </Flex>
          <Timestamp>
            <DateString date={message.timestamp} isDateTime />
          </Timestamp>
        </Subject>
      </Heading>
      {message.attachments.nodes.map(document => (
        <Panel key={document.id}>
          <Document document={document} />
        </Panel>
      ))}
      {messageIsHTML ? (
        <Body html>
          <RichTextDiv
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(message.body),
            }}
          />
        </Body>
      ) : (
        <Body>{message.body}</Body>
      )}
    </Container>
  )
}

Message.fragment = MessageFragmentDoc

export default Message
