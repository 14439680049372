import React from 'react'
import styled from 'styled-components'
import { Div, Text } from 'atoms'
import { device } from 'util/devices'

const Explanation = styled(Div)`
  position: sticky;
  background: none;
  right: 30px;
  top: 0;
  float: right;
  z-index: 5;
  height: 0;
  max-width: 300px;
  width: 25%;
  @media ${device.desktop} {
    width: 20%;
  }
`

const ExplanationText = styled(Text)`
  margin-top: 50px;
  padding: 10px;
  border-radius: 2px;
  display: block;
  font-weight: bold;
  color: #434f5c;
  background: #fff0c1;
`

const ExplanationBox = ({ children }) => (
  <Explanation>
    <ExplanationText>{children}</ExplanationText>
  </Explanation>
)

export default ExplanationBox
