import React from 'react'
import { $, Div, Text } from 'atoms'
import { makeStyles } from '@material-ui/core'

const WholesalerCommissionWithPercentageRenderer = ({
  children: quotedLine,
}) => {
  const useStyles = makeStyles({
    textRow: {
      display: 'flex',
      alignItems: 'center',
    },
    percentage: {
      margin: '0 5px',
    },
  })

  const wholesalerCommissionPercentage =
    quotedLine?.wholesalerCommissionPercentage * 100
  const classes = useStyles()

  return (
    <Text className={classes.textRow}>
      <$ fallback="—">{quotedLine?.wholesalerCommission}</$>
      <Div className={classes.percentage}>
        (
        {wholesalerCommissionPercentage
          ? wholesalerCommissionPercentage.toFixed(2)
          : '-'}
        %)
      </Div>
    </Text>
  )
}

export default WholesalerCommissionWithPercentageRenderer
