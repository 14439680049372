import React from 'react'
import {
  AdmittedRenderer,
  BindButtonRenderer,
  DocumentRenderer,
  MoneyRenderer,
  MultiTitleRenderer,
  NavButtonRenderer,
  RetailCommissionRenderer,
  StatusRenderer,
  TextRenderer,
  UnderwriterRenderer,
  WholesalerCommissionWithPercentageRenderer,
} from '../../renderers'
import { insuringAgreements } from './terrorismConstants'
import { styleNavButtonRenderer } from 'molecules/PanelQuoteComparator/renderers/NavButtonRenderer'
import { QUOTE_STATUSES } from 'panels/constants'
import AMBestLabel from 'molecules/AMBestLabel'
import { parseTooltipDefinitions, NON_BINDABLE_STATES } from '../utils'

const hasStatus = status => entries =>
  entries.some(entry => status === entry.status)

// eslint-disable-next-line no-unused-vars
const criteria = ({ panelConfiguration, currentUser }) => {
  const customTooltipDefinitions = parseTooltipDefinitions(
    panelConfiguration?.tooltip_definitions
  )

  return {
    context: {
      bindable: entries =>
        !entries.some(entry => NON_BINDABLE_STATES.includes(entry.status)),
      binding: hasStatus(QUOTE_STATUSES.binding),
      bound: hasStatus(QUOTE_STATUSES.bound),
      issued: hasStatus(QUOTE_STATUSES.issued),
      outdated: hasStatus(QUOTE_STATUSES.outdated),
      referred: hasStatus(QUOTE_STATUSES.referred),
      extracted: hasStatus(QUOTE_STATUSES.extracted),
      statusMessage: entries => entries.some(entry => entry.statusMessage),
      nonAPICarriers: entries =>
        entries.some(entry => !entry.isApi && !entry.externalCarrier),
      userIsWholesaler: (_quotes, { wholesalerContext }) =>
        wholesalerContext.userIsWholesaler,
      hasWholesaler: (_quotes, { wholesalerContext }) =>
        wholesalerContext.hasWholesaler,
      isSubmissionOwner: (_quotes, { wholesalerContext }) => {
        const { submissionOwnerId } = wholesalerContext
        return submissionOwnerId === currentUser.id
      },
      isCollaboratingWholesaler: (_quotes, { wholesalerContext }) => {
        const { collaboratingWholesalerIds } = wholesalerContext
        return collaboratingWholesalerIds.includes(currentUser.id)
      },
      wholesalerCollaborations: (_quotes, { wholesalerContext }) =>
        wholesalerContext.wholesalerCollaborations,
    },
    sections: [
      {
        criteria: [
          {
            key: 'requestedLimit',
            label: 'Limit',
            renderer: MoneyRenderer,
          },
          {
            key: 'requestedDeductible',
            label: 'Deductible',
            renderer: MoneyRenderer,
          },
          {
            key: 'quotedLines[0].clientCost',
            label: 'Cost for Insured',
            renderer: MoneyRenderer,
            props: { enhanced: true },
          },

          {
            key: 'quotedLines[0].annualPremium',
            label: 'Annual Premium (Net)',
            renderer: MoneyRenderer,
          },
          {
            key: 'quotedLines[0]',
            label: 'Retailer Commission',
            renderer: RetailCommissionRenderer,
            isAdjustable: true,
          },
          {
            key: 'quotedLines[0]',
            label: 'Wholesaler Commission',
            renderer: WholesalerCommissionWithPercentageRenderer,
            showIf: ({ context }) => {
              const { userIsWholesaler } = context
              return userIsWholesaler
            },
          },
          {
            key: 'ambestRating',
            label: <AMBestLabel />,
            renderer: TextRenderer,
            fallback: 'Not Rated',
          },
          {
            key: 'admitted',
            label: 'Admitted / Non-Admitted',
            renderer: AdmittedRenderer,
          },
          {
            key: 'documents',
            label: 'Documents',
            renderer: DocumentRenderer,
          },
          {
            key: 'quotedLines[0].policyNumber',
            label: 'Policy Number',
            renderer: TextRenderer,
            props: { fontWeight: 2 },
            showIf: ({ context }) => context.bound || context.issued,
          },
          // Buttons and statuses
          {
            renderer: NavButtonRenderer,
            label: 'View Details',
            secondary: true,
            to: ({ quote }) => `${quote.submissionId}/quotes/${quote.id}`,
            show: ({ quote }) =>
              !quote.externalCarrier && !quote.isApi && !quote.isExtracted,
            showIf: ({ context }) => context.nonAPICarriers,
          },
          {
            renderer: BindButtonRenderer,
            label: 'Bind Quote',
            showIf: ({ context }) => context.bindable,
            show: ({ quote }) =>
              !quote.externalCarrier &&
              quote.status !== QUOTE_STATUSES.referred,
            to: quote => `${quote.submissionId}/quotes/${quote.id}/bind`,
          },
          // TODO: Once we want to support a retailer messaging multiple wholesalers, we will
          // need to turn this into a select or something where the retailer can choose which
          // wholesaler to message
          {
            renderer: styleNavButtonRenderer({
              style: { width: '200px', maxWidth: '75%' },
              lockable: false,
            }),
            label: 'Message Wholesaler',
            secondary: true,
            showIf: ({ context }) => {
              const { hasWholesaler, userIsWholesaler, isSubmissionOwner } =
                context
              return hasWholesaler && !userIsWholesaler && isSubmissionOwner
            },
            to: ({ quote, context }) => {
              const { wholesalerCollaborations } = context
              return `${quote.submissionId}/collaborations/${wholesalerCollaborations[0].id}/discussion`
            },
          },
          {
            renderer: styleNavButtonRenderer({
              style: { width: '200px', maxWidth: '75%' },
              lockable: false,
            }),
            label: 'Message Retailer',
            secondary: true,
            showIf: ({ context }) => {
              const {
                hasWholesaler,
                userIsWholesaler,
                isCollaboratingWholesaler,
              } = context
              // display if:
              // - the user is a wholesaler
              // - the current user is the collaborating wholesaler
              return (
                hasWholesaler && userIsWholesaler && isCollaboratingWholesaler
              )
            },
            to: ({ quote, context }) => {
              const { wholesalerCollaborations } = context
              return `${quote.submissionId}/collaborations/${wholesalerCollaborations[0].id}/discussion`
            },
          },
          {
            renderer: UnderwriterRenderer,
            label: 'Underwriter',
            path: ({ submissionId }, quotation) =>
              `/panels/submissions/${submissionId}/participations/${quotation.participationId}`,
            showIf: ({ context }) => {
              // display if:
              // - Retailer submission: The user is NOT a retailer and is the current collaborating wholesaler
              // - Wholesaler submission: The current user owns the submission
              const {
                hasWholesaler,
                userIsWholesaler,
                isCollaboratingWholesaler,
                isSubmissionOwner,
              } = context
              return (
                !(hasWholesaler && !userIsWholesaler) &&
                (isCollaboratingWholesaler || isSubmissionOwner)
              )
            },
          },
          {
            renderer: StatusRenderer,
            showIf: ({ context }) =>
              context.binding ||
              context.bound ||
              context.issued ||
              context.referred,
          },
          {
            key: 'statusMessage',
            renderer: TextRenderer,
            props: { fontWeight: 2 },
            showIf: ({ context }) => context.statusMessage,
          },
        ],
      },
      {
        title: 'Insuring Agreements',
        criteria: [
          {
            label: '',
            renderer: MultiTitleRenderer,
            titles: ['LIMIT/NOTES'],
          },
          ...insuringAgreements(customTooltipDefinitions).map(
            ({ label, key, tooltipContent }) => {
              return {
                label,
                key: `${key}.value`,
                renderer: TextRenderer,
                tooltipContent,
              }
            }
          ),
        ],
      },
    ],
  }
}

export default criteria
