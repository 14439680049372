import React from 'react'
import { Column, Row, Text, Subtitle, Panel, Avatar } from 'atoms'
import { Quotations, SubmissionActions } from 'pages/shared/panels'

const ReinsurerShow = ({
  config,
  participation,
  submission,
  editUrl,
  broker,
  userType,
}) => {
  const { ApplicantContent } = config.components

  return (
    <>
      <Quotations
        config={config}
        participation={participation}
        submission={submission}
        editUrl={editUrl}
        basePath={`/quotes/panels/${participation.id}`}
        userType={userType}
      />
      <Subtitle mb={1} mt={2}>
        Application
      </Subtitle>
      <Panel submission mb={2}>
        <Row
          pb={2}
          alignItems="center"
          borderBottomColor="secondaryBlue"
          borderBottomStyle="Solid"
          borderBottomWidth="1px">
          <Avatar user={broker} />
          <Column>
            <Text fontWeight={2} ml={2} mr="auto">
              {broker.company?.name}
            </Text>
            <Text ml={2} mr="auto">
              {broker.fullName} | {broker.jobTitle || 'Broker'}
            </Text>
          </Column>
          <SubmissionActions participation={participation} />
        </Row>
        <ApplicantContent submission={submission} />
      </Panel>
    </>
  )
}

export default ReinsurerShow
