import {
  MoneyRenderer,
  MultiColumnRenderer,
  MultiTitleRenderer,
  TitleRenderer,
} from '../../../renderers'
import { uniq } from 'util/lodash'

const getExcessLine = quotation => {
  return quotation.quotedLines?.find(
    ql => ql.__typename === 'PCGExcessQuotedLineType'
  )
}

const getExcessLines = quotations => {
  return quotations
    .flatMap(quote =>
      quote.quotedLines?.filter(
        ql => ql.__typename === 'PCGExcessQuotedLineType'
      )
    )
    .filter(Boolean)
}
const getCoverages = quotations => {
  const excesses = getExcessLines(quotations)
  const allCoverages = excesses.flatMap(el => el.coverages).filter(Boolean)
  return uniq(allCoverages.map(coverage => coverage.coverage))
}
const getLimitOptions = quotations => {
  const excesses = getExcessLines(quotations)
  const allFeatures = excesses.flatMap(el => el.limitOptions).filter(Boolean)
  return uniq(allFeatures.map(option => option.option))
}
const renderExcessCoverage = () => {
  return {
    label: 'Excess Liability',
    renderer: TitleRenderer,
    icon: 'umbrella',
  }
}
const renderExcessQuotedLine = (quotedLine, idx) => {
  return [
    {
      __typename: 'PCGExcessQuotedLineType',
      label: 'Excess Liability',
      renderer: TitleRenderer,
      icon: 'umbrella',
    },
    {
      label: 'Base Coverage',
      renderer: MultiTitleRenderer,
      titles: ['Limit'],
    },
    quotations => {
      const coverages = getCoverages(quotations)

      const finder = (quotation, criterion) => {
        const excess = getExcessLine(quotation)
        return excess?.coverages.find(
          coverage => coverage.coverage === criterion.label
        )
      }
      return coverages.map(coverage => ({
        label: coverage,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [{ renderer: MoneyRenderer, key: 'limit' }],
      }))
    },
    {
      label: 'Limit Options',
      renderer: MultiTitleRenderer,
      titles: ['Limit', 'Total Premium'],
    },
    quotations => {
      const options = getLimitOptions(quotations)
      const finder = (quotation, criterion) => {
        const excess = getExcessLine(quotation)
        return excess?.limitOptions.find(
          option => option.coverage === criterion.label
        )
      }
      return options.map(option => ({
        label: option,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [
          { renderer: MoneyRenderer, key: 'limit' },
          { renderer: MoneyRenderer, key: 'totalPremium' },
        ],
      }))
    },
    {
      key: `quotedLines[${idx}].totalPremium`,
      label: 'TOTAL PREMIUM',
      renderer: MoneyRenderer,
    },
  ]
}

export {
  renderExcessQuotedLine,
  renderExcessCoverage,
  getExcessLine,
  getExcessLines,
  getCoverages,
  getLimitOptions,
}
