import React, { useState, useEffect } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

import { Subtitle, Panel } from 'atoms'
import { BindModal, PolicyModal, Declined } from 'pages/shared/panels'
import { QUOTE_STATUSES } from 'panels/constants'

export const quoteStatus = (quotation, status) => {
  return quotation.status.toLowerCase() === status
}
export const notQuoteStatus = (quotation, status) => {
  return quotation.status.toLowerCase() !== status
}
export const includedInQuotationStatus = (quotation, states) => {
  return states.includes(quotation.status.toLowerCase())
}

const Quotation = ({
  config,
  submission,
  title,
  quotation,
  country,
  bound,
  issued,
  userType,
  children = null,
}) => {
  const { QuoteContent } = config.components
  const { path } = useRouteMatch()
  const [current, setCurrent] = useState(quotation)
  useEffect(() => {
    setCurrent(quotation)
  }, [quotation])
  if (!current) return null
  if (quoteStatus(current, QUOTE_STATUSES.declined)) {
    return (
      <Declined
        quotation={current}
        customText={
          quotation.declinationReason ||
          'The industry this company operates in is outside of our underwriting appetite.'
        }
      />
    )
  }

  return (
    <>
      {userType === 'reinsurer' && (
        <>
          <Route path={`${path}/bind`}>
            <BindModal
              config={config}
              submission={submission}
              quotation={current}
              userType={userType}
            />
          </Route>
          <Route path={`${path}/issue`}>
            <PolicyModal
              config={config}
              submission={submission}
              quotation={current}
              userType={userType}
            />
          </Route>
        </>
      )}
      <Subtitle mb={1}>{title}</Subtitle>
      <Panel
        quote={!bound && !issued}
        binder={bound}
        certificate={issued}
        mb={2}>
        {children}
        <QuoteContent
          quotation={current}
          country={country}
          submission={submission}
        />
      </Panel>
    </>
  )
}

export default Quotation
