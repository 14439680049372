import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'

import notificationAllActionedMutation from 'components/mutations/notifications/notificationAllActioned'

const MarkAll = styled.p`
  color: ${props => props.theme.colors.link};
  margin-left: 20px;
  cursor: pointer;
`

const MarkAllAsRead = ({ instrument, notificationAllActioned }) => (
  <MarkAll
    onClick={instrument('MarkAllReadClicked', () => {
      notificationAllActioned()
    })}>
    Mark All as Read
  </MarkAll>
)

export default compose(notificationAllActionedMutation())(MarkAllAsRead)
