import React, { useEffect, useState } from 'react'

import { Checkbox } from '@material-ui/core'
import { Row, Column, Text } from 'atoms'
import styled from 'styled-components'

const Label = styled(Column)`
  margin-top: 0.4em;
  cursor: pointer;
`
const Subtitle = styled(Text)`
  font-style: italic;
`
const Check = styled(Checkbox)`
  margin-right: 15px;
`

const SubtitledCheckbox = ({
  name,
  schema,
  uiSchema,
  onChange,
  formData,
  disabled = false,
}) => {
  const [value, setValue] = useState(formData)
  useEffect(() => {
    setValue(formData)
  }, [formData])
  const onCheck = () => {
    const newValue = !value
    setValue(newValue)
    onChange(newValue)
  }
  return (
    <Row>
      <Column width="1.4em" m="0 1em 0 0">
        <Check
          disabled={disabled}
          checked={value}
          onChange={onCheck}
          name={name}
        />
      </Column>
      <Label onClick={!disabled && onCheck}>
        <Text fontSize="medium">{schema.title}</Text>
        <Subtitle fontSize="x-small">{uiSchema['ui:config'].subtitle}</Subtitle>
      </Label>
    </Row>
  )
}

export default SubtitledCheckbox
