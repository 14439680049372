import styled from 'styled-components'
import { space, flexbox } from 'styled-system'

const ButtonContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};

  & > :not(:only-child):first-child {
    margin-right: 5px;
  }

  & > :not(:only-child):not(:first-child) {
    margin-left: 5px;
  }
  ${space}
  ${flexbox}
`

export default ButtonContainer
