import React from 'react'
import { Flex, Icon, Div, Subtitle } from 'atoms'
import { faPlusCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'

const AddContainer = styled(Div)`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
`

const Simple = ({ canAdd, items, onAddClick, uiSchema, title, className }) => {
  const addLabel = uiSchema.addLabel || `Add ${title}`

  return (
    <Div className={className}>
      {(uiSchema['ui:title'] || title) && (
        <Subtitle m="0px">{title.toUpperCase()}</Subtitle>
      )}
      {items.map(element => (
        <Div key={element.key}>
          <Flex alignItems="center">
            <Div flex={1}>{element.children}</Div>
            {element.hasRemove && (
              <Icon
                data-testid="remove"
                ml={0}
                icon={faTimesCircle}
                onClick={element.onDropIndexClick(element.index)}
              />
            )}
          </Flex>
        </Div>
      ))}
      {canAdd && (
        <AddContainer color="link" onClick={onAddClick} data-testid="add">
          <Icon icon={faPlusCircle} mr={0} />
          {addLabel}
        </AddContainer>
      )}
    </Div>
  )
}

export default Simple
