import { MLConfig, getConfig, PCGConfig } from 'pages/shared/panels/config'

const defaultLinks = {
  messageReceived: (discussable, message, submissionPath) => {
    if (discussable.__typename === 'Worksheet') {
      const worksheet = discussable
      return `/${submissionPath(worksheet.demo)}/${
        worksheet.id
      }/conversation?msgId=${message.id}`
    } else if (discussable.__typename === 'Participation') {
      //for P3 (@adibsaad) TODO:connect to broker view and add case for reinsurer participation
      return `/broker/participations/${discussable.id}/conversation?msgId=${message.id}`
    } else {
      return `/${submissionPath(discussable.worksheet.demo)}/${
        discussable.worksheet.id
      }/quotes/${discussable.id}?msgId=${message.id}`
    }
  },
}

const brokerNotificationLinks = lineOfBusiness => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return getConfig(defaultLinks, MLConfig.Notifications.broker)
    case 'Private_Clients':
      return getConfig(defaultLinks, PCGConfig.Notifications.broker)
    default:
      return defaultLinks
  }
}

export default brokerNotificationLinks
