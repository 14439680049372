import React from 'react'

import { Column } from 'atoms'
import { BasicUploader } from 'molecules/DocumentUpload'

const DocumentsField = ({ formData: documents = [], onChange, schema }) => {
  const upload = newDocs => {
    onChange([...documents, ...newDocs])
  }

  const remove = index => {
    onChange(
      documents.map((doc, docIndex) =>
        docIndex === index ? { ...doc, _destroy: true } : doc
      )
    )
  }

  return (
    <Column pr={1}>
      <BasicUploader
        generateId
        upload={upload}
        remove={remove}
        documents={documents}
        label={schema.title}
      />
    </Column>
  )
}

export default DocumentsField
