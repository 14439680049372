import {
  nestedCheckListValues,
  removeKeyFromUiOrder,
  appendToUiOrder,
  insertSelectSectionCheckbox,
} from 'pages/shared/panels/config'
import { bulkEditOptions, ignoreList } from './constants'

export const insertCarrierName = schemas => {
  schemas.schema.properties.carrier = {
    type: 'string',
    title: 'Carrier Name',
  }
  schemas.uiSchema.carrier = {
    cols: 4,
  }
}

export const setCheckboxHeadings = (schemas, formData, updateFormData) => {
  let sections = []
  Object.keys(schemas.schema.properties).forEach(key => {
    if (!ignoreList.schemas.entry.includes(key)) {
      removeKeyFromUiOrder(schemas, key)
      sections.push(key)
      insertSelectSectionCheckbox(
        schemas,
        key,
        ignoreList.formData.subEntry,
        updateFormData
      )
    }
  })
  appendToUiOrder(schemas, sections.sort())
}

export const setSubjectivities = (schemas, key) => {
  delete schemas.uiSchema[key]['ui:options']
  schemas.uiSchema[key].addLabel = 'Capture Subjectivities'
  const index = schemas.uiSchema['ui:order'].indexOf(key)
  schemas.uiSchema['ui:order'].splice(index, 1)
  schemas.uiSchema['ui:order'].push(key)
}

export const updateCheckListValues = (
  formData,
  updateFormData,
  keys,
  value
) => {
  const formDataCopy = { ...formData }
  Object.keys(formData).forEach(key => {
    const entry = formDataCopy[key]
    if (!ignoreList.schemas.entry.includes(key)) {
      Object.keys(entry).forEach(subKey => {
        let subEntry = entry[subKey]
        if (keys.includes(subKey)) {
          subEntry = {
            ...subEntry,
            ...value,
          }
        }
        entry[subKey] = subEntry
      })
    }
    formDataCopy[key] = entry
  })
  updateFormData(formDataCopy)
}

export const bulkEditData = (formData, updateFormData) => {
  return {
    options: [
      {
        label: 'Limit',
        values: bulkEditOptions.limit,
      },
      {
        label: 'Retention',
        values: bulkEditOptions.retention,
      },
    ],
    updateSelect: (formData, keys, value) =>
      updateCheckListValues(formData, updateFormData, keys, {
        checkbox: value,
      }),
    updateValues: (formData, keys, values) =>
      updateCheckListValues(formData, updateFormData, keys, {
        limit: values[0],
        retention: values[1],
      }),
    formatData: formData =>
      nestedCheckListValues(formData, ignoreList.formData, true),
    formData,
  }
}
