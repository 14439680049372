import React from 'react'

const appCueId = user => `${process.env.REACT_APP_ENV}-${user.id}`

export const Appcues = ({ children, tag, className = '' }) => {
  return <div className={`${tag} ${className}`}>{children}</div>
}

export default {
  page: () => {
    if (window.Appcues) {
      window.Appcues.page()
    }
  },
  identify: user => {
    if (window.Appcues) {
      window.Appcues.identify(appCueId(user), {
        email: user.email,
        displayName: user.fullName,
        firstName: user.firstName,
        type: user.company.type,
        company: user.company,
        panelIds: user.company?.panels?.nodes?.map(panel => panel.id).join(','),
        env: process.env.REACT_APP_ENV,
        onboarding: user.onboarding,
        activationDate: user.termsAndConditionsAcceptedAt,
      })
    }
  },
  track: event => window.Appcues && window.Appcues.track(event),
  show: flowId => window.Appcues && window.Appcues.show(flowId),
}
