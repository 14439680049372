import React from 'react'
import { Subtitle, Divider, Div } from 'atoms'

const SubtitleWidget = ({ schema, uiSchema }) => {
  const { 'ui:styles': styles = {} } = uiSchema
  const { divider, fontSize, fontWeight } = styles
  return (
    <Div mb={'-16px'}>
      <Subtitle
        m={'0px'}
        fontWeight={fontWeight || ''}
        fontSize={fontSize || '24px'}>
        {schema.title?.toUpperCase()}
      </Subtitle>
      {divider && <Divider />}
    </Div>
  )
}

export default SubtitleWidget
