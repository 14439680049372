import { useState, useCallback } from 'react'
import { clamp } from 'ramda'

const useScrollSync = () => {
  const [subscribers, setSubscribers] = useState([])
  const [atStart, setAtStart] = useState(true)
  const [atEnd, setAtEnd] = useState(true)
  const [timer, setTimer] = useState(null)

  let listening = true

  let ticking = false

  const observer = new ResizeObserver(entries => {
    setStartAndEnd(entries[0].target)
  })

  const setStartAndEnd = element => {
    const offset = element.scrollLeft
    const max = element.scrollWidth - element.clientWidth
    setAtStart(offset === 0)
    setAtEnd(offset === max)
  }

  const subscribe = useCallback(ref => {
    observer.observe(ref.current)
    setSubscribers(subscribers => [...subscribers, ref])
  }, [])

  const onScroll = useCallback(
    event => {
      if (!listening) return
      // Race condition if element was destroyed before callback happens
      if (!event || !event.target) return
      // Don't handle bubbled scroll events
      if (!subscribers.find(subscriber => subscriber.current == event.target))
        return

      const target = event.target
      const offset = target.scrollLeft
      setStartAndEnd(target)
      if (!ticking) {
        window.requestAnimationFrame(() => {
          subscribers.forEach(subscriber => {
            if (subscriber.current !== target) {
              subscriber.current.scrollLeft = offset
            }
          })
          ticking = false
        })
        ticking = true
      }
    },
    [subscribers, setAtStart, setAtEnd]
  )

  const adjustScroll = useCallback(
    adjustment => () => {
      const max =
        subscribers[0].current.scrollWidth - subscribers[0].current.clientWidth
      const newPos = clamp(
        0,
        max
      )(subscribers[0].current.scrollLeft + adjustment)
      listening = false
      subscribers.forEach(sub =>
        sub.current.scrollTo({ left: newPos, behavior: 'smooth' })
      )
      if (timer) {
        clearTimeout(timer)
      }
      setTimer(
        setTimeout(() => {
          listening = true
          setStartAndEnd(subscribers[0].current)
        }, 800)
      )
    },
    [subscribers]
  )

  return { subscribe, onScroll, atStart, atEnd, adjustScroll }
}

export default useScrollSync
