import { colors } from 'components/theme'

export const unansweredDataTestId = (isUnanswered, firmup) => {
  return isUnanswered && firmup ? 'unanswered-question-warning-label' : null
}

export const unansweredStyle = (isUnanswered, firmup) => {
  return isUnanswered && firmup
    ? {
        paddingLeft: '10px',
        borderLeft: `3px solid ${colors.error}`,
      }
    : {}
}
