import { useRef, useEffect } from 'react'
import { useFormState, useForm } from 'react-final-form'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

import { deepDiff } from 'util/objectUtil'
import { isPresent } from 'util/langUtil'

const noop = () => {}

const format = value => {
  if (value instanceof Date) {
    return value.toISOString()
  }
  return value
}

const computeMinimallyDirtySet = dirtyFields => {
  return dirtyFields.reduce((minimal, field) => {
    // If there is a dirty field higher in the tree, use that when
    // merging.
    if (dirtyFields.find(otherField => field.startsWith(`${otherField}.`))) {
      return minimal
    } else return [field, ...minimal]
  }, [])
}

// Merge data without reintroducing items that have been deleted or that are
// incomplete (no ID)
export const mapOfRemaining = current =>
  current.reduce((acc, item) => {
    if (Object.hasOwn(item, 'id') && !item._destroy) {
      acc[item.id] = item
    }
    return acc
  }, {})

export const mergeRecords = (newRecords, currentRecords) => {
  if (!newRecords) return

  if (Object.hasOwn(currentRecords[0], 'id')) {
    return mergeIdRecords(newRecords, currentRecords)
  } else {
    // If we don't have IDs on the records, we just stick with the current
    // records and let final form trigger an update if that's still dirty
    // because trying to dedupe based on order is far too unreliable.
    if (isEqual(newRecords, currentRecords)) {
      return newRecords
    } else {
      return currentRecords
    }
  }
}

const mergeIdRecords = (newRecords, currentRecords) => {
  const remainingCurrentRecords = mapOfRemaining(currentRecords)
  return Object.values(
    newRecords.reduce((acc, newRecord) => {
      if (newRecord.id) {
        const currRecord = acc[newRecord.id] || {}
        acc[newRecord.id] = {
          ...newRecord,
          ...currRecord,
        }
      }
      return acc
    }, remainingCurrentRecords)
  )
}

export const useNewValue = newVal => newVal

// If the previous value was undefined (the default for blank
// fields) and the new value from the server is falsy but not
// undefined, we want to set it to undefined. This is to match the
// final form dirty tracking which casts empty strings to
// undefined when initializing the form.
const coalesceUndefineds = (update, previousState) => {
  if (Array.isArray(update)) {
    return update.reduce((changedFields, newValue, index) => {
      const previousValue = previousState && previousState[index]
      if (typeof newValue === 'object' && newValue !== null) {
        changedFields.push(coalesceUndefineds(newValue, previousValue))
      } else {
        changedFields.push(newValue)
      }
      return changedFields
    }, [])
  } else {
    return Object.entries(update).reduce((changedFields, [key, newValue]) => {
      const previousValue = previousState && previousState[key]
      if (previousValue === undefined && !newValue) {
        changedFields[key] = undefined
      } else {
        if (typeof newValue === 'object' && newValue !== null) {
          changedFields[key] = coalesceUndefineds(newValue, previousValue)
        } else {
          changedFields[key] = newValue
        }
      }
      return changedFields
    }, {})
  }
}

const saveFn = function ({
  previousValues,
  modified,
  values,
  save,
  getErrors,
  getFormData,
  form,
  dirtyResolvers,
  onError,
  onSave,
}) {
  // We need to set previousValues.current to the latest form values here
  // so that the code can tell the difference between when a user switched back
  // to the original values before or after the debounced save was called.
  previousValues.current = { ...values }

  const originalMinimalDirtySet = computeMinimallyDirtySet(
    Object.keys(form.getState().dirtyFields)
  )
  const attributes = Object.keys(modified).reduce((acc, key) => {
    if (!get(acc, key)) {
      set(acc, key, null)
    }
    return acc
  }, {})
  merge(attributes, values)
  return save(attributes).then(result => {
    const errors = getErrors(result)
    if (errors) {
      form.mutators.setErrors(errors)
    } else {
      const newValues = cloneDeep(getFormData(result))
      const formState = form.getState()

      // Merge any changes since the request was sent back in to the
      // response so the user doesn't lose recent work.
      const currentMinimalDirtySet = computeMinimallyDirtySet(
        Object.keys(formState.dirtyFields)
      )

      originalMinimalDirtySet.forEach(field => {
        if (!currentMinimalDirtySet.includes(field)) {
          // if a field was in the originalMinimalDirtySet,
          // but not in the currentMinimalDirtySet, that means
          // dirty fields were reverted back to their original values
          // by the user while request was in flight, which still makes
          // them dirty.
          currentMinimalDirtySet.push(field)
        }
      })

      currentMinimalDirtySet.forEach(field => {
        const currentValue = get(formState.values, field)
        // If there's a custom resolver passed in via props, we use that
        // to merge the two values. Otherwise we'll prefer the value from
        // the server at the risk of losing edits to the same field made
        // during the request.
        const genericFieldName = field.replace(/\d+/g, '*')
        const providedResolver = dirtyResolvers[genericFieldName]
        if (providedResolver) {
          const newValue = get(newValues, field)
          const mergedVal = providedResolver(newValue, currentValue)
          if (mergedVal) {
            set(newValues, field, mergedVal)
          }
        } else {
          if (
            Array.isArray(currentValue) &&
            typeof currentValue[0] === 'object'
          ) {
            set(
              newValues,
              field,
              mergeRecords(get(newValues, field), currentValue)
            )
          } else {
            set(newValues, field, format(currentValue))
          }
        }
      })

      const update = coalesceUndefineds(newValues, formState.values)

      // Finally, we can reinitialize the form with the merged, cleaned
      // response data.
      form.initialize(update)
      onSave()
    }
  }, onError)
}

const useAutoSave = ({
  debounce: debounceTime = 1000,
  save,
  getErrors,
  getFormData,
  dirtyResolvers = {},
  onSave = noop,
  onError = noop,
}) => {
  const previousValues = useRef(null)
  const debouncedFn = useRef(debounce(saveFn, debounceTime))
  const { values, modified, pristine } = useFormState({
    subscribe: {
      values: true,
      modified: true,
      pristine: true,
    },
  })
  const form = useForm()

  useEffect(() => {
    if (previousValues.current === null) {
      previousValues.current = { ...values }
      return
    }

    const diff = deepDiff(previousValues.current, values)
    const isDifferent = isPresent(diff)

    // when the save mutation goes off, the server will send a new updatedAt
    // value, which will re-trigger this effect. we don't want to fire off another save
    // so just set the updated previousValues, and return
    const diffKeys = Object.keys(diff)
    if (diffKeys.length === 1 && diffKeys[0] === 'updatedAt') {
      previousValues.current = { ...values }
      return
    }

    // A user changed a field, and changed it back
    // before the debounced save was called, so cancel the debounce and return.
    if (!isDifferent) {
      debouncedFn.current.cancel()
      return
    }

    // If a user changed a field and changed it back to the original value
    // *after* the debounced save was called, then isDifferent would be true,
    // since previousValues.current is set to the latest form values inside the
    // save function.
    debouncedFn.current({
      form,
      previousValues,
      modified,
      values,
      save,
      getErrors,
      getFormData,
      dirtyResolvers,
      onError,
      onSave,
    })
  }, [modified, pristine, values])
}

export default useAutoSave
