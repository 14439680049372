import React from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useFieldArray } from 'react-final-form-arrays'
import { faUpload } from '@fortawesome/pro-solid-svg-icons'

import { Flex, Column, Text, Icon } from 'atoms'

const Container = styled(Column)`
  min-height: 100px;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 16px;
  color: ${props => props.theme.colors.primaryTextLight};
  border: 1px dotted ${props => props.theme.colors.link};
  background: ${props => props.theme.colors.white};
  box-sizing: border-box;
  border-radius: 0.25rem;
`

const DropzoneTarget = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  cursor: pointer;
  outline: none;
`

const DropzoneArea = ({ name, onUpload, dropzoneOptions, ...props }) => {
  const { fields } = useFieldArray(name)
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: accepted => onUpload(accepted, fields),
    ...dropzoneOptions,
  })
  return (
    <Container {...props}>
      <DropzoneTarget {...getRootProps()}>
        <input data-testid="file-input" {...getInputProps()} />
        <Flex flexDirection="row" justifyContent="center" color="link">
          <Icon icon={faUpload} fontSize="large" />
          <Text ml={0} color="link">
            Drag and Drop all Documents here
          </Text>
        </Flex>
      </DropzoneTarget>
    </Container>
  )
}

export default DropzoneArea
