import React from 'react'
import {
  TextField,
  useMediaQuery,
  ListSubheader,
  Typography,
  Box,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { VariableSizeList } from 'react-window'
import { unansweredDataTestId, unansweredStyle } from './utils'
import { useSubmission, usePanel } from 'hooks'
import { Panel } from 'atoms'

const LISTBOX_PADDING = 8 // px

function renderRow(props) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})
OuterElementType.displayName = 'OuterElementType'

function useResetCache(data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

const Virtualize = ({
  schema,
  uiSchema,
  value,
  onChange,
  onInputChange,
  label,
  rawErrors,
  required,
  id,
  options: uiOptions,
}) => {
  const classes = useStyles()
  const submission = useSubmission()
  const panel = usePanel()
  const freeSolo = !!schema['freeSolo']

  const isUnanswered = !value
  const { 'ui:firmup': firmup = false } = uiSchema

  const dataTestId = unansweredDataTestId(isUnanswered, firmup)
  const style = unansweredStyle(isUnanswered, firmup)

  const handleChange = (event, selected, reason) => {
    if (reason === 'clear' && freeSolo && schema['clearValue'] !== undefined) {
      onChange(schema['clearValue'])
    } else {
      onChange(selected?.value)
    }
  }
  const handleInputChange = (event, value) => {
    onChange(value)
  }

  const { 'ui:styles': styles = {} } = uiSchema
  const { alignSelf, width } = styles

  const handleEnterPressed = (event, options) => {
    // TODO: Investigate whether react-window can automatically open up to
    // previously selected value - there is a small bug where the user has to
    // mouseOver the list if the previous value is outside of the original
    // "window", otherwise there is no textValue as there is no element with
    // data-focus=true

    if (event.key == 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      const textValue =
        document.querySelectorAll('[data-focus=true]')[0]?.children[0]
          ?.innerText
      if (textValue === undefined) return

      const selectedValue = options.find(
        opt => opt.value === textValue || opt.label === textValue
      )
      onChange(selectedValue?.value)
    }
  }

  const showWarning = () => {
    if (uiOptions.warningType === 'submitAdditional') {
      const submissionVal = submission?.questions[id.replace('root_', '')]
      const apiUsers = submission.participations.nodes
        .filter(p => p.apiUser)
        .map(p => p.apiUser.id)

      const existingAnswer = !!submissionVal && submissionVal !== value
      const prevUnanswered =
        !submissionVal &&
        value &&
        (schema.carriers.includes('ALL') ||
          !!panel.panelists.nodes
            .filter(el => apiUsers.includes(el.id))
            .find(el => schema.carriers?.includes(el.firstName.toLowerCase())))

      return !!existingAnswer || !!prevUnanswered
    } else {
      return false
    }
  }
  const renderInput = params => (
    <TextField
      {...params}
      error={rawErrors?.length > 0}
      required={required}
      variant="outlined"
      label={label}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
    />
  )

  const renderOption = option => <Typography noWrap>{option.label}</Typography>
  const getOptionLabel = option =>
    typeof option === 'string' ? option : option.label

  if (freeSolo) {
    const optionNames = schema.optionNames || schema.option
    const options = schema.option.map((value, index) => ({
      value,
      label: optionNames[index],
    }))
    const selected = options.find(opt => opt.value === value)
    return (
      <Box data-testid={dataTestId} style={style}>
        <Autocomplete
          freeSolo
          disableListWrap
          inputValue={value || ''}
          value={selected || value || null}
          onInputChange={handleInputChange}
          onChange={handleChange}
          onKeyUp={event => handleEnterPressed(event, options)}
          classes={classes}
          ListboxComponent={ListboxComponent}
          options={options}
          renderInput={renderInput}
          renderOption={renderOption}
          getOptionLabel={getOptionLabel}
          style={{ width: width || 'auto', alignSelf: alignSelf || 'auto' }}
        />
        {showWarning() && (
          <Panel info gradient={25} m="1rem" p="1rem">
            {uiOptions.warningMsg}
          </Panel>
        )}
        {/* Add an invisible input to support the form filler extension */}
        <input
          id={id}
          style={{ display: 'none' }}
          value={value}
          onChange={evt => {
            onChange(evt.target.value)
          }}
        />
      </Box>
    )
  } else {
    const enumNames = schema.enumNames || schema.enum
    const options = schema.enum.map((value, index) => ({
      value,
      label: enumNames[index],
    }))
    const selected = options.find(opt => opt.value === value)
    return (
      <Box data-testid={dataTestId} style={style}>
        <Autocomplete
          disableListWrap
          value={selected || null}
          onChange={onInputChange === undefined ? handleChange : onChange}
          onInputChange={onInputChange}
          onKeyUp={event => handleEnterPressed(event, options)}
          classes={classes}
          ListboxComponent={ListboxComponent}
          options={options}
          renderInput={renderInput}
          renderOption={renderOption}
          getOptionLabel={getOptionLabel}
          style={{ width: width || 'auto', alignSelf: alignSelf || 'auto' }}
        />
        {showWarning() && (
          <Panel info gradient={25} m="1rem" p="1rem">
            {uiOptions.warningMsg}
          </Panel>
        )}
        {/* Add an invisible input to support the form filler extension */}
        <input
          id={id}
          style={{ display: 'none' }}
          value={value}
          onChange={evt => {
            onChange(evt.target.value)
          }}
        />
      </Box>
    )
  }
}

export default Virtualize
