import i18next from 'i18next'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'
import { nonProdThrowError } from '../util/debug'

import { resources } from '../locales'

export default i18next
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    resources,
    debug: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (format === 'uppercase') return value.toUpperCase()
        if (value instanceof Date) return moment(value).format(format)
        return value
      },
    },
    react: {
      wait: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
      useSuspense: true,
    },
    saveMissing: true,
    updateMissing: true,
    appendNamespaceToMissingKey: true,
    returnEmptyString: false,
    missingKeyHandler: (lng, ns, key) => {
      const matchingFiles = key.startsWith('errors')
        ? 'errors.translations.js'
        : 'translations.json'

      nonProdThrowError(
        `Missing translation key: '${key}', please add entry to ${matchingFiles}`
      )
    },
  })
  .then(translate => {
    window.i18n = translate
    return translate
  })
  .catch(nonProdThrowError)
