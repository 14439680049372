import React, { useState } from 'react'
import { Div } from 'atoms'

import useOnClickOutside from 'hooks/onClickOutside'
import MenuItem from './MenuItem'
import Menu from './Menu'
import { addPropsToChildElements } from 'util/addPropsToChildElements'

const MenuWithRef = React.forwardRef(
  ({ ItemComponent, options, right, onClose, ...props }, ref) => (
    <Menu
      ref={ref}
      onClose={onClose}
      options={options}
      right={right}
      ItemComponent={ItemComponent}
      {...props}
    />
  )
)

const GenericContextMenu = ({
  options,
  right = false,
  itemComponent = MenuItem,
  children,
  ...style
}) => {
  const [showMenu, toggleShow] = useState(false)
  const ref = useOnClickOutside(() => toggleShow(false))

  const content = addPropsToChildElements({
    children,
    props: {
      onClick: e => {
        toggleShow(!showMenu)
        e.stopPropagation()
      },
    },
  })

  MenuWithRef.displayName = 'MenuWithRef'

  return (
    <Div {...style}>
      {content}
      {showMenu && (
        <MenuWithRef
          onClose={() => toggleShow(false)}
          ItemComponent={itemComponent}
          ref={ref}
          options={options}
          right={right}
        />
      )}
    </Div>
  )
}

export default GenericContextMenu
