import { fonts, colors } from './'

export default {
  container: {
    fontFamily: fonts.primary,
    color: colors.primaryText,
  },
  sectionHeader: {
    fontFamily: fonts.primary,
    color: colors.primaryText,
  },
  navBar: {
    borderColor: colors.lightGray,
  },
  input: {
    padding: '7px 0',
    boxSizing: 'border-box',
    fontFamily: fonts.secondary,
    fontWeight: 300,
    fontSize: '18px',
    color: colors.primaryText,
    outline: 'none',
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderColor: colors.secondaryYellow,
    background: 'initial',
  },
  button: {
    minWidth: '140px',
    height: '50px',
    fontFamily: fonts.secondary,
    fontWeight: 600,
    fontSize: '16px',
    color: colors.primaryTextDark,
    background: colors.secondaryYellow,
    border: colors.secondaryYellow,
    borderRadius: '24px',
    boxSizing: 'border-box',
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    lineHeight: '24px',
    padding: '0 1rem',
    whiteSpace: 'nowrap',
    textTransform: 'none',
  },
  oAuthSignInButton: {
    height: '50px',
    width: 'auto',
    margin: 'auto',
    fontFamily: fonts.secondary,
    fontWeight: 600,
    fontSize: '16px',
    color: colors.primaryTextDark,
    background: colors.white,
    border: `1px solid ${colors.primaryTextLight}`,
    borderRadius: '24px',
    boxSizing: 'border-box',
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    lineHeight: '24px',
    padding: '0 2rem',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    marginBottom: '1rem',
  },
  signInButtonContent: {
    padding: 0,
  },
  toast: {
    backgroundColor: colors.toast.info.bg,
    color: colors.toast.info.text,
    fontFamily: fonts.secondary,
    width: 'auto',
    fontSize: '16px',
    position: 'fixed',
    top: '1em',
    right: '1em',
    left: 'auto',
  },
  a: {
    color: colors.link,
  },
  strike: {
    display: 'none',
  },
  // navButton: {
  //   backgroundColor:
  //   color:
  // }
}
