import { useEffect } from 'react'
import find from 'lodash/find'
import { useLocation } from 'react-router'

export const setUser = user => {
  if (user && window.zE) {
    window.zE('webWidget', 'clear')
    window.zE('webWidget', 'identify', {
      name: user.fullName,
      email: user.email,
    })

    window.zE('webWidget', 'prefill', {
      name: { value: user.fullName },
      email: { value: user.email },
    })

    window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'the' })
  }
}

export const injectZendesk = key => {
  const zendeskForms = process.env.REACT_APP_ZENDESK_TICKET_FORMS

  if (zendeskForms) {
    window.zESettings = {
      webWidget: {
        contactForm: {
          ticketForms: zendeskForms.split(',').map(id => ({ id })),
        },
      },
    }
  }

  // Get the first script element on the page
  const ref = window.document.getElementsByTagName('script')[0]

  // Create a new script element
  const script = window.document.createElement('script')

  // Set the script element `src`
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`
  script.id = 'ze-snippet'

  // Inject the script into the DOM
  ref.parentNode.insertBefore(script, ref)
}

const ROUTE_ARTICLE_KEYWORDS = [
  { key: /submissions$/i, value: 'overview property casualty report' },
  {
    key: /submissions.*\/setup/i,
    value: 'overview submission extract line of business',
  },
  { key: /submissions.*\/policy/i, value: 'overview submission' },
  { key: /submissions.*\/coverage-requested/i, value: 'submission' },
  { key: /submissions.*\/underlying-coverage/i, value: 'submission' },
  { key: /submissions.*\/reinsurance-structure/i, value: 'submission' },
  { key: /submissions.*\/supporting-documents/i, value: 'submission' },
  { key: /submissions.*\/invite-markets/i, value: 'invite' },
  { key: /submissions.*\/summary/i, value: 'submission overview' },
  { key: /submissions\/[0-9]+/i, value: 'accept quote' },
  { key: /submissions.*\/quotes\/[0-9]+/i, value: 'accept quote' },
  { key: /manage.*\/permission/i, value: 'permission' },
  { key: /manage.*\/team/i, value: 'team permission' },
  { key: /\/markets/i, value: 'markets approval' },
  { key: /plan_details/i, value: 'billing' },
  { key: /plan_options/i, value: 'billing' },
  { key: /\/reports/i, value: 'report' },
  { key: /\/reports.*statsAndTrends/i, value: 'report' },
  { key: /\/reports.*topMarkets/i, value: 'exposure report' },
  { key: /\/reports.*marketExposure/i, value: 'exposure report' },
  { key: /\/integrate/i, value: 'integrate' },
  { key: /.*/i, value: 'the' },
]

export const mapRouteToKeywords = pathname =>
  find(ROUTE_ARTICLE_KEYWORDS, ({ key }) => pathname.match(key)).value

export const HelpCenterPrimer = () => {
  const location = useLocation()

  useEffect(() => {
    if (!window.zE) return

    const keyword = mapRouteToKeywords(location.pathname)

    window.zE('webWidget', 'helpCenter:setSuggestions', {
      search: keyword || 'the',
    })
  }, [location])

  return null
}

export const helpLink =
  'https://relayplatformhelp.zendesk.com/hc/en-us/requests/new?ticket_form_id=360001914734'
