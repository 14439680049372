import styled from 'styled-components'
import { colors, fonts } from '../../theme'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  width: ${props => (props.stretch ? 'auto' : '512px')};
  justify-content: space-between;
`

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${props => (props.stretch ? 'auto' : '512px')};
  justify-content: space-between;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 1 auto;
  width: ${props => (props.wide ? '100%' : '50%')};

  &:nth-child(2) {
    margin-left: 1rem;
  }
`
const SubRow = styled(Row)`
  margin-top: 0px;
  width: ${props => props.wide && '100%'};
`

const ButtonBox = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Content = styled.div`
  margin-left: 4rem;
  margin-right: 4rem;
  display: flex;
  flex-direction: column;
  flex: auto;
`

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.secondary};
  font-size: 14px;
  color: ${colors.message};
  margin-bottom: 1em;
`

const MultiItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

const Title = styled.h2`
  font-family: ${fonts.secondary};
  font-size: 24px;
  font-weight: normal;
  color: ${colors.primary};
  font-weight: 600;
  margin-bottom: 0;
`

export { NavItem, SideNav, LeftSideBar, RightSideBar, SubNavItem } from 'atoms'

export {
  Layout,
  Content,
  Row,
  TopRow,
  Column,
  ButtonBox,
  SubRow,
  Info,
  MultiItemContainer,
  Title,
}
