import React from 'react'
import { Typography } from '@material-ui/core'

import { Flex } from 'atoms'

const Label = ({ value, uiSchema }) => {
  const styles = uiSchema['ui:styles'] || {}
  return (
    <Flex alignItems="center" height="40px" {...styles}>
      <Typography>{value}</Typography>
    </Flex>
  )
}
export default Label
