import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'

import { Row } from 'atoms'

const NavItem = styled(NavLink).attrs({
  activeClassName: 'active',
})`
  font-family: ${props => props.theme.fonts.primary};
  font-size: 16px;
  color: ${props => props.theme.colors.black};
  padding: 10px 30px 3px 0;
  margin-bottom: 20px;
  text-decoration: none;
  transform: translateX(2px);
  display: block;
  transition: all 0.05s ease-in-out;

  &.active {
    border-right: 3px solid ${props => props.theme.colors.link};
    font-weight: 600;
    cursor: default;
  }

  &:hover {
    font-weight: 600;
  }

  &[disabled],
  &.disabled {
    color: ${props => props.theme.colors.navDisabled};
  }

  &[disabled]:hover,
  &.disabled {
    font-weight: 400;
    cursor: default;
  }
`
export const SubNavItem = styled(NavItem)`
  margin-bottom: 5px;
  &:first-child {
    margin-top: -15px;
  }
`
const NavItemTriangle = styled(NavItem)`
  color: ${props => props.theme.colors.white};
  padding: 10px;
  transform: none;
  height: 40px;
  width: 99%;
  margin-bottom: 0;
  &.active {
    border-right: none;
    background-color: ${props => props.theme.colors.navActiveBlue};
  }
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 23px solid transparent;
  border-bottom: 23px solid transparent;
  border-left: 15px solid ${props => props.theme.colors.navActiveBlue};
`

export const NavItemWithTriangle = props => {
  const location = useLocation()
  const [active, setActive] = useState(location.pathname === props.to)
  useEffect(() => setActive(location.pathname === props.to))
  return (
    <Row
      mb="0"
      mr={active ? '-14px' : '0px'}
      alignItems="center"
      justifyContent="space-between">
      <NavItemTriangle {...props} />
      {active && <Triangle />}
    </Row>
  )
}

export default NavItem
