import React from 'react'
import gql from 'graphql-tag'
import orderBy from 'lodash/orderBy'

import Message from 'molecules/Message'

const Messages = ({ messages, discussionParticipants }) => {
  const userToLastMsgId = {}
  if (discussionParticipants) {
    for (const dp of discussionParticipants.nodes) {
      userToLastMsgId[dp.participant.id] = dp.lastReadMessageId
    }
  }

  return orderBy(messages, ['timestamp'], ['desc']).map(message => (
    <Message
      data-scroll={message.id}
      key={message.id}
      message={message}
      userToLastMsgId={userToLastMsgId}
    />
  ))
}

export const MessagesFragment = gql`
  fragment Messages on Discussion {
    messages(order: "DESC") {
      nodes {
        ...Message
      }
    }
  }
  ${Message.fragment}
`

export default Messages
