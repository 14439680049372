import React from 'react'
import { Flex, $, Text, Div } from 'atoms'
import styled from 'styled-components';

const StyledText = styled(Text)`
  font-weight: bold;
  display: block;
  line-height: 1;
  margin-bottom: 2px;
  margin-top: ${props => props.paddingTop ? '5' : '0'}px;
`

const COLUMNS = {
  LIMIT: "limit",
  DEDUCTIBLE: "deductible",
  PREMIUM: "premium",
}

const Fallback = () => (
  <Flex>
    <Flex flexBasis="50%">—</Flex>
  </Flex>
)


const LimitDeductibleAndPremiumRenderer = ({ children = {}, criterion }) => {
  const fallback = (
      <Fallback />
    )
  if (!children) return fallback
  const { limit, deductible, premium } = children
  const columns = criterion?.columns || []
  const components = []

  let limits = limit?.map((x, index) => (
    <Div key={index}>
      {x?.appliesTo && <StyledText paddingTop={index !== 0}>{x.appliesTo}</StyledText>}
      <$ fallback="—">{x?.amount}</$>
    </Div>
  ))

  if (!limits || limits.length === 0) {
    limits = '—'
  }

  let deductibles = deductible?.map((x, index) => (
    <Div key={index}>
        <$ fallback="—">{x?.amount}</$>
    </Div>
  ))

  if (!deductibles || deductibles.length === 0) {
    deductibles = '—'
  }

  const basis = `${100 / columns.length}%`

  if (columns.includes(COLUMNS.LIMIT)){
    components.push(
      <Flex flexBasis={basis} key={'limit'}>
        <span>{limits || 'Limit Applies Per Endorsement'}</span>
      </Flex>
    )
  }

  if (columns.includes(COLUMNS.DEDUCTIBLE)){
    components.push(
      <Flex flexBasis={basis} key={'deductible'}>
        <span>{deductibles}</span>
      </Flex>
    )
  }

  if (columns.includes(COLUMNS.PREMIUM)){
    components.push(
      <Flex flexBasis={basis} key={'premium'}>
        <$ fallback="—">{premium}</$>
      </Flex>
    )
  }

  return (
    <Flex>
      {components}
    </Flex>
  )
}

export default LimitDeductibleAndPremiumRenderer
