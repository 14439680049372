export function formatter(value) {
  if (!value) return ''
  return value && /^(100|[0-9]{0,2}(\.[0-9]*)?|\.[0-9]+)$/.test(value)
    ? `${value}%`
    : ''
}
export function normalizer(value) {
  if (!value) return ''
  return value && /^(100|[0-9]{0,2}(\.[0-9]*)?|\.[0-9]+)%?$/.test(value)
    ? value.match(/^(100|[0-9]{0,2}(\.[0-9]*)?|\.[0-9]+)/g)[0]
    : ''
}
