import React from 'react'
import { Form, Field } from 'react-final-form'
import { toast } from 'react-toastify'
import {
  EnhancedModal,
  Subtitle,
  Div,
  Text,
  ButtonContainer,
  Button,
} from 'atoms'
import { TextArea, MultiSelect } from 'atoms/form'
import { required, length } from 'util/validators'
import { useForwardDocumentMutation } from 'graphql/api'

import { useSubmission } from 'hooks'

const ForwardModal = ({ onClose, file }) => {
  const submission = useSubmission()
  const [forwardDocument] = useForwardDocumentMutation()
  const participations = submission.participations.nodes

  const authorizedInsurers = participations
    .filter(contact => contact.user)
    .map(contact => {
      return { label: `${contact.user.fullName}`, value: contact }
    })

  const handleSubmit = ({ message, file, participations }) => {
    forwardDocument({
      variables: {
        input: {
          participantIds: participations.map(
            participation => participation.value.id
          ),
          message: message,
          documentId: file.id,
        },
      },
    }).then(({ data }) => {
      if (data.forwardDocument.errors) {
        toast.error(data.forwardDocument.errors)
      } else {
        toast.success(
          `You have successfully forwarded your document to ${
            data.forwardDocument.participations.length
          } recipient${data.forwardDocument.participations.length && 's'}`
        )
        onClose()
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={{ message: '', file: file }}>
      {({ handleSubmit }) => (
        <EnhancedModal onClose={onClose}>
          <Div p={2}>
            <Subtitle>Select Recipients</Subtitle>
            <Text>Select recipients to send the document to.</Text>
            <Field
              name="participations"
              mr={1}
              required
              isValidNewOption={() => false}
              validate={length()}
              component={MultiSelect}
              options={authorizedInsurers}
            />
            <Field
              component={TextArea}
              name="message"
              label="Your Message"
              required
              validate={required()}
              my={2}
            />
            <ButtonContainer justifyContent="space-between">
              <Button onClick={onClose} light>
                Cancel
              </Button>
              <Button onClick={handleSubmit} primary>
                Send Now
              </Button>
            </ButtonContainer>
          </Div>
        </EnhancedModal>
      )}
    </Form>
  )
}

export default ForwardModal
