/*

Note: If any fonts are being changed here, please also reflect
those changes in ~/web/src/components/forms/react-datepicker.css

*/
import './fonts.less'

export default {
  primary: `'Source Sans Pro', sans-serif`,
  secondary: `'Source Sans Pro', sans-serif`,
}
