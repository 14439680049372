import React from 'react'

import { Column, Row, ToggleLink } from 'atoms'
import Field from 'molecules/Field'
import { Coverages } from 'pages/shared/panels'
import { money } from 'atoms/$'
import { formatDate } from 'util/date'
import { useToggle } from 'hooks'
import DocumentsTable from 'molecules/DocumentUpload/DocumentsTable'

export const getSections = () => [
  { title: 'Insuring Agreements & Sublimits', type: 'InsuringAgreement' },
]

export const getCoverages = quotation => {
  const insuringAgreements = quotation.insuringAgreements.map(element => ({
    quote_id: quotation.id,
    name: element.name,
    col1: element.value,
    type: 'InsuringAgreement',
  }))
  return [...insuringAgreements]
}

const QuoteContent = ({ quotation }) => {
  const [showDetails, toggleDetails] = useToggle(false)

  return (
    <>
      <Row mt={2}>
        <Field
          label="Limit"
          value={money(quotation.requestedLimit)}
          width="33.3%"
        />
        <Field
          label="Deductible"
          value={money(quotation.requestedDeductible)}
          width="33.3%"
        />
        <Field
          label="Admitted?"
          value={quotation.admitted ? 'Yes' : 'No'}
          width="33.3%"
        />
      </Row>
      <Row mt={2} mb={2}>
        <Field
          label="Cost for insured"
          value={money(quotation.quotedLines[0].clientCost)}
          width="33.3%"
        />
        <Field
          label="Annual Premium"
          value={money(quotation.quotedLines[0].annualPremium)}
          width="33.3%"
        />
        <Field
          label="Taxes"
          value={money(quotation.quotedLines[0].taxAmount)}
          width="33.3%"
        />
      </Row>
      <Row mt={2} mb={2}>
        <Field
          label="Policy Effective Date"
          value={formatDate(
            quotation.quotedLines[0].coverageStartDate,
            'MMMM DD, YYYY'
          )}
          width="33.3%"
        />
        {quotation.quotedLines[0].policyNumber && (
          <Field
            label="Policy Number"
            value={quotation.quotedLines[0].policyNumber}
          />
        )}
      </Row>
      <Column mb={2}>
        <ToggleLink onClick={toggleDetails}>
          {showDetails ? 'Hide' : 'Show'} Insuring Agreements & Sublimits
        </ToggleLink>
        {showDetails && (
          <Row mt={1}>
            <Column pr={2}>
              <Coverages
                coverages={getCoverages(quotation)}
                sections={getSections()}
                emptyLabel="No insuring agreements provided."
                col1Header="Limit"
                col1Type="String"
              />
            </Column>
          </Row>
        )}
      </Column>
      <DocumentsTable documents={quotation.documents} />
    </>
  )
}

export default QuoteContent
