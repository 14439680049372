import React from 'react'
import { Div, Flex } from 'atoms'
import styled from 'styled-components'
const Dots = styled(Div)`
  width: 70%;
  box-sizing: initial;
  border-bottom: dotted 6px;
  border-bottom-color: ${props => props.theme.colors.primaryTextInactive};
`
const DottedTrail = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="40px">
      <Dots />
    </Flex>
  )
}
export default DottedTrail
