import React from 'react'
import { toast } from 'react-toastify'
import { useHistory, useRouteMatch, Route } from 'react-router-dom'
import { faEllipsisV, faFileImport } from '@fortawesome/pro-regular-svg-icons'

import AddQuestionModal from 'components/QAndA/Question/AddQuestionModal'
import AddFollowUpQuestionModal from 'components/QAndA/Question/AddFollowUpQuestionModal'
import EditQuestionModal from 'components/QAndA/Question/EditQuestionModal'
import ImportModal from 'components/QAndA/Import/ImportModal'
import { GenericContextMenu } from 'molecules/ContextMenu'

import QAndA from 'components/QAndA/QAndA'
import { Panel, Flex, Button, Loading, Icon } from 'atoms'
import { useQuestionsQuery } from 'graphql/api'

const RequesterQAndA = ({ submission, questionable }) => {
  const { loading, error, data, refetch } = useQuestionsQuery({
    variables: {
      questionableId: questionable.id,
      questionableType: questionable.__typename,
    },
    fetchPolicy: 'cache-and-network',
  })

  const history = useHistory()
  const { path, url } = useRouteMatch()

  if (loading) return <Loading />
  if (error) throw error

  const getAddQuestionContextOptions = () => {
    const options = []
    options.push({
      title: 'Bulk Import Questions',
      icon: faFileImport,
      onClick: () => history.replace(`${url}/import`),
      autoClose: true,
    })
    return options
  }

  const questions = data.qAndA.questionable.questions.nodes
  const addQuestionOptions = getAddQuestionContextOptions(questions)
  return (
    <>
      <Route path={`${path}/questions/:questionId/follow-up`}>
        <AddFollowUpQuestionModal
          initialValues={{
            ownerId: submission.owner.id,
            questionableId: questionable.id,
            questionableType: questionable.__typename,
          }}
          sections={Array.from(
            new Set(questions.map(question => question.section))
          )}
          questions={questions}
          onCompleted={() => {
            history.replace(url)
            toast.success('Your follow-up question has been saved')
          }}
          onClose={() => history.replace(url)}
        />
      </Route>
      <Route path={`${path}/questions/:questionId/edit`}>
        <EditQuestionModal
          sections={Array.from(
            new Set(questions.map(question => question.section))
          )}
          questions={questions}
          onCompleted={() => {
            history.replace(url)
            toast.success('Your question has been saved')
          }}
          onClose={() => history.replace(url)}></EditQuestionModal>
      </Route>
      <Route path={`${path}/questions/new`}>
        <AddQuestionModal
          sections={Array.from(
            new Set(questions.map(question => question.section))
          )}
          initialValues={{
            ownerId: submission.owner.id,
            questionableId: questionable.id,
            questionableType: questionable.__typename,
          }}
          onCompleted={() => {
            history.replace(url)
            toast.success('Your question has been saved')
          }}
          onClose={() => history.replace(url)}></AddQuestionModal>
      </Route>
      <Route path={`${path}/import`}>
        <ImportModal
          sections={Array.from(
            new Set(questions.map(question => question.section))
          )}
          initialValues={{
            ownerId: submission.owner.id,
            questionableId: questionable.id,
            questionableType: questionable.__typename,
          }}
          onClose={() => history.replace(url)}
          onCompleted={(savedQuestionCount, totalQuestionCount) => {
            refetch().then(() => {
              history.replace(url)
              if (savedQuestionCount == totalQuestionCount) {
                toast.success('Your question has been saved')
              } else {
                toast.error(
                  `${
                    totalQuestionCount - savedQuestionCount
                  } questions failed to save`
                )
              }
            })
          }}
        />
      </Route>
      {questions.length === 0 ? (
        <>
          <Flex mb={1}>
            <Button
              onClick={() => history.push(`${url}/questions/new`)}
              mr={0}
              ml="auto"
              px={2}
              data-testid="add-question-top"
              secondary>
              Add Question
            </Button>
            {addQuestionOptions.length > 0 && (
              <GenericContextMenu right options={addQuestionOptions}>
                <Button
                  mr={0}
                  minWidth={50}
                  height={50}
                  data-testid="import-context-menu"
                  secondary>
                  <Icon icon={faEllipsisV} fontSize="m" />
                </Button>
              </GenericContextMenu>
            )}
          </Flex>
          <Panel p={2} mb={2}>
            <Flex justifyContent="center">
              <Button
                onClick={() => history.push(`${url}/questions/new`)}
                secondary>
                Ask a Question
              </Button>
            </Flex>
          </Panel>
        </>
      ) : (
        <QAndA
          asker={questionable.owner}
          questions={questions}
          questionable={questionable}
          context="asker"
        />
      )}
    </>
  )
}

export default RequesterQAndA
