import styled from 'styled-components'
import { space, flexbox, layout, color } from 'styled-system'

const ExternalLink = styled.a`
  color: ${props => props.theme.colors.link};
  text-decoration: none;
  cursor: pointer;
  ${space}
  ${flexbox}
  ${layout}
  ${color}
`

export default ExternalLink
