import styled from 'styled-components'
import { space, layout, flexbox, color } from 'styled-system'
import Panel from 'atoms/Panel'

const Fieldset = styled.section`
  background-color: ${props => props.theme.colors.secondaryBackground};

  & input,
  & textarea,
  ${Panel} & input,
  ${Panel} & textarea {
    background-color: ${props => props.theme.colors.secondaryBackground};
  }

  font-size: 14px;
  font-weight: 400;
  padding: 2rem;
  border-radius: 0.25rem;

  ${space}
  ${layout}
  ${flexbox}
  ${color}
`
Fieldset.displayName = 'Fieldset'

export default Fieldset
