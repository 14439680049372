import React from 'react'
import styled, { css } from 'styled-components'
import { Field } from 'redux-form'
import { required, exclusion } from 'redux-form-validators'
import { withRouter } from 'react-router-dom'
import { Amplitude } from '@amplitude/react-amplitude'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'

import Button from '../common/Button'
import Error from '../common/Error'
import Uploader, { UploadButton } from 'components/forms/widgets/Uploader'
import { colors } from '../theme'
import { StyledInput } from './Input'
import { Div, Row, Column, Icon, Img } from 'atoms'
import { useCurrentUser, useToggle } from 'hooks'
import { RichText } from 'atoms/form'

const FormContainer = styled.form`
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  &:before,
  &:after {
    content: '';
    display: block;
  }
`

const MessageInput = css`
  border: solid 1px ${colors.secondaryBlue};
  border-radius: 8px;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 1.14;

  padding: 24px;
  color: ${colors.message};

  &:hover,
  &:focus {
    border-color: ${colors.secondaryBlue};
  }
`

const SubjectInput = styled(StyledInput)`
  ${MessageInput}
`

export const SubjectInputWidget = ({ type, input, meta, ...props }) => (
  <Column>
    <SubjectInput
      touched={meta.touched}
      error={meta.error}
      type={type}
      {...input}
      {...props}
    />
    {meta.touched && meta.error && <Error>{meta.error}</Error>}
  </Column>
)

const StyledRichText = props => {
  return (
    <Column mt="1rem">
      <RichText {...props} />
    </Column>
  )
}

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
`

const PreviewContainer = styled(Div)`
  background-color: ${props => props.theme.colors.secondaryBackground};
  border-radius: 0.5rem;
  min-height: 30px;
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
`

const PreviewBtn = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  text-decoration: underline;
  padding: 0px;
`

const SigPreview = () => {
  const [open, toggle] = useToggle(false)
  const currentUser = useCurrentUser()

  return (
    <PreviewContainer>
      {!open ? (
        <Row alignItems="center">
          <Div>
            Your email signature and a confidentiality statement are added
            automatically
          </Div>
          <Icon mx="5px" icon={faQuestionCircle} />
          <PreviewBtn onClick={toggle}>Preview</PreviewBtn>
        </Row>
      ) : (
        <>
          <Row justifyContent="flex-end">
            <PreviewBtn onClick={toggle}>Close Preview</PreviewBtn>
          </Row>
          <Field
            name="signOff"
            type="text"
            component={StyledRichText}
            placeholder="Your email signature"
          />
          {currentUser.company.logoUrl && (
            <Img
              mt="1rem"
              maxWidth="150px"
              src={currentUser.company.emailLogoUrl}
            />
          )}
          <Div fontSize="0.8rem">
            <p>CONFIDENTIAL DOCUMENTS:</p>
            <p>
              The information contained in this document is strictly
              confidential and is intended for the addressee only. The
              unauthorized use, disclosure, copying, alteration or distribution
              of this document is strictly prohibited and may be unlawful.
            </p>
          </Div>
        </>
      )}
    </PreviewContainer>
  )
}

const MessageForm = props => {
  const { handleSubmit, error, attachments } = props
  const hasAttachments = attachments ? attachments.length > 0 : false

  return (
    <FormContainer onSubmit={handleSubmit}>
      {error && <Error>{error}</Error>}
      <Field
        name="subject"
        type="text"
        component={SubjectInputWidget}
        validate={required({ message: 'Please enter a subject' })}
        placeholder="Subject"
      />
      <Field
        name="body"
        type="text"
        component={StyledRichText}
        placeholder="Your message"
        validate={exclusion({
          in: ['', '<p><br></p>'],
          message: 'Please enter a message',
        })}
      />
      <SigPreview />
      <Div width="50%" m="auto">
        <Field name="attachments" component={Uploader} />
      </Div>
      <Amplitude
        eventProperties={{
          hasAttachments: hasAttachments,
        }}>
        {({ instrument }) => (
          <Buttons>
            <Field
              name="attachments"
              component={UploadButton}
              label="Attach Document"
              secondary
            />
            <Button onClick={instrument('SendMessage')} type="submit">
              Send Email
            </Button>
          </Buttons>
        )}
      </Amplitude>
    </FormContainer>
  )
}

export default withRouter(MessageForm)
