import React, { useState, useEffect } from 'react'

import NotFound, { error404 } from 'pages/NotFound'
import SubmissionContext from 'components/contexts/submission'
import { Loading } from 'atoms'
import { usePanelSubmissionQuery } from 'graphql/api'
import { useMultilineTab } from 'hooks/multilineTab'
import { POLL_INTERVALS } from 'panels/constants'
import { getModelForLine } from 'pages/shared/panels/config/utils'

let timeLoop
const SubmissionModelWrapper = ({
  panelId,
  lineOfBusiness,
  submissionId,
  Component,
}) => {
  const [isPolling, setIsPolling] = useState(false)
  const { refetch, data, loading, error } = usePanelSubmissionQuery({
    variables: { submissionId },
    fetchPolicy: 'cache-and-network',
  })
  const currentPanel = useMultilineTab()
  const pollableQuery = queryAction => {
    timeLoop = setTimeout(() => {
      if (isPolling) {
        pollableQuery(queryAction)
      }
    }, POLL_INTERVALS.fast)
    try {
      queryAction()
    } catch (e) {
      clearTimeout(timeLoop)
    }
  }

  useEffect(() => {
    clearTimeout(timeLoop)

    pollableQuery(() => refetch({ variables: { submissionId } }))
  }, [isPolling])

  useEffect(() => {
    refetch({ variables: { submissionId } })
  }, [submissionId])

  if (error) {
    if (error.message === error404) return <NotFound showHeader={false} />

    throw error
  }

  if (!data) return <Loading />
  const SubmissionModel = getModelForLine(lineOfBusiness)
  const submission = new SubmissionModel().build(data.panels.submission)
  if (!submission.hasLoaded(submissionId) || (loading && !data))
    return <Loading showHeader={false} />

  if (data && !data.panels?.submission) return <NotFound showHeader={false} />

  if (!submission.data) return <Loading />

  if (submission?.panelId !== panelId && !currentPanel) {
    return <NotFound showHeader={false} />
  }

  return (
    <SubmissionContext.Provider value={data.panels.submission}>
      <Component submission={submission} setIsPolling={setIsPolling} />
    </SubmissionContext.Provider>
  )
}

export default SubmissionModelWrapper
