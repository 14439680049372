import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Div } from 'atoms'
import { colors } from 'components/theme'
import ThreeDots from 'images/three-dot-dark.svg'
import useOnClickOutside from 'hooks/onClickOutside'
import MenuItem from './MenuItem'
import Menu from './Menu'

const size = ({ size }) => {
  switch (size) {
    case 'small':
      return {
        width: '28px',
        height: '28px',
      }
    default:
      return {
        width: '48px',
        height: '48px',
      }
  }
}

const CircleButton = styled.button`
  ${size}
  border-radius: 50%;
  background-color: ${colors.white};
  border: 4px solid
    ${props =>
      props.bindModal ? colors.primaryTextLight : colors.secondaryYellow};
  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Dots = styled.img`
  color: white;
  height: 80%;
  max-height: 20px;
`

const ContextMenu = ({
  options,
  size,
  bindModal = false,
  right = false,
  itemComponent = MenuItem,
  ...style
}) => {
  const ref = useRef()
  const [showMenu, toggleShow] = useState(false)
  useOnClickOutside(ref, () => toggleShow(false))
  const ItemComponent = itemComponent

  return (
    <Div {...style}>
      <CircleButton
        size={size}
        bindModal={bindModal}
        onClick={e => {
          toggleShow(!showMenu)
          e.preventDefault()
          e.stopPropagation()
        }}>
        <Dots alt="Context Menu" src={ThreeDots} />
      </CircleButton>
      {showMenu && (
        <Menu
          ref={ref}
          onClose={() => toggleShow(false)}
          options={options}
          right={right}
          ItemComponent={ItemComponent}
        />
      )}
    </Div>
  )
}

ContextMenu.displayName = 'ContextMenu'

export default ContextMenu
