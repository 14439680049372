import React, { useState, useEffect } from 'react'

import { Flex, Error, Label, Text } from 'atoms'
import { Input } from 'atoms/form'
import { percentNormalizer } from 'util/fieldNormalizers'

const PARTIAL_CENTS = /\.[0-9]?0?$/

const PercentInput = ({
  input,
  meta,
  label,
  required,
  note,
  disabled,
  bg,
  onKeyUp = () => {},
  autoFocus,
  ...props
}) => {
  const [currentValue, setValue] = useState(input.value)
  const [error, setError] = useState(meta.error || meta.submitError)

  useEffect(() => {
    setValue(Number.isNaN(input.value) ? '' : input.value)
  }, [input.value])

  useEffect(() => {
    if (meta.error || meta.submitError) {
      setError(meta.error || meta.submitError)
    } else {
      setError('')
    }
  }, [meta.error, meta.submitError])

  const handleChange = event => {
    const normalized = percentNormalizer(event.target.value) || ''
    input.onChange(normalized)
    if (normalized.length > 14) {
      setError('The value is too large')
    } else {
      setError('')
    }
    if (PARTIAL_CENTS.test(event.target.value)) {
      setValue(event.target.value)
    } else {
      setValue(normalized)
    }
  }

  const handleBlur = () => {
    const normalized = percentNormalizer(currentValue)
    setValue(normalized)
    if (input.onBlur) {
      return input.onBlur(normalized)
    }
  }

  return (
    <Flex flexDirection="column" {...props} bg={bg}>
      {label && (
        <Label htmlFor={input.name} required={required} note={note}>
          {label}
        </Label>
      )}
      <Flex alignItems="baseline">
        <Input
          autoFocus={autoFocus}
          onChange={handleChange}
          onFocus={input.onFocus}
          onBlur={handleBlur}
          onKeyUp={onKeyUp}
          touched={meta.touched}
          id={input.name}
          error={error}
          value={currentValue}
          disabled={disabled}
          type="text"
          bg={bg}
        />
        <Text as="div">%</Text>
      </Flex>
      {meta.touched && error && <Error>{error}</Error>}
    </Flex>
  )
}

export default PercentInput
