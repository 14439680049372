import React from 'react'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { toast } from 'react-toastify'

import { Panel, AutoSave } from 'atoms'
import { DetailedUploader } from 'molecules/DocumentUpload'
import { Div, Flex, Subtitle } from 'atoms'

const FileCabinet = ({
  title,
  documents,
  uploadFile,
  autoSave,
  allowDelete = false,
}) => {
  return (
    <Flex flexDirection="column">
      <Flex alignItems="center">
        <Subtitle>{title}</Subtitle>
      </Flex>
      <Div position="relative">
        <Panel>
          <Form
            mutators={arrayMutators}
            initialValues={{ documents }}
            onSubmit={uploadFile}>
            {() => (
              <AutoSave
                getErrors={autoSave.getErrors}
                getFormData={autoSave.getFormData}
                save={uploadFile}
                onSave={() => toast('Saved')}
                onError={errors => toast.error(errors)}>
                <DetailedUploader
                  canForward={false}
                  label="Documents"
                  name="documents"
                  creatable
                  canBeBlank={false}
                  allowDelete={allowDelete}
                />
              </AutoSave>
            )}
          </Form>
        </Panel>
      </Div>
    </Flex>
  )
}

export default FileCabinet
