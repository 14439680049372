import ApplicationModel from '../../ApplicationModel'

import { SubmissionMapping } from './mappings'

class SubmissionModel extends ApplicationModel {
  constructor() {
    super({
      mapping: SubmissionMapping,
    })
  }

  get coverages() {
    return this.attributes.coverages
  }

  get productOptions() {
    return this.attributes.productOptions
  }

  get quotedLines() {
    if (!this.attributes.quotations) return []

    return this.attributes.quotations.map(q => q.quotedLines)
  }

  get pendingState() {
    return this.attributes.pendingState
  }

  get name() {
    return this.attributes.name
  }

  get id() {
    return this.attributes.id
  }

  get panelId() {
    return this.attributes.panelId
  }

  get updatedAt() {
    return this.attributes.updatedAt
  }

  get country() {
    return this.attributes.country || 'US'
  }

  get documents() {
    return this.attributes.documents
  }

  get quotations() {
    return this.attributes.quotations
  }

  get participations() {
    return this.attributes.participations
  }

  get lineOfBusiness() {
    return this.attributes.lineOfBusiness
  }

  get applicant() {
    return this.attributes.applicant
  }

  get applicantAddress() {
    return this.attributes.applicantAddress
  }

  get products() {
    return this.attributes.products
  }

  get owner() {
    return this.attributes.owner
  }

  get quotationLimits() {
    return this.attributes.limit
  }

  get quotationDeductibles() {
    return this.attributes.deductible
  }

  hasLoaded(id) {
    return this.attributes && this.attributes.id == id
  }

  get participationId() {
    return this.attributes.participationId
  }
}

export default SubmissionModel
