import {
  TextRenderer,
  TitleRenderer,
  MoneyRenderer,
  MultiTitleRenderer,
  MultiColumnRenderer,
  MultiTypeRenderer,
} from '../../../renderers'
import { isEqual, uniq } from 'util/lodash'

const getHomeLine = (quotation, quotedLine) => {
  return quotation.quotedLines
    ?.filter(ql => ql.__typename === 'PCGHomeQuotedLineType')
    .filter(ql => isEqual(ql.address, quotedLine.address))[0]
}
const getHomeLines = (quotations = [], quotedLine) => {
  return quotations
    .flatMap(quote =>
      quote.quotedLines?.filter(ql => ql.__typename === 'PCGHomeQuotedLineType')
    )
    .filter(Boolean)
    .filter(ql => isEqual(ql.address, quotedLine.address))
}

const getCoverages = (quotations, quotedLine) => {
  const homes = getHomeLines(quotations, quotedLine)
  const allCoverages = homes.flatMap(hl => hl.coverages).filter(Boolean)
  return uniq(allCoverages.map(coverage => coverage.coverage))
}
const getEnhancements = (quotations, quotedLine) => {
  const homes = getHomeLines(quotations, quotedLine)
  const allEnhancements = homes.flatMap(hl => hl.enhancements).filter(Boolean)
  return uniq(allEnhancements.map(enhancement => enhancement.enhancement))
}
const getAdditionalOptions = (quotations, quotedLine) => {
  const homes = getHomeLines(quotations, quotedLine)
  const allOptions = homes.flatMap(hl => hl.additionalOptions).filter(Boolean)
  return uniq(allOptions.map(option => option.option))
}
const getAdditionalFeatures = (quotations, quotedLine) => {
  const homes = getHomeLines(quotations, quotedLine)
  const allFeatures = homes.flatMap(hl => hl.additionalFeatures).filter(Boolean)
  return uniq(allFeatures.map(feature => feature.feature))
}
const renderHomeCoverage = coverage => {
  return {
    icon: 'home',
    label: `${coverage?.address?.fullAddress}`,
    renderer: TitleRenderer,
  }
}

const renderHomeQuotedLine = (quotedLine, idx) => {
  return [
    {
      __typename: 'PCGHomeQuotedLineType',
      icon: 'home',
      label: `${quotedLine?.address?.fullAddress}`,
      renderer: TitleRenderer,
    },
    {
      label: 'Base Coverage',
      renderer: MultiTitleRenderer,
      titles: ['Limit', 'Deductible'],
    },
    quotations => {
      const coverages = getCoverages(quotations, quotedLine)

      const finder = (quotation, criterion) => {
        const home = getHomeLine(quotation, quotedLine)
        return home?.coverages?.find(
          coverage => coverage.coverage === criterion.label
        )
      }

      return coverages.map(coverage => ({
        label: coverage,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [
          {
            renderer: MultiTypeRenderer,
            key: 'homeCoverageLimit',
          },
          {
            renderer: MultiTypeRenderer,
            key: 'deductible',
          },
        ],
      }))
    },
    {
      label: 'All Other Perils Deductible',
      renderer: MultiTitleRenderer,
      titles: ['Deductible'],
    },
    {
      key: `quotedLines[${idx}].allOtherPerilDeductible`,
      label: 'All Other Perils Deductible',
      indent: true,
      renderer: MoneyRenderer,
    },
    {
      label: 'Coverage Enhancements',
      renderer: MultiTitleRenderer,
      titles: ['Limit', 'Deductible', 'Premium'],
    },
    quotations => {
      const enhancements = getEnhancements(quotations, quotedLine)

      const finder = (quotation, criterion) => {
        const home = getHomeLine(quotation, quotedLine)
        return home?.enhancements.find(
          enhancement => enhancement.enhancement === criterion.label
        )
      }

      return enhancements.map(enhancement => ({
        label: enhancement,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [
          {
            renderer: MultiTypeRenderer,
            key: 'limit',
          },
          {
            renderer: MultiTypeRenderer,
            key: 'deductible',
          },
          {
            renderer: MultiTypeRenderer,
            key: 'premium',
          },
        ],
      }))
    },
    {
      label: 'Additional Options',
      renderer: MultiTitleRenderer,
      titles: ['Deductible', 'Premium'],
    },
    quotations => {
      const options = getAdditionalOptions(quotations, quotedLine)

      const finder = (quotation, criterion) => {
        const home = getHomeLine(quotation, quotedLine)
        return home?.additionalOptions.find(
          feature => feature.option === criterion.label
        )
      }
      return options.map(option => ({
        label: option,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [
          {
            renderer: MultiTypeRenderer,
            key: 'deductible',
          },
          {
            renderer: MultiTypeRenderer,
            key: 'premium',
          },
        ],
      }))
    },
    {
      label: 'Additional Features',
      renderer: MultiTitleRenderer,
      titles: ['Included?'],
    },
    quotations => {
      const features = getAdditionalFeatures(quotations, quotedLine)

      const finder = (quotation, criterion) => {
        const home = getHomeLine(quotation, quotedLine)
        return home?.additionalFeatures.find(
          feature => feature.feature === criterion.label
        )
      }
      return features.map(feature => ({
        label: feature,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [{ renderer: TextRenderer, key: 'included', props: {} }],
      }))
    },
    {
      key: `quotedLines[${idx}].totalPremium`,
      label: 'TOTAL PREMIUM',
      renderer: MoneyRenderer,
    },
  ]
}

export {
  renderHomeQuotedLine,
  renderHomeCoverage,
  getHomeLine,
  getHomeLines,
  getCoverages,
  getEnhancements,
  getAdditionalOptions,
  getAdditionalFeatures,
}
