import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useBopAppetiteMutation } from 'graphql/api'

const useNextButton = (formData, currentTab, pages) => {
  const { submissionId } = useParams()
  const [loading, setLoading] = useState(false)
  const [bopMutation] = useBopAppetiteMutation()
  const currentPage = pages[currentTab]

  if (currentPage === 'business_owner') {
    const { nextButtonTitle, onNext } = bopNextButton({
      submissionId,
      setLoading,
      mutation: bopMutation,
      formCurrentState: formData[currentPage].form_current_state,
    })
    return {
      nextButtonTitle,
      onNext,
      loading,
    }
  } else {
    return {
      onNext: undefined,
      nextButtonTitle: undefined,
      loading: false,
    }
  }
}

const bopNextButton = ({
  formCurrentState,
  setLoading,
  mutation,
  submissionId,
}) => {
  let nextButtonTitle
  let formEndpoint
  if (formCurrentState === 'applicant') {
    nextButtonTitle = 'Basic Appetite'
    formEndpoint = 'basic_appetite'
  } else if (formCurrentState === 'basic_appetite') {
    nextButtonTitle = 'Capture Appetite'
    formEndpoint = 'basic_appetite_capture'
  } else if (formCurrentState === 'basic_appetite_capture') {
    nextButtonTitle = 'Enhanced Appetite'
    formEndpoint = 'enhanced_appetite'
  }
  const onNext = async formData => {
    setLoading(true)
    await mutation({
      variables: {
        submissionId,
        input: {
          attributes: { formData, formEndpoint },
          submissionId: submissionId,
        },
      },
    })
    setLoading(false)
  }
  return { nextButtonTitle, onNext: nextButtonTitle ? onNext : undefined }
}

export default useNextButton
