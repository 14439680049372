import React from 'react'
import get from 'lodash/get'

import { Flex } from 'atoms'

const MultiRenderer = ({ criterion, children }) => {
  const basis = 1 / criterion.length
  const base = criterion.finder(children, criterion)
  return (
    <Flex>
      {criterion.columns.map(({ renderer: Renderer, key }) => {
        return (
          <Flex flexBasis={basis} key={key}>
            <Renderer criterion={criterion}>{get(base, key)}</Renderer>
          </Flex>
        )
      })}
    </Flex>
  )
}

export default MultiRenderer
