import React from 'react'
import styled from 'styled-components'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

import Icon from 'components/Icon'
import { Div, BlackButton } from 'atoms'

const CheckIcon = styled(Icon)`
  color: ${props => props.theme.colors.white};
`

const CheckedButton = ({ children }) => (
  <BlackButton selected>
    <Div mr="0.5rem">
      <CheckIcon icon={faCheck} />
    </Div>
    {children}
  </BlackButton>
)
export default CheckedButton
