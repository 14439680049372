import React from 'react'
import {
  ShowConfig,
  Conversation,
  ParticipationNavigation,
} from 'pages/shared/panels'
import { useParams } from 'react-router-dom'

const ParticipationNavAndConvo = ({ submission, participation, userType }) => {
  const { participationId } = useParams()
  const participations = submission.participations || []
  let selectedParticipation = participation
  participations.forEach(part => {
    if (part?.id === participationId) {
      selectedParticipation = part
    }
  })
  const getConvoDetails = ShowConfig.convoDetails(userType)
  const isQuoted = quotation =>
    quotation.id && !['pending', 'declined'].includes(quotation.status)
  const hasQuote =
    selectedParticipation.quotations.nodes.filter(q => isQuoted(q)).length > 0
  return hasQuote ? (
    <ParticipationNavigation
      submission={submission}
      participation={selectedParticipation}
      getConvoDetails={getConvoDetails}
      userType={userType}
    />
  ) : (
    <Conversation
      submission={submission}
      participation={selectedParticipation}
      getConvoDetails={getConvoDetails}
    />
  )
}

export default ParticipationNavAndConvo
