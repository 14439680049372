import { currencyToBigDecimalNumber } from 'util/fieldNormalizers'

import { SubmissionMapping } from './mappings'
import ApplicationModel from '../../ApplicationModel'

class SubmissionModel extends ApplicationModel {
  constructor() {
    super({
      mapping: SubmissionMapping,
    })
  }

  get quotationLimits() {
    return []
  }

  get quotationDeductibles() {
    return []
  }

  get name() {
    return this.attributes.name
  }

  get limit() {
    return 0
  }

  get deductible() {
    return 0
  }

  get id() {
    return this.attributes.id
  }

  get panelId() {
    return this.attributes.panelId
  }

  get country() {
    return 'US'
  }

  get documents() {
    return this.attributes.documents
  }

  get quotations() {
    return this.attributes.quotations
  }

  get applicants() {
    return this.attributes.applicants
  }

  get lineOfBusiness() {
    return this.attributes.lineOfBusiness
  }

  hasLoaded(id) {
    return this.attributes && this.attributes.id == id
  }

  _toBigNumber(currencyAmount) {
    return currencyToBigDecimalNumber({
      currencyAmount,
    })
  }
}

export default SubmissionModel
