import React, { useState, useEffect } from 'react'
import { SectionTitle } from 'atoms'
import StatusPill from 'pages/shared/panels/StatusPill.js'

const QuotationHeader = ({ title, quotation, isOutdated }) => {
  const [current, setCurrent] = useState(quotation)
  useEffect(() => {
    setCurrent(quotation)
  }, [quotation])

  const status = current.status.toLowerCase()

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      <StatusPill status={status} isOutdated={isOutdated} />
    </>
  )
}

export default QuotationHeader
