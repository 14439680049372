import React from 'react'

const parseMetadata = ({ metadata, ...rest }) => ({
  ...rest,
  metadata: JSON.parse(metadata),
})

const PermissionsContext = React.createContext([])
PermissionsContext.displayName = 'PermissionsContext'

export { parseMetadata }
export default PermissionsContext
