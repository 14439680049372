import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Label from './Label'

const RequiredLabel = styled.span`
  color: red;
  font-size: 18px;
`

const FieldLabel = ({ required = false, children, ...rest }) => (
  <Label {...rest}>
    {children} {required ? <RequiredLabel>*</RequiredLabel> : ''}
  </Label>
)

FieldLabel.propTypes = {
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default FieldLabel
