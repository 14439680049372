import React from 'react'
import {
  cyberCriteria,
  cyberInsuringAgreements,
  firstPartyInsuringAgreements,
  thirdPartyInsuringAgreements,
  endorsements,
  additionalFeatures,
} from './cyberConstants'
import {
  TextRenderer,
  MoneyRenderer,
  PremiumRenderer,
  AdmittedRenderer,
  DocumentRenderer,
  LimitAndDeductibleRenderer,
  TwoColumnTitleRenderer,
  NavButtonRenderer,
  BindButtonRenderer,
  LimitAndCoinsuranceRenderer,
  StatusRenderer,
  AdditionalFeaturesTextRenderer,
  PercentRenderer,
  UnderwriterRenderer,
} from 'molecules/PanelQuoteComparator/renderers'
import { QUOTE_STATUSES } from 'panels/constants'
import { styleNavButtonRenderer } from '../../renderers/NavButtonRenderer'
import AMBestLabel from 'molecules/AMBestLabel'
import { parseTooltipDefinitions, isQuotePotentiallyBindable } from '../utils'

const hasStatus = status => quotations =>
  quotations.some(entry => status === entry.status)

// eslint-disable-next-line no-unused-vars
const criteria = ({
  country,
  customLabelForFees,
  displayTax,
  displayTotalCost,
  panelConfiguration,
  showCommission,
  subjectivities,
}) => {
  const customTooltipDefinitions = parseTooltipDefinitions(
    panelConfiguration?.tooltip_definitions
  )
  return {
    context: {
      bindable: quotations =>
        quotations.some(entry => isQuotePotentiallyBindable(entry)),
      binding: hasStatus(QUOTE_STATUSES.binding),
      bound: hasStatus(QUOTE_STATUSES.bound),
      issued: hasStatus(QUOTE_STATUSES.issued),
      referred: hasStatus(QUOTE_STATUSES.referred),
      extracted: hasStatus(QUOTE_STATUSES.extracted),
      outdated: hasStatus(QUOTE_STATUSES.outdated),
      statusMessage: quotations =>
        quotations.some(entry => entry.statusMessage),
      nonAPICarriers: quotations =>
        quotations.some(entry => !entry.isApi && !entry.externalCarrier),
      userIsWholesaler: (_quotations, { wholesalerContext }) =>
        wholesalerContext?.userIsWholesaler,
      hasWholesaler: (_quotations, { wholesalerContext }) =>
        wholesalerContext?.hasWholesaler,
      isPanelOwner: (_quotations, { wholesalerContext }) =>
        wholesalerContext?.isPanelOwner,
      wholesalerCollaborations: (_quotations, { wholesalerContext }) =>
        wholesalerContext?.wholesalerCollaborations,
    },
    sections: [
      {
        criteria: [
          ...cyberCriteria(
            customTooltipDefinitions['Cyber Criteria'] || {}
          ).map(({ label, key, tooltipContent }) => ({
            label,
            key,
            renderer: MoneyRenderer,
            tooltipContent,
          })),
          { key: 'deductible', label: 'Deductible', renderer: MoneyRenderer },
          {
            key: 'ambestRating',
            label: <AMBestLabel />,
            renderer: TextRenderer,
            fallback: 'NR',
          },
          {
            key: 'retailCommissionPercentage',
            label: 'Retailer Commission',
            renderer: PercentRenderer,
            decimal: true,
            showIf: () => showCommission,
          },
          {
            key: 'admitted',
            label: 'Admitted / Non-Admitted',
            renderer: AdmittedRenderer,
            showIf: () => country == 'US',
          },
          { label: 'Premium', renderer: PremiumRenderer },
          {
            key: 'fee',
            label: customLabelForFees ?? 'Fee',
            renderer: MoneyRenderer,
          },
          {
            key: 'tax',
            label: 'Tax',
            renderer: MoneyRenderer,
            showIf: () => displayTax,
          },
          {
            key: 'totalCost',
            label: 'Total',
            renderer: MoneyRenderer,
            showIf: () => displayTotalCost,
          },
          {
            label: 'Subjectivities',
            renderer: NavButtonRenderer,
            cellText: 'View/Edit',
            tertiary: true,
            edit: true,
            showIf: () => subjectivities,
            to: ({ quote }) => {
              return `${quote.submissionId}/quotes/${quote.id}/subjectivities-edit`
            },
          },
          { key: 'documents', label: 'Documents', renderer: DocumentRenderer },
          {
            key: 'policyNumber',
            label: 'Policy Number',
            renderer: TextRenderer,
            props: { fontWeight: 2 },
            showIf: ({ context }) => context.bound || context.issued,
          },
          // Buttons and statuses
          {
            renderer: NavButtonRenderer,
            label: 'View Details',
            secondary: true,
            to: ({ quote }) => `${quote.submissionId}/quotes/${quote.id}`,
            show: ({ quote }) =>
              !quote.externalCarrier && !quote.isApi && !quote.isExtracted,
            showIf: ({ context }) => context.nonAPICarriers,
          },
          {
            renderer: BindButtonRenderer,
            label: 'Bind Quote',
            showIf: ({ context }) => {
              return context.bindable
            },
            show: ({ quote }) => isQuotePotentiallyBindable(quote),
            to: quote => `${quote.submissionId}/quotes/${quote.id}/bind`,
          },
          // TODO: Once we want to support a retailer messaging multiple wholesalers, we will
          // need to turn this into a select or something where the retailer can choose which
          // wholesaler to message
          {
            renderer: styleNavButtonRenderer({
              style: { width: 'fit-content', maxWidth: '75%' },
              lockable: false,
            }),
            label: props =>
              `Message ${props?.wholesalerCompanyName || 'Wholesaler'}`,
            secondary: true,
            show: ({ quote, context }) => {
              return (
                quote.underWholesaler ||
                (!context.isPanelOwner &&
                  context.hasWholesaler &&
                  !context.userIsWholesaler)
              )
            },
            showIf: ({ context }) => {
              const { hasWholesaler, userIsWholesaler } = context
              return hasWholesaler && !userIsWholesaler
            },
            to: ({ quote, context }) => {
              const collaboration =
                context.wholesalerCollaborations.find(collaboration => {
                  return (
                    collaboration?.user?.company?.id ===
                    quote.wholesalerCompanyId
                  )
                }) || context.wholesalerCollaborations[0]
              return `${quote.submissionId}/collaborations/${collaboration.id}/discussion`
            },
          },
          {
            renderer: styleNavButtonRenderer({
              style: { width: 'fit-content', maxWidth: '75%' },
              lockable: false,
            }),
            label: props => `Message ${props?.submissionOwner || 'Retailer'}`,
            secondary: true,
            showIf: ({ context }) => {
              const { hasWholesaler, userIsWholesaler } = context
              return hasWholesaler && userIsWholesaler
            },
            to: ({ quote, context }) => {
              return `${quote.submissionId}/collaborations/${context.wholesalerCollaborations[0].id}/discussion`
            },
          },
          {
            renderer: UnderwriterRenderer,
            label: 'Underwriter',
            path: ({ panelId, submissionId }, quotation) =>
              `/panels/${panelId}/submissions/${submissionId}/participations/${quotation.participationId}`,
            show: ({ quote, context }) => {
              return !quote.underWholesaler || context.userIsWholesaler
            },
            showIf: ({ quotations, context }) => {
              if (quotations.length === 0) return false
              if (context.userIsWholesaler) return true

              /* Some quotations do not include underwriter field, if all of them
              do not include that field, do not display the column */
              let totalWithoutUnderwriterField
              const statusWithoutUnderwriter = {
                declined: true,
                failed: true,
              }
              totalWithoutUnderwriterField = quotations.filter(
                entry =>
                  entry.underWholesaler ||
                  statusWithoutUnderwriter[entry.status] ||
                  !entry.underwriterEmail
              ).length
              return totalWithoutUnderwriterField !== quotations.length
            },
          },
          {
            renderer: StatusRenderer,
            showIf: ({ context }) =>
              context.binding ||
              context.bound ||
              context.issued ||
              context.referred,
          },
          {
            key: 'statusMessage',
            renderer: TextRenderer,
            props: { fontWeight: 2 },
            showIf: ({ context }) => !context.outdated && context.statusMessage,
          },
        ],
      },
      {
        title: 'Insuring Agreements',
        criteria: [
          {
            label: 'Third Party',
            renderer: TwoColumnTitleRenderer,
            titles: ['Limit', 'Deduct / Waiting'],
          },
          ...thirdPartyInsuringAgreements(
            country,
            customTooltipDefinitions['Third Party Insuring Agreements'] || {}
          ).map(({ label, key, tooltipContent }) => ({
            label,
            key,
            renderer: LimitAndDeductibleRenderer,
            tooltipContent,
          })),
          {
            label: 'First Party',
            renderer: TwoColumnTitleRenderer,
            titles: ['Limit', 'Deduct / Waiting'],
          },
          ...firstPartyInsuringAgreements(
            country,
            customTooltipDefinitions['First Party Insuring Agreements'] || {}
          ).map(({ label, key, tooltipContent }) => ({
            label,
            key,
            renderer: LimitAndDeductibleRenderer,
            tooltipContent,
          })),
          {
            label: 'Cyber Crime',
            renderer: TwoColumnTitleRenderer,
            titles: ['Limit', 'Deduct / Waiting'],
          },
          ...cyberInsuringAgreements(
            country,
            customTooltipDefinitions['Cyber Insuring Agreements'] || {}
          ).map(({ label, key, tooltipContent }) => ({
            label,
            key,
            renderer: LimitAndDeductibleRenderer,
            tooltipContent,
          })),
        ],
      },
      {
        title: 'Endorsements',
        criteria: [
          ...endorsements(customTooltipDefinitions['Endorsements'] || {}).map(
            ({ label, key, tooltipContent }) => ({
              label,
              key,
              renderer: LimitAndCoinsuranceRenderer,
              tooltipContent,
            })
          ),
        ],
      },
      {
        title: 'Additional Features',
        showIf: () => country == 'CA',
        criteria: [
          ...additionalFeatures(
            customTooltipDefinitions['Additional Features'] || {}
          ).map(({ label, key, tooltipContent }) => ({
            label,
            key,
            renderer: AdditionalFeaturesTextRenderer,
            tooltipContent,
          })),
        ],
      },
    ],
  }
}

export default criteria
