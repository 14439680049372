import { QUOTE_STATUSES } from 'panels/constants'

export const parseTooltipDefinitions = tooltipDefinitions => {
  try {
    JSON.parse(tooltipDefinitions)
  } catch (e) {
    return {}
  }
  return JSON.parse(tooltipDefinitions)
}

// if a definition cannot be found, show the default tooltip content.
export const mapTooltipDefinitions = (coverages, tooltipDefinitions = {}) =>
  coverages.map(coverage => {
    return {
      ...coverage,
      tooltipContent:
        tooltipDefinitions[coverage.label] || coverage.defaultTooltipContent,
    }
  })

export const NON_BINDABLE_STATES = [
  QUOTE_STATUSES.issued,
  QUOTE_STATUSES.binding,
  QUOTE_STATUSES.bound,
  QUOTE_STATUSES.failed,
  QUOTE_STATUSES.declined,
  QUOTE_STATUSES.outdated,
  QUOTE_STATUSES.declinationConfirmed,
  QUOTE_STATUSES.referred,
]

const isQuoteBindable = quote =>
  !quote.externalCarrier && !NON_BINDABLE_STATES.includes(quote.status)

// Quote can be bound if indications are resolved successfully
export const isQuotePotentiallyBindable = quote =>
  isQuoteBindable(quote) || quote.indication
