import React, { useState } from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import { useForm } from 'react-final-form'
import { length } from 'redux-form-validators'
import { v4 as uuid } from 'uuid'
import { faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons'
import union from 'lodash/union'

import { FilenameWithField } from './Filename'
import Category from './Category'
import Version from './Version'
import DropzoneArea from './DropzoneArea'
import UploadLink from './UploadLink'
import DocumentsTable from './DocumentsTable'
import ContextMenu from 'molecules/ContextMenu'
import ForwardModal from 'molecules/DocumentUpload/ForwardModal'
import {
  Flex,
  Error,
  Table,
  TR,
  TH,
  TD,
  Text,
  Icon,
  IconCircle,
  DateString,
} from 'atoms'
import { getNextVersion } from './index'

const Trash = ({ remove }) => {
  return (
    <Flex p={1}>
      <Icon data-testid="trash" icon={faTrash} onClick={remove} />
    </Flex>
  )
}

const DetailsDocumentUploader = ({
  name,
  required,
  compact = false,
  label = 'Upload Document',
  readOnly = false,
  categories = [],
  tags = [],
  defaultCategory,
  allowDelete = false,
  creatable,
  canForward = true,
  ...props
}) => {
  const [file, setFile] = useState(null)
  const [showModal, toggleModal] = useState(false)

  const { fields, meta } = useFieldArray(name, {
    validate:
      required &&
      length({ min: 1, message: 'Please upload at least one document' }),
  })
  const onUpload = (attachments, currentFields = []) => {
    attachments.forEach(file => {
      currentFields.push({
        id: uuid(),
        version: getNextVersion(fields.value, defaultCategory),
        tags,
        attachment: file,
      })
    })
  }

  const documentCategories = union(
    categories,
    (fields.value || []).map(f => f.category).filter(Boolean)
  )

  const openModal = file => {
    setFile(file)
    toggleModal(true)
  }

  const options = file => {
    const menuOptions = []
    menuOptions.unshift({
      title: `Forward`,
      description: `Forward to insurer`,
      icon: 'envelope-open-text',
      onClick: () => openModal(file),
      autoClose: true,
    })

    return { menuOptions }
  }

  const remove = index => () => {
    const current = fields.value[index]
    if (current.id) {
      fields.update(index, { ...current, _destroy: true })
    } else {
      fields.remove(index)
    }
  }

  const { change } = useForm()

  const onCategoryChanged = (name, newCategory) => {
    const version = getNextVersion(fields.value, newCategory)
    change(`${name}.version`, version)
  }

  return (
    <>
      {canForward && showModal && (
        <ForwardModal file={file} onClose={() => toggleModal(false)} />
      )}
      <Flex flexDirection="column" backgroundColor="white" {...props}>
        {!compact && !readOnly && (
          <DropzoneArea mb={2} name={name} onUpload={onUpload} />
        )}
        {readOnly ? (
          <DocumentsTable
            // if categories prop is empty, we don't filter, otherwise filter based on category
            documents={
              !categories.length
                ? fields.value
                : fields.value.filter(doc => categories.includes(doc.category))
            }
          />
        ) : (
          <Table
            header={
              <TR>
                <TH color="primaryTextMid" p="0px" width="35%">
                  DOCUMENT
                </TH>
                <TH color="primaryTextMid" width="20%" p="0px">
                  TYPE
                </TH>
                {canForward && (
                  <TH color="primaryTextMid" width="10%" p="0px">
                    FORWARD
                  </TH>
                )}
                <TH color="primaryTextMid" p="0px" width="10%">
                  VERSION
                </TH>
                <TH color="primaryTextMid" p="0px" width="10%">
                  WHEN
                </TH>
                {allowDelete && (
                  <TH color="primaryTextMid" p="0px" width="10%">
                    ACTIONS
                  </TH>
                )}
              </TR>
            }>
            {fields &&
              fields.map((name, index) => {
                const file = fields.value[index]
                if (file._destroy) return null

                return (
                  <TR key={index} p="5px">
                    <TD p="0px">
                      <FilenameWithField maxLength={50} name={name} />
                    </TD>
                    <TD p="0px" pr={0}>
                      <Category
                        creatable={creatable}
                        name={name}
                        readOnly={readOnly}
                        defaultCategory={defaultCategory}
                        onCategoryChanged={onCategoryChanged}
                        documentCategories={documentCategories}
                      />
                    </TD>
                    {canForward && (
                      <TD p="0px">
                        <Flex>
                          <ContextMenu
                            size="small"
                            options={file && options(file).menuOptions}
                          />
                        </Flex>
                      </TD>
                    )}
                    <TD p="0px">
                      <Version name={name} pr={1} />
                    </TD>
                    <TD p="0px">
                      <DateString date={file.createdAt} />
                    </TD>
                    {allowDelete && !file.tags?.includes('_important') && (
                      <TD p="0px">
                        <Trash data-testid="trash" remove={remove(index)} />
                      </TD>
                    )}
                  </TR>
                )
              })}
            {meta.touched && meta.error && <Error>{meta.error}</Error>}
            {compact &&
              !readOnly(
                <UploadLink name={name} onUpload={onUpload}>
                  <Flex flexDirection="row" mt={1}>
                    <IconCircle
                      ml={1}
                      title="Remove"
                      role="button"
                      borderColor="link"
                      bg="link">
                      <Icon alt="Add" icon={faPlus} color="white" />
                    </IconCircle>
                    <Text>{label}</Text>
                  </Flex>
                </UploadLink>
              )}
          </Table>
        )}
      </Flex>
    </>
  )
}

export default DetailsDocumentUploader
