import React from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import {
  faClock,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/pro-light-svg-icons'
import { Link as RRLink } from 'react-router-dom'
import { Flex, Text, IconButton, Div, Icon } from 'atoms'

const actions = {
  bind: {
    path: 'confirm-binding',
    label: 'Bind',
  },
  decline: {
    path: 'decline-binding',
    label: 'Decline',
  },
}
const Link = ({ path, url, label }) => {
  return (
    <RRLink to={`${url}/${path}`} style={{ textDecoration: 'none' }}>
      {label}
    </RRLink>
  )
}
export default function ConfirmBindingButton({
  criterion: { to, quoteActions },
  children: entity,
  show,
}) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const url = to(entity)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }
  if (!show) return null
  return (
    <Flex justifyContent="center">
      <Div>
        <IconButton icon={faClock} tertiary onClick={handleToggle}>
          <Text>Requested to Bind</Text>
          <Icon ml={8} icon={open ? faAngleUp : faAngleDown} />
        </IconButton>
        <Div ref={anchorRef}>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom"
            container={anchorRef.current}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {quoteActions.map((quoteAction, index) => {
                    const action = actions[quoteAction]
                    return (
                      <MenuItem key={index} onClick={handleClose}>
                        <Link {...action} url={url} />
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </Div>
      </Div>
    </Flex>
  )
}
