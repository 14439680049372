import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import {
  faFolder,
  faQuestionCircle,
  faComment,
  faClipboardListCheck,
} from '@fortawesome/pro-solid-svg-icons'
import { Conversation, ParticipationFileCabinet } from 'pages/shared/panels'
import { useIsWholesalerGuest } from 'components/contexts/wholesalerSubmission'
import { Icon } from 'atoms'
import RequesterQAndA from 'components/QAndA/Requester/RequesterQAndA'
import AnswererQAndA from 'components/QAndA/Answerer/AnswererQAndA'
import RequesterSubjectivity from 'components/subjectivities/RequesterSubjectivity'
import AnswererSubjectivity from 'components/subjectivities/AnswererSubjectivity'
import ParticipationContext from 'components/contexts/participation'
import Tabs, { TabLink } from 'molecules/Tabs'

const ParticipationNavigation = ({
  submission,
  participation,
  getConvoDetails,
  userType,
}) => {
  const { url, path } = useRouteMatch()
  const isWholesalerGuest = useIsWholesalerGuest()
  return (
    <ParticipationContext.Provider value={participation}>
      <Tabs>
        {!isWholesalerGuest && (
          <TabLink exact to={url}>
            <Icon mr={0} icon={faComment} />
            Conversation
          </TabLink>
        )}
        <TabLink exact to={`${url}/q-and-a`}>
          <Icon mr={0} icon={faQuestionCircle} />
          Follow-Up Q&amp;A
        </TabLink>
        <TabLink exact to={`${url}/subjectivities`}>
          <Icon mr={0} icon={faClipboardListCheck} />
          Subjectivities
        </TabLink>
        <TabLink exact to={`${url}/all-documents`}>
          <Icon mr={0} icon={faFolder} />
          All Documents
        </TabLink>
      </Tabs>
      <Switch>
        <Route path={`${path}/q-and-a`}>
          {userType === 'broker' ? (
            <AnswererQAndA
              submission={submission}
              questionable={participation}
            />
          ) : (
            <RequesterQAndA
              submission={submission}
              questionable={participation}
            />
          )}
        </Route>
        <Route path={`${path}/subjectivities`}>
          {userType === 'broker' ? (
            <AnswererSubjectivity
              submission={submission}
              subjectivitable={participation}
            />
          ) : (
            <RequesterSubjectivity
              submission={submission}
              subjectivitable={participation}
            />
          )}
        </Route>
        <Route path={`${path}/all-documents`}>
          <ParticipationFileCabinet allowDelete />
        </Route>
        <Route path={path}>
          <Conversation
            submission={submission}
            participation={participation}
            getConvoDetails={getConvoDetails}
          />
        </Route>
      </Switch>
    </ParticipationContext.Provider>
  )
}

export default ParticipationNavigation
