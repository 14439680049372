import { Div, Text } from 'atoms'
import omit from 'lodash/omit'
import startCase from 'lodash/startCase'

import { shape, string } from 'prop-types'
import React from 'react'
import { isPresent } from '../../util'

const ToastContractError = ({ title, errors }) => {
  const { base, ...rest } = omit(errors, '__typename')

  const attrErrors = Object.entries(rest).filter(([, value]) =>
    isPresent(value)
  )

  return (
    <>
      <Div pb={1}>
        <Text fontWeight={2}>{title}</Text>
      </Div>

      <ul>
        {base && <li key="base-error">{base}</li>}
        {attrErrors.map(([key, value]) => {
          return (
            <li key={key}>
              {startCase(key)}: {value}
            </li>
          )
        })}
      </ul>
    </>
  )
}

ToastContractError.propTypes = {
  title: string.isRequired,
  errors: shape({ base: string }).isRequired,
}

export default ToastContractError
