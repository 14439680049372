import React from 'react'
import pickBy from 'lodash/pickBy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Div from 'atoms/Div'
import styled from 'styled-components'
import { color, border } from 'styled-system'

const Container = styled(Div)`
  flex: 0 0 auto;
  display: ${props => (props.display ? props.display : 'flex')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  border-radius: 50%;
  ${props => (props.middle ? 'margin-top: auto; margin-bottom: auto;' : '')}
  ${props =>
    props.dark &&
    `
    background-color: ${props.theme.colors.primaryText};
    padding: 2px;
  `}

  ${props => (props.cursor ? `cursor: ${props.cursor};` : '')}
`

const IconCircle = styled(Div)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 8px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: ${props => (props.disabled ? 'none' : 'pointer')};
  border: ${props =>
    props.muted && `1px solid ${props.theme.colors.secondaryBlue}`};
  background-color: ${props =>
    props.muted ? 'none' : props.theme.colors.link};
  ${color}
  ${border}
`

const VARIANTS = {
  solid: 'fas',
  regular: 'far',
  light: 'fal',
  brand: 'fab',
}

export const marginProps = obj => pickBy(obj, v => v !== undefined)

const Icon = ({
  icon,
  name,
  middle,
  dark,
  bg,
  variant = 'solid',
  className,
  fontSize,
  onClick,
  style,
  mr,
  ml,
  mt,
  mb,
  mx,
  my,
  clickable,
  color,
  border,
  position,
  top,
  right,
  bottom,
  left,
  display,
  ..._rest
}) => {
  const faVariant = VARIANTS[variant] || 'fas'
  const faIcon =
    typeof (icon || name) === 'string' ? [faVariant, icon || name] : icon

  const rest = pickBy(_rest, (v, k) => k !== 'iconColor')
  return (
    <Container
      {...style}
      display={display}
      position={position}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      bg={bg}
      {...marginProps({ mr, ml, mt, mb, mx, my })}
      color={color}
      className={className}
      middle={middle}
      dark={dark}
      fontSize={fontSize}
      onClick={onClick}
      border={border}
      cursor={onClick || clickable ? 'pointer' : undefined}>
      <FontAwesomeIcon {...rest} icon={faIcon} />
    </Container>
  )
}

export default Icon
export { IconCircle }
