import { useContext } from 'react'

import QuoteContext from 'components/contexts/quote'

const useQuote = () => {
  return useContext(QuoteContext)
}

export const ownerFeature = feature => {
  const quote = useContext(QuoteContext)
  return feature && JSON.parse(quote?.worksheet?.owner?.features)[feature]
}

export default useQuote
