import React from 'react'
import { Link } from 'react-router-dom'
import { Flex, Icon, Text } from 'atoms'
import { Menu, Popconfirm } from 'antd'
import styled from 'styled-components'

const IconWrapper = styled(Icon)`
  cursor: pointer;
  display: inline-block;
`
const FlexWrapper = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: left;
`
const MenuItemWrapper = styled(Menu.Item)`
  padding: 5px 12px;
  cursor: pointer;
`

const LinkEntry = ({ label, icon, url, dataTestId }) => (
  <MenuItemWrapper>
    <Link
      data-testid={dataTestId}
      alt={label}
      to={url}
      style={{ textDecoration: 'none' }}>
      <FlexWrapper>
        <IconWrapper color="black" icon={icon} mr={1} />
        <Text>{label}</Text>
      </FlexWrapper>
    </Link>
  </MenuItemWrapper>
)

const PopupEntry = ({ label, icon, popup, dataTestId }) => (
  <MenuItemWrapper>
    <Popconfirm
      data-testid={dataTestId}
      okText="Yes"
      cancelText="No"
      {...popup}>
      <FlexWrapper>
        <IconWrapper color="black" icon={icon} mr={1} />
        <Text>{label}</Text>
      </FlexWrapper>
    </Popconfirm>
  </MenuItemWrapper>
)

export default {
  LinkEntry,
  PopupEntry,
}
