import React, { useState, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons'

import { ExternalLink as Link, EnhancedModal, Icon, Div, Loading } from 'atoms'
import { truncateFileName } from 'util/document'
import { addPropsToChildElements } from 'util/addPropsToChildElements'

const PDFViewer = lazy(() => import('./PDFViewer'))

const ExternalLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :first-child {
    margin-right: 0.5rem;
  }
`
const LazyPDFViewer = props => {
  return (
    <Suspense fallback={<Loading />}>
      <PDFViewer {...props} />
    </Suspense>
  )
}

export const GenericPDFViewer = ({ document, children }) => {
  const [showDoc, setShowDoc] = useState(false)
  const content = addPropsToChildElements({
    children,
    props: {
      onClick: e => {
        e.preventDefault()
        e.stopPropagation()
        setShowDoc(true)
      },
    },
  })

  return (
    <>
      {showDoc && (
        <EnhancedModal showExit={false} wide onClose={() => setShowDoc(false)}>
          <LazyPDFViewer url={document.url} filename={document.name} />
        </EnhancedModal>
      )}
      {content}
    </>
  )
}

export const PDFViewerLink = ({
  document,
  maxLength = 30,
  largeIcon = false,
}) => {
  const [showDoc, setShowDoc] = useState(false)

  return (
    <>
      {showDoc && (
        <EnhancedModal showExit={false} wide onClose={() => setShowDoc(false)}>
          <Div
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}>
            <LazyPDFViewer url={document.url} filename={document.name} />
          </Div>
        </EnhancedModal>
      )}
      <ExternalLink
        onClick={e => {
          e.preventDefault()
          setShowDoc(true)
        }}
        title={document.filename || document.name}>
        <Icon icon={faPaperclip} fontSize={largeIcon ? '18px' : 'm'} />
        {truncateFileName(document.filename || document.name, maxLength)}
      </ExternalLink>
    </>
  )
}

export default LazyPDFViewer
