import {
  CYBER_CRITERIA,
  THIRD_PARTY_INSURING_AGREEMENTS,
  FIRST_PARTY_INSURING_AGREEMENTS,
  CYBER_INSURING_AGREEMENTS,
  ADDITIONAL_FEATURES,
  ENDORSEMENTS,
} from './allCoveragesConstants'
import { mapTooltipDefinitions } from '../utils'

export const cyberCriteria = tooltipDefinitions =>
  mapTooltipDefinitions(CYBER_CRITERIA, tooltipDefinitions)

export const thirdPartyInsuringAgreements = (country, tooltipDefinitions) => {
  const coverages = mapTooltipDefinitions(
    THIRD_PARTY_INSURING_AGREEMENTS,
    tooltipDefinitions
  )
  if (country) {
    return coverages.filter(
      coverage => !coverage.country || coverage.country.includes(country)
    )
  } else {
    return coverages
  }
}

export const firstPartyInsuringAgreements = (country, tooltipDefinitions) => {
  const coverages = mapTooltipDefinitions(
    FIRST_PARTY_INSURING_AGREEMENTS,
    tooltipDefinitions
  )
  if (country) {
    return coverages.filter(
      coverage => !coverage.country || coverage.country.includes(country)
    )
  } else {
    return coverages
  }
}

export const cyberInsuringAgreements = (country, tooltipDefinitions) => {
  const coverages = mapTooltipDefinitions(
    CYBER_INSURING_AGREEMENTS,
    tooltipDefinitions
  )
  if (country) {
    return coverages.filter(
      coverage => !coverage.country || coverage.country.includes(country)
    )
  } else {
    return coverages
  }
}

export const additionalFeatures = tooltipDefinitions => {
  return mapTooltipDefinitions(ADDITIONAL_FEATURES, tooltipDefinitions)
}

export const endorsements = tooltipDefinitions => {
  return mapTooltipDefinitions(ENDORSEMENTS, tooltipDefinitions)
}

const toLabelToKeyMap = items =>
  items.reduce((memo, { label, key }) => ({ ...memo, [label]: key }), {})

export const getCoveragesLabelMap = country => {
  return {
    ...toLabelToKeyMap(thirdPartyInsuringAgreements(country)),
    ...toLabelToKeyMap(firstPartyInsuringAgreements(country)),
    ...toLabelToKeyMap(cyberInsuringAgreements(country)),
  }
}

export const getEndorsementsLabelMap = () => toLabelToKeyMap(ENDORSEMENTS)
