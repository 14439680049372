import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export default function createAuthorizedMarketContactMutation() {
  return graphql(
    gql`
      mutation createAuthorizedMarketContact(
        $input: CreateAuthorizedMarketContactInput!
      ) {
        createAuthorizedMarketContact(input: $input) {
          authorizedMarketContact {
            id
            authorizedMarket {
              id
              cedingCompany {
                id
                authorizedMarkets {
                  nodes {
                    id
                  }
                }
              }
              contacts {
                nodes {
                  id
                  reinsurer {
                    id
                  }
                }
              }
            }
            reinsurer {
              id
              email
              fullName
              firstName
              lastName
              companyName
              avatarUrl
              pendingInvitation
              newMarket
            }
          }
          errors {
            authorizedMarketContacts
          }
        }
      }
    `,
    {
      props: ({ mutate }) => ({
        createAuthorizedMarketContact: input =>
          mutate({
            variables: input,
            refetchQueries: ['AuthorizedMarkets'],
          }),
      }),
    }
  )
}
