import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import 'moment-duration-format'
import Checkbox from '@material-ui/core/Checkbox'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { faRocket, faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import chunk from 'lodash/chunk'

import selectorBackground from 'images/selectorTabBG.svg'
import selectorInactiveBackground from 'images/selectorTabBGInactive.svg'
import checkBoxOn from 'images/squareCheckboxOn.svg'
import { colors } from 'components/theme'
import checkBoxOff from 'images/squareCheckboxOff.svg'
import { Note, Flex, Row, Column, Div, Text, Icon } from 'atoms'
import NotEligibleCarrier from './NotEligibleCarrier'
import Dialog from 'molecules/Dialog'

const Img = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

const SelectorCheckboxPanel = styled(Div)`
  background-image: url(${props =>
    props.checked ? selectorBackground : selectorInactiveBackground});
  background-repeat: no-repeat;
  background-size: 100%;
  color: white;
  font-weight: 600;
`

const EnhancedPanelistDiv = styled(Row)`
  background: white;
  position: relative;
  width: 265px;
  height: 137px;
  border-radius: 0.5rem;
  border-width: 2px;
  border-style: solid;
  padding: 0px;
  opacity: ${props => props.disabled && 0.5};
  pointer-events: ${props => props.disabled && 'none'};
  cursor: ${props => props.disabled && 'not-allowed'};
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
`

const LogoOverlay = styled(Div)`
  background-color: #f1f1f1e6;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  display: flex;
`

const ProfileRow = styled(Row)`
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0rem;
  }
`

const humanizedResponseTime = responseTime => {
  return moment
    .duration(responseTime, 'seconds')
    .humanize()
    .includes('a few seconds')
    ? '1 min'
    : moment.duration(responseTime, 'seconds').humanize()
}

const LogoPanel = ({ profile, isApiCarrier, logo }) => {
  const [hover, setHover] = useState(false)
  const [show, setShow] = useState(false)

  return (
    <>
      <Div
        p="1"
        width="50%"
        onClick={() => {
          setShow(true)
          setHover(false)
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <Div
          width="100%"
          height="100%"
          position="relative"
          cursor="pointer"
          display="flex">
          {isApiCarrier ? (
            <Img src={logo} />
          ) : (
            <Icon
              icon={faEnvelope}
              color={colors.disabled}
              fontSize="4em"
              style={{ alignItems: 'center' }}
            />
          )}
          {hover && (
            <LogoOverlay>
              <Div color={colors.link} margin="auto">
                View Details
              </Div>
            </LogoOverlay>
          )}
        </Div>
      </Div>
      {show && (
        <CarrierProfile
          onClose={() => {
            setShow(false)
          }}
          person={profile}
          logo={logo}
        />
      )}
    </>
  )
}

const ProfileSchema = {
  'QUOTE RATIO': { key: 'quoteRatio', transform: value => `~${value * 100}%` },
  'RESPONSE TIME': {
    key: 'medianReplyTime',
    transform: value => (!value ? 'N/A' : `~${humanizedResponseTime(value)}`),
  },
  'AMBEST RATING': {
    key: profile => profile.company?.ambestRating?.rating,
    transform: value => value || '-',
  },
  'UW QUESTIONS': {
    key: 'numberOfUwQuestions',
    defaultValue: 0,
  },
}

const CarrierProfile = ({ onClose, person, logo }) => {
  const fields = chunk(Object.keys(ProfileSchema), 3)

  return (
    <Dialog maxWidth="lg" showDialog={true} onClose={onClose}>
      <Flex>
        <Column mr={2} width="250px" flexGrow={1}>
          <Img src={logo || person?.company?.logoUrl} />
        </Column>
        <Column flexGrow={3}>
          {fields.map((titles, index) => (
            <ProfileRow key={index}>
              {titles.map(title => {
                const key = ProfileSchema[title].key
                const defaultValue = ProfileSchema[title].defaultValue
                let value = 'N/A'
                let transform = ProfileSchema[title].transform || (v => v)

                if (typeof key === 'function') {
                  value = transform(key(person)) || defaultValue || value
                } else if (key !== null) {
                  value = transform(person[key]) || defaultValue || value
                }

                return (
                  <Column pr={2} key={title} width={'33%'}>
                    <Div>
                      <Note fontSize="s">{title}</Note>
                      <Div>{value}</Div>
                    </Div>
                  </Column>
                )
              })}
            </ProfileRow>
          ))}
        </Column>
      </Flex>
    </Dialog>
  )
}

const InstantIndication = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const handleClick = e => {
    setAnchorEl(e.currentTarget)
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Div fontWeight="2" color="link" cursor="pointer">
        <Popper open={open} anchorEl={anchorEl} placement="top">
          <Paper elevation={3}>
            <Div width="500px" p={1}>
              This carrier is not yet appointed, but will still give an
              indication while awaiting your application. Using their easy
              online process you can become appointed quickly and easily, and
              then bind right through the application.
            </Div>
          </Paper>
        </Popper>
        <div onClick={handleClick}>
          <Icon display="inline" mr="5px" icon={faRocket} color="link" />
          Instant Indication
        </div>
      </Div>
    </ClickAwayListener>
  )
}

const TileLabel = ({ notAppointed, isApiCarrier }) => {
  if (!isApiCarrier) {
    return <strong>Email Quote</strong>
  } else if (notAppointed && isApiCarrier) {
    return (
      <>
        <strong>Not-appointed</strong>
        <InstantIndication />
      </>
    )
  } else {
    return <></>
  }
}

const Panelist = ({
  logo,
  checked,
  onChange,
  panelist,
  hasNoWebsite,
  requestingIndication,
  mfa,
  wholesaler,
  children,
  ...rest
}) => {
  const { id, firstName, lastName } = panelist

  const isApiCarrier = panelist.type === 'CarrierAPI'
  const notAppointed = panelist.panelistConfiguration?.indicationOnly

  const disabledForWebsite = !panelist.websiteOptional && hasNoWebsite

  const disabledForIndication =
    !panelist.indicationProvided && requestingIndication
  const disabledForMfaEmail =
    panelist.mfaCorporateEmailRequired && !mfa.includes('corporate_email')
  const disabledForCorpNetworks =
    panelist.mfaCorporateNetworksRequired && !mfa.includes('corporate_network')
  const disabledForRemoteNetwork =
    panelist.mfaRemoteNetworkAccessRequired && !mfa.includes('remote_network')

  const disabled =
    disabledForWebsite ||
    disabledForIndication ||
    disabledForMfaEmail ||
    disabledForCorpNetworks ||
    disabledForRemoteNetwork

  return (
    <Column width="auto" {...rest}>
      <Row justifyContent="center">
        {disabled && (
          <NotEligibleCarrier
            panelist={panelist}
            missingWebsite={disabledForWebsite}
            requestingIndication={requestingIndication}
            missingEmailMfa={disabledForMfaEmail}
            missingCorporateNetworkMfa={disabledForCorpNetworks}
            missingRemoteNetworkMfa={disabledForRemoteNetwork}
          />
        )}
      </Row>
      {isApiCarrier ? (
        <Text textAlign="center" mb={0} fontSize="3" fontWeight={2}>
          <label htmlFor={id}>{firstName}</label>
          {!lastName.includes('API') && (
            <Text textAlign="center" mb={0} fontSize="2" fontWeight={2}>
              &nbsp; ({lastName})
            </Text>
          )}
        </Text>
      ) : (
        <Row justifyContent="center" alignItems="center">
          <Icon
            icon={faEnvelope}
            color={colors.disabled}
            fontSize="3"
            fontWeight={2}
          />
          &nbsp;&nbsp;
          <Text
            textAlign="center"
            mb={0}
            fontSize="2"
            fontWeight={2}
            maxWidth="200px">
            {panelist.company.name} - {firstName} {lastName}
          </Text>
        </Row>
      )}
      {children}
      <EnhancedPanelistDiv
        borderColor={checked ? 'binder' : 'transparent'}
        disabled={disabled}>
        <LogoPanel
          profile={panelist}
          key={panelist.id}
          logo={logo}
          isApiCarrier={isApiCarrier}
        />
        <Flex width="62%" mt="-2px" mr="-2px">
          <Column>
            <SelectorCheckboxPanel
              checked={checked}
              borderRadius="0 0.5rem 0 0"
              pl="30%">
              <Checkbox
                color="primary"
                icon={<Img src={checkBoxOff} />}
                disabled={disabled}
                checkedIcon={<Img src={checkBoxOn} />}
                id={id}
                checked={checked}
                onChange={onChange}
                data-testid="panelist-checkbox"
              />
              Select
            </SelectorCheckboxPanel>
            {wholesaler && (
              <Div pt={0.5} pr={0.5}>
                <b>By {wholesaler}</b>
              </Div>
            )}
            <Div pt={0.5} pr={0.5} fontSize="s">
              <TileLabel
                notAppointed={notAppointed}
                isApiCarrier={isApiCarrier}
              />
              {panelist.medianReplyTime && (
                <>
                  {!notAppointed && (
                    <Div fontWeight="2">
                      <Icon display="inline" mr="5px" icon={faRocket} />
                      Instant Quote&nbsp;
                    </Div>
                  )}
                  <Div>
                    Responds in&nbsp;
                    <Text fontWeight="2" color="binder" fontSize="14px">
                      ~{humanizedResponseTime(panelist.medianReplyTime)}
                    </Text>
                  </Div>
                </>
              )}
              {!notAppointed && (
                <Div>
                  <strong>
                    {panelist.numberOfUwQuestions
                      ? panelist.numberOfUwQuestions
                      : 'No'}
                  </strong>
                  &nbsp; Questions
                </Div>
              )}
            </Div>
          </Column>
        </Flex>
      </EnhancedPanelistDiv>
    </Column>
  )
}

export default Panelist
