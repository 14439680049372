import BigNumber from 'bignumber.js'

import { currencyToBigDecimalNumber } from 'util/fieldNormalizers'
import { SubmissionMapping } from './mappings'
import ApplicationModel from '../../ApplicationModel'

class SubmissionModel extends ApplicationModel {
  constructor() {
    super({
      mapping: SubmissionMapping,
    })
  }

  get quotationLimits() {
    if (!this.attributes.quotations) return []

    return this.attributes.quotations
      .map(q => new BigNumber(q.limit).toNumber())
      .filter(l => l != null && !isNaN(l))
      .sort((a, b) => a - b)
      .reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      )
  }

  get quotationDeductibles() {
    if (!this.attributes.quotations) return []

    return this.attributes.quotations
      .map(q => new BigNumber(q.deductible).toNumber())
      .filter(l => l != null && !isNaN(l))
      .sort((a, b) => a - b)
      .reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      )
  }

  get name() {
    return this.attributes.name
  }

  get limit() {
    return this._toBigNumber(this.attributes.limit)
  }

  get deductible() {
    return this._toBigNumber(this.attributes.deductible)
  }

  get id() {
    return this.attributes.id
  }

  get panelId() {
    return this.attributes.panelId
  }

  get country() {
    return this.attributes.country
  }

  get documents() {
    return this.attributes.documents
  }

  get revisedAt() {
    return this.attributes.revisedAt
  }

  get quotations() {
    return this.attributes.quotations
  }

  get lineOfBusiness() {
    return this.attributes.lineOfBusiness
  }

  get customLabelForFees() {
    return this.attributes.customLabelForFees
  }

  hasLoaded(id) {
    return this.attributes && this.attributes.id == id
  }

  _toBigNumber(currencyAmount) {
    return currencyToBigDecimalNumber({
      currencyAmount,
    })
  }
}

export default SubmissionModel
