import React from 'react'
import { Panel, Subtitle, Div } from 'atoms'
import mapDocs from 'util/mapDocs'
import DocumentsTable from 'molecules/DocumentUpload/DocumentsTable'
import uniqBy from 'lodash/uniqBy'
import flattenDeep from 'lodash/flattenDeep'
import { FileCabinet } from 'pages/shared/panels'
import { usePanelsUpdateSubmissionDocumentsMutation } from 'graphql/api'

const SubmissionFileCabinet = ({ submission, allowDelete = false }) => {
  const [upload] = usePanelsUpdateSubmissionDocumentsMutation()
  const autoSave = {
    getErrors: ({ data }) => data.panelsUpdateSubmissionDocuments.errors,
    getFormData: ({ data }) => ({
      documents: data.panelsUpdateSubmissionDocuments.submission.documents,
    }),
  }

  const participations =
    submission.participations?.nodes || submission.data?.participations?.nodes
  const documents = uniqBy(
    flattenDeep(
      participations.map(p => [
        p.documents,
        p.quotations.nodes.map(q => q.documents),
      ])
    ),
    'id'
  )

  const handleSubmit = values => {
    return upload({
      variables: {
        input: {
          submissionId: submission.id,
          documents: mapDocs(values.documents),
        },
      },
    })
  }

  return (
    <>
      <FileCabinet
        title={`All Documents for ${submission.name}`}
        documents={submission.documents}
        uploadFile={handleSubmit}
        autoSave={autoSave}
        allowDelete={allowDelete}
      />
      <Div>
        <Subtitle>All Quotation Documents</Subtitle>
        <Panel>
          <DocumentsTable documents={documents} />
        </Panel>
      </Div>
    </>
  )
}

export default SubmissionFileCabinet
