import React from 'react'

import RichText from 'atoms/form/RichText'

function RichTextWidget({ value, onChange, onFocus, onBlur, uiSchema, rawErrors }) {
  return (
    <RichText
      required={uiSchema.required}
      label={uiSchema.label}
      input={{ value, onChange, onFocus, onBlur }}
      meta={{ error: rawErrors }}
    />
  )
}

export default RichTextWidget
