import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useCombobox } from 'downshift'
import debounce from 'lodash/debounce'

import { useReinsuranceCompaniesLazyQuery } from 'graphql/api'
import Error from 'components/common/Error'
import InputContainer from 'components/forms/InputContainer'
import Label from 'components/forms/FieldLabel'
import { StyledInput as Input } from 'components/forms/Input'

const ComboboxContainer = styled(InputContainer)`
  height: 70px;
  margin-bottom: 1rem;
  position: relative;
`

const DropdownList = styled.ul`
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
  padding: 0.5rem;
  box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.24)};
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 10000;

  & li {
    list-style-type: none;
    padding: 0.5rem 0;
    border-top: 1px solid ${props => props.theme.colors.secondaryBackground};

    &:first-child {
      border-top: none;
    }

    &:hover {
      background-color: ${props => props.theme.colors.background};
    }
  }
`

const Container = styled.div`
  display: flex;
`

const DropdownUI = ({
  input,
  meta,
  reinsuranceCompanies,
  onSearch,
  onSelect,
}) => {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
  } = useCombobox({
    items: reinsuranceCompanies,
    onInputValueChange: ({ inputValue }) => {
      inputValue !== undefined && onSearch(inputValue)
    },
    itemToString: item => (item ? item.name : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      onSelect(selectedItem)
    },
  })

  const onBlur = evt => {
    const name = evt.target.value
    const item = reinsuranceCompanies.find(item => item.name === name)
    input.onBlur(item || { name })
  }

  return (
    <ComboboxContainer>
      <Label required {...getLabelProps()}>
        Company
      </Label>
      <Container {...getComboboxProps()}>
        <Input
          {...getToggleButtonProps()}
          {...getInputProps({
            onBlur: onBlur,
            onFocus: input.onFocus,
          })}
        />
      </Container>
      {isOpen && reinsuranceCompanies.length > 0 && (
        <DropdownList {...getMenuProps()}>
          {reinsuranceCompanies.map((item, index) => (
            <li key={[item.name + index]} {...getItemProps({ item, index })}>
              {item.name}
            </li>
          ))}
        </DropdownList>
      )}
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </ComboboxContainer>
  )
}

const ReinsuranceCompaniesDropdown = ({ input, meta }) => {
  const [loadReinsuranceCompanies, { data, error, loading }] =
    useReinsuranceCompaniesLazyQuery({
      fetchPolicy: 'no-cache',
    })
  const searchReinsuranceCompanies = debounce(loadReinsuranceCompanies, 250)
  const [reinsuranceCompanies, setReinsuranceCompanies] = useState([])
  const setCompany = debounce(input.onChange, 250)

  useEffect(() => {
    if (!error && !loading) {
      if (data && data.viewer && data.viewer.reinsuranceCompanies) {
        setReinsuranceCompanies(data.viewer.reinsuranceCompanies)
      }
    }
  }, [data, error, loading])

  useEffect(() => {
    searchReinsuranceCompanies()
  }, [])

  const onSearch = inputValue => {
    searchReinsuranceCompanies({
      variables: { search: inputValue },
    })
  }

  return (
    <DropdownUI
      input={input}
      meta={meta}
      reinsuranceCompanies={reinsuranceCompanies}
      onSearch={onSearch}
      onSelect={setCompany}
    />
  )
}

export default ReinsuranceCompaniesDropdown
