import get from 'lodash/get'

import { cognitoBrandingOverrides } from './data'

const customerSSORedirectURI = customerDomain => {
  const cognitoRedirectUri = window.config.COGNITO_REDIRECT_URI
  if (!cognitoRedirectUri) return ''
  if (/relay.test/.test(cognitoRedirectUri)) {
    return `https://${customerDomain}.relay.test:3333/`
  }

  return cognitoRedirectUri.replace(/app/, customerDomain)
}

const customerSSOCognitoOverrides = customerDomain =>
  get(
    cognitoBrandingOverrides,
    [customerDomain, window.config.COGNITO_USER_POOL_ID],
    {}
  )

export { customerSSORedirectURI, customerSSOCognitoOverrides }
