import BigNumber from 'bignumber.js'

import ApplicationModel from '../../ApplicationModel'

import { SubmissionMapping } from './mappings'

class SubmissionModel extends ApplicationModel {
  constructor() {
    super({
      mapping: SubmissionMapping,
    })
  }

  get coverages() {
    return this.attributes.coverages
  }

  get productOptions() {
    return this.attributes.productOptions
  }

  get quotedLines() {
    if (!this.attributes.quotations) return []

    return this.attributes.quotations.map(q => q.quotedLines)
  }

  get name() {
    return this.attributes.name
  }

  get industry() {
    return this.attributes.industry
  }

  get id() {
    return this.attributes.id
  }

  get panelId() {
    return this.attributes.panelId
  }

  get updatedAt() {
    return this.attributes.updatedAt
  }

  get country() {
    return this.attributes.country || 'US'
  }

  get documents() {
    return this.attributes.documents
  }

  get quotations() {
    return this.attributes.quotations
  }

  get participations() {
    return this.attributes.participations
  }

  get lineOfBusiness() {
    return this.attributes.lineOfBusiness
  }

  get applicant() {
    return this.attributes.applicant
  }

  get applicantAddress() {
    return this.attributes.applicantAddress
  }

  get products() {
    return this.attributes.products
  }

  get owner() {
    return this.attributes.owner
  }

  get quotationLimits() {
    return this.getLimitOrDeductible('limit')
  }

  get quotationDeductibles() {
    return this.getLimitOrDeductible('retention')
  }

  getLimitOrDeductible(value) {
    if (!this.attributes.quotations) return []

    return this.attributes.quotations
      .flatMap(q => q.quotedLines)
      .filter(
        l =>
          l != null &&
          l !== undefined &&
          l.type === 'ManagementLiability::DirectorsAndOfficersQuotedLine'
      )
      .flatMap(line => line.coverages)
      .filter(cov => cov.name === 'Directors & Officers')
      .map(d => new BigNumber(d[`${value}`]).toNumber())
      .sort((a, b) => a - b)
      .reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      )
  }

  hasLoaded(id) {
    return this.attributes && this.attributes.id == id
  }

  get participationId() {
    return this.attributes.participationId
  }
}

export default SubmissionModel
