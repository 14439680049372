import React from 'react'
import styled from 'styled-components'

import { Text, Flex } from 'atoms'

const Radio = styled.input.attrs({
  type: 'radio',
})`
  margin-right: 0.5rem;
`

const RadioButton = ({ id, label, vertical, ...rest }) => {
  return (
    <Flex
      width="auto"
      alignItems="baseline"
      flexDirection={vertical ? 'column-reverse' : 'row'}
      {...rest}>
      <Radio id={id || label} {...rest} />
      <Text as="label" htmlFor={id || label} mr={1}>
        {label}
      </Text>
    </Flex>
  )
}

export default RadioButton
