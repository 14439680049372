const schemas = (
  schemas
  // userType,
  // formData,
  // updateFormData,
  // manual = false,
  // editQuotation = false
) => {
  return schemas
}

export default { schemas }
