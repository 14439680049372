import {
  faGem,
  faHome,
  faFileAlt,
  faHandHoldingUsd,
  faList,
  faCheckCircle,
  faCar,
  faUmbrella,
  faPaperPlane,
  faFire,
  faFingerprint,
  faBriefcase,
} from '@fortawesome/pro-solid-svg-icons'

const getIcon = icon => {
  switch (icon) {
    case 'home':
      return faHome
    case 'gem':
      return faGem
    case 'file-alt':
      return faFileAlt
    case 'hand-holding-usd':
      return faHandHoldingUsd
    case 'check-circle':
      return faCheckCircle
    case 'car':
      return faCar
    case 'umbrella':
      return faUmbrella
    case 'paper-plane':
      return faPaperPlane
    case 'fire':
      return faFire
    case 'fingerprint':
      return faFingerprint
    case 'briefcase':
      return faBriefcase
    default:
      return faList
  }
}

export default getIcon
