import React, { useEffect, useState } from 'react'
import { CurrencyDropdownWidget } from 'pages/shared/panels'
import styled from 'styled-components'

const Wrapper = styled.div`
  background: #edeae1;
  border-radius: 5px;
  display: flex;
  padding: 20px;
`
const Label = styled.span`
  flex-grow: 1;
  line-height: 30px;
  font-size: 16px;
`
const Clickable = styled.span`
  cursor: pointer;
  color: ${props => props.theme.colors.link};
  line-height: 30px;
  min-width: 100px;
  text-align: center;
  font-size: 16px;
`
const CurrencyWrapper = styled.div`
  width: 140px;
  font-size: 16px;
  line-height: 30px;
  padding: 0 20px;
`
const Button = styled.button`
  background: #357ffa;
  color: #ffffff;
  border-radius: 5px;
  text-align: center;
  width: 60px;
  font-size: 16px;
  line-height: 30px;
`
const generateOptions = options => {
  return {
    enumOptions: options.map(option => ({
      label: option.toString(),
      value: option,
    })),
  }
}

const BulkEdit = ({ schema, onChange }) => {
  const { options, updateSelect, updateValues, formData, formatData } =
    schema.default
  const extractSelected = data => {
    let rows = []
    Object.keys(data).forEach(key => {
      if (data[key] && key !== 'ignore') {
        rows.push(key)
      }
    })
    return rows
  }
  const initialValues = value => {
    const newValues = []
    options?.forEach(() => {
      newValues.push(value)
    })
    return newValues
  }
  const [data, updateData] = useState(formatData(formData))
  const [selected, updateSelected] = useState(extractSelected(data))
  const [values, updateAllValues] = useState(initialValues(''))

  const selectAll = () => {
    let keys = Object.keys(data)
    updateSelect(formData, keys, true)
    setTimeout(() => {
      onChange({
        ...formData,
        selected: keys,
      })
    }, 50)
  }
  const deselectAll = () => {
    let keys = Object.keys(data)
    updateSelect(formData, keys, false)
    setTimeout(() => {
      onChange({
        ...formData,
        selected: [],
      })
    }, 50)
  }

  const applyBulkEdit = event => {
    event.preventDefault()
    const newValues = initialValues('')
    updateValues(formData, selected, values)
    updateAllValues(newValues)
    setTimeout(() => {
      onChange({
        ...formData,
        selected: newValues,
      })
    }, 50)
  }
  const updateValueAtIndex = index => value => {
    const newValues = [...values]
    newValues[index] = value
    updateAllValues(newValues)
  }
  useEffect(() => {
    const newData = formatData(formData)
    updateData(newData)
    updateSelected(extractSelected(newData))
  }, [formData])

  return (
    <Wrapper>
      <Label>Bulk Edit {selected.length} rows</Label>
      <Clickable onClick={selectAll}>Select All</Clickable>
      <Clickable onClick={deselectAll}>Select None</Clickable>
      {options?.map((option, i) => (
        <CurrencyWrapper key={`currency-option-${i}`}>
          <CurrencyDropdownWidget
            value={values[i]}
            onChange={updateValueAtIndex(i)}
            name={option.label}
            label={option.label}
            options={generateOptions(option.values)}
          />
        </CurrencyWrapper>
      ))}
      <Button onClick={applyBulkEdit}>Apply</Button>
    </Wrapper>
  )
}

export default BulkEdit
