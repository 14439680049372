import {
  TextRenderer,
  TitleRenderer,
  MoneyRenderer,
  MultiTitleRenderer,
  MultiColumnRenderer,
} from '../../../renderers'
import { uniq } from 'util/lodash'

const getValuableLine = quotation => {
  return quotation.quotedLines?.find(
    ql => ql.__typename === 'PCGValuablesQuotedLineType'
  )
}

const getValuableLines = quotations => {
  return quotations
    .flatMap(quote =>
      quote.quotedLines?.filter(
        ql => ql.__typename === 'PCGValuablesQuotedLineType'
      )
    )
    .filter(Boolean)
}
const getCoverages = quotations => {
  const valuables = getValuableLines(quotations)
  const allCoverages = valuables.flatMap(vl => vl.coverages).filter(Boolean)
  return uniq(allCoverages.map(coverage => coverage.name))
}
const getAdditionalFeatures = quotations => {
  const valuables = getValuableLines(quotations)
  const allFeatures = valuables
    .flatMap(vl => vl.additionalFeatures)
    .filter(Boolean)
  return uniq(allFeatures.map(feature => feature.feature))
}
const renderValuablesCoverage = () => {
  return {
    label: 'Valuable Assets',
    renderer: TitleRenderer,
    icon: 'gem',
  }
}

const renderValuablesQuotedLine = (quotedLine, idx) => {
  return [
    {
      __typename: 'PCGValuablesQuotedLineType',
      label: 'Valuable Assets',
      renderer: TitleRenderer,
      icon: 'gem',
    },
    {
      label: 'Coverages',
      renderer: MultiTitleRenderer,
      titles: ['Limit', 'Bkt/Item Limit'],
    },
    quotations => {
      const coverages = getCoverages(quotations, quotedLine)

      const finder = (quotation, criterion) => {
        const valuable = getValuableLine(quotation)
        return valuable?.coverages?.find(
          coverage => coverage.name === criterion.label
        )
      }
      return coverages.map(coverage => ({
        label: coverage,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [
          { renderer: MoneyRenderer, key: 'limit' },
          { renderer: MoneyRenderer, key: 'blanketLimit' },
        ],
      }))
    },
    {
      label: 'Additional Features',
      renderer: MultiTitleRenderer,
      titles: ['Included?'],
    },
    quotations => {
      const features = getAdditionalFeatures(quotations)

      const finder = (quotation, criterion) => {
        const valuable = getValuableLine(quotation)
        return valuable?.additionalFeatures?.find(
          feature => feature.feature === criterion.label
        )
      }
      return features.map(feature => ({
        label: feature,
        indent: true,
        renderer: MultiColumnRenderer,
        finder,
        columns: [{ renderer: TextRenderer, key: 'included', props: {} }],
      }))
    },
    {
      key: `quotedLines[${idx}].totalPremium`,
      label: 'TOTAL PREMIUM',
      renderer: MoneyRenderer,
    },
  ]
}

export {
  renderValuablesQuotedLine,
  renderValuablesCoverage,
  getValuableLine,
  getValuableLines,
  getCoverages,
  getAdditionalFeatures,
}
