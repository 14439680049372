import React from 'react'
import { Button, ButtonContainer, Div } from 'atoms'
import { Field } from 'react-final-form'

import { Dropdown, TextArea, TextInput } from 'atoms/form'
import { SimpleUploader } from 'molecules/DocumentUpload'
import { required } from 'util/validators'
import { convertToOption } from 'util/forms'
import { QUESTION } from '../QAndA'
import { qandaTranslator } from 'util/translations'

const QuestionForm = ({
  onSubmit,
  documentCategory,
  sections = [],
  onClose,
  followUp,
  scope = QUESTION,
}) => {
  const sectionChoices = sections.map(convertToOption)
  const translator = qandaTranslator(scope)

  return (
    <>
      {followUp ? (
        <Div>
          <Field
            mb={2}
            name="attributes.section"
            label="Section"
            component={TextInput}
            disabled
            required></Field>
        </Div>
      ) : (
        <Field
          mb={2}
          name="attributes.section"
          label="Section"
          required
          validate={required({
            message: 'Enter Section',
          })}
          component={Dropdown}
          creatable
          options={sectionChoices}
        />
      )}
      <Field
        component={TextArea}
        name="attributes.text"
        label={translator('Question')}
        required
        validate={required()}
        mb={2}></Field>
      <SimpleUploader
        label="Documents"
        name="attributes.documents"
        defaultCategory={documentCategory}
      />
      <ButtonContainer justifyContent="space-between">
        <Button onClick={onClose} light>
          Cancel
        </Button>
        <Button onClick={onSubmit} primary>
          Send {translator('Question')}
        </Button>
      </ButtonContainer>
    </>
  )
}

export default QuestionForm
