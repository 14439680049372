import React from 'react'
import styled from 'styled-components'
import { branch, renderComponent, mapProps } from 'recompose'
import { path, useWith } from 'ramda'

import { fonts, colors } from '../components/theme'
import Loading from '../components/Loading'

const ErrorText = styled.p`
  font-size: 1.5rem;
  color: ${colors.error};
  font-family: ${fonts.primary};
`

const Error = ({ data: { error } }) => {
  try {
    return <ErrorText>{error.toString()}</ErrorText>
  } catch (error) {
    return <ErrorText>An error occurred</ErrorText>
  }
}

export const handleErrors = branch(
  ({ data: { error } }) => !!error,
  renderComponent(Error)
)

export const handleLoading = branch(
  ({ data: { loading } }) => !!loading,
  renderComponent(Loading)
)

export const extract = useWith(mapProps, [path])

export function stripTypenames(obj, propToDelete) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property
      const newData = stripTypenames(obj[property], propToDelete)
      obj[property] = newData
    } else {
      if (property === propToDelete) {
        delete obj[property]
      }
    }
  }
  return obj
}
