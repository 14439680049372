import React from 'react'
import styled from 'styled-components'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import { Text, Div, Icon } from 'atoms'

const Container = styled(Div)`
  background: ${props => props.theme.colors.blueGradient};
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadows.small};
  border-left: ${props => `12px solid ${props.theme.colors.primaryText}`};
  padding: 1rem;
  display: flex;
  align-items: center;
`
const List = styled.ul`
  margin-bottom: 0px;
`
const Alert = ({ formData = {} }) => {
  const { title, subtitle, message, errors = [] } = formData
  if (!title && !subtitle && !message && !errors.length) return null
  return (
    <Container>
      <Icon
        mr={2}
        fontSize="xxl"
        color="primaryAction"
        icon={faExclamationTriangle}
      />
      <div>
        {title && (
          <Text display="block" fontWeight={2} fontSize="l">
            {title}
          </Text>
        )}

        {subtitle && <Text>{subtitle}</Text>}
        <List>
          {errors.map((error, i) => (
            <li key={i}>
              <Text>{error}</Text>
            </li>
          ))}
        </List>

        {message && <Text>{message}</Text>}
      </div>
    </Container>
  )
}

export default Alert
