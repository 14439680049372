import * as Sentry from '@sentry/react'
import find from 'lodash/find'
import { colors } from 'components/theme'
import {
  faBroadcastTower,
  faCheck,
  faCheckCircle,
  faClipboardCheck,
  faClock,
  faComment,
  faDollarSign,
  faDotCircle,
  faEdit,
  faExchange,
  faFileContract,
  faFileImport,
  faFileInvoiceDollar,
  faFileSignature,
  faHandReceiving,
  faHandsHelping,
  faLongArrowAltRight,
  faPaperclip,
  faQuestionCircle,
  faRedoAlt,
  faScroll,
  faShare,
  faTimesCircle,
  faTimesSquare,
  faUserFriends,
} from '@fortawesome/pro-solid-svg-icons'

const ALL_NOTIFICATIONS = [
  {
    name: 'Worksheet.Created',
    text: 'Submission Created',
    icon: faFileContract,
    color: colors.link,
  },
  {
    name: 'Message.Received',
    text: 'New Message',
    icon: faComment,
    color: colors.link,
  },
  {
    name: 'MessageWithAttachment.Received',
    text: 'New Message + Attachment',
    icon: faPaperclip,
    color: colors.link,
  },
  {
    name: 'Broadcast.Received',
    text: 'New Broadcast Message',
    icon: faBroadcastTower,
    color: colors.link,
  },
  {
    name: 'BroadcastWithAttachment.Received',
    text: 'New Broadcast + Attachment',
    icon: faPaperclip,
    color: colors.link,
  },
  {
    name: 'Quote.Created',
    text: 'Quote Received',
    icon: faDollarSign,
    color: colors.quote,
  },
  {
    name: 'Quote.Provided',
    text: 'Quote Received',
    icon: faDollarSign,
    color: colors.quote,
  },
  {
    name: 'Quote.Modified',
    text: 'Revised Quote Received',
    icon: faDollarSign,
    color: colors.quote,
  },
  {
    name: 'Quote.Accepted',
    text: 'Quote Accepted',
    icon: faDollarSign,
    color: colors.link,
  },
  {
    name: 'Quote.Approved',
    text: 'Quote Approved by Client',
    icon: faCheck,
    color: colors.link,
  },
  {
    name: 'Quote.Requested',
    text: 'Quote Requested',
    icon: faDollarSign,
    color: colors.quote,
  },
  {
    name: 'Quote.Rejected',
    text: 'Revision Requested',
    icon: faRedoAlt,
    color: colors.quote,
  },
  {
    name: 'Quote.Declined',
    text: 'Quote Declined',
    icon: faTimesCircle,
    color: colors.quote,
  },
  {
    name: 'Quote.DeclinedOutdated',
    text: 'Quote Declined',
    icon: faTimesCircle,
    color: colors.quote,
  },
  {
    name: 'Quote.DeclinationConfirmed',
    text: 'Quote Declined',
    icon: faTimesCircle,
    color: colors.quote,
  },
  {
    name: 'Proposal.Accepted',
    text: 'Counter-Proposal Accepted. Bind?',
    icon: faRedoAlt,
    color: colors.quote,
  },
  {
    name: 'Proposal.Declined',
    text: 'Counter-Proposal Declined',
    icon: faTimesCircle,
    color: colors.quote,
  },
  {
    name: 'Quote.Proposed',
    text: 'Counter-Proposal Received',
    icon: faRedoAlt,
    color: colors.quote,
  },
  {
    name: 'Quote.ProposalModified',
    text: 'Counter-Proposal Modified',
    icon: faRedoAlt,
    color: colors.quote,
  },
  {
    name: 'Binder.Requested',
    text: 'Request to Bind Coverage Received',
    icon: faFileInvoiceDollar,
    color: colors.binder,
  },
  {
    name: 'Binder.Created',
    text: 'Coverage Bound',
    icon: faFileInvoiceDollar,
    color: colors.binder,
  },
  {
    name: 'Binder.PolicyProvided',
    text: 'Policy Number Provided',
    icon: faScroll,
    color: colors.binder,
  },
  {
    name: 'Certificate.Requested',
    text: 'Certificate Required',
    icon: faCheckCircle,
    color: colors.certificate,
  },
  {
    name: 'Certificate.Issued',
    text: 'Certificate Received',
    icon: faCheckCircle,
    color: colors.certificate,
  },
  {
    name: 'Worksheet.ApprovalRequested',
    text: 'Submission Approval Requested',
    icon: faClipboardCheck,
    color: colors.submission,
  },
  {
    name: 'Worksheet.Approved',
    text: 'Submission Approved',
    icon: faClipboardCheck,
    color: colors.submission,
  },
  {
    name: 'Quote.ApprovalRequested',
    text: 'Quote Approval Requested',
    icon: faClipboardCheck,
    color: colors.quote,
  },
  {
    name: 'QAndA.QuestionReceived',
    text: 'Question Received',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.QuestionModified',
    text: 'Question Modified',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.QuestionDeleted',
    text: 'Question Retracted',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.AnswerApproved',
    text: 'Answer Approved',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.AnswerUnapproved',
    text: 'Answer Unapproved',
    icon: 'question-circle',
    color: colors.submission,
  },
  {
    name: 'QAndA.AnswerEdited',
    text: 'Answer Edited',
    icon: 'question-circle',
    color: colors.submission,
  },
  {
    name: 'QAndA.AnswerReceived',
    text: 'Answer Received. Approve?',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.AnswerModified',
    text: 'Answer Modified',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.AnswerDeleted',
    text: 'Answer Retracted',
    icon: faQuestionCircle,
    color: colors.submission,
  },

  {
    name: 'QAndA.SubjectivityReceived',
    text: 'Subjectivity Received',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.SubjectivityModified',
    text: 'Subjectivity Modified',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.SubjectivityDeleted',
    text: 'Subjectivity Retracted',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.ResponseApproved',
    text: 'Response Approved',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.ResponseReceived',
    text: 'Response Received. Approve?',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.ResponseModified',
    text: 'Response Modified',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'QAndA.ResponseDeleted',
    text: 'Response Retracted',
    icon: faQuestionCircle,
    color: colors.submission,
  },
  {
    name: 'RepsAndWarranties.Binder.Requested',
    text: 'Binder Requested By Broker',
    icon: faFileInvoiceDollar,
    color: colors.binder,
  },
  {
    name: 'RepsAndWarranties.Binder.Created',
    text: 'Coverage Bound By Insurer',
    icon: faFileInvoiceDollar,
    color: colors.binder,
  },
  {
    name: 'RepsAndWarranties.Deal.Closed',
    text: 'Deal Marked as Closed',
    icon: faCheckCircle,
    color: colors.binder,
  },
  {
    name: 'RepsAndWarranties.Policy.Created',
    text: 'Policy Issued, Deal Complete',
    icon: faCheckCircle,
    color: colors.certificate,
  },
  {
    name: 'RepsAndWarranties.NDA.Revised',
    text: 'NDA Revised',
    icon: faFileSignature,
    color: colors.submission,
  },
  {
    name: 'RepsAndWarranties.NDA.Declined',
    text: 'NDA Declined',
    icon: faFileSignature,
    color: colors.submission,
  },
  {
    name: 'RepsAndWarranties.NDA.ExecutedAndAwaiting',
    text: 'NDA Executed | Awaiting Submission',
    icon: faFileSignature,
    color: colors.submission,
  },
  {
    name: 'RepsAndWarranties.NDA.ExecutedAndSent',
    text: 'NDA Executed | Submission Sent',
    icon: faFileSignature,
    color: colors.submission,
  },
  {
    name: 'RepsAndWarranties.Document.Forwarded',
    text: 'Document Forwarded',
    icon: faLongArrowAltRight,
    color: colors.submission,
  },
  {
    name: 'RepsAndWarranties.NDA.SubmissionReleased',
    text: 'Submission Released | Ready to Quote',
    icon: faFileSignature,
    color: colors.submission,
  },
  {
    name: 'Worksheet.DocumentUploaded',
    text: 'Submission Document Uploaded',
    icon: faFileImport,
    color: colors.submission,
  },
  {
    name: 'Broker.Invited',
    text: 'New Placement Request from Client',
    icon: faHandsHelping,
    color: colors.submission,
  },
  {
    name: 'Broker.AcceptedInvite',
    text: 'Placement Invitation Accepted by Broker',
    icon: faHandsHelping,
    color: colors.submission,
  },
  {
    name: 'Quote.BrokerCommentUpdate',
    text: 'Quote Comment Modified by Broker',
    icon: faComment,
    color: colors.submission,
  },
  {
    name: 'RepsAndWarranties.Quote.Provided',
    text: 'Quote Provided',
    icon: faFileInvoiceDollar,
    color: colors.quote,
  },
  {
    name: 'RepsAndWarranties.Quote.Declined',
    text: 'Quote Request Declined',
    icon: faTimesSquare,
    color: colors.quote,
  },
  {
    name: 'RepsAndWarranties.Quote.Requested',
    text: 'Quote Requested',
    icon: faDollarSign,
    color: colors.quote,
  },
  {
    name: 'RepsAndWarranties.Deal.Cancelled',
    text: 'Deal Cancelled',
    icon: faTimesSquare,
    color: colors.submission,
  },
  {
    name: 'RepsAndWarranties.Quote.Selected',
    text: 'Quote Selected',
    icon: faFileInvoiceDollar,
    color: colors.quote,
  },
  {
    name: 'Connection.Invited',
    text: 'Connection Invitation Received',
    icon: faUserFriends,
    color: colors.quote,
  },
  {
    name: 'Connection.Accepted',
    text: 'Connection Invitation Accepted',
    icon: faUserFriends,
    color: colors.quote,
  },

  // Time-Based Notifications

  {
    name: 'Quote.ProposalAwaitingResponse',
    text: 'Counter-Proposal Awaiting Response',
    icon: faClock,
    color: colors.quote,
  },

  {
    name: 'Worksheet.UpcomingRenewal',
    text: 'Upcoming Renewal in 90 Days',
    icon: faClock,
    color: colors.primaryText,
  },
  {
    name: 'Quote.UpcomingExpiry',
    text: 'Quote Expires Tomorrow',
    icon: faClock,
    color: colors.quote,
  },
  {
    name: 'Worksheet.UpcomingPolicy',
    text: 'No Certificate Received',
    icon: faClock,
    color: colors.quote,
  },
  {
    name: 'Quote.AwaitingResponse',
    text: 'Quote Awaiting Response',
    icon: faClock,
    color: colors.quote,
  },
  {
    name: 'Quote.ProposalAwaitingResponse',
    text: 'Counter-Proposal Awaiting Response',
    icon: faClock,
    color: colors.quote,
  },
  {
    name: 'Binder.AwaitingCertificate',
    text: 'Certificate Not Yet Provided',
    icon: faClock,
    color: colors.certificate,
  },
  {
    name: 'Quote.AwaitingBinder',
    text: 'Binder Not Yet Provided',
    icon: faClock,
    color: colors.binder,
  },
  {
    name: 'Quote.UpcomingRenewal',
    text: 'Upcoming Renewal in 90 Days',
    icon: faClock,
    color: colors.link,
  },
  {
    name: 'Worksheet.AwaitingApproval',
    text: 'Submission Awaiting Your Approval',
    icon: faClipboardCheck,
    color: colors.submission,
  },
  {
    name: 'Proposal.DeclinedAwaitingResponse',
    text: 'Counter-Proposal Declined | Accept Original Quote?',
    icon: faClock,
    color: colors.quote,
  },
  {
    name: 'Proposal.AcceptedAwaitingResponse',
    text: 'Counter-Proposal Accepted | Request to Bind?',
    icon: faClock,
    color: colors.quote,
  },
  {
    name: 'Policy.Issued',
    text: 'Policy Issued',
    icon: faCheckCircle,
    color: colors.certificate,
  },
  {
    name: 'Quote.Referred',
    text: 'Quote Referred',
    icon: faShare,
    color: colors.quote,
  },
  {
    name: 'Quote.Bound',
    text: 'Quote Bound',
    icon: faFileInvoiceDollar,
    color: colors.binder,
  },
  {
    name: 'Submission.Edited',
    text: 'Application Modified',
    icon: faEdit,
    color: colors.primary,
  },
  {
    name: 'Submission.ShareableReceived',
    text: 'Shareable Application Received',
    icon: faHandReceiving,
    color: colors.primary,
  },
  {
    name: 'Submission.ShareableReceived',
    text: 'Shareable Application Received',
    icon: faHandReceiving,
    color: colors.primary,
  },
  {
    name: 'Submission.Reassigned',
    text: 'Submission Reassigned to You',
    icon: faExchange,
    color: colors.primary,
  },
  {
    name: 'Worksheet.Reassigned',
    text: 'Submission Reassigned to You',
    icon: faExchange,
    color: colors.primary,
  },
]

const fallback = event => {
  Sentry.captureMessage(`Missing notification translation for ${event}`)
  return {
    text: event.split('.').join(' '),
    icon: faDotCircle,
    color: colors.link,
  }
}

const translateNotification = event => {
  return find(ALL_NOTIFICATIONS, { name: event }) || fallback(event)
}

export default translateNotification
