import React from 'react'
import { Widgets } from '@rjsf/material-ui'
import { unansweredDataTestId, unansweredStyle } from './utils'
import { useSubmission, usePanel } from 'hooks'
import { Panel } from 'atoms'

// Example usage in uiSchema of a field:
// "ui:options": {
//   "htmlAttributes": {
//     "autocomplete": "disabled"
//   }
// }
// See: https://react-jsonschema-form.readthedocs.io/en/latest/advanced-customization/custom-widgets-fields/#custom-widget-options
const CustomText = props => {
  const submission = useSubmission()
  const panel = usePanel()
  const { id, options, value, schema, uiSchema } = props

  const isUnanswered = !value
  const { 'ui:firmup': firmup = false } = uiSchema

  const dataTestId = unansweredDataTestId(isUnanswered, firmup)
  const style = unansweredStyle(isUnanswered, firmup)

  const showWarning = () => {
    if (options.warningType === 'submitAdditional') {
      const submissionVal = submission?.questions[id.replace('root_', '')]
      const apiUsers = submission.participations.nodes
        .filter(p => p.apiUser)
        .map(p => p.apiUser.id)

      const existingAnswer = !!submissionVal && submissionVal !== value
      const prevUnanswered =
        !submissionVal &&
        value &&
        (schema.carriers.includes('ALL') ||
          !!panel.panelists.nodes
            .filter(el => apiUsers.includes(el.id))
            .find(el => schema.carriers?.includes(el.firstName.toLowerCase())))

      return !!existingAnswer || !!prevUnanswered
    } else {
      return false
    }
  }
  return (
    <>
      <Widgets.TextWidget
        data-testid={dataTestId}
        style={style}
        {...props}
        {...(props.options?.htmlAttributes
          ? { inputProps: props.options.htmlAttributes }
          : {})}></Widgets.TextWidget>
      {showWarning() && (
        <Panel info gradient={25} m="1rem" p="1rem">
          {options.warningMsg}
        </Panel>
      )}
    </>
  )
}

export default CustomText
