import React, { memo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons'

import { Div, Icon, Column } from 'atoms'
import PageWrapper from 'components/common/PageWrapper'
import Nav from './Nav'
import SettingsMenu from './menu/SettingsMenu'
import { useBranding, useCurrentUser } from 'hooks'
import SoftwareUpdateVersionNotification from 'components/SoftwareUpdateVersionNotification'

const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  background: ${props => props.theme.colors.header};

  @media (max-width: 1320px) {
    padding: 0 1rem;
  }
`

const HeaderWrapper = styled(PageWrapper)`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0;
  padding-top: 0;
`

const MainMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 0
  bottom: 100%;
  left: 0;
  right: 0;
  color: white;
  background-color: ${props => props.theme.colors.link};
  opacity: 0;
  transition: 0.5s ease;
`

const Img = styled.img`
  height: auto;
  width: auto;
  max-height: 65px;
`

// This needs to be a div around the image, not the image itself for IE11
// compatibility
const HeaderLogo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0px 20px;
  &:hover ${Overlay} {
    bottom: 0;
    height: 100%;
    opacity: 1;
  }
`

const ZENDESK_LINK =
  'https://relayplatformhelp.zendesk.com/hc/en-us/requests/new?ticket_form_id=1500000193102'

const Header = memo(() => {
  const { logo, logoAlt, isRelay } = useBranding()
  const currentUser = useCurrentUser()

  const linkHeader =
    currentUser &&
    (currentUser.termsAndConditionsAcceptedAt ||
      currentUser.type !== 'Reinsurer') &&
    !currentUser.pendingInvitation

  const showNav =
    currentUser &&
    !currentUser.pendingInvitation &&
    (currentUser.type !== 'Reinsurer' ||
      currentUser.termsAndConditionsAcceptedAt)

  return (
    <>
      <SoftwareUpdateVersionNotification />
      <HeaderContainer>
        <HeaderWrapper>
          <MainMenu>
            {linkHeader ? (
              currentUser.type === 'Reinsurer' && isRelay ? (
                <a target="_blank" rel="noreferrer" href={ZENDESK_LINK}>
                  <HeaderLogo>
                    <Img src={logo} alt={logoAlt} />
                    <Overlay>
                      <Column alignItems="center">
                        <Icon icon={faPlusCircle} />
                        <Div>Customize Logo</Div>
                      </Column>
                    </Overlay>
                  </HeaderLogo>
                </a>
              ) : (
                <Link to="/">
                  <HeaderLogo>
                    <Img src={logo} alt={logoAlt} />
                  </HeaderLogo>
                </Link>
              )
            ) : (
              <HeaderLogo>
                <Img src={logo} alt={logoAlt} />
              </HeaderLogo>
            )}
            {showNav && <Nav />}
          </MainMenu>
          {showNav && <SettingsMenu />}
        </HeaderWrapper>
      </HeaderContainer>
    </>
  )
})
Header.displayName = 'Header'

export default Header
