import { mapJson, mapFields } from 'util/jsonpath'

export const InsuringAgreementMapping = {
  ...mapFields(['name', 'value']),
}

export const QuotedLinesMapping = {
  ...mapFields([
    'annualPremium',
    'maximumCommissionPercentage',
    'retailCommissionPercentage',
    'wholesalerCommissionPercentage',
    'totalCommissionPercentage',
    'retailCommission',
    'clientCost',
    'retailCommission',
    'wholesalerCommission',
    'policyNumber',
    'coverageStartDate',
    'taxAmount',
    '__typename',
  ]),
  policyNumber: quotedLine => quotedLine.policyNumber,
}

export const QuotationMapping = {
  ...mapFields([
    'id',
    'carrierName',
    'declinationReason',
    'participationId',
    'submissionId',
    'status',
    'documents',
    'requestedDeductible',
    'limit',
    'admitted',
    'selected',
    'underwriterFirstName',
    'underwriterLastName',
    'underwriterEmail',
    '__typename',
  ]),
  requestedLimit: quotation =>
    quotation.requestedLimit > 0 ? quotation.requestedLimit : quotation.limit,
  ambestRating: 'user.company.ambestRating.rating',
  isApi: 'collaborator.api',
  isOutdated: quotation => quotation.status === 'outdated',
  insuringAgreements: quotation => {
    if (quotation.insuringAgreements) {
      return quotation.insuringAgreements.map(agreement =>
        mapJson(agreement || {}, InsuringAgreementMapping)
      )
    }
    return []
  },
  quotedLines: quotation => {
    if (quotation.quotedLines) {
      const terrorismLines = quotation.quotedLines.nodes.filter(
        line => line.__typename === 'TerrorismQuotedLineType'
      )
      return (
        terrorismLines?.map(line => mapJson(line || {}, QuotedLinesMapping)) ||
        []
      )
    }
  },
  carrier: ['user.company.name', 'externalCarrier.name'],
  logoUrl: ['user.company.logoUrl', 'externalCarrier.logoUrl'],
}
