import { COMPLETED_STATES } from 'panels/constants'
import { clone } from 'util/lodash'
import {
  CyberCriteria,
  MultilineCyberCriteria,
  PCGCriteria,
  MLCriteria,
  TerrorismCriteria,
  BOPCriteria,
} from 'molecules/PanelQuoteComparator/criteria'
import CyberModel from 'models/panels/cyber'
import PCGModel from 'models/panels/privateClients'
import MLModel from 'models/panels/managementLiability'
import MultilineModel from 'models/panels/multiline'

export const getConfig = (defaultConfig, config) => {
  const configCopy = clone(config)
  Object.keys(defaultConfig).forEach(key => {
    if (typeof defaultConfig[key] === 'function') {
      configCopy[key] = configCopy[key] || defaultConfig[key]
    } else {
      configCopy[key] = configCopy[key]
        ? {
            ...defaultConfig[key],
            ...configCopy[key],
          }
        : defaultConfig[key]
    }
  })
  return configCopy
}

export const mapApplicantAddressFields = (address = {}) => {
  const addressFields = [
    'country',
    'city',
    'state',
    'street_line1',
    'street_line2',
    'zip_code',
  ]
  const parsedAddress = addressFields.reduce((acc, key) => {
    acc[key] = address[key] || undefined
    return acc
  }, {})
  if (!parsedAddress.state) {
    parsedAddress['state'] = 'Unknown'
  }
  return parsedAddress
}

export const insertBulkEdit = (
  schemas,
  formData,
  updateFormData,
  afterAttribute,
  formatData
) => {
  schemas.schema.properties.bulk_edit = {
    type: 'object',
    title: '',
    default: formatData(formData, updateFormData),
    required: [],
    properties: {
      id: {
        type: 'string',
      },
    },
  }
  schemas.uiSchema.bulk_edit = { 'ui:field': 'bulkEdit', cols: 12 }
  insertToAfterKeyUiOrder(schemas, afterAttribute, 'bulk_edit')
}

export const insertSelectSectionCheckbox = (
  schemas,
  key,
  ignoreList,
  updateFormData
) => {
  const entry =
    schemas.schema.properties[key].properties[key].properties.checkbox
  entry.config = {
    title: entry.title,
    formatData: data => checkListValues(data[key], ignoreList),
    update: (formData, values) =>
      updateCheckListSelected(formData, updateFormData, key, values),
    itemValue: key,
  }
  schemas.uiSchema[key][key].checkbox['ui:field'] = 'selectSectionCheckbox'
}

export const checkListValues = (section, ignoreList) => {
  let checkList = {}
  if (section) {
    Object.keys(section).forEach(subKey => {
      if (!ignoreList.includes(subKey)) {
        checkList[subKey] = section[subKey].checkbox
      }
    })
  }
  return checkList
}

const updateCheckListSelected = (
  formData,
  updateFormData,
  sectionKey,
  values
) => {
  const formDataCopy = { ...formData }
  Object.keys(formData).forEach(key => {
    const entry = formData[key]
    if (sectionKey === key) {
      Object.keys(values).forEach(subKey => {
        entry[subKey] = {
          ...entry[subKey],
          checkbox: values[subKey],
        }
      })
    }
    formData[key] = entry
  })
  updateFormData(formDataCopy)
}

export const nestedCheckListValues = (
  formData,
  ignoreListGroup,
  addIgnore = false
) => {
  let checkList = {}
  if (addIgnore) {
    checkList.ignore = {}
  }
  Object.keys(formData).forEach(key => {
    if (!ignoreListGroup.entry.includes(key)) {
      checkList = {
        ...checkList,
        ...checkListValues(formData[key], ignoreListGroup.subEntry),
      }
    } else {
      if (addIgnore && key !== 'bulk-edit') {
        checkList.ignore = {
          ...checkList.ignore,
          [key]: formData[key],
        }
      }
    }
  })

  return checkList
}

export const insertToAfterKeyUiOrder = (schemas, target, key) => {
  const targetIndex = schemas.uiSchema['ui:order'].indexOf(target) + 1
  schemas.uiSchema['ui:order'] = [
    ...schemas.uiSchema['ui:order'].slice(0, targetIndex),
    key,
    ...schemas.uiSchema['ui:order'].slice(targetIndex),
  ]
}

export const removeKeyFromUiOrder = (schemas, key) => {
  const keyIndex = schemas.uiSchema['ui:order'].indexOf(key)
  schemas.uiSchema['ui:order'].splice(keyIndex, 1)
}

export const appendToUiOrder = (schemas, values) => {
  schemas.uiSchema['ui:order'] = [...schemas.uiSchema['ui:order'], ...values]
}

export const checkForDoneQuotations = (quotations, currentQuotationId) => {
  const otherQuotations = quotations.filter(
    quotation => quotation.id !== currentQuotationId
  )

  return otherQuotations.some(quotation =>
    COMPLETED_STATES.includes(quotation.status.toLowerCase())
  )
}
export const checkForAnyDoneQuotation = (quotations = []) => {
  return quotations.some(quotation =>
    COMPLETED_STATES.includes(quotation.status.toLowerCase())
  )
}
export const lineKeys = {
  ManagementLiabilityProduct: 'management_liability',
  TerrorismProduct: 'terrorism',
  CyberProduct: 'cyber',
  BOPProduct: 'business_owner',
}

export const getCriteriaForLine = lineOfBusiness => {
  switch (lineOfBusiness) {
    case 'Cyber':
      return CyberCriteria
    case 'Multiline Cyber':
      return MultilineCyberCriteria
    case 'Private_Clients':
      return PCGCriteria
    case 'Management Liability':
      return MLCriteria
    case 'Terrorism':
      return TerrorismCriteria
    case 'Business_Owner':
      return BOPCriteria
  }
}

export const getModelForLine = lineOfBusiness => {
  switch (lineOfBusiness) {
    case 'Cyber':
      return CyberModel
    case 'Multiline':
      return MultilineModel
    case 'Private_Clients':
      return PCGModel
    case 'Management Liability':
      return MLModel
    case 'Business_Owner':
      return MultilineModel
  }
}
export const queryDefaults = {
  participationQuotations: {
    getBroker: participation => participation.submission.owner,
    getCarrier: participation => participation.user,
  },
}
