import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

import _Icon from 'atoms/Icon'
import { Row } from 'atoms'

const Clickable = styled(Row)`
  cursor: pointer;
  ${space}
`

const Icon = styled(_Icon)`
  width: 24px;
  height: 24px;
  background-color: ${props => props.theme.colors.link};
  border-radius: 12px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: ${props => (props.disabled ? 'none' : 'pointer')};
`

const IconLink = ({ children, disabled = false, onClick, icon, ...rest }) => {
  return (
    <Clickable onClick={onClick} alignItems="baseline" color="link" {...rest}>
      <Icon disabled={disabled} icon={icon} color="white" />
      {children}
    </Clickable>
  )
}

export default IconLink
export { Clickable }
