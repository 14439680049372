import MultilineCyberConfig from './MultilineCyber'
import CyberConfig from './Cyber'
import MLConfig from './ML'
import BOPConfig from './BOP'
import TerrorismConfig from './Terrorism'
import { checkForDoneQuotations } from './utils'
import { includedInQuotationStatus } from '..'
import { COMPLETED_STATES } from 'panels/constants'

const lineOfBusiness = product => {
  switch (product.__typename) {
    case 'ManagementLiabilityProduct':
      return 'Management Liability'
    case 'BOPProduct':
      return 'Business Owner Policy'
    default:
      return product.__typename.replace(/Product/, '')
  }
}

const emailCarriers = markets => {
  let emailCarrierMapping = {}
  markets.forEach(market => {
    market.contacts.nodes.forEach(contact => {
      const user = contact.reinsurer
      if (!emailCarrierMapping[user.email]) {
        emailCarrierMapping[user.email] = user.id
      }
    })
  })
  return emailCarrierMapping
}

const defaultMessage = type =>
  `<p>We are pleased to present the following ${type} application.</p><p><strong>If you wish to provide a quote for this risk, please send your terms through the Relay platform. Only terms submitted through the platform will be considered.</strong></p>`

const schemas = (
  lineOfBusiness,
  submission,
  schemas,
  newSubmission = false
) => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Submission.schemas(submission, schemas, newSubmission)
    case 'Multiline Cyber':
      return MultilineCyberConfig.Submission.schemas(
        submission,
        schemas,
        newSubmission
      )
    case 'Terrorism':
      return TerrorismConfig.Submission.schemas(
        submission,
        schemas,
        newSubmission
      )
    case 'Business_Owner':
      return BOPConfig.Submission.schemas(schemas, newSubmission)
    default:
      return schemas
  }
}

const formatData = (lineOfBusiness, formData, isEdit) => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      MLConfig.Submission.formatData(formData, isEdit)
      break
    case 'Multiline Cyber':
      MultilineCyberConfig.Submission.formatData(formData, isEdit)
      break
    case 'Terrorism':
      TerrorismConfig.Submission.formatData(formData, isEdit)
      break
    case 'Business_Owner':
      BOPConfig.Submission.formatData(formData, isEdit)
      break
  }
  return formData
}

const model = lineOfBusiness => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Submission.model
    case 'Cyber':
      return CyberConfig.Submission.model
    case 'Multiline Cyber':
      return MultilineCyberConfig.Submission.model
    case 'Terrorism':
      return TerrorismConfig.Submission.model
    case 'Business_Owner':
      return BOPConfig.Submission.model
    default:
      return {}
  }
}

const flexiForm = (
  lineOfBusiness,
  formData,
  updateFormData,
  isEdit = false
) => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Submission.flexiForm(isEdit)
    case 'Multiline Cyber':
      return MultilineCyberConfig.Submission.flexiForm(formData, updateFormData)
    case 'Terrorism':
      return TerrorismConfig.Submission.flexiForm(formData, updateFormData)
    case 'Business_Owner':
      return BOPConfig.Submission.flexiForm(formData, updateFormData)
    default:
      return {}
  }
}

const panel = lineOfBusiness => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Submission.panel
    case 'Multiline Cyber':
      return MultilineCyberConfig.Submission.panel
    case 'Terrorism':
      return TerrorismConfig.Submission.panel
    default:
      return {}
  }
}

const submissionData = (lineOfBusiness, data) => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Submission.submissionData(data)
    case 'Multiline Cyber':
      return MultilineCyberConfig.Submission.submissionData(data)
    case 'Terrorism':
      return TerrorismConfig.Submission.submissionData(data)
    case 'Business_Owner':
      return BOPConfig.Submission.submissionData(data)
    default:
      return {}
  }
}

const afterSubmit = (lineOfBusiness, isDraft, data) => {
  switch (lineOfBusiness) {
    case 'Multiline Cyber':
      MultilineCyberConfig.Submission.afterSubmit(isDraft, data)
      break
    case 'Terrorism':
      TerrorismConfig.Submission.afterSubmit(isDraft, data)
      break
    default:
      break
  }
}

const renderNav = (lineOfBusiness, submission, panelId) => {
  switch (lineOfBusiness) {
    case 'Management Liability':
      return MLConfig.Submission.renderNav(submission, panelId)
    default:
      return {
        path: `/submissions`,
        label: 'Return to Dashboard',
      }
  }
}

const quoteActions = ({ lineOfBusiness, quotation, quotations }) => {
  // it checks if other quotes are done. It excludes the the quote that matches quotation.id
  const doneQuotations = checkForDoneQuotations(quotations, quotation.id)
  if (doneQuotations) return []

  const isCompleted = includedInQuotationStatus(quotation, COMPLETED_STATES)
  const isEditable = quotation.isCarrier || quotation.isManual

  switch (lineOfBusiness) {
    case 'Management Liability':
      return isEditable ? ['edit'] : []
    case 'Cyber':
      if (isEditable && isCompleted) return ['edit']
      if (isCompleted) return []
      if (isEditable) return ['edit', 'archive']
      return ['archive']
    case 'Private_Clients': {
      const isDeletable = quotation.isDeletable
      if (isCompleted) return ['edit']
      if (isDeletable) return ['edit', 'delete']
      return ['edit']
    }
    default:
      return []
  }
}

const showPdf = (lineOfBusiness, isMultiline) => {
  if (isMultiline) {
    return false
  } else {
    switch (lineOfBusiness) {
      case 'Cyber':
        return true
      case 'Management Liability':
        return false
      default:
        return false
    }
  }
}

const selectedQuoteWarning = quotations => {
  const linesOfBusiness = quotations.map(q => q.lineOfBusiness)
  if (linesOfBusiness.includes('Management Liability')) {
    return {
      numAllowed: 5,
      keyWord: 'application',
    }
  } else {
    return {
      numAllowed: 3,
      keyWord: 'line',
    }
  }
}

export default {
  lineOfBusiness,
  defaultMessage,
  emailCarriers,
  schemas,
  formatData,
  model,
  submissionData,
  afterSubmit,
  flexiForm,
  panel,
  renderNav,
  quoteActions,
  showPdf,
  selectedQuoteWarning,
}
