import React from 'react'
import MUISwitch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'components/theme'

const useStyles = makeStyles({
  switchBase: {
    color: colors.lightGray,
    '&$checked': {
      color: colors.primaryText,
    },
    '&$checked + $track': {
      backgroundColor: colors.primaryText,
    },
  },
  checked: {},
  track: {},
})

const Switch = ({
  input: { name, checked, onChange, value, ...restInput },
  meta,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <MUISwitch
      {...rest}
      name={name}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
      }}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      inputProps={restInput}
      onChange={onChange}
      checked={checked}
      value={value}
    />
  )
}

export default Switch
