import React, { useState } from 'react'

import { useHistory } from 'react-router'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'

import { Row, Text, Icon, Loading, Panel } from 'atoms'
import FlexiForm from 'FlexiForm'
import { PageLayout, QuotationConfig } from 'pages/shared/panels'
import { useCurrentUser } from 'hooks'

const Quote = ({
  lineOfBusiness,
  getSchemas,
  returnUrl,
  isManual,
  newQuoteSchemaQuery,
  createQuoteMutation,
  submission,
  userType,
}) => {
  const { pendingInvitation } = useCurrentUser()
  const history = useHistory()
  const createQuotation = createQuoteMutation()
  const [formData, updateFormData] = useState({})
  const { data, loading, error } = newQuoteSchemaQuery()

  if (error) throw error
  if (loading) return <Loading />

  let schemas = JSON.parse(getSchemas(data))
  schemas = QuotationConfig.schemas(
    lineOfBusiness,
    schemas,
    userType,
    formData,
    updateFormData,
    isManual
  )
  const initialValues = QuotationConfig.prefillQuotationData(
    lineOfBusiness,
    schemas,
    submission,
    userType,
    isManual
  )

  const handleSubmit = async formData => {
    await createQuotation({
      ...formData,
      status: formData.status.toLowerCase(),
    })
    // TODO: add error handling
    if (pendingInvitation && userType === 'reinsurer') {
      history.push('/')
    } else {
      history.push(returnUrl)
    }
  }

  // const handleAnotherQuoteOption = () => {
  //   history.push(returnUrl)
  // }

  return (
    <PageLayout
      nav={{
        path: returnUrl,
        label: 'Return to Application',
      }}>
      <Panel p={3}>
        <FlexiForm
          {...schemas}
          initialValues={initialValues}
          onChange={updateFormData}
          onSubmit={handleSubmit}
          skipRules={lineOfBusiness === 'Management Liability'}
          submitLabel={
            <Row alignItems="center">
              <Text>Finish Quoting</Text>
              <Icon ml={0} fontSize="1rem" icon={faAngleRight} />
            </Row>
          }
          // enableDraft={true}
          // onSaveDraft={handleAnotherQuoteOption}
          // draftLabel="Enter Another Quote"
        />
      </Panel>
    </PageLayout>
  )
}

export default Quote
