import React from 'react'
import styled from 'styled-components'
import { layout } from 'styled-system'

import { Flex, Label, Error } from 'atoms'

const Area = styled.textarea`
  width: 100%;
  padding: 10px;
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 300;
  font-size: 16px;
  resize: vertical;
  color: ${props => props.theme.colors.primaryText};
  outline: none;
  box-shadow: 1px 2px 2px inset #eee;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  border-color: ${props =>
    props.touched && props.error ? props.theme.colors.error : '#C2C4C6'};
  background-color: white !important;
  overflow: auto;

  &::placeholder {
    color: ${props => props.theme.colors.primaryTextInactive};
  }

  &:hover,
  &:focus {
    border-color: #afb1b3;
  }

  ${layout}
`
Area.displayName = 'StyledArea'
Area.defaultProps = { minHeight: '150px' }

const TextArea = React.forwardRef(
  (
    {
      input,
      meta,
      label,
      note,
      required,
      disabled,
      textareaStyles,
      toolTipContent = null,
      placeholder = null,
      ...style
    },
    ref
  ) => {
    return (
      <Flex flexDirection="column" {...style}>
        {label && (
          <Label
            htmlFor={input.name}
            required={required}
            note={note}
            toolTipContent={toolTipContent}>
            {label}
          </Label>
        )}
        <Area
          id={input.name}
          ref={ref}
          disabled={disabled}
          touched={meta.touched}
          placeholder={placeholder}
          error={meta.error || meta.submitError}
          {...input}
          {...textareaStyles}></Area>
        {(meta.error || meta.submitError) && meta.touched && (
          <Error>{meta.error || meta.submitError}</Error>
        )}
      </Flex>
    )
  }
)

TextArea.displayName = 'TextArea'

export default TextArea
