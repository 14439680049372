import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { isEmpty } from 'util/lodash'
import {
  Paper,
  Modal,
  Box,
  Button,
  Checkbox,
  makeStyles,
  FormControlLabel,
} from '@material-ui/core'
import { faList } from '@fortawesome/pro-solid-svg-icons'
import { Link, Element } from 'react-scroll'
import CyberModalTemplate from './CyberModalTemplate'
import { colors } from 'components/theme'
import {
  Icon,
  Subtitle,
  Div,
  ExplanationBox,
  Column,
  Button as StyledButton,
} from 'atoms'
import { useFeature } from 'components/common/FeatureSwitch'

const IconInButton = styled(Icon)`
  font-size: 12px !important; // Override the Material UI button icon sizing
`

const ModalContainer = styled(Div)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  max-height: 86%;
  overflow: auto;
  transform: translate(-50%, -50%);
`

const useStyles = makeStyles({
  sectionTitle: {
    fontSize: '1.1rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  questionButton: {
    color: colors.link,
    borderColor: colors.link,
    borderWidth: 2,
    fontWeight: 600,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    paddingTop: '30px',
    marginBottom: '10px',
  },
  titleHelper: {
    marginBottom: '10px',
  },
  navSection: {
    position: 'sticky',
    top: '25%',
    display: 'flex',
    flexDirection: 'column',
    padding: '22px',
  },
  active: {
    color: colors.black,
    fontWeight: '600',
  },
  form: {
    marginBottom: '10px',
  },
  submitBtn: {
    zIndex: '1300',
    position: 'sticky',
    marginTop: '60px',
    bottom: '0%',
    borderTop: `1px solid ${colors.secondaryYellow}`,
    backgroundColor: colors.white,
    backgroundImage: 'linear-gradient(90deg, #FFF9E7 71.32%, #FFFFFF 95.87%)',
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

const filterSchema = (category, schema) => ({
  ...schema,
  properties: Object.fromEntries(
    Object.entries(schema.properties).filter(
      ([, property]) => property.category === category
    )
  ),
})

export const getCategories = (sections, schema) =>
  sections.filter(
    category =>
      category !== 'Hidden' &&
      !isEmpty(filterSchema(category, schema).properties) &&
      !filterSchema(category, schema).properties.multiAuthKeys
  )

// eslint-disable-next-line no-unused-vars
const ModalField = ({
  uiSchema,
  schema,
  onChange,
  formData = {},
  formContext,
  registry,
  ...props
}) => {
  const sections = [
    'Prequalification',
    'General',
    'Prior Acts/Losses & Warranty',
    'Information & Network Security',
    'Professional Liability',
    'Computer Crime',
    'Media',
    'Others',
    'Hidden',
  ]

  const categories = getCategories(sections, schema)

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [indication, setIndication] = React.useState(false)
  const [category, setCategory] = useState(categories[0])
  const indicationEnabled = useFeature('indication')

  useEffect(() => {
    setIndication(formData.indication)
  }, [formData.indication])

  if (Object.keys(schema.properties).length === 0) {
    return null
  }

  // Remove the ui:field so it's not an infinitely nested modal.
  const { 'ui:field': _, ...rest } = uiSchema
  const [newUiSchema, setUiSchema] = useState(rest)

  const submitAdditionalWarningMsg =
    'You have changed the answer to a common underwriting question. If you continue, quotes for affected carriers will also be refreshed and any out of date quotes will be archived.'

  const handleChange = changedData => {
    if (newUiSchema['ui:options']?.warningType === 'submitAdditional') {
      for (const k in changedData) {
        if (changedData[k] !== formData[k]) {
          setUiSchema({
            ...newUiSchema,
            [k]: {
              ...newUiSchema[k],
              'ui:options': {
                warningType: 'submitAdditional',
                warningMsg: submitAdditionalWarningMsg,
              },
            },
          })
        }
      }
    }
    onChange(changedData)
  }

  const handleSubmit = () => {
    onChange(formData)
    setOpen(false)
  }

  const handleCheckboxChange = event => {
    onChange({
      ...formData,
      indication: event.target.checked,
    })
    setIndication(event.target.checked)
  }

  const notSureAnswer = Object.values(formData).includes('UN')
  let scrollFixTimeout = null

  const SchemaField = registry?.fields?.SchemaField

  return (
    <>
      <Subtitle className="cyber-uw-questions">
        {schema.title.toUpperCase()}
      </Subtitle>
      <Box width="auto">
        <Button
          size="large"
          variant={indication ? 'contained' : 'outlined'}
          startIcon={<IconInButton icon={faList} fontSize="s" />}
          className={classes.questionButton}
          onClick={() => setOpen(isOpen => !isOpen)}
          disabled={indication}>
          Show {schema.title}
        </Button>

        {indicationEnabled && (
          <>
            <Div display="inline" verticalAlign="middle" ml="1.5rem">
              OR
            </Div>
            <FormControlLabel
              checked={indication}
              control={
                <Checkbox
                  name="indication"
                  color="primary"
                  sx={{ m: 2 }}
                  style={{
                    marginLeft: 16,
                    marginRight: -4,
                  }}
                />
              }
              label="Get Quick Indication"
              onChange={handleCheckboxChange}
            />
          </>
        )}
      </Box>

      <Modal keepMounted open={open} onClose={() => setOpen(false)}>
        <ModalContainer id="scrollContainer">
          {notSureAnswer && (
            <ExplanationBox>
              Questions marked with Not Sure will lead to an indication. You may
              change these answers to create a valid quote.
            </ExplanationBox>
          )}

          <Paper>
            <Div display="flex">
              <Column width="35%">
                <Div className={classes.navSection}>
                  {categories.map(section => (
                    <Link
                      style={{ paddingBottom: '5px' }}
                      key={section}
                      activeClass="active"
                      className={section === category ? classes.active : ''}
                      to={section}
                      spy={true}
                      smooth={true}
                      isDynamic={true}
                      duration={500}
                      offset={30}
                      onSetActive={category => {
                        setCategory(category)
                      }}
                      onClick={() => {
                        if (scrollFixTimeout) {
                          clearTimeout(scrollFixTimeout)
                        }
                        scrollFixTimeout = setTimeout(() => {
                          document
                            .getElementById('scrollContainer')
                            .scrollBy(0, 1)
                        }, 600)
                      }}
                      containerId="scrollContainer">
                      {section} (
                      {
                        Object.keys(filterSchema(section, schema).properties)
                          .length
                      }
                      )
                    </Link>
                  ))}
                </Div>
              </Column>
              <Column style={{ paddingBottom: 'calc(100vh - 270px)' }}>
                <Div pl="20px">
                  <Div className={classes.title}>{schema.title}</Div>
                  <Div className={classes.titleHelper}>
                    Answer as many questions as you can to unlock all the
                    carriers. You can choose{' '}
                    <strong>Not Sure or leave an answer blank</strong>, which
                    may allow a carrier to provide a non-binding indication
                    while you confirm the facts with your client.
                  </Div>
                </Div>
                {categories.map(category => (
                  <Element key={category} name={category}>
                    {category !== 'Others' && (
                      <Div mt="45px" pl="20px" className={classes.sectionTitle}>
                        {category?.toUpperCase()}
                      </Div>
                    )}
                    <Div width="calc(100% - 16px)">
                      <SchemaField
                        schema={{
                          ...filterSchema(category, schema),
                          title: '',
                        }}
                        uiSchema={{
                          ...newUiSchema,
                          ['ui:title']: '',
                        }}
                        formData={formData}
                        onChange={newFlatAnswers => {
                          handleChange(newFlatAnswers)
                        }}
                        registry={{
                          ...registry,
                          FieldTemplate: CyberModalTemplate,
                        }}
                        {...props}
                      />
                    </Div>
                  </Element>
                ))}
              </Column>
            </Div>

            <Div className={classes.submitBtn}>
              <StyledButton onClick={handleSubmit}>Continue</StyledButton>
            </Div>
          </Paper>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default ModalField
