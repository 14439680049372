import React from 'react'
import numeral from 'numeral'
import styled from 'styled-components'
import { typography, space, color, layout, flexbox } from 'styled-system'

import { useSubmission } from 'hooks'
import currencies from 'data/currencies'

const Amount = styled.span`
  white-space: nowrap;
  display: inline-block;
  text-decoration: inherit;
  ${space} ${color} ${typography} ${layout} ${flexbox};
`

const $ = ({
  short = false,
  fallback = '',
  children,
  currency: defaultCurrency,
  ...style
}) => {
  const submission = useSubmission()
  const currency =
    currencies[submission?.currency] ||
    currencies[defaultCurrency] ||
    currencies.USD

  if (children === undefined || children === null || children === '') {
    return <>{fallback}</>
  }

  const value = numeral(children.toString())

  if (value.value() === null || value.value().toString() === 'NaN') {
    return <Amount {...style}>{fallback}</Amount>
  }

  const abs = numeral(Math.abs(value.value()))
  const negative = value.value() < 0

  if (!short) {
    return (
      <Amount {...style}>
        {`${negative ? '-' : ''}${currency.symbol}${abs.format(
          currency.format.long
        )}`}
      </Amount>
    )
  }

  const long = `${negative ? '-' : ''}${currency.symbol}${value.format(
    currency.format.long
  )}`

  const truncated = numeral(value.format(currency.format.truncated))
  if (truncated.value() === value.value()) {
    return (
      <Amount title={long} {...style}>
        {`${negative ? '-' : ''}${currency.symbol}${abs
          .format(currency.format.truncated)
          .toUpperCase()}`}
      </Amount>
    )
  }

  return (
    <Amount title={long} {...style}>
      {`~${negative ? '-' : ''}${currency.symbol}${abs
        .format(currency.format.rounded)
        .toUpperCase()}`}
    </Amount>
  )
}

export const money = (value, options = {}) => <$ {...options}>{value}</$>

export default $
