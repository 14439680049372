import React from 'react'
import { Div } from 'atoms'
import styled from 'styled-components'
import { space } from 'styled-system'

const Button = styled(Div)`
  min-width: 90px;
  height: 30px;
  font-weight: 600;
  font-size: 16px;
  background-color: ${props =>
    props.selected ? props.theme.colors.primaryText : 'none'};
  color: ${props =>
    props.selected ? props.theme.colors.white : props.theme.colors.link};
  border-radius: 24px;
  border: ${props =>
    props.selected ? 'none' : `1px solid ${props.theme.colors.link}`};
  box-sizing: border-box;
  cursor: ${props => (props.selected ? 'default' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 24px;
  padding: 0 1rem;
  white-space: nowrap;
  flex-shrink: 0;
  ${space}
`

const FilterButton = ({ label, amount, selected, ...rest }) => {
  return (
    <Button {...rest} selected={selected}>
      {label} ({amount})
    </Button>
  )
}

export default FilterButton
