import React, { Fragment } from 'react'
import moment from 'moment-timezone'

const TIME_FORMAT = 'h:mma'

const TimeString = ({ time }) => {
  return <Fragment>{moment(time).format(TIME_FORMAT)}</Fragment>
}

export default TimeString
