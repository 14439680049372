import { CompanyKindType } from 'graphql/api'
import { useContext } from 'react'

import UserContext, { isAuthenticated } from '../components/contexts/user'

const useCurrentUser = () => {
  const userContext = useContext(UserContext)

  return isAuthenticated(userContext) ? userContext.currentUser : null
}

export const isCedent = user => user.type === 'Cedent'
export const isReinsurer = user => user.type === 'Reinsurer'
export const isBroker = user => user.type === 'Broker'
export const isNotReinsurer = user =>
  user.type === 'Broker' || user.type === 'Cedent'
export const isReinsuranceBroker = user =>
  user.company.type === 'ReinsuranceBroker'
export const isInvited = user => user.pendingInvitation
export const inInsurance = user => user.company.inInsurance
export const isGuest = user => user.company.guest

export const isBMS = user =>
  user.company.name === 'BMS' || user.company.name === 'BMS Re'
export const isBrokerBindingOn = user => JSON.parse(user.features).brokerBinding

export const REINSURANCE_BROKER = 'ReinsuranceBroker'

export const isWholesaler = user =>
  user.company.kind === CompanyKindType.WholesaleBrokerage

export default useCurrentUser
