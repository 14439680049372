import {
  usePcgDeclineToBindEmailQuotationMutation,
  usePcgBindEmailQuotationMutation,
} from 'graphql/api'

const queries = {}

const mutations = {
  bindQuote: {
    mutation: usePcgBindEmailQuotationMutation,
    getVariables: ({ quotationId, policyNumber, documents }) => ({
      variables: {
        input: {
          id: quotationId,
          policy_number: policyNumber,
          documents: documents,
        },
      },
    }),
    getError: data => data.pcgBindEmailQuotation.errors,
  },
  declineQuote: {
    mutation: usePcgDeclineToBindEmailQuotationMutation,
    getVariables: ({ quotationId }) => ({
      variables: {
        input: {
          id: quotationId,
        },
      },
    }),
    getError: data => data.pcgDeclineToBindEmailQuotation.errors,
  },
}

const getters = {}

const components = {}

const config = {
  queries,
  mutations,
  getters,
  components,
}

export default config
