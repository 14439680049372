import React from 'react'
import {
  faFingerprint,
  faBell,
  faIdCard,
  faStream,
  faSignOutAlt,
} from '@fortawesome/pro-solid-svg-icons'

import SettingsMenuDropdown from './SettingsMenuDropdown'
import { usePermission, useCurrentUser, useBranding, useLogout } from 'hooks'

const SettingsMenu = () => {
  const [logout] = useLogout()
  const currentUser = useCurrentUser()
  const [canManageBilling] = usePermission('manage', 'billing')
  const { billable } = useBranding()

  if (!currentUser) return null

  let menuItems = [
    {
      name: 'My Profile',
      icon: faIdCard,
      to: '/settings/profile/edit',
    },
    {
      name: 'Login Settings',
      icon: faFingerprint,
      to: '/settings/login',
    },
    {
      name: 'Notification Settings',
      icon: faBell,
      to: '/settings/notification',
    },
    {
      name: 'Logout',
      icon: faSignOutAlt,
      action: logout,
    },
  ]

  if (billable && canManageBilling) {
    menuItems.splice(-1, 0, {
      name: 'Plan Details',
      icon: faStream,
      to: '/settings/plan_details',
    })
  }

  return <SettingsMenuDropdown testID="navMenu" options={menuItems} />
}

export default SettingsMenu
