import Quotation from './QuotationConfig'
import Submission from './SubmissionConfig'
import Config from './Config'
import Applicant from './Applicant'
import QuoteContent from './QuoteContent'
import QuoteSummary from './QuoteSummary'
import Notifications from './NotificationConfig'

export default {
  Config,
  Quotation,
  Submission,
  Applicant,
  QuoteContent,
  QuoteSummary,
  Notifications,
}
