import React from 'react'
import styled from 'styled-components'
import { useField } from 'react-final-form'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'

import { Flex, Label, Text, Error } from 'atoms'

const Preformatted = styled(Text)`
  white-space: pre-wrap;
`

const identity = x => x

const Field = ({
  label,
  required,
  name,
  fallback = '—',
  format = identity,
  ...rest
}) => {
  const { input, meta } = useField(name, rest)
  return (
    <Flex {...rest} flexDirection="column">
      {label && <Label required={required}>{label}</Label>}
      <Preformatted>
        {isEmpty(input.value) && !isNumber(input.value)
          ? fallback
          : format(input.value)}
      </Preformatted>
      {meta.submitError ? (
        <Error>{meta.submitError}</Error>
      ) : (
        meta.error && <Error>{meta.error}</Error>
      )}
    </Flex>
  )
}

export default Field
