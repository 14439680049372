import React from 'react'
import { useTheme, ThemeProvider } from '@material-ui/core/styles'
import { darken } from 'polished'

import { colors, fonts } from '../components/theme'

const Theme = ({ children }) => {
  const theme = useTheme()
  theme.overrides = {
    ...theme.overrides,
    MuiTab: {
      root: {
        zIndex: 2,
        color: colors.black,
        fontWeight: 600,
        textTransform: 'none',
        minHeight: '37px',
        marginBottom: '0.5rem',
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowWrap: 'anywhere',
        marginLeft: '2rem',
      },
      labelIcon: {
        minHeight: '0',
        paddingTop: '4px',
      },
    },
    MuiTabs: {
      indicator: {
        width: '90%',
        backgroundColor: colors.white,
        borderTopLeftRadius: '30px',
        borderBottomLeftRadius: '30px',
        borderBottom: `3px solid transparent`,
        borderImage: `linear-gradient(90deg,${colors.background},${colors.secondaryYellow}) 1`,
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiButton: {
      containedPrimary: {
        borderRadius: '24px',
        height: '48px',
        fontFamily: fonts.secondary,
        fontWeight: 600,
        fontSize: '16px',
        textTransform: 'none',
        color: colors.primaryActionText,
        boxShadow: 'none',
        backgroundColor: colors.primaryAction,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: darken(0.03, colors.primaryAction),
          borderColor: darken(0.03, colors.primaryAction),
        },
      },
      outlinedPrimary: {
        borderRadius: '24px',
        height: '48px',
        fontFamily: fonts.secondary,
        fontWeight: 600,
        fontSize: '16px',
        textTransform: 'none',
        color: colors.primaryActionText,
        boxShadow: 'none',
        borderStyle: 'solid',
        borderWidth: '4px',
        borderColor: colors.primaryAction,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: darken(0.03, colors.white),
          borderColor: darken(0.03, colors.primaryAction),
          borderStyle: 'solid',
          borderWidth: '4px',
        },
      },
      label: {
        margin: '0 1rem',
      },
      outlinedSecondary: {
        color: colors.primaryText,
        borderColor: colors.primaryText,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: colors.lightGray,
          borderColor: colors.black,
        },
      },
      containedSecondary: {
        fontSize: '14px',
        textTransform: 'uppercase',
        color: colors.white,
        backgroundColor: colors.primaryText,
        '&:hover': {
          backgroundColor: darken(0.03, colors.primaryText),
        },
      },
    },
  }
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme
