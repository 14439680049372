import React from 'react'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'

import { Div, Text, Icon, Flex, Note } from 'atoms'
import { Popover } from 'antd'

const Label = ({
  htmlFor,
  note,
  required,
  children,
  fontWeight = 2,
  fontSize = 'm',
  toolTipContent = null,
  color = 'primaryText',
  ...rest
}) => (
  <Flex width="auto" alignItems="baseline" {...rest}>
    <Text
      as="label"
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      m="0.125rem 0"
      htmlFor={htmlFor}>
      {children}
    </Text>
    {required && (
      <Text color={color} fontWeight={2} fontSize="m">
        *
      </Text>
    )}
    {toolTipContent && (
      <Div ml="1rem" mt="auto" mb="auto">
        <Popover content={toolTipContent} trigger="click">
          <Icon icon={faQuestionCircle} />
        </Popover>
      </Div>
    )}

    {note && (React.isValidElement(note) ? note : <Note ml={1}>{note}</Note>)}
  </Flex>
)

export default Label
